import React, { FunctionComponent } from 'react';
import GameOverview from './GameOverview';
import { RecentBossBattles } from '../../schema';
import BossHeader from '../BossHeader';
import useBossLobbyStyles from '../useBossFightsStyles';
import { Fade } from '@mui/material';
import { IBossBattleOverview } from './GameOverviewPlayer';

interface IBossGame {
  recentBossBattles: RecentBossBattles;
}

const BossGame: FunctionComponent<IBossGame> = (props) => {
  const { boss, battles } = props.recentBossBattles;
  const bossStyles = useBossLobbyStyles();

  function renderGames() {
    if (battles) {
      return (
        <GameOverview
          battles={battles.nodes as IBossBattleOverview[]}
          pageInfo={battles.pageInfo}
          realm={boss.realm}
        />
      );
    }

    return null;
  }

  return (
    <Fade in={true} timeout={600}>
      <div className={bossStyles.bossCard}>
        <BossHeader boss={boss} showInfo={true} />
        {renderGames()}
      </div>
    </Fade>
  );
};

export default BossGame;
