import React, { FunctionComponent } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { white } from '../../themev2';
import LeaderboardResetCountdown, {
  ILeaderboardCountdown,
} from '../../components/BossFights/BossLeaderboards/LeaderboardResetCountdown';
import { LeaderboardTimeframe } from '../../components/schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 2rem 1rem 2rem',
    position: 'relative',

    '@media (max-width: 1300px)': {
      margin: '0 2rem 7rem 2rem',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',

      '& p': {
        margin: '1rem auto',
        maxWidth: '310px',
      },
    },
  },
  countdown: {
    marginTop: (props: any) => (props.small ? '0' : '1.5rem'),
    padding: (props: any) =>
      props.small ? '0.5rem 1rem 1.2rem 1rem' : '1rem 1.5rem 1.7rem 1.5rem',
    backgroundColor: 'rgba(51, 49, 148, 0.5)',
    borderRadius: '8px',
    minWidth: '220px',
    maxWidth: '360px',
  },
}));

interface ILeaderboardHeader extends ILeaderboardCountdown {
  title: React.ReactNode | string;
  body?: React.ReactNode | string;
  timeframe?: LeaderboardTimeframe;
  children?: React.ReactNode;
}

export const LeaderboardHeader: FunctionComponent<ILeaderboardHeader> = (
  props,
) => {
  const classes = useStyles({ small: false });
  const { title, body, children, timeframe } = props;

  return (
    <div className={clsx(classes.root)}>
      <Typography variant={'h1'} color={white}>
        {title}
      </Typography>
      {body && (
        <Typography variant={'body1'} color={white} style={{ opacity: '0.9' }}>
          {body}
        </Typography>
      )}
      {children ?? null}
      <LeaderboardResetCountdown
        resetAt={props.resetAt}
        timeframe={timeframe}
        resetMessage={props.resetMessage}
        heartsMessage={props.heartsMessage}
        className={classes.countdown}
      />
    </div>
  );
};

export default LeaderboardHeader;
