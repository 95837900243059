import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Spider = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <circle fill='#FFF' cx={148} cy={137} r={10} />
    <linearGradient
      id='Spider__a'
      gradientUnits='userSpaceOnUse'
      x1={152}
      y1={-2392}
      x2={152}
      y2={-2378}
      gradientTransform='matrix(1 0 0 -1 0 -2248)'>
      <stop
        offset={0.062}
        style={{
          stopColor: '#daffa4',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#84ffc0',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__a)' cx={152} cy={137} r={7} />
    <circle cx={152} cy={137} r={5} />
    <path
      fill='#FFF'
      d='M156.49 130.6h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.44-1.65-1.26-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={148}
      cy={137}
      r={10}
    />
    <circle fill='#FFF' cx={172} cy={138} r={10} />
    <linearGradient
      id='Spider__b'
      gradientUnits='userSpaceOnUse'
      x1={175}
      y1={-2393}
      x2={175}
      y2={-2378}
      gradientTransform='matrix(1 0 0 -1 0 -2248)'>
      <stop
        offset={0.062}
        style={{
          stopColor: '#d6c2ff',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#fb7074',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__b)' cx={175} cy={138} r={7} />
    <circle cx={175} cy={138} r={5} />
    <path
      fill='#FFF'
      d='M179.9 131.09h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.44-1.65-1.26-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={172}
      cy={138}
      r={10}
    />
    <circle fill='#FFF' cx={160} cy={156} r={10} />
    <linearGradient
      id='Spider__c'
      gradientUnits='userSpaceOnUse'
      x1={163}
      y1={163}
      x2={163}
      y2={148}>
      <stop
        offset={0.187}
        style={{
          stopColor: '#abffff',
        }}
      />
      <stop
        offset={0.605}
        style={{
          stopColor: '#c0adff',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__c)' cx={163} cy={155} r={7} />
    <circle cx={163} cy={155} r={5} />
    <path
      fill='#FFF'
      d='M167.85 148.75h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.45-1.65-1.27-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={160}
      cy={156}
      r={10}
    />
    <circle fill='#FFF' cx={219} cy={137} r={10} />
    <linearGradient
      id='Spider__d'
      gradientUnits='userSpaceOnUse'
      x1={222}
      y1={-2392}
      x2={222}
      y2={-2378}
      gradientTransform='matrix(1 0 0 -1 0 -2248)'>
      <stop
        offset={0.062}
        style={{
          stopColor: '#d6c2ff',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#fb7074',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__d)' cx={222} cy={137} r={7} />
    <circle cx={222} cy={137} r={5} />
    <path
      fill='#FFF'
      d='M226.81 130.6h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.44-1.65-1.26-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={219}
      cy={137}
      r={10}
    />
    <circle fill='#FFF' cx={242} cy={138} r={10} />
    <linearGradient
      id='Spider__e'
      gradientUnits='userSpaceOnUse'
      x1={245}
      y1={-2393}
      x2={245}
      y2={-2378}
      gradientTransform='matrix(1 0 0 -1 0 -2248)'>
      <stop
        offset={0.187}
        style={{
          stopColor: '#abffff',
        }}
      />
      <stop
        offset={0.605}
        style={{
          stopColor: '#c0adff',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__e)' cx={245} cy={138} r={7} />
    <circle cx={245} cy={138} r={5} />
    <path
      fill='#FFF'
      d='M250.22 131.09h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.44-1.65-1.26-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={242}
      cy={138}
      r={10}
    />
    <circle fill='#FFF' cx={230} cy={156} r={10} />
    <linearGradient
      id='Spider__f'
      gradientUnits='userSpaceOnUse'
      x1={233}
      y1={163}
      x2={233}
      y2={148}>
      <stop
        offset={0.062}
        style={{
          stopColor: '#daffa4',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#84ffc0',
        }}
      />
    </linearGradient>
    <circle fill='url(#Spider__f)' cx={233} cy={155} r={7} />
    <circle cx={233} cy={155} r={5} />
    <path
      fill='#FFF'
      d='M238.17 148.75h-.05c-1.55 0-2.81 1.3-2.81 2.9 0 1.6 1.26 2.9 2.81 2.9 1.03 0 1.92-.57 2.41-1.43-.45-1.65-1.27-3.14-2.36-4.37z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={230}
      cy={156}
      r={10}
    />
  </svg>
);

export default Spider;
