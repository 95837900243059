import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import SvgTix from '../../../assets/SvgIconTix';
import { IPurchasedTickets } from '../TicketPurchaseForm';
import useCommonStyles from '../../../components/useCommonStyles';
import { useWalletSelector } from '../../../hooks';

type ITicketPurchaseStripe = IPurchasedTickets;

const stripePk =
  'pk_live_51MVe9bHv2TvDINA3BehOl3zxExatWEcEQpPF79olFJvxS95AXdvncgOYZyd3AcnqjCCRfTuJTNZ74uXTdzlMfmSm00DHtnit6M';

export interface IStripeProduct {
  id: string;
  priceUsd: number;
  quantity: number;
}

export const stripeProducts: IStripeProduct[] = [
  {
    id: 'price_1MVg64Hv2TvDINA3KcmNnVqJ',
    quantity: 5,
    priceUsd: 9.99,
  },
  {
    id: 'price_1MVg6rHv2TvDINA3Z6M6MGTe',
    quantity: 25,
    priceUsd: 46.99,
  },
  {
    id: 'price_1MVg7WHv2TvDINA3lm82cM0i',
    quantity: 50,
    priceUsd: 89.99,
  },
];

const TicketPurchaseStripe: React.FunctionComponent<ITicketPurchaseStripe> = (
  props,
) => {
  // const { onPurchased } = props;
  // // const stripe = useStripe();
  // const stripePromise = loadStripe(stripePk);
  //
  // return <Elements stripe={stripePromise} >
  //   <StripeCheckoutForm />
  // </Elements>;
  const common = useCommonStyles();
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');

  function handlePurchase(product: IStripeProduct): void {
    window.location.replace(
      `/checkout/tix?playerId=${playerId}&quantity=${product.quantity}`,
    );
  }

  return (
    <Grid container>
      {stripeProducts.map((iap) => {
        const { id, quantity, priceUsd } = iap;
        const resource = 'TIX';

        return (
          <Grid key={id} item xs={4} spacing={1}>
            <Typography variant={'h4'} style={{ marginBottom: 14 }}>
              {quantity}{' '}
              <span>
                <SvgTix fill={'black'} style={{ width: 20 }} />
              </span>
            </Typography>
            <Button
              variant={'contained'}
              className={common.actionButton}
              color={'primary'}
              // disabled={!!purchasing}
              onClick={() => handlePurchase(iap)}>
              <Typography variant={'h6'}>${priceUsd}</Typography>
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TicketPurchaseStripe;
