import React, { FunctionComponent } from 'react';
import { Container, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { usePageTitle } from '../../Analytics';
import { useParams } from 'react-router-dom';
import SignupPromptCard from './SignupPromptCard';
import useCommonStyles from '../../components/useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    width: '100%',
    minHeight: 'calc(100vh)',
    maxHeight: 'calc(100vh)',
    color: 'white',
  },
}));

interface IParams {
  source?: string;
}

interface IGetFurballs {}

// Meant to be LIGHTWEIGHT promo for Furballs, i.e., inside iFrame (500x500) widget!
export const GetFurballs: FunctionComponent<IGetFurballs> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const params = useParams<IParams>();

  function getSourceName(): string {
    if (params.source === 'iframe') return 'Embedded Widget';
    return params.source ?? 'Unknown';
  }

  usePageTitle(getSourceName() + ' | Get Furballs');

  return (
    <div className={clsx(classes.root, common.furBk)}>
      <Container maxWidth={'sm'}>
        <SignupPromptCard />
      </Container>
    </div>
  );
};

export default GetFurballs;
