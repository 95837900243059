import { itemLibrary } from '../../../components/Inventory/ItemLibrary';
import { ItemDefinitionFragment, ItemGroup } from '../../../components/schema';

export function getLootDisplayGroup(g: ItemGroup): string {
  if (g === ItemGroup.EquipmentRing) return 'Rings (Equipment)';
  if (g === ItemGroup.EquipmentOffHand) return 'Off-Hand (Equipment)';
  if (g === ItemGroup.Material) return 'Crafting Materials';
  if (g === ItemGroup.Consumable) return 'Elixirs';
  if (g === ItemGroup.Special) return 'Keys';
  return 'Items';
}

export function getLootDisplayName(i: ItemDefinitionFragment): string {
  if (i.itemGroup === ItemGroup.Consumable) {
    const p = i.name.split(' ');
    p.pop();
    return p.join(' ');
  }
  return i.name;
}

export function getLootItemDefinition(
  itemId: string,
): ItemDefinitionFragment | undefined {
  return itemLibrary.find((item) => item.id == itemId);
}
