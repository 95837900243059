import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IFurball,
  IFurballEdition,
  IFurballWallet,
  IFurballWalletContents,
  IFurballZoneMove,
  IFurballPending,
  IBalances,
  IClaimedRewards,
  ICraftingSlot,
  IInventoryQuantities,
  ICraftingSlotChanged,
  IUpgradeFurball,
} from './WalletTypes';
import { IPlayResponse } from '../pages/Play/PlayTypes';
import _ from 'lodash';
import { saveFurballsToStorage } from '../components/Auth/PlayerFurballLoader';

const initialState: IFurballWallet = {
  furballs: {},
  pending: {},
  received: [],
};

function onPending(
  state: IFurballWallet,
  tokenId: string,
  updater: (p: IFurballPending) => void,
) {
  if (!state.pending[tokenId]) {
    state.pending[tokenId] = { playResponses: [] };
  }
  updater(state.pending[tokenId]);

  if (
    state.pending[tokenId].playResponses.length === 0 &&
    state.pending[tokenId].zoning === undefined
  ) {
    delete state.pending[tokenId];
  }
}

export function mapFurballs(
  addr: string | undefined,
  furballs: IFurball[],
): { [key: string]: IFurball } {
  const fbls: { [key: string]: IFurball } = {};
  furballs.forEach((fb) => {
    const isRented =
      !!fb.activeRentalAgreement?.renterPlayer &&
      fb.activeRentalAgreement.renterPlayer?.id === addr;

    fbls[fb.tokenId] = { ...fb, isRented };
  });
  return fbls;
}

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      if (!action.payload) {
        // Finished loading
        state.loaded = true;
      }
      state.loading = action.payload;
    },
    setAddress: (state, action: PayloadAction<string | undefined>) => {
      // state.user = undefined;
      state.address = action.payload;

      // setUser(action.payload);
    },
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload;
    },
    // setUser: (state, action: PayloadAction<IFurballUser | undefined>) => {
    //   state.user = action.payload;
    // },
    setEdition: (state, action: PayloadAction<IFurballEdition | undefined>) => {
      state.edition = action.payload;
    },
    // updateEditionCount: (state, action: PayloadAction<number>) => {
    //   if (!state.edition) return;
    //   state.edition.count = action.payload; //
    // },
    clearForLogout: (state, action: PayloadAction<void>) => {
      state.contents = undefined;
      state.usableFurballsLoaded = false;
      state.address = undefined;
      state.furballs = {};
    },
    setWalletContents: (
      state,
      action: PayloadAction<IFurballWalletContents | undefined>,
    ) => {
      state.contents = action.payload;
      // action.payload?.furballs.map((fb) => {
      //   state.furballs[fb.tokenId] = fb;
      // });
    },
    addToWallet: (state, action: PayloadAction<string>) => {
      if (!state.contents) return;
      const tokenId = action.payload;
      const tokens = state.contents.tokenIds;
      const isNew = tokens.indexOf(tokenId) < 0;
      if (isNew) {
        tokens.push(tokenId);
        state.contents.tokenIds = tokens;
        state.contents.furballBalance++;
        // if (state.edition) {
        //   state.edition.minted++;
        // }
        state.received.push(action.payload);
      }
    },
    clearReceived: (state, action: PayloadAction<string>) => {
      const idx = state.received.indexOf(action.payload);
      if (idx >= 0) state.received.splice(idx, 1);
    },
    setFurball: (state, action: PayloadAction<IFurball>) => {
      state.furballs[action.payload.tokenId] = action.payload;
    },
    // setFurballUpgrade: (state, action: PayloadAction<IFurball>) => {
    //   state.furballs[action.payload.tokenId].equipment =
    //     action.payload.equipment;
    //   state.furballs[action.payload.tokenId].state = action.payload.state;
    //   state.furballs[action.payload.tokenId].battleStats =
    //     action.payload.battleStats;
    // },
    setFurballs: (
      state,
      action: PayloadAction<{ [key: string]: IFurball }>,
    ) => {
      state.furballs = {
        ...state.furballs,
        ...action.payload,
      };
    },
    updateFurballs: (state, action: PayloadAction<IFurball[]>) => {
      state.furballs = {
        ...state.furballs,
        ...mapFurballs(state.contents?.id, action.payload),
      };
      if (state.contents)
        saveFurballsToStorage(
          state.contents.id,
          state.contents.tokenIds.map((id) => state.furballs[id]),
        );
    },
    setUsableFurballs: (state, action: PayloadAction<IFurball[]>) => {
      state.furballs = {
        ...state.furballs,
        ...mapFurballs(state.contents?.id, action.payload),
      };
      if (state.contents) {
        state.contents.tokenIds = action.payload.map((fb) => fb.tokenId);
        saveFurballsToStorage(
          state.contents.id,
          state.contents.tokenIds.map((id) => state.furballs[id]),
        );
      }
      state.usableFurballsLoaded = true;
    },
    addUsableFurballs: (state, action: PayloadAction<IFurball[]>) => {
      state.furballs = {
        ...state.furballs,
        ...mapFurballs(state.contents?.id, action.payload),
      };
      if (state.contents) {
        state.contents.tokenIds = _.uniq([
          ...state.contents.tokenIds,
          ...action.payload.map((fb) => fb.tokenId),
        ]);
        saveFurballsToStorage(
          state.contents.id,
          state.contents.tokenIds.map((id) => state.furballs[id]),
        );
      } else {
        console.warn('No contents');
      }
    },
    clearUsableFurballs: (state) => {
      if (state.contents) {
        state.contents.tokenIds = [];
      }
      // state.contents.usableFurballsLoaded = false;
    },
    setFurBalance: (state, action: PayloadAction<number>) => {
      if (state.contents) state.contents.furBalance = action.payload;
    },
    setWFurBalance: (state, action: PayloadAction<number>) => {
      if (state.contents) state.contents.wFur = action.payload;
    },
    setTriedLogin: (state, action: PayloadAction<boolean>) => {
      state.triedLogin = action.payload;
    },
    incTicketBalance: (state, action: PayloadAction<number>) => {
      if (state.contents) state.contents.tix += action.payload;
    },
    setTixBalance: (state, action: PayloadAction<number>) => {
      if (state.contents) state.contents.tix = action.payload;
    },
    setCraftingSlot: (state, action: PayloadAction<ICraftingSlot>) => {
      if (state.contents) {
        const craftingSlots = state.contents.craftingSlots;
        craftingSlots[action.payload.slotIndex] = action.payload;
        state.contents.craftingSlots = craftingSlots;
      }
    },
    setCraftingSlots: (state, action: PayloadAction<ICraftingSlot[]>) => {
      if (state.contents) {
        state.contents.craftingSlots = action.payload;
      }
    },
    setInventory: (state, action: PayloadAction<IInventoryQuantities>) => {
      if (state.contents) state.contents.inventory = action.payload;
    },
    updateSlotsAndInventoryAfterCraft: (
      state,
      action: PayloadAction<ICraftingSlotChanged>,
    ) => {
      if (state.contents && action.payload.currentAttempt) {
        const inventory = action.payload.currentAttempt.player.inventory;
        const craftingSlots = state.contents.craftingSlots;
        craftingSlots[action.payload.slotIndex] = action.payload;

        state.contents.inventory = inventory;
        state.contents.craftingSlots = craftingSlots;
      }
    },
    updateBalances: (state, action: PayloadAction<IBalances>) => {
      if (state.contents) {
        if (action.payload.fur !== undefined)
          state.contents.furBalance = action.payload.fur;
        if (action.payload.wFur !== undefined)
          state.contents.wFur = action.payload.wFur;
        if (action.payload.tix !== undefined)
          state.contents.tix = action.payload.tix;
      }
    },
    updateUserAfterClaimedRewards: (
      state,
      action: PayloadAction<IClaimedRewards>,
    ) => {
      if (state.contents) {
        // state.contents.pendingRewards = action.payload.pendingRewards;
        if (state.contents.inventory) {
          state.contents.inventory = action.payload.inventory;
        }
      }
    },
    addPlayReward: (state, action: PayloadAction<IPlayResponse>) => {
      const { tokenId } = action.payload;
      const contents = state.contents;
      if (!contents?.tokenIds.includes(tokenId)) {
        // We only care about tokens that belong to current user
        return;
      }

      // state.furballs[tokenId].stats = action.payload.stats;

      onPending(state, tokenId, (p) => {
        if (action.payload.furBalance)
          contents.furBalance = action.payload.furBalance;
        p.playResponses.push(action.payload);
        p.zoning = undefined;
      });
    },
    clearPlayResponse: (state, action: PayloadAction<string>) => {
      onPending(state, action.payload, (p) => {
        p.playResponses = [];
      });
    },
    setFurballZoning: (state, action: PayloadAction<IFurballZoneMove>) => {
      const { tokenId, zone } = action.payload;
      onPending(state, tokenId, (p) => {
        p.zoning = zone;
      });
    },
    setUpgrading: (
      state,
      action: PayloadAction<IUpgradeFurball | undefined>,
    ) => {
      state.upgrade = action.payload;
    },
  },
});

export default walletSlice;
