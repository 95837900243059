import { IFurballContext } from '../../wallet/WalletTypes';
import { getSignatureDomain } from '../../blockchain';

export const TokenDomain = {
  name: 'ScholarRental',
  version: '1',
};

export const TokenSigType = {
  RentalTerms: [
    { name: 'tokenId', type: 'uint256' },
    { name: 'owner', type: 'address' },
    { name: 'startsAt', type: 'uint64' },
    { name: 'duration', type: 'uint32' },
    { name: 'autoRenew', type: 'bool' },
    { name: 'percent', type: 'uint8' },
    { name: 'playerId', type: 'string' },
  ],
};

export interface IRentalTerms {
  tokenId: string;
  owner: string;
  startsAt: number;
  duration: number;
  autoRenew: boolean;
  percent: number;
  playerId?: string;
}

export interface ISignedRentalTerms extends IRentalTerms {
  signature: string;
}

export async function signScholarRental(
  context: IFurballContext,
  walletAddress: string, // The owner of the furball
  tokenId: string, // The furball being rented
  duration = 0, // 0 = forever, otherwise pass number of seconds before it auto-expires
  autoRenew = false, // does the contract automatically extend itself when it ends?
  playerId?: string, // empty string = anyone can rent... pass a furPlayer.Id to give it to just one player
  percent = 0, // What percent share of the rewards does the owner give the scholar?
): Promise<ISignedRentalTerms> {
  const domain = getSignatureDomain(
    TokenDomain,
    context.network.deployment?.addresses.scholarrental,
    context,
  );

  const terms: IRentalTerms = {
    tokenId,
    duration,
    autoRenew,
    percent,
    playerId: playerId ?? '',
    owner: walletAddress,
    startsAt: Math.round(new Date().getTime() / 1000),
  };

  const signature = await context.provider
    .getSigner()
    ._signTypedData(domain, TokenSigType, terms);
  return { ...terms, signature };
}
