import {
  // CircularProgress,
  Divider,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import React, { FunctionComponent } from 'react';

import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';

import { StatEntity, EntitySkill } from '../schema';
import { ItemStatRow } from '../Stats';
import { makeStyles } from '@mui/styles';
import { cdnRoot } from '../../theme';
import { white } from '../../themev2';
import { getSkillDefinitionById } from '../../pages/Skills/SkillLibrary';
import { getStatTitleValue } from './BattleStats';
import { IEquipment } from '../../wallet/WalletTypes';

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    paddingTop: '3px',
    paddingRight: '25px',
  },
  loading: {
    position: 'absolute',
    margin: '15px auto',
    left: '0',
    right: '0',
    textAlign: 'center',
  },
  progress: {
    color: `${theme.palette.secondary.main} !important`,
  },
  header: {
    padding: '15px 0 !important',
    backgroundColor: white,
  },
  divider: {
    margin: '40px 20px 10px 20px !important',
  },
}));

interface ISkillDefinition {
  name: string;
  summary: string;
  icon: string;
  maxLevel: number;
}

interface IEntitySkillDescriptor {
  skillDefinitionId: number;
  level: number;
  maxUses: number;
}

interface ISkillRow extends IEntitySkillDescriptor {
  value: number | null;
  valueUnits: string | null;
  buffType: number;
}

interface IBattleStatsRow {
  stat: string;
  value: number;
}

interface IProps {
  tokenId?: string;
  hideSkills?: boolean;
  battleStats: StatEntity;
  equipment: IEquipment[];
}

export function getSkillDescription(
  skill: IEntitySkillDescriptor,
  includeName = true,
): string {
  const def = getSkillDefinitionById(skill.skillDefinitionId);
  const lvlTxt = def?.maxLevel === 0 ? undefined : `LVL ${skill.level}`;
  const maxTxt = `${skill.maxUses} uses`;
  const desc = lvlTxt ? `${maxTxt}, ${lvlTxt}` : maxTxt;
  return includeName ? `${def?.name}: ${desc}` : desc;
}

export const Skills: FunctionComponent<IProps> = (props) => {
  const { tokenId, hideSkills, battleStats, equipment } = props;
  const classes = useStyles();
  const common = useCommonStyles();

  // useEffect(() => {
  //   if (!battleStats && tokenId) {
  //     loadBattleStats({
  //       variables: { tokenId },
  //     });
  //   }
  // }, []);

  function renderIcon(
    icon: string,
    name: string,
    folder: string,
  ): React.ReactNode {
    return (
      <img
        src={`${cdnRoot}/images/icons/${folder}/${icon}.png`}
        alt={name}
        className={classes.icon}
        style={{
          width: folder === 'skills' ? '80px' : '60px',
          marginLeft: folder === 'skills' ? '-5px' : '5px', // due to different icon sizes
        }}
      />
    );
  }

  function renderSkillRow(skill: ISkillRow, i: number): React.ReactNode {
    const { value, valueUnits, buffType } = skill;
    const definition = getSkillDefinitionById(skill.skillDefinitionId);

    if (!definition) return null;
    return (
      <ItemStatRow
        title={definition.name}
        name={getSkillDescription(skill, false)}
        odd={i % 2 === 1}
        icon={renderIcon(definition.icon, definition.name, 'skills')}
        callout={
          value
            ? {
                value: value,
                units: valueUnits ? valueUnits : '',
                buffType,
              }
            : undefined
        }
      />
    );
  }

  function renderBattleStatsRow(
    stats: IBattleStatsRow,
    i: number,
  ): React.ReactNode {
    const { stat, value } = stats;
    const [title, valueStr, bonus] = getStatTitleValue(
      stat,
      value,
      ...equipment,
    );
    const name = bonus > 0 ? `${valueStr} (+${bonus})` : valueStr;

    return (
      <ItemStatRow
        title={title}
        name={name}
        odd={i % 2 === 1}
        icon={renderIcon(stat, stat, 'battle_stats')}
      />
    );
  }

  function renderSkills() {
    if (!battleStats.skills) return null;

    const skills = battleStats?.skills;

    return skills.map((skill: EntitySkill, i: number) => {
      return (
        <Grid key={`furball-skill-${i}`} item xs={12}>
          {renderSkillRow(skill, i)}
        </Grid>
      );
    });
  }

  function renderBattleStats() {
    const stats = props.battleStats;

    const battleStats = {
      hp: stats?.maxHealth,
      attack_power: stats?.attackPower,
      defence_power: stats?.defencePower,
      heal: stats?.heal,
      buff: stats?.buff,
      debuff: stats?.deBuff,
      crit_rate: stats?.critRate,
      crit_attack_power: stats?.critAttackPower,
      speed: stats?.speed,
    };

    return Object.entries(battleStats).map(([stat, value], i) => {
      return (
        <Grid key={`furball-battlestat-${i}`} item xs={12}>
          {renderBattleStatsRow(
            {
              stat,
              value: value ? value : 0,
            },
            i,
          )}
        </Grid>
      );
    });
  }

  function render() {
    // if (loading) {
    //   return (
    //     <Grid
    //       container
    //       className={common.paddingTable}
    //       style={{ position: 'relative', minHeight: '100px' }}>
    //       <div className={classes.loading}>
    //         <CircularProgress className={classes.progress} />
    //       </div>
    //     </Grid>
    //   );
    // }

    return (
      <>
        <div className={clsx(common.cardDropHeader)}>
          <Typography variant={'h5'} className={common.unfocusedText}>
            {hideSkills ? 'Battle Stats' : 'Skills & Battle Stats'}
          </Typography>
        </div>
        {!hideSkills && (
          <>
            <Grid
              container
              className={common.paddingTable}
              spacing={1}
              sx={{ paddingBottom: '0 !important' }}>
              {renderSkills()}
            </Grid>
            <Divider className={classes.divider} />
          </>
        )}

        <Grid
          container
          className={common.paddingTable}
          sx={{ paddingBottom: '0 !important' }}
          spacing={1}>
          {renderBattleStats()}
        </Grid>
      </>
    );
  }

  return render();
};

export default Skills;
