import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';
import { reloadAllFurballs, usePlayersReadyFurballs } from '../../wallet';
import { IFurball } from '../../wallet/WalletTypes';

import FurballImg from '../Furballs/FurballImg';
import SvgIconClock from '../../assets/SvgIconClock';
import OkDialog from '../OkDialog';
import WalletContext from '../../wallet/WalletContext';

import { purple } from '../../theme';

import TimekeeperToggleButton from './TimekeeperToggleButton';
import { useAppDispatch } from '../../hooks';
import { useApolloClient } from '@apollo/client';
import { OutboundLink } from '../Navigation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  header: {
    textAlign: 'center',
  },
  table: {
    maxWidth: 800,
  },
  marketButton: {
    minWidth: 480,
  },
  checkbox: {
    color: `${purple.dark} !important`,
  },
}));

interface IUnknown {}

type Props = IUnknown;

//
type TkChangeMap = { [key: string]: boolean | undefined };

export const ToggleTimekeeper: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const context = React.useContext(WalletContext);
  const furballs = usePlayersReadyFurballs();
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const [changes, setChanges] = React.useState<TkChangeMap>({});
  const [disabling, setDisabling] = React.useState<string[]>([]);
  const [enabling, setEnabling] = React.useState<string[]>([]);
  const changedFurballs = furballs.filter((fb) =>
    disabledChanged(fb, currentDisabled(fb)),
  );
  const hasChanges = changedFurballs.length > 0;
  const changedDisable = changedFurballs.filter(
    (fb) => changes[fb.tokenId] === true,
  );
  const changedEnable = changedFurballs.filter(
    (fb) => changes[fb.tokenId] === false,
  );
  const hasDisable = changedDisable.length > 0;
  const hasEnable = changedEnable.length > 0;
  const zonesAddr = context?.network.deployment?.addresses.zones;
  const furballsEnabled = furballs.filter((fb) => !fb.timekeeperDisabled);
  const furballsDisabled = furballs.filter((fb) => fb.timekeeperDisabled);

  function currentDisabled(fb: IFurball): boolean {
    const chng = changes[fb.tokenId];
    if (chng !== undefined) return chng;
    return fb.timekeeperDisabled;
  }

  function disabledChanged(fb: IFurball, disabled?: boolean): boolean {
    const chng = changes[fb.tokenId];
    if (chng === undefined) return false;
    return fb.timekeeperDisabled !== disabled;
  }

  function setDisabled(fb: IFurball, disabled?: boolean) {
    setChanges({ ...changes, [fb.tokenId]: disabled });
  }

  function renderFurball(fb: IFurball): React.ReactNode {
    const disabled = currentDisabled(fb);
    const unsaved = disabledChanged(fb, disabled);
    return (
      <React.Fragment key={fb.tokenId}>
        <Grid item xs={2}>
          <FurballImg tokenId={fb.tokenId} size={96} />
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'left', paddingTop: 12 }}>
          <Typography variant={'h5'}>{fb.name}</Typography>
          <Typography variant={'subtitle1'}>
            <SvgIconClock
              style={{
                width: 24,
                marginRight: 4,
                position: 'relative',
                top: 6,
              }}
            />
            {unsaved
              ? `${new Date(fb.last * 1000).toLocaleString()}`
              : disabled
              ? 'Disabled'
              : 'Enabled'}
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: 18 }}>
          <FormControlLabel
            // disabled
            control={
              <Checkbox
                color={'secondary'}
                className={classes.checkbox}
                checked={!disabled}
                onChange={(e) => setDisabled(fb, !disabled)}
              />
            }
            label={disabled ? 'Disabled' : 'Enabled'}
          />
        </Grid>
      </React.Fragment>
    );
  }

  function onPressedDisable(): void {
    setDisabling(changedDisable.map((fb) => fb.tokenId));
  }

  function onPressedEnable(): void {
    setEnabling(changedEnable.map((fb) => fb.tokenId));
  }

  function onComplete(): void {
    // setEnabling([]);
    // setDisabling([]);
    void reloadAllFurballs(
      furballs.map((fb) => fb.tokenId),
      client,
      dispatch,
    );
  }

  return (
    <div className={clsx(classes.root, common.underHeaderBar)}>
      <div style={{ display: 'inline-block' }}>
        <Grid container className={classes.table}>
          <Grid item xs={12} className={classes.header}>
            <Typography variant={'h4'}>Toggle Timekeeper</Typography>
            <Typography variant={'h6'}>
              Disabling Timekeeper re-enables "Instant" Collections
            </Typography>
          </Grid>
          {furballsEnabled.map(renderFurball)}
          {furballsEnabled.length > 0 && (
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                disabled={!hasDisable}
                onClick={onPressedDisable}>
                <SvgIconClock
                  style={{ width: 24, marginRight: 4, position: 'relative' }}
                />
                <Typography variant={'h6'}>DISABLE TIMEKEEPER</Typography>
              </Button>
            </Grid>
          )}
          {furballsDisabled.map(renderFurball)}
          {furballsDisabled.length > 0 && (
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                color={'primary'}
                disabled={!hasEnable}
                onClick={onPressedEnable}>
                <SvgIconClock
                  style={{ width: 24, marginRight: 4, position: 'relative' }}
                />
                <Typography variant={'h6'}>ENABLE TIMEKEEPER</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <OkDialog
          title={'Enable Timekeeper'}
          open={enabling.length > 0}
          onClose={() => setEnabling([])}
          topRight={true}>
          <Grid container>
            <Grid item lg={12} style={{ paddingBottom: 10 }}>
              <Typography variant={'h4'}>Timekeeper will be Enabled</Typography>
              <Typography variant={'h4'}></Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography variant={'body1'}>
                While Timekeeper is enabled, do not try to collect via contract!
                <br />
                You'll need to disable Timekeeper again to enable direct
                rewards.
                <br />
                Timekeeper writes to the blockchain, but manages the clock
                itself.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TimekeeperToggleButton
                furballIds={enabling}
                timekeeperDisabled={false}
                onComplete={onComplete}
              />
            </Grid>
          </Grid>

          <br />
        </OkDialog>
        <OkDialog
          title={'Disable Timekeeper'}
          open={disabling.length > 0}
          onClose={() => setDisabling([])}
          topRight={true}>
          <Typography variant={'body1'}>
            <strong>To receive unclaimed rewards</strong> you must use this
            window to disable Timekeeper.
          </Typography>

          <br />

          <Grid container>
            <Grid item lg={12} style={{ paddingBottom: 10 }}>
              <Typography variant={'h4'}>Timekeeper will be Locked</Typography>
              <Typography variant={'h4'}></Typography>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography variant={'body1'}>
                Each Furball will be timestamped onto the blockchain.
                <br />
                Once disabled, the Furballs rewards are available on-chain.
                <br />
                They will remain unusable by Timekeeper until you change them
                back.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TimekeeperToggleButton
                furballIds={disabling}
                timekeeperDisabled={true}
                onComplete={onComplete}
              />
            </Grid>
          </Grid>

          <br />

          <Typography variant={'subtitle2'}>
            This action happens immediately when you press the button (unlike
            Timekeeper actions).
            <br />
            You can always call <strong>disableTimekeeper</strong> directly on
            the
            <OutboundLink to={`https://etherscan.io/address/${zonesAddr}`}>
              {' '}
              Zones contract
            </OutboundLink>
            , but you will not receive pending rewards.{' '}
          </Typography>
        </OkDialog>
      </div>
    </div>
  );
};

export default ToggleTimekeeper;
