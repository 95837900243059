import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, MenuItem, Select, Theme, useTheme } from '@mui/material';
import { white } from '../../themev2';
import { renderSwapCurrencyIcon, SwapCurrency } from './ExchangeCurrencies';
import { styled, InputBase } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  token: {
    height: '25px',
    minWidth: '90px',
    borderRadius: '16px',
    backgroundColor: theme.palette.secondary.light,
    padding: '6px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: white,
    marginRight: '8.5px',
  },

  tokenTextCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  tokenText: {
    fontSize: '14px !important',
    // marginTop: '2px !important',
    paddingLeft: '6px !important',
    paddingRight: '6px !important',
    paddingTop: '3px !important',
    color: 'white !important',
  },

  dropdownIcon: {
    color: 'white !important',
  },

  disabled: {
    display: 'none',
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    // borderRadius: '0 6px 6px 0 !important',
    // position: 'relative',
    color: white,
    // border: `1px solid ${black}`,
    // borderLeft: `1px solid ${theme.palette.common.black}`,
    // padding: '10px 15px',
    //
    // '&:focus': {
    //   borderRadius: 6,
    // },
  },
}));
interface ICurrencySelector {
  currency: SwapCurrency;
  options: SwapCurrency[];
  onSelectCurrency: (c: SwapCurrency) => void;
}

const CurrencySelector: React.FunctionComponent<ICurrencySelector> = (
  props,
) => {
  const { currency, options, onSelectCurrency } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Select
      className={classes.token}
      value={currency}
      input={<BootstrapInput />}
      inputProps={{
        classes: {
          icon: clsx(classes.dropdownIcon, {
            [classes.disabled]: options.length < 2,
          }),
        },
        MenuProps: {
          MenuListProps: {
            sx: {
              backgroundColor: theme.palette.secondary.light,
            },
          },
        },
      }}
      onChange={(e, v) => onSelectCurrency(e.target.value as SwapCurrency)}
      // IconComponent={SvgCaret}
    >
      {options.map((opt) => {
        return (
          <MenuItem key={opt} value={opt} className={classes.tokenTextCont}>
            <Grid
              container
              className={classes.tokenText}
              style={{ fontWeight: 'bold' }}>
              <Grid item xs={6} style={{ paddingTop: 3, textAlign: 'left' }}>
                {renderSwapCurrencyIcon(opt, 15)}
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                {opt}
              </Grid>
            </Grid>
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CurrencySelector;
