import * as React from 'react';

function SvgIconLeaderboardTiers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-leaderboard-tiers_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 37.6 16'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.icon-leaderboard-tiers_svg__st0{fill:#6c6c6c}.icon-leaderboard-tiers_svg__st1{fill:#fff}'
        }
      </style>
      <path
        className='icon-leaderboard-tiers_svg__st0'
        d='M1.9.1h20.3c1 0 1.9.8 1.9 1.9v12.1c0 1-.8 1.9-1.9 1.9H1.9c-1 0-1.9-.8-1.9-1.9V2C0 .9.8.1 1.9.1z'
      />
      <path
        className='icon-leaderboard-tiers_svg__st1'
        d='M7.4 6.6h14.7v2.8H7.4zM1.9 6.6h3.7v2.8H1.9zM7.4 11.3h14.7v2.8H7.4zM1.9 11.3h3.7v2.8H1.9zM7.4 2h14.7v2.8H7.4zM1.9 2h3.7v2.8H1.9z'
      />
      <g>
        <path
          className='icon-leaderboard-tiers_svg__st0'
          d='M36.5 9.9c-.6 0-1-.5-1-1V2.1h-6.8c-.6 0-1-.5-1-1s.5-1 1-1l7.8.1c.3 0 .5.1.7.3.2.2.3.4.3.7L37.4 9c.1.4-.3.9-.9.9z'
        />
        <path
          className='icon-leaderboard-tiers_svg__st0'
          d='M28.1 10.4c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L35.2 1c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7.8 7.7c-.2.2-.4.3-.7.3z'
        />
      </g>
    </svg>
  );
}

export default SvgIconLeaderboardTiers;
