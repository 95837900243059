import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useAppBarHeight } from '../../../components/useCommonStyles';
import SvgX from '../../../assets/SvgX';
import { useClientHardware } from '../../App/clientHardware';
import theme, { zIndicies } from '../../../themev2';
import { useHistory } from 'react-router-dom';
export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      position: 'fixed',
      zIndex: zIndicies.appBar + 1,
      top: 0,
      left: 0,
      right: 0,
      width: '100vw',
      backgroundColor: 'white',
      padding: '0.5rem',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
    cont: {
      paddingRight: 100,
      paddingLeft: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      gap: '1.25rem',
    },
    stepCont: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '0.85rem',

      '& h4': {
        fontSize: '1.1rem !important',

        [theme.breakpoints.down('md')]: {
          fontSize: '0.9rem !important',
        },

        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
      },
    },
    step: {
      backgroundColor: theme.palette.secondary.light,
      height: '32.5px',
      width: '32.5px',
      borderRadius: '50%',
      color: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    separator: {
      width: '100px',
      height: '1px',
      backgroundColor: '#D6D3D1',

      [theme.breakpoints.down('md')]: {
        width: '75px',
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  };
});

export enum OnboardStep {
  CREATE = 1,
  RENT = 2,
  COMPLETE = 3,
}

interface IRentMarketOnboardProgress {
  step: OnboardStep;
}

const RentMarketOnboardProgress: FunctionComponent<
  IRentMarketOnboardProgress
> = (props) => {
  const { step } = props;
  const classes = useStyles();
  const barHeight = useAppBarHeight();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const history = useHistory();
  const { cornerPadding } = useClientHardware();

  function getStepDescription(onboardStep: OnboardStep) {
    if (onboardStep === OnboardStep.CREATE) return 'Create Party';
    if (onboardStep === OnboardStep.RENT) return 'Borrow Furballs';
    return 'Fight!';
  }

  function renderStep(onboardStep: OnboardStep) {
    const opacity = step >= onboardStep ? 1 : 0.25;

    return (
      <>
        <div className={classes.stepCont} style={{ opacity }}>
          <div className={classes.step}>
            <Typography variant={'h6'}>{onboardStep}</Typography>
          </div>
          <Typography variant={'h4'}>
            {getStepDescription(onboardStep)}
          </Typography>
        </div>
        {onboardStep !== OnboardStep.COMPLETE && (
          <div className={classes.separator} style={{ opacity }} />
        )}
      </>
    );
  }

  return (
    <div
      className={classes.root}
      style={{ paddingTop: cornerPadding, height: barHeight }}>
      <div className={classes.cont}>
        {renderStep(OnboardStep.CREATE)}
        {renderStep(OnboardStep.RENT)}
        {renderStep(OnboardStep.COMPLETE)}
      </div>
      <div
        className={classes.closeBtn}
        style={{ top: cornerPadding + (isSmallScreen ? 10 : 0) }}>
        <Button
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            border: 'none',
            height: '55px',
            width: '55px',
            marginRight: '8px',
          }}
          onClick={() => history.push('/account')}>
          <SvgX fill={'#444444'} style={{ width: 22, height: 22 }} />
        </Button>
      </div>
    </div>
  );
};

export default RentMarketOnboardProgress;
