import React, { FunctionComponent, useEffect, useState } from 'react';
import { ALERT_TYPES, IAlert } from './AlertContext';
import { makeStyles } from '@mui/styles';
import { Slide, Theme, Typography } from '@mui/material';
import SvgMobileMenuX from '../../assets/SvgMobileMenuX';
import { white } from '../../themev2';
import SvgIconCircleChecked from '../../assets/SvgIconCircleChecked';
import SvgIconCaretRight from '../../assets/SvgIconCaretRight';
import SvgIconInfo from '../../assets/SvgIconInfo';
import SvgIconWarning from '../../assets/SvgIconWarning';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    overflow: 'hidden',
    lineHeight: '20px',
    right: 30,
    bottom: (props: any) => props.bottomPos,
    transition: 'bottom 2s linear',
    backgroundColor: '#1e272e',
    minWidth: '350px',
    maxWidth: '350px',
    minHeight: '70px',
    borderRadius: '8px',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    color: white,
    display: 'flex',
    alignItems: 'center',
    zIndex: 10000,
  },
  icon: {
    minWidth: '70px',
    maxWidth: '70px',
    height: '70px',
    backgroundColor: '#2f3542',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '5px',
  },
  message: {
    padding: '0 20px',
    textAlign: 'left',

    '& h6': {
      letterSpacing: '0.5px',
    },

    '& p': {
      margin: '0',
      fontSize: '12px !important',
      color: '#a4b0be !important',
    },
  },
  helperIcon: {
    position: 'absolute',
    top: 6,
    right: 12,
  },
  indicator: {
    position: 'absolute',
    left: 0,
    height: '80px',
    width: '5px',
    backgroundColor: (props: any) => props.alertColor,
  },
}));

export const BASE_DURATION = 8000;
const MIN_DURATION = 5000;
const TRANSITION_DURATION = 600;
const TRANSITION_OFFSET = 200;
const TRANSITION = 'cubic-bezier(0.68, -0.6, 0.32, 1.6)';
const GAP = 100;
const START_POS = 70;

const ALERT_THEMES: { [key: string]: string } = {
  info: '#778beb',
  error: '#ef3d59',
  success: '#00cc99',
  warning: '#f2c94c',
};

interface OwnProps {
  alert: IAlert;
  alertIndex: number;
  removeAlert: () => void;
}

const Alert: FunctionComponent<OwnProps> = (props) => {
  const { alert, alertIndex, removeAlert } = props;

  const alertColor = ALERT_THEMES[alert.type];
  const classes = useStyles({
    alertColor,
    bottomPos: (alertIndex + 1) * GAP - START_POS,
  });

  let duration = alert.duration ?? BASE_DURATION;
  if (duration < MIN_DURATION) duration = MIN_DURATION;

  const [close, setClose] = useState<boolean>(false);

  useEffect(() => {
    const dismiss = setTimeout(() => {
      removeAlert();
    }, duration);

    const slideOut = setTimeout(() => {
      setClose(true);
    }, duration - TRANSITION_DURATION);

    return () => {
      clearTimeout(dismiss);
      clearTimeout(slideOut);
    };
  }, []);

  useEffect(() => {
    let dismiss: NodeJS.Timeout;

    if (close) {
      dismiss = setTimeout(() => {
        removeAlert();
      }, TRANSITION_DURATION - TRANSITION_OFFSET);
    }
    return () => (close ? clearInterval(dismiss) : undefined);
  }, [close]);

  function renderIcon() {
    if (alert.type === ALERT_TYPES.SUCCESS) {
      return <SvgIconCircleChecked height={30} width={30} fill={alertColor} />;
    }

    if (alert.type === ALERT_TYPES.INFO) {
      return <SvgIconInfo height={30} width={30} fill={alertColor} />;
    }

    return <SvgIconWarning height={30} width={30} fill={alertColor} />;
  }

  function renderHelperIcon() {
    if (alert.action) {
      return <SvgIconCaretRight height={10} width={10} fill={'#576574'} />;
    }

    return <SvgMobileMenuX height={10} width={10} stroke={'#576574'} />;
  }

  function handleOnClick() {
    if (alert.action) {
      alert.action();
    }
    setClose(true);
  }

  return (
    <Slide
      direction={'left'}
      in={!close}
      timeout={TRANSITION_DURATION}
      easing={TRANSITION}
      mountOnEnter
      unmountOnExit>
      <div className={classes.root} onClick={() => handleOnClick()}>
        <div className={classes.icon}>{renderIcon()}</div>
        <div className={classes.indicator} />
        <div className={classes.message}>
          <Typography variant={'h6'}>
            {alert.title ?? alert.type.toUpperCase()}
          </Typography>
          <Typography variant={'body1'}>{alert.message}</Typography>
        </div>
        <div className={classes.helperIcon}>{renderHelperIcon()}</div>
      </div>
    </Slide>
  );
};

export default Alert;
