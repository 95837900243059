import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { EntitySkill, useUpgradeSkillMutation } from '../../components/schema';
import WalletSlice from '../../wallet/WalletSlice';
import SpendWFurButton from '../../components/Account/SpendWFurButton';
import { IFurball } from '../../wallet/WalletTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '2rem',
  },
  confirmButton: {
    width: '300px !important',
    borderRadius: '6px !important',
    height: '50px !important',
    fontSize: '0.875rem !important',

    '&:disabled': {
      backgroundColor: '#eee !important',
    },
  },
}));

interface ISkillConfirmChange {
  skillId: string;
  furballId: string;
  levelPartials: number;
  maxUsesPartials: number;
  totalFurCost: number;
  setSkills: (skills: EntitySkill[]) => void;
}

const SkillConfirmChange: FunctionComponent<ISkillConfirmChange> = (props) => {
  const {
    skillId,
    furballId,
    levelPartials,
    maxUsesPartials,
    totalFurCost,
    setSkills,
  } = props;

  const classes = useStyles();
  const [upgrade, { loading }] = useUpgradeSkillMutation();

  const dispatch = useAppDispatch();
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const hasEnoughFur = wFurBalance >= totalFurCost;
  const noUpgradeMade = !levelPartials && !maxUsesPartials;
  const disabled = !hasEnoughFur || noUpgradeMade || loading;

  async function upgradeSkill() {
    const { data } = await upgrade({
      variables: { furballId, skillId, levelPartials, maxUsesPartials },
    });
    const furball = data?.upgradeSkill as IFurball;

    if (furball) {
      const upgradedSkills = furball.battleStats.skills;
      setSkills(upgradedSkills as EntitySkill[]);
      dispatch(WalletSlice.actions.updateFurballs([furball]));
    }
    // dispatch(WalletSlice.actions.setWFurBalance(wFurBalance - totalFurCost));
  }

  return (
    <div className={classes.root}>
      <SpendWFurButton
        itemName={'Upgrade Skill'}
        cost={totalFurCost}
        onAccepted={upgradeSkill}
        className={classes.confirmButton}
        disabled={disabled}
        title={'Upgrade'}
      />
    </div>
  );
};

export default SkillConfirmChange;
