import React, { FunctionComponent, useEffect, useState } from 'react';
import { FurballBattleStatsFragment } from '../schema';
import { Grid, Theme, Tooltip, Typography } from '@mui/material';
import { cdnRoot } from '../../theme';
import { makeStyles } from '@mui/styles';
import themev2, { white } from '../../themev2';
import { IEquipment } from '../../wallet/WalletTypes';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  statsCont: {
    textAlign: 'center',
    color: white,
    minWidth: (props: any) => (props.minWidth ? `${props.minWidth}px` : '0'),
    marginLeft: (props: any) => (props.offset ? `${props.offset}px` : '0'),
    rowGap: (props: any) => (props.rowGap ? `${props.rowGap}px` : '0'),
    columnGap: (props: any) => (props.colGap ? `${props.colGap}px` : '0'),
    fontSize: (props: any) => (props.textSize ? `${props.textSize}px` : '16px'),

    [theme.breakpoints.down('md')]: {
      marginLeft: '35px',
      marginBottom: '7.5px',
    },
  },
  statCont: {
    display: 'grid',
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  iconCont: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '& span': {
      color: theme.palette.primary.main,
      marginLeft: '2.5px',
      fontSize: '12px',
    },
  },
  statIcon: {
    height: (props: any) => (props.iconSize ? `${props.iconSize}px` : '17.5px'),
    width: (props: any) => (props.iconSize ? `${props.iconSize}px` : '17.5px'),
    marginRight: '5px',
  },
  statName: {
    fontSize: '12px !important',
    textTransform: 'uppercase',
  },
}));

export interface IBattleStats {
  hp: number;
  attack_power: number;
  defence_power: number;
  heal: number;
  buff: number;
  debuff: number;
  crit_rate: number;
  crit_attack_power: number;
  speed: number;
}

interface IBattleStatsProps {
  tokenId: string;
  showName?: boolean;
  iconSize?: number;
  textSize?: number;
  rowGap?: number;
  colGap?: number;
  offset?: number;
  className?: string;
  equipment?: (IEquipment | null)[];
  battleStats: Omit<FurballBattleStatsFragment, 'skills'>;
}

export function getStatTitleValue(
  stat: string,
  value: number,
  ...equipment: IEquipment[]
): [string, string, number] {
  const equipmentBonus = equipment
    .map((item) => {
      if (!item) return 0;

      const { passives } = item;
      return passives
        .filter((passive) => passive.stat.toLowerCase() === stat)
        .map((passive) => passive.value)
        .reduce((total, current) => total + current, 0);
    })
    .reduce((total, current) => total + current, 0);

  value += equipmentBonus;

  const titles: { [key: string]: string } = {
    hp: 'Health Points (HP)',
    attack_power: 'Attack Power (AP)',
    defence_power: 'Defense Power (DP)',
    crit_rate: 'Crit Rate (CR)',
    crit_attack_power: 'Crit AP (CAP)',
    heal: 'Heal Bonus',
    debuff: 'Debuff Bonus',
    buff: 'Buff Bonus',
  };
  const units: { [key: string]: string } = {
    buff: '%',
    debuff: '%',
    heal: '%',
    crit_rate: '%',
  };
  const pres: { [key: string]: string } = {
    buff: '+',
    debuff: '+',
    heal: '+',
    crit_rate: '+',
  };
  const title = titles[stat] ?? stat.replaceAll('_', ' ');
  const names = [];
  if (pres[stat]) names.push(pres[stat]);
  names.push(value.toLocaleString());
  if (units[stat]) names.push(units[stat]);

  return [title, names.join(''), equipmentBonus];
}

const BattleStats: FunctionComponent<IBattleStatsProps> = (props) => {
  const {
    tokenId,
    showName,
    iconSize,
    textSize,
    rowGap,
    colGap,
    offset,
    className,
    equipment,
    battleStats,
  } = props;

  const classes = useStyles({
    iconSize,
    textSize,
    rowGap,
    colGap,
    offset,
    className,
  });

  const [stats, setStats] = useState<any>(undefined);

  useEffect(() => {
    setStats(battleStats);
  }, [tokenId]);

  function getStatsFromEquipments(stat: string) {
    if (!equipment) return 0;

    const bonus = equipment.map((item) => {
      if (!item) return 0;

      const { passives } = item;
      return passives
        .filter((passive) => passive.stat.toLowerCase() === stat)
        .map((passive) => passive.value)
        .reduce((total, current) => total + current, 0);
    });

    return bonus.reduce((total, current) => total + current, 0);
  }

  function renderBattleStats() {
    const battleStats: IBattleStats = {
      hp: stats?.maxHealth,
      attack_power: stats?.attackPower,
      defence_power: stats?.defencePower,
      heal: stats?.heal,
      buff: stats?.buff,
      debuff: stats?.deBuff,
      crit_rate: stats?.critRate,
      crit_attack_power: stats?.critAttackPower,
      speed: stats?.speed,
    };

    return Object.entries(battleStats).map(([stat, value], i) => {
      const equip: IEquipment[] =
        equipment?.filter((e) => !!e).map((e) => e!) ?? [];
      const [title, valueStr, bonus] = getStatTitleValue(stat, value, ...equip);
      // const statName = stat.replaceAll('_', ' ');

      return (
        <Grid
          key={`furball-battlestat-${i}`}
          item
          xs={4}
          className={classes.statCont}>
          {showName && (
            <Typography variant={'body2'} className={classes.statName}>
              {valueStr}
            </Typography>
          )}
          <Tooltip title={title}>
            <div className={classes.iconCont}>
              <img
                src={`${cdnRoot}/images/icons/battle_stats/${stat}.png`}
                className={classes.statIcon}
              />
              {valueStr}
              {bonus > 0 && renderBonusStat(bonus)}
            </div>
          </Tooltip>
        </Grid>
      );
    });
  }

  function renderBonusStat(value: number) {
    const reducedStat = value < 0;
    const operand = reducedStat ? '-' : '+';

    return (
      <span
        style={{
          color: reducedStat ? '#EF5350' : themev2.palette.primary.light,
        }}>
        ({operand}
        {value})
      </span>
    );
  }

  return (
    <Grid container className={clsx(classes.statsCont, className)}>
      {stats && renderBattleStats()}
    </Grid>
  );
};

export default BattleStats;
