import React from 'react';
import { Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OkDialog from '../OkDialog';
import FurballImg from '../Furballs/FurballImg';

import { DilemmaResultType } from './NotificationTypes';
import { useLoadedFurball } from '../../wallet';

import {
  dilemmaSubtitle,
  dilemmaDesc,
} from '../Zones/Realms/RealmDilemmaResultDialog';

import { cdnRoot } from '../../theme';
import PlayerFurballLink from '../Players/PlayerFurballLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFC7F2',
  },

  furballContainer: {
    backgroundSize: 'cover',
  },

  furballImg: {
    transform: 'scale(-1,1)',
    marginRight: 20,
  },
}));

export interface IDelimmaResultDialogue extends DilemmaResultType {
  onClose: (id: string) => void;
}

const DilemmaResult = ({
  show,
  title,
  onClose,
  sender,
  encounterId,
  furballId,
  notificationId,
}: IDelimmaResultDialogue) => {
  const classes = useStyles();
  const furball = useLoadedFurball(furballId);

  const encounter = furball?.activeVoyage?.encounters?.find(
    (item) => item.id === encounterId,
  );

  const backgroundImage = `url(${cdnRoot}/svgs/pd-bk-${
    encounter?.decision ?? 0
  }_${encounter?.match?.decision ?? 0}.svg)`;

  const link = encounter?.match ? (
    <PlayerFurballLink tokenId={encounter?.match?.tokenId} />
  ) : (
    'a wild Furball'
  );

  return (
    <OkDialog
      className={classes.root}
      title={title}
      open={show}
      onClose={() => onClose(notificationId)}
      maxWidth={'sm'}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant={'h4'}>
            {encounter ? dilemmaSubtitle(encounter) : '???'}
          </Typography>
          <br />
          <Typography variant={'body1'}>
            {encounter ? dilemmaDesc(encounter, link) : 'Encounter description'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Container
            className={classes.furballContainer}
            style={{ backgroundImage }}>
            <FurballImg
              size={180}
              className={classes.furballImg}
              tokenId={sender}
            />
          </Container>
        </Grid>
      </Grid>
    </OkDialog>
  );
};

export default DilemmaResult;
