import React, { FunctionComponent, useEffect, useState } from 'react';

import { Grid, Theme, Typography } from '@mui/material';
import clsx from 'clsx';

import { makeStyles } from '@mui/styles';
import { cdnRoot } from '../../../theme';
import { useHistory, useParams } from 'react-router-dom';
import { useBossBattle } from '../useBossBattle';
import {
  PlayerPartyFragment,
  RealmType,
  useRefillHeartsMutation,
} from '../../../components/schema';

import useCommonStyles from '../../../components/useCommonStyles';
import AccountHeader from '../../../AccountHeader';
import {
  getRealmFromSlug,
  getRealmName,
  getRealmSlug,
} from '../../../components/Zones/ZoneHelpers';
import { getBossNameFromRealm, slugify, useFurComponent } from '../../../utils';
import UpgradeDialog from '../../../components/Town/Upgrade/UpgradeDialog';
import LiveFightFeed from '../../../components/BossFights/LiveFightFeed/LiveFightFeed';
import BossGameComplete from './BossGameComplete';
import BossGamePrepare from './BossGamePrepare';
import { useTimekeeperSelector } from '../../../components/Timekeeper/TimekeeperSlice';
import { firePostScoreEvent, usePageTitle } from '../../../Analytics';
import { isVuplexExpected } from '../../App/vuplexBridge';
import { useClientHardware } from '../../App/clientHardware';
import { useWindowSize } from '../../../components/useWindowSize';
import { useFurverseContext } from '../Furverse';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // padding: '0.5rem 1rem 0',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundImage: `url(${cdnRoot}/images/backgrounds/lobby-background.png)`,
    boxShadow: 'inset 0 0 3000px 3000px #0000002e',
    overflow: 'hidden',

    // [theme.breakpoints.down('lg')]: {
    //   overflow: 'auto',
    //   // paddingTop: '2rem',
    //   // marginTop: appBarHeightSm,
    // },
  },

  mainContainer: {
    flexGrow: 1,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 25rem',

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: '1fr',
    },

    // [theme.breakpoints.down('sm')]: {
    //   paddingBottom: '3rem',
    // },
  },

  ctrlsIcon: {
    transform: 'scale(1.6)',
    position: 'relative',
    top: '0.5rem',
  },

  chatWidget: {
    position: 'absolute',
    bottom: 10,
    right: 10,
    zIndex: 10001, // Above Unity.
  },

  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    // backgroundColor: `${darkBlue}88`,
    padding: 20,
    paddingBottom: 8,
  },
}));

interface IBossBattle {
  // setRealm: (realm: RealmType) => void;
  // cdn?: string;
}

export interface ILobbyParams {
  realm?: string;
  party?: string;
}

export interface IFightArgs {
  battleType: string;
  realm: RealmType;
  partyId: string;
  practiceGame?: boolean;
  level?: number;
}
//
// export function isBossFightUnityPage(path?: string): boolean {
//   if (!path) path = window.location.pathname;
//   return path.toLowerCase().includes('boss/fight');
// }

export interface IPartyName {
  id: string;
  name?: string;
}

export function getFightBossLink(
  newRealm: RealmType,
  party?: IPartyName,
  trial?: boolean,
): string {
  return getFightLink('boss', newRealm, party, trial);
}

export function getMonsterFightLink(
  newRealm: RealmType,
  party?: IPartyName,
  trial?: boolean,
): string {
  return getFightLink('monsters', newRealm, party, trial);
}

function getFightLink(
  gameType: string,
  newRealm: RealmType,
  party?: IPartyName,
  trial?: boolean,
): string {
  const realmSlug = getRealmSlug(newRealm);
  const partySlug = slugify(party?.name ?? '');
  const practice = trial ? '#practice' : '';
  return `/${gameType.toLowerCase()}/fight/${realmSlug}/${partySlug}${practice}`;
}

export function getPartyFromSlug(
  slug: string,
  allParties: PlayerPartyFragment[],
): PlayerPartyFragment | undefined {
  // const parties = allParties ?? [];
  if (!allParties || allParties.length === 0) return undefined;
  const sl = slugify(slug);
  return allParties.find((p) => p.id === sl || slugify(p.name) === sl);
}

export type StartGameFunc = (
  battleType: string,
  gameRealm: RealmType,
  gameParty: IPartyName,
  practice?: boolean,
) => void;

function useLobbyPage(realm?: RealmType) {
  const path = window.location.pathname.toLowerCase();
  const pps = (path.startsWith('/') ? path.substr(1) : path).split('/');
  const page = pps[1];
  const isLobby = page === 'lobby';
  const isPartyPage = page === 'party';
  const isFightPage = page === 'fight' && !!realm;
  const isCompletePage = page === 'complete' && realm;
  const isMonster = pps[0].startsWith('monster');
  const isBoss = pps[0].startsWith('boss');
  const isDuel = pps[0].startsWith('duel');
  const hash = window.location.hash.startsWith('#')
    ? window.location.hash.substr(1)
    : undefined;
  const isPracticeGame = hash === 'practice';
  const isNewPartyPage = isPartyPage && path.includes('/new');
  const gameType = isMonster
    ? 'Monsters'
    : isDuel
    ? 'Duel'
    : isBoss
    ? 'Boss'
    : 'Unknown';

  const bossName =
    realm && gameType === 'Boss'
      ? getBossNameFromRealm(realm, true)
      : undefined;
  const realmName = realm ? getRealmName(realm) : undefined;
  const gameName = gameType === 'Duel' ? '' : bossName ?? realmName;
  return {
    isFightPage,
    isPartyPage,
    isNewPartyPage,
    isCompletePage,
    gameType,
    isPracticeGame,
    hash,
    bossName,
    realmName,
    gameName,
  };
}

export const LobbyPage: FunctionComponent<IBossBattle> = () => {
  const { log } = useFurComponent(LobbyPage);
  const history = useHistory();
  const params = useParams<ILobbyParams>();

  const [battleBossName, setBattleBossName] = React.useState<string>();
  const gameBuild = useTimekeeperSelector((tk) => tk.meta?.gameBuild);
  const [showStatsTokenId, setShowStatsTokenId] = useState<
    string | undefined
  >();

  const classes = useStyles();
  const common = useCommonStyles();
  const { onPartyUpdated, currentParty, currentPartyFurballs } =
    useBossBattle(params);

  const realm = params.realm ? getRealmFromSlug(params.realm) : undefined;
  const {
    gameType,
    hash,
    isFightPage,
    isPartyPage,
    isNewPartyPage,
    isCompletePage,
    isPracticeGame,
    gameName,
  } = useLobbyPage(realm);

  const partyId = currentParty?.id;
  const { cornerPadding, isMobile } = useClientHardware();
  const isApp = isVuplexExpected();
  const { isLandscape } = useWindowSize();
  const [refillHearts] = useRefillHeartsMutation();
  const { sendGameMessage } = useFurverseContext(isFightPage, 'fight');
  const hideAccountHeader = isApp && isLandscape;

  React.useEffect(() => {
    if (isFightPage && gameType !== 'Unknown' && realm && currentParty) {
      startGame(gameType, realm, currentParty, isPracticeGame);
    }
  }, []); // just once when starting up page, start game if we're on a game load page

  function startGame(
    battleType: string,
    gameRealm: RealmType,
    gameParty: IPartyName,
    practice = false,
  ): void {
    log.info(
      '[FIGHT]',
      gameType,
      isPracticeGame ? 'practice' : 'regular',
      gameParty,
      partyId,
    );
    const fightArgs: IFightArgs = {
      realm: gameRealm,
      partyId: gameParty.id,
      battleType,
      practiceGame: practice,
    };
    // setOptionalArgs({ trialGame, realm, partyId, battleType: gameType });
    sendGameMessage({
      topic: 'Fight',
      data: { fightArgs },
    });

    const page = getFightLink(battleType, gameRealm, gameParty, practice);
    if (!window.location.pathname.startsWith(page)) history.push(page);
  }

  React.useEffect(() => {
    if (!hash || !hash.startsWith('score=')) return;
    const str = hash.substr('score='.length);
    const parts = str.split('&level=');
    const score = Number.parseInt(parts[0] ?? '0');
    const level =
      parts.length > 1 ? Number.parseInt(parts[1] ?? '0') : undefined;
    log.info('[SCORE]', score, '[LEVEL]', level);
    const bossName = realm ? getBossNameFromRealm(realm) : undefined;
    firePostScoreEvent(score, bossName, level);
    history.push(window.location.pathname); // clear hash
  }, [hash]);

  const getPageTitle = React.useCallback((): string => {
    if (isPartyPage)
      return (isNewPartyPage ? 'Create' : 'Edit') + ' Party | ' + gameType;
    const gn = gameName && gameName.length > 0 ? gameName + ' ' : '';
    if (isCompletePage) return `Complete ${gn}| ${gameType}`;
    if (isFightPage) return `Fight ${gn}| ${gameType}`;
    return `Lobby ${gn}| ${gameType}`;
  }, [isNewPartyPage, isPartyPage, gameType]);

  usePageTitle(getPageTitle());

  function getTokenIds(): string[] {
    const tokenIds: string[] = [];
    currentPartyFurballs.forEach((fb) => {
      if (!!fb) tokenIds.push(fb.tokenId);
    });

    return tokenIds;
  }

  async function doRefillHearts() {
    if (!partyId) return;
    try {
      const { data } = await refillHearts({ variables: { partyId } });
      onPartyUpdated(data?.party);
    } catch (e) {
      log.error('refill hearts', e);
    }
  }

  useEffect(() => {
    if (gameBuild)
      log.info(
        '[UNITY] game build v',
        gameBuild.semver,
        'created at',
        gameBuild.createdAt,
        '...',
        gameBuild,
      );
  }, [gameBuild]);

  return (
    <div
      className={clsx(classes.root, {
        [common.fullScreen]: true,
      })}>
      {!hideAccountHeader && (
        <AccountHeader
          title={'Boss Fights'}
          hideCurrencies={true}
          tint={true}
        />
      )}

      {isCompletePage && (
        <BossGameComplete
          realm={realm}
          party={currentParty}
          refillHearts={doRefillHearts}
        />
      )}
      {!isCompletePage && (
        <div className={classes.mainContainer}>
          {!gameBuild && (
            <>
              <Typography variant={'h5'}>
                Warning: failed to load game build meta
              </Typography>
            </>
          )}
          <BossGamePrepare
            startGame={startGame}
            battleBossName={battleBossName}
            setBattleBossName={setBattleBossName}
            setShowStatsTokenId={setShowStatsTokenId}
            refillHearts={doRefillHearts}
          />
        </div>
      )}

      {!isFightPage && (
        <div className={classes.footer} style={{ bottom: cornerPadding }}>
          {/*<BossChat*/}
          {/*  realm={realm ?? RealmType.None}*/}
          {/*  open={chatOpen}*/}
          {/*  setOpen={setChatOpen}*/}
          {/*/>*/}
          <Grid container maxWidth={'sm'}>
            <Grid item xs={2} sm={1}>
              {/*<GuideLink to={'bosses/gameplay-basics'}>*/}
              {/*  <Tooltip*/}
              {/*    placement='right-end'*/}
              {/*    title='Read the Boss Fights Guide'>*/}
              {/*    <Fab*/}
              {/*      size='medium'*/}
              {/*      color={'secondary'}*/}
              {/*      // className={classes.button}*/}
              {/*    >*/}
              {/*      <SvgIconBook width='1.3rem' />*/}
              {/*    </Fab>*/}
              {/*  </Tooltip>*/}
              {/*</GuideLink>*/}
            </Grid>
            <Grid item xs={2} sm={1}>
              <LiveFightFeed />
            </Grid>
          </Grid>
        </div>
      )}

      <UpgradeDialog
        show={!!showStatsTokenId}
        tokenId={showStatsTokenId}
        onClose={() => setShowStatsTokenId(undefined)}
        onAction={() => setShowStatsTokenId(undefined)}
        setTokenId={setShowStatsTokenId}
        tokenIds={getTokenIds()}
      />
    </div>
  );
};

export const Lobby: FunctionComponent<IBossBattle> = () => {
  return <LobbyPage />;
};
