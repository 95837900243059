import React, { FunctionComponent } from 'react';
// import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { IStatCallout, StatAbbreviation } from './StatTypes';
import { expColor, furColor, luckColor } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // padding: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
    // paddingLeft: theme.spacing(2),
    color: '#FFF',
    position: 'relative',
    // width: '250px',
    // height: '120px',
    borderRadius: '6px',
    textAlign: 'center',
    '&:after': {
      content: '',
      position: 'absolute',
      display: 'block',
      width: 0,
      zIndex: 1,
      borderStyle: 'solid',
      borderColor: 'transparent #000000',
      borderWidth: '20px 20px 20px 0',
      top: '50%',
      left: '-20px',
      marginTop: '-20px',
    },
  },
  tag: {
    content: '',
    position: 'absolute',
    display: 'block',
    width: 0,
    zIndex: 1,
    borderStyle: 'solid',
    borderWidth: '5px 7px 5px 0',
    top: '50%',
    left: '-7px',
    marginTop: '-5px',
  },
  operand: {
    color: '#CCC',
  },
  units: {
    fontSize: '1rem',
    color: '#CCC',
  },
}));

type Props = IStatCallout;

function getStatColor(stat?: StatAbbreviation): string {
  if (stat === 'exp') return expColor.main;
  if (stat === 'fur') return furColor.main;
  if (stat === 'luck') return luckColor.main;
  return '#000';
}

export const StatCallout: FunctionComponent<Props> = (props) => {
  const { stat, value, units, buffType } = props;
  const backgroundColor = getStatColor(stat);
  const color = '#ffffff';
  const borderColor = `transparent ${backgroundColor}`;
  const classes = useStyles();
  const hasVal = value !== undefined;

  function renderPreValue() {
    if (!hasVal) return null;

    let op = '';
    if (buffType !== undefined) {
      op = buffType === 1 ? '+' : '-';
    } else {
      op = (hasVal ? value : -1) >= 0 ? '+' : '';
    }
    return <span className={classes.operand}>{op}</span>;
  }

  function renderValue() {
    if (!hasVal) return '???';

    return buffType !== undefined && value < 0 ? Math.abs(value) : value;
  }

  function renderUnits(): React.ReactNode {
    if (!hasVal) return '';
    if (units !== undefined)
      return <span className={classes.units}>{units}</span>;
    if (buffType) return '';
    return '%';
  }

  return (
    <div className={classes.root} style={{ backgroundColor, color }}>
      <div className={classes.tag} style={{ borderColor }} />
      <Typography variant={'h5'}>
        {renderPreValue()}
        {renderValue()}
        {renderUnits()}
      </Typography>
    </div>
  );
};

export default StatCallout;
