import { SvgEditionSlot } from '../part';
import * as React from 'react';

const BowlerHat = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#abc3ff'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M213.22 97.39c0-9.69-7.59-16.96-16.96-16.96-9.37 0-17.27 7.6-16.96 16.96.18 5.17 33.92 2.76 33.92 0z'
    />
    <path d='M213.22 99.07c0-1.61-.22-3.17-.64-4.64-4.75 2.06-21.21 4.76-32.64.03-.41 1.47-.64 3.01-.64 4.61 0 .64.04 1.27.11 1.89h33.69c.08-.62.12-1.25.12-1.89z' />
    <path
      fill='#6c99ff'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M174.76 105.21a3.457 3.457 0 0 1-1.73-4.57 3.446 3.446 0 0 1 4.56-1.73c.04.02 11.95 6.2 37.5-.07 1.85-.45 3.78.18 4.47 1.96s-.12 3.99-1.96 4.47c-29.55 7.71-42.63.04-42.84-.06z'
    />
  </svg>
);

export default BowlerHat;
