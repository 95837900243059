import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme } from '@mui/material';
import { FurPlayerBaseFragment } from '../../schema';
import themev2, { headerFont } from '../../../themev2';
import GamePlayerAvatar from '../LiveFightFeed/GamePlayerAvatar';
import PlayerLink from '../../Players/PlayerLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '70px',
    maxHeight: '70px',
    borderBottom: '1px solid #c5cae9',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    padding: '0 20px 0 15px',
    backgroundColor: '#E9E9FF',

    [theme.breakpoints.down('sm')]: {
      padding: '0 15px 0 10px',
    },
  },
  text: {
    fontSize: '24px !important',
    fontFamily: headerFont,
    margin: '0',
    color: theme.palette.secondary.dark,

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
  },
  cont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rank: {
    minHeight: '80px',
    minWidth: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',

    [theme.breakpoints.down('sm')]: {
      marginRight: '5px',
    },
  },
  statsCont: {
    display: 'flex',

    '&:focus': {
      outline: 'none !important',
    },

    '& span': {
      fontWeight: 600,
      fontSize: '1.25rem',
    },
  },
  img: {
    height: '50px',
    width: '50px',
  },
  player: {
    borderBottom: '1px solid #EEEEEE',
    padding: '12.5px 15.5px',
    display: 'flex',
    alignItems: 'center',

    '& h5': {
      margin: '0 5px',
      fontSize: '1.05rem !important',
      fontStyle: headerFont,
      color: '#212121',
    },
  },
  button: {
    border: 'none !important',
  },
  buttonCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '5px',
    height: '30px',
    width: '30px',
    borderRadius: '60px',
  },
  dmg: {
    fontSize: '14px',
    color: '#9e9e9e !important',
    marginLeft: '3px',
    fontWeight: 400,

    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  dmgText: {
    fontWeight: 600,
    fontSize: '16px !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  dmgCont: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
  },
}));

interface ITierList {
  players: FurPlayerBaseFragment[];
  scores: { [k: string]: number };
  currentUserId: string | undefined;
  lastTierEndRank: number;
  playerPlacingRef: (playerPlacingNode: HTMLDivElement) => void;
}

const TierPlacing: FunctionComponent<ITierList> = (props) => {
  const { players, scores, currentUserId, lastTierEndRank, playerPlacingRef } =
    props;
  const classes = useStyles();

  function renderPlacings() {
    return players.map((player, i) => {
      const isCurrentUser = currentUserId === player.id.toUpperCase();

      const topRadius = i === 0 ? '8px' : '0';
      const bottomRadius = i === players.length - 1 ? '8px' : '0';

      return (
        <div
          ref={isCurrentUser ? playerPlacingRef : undefined}
          key={`tier-player-${i}`}
          className={classes.root}
          style={{
            backgroundColor: isCurrentUser
              ? themev2.palette.primary.main
              : '#E9E9FF',
            borderTopRightRadius: topRadius,
            borderTopLeftRadius: topRadius,
            borderBottomRightRadius: bottomRadius,
            borderBottomLeftRadius: bottomRadius,
          }}>
          <div className={classes.cont}>
            <div className={classes.rank}>
              <span className={classes.text}>{lastTierEndRank + i}</span>
            </div>
            <GamePlayerAvatar src={player.avatar} size={48} />
            <h5 className={classes.text}>
              <PlayerLink player={player} />
            </h5>
          </div>
          <div className={classes.dmgCont}>
            <span className={classes.dmgText}>
              {Number(scores[player.id]).toLocaleString()}
            </span>
            <span className={classes.dmg}>pts</span>
          </div>
        </div>
      );
    });
  }

  return (
    <Fade in={true} timeout={400}>
      <div>{renderPlacings()}</div>
    </Fade>
  );
};

export default TierPlacing;
