import * as React from 'react';
import { SwapCurrency } from './ExchangeCurrencies';
import SpendUsdButton from '../Account/SpendUsdButton';
import { CurrencyCostsFragment } from '../schema';
import { useFurComponent } from '../../utils';
import { AlertContext } from '../Alert/AlertContext';
import BuyTicketsButton from '../Timekeeper/BuyTicketsButton';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import { Typography } from '@mui/material';

interface ITixBuyButton {
  currency: SwapCurrency;
  amount: number;
  costs: CurrencyCostsFragment;
  onComplete: () => void;
  disabled?: boolean;
}

const TixBuyButton: React.FunctionComponent<ITixBuyButton> = (props) => {
  const { log } = useFurComponent(TixBuyButton);
  const { amount, currency, costs, onComplete, disabled } = props;
  const fuelCost = Math.ceil(
    costs.costEach.fuel * amount + costs.costBase.fuel,
  );
  // const usdPrice = Math.ceil(costs.costEach.usd * amount + costs.costBase.usd);
  const { addAlert } = React.useContext(AlertContext);
  const isLoading = false;
  const isDisabled = !!disabled;

  function setState(s: TransactionState, e?: ITransactionError): void {
    log.info('[ETH] +TIX', s, e);
    // setTransactionState(s);
    if (s === TransactionState.Complete) {
      onComplete();
    }
  }

  function renderButton() {
    if (currency === SwapCurrency.ETH)
      return (
        <BuyTicketsButton
          amount={amount}
          onState={setState}
          disabled={isDisabled}
        />
      );

    if (currency === SwapCurrency.USD)
      return (
        <SpendUsdButton
          resource={'TIX'}
          quantity={amount}
          priceEach={costs.costEach.usd}
          title={`${amount.toLocaleString()} TIX`}
          disabled={isDisabled}
        />
      );

    return <span>Unknown TIX Currency: {currency}</span>;
  }

  return (
    <>
      {renderButton()}
      <br />
      <Typography variant={'subtitle2'} color={'gray'}>
        TIX are sold by LFG Gaming LLC.
        <br />
        They can be used for in-game purchases of items.
      </Typography>
    </>
  );
};

export default TixBuyButton;
