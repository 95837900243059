import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Evil = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.4} cx={158} cy={150} rx={21} ry={22} />
    <ellipse opacity={0.4} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <circle fill='#fa4f45' cx={163} cy={145} r={19} />
    <circle cx={165} cy={148} r={15} />
    <circle fill='#FFF' cx={174} cy={135} r={8} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <circle fill='#fa4f45' cx={230} cy={145} r={19} />
    <circle cx={228} cy={148} r={15} />
    <circle fill='#FFF' cx={244} cy={135} r={8} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <path d='M181.92 127.34 167.2 116.3l17.17 6.64c1.31.51 1.96 1.98 1.45 3.29s-1.98 1.96-3.29 1.45c-.22-.09-.43-.21-.61-.34zM209.58 123.41l13.57-7.12-10.53 11.13a2.557 2.557 0 0 1-3.61.1 2.557 2.557 0 0 1-.1-3.61c.2-.2.43-.37.67-.5z' />
  </svg>
);

export default Evil;
