import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Rabbit = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#f0e6e9'
      stroke='#000'
      strokeWidth={4}
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M94.61 211.63c-.22-4.08-4.02-7.16-8.1-7.16-2.64 0-7.12 2.46-7.12 2.43 0-3.3-3.07-5.62-6.37-5.62-2.83 0-5.87 2.09-6.49 4.73-.26.05.16-.03-.1.03a7.387 7.387 0 0 0-6.11-2.68c-3.72.2-6.75 3.19-6.99 6.91-.08 1.32.65 4.16.65 4.17-.12.17-1.96 0-2.72.1-2.69.36-4.83 2.54-5.12 5.24a5.992 5.992 0 0 0 3.85 6.28 7.408 7.408 0 0 0-4.95 6.98c0 4.09 3.31 7.4 7.4 7.4.46 0 .91-.04 1.34-.12-.4.9-.59 1.91-.49 2.98.26 2.79 2.46 5.06 5.25 5.38 1.03.11 38.46 7.32 36.07-37.05z'
    />
    <path d='M69.58 216.23s-3.46-5.13-7.77-2.4-.29 7.61-.29 7.61-1.72-10.06 8.06-5.21zM79.69 210.41s-5.27 3.12-2.87 6.98c2.4 3.86 7.47.24 7.47.24s-9.99 1.57-4.6-7.22z' />
    <path
      opacity={0.15}
      d='M74 237c-13.89 3.1-18-1.38-18-11 0-8.28 13.48-14.37 5.65-20.88-2.87-2.38-8.08 1.42-8.32 5.14-.08 1.32-1.31 4.16-2.06 4.27-2.69.36-4.83 2.54-5.12 5.24a5.992 5.992 0 0 0 3.85 6.28 7.408 7.408 0 0 0-4.95 6.98c0 4.09 3.31 7.4 7.4 7.4.46 0 .76 1.79.85 2.86.26 2.79 2.46 5.06 5.25 5.38 1.02.12 15.58-11.7 15.45-11.67zM73.8 201.32c-.26-.03-.52-.04-.78-.04-2.66 0-5.51 1.85-6.35 4.27 2.09-1.8 4.5-3.24 7.13-4.23z'
    />
    <path d='M58.38 226.47s-4.53 4.21-1.17 8.05c3.36 3.83 7.56-.89 7.56-.89s-9.66 3.25-6.39-7.16z' />
  </svg>
);

export default Rabbit;
