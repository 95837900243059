import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  CircularProgress,
  Typography,
  Container,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LobbyFurball } from './LobbyFurball';
import {
  FormationPosition,
  useBossBattleLazyQuery,
} from '../../../components/schema';
import SelectPartyDropdown from './SelectPartyDropdown';
import { useBossBattle } from '../useBossBattle';
import { LobbyBossList } from './LobbyBossList';
import { useHistory } from 'react-router-dom';
import { useRefillHeartsFurCost } from './BossGameComplete';
import { BossKeyDefinition } from '../../../components/Loot';
import { slugify, useFurComponent } from '../../../utils';
import useCommonStyles from '../../../components/useCommonStyles';
import OkDialog from '../../../components/OkDialog';
import { yellow } from '../../../themev2';
import theme, { mint } from '../../../theme';
import { useClientHardware } from '../../App/clientHardware';
import ZoneButton from '../../../components/Zones/ZoneButton';
import { usePlayersReadyFurballs } from '../../../wallet';
import SpendWFurButton from '../../../components/Account/SpendWFurButton';
import { StartGameFunc } from './Lobby';
import WalletContext from '../../../wallet/WalletContext';

export const playGameVerb = 'Play Game';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '1rem',
    marginLeft: '0.6rem',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '0',
    },
  },

  loader: {
    position: 'absolute',
    inset: 0,
    display: 'grid',
    placeItems: 'center',
    padding: '0 10rem 6rem 5rem',
  },

  furballsContainer: {
    // padding: '0 14rem 9rem 5rem',
    display: 'grid',
    // placeItems: 'center',
    // height: 'calc(100% - 10rem)',
    // position: 'relative',
    // transform: 'scale(0.9)',
    padding: '0px !important',

    [theme.breakpoints.up('lg')]: {
      placeItems: 'center',
      height: 'calc(100% - 10rem)',
      position: 'relative',
      paddingLeft: '40px !important',
    },
  },

  furballsGrid: {
    width: '100%',
    display: 'grid',
    gap: '0rem',
    gridTemplateAreas: `
      "${FormationPosition.TopBack} a ${FormationPosition.TopFront}" 
      "b ${FormationPosition.Center} c" 
      "${FormationPosition.BottomBack} d ${FormationPosition.BottomFront}"`,
    gridTemplateColumns: 'repeat(3,  1fr)',
    gridTemplateRows: 'repeat(3,  1fr)',
    height: '18rem',
  },

  innerGrid: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: '1fr',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      // padding: '3rem 0',
      marginLeft: '2.5rem',
      // marginRight: '-0.3rem',
      overflow: 'hidden',
    },
  },

  defaultBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  defaultButton: {
    marginTop: '14x !important',
    color: '#ffffffaa !important',
    textDecoration: 'underline',

    '&:hover': {
      color: 'white !important',
    },
    // width: '9rem !important',
    // height: '100% !important',
    // borderRadius: '20px !important',
    //
    // '&[data-disabled="true"]': {
    //   opacity: 0.5,
    //   pointerEvents: 'none',
    // },
    //
    // [theme.breakpoints.down('sm')]: {
    //   width: '100% !important',
    //   margin: 'auto',
    // },
  },

  partyContainer: {
    // marginTop: 20,
    // paddingTop: 20,
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      // paddingLeft: '50% !important',
    },
  },

  partySwitchContainer: {
    // minWidth: '17rem',
    // textAlign: 'center',
    paddingTop: 20,

    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '100%',
    //   display: 'flex !important',
    // },
    [theme.breakpoints.down('lg')]: {
      // marginLeft: '-50% !important',
    },
  },

  furballFooter: {
    // position: 'fixed',
    textAlign: 'center',
    width: '100%',
    // bottom: 10,
    // right: 10,
    // backgroundColor: 'black',
    // marginTop: -100,
  },

  refillHearts: {
    // marginTop: -60,
    // position: 'relative',
    // top: -140,
    height: '60px !important',

    // padding: '0.8rem 0 0.8rem 0.6rem !important',
    backgroundColor: `${yellow} !important`,
    border: '2px solid #000 !important',
    // boxShadow: 'outset 0 -5px 0 2px #00000066 !important',
    boxShadow: 'inset 0 -5px 0 2px #00000022 !important',
    borderRadius: '50px !important',
    transition: 'all 0.2s linear',
  },

  bossList: {
    marginTop: 20,
    marginBottom: 80,
    paddingRight: 20,
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  bossListDialog: {
    minWidth: 320,
  },

  playFooter: {
    position: 'fixed',
    bottom: 16,
    right: 10,
    zIndex: 100,
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },

  playButton: {
    // padding: '20px 10px !important',
    backgroundColor: `${yellow} !important`,

    '&:hover': {
      backgroundColor: `${mint.light} !important`,
    },
  },
}));

interface IBossGamePrepare {
  battleBossName?: string;
  setBattleBossName: (n: string | undefined) => void;
  setShowStatsTokenId: (i: string | undefined) => void;
  refillHearts: () => Promise<void>;
  startGame: StartGameFunc;
}

export const BossGamePrepare: FunctionComponent<IBossGamePrepare> = (props) => {
  const {
    battleBossName,
    setBattleBossName,
    setShowStatsTokenId,
    refillHearts,
    startGame,
  } = props;
  const classes = useStyles();
  const [isDefault, setIsDefault] = React.useState(true);
  const [hovering, setHovering] = React.useState<BossKeyDefinition>();
  const [hasNonBossZoneMember, setHasNonBossZoneMember] = React.useState(false);
  const [showBossSelection, setShowBossSelection] = React.useState(false);
  const [currentlyDeleting, setCurrentlyDeleting] = React.useState<string>();
  const history = useHistory();
  const isSmall = useMediaQuery(theme.breakpoints.down('lg'));
  const { cornerPadding } = useClientHardware();
  const common = useCommonStyles();
  const { log } = useFurComponent(BossGamePrepare);
  const furballs = usePlayersReadyFurballs();
  const context = React.useContext(WalletContext);
  // const isMainNet = context?.network.type === NetworkType.MainNet;

  const [bossBattle, { loading: fetchingBattle, data }] =
    useBossBattleLazyQuery();

  const bbc = useBossBattle();
  const {
    setPartyId,
    // partySize,
    // partyMembers,
    currentParty,
    allParties,
    mutateParty,
    loading,
    currentPartyFurballs,
    isDeleting,
  } = bbc;
  const hasDeadMember = !!currentPartyFurballs.find((fb) => fb.hearts <= 0);
  const partyId = currentParty?.id ?? bbc.partyId;
  const partySize = currentParty?.partyMembers.length ?? -1;
  const partyMembers = currentParty?.partyMembers ?? [];
  const hasIncompleteMembers = ![1, 3, 5].includes(partySize);
  // const hasIncompleteMembers = partyMembers.length < partySize;
  // log.info('party', partyMembers.length, partySize);
  const refillFurCost = useRefillHeartsFurCost(currentPartyFurballs);

  React.useEffect(() => {
    const battleId = currentParty?.currentBattleId;
    const bossName = data?.bossBattle?.worldBoss?.name;

    !battleId
      ? setBattleBossName(undefined)
      : bossName
      ? setBattleBossName(bossName)
      : null;
  }, [data, currentParty, location]);

  async function onMenuOptionAction(
    action: 'delete' | 'edit',
    id: string,
    name: string,
  ) {
    if (action === 'edit') {
      history.push(`/boss/party/${id}`);
    } else {
      setCurrentlyDeleting(id);
      await mutateParty('delete', id);
      setCurrentlyDeleting(undefined);
    }
  }

  React.useEffect(() => {
    setIsDefault(currentParty?.slotNumber === 0);
    const battleId = currentParty?.currentBattleId;

    if (!battleId) return;

    bossBattle({ variables: { id: battleId } });
  }, [currentParty, allParties, location, battleBossName]);

  function handleChangeParty(id: string) {
    log.info('[BOSS] change party', id);
    setPartyId(id);
    setHasNonBossZoneMember(false);

    const party = allParties.find((p) => p.id === id);
    const slug = party ? slugify(party.name) : id;
    history.push('/furverse');
  }

  function renderGameOptions(className: string): React.ReactNode {
    // const battle: CurrentBattleFragment | undefined =
    //   currentParty?.currentBattle ?? undefined;
    return (
      <LobbyBossList
        className={className}
        startGame={startGame}
        hasDeadMember={hasDeadMember}
        hasIncompleteMembers={hasIncompleteMembers}
        battleBossName={battleBossName}
        bossBattle={data?.bossBattle}
        fetchingBattle={fetchingBattle}
        hovering={hovering}
        setHovering={(id) => setHovering(id)}
        hasNonBossZoneMember={hasNonBossZoneMember}
        setHasNoneBossZoneMember={(value) => setHasNonBossZoneMember(value)}
        refillHearts={refillHearts}
        refillFurCost={refillFurCost}
      />
    );
  }

  return (
    <>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        spacing={1}
        className={classes.root}>
        <Grid
          item
          container
          alignItems='stretch'
          gap={1}
          className={classes.partyContainer}>
          <Container className={classes.partySwitchContainer}>
            <SelectPartyDropdown
              parties={allParties ?? []}
              partyId={partyId ?? ''}
              setPartyId={handleChangeParty}
              disabled={loading}
              isDeleting={isDeleting}
              currentlyDeleting={currentlyDeleting}
              onMenuOptionAction={onMenuOptionAction}
            />
            <br />
            <a
              // variant='text'
              data-disabled={loading || currentParty?.isInBattle || isDefault}
              // data-disabled={loading || currentParty?.isInBattle || isDefault}
              className={classes.defaultButton}
              // style={{ width: '100%' }}
              href={'#default'}
              onClick={() => mutateParty('setDefault')}>
              <Typography
                fontSize='0.8rem !important'
                fontWeight='600'
                display='flex'
                justifyContent='center'
                alignItems='center'>
                {loading ? (
                  <CircularProgress size={18} color='inherit' />
                ) : (
                  'Set As Default'
                )}
              </Typography>
            </a>
          </Container>
        </Grid>

        <div className={classes.innerGrid}>
          <Grid container flexGrow={1} className={classes.furballsContainer}>
            {(!currentParty || allParties?.length === 0) && (
              <div className={classes.loader}>
                <CircularProgress size={60} />
              </div>
            )}
            <div className={classes.furballsGrid}>
              {partyMembers.map((fb, index) => {
                const furball = furballs.find((f) => f?.tokenId === fb?.id);
                return fb && furball ? (
                  <LobbyFurball
                    key={index}
                    furball={furball}
                    hasDeadMember={hasDeadMember}
                    position={fb.position}
                    hovering={hasIncompleteMembers ? undefined : hovering}
                    hasNonBossZoneMember={hasNonBossZoneMember}
                    setHasNonBossZoneMember={(value) =>
                      setHasNonBossZoneMember(value)
                    }
                    setStatsTokenId={setShowStatsTokenId}
                  />
                ) : null;
              })}
            </div>
            <div className={classes.furballFooter}>
              {refillFurCost > 0 && (
                <SpendWFurButton
                  itemName={'Refill Hearts'}
                  cost={refillFurCost}
                  onAccepted={refillHearts}
                  className={classes.refillHearts}
                />
              )}
            </div>
          </Grid>
        </div>
      </Grid>

      {!isSmall && renderGameOptions(classes.bossList)}

      {!hasDeadMember && (
        <div
          className={classes.playFooter}
          style={{ bottom: cornerPadding, paddingBottom: 8 }}>
          <ZoneButton
            onClick={() => setShowBossSelection(true)}
            text={playGameVerb}
          />
        </div>
      )}

      <OkDialog
        title={'Choose a Boss'}
        open={showBossSelection}
        maxWidth={'lg'}
        onClose={() => setShowBossSelection(false)}>
        {isSmall && renderGameOptions(classes.bossListDialog)}
      </OkDialog>
    </>
  );
};

export default BossGamePrepare;
