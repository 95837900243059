import React, { FunctionComponent } from 'react';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  LeaderboardStat,
  LeaderboardTimeframe,
  RealmType,
  TournamentFragment,
  TournamentScoringStyle,
  useJoinTournamentMutation,
} from '../../components/schema';
import useCommonStyles from '../../components/useCommonStyles';
import { cdnRoot } from '../../theme';
import LeaderboardResetCountdown from '../../components/BossFights/BossLeaderboards/LeaderboardResetCountdown';
import { getBossNameFromRealm } from '../../utils';
import BossLeaderboard from '../../components/BossFights/BossLeaderboards/BossLeaderboard';
import { IPendingRewards } from '../../wallet/WalletTypes';
import OkDialog from '../../components/OkDialog';
import TixBalanceButtonV2 from '../Account/BalanceButtons/TixBalanceButtonV2';
import SpendTixButton from '../../components/Account/SpendTixButton';
import ShareReferButton from '../../components/Account/ShareReferButton';
import { Link } from 'react-router-dom';
import { getRealmSlug } from '../../components/Zones/ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#cde1b3',
    borderRadius: '20px !important',
    marginBottom: '4rem',
  },
  heroContainer: {
    maxWidth: 1000,
    padding: '1rem',
  },
  leaderboards: {
    padding: '2rem',
  },
  heroImg: {
    maxWidth: '100%',
  },
  footer: {
    padding: '0rem 1rem 1rem 1rem',
  },
  footerContainer: {
    backgroundColor: '#4031b3',
    borderRadius: '8px',
  },
  footerCell: {
    paddingLeft: '1rem',
    color: 'white',
  },
  footerLink: {
    color: '#efefef !important',
    textDecoration: 'underline',
  },
  joinButton: {
    padding: 10,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    paddingBottom: '1rem',

    '& button': {
      width: '250px !important',
      minWidth: '250px !important',
      height: '42.5px !important',

      [theme.breakpoints.down('sm')]: {
        width: '290px !important',
        minWidth: '290px !important',
      },
    },
  },
}));

interface IActiveTournament {
  tournament: TournamentFragment;
  pendingRewards: IPendingRewards[];
}

const backgroundColors = {
  [RealmType.None]: '#000000',
  [RealmType.NumRealms]: '#000000',
  [RealmType.TurtleSea]: '#cde1b3',
  [RealmType.WizardsPath]: '#c2c2fa',
  [RealmType.Boneyard]: '#000000',
};

export const ActiveTournament: FunctionComponent<IActiveTournament> = (
  props,
) => {
  const { pendingRewards } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const [tournament, setTournament] = React.useState(props.tournament);
  const startsAt = new Date(tournament.startsAt);
  const hasStarted = startsAt <= new Date();
  const endsAt = new Date(tournament.endsAt);
  const [joining, setJoining] = React.useState(false);
  const [joinTournament, { data, loading }] = useJoinTournamentMutation();
  const rf = 10000;
  const roundedEth = Math.round(tournament.payoutPoolEth * rf) / rf;
  const assetUrl = tournament.heroUrl
    ? tournament.heroUrl.startsWith('http')
      ? tournament.heroUrl
      : `${cdnRoot}/images/tournaments/${tournament.heroUrl}`
    : `${cdnRoot}/images/tournaments/${tournament.id}.png`;

  async function doJoin() {
    const res = await joinTournament({
      variables: { tournamentId: tournament.id },
    });

    if (res.data?.joinTournament) {
      setTournament(res.data.joinTournament);
    }
  }

  function renderJoinControls(): React.ReactNode {
    if (tournament.currentParticipant) {
      return (
        <>
          <LeaderboardResetCountdown
            resetMessage={'My score'}
            valueOverride={
              (tournament.currentParticipant.totalScore ?? 0) + ' PTS'
            }
          />
          <div className={classes.buttons}>
            <ShareReferButton primary={true} />
            <Link to={`/boss/lobby/${getRealmSlug(realm)}`}>
              <Button variant={'contained'} className={common.actionButton}>
                <Typography variant={'h6'}>Fight Boss!</Typography>
              </Button>
            </Link>
          </div>
        </>
      );
    }

    return (
      <>
        <LeaderboardResetCountdown
          resetMessage={'My score'}
          valueOverride={'None!'}
        />
        <Button
          variant={'contained'}
          className={common.actionButton}
          color={'primary'}
          onClick={() => setJoining(true)}
          disabled={!hasStarted}>
          <Typography variant={'h6'}>Join Tournament</Typography>
        </Button>
        {/*<Typography variant={'subtitle2'}>*/}
        {/*  Learn more{' '}*/}
        {/*  <GuideLink className={classes.footerLink} to={'bosses/tournaments'}>*/}
        {/*    about Tournaments*/}
        {/*  </GuideLink>*/}
        {/*  .*/}
        {/*</Typography>*/}
      </>
    );
  }

  function getRuleDescription(scoring: TournamentScoringStyle): string {
    if (scoring === TournamentScoringStyle.MaxNormalizedScore)
      return 'Best single-game avg. score against ';
    if (scoring === TournamentScoringStyle.MaxNormalizedDamageFromBoss)
      return 'Most damage survived from ';
    if (scoring === TournamentScoringStyle.MaxPlayerDamagePerTurn)
      return 'Max damage PER TURN against ';
    if (scoring === TournamentScoringStyle.MaxNormalizedDamageFromPlayer)
      return 'Max total damage against ';
    return 'Top 3 daily avg. scores against ';
  }

  function renderRules(): React.ReactNode {
    const targ = tournament.realm
      ? getBossNameFromRealm(tournament.realm)
      : 'any boss';
    return (
      <>
        <LeaderboardResetCountdown
          resetMessage={'Rules'}
          valueOverride={getRuleDescription(tournament.scoringStyle) + targ}
        />
      </>
    );
  }

  const realm = tournament.realm ?? RealmType.Boneyard;
  const backgroundColor =
    tournament.backgroundColor ?? backgroundColors[realm] ?? '#000000';

  return (
    <Grid container className={clsx(classes.root)} style={{ backgroundColor }}>
      <Grid item xs={12} lg={8}>
        <div className={classes.heroContainer}>
          <img src={assetUrl} className={classes.heroImg} />
        </div>
      </Grid>
      <Grid item xs={12} lg={4} className={classes.leaderboards}>
        <BossLeaderboard
          boss={{
            name: getBossNameFromRealm(realm),
            realm: realm,
            leaderboardStat: LeaderboardStat.Tournament,
          }}
          timeframe={LeaderboardTimeframe.AllTime}
          leaderboard={tournament?.leaderboard}
          pendingRewards={pendingRewards}

          // tabs={{}}
        />
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Grid container className={classes.footerContainer}>
          <Grid item xs={12} lg={5} className={classes.footerCell}>
            {renderRules()}
          </Grid>
          <Grid item xs={12} lg={4} className={classes.footerCell}>
            {tournament.entryFeeFuel > 0 && (
              <LeaderboardResetCountdown
                valueOverride={`${roundedEth} ETH`}
                resetMessage={'Current prize pool'}
              />
            )}
            {hasStarted && (
              <LeaderboardResetCountdown
                resetAt={endsAt}
                resetMessage={'Tournament ends in'}
              />
            )}
            {!hasStarted && (
              <LeaderboardResetCountdown
                resetAt={startsAt}
                resetMessage={'Tournament starts in'}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={3} className={classes.footerCell}>
            {renderJoinControls()}
          </Grid>
        </Grid>
      </Grid>
      <OkDialog
        title={'Join Tournament?'}
        open={joining}
        onClose={() => setJoining(false)}>
        {!hasStarted && (
          <>
            <Typography variant={'h6'}>
              This tournament has not started yet!
            </Typography>
            <Typography variant={'subtitle2'}>
              The pool will increase as players join before it begins.
            </Typography>
          </>
        )}
        {hasStarted && (
          <>
            <Typography variant={'h5'}>Ready to compete?</Typography>
            <Typography variant={'subtitle2'}>
              The prizes will continue to grow as more players join.
            </Typography>
          </>
        )}
        <Grid container style={{ minWidth: 500, textAlign: 'center' }}>
          <Grid item xs={12} lg={4} style={{ paddingTop: 18 }}>
            <TixBalanceButtonV2 />
          </Grid>
          <Grid item xs={12} lg={8} style={{ paddingTop: 20 }}>
            <SpendTixButton
              cost={tournament.entryFeeFuel}
              onAccepted={doJoin}
              onComplete={() => setJoining(false)} // close dialog late so we don't accidentally not complete
              itemName={'Tournament (' + tournament.name + ')'}
              isLoading={loading}
              profitMult={0.5}
            />
          </Grid>
        </Grid>
      </OkDialog>
    </Grid>
  );
};

export default ActiveTournament;
