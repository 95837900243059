import * as React from 'react';

function SvgIconSkills(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' {...props}>
      <path
        d='M16.4 6.8h-.3l-5.1.8.4-5.8v-.4c0-.5-.4-.9-.9-.8h-.4l-1 .2-6.4 1 1.5 12.6.7-.1 4.5-.5c.6-.1 1.1.3 1.2.9l.1.5 1.1 5.6.1.3c.1.3.4.3.5 0l1.9-5.1 3-8.2c.2-.5-.3-1.1-.9-1z'
        fill={`#${props.fill ?? 'FFFFFF'}`}
      />
    </svg>
  );
}

export default SvgIconSkills;
