import { Button, Grid, Theme, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useFurballs } from '../../wallet';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import {
  getZone,
  getZoneNumbers,
  useActiveVoyageRealms,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_EXPLORE,
  ZONE_NUM_SCHOLARSHIPS,
  ZONE_NUM_TOWN,
  ZONE_NUM_VOYAGE,
} from './ZoneHelpers';
import { IHaveZone, ISelectedFurball } from './ZoneTypes';
import { IFurballIds } from '../../pages/Play/PlayTypes';
import { useFrameRate } from '../useAnimation';
import OkDialog, { ICloseDialog } from '../OkDialog';
import {
  expColor,
  furColor,
  realmBoneyardColor,
  realmTurtleSeaColor,
  realmWizardsPathColor,
} from '../../theme';
import { useEstimatedFurballRewards } from '../Timekeeper/RewardEstimator';
import EstimatedRewards from '../Timekeeper/EstimatedRewards';

import { Link } from 'react-router-dom';
import { StatAbbreviation } from '../Stats';
import MoveZonesButtons from './MoveZonesButtons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  furballCell: {},
  zoneButton: {
    width: '100%',
    maxWidth: '400px !important',
  },
  furballGrid: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '360px',
    width: 520,
    paddingBottom: 10,
    padding: 0,
  },
  rateText: {
    marginTop: '-20px !important',
    marginBottom: '-6px !important',
    width: '100%',
    textAlign: 'center',
  },
  subText: {
    textAlign: 'center',
  },
  caret: {
    maxHeight: '24px',
    position: 'absolute',
    right: '20px',
  },
  caretFlip: {
    maxHeight: '24px',
    position: 'absolute',
    left: '20px',
    transform: 'scale(-1, 1)',
  },
  furballImage: {
    minWidth: '300px',
    margin: '4px !important',
    marginBottom: '-24px !important',
    padding: '0px !important',
    maxWidth: '300px',
  },
  turtleSeaButton: {
    backgroundColor: `${realmTurtleSeaColor.light} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: realmTurtleSeaColor.light,
    },
  },
  wizardsPathButton: {
    backgroundColor: `${realmWizardsPathColor.light} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: realmWizardsPathColor.light,
    },
  },
  boneyardButton: {
    backgroundColor: `${realmBoneyardColor.light} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: realmBoneyardColor.light,
    },
  },
  battleButton: {
    backgroundColor: `${furColor.light.toString()} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: furColor.light,
    },
  },
  exploreButton: {
    backgroundColor: `${expColor.light.toString()} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: expColor.light,
    },
  },
  headerTimekeeper: {},
  [theme.breakpoints.down('lg')]: {
    furballGrid: {
      minWidth: '340px',
    },
    furballImage: {
      minWidth: '280px',
    },
  },

  dialogContent: {
    minWidth: '700px',
    maxWidth: '700px',
    padding: '0 !important',
    margin: '0 auto',

    ['@media (max-width: 700px)']: {
      minWidth: '0',
    },
  },
}));

type Props = IHaveZone &
  IFurballIds &
  ICloseDialog & {
    onSelectedZone: (newZone: number) => void;
    timekeeperDisabled: boolean;
    setSelectedFurballs?: (furballIds: ISelectedFurball[]) => void;
  };

export function getOtherZones(currentZoneNum: number): number[] {
  const zoneNumbers = getZoneNumbers();
  return zoneNumbers.filter(
    (n) =>
      n !== currentZoneNum &&
      n !== ZONE_NUM_VOYAGE &&
      n !== ZONE_NUM_SCHOLARSHIPS,
  );
}

export const ZoneSelection: FunctionComponent<Props> = (props) => {
  const {
    zoneNumber,
    onSelectedZone,
    furballIds,
    open,
    onClose,
    timekeeperDisabled,
    setSelectedFurballs,
  } = props;
  const zone = getZone(zoneNumber);
  const classes = useStyles();
  const otherZone = getZone(zoneNumber === 0 ? 0x10000 : 0);
  const furballs = useFurballs(furballIds);
  const rewards = useEstimatedFurballRewards(furballs);
  const snackStats: StatAbbreviation[] = zone.isBattle
    ? ['fur']
    : ['exp', 'luck'];

  const realms = useActiveVoyageRealms();
  const isVoyage = furballs[0]?.zone === ZONE_NUM_VOYAGE;
  const isBossFights =
    zone.zoneNumber === ZONE_NUM_BOSS_FIGHTS ||
    zone.zoneNumber === ZONE_NUM_SCHOLARSHIPS;

  let title = 'Collect EXP & Loot';
  if (isVoyage) title = 'End Voyage?';
  else if (isBossFights) title = 'Leave Boss Fights?';
  else if (zone.isBattle)
    title = `Collect EST. ${rewards.furEarned.toLocaleString()} $wFUR`;

  // Refresh rewards
  useFrameRate(true);

  function handleSaved() {
    !!setSelectedFurballs && setSelectedFurballs([]);
    onClose();
  }

  if (timekeeperDisabled)
    return (
      <OkDialog
        open={open}
        topRight={true}
        color={'secondary'}
        onClose={onClose}
        title={title}
        actions={
          <Grid container spacing={1}>
            <Grid item xs={12} lg={6} style={{ textAlign: 'center' }}>
              <Button
                color={'secondary'}
                disableElevation
                variant={'contained'}
                className={clsx(classes.zoneButton, {
                  [classes.exploreButton]: !zone.isBattle,
                  [classes.battleButton]: zone.isBattle,
                })}
                onClick={() => void onSelectedZone(zone.zoneNumber)}>
                <Typography variant={'h6'}>
                  Collect + Stay In {zone.title}
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} lg={6} style={{ textAlign: 'center' }}>
              <Button
                variant={'contained'}
                color={'primary'}
                className={clsx(classes.zoneButton, {
                  [classes.exploreButton]: !otherZone.isBattle,
                  [classes.battleButton]: otherZone.isBattle,
                })}
                disableElevation
                onClick={() => onSelectedZone(otherZone.zoneNumber)}>
                <Typography variant={'h6'}>
                  Collect + Go to {otherZone.title}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        }>
        <div className={classes.furballGrid}>
          <EstimatedRewards furballs={furballs} showFur={true} showExp={true} />
        </div>

        <Typography variant={'body1'} style={{ textAlign: 'center' }}>
          To save on gas,{' '}
          <Link to={`/timekeeper/toggle/${furballs.map((fb) => fb.tokenId)}`}>
            enable Timekeeper
          </Link>
          .
        </Typography>
      </OkDialog>
    );

  return (
    <OkDialog title={title} open={open} onClose={onClose}>
      <Grid container className={classes.dialogContent}>
        <MoveZonesButtons
          realms={realms}
          canShowRealms={
            isVoyage ||
            zone.zoneNumber === ZONE_NUM_EXPLORE ||
            zone.zoneNumber === ZONE_NUM_TOWN
          }
          furballIds={furballIds}
          currentZone={zoneNumber}
          onSaved={handleSaved}
          closeEarly={handleSaved}
          isBossFights={isBossFights}
        />
      </Grid>
    </OkDialog>
  );
};

export default ZoneSelection;
