import {
  IShopSnack,
  IShopWares,
  ITimekeeperInteraction,
} from '../Timekeeper/TimekeeperSlice';
import { TimekeeperInteractionType } from '../schema';
import { IFurball } from '../../wallet/WalletTypes';
import { StatAbbreviation } from '../Stats';

export function findInteraction(
  interactions: ITimekeeperInteraction[],
  type: TimekeeperInteractionType,
  tokenId: string,
  expectedValue: number | null = null,
): ITimekeeperInteraction | undefined {
  for (let i = 0; i < interactions.length; i++) {
    if (
      interactions[i].interactionType === type &&
      interactions[i].furballIds.includes(tokenId) &&
      interactions[i].expectedValue === expectedValue
    ) {
      return interactions[i];
    }
  }
  return undefined;
}

export function hasWaresPurchase(
  furballs: IFurball[],
  interactions: ITimekeeperInteraction[],
  tokenId: string,
  w: IShopWares,
): boolean {
  // In active purchases?
  if (
    findInteraction(
      interactions,
      TimekeeperInteractionType.ShopPurchase,
      tokenId,
      w.id,
    )
  )
    return true;

  const furball = furballs.find((fb) => fb.tokenId === tokenId);
  if (furball) {
    const isMystery = w.itemIds.length === 1 && w.itemIds[0] === 0x101;
    return !!furball.inventory.items.find(
      (i) =>
        w.itemIds.includes(i.itemId) ||
        // mystery box unpack...
        (isMystery && i.itemId % 0x100 === 1),
    );
  }
  return false;
}

export function hasSnackPurchase(
  interactions: ITimekeeperInteraction[],
  tokenId: string,
  snack: IShopSnack,
): boolean {
  if (
    findInteraction(
      interactions,
      TimekeeperInteractionType.FeedSnack,
      tokenId,
      snack.id,
    )
  )
    return true;
  return false;
}

export function doesSnackBoostStats(
  snack: IShopSnack,
  ...stats: StatAbbreviation[]
): boolean {
  if (stats.includes('exp') && snack.happiness > 0) return true;
  return false;
}
