import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';
import { IFurballWallet } from './wallet/WalletTypes';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useWalletSelector: TypedUseSelectorHook<IFurballWallet> = (a) => {
  return useAppSelector((s) => a(s.wallet));
};
