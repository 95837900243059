import { useContext, useCallback } from 'react';
import WalletSlice from '../../wallet/WalletSlice';

import { useAppDispatch, useWalletSelector } from '../../hooks';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { useFurballsLazyQuery } from '../schema';
import { useAuthContext } from '../Auth/AuthContext';
import { FurAccountTypes } from '../Auth/AuthTypes';
import { IFurball } from '../../wallet/WalletTypes';
import { usePlayersReadyFurballs } from '../../wallet';
import { getInventoryQuantities } from './Inventory';
import { ILogger } from '../../utils';

export const useFurballHelpers = (log: ILogger) => {
  const dispatch = useAppDispatch();
  const walletContents = useWalletSelector((s) => s.contents);
  const playerId = useWalletSelector((s) => s.contents?.id);
  const furballs = usePlayersReadyFurballs();
  const {
    authState: { playerType },
  } = useAuthContext();

  const { addAlert } = useContext(AlertContext);
  const [loadFurballs] = useFurballsLazyQuery();

  const refreshFurballs = useCallback(async (ids: string[]) => {
    if (ids.length === 0) return;

    try {
      log.info('[FB] reloading', ids.length);
      const { data } = await loadFurballs({
        variables: { tokenIds: ids },
      });

      if (!data) return;

      const fbs = data.furballs.map((furball) => {
        const isRented =
          furball.activeRentalAgreement?.playerId?.toLowerCase() ===
          playerId?.toLowerCase();

        return {
          ...furball,
          isRented: isRented ?? playerType === FurAccountTypes.SCHOLAR,
          inventory: getInventoryQuantities(furball.inventory),
        };
      });
      if (fbs.length > 0) dispatch(WalletSlice.actions.addUsableFurballs(fbs));
      log.info('[FB] reloaded', data.furballs.length);
    } catch (error: any) {
      log.warn('[FB] reloading', ids, error);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Opps! Failed to refresh furballs.',
      });
    }
  }, []);

  const removeFurballs = (ids: string[]) => {
    if (ids.length === 0) return;
    const updatedFbs: { [key: string]: IFurball } = {};

    const filteredFurballs = furballs.filter(
      (item) => !ids.includes(item.tokenId),
    );

    const filteredIds = walletContents?.tokenIds.filter(
      (id) => !ids.includes(id),
    );

    filteredFurballs?.forEach((furball) => {
      updatedFbs[furball.tokenId] = furball;
    });

    dispatch(WalletSlice.actions.setFurballs(updatedFbs));
    dispatch(
      WalletSlice.actions.setWalletContents({
        ...(walletContents as any),
        tokenIds: filteredIds,
      }),
    );
  };

  return { refreshFurballs, removeFurballs };
};
