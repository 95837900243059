import { SvgEditionSlot } from '../part';
import * as React from 'react';

const StrawofDeath = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='m331.29 118.59-4.43-56.83c-.8-3.96-2.65-7.62-5.33-10.58-2.52-2.78-5.74-4.9-9.31-6.13a22.428 22.428 0 0 0-10.98-.93c-3.91.66-7.58 2.38-10.64 4.98-3.49 2.98-5.98 6.94-7.18 11.47-.01.04-.02.08-.03.13l-56.37 219.83c-1.78 7.35 5.39 15.7 12.74 17.46l6.84 1.64 57.34-233.66c.12-.43.3-.62.42-.73.14-.12.4-.34.94-.15.65.23.75.72.78.88l5.74 55.79'
    />
    <path
      fill='#7dffdf'
      d='m329.07 87.82-1.75-16.75s-7.47-6.65-20.4-.81l2.8 17.22c12.61-7.1 19.35.34 19.35.34zM306.22 67.31l7.66-19.97s-15.85-9.46-28.53 8.36l17.78 13.51c3.31-1.29 3.09-1.9 3.09-1.9z'
    />
    <path
      opacity={0.35}
      fill='#FFF'
      d='M326.03 62.18a4.848 4.848 0 0 0-4.22 5.62l4.23 51.41-14.16 2.56.07.48 19.25-3.4-5.17-56.67z'
    />
    <path
      fill='#7dffdf'
      d='m237.21 243.82-5.58 17.58s7.78 10.66 19.24 12.07l5-17.88c-11.47-1.42-18.66-11.77-18.66-11.77zM247.7 201.5l-4.43 18.18s6.62 10.06 18.08 11.47l5.58-17.58c-11.45-1.41-19.23-12.07-19.23-12.07zM259.79 159.74l-4.33 18.39s6.52 9.84 17.99 11.25l5.58-17.58c-11.46-1.4-19.24-12.06-19.24-12.06zM267.9 119.98l-4.11 17.03s7.29 10.75 18.75 12.16l3.68-18.39c-11.46-1.41-18.32-10.8-18.32-10.8zM278.97 78.16 274.2 96.8s6.97 9.47 18.44 10.88l3.8-17.88c-11.47-1.41-17.47-11.64-17.47-11.64z'
    />
    <path
      opacity={0.29}
      fill='#548f81'
      d='M296.21 70.95 241.02 298.3l5.57 1.34 58.24-236.11-2.65.91a9.228 9.228 0 0 0-5.97 6.51z'
    />
    <path
      fill='#7dffdf'
      d='m331.33 117.43-1.11-15.2s-8.62-5.38-20.08 1.98l1.77 15.73c11.46-7.36 19.42-2.51 19.42-2.51z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m331.29 118.59-4.43-56.83c-.8-3.96-2.65-7.62-5.33-10.58-2.52-2.78-5.74-4.9-9.31-6.13a22.428 22.428 0 0 0-10.98-.93c-3.91.66-7.58 2.38-10.64 4.98-3.49 2.98-5.98 6.94-7.18 11.47-.01.04-.02.08-.03.13l-56.37 219.83c-1.78 7.35 5.39 15.7 12.74 17.46l6.84 1.64 57.34-233.66c.12-.43.3-.62.42-.73.14-.12.4-.34.94-.15.65.23.75.72.78.88l5.74 55.79'
    />
    <path
      opacity={0.29}
      fill='#548f81'
      d='m304.83 63.53 6.88 57.06 6.35-.97-4.86-48.67a8.882 8.882 0 0 0-8.37-7.42z'
    />
    <path
      fill='#FFF'
      d='M322.91 125.12c-5.65.9-10.55-.39-10.94-2.88-.39-2.49 3.87-5.23 9.52-6.12 5.65-.9 9.35.39 9.74 2.88.39 2.48-2.67 5.22-8.32 6.12z'
    />
    <path
      fill='#548f81'
      d='M322.91 125.12c-5.65.9-10.55-.39-10.94-2.88-.39-2.49 3.87-5.23 9.52-6.12 5.65-.9 9.35.39 9.74 2.88.39 2.48-2.67 5.22-8.32 6.12z'
      opacity={0.29}
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M322.91 125.12c-6.08.96-11.33-.27-11.72-2.75-.39-2.49 4.22-5.28 10.31-6.25 6.08-.96 10.13.27 10.52 2.75.39 2.49-3.02 5.28-9.11 6.25z'
    />
  </svg>
);

export default StrawofDeath;
