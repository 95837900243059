import React, { FunctionComponent, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ItemQuantityFragment } from '../schema';

import InventorySlot, { IInventorySlot } from './InventorySlot';
import useCommonStyles from '../useCommonStyles';
import clsx from 'clsx';
import InventoryItemDialog from './InventorItemDialog';
import AlertProvider from '../Alert/AlertProvider';

import InventoryFilters, {
  ALL_INVENTORIES_OPTION,
  IFilterOption,
  ITEMGROUP_FILTER_OPTIONS,
  PLAYER_INVENTORY_OPTION,
} from './InventoryFilters';

import { usePlayersReadyFurballs } from '../../wallet';
import { useWalletSelector } from '../../hooks';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';

import {
  getFilteredItems,
  getFurballInventoryItems,
  getItemQuantityList,
  IOwnedItem,
  useFurballItemList,
  useInventoryHelpers,
} from './InventoryHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    // maxWidth: '680px',
    margin: '60px auto 5px auto !important',

    '@media (max-width: 972px)': {
      justifyContent: 'center',
      padding: '10px',
      marginBottom: '0 !important',
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: '0 !important',
    },
  },
  root: {
    maxWidth: '100%',
    maxHeight: '490px',
    padding: '5px',
    marginTop: '0.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(156px, 1fr))',
    overflowY: 'auto',

    '@media (max-width: 972px)': {
      justifyContent: 'center',
      marginTop: '0',
    },
  },
  header: {
    marginBottom: '5px',
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  select: {
    minWidth: '16rem',

    '& > div': {
      border: `2px solid ${theme.palette.common.black} !important`,
      backgroundColor: '#784DFF !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& [data-icons]': {
        display: 'none !important',
      },
    },

    '& p': {
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.common.white,
    },
  },
  selectDropdown: {
    border: `2px solid ${theme.palette.common.black} !important`,
  },
  selectMenuItem: {
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },
}));

interface IPlayerInventory {}

const Inventory: FunctionComponent<IPlayerInventory> = (props) => {
  const [selectedItem, setSelectedItem] = useState<IOwnedItem | null>(null);
  const [selectedItemId, setSelectedItemId] = useState(0);

  const classes = useStyles();
  const common = useCommonStyles();

  const { inventoryItemQuantities, allInventoryItems, playerInventoryItems } =
    useInventoryHelpers();

  const furballs = usePlayersReadyFurballs();
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');

  const inventoryRef = useRef<HTMLDivElement>(null);
  const items = allInventoryItems(false, true);

  const [filterOnInventoryFor, setFilterOnInventoryFor] =
    useState<IFilterOption>(ALL_INVENTORIES_OPTION);

  const [filterOnItemGroup, setFilterOnItemGroup] = useState<IFilterOption>(
    ITEMGROUP_FILTER_OPTIONS[0],
  );

  const playerOnly =
    filterOnInventoryFor.value === PLAYER_INVENTORY_OPTION.value;
  const includePlayerInventory =
    playerOnly || filterOnInventoryFor.value === ALL_INVENTORIES_OPTION.value;

  const furballItems = useFurballItemList({
    playerOnly,
    includePlayerInventory,
    filterOnInventoryFor,
  });

  const itemQuantities = getItemQuantityList(
    includePlayerInventory ? inventoryItemQuantities : [],
    furballItems,
  ).filter(filterItemQuantity);

  function filterItemQuantity(quantity: ItemQuantityFragment): boolean {
    if (filterOnItemGroup.value === ITEMGROUP_FILTER_OPTIONS[0].value) {
      return true;
    }
    const def = getItemDef(quantity.itemId);
    return def?.itemGroup === filterOnItemGroup.value;
  }

  function setInventoryAfterSelectedFilter() {
    if (filterOnInventoryFor.value === PLAYER_INVENTORY_OPTION.value) {
      return playerInventoryItems();
    }

    if (filterOnInventoryFor.value !== ALL_INVENTORIES_OPTION.value) {
      return getFbInventoryItems(filterOnInventoryFor.value);
    }

    return items;
  }

  function getFbInventoryItems(tokenId: string): IOwnedItem[] {
    const furball = furballs.find((fb) => fb.tokenId === tokenId);
    if (!furball) return [];

    return getFurballInventoryItems(furball, true);
  }

  const baseInventoryItems = setInventoryAfterSelectedFilter();
  const inventoryItems = getFilteredItems(
    baseInventoryItems,
    selectedItemId,
    filterOnItemGroup,
  );

  React.useEffect(() => {
    if (!!inventoryRef.current) {
      inventoryRef.current.scrollTo(0, 0);
    }
  }, [filterOnItemGroup, filterOnInventoryFor]);

  function renderSlots() {
    const slotProps: IInventorySlot = {
      containerId: playerId,
    };

    if (selectedItemId <= 0) {
      return itemQuantities.map((quant, i) => {
        return (
          <InventorySlot
            {...slotProps}
            fluidSize
            key={`inventory-slot-${quant.itemId}`}
            itemQuantity={quant}
            onSelectItemId={() => setSelectedItemId(quant.itemId)}
          />
        );
      });
    }

    return inventoryItems.map((ownedItem, i) => {
      const item = ownedItem?.item;

      return (
        <InventorySlot
          {...slotProps}
          fluidSize
          key={`inventory-slot-${i}`}
          item={item}
          onSelectItem={() => setSelectedItem(ownedItem)}
          furball={ownedItem?.furball}
        />
      );
    });
  }

  return (
    <AlertProvider>
      <div className={classes.main}>
        <InventoryFilters
          filterOnItemGroup={filterOnItemGroup}
          setFilterOnItemGroup={setFilterOnItemGroup}
          filterOnInventoryFor={filterOnInventoryFor}
          setFilterOnInventoryFor={setFilterOnInventoryFor}
          furballs={furballs}
          selectedItemId={selectedItemId}
          setSelectedItemId={setSelectedItemId}
        />

        <div
          ref={inventoryRef}
          className={clsx(classes.root, common.styledScrollBar)}>
          {renderSlots()}
        </div>
      </div>
      <InventoryItemDialog
        open={!!selectedItem}
        close={() => setSelectedItem(null)}
        selectedItem={selectedItem}
      />
    </AlertProvider>
  );
};

export default Inventory;
