import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Surprised = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#8b1000'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M204.81 160.13c-1.04-.03-2.05.12-3 .4-3.11.94-6.43.94-9.54 0-.95-.29-1.95-.43-3-.4-4.85.12-8.97 4.07-9.27 8.92-.34 5.53 4.05 10.13 9.5 10.13.86 0 1.7-.11 2.49-.33 3.29-.89 6.79-.89 10.08 0 .79.21 1.63.33 2.49.33 5.46 0 9.84-4.59 9.5-10.13-.29-4.84-4.4-8.8-9.25-8.92z'
    />
    <path
      fill='#ffbad3'
      d='M197.04 170.46c-7.05-.2-9.9 3.01-10.87 5.74 1.17.53 2.19.95 4.18.81.86-.06 3.8-.96 6.86-.96 2.94 0 6.01.96 6.83.96 1.37 0 2.93-.08 4.2-.92-.86-2.43-2.7-5.4-11.2-5.63z'
    />
    <path d='m194.22 154.64-3.75-3.75c-2.51-2.51-.73-6.8 2.82-6.8h7.5c3.55 0 5.32 4.29 2.82 6.8l-3.75 3.75a3.983 3.983 0 0 1-5.64 0z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M197 149v12'
    />
  </svg>
);

export default Surprised;
