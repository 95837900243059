import React, { FunctionComponent } from 'react';
import SvgCaret from '../assets/SvgCaret';
import useCommonStyles from './useCommonStyles';
import { makeStyles } from '@mui/styles';
import { Button, Pagination, Theme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    position: 'absolute',
    top: (props: any) => (props.smallNavButtons ? '2px' : '15%'),
  },
  pagePrev: {
    left: (props: any) => (props.smallNavButtons ? '-50px' : '20px'),
  },
  pageNext: {
    right: (props: any) => (props.smallNavButtons ? '-50px' : '20px'),
  },
  scrollV: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  scrollH: {
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  hidden: {
    color: '#AAA !important',
  },
  button: {
    borderRadius: '50% !important',
    backgroundColor: (props: any) =>
      props.smallNavButtons
        ? 'transparent !important'
        : 'rgba(0,0,0, 0.4) !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: (props: any) =>
      props.smallNavButtons ? '40px !important' : 'inherit',
    width: (props: any) =>
      props.smallNavButtons ? '40px !important' : 'inherit',
    minWidth: (props: any) =>
      props.smallNavButtons ? '40px !important' : 'inherit',
  },
  scroller: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
  },
}));

type Props = {
  className?: string;
  numItems: number;
  itemIndex: number;
  setItemIndex: (n: number) => void;
  renderItem: (pos: number, idx: number) => React.ReactNode;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  smallNavButtons?: boolean;
  hidePagination?: boolean;
};

export interface IRect {
  width: number;
  height: number;
}

// https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-elementw
export function useContainerDimensions(
  div: HTMLDivElement | null,
  defSize?: IRect,
): IRect {
  const def = defSize ?? { width: 0, height: 0 };
  const getDimensions = () => ({
    width: div?.offsetWidth ?? def.width,
    height: div?.offsetHeight ?? def.height,
  });

  const [dimensions, setDimensions] = React.useState({ ...def });

  React.useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (div) {
      setDimensions(getDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [div]);

  return dimensions;
}

export const HorizontalScroller: FunctionComponent<Props> = (props) => {
  const {
    children,
    className,
    style,
    itemIndex,
    setItemIndex,
    numItems,
    renderItem,
    smallNavButtons,
  } = props;
  const btnSize = smallNavButtons ? '12px' : '30px';
  const classes = useStyles({ smallNavButtons });
  const common = useCommonStyles();
  // const size = useWindowSize();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [atEnd, setAtEnd] = React.useState(false);
  const [atStart, setAtStart] = React.useState(false);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const numPages = Math.ceil(numItems / itemsPerPage);
  // const pages = [...Array(numPages)].map((a, i) => i);
  const curPageNum = Math.floor(itemIndex / itemsPerPage);
  const curRef = containerRef.current;
  // const clientWidth = Math.max(300, curRef?.clientWidth ?? 0);
  const endAt = Math.max(0, numItems - 1);
  const visibleIndexes = Array.from(
    Array(itemsPerPage),
    (_, i) => i + itemIndex,
  );
  // const numItemsVisible = Math.min(
  //   itemsPerPage,
  //   Math.max(1, numItems - itemIndex),
  // );
  const [mouseDown, setMouseDown] = React.useState(0);
  const dimensions = useContainerDimensions(curRef, {
    width: 240,
    height: 200,
  });
  const clientWidth = dimensions.width;
  const hidePagination = !!props.hidePagination || dimensions.height < 100;

  const firstChild =
    numItems > 0 ? (curRef?.children[0] as HTMLDivElement) : undefined;
  const realItemWidth = Math.ceil(firstChild?.offsetWidth ?? 0);
  React.useEffect(() => {
    if (realItemWidth > 0) {
      // setItemWidth(realItemWidth);
      setItemsPerPage(Math.ceil(clientWidth / realItemWidth));
    }
  }, [realItemWidth, clientWidth]);

  function nextPage(num: number) {
    const ii = Math.max(0, Math.min(itemIndex + num, endAt));
    setItemIndex(ii);
  }

  function updateMouseDown(num: number) {
    setMouseDown(num);
  }

  React.useEffect(() => {
    if (mouseDown !== 0) {
      const to = setTimeout(
        () => {
          nextPage(mouseDown < 0 ? -1 : 1);
          updateMouseDown(mouseDown < 0 ? mouseDown - 1 : mouseDown + 1);
        },
        Math.abs(mouseDown) === 1 ? 1000 : 30,
      );
      return () => {
        clearTimeout(to);
      };
    }
  }, [mouseDown, itemIndex]);

  React.useEffect(() => {
    // curRef?.scrollTo({ left: itemIndex * itemWidth });
    setAtStart(itemIndex === 0);
    setAtEnd(itemIndex >= endAt);
  }, [itemIndex, endAt]);

  return (
    <React.Fragment>
      <div
        ref={containerRef}
        // onScroll={(e) => updatePrevNext()}
        className={clsx(className, {
          [classes.scrollH]: true,
        })}
        style={style}>
        {visibleIndexes.map((pos, idx) => {
          if (idx >= numItems) return null;
          return renderItem(pos, idx);
        })}
        {children ?? <></>}
      </div>
      <div className={clsx(classes.page, classes.pagePrev)}>
        {!atStart && (
          <Button
            variant={'text'}
            onClick={() => nextPage(-1)}
            onMouseDown={() => updateMouseDown(-1)}
            onMouseUp={() => updateMouseDown(0)}
            onMouseLeave={() => updateMouseDown(0)}
            disabled={atStart}
            style={{ border: 'none' }}
            className={clsx(common.actionButton, classes.button, {
              [classes.hidden]: atStart,
            })}>
            <SvgCaret
              fill={'#FFF'}
              style={{ maxWidth: btnSize, transform: 'scale(-1, 1)' }}
            />
          </Button>
        )}
      </div>
      <div className={clsx(classes.page, classes.pageNext)}>
        {!atEnd && (
          <Button
            variant={'text'}
            onClick={() => nextPage(1)}
            disabled={atEnd}
            onMouseDown={() => updateMouseDown(1)}
            onMouseUp={() => updateMouseDown(0)}
            onMouseLeave={() => updateMouseDown(0)}
            style={{ border: 'none' }}
            className={clsx(common.actionButton, classes.button, {
              [classes.hidden]: atEnd,
            })}>
            <SvgCaret fill={'#FFF'} style={{ maxWidth: btnSize }} />
          </Button>
        )}
      </div>
      {numPages > 1 && !hidePagination && (
        <div className={classes.scroller}>
          <Pagination
            // key={`page-${p}`}
            count={numPages}
            page={curPageNum + 1}
            onChange={(e: React.ChangeEvent<unknown>, p: number) => {
              if (p === null) return;
              setItemIndex((p - 1) * itemsPerPage);
            }}
          />
          {/*{pages.map(p => {*/}
          {/*  return <Pagination*/}
          {/*    key={`page-${p}`}*/}
          {/*    count={numPages}*/}
          {/*    page={curPageNum}*/}
          {/*    onChange={(e: React.ChangeEvent<unknown>, p: number) => setItemIndex(p * itemsPerPage)}*/}
          {/*  />;*/}
          {/*})}*/}
        </div>
      )}
    </React.Fragment>
  );
};

export default HorizontalScroller;
