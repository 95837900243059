import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import { useWalletSelector } from '../../hooks';

import useCommonStyles from '../../components/useCommonStyles';
import AccountHeader from '../../AccountHeader';
import GameStats from './GameStats';
import MyFurballsList from './MyFurballsList';
import FullDrawer from '../../components/Drawer/FullDrawer';
import FurballSortBySelect, {
  SELECT_OPTIONS,
} from '../../components/FurballSortBySelect';
import { usePlayersReadyFurballs } from '../../wallet';
import { useWindowSize } from '../../components/useWindowSize';
import SvgZoneIconsInventory from '../../assets/SvgZoneIconsInventory';
import Inventory from '../../components/Inventory/Inventory';
import { usePageTitle } from '../../Analytics';
import SvgZoneIconsFurballs from '../../assets/SvgZoneIconsFurballs';
import { PayoutMethod, useTakePayoutMutation } from '../../components/schema';
import { ALERT_TYPES, AlertContext } from '../../components/Alert/AlertContext';
import PlayerRewards from './PlayerRewards';
import { yellow } from '../../themev2';
import SvgIconHistory from '../../assets/SvgIconHistory';
import { TransactionHistory } from './TransactionHistory';
import Quests from '../../components/Quests/Quests';
import SvgZoneIconsQuests from '../../assets/SvgZoneIconsQuests';
import { useFurverseContext } from '../Furverse/Furverse';
import NotificationsList from '../../components/Notifications/NotificationsList';

const useStyles = makeStyles((theme: Theme) => ({
  action: {
    display: 'inline !important',
  },
  main: {
    textAlign: 'center',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    marginTop: '0 !important',
    backgroundColor: theme.palette.secondary.light,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
  },
  cardContent: {
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      padding: '0.05rem !important',
    },
  },
  fbList: {
    marginTop: '3.25rem',

    [theme.breakpoints.down('sm')]: {
      margin: '3.7rem 1rem 1rem 1rem',
    },
  },
  rentedWrapper: {
    margin: '1rem',
    marginTop: '2rem',

    '& h6': {
      fontSize: '1.55rem !important',
      padding: '0.2rem 1rem',
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
      backgroundColor: '#18181b',
      color: yellow,
      display: 'flex',

      '& span': {
        marginLeft: 'auto',
      },
    },
  },

  tabLabel: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  rentedCont: {
    border: '2px solid #18181b',
    borderTop: 'none',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
  },

  sortBy: {
    transformOrigin: 'center left',
  },
}));

interface IUnknown {
  welcomeSource?: string;
}

type Props = IUnknown;

interface ITabOpt {
  value: string;
  label: string;
  icon: React.ReactNode;
}

export const Account: FunctionComponent<Props> = (props) => {
  const { welcomeSource } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const isDesktop = useWindowSize().width > 600;
  const account = useWalletSelector((s) => s.contents);
  const bossBattleCount = account?.bossBattleCount ?? 0;
  const payouts = account?.payouts ?? [];
  const [takePayout, { loading, data }] = useTakePayoutMutation();
  const { addAlert } = React.useContext(AlertContext);
  const { vuplexReady, sendGameMessage } = useFurverseContext();

  const furballs = usePlayersReadyFurballs();
  const rentalFurballs = furballs.filter((item) => {
    const agreement = item.activeRentalAgreement;
    return agreement?.ownerPlayer.id === account?.id;
  });

  const hasRentalContract =
    rentalFurballs.length > 0 ||
    !!furballs.find((f) => !!f.openRentalAgreement);

  const [sortBy, setSortBy] = useState(SELECT_OPTIONS[0].value);
  const [selectedTab, setSelectedTab] = useState<string>('0');

  const tabOptions = React.useMemo(() => {
    const ret: { [key: string]: ITabOpt } = {
      '0': { value: '0', label: 'Furballs', icon: <SvgZoneIconsFurballs /> },
      '1': { value: '1', label: 'Items', icon: <SvgZoneIconsInventory /> },
      // '2': { value: '2', label: 'Crafting', icon: <SvgZoneIconsCraft /> },
      // '2': { value: '2', label: 'Rewards', icon: <SvgZoneIconsLeaderboards /> },
      '2': { value: '2', label: 'Quests', icon: <SvgZoneIconsQuests /> },
      '3': {
        value: '3',
        label: 'Transactions',
        icon: <SvgIconHistory />,
      },
    };
    return ret;
  }, []);

  async function choosePayoutMethod(
    id: string,
    method: PayoutMethod,
  ): Promise<void> {
    try {
      const { data } = await takePayout({ variables: { id, method } });
      window.location.reload();
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Payout processed',
      });
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to receive payout',
      });
    }
  }

  function getPageTitle(tabValue: string) {
    const group = welcomeSource ? 'Welcome' : 'Account';
    const section = tabOptions[tabValue]?.label ?? '';
    return section + ' | ' + group;
  }

  usePageTitle(getPageTitle(selectedTab));

  function renderTabSection() {
    if (selectedTab === '0') {
      return (
        <div className={classes.fbList}>
          <MyFurballsList
            furballs={furballs}
            sortBy={sortBy}
            showStats={true}
            onAccountPage={true}
          />
        </div>
      );
    }

    if (selectedTab === '1') return <Inventory />;
    if (selectedTab === '2') return <PlayerRewards />;
    if (selectedTab === '3') return <Quests />;
    if (selectedTab === '4') return <TransactionHistory />;
    return null;
  }

  const realPayouts = payouts.filter((po) => !po.transactionId);

  function renderDrawerChildren() {
    // const noActiveRentals = rentalFurballs.length === 0;
    return (
      <>
        <GameStats />
        <NotificationsList />
        {vuplexReady && (
          <Button
            variant={'contained'}
            className={common.actionButton}
            onClick={() =>
              sendGameMessage({
                topic: 'RequestNotificationPermissions',
                data: {},
              })
            }>
            <Typography variant={'h5'}>Request Push Permissions</Typography>
          </Button>
        )}
        {realPayouts.length > 0 && (
          <Paper style={{ margin: 20, padding: 20 }}>
            <Typography variant={'h5'}>Pending Payout</Typography>
            {realPayouts.map((p) => {
              return (
                <div key={p.id}>
                  You are owed: {p.ethAmount} ETH
                  <br />
                  {p.payoutMethod === PayoutMethod.Eth && (
                    <Typography variant={'subtitle2'}>
                      ETH transaction is pending...
                    </Typography>
                  )}
                  {p.payoutMethod === PayoutMethod.Undecided && (
                    <>
                      <Typography variant={'subtitle2'}>
                        If you choose to receive TIX, you will receive an extra
                        +10% and they will appear instantly. ETH transactions
                        are Net 30 (payments made every 30 days), and will not
                        be paid until you have a balance of at least 0.02 ETH.
                      </Typography>
                      <br />
                      <Button
                        variant={'contained'}
                        color={'secondary'}
                        disabled={loading}
                        onClick={() =>
                          choosePayoutMethod(p.id, PayoutMethod.Eth)
                        }>
                        Choose ETH
                      </Button>
                      &nbsp;&nbsp;
                      <Button
                        variant={'contained'}
                        color={'primary'}
                        disabled={loading}
                        onClick={() =>
                          choosePayoutMethod(p.id, PayoutMethod.Tix)
                        }>
                        Choose TIX
                      </Button>
                    </>
                  )}
                </div>
              );
            })}
          </Paper>
        )}
        {/*<div className={classes.rentedWrapper}>*/}
        {/*  <Typography variant={'h6'}>*/}
        {/*    Furballs Rented Out{' '}*/}
        {/*    {!noActiveRentals && <span>(x{rentalFurballs.length})</span>}*/}
        {/*  </Typography>*/}
        {/*  <div className={classes.rentedCont}>*/}
        {/*    {rentalFurballs.map((furball, index) => (*/}
        {/*      <ScholarshipRewardSummary furball={furball} key={index} />*/}
        {/*    ))}*/}
        {/*    {noActiveRentals && (*/}
        {/*      <Grid*/}
        {/*        container*/}
        {/*        alignItems='center'*/}
        {/*        justifyContent='center'*/}
        {/*        padding='1.5rem 2rem'>*/}
        {/*        <Typography variant={'h5'}>No Active Rentals!</Typography>*/}
        {/*        <Typography*/}
        {/*          variant='body1'*/}
        {/*          style={{*/}
        {/*            textAlign: 'center',*/}
        {/*            fontSize: '0.75rem',*/}
        {/*            marginTop: '0.5rem',*/}
        {/*          }}>*/}
        {/*          {hasRentalContract*/}
        {/*            ? 'When a Furball is rented, it will appear here.'*/}
        {/*            : 'Use the Market to place Furballs for rent.'}*/}
        {/*        </Typography>*/}
        {/*      </Grid>*/}
        {/*    )}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    );
  }

  function renderChildren() {
    return (
      <Grid container className={classes.main} spacing={2}>
        <AccountHeader showEditUsername={true} />
        <>
          {/*{account && <BossBattleHistory onAccountPage={true} />}*/}
          <Grid item xs={12}>
            <Card className={common.cardRoot}>
              <CardHeader
                className={common.cardHeader}
                classes={{ title: common.cardTitle }}
                sx={{ minHeight: '62px' }}
                title={'My Stuff'}
                action={
                  selectedTab === '0' && (
                    <FurballSortBySelect
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      minWidth={15}
                      offsetRight={'0.5rem'}
                      smallSelect={true}
                      className={classes.sortBy}
                    />
                  )
                }
              />
              <CardContent
                className={classes.cardContent}
                style={{ minHeight: '490px' }}>
                <Tabs
                  variant='scrollable'
                  value={selectedTab}
                  className={common.tabs}
                  onChange={(e, v) => setSelectedTab(v)}
                  textColor='secondary'
                  indicatorColor='secondary'
                  TabIndicatorProps={{ style: { height: '4px' } }}>
                  {Object.keys(tabOptions).map((tabKey) => {
                    const opt = tabOptions[tabKey];
                    return (
                      <Tab
                        key={tabKey}
                        className={common.tab}
                        color={'secondary'}
                        label={
                          <div className={common.tabLabel}>
                            {opt.icon}
                            <span className={classes.tabLabel}>
                              {opt.label}
                            </span>
                          </div>
                        }
                        value={tabKey}
                      />
                    );
                  })}
                </Tabs>
                {renderTabSection()}
              </CardContent>
            </Card>
          </Grid>

          {/*{bossBattleCount === 0 && (*/}
          {/*  <Grid item xs={12}>*/}
          {/*    <Link to={'/boss/onboard'}>*/}
          {/*      <Typography*/}
          {/*        variant={'h2'}*/}
          {/*        style={{ color: 'white', textDecoration: 'underline' }}>*/}
          {/*        Play Your first Boss Game*/}
          {/*      </Typography>*/}
          {/*    </Link>*/}
          {/*  </Grid>*/}
          {/*)}*/}
        </>
      </Grid>
    );
  }

  return (
    <FullDrawer drawer={renderDrawerChildren()}>{renderChildren()}</FullDrawer>
  );
};

export default Account;
