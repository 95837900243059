import { FurOAuthTokenInput } from '../schema';
import { IFurballContext } from '../../wallet/WalletTypes';
import { getSignatureDomain } from '../../blockchain';
// import { FurgreementDomain, TimekeeperRequestSigType } from '../Timekeeper/FurgreementSig';
import WalletContext from '../../wallet/WalletContext';
import { useWalletSelector } from '../../hooks';
import React from 'react';
// import TimekeeperSlice from '../Timekeeper/TimekeeperSlice';

export const TokenDomain = {
  name: 'FurballsLogin',
  version: '1',
  // "chainId": 31337,
  // "verifyingContract": context.addresses.furgreement,
};

export const TokenSigType = {
  OAuthToken: [
    { name: 'owner', type: 'address' },
    { name: 'access', type: 'uint32' },
    { name: 'deadline', type: 'uint64' },
  ],
};

export type OAuthAction = (token: FurOAuthTokenInput) => Promise<void>;

export type OAuthRequester = () => Promise<void>;

export function useOAuthRequest(
  action: OAuthAction,
  access?: number,
): OAuthRequester {
  const context = React.useContext(WalletContext);
  const walletAddress = useWalletSelector((w) => w.address);

  return async () => {
    if (!walletAddress || !context) return;
    try {
      const token = await signOAuthToken(walletAddress, context, access);
      await action(token);
    } catch (e) {
      console.error(e, 'Encounter');
    }
  };
}

export async function signOAuthToken(
  walletAddress: string,
  context: IFurballContext,
  access?: number,
): Promise<FurOAuthTokenInput> {
  const domain = getSignatureDomain(
    TokenDomain,
    context.network.deployment?.addresses.oauth,
    context,
  );
  const token = {
    owner: walletAddress,
    access: access === undefined ? 0 : access,
    deadline: Math.round(new Date().getTime() / 1000) + 3600 * 24 * 7,
  };
  const signature = await context.provider
    .getSigner()
    ._signTypedData(domain, TokenSigType, token);
  return { ...token, signature, id: '' };
  //
  // const data = JSON.stringify({
  //   types: {
  //     ...domainType,
  //     ...TokenSigType,
  //   },
  //   domain: domain,
  //   primaryType: Object.keys(TokenSigType)[0],
  //   message: token,
  // });
  //
  // const signature = await context.provider.send("eth_signTypedData_v4", [walletAddress, data]);
  //
  // return { ...token, signature, id: '' };
  // return [{
  //   gained: input.gained,
  //   mintEdition: input.mintEdition,
  //   mintCount: input.mintCount,
  //   sender,
  //   spent: input.spent,
  //   fuelCost: input.fuelCost,
  //   deadline,
  //   movementList: input.movementList,
  //   collectStayList: input.collectStayList,
  // }, signature];
}
