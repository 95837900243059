import * as React from 'react';

function SvgTix(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill ?? '#fde99a';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 44.31 44.31'
      {...props}>
      <g id='tix_svg__Layer_2' data-name='Layer 2'>
        <g id='tix_svg__tix'>
          <path
            fill={fill}
            d='M36.56 19.59l-17 17 7.75 7.75 4-4a6.34 6.34 0 019-9l4-4zM17 0l-4 4a6.34 6.34 0 01-9 9l-4 4 17.05 17 17-17z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgTix;
