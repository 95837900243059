import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  Typography,
  Theme,
  Grid,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FullDrawer from '../../../components/Drawer/FullDrawer';
import LootFilters from './LootFilters';
import {
  ItemDefinitionFragment,
  ItemListingFragment,
  LootMarketplaceDocument,
  MyLootListingsDocument,
  useLootMarketplaceLazyQuery,
  useMyLootListingsLazyQuery,
} from '../../../components/schema';

import LootListingDialog from './LootListingDialog';
import AlertProvider from '../../../components/Alert/AlertProvider';
import { TabContext, TabPanel } from '@mui/lab';
import { useAuthContext } from '../../../components/Auth/AuthContext';
import LootListing from './LootListing';
import PurchaseLootListingDialog from './PurchaseLootListingDialog';
import { useAppDispatch, useWalletSelector } from '../../../hooks';
import { useApolloClient } from '@apollo/client';
import WalletSlice from '../../../wallet/WalletSlice';
import useCommonStyles from '../../../components/useCommonStyles';
import clsx from 'clsx';
import { useStyles as useRentStyles } from '../Rent/RentMarket';
import { usePageTitle } from '../../../Analytics';
import { useParams } from 'react-router-dom';
import LootMarketSelection from './LootMarketSelection';
import { itemLibrary } from '../../../components/Inventory/ItemLibrary';
import { slugify } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    color: 'white',
  },

  listingButton: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.75rem !important',
    },
  },

  headSection: {
    padding: '1rem 0',
    width: 'calc(100% - 16px) !important',
    zIndex: 5,

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '16px',
    },
  },

  tabs: {
    zIndex: 2,

    '& .MuiTabs-indicator': {
      height: '3px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: '60px',
    },
  },

  tab: {
    border: 'none !important',
    color: '#978EE4 !important',
    fontSize: '1rem !important',

    '&.Mui-selected': {
      color: `${theme.palette.common.white} !important`,
    },
  },

  tabPanel: {
    width: '100%',
    padding: '0px !important',
    paddingRight: '14px !important',
    paddingLeft: '14px !important',
  },

  results: {
    width: '100%',
    marginTop: '1rem',
    display: 'grid',
    gap: '1.5rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(10rem, 1fr))',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gap: '0.5rem',
    },
  },
}));

interface IParams {
  section?: string;
  lootId?: string;
}

interface IMarketLoot {}

export const LootMarket: FunctionComponent<IMarketLoot> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const rentStyles = useRentStyles();

  const [tab, setTab] = useState<'1' | '2'>('1');
  const { lootId, section } = useParams<IParams>();
  const [listingDialogOpen, setListingDialogOpen] = useState(false);
  const [purchasing, setPurchasing] = useState<ItemListingFragment>();
  const [editingListing, setEditingListing] = useState<ItemListingFragment>();
  const [filteredItems, setFilteredItems] = useState<ItemDefinitionFragment[]>(
    [],
  );
  const linkedItemDef = itemLibrary.find((i) => slugify(i.name) === lootId);

  const [search, searchLoader] = useLootMarketplaceLazyQuery();
  const [myListings, myListingsLoader] = useMyLootListingsLazyQuery();

  const dispatch = useAppDispatch();
  const { isSignedIn } = useAuthContext();

  const playerId = useWalletSelector((c) => c.contents?.id ?? '');

  const searchResults = searchLoader.data?.itemListings?.nodes ?? [];
  const loading = searchLoader.loading || myListingsLoader.loading;
  const myItemListings = myListingsLoader.data?.myItemListings ?? [];
  const client = useApolloClient();

  const listings = React.useMemo(() => {
    return tab === '2' ? myItemListings : searchResults;
  }, [tab, myItemListings, searchResults]);

  const tabOptions = React.useMemo(() => {
    const ret = [{ value: '1', label: 'Browse' }];
    if (isSignedIn) {
      ret.push({ value: '2', label: 'My Listings' });
    }
    return ret;
  }, [isSignedIn]);

  function getSectionName(tabValue: string): string {
    if (purchasing) return 'Purchasing';
    if (listingDialogOpen) return editingListing ? 'Editing' : 'Creating';
    if (tabValue === '1' && linkedItemDef) return linkedItemDef.name;
    return tabOptions.find((t) => t.value === tabValue)?.label ?? '';
  }

  function getPageTitle(tabValue: string) {
    const section = !lootId ? 'Select' : getSectionName(tabValue);
    return section + ' | Loot Market';
  }

  usePageTitle(getPageTitle(tab));

  function onSearchFiltersChanged(items: ItemDefinitionFragment[]) {
    setFilteredItems(items);
  }

  React.useEffect(() => {
    const itemIds = filteredItems.map((i) => i.id);
    search({ variables: { itemIds } });
  }, [filteredItems]);

  React.useEffect(() => {
    if (tab === '2') {
      myListings();
    }
  }, [tab]);

  React.useEffect(() => {
    if (section === 'browse') setTab('1');
    else if (section === 'mine') setTab('2');
    else if (section === 'new') setListingDialogOpen(true);

    if (lootId) {
      setFilteredItems(
        itemLibrary.filter((i) => slugify(i.name).includes(lootId)),
      );
    }
  }, [section, lootId]);

  function reloadResults() {
    void client.refetchQueries({
      include: [LootMarketplaceDocument, MyLootListingsDocument],
    });
  }

  function onPurchased(l: ItemListingFragment): void {
    reloadResults();
    if (!l.buyer) {
      console.warn('purchased listing; no buyer update?', l);
      return;
    }
    if (l.buyer) dispatch(WalletSlice.actions.setWFurBalance(l.buyer.wFur));
    dispatch(WalletSlice.actions.setInventory(l.buyer.inventory));
  }

  function onClosedEdit() {
    setListingDialogOpen(false);
    setEditingListing(undefined);
    reloadResults();
  }

  function onClickListing(listing: ItemListingFragment): void {
    if (listing.seller.id === playerId) {
      setEditingListing(listing);
      setListingDialogOpen(true);
    } else {
      setPurchasing(listing);
    }
  }

  if (!section) return <LootMarketSelection />;

  return (
    <FullDrawer
      drawer={
        <LootFilters
          allowedItems={filteredItems}
          setAllowedItems={onSearchFiltersChanged}
        />
      }>
      {loading ? (
        <div className={clsx(rentStyles.loader, common.fullHeight)}>
          <CircularProgress size='2.5rem' color='inherit' />
        </div>
      ) : (
        !loading &&
        listings.length === 0 && (
          <div className={clsx(rentStyles.loader, common.fullHeight)}>
            <Typography>No Results</Typography>
          </div>
        )
      )}

      <AlertProvider>
        <TabContext value={tab}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            rowGap='1rem'
            flexWrap='nowrap'
            className={classes.headSection}>
            <Tabs
              value={tab}
              indicatorColor='secondary'
              textColor='secondary'
              onChange={(e, value) => setTab(value)}
              aria-label='furballs tabs'
              className={classes.tabs}>
              <Tab label='Browse' value='1' className={classes.tab} />
              {isSignedIn && (
                <Tab label='My Listings' value='2' className={classes.tab} />
              )}
            </Tabs>

            <Grid
              item
              container
              width='fit-content !Important'
              alignItems='center'
              gap='1rem'>
              {isSignedIn && (
                <Button
                  variant='contained'
                  className={classes.listingButton}
                  disabled={listingDialogOpen}
                  onClick={() => setListingDialogOpen(true)}
                  startIcon={
                    <Typography variant='h5' lineHeight='0'>
                      +
                    </Typography>
                  }>
                  New Listing
                </Button>
              )}
            </Grid>
          </Grid>

          <TabPanel className={classes.tabPanel} value='1'>
            {!loading && (
              <div className={classes.results}>
                {searchResults.map((r) => {
                  return (
                    <LootListing
                      key={r.id}
                      listing={r}
                      purchase={onClickListing}
                    />
                  );
                })}
              </div>
            )}
            <LootListingDialog
              open={listingDialogOpen}
              close={onClosedEdit}
              listing={editingListing}
            />
          </TabPanel>
          <TabPanel className={classes.tabPanel} value='2'>
            {!loading && (
              <div className={classes.results}>
                {myItemListings.map((r) => {
                  return (
                    <LootListing
                      key={r.id}
                      listing={r}
                      purchase={onClickListing}
                    />
                  );
                })}
              </div>
            )}
            <LootListingDialog
              open={listingDialogOpen}
              close={onClosedEdit}
              listing={editingListing}
            />
          </TabPanel>
        </TabContext>

        <PurchaseLootListingDialog
          listing={purchasing}
          setListing={setPurchasing}
          onPurchased={onPurchased}
        />
      </AlertProvider>
    </FullDrawer>
  );
};
