import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StatEntity } from '../../../components/schema';
import FurballHead from '../../../components/Furballs/FurballHead';
import { useRoundOutPartialPartyContext } from './RoundOutPartialPartyContext';
import { PartySize } from '../../Furverse/useBossBattle';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import FtuePopout from '../../../components/FtuePopout';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '150px',
    height: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  select: {
    minWidth: '303px',
    maxWidth: '303px',
    borderRadius: '6px !important',

    '& > div': {
      border: `2px solid ${theme.palette.common.black} !important`,
      backgroundColor: '#784DFF !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '6px !important',
      paddingRight: (props: any) =>
        props.onboard ? '10px !important' : '32px !important',

      '& [data-icons]': {
        display: 'none !important',
      },
    },

    '& p': {
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.common.white,
      display: (props: any) => (props.onboard ? 'none' : 'block'),
    },
  },

  selectDropdown: {
    marginTop: '3px',
    borderRadius: '6px !important',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px !important',

    '& ul': {
      padding: '0 !important',
    },
  },

  selectMenuItem: {
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    padding: '12px !important',
    borderBottom: '1px solid #f5f5f5 !important',

    '&:focus': {
      backgroundColor: 'transparent !important',
    },

    '&:hover': {
      backgroundColor: '#eee !important',
    },
  },

  disabledItem: {
    backgroundColor: '#eee !important',
    opacity: '1 !important',
  },

  fbHeads: {
    display: 'flex',
    maxHeight: '22px',
  },

  empty: {
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    marginLeft: '4px',
    marginTop: '2px',
  },

  info: {
    marginTop: '5px !important',
    textAlign: 'center',
    fontSize: '0.7rem !important',
    opacity: 0.6,
  },

  actionButton: {
    borderRadius: '0 !important',
    border: 'none !important',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',

    '& p': {
      fontSize: '0.9rem !important',
      marginTop: '2px !important',
    },
  },

  cancelButton: {
    backgroundColor: '#fecdd3 !important',
    color: '#f43f5e !important',
  },

  ctaText: {
    fontSize: '0.9rem !important',
  },

  hidden: {
    display: 'none !important',
  },
}));

interface IRoundOutPartialParty {
  partyId: string | undefined;
  realm: string | undefined;
  onboard: boolean;
}

export const RoundOutPartialParty: FunctionComponent<IRoundOutPartialParty> = (
  props,
) => {
  const { partyId, realm, onboard } = props;
  const classes = useStyles({ onboard });
  const history = useHistory();

  const {
    selectedPartyId,
    setSelectedPartyId,
    unfilledParties,
    selectedPartySize,
  } = useRoundOutPartialPartyContext();
  const value = !!selectedPartyId ? selectedPartyId : 'select_party';

  const [openSelect, setOpenSelect] = useState<boolean>(false);
  const [showCancelBtn, setShowCancelBtn] = useState<boolean>(!!partyId);

  useEffect(() => {
    if (!unfilledParties) return;

    setSelectedPartyId(partyId ?? '');

    // remove cancel party btn after menu transition is over
    const timeout = setTimeout(() => {
      setShowCancelBtn(!!partyId);
    }, 600);

    return () => clearTimeout(timeout);
  }, [partyId, unfilledParties]);

  function renderFurballHeads(partyMembers: StatEntity[], size: number) {
    const heads = partyMembers.map(({ id }) => (
      <FurballHead key={`fb-unfilled-${id}`} tokenId={id} size={25} />
    ));

    while (heads.length < Math.max(size, PartySize.FIVE)) {
      heads.push(
        <div key={`empty-${heads.length}`} className={classes.empty} />,
      );
    }

    return heads;
  }

  function render() {
    if (!unfilledParties) {
      return <CircularProgress size={20} color={'secondary'} />;
    }

    if (unfilledParties.length > 0) {
      const msg =
        selectedPartySize > 0 && selectedPartySize < 3
          ? 'Keep borrowing until you have 3'
          : undefined;

      return (
        <FtuePopout message={msg} placement={'bottom'}>
          <FormControl size='small'>
            <Select
              labelId='party'
              id='party'
              color='secondary'
              open={openSelect}
              onOpen={() => setOpenSelect(true)}
              onClose={() => setOpenSelect(false)}
              className={classes.select}
              disabled={onboard}
              MenuProps={{
                classes: {
                  paper: classes.selectDropdown,
                },
              }}
              value={value}
              defaultValue={'select_party'}
              onChange={(e) => {
                setSelectedPartyId(e.target.value);
                history.push(
                  `/market/furballs/${e.target.value}${
                    !!realm ? `/${realm}` : ''
                  }`,
                );
              }}>
              <MenuItem
                key={'select_party'}
                value={'select_party'}
                className={clsx(classes.selectMenuItem, classes.hidden)}>
                <Typography>Select a boss party</Typography>
              </MenuItem>
              {unfilledParties.map((party) => (
                <MenuItem
                  key={party.id}
                  value={party.id}
                  disabled={party.id === selectedPartyId}
                  className={clsx(classes.selectMenuItem, {
                    [classes.disabledItem]: party.id === selectedPartyId,
                  })}>
                  <Typography>{party.name}</Typography>
                  <div className={classes.fbHeads}>
                    {renderFurballHeads(
                      party.partyMembers as StatEntity[],
                      party.size,
                    )}
                  </div>
                </MenuItem>
              ))}
              {showCancelBtn && (
                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  gap='0.3rem'>
                  <Button
                    fullWidth
                    variant='contained'
                    className={clsx(classes.actionButton, classes.cancelButton)}
                    onClick={() => {
                      history.push('/market/furballs');
                      setOpenSelect(false);
                    }}>
                    <Typography fontWeight='600' className={classes.ctaText}>
                      Cancel Party Auto Add
                    </Typography>
                  </Button>
                </Grid>
              )}
              <Grid
                container
                alignItems='center'
                justifyContent='center'
                gap='0.3rem'>
                <Button
                  fullWidth
                  variant='contained'
                  className={classes.actionButton}
                  onClick={() => history.push('/market/furballs/create')}>
                  <Typography fontWeight='600' className={classes.ctaText}>
                    Create new boss party
                  </Typography>
                </Button>
              </Grid>
            </Select>
          </FormControl>
        </FtuePopout>
      );
    }

    return (
      <Button
        variant={'contained'}
        onClick={() => history.push('/market/furballs/create')}>
        <Typography fontWeight='600' className={classes.ctaText}>
          Create new boss party
        </Typography>
      </Button>
    );
  }

  return (
    <Grid item>
      <div className={classes.root}>{render()}</div>
    </Grid>
  );
};

export default RoundOutPartialParty;
