import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Gorilla = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M147.55 131.16s-13.77-17.66-23.15-2.66c-9.83 15.72 14.12 26.09 14.12 26.09'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M144.99 129.2s-11.21-15.7-20.59-.7c-9.83 15.72 10.77 23.67 10.77 23.67'
    />
    <path
      fill='#ffafab'
      d='M139.36 141.97c5.64-5.37-5.64-11.38-5.64-11.38s-4.82.72-4.99 4.95c-.14 3.43 5.69 11.13 10.63 6.43z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M130.1 131.52s8.42-5.76 10.1 9.15'
    />
    <path
      fill={props.renderer.color1}
      d='M245.27 131.16s13.77-17.66 23.15-2.66c9.83 15.72-14.12 26.09-14.12 26.09'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M247.83 129.54s11.21-16.05 20.59-1.04c9.83 15.72-10.77 23.67-10.77 23.67'
    />
    <path
      fill='#ffafab'
      d='M253.46 141.97c-5.64-5.37 5.46-11.38 5.46-11.38s5.01.72 5.17 4.95c.14 3.43-5.69 11.13-10.63 6.43z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M262.72 131.52s-8.42-5.76-10.1 9.15'
    />
  </svg>
);

export default Gorilla;
