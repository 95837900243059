import * as React from 'react';

function SvgIconClock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' {...props}>
      <circle cx={9.89} cy={12.66} r={7.26} fill='#fff' />
      <path d='M11.35 3.42V3c.35-.26.55-.63.55-1.06 0-.91-.9-1.65-2-1.65s-2 .74-2 1.65c0 .43.2.8.55 1.06v.45C4.11 4.19.8 7.96.8 12.51c0 5.08 4.12 9.2 9.2 9.2 5.08 0 9.2-4.12 9.2-9.2 0-4.62-3.41-8.43-7.85-9.09zM10 19.69c-3.94 0-7.14-3.2-7.14-7.14 0-3.94 3.2-7.14 7.14-7.14 3.94 0 7.14 3.2 7.14 7.14 0 3.95-3.2 7.14-7.14 7.14z' />
      <path d='M13.52 15.26l-.59-.05-1.81-2.14c.09-.17.14-.36.14-.56 0-.53-.33-.98-.8-1.17V9.26l.38-.38a.28.28 0 00.05-.29l-.64-1.39a.253.253 0 00-.23-.15c-.1 0-.19.06-.23.15l-.64 1.39c-.05.1-.03.21.05.29l.38.38v2.08a1.25 1.25 0 00.41 2.43c.16 0 .31-.03.45-.09l1.79 2.12-.05.6c-.01.11.05.21.15.25l1.51.71c.03.02.07.02.11.02.06 0 .12-.02.17-.06.08-.07.11-.17.08-.27l-.45-1.61a.274.274 0 00-.23-.18z' />
    </svg>
  );
}

export default SvgIconClock;
