import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme } from '@mui/material';
import SvgIconLeaderboardTiers from '../../../assets/SvgIconLeaderboardTiers';
import SvgIconLeaderboardRewards from '../../../assets/SvgIconLeaderboardRewards';
import { bodyFont, headerFont, white } from '../../../themev2';
import { cdnRoot } from '../../../theme';
import {
  ItemGroup,
  LeaderboardTierRewardChanceFragment,
  RarityLevel,
  TierRewardsFragment,
  TierRewardType,
} from '../../schema';
import { getItemDef } from '../BossRewards/bossRewardsUtils';
import { itemLibrary } from '../../Inventory/ItemLibrary';
import { getBossNameFromRealm } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  tier: {
    marginTop: '15px',
    marginBottom: (props: any) => (props.lastTier ? '0' : '15px'),
  },
  tierHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '5px',
    fontSize: '14px',
    fontWeight: 700,
    textTransform: 'uppercase',
    color: '#636262',
  },
  tierPlaceholderHeaderItem: {
    minWidth: '60px',
    minHeight: '15px',
    borderRadius: '20px',
    backgroundColor: '#eee',
  },
  rewardToggle: {
    border: 'none !important',
    margin: '0 !important',
  },
  rewards: {
    minHeight: '220px',
    // maxHeight: '380px',
    padding: '20px 10px',
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.light,
    color: white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rewardItems: {
    minWidth: '100%',
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
  },
  rewardItem: {
    width: '85px',
    height: '85px',
    backgroundColor: '#D1CEFF',
    border: '6px solid #AFABFF',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 5px',

    [theme.breakpoints.down('sm')]: {
      width: '75px',
      height: '75px',
    },

    ['@media (max-width: 385px)']: {
      margin: '0 2.5px',
    },
  },
  rewardText: {
    textAlign: 'center',
    fontSize: '24px',
    fontFamily: headerFont,
    margin: '0 0 8px 0',

    '& span': {
      display: 'block',
      lineHeight: '10px',
      fontSize: '14px',
      fontFamily: `${bodyFont} !important`,
      fontWeight: 100,
      opacity: 0.7,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',

      '& span': {
        fontSize: '12px !important',
      },
    },
  },
  rewardHeader: {
    fontSize: '24px',
    fontWeight: 700,
    margin: '5px 0',
    fontFamily: headerFont,
  },
  tierPlaceholder: {
    borderRadius: '8px',
    minHeight: '175px',
    minWidth: '100%',
    marginBottom: '25px',

    '& div': {
      minHeight: '70px',
      backgroundColor: '#eee',
      borderRadius: '6px',
      margin: '7.5px',
    },
  },
  rewardItemCont: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '115px',

    '& img': {
      width: 58,
      height: 58,
    },

    [theme.breakpoints.down('sm')]: {
      width: '85px',

      '& img': {
        width: 50,
        height: 50,
      },
    },
  },
}));

interface ITier {
  tier: number;
  players: JSX.Element;
  isInTier: boolean;
  boss: string;
  rewards: TierRewardsFragment;
  lastTier: boolean;
  playerCount: number;
}

export const TierPlaceholder: FunctionComponent = () => {
  const classes = useStyles({});

  return (
    <div>
      <div className={classes.tier} style={{ margin: '15px 10px' }}>
        <div className={classes.tierHeader} style={{ padding: '0' }}>
          <span className={classes.tierPlaceholderHeaderItem} />
          <span className={classes.tierPlaceholderHeaderItem} />
        </div>
      </div>
      <div className={classes.tierPlaceholder}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

interface IReward {
  icon: string;
  title: string;
  description: string;
  small?: boolean;
}

const Tier: FunctionComponent<ITier> = (props) => {
  const { tier, players, isInTier, boss, rewards, lastTier, playerCount } =
    props;
  const classes = useStyles({ lastTier });
  const capacity = rewards.entityMax ?? 0;
  const guaranteedRewards =
    rewards?.possibleRewards.filter((r) => r.percentChance >= 100) ?? [];
  const possibleRewards =
    rewards?.possibleRewards.filter((r) => r.percentChance < 100) ?? [];

  const [showRewards, setShowRewards] = useState(false);

  function getPossibleReward(
    reward: LeaderboardTierRewardChanceFragment,
  ): IReward {
    const { rewardId, rewardType, rewardQuantity, percentChance } = reward;
    const rewardRarity = rewardType.split('_')[0].toLowerCase();

    let icon = 'boss_loot_generic';
    let title = rewardRarity.toUpperCase();
    let description = 'Boss Loot';

    if (rewardType === TierRewardType.Mint) {
      icon = 'boss_loot_mint_sample';
      title = `${percentChance}%`;
      description = 'chance';
    }

    if (rewardId && rewardType === TierRewardType.Loot) {
      const def = getItemDef(rewardId);
      if (def) {
        icon = def.icon;
        title = def.name.toUpperCase();
        description = def.rarity;
      }
    }

    const bossMats = [
      TierRewardType.EliteBossMaterials,
      TierRewardType.MythicBossMaterials,
      TierRewardType.LegendaryBossMaterials,
    ];
    const matIdx = bossMats.indexOf(rewardType);
    if (matIdx >= 0) {
      const rarity = [
        RarityLevel.Elite,
        RarityLevel.Mythic,
        RarityLevel.Legendary,
      ][matIdx];
      const defs = itemLibrary.find(
        (i) =>
          i.itemGroup === ItemGroup.Material &&
          i.rarity === rarity &&
          !i.name.includes(' Dust') &&
          getBossNameFromRealm(i.realmAffiliation) === boss,
      );
      if (defs) {
        icon = defs.icon;
        title = 'CRAFT';
        description = defs.rarity;
      }
    }

    const cnt = rewardQuantity > 1 ? ` (x${rewardQuantity})` : '';

    return {
      icon,
      title,
      description: `${description}${cnt}`,
      small: percentChance < 100,
    };
  }

  function renderReward(reward: IReward) {
    const { icon, title, description } = reward;
    return (
      <div className={classes.rewardItemCont}>
        <div className={classes.rewardItem}>
          <img
            src={`${cdnRoot}/images/icons/boss_loot/${icon}.png`}
            height={58}
            width={58}
          />
        </div>
        <h2 className={classes.rewardText}>
          {title}
          <span>{description}</span>
        </h2>
      </div>
    );
  }

  const rewardLists: IReward[][] = React.useMemo(() => {
    const ret: IReward[] = [];
    if (rewards.furAmount > 0)
      ret.push({
        icon: 'wfur',
        title: rewards.furAmount.toLocaleString(),
        description: 'wFUR',
      });
    if (rewards.poolSplit > 0)
      ret.push({
        icon: 'eth',
        title: Math.round(rewards.poolSplit * 100).toLocaleString() + '%',
        description: 'Prize Pool',
      });
    if (rewards.bossDustAmount > 0)
      ret.push({
        icon: `boss_dust_${boss}`,
        title: rewards.bossDustAmount.toLocaleString(),
        description: 'dust',
      });
    ret.push(...guaranteedRewards.map(getPossibleReward));

    const res: IReward[][] = [];
    for (let i = 0; i < ret.length; i += 3) {
      const r: IReward[] = [];
      for (let j = 0; j < 3 && i + j < ret.length; j++) {
        r.push(ret[i + j]);
      }
      res.push(r);
    }
    return res;
  }, [guaranteedRewards, rewards]);

  function renderRewards() {
    return (
      <div className={classes.rewards}>
        <h2 className={classes.rewardHeader}>
          {isInTier
            ? 'Stay in this tier to win...'
            : `Tier ${tier + 1} will win...`}
        </h2>
        {rewardLists.map((list, i) => {
          return (
            <div key={`item-${i}`} className={classes.rewardItems}>
              {list.map(renderReward)}
            </div>
          );
        })}
        {possibleRewards.length > 0 && (
          <>
            <h2 className={classes.rewardHeader}>And possibly also...</h2>
            <div className={classes.rewardItems}>
              {possibleRewards.map(getPossibleReward).map(renderReward)}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className={classes.tier}>
      <div className={classes.tierHeader}>
        <span>
          Tier {tier + 1} ({capacity.toLocaleString()})
        </span>
        <Button
          className={classes.rewardToggle}
          style={{
            justifyContent: 'flex-end',
          }}
          onClick={() => setShowRewards(!showRewards)}>
          {showRewards ? (
            <SvgIconLeaderboardTiers height={15} />
          ) : (
            <SvgIconLeaderboardRewards height={15} />
          )}
        </Button>
      </div>
      {showRewards ? renderRewards() : players}
    </div>
  );
};

export default Tier;
