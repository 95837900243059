import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Creepy = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.15} cx={158} cy={150} rx={21} ry={22} />
    <ellipse opacity={0.15} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <linearGradient
      id='Creepy__a'
      gradientUnits='userSpaceOnUse'
      x1={162}
      y1={155}
      x2={162}
      y2={127}>
      <stop
        offset={0.501}
        style={{
          stopColor: '#c2ff97',
        }}
      />
      <stop
        offset={0.629}
        style={{
          stopColor: '#c6fe96',
        }}
      />
      <stop
        offset={0.757}
        style={{
          stopColor: '#d2fb94',
        }}
      />
      <stop
        offset={0.884}
        style={{
          stopColor: '#e6f691',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ffef8c',
        }}
      />
    </linearGradient>
    <circle fill='url(#Creepy__a)' cx={162} cy={141} r={13} />
    <circle cx={162} cy={141} r={9} />
    <linearGradient
      id='Creepy__b'
      gradientUnits='userSpaceOnUse'
      x1={230}
      y1={155}
      x2={230}
      y2={127}>
      <stop
        offset={0.501}
        style={{
          stopColor: '#c2ff97',
        }}
      />
      <stop
        offset={0.629}
        style={{
          stopColor: '#c6fe96',
        }}
      />
      <stop
        offset={0.757}
        style={{
          stopColor: '#d2fb94',
        }}
      />
      <stop
        offset={0.884}
        style={{
          stopColor: '#e6f691',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ffef8c',
        }}
      />
    </linearGradient>
    <circle fill='url(#Creepy__b)' cx={230} cy={141} r={13} />
    <circle cx={230} cy={141} r={9} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M139.07 136.47a23.279 23.279 0 0 0 1.42 17.16c5.73 11.55 19.74 16.27 31.29 10.54 6.73-3.34 11.14-9.49 12.52-16.33l-45.23-11.37zM208.96 142.8a23.258 23.258 0 0 0 6.04 16.12c8.66 9.55 23.42 10.28 32.97 1.62 5.57-5.05 8.14-12.17 7.6-19.12l-46.61 1.38z'
    />
  </svg>
);

export default Creepy;
