import * as React from 'react';

function SvgIconBossHistoryDuration(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-boss-history-duration_svg__a'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      {...props}>
      <defs>
        <style>{'.icon-boss-history-duration_svg__b{fill:#410cd6}'}</style>
      </defs>
      <path
        className='icon-boss-history-duration_svg__b'
        d='M10 20c-4.61 0-8.36-3.75-8.36-8.36S5.39 3.28 10 3.28s8.36 3.75 8.36 8.36S14.61 20 10 20zm0-15.13c-3.74 0-6.77 3.03-6.77 6.77s3.03 6.77 6.77 6.77 6.77-3.03 6.77-6.77S13.74 4.87 10 4.87z'
      />
      <path
        className='icon-boss-history-duration_svg__b'
        d='M10 4.87v6.77h8.36S15.73 3.26 10 4.87zM12.13 1.9H7.87a.95.95 0 110-1.9h4.27a.95.95 0 110 1.9z'
      />
      <path
        className='icon-boss-history-duration_svg__b'
        d='M10 4.24a.95.95 0 01-.95-.95V1.37a.95.95 0 111.9 0v1.92c0 .53-.43.95-.95.95z'
      />
    </svg>
  );
}

export default SvgIconBossHistoryDuration;
