import React, { useEffect } from 'react';

import theme from '../../themev2';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { Lobby } from './Lobby';
import { Formation } from './Formation';
import { Notice } from './Notice';
import { useWalletSelector } from '../../hooks';
import WalletButton from '../../wallet/WalletButton';
import AlertProvider from '../../components/Alert/AlertProvider';
import { BossBattleProvider } from './useBossBattle';
import RentMarketGenerateParty from '../Marketplace/Rent/RentMarketGenerateParty';
import DuelLobby from './Lobby/DuelLobby';

export const gameBattleRootPath = '(monsters|boss|duel|battle|alpha|beta)';

export const Boss = () => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const wallet = useWalletSelector((s) => s);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  // useEffect(() => {
  //   const html = document.documentElement;
  //   html.style.fontSize = 'calc(100vw / 1440 * 16)';

  //   return () => {
  //     html.style.fontSize = 'initial';
  //   };
  // }, [isSmallScreen]);

  useEffect(() => {
    const shouldRedirect = location.pathname === '/bosses';
    shouldRedirect && history.replace('/boss');
  }, [currentPath]);

  // if (isSmallScreen) {
  //   return (
  //     <Notice
  //       title='WEN MOBILE?'
  //       button='Go Back'
  //       subTitle='Sorry, boss battle is only available on desktop screens for now.'
  //       action={() => history.goBack()}
  //     />
  //   );
  // }

  if (!wallet.contents) {
    return (
      <Notice
        hideImage
        title='No Account Detected'
        subTitle='Sorry, you do not appear to be logged in.'
        button={
          <WalletButton hideLoading={true} analyticsPathComponent={'boss'} />
        }
      />
    );
  }

  return (
    <AlertProvider>
      <BossBattleProvider>
        <Switch>
          <Route path='/party/:party?' component={Formation} />
          <Route
            exact
            path={`/${gameBattleRootPath}/onboard`}
            component={RentMarketGenerateParty}
          />
          <Route
            path={`/${gameBattleRootPath}/party/:party?`}
            component={Formation}
          />
          <Route
            path={'/duel/(fight|complete|lobby)/:realm?/:party?/:battleId?'}
            component={DuelLobby}
          />
          <Route
            path={`/${gameBattleRootPath}/(fight|complete|lobby)/:realm?/:party?/:battleId?`}
            component={Lobby}
          />
          <Route component={Lobby} />
        </Switch>
      </BossBattleProvider>
    </AlertProvider>
  );
};

export default Boss;
