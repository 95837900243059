import React, { FunctionComponent } from 'react';
import { TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IOwnedItem } from '../../../components/Inventory/InventoryHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  numberInput: {
    width: '100%',
  },
}));

export interface IInputItemQuantity {
  quantity: number;
  setQuantity: (quantity: number) => void;
  selectedItem: IOwnedItem | undefined;
}

const InputItemQuantity: FunctionComponent<IInputItemQuantity> = (props) => {
  const { quantity, setQuantity, selectedItem } = props;
  const classes = useStyles();

  return (
    <TextField
      id='outlined-number'
      label='Quantity'
      type='number'
      color='secondary'
      value={quantity}
      className={classes.numberInput}
      disabled={!selectedItem || selectedItem.item?.stack === 1}
      onChange={(e) =>
        setQuantity(
          Math.max(
            1,
            Math.min(
              selectedItem?.item?.stack ?? 1,
              Number.parseInt(e.target.value),
            ),
          ),
        )
      }
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

export default InputItemQuantity;
