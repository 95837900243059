import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RealmType } from '../../schema';
import SvgCreatureRealmTurtleSea from '../../../assets/voyages/SvgCreatureRealmTurtleSea';
import SvgRealmCreatureWizardsPath from '../../../assets/voyages/SvgCreatureRealmWizardsPath';
import SvgCreatureRealmBoneyard from '../../../assets/voyages/SvgCreatureRealmBoneyard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IRealmCreature {
  realmType: RealmType;
}

export const RealmCreature: FunctionComponent<IRealmCreature> = (props) => {
  const { realmType } = props;
  const classes = useStyles();

  if (realmType === RealmType.TurtleSea)
    return <SvgCreatureRealmTurtleSea className={classes.root} />;
  if (realmType === RealmType.WizardsPath)
    return <SvgRealmCreatureWizardsPath className={classes.root} />;
  if (realmType === RealmType.Boneyard)
    return <SvgCreatureRealmBoneyard className={classes.root} />;

  console.warn('CREATURE', realmType);
  return null;
};

export default RealmCreature;
