import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Container } from '@mui/material';
import useCommonStyles from '../../components/useCommonStyles';
import { cdnRoot } from '../../theme';
import FullDrawer from '../../components/Drawer/FullDrawer';
import clsx from 'clsx';
import AccountHeader from '../../AccountHeader';
import { usePageTitle } from '../../Analytics';
import TixCard from '../../components/Swap/TixCard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'auto',
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  main: {
    paddingTop: 20,
  },
  header: {
    textAlign: 'center',
    backgroundImage: `url(${cdnRoot}/svgs/timekeeper-bk.svg)`,
    backgroundSize: 'cover',
    // backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'bottom',
    padding: theme.spacing(4),
  },
  bar: {
    height: 40,
    backgroundColor: 'black',
    paddingTop: 2,
  },
}));

interface IUnknown {}

type Props = IUnknown;

// interface IMaybeTransfer {
//   transfer?: string;
// }

export const Tickets: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  // const params = useParams<IMaybeTransfer>();

  usePageTitle('Buy TIX | Furballs');

  return (
    <FullDrawer drawer={null}>
      <div className={clsx(common.fullHeight, common.furBk, classes.root)}>
        <Container maxWidth={'lg'}>
          <AccountHeader title={'Buy TIX'} />
        </Container>
        <Container maxWidth={'sm'}>
          <TixCard />
        </Container>
      </div>
    </FullDrawer>
  );
};

export default Tickets;
