import React, { FunctionComponent, useCallback, useState } from 'react';
import { useBossBattle } from '../useBossBattle';
import theme, { mint } from '../../../themev2';
import SvgIconCaretRight from '../../../assets/SvgIconCaretRight';
import {
  BossBattlePlayerFragment,
  RealmType,
} from '../../../components/schema';
import { Link, useHistory } from 'react-router-dom';
import { cdnRoot } from '../../../theme';
import {
  Theme,
  Button,
  CircularProgress,
  Typography,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgIconDice from '../../../assets/SvgIconDice';
import { size5Formations } from '../FormationLibrary';
import OkDialog from '../../../components/OkDialog';
import SvgIconTix from '../../../assets/SvgIconTix';
import BuyKeysDialog from '../BuyKeysDialog';
import { IFurball } from '../../../wallet/WalletTypes';
import { BossKeyDefinition } from '../../../components/Loot';
import clsx from 'clsx';
import { usePlayersReadyFurballs } from '../../../wallet';
import SpendWFurButton from '../../../components/Account/SpendWFurButton';
import { StartGameFunc } from './Lobby';

const useStyles = makeStyles((theme: Theme) => ({
  bossList: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'repeat(3, 1fr)',
    gap: '1rem',
    paddingBottom: '2rem',

    [theme.breakpoints.down('lg')]: {
      padding: '4rem 0',
    },
  },

  bossItem: {
    border: '6px solid #1D1378',
    borderRadius: '25px',
    position: 'relative',
    padding: '0.5rem 1rem',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      minHeight: '10rem',
    },
  },

  bossLevel: {
    position: 'absolute',
    top: '0.5rem',
    right: '1rem',
  },

  bossName: {
    textTransform: 'uppercase',
    fontSize: '2.7rem !important',

    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem !important',
    },
  },

  fightButton: {
    width: '12rem',
    height: '3rem',
    padding: '0.6rem 1rem !important',
    backgroundColor: '#FFEC61 !important',
    border: '3px solid #000 !important',
    boxShadow: 'inset 0 -5px 0 2px #FFCA04',
    borderRadius: '25px !important',
    transition: 'all 0.2s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      boxShadow: 'none',
    },

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      backgroundColor: '#FFCA04 !important',
      opacity: 0.8,
      filter: 'grayScale(80%)',
    },

    '&[data-fit-width="true"]': {
      minWidth: '8rem !important',
      width: 'fit-content !important',
    },
  },

  fightButtonText: {
    fontSize: '1.6rem !important',
  },

  fightButtonIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: '1.5rem',
    margin: 'auto',
  },

  hoverNotice: {
    position: 'absolute',
    inset: 0,
    backgroundColor: '#180e74d2',
    zIndex: 5,
    display: 'grid',
    placeItems: 'center',
    transition: 'all 0.2s ease-out',
    padding: '2rem 3rem',
    cursor: 'default',
    opacity: 0,
    // transition: 'all 0.2s linear',

    '&[data-visible="true"]': {
      opacity: 1,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0 2rem',
    },
  },

  link: {
    textDecoration: 'none !important',
  },

  actionButtons: {
    minWidth: 140,
    // width: '100%',
    // display: 'grid',
    // placeItems: 'center',
    // position: 'relative',
  },

  actionBtn: {
    width: '100%',
    maxHeight: 40,
    backgroundColor: `${mint.main} !important`,

    [theme.breakpoints.down('sm')]: {
      width: '12rem',
    },
  },

  practiceLink: {
    position: 'absolute',
    top: '0.5rem',
    left: '1rem',
    color: 'white !important',
    '&:hover': {
      color: `${theme.palette.primary.light} !important`,
    },
  },
}));

interface IProps {
  battleBossName?: string;
  fetchingBattle: boolean;
  hasNonBossZoneMember: boolean;
  hasIncompleteMembers: boolean;
  hasDeadMember: boolean;
  hovering?: BossKeyDefinition;
  bossBattle?: BossBattlePlayerFragment | null;
  setHasNoneBossZoneMember: (value: boolean) => void;
  setHovering: (id: BossKeyDefinition | undefined) => void;
  className?: string;
  refillHearts: () => Promise<void>;
  refillFurCost: number;
  startGame: StartGameFunc;
}

export const LobbyBossList: FunctionComponent<IProps> = ({
  battleBossName,
  fetchingBattle,
  hasNonBossZoneMember,
  hasIncompleteMembers,
  setHasNoneBossZoneMember,
  hasDeadMember,
  setHovering,
  hovering,
  bossBattle,
  className,
  refillHearts,
  refillFurCost,
  startGame,
}) => {
  const [showMoveZones, setShowMoveZones] = useState<BossKeyDefinition>();
  const [practicePrompt, setPracticePrompt] = useState<RealmType | undefined>();
  const [buyingKeys, setBuyingKeys] = useState<RealmType | undefined>(
    undefined,
  );
  const furballs = usePlayersReadyFurballs();

  const {
    partyId,
    currentParty,
    forfeitBattle,
    isForfeiting,
    hasRequiredKey,
    bosslist,
  } = useBossBattle();

  const classes = useStyles();
  const history = useHistory();

  const bosses = useCallback(() => {
    return (
      bosslist?.map((item) => ({
        ...item,
        bossKey:
          item.boss.realm === RealmType.TurtleSea
            ? BossKeyDefinition.TURTLE_SEA
            : item.boss.realm === RealmType.WizardsPath
            ? BossKeyDefinition.WIZARDS_PATH
            : BossKeyDefinition.BONE_YARD,
        background: `url(${cdnRoot}/svgs/background-lobby-${item.boss.name.toLowerCase()}.svg)`,
      })) || []
    );
  }, [bosslist]);

  function playInRealm(newRealm: RealmType, trial: boolean): void {
    if (!currentParty) return;
    setPracticePrompt(undefined);
    startGame('Boss', newRealm, currentParty, trial);
  }

  function onClickPracticeGame(realm: RealmType, e: React.MouseEvent): boolean {
    setPracticePrompt(realm);
    e.preventDefault();
    return false;
  }

  return (
    <div className={clsx(classes.bossList, className)}>
      {bosses().map((item, index) => {
        const hasBattle =
          bossBattle && !fetchingBattle && battleBossName === item.boss.name;
        const keysComplete = hasBattle || hasRequiredKey(item.bossKey);
        const realm = item.boss.realm.replace(/_/g, ' ').toLowerCase();
        const preventNewBattle =
          currentParty?.isInBattle ||
          fetchingBattle ||
          !keysComplete ||
          hasIncompleteMembers ||
          hasDeadMember ||
          hasNonBossZoneMember;

        return (
          <div
            key={index}
            className={classes.bossItem}
            onMouseEnter={() => setHovering(item.bossKey)}
            onMouseLeave={() => setHovering(undefined)}
            style={{ backgroundImage: item.background }}>
            <>
              {hasIncompleteMembers ? (
                <Grid
                  container
                  gap='0.8rem'
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  data-visible={hovering === item.bossKey}
                  color={theme.palette.common.white}
                  className={classes.hoverNotice}>
                  <Typography textAlign='center' variant='h4' fontSize='1.2rem'>
                    Incomplete party members (missing members are probably on
                    rental contracts)
                  </Typography>
                  <div className={classes.actionButtons}>
                    <Link
                      className={classes.link}
                      to={`/boss/party/${partyId}`}>
                      <Button
                        size='small'
                        variant='contained'
                        startIcon={
                          <SvgIconDice
                            width='1rem'
                            height='1rem'
                            fill='black'
                            formation={size5Formations[0]}
                          />
                        }
                        className={classes.actionBtn}>
                        <Typography
                          variant='h6'
                          fontSize='1rem'
                          fontWeight='600'>
                          Edit Party
                        </Typography>
                      </Button>
                    </Link>
                  </div>
                </Grid>
              ) : hasDeadMember ? (
                <Grid
                  container
                  gap='0.8rem'
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  data-visible={hovering === item.bossKey}
                  color={theme.palette.common.white}
                  className={classes.hoverNotice}>
                  <Typography textAlign='center' variant='h4' fontSize='1.2rem'>
                    Furball hearts refill automatically daily at midnight UTC.
                  </Typography>
                  <div className={classes.actionButtons}>
                    <SpendWFurButton
                      itemName={'Refill Now'}
                      cost={refillFurCost}
                      onAccepted={refillHearts}
                      className={classes.actionBtn}
                      secondary={true}
                    />
                  </div>
                </Grid>
              ) : (
                !keysComplete && (
                  <Grid
                    container
                    gap='0.8rem'
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    data-visible={hovering === item.bossKey}
                    color={theme.palette.common.white}
                    className={classes.hoverNotice}>
                    <Typography
                      textAlign='center'
                      variant='h4'
                      fontSize='1.2rem'>
                      Missing{' '}
                      <span style={{ textTransform: 'capitalize' }}>
                        {realm}
                      </span>{' '}
                      keys
                    </Typography>
                    <Grid
                      container
                      className={classes.actionButtons}
                      style={{ minWidth: 260 }}>
                      <Grid item xs={6}>
                        <Link
                          className={classes.link}
                          to={`/boss/party/${partyId}`}>
                          <Button
                            size='small'
                            variant='contained'
                            startIcon={
                              <SvgIconDice
                                width='1rem'
                                height='1rem'
                                fill='black'
                                formation={size5Formations[0]}
                              />
                            }
                            className={classes.actionBtn}>
                            Edit Party
                          </Button>
                        </Link>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          size='small'
                          variant='contained'
                          onClick={() => setBuyingKeys(item.boss.realm)}
                          startIcon={
                            <SvgIconTix
                              width='1rem'
                              height='1rem'
                              fill='black'
                            />
                          }
                          className={classes.actionBtn}>
                          Buy Keys
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              )}
            </>

            {(hovering !== item.bossKey ||
              (!hasNonBossZoneMember && keysComplete)) && (
              <>
                <Typography
                  variant='h4'
                  fontSize='1.1rem'
                  color='#fff'
                  className={classes.bossLevel}>
                  LVL {item.boss.level}
                </Typography>

                <Grid
                  container
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  flexGrow={1}>
                  <Typography
                    variant='h4'
                    fontWeight={800}
                    className={classes.bossName}
                    color={item.boss.name === 'Skullumbo' ? '#000' : '#fff'}>
                    {item.boss.name}
                  </Typography>

                  {hasBattle ? (
                    <Grid
                      container
                      gap='0.3rem'
                      justifyContent='center'
                      alignItems='center'>
                      <Button
                        data-fit-width
                        data-disabled={isForfeiting}
                        className={classes.fightButton}
                        onClick={() => playInRealm(item.boss.realm, false)}>
                        <Typography
                          role='textbox'
                          variant='h4'
                          fontSize='1.1rem'
                          fontWeight={800}
                          color='#000'
                          lineHeight={1}>
                          Resume
                        </Typography>
                      </Button>

                      <Button
                        data-fit-width
                        data-disabled={isForfeiting}
                        className={classes.fightButton}
                        onClick={() => forfeitBattle()}>
                        {isForfeiting ? (
                          <CircularProgress size='1rem' color='secondary' />
                        ) : (
                          <Typography
                            role='textbox'
                            variant='h4'
                            fontSize='1.1rem'
                            fontWeight={600}
                            color='#000'
                            lineHeight={1}>
                            Forfeit
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  ) : (
                    <>
                      <Button
                        className={classes.fightButton}
                        // disabled={loadProgress < 1}
                        data-disabled={preventNewBattle}
                        onClick={(e) =>
                          currentParty?.size === 1
                            ? onClickPracticeGame(item.boss.realm, e)
                            : playInRealm(item.boss.realm, false)
                        }>
                        {fetchingBattle ? (
                          <CircularProgress size='1rem' color='secondary' />
                        ) : (
                          <>
                            <Typography
                              role='textbox'
                              variant='h4'
                              className={classes.fightButtonText}
                              fontWeight={800}
                              color='#000'
                              lineHeight={1}>
                              Fight
                            </Typography>
                            <SvgIconCaretRight
                              width='1rem'
                              height='1rem'
                              fill='#000'
                              className={classes.fightButtonIcon}
                            />
                          </>
                        )}
                      </Button>
                      {!preventNewBattle && (
                        <Typography variant={'h6'}>
                          <Link
                            to={'#'}
                            // to={getFightBossLink(
                            //   item.boss.realm,
                            //   currentParty,
                            //   true,
                            // )}
                            onClick={(e) =>
                              onClickPracticeGame(item.boss.realm, e)
                            }
                            className={classes.practiceLink}>
                            Play Practice Game
                            <SvgIconCaretRight
                              width='0.7rem'
                              height='0.7rem'
                              fill='#FFF'
                              style={{ marginLeft: 4 }}
                            />
                          </Link>
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
              </>
            )}
          </div>
        );
      })}
      <OkDialog
        title={'Practice Games'}
        open={!!practicePrompt}
        onClose={() => setPracticePrompt(undefined)}>
        Practice mode is a risk-free way to learn the game.
        <br />
        <br />
        Fights will not count against your daily total, and scores will not
        count.
        <br />
        The game is also slower, with bosses waiting for you to move first.
        <br />
        <br />
        {practicePrompt && (
          <Button
            size='medium'
            variant='contained'
            className={classes.actionBtn}
            style={{ width: '100%' }}
            onClick={() => playInRealm(practicePrompt, true)}>
            Play Practice Game
          </Button>
        )}
      </OkDialog>
      <BuyKeysDialog
        open={!!buyingKeys}
        onClose={() => setBuyingKeys(undefined)}
        furballs={furballs.filter((f) => !!f).map((f) => f as IFurball)}
        realms={buyingKeys ? [buyingKeys] : undefined}
      />
    </div>
  );
};
