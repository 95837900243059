import * as React from 'react';

function SvgRealmCreatureBoneyard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 267.61 292.26'
      {...props}>
      <ellipse opacity={0.12} cx={133.07} cy={277.57} rx={122.25} ry={9.7} />
      <path fill='#E0B45C' d='M23.66 208.85h74.77v66.89H23.66z' />
      <path
        stroke='#000'
        strokeMiterlimit={10}
        d='M22.74 207.93h76.62v68.73H22.74v-68.73zm74.77 1.48H24.59v65.41h72.92v-65.41z'
      />
      <path fill='#ECC373' d='M84.44 208.49h93.78v67.25H84.44z' />
      <path
        stroke='#000'
        strokeMiterlimit={10}
        d='M83.52 207.56h95.63v69.1H83.52v-69.1zm93.78 1.85H85.37v65.4h91.93v-65.4z'
      />
      <path fill='#AF6F43' d='M95.07 218.65h72.52v47.67H95.07z' />
      <path fill='#AF8064' d='M95.07 218.65h72.52v15.88H95.07z' />
      <path
        fill='#976E56'
        d='M94.33 217.9h74v17.36h-74V217.9zm72.52 1.48H95.81v14.4h71.04v-14.4z'
      />
      <path fill='#AF8064' d='M95.07 234.53h72.52v15.88H95.07z' />
      <path
        fill='#976E56'
        d='M94.33 233.79h74v17.36h-74v-17.36zm72.52 1.47H95.81v14.4h71.04v-14.4z'
      />
      <path fill='#AF8064' d='M95.07 250.41h72.52v15.88H95.07z' />
      <path
        fill='#976E56'
        d='M94.33 249.67h74v17.36h-74v-17.36zm72.52 1.48H95.81v14.4h71.04v-14.4z'
      />
      <path fill='#9C6A4B' d='M34.47 218.64h39.41v47.67H34.47z' />
      <path
        fill='#905F40'
        d='M33.73 217.9h40.89v49.15H33.73V217.9zm39.41 1.48H35.21v46.19h37.93v-46.19z'
      />
      <g>
        <path
          fill='#E0B45C'
          d='M98.44 207.95H23.66c0-19.66 9.19-35.6 36.13-35.6h38.64v35.6h.01z'
        />
        <path
          stroke='#000'
          strokeMiterlimit={10}
          d='M22.74 207.95c0-10.93 2.76-19.6 8.19-25.78 6.27-7.13 15.98-10.74 28.86-10.74h38.64c.51 0 .92.41.92.92v35.6c0 .51-.41.92-.92.92H23.66c-.51 0-.92-.41-.92-.92zm74.77-34.68H59.8c-15.89 0-34.79 6.42-35.2 34.28h72.92l-.01-34.28h0z'
        />
      </g>
      <g>
        <path
          fill='#ECC373'
          d='M58.75 172.35h83.34c19.96 0 36.13 16.18 36.13 36.13H84.44c0-31.81-25.69-36.13-25.69-36.13z'
        />
        <path
          stroke='#000'
          strokeMiterlimit={10}
          d='M83.52 208.48c0-12.86-4.18-22.69-12.42-29.22-6.14-4.86-12.39-5.98-12.49-6a.925.925 0 01.15-1.84h83.34c20.43 0 37.06 16.62 37.06 37.06 0 .51-.41.92-.92.92h-93.8a.911.911 0 01-.92-.92zm58.57-35.21H64.64c2.22.96 4.87 2.39 7.53 4.48 5.91 4.65 12.93 13.58 13.19 29.8h91.92c-.49-18.98-16.09-34.28-35.19-34.28z'
        />
      </g>
      <g>
        <path
          fill='#AF8064'
          d='M72.75 172.72h61.16c13.54 0 30.73 11.97 30.73 26.73H94.06c-4.47-22.97-21.31-26.73-21.31-26.73z'
        />
        <path
          fill='#976E56'
          d='M93.33 199.6c-2.26-11.61-7.77-18.1-12-21.5-4.58-3.68-8.7-4.65-8.74-4.66l-6.55-1.46h67.88c6.89 0 14.71 2.99 20.91 7.99 6.81 5.5 10.56 12.42 10.56 19.48v.74H93.45l-.12-.59zm40.58-26.14H76.78c1.55.75 3.43 1.85 5.41 3.43 4.38 3.5 10.05 10.11 12.47 21.82h69.22c-.56-13.68-16.71-25.25-29.97-25.25z'
        />
      </g>
      <g>
        <path
          fill='#9C6A4B'
          d='M54.18 181.49c10.88 0 19.71 6.96 19.71 16.77H34.47c0-9.81 8.82-16.77 19.71-16.77z'
        />
        <path
          fill='#905F40'
          d='M33.73 198.26c0-9.98 8.79-17.51 20.45-17.51s20.45 7.53 20.45 17.51v.74h-40.9v-.74zm20.45-16.03c-10.52 0-18.52 6.52-18.95 15.29h37.9c-.43-8.77-8.43-15.29-18.95-15.29z'
        />
      </g>
      <g>
        <path
          fill='#E0B45C'
          d='M125.71 230.92h14.58c3.14 0 5.69-2.55 5.69-5.69V208.5h-25.96v16.72c0 3.15 2.55 5.7 5.69 5.7z'
        />
        <path
          stroke='#000'
          strokeMiterlimit={10}
          d='M119.31 225.44v-16.91c0-.51.41-.92.92-.92h25.96c.51 0 .92.41.92.92v16.91c0 3.65-2.97 6.62-6.62 6.62h-14.58c-3.63 0-6.6-2.97-6.6-6.62zm1.85-15.98v15.98c0 2.63 2.14 4.77 4.77 4.77h14.58c2.63 0 4.77-2.14 4.77-4.77v-15.98h-24.12z'
        />
      </g>
      <g>
        <path fill='none' d='M172.58 218.01v48.31' />
        <path
          stroke='#000'
          strokeMiterlimit={10}
          d='M172.58 265.72h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.83 1.85 1.85s-.83 1.85-1.85 1.85zm0-11.46h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85 0 1.02-.83 1.85-1.85 1.85zm0-11.47h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85 0 1.02-.83 1.85-1.85 1.85zm0-11.47h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85 0 1.03-.83 1.85-1.85 1.85zm0-11.46h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85 0 1.02-.83 1.85-1.85 1.85z'
        />
      </g>
      <g>
        <path fill='none' d='M90.19 218.01v48.31' />
        <path
          stroke='#000'
          strokeMiterlimit={10}
          d='M90.19 265.72h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.83 1.85 1.85a1.859 1.859 0 01-1.85 1.85zm0-11.46h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85a1.859 1.859 0 01-1.85 1.85zm0-11.47h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85a1.859 1.859 0 01-1.85 1.85zm0-11.47h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85a1.852 1.852 0 01-1.85 1.85zm0-11.46h0c-1.02 0-1.85-.83-1.85-1.85h0c0-1.02.83-1.85 1.85-1.85h0c1.02 0 1.85.82 1.85 1.85a1.859 1.859 0 01-1.85 1.85z'
        />
      </g>
      <g>
        <path fill='none' d='M162.19 189.88H91.33' />
        <path fill='#976E56' d='M91.33 189.14h70.86v1.48H91.33z' />
      </g>
      <g>
        <path fill='none' d='M85.87 181.49h69.17' />
        <path fill='#976E56' d='M85.87 180.75h69.17v1.48H85.87z' />
      </g>
      <g>
        <path fill='none' d='M78.95 175.5h66.76' />
        <path fill='#976E56' d='M78.95 174.76h66.76v1.48H78.95z' />
      </g>
      <g>
        <path fill='none' d='M73.88 234.52H34.47' />
        <path fill='#905F40' d='M34.47 233.79h39.41v1.48H34.47z' />
      </g>
      <g>
        <path fill='none' d='M73.88 250.41H34.47' />
        <path fill='#905F40' d='M34.47 249.67h39.41v1.48H34.47z' />
      </g>
      <g>
        <path
          fill='#C9D8FA'
          d='M225.55 88.16s12.32-4.08 18.46-9.92c6.15-5.85 15.68-3.52 15.14 6.18-.55 9.7-18.61 30.35-36.53 31.48'
        />
        <path
          fill='#E7F1FC'
          d='M257.55 78.04c-2.91-3.98-9.1-4.01-13.53.2-6.15 5.85-18.46 9.92-18.46 9.92l9.6 1.67c8.87 12.04 22.31-2.1 22.39-11.79z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={3}
          strokeMiterlimit={10}
          d='M225.55 88.16s12.32-4.08 18.46-9.92c6.15-5.85 15.68-3.52 15.14 6.18-.55 9.7-18.61 30.35-36.53 31.48'
        />
        <path
          fill='#E7F1FC'
          d='M167.96 8.57c-19.57 6.63-30.61 23.8-41.96 41.06l-12.58 10.96c2.48 4.53 3.81 20.47-13.56 34.17-10.49 8.27-34.77 5.84-25.24 46.86 1.63 6.99 12.57 40.01-12.7 30.42-11.29-4.29-9.12 3.18-15.27 8.78-2.09 1.9-1.03 13.04 1.67 12.26 6.86-1.99 7.28-18.52 15.54-8.07 9.86 12.49 32.61 5.72 33.75-15.9 1.14-21.61-6.64-38.69 31.29-14.54 0 0 60.46 44.17 96.32-38.91C268.83 25.99 194.78-.5 167.96 8.57z'
        />
        <ellipse
          transform='rotate(-68.6 171.37 36.826)'
          fill='#C9D8FA'
          cx={171.37}
          cy={36.83}
          rx={10.89}
          ry={8.8}
        />
        <path
          fill='#FFF'
          d='M225.17 27.43c6.97 16.6 12.44 26.1.79 70.04-30.85 85.07-98.29 42-98.29 42-25.38-11.47-36.58 4.6-36.43 26.24s-15.3 22.22-28.01 12.63c-5.54-4.18-13.1-1.39-16.57 4.52-.29.49-1.07 11 1.64 10.21 6.86-1.99 9.18-15.84 15.54-8.07 10.08 12.32 30.21 5.45 33.75-15.9 2.98-17.98 5.2-31.57 43.14-7.43 0 0 49.79 30.4 84.48-46.03 20.82-42.79 14.83-71.2-.04-88.21z'
        />
        <path
          fill='#C9D8FA'
          d='M137.67 49.85c19.85-28.07 42.75-42.95 69.59-36.13-12.88-5.32-30.69-8.06-39.29-5.14-19.58 6.62-30.62 23.79-41.97 41.05l-12.58 10.96c2 3.66 6.4 13.98-2.32 27.02 11.77-11.25 9.83-14.09 26.57-37.76z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={3}
          strokeLinecap='round'
          strokeMiterlimit={10}
          d='M126 49.63c11.35-17.26 22.4-34.43 41.97-41.06 26.81-9.08 100.87 17.41 57.24 107.08-35.86 83.08-90.67 42.35-90.67 42.35-21.53-18.41-35.81-10.51-36.95 11.1s-18.45 28.33-30 17.38c-10.24-9.71-15.25 1.39-16.7 5.49-.94 2.66-3.7 1.17-4.36-1.57-1.74-7.29 4.08-22.67 15.37-18.38 25.27 9.59 14.4-23.44 12.7-30.42-9.63-39.67 14.75-38.59 25.24-46.86 17.38-13.7 16.04-29.64 13.56-34.17'
        />
        <path
          fill='#E7F1FC'
          d='M137.67 49.85s-7.58-11.33-8.91-20.14-10.73-13.04-16.54-4.64c-5.8 8.4-4.15 37.17 9.99 49.66'
        />
        <path
          fill='#C9D8FA'
          d='M111.88 25.61c-4.36 7.3-4.22 26.78 3.31 40.44 1.78 3.23 3.98 6.14 6.63 8.48l4.25-6.88c-10.73-10.79-14.93-29.54-14.19-42.04z'
        />
        <path
          fill='#274167'
          d='M170.68 61.36c-3.92 9.99 3.84 3.68 10.8 6.41s10.5 13.47 14.42 3.48 2.13-22.04-4.84-24.77c-6.96-2.74-16.47 4.89-20.38 14.88z'
        />
        <circle cx={170.27} cy={39.34} r={7.91} />
        <circle fill='#FFF' cx={169.28} cy={43.85} r={1.59} />
        <ellipse
          transform='rotate(-68.6 212.154 52.314)'
          fill='#C9D8FA'
          cx={212.15}
          cy={52.31}
          rx={10.89}
          ry={8.8}
        />
        <circle cx={211.07} cy={55.09} r={7.91} />
        <circle fill='#FFF' cx={209.22} cy={59.65} r={1.59} />
        <path
          fill='#FFC3C3'
          d='M191.36 75.27c-1.81 4.61-3.97-3.8-9.35-5.91-5.38-2.11-11.95 2.88-10.14-1.73s7.94-8.27 13.33-6.16c5.38 2.11 7.97 9.19 6.16 13.8z'
        />
        <g>
          <path d='M188.02 45.91c1.05 0 2.07.18 3.04.56 6.96 2.73 8.75 14.78 4.84 24.77-1.2 3.05-2.36 4.17-3.59 4.17-2.8 0-5.99-5.75-10.82-7.65-1.51-.59-3.06-.76-4.52-.76-1.88 0-3.61.28-4.92.28-2.35 0-3.34-.9-1.37-5.93 3.37-8.59 10.88-15.44 17.34-15.44m0-4c-8.12 0-16.99 7.56-21.07 17.99-1.22 3.11-2.1 6.34-.34 8.91 1.12 1.64 2.95 2.47 5.44 2.47.76 0 1.54-.07 2.36-.13.84-.07 1.71-.14 2.56-.14 1.24 0 2.22.15 3.06.48 1.69.66 3.32 2.22 4.89 3.72 2.16 2.07 4.39 4.2 7.39 4.2 4.69 0 6.67-5.05 7.32-6.71 2.17-5.54 2.85-11.83 1.87-17.28-1.14-6.36-4.33-10.86-8.98-12.68-1.41-.55-2.93-.83-4.5-.83z' />
        </g>
        <path
          fill='none'
          stroke='#000'
          strokeWidth={3}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M137.67 49.85s-7.58-11.33-8.91-20.14-10.73-13.04-16.54-4.64c-4.68 6.78-4.99 28.85 2.69 42.9'
        />
      </g>
      <g fill='#FFF'>
        <path d='M130.23 220.12v1.69c-.3.11-.51.39-.51.72v.7c0 .42.35.77.77.77h.35c.42 0 .77-.35.77-.77v-.7c0-.33-.21-.61-.51-.72v-1.69h3.79v1.69c-.3.11-.51.39-.51.72v.7c0 .42.35.77.77.77h.35c.42 0 .77-.35.77-.77v-.7c0-.33-.21-.61-.51-.72v-1.69h1.4c.42 0 .81-.13 1.12-.36v4.8c0 1.13-.92 2.05-2.05 2.05h-6.5c-1.13 0-2.05-.92-2.05-2.05v-4.8c.32.22.71.36 1.12.36h1.43zM134.23 213.87a.37.37 0 00-.37-.37h-1.7a.37.37 0 00-.37.37v.6h-.88v-.6c0-.69.56-1.25 1.25-1.25h1.7c.69 0 1.25.56 1.25 1.25v.6h-.88v-.6zM135.78 218.88v-3.96h2.92v3.24c0 .39-.15.75-.4 1.02v-.3h-2.52zM131.11 214.91h3.79v3.96h-3.79zM127.71 218.88v.3c-.25-.27-.4-.63-.4-1.02v-3.24h2.92v3.96h-2.52zM131.11 218.88h3.79v.81h-3.79z' />
        <path d='M138.3 218.88v.3c-.28.31-.68.5-1.12.5h-1.4v-.81l2.52.01zM130.23 218.88v.81h-1.4c-.45 0-.84-.2-1.12-.5v-.3l2.52-.01z' />
      </g>
    </svg>
  );
}

export default SvgRealmCreatureBoneyard;
