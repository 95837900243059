import { Grid, Button, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import SVGFurballIconLarge from '../../assets/SVGFurballIconLarge';
import SvgIconDiscord from '../../assets/SvgIconDiscord';
import SvgIconTwitter from '../../assets/SvgIconTwitter';
import SvgIconOpensea from '../../assets/SvgIconOpenSea';
import { cdnRoot } from '../../theme';
import { white } from '../../themev2';
import { discordChannelInviteUrl } from './StickyDiscordInvite';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '470px',
    width: '100%',
    paddingTop: '70px',
    paddingBottom: '70px',
    marginTop: '0px !important',
  },
  socialsContainer: {
    width: '400px !important',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  socialButton: {
    border: '0px !important',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  footerText: {
    fontSize: '1rem !important',
  },
}));

interface OwnProps {}

type Props = OwnProps;

const FooterV2: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  function opensea() {
    window.open('https://opensea.io/collection/furballs-com-official');
  }
  function twitter() {
    window.open('https://twitter.com/furballsNFT');
  }
  function discord() {
    window.open(discordChannelInviteUrl);
  }

  const iconWidth = 35;

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <SVGFurballIconLarge />
      </Grid>
      <Grid item container sx={{ width: '210px' }}>
        <Grid item xs={4} lg={4}>
          <Button className={classes.socialButton} onClick={opensea}>
            <SvgIconOpensea style={{ width: `${iconWidth}px` }} fill={white} />
          </Button>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Button className={classes.socialButton} onClick={twitter}>
            <SvgIconTwitter style={{ width: `${iconWidth}px` }} fill={white} />
          </Button>
        </Grid>
        <Grid item xs={4} lg={4}>
          <Button className={classes.socialButton} onClick={discord}>
            <SvgIconDiscord style={{ width: `${iconWidth}px` }} fill={white} />
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ color: white }}>
        <Typography className={classes.footerText}>
          See our{' '}
          <a
            style={{ color: white }}
            href={`${cdnRoot}/downloads/terms-of-service.pdf`}>
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            style={{ color: white }}
            href={`${cdnRoot}/downloads/privacy-policy.pdf`}>
            Privacy Policy
          </a>
          .
          <br />
          (C) 2021-2023 LFG Gaming LLC
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FooterV2;
