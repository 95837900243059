import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Fade, Grow, Theme, Typography } from '@mui/material';
import { IBossRewards } from './BossRewardsClaim';
import { cdnRoot } from '../../../theme';
import { GameItem, ItemDefinitionFragment } from '../../schema';
import BossRewardsOverview from './BossRewardsOverview';
import SvgX from '../../../assets/SvgX';
import useBossFightsStyles from '../useBossFightsStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    zIndex: 1252,
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  revealCont: {
    height: '700px',
    width: '1200px',
    marginBottom: '40px',
    borderRadius: '8px',
    backgroundColor: theme.palette.secondary.light,
    backgroundImage: (props: any) =>
      `url(${cdnRoot}/images/backgrounds/leaderboard_${props.bossName}.svg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      height: '100%',
      width: '100%',
      borderRadius: 0,
      marginBottom: 0,
    },
  },
  loot: {
    padding: '20px',
    height: '100%',
    width: '750px',
    marginBottom: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    color: 'white !important',
    borderRadius: '8px',
    background:
      'radial-gradient(circle at 50% 50%, rgba(255, 255, 255, 0.4) 0%, rgba(84, 48, 224, 0.1) 50%, rgba(84, 48, 224, 0.1) 100%)',
  },
  lootImg: {
    marginTop: '85px',

    '& img': {
      height: '175px',
      width: '175px',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: '65px',

      '& img': {
        height: '125px',
        width: '125px',
      },
    },
  },
  tags: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tag: {
    margin: '10px 5px 0 5px',
    width: '170px',
    fontSize: '0.8rem',
    padding: '5px 10px',
    textAlign: 'center',
    color: 'white',
    borderRadius: '20px',
    border: '2px solid rgba(255, 255, 225, 0.8)',
    backgroundColor: 'rgba(84, 48, 224, 0.7)',

    [theme.breakpoints.down('sm')]: {
      width: '145px',
      fontSize: '0.7rem',
      padding: '5px',
    },
  },
  closeButtonCont: {
    position: 'absolute',
    top: 20,
    right: 15,
  },
  closeButton: {
    backgroundColor: 'transparent !important',
    border: 'none !important',
    height: '40px !important',
    width: '40px !important',
    opacity: 0.8,
  },
}));

export interface IRewardItem {
  item: GameItem;
  quantity: number;
  revealed: boolean;
}

export interface IBossRewardsReveal extends IBossRewards {
  onClose: () => void;
  itemDefs: { [p: string]: ItemDefinitionFragment };
  iconSrc: string;
}

const REVEAL_DURATION = 1200;

const BossRewardsReveal: FunctionComponent<IBossRewardsReveal> = (props) => {
  const { onClose, itemDefs, bossName, pendingReward, iconSrc } = props;
  const classes = useStyles({ bossName });
  const bossStyles = useBossFightsStyles();

  const revealTimerRef = useRef<NodeJS.Timeout | null>(null);
  const backdropRef = useRef<HTMLDivElement>(null);
  const [itemsIndex, setItemsIndex] = useState<number>(0);
  const [lootItems, setLootItems] = useState<IRewardItem[]>(getLootItems());
  const currentLootItem = lootItems[itemsIndex];

  useEffect(() => {
    return () => {
      if (revealTimerRef.current) {
        clearTimeout(revealTimerRef.current);
      }
    };
  }, []);

  function onBackdropClick(e: React.MouseEvent<HTMLElement>) {
    if (!currentLootItem.revealed) return;

    if (backdropRef.current === e.target) {
      onClose();
    }
  }

  function revealItem() {
    revealTimerRef.current = setTimeout(() => {
      lootItems[itemsIndex].revealed = true;
      setLootItems([...lootItems]);
    }, REVEAL_DURATION);
  }

  function goToItem(index: number) {
    setItemsIndex(index);
  }

  function getLootItems() {
    const rewardItems: IRewardItem[] = [];
    pendingReward.items.forEach((item) => {
      const exists = rewardItems.findIndex(
        (rewardItem) => rewardItem.item.itemId === item.itemId,
      );

      exists === -1
        ? rewardItems.push({ item, quantity: item.stack, revealed: false })
        : (rewardItems[exists].quantity += item.stack);
    });

    return rewardItems;
  }

  function renderClose() {
    return (
      <Fade in={currentLootItem.revealed}>
        <div className={classes.closeButtonCont}>
          <Button
            className={classes.closeButton}
            color={'secondary'}
            onClick={onClose}>
            <SvgX height={25} width={25} />
          </Button>
        </div>
      </Fade>
    );
  }

  function renderPlaceholder() {
    return (
      <Fade in={true} timeout={500}>
        <div className={classes.loot}>
          <div className={classes.lootImg}>
            <img className={bossStyles.shakeAnimation} src={iconSrc} />
          </div>
        </div>
      </Fade>
    );
  }

  function renderLootItem() {
    if (!currentLootItem.revealed) {
      revealItem();
      return renderPlaceholder();
    }

    const itemDef = itemDefs[currentLootItem.item.itemId];

    return (
      <Fade in={true} timeout={500} key={currentLootItem.item.itemId}>
        <div className={classes.loot}>
          <Typography variant={'h1'}>{itemDef.name}</Typography>
          <div className={classes.tags}>
            <div className={classes.tag}>{itemDef.rarity}</div>
            <div className={classes.tag}>
              {itemDef.itemGroup.split('_').join(' ')}
            </div>
          </div>
          <Grow in={true} timeout={500}>
            <div className={classes.lootImg}>
              <img
                src={`${cdnRoot}/images/icons/boss_loot/${itemDef.icon}.png`}
              />
            </div>
          </Grow>
          <Typography variant={'h2'}>
            x {currentLootItem.quantity.toLocaleString()}
          </Typography>
        </div>
      </Fade>
    );
  }

  return (
    <Fade in={true}>
      <div
        ref={backdropRef}
        className={classes.root}
        onClick={(e: React.MouseEvent<HTMLElement>) => onBackdropClick(e)}>
        <div className={classes.revealCont}>
          {renderClose()}
          {lootItems.length > 1 && (
            <BossRewardsOverview
              lootItems={lootItems}
              show={currentLootItem.revealed}
              itemDefs={itemDefs}
              goToItem={goToItem}
              iconSrc={iconSrc}
            />
          )}
          {renderLootItem()}
        </div>
      </div>
    </Fade>
  );
};

export default BossRewardsReveal;
