import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Furdenza = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='none'
      stroke='#e0d7c5'
      strokeWidth={23}
      strokeMiterlimit={10}
      d='M85.86 278.21c1.23 1.72 1.99 7.06 17.51 2.97'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={23}
      strokeMiterlimit={10}
      d='M82.31 266.57c-.13 4.93 1.7 9.02 4.55 12.64'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={23}
      strokeMiterlimit={10}
      d='M101.79 235.57c-3.87 3.41-7.43 7.09-10.52 11.05'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={44}
      strokeMiterlimit={10}
      d='M84.57 257.39c-1.49 3.37-2.19 6.41-2.26 9.18'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={23}
      strokeMiterlimit={10}
      d='M91.26 246.62c-3.07 3.94-5.25 7.51-6.69 10.76'
    />
    <path
      fill='none'
      stroke='#315f8c'
      strokeWidth={23}
      strokeMiterlimit={10}
      d='M145.13 210.05c-7.91 3.24-16.83 7.43-25.5 12.64'
    />
    <path
      fill='none'
      stroke='#e0d7c5'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M90.19 227.83c-.79.54-1.58 1.09-2.35 1.65-3.72 2.69-7.15 5.56-10.08 8.58-1.71 1.77-3.25 3.59-4.57 5.45-2.57 3.64-4.31 7.46-4.9 11.42'
    />
    <path
      fill='none'
      stroke='#db8e54'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M73.2 243.51c-2.57 3.64-4.31 7.46-4.9 11.42'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M118.32 212.65c-5.31 2.33-11.15 5.13-16.91 8.31-3.83 2.12-7.63 4.42-11.22 6.87'
    />
    <path
      fill='none'
      stroke='#fcd265'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M118.32 212.65c-5.31 2.33-11.15 5.13-16.91 8.31'
    />
    <path
      fill='none'
      stroke='#1f3359'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M73.2 243.51c-2.57 3.64-4.31 7.46-4.9 11.42'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M90.19 227.83c-.79.54-1.58 1.09-2.35 1.65'
    />
    <path
      fill='none'
      stroke='#543e2e'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M70.53 281.51C72.32 285.61 77 290 76 293'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={14}
      strokeMiterlimit={10}
      d='M66.5 265.5s.31 7.49 4.03 16.01'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M119.01 271.04c.7 2.73-1.75 8.44-1.53 11.13'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M106.93 242.06c-1.19 4.35-1.25 8.56-.73 12.68'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M123.23 220.6a46.15 46.15 0 0 0-7.51 6.52'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M108.5 265.19c.85 2.99 4.02 9.37 4.76 12.29'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M110.1 234.6c-1.47 2.53-2.5 5.01-3.17 7.46'
    />
    <path
      fill='none'
      stroke='#fcbc19'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M134.63 214.76c-3.82 1.38-7.69 3.27-11.4 5.85'
    />
    <path
      fill='none'
      stroke='#e67d32'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M116.48 281.17c.29 3.65 1.99 7.25 2.02 11.33'
    />
    <path
      fill='none'
      stroke='#543e2e'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M106.2 254.74c.45 3.55 1.33 7.03 2.3 10.45'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M115.72 227.13c-2.36 2.54-4.2 5.03-5.63 7.47'
    />
    <path
      fill='none'
      stroke='#e67d32'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M208.05 260c1.32 4.31 3.52 7.91 6.16 11.1'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M210.36 233.15c-1.24 2.93-2.22 6.1-2.86 9.53'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M206.74 252c.11 2.93.57 5.57 1.31 7.99'
    />
    <path
      fill='none'
      stroke='#fcbc19'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M221.44 216.37c-2.49 3.2-9.32 12.62-11.07 16.78'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M207.51 242.68c-.63 3.4-.86 6.5-.76 9.32'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M129.89 263.43c.36 1.17.81 2.54 1.28 4.09'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M135.08 231.59c-3.23 3.88-5.32 8.42-6.32 13.28'
    />
    <path
      fill='none'
      stroke='#315f8c'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M131.17 267.52c.48 1.57 1 3.32 1.5 5.22'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M143.73 224.47c-3.48 1.95-6.35 4.36-8.65 7.12'
    />
    <path
      fill='none'
      stroke='#543e2e'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M132.66 272.73c1.4 5.33 3.68 12.85 3.77 19.96'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M128.76 244.87c-1.22 5.94-.81 12.35 1.12 18.57'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={19}
      strokeMiterlimit={10}
      d='M171.5 209.5c-8.91 4.01-24.32 13.04-27.77 14.97'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M160.47 293.66s-.57-3.95-1.06-5.43c-.81-2.45-3.45-3.43-4.65-6.43'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={31}
      strokeMiterlimit={10}
      d='M178.99 252.87c.03 7.24 1.2 13.96 2.95 20.37'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={31}
      strokeMiterlimit={10}
      d='M180.1 240.15c-.8 4.43-1.13 8.65-1.12 12.72'
    />
    <path
      fill='none'
      stroke='#543e2e'
      strokeWidth={31}
      strokeMiterlimit={10}
      d='M181.94 273.24c1.51 5.53 5.6 11.13 7.04 18.6'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={31}
      strokeMiterlimit={10}
      d='M184.71 220.97c-1.71 4.68-3.84 14.9-4.61 19.18'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={16}
      strokeMiterlimit={10}
      d='M216.07 269.69c4.93-6.17 8.51-13.7 10.74-21.97'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={16}
      strokeMiterlimit={10}
      d='M203.5 280.54c4.88-2.78 9.07-6.47 12.57-10.85'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={16}
      strokeMiterlimit={10}
      d='M226.81 247.72c2.72-10.11 3.51-24.58 2.19-35.72'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={16}
      strokeMiterlimit={10}
      d='M188.54 285.8c5.95-.77 10.27-2.59 14.96-5.26'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M155.28 234.82c2.8-4.01 6.44-6.72 10.48-8.73'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M150.26 265.84c-.61-3.95-.86-8.03-.63-12.18'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M176.85 222.02c3.33-.96 13.27-3.79 13.27-9.37'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M151.42 242.91c.97-3.18 2.28-5.84 3.85-8.09'
    />
    <path
      fill='none'
      stroke='#fcbc19'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M154.76 281.79c-2.01-4.98-3.63-10.34-4.5-15.95'
    />
    <path
      fill='none'
      stroke='#543e2e'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M165.76 226.09c3.49-1.74 7.28-2.97 11.09-4.07'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={30}
      strokeMiterlimit={10}
      d='M149.63 253.66c.23-4.13.85-7.68 1.79-10.75'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m132.95 196.17-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h113.95c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill='#543e2e'
      d='M131.21 189.68c-3.31.73-4.45 6.48-2.65 9.55 7.67 13.09 38.13 20.17 38.13 20.17.93-2.67 12.94-3.69 10.16-4.2-13.68-2.55-42.06-20.74-45.64-25.52z'
    />
    <path
      fill='#fcbc19'
      d='M241.34 206.9c-7.2 4.18-15.13 7.33-23.43 9.33-3.22.78-4.56 4.62-2.55 7.25 0 0 19.09-2.21 26.34-8.3 2.54-2.15 2.79-6.83-.36-8.28z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M241.91 205.55c2.16 3.41 1.26 9.67-2.26 11.66-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M80.68 293.8h24.12v-1.28c0-6.08-4.93-11.01-11.01-11.01M154.48 268.6l5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Furdenza;
