import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Rat = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M60.54 184.2c6.3 8.18 19.6 8.78 27.39 1.99 9.73-8.48 12.77-19.76 4.9-29.99-9.84-12.78-24.98-15.86-38.26-4.18-15.15 13.32-15.75 63.05 19.13 76.15l-10.17 21.36c-40.47-23.66-40.68-85.32-15.81-106.27 16.88-14.22 40.56-10.95 52.58 4.51 11.36 14.61 7.67 31.27-4.44 41.2-9.69 7.95-26.27 6.43-34.22-3.27'
    />
    <g opacity={0.3} fill='#FFF'>
      <path d='M88.25 185.9c-.11.1-.22.19-.33.29-7.78 6.79-21.09 6.19-27.39-1.99l1.1 1.5c3.95 4.82 10.04 7.62 16.35 8.24 2.13-.78 4.13-1.92 5.86-3.42 1.68-1.47 3.15-3.02 4.41-4.62zM51.49 155.48c-.34.28-.67.56-1.01.85-15.15 13.32-15.75 63.05 19.13 76.15l-7.66 16.09c.52.32 1.04.64 1.57.95l10.17-21.36c-32.33-12.14-34.18-55.77-22.2-72.68zM100.29 147.76c-12.02-15.46-35.7-18.72-52.58-4.51a37.82 37.82 0 0 0-4.91 5.04c.27-.25.55-.49.83-.72 16.88-14.22 40.56-10.95 52.58 4.51 9.57 12.3 8.46 26.05.64 36.02 11.31-10.01 14.49-26.14 3.44-40.34z' />
    </g>
    <path
      d='M49.54 141.81c-.62.46-1.23.94-1.83 1.45-24.87 20.95-24.66 82.6 15.81 106.27l3.1-6.52c-35.02-23.84-36.91-77.34-17.08-101.2z'
      opacity={0.12}
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M63.52 249.52c-40.47-23.66-41.88-84-15.81-106.27 16.78-14.33 40.56-10.95 52.58 4.51 11.36 14.61 7.67 31.27-4.44 41.2-9.69 7.95-26.27 6.43-34.22-3.27-6.36-7.75-5.22-19.19 2.53-25.55 6.2-5.09 15.35-4.18 20.44 2.02'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M74.77 228.52c-34.88-13.1-38.61-60.48-20.21-76.51 13.34-11.62 28.42-8.6 38.26 4.18 7.87 10.23 4.83 21.51-4.9 29.99-7.78 6.79-21.09 6.19-27.39-1.99'
    />
  </svg>
);

export default Rat;
