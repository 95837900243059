import React, { FunctionComponent } from 'react';
import { Theme, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
  },
  main: {
    width: '90% !important',
    margin: '1rem auto !important',
    textAlign: 'center',
    maxWidth: '1000px',
  },
  card: {
    backgroundColor: 'white',
    borderRadius: '8px',
    maxWidth: '400px',
  },
}));

interface IMarket {}

export const Market: FunctionComponent<IMarket> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <div className={clsx(classes.root, common.fullScreen, common.furBk)}>
      <Grid container className={classes.main} spacing={2}>
        <Grid item xs={12} lg={6}>
          <div className={classes.card}>
            <Typography variant={'h4'}>
              <Link to={'/market/furballs'}>Borrow Furballs</Link>
            </Typography>
            <Typography variant={'body1'}>
              Acquire new Furballs for Boss Fights.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={classes.card}>
            <Typography variant={'h4'}>
              <Link to={'/market/loot'}>Buy/Sell Loot</Link>
              <Typography variant={'body1'}>
                Use wFUR to trade with other players.
              </Typography>
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Market;
