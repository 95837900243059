import { gameCdnRoot } from '../theme';
import React from 'react';
import { IFurball, IFurballBase } from '../wallet/WalletTypes';
import { GameBuildFragment } from './schema';
import { useUnityContext } from 'react-unity-webgl';
import { IUnityContextHook } from 'react-unity-webgl/distribution/interfaces/unity-context-hook';
import { IClientHardware } from '../pages/App/clientHardware';
import { IInAppPurchaseItem } from '../pages/Tickets/TicketPurchaseApp';
import { ILogEntry, useFurComponent } from '../utils';

export interface IUnityClientPlayerArgs {
  id: string;
  type: string;
  username?: string;
  avatar: string;
  tix: number;
  wFur: number;
  fur: number;
  furballs: IFurball[];
}

export interface IUnityOptionalArgs {
  furballId?: string;
  furball?: IFurballBase;
  unreadNotificationCount?: number;
}

export interface IUnityClientArgs extends IUnityOptionalArgs {
  networkTimeout?: number;
  buildNumber?: number;
  api: string;
  player?: IUnityClientPlayerArgs;
  secret?: string;
  clientHardware?: IClientHardware;

  products: IInAppPurchaseItem[];
}

export interface IUnityBuild {
  buildName: string;
  webCodeFileHash: string;
  webDataFileHash: string;
  webFrameworkFileHash: string;
  productName?: string;
  streamingAssetsFolder?: string;
}

function useUnityBuild(
  build: IUnityBuild,
  cdn: string | undefined = undefined,
  beforeShutdown?: () => void,
) {
  const qp = '';
  const { log, logManager } = useFurComponent(useUnityBuild.name);
  const buildRoot = `${cdn ?? gameCdnRoot}/${build.buildName}`;
  const streamingAssets = build.streamingAssetsFolder ?? 'StreamingAssets';
  const unityContext = useUnityContext({
    loaderUrl: `${buildRoot}/Build/${build.buildName}.loader.js${qp}`,
    dataUrl: `${buildRoot}/Build/${build.webDataFileHash}${qp}`,
    frameworkUrl: `${buildRoot}/Build/${build.webFrameworkFileHash}${qp}`,
    codeUrl: `${buildRoot}/Build/${build.webCodeFileHash}${qp}`,
    streamingAssetsUrl: `${buildRoot}/${streamingAssets}`,
    companyName: 'LFG Gaming LLC',
    productName: build.productName ?? 'Furballs',
    productVersion: '1.0.0',
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  function handleLog(json: string): void {
    try {
      logManager.pipeToServer('Unity.WebGL', JSON.parse(json) as ILogEntry);
    } catch (e: any) {
      log.warn(e, 'Failed to deserialize WriteLog', json);
    }
  }

  React.useEffect(() => {
    unityContext.addEventListener('WriteLog', handleLog);

    return () => {
      unityContext.removeEventListener('WriteLog', handleLog);
    };
  }, []);

  useUnitySafeUnload(unityContext, beforeShutdown);
  return unityContext;
}

export function useUnityGameBuild(
  build: GameBuildFragment,
  cdn?: string,
  beforeShutdown?: () => void,
) {
  const buildName = 'bosses';
  return useUnityBuild(
    {
      buildName,
      webDataFileHash: build.webDataFileHash,
      webFrameworkFileHash: build.webFrameworkFileHash,
      webCodeFileHash: build.webCodeFileHash,
      streamingAssetsFolder: `StreamingAssets-${build.id}`,
    },
    cdn,
    beforeShutdown,
  );
}

// WIDGET ONLY>>>>
export function useUnityBuildName(
  buildName: string,
  clientArgs: IUnityClientArgs,
  nonce: string | undefined = '',
  cdn: string | undefined = undefined,
): IUnityContextHook {
  const unityContext = useUnityBuild(
    {
      buildName,
      webDataFileHash: `${buildName}.data.unityweb`,
      webFrameworkFileHash: `${buildName}.framework.js.unityweb`,
      webCodeFileHash: `${buildName}.wasm.unityweb`,
    },
    cdn,
  );

  React.useEffect(() => {
    if (unityContext) {
      // log.info('[ARGS] change', clientArgs);
      const jsonArgs = JSON.stringify(clientArgs);

      if (unityContext.loadingProgression < 1) return;

      // Can't sendMessage before bridge is ready
      const to = setTimeout(() => {
        unityContext.sendMessage('ClientBridge', 'LoadClientArgs', jsonArgs);
      }, 100);
      return () => clearTimeout(to);
    }
  }, [clientArgs]);

  return unityContext;
}

export function useUnitySafeUnload(
  unityContext: IUnityContextHook,
  shutdown?: () => void,
) {
  const {
    isLoaded,
    UNSAFE__detachAndUnloadImmediate: detachAndUnloadImmediate,
  } = unityContext;
  const { log } = useFurComponent(useUnitySafeUnload.name);

  React.useEffect(() => {
    return () => {
      if (!isLoaded) return;
      log.info('[UNITY] detatch & unload');
      if (shutdown) shutdown();
      detachAndUnloadImmediate().catch((reason) => {
        log.warn('[UNITY]', 'detach & unload failed', reason);
      });
    };
  }, [detachAndUnloadImmediate, isLoaded]);
}

export function usePaused(): boolean {
  const [paused, setPaused] = React.useState(false);

  React.useEffect(() => {
    const onBlur = () => {
      setPaused(true);
    };

    const onFocus = () => {
      setPaused(false);
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    const onVisChange = () => {
      // const paused = document.visibilityState !== 'visible';
      setPaused(document.visibilityState !== 'visible');
    };

    document.addEventListener('visibilitychange', onVisChange);

    return () => {
      document.removeEventListener('visibilitychange', onVisChange);
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
      // window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return paused;
}

export function useDevicePixelRatio(): number {
  // We'll use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = React.useState(
    window.devicePixelRatio,
  );

  React.useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`,
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener('change', updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
      };
    },
    [devicePixelRatio],
  );

  return devicePixelRatio;
}
