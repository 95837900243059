import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Cap = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#96fff4'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M189.24 79.36c0-1.98 1.61-3.59 3.59-3.59 1.98 0 3.59 1.61 3.59 3.59M181.22 104.18s-2.75.42-4.81-.53c-.96-.44-1.56-1.42-1.56-2.48v-5.49c0-6.96 4.31-11.57 10.85-13.95 2.18-.79 4.79-1.31 7.91-1.37 7.9-.17 12.45 3.02 15.05 6.41 2.21 2.89 3.36 4.76 3.36 8.4v7.28'
    />
    <path
      fill='#96fff4'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M184.53 102.69c-.94-25.07 9.07-22.32 9.07-22.32s9.05-1.33 8.93 20.5M181.61 104.13s4.45 10.78 16.2 7.16c6.42-1.98 12.94-5.08 21.47-3.25 1.68.36 3.76-1.69 2.4-3.24-1.8-2.04-9.84-3.96-9.84-3.96s-9.32.08-30.23 3.29z'
    />
  </svg>
);

export default Cap;
