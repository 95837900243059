import * as React from 'react';

function SvgRealmCreatureTurtleSea(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 284.06 232.73'
      {...props}>
      <path
        opacity={0.12}
        d='M235.84 158.97c-4.06 0-7.98.16-11.7.45-14.37-11.37-47.77-22.99-86.77-22.05l-4.69.11c-3.25.08-6.48.13-9.71.21-38.21.81-71.19 1.53-83.56 13.7-3.69 3.63 9.24 12.77 9.65 18.09.36 4.61-12.55 4.49-7.28 8.32-6.44 4.31-13.57 7.77-21.16 10.22l-3.26 1.05c-3.47 1.12-5.73 3.88-5.89 7.2-.16 3.32 1.84 6.26 5.2 7.64l8.72 3.58a51.169 51.169 0 0020.56 3.78c9.93-.23 19.69-3.35 27.8-9.25l5.12-3.7c8.32-6.01 18.74-9.18 29.42-9.03 7.8.11 16.41.06 25.92-.16 10.86-.26 20.86-.78 30.01-1.56-5.86 6.3-14.8 13.72-28.45 21.62-2.95 1.71-11.18-.57-10.27 2.48.93 3.25 4.05 10.92 14.87 10.91.05-.01 40.86 1.87 61.39-20.84 1.87-2.07 3.52-4.14 4.74-6.4 4.37-8.07 12.76-13.05 21.93-12.62 2.41.11 4.88.17 7.4.17 23.89 0 33.74-5.35 33.74-11.95.01-6.62-9.84-11.97-33.73-11.97z'
      />
      <path
        fill='#CBEE5E'
        d='M163.73 166.37c-2.72 7.48-10.82 21.31-35.01 36.9a5.819 5.819 0 00-2.47 6.34 5.78 5.78 0 005.89 4.31c15.21-.77 69.96-5.81 66.89-40.99-1.95-22.38-15.72-20.94-25.11-17.17-4.75 1.91-8.44 5.78-10.19 10.61z'
      />
      <path
        fill='#A2D257'
        d='M199.03 172.92c-1.6-18.17-10.94-20.62-19.43-18.9.79 1.59 1.53 3.42 2.2 5.5 10.23 31.07-33.75 48.3-51.95 54.02.7.26 1.49.39 2.28.35 15.23-.74 69.98-5.78 66.9-40.97z'
      />
      <path d='M132.05 215.39c-3.34.08-6.37-2.16-7.21-5.42-.82-3.06.44-6.26 3.09-7.98 24.16-15.56 31.92-29.32 34.4-36.15a19.55 19.55 0 0111.06-11.47c7.44-2.99 13.82-3.06 18.44-.21 4.97 3.05 7.88 9.31 8.68 18.63.73 8.31-1.59 15.62-6.92 21.76-14.6 16.77-47.78 20.18-61.37 20.85-.07 0-.12-.01-.17-.01zm33.08-48.52c-6.06 16.71-24.92 30.74-35.59 37.64a4.315 4.315 0 00-1.84 4.72c.51 1.95 2.39 3.31 4.36 3.2 39.64-2 54.65-14.52 59.28-19.82 4.78-5.47 6.87-12.06 6.22-19.53-.73-8.33-3.18-13.83-7.29-16.36-4.78-2.95-11.22-1.38-15.77.44a16.473 16.473 0 00-9.37 9.71z' />
      <path
        fill='#CBEE5E'
        d='M271.1 101.36c-.2-7.41-3.6-14.18-9.24-19.76a18.378 18.378 0 01-4.35-6.83c-5.48-15.12-21.05-25.34-39.16-25.34-49.86.05-45.08 48.13-44.88 55.53.36 13.27 8.73 24.19 22.77 29.7l4.33 33.05c23.63-8.44 28.98-21.73 33.98-32.2 18.58-5.81 36.95-19.16 36.55-34.15z'
      />
      <path
        fill='#A2D257'
        d='M259.52 122.34c-7.87 3.76-14.53 6-24.69 6.28-28.36.76-46.71-13.79-47.24-33.58-.14-4.85-2.25-27.26 10.45-42.08-28.09 11.19-24.73 45.85-24.56 52.04.4 15.06 5.24 24.66 22.83 29.22-9.5 4.08-12.04 16.36-4.92 23.88l9.17 9.68c30.02-3.07 32.49-24.62 32.45-32.09 11.56-2.58 19.91-6.49 26.51-13.35z'
      />
      <path d='M196.98 169.47l-5.23-10.62c-2.95-3.84-4.22-8.59-3.55-13.39.56-4.02 2.39-7.62 5.26-10.37-13.32-6.02-21.1-16.89-21.45-30.09-.02-.37-.02-.82-.07-1.38-.32-7.19-1.32-29.06 12.21-43.19 7.9-8.28 19.44-12.48 34.22-12.48 18.56 0 34.87 10.55 40.55 26.32.89 2.43 2.21 4.55 3.99 6.3 6.15 6.04 9.47 13.22 9.69 20.77.42 16.03-18.75 29.52-36.97 35.42-.78 1.76-1.35 3.67-1.95 5.68-2.89 9.62-6.49 21.58-35.6 26.83l-1.1.2zM216.5 50.95c-13.05.33-23.22 4.22-30.21 11.55-12.64 13.23-11.68 34.13-11.38 41 .02.57.05 1.06.05 1.44.34 12.71 8.29 23.03 21.83 28.35l2.5.99-2.19 1.61c-3.31 2.41-5.42 5.97-6 10.02-.56 4.04.52 8.04 3.02 11.26l.15.25 4.31 8.75c26.3-5.01 29.42-15.4 32.2-24.56.71-2.35 1.39-4.57 2.37-6.7l.28-.58.62-.2c17.93-5.64 35.89-18.69 35.51-32.71-.17-6.76-3.21-13.25-8.79-18.72-2.09-2.06-3.68-4.55-4.7-7.41-5.26-14.57-20.46-24.37-37.75-24.34-.58-.02-1.21-.02-1.82 0z' />
      <path
        fill='#CBEE5E'
        d='M90.77 178.03l-19.5 15.67a40.146 40.146 0 01-41.88 5.23l-7.84-3.58c-5.31-2.44-5-10.11.51-12.06l2.93-1.05a74.159 74.159 0 0031.23-21.43l34.55 17.22z'
      />
      <path
        fill='#A2D257'
        d='M78.63 172.34l-19.65 15.47a40.186 40.186 0 01-39.84 5.68c.61.77 1.42 1.44 2.44 1.89l7.84 3.58a40.231 40.231 0 0041.88-5.23l19.5-15.67-12.17-5.72z'
      />
      <path d='M47.25 204.08c-6.22.15-12.53-1.07-18.47-3.78l-7.84-3.58c-3.02-1.37-4.82-4.31-4.67-7.64.15-3.32 2.17-6.08 5.29-7.2l2.93-1.05c11.81-4.23 22.39-11.5 30.61-21.01l.76-.89 37.65 18.76-21.29 17.13c-7.29 5.91-16.05 9.03-24.97 9.26zm9.34-41.4a75.973 75.973 0 01-31.09 20.99l-2.93 1.05c-2.36.83-3.26 2.88-3.33 4.53s.68 3.74 2.94 4.79l7.84 3.58c13.41 6.11 28.86 4.2 40.34-5.04l17.69-14.21-31.46-15.69z' />
      <path
        fill='#CFD867'
        d='M208.69 152.65c.1 12.74-16.75 26.1-82.22 27.83-76.21 2.01-82.7-10.75-83.58-23.43-1.77-25.35 36.51-24.03 82.28-25.26 45.8-1.23 83.35-3 83.52 20.86z'
      />
      <path
        fill='#E6E592'
        d='M212.65 152.54c.1 12.74-20.72 26.2-86.18 27.94-76.21 2.01-87.19-10.13-88.06-22.85-1.77-25.35 40.98-24.65 86.78-25.85 45.78-1.22 87.29-3.09 87.46 20.76z'
      />
      <path d='M126.53 181.94c-77.13 2.05-88.62-10.43-89.59-24.23-.37-5.32 1.12-9.88 4.43-13.51 11.1-12.17 40.73-12.89 75.05-13.7 2.9-.08 5.8-.13 8.72-.21l4.21-.11c36.23-.97 67.49-1.83 79.61 9.9 3.4 3.29 5.15 7.48 5.18 12.44.03 5.44-3.3 13.24-19.46 19.49-14.98 5.8-37.91 9.14-68.15 9.93zm-18.35-48.25c-29.05.77-55.26 2.27-64.63 12.54-2.79 3.04-3.98 6.74-3.66 11.3.82 11.73 11.72 23.43 86.55 21.46 53.15-1.39 84.83-11.28 84.75-26.42l1.49-.01-1.49.01c-.03-4.2-1.44-7.58-4.28-10.32-11.22-10.85-43.39-9.97-77.47-9.06l-4.21.11c-2.92.08-5.84.16-8.75.21-2.78.04-5.55.1-8.3.18z' />
      <path
        fill='#F9FCC5'
        d='M126.22 170.53c55.42-1.74 70.02-8.27 76.51-17.06 2.35-3.17 2.19-7.57-.47-10.48-10.88-11.96-40.58-12.17-77.06-11.2-41.44 1.1-77.94 1.48-82.95 20.1.56 10.21 2.13 21.2 83.97 18.64z'
      />
      <path
        fill='#6BB251'
        d='M58.12 141.77l7.42 4.02c4.28 2.34 7 6.76 7.15 11.66l.07 2.65c.02 0 .08.02.1.01l-.07-2.65c-.14-4.88 2.36-9.45 6.53-12.02l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36l7.42 4.02c3.51 1.89 5.94 5.2 6.8 8.99.66-3.83 2.93-7.26 6.33-9.34l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36l7.42 4.02c4.28 2.34 7 6.76 7.15 11.66l.15 5.97c.12-.01.22-.03.34-.04l-.15-5.95c-.14-4.88 2.36-9.45 6.53-12.02l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36 0 0 11.97 7.55 13.19 10.09 2.99-2.57 4.04-4.99 3.99-6.69-.39-14.34-6.14-27.44-15.56-37.93l-2.84 1.76c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-3.51-1.89-5.94-5.2-6.8-8.99-.61 3.58-2.63 6.8-5.63 8.9 3.86-1.76 8.38-1.63 12.15.42l7.42 4.02c4.28 2.34 7 6.76 7.15 11.66l.22 8.42c.14 4.88-2.36 9.45-6.53 12.02l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-3.51-1.89-5.94-5.2-6.8-8.99-.66 3.83-2.93 7.26-6.33 9.34l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-4.28-2.34-7-6.76-7.15-11.66l-.22-8.42c-.14-4.88 2.36-9.45 6.53-12.02l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36l7.42 4.02c3.51 1.89 5.94 5.2 6.8 8.99.61-3.58 2.63-6.8 5.63-8.9a13.642 13.642 0 01-12.15-.42l-7.42-4.02c-4.28-2.34-7-6.76-7.15-11.66l-.22-8.42c-.02-1.02.08-2.02.26-3.01-.24.03-.51.09-.76.12.24.94.38 1.92.4 2.91l.22 8.42c.14 4.88-2.36 9.45-6.53 12.02l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-2.9-1.56-5.06-4.09-6.22-7.05a72.522 72.522 0 00-9.68 8.92l1.92-1.18c4.16-2.56 9.37-2.7 13.67-.36l7.42 4.02c4.28 2.34 7 6.76 7.15 11.66l.22 8.42c.14 4.88-2.36 9.45-6.53 12.02l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-4.28-2.34-7-6.76-7.15-11.66l-.06-2.33c-.66 2.8-.59 5.65-.35 8.54.18 2.15 1.8 3.88 3.92 4.26 1.3.24 2.55.74 3.98 1.52z'
      />
      <path
        fill='#82C771'
        d='M112.92 91.45c.14 4.88 2.86 9.32 7.15 11.66l7.42 4.02c3.78 2.05 8.29 2.18 12.15.42.23-.15.46-.3.69-.46l7.2-4.4c.48-.31 1-.57 1.49-.8 3.03-2.1 5.02-5.33 5.63-8.9-.2-.87-.31-1.75-.34-2.64l-.02-.54c-.09-3.64-2.25-6.91-5.57-8.45-5.77-2.68-10.01-4.71-18.33-4.47-3.05.07-10.3 1.57-14.94 2.62-1.62.37-2.81 1.83-2.78 3.49v.02l.25 8.43z'
      />
      <path
        fill='#6BB251'
        d='M134.4 110.25c-2.63.07-5.28-.53-7.62-1.81l-7.42-4.02c-4.73-2.58-7.77-7.54-7.92-12.91l-.22-8.42c-.06-2.38 1.6-4.47 3.92-5 1.86-.42 11.32-2.53 15.21-2.65 8.64-.22 13.17 1.89 18.91 4.58 3.92 1.84 6.39 5.57 6.5 9.78l.02.54c.02.79.12 1.58.28 2.35l.06.29-.04.28c-.69 3.96-2.96 7.56-6.26 9.87l-.23.13c-.47.21-.91.46-1.33.71l-7.17 4.39c-.21.12-.41.27-.6.4l-.23.13c-1.87.86-3.86 1.3-5.86 1.36zm-3.95-31.87c-2.63.07-9.26 1.34-14.65 2.59-.97.22-1.64 1.07-1.62 2.01l.22 8.45c.13 4.33 2.54 8.32 6.37 10.39l7.42 4.02c3.3 1.79 7.29 1.95 10.71.45.21-.15.41-.27.64-.43l7.2-4.4c.48-.31 1-.57 1.54-.83 2.52-1.79 4.27-4.54 4.88-7.57-.18-.87-.29-1.75-.29-2.65l-.02-.54c-.07-3.07-1.89-5.81-4.71-7.13-5.7-2.68-9.76-4.57-17.69-4.36z'
      />
      <path
        fill='#82C771'
        d='M154.66 93c.86 3.8 3.32 7.1 6.8 8.99l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l2.84-1.76c-6.54-7.29-14.87-13.31-24.41-17.63-2.92-1.31-6.21.85-6.12 4.04.01.94-.06 1.84-.2 2.7z'
      />
      <path
        fill='#6BB251'
        d='M175.79 109.16c-2.63.07-5.26-.53-7.62-1.81l-7.42-4.02a15.127 15.127 0 01-7.54-9.97l-.06-.29.04-.28c.13-.79.18-1.59.16-2.38-.04-2.01.93-3.86 2.58-4.98a5.831 5.831 0 015.63-.46c9.62 4.33 18.25 10.57 24.91 17.99l1.2 1.32-4.37 2.69c-2.3 1.36-4.89 2.12-7.51 2.19zm-19.63-16.22c.82 3.3 3 6.12 6 7.75l7.42 4.02c3.8 2.08 8.48 1.93 12.19-.33l1.32-.81c-6.2-6.53-14.02-12.02-22.72-15.94-.92-.41-1.93-.34-2.75.23-.83.55-1.29 1.45-1.27 2.44 0 .88-.04 1.78-.19 2.64z'
      />
      <path
        fill='#82C771'
        d='M78.06 104.2l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42V83c-.04-1.56-1.5-2.33-3.07-2.41-14.14-.61-25.9 1.58-35.93 15.24-.79 1.09-1.41 2.28-.7 3.44 1.18 2.05 2.92 3.78 5.1 4.93z'
      />
      <path
        fill='#6BB251'
        d='M92.39 111.37c-2.63.07-5.26-.53-7.62-1.81l-7.42-4.02a14.976 14.976 0 01-5.73-5.47c-1.33-2.2.27-4.38.78-5.09 10.28-14.04 22.31-16.48 37.2-15.84 2.62.11 4.42 1.65 4.49 3.86l.22 8.45c.15 5.4-2.63 10.51-7.21 13.32l-7.2 4.4c-2.29 1.37-4.91 2.14-7.51 2.2zm-13.62-8.47l7.42 4.02c3.8 2.08 8.48 1.93 12.19-.33l7.2-4.4c3.7-2.26 5.91-6.38 5.81-10.71l-.22-8.42c-.03-.89-1.49-.99-1.64-1-13.93-.58-25.14 1.67-34.66 14.64-.94 1.28-.72 1.65-.63 1.79 1.05 1.87 2.65 3.4 4.53 4.41z'
      />
      <path
        fill='#82C771'
        d='M114.34 153.67c-.86-3.8-3.32-7.1-6.8-8.99l-7.42-4.02c-4.28-2.34-9.51-2.18-13.67.36l-7.2 4.4a13.746 13.746 0 00-6.53 12.02l.07 2.65c9.26 1.79 22.53 3.02 41.5 3.09l-.18-6.86c.02-.88.09-1.76.23-2.65z'
      />
      <path
        fill='#6BB251'
        d='M115.87 164.69l-1.54-.01c-17.38-.07-31.44-1.13-41.77-3.11l-1.17-.23-.09-3.86c-.15-5.4 2.63-10.51 7.21-13.32l7.2-4.4c4.6-2.82 10.4-2.97 15.16-.41l7.42 4.02c3.83 2.07 6.58 5.72 7.54 9.97l.06.29-.04.28a12.7 12.7 0 00-.16 2.38l.18 8.4zm-41.58-5.84c9.73 1.76 22.67 2.73 38.51 2.83l-.15-5.32c-.03-.89.04-1.77.15-2.65-.82-3.3-3-6.12-6-7.75l-7.42-4.02c-3.8-2.08-8.48-1.93-12.19.33l-7.2 4.4c-3.7 2.26-5.91 6.38-5.81 10.71l.11 1.47z'
      />
      <path
        fill='#82C771'
        d='M148.93 143.58l-7.42-4.02c-4.28-2.34-9.51-2.18-13.67.36l-7.2 4.4c-3.4 2.08-5.67 5.5-6.33 9.34.2.87.31 1.75.34 2.64l.18 6.86c3.6.01 7.38-.02 11.39-.11 11.65-.21 21.56-.89 29.98-1.9l-.15-5.97c-.12-4.84-2.84-9.28-7.12-11.6z'
      />
      <path
        fill='#6BB251'
        d='M129.29 164.51c-.99.02-2.01.04-3.02.07-3.84.07-7.69.11-11.42.09h-1.44l-.23-8.3c-.02-.79-.12-1.58-.29-2.38l-.06-.29.04-.28c.72-4.31 3.3-8.08 7.02-10.36l7.2-4.4c4.6-2.82 10.4-2.97 15.16-.41l7.42 4.02c4.73 2.58 7.79 7.51 7.92 12.91l.19 7.33-1.35.16c-8.11.97-17.23 1.59-27.14 1.84zm-12.98-2.8c3.26 0 6.58-.02 9.9-.1 10.44-.19 20.01-.77 28.49-1.72l-.13-4.61c-.13-4.33-2.54-8.32-6.37-10.39l-7.42-4.02c-3.8-2.08-8.48-1.93-12.19.33l-7.15 4.42c-2.91 1.79-4.95 4.72-5.59 8.05.18.87.29 1.75.29 2.65l.17 5.39z'
      />
      <path
        fill='#82C771'
        d='M114.34 153.67c-.14.86-.21 1.76-.2 2.66l.18 6.86c.17 0 .35.01.52.01l-.18-6.86c-.01-.92-.11-1.8-.32-2.67z'
      />
      <path
        fill='#6BB251'
        d='M116.39 164.68l-3.48-.03-.23-8.3c-.02-.99.04-1.97.21-2.96l1.27-7.38 1.65 7.3c.22.97.33 1.95.38 2.94l.2 8.43z'
      />
      <path
        fill='#82C771'
        d='M65.51 145.8l-7.42-4.02c-.82-.45-1.66-.79-2.51-1.06-2.97-.91-5.84 1.67-5.15 4.7.04.14.06.27.1.41.7 2.95.87 10.11 22.19 14.24l-.07-2.65c-.11-4.87-2.83-9.31-7.14-11.62z'
      />
      <path
        fill='#6BB251'
        d='M74.26 161.89l-1.81-.35c-21.04-4.1-22.54-11.34-23.26-14.84-.05-.19-.07-.36-.11-.53l-.1-.41c-.43-1.94.41-3.72 1.86-5.06 1.47-1.37 3.28-2 5.18-1.41.98.3 1.92.69 2.8 1.18l7.42 4.02c4.73 2.58 7.76 7.51 7.92 12.91l.1 4.49zM54.3 142.03c-.62.02-1.21.25-1.68.7-.67.63-.94 1.48-.73 2.37l.1.39c.05.19.1.39.12.61.58 2.76 1.74 8.41 19.08 12.16l-.02-.77c-.13-4.33-2.54-8.32-6.37-10.39l-7.42-4.02c-.72-.39-1.47-.69-2.25-.95a2.78 2.78 0 00-.83-.1z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M65.51 145.8l-7.42-4.02c-.82-.45-1.66-.79-2.51-1.06-2.97-.91-5.84 1.67-5.15 4.7.04.14.06.27.1.41.7 2.95.87 10.11 22.19 14.24l-.07-2.65c-.11-4.87-2.83-9.31-7.14-11.62z'
      />
      <path
        fill='#82C771'
        d='M191.16 142.47l-7.42-4.02c-4.28-2.34-9.51-2.18-13.67.36l-7.2 4.4a13.746 13.746 0 00-6.53 12.02l.15 5.95c16.93-2.02 27.76-5.34 34.47-8.78 3.96-2.02 4.11-7.73.22-9.89.03-.03.01-.02-.02-.04z'
      />
      <path
        fill='#6BB251'
        d='M155.11 162.84l-.19-7.58c-.15-5.4 2.63-10.51 7.21-13.32l7.2-4.4c4.6-2.82 10.4-2.97 15.16-.41l7.47 4.07c2.31 1.26 3.67 3.61 3.61 6.27a7.17 7.17 0 01-3.87 6.26c-8.03 4.1-19.79 7.11-34.98 8.92l-1.61.19zm21.81-24.55c-2.11.06-4.19.66-6.04 1.8l-7.2 4.4c-3.7 2.26-5.91 6.38-5.81 10.71l.12 4.31c14.05-1.79 24.91-4.62 32.35-8.43a4.083 4.083 0 002.24-3.65c.01-1.54-.75-2.89-2.07-3.6l.63-1.34-.71 1.3-7.42-4.02a11.967 11.967 0 00-6.09-1.48z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M154.31 90.36l-.02-.54a9.614 9.614 0 00-4.61-7.92c-.86 2.94-2.71 5.54-5.28 7.35-.52.24-1.01.49-1.49.8l-7.2 4.4c-.23.15-.46.3-.69.46a13.642 13.642 0 01-12.15-.42l-7.42-4.02a13.3 13.3 0 01-2.61-1.87l.07 2.87c.14 4.88 2.86 9.32 7.15 11.66l7.42 4.02c3.78 2.05 8.29 2.18 12.15.42.23-.15.46-.3.69-.46l7.2-4.4c.48-.31 1-.57 1.49-.8 3.03-2.1 5.02-5.33 5.63-8.9-.19-.85-.3-1.76-.33-2.65z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M174.85 94.39a13.63 13.63 0 01-10.59-1.03l-7.42-4.02c-.56-.31-1.07-.64-1.59-1-.3.61-.47 1.27-.43 2.01.03.89-.03 1.79-.2 2.66.86 3.8 3.32 7.1 6.8 8.99l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l2.84-1.76c-3.09-3.47-6.63-6.65-10.5-9.51zM112.6 82.99c-.04-1.56-1.5-2.33-3.07-2.41l-1.37-.06c-.44 4.2-2.79 8.01-6.44 10.24l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-4.92-2.66c-.78.91-1.58 1.91-2.35 2.94-.79 1.09-1.41 2.28-.7 3.44 1.24 2.04 2.99 3.74 5.17 4.92l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42c-.05.04-.05.04-.05.01zM109.62 146.07l.12 4.46c-14.39-.05-25.49-.78-34.05-1.91-1.94 2.47-3 5.58-2.94 8.8l.07 2.65c9.26 1.79 22.53 3.02 41.5 3.09l-.18-6.86c-.03-.89.03-1.79.2-2.66a13.86 13.86 0 00-4.72-7.57z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M151.54 145.46l.08 3.1c-8.4.99-18.34 1.67-29.98 1.9-2.18.04-4.29.07-6.35.07-.45 1-.77 2.06-.96 3.17.2.87.31 1.75.34 2.64l.18 6.86c3.6.01 7.38-.02 11.39-.11 11.65-.21 21.56-.89 29.98-1.9l-.15-5.97c-.1-3.79-1.77-7.29-4.53-9.76zM191.24 142.51c-.03-.02-.05-.02-.08-.04l-4.89-2.66c-5.81 2.94-14.66 5.79-27.78 7.8-1.4 2.23-2.14 4.88-2.09 7.61l.15 5.95c16.93-2.02 27.76-5.34 34.47-8.78 3.98-2.01 4.13-7.75.22-9.88z'
      />
      <path
        fill='#82C771'
        d='M133.8 119.12c-.03-.89.03-1.79.2-2.66-.86-3.8-3.32-7.1-6.8-8.99l-7.42-4.02c-4.28-2.34-9.51-2.18-13.67.36l-7.2 4.4a13.746 13.746 0 00-6.53 12.02l.22 8.42c.12 4.88 2.86 9.32 7.15 11.66l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c3.4-2.08 5.67-5.5 6.33-9.34-.2-.87-.31-1.75-.34-2.64l-.23-8.47z'
      />
      <path
        fill='#6BB251'
        d='M114.11 147.44c-2.63.07-5.26-.53-7.62-1.81l-7.42-4.02c-4.73-2.58-7.79-7.53-7.92-12.91l-.22-8.42c-.15-5.4 2.63-10.51 7.24-13.32l7.2-4.4c4.6-2.82 10.4-2.97 15.16-.41l7.42 4.02c3.83 2.07 6.58 5.72 7.54 9.97l.06.29-.04.28a12.7 12.7 0 00-.16 2.38l.22 8.42c.02.79.12 1.58.28 2.35l.06.29-.04.28c-.72 4.31-3.3 8.08-7.02 10.36l-7.2 4.4a15.28 15.28 0 01-7.54 2.25zm-1.18-44.16c-2.11.06-4.19.66-6.04 1.8l-7.2 4.4c-3.7 2.26-5.91 6.38-5.81 10.71l.22 8.42c.13 4.33 2.54 8.32 6.37 10.39l7.42 4.02c3.8 2.08 8.48 1.93 12.19-.33l7.2-4.4c2.91-1.79 4.95-4.72 5.59-8.05-.18-.87-.29-1.75-.29-2.65l-.22-8.42c-.03-.89.04-1.77.15-2.65-.82-3.3-3-6.12-6-7.75l-7.42-4.02a12.233 12.233 0 00-6.16-1.47z'
      />
      <path
        fill='#82C771'
        d='M141.13 106.76c-.48.31-1 .57-1.49.8-3.03 2.1-5.02 5.33-5.63 8.9.2.87.31 1.75.34 2.64l.22 8.42c.03.89-.03 1.79-.2 2.66.86 3.8 3.32 7.1 6.8 8.99l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42c-.14-4.88-2.86-9.32-7.15-11.66l-7.42-4.02a13.643 13.643 0 00-12.15-.42c-.23.15-.46.3-.69.46l-7.23 4.41z'
      />
      <path
        fill='#6BB251'
        d='M155.5 146.35c-2.63.07-5.26-.53-7.62-1.81l-7.42-4.02c-3.83-2.07-6.58-5.72-7.56-9.97l-.06-.29.04-.3c.13-.79.18-1.59.16-2.38l-.22-8.42c-.02-.79-.12-1.58-.28-2.35l-.06-.29.04-.28c.69-3.96 2.96-7.56 6.26-9.87l.23-.13c.47-.21.94-.46 1.33-.71l7.17-4.39c.21-.12.41-.27.6-.4l.23-.13c4.29-1.96 9.33-1.77 13.48.49l7.42 4.02c4.73 2.58 7.79 7.53 7.92 12.91l.22 8.42c.15 5.4-2.63 10.51-7.24 13.32l-7.17 4.39a15.365 15.365 0 01-7.47 2.19zm-19.62-16.22c.82 3.3 3 6.12 6 7.75l7.42 4.02c3.8 2.08 8.48 1.93 12.19-.33l7.17-4.39c3.7-2.26 5.91-6.38 5.81-10.71l-.22-8.42c-.13-4.33-2.54-8.32-6.37-10.39l-7.42-4.02c-3.3-1.79-7.29-1.95-10.71-.45-.21.15-.41.27-.64.43l-7.2 4.4c-.49.28-1 .57-1.54.83-2.52 1.79-4.27 4.54-4.88 7.57.18.87.29 1.75.29 2.65l.22 8.42c.06.88 0 1.78-.12 2.64z'
      />
      <path
        fill='#82C771'
        d='M140.33 107.1c-.23.15-.46.3-.69.46.52-.24 1.01-.49 1.49-.8l7.2-4.4c.23-.15.46-.3.69-.46-.52.24-1.01.49-1.49.8l-7.2 4.4z'
      />
      <path
        fill='#6BB251'
        d='M140.25 108.9l-1.45-2.58c.25-.18.51-.33.76-.49l7.2-4.4c.53-.31 1.09-.63 1.66-.87l1.45 2.58c-.25.18-.51.33-.76.49l-7.2 4.4c-.51.31-1.07.6-1.66.87z'
      />
      <path
        fill='#82C771'
        d='M57.77 141.39l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42c-.12-4.88-2.86-9.32-7.15-11.66l-7.42-4.02c-4.28-2.34-9.51-2.18-13.67.36l-1.92 1.18c-5.69 6.4-9.76 13.63-11.61 21.33l.06 2.33c.09 4.9 2.81 9.35 7.11 11.66z'
      />
      <path
        fill='#6BB251'
        d='M72.08 148.56c-2.63.07-5.26-.53-7.62-1.81l-7.42-4.02c-4.73-2.58-7.77-7.54-7.92-12.91l-.06-2.53.05-.18c1.85-7.75 5.88-15.15 11.96-21.97l.15-.17 2.1-1.3c4.6-2.82 10.4-2.97 15.16-.41l7.42 4.02c4.73 2.58 7.77 7.54 7.92 12.91l.22 8.42c.15 5.4-2.63 10.51-7.21 13.32l-7.2 4.4c-2.33 1.4-4.92 2.16-7.55 2.23zm-13.62-8.47l7.42 4.02c3.8 2.08 8.48 1.93 12.19-.33l7.2-4.4c3.7-2.26 5.91-6.38 5.81-10.71l-.22-8.42c-.13-4.33-2.54-8.32-6.37-10.39l-7.42-4.02c-3.8-2.08-8.48-1.93-12.19.33l-1.74 1.05c-5.62 6.34-9.34 13.2-11.09 20.35l.06 2.13c.11 4.33 2.55 8.31 6.35 10.39z'
      />
      <path
        opacity={0.66}
        fill='#4E953B'
        d='M174.86 99.02c-1.14-1.26-96.36 4.24-96.36 4.24a15.278 15.278 0 00-15.16.41l-1.49.92c.92 1.91 2.16 3.73 3.71 5.38 6.75 7.21 17.99 10.03 28.22 7.12 7.07-2.01 25.36-4.52 34.26-5.7 3.83-.51 7.69-.76 11.56-.74 9.57.04 20.83.7 30.92 2.2.91-.11 1.81-.22 2.69-.33-.04-.48 1.6-11.53 1.65-13.5z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M133.8 119.12c-.03-.89.03-1.79.2-2.66-.86-3.8-3.32-7.1-6.8-8.99l-.21-.12.12 4.09c.03.89.14 1.8.34 2.64-.66 3.83-2.93 7.26-6.33 9.34l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.42-4.02c-.11-.06-.21-.12-.32-.18l.11 4.66c.12 4.88 2.86 9.32 7.15 11.66l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c3.4-2.08 5.67-5.5 6.33-9.34-.2-.87-.31-1.75-.34-2.64l-.25-8.46z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M168.89 106.54l.11 3.76c.14 4.88-2.36 9.45-6.53 12.02l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.18-3.9.13 4.33c.03.89-.03 1.79-.2 2.66.86 3.8 3.32 7.1 6.8 8.99l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42c-.16-4.72-2.75-9.05-6.86-11.44zM85.49 108.75l.11 3.76c.14 4.88-2.36 9.45-6.53 12.02l-7.2 4.4c-4.16 2.56-9.37 2.7-13.67.36l-7.12-3.86c-.19.67-.39 1.34-.53 2l.06 2.33c.12 4.88 2.86 9.32 7.15 11.66l7.42 4.02c4.28 2.34 9.51 2.18 13.67-.36l7.2-4.4c4.16-2.56 6.64-7.14 6.53-12.02l-.22-8.42c-.17-4.79-2.78-9.13-6.87-11.49z'
      />
      <path
        fill='#82C771'
        d='M211.61 137.34s-18.99 17.42-23.28 15.08a14.2 14.2 0 00-3.89-14.14c-4.07-3.86-8.12-8.81-8.21-11.86l-.22-8.42c-.14-4.88 2.36-9.45 6.53-12.02l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36l1.99 1.08c6.03 6.08 10.47 13.07 12.73 20.68l.06 2.33c.07 4.89-2.41 9.46-6.58 12.03z'
      />
      <path
        fill='#6BB251'
        d='M189.28 154.07c-.64.03-1.18-.08-1.66-.35l-1.04-.57.31-1.13c1.29-4.55-.07-9.41-3.49-12.65-2.58-2.42-8.56-8.53-8.69-12.89l-.22-8.42c-.15-5.4 2.63-10.51 7.21-13.32l7.2-4.4c4.6-2.82 10.4-2.97 15.16-.41l2.18 1.18.14.16c6.42 6.48 10.84 13.65 13.11 21.3l.05.19.06 2.53c.15 5.35-2.57 10.4-7.1 13.23-5.39 4.95-17.45 15.4-23.22 15.55zm7.25-53c-2.11.06-4.19.66-6.04 1.8l-7.2 4.4c-3.7 2.26-5.91 6.38-5.81 10.71l.22 8.42c.06 2.15 3.17 6.5 7.74 10.82 3.81 3.59 5.53 8.8 4.71 13.89 3.32-.87 12.03-7.14 20.47-14.87l.23-.18c3.7-2.26 5.91-6.38 5.81-10.71l-.06-2.13c-2.12-7.05-6.2-13.69-12.15-19.73l-1.78-.98c-1.92-1.01-4.04-1.5-6.14-1.44z'
      />
      <path
        opacity={0.41}
        fill='#6BB251'
        d='M211.61 137.34s-18.99 17.42-23.28 15.08a14.2 14.2 0 00-3.89-14.14c-4.07-3.86-8.12-8.81-8.21-11.86l-.22-8.42c-.14-4.88 2.36-9.45 6.53-12.02l7.2-4.4c4.16-2.56 9.37-2.7 13.67-.36l1.99 1.08c6.03 6.08 10.47 13.07 12.73 20.68l.06 2.33c.07 4.89-2.41 9.46-6.58 12.03z'
      />
      <path
        fill='#CBEE5E'
        d='M234.55 135.54c18.58-5.84 36.94-19.19 36.55-34.18-.2-7.41-3.6-14.18-9.24-19.76a18.378 18.378 0 01-4.35-6.83c-5.48-15.12-21.05-25.34-39.16-25.34-49.86.05-45.08 48.13-44.88 55.53.36 13.27 8.73 24.19 22.77 29.7'
      />
      <g fill='#B0D243'>
        <circle
          transform='rotate(-12.068 219.916 82.77)'
          cx={219.89}
          cy={82.77}
          r={10.66}
        />
        <path d='M220.22 95.43c-6.96.19-12.79-5.34-12.99-12.3-.19-6.96 5.34-12.79 12.3-12.96 6.96-.19 12.79 5.34 12.96 12.3.23 6.95-5.28 12.76-12.27 12.96zm-.56-21.33c-4.78.13-8.57 4.12-8.44 8.9.13 4.78 4.12 8.57 8.9 8.44 4.78-.13 8.57-4.12 8.44-8.9-.11-4.78-4.12-8.57-8.9-8.44z' />
      </g>
      <g fill='#B0D243'>
        <circle
          transform='rotate(-12.068 253.308 81.897)'
          cx={253.28}
          cy={81.9}
          r={10.66}
        />
        <path d='M253.61 94.53c-6.96.19-12.79-5.34-12.96-12.3-.19-6.96 5.34-12.79 12.3-12.96 6.96-.19 12.79 5.34 12.96 12.3.19 6.95-5.34 12.79-12.3 12.96zm-.56-21.33c-4.78.13-8.57 4.12-8.44 8.9.13 4.78 4.12 8.57 8.9 8.44 4.78-.13 8.57-4.12 8.44-8.9-.14-4.77-4.12-8.54-8.9-8.44z' />
      </g>
      <circle
        transform='rotate(-12.068 222.653 81.77)'
        fill='#FFF'
        cx={222.62}
        cy={81.77}
        r={10.66}
      />
      <path d='M222.95 93.93c-6.69.19-12.3-5.13-12.46-11.82-.19-6.69 5.13-12.3 11.82-12.46 6.69-.16 12.3 5.13 12.46 11.82.18 6.69-5.14 12.27-11.82 12.46zm-.56-21.33c-5.05.14-9.07 4.35-8.93 9.43.14 5.05 4.35 9.07 9.43 8.93 5.05-.14 9.07-4.35 8.93-9.43-.14-5.05-4.38-9.06-9.43-8.93z' />
      <circle
        transform='rotate(-5.732 227.005 82.412)'
        cx={226.92}
        cy={82.39}
        r={3.63}
      />
      <path d='M238.89 99.81c-3.79.11-6.91-2.05-8.14-3.04-.63-.52-.75-1.45-.23-2.08.52-.63 1.45-.75 2.08-.23 5.23 4.19 9.18 2.17 11.59-.28.57-.59 1.52-.61 2.11-.03.59.57.61 1.52.03 2.11-2.54 2.56-5.11 3.49-7.44 3.55z' />
      <circle
        transform='rotate(-42.92 55.176 101.075)'
        fill='#707CB0'
        cx={55.18}
        cy={101.08}
        r={25.25}
      />
      <path
        fill='#707CB0'
        d='M68.97 61.28L41.43 79.9c-.21.14-.41.28-.62.42l-.28.19v.01c-5.69 4.05-9.68 10.43-10.47 17.93-1.46 13.87 8.61 26.29 22.48 27.75 1.11.12 2.21.15 3.3.12v.01l4.23.01c7.48.02 14.42-3.91 18.26-10.34l4.97-8.34 1.47-20.49-15.8-25.89z'
      />
      <path d='M55.15 83.65c.61 0 1.23.03 1.84.1 9.56 1 16.52 9.6 15.51 19.15a17.377 17.377 0 01-19.15 15.51c-9.56-1-16.52-9.6-15.51-19.15.94-8.9 8.38-15.61 17.31-15.61m0-2c-9.82 0-18.25 7.42-19.3 17.4-1.12 10.67 6.62 20.23 17.29 21.35.69.07 1.37.11 2.05.11 9.82 0 18.25-7.42 19.3-17.4 1.12-10.67-6.62-20.23-17.29-21.35-.68-.08-1.37-.11-2.05-.11z' />
      <path d='M55.16 88.76c.43 0 .87.02 1.3.07 6.76.71 11.68 6.78 10.97 13.54a12.29 12.29 0 01-13.54 10.97c-6.76-.71-11.68-6.78-10.97-13.54.67-6.3 5.93-11.04 12.24-11.04m0-1c-6.73 0-12.51 5.09-13.23 11.93-.77 7.32 4.54 13.87 11.86 14.64.47.05.94.07 1.41.07 6.73 0 12.51-5.09 13.23-11.93.77-7.32-4.54-13.87-11.86-14.64-.47-.05-.94-.07-1.41-.07z' />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M68.97 61.28L41.43 79.9c-.21.14-.41.28-.62.42l-.28.19v.01c-5.69 4.05-9.68 10.43-10.47 17.93-1.46 13.87 8.61 26.29 22.48 27.75 1.11.12 2.21.15 3.3.12v.01l4.23.01c7.48.02 14.42-3.91 18.26-10.34l4.97-8.34 1.47-20.49-15.8-25.89z'
      />
      <path
        fill='#EAD1AE'
        stroke='#000'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M71.08 85.87l-4.04 6.36 7.21 5.76c4.82 3.85 10.74 5.94 16.84 5.94l57.77.81c8.61 0 17.11-2.03 24.85-5.94l6.1-3.08c3.3-1.67 3.47-6.32.3-8.22l-.27-.16a38.25 38.25 0 01-7.16-5.53l-2.89-2.82c-3.21-3.14-7.28-5.16-11.65-5.8l-11.34-1.65a184.01 184.01 0 00-57.94.77l2.04.23c-8.13 1.39-15.31 6.22-19.82 13.33z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M67.03 92.23c71.65-17.5 113.01-1.27 113.01-1.27'
      />
      <path
        opacity={0.12}
        d='M187.39 91.87l-2.34-1.4c-7.52 3.32-22.69 9.34-33.51 9.1-14.93-.32-33.65-3.81-52.66.27-18.13 3.88-14.2-6.8-30.66-7.6 3.06 3.4 11.04 9.93 15.45 9.93l65.17 2.57c8.61 0 17.11-2.03 24.85-5.94l13.7-6.93z'
      />
      <g>
        <path d='M94.68 106.74c5.49 23.6 17.14 47.11 36.61 62.22 6.5 5.03 13.96 8.81 21.87 11.25.19.06.31.26.25.46-.06.19-.25.29-.44.24-8.09-2.09-15.88-5.58-22.78-10.43-13.93-9.64-24.28-23.73-31.72-38.81-3.75-7.69-6.83-15.46-9.03-23.81-.37-1.44.5-2.9 1.93-3.26 1.5-.39 3.04.61 3.31 2.14z' />
        <path d='M167.91 106.51c-6.75 27.58-26.88 50.67-51.45 64.2a139.44 139.44 0 01-27.99 11.42c-.25.07-.5-.07-.57-.32-.07-.24.07-.49.31-.57 8.99-2.84 17.7-6.54 25.88-11.16 1.72-.91 4.35-2.6 6.03-3.63 1.66-1.16 4.2-2.79 5.77-4.02 16.88-12.42 30.14-29.98 36.54-49.99.69-2.21 1.32-4.53 1.71-6.69.5-2.46 4.25-1.8 3.77.76z' />
      </g>
      <path
        fill='#A2D257'
        d='M233.04 136.57c11.55-2.54 19.88-7.37 26.49-14.23-7.87 3.76-14.53 6-24.69 6.28-28.36.76-46.71-13.79-47.24-33.58-.14-4.85-2.25-27.26 10.45-42.08-28.09 11.19-24.73 45.85-24.56 52.04.4 15.06 7.74 28.34 25.34 32.9l11.92 4.03'
      />
      <g>
        <path d='M218.04 163.05c-.54.02-1.1-.29-1.36-.8-.36-.73-.07-1.63.65-1.99 10.26-5.19 11.94-11.83 13.58-18.24.64-2.49 1.23-4.87 2.3-7.11l.28-.58.62-.2c17.93-5.64 35.89-18.69 35.51-32.71-.17-6.76-3.21-13.25-8.79-18.72-2.09-2.06-3.68-4.55-4.7-7.41-5.26-14.57-20.46-24.37-37.75-24.34-13.84.03-24.56 3.83-31.85 11.37-12.48 12.91-11.83 33.11-11.59 40.71.02.82.05 1.46.04 1.96.01 23.53 17.11 30.01 30.84 35.22 1.22.45 2.39.9 3.54 1.36.76.31 1.13 1.16.83 1.94-.31.76-1.16 1.13-1.94.83-1.12-.44-2.29-.89-3.52-1.34-13.83-5.24-32.75-12.4-32.76-37.99-.01-.47-.03-1.09-.05-1.86-.26-7.94-.95-29.06 12.42-42.87 7.86-8.13 19.32-12.27 34-12.28 18.56 0 34.87 10.55 40.55 26.32.89 2.43 2.21 4.55 3.99 6.3 6.15 6.04 9.47 13.22 9.69 20.77.42 16.03-18.73 29.52-36.95 35.42-.8 1.84-1.3 3.86-1.84 5.99-1.72 6.72-3.66 14.36-15.11 20.16-.18.04-.4.09-.63.09z' />
      </g>
      <g
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path
          fill='#E5AE93'
          d='M81.28 99.65c-2.02 5.28-6.05 22.1-10.31 27.33l54.01 9.04s6.14-17.56 9.76-28.35c.26-.77 0-1.59-.81-1.72l-49.82-7.9c-1.21-.22-2.39.45-2.83 1.6z'
        />
        <path
          fill='#C69579'
          d='M124.45 136.01s-43.96-9.78-53.48-9.04l7.93 8.46 51.25 7.93'
        />
        <path
          fill='#C3957C'
          d='M124.98 136.01l5.69 7.36 6.59-25.78-3.75-10.52z'
        />
      </g>
      <path
        fill='none'
        d='M99.86 140.34s-4.92-6.33-4.56-9.73 6.42-46.24 6.42-46.24'
      />
      <g
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path
          fill='#B3B395'
          d='M137.42 94.33s-4.77 3.24-6.16 5.78l1.96 37.42 6.85-8.11s.75-10.09-2.65-35.09z'
        />
        <path
          fill='#D3D4B5'
          d='M137.42 94.33l33.56-1.27 4.04 34.23-34.88 3.05z'
        />
        <path
          fill='#B1B389'
          d='M140.14 130.34l-6.92 7.19 35.1-1.11 6.69-9.13c.01 0-17.04-3.12-34.87 3.05z'
        />
      </g>
      <path
        fill='#EAD1AE'
        stroke='#000'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M59.02 39.9s21.55-21.46 48.14-27.67c33.38-7.8 51.8 8.12 75.88 17.21 2.86 1.08 5.61 2.4 8.14 4.12 3.18 2.16 6.84 5.34 8.7 9.41 0 0-1.58.99-3.41.68-1.16-.2-2.34.27-3.12 1.14-3.16 3.51-11.13 10.49-21.52 6.15-9.07-3.79-90.93 0-90.93 0s-9.98-.22-15.2-5.73-6.68-5.31-6.68-5.31z'
      />
      <path
        fill='none'
        d='M149.02 138.04s5.65-6.1 7.14-10.61c1.49-4.52 1.61-45.53 1.61-45.53'
      />
      <path
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M59.02 39.9l11.88-.83c4.94-.34 9.72-1.9 13.87-4.6 12.32-8.04 44.81-23.5 89.02-.86 2.58 1.32 5.26 2.46 8.01 3.37l17.65 5.85'
      />
      <path
        opacity={0.12}
        d='M147.69 37.51C83.02 26.81 80.9 50.93 80.9 50.93s81.86-3.79 90.93 0c9.14 3.82 16.4-1.12 20.17-4.75l2.84-3.21s-3.77 6.15-15 4.98c-7.14-.75-16.63-7.87-32.15-10.44z'
      />
      <g>
        <path
          fill='#C8AD90'
          d='M192.06 46.12c-.12.1-.25.22-.39.37.14-.13.27-.25.39-.37z'
        />
      </g>
      <path
        fill='#EAD1AE'
        stroke='#000'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M178.8 55.81c-1.75-.59-3.31-1.64-4.6-2.96a32.045 32.045 0 00-14.24-8.49l-7.88-2.24c-4.5-1.28-9.19-1.76-13.86-1.42l-34.83 2.54c-9.25.67-19.63 4.25-26.35 10.65l-20.7 21.53 3.87 2.2c3.37 1.91 7.14 3.02 11.02 3.24l.37.02c3.62.2 73.1-7.66 89.04-9.6 5.33-.65 10.32-2.94 14.29-6.56l8.16-7.45-4.29-1.46z'
      />
      <path
        opacity={0.12}
        d='M135.62 67.3c-37.12-3.53-66.1 3.22-67.4 12.75l1.57.67c.47.05.95.09 1.43.12l.37.02c3.62.2 73.1-7.66 89.04-9.6 5.33-.65 10.32-2.94 14.29-6.56l8.16-7.45c.01.01-21.16 12.55-47.46 10.05z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M56.34 75.42s3.87 2.2 33.53-12.46c23.54-11.63 54.13-8.39 65.75-6.55 3.62.57 7.27.86 10.93.86h16'
      />
      <path d='M105.71 12.59c-.65.17-1.29.34-1.94.52 2.03 10.93 7.77 52.68-7.02 117.39-.07.32.02.66.24.9 2.7 2.93 5.59 6.71 5.58 7.67 0 0 0-.07.05-.16l1.74.98c.77-1.38-1.09-4.56-5.55-9.45 14.78-64.98 8.93-106.94 6.9-117.85zM133.84 10.81c4.3 9.37 25.85 59.57 22.52 116.58-1.35 1.76-5.96 7.98-5.88 10.68l2-.06c-.05-1.56 3.27-6.57 5.65-9.63.12-.16.2-.35.21-.55 3.32-55.45-16.75-104.56-22.14-116.62-.77-.16-1.56-.28-2.36-.4z' />
      <g>
        <circle
          transform='rotate(-12.068 256.045 80.895)'
          fill='#FFF'
          cx={256.01}
          cy={80.9}
          r={10.66}
        />
        <path d='M256.33 93.03c-6.69.19-12.3-5.13-12.46-11.82-.19-6.69 5.13-12.3 11.82-12.46 6.69-.16 12.3 5.13 12.46 11.82.19 6.69-5.13 12.3-11.82 12.46zm-.55-21.3c-5.05.14-9.07 4.35-8.93 9.43.14 5.05 4.35 9.07 9.43 8.93a9.17 9.17 0 008.93-9.43c-.17-5.05-4.38-9.07-9.43-8.93z' />
      </g>
      <circle
        transform='rotate(-5.732 259.91 81.548)'
        cx={259.82}
        cy={81.53}
        r={3.63}
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M48.85 125.53s-1.27 6.72 2.85 14.16M51.69 139.68s-11.76 14.65 19.7 21.66c29.07 6.48 118.53 4.66 135.53-19.08'
      />
    </svg>
  );
}

export default SvgRealmCreatureTurtleSea;
