import * as React from 'react';

function SvgDiscordChatBubble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width='179'
      height='182'
      viewBox='0 0 179 182'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask id='path-1-inside-1_70_3' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11 0C4.92487 0 0 4.92485 0 11V138.918C0 144.993 4.92486 149.918 11 149.918H125.931L157.114 181.101L157.113 149.918H167.702C173.777 149.918 178.702 144.993 178.702 138.918V11C178.702 4.92487 173.777 0 167.702 0H11Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11 0C4.92487 0 0 4.92485 0 11V138.918C0 144.993 4.92486 149.918 11 149.918H125.931L157.114 181.101L157.113 149.918H167.702C173.777 149.918 178.702 144.993 178.702 138.918V11C178.702 4.92487 173.777 0 167.702 0H11Z'
        fill='#FDE99A'
      />
      <path
        d='M125.931 149.918L128.759 147.089L127.588 145.918H125.931V149.918ZM157.114 181.101L154.285 183.929L161.114 190.758L161.114 181.1L157.114 181.101ZM157.113 149.918V145.918H153.113L153.113 149.918L157.113 149.918ZM4 11C4 7.134 7.134 4 11 4V-4C2.71574 -4 -4 2.7157 -4 11H4ZM4 138.918V11H-4V138.918H4ZM11 145.918C7.134 145.918 4 142.784 4 138.918H-4C-4 147.202 2.71572 153.918 11 153.918V145.918ZM125.931 145.918H11V153.918H125.931V145.918ZM159.942 178.272L128.759 147.089L123.103 152.746L154.285 183.929L159.942 178.272ZM153.113 149.918L153.114 181.101L161.114 181.1L161.113 149.918L153.113 149.918ZM167.702 145.918H157.113V153.918H167.702V145.918ZM174.702 138.918C174.702 142.784 171.568 145.918 167.702 145.918V153.918C175.986 153.918 182.702 147.202 182.702 138.918H174.702ZM174.702 11V138.918H182.702V11H174.702ZM167.702 4C171.568 4 174.702 7.134 174.702 11H182.702C182.702 2.71574 175.986 -4 167.702 -4V4ZM11 4H167.702V-4H11V4Z'
        fill='black'
        mask='url(#path-1-inside-1_70_3)'
      />
      {props.children}
    </svg>
  );
}

export default SvgDiscordChatBubble;
