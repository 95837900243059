/* eslint-disable */
import {
  GraphQLResolveInfo,
  GraphQLScalarType,
  GraphQLScalarTypeConfig,
} from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The built-in `Decimal` scalar type. */
  Decimal: any;
  /** The `Long` scalar type represents non-fractional signed whole 64-bit numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `Short` scalar type represents non-fractional signed whole 16-bit numeric values. Short can represent values between -(2^15) and 2^15 - 1. */
  Short: any;
  /** The `TimeSpan` scalar represents an ISO-8601 compliant duration type. */
  TimeSpan: any;
  /** The UnsignedInt scalar type represents a unsigned 32-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedInt: any;
  /** The UnsignedLong scalar type represents a unsigned 64-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedLong: any;
  /** The UnsignedShort scalar type represents a unsigned 16-bit numeric non-fractional value greater than or equal to 0. */
  UnsignedShort: any;
};

/** A connection to a list of items. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of edges. */
  edges: Maybe<Array<AccountsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<FurAccount>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FurAccount;
};

export type AirdropRequest = {
  __typename?: 'AirdropRequest';
  approvedBy: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  fur: Scalars['Int'];
  id: Scalars['String'];
  recipient: Scalars['String'];
  sender: Scalars['String'];
  signature: Maybe<Scalars['String']>;
  tix: Scalars['Int'];
  transactionId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type AirdropRequestArgsInput = {
  fur: Scalars['Int'];
  recipient: Scalars['String'];
  tix: Scalars['Int'];
};

export type AnimationAsset = {
  __typename?: 'AnimationAsset';
  damageAt: Scalars['Float'];
  duration: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AnimationAssetFilterInput = {
  and: Maybe<Array<AnimationAssetFilterInput>>;
  damageAt: Maybe<FloatOperationFilterInput>;
  duration: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<AnimationAssetFilterInput>>;
};

export type AnimationAssetInput = {
  damageAt: Scalars['Float'];
  duration: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type AnimationAssetSortInput = {
  damageAt: Maybe<SortEnumType>;
  duration: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
};

export type AnteInput = {
  furAmount: Scalars['Long'];
};

export type AntePool = {
  __typename?: 'AntePool';
  furAmount: Scalars['Long'];
  /** [DB] the FUR payout transaction */
  furPayout: Maybe<BlockchainTokenTransaction>;
  /** [DB] the FUR payout transaction */
  furPayoutId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** [DB] the actual antes made by players */
  playerAntes: Array<PlayerAnte>;
  /** [DB] keeps track of how many have anted */
  playerCount: Scalars['UnsignedInt'];
};

export type AntePoolFilterInput = {
  and: Maybe<Array<AntePoolFilterInput>>;
  furAmount: Maybe<LongOperationFilterInput>;
  /** [DB] the FUR payout transaction */
  furPayout: Maybe<BlockchainTokenTransactionFilterInput>;
  /** [DB] the FUR payout transaction */
  furPayoutId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<AntePoolFilterInput>>;
  /** [DB] the actual antes made by players */
  playerAntes: Maybe<ListFilterInputTypeOfPlayerAnteFilterInput>;
};

export type AntePoolSortInput = {
  furAmount: Maybe<SortEnumType>;
  /** [DB] the FUR payout transaction */
  furPayout: Maybe<BlockchainTokenTransactionSortInput>;
  /** [DB] the FUR payout transaction */
  furPayoutId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** [DB] keeps track of how many have anted */
  playerCount: Maybe<SortEnumType>;
};

export type AppleOAuthDtoInput = {
  code: Scalars['String'];
  id_token: Scalars['String'];
  state: Scalars['String'];
  user: Maybe<Scalars['String']>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION',
}

export enum AuctionKind {
  GenesisMint = 'GENESIS_MINT',
}

export enum AuctionOutcome {
  Cooldown = 'COOLDOWN',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Won = 'WON',
}

export type BattleEffectPassive = IBattleEffect & {
  __typename?: 'BattleEffectPassive';
  compact: Scalars['String'];
  decay: Scalars['Float'];
  id: Scalars['ID'];
  operand: EntityEffectOperand;
  stat: StatEffectTarget;
  value: Scalars['Int'];
};

export type BattleEffectPassiveFilterInput = {
  and: Maybe<Array<BattleEffectPassiveFilterInput>>;
  decay: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<BattleEffectPassiveFilterInput>>;
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  value: Maybe<IntOperationFilterInput>;
};

export type BattleMove = {
  __typename?: 'BattleMove';
  /** [LL] the actor */
  actor: StatEntity;
  actorId: Scalars['String'];
  allEntities: Array<StatEntity>;
  animation: AnimationAsset;
  /** Used by server to determine how much time should be allocated for the client to run the move */
  animationDuration: Scalars['TimeSpan'];
  /** [LL] the battle */
  battle: GameBattle;
  battleId: Scalars['String'];
  battleState: GameBattleState;
  bossBattle: Maybe<GameBattleBoss>;
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  count: Scalars['Int'];
  createdAt: Maybe<Scalars['DateTime']>;
  duelBattle: Maybe<GameBattleDuel>;
  /** [LL] the effect */
  effect: Maybe<EntityEffect>;
  effectId: Maybe<Scalars['String']>;
  effectsDuration: Scalars['Float'];
  endsAt: Scalars['DateTime'];
  entityId: Scalars['String'];
  entityMoveNumber: Scalars['Int'];
  /** [DB] computed during resolution by resolving the ExpIntervalsGained into actual EXP */
  expGained: Maybe<Scalars['Int']>;
  expectedStartTime: Scalars['DateTime'];
  id: Scalars['String'];
  isBackground: Scalars['Boolean'];
  isDefensive: Scalars['Boolean'];
  isOffensive: Scalars['Boolean'];
  isPlayerAction: Scalars['Boolean'];
  /** [LL] the item */
  item: Maybe<GameItem>;
  itemId: Maybe<Scalars['String']>;
  /** [DB] computed during resolution by resolving the ExpIntervalsGained into actual EXP */
  levelsGained: Maybe<Scalars['Int']>;
  loadedAt: Maybe<Scalars['DateTime']>;
  monsterBattle: Maybe<GameBattleMonsters>;
  moveId: Maybe<Scalars['String']>;
  moveKind: BattleMoveKind;
  ntp: Scalars['String'];
  /** [LL] the outcomes */
  outcomes: Array<BattleMoveOutcome>;
  pvEBattle: Maybe<GameBattlePvE>;
  pvPBattle: Maybe<GameBattlePvP>;
  ranAt: Maybe<Scalars['DateTime']>;
  rebroadcastCount: Scalars['Int'];
  /** [LL] the skill */
  skill: Maybe<EntitySkill>;
  skillId: Maybe<Scalars['String']>;
  startsAt: Scalars['DateTime'];
  targetIds: Array<Scalars['String']>;
  targetIdsList: Scalars['String'];
  targetsAiTeam: Scalars['Boolean'];
  updatedAt: Maybe<Scalars['DateTime']>;
  useJumpAnimation: Scalars['Boolean'];
  vfx: Maybe<VfxAsset>;
};

export type BattleMoveCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type BattleMoveFilterInput = {
  actor: Maybe<StatEntityFilterInput>;
  actorId: Maybe<StringOperationFilterInput>;
  allEntities: Maybe<ListFilterInputTypeOfStatEntityFilterInput>;
  and: Maybe<Array<BattleMoveFilterInput>>;
  animation: Maybe<AnimationAssetFilterInput>;
  /** Used by server to determine how much time should be allocated for the client to run the move */
  animationDuration: Maybe<TimeSpanOperationFilterInput>;
  battle: Maybe<GameBattleFilterInput>;
  battleId: Maybe<StringOperationFilterInput>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  bossBattle: Maybe<GameBattleBossFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  count: Maybe<IntOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  duelBattle: Maybe<GameBattleDuelFilterInput>;
  /** If the move was caused by a background effect */
  effect: Maybe<EntityEffectFilterInput>;
  effectId: Maybe<StringOperationFilterInput>;
  effectsDuration: Maybe<FloatOperationFilterInput>;
  endsAt: Maybe<DateTimeOperationFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  entityMoveNumber: Maybe<IntOperationFilterInput>;
  /** [DB] computed during resolution by resolving the ExpIntervalsGained into actual EXP */
  expGained: Maybe<IntOperationFilterInput>;
  expectedStartTime: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isBackground: Maybe<BooleanOperationFilterInput>;
  isDefensive: Maybe<BooleanOperationFilterInput>;
  isOffensive: Maybe<BooleanOperationFilterInput>;
  isPlayerAction: Maybe<BooleanOperationFilterInput>;
  /** If the move was an item, which item? */
  item: Maybe<GameItemFilterInput>;
  itemId: Maybe<StringOperationFilterInput>;
  /** [DB] computed during resolution by resolving the ExpIntervalsGained into actual EXP */
  levelsGained: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  monsterBattle: Maybe<GameBattleMonstersFilterInput>;
  moveId: Maybe<StringOperationFilterInput>;
  moveKind: Maybe<BattleMoveKindOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<BattleMoveFilterInput>>;
  outcomes: Maybe<ListFilterInputTypeOfBattleMoveOutcomeFilterInput>;
  pvEBattle: Maybe<GameBattlePvEFilterInput>;
  pvPBattle: Maybe<GameBattlePvPFilterInput>;
  ranAt: Maybe<DateTimeOperationFilterInput>;
  rebroadcastCount: Maybe<IntOperationFilterInput>;
  /** If the move was a skill, which skill? */
  skill: Maybe<EntitySkillFilterInput>;
  skillId: Maybe<StringOperationFilterInput>;
  startsAt: Maybe<DateTimeOperationFilterInput>;
  targetIds: Maybe<ListStringOperationFilterInput>;
  targetIdsList: Maybe<StringOperationFilterInput>;
  targetsAiTeam: Maybe<BooleanOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  useJumpAnimation: Maybe<BooleanOperationFilterInput>;
  vfx: Maybe<VfxAssetFilterInput>;
};

export enum BattleMoveKind {
  Attack = 'ATTACK',
  Block = 'BLOCK',
  Effect = 'EFFECT',
  Focus = 'FOCUS',
  Item = 'ITEM',
  Skill = 'SKILL',
  Summon = 'SUMMON',
}

export type BattleMoveKindOperationFilterInput = {
  eq: Maybe<BattleMoveKind>;
  in: Maybe<Array<BattleMoveKind>>;
  neq: Maybe<BattleMoveKind>;
  nin: Maybe<Array<BattleMoveKind>>;
};

export type BattleMoveNext = {
  __typename?: 'BattleMoveNext';
  createdAt: Maybe<Scalars['DateTime']>;
  entity: StatEntity;
  entityId: Scalars['String'];
  entityMoveNumber: Scalars['Int'];
  expectedStartTime: Scalars['DateTime'];
  id: Scalars['String'];
  moveId: Maybe<Scalars['String']>;
  moveKind: BattleMoveKind;
  targetIdList: Scalars['String'];
  targetIds: Maybe<Array<Scalars['String']>>;
  targetIdsList: Maybe<Scalars['String']>;
};

export type BattleMoveNextFilterInput = {
  and: Maybe<Array<BattleMoveNextFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  entity: Maybe<StatEntityFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  entityMoveNumber: Maybe<IntOperationFilterInput>;
  expectedStartTime: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  moveId: Maybe<StringOperationFilterInput>;
  moveKind: Maybe<BattleMoveKindOperationFilterInput>;
  or: Maybe<Array<BattleMoveNextFilterInput>>;
  targetIdList: Maybe<StringOperationFilterInput>;
  targetIds: Maybe<ListStringOperationFilterInput>;
  targetIdsList: Maybe<StringOperationFilterInput>;
};

export type BattleMoveNextSortInput = {
  createdAt: Maybe<SortEnumType>;
  entity: Maybe<StatEntitySortInput>;
  entityId: Maybe<SortEnumType>;
  entityMoveNumber: Maybe<SortEnumType>;
  expectedStartTime: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  moveId: Maybe<SortEnumType>;
  moveKind: Maybe<SortEnumType>;
  targetIdList: Maybe<SortEnumType>;
  targetIdsList: Maybe<SortEnumType>;
};

export type BattleMoveOutcome = IBattleEffect & {
  __typename?: 'BattleMoveOutcome';
  createdAt: Maybe<Scalars['DateTime']>;
  damage: Scalars['Int'];
  decay: Scalars['Float'];
  /** [LL] the effect */
  effect: Maybe<EntityEffect>;
  effectId: Maybe<Scalars['String']>;
  /** [LL] the game entity */
  entity: Maybe<StatEntity>;
  entityId: Scalars['String'];
  /** [DB] intervals, aka hour-equivalents, of EXP gained */
  expIntervalsGained: Scalars['Float'];
  flags: OutcomeFlag;
  id: Scalars['ID'];
  ids: Array<Scalars['String']>;
  idsList: Scalars['String'];
  isDamage: Scalars['Boolean'];
  isDefensive: Scalars['Boolean'];
  isHeal: Scalars['Boolean'];
  isOffensive: Scalars['Boolean'];
  move: BattleMove;
  moveId: Scalars['String'];
  operand: EntityEffectOperand;
  score: Scalars['Int'];
  stat: StatEffectTarget;
  value: Scalars['Int'];
};

export type BattleMoveOutcomeFilterInput = {
  and: Maybe<Array<BattleMoveOutcomeFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  damage: Maybe<IntOperationFilterInput>;
  decay: Maybe<FloatOperationFilterInput>;
  effect: Maybe<EntityEffectFilterInput>;
  effectId: Maybe<StringOperationFilterInput>;
  entity: Maybe<StatEntityFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  /** [DB] intervals, aka hour-equivalents, of EXP gained */
  expIntervalsGained: Maybe<FloatOperationFilterInput>;
  flags: Maybe<OutcomeFlagOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  ids: Maybe<ListStringOperationFilterInput>;
  idsList: Maybe<StringOperationFilterInput>;
  isDamage: Maybe<BooleanOperationFilterInput>;
  isDefensive: Maybe<BooleanOperationFilterInput>;
  isHeal: Maybe<BooleanOperationFilterInput>;
  isOffensive: Maybe<BooleanOperationFilterInput>;
  move: Maybe<BattleMoveFilterInput>;
  moveId: Maybe<StringOperationFilterInput>;
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<BattleMoveOutcomeFilterInput>>;
  score: Maybe<IntOperationFilterInput>;
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  value: Maybe<IntOperationFilterInput>;
};

export type BattleMoveRequestInput = {
  createdAt: Maybe<Scalars['DateTime']>;
  /** Some time after which the move becomes invalid */
  deadline: Maybe<Scalars['DateTime']>;
  entityId: Scalars['String'];
  /** The request number as known by the client */
  entityMoveNumber: Scalars['Int'];
  gameId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  moveId: Maybe<Scalars['String']>;
  moveKind: BattleMoveKind;
  /** When the request is expected to run, based on the entity's nextMoveAt */
  startsAt: Maybe<Scalars['DateTime']>;
  targetIds: Maybe<Array<Scalars['String']>>;
  targetIdsList: Maybe<Scalars['String']>;
};

export type BattleMoveResponse = {
  __typename?: 'BattleMoveResponse';
  /** [LL] current player percentiles */
  activeBossBattles: Array<GameBattleBoss>;
  actor: Maybe<StatEntity>;
  actorId: Maybe<Scalars['String']>;
  battle: GameBattle;
  bossBattle: Maybe<GameBattleBoss>;
  /** [LL] accessor to return current player's usable consumables */
  consumableItems: Array<ConsumableItem>;
  createdAt: Scalars['DateTime'];
  duelBattle: Maybe<GameBattleDuel>;
  monsterBattle: Maybe<GameBattleMonsters>;
  move: Maybe<BattleMove>;
  /** [LL] current player percentiles */
  percentiles: BattlePercentiles;
  pvEBattle: Maybe<GameBattlePvE>;
  pvPBattle: Maybe<GameBattlePvP>;
};

export type BattlePercentiles = {
  __typename?: 'BattlePercentiles';
  bossLevel: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  leaderboardGroup: LeaderboardGroup;
  maxScore: Scalars['Long'];
  minScore: Scalars['Long'];
  pctRank: Scalars['Float'];
  pctile: Scalars['Float'];
  percentile: Scalars['Int'];
  percentileRank: Scalars['Int'];
  position: Scalars['Int'];
  realm: RealmType;
  scores: Array<Scalars['Long']>;
  spread: Scalars['Long'];
};

export type BattleStats = {
  __typename?: 'BattleStats';
  attackPower: Scalars['Int'];
  buff: Scalars['Int'];
  critAttackPower: Scalars['Int'];
  critRate: Scalars['Int'];
  deBuff: Scalars['Int'];
  defencePower: Scalars['Int'];
  heal: Scalars['Int'];
  karma: Scalars['Int'];
  maxHealth: Scalars['Long'];
  revive: Scalars['Int'];
  speed: Scalars['Int'];
  vamp: Scalars['Int'];
};

export type BattleStatsFilterInput = {
  and: Maybe<Array<BattleStatsFilterInput>>;
  attackPower: Maybe<IntOperationFilterInput>;
  buff: Maybe<IntOperationFilterInput>;
  critAttackPower: Maybe<IntOperationFilterInput>;
  critRate: Maybe<IntOperationFilterInput>;
  deBuff: Maybe<IntOperationFilterInput>;
  defencePower: Maybe<IntOperationFilterInput>;
  heal: Maybe<IntOperationFilterInput>;
  karma: Maybe<IntOperationFilterInput>;
  maxHealth: Maybe<LongOperationFilterInput>;
  or: Maybe<Array<BattleStatsFilterInput>>;
  revive: Maybe<IntOperationFilterInput>;
  speed: Maybe<IntOperationFilterInput>;
  vamp: Maybe<IntOperationFilterInput>;
};

export type BattleStatsInput = {
  attackPower: Scalars['Int'];
  buff: Scalars['Int'];
  critAttackPower: Scalars['Int'];
  critRate: Scalars['Int'];
  deBuff: Scalars['Int'];
  defencePower: Scalars['Int'];
  heal: Scalars['Int'];
  karma: Scalars['Int'];
  maxHealth: Scalars['Long'];
  revive: Scalars['Int'];
  speed: Scalars['Int'];
  vamp: Scalars['Int'];
};

export type BattleStatsSortInput = {
  attackPower: Maybe<SortEnumType>;
  buff: Maybe<SortEnumType>;
  critAttackPower: Maybe<SortEnumType>;
  critRate: Maybe<SortEnumType>;
  deBuff: Maybe<SortEnumType>;
  defencePower: Maybe<SortEnumType>;
  heal: Maybe<SortEnumType>;
  karma: Maybe<SortEnumType>;
  maxHealth: Maybe<SortEnumType>;
  revive: Maybe<SortEnumType>;
  speed: Maybe<SortEnumType>;
  vamp: Maybe<SortEnumType>;
};

export enum BattleTurnPhase {
  Accepted = 'ACCEPTED',
  NotPlaying = 'NOT_PLAYING',
  Ready = 'READY',
  Waiting = 'WAITING',
}

export type BattleTurnPhaseOperationFilterInput = {
  eq: Maybe<BattleTurnPhase>;
  in: Maybe<Array<BattleTurnPhase>>;
  neq: Maybe<BattleTurnPhase>;
  nin: Maybe<Array<BattleTurnPhase>>;
};

/** A connection to a list of items. */
export type BattlesConnection = {
  __typename?: 'BattlesConnection';
  /** A list of edges. */
  edges: Maybe<Array<BattlesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattleBoss>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BattlesEdge = {
  __typename?: 'BattlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattleBoss;
};

export type BlockWork = {
  __typename?: 'BlockWork';
  averageBaseFee: Scalars['Float'];
  averageTransactionFee: Scalars['Float'];
  completedBlockNumber: Scalars['UnsignedLong'];
  createdAt: Scalars['DateTime'];
  curBlockNumber: Maybe<Scalars['UnsignedLong']>;
  /** If set, the next transaction executed should use this gwei cost (useful for txn replacement) */
  gasPrice: Maybe<Scalars['Float']>;
  hasStarted: Scalars['Boolean'];
  host: HostNode;
  hostName: Scalars['String'];
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  lockState: WorkLockState;
  numBlocks: Scalars['Float'];
  startBlockNumber: Scalars['UnsignedLong'];
  stopBlockNumber: Scalars['UnsignedLong'];
};

export type BlockchainModel = {
  __typename?: 'BlockchainModel';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type BlockchainToken = {
  __typename?: 'BlockchainToken';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isWrapped: Scalars['Boolean'];
  name: Scalars['String'];
  schema: TokenSchema;
  symbol: Scalars['String'];
  totalSupply: Scalars['UnsignedLong'];
  transactions: Array<BlockchainTokenTransaction>;
  unwrappedToken: Maybe<BlockchainToken>;
  updatedAt: Maybe<Scalars['DateTime']>;
  wrappedToken: Maybe<BlockchainToken>;
};

export type BlockchainTokenFilterInput = {
  and: Maybe<Array<BlockchainTokenFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isWrapped: Maybe<BooleanOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<BlockchainTokenFilterInput>>;
  schema: Maybe<TokenSchemaOperationFilterInput>;
  symbol: Maybe<StringOperationFilterInput>;
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  unwrappedToken: Maybe<BlockchainTokenFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  wrappedToken: Maybe<BlockchainTokenFilterInput>;
};

export type BlockchainTokenSortInput = {
  createdAt: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isWrapped: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  schema: Maybe<SortEnumType>;
  symbol: Maybe<SortEnumType>;
  totalSupply: Maybe<SortEnumType>;
  unwrappedToken: Maybe<BlockchainTokenSortInput>;
  updatedAt: Maybe<SortEnumType>;
  wrappedToken: Maybe<BlockchainTokenSortInput>;
};

export type BlockchainTokenTransaction = {
  __typename?: 'BlockchainTokenTransaction';
  /** If From is NULL, this Amount was earned. If To is NULL, this amount was burned. If both non-null, it was a trade. */
  amount: Scalars['UnsignedLong'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** The ID of the player who sent the transaction */
  from: Maybe<Scalars['String']>;
  /** If there is an associated Furball */
  furball: Maybe<Furball>;
  id: Scalars['String'];
  /** If Reason refers to an object, this is the ID of that object */
  instanceId: Maybe<Scalars['String']>;
  /** If this came from a particular object */
  instanceType: Maybe<Scalars['String']>;
  /** If a specific item, which? */
  itemId: Maybe<Scalars['String']>;
  /** If imported, from when? */
  occurredAt: Maybe<Scalars['DateTime']>;
  players: Array<FurPlayer>;
  /** The ID of the player who received the transaction */
  to: Maybe<Scalars['String']>;
  token: BlockchainToken;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** General indication of where this came from */
  validation: Scalars['String'];
  wrappedTransactions: Array<BlockchainTokenTransaction>;
  writeBack: Maybe<BlockchainTokenTransaction>;
};

export type BlockchainTokenTransactionFilterInput = {
  and: Maybe<Array<BlockchainTokenTransactionFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** The ID of the player who sent the transaction */
  from: Maybe<StringOperationFilterInput>;
  /** If there is an associated Furball */
  furball: Maybe<FurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** If Reason refers to an object, this is the ID of that object */
  instanceId: Maybe<StringOperationFilterInput>;
  /** If this came from a particular object */
  instanceType: Maybe<StringOperationFilterInput>;
  /** If a specific item, which? */
  itemId: Maybe<StringOperationFilterInput>;
  /** If imported, from when? */
  occurredAt: Maybe<DateTimeOperationFilterInput>;
  or: Maybe<Array<BlockchainTokenTransactionFilterInput>>;
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  /** The ID of the player who received the transaction */
  to: Maybe<StringOperationFilterInput>;
  token: Maybe<BlockchainTokenFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  /** General indication of where this came from */
  validation: Maybe<StringOperationFilterInput>;
  wrappedTransactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  writeBack: Maybe<BlockchainTokenTransactionFilterInput>;
};

export type BlockchainTokenTransactionSortInput = {
  /** If From is NULL, this Amount was earned. If To is NULL, this amount was burned. If both non-null, it was a trade. */
  amount: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** The ID of the player who sent the transaction */
  from: Maybe<SortEnumType>;
  /** If there is an associated Furball */
  furball: Maybe<FurballSortInput>;
  id: Maybe<SortEnumType>;
  /** If Reason refers to an object, this is the ID of that object */
  instanceId: Maybe<SortEnumType>;
  /** If this came from a particular object */
  instanceType: Maybe<SortEnumType>;
  /** If a specific item, which? */
  itemId: Maybe<SortEnumType>;
  /** If imported, from when? */
  occurredAt: Maybe<SortEnumType>;
  /** The ID of the player who received the transaction */
  to: Maybe<SortEnumType>;
  token: Maybe<BlockchainTokenSortInput>;
  updatedAt: Maybe<SortEnumType>;
  /** General indication of where this came from */
  validation: Maybe<SortEnumType>;
  writeBack: Maybe<BlockchainTokenTransactionSortInput>;
};

export type BlockchainTransaction = {
  __typename?: 'BlockchainTransaction';
  blockNumber: Scalars['Long'];
  contractAddress: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  cumulativeGasUsed: Scalars['Long'];
  effectiveGasPrice: Scalars['Long'];
  ethCost: Scalars['Float'];
  gasEstimate: Maybe<Scalars['Long']>;
  gasUsed: Scalars['Long'];
  gweiToGas: Scalars['Float'];
  hash: Scalars['String'];
  id: Scalars['String'];
  index: Scalars['Long'];
  instant: Maybe<TimekeeperInstant>;
  logsBloom: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['Long'];
  type: Scalars['Long'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type BlockchainTransactionFilterInput = {
  and: Maybe<Array<BlockchainTransactionFilterInput>>;
  blockNumber: Maybe<LongOperationFilterInput>;
  contractAddress: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  cumulativeGasUsed: Maybe<LongOperationFilterInput>;
  effectiveGasPrice: Maybe<LongOperationFilterInput>;
  ethCost: Maybe<FloatOperationFilterInput>;
  gasEstimate: Maybe<LongOperationFilterInput>;
  gasUsed: Maybe<LongOperationFilterInput>;
  gweiToGas: Maybe<FloatOperationFilterInput>;
  hash: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  index: Maybe<LongOperationFilterInput>;
  instant: Maybe<TimekeeperInstantFilterInput>;
  logsBloom: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<BlockchainTransactionFilterInput>>;
  status: Maybe<LongOperationFilterInput>;
  type: Maybe<LongOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type BlockchainTransactionSortInput = {
  blockNumber: Maybe<SortEnumType>;
  contractAddress: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  cumulativeGasUsed: Maybe<SortEnumType>;
  effectiveGasPrice: Maybe<SortEnumType>;
  ethCost: Maybe<SortEnumType>;
  gasEstimate: Maybe<SortEnumType>;
  gasUsed: Maybe<SortEnumType>;
  gweiToGas: Maybe<SortEnumType>;
  hash: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  index: Maybe<SortEnumType>;
  instant: Maybe<TimekeeperInstantSortInput>;
  logsBloom: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  status: Maybe<SortEnumType>;
  type: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export type BooleanOperationFilterInput = {
  eq: Maybe<Scalars['Boolean']>;
  neq: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of items. */
export type BossBattlesConnection = {
  __typename?: 'BossBattlesConnection';
  /** A list of edges. */
  edges: Maybe<Array<BossBattlesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattleBoss>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BossBattlesEdge = {
  __typename?: 'BossBattlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattleBoss;
};

/** A connection to a list of items. */
export type BossFightsConnection = {
  __typename?: 'BossFightsConnection';
  /** A list of edges. */
  edges: Maybe<Array<BossFightsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattleBoss>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type BossFightsEdge = {
  __typename?: 'BossFightsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattleBoss;
};

export enum BuildHealth {
  Bugged = 'BUGGED',
  Healthy = 'HEALTHY',
}

export enum BuildTag {
  Alpha = 'ALPHA',
  Beta = 'BETA',
  Latest = 'LATEST',
}

export type ByteOperationFilterInput = {
  eq: Maybe<Scalars['Byte']>;
  gt: Maybe<Scalars['Byte']>;
  gte: Maybe<Scalars['Byte']>;
  in: Maybe<Array<Maybe<Scalars['Byte']>>>;
  lt: Maybe<Scalars['Byte']>;
  lte: Maybe<Scalars['Byte']>;
  neq: Maybe<Scalars['Byte']>;
  ngt: Maybe<Scalars['Byte']>;
  ngte: Maybe<Scalars['Byte']>;
  nin: Maybe<Array<Maybe<Scalars['Byte']>>>;
  nlt: Maybe<Scalars['Byte']>;
  nlte: Maybe<Scalars['Byte']>;
};

export type ClientSpecification = {
  __typename?: 'ClientSpecification';
  /** The value used by client for scaling */
  referenceDpi: Scalars['Float'];
};

export enum CommunityFlag {
  Admin = 'ADMIN',
  Alpha = 'ALPHA',
  Engagement = 'ENGAGEMENT',
  FurballTeam = 'FURBALL_TEAM',
  FurryTester = 'FURRY_TESTER',
  International = 'INTERNATIONAL',
  ModTeam = 'MOD_TEAM',
  None = 'NONE',
  OriginalG = 'ORIGINAL_G',
  ProjectVoting = 'PROJECT_VOTING',
  Support = 'SUPPORT',
  TwitterPosts = 'TWITTER_POSTS',
}

export type CommunityFlagOperationFilterInput = {
  eq: Maybe<CommunityFlag>;
  in: Maybe<Array<CommunityFlag>>;
  neq: Maybe<CommunityFlag>;
  nin: Maybe<Array<CommunityFlag>>;
};

/** Items usable in battles or as one-shots to boost stats */
export type ConsumableItem = {
  __typename?: 'ConsumableItem';
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  /** A top level grouping derived from the ItemId */
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  /** How many items can be stacked? */
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
};

/** Items usable in battles or as one-shots to boost stats */
export type ConsumableItemFilterInput = {
  and: Maybe<Array<ConsumableItemFilterInput>>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** How many items can be stacked? */
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<ConsumableItemFilterInput>>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

/** Items usable in battles or as one-shots to boost stats */
export type ConsumableItemSortInput = {
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemSortInput>;
  /** The parent container, if any */
  containerId: Maybe<SortEnumType>;
  /** When was the item/stack created? */
  createdAt: Maybe<SortEnumType>;
  definition: Maybe<ItemDefinitionSortInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<SortEnumType>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<SortEnumType>;
  gameBattlePvEId: Maybe<SortEnumType>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<SortEnumType>;
  itemDefinition: Maybe<ItemDefinitionSortInput>;
  itemDiscriminator: Maybe<SortEnumType>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<SortEnumType>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<SortEnumType>;
  /** How many items can be stacked? */
  maxStack: Maybe<SortEnumType>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<SortEnumType>;
  playerPendingReward: Maybe<PlayerPendingRewardSortInput>;
  playerPendingRewardId: Maybe<SortEnumType>;
  /** The rarity level, derived from definition */
  rarity: Maybe<SortEnumType>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<SortEnumType>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<SortEnumType>;
};

/** Capable of holding other items; may be the inventory of a player/furball */
export type ContainerItem = {
  __typename?: 'ContainerItem';
  /** [LL] the actual contents of the container for just the item type */
  consumableItems: Array<ConsumableItem>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** [LL] the actual contents of the container for just the item type */
  containerItems: Array<ContainerItem>;
  /** [LL] the actual contents of the container (items) */
  contents: Array<GameItem>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  /** This container is the inventory of this GameEntity */
  entity: Maybe<GameEntity>;
  /** If owned by a GameEntity, the ID of that entity */
  entityId: Maybe<Scalars['String']>;
  /** [LL] the actual contents of the container for just the item type */
  equipmentItems: Array<EquipmentItem>;
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** Check if ItemQuantities are available; if false, they were not included in the query, and need be loaded separately */
  hasItemQuantities: Scalars['Boolean'];
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  /** The number of items in the inventory */
  itemCount: Scalars['Int'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  /** [LL] the actual contents of the container (legacy name) */
  itemQuantities: Array<ItemQuantity>;
  /** The items within this container */
  items: Array<GameItem>;
  /** [LL] the actual contents of the container for just the item type */
  lootItems: Array<LootItem>;
  /** [LL] the actual contents of the container for just the item type */
  materialItems: Array<MaterialItem>;
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  /** This container is the inventory of this player */
  player: Maybe<FurPlayer>;
  /** If owned by a FurPlayer, the ID of that player */
  playerId: Maybe<Scalars['String']>;
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** [LL] the actual contents of the container (items) */
  quantities: Array<ItemQuantity>;
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** [LL] the actual contents of the container for just the item type */
  specialItems: Array<SpecialItem>;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
  /** [LL] The sum of all stacks of dust in the inventory */
  totalDustCount: Scalars['Int'];
};

/** Capable of holding other items; may be the inventory of a player/furball */
export type ContainerItemFilterInput = {
  and: Maybe<Array<ContainerItemFilterInput>>;
  /** [DB] Filters the Items */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** [DB] Filters the Items */
  containerItems: Maybe<ListFilterInputTypeOfContainerItemFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  /** This container is the inventory of this GameEntity */
  entity: Maybe<GameEntityFilterInput>;
  /** If owned by a GameEntity, the ID of that entity */
  entityId: Maybe<StringOperationFilterInput>;
  /** [DB] Filters the Items */
  equipmentItems: Maybe<ListFilterInputTypeOfEquipmentItemFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** Check if ItemQuantities are available; if false, they were not included in the query, and need be loaded separately */
  hasItemQuantities: Maybe<BooleanOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  /** The number of items in the inventory */
  itemCount: Maybe<IntOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** The items within this container */
  items: Maybe<ListFilterInputTypeOfGameItemFilterInput>;
  /** [DB] Filters the Items */
  lootItems: Maybe<ListFilterInputTypeOfLootItemFilterInput>;
  /** [DB] Filters the Items */
  materialItems: Maybe<ListFilterInputTypeOfMaterialItemFilterInput>;
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<ContainerItemFilterInput>>;
  /** This container is the inventory of this player */
  player: Maybe<FurPlayerFilterInput>;
  /** If owned by a FurPlayer, the ID of that player */
  playerId: Maybe<StringOperationFilterInput>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** [DB] Filters the Items */
  specialItems: Maybe<ListFilterInputTypeOfSpecialItemFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

/** Capable of holding other items; may be the inventory of a player/furball */
export type ContainerItemSortInput = {
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemSortInput>;
  /** The parent container, if any */
  containerId: Maybe<SortEnumType>;
  /** When was the item/stack created? */
  createdAt: Maybe<SortEnumType>;
  definition: Maybe<ItemDefinitionSortInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<SortEnumType>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<SortEnumType>;
  /** This container is the inventory of this GameEntity */
  entity: Maybe<GameEntitySortInput>;
  /** If owned by a GameEntity, the ID of that entity */
  entityId: Maybe<SortEnumType>;
  gameBattlePvEId: Maybe<SortEnumType>;
  /** Check if ItemQuantities are available; if false, they were not included in the query, and need be loaded separately */
  hasItemQuantities: Maybe<SortEnumType>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<SortEnumType>;
  /** The number of items in the inventory */
  itemCount: Maybe<SortEnumType>;
  itemDefinition: Maybe<ItemDefinitionSortInput>;
  itemDiscriminator: Maybe<SortEnumType>;
  itemGroup: Maybe<SortEnumType>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<SortEnumType>;
  maxStack: Maybe<SortEnumType>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<SortEnumType>;
  /** This container is the inventory of this player */
  player: Maybe<FurPlayerSortInput>;
  /** If owned by a FurPlayer, the ID of that player */
  playerId: Maybe<SortEnumType>;
  playerPendingReward: Maybe<PlayerPendingRewardSortInput>;
  playerPendingRewardId: Maybe<SortEnumType>;
  /** The rarity level, derived from definition */
  rarity: Maybe<SortEnumType>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<SortEnumType>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<SortEnumType>;
};

export type CraftRecipeIngredient = {
  __typename?: 'CraftRecipeIngredient';
  amount: Scalars['Int'];
  itemType: CraftRecipeItemType;
  rarity: Maybe<RarityLevel>;
  realm: Maybe<RealmType>;
};

export type CraftRecipeIngredientFilterInput = {
  amount: Maybe<IntOperationFilterInput>;
  and: Maybe<Array<CraftRecipeIngredientFilterInput>>;
  itemType: Maybe<CraftRecipeItemTypeOperationFilterInput>;
  or: Maybe<Array<CraftRecipeIngredientFilterInput>>;
  rarity: Maybe<NullableOfRarityLevelOperationFilterInput>;
  realm: Maybe<NullableOfRealmTypeOperationFilterInput>;
};

export enum CraftRecipeItemType {
  BossDust = 'BOSS_DUST',
  BossMaterial = 'BOSS_MATERIAL',
  None = 'NONE',
}

export type CraftRecipeItemTypeOperationFilterInput = {
  eq: Maybe<CraftRecipeItemType>;
  in: Maybe<Array<CraftRecipeItemType>>;
  neq: Maybe<CraftRecipeItemType>;
  nin: Maybe<Array<CraftRecipeItemType>>;
};

export type CraftSlot = {
  __typename?: 'CraftSlot';
  /** The ID of the crafter */
  crafterId: Scalars['String'];
  /** If non-null, this tracks what the slot is currently crafting */
  currentAttempt: Maybe<CraftingAttempt>;
  currentAttemptId: Maybe<Scalars['String']>;
  /** The equivalent cost in Fuel (TIX) */
  fuelCost: Scalars['Long'];
  id: Scalars['ID'];
  /** Derived from CurrentAttemptId != null */
  isActive: Scalars['Boolean'];
  /** Derived from UnlockCost & UnlockedAt */
  isLocked: Scalars['Boolean'];
  /** The sort-order in which this slot appears */
  slotIndex: Scalars['Int'];
  /** The FUR cost to use this slot; if zero, it is always unlocked */
  unlockCost: Scalars['Int'];
  /** If this costs FUR, and has been unlocked, when? Reset to null when an attempt begins. */
  unlockedAt: Maybe<Scalars['DateTime']>;
};

export type CraftSlotFilterInput = {
  and: Maybe<Array<CraftSlotFilterInput>>;
  /** The ID of the crafter */
  crafterId: Maybe<StringOperationFilterInput>;
  /** If non-null, this tracks what the slot is currently crafting */
  currentAttempt: Maybe<CraftingAttemptFilterInput>;
  currentAttemptId: Maybe<StringOperationFilterInput>;
  /** The equivalent cost in Fuel (TIX) */
  fuelCost: Maybe<LongOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** Derived from CurrentAttemptId != null */
  isActive: Maybe<BooleanOperationFilterInput>;
  /** Derived from UnlockCost & UnlockedAt */
  isLocked: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<CraftSlotFilterInput>>;
  /** The sort-order in which this slot appears */
  slotIndex: Maybe<IntOperationFilterInput>;
  /** The FUR cost to use this slot; if zero, it is always unlocked */
  unlockCost: Maybe<IntOperationFilterInput>;
  /** If this costs FUR, and has been unlocked, when? Reset to null when an attempt begins. */
  unlockedAt: Maybe<DateTimeOperationFilterInput>;
};

/** Each attempt represents a single session of crafting in a CraftSlot */
export type CraftingAttempt = {
  __typename?: 'CraftingAttempt';
  /** Derived from CreatedAt, SpeedUpCount, and Recipe... the attempt will be completed (factoring in any speed-ups) */
  completesAt: Scalars['DateTime'];
  /** When the attempt was started (created) */
  createdAt: Maybe<Scalars['DateTime']>;
  /** Derived from Recipe.Duration - SpeedUpDuration */
  duration: Scalars['Int'];
  id: Scalars['String'];
  /** Derived from CompletesAt <= now */
  isComplete: Scalars['Boolean'];
  /** Once completed, this field will be auto-populated. */
  pendingReward: Maybe<PlayerPendingReward>;
  pendingRewardId: Maybe<Scalars['String']>;
  /** All crafting attempts are associated with a player */
  player: FurPlayer;
  playerId: Scalars['String'];
  /** Derived from RecipeId and looked up from ItemLibrary */
  recipe: CraftingRecipe;
  /** The ID of the recipe being crafted */
  recipeId: Scalars['String'];
  /** While the attempt is active, it maintains the reference back to the slot */
  slot: Maybe<CraftSlot>;
  /** The FUR cost to speed this slot ONE hour; if zero it may not be sped up */
  speedUpCostPerHour: Scalars['Int'];
  /** The number of SECONDS this attempt has been sped up */
  speedUpDuration: Scalars['Int'];
};

/** Each attempt represents a single session of crafting in a CraftSlot */
export type CraftingAttemptFilterInput = {
  and: Maybe<Array<CraftingAttemptFilterInput>>;
  /** Derived from CreatedAt, SpeedUpCount, and Recipe... the attempt will be completed (factoring in any speed-ups) */
  completesAt: Maybe<DateTimeOperationFilterInput>;
  /** When the attempt was started (created) */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** Derived from Recipe.Duration - SpeedUpDuration */
  duration: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** Derived from CompletesAt <= now */
  isComplete: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<CraftingAttemptFilterInput>>;
  /** Once completed, this field will be auto-populated. */
  pendingReward: Maybe<PlayerPendingRewardFilterInput>;
  pendingRewardId: Maybe<StringOperationFilterInput>;
  /** All crafting attempts are associated with a player */
  player: Maybe<FurPlayerFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** Derived from RecipeId and looked up from ItemLibrary */
  recipe: Maybe<CraftingRecipeFilterInput>;
  /** The ID of the recipe being crafted */
  recipeId: Maybe<StringOperationFilterInput>;
  /** While the attempt is active, it maintains the reference back to the slot */
  slot: Maybe<CraftSlotFilterInput>;
  /** The FUR cost to speed this slot ONE hour; if zero it may not be sped up */
  speedUpCostPerHour: Maybe<IntOperationFilterInput>;
  /** The number of SECONDS this attempt has been sped up */
  speedUpDuration: Maybe<IntOperationFilterInput>;
};

export type CraftingRecipe = {
  __typename?: 'CraftingRecipe';
  /** Seconds it takes to craft */
  duration: Scalars['Int'];
  id: Scalars['String'];
  /** What items are required to craft this */
  ingredients: Array<CraftRecipeIngredient>;
  /** The thing which this recipe crafts */
  item: ItemDefinition;
  /** The ID of the craftable thing */
  itemId: Scalars['Long'];
};

export type CraftingRecipeFilterInput = {
  and: Maybe<Array<CraftingRecipeFilterInput>>;
  /** Seconds it takes to craft */
  duration: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** What items are required to craft this */
  ingredients: Maybe<ListFilterInputTypeOfCraftRecipeIngredientFilterInput>;
  /** The thing which this recipe crafts */
  item: Maybe<ItemDefinitionFilterInput>;
  /** The ID of the craftable thing */
  itemId: Maybe<LongOperationFilterInput>;
  or: Maybe<Array<CraftingRecipeFilterInput>>;
};

export type CraftingRecipeSortInput = {
  /** Seconds it takes to craft */
  duration: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** The thing which this recipe crafts */
  item: Maybe<ItemDefinitionSortInput>;
  /** The ID of the craftable thing */
  itemId: Maybe<SortEnumType>;
};

export type CurrencyCost = {
  __typename?: 'CurrencyCost';
  eth: Scalars['Float'];
  fuel: Scalars['Float'];
  tix: Scalars['Long'];
  usd: Scalars['Float'];
};

export type CurrencyCosts = {
  __typename?: 'CurrencyCosts';
  costBase: CurrencyCost;
  costEach: CurrencyCost;
};

export type CurrencyExchangeRates = {
  __typename?: 'CurrencyExchangeRates';
  eth: EthExchange;
  fur: FurExchange;
  gasPrice: Scalars['Int'];
  tix: TixExchange;
};

export enum CurrencyType {
  Fur = 'FUR',
}

export type CurrencyTypeOperationFilterInput = {
  eq: Maybe<CurrencyType>;
  in: Maybe<Array<CurrencyType>>;
  neq: Maybe<CurrencyType>;
  nin: Maybe<Array<CurrencyType>>;
};

export type DateTimeOperationFilterInput = {
  eq: Maybe<Scalars['DateTime']>;
  gt: Maybe<Scalars['DateTime']>;
  gte: Maybe<Scalars['DateTime']>;
  in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  lt: Maybe<Scalars['DateTime']>;
  lte: Maybe<Scalars['DateTime']>;
  neq: Maybe<Scalars['DateTime']>;
  ngt: Maybe<Scalars['DateTime']>;
  ngte: Maybe<Scalars['DateTime']>;
  nin: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  nlt: Maybe<Scalars['DateTime']>;
  nlte: Maybe<Scalars['DateTime']>;
};

export type DecimalOperationFilterInput = {
  eq: Maybe<Scalars['Decimal']>;
  gt: Maybe<Scalars['Decimal']>;
  gte: Maybe<Scalars['Decimal']>;
  in: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  lt: Maybe<Scalars['Decimal']>;
  lte: Maybe<Scalars['Decimal']>;
  neq: Maybe<Scalars['Decimal']>;
  ngt: Maybe<Scalars['Decimal']>;
  ngte: Maybe<Scalars['Decimal']>;
  nin: Maybe<Array<Maybe<Scalars['Decimal']>>>;
  nlt: Maybe<Scalars['Decimal']>;
  nlte: Maybe<Scalars['Decimal']>;
};

/** A connection to a list of items. */
export type DuelBattlesConnection = {
  __typename?: 'DuelBattlesConnection';
  /** A list of edges. */
  edges: Maybe<Array<DuelBattlesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattleDuel>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type DuelBattlesEdge = {
  __typename?: 'DuelBattlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattleDuel;
};

export type DutchAuction = {
  __typename?: 'DutchAuction';
  createdAt: Maybe<Scalars['DateTime']>;
  /** The current (or final) Dust cost */
  currentCost: Scalars['UnsignedInt'];
  id: Scalars['String'];
  kind: AuctionKind;
  /** The cost of the PRIOR auction */
  lastCost: Scalars['UnsignedInt'];
  /** If sold, to whom? */
  player: Maybe<FurPlayer>;
  playerId: Maybe<Scalars['String']>;
  /** When was the cost last reduced? */
  reducedAt: Maybe<Scalars['DateTime']>;
  sequence: Scalars['UnsignedInt'];
  /** If sold, when? */
  soldAt: Maybe<Scalars['DateTime']>;
  /** The starting Dust cost */
  startingCost: Scalars['UnsignedInt'];
  targetDays: Scalars['Float'];
  /** If sold AND completed, the mint transaction */
  transaction: Maybe<BlockchainTransaction>;
};

export type DutchAuctionResult = {
  __typename?: 'DutchAuctionResult';
  account: FurAccount;
  auction: DutchAuction;
  outcome: AuctionOutcome;
};

export type EditionAsset = {
  __typename?: 'EditionAsset';
  assetIndex: Scalars['Int'];
  componentName: Scalars['String'];
  editionNumber: Scalars['Int'];
  filePath: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  percentBoost: Scalars['Int'];
  rarityLevel: RarityLevel;
  realmAffiliation: RealmType;
  skill: Maybe<SkillDefinition>;
  slot: FurballSlot;
};

export type EditionAssetFilterInput = {
  and: Maybe<Array<EditionAssetFilterInput>>;
  assetIndex: Maybe<IntOperationFilterInput>;
  componentName: Maybe<StringOperationFilterInput>;
  editionNumber: Maybe<IntOperationFilterInput>;
  filePath: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<EditionAssetFilterInput>>;
  percentBoost: Maybe<IntOperationFilterInput>;
  rarityLevel: Maybe<RarityLevelOperationFilterInput>;
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  skill: Maybe<SkillDefinitionFilterInput>;
  slot: Maybe<FurballSlotOperationFilterInput>;
};

export type EditionAssetSortInput = {
  assetIndex: Maybe<SortEnumType>;
  componentName: Maybe<SortEnumType>;
  editionNumber: Maybe<SortEnumType>;
  filePath: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  percentBoost: Maybe<SortEnumType>;
  rarityLevel: Maybe<SortEnumType>;
  realmAffiliation: Maybe<SortEnumType>;
  skill: Maybe<SkillDefinitionSortInput>;
  slot: Maybe<SortEnumType>;
};

export type EditionBase = {
  __typename?: 'EditionBase';
  allAssets: Array<EditionAsset>;
  asset: Maybe<EditionAsset>;
  assetSlots: Array<FurballSlot>;
  backgroundColors: Array<Scalars['String']>;
  bodies: Array<EditionAsset>;
  byteNumber: Scalars['UnsignedShort'];
  ears: Array<EditionAsset>;
  editionIndex: Scalars['Int'];
  eyes: Array<EditionAsset>;
  hats: Array<EditionAsset>;
  mouths: Array<EditionAsset>;
  offHands: Array<EditionAsset>;
  outfits: Array<EditionAsset>;
  paletteColors: Array<Scalars['String']>;
  slotAssets: Array<EditionAsset>;
  tails: Array<EditionAsset>;
  tattoos: Array<EditionAsset>;
  weapons: Array<EditionAsset>;
  wings: Array<EditionAsset>;
};

export type EditionBaseAssetArgs = {
  offset: Scalars['Int'];
  tokenByte: Scalars['UnsignedShort'];
};

export type EditionBaseByteNumberArgs = {
  slot: FurballSlot;
};

export type EditionBaseSlotAssetsArgs = {
  slot: FurballSlot;
};

export type EditionBaseFilterInput = {
  allAssets: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  and: Maybe<Array<EditionBaseFilterInput>>;
  assetSlots: Maybe<ListFurballSlotOperationFilterInput>;
  backgroundColors: Maybe<ListStringOperationFilterInput>;
  bodies: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  ears: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  editionIndex: Maybe<IntOperationFilterInput>;
  eyes: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  hats: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  mouths: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  offHands: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  or: Maybe<Array<EditionBaseFilterInput>>;
  outfits: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  paletteColors: Maybe<ListStringOperationFilterInput>;
  tails: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  tattoos: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  weapons: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  wings: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
};

export type EditionBaseSortInput = {
  editionIndex: Maybe<SortEnumType>;
};

export type EntityAnimationCollection = {
  __typename?: 'EntityAnimationCollection';
  animationGroup: Maybe<Scalars['String']>;
  animationKey: Scalars['String'];
  animations: Array<KeyValuePairOfStringAndAnimationAsset>;
  attack: AnimationAsset;
  attackRanged: AnimationAsset;
  buffAP: AnimationAsset;
  buffCritAP: AnimationAsset;
  buffDP: AnimationAsset;
  death: AnimationAsset;
  debuffAP: AnimationAsset;
  debuffCritAP: AnimationAsset;
  debuffDP: AnimationAsset;
  find: AnimationAsset;
  heal: AnimationAsset;
  idle: AnimationAsset;
  idleVariation: AnimationAsset;
  invulnerability: AnimationAsset;
  jumpLand: AnimationAsset;
  jumpStart: AnimationAsset;
  none: AnimationAsset;
  paralyze: AnimationAsset;
  revive: AnimationAsset;
  reviveTarget: AnimationAsset;
  slow: AnimationAsset;
  summon: AnimationAsset;
  takeDamage: AnimationAsset;
  vfxGroup: Maybe<Scalars['String']>;
};

export type EntityAnimationCollectionAnimationKeyArgs = {
  animationAsset: AnimationAssetInput;
};

export type EntityAnimationCollectionFindArgs = {
  animationId: Scalars['String'];
};

export type EntityAnimationCollectionFilterInput = {
  and: Maybe<Array<EntityAnimationCollectionFilterInput>>;
  animationGroup: Maybe<StringOperationFilterInput>;
  animations: Maybe<ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput>;
  attack: Maybe<AnimationAssetFilterInput>;
  attackRanged: Maybe<AnimationAssetFilterInput>;
  buffAP: Maybe<AnimationAssetFilterInput>;
  buffCritAP: Maybe<AnimationAssetFilterInput>;
  buffDP: Maybe<AnimationAssetFilterInput>;
  death: Maybe<AnimationAssetFilterInput>;
  debuffAP: Maybe<AnimationAssetFilterInput>;
  debuffCritAP: Maybe<AnimationAssetFilterInput>;
  debuffDP: Maybe<AnimationAssetFilterInput>;
  heal: Maybe<AnimationAssetFilterInput>;
  idle: Maybe<AnimationAssetFilterInput>;
  idleVariation: Maybe<AnimationAssetFilterInput>;
  invulnerability: Maybe<AnimationAssetFilterInput>;
  jumpLand: Maybe<AnimationAssetFilterInput>;
  jumpStart: Maybe<AnimationAssetFilterInput>;
  none: Maybe<AnimationAssetFilterInput>;
  or: Maybe<Array<EntityAnimationCollectionFilterInput>>;
  paralyze: Maybe<AnimationAssetFilterInput>;
  revive: Maybe<AnimationAssetFilterInput>;
  reviveTarget: Maybe<AnimationAssetFilterInput>;
  slow: Maybe<AnimationAssetFilterInput>;
  summon: Maybe<AnimationAssetFilterInput>;
  takeDamage: Maybe<AnimationAssetFilterInput>;
  vfxGroup: Maybe<StringOperationFilterInput>;
};

export type EntityAnimationCollectionSortInput = {
  animationGroup: Maybe<SortEnumType>;
  attack: Maybe<AnimationAssetSortInput>;
  attackRanged: Maybe<AnimationAssetSortInput>;
  buffAP: Maybe<AnimationAssetSortInput>;
  buffCritAP: Maybe<AnimationAssetSortInput>;
  buffDP: Maybe<AnimationAssetSortInput>;
  death: Maybe<AnimationAssetSortInput>;
  debuffAP: Maybe<AnimationAssetSortInput>;
  debuffCritAP: Maybe<AnimationAssetSortInput>;
  debuffDP: Maybe<AnimationAssetSortInput>;
  heal: Maybe<AnimationAssetSortInput>;
  idle: Maybe<AnimationAssetSortInput>;
  idleVariation: Maybe<AnimationAssetSortInput>;
  invulnerability: Maybe<AnimationAssetSortInput>;
  jumpLand: Maybe<AnimationAssetSortInput>;
  jumpStart: Maybe<AnimationAssetSortInput>;
  none: Maybe<AnimationAssetSortInput>;
  paralyze: Maybe<AnimationAssetSortInput>;
  revive: Maybe<AnimationAssetSortInput>;
  reviveTarget: Maybe<AnimationAssetSortInput>;
  slow: Maybe<AnimationAssetSortInput>;
  summon: Maybe<AnimationAssetSortInput>;
  takeDamage: Maybe<AnimationAssetSortInput>;
  vfxGroup: Maybe<SortEnumType>;
};

export type EntityEffect = IBattleEffect & {
  __typename?: 'EntityEffect';
  /** If the effect is acive (time based) will be non null */
  activeAt: Maybe<Scalars['DateTime']>;
  /** Who created the effect (the Skill.Entity) */
  actor: StatEntity;
  animation: AnimationAsset;
  animationId: Scalars['String'];
  /** 0 = neutral, 1 = buff, -1 = debuff */
  buffType: Scalars['Int'];
  created: Scalars['DateTime'];
  createdAt: Maybe<Scalars['DateTime']>;
  decay: Scalars['Float'];
  /** If the effect has a duration, the DeductAt will be set to the expiration time (otherwise null) */
  deductAt: Maybe<Scalars['DateTime']>;
  /** The target of the effect */
  entity: StatEntity;
  entityId: Scalars['String'];
  icon: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  isInstant: Scalars['Boolean'];
  /** The item which created this */
  item: Maybe<GameItem>;
  /** Passthrough to SkillEffect.Operand (used by IBattleEffect) */
  operand: EntityEffectOperand;
  /** Passthrough to SkillEffect.Phase (used by IBattleEffect) */
  phase: BattleTurnPhase;
  reApply: Scalars['Boolean'];
  /** If the DeductAt has passed and this has been removed (it is used to filter out the search during queries when non null) */
  removedAt: Maybe<Scalars['DateTime']>;
  sfx: Maybe<SfxAsset>;
  /** [LL] the skill whence the effect came */
  skill: Maybe<EntitySkill>;
  skillEffect: SkillStatEffect;
  /** A reference to look up the SkillEffect */
  skillEffectId: Scalars['String'];
  /** The SkillEffect.Definition.Name */
  skillEffectName: Scalars['String'];
  skillId: Maybe<Scalars['String']>;
  /** Passthrough to SkillEffect.Stat (used by IBattleEffect) */
  stat: StatEffectTarget;
  /** Using the SkillEffect.SummaryStatTemplate, compute the stat summary */
  summaryStat: Scalars['String'];
  /** NULL if DeductAt is null... otherwise, how much time before it will be removed */
  timeRemaining: Maybe<Scalars['TimeSpan']>;
  /** Partially deprecated, replaced by DeductAt */
  turnsRemaining: Scalars['Int'];
  /** The final computed value of the effect */
  value: Scalars['Int'];
  vfx: Maybe<VfxAsset>;
  vfxId: Maybe<Scalars['String']>;
};

export type EntityEffectFilterInput = {
  /** If the effect is acive (time based) will be non null */
  activeAt: Maybe<DateTimeOperationFilterInput>;
  /** Who created the effect (the Skill.Entity) */
  actor: Maybe<StatEntityFilterInput>;
  and: Maybe<Array<EntityEffectFilterInput>>;
  animation: Maybe<AnimationAssetFilterInput>;
  animationId: Maybe<StringOperationFilterInput>;
  /** 0 = neutral, 1 = buff, -1 = debuff */
  buffType: Maybe<IntOperationFilterInput>;
  created: Maybe<DateTimeOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  decay: Maybe<FloatOperationFilterInput>;
  /** If the effect has a duration, the DeductAt will be set to the expiration time (otherwise null) */
  deductAt: Maybe<DateTimeOperationFilterInput>;
  /** The target of the effect */
  entity: Maybe<StatEntityFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  icon: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isActive: Maybe<BooleanOperationFilterInput>;
  isInstant: Maybe<BooleanOperationFilterInput>;
  /** The item which created this */
  item: Maybe<GameItemFilterInput>;
  /** Passthrough to SkillEffect.Operand (used by IBattleEffect) */
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<EntityEffectFilterInput>>;
  /** Passthrough to SkillEffect.Phase (used by IBattleEffect) */
  phase: Maybe<BattleTurnPhaseOperationFilterInput>;
  reApply: Maybe<BooleanOperationFilterInput>;
  /** If the DeductAt has passed and this has been removed (it is used to filter out the search during queries when non null) */
  removedAt: Maybe<DateTimeOperationFilterInput>;
  sfx: Maybe<SfxAssetFilterInput>;
  skill: Maybe<EntitySkillFilterInput>;
  skillEffect: Maybe<SkillStatEffectFilterInput>;
  /** A reference to look up the SkillEffect */
  skillEffectId: Maybe<StringOperationFilterInput>;
  /** The SkillEffect.Definition.Name */
  skillEffectName: Maybe<StringOperationFilterInput>;
  skillId: Maybe<StringOperationFilterInput>;
  /** Passthrough to SkillEffect.Stat (used by IBattleEffect) */
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  /** Using the SkillEffect.SummaryStatTemplate, compute the stat summary */
  summaryStat: Maybe<StringOperationFilterInput>;
  /** NULL if DeductAt is null... otherwise, how much time before it will be removed */
  timeRemaining: Maybe<TimeSpanOperationFilterInput>;
  /** Partially deprecated, replaced by DeductAt */
  turnsRemaining: Maybe<IntOperationFilterInput>;
  /** The final computed value of the effect */
  value: Maybe<IntOperationFilterInput>;
  vfx: Maybe<VfxAssetFilterInput>;
  vfxId: Maybe<StringOperationFilterInput>;
};

export enum EntityEffectOperand {
  Add = 'ADD',
  Leech = 'LEECH',
  Set = 'SET',
  Subtract = 'SUBTRACT',
}

export type EntityEffectOperandOperationFilterInput = {
  eq: Maybe<EntityEffectOperand>;
  in: Maybe<Array<EntityEffectOperand>>;
  neq: Maybe<EntityEffectOperand>;
  nin: Maybe<Array<EntityEffectOperand>>;
};

export type EntitySkill = {
  __typename?: 'EntitySkill';
  aiTargetingRules: Maybe<TargetingRule>;
  aiTargetingStrategy: Maybe<TargetingStrategy>;
  aiUsageFrequency: Maybe<UsageFrequency>;
  buffType: Scalars['Int'];
  createdAt: Maybe<Scalars['DateTime']>;
  definition: SkillDefinition;
  effectsSummary: Array<Scalars['String']>;
  /** [LL] the entity to which it belongs */
  entity: StatEntity;
  entityId: Scalars['String'];
  id: Scalars['ID'];
  level: Scalars['Int'];
  levelIsUpgradeable: Scalars['Boolean'];
  levelNumPartialsPerTier: Scalars['Int'];
  /** How much does each partial cost? */
  levelPartialCost: Scalars['Int'];
  /** How many of LevelNumPartialsPerTier have been upgraded? */
  levelPartialUpgrade: Scalars['Int'];
  levelPartialsPurchased: Scalars['Int'];
  /** How many are remaining until the next Level? */
  levelTotalPartialsRemaining: Scalars['Int'];
  levelTotalSpent: Scalars['Long'];
  levelUpgradeTotalCost: Scalars['Int'];
  /** The last time Level was upgraded (must be at least 24h ago to upgrade) */
  levelUpgradedAt: Maybe<Scalars['DateTime']>;
  maxLevel: Scalars['Int'];
  maxUses: Scalars['Int'];
  maxUsesBoost: Scalars['Int'];
  maxUsesIsUpgradeable: Scalars['Boolean'];
  maxUsesNumPartialsPerTier: Scalars['Int'];
  maxUsesPartialCost: Scalars['Int'];
  maxUsesPartialUpgrade: Scalars['Int'];
  maxUsesPartialsPurchased: Scalars['Int'];
  maxUsesTotalPartialsRemaining: Scalars['Int'];
  maxUsesTotalSpent: Scalars['Long'];
  maxUsesUpgradeTotalCost: Scalars['Int'];
  /** The last time MaxUses were upgraded (must be at least 24h ago) */
  maxUsesUpgradedAt: Maybe<Scalars['DateTime']>;
  skillDefinitionId: Scalars['Long'];
  /** Which of the entity's slots does this skill reside in? */
  slotIndex: Scalars['Int'];
  stat: StatEffectTarget;
  summary: Scalars['String'];
  totalFurSpent: Scalars['Long'];
  updatedAt: Maybe<Scalars['DateTime']>;
  useCount: Scalars['Int'];
  value: Maybe<Scalars['Int']>;
  valueUnits: Maybe<Scalars['String']>;
};

export type EntitySkillFilterInput = {
  aiTargetingRules: Maybe<NullableOfTargetingRuleOperationFilterInput>;
  aiTargetingStrategy: Maybe<NullableOfTargetingStrategyOperationFilterInput>;
  aiUsageFrequency: Maybe<NullableOfUsageFrequencyOperationFilterInput>;
  and: Maybe<Array<EntitySkillFilterInput>>;
  buffType: Maybe<IntOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<SkillDefinitionFilterInput>;
  effectsSummary: Maybe<ListStringOperationFilterInput>;
  entity: Maybe<StatEntityFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  levelIsUpgradeable: Maybe<BooleanOperationFilterInput>;
  levelNumPartialsPerTier: Maybe<IntOperationFilterInput>;
  /** How much does each partial cost? */
  levelPartialCost: Maybe<IntOperationFilterInput>;
  /** How many of LevelNumPartialsPerTier have been upgraded? */
  levelPartialUpgrade: Maybe<IntOperationFilterInput>;
  levelPartialsPurchased: Maybe<IntOperationFilterInput>;
  /** How many are remaining until the next Level? */
  levelTotalPartialsRemaining: Maybe<IntOperationFilterInput>;
  levelTotalSpent: Maybe<LongOperationFilterInput>;
  levelUpgradeTotalCost: Maybe<IntOperationFilterInput>;
  /** The last time Level was upgraded (must be at least 24h ago to upgrade) */
  levelUpgradedAt: Maybe<DateTimeOperationFilterInput>;
  maxLevel: Maybe<IntOperationFilterInput>;
  maxUses: Maybe<IntOperationFilterInput>;
  maxUsesBoost: Maybe<IntOperationFilterInput>;
  maxUsesIsUpgradeable: Maybe<BooleanOperationFilterInput>;
  maxUsesNumPartialsPerTier: Maybe<IntOperationFilterInput>;
  maxUsesPartialCost: Maybe<IntOperationFilterInput>;
  maxUsesPartialUpgrade: Maybe<IntOperationFilterInput>;
  maxUsesPartialsPurchased: Maybe<IntOperationFilterInput>;
  maxUsesTotalPartialsRemaining: Maybe<IntOperationFilterInput>;
  maxUsesTotalSpent: Maybe<LongOperationFilterInput>;
  maxUsesUpgradeTotalCost: Maybe<IntOperationFilterInput>;
  /** The last time MaxUses were upgraded (must be at least 24h ago) */
  maxUsesUpgradedAt: Maybe<DateTimeOperationFilterInput>;
  or: Maybe<Array<EntitySkillFilterInput>>;
  skillDefinitionId: Maybe<LongOperationFilterInput>;
  /** Which of the entity's slots does this skill reside in? */
  slotIndex: Maybe<IntOperationFilterInput>;
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  summary: Maybe<StringOperationFilterInput>;
  totalFurSpent: Maybe<LongOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  useCount: Maybe<IntOperationFilterInput>;
  value: Maybe<IntOperationFilterInput>;
  valueUnits: Maybe<StringOperationFilterInput>;
};

/** Equipment items, to be worn, must be placed within the Equipment array of a StatEntity */
export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  /** When will this item break, if equipped? Computed/set at the same time as EquippedAt, by adding DurabilityTime */
  breakAt: Maybe<Scalars['DateTime']>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** How long this equipment has remaining (seconds) after the equippedAt time; will go down when unequipped; negative=blessed (unbreakable) */
  durabilityTime: Scalars['Int'];
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  effects: Array<IBattleEffect>;
  /** If equipped, the entity which equipped it */
  entity: Maybe<GameEntity>;
  /** If equipped, the entity which equipped it */
  entityId: Maybe<Scalars['String']>;
  /** If equipped, when? */
  equippedAt: Maybe<Scalars['DateTime']>;
  /** If equipped by someone OTHER than the owner of the Furball, the player who equipped it */
  equippedBy: Maybe<FurPlayer>;
  /** If equipped by someone OTHER than the owner of the Furball, the playerId who equipped it */
  equippedById: Maybe<Scalars['String']>;
  /** If non-null, the equipment always returns to this Furball's inventory.  */
  fusedFurball: Maybe<Furball>;
  /** If non-null, the equipment always returns to this Furball's inventory.  */
  fusedFurballId: Maybe<Scalars['String']>;
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  /** A top level grouping derived from the ItemId */
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  passive: Scalars['String'];
  /** Passive effects granted by this equipment when equipped. */
  passives: Array<BattleEffectPassive>;
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** Of the 9 positions for a piece of equipment, which is this? */
  position: Scalars['Int'];
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** Which of the equipment slots */
  slot: EquipmentSlot;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
};

/** Equipment items, to be worn, must be placed within the Equipment array of a StatEntity */
export type EquipmentItemFilterInput = {
  and: Maybe<Array<EquipmentItemFilterInput>>;
  /** When will this item break, if equipped? Computed/set at the same time as EquippedAt, by adding DurabilityTime */
  breakAt: Maybe<DateTimeOperationFilterInput>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** How long this equipment has remaining (seconds) after the equippedAt time; will go down when unequipped; negative=blessed (unbreakable) */
  durabilityTime: Maybe<IntOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  effects: Maybe<ListFilterInputTypeOfIBattleEffectFilterInput>;
  /** If equipped, the entity which equipped it */
  entity: Maybe<GameEntityFilterInput>;
  /** If equipped, the entity which equipped it */
  entityId: Maybe<StringOperationFilterInput>;
  /** If equipped, when? */
  equippedAt: Maybe<DateTimeOperationFilterInput>;
  /** If equipped by someone OTHER than the owner of the Furball, the player who equipped it */
  equippedBy: Maybe<FurPlayerFilterInput>;
  /** If equipped by someone OTHER than the owner of the Furball, the playerId who equipped it */
  equippedById: Maybe<StringOperationFilterInput>;
  /** If non-null, the equipment always returns to this Furball's inventory.  */
  fusedFurball: Maybe<FurballFilterInput>;
  /** If non-null, the equipment always returns to this Furball's inventory.  */
  fusedFurballId: Maybe<StringOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<EquipmentItemFilterInput>>;
  passive: Maybe<StringOperationFilterInput>;
  /** Passive effects granted by this equipment when equipped. */
  passives: Maybe<ListFilterInputTypeOfBattleEffectPassiveFilterInput>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** Of the 9 positions for a piece of equipment, which is this? */
  position: Maybe<IntOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** Which of the equipment slots */
  slot: Maybe<EquipmentSlotOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

export enum EquipmentSlot {
  Body = 'BODY',
  Head = 'HEAD',
  OffHand = 'OFF_HAND',
  Ring = 'RING',
  Weapon = 'WEAPON',
}

export type EquipmentSlotOperationFilterInput = {
  eq: Maybe<EquipmentSlot>;
  in: Maybe<Array<EquipmentSlot>>;
  neq: Maybe<EquipmentSlot>;
  nin: Maybe<Array<EquipmentSlot>>;
};

export type EthExchange = {
  __typename?: 'EthExchange';
  usdPrice: Scalars['Float'];
};

export type FloatOperationFilterInput = {
  eq: Maybe<Scalars['Float']>;
  gt: Maybe<Scalars['Float']>;
  gte: Maybe<Scalars['Float']>;
  in: Maybe<Array<Maybe<Scalars['Float']>>>;
  lt: Maybe<Scalars['Float']>;
  lte: Maybe<Scalars['Float']>;
  neq: Maybe<Scalars['Float']>;
  ngt: Maybe<Scalars['Float']>;
  ngte: Maybe<Scalars['Float']>;
  nin: Maybe<Array<Maybe<Scalars['Float']>>>;
  nlt: Maybe<Scalars['Float']>;
  nlte: Maybe<Scalars['Float']>;
};

export type FormationDefinition = {
  __typename?: 'FormationDefinition';
  comparator: Scalars['String'];
  isValid: Scalars['Boolean'];
  positions: Array<FormationPosition>;
  size: Scalars['Int'];
};

export type FormationDefinitionFilterInput = {
  and: Maybe<Array<FormationDefinitionFilterInput>>;
  comparator: Maybe<StringOperationFilterInput>;
  isValid: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<FormationDefinitionFilterInput>>;
  positions: Maybe<ListFormationPositionOperationFilterInput>;
  size: Maybe<IntOperationFilterInput>;
};

export type FormationDefinitionSortInput = {
  comparator: Maybe<SortEnumType>;
  isValid: Maybe<SortEnumType>;
  size: Maybe<SortEnumType>;
};

export enum FormationPosition {
  All = 'ALL',
  Any = 'ANY',
  Back = 'BACK',
  Bottom = 'BOTTOM',
  BottomBack = 'BOTTOM_BACK',
  BottomFront = 'BOTTOM_FRONT',
  Center = 'CENTER',
  Front = 'FRONT',
  Top = 'TOP',
  TopBack = 'TOP_BACK',
  TopFront = 'TOP_FRONT',
}

export type FormationPositionOperationFilterInput = {
  eq: Maybe<FormationPosition>;
  in: Maybe<Array<FormationPosition>>;
  neq: Maybe<FormationPosition>;
  nin: Maybe<Array<FormationPosition>>;
};

export type FurAccount = IFurPlayer & {
  __typename?: 'FurAccount';
  activeAgreements: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the owner */
  activeAgreementsOwner: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the scholar */
  activeAgreementsPlayer: Array<RentalAgreement>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  balance: Scalars['Decimal'];
  balanceFur: Scalars['Long'];
  balanceFurballs: Scalars['Long'];
  /** All battles in which this player has participated */
  battles: Array<GameBattle>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Scalars['Int'];
  /** Boss battles fought by this player */
  bossBattles: Array<GameBattleBoss>;
  communityFlags: Array<CommunityFlag>;
  countBossBattles: Scalars['Long'];
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Array<CraftingAttempt>;
  /** [LL] all crafting slots */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  createdTimestamp: Scalars['Int'];
  dirtyAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  discordProfile: Maybe<SocialProfile>;
  displayName: Scalars['String'];
  email: Maybe<Scalars['String']>;
  /** Computed current Fuel balance */
  fuel: Scalars['Long'];
  /** Earned off-chain */
  fuelEarned: Scalars['UnsignedLong'];
  /** Purchased on-chain */
  fuelPurchased: Scalars['Long'];
  /** Spent off-chain */
  fuelSpent: Scalars['Long'];
  furBalance: Scalars['Long'];
  furballBalance: Scalars['Long'];
  furballs: Array<Furball>;
  hasSetUsername: Scalars['Boolean'];
  /** Paginate through the history of the player */
  history: Maybe<HistoryConnection>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  /** [LL] the inventory of this player */
  inventory: ContainerItem;
  isAdmin: Scalars['Boolean'];
  isModerator: Scalars['Boolean'];
  /** The (active) listings created by this player */
  itemListings: Array<PlayerItemListing>;
  /** Get the leaderboard ranks for the player */
  leaderboardRanks: Array<LeaderboardRankPlayer>;
  maxFurballs: Scalars['Int'];
  maxLevel: Scalars['Int'];
  name: Scalars['String'];
  /** The last time this was checked by a BK worker */
  nextCheckAt: Scalars['DateTime'];
  numFurballs: Scalars['Int'];
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Scalars['Int'];
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Scalars['Int'];
  /** [LL] all onboarding steps */
  onboarding: Array<PlayerOnboardingStep>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  /** [LL] all social profiles associated with this user */
  payoutsPending: Array<PlayerPayout>;
  /** Paginate through the moves in the game */
  pendingFurWraps: Maybe<PendingFurWrapsConnection>;
  /** [LL] When a player earns a reward (such as from leaderboards); after claimed, it will move to the player inventory */
  pendingRewards: Array<PlayerPendingReward>;
  /** [LL] those FUR wraps which are in progress */
  pendingWraps: Array<TimekeeperWrapFur>;
  permissions: Scalars['Int'];
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  profiles: Array<SocialProfile>;
  /** All quests for this player */
  quests: Array<Quest>;
  /** [LL] the realms of games this player has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** The number of players whom this player has referred */
  referralCount: Scalars['UnsignedInt'];
  /** Those players whom this player has referred */
  referrals: Array<FurPlayer>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayer>;
  /** The player who referred this player, if any */
  referrerId: Maybe<Scalars['String']>;
  rentalAgreements: Array<RentalAgreement>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Scalars['Int'];
  reputation: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Array<RentalAgreement>;
  /** All sessions for this player */
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  /** [LL] all social profiles associated with this user */
  socialProfiles: Array<SocialProfile>;
  standing: Scalars['Int'];
  tix: Scalars['Float'];
  tixBought: Scalars['Float'];
  tixSpent: Scalars['Float'];
  /** Get the history on a single day from the player */
  today: PlayerHistoryDay;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Array<BlockchainTokenTransaction>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** [LL] all Furballs this player can use (rented plus owned) */
  usableFurballs: Array<Furball>;
  username: Maybe<Scalars['String']>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Scalars['Long'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
  writtenRolesList: Scalars['String'];
};

export type FurAccountCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurAccountHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurAccountPendingFurWrapsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurAccountPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type FurAccountFilterInput = {
  activeAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionFilterInput>;
  and: Maybe<Array<FurAccountFilterInput>>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<StringOperationFilterInput>;
  balance: Maybe<DecimalOperationFilterInput>;
  balanceFur: Maybe<LongOperationFilterInput>;
  balanceFurballs: Maybe<LongOperationFilterInput>;
  /** All battles in which this player has participated */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** Boss battles fought by this player */
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  communityFlags: Maybe<ListCommunityFlagOperationFilterInput>;
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Maybe<ListFilterInputTypeOfCraftingAttemptFilterInput>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Maybe<ListFilterInputTypeOfCraftSlotFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  createdTimestamp: Maybe<IntOperationFilterInput>;
  dirtyAt: Maybe<DateTimeOperationFilterInput>;
  discordProfile: Maybe<SocialProfileFilterInput>;
  displayName: Maybe<StringOperationFilterInput>;
  email: Maybe<StringOperationFilterInput>;
  /** Computed current Fuel balance */
  fuel: Maybe<LongOperationFilterInput>;
  /** Purchased on-chain */
  fuelPurchased: Maybe<LongOperationFilterInput>;
  /** Spent off-chain */
  fuelSpent: Maybe<LongOperationFilterInput>;
  furBalance: Maybe<LongOperationFilterInput>;
  furballBalance: Maybe<LongOperationFilterInput>;
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  hasSetUsername: Maybe<BooleanOperationFilterInput>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Maybe<ListFilterInputTypeOfPlayerHistoryDayFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isAdmin: Maybe<BooleanOperationFilterInput>;
  isModerator: Maybe<BooleanOperationFilterInput>;
  /** The (active) listings created by this player */
  itemListings: Maybe<ListFilterInputTypeOfPlayerItemListingFilterInput>;
  maxFurballs: Maybe<IntOperationFilterInput>;
  maxLevel: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<DateTimeOperationFilterInput>;
  numFurballs: Maybe<IntOperationFilterInput>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<IntOperationFilterInput>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<IntOperationFilterInput>;
  /** [DB] The onboarding steps the player has completed */
  onboarding: Maybe<ListFilterInputTypeOfPlayerOnboardingStepFilterInput>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurAccountFilterInput>>;
  payouts: Maybe<ListFilterInputTypeOfPlayerPayoutFilterInput>;
  permissions: Maybe<IntOperationFilterInput>;
  profiles: Maybe<ListFilterInputTypeOfSocialProfileFilterInput>;
  /** All quests for this player */
  quests: Maybe<ListFilterInputTypeOfQuestFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  /** Those players whom this player has referred */
  referrals: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<StringOperationFilterInput>;
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<IntOperationFilterInput>;
  reputation: Maybe<IntOperationFilterInput>;
  roleFlags: Maybe<ListStringOperationFilterInput>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** All sessions for this player */
  sessions: Maybe<ListFilterInputTypeOfFurSessionFilterInput>;
  socialName: Maybe<StringOperationFilterInput>;
  socialProfiles: Maybe<ListFilterInputTypeOfSocialProfileFilterInput>;
  standing: Maybe<IntOperationFilterInput>;
  tix: Maybe<FloatOperationFilterInput>;
  tixBought: Maybe<FloatOperationFilterInput>;
  tixSpent: Maybe<FloatOperationFilterInput>;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<LongOperationFilterInput>;
  waitlistFlagList: Maybe<StringOperationFilterInput>;
  waitlistFlags: Maybe<ListWaitlistFlagOperationFilterInput>;
  writtenRoles: Maybe<ListStringOperationFilterInput>;
  writtenRolesList: Maybe<StringOperationFilterInput>;
};

export type FurAccountSortInput = {
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionSortInput>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<SortEnumType>;
  balance: Maybe<SortEnumType>;
  balanceFur: Maybe<SortEnumType>;
  balanceFurballs: Maybe<SortEnumType>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  createdTimestamp: Maybe<SortEnumType>;
  dirtyAt: Maybe<SortEnumType>;
  discordId: Maybe<SortEnumType>;
  discordProfile: Maybe<SocialProfileSortInput>;
  displayName: Maybe<SortEnumType>;
  email: Maybe<SortEnumType>;
  /** Computed current Fuel balance */
  fuel: Maybe<SortEnumType>;
  /** Earned off-chain */
  fuelEarned: Maybe<SortEnumType>;
  /** Purchased on-chain */
  fuelPurchased: Maybe<SortEnumType>;
  /** Spent off-chain */
  fuelSpent: Maybe<SortEnumType>;
  furBalance: Maybe<SortEnumType>;
  furballBalance: Maybe<SortEnumType>;
  hasSetUsername: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isAdmin: Maybe<SortEnumType>;
  isModerator: Maybe<SortEnumType>;
  maxFurballs: Maybe<SortEnumType>;
  maxLevel: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<SortEnumType>;
  numFurballs: Maybe<SortEnumType>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<SortEnumType>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<SortEnumType>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<SortEnumType>;
  permissions: Maybe<SortEnumType>;
  /** The number of players whom this player has referred */
  referralCount: Maybe<SortEnumType>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerSortInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<SortEnumType>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<SortEnumType>;
  reputation: Maybe<SortEnumType>;
  socialName: Maybe<SortEnumType>;
  standing: Maybe<SortEnumType>;
  tix: Maybe<SortEnumType>;
  tixBought: Maybe<SortEnumType>;
  tixSpent: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<SortEnumType>;
  waitlistFlagList: Maybe<SortEnumType>;
  writtenRolesList: Maybe<SortEnumType>;
};

export type FurExceptionInput = {
  innerException: Maybe<FurExceptionInput>;
  message: Scalars['String'];
  name: Scalars['String'];
  stackTrace: Array<Scalars['String']>;
};

export type FurExchange = {
  __typename?: 'FurExchange';
  /** [DB] the max allowed to purchase at this price. */
  amountAvailable: Scalars['Long'];
  costBuy: CurrencyCosts;
  costSwap: CurrencyCosts;
  fundingLots: Array<FurFundingLot>;
};

export type FurFundingLot = {
  __typename?: 'FurFundingLot';
  /** [DB] the amount remaining, of the total available */
  amountRemaining: Scalars['Long'];
  /** [DB] once AmountRemaining=0, this may be closed (paid out) */
  closedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  fuelCostBase: Scalars['Long'];
  /** [DB] the cost BASIS per single FUR */
  fuelCostEach: Scalars['Float'];
  /** [DB] the amount of FUR directly contributed to this lot */
  funding: Scalars['Long'];
  /** [DB] the funding transactions that made up the lot */
  fundingTransactions: Array<BlockchainTokenTransaction>;
  id: Scalars['String'];
  maximumPurchaseQuantity: Scalars['Long'];
  /** Computed from price; whatever amounts to 1 TIX per purchase... */
  minimumPurchaseQuantity: Scalars['Int'];
  /** [DB] the currencies given to players in compensation */
  payouts: Array<PlayerPayout>;
  /** [DB] the purchases by players */
  playerPurchases: Array<FurPurchasePlayer>;
  /** [DB] the entire lot size, including consolidated transactions */
  total: Scalars['Long'];
};

export type FurOAuthToken = {
  __typename?: 'FurOAuthToken';
  access: Scalars['Int'];
  deadline: Scalars['Int'];
  id: Scalars['ID'];
  owner: Scalars['String'];
  signature: Scalars['String'];
};

export type FurOAuthTokenFilterInput = {
  access: Maybe<IntOperationFilterInput>;
  and: Maybe<Array<FurOAuthTokenFilterInput>>;
  deadline: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurOAuthTokenFilterInput>>;
  owner: Maybe<StringOperationFilterInput>;
  signature: Maybe<StringOperationFilterInput>;
};

export type FurOAuthTokenInput = {
  access: Scalars['Int'];
  deadline: Scalars['Int'];
  id: Scalars['String'];
  owner: Scalars['String'];
  signature: Scalars['String'];
};

export type FurOAuthTokenSortInput = {
  access: Maybe<SortEnumType>;
  deadline: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  owner: Maybe<SortEnumType>;
  signature: Maybe<SortEnumType>;
};

export type FurPlayer = IFurPlayer & {
  __typename?: 'FurPlayer';
  activeAgreements: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the owner */
  activeAgreementsOwner: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the scholar */
  activeAgreementsPlayer: Array<RentalAgreement>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  /** All battles in which this player has participated */
  battles: Array<GameBattle>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Scalars['Int'];
  /** Boss battles fought by this player */
  bossBattles: Array<GameBattleBoss>;
  communityFlags: Array<CommunityFlag>;
  countBossBattles: Scalars['Long'];
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Array<CraftingAttempt>;
  /** [LL] all crafting slots */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  email: Maybe<Scalars['String']>;
  furBalance: Scalars['Long'];
  furballBalance: Scalars['Long'];
  hasSetUsername: Scalars['Boolean'];
  /** Paginate through the history of the player */
  history: Maybe<HistoryConnection>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  /** [LL] the inventory of this player */
  inventory: ContainerItem;
  /** The (active) listings created by this player */
  itemListings: Array<PlayerItemListing>;
  /** Get the leaderboard ranks for the player */
  leaderboardRanks: Array<LeaderboardRankPlayer>;
  name: Scalars['String'];
  /** The last time this was checked by a BK worker */
  nextCheckAt: Scalars['DateTime'];
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Scalars['Int'];
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Scalars['Int'];
  /** [LL] all onboarding steps */
  onboarding: Array<PlayerOnboardingStep>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  /** [LL] all social profiles associated with this user */
  payoutsPending: Array<PlayerPayout>;
  /** [LL] When a player earns a reward (such as from leaderboards); after claimed, it will move to the player inventory */
  pendingRewards: Array<PlayerPendingReward>;
  /** [LL] those FUR wraps which are in progress */
  pendingWraps: Array<TimekeeperWrapFur>;
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  /** All quests for this player */
  quests: Array<Quest>;
  /** [LL] the realms of games this player has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** The number of players whom this player has referred */
  referralCount: Scalars['UnsignedInt'];
  /** Those players whom this player has referred */
  referrals: Array<FurPlayer>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayer>;
  /** The player who referred this player, if any */
  referrerId: Maybe<Scalars['String']>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Array<RentalAgreement>;
  /** All sessions for this player */
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  /** [LL] all social profiles associated with this user */
  socialProfiles: Array<SocialProfile>;
  /** The number of TIX owned */
  tix: Scalars['Float'];
  /** The number of TIX ever bought */
  tixBought: Scalars['Float'];
  /** The number of TIX ever spent */
  tixSpent: Scalars['Float'];
  /** Get the history on a single day from the player */
  today: PlayerHistoryDay;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Array<BlockchainTokenTransaction>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** [LL] all Furballs this player can use (rented plus owned) */
  usableFurballs: Array<Furball>;
  username: Maybe<Scalars['String']>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Scalars['Long'];
  waitlistFlagList: Scalars['String'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
  writtenRolesList: Scalars['String'];
};

export type FurPlayerCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurPlayerHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurPlayerPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type FurPlayerFilterInput = {
  activeAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionFilterInput>;
  and: Maybe<Array<FurPlayerFilterInput>>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<StringOperationFilterInput>;
  /** All battles in which this player has participated */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Maybe<IntOperationFilterInput>;
  /** Boss battles fought by this player */
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  communityFlags: Maybe<ListCommunityFlagOperationFilterInput>;
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Maybe<ListFilterInputTypeOfCraftingAttemptFilterInput>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Maybe<ListFilterInputTypeOfCraftSlotFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  email: Maybe<StringOperationFilterInput>;
  furBalance: Maybe<LongOperationFilterInput>;
  furballBalance: Maybe<LongOperationFilterInput>;
  hasSetUsername: Maybe<BooleanOperationFilterInput>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Maybe<ListFilterInputTypeOfPlayerHistoryDayFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** The (active) listings created by this player */
  itemListings: Maybe<ListFilterInputTypeOfPlayerItemListingFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<DateTimeOperationFilterInput>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<IntOperationFilterInput>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<IntOperationFilterInput>;
  /** [DB] The onboarding steps the player has completed */
  onboarding: Maybe<ListFilterInputTypeOfPlayerOnboardingStepFilterInput>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurPlayerFilterInput>>;
  payouts: Maybe<ListFilterInputTypeOfPlayerPayoutFilterInput>;
  /** All quests for this player */
  quests: Maybe<ListFilterInputTypeOfQuestFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  /** Those players whom this player has referred */
  referrals: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<StringOperationFilterInput>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<IntOperationFilterInput>;
  roleFlags: Maybe<ListStringOperationFilterInput>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** All sessions for this player */
  sessions: Maybe<ListFilterInputTypeOfFurSessionFilterInput>;
  socialName: Maybe<StringOperationFilterInput>;
  /** The number of TIX owned */
  tix: Maybe<FloatOperationFilterInput>;
  /** The number of TIX ever bought */
  tixBought: Maybe<FloatOperationFilterInput>;
  /** The number of TIX ever spent */
  tixSpent: Maybe<FloatOperationFilterInput>;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<LongOperationFilterInput>;
  waitlistFlagList: Maybe<StringOperationFilterInput>;
  waitlistFlags: Maybe<ListWaitlistFlagOperationFilterInput>;
  writtenRoles: Maybe<ListStringOperationFilterInput>;
  writtenRolesList: Maybe<StringOperationFilterInput>;
};

export type FurPlayerSortInput = {
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionSortInput>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<SortEnumType>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  discordId: Maybe<SortEnumType>;
  email: Maybe<SortEnumType>;
  furBalance: Maybe<SortEnumType>;
  furballBalance: Maybe<SortEnumType>;
  hasSetUsername: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<SortEnumType>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<SortEnumType>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<SortEnumType>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<SortEnumType>;
  /** The number of players whom this player has referred */
  referralCount: Maybe<SortEnumType>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerSortInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<SortEnumType>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<SortEnumType>;
  socialName: Maybe<SortEnumType>;
  /** The number of TIX owned */
  tix: Maybe<SortEnumType>;
  /** The number of TIX ever bought */
  tixBought: Maybe<SortEnumType>;
  /** The number of TIX ever spent */
  tixSpent: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<SortEnumType>;
  waitlistFlagList: Maybe<SortEnumType>;
  writtenRolesList: Maybe<SortEnumType>;
};

export type FurPurchasePlayer = {
  __typename?: 'FurPurchasePlayer';
  createdAt: Maybe<Scalars['DateTime']>;
  ethCost: Scalars['Float'];
  /** [DB] the cost, in Fuel, to purchase the FUR */
  fuelCost: Scalars['Long'];
  /** [DB] the lots used to fund this transaction */
  fundingLots: Array<FurFundingLot>;
  /** [DB] the completed purchase transaction */
  furTransaction: BlockchainTokenTransaction;
  id: Scalars['String'];
  /** [DB] the player for whom the quote was generated */
  player: FurPlayer;
  /** [DB] the amount of FUR to purchase */
  quantity: Scalars['Long'];
  tixCost: Scalars['Float'];
  /** [DB] the completed purchase transaction */
  tixTransaction: BlockchainTokenTransaction;
};

export type FurScholar = IFurPlayer & {
  __typename?: 'FurScholar';
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccount>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<Scalars['String']>;
  activeAgreements: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the owner */
  activeAgreementsOwner: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the scholar */
  activeAgreementsPlayer: Array<RentalAgreement>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  /** All battles in which this player has participated */
  battles: Array<GameBattle>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Scalars['Int'];
  /** Boss battles fought by this player */
  bossBattles: Array<GameBattleBoss>;
  communityFlags: Array<CommunityFlag>;
  countBossBattles: Scalars['Long'];
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Array<CraftingAttempt>;
  /** [LL] all crafting slots */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  email: Maybe<Scalars['String']>;
  furBalance: Scalars['Long'];
  furballBalance: Scalars['Long'];
  hasSetUsername: Scalars['Boolean'];
  /** Paginate through the history of the player */
  history: Maybe<HistoryConnection>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  /** [LL] the inventory of this player */
  inventory: ContainerItem;
  /** The (active) listings created by this player */
  itemListings: Array<PlayerItemListing>;
  /** Get the leaderboard ranks for the player */
  leaderboardRanks: Array<LeaderboardRankPlayer>;
  name: Scalars['String'];
  /** The last time this was checked by a BK worker */
  nextCheckAt: Scalars['DateTime'];
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Scalars['Int'];
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Scalars['Int'];
  /** [LL] all onboarding steps */
  onboarding: Array<PlayerOnboardingStep>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  /** [LL] all social profiles associated with this user */
  payoutsPending: Array<PlayerPayout>;
  /** [LL] When a player earns a reward (such as from leaderboards); after claimed, it will move to the player inventory */
  pendingRewards: Array<PlayerPendingReward>;
  /** [LL] those FUR wraps which are in progress */
  pendingWraps: Array<TimekeeperWrapFur>;
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  /** All quests for this player */
  quests: Array<Quest>;
  /** [LL] the realms of games this player has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** The number of players whom this player has referred */
  referralCount: Scalars['UnsignedInt'];
  /** Those players whom this player has referred */
  referrals: Array<FurPlayer>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayer>;
  /** The player who referred this player, if any */
  referrerId: Maybe<Scalars['String']>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Array<RentalAgreement>;
  /** All sessions for this player */
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  /** [LL] all social profiles associated with this user */
  socialProfiles: Array<SocialProfile>;
  tix: Scalars['Float'];
  tixBalance: Scalars['Float'];
  tixBought: Scalars['Float'];
  tixConsumed: Scalars['Float'];
  tixPurchased: Scalars['Float'];
  tixSpent: Scalars['Float'];
  /** Get the history on a single day from the player */
  today: PlayerHistoryDay;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Array<BlockchainTokenTransaction>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** [LL] all Furballs this player can use (rented plus owned) */
  usableFurballs: Array<Furball>;
  username: Maybe<Scalars['String']>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Scalars['Long'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
};

export type FurScholarCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurScholarHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurScholarPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type FurScholarDiscord = IFurPlayer & {
  __typename?: 'FurScholarDiscord';
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccount>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<Scalars['String']>;
  activeAgreements: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the owner */
  activeAgreementsOwner: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the scholar */
  activeAgreementsPlayer: Array<RentalAgreement>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  /** All battles in which this player has participated */
  battles: Array<GameBattle>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Scalars['Int'];
  /** Boss battles fought by this player */
  bossBattles: Array<GameBattleBoss>;
  communityFlags: Array<CommunityFlag>;
  countBossBattles: Scalars['Long'];
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Array<CraftingAttempt>;
  /** [LL] all crafting slots */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  email: Maybe<Scalars['String']>;
  furBalance: Scalars['Long'];
  furballBalance: Scalars['Long'];
  hasSetUsername: Scalars['Boolean'];
  /** Paginate through the history of the player */
  history: Maybe<HistoryConnection>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  /** [LL] the inventory of this player */
  inventory: ContainerItem;
  /** The (active) listings created by this player */
  itemListings: Array<PlayerItemListing>;
  /** Get the leaderboard ranks for the player */
  leaderboardRanks: Array<LeaderboardRankPlayer>;
  name: Scalars['String'];
  /** The last time this was checked by a BK worker */
  nextCheckAt: Scalars['DateTime'];
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Scalars['Int'];
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Scalars['Int'];
  /** [LL] all onboarding steps */
  onboarding: Array<PlayerOnboardingStep>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  /** [LL] all social profiles associated with this user */
  payoutsPending: Array<PlayerPayout>;
  /** [LL] When a player earns a reward (such as from leaderboards); after claimed, it will move to the player inventory */
  pendingRewards: Array<PlayerPendingReward>;
  /** [LL] those FUR wraps which are in progress */
  pendingWraps: Array<TimekeeperWrapFur>;
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  /** All quests for this player */
  quests: Array<Quest>;
  /** [LL] the realms of games this player has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** The number of players whom this player has referred */
  referralCount: Scalars['UnsignedInt'];
  /** Those players whom this player has referred */
  referrals: Array<FurPlayer>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayer>;
  /** The player who referred this player, if any */
  referrerId: Maybe<Scalars['String']>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Array<RentalAgreement>;
  /** All sessions for this player */
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  /** [LL] all social profiles associated with this user */
  socialProfiles: Array<SocialProfile>;
  tix: Scalars['Float'];
  tixBalance: Scalars['Float'];
  tixBought: Scalars['Float'];
  tixConsumed: Scalars['Float'];
  tixPurchased: Scalars['Float'];
  tixSpent: Scalars['Float'];
  /** Get the history on a single day from the player */
  today: PlayerHistoryDay;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Array<BlockchainTokenTransaction>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** [LL] all Furballs this player can use (rented plus owned) */
  usableFurballs: Array<Furball>;
  username: Maybe<Scalars['String']>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Scalars['Long'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
};

export type FurScholarDiscordCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurScholarDiscordHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurScholarDiscordPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type FurScholarDiscordFilterInput = {
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccountFilterInput>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<StringOperationFilterInput>;
  activeAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionFilterInput>;
  and: Maybe<Array<FurScholarDiscordFilterInput>>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<StringOperationFilterInput>;
  /** All battles in which this player has participated */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** Boss battles fought by this player */
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  communityFlags: Maybe<ListCommunityFlagOperationFilterInput>;
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Maybe<ListFilterInputTypeOfCraftingAttemptFilterInput>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Maybe<ListFilterInputTypeOfCraftSlotFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  email: Maybe<StringOperationFilterInput>;
  furBalance: Maybe<LongOperationFilterInput>;
  furballBalance: Maybe<LongOperationFilterInput>;
  hasSetUsername: Maybe<BooleanOperationFilterInput>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Maybe<ListFilterInputTypeOfPlayerHistoryDayFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** The (active) listings created by this player */
  itemListings: Maybe<ListFilterInputTypeOfPlayerItemListingFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<DateTimeOperationFilterInput>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<IntOperationFilterInput>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<IntOperationFilterInput>;
  /** [DB] The onboarding steps the player has completed */
  onboarding: Maybe<ListFilterInputTypeOfPlayerOnboardingStepFilterInput>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurScholarDiscordFilterInput>>;
  payouts: Maybe<ListFilterInputTypeOfPlayerPayoutFilterInput>;
  /** All quests for this player */
  quests: Maybe<ListFilterInputTypeOfQuestFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  /** Those players whom this player has referred */
  referrals: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<StringOperationFilterInput>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<IntOperationFilterInput>;
  roleFlags: Maybe<ListStringOperationFilterInput>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** All sessions for this player */
  sessions: Maybe<ListFilterInputTypeOfFurSessionFilterInput>;
  socialName: Maybe<StringOperationFilterInput>;
  tix: Maybe<FloatOperationFilterInput>;
  tixBalance: Maybe<FloatOperationFilterInput>;
  tixBought: Maybe<FloatOperationFilterInput>;
  tixConsumed: Maybe<FloatOperationFilterInput>;
  tixPurchased: Maybe<FloatOperationFilterInput>;
  tixSpent: Maybe<FloatOperationFilterInput>;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<LongOperationFilterInput>;
  waitlistFlagList: Maybe<StringOperationFilterInput>;
  waitlistFlags: Maybe<ListWaitlistFlagOperationFilterInput>;
  writtenRoles: Maybe<ListStringOperationFilterInput>;
  writtenRolesList: Maybe<StringOperationFilterInput>;
};

export type FurScholarDiscordSortInput = {
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccountSortInput>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<SortEnumType>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionSortInput>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<SortEnumType>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  discordId: Maybe<SortEnumType>;
  email: Maybe<SortEnumType>;
  furBalance: Maybe<SortEnumType>;
  furballBalance: Maybe<SortEnumType>;
  hasSetUsername: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<SortEnumType>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<SortEnumType>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<SortEnumType>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<SortEnumType>;
  /** The number of players whom this player has referred */
  referralCount: Maybe<SortEnumType>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerSortInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<SortEnumType>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<SortEnumType>;
  socialName: Maybe<SortEnumType>;
  tix: Maybe<SortEnumType>;
  tixBalance: Maybe<SortEnumType>;
  tixBought: Maybe<SortEnumType>;
  tixConsumed: Maybe<SortEnumType>;
  tixPurchased: Maybe<SortEnumType>;
  tixSpent: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<SortEnumType>;
  waitlistFlagList: Maybe<SortEnumType>;
  writtenRolesList: Maybe<SortEnumType>;
};

export type FurScholarEmail = IFurPlayer & {
  __typename?: 'FurScholarEmail';
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccount>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<Scalars['String']>;
  activeAgreements: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the owner */
  activeAgreementsOwner: Array<RentalAgreement>;
  /** [LL] all active rental agreements where the player is the scholar */
  activeAgreementsPlayer: Array<RentalAgreement>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  /** All battles in which this player has participated */
  battles: Array<GameBattle>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Scalars['Int'];
  /** Boss battles fought by this player */
  bossBattles: Array<GameBattleBoss>;
  codeGeneratedAt: Maybe<Scalars['DateTime']>;
  communityFlags: Array<CommunityFlag>;
  countBossBattles: Scalars['Long'];
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Array<CraftingAttempt>;
  /** [LL] all crafting slots */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  furBalance: Scalars['Long'];
  furballBalance: Scalars['Long'];
  hasSetUsername: Scalars['Boolean'];
  /** Paginate through the history of the player */
  history: Maybe<HistoryConnection>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  /** [LL] the inventory of this player */
  inventory: ContainerItem;
  isCodeExpired: Scalars['Boolean'];
  /** The (active) listings created by this player */
  itemListings: Array<PlayerItemListing>;
  /** Get the leaderboard ranks for the player */
  leaderboardRanks: Array<LeaderboardRankPlayer>;
  name: Scalars['String'];
  /** The last time this was checked by a BK worker */
  nextCheckAt: Scalars['DateTime'];
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Scalars['Int'];
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Scalars['Int'];
  /** [LL] all onboarding steps */
  onboarding: Array<PlayerOnboardingStep>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  /** [LL] all social profiles associated with this user */
  payoutsPending: Array<PlayerPayout>;
  /** [LL] When a player earns a reward (such as from leaderboards); after claimed, it will move to the player inventory */
  pendingRewards: Array<PlayerPendingReward>;
  /** [LL] those FUR wraps which are in progress */
  pendingWraps: Array<TimekeeperWrapFur>;
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  /** All quests for this player */
  quests: Array<Quest>;
  /** [LL] the realms of games this player has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this player has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** The number of players whom this player has referred */
  referralCount: Scalars['UnsignedInt'];
  /** Those players whom this player has referred */
  referrals: Array<FurPlayer>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayer>;
  /** The player who referred this player, if any */
  referrerId: Maybe<Scalars['String']>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Array<RentalAgreement>;
  /** All sessions for this player */
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  /** [LL] all social profiles associated with this user */
  socialProfiles: Array<SocialProfile>;
  tix: Scalars['Float'];
  tixBalance: Scalars['Float'];
  tixBought: Scalars['Float'];
  tixConsumed: Scalars['Float'];
  tixPurchased: Scalars['Float'];
  tixSpent: Scalars['Float'];
  /** Get the history on a single day from the player */
  today: PlayerHistoryDay;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Array<BlockchainTokenTransaction>;
  updatedAt: Maybe<Scalars['DateTime']>;
  /** [LL] all Furballs this player can use (rented plus owned) */
  usableFurballs: Array<Furball>;
  username: Maybe<Scalars['String']>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Scalars['Long'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
};

export type FurScholarEmailCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurScholarEmailHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type FurScholarEmailPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type FurScholarEmailFilterInput = {
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccountFilterInput>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<StringOperationFilterInput>;
  activeAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionFilterInput>;
  and: Maybe<Array<FurScholarEmailFilterInput>>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<StringOperationFilterInput>;
  /** All battles in which this player has participated */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** Boss battles fought by this player */
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  codeGeneratedAt: Maybe<DateTimeOperationFilterInput>;
  communityFlags: Maybe<ListCommunityFlagOperationFilterInput>;
  /** Historical attempts at crafting by this player, regardless if done by Furball or player */
  craftingAttempts: Maybe<ListFilterInputTypeOfCraftingAttemptFilterInput>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Maybe<ListFilterInputTypeOfCraftSlotFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  furBalance: Maybe<LongOperationFilterInput>;
  furballBalance: Maybe<LongOperationFilterInput>;
  hasSetUsername: Maybe<BooleanOperationFilterInput>;
  /** The history of this player (not loaded by default; use history() function instead) */
  historyDays: Maybe<ListFilterInputTypeOfPlayerHistoryDayFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isCodeExpired: Maybe<BooleanOperationFilterInput>;
  /** The (active) listings created by this player */
  itemListings: Maybe<ListFilterInputTypeOfPlayerItemListingFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<DateTimeOperationFilterInput>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<IntOperationFilterInput>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<IntOperationFilterInput>;
  /** [DB] The onboarding steps the player has completed */
  onboarding: Maybe<ListFilterInputTypeOfPlayerOnboardingStepFilterInput>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurScholarEmailFilterInput>>;
  payouts: Maybe<ListFilterInputTypeOfPlayerPayoutFilterInput>;
  /** All quests for this player */
  quests: Maybe<ListFilterInputTypeOfQuestFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  /** Those players whom this player has referred */
  referrals: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerFilterInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<StringOperationFilterInput>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<IntOperationFilterInput>;
  roleFlags: Maybe<ListStringOperationFilterInput>;
  /** Boss battles fought by this player */
  scholarshipAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** All sessions for this player */
  sessions: Maybe<ListFilterInputTypeOfFurSessionFilterInput>;
  socialName: Maybe<StringOperationFilterInput>;
  tix: Maybe<FloatOperationFilterInput>;
  tixBalance: Maybe<FloatOperationFilterInput>;
  tixBought: Maybe<FloatOperationFilterInput>;
  tixConsumed: Maybe<FloatOperationFilterInput>;
  tixPurchased: Maybe<FloatOperationFilterInput>;
  tixSpent: Maybe<FloatOperationFilterInput>;
  /** All transactions of all tokens, both wrapped and unwrapped, including ERC-20 and ERC-721 */
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<LongOperationFilterInput>;
  waitlistFlagList: Maybe<StringOperationFilterInput>;
  waitlistFlags: Maybe<ListWaitlistFlagOperationFilterInput>;
  writtenRoles: Maybe<ListStringOperationFilterInput>;
  writtenRolesList: Maybe<StringOperationFilterInput>;
};

export type FurScholarEmailSortInput = {
  /** If present, the scholar has upgraded to a full account, and this scholar profile is no longer valid */
  account: Maybe<FurAccountSortInput>;
  /** The ID of the migrated player, if any */
  accountId: Maybe<SortEnumType>;
  /** Searches through sessions to find any active session (derived) */
  activeSession: Maybe<FurSessionSortInput>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<SortEnumType>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  bossBattleCount: Maybe<SortEnumType>;
  codeGeneratedAt: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  discordId: Maybe<SortEnumType>;
  furBalance: Maybe<SortEnumType>;
  furballBalance: Maybe<SortEnumType>;
  hasSetUsername: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isCodeExpired: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  /** The last time this was checked by a BK worker */
  nextCheckAt: Maybe<SortEnumType>;
  /** As owner of furball, how many contracts canceled? */
  numRentalCancellationsOwner: Maybe<SortEnumType>;
  /** As scholar/player, how many contracts canceled? */
  numRentalCancellationsPlayer: Maybe<SortEnumType>;
  /** [DB] high order check for number of onboarding completed */
  onboardingSequence: Maybe<SortEnumType>;
  /** The number of players whom this player has referred */
  referralCount: Maybe<SortEnumType>;
  /** The player who referred this player, if any */
  referrer: Maybe<FurPlayerSortInput>;
  /** The player who referred this player, if any */
  referrerId: Maybe<SortEnumType>;
  /** Quick access for num rental agreements */
  rentedFurballCount: Maybe<SortEnumType>;
  socialName: Maybe<SortEnumType>;
  tix: Maybe<SortEnumType>;
  tixBalance: Maybe<SortEnumType>;
  tixBought: Maybe<SortEnumType>;
  tixConsumed: Maybe<SortEnumType>;
  tixPurchased: Maybe<SortEnumType>;
  tixSpent: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
  /** WrappedFUR; contains the off-chain balance. */
  wFur: Maybe<SortEnumType>;
  waitlistFlagList: Maybe<SortEnumType>;
  writtenRolesList: Maybe<SortEnumType>;
};

export type FurSession = {
  __typename?: 'FurSession';
  accountId: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  oAuthToken: Maybe<FurOAuthToken>;
  oAuthTokenId: Maybe<Scalars['String']>;
  player: FurPlayer;
  playerId: Scalars['String'];
  playerType: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  username: Maybe<Scalars['String']>;
};

export type FurSessionFilterInput = {
  accountId: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<FurSessionFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isActive: Maybe<BooleanOperationFilterInput>;
  oAuthToken: Maybe<FurOAuthTokenFilterInput>;
  oAuthTokenId: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurSessionFilterInput>>;
  player: Maybe<FurPlayerFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  playerType: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
};

export type FurSessionSortInput = {
  accountId: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isActive: Maybe<SortEnumType>;
  oAuthToken: Maybe<FurOAuthTokenSortInput>;
  oAuthTokenId: Maybe<SortEnumType>;
  player: Maybe<FurPlayerSortInput>;
  playerId: Maybe<SortEnumType>;
  playerType: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
};

export type FurUpdates = {
  __typename?: 'FurUpdates';
  accounts: Maybe<AccountsConnection>;
  players: Array<FurPlayer>;
  rentalAgreements: Maybe<RentalAgreementsConnection>;
  scholarsDiscord: Maybe<ScholarsDiscordConnection>;
  scholarsEmail: Maybe<ScholarsEmailConnection>;
  updatedSince: Scalars['DateTime'];
};

export type FurUpdatesAccountsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<FurAccountSortInput>>;
  where: Maybe<FurAccountFilterInput>;
};

export type FurUpdatesRentalAgreementsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<RentalAgreementSortInput>>;
  where: Maybe<RentalAgreementFilterInput>;
};

export type FurUpdatesScholarsDiscordArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<FurScholarDiscordSortInput>>;
  where: Maybe<FurScholarDiscordFilterInput>;
};

export type FurUpdatesScholarsEmailArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<FurScholarEmailSortInput>>;
  where: Maybe<FurScholarEmailFilterInput>;
};

export type Furball = {
  __typename?: 'Furball';
  /** [LL] the active rental agreement (rented out) */
  activeRentalAgreement: Maybe<RentalAgreement>;
  /** [LL] the current voyage */
  activeVoyage: Maybe<Voyage>;
  /** Derived from Info */
  backgroundColor: Scalars['String'];
  baseModifiersAt: Maybe<Scalars['DateTime']>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  battleCount: Scalars['UnsignedInt'];
  battleStats: StatEntity;
  battleStatsId: Scalars['String'];
  /** All battles in which this Furball has participated */
  battles: Array<GameBattle>;
  /** Derived from Stats.Definition.Birth */
  birth: Scalars['Long'];
  bossBattleCount: Scalars['Int'];
  bossFights: Maybe<BossFightsConnection>;
  /** [LL] the realms for which this Furball has a boss key */
  bossKeys: Array<RealmType>;
  chainMode: Scalars['Int'];
  countBossBattles: Scalars['Long'];
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** Resets at 24:00:00 UTC daily */
  dailyBattleCount: Scalars['Int'];
  dirtyAt: Maybe<Scalars['DateTime']>;
  /** [LL] the currently equipped items */
  equipment: Array<EquipmentItem>;
  expBoost: Scalars['Int'];
  /** Hoisted, derived value */
  expRate: Scalars['Int'];
  furBoost: Scalars['Int'];
  /** Hoisted, derived value */
  furRate: Scalars['Int'];
  gameBattles: Maybe<GameBattlesConnection>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Array<StatEntity>;
  hasDayReset: Scalars['Boolean'];
  haveHeartsReset: Scalars['Boolean'];
  /** Resets at 24:00:00 UTC daily */
  hearts: Scalars['Int'];
  id: Scalars['ID'];
  info: FurballInfo;
  intervals: Scalars['Float'];
  /** [LL] the inventory of this entity */
  inventory: ContainerItem;
  isListedForSale: Scalars['Boolean'];
  /** Earned thru prisonners dilemma; >=0 is an angel */
  karma: Scalars['Int'];
  last: Scalars['Long'];
  lastAt: Maybe<Scalars['DateTime']>;
  /** The time at which the last fight occurred */
  lastBattleAt: Maybe<Scalars['DateTime']>;
  lastBossBattleAt: Maybe<Scalars['DateTime']>;
  /** The time at which the last heart was lost */
  lastHeartLostAt: Maybe<Scalars['DateTime']>;
  lastResolvedAt: Maybe<Scalars['DateTime']>;
  /** The time at which the last trade between players occurred */
  lastTradeAt: Maybe<Scalars['DateTime']>;
  /** [LL] the last voyage */
  lastVoyage: Maybe<Voyage>;
  /** Hoisted, derived value */
  level: Scalars['Int'];
  marketDeListedAt: Scalars['Long'];
  marketListedAt: Scalars['Long'];
  marketListingCount: Scalars['Int'];
  maxDailyBattles: Scalars['Int'];
  /** [C] computed from MaxLevel */
  maxExp: Scalars['Long'];
  maxHearts: Scalars['Int'];
  /** [DB] Furball max level allowed */
  maxLevel: Scalars['Int'];
  name: Scalars['String'];
  numVoyages: Scalars['Int'];
  /** Derived from Stats.Definition.Number */
  number: Scalars['Int'];
  /** [LL] the open rental agreement (available to rent) */
  openRentalAgreement: Maybe<RentalAgreement>;
  /** [LL] the inventory of this furball */
  owner: FurAccount;
  ownerId: Scalars['String'];
  partyId: Maybe<Scalars['String']>;
  /** EXP accrued from an item while it was equipped, to be collected later. */
  pendingExp: Scalars['UnsignedInt'];
  /** FUR accrued from an item while it was equipped, to be collected later. */
  pendingFur: Scalars['UnsignedInt'];
  /** [LL] any pending upgrades */
  pendingUpgrade: Maybe<FurballUpgrade>;
  purchases: Array<ShopPurchase>;
  rarity: Scalars['Int'];
  /** [LL] computed from current battle or voyage */
  realm: RealmType;
  /** [LL] the realms of games this furball has played */
  realmsPlayed: Array<RealmType>;
  /** [LL] the realms of games this furball has played */
  realmsPlayedBoss: Array<RealmType>;
  /** [LL] the realms of games this furball has played */
  realmsPlayedMonsters: Array<RealmType>;
  /** [LL] any active rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  /** The FUR cost of (re)rolling a skill */
  skillRollCost: Scalars['UnsignedInt'];
  /** How many upgrades the player may perform */
  skillUpgradesAvailable: Scalars['UnsignedInt'];
  /** [DB] the furball's specialization state */
  specialization: Specialization;
  /** [LL] computed derived state */
  state: FurballState;
  /** [LL] on-chain definition data */
  stats: FurballStats;
  timekeeperDisabled: Scalars['Boolean'];
  token: FurballToken;
  tokenId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  /** All upgrades (note, only includes one incomplete upgrade by default) */
  upgrades: Array<FurballUpgrade>;
  voyages: Array<Voyage>;
  wExp: Scalars['Int'];
  /** [DB] The Furball's zone */
  zone: Scalars['Int'];
  /** [LL] on-chain zone reward info */
  zoneReward: Maybe<FurballZoneReward>;
};

export type FurballBossFightsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleBossSortInput>>;
  where: Maybe<GameBattleBossFilterInput>;
};

export type FurballCountBossBattlesArgs = {
  since: Scalars['DateTime'];
  until: Maybe<Scalars['DateTime']>;
};

export type FurballGameBattlesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleSortInput>>;
  where: Maybe<GameBattleFilterInput>;
};

export type FurballAttribute = {
  __typename?: 'FurballAttribute';
  displayType: Maybe<Scalars['String']>;
  traitType: Scalars['String'];
  value: Scalars['String'];
};

export type FurballAttributeFilterInput = {
  and: Maybe<Array<FurballAttributeFilterInput>>;
  displayType: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurballAttributeFilterInput>>;
  traitType: Maybe<StringOperationFilterInput>;
  value: Maybe<StringOperationFilterInput>;
};

export type FurballDefinition = {
  __typename?: 'FurballDefinition';
  birth: Scalars['Long'];
  count: Scalars['Short'];
  createdAt: Maybe<Scalars['DateTime']>;
  experience: Scalars['Int'];
  id: Scalars['ID'];
  inventory: Array<InventoryItem>;
  last: Scalars['Long'];
  level: Scalars['Short'];
  moves: Scalars['Int'];
  number: Scalars['Int'];
  rarity: Scalars['Short'];
  stats: FurballStats;
  trade: Scalars['Long'];
  updatedAt: Maybe<Scalars['DateTime']>;
  weight: Scalars['Short'];
  zone: Scalars['Int'];
};

export type FurballDefinitionFilterInput = {
  and: Maybe<Array<FurballDefinitionFilterInput>>;
  birth: Maybe<LongOperationFilterInput>;
  count: Maybe<ShortOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  experience: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  inventory: Maybe<ListFilterInputTypeOfInventoryItemFilterInput>;
  last: Maybe<LongOperationFilterInput>;
  level: Maybe<ShortOperationFilterInput>;
  moves: Maybe<IntOperationFilterInput>;
  number: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurballDefinitionFilterInput>>;
  rarity: Maybe<ShortOperationFilterInput>;
  stats: Maybe<FurballStatsFilterInput>;
  trade: Maybe<LongOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  weight: Maybe<ShortOperationFilterInput>;
  zone: Maybe<IntOperationFilterInput>;
};

export type FurballDefinitionSortInput = {
  birth: Maybe<SortEnumType>;
  count: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  experience: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  last: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  moves: Maybe<SortEnumType>;
  number: Maybe<SortEnumType>;
  rarity: Maybe<SortEnumType>;
  stats: Maybe<FurballStatsSortInput>;
  trade: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  weight: Maybe<SortEnumType>;
  zone: Maybe<SortEnumType>;
};

export type FurballFilterInput = {
  /** The rental agreement which is currently in effect (if any) */
  activeRentalAgreement: Maybe<RentalAgreementFilterInput>;
  activeVoyage: Maybe<VoyageFilterInput>;
  and: Maybe<Array<FurballFilterInput>>;
  /** Derived from Info */
  backgroundColor: Maybe<StringOperationFilterInput>;
  baseModifiersAt: Maybe<DateTimeOperationFilterInput>;
  battleStats: Maybe<StatEntityFilterInput>;
  battleStatsId: Maybe<StringOperationFilterInput>;
  /** All battles in which this Furball has participated */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** Derived from Stats.Definition.Birth */
  birth: Maybe<LongOperationFilterInput>;
  bossBattleCount: Maybe<IntOperationFilterInput>;
  chainMode: Maybe<IntOperationFilterInput>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Maybe<ListFilterInputTypeOfCraftSlotFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** Resets at 24:00:00 UTC daily */
  dailyBattleCount: Maybe<IntOperationFilterInput>;
  dirtyAt: Maybe<DateTimeOperationFilterInput>;
  /** The actual equipped items are stored separate from the inventory */
  equipment: Maybe<ListFilterInputTypeOfEquipmentItemFilterInput>;
  expBoost: Maybe<IntOperationFilterInput>;
  /** Hoisted, derived value */
  expRate: Maybe<IntOperationFilterInput>;
  furBoost: Maybe<IntOperationFilterInput>;
  /** Hoisted, derived value */
  furRate: Maybe<IntOperationFilterInput>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Maybe<ListFilterInputTypeOfStatEntityFilterInput>;
  hasDayReset: Maybe<BooleanOperationFilterInput>;
  haveHeartsReset: Maybe<BooleanOperationFilterInput>;
  /** Resets at 24:00:00 UTC daily */
  hearts: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  info: Maybe<FurballInfoFilterInput>;
  intervals: Maybe<FloatOperationFilterInput>;
  inventory: Maybe<ContainerItemFilterInput>;
  isListedForSale: Maybe<BooleanOperationFilterInput>;
  /** Earned thru prisonners dilemma; >=0 is an angel */
  karma: Maybe<IntOperationFilterInput>;
  last: Maybe<LongOperationFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  /** The time at which the last fight occurred */
  lastBattleAt: Maybe<DateTimeOperationFilterInput>;
  lastBossBattleAt: Maybe<DateTimeOperationFilterInput>;
  /** The time at which the last heart was lost */
  lastHeartLostAt: Maybe<DateTimeOperationFilterInput>;
  lastResolvedAt: Maybe<DateTimeOperationFilterInput>;
  /** The time at which the last trade between players occurred */
  lastTradeAt: Maybe<DateTimeOperationFilterInput>;
  lastVoyage: Maybe<VoyageFilterInput>;
  /** Hoisted, derived value */
  level: Maybe<IntOperationFilterInput>;
  marketDeListedAt: Maybe<LongOperationFilterInput>;
  marketListedAt: Maybe<LongOperationFilterInput>;
  marketListingCount: Maybe<IntOperationFilterInput>;
  maxDailyBattles: Maybe<IntOperationFilterInput>;
  /** [C] computed from MaxLevel */
  maxExp: Maybe<LongOperationFilterInput>;
  maxHearts: Maybe<IntOperationFilterInput>;
  /** [DB] Furball max level allowed */
  maxLevel: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  numVoyages: Maybe<IntOperationFilterInput>;
  /** Derived from Stats.Definition.Number */
  number: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurballFilterInput>>;
  owner: Maybe<FurAccountFilterInput>;
  ownerId: Maybe<StringOperationFilterInput>;
  partyId: Maybe<StringOperationFilterInput>;
  purchases: Maybe<ListFilterInputTypeOfShopPurchaseFilterInput>;
  rarity: Maybe<IntOperationFilterInput>;
  /** [DB] The current realm (last fight took place in...) */
  realm: Maybe<RealmTypeOperationFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  /** All rental agreements for this furball except those which have ended already */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** [DB] the furball's specialization state */
  specialization: Maybe<SpecializationOperationFilterInput>;
  stats: Maybe<FurballStatsFilterInput>;
  timekeeperDisabled: Maybe<BooleanOperationFilterInput>;
  token: Maybe<FurballTokenFilterInput>;
  tokenId: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  /** All upgrades (note, only includes one incomplete upgrade by default) */
  upgrades: Maybe<ListFilterInputTypeOfFurballUpgradeFilterInput>;
  voyages: Maybe<ListFilterInputTypeOfVoyageFilterInput>;
  wExp: Maybe<IntOperationFilterInput>;
  /** [DB] The Furball's zone */
  zone: Maybe<IntOperationFilterInput>;
  zoneReward: Maybe<FurballZoneRewardFilterInput>;
};

export type FurballFiltersInput = {
  level: Maybe<FurballNumberFilterInput>;
  number: Maybe<Scalars['Int']>;
  rentable: Maybe<Scalars['Boolean']>;
  totalRarity: Maybe<FurballNumberFilterInput>;
  traits: Maybe<Array<FurballTraitsFilterInput>>;
};

export type FurballInfo = {
  __typename?: 'FurballInfo';
  attributeData: Scalars['String'];
  attributes: Array<FurballAttribute>;
  backgroundColor: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  furball: Furball;
  id: Scalars['ID'];
  image: Scalars['String'];
  name: Scalars['String'];
  rareGenesCount: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FurballInfoFilterInput = {
  and: Maybe<Array<FurballInfoFilterInput>>;
  attributeData: Maybe<StringOperationFilterInput>;
  attributes: Maybe<ListFilterInputTypeOfFurballAttributeFilterInput>;
  backgroundColor: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  description: Maybe<StringOperationFilterInput>;
  furball: Maybe<FurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  image: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurballInfoFilterInput>>;
  rareGenesCount: Maybe<IntOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type FurballInfoSortInput = {
  attributeData: Maybe<SortEnumType>;
  backgroundColor: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  description: Maybe<SortEnumType>;
  furball: Maybe<FurballSortInput>;
  id: Maybe<SortEnumType>;
  image: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  rareGenesCount: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export type FurballLootUpgradeResponse = {
  __typename?: 'FurballLootUpgradeResponse';
  furball: Maybe<Furball>;
  upgradedItem: Maybe<GameItem>;
};

export type FurballModification = {
  __typename?: 'FurballModification';
  addEncounterType: Maybe<VoyageEncounterType>;
  createdAt: Maybe<Scalars['DateTime']>;
  drops: Array<Scalars['Long']>;
  furball: Furball;
  hash: Scalars['Int'];
  id: Scalars['String'];
  pickups: Array<Scalars['Long']>;
  sender: Scalars['String'];
  setActiveVoyageDays: Maybe<Scalars['Int']>;
  setBondingDays: Maybe<Scalars['Int']>;
  setMarketListingCount: Maybe<Scalars['Int']>;
  signature: Maybe<Scalars['String']>;
  tokenId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FurballModificationArgsInput = {
  addEncounterType: Maybe<VoyageEncounterType>;
  drops: Array<Scalars['Long']>;
  pickups: Array<Scalars['Long']>;
  setActiveVoyageDays: Maybe<Scalars['Int']>;
  setBondingDays: Maybe<Scalars['Int']>;
  setMarketListingCount: Maybe<Scalars['Int']>;
  tokenId: Scalars['String'];
};

export type FurballNameChange = {
  __typename?: 'FurballNameChange';
  /** [DB] has this name been approved by a moderator? */
  approved: Maybe<Scalars['Boolean']>;
  /** [DB] the Player who approved the change */
  approvedBy: Maybe<FurPlayer>;
  /** [DB] the Player who approved the change */
  approvedById: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] if the Request is complete, when did it complete? */
  executedAt: Maybe<Scalars['DateTime']>;
  /** [DB] the Furball whose name is changed */
  furball: Furball;
  /** [DB] the Furball whose name is changed */
  furballId: Scalars['String'];
  id: Scalars['String'];
  /** [DB] the new Furball name */
  name: Scalars['String'];
  /** [DB] the Player who requested the change */
  player: FurPlayer;
  /** [DB] the Blockchain transaction to change the name */
  request: TimekeeperRenameFurball;
  /** [DB] the Blockchain transaction to change the name */
  requestId: Scalars['String'];
};

export type FurballNameChangeFilterInput = {
  and: Maybe<Array<FurballNameChangeFilterInput>>;
  /** [DB] has this name been approved by a moderator? */
  approved: Maybe<BooleanOperationFilterInput>;
  /** [DB] the Player who approved the change */
  approvedBy: Maybe<FurPlayerFilterInput>;
  /** [DB] the Player who approved the change */
  approvedById: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] if the Request is complete, when did it complete? */
  executedAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] the Furball whose name is changed */
  furball: Maybe<FurballFilterInput>;
  /** [DB] the Furball whose name is changed */
  furballId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** [DB] the new Furball name */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurballNameChangeFilterInput>>;
  /** [DB] the Player who requested the change */
  player: Maybe<FurPlayerFilterInput>;
  /** [DB] the Blockchain transaction to change the name */
  request: Maybe<TimekeeperRenameFurballFilterInput>;
  /** [DB] the Blockchain transaction to change the name */
  requestId: Maybe<StringOperationFilterInput>;
};

export type FurballNameChangeSortInput = {
  /** [DB] has this name been approved by a moderator? */
  approved: Maybe<SortEnumType>;
  /** [DB] the Player who approved the change */
  approvedBy: Maybe<FurPlayerSortInput>;
  /** [DB] the Player who approved the change */
  approvedById: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** [DB] if the Request is complete, when did it complete? */
  executedAt: Maybe<SortEnumType>;
  /** [DB] the Furball whose name is changed */
  furball: Maybe<FurballSortInput>;
  /** [DB] the Furball whose name is changed */
  furballId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** [DB] the new Furball name */
  name: Maybe<SortEnumType>;
  /** [DB] the Player who requested the change */
  player: Maybe<FurPlayerSortInput>;
  /** [DB] the Blockchain transaction to change the name */
  request: Maybe<TimekeeperRenameFurballSortInput>;
  /** [DB] the Blockchain transaction to change the name */
  requestId: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type FurballNameChangesConnection = {
  __typename?: 'FurballNameChangesConnection';
  /** A list of edges. */
  edges: Maybe<Array<FurballNameChangesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<FurballNameChange>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type FurballNameChangesEdge = {
  __typename?: 'FurballNameChangesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FurballNameChange;
};

export type FurballNumberFilterInput = {
  gt: Maybe<Scalars['Int']>;
  lt: Maybe<Scalars['Int']>;
};

export type FurballRank = {
  __typename?: 'FurballRank';
  expRate: Maybe<LeaderboardSlot>;
  furRate: Maybe<LeaderboardSlot>;
  id: Scalars['ID'];
  level: Maybe<LeaderboardSlot>;
};

export type FurballRewardModifiers = {
  __typename?: 'FurballRewardModifiers';
  createdAt: Maybe<Scalars['DateTime']>;
  energyPoints: Scalars['Int'];
  expPercent: Scalars['Int'];
  furPercent: Scalars['Int'];
  happinessPoints: Scalars['Int'];
  id: Scalars['ID'];
  luckPercent: Scalars['Int'];
  stats: FurballStats;
  updatedAt: Maybe<Scalars['DateTime']>;
  zone: Scalars['Int'];
};

export type FurballRewardModifiersFilterInput = {
  and: Maybe<Array<FurballRewardModifiersFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  energyPoints: Maybe<IntOperationFilterInput>;
  expPercent: Maybe<IntOperationFilterInput>;
  furPercent: Maybe<IntOperationFilterInput>;
  happinessPoints: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  luckPercent: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurballRewardModifiersFilterInput>>;
  stats: Maybe<FurballStatsFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  zone: Maybe<IntOperationFilterInput>;
};

export type FurballRewardModifiersSortInput = {
  createdAt: Maybe<SortEnumType>;
  energyPoints: Maybe<SortEnumType>;
  expPercent: Maybe<SortEnumType>;
  furPercent: Maybe<SortEnumType>;
  happinessPoints: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  luckPercent: Maybe<SortEnumType>;
  stats: Maybe<FurballStatsSortInput>;
  updatedAt: Maybe<SortEnumType>;
  zone: Maybe<SortEnumType>;
};

export enum FurballSlot {
  Body = 'BODY',
  Ears = 'EARS',
  Eyes = 'EYES',
  Hat = 'HAT',
  Mouth = 'MOUTH',
  NumSlots = 'NUM_SLOTS',
  OffHand = 'OFF_HAND',
  Outfit = 'OUTFIT',
  Tail = 'TAIL',
  Tattoo = 'TATTOO',
  Weapon = 'WEAPON',
  Wings = 'WINGS',
}

export type FurballSlotOperationFilterInput = {
  eq: Maybe<FurballSlot>;
  in: Maybe<Array<FurballSlot>>;
  neq: Maybe<FurballSlot>;
  nin: Maybe<Array<FurballSlot>>;
};

export type FurballSnack = {
  __typename?: 'FurballSnack';
  count: Scalars['Int'];
  createdAt: Maybe<Scalars['DateTime']>;
  duration: Scalars['Int'];
  energy: Scalars['Int'];
  fed: Scalars['Int'];
  furCost: Scalars['Int'];
  happiness: Scalars['Int'];
  id: Scalars['ID'];
  snackId: Scalars['Int'];
  stats: FurballStats;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FurballSnackFilterInput = {
  and: Maybe<Array<FurballSnackFilterInput>>;
  count: Maybe<IntOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  duration: Maybe<IntOperationFilterInput>;
  energy: Maybe<IntOperationFilterInput>;
  fed: Maybe<IntOperationFilterInput>;
  furCost: Maybe<IntOperationFilterInput>;
  happiness: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<FurballSnackFilterInput>>;
  snackId: Maybe<IntOperationFilterInput>;
  stats: Maybe<FurballStatsFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type FurballSortInput = {
  /** The rental agreement which is currently in effect (if any) */
  activeRentalAgreement: Maybe<RentalAgreementSortInput>;
  activeVoyage: Maybe<VoyageSortInput>;
  /** Derived from Info */
  backgroundColor: Maybe<SortEnumType>;
  baseModifiersAt: Maybe<SortEnumType>;
  /** A count of the number of completed, non-forfeit, non-trial boss battles (which does not require loading BossBattles nested objects) */
  battleCount: Maybe<SortEnumType>;
  battleStats: Maybe<StatEntitySortInput>;
  battleStatsId: Maybe<SortEnumType>;
  /** Derived from Stats.Definition.Birth */
  birth: Maybe<SortEnumType>;
  bossBattleCount: Maybe<SortEnumType>;
  chainMode: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** Resets at 24:00:00 UTC daily */
  dailyBattleCount: Maybe<SortEnumType>;
  dirtyAt: Maybe<SortEnumType>;
  expBoost: Maybe<SortEnumType>;
  /** Hoisted, derived value */
  expRate: Maybe<SortEnumType>;
  furBoost: Maybe<SortEnumType>;
  /** Hoisted, derived value */
  furRate: Maybe<SortEnumType>;
  hasDayReset: Maybe<SortEnumType>;
  haveHeartsReset: Maybe<SortEnumType>;
  /** Resets at 24:00:00 UTC daily */
  hearts: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  info: Maybe<FurballInfoSortInput>;
  intervals: Maybe<SortEnumType>;
  inventory: Maybe<ContainerItemSortInput>;
  isListedForSale: Maybe<SortEnumType>;
  /** Earned thru prisonners dilemma; >=0 is an angel */
  karma: Maybe<SortEnumType>;
  last: Maybe<SortEnumType>;
  lastAt: Maybe<SortEnumType>;
  /** The time at which the last fight occurred */
  lastBattleAt: Maybe<SortEnumType>;
  lastBossBattleAt: Maybe<SortEnumType>;
  /** The time at which the last heart was lost */
  lastHeartLostAt: Maybe<SortEnumType>;
  lastResolvedAt: Maybe<SortEnumType>;
  /** The time at which the last trade between players occurred */
  lastTradeAt: Maybe<SortEnumType>;
  lastVoyage: Maybe<VoyageSortInput>;
  /** Hoisted, derived value */
  level: Maybe<SortEnumType>;
  marketDeListedAt: Maybe<SortEnumType>;
  marketListedAt: Maybe<SortEnumType>;
  marketListingCount: Maybe<SortEnumType>;
  maxDailyBattles: Maybe<SortEnumType>;
  /** [C] computed from MaxLevel */
  maxExp: Maybe<SortEnumType>;
  maxHearts: Maybe<SortEnumType>;
  /** [DB] Furball max level allowed */
  maxLevel: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  numVoyages: Maybe<SortEnumType>;
  /** Derived from Stats.Definition.Number */
  number: Maybe<SortEnumType>;
  owner: Maybe<FurAccountSortInput>;
  ownerId: Maybe<SortEnumType>;
  partyId: Maybe<SortEnumType>;
  /** EXP accrued from an item while it was equipped, to be collected later. */
  pendingExp: Maybe<SortEnumType>;
  /** FUR accrued from an item while it was equipped, to be collected later. */
  pendingFur: Maybe<SortEnumType>;
  rarity: Maybe<SortEnumType>;
  /** [DB] The current realm (last fight took place in...) */
  realm: Maybe<SortEnumType>;
  /** The FUR cost of (re)rolling a skill */
  skillRollCost: Maybe<SortEnumType>;
  /** How many upgrades the player may perform */
  skillUpgradesAvailable: Maybe<SortEnumType>;
  /** [DB] the furball's specialization state */
  specialization: Maybe<SortEnumType>;
  stats: Maybe<FurballStatsSortInput>;
  timekeeperDisabled: Maybe<SortEnumType>;
  token: Maybe<FurballTokenSortInput>;
  tokenId: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  wExp: Maybe<SortEnumType>;
  /** [DB] The Furball's zone */
  zone: Maybe<SortEnumType>;
  zoneReward: Maybe<FurballZoneRewardSortInput>;
};

export type FurballState = {
  __typename?: 'FurballState';
  bonded: Scalars['Boolean'];
  bondingDays: Scalars['Float'];
  daysSinceTrade: Scalars['Float'];
  estimatedExperience: Scalars['Int'];
  estimatedFur: Scalars['Int'];
  estimatedLevel: Scalars['Int'];
  /** Computed from on-chain EXP plus current loot */
  expPercent: Scalars['Int'];
  expPercentBase: Scalars['Int'];
  expRate: Scalars['Int'];
  /** Computed from on-chain rate plus current loot */
  furPercent: Scalars['Int'];
  furPercentBase: Scalars['Int'];
  furRate: Scalars['Int'];
  furRateBase: Scalars['Int'];
  happiness: Scalars['Int'];
  id: Scalars['ID'];
  lastTradeAt: Scalars['Long'];
  level: Scalars['Int'];
  /** Computed from on-chain rate plus current loot */
  luckPercent: Scalars['Int'];
  luckPercentBase: Scalars['Int'];
  ranks: FurballRank;
  realm: RealmType;
  teamSize: Scalars['Int'];
  totalExp: Scalars['Int'];
  totalLuck: Scalars['Int'];
  totalRarity: Scalars['Int'];
  zone: Scalars['Int'];
};

export type FurballStats = {
  __typename?: 'FurballStats';
  createdAt: Maybe<Scalars['DateTime']>;
  definition: FurballDefinition;
  expRate: Scalars['Int'];
  furRate: Scalars['Int'];
  furball: Furball;
  id: Scalars['ID'];
  modifiers: FurballRewardModifiers;
  snacks: Array<FurballSnack>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type FurballStatsFilterInput = {
  and: Maybe<Array<FurballStatsFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<FurballDefinitionFilterInput>;
  expRate: Maybe<IntOperationFilterInput>;
  furRate: Maybe<IntOperationFilterInput>;
  furball: Maybe<FurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  modifiers: Maybe<FurballRewardModifiersFilterInput>;
  or: Maybe<Array<FurballStatsFilterInput>>;
  snacks: Maybe<ListFilterInputTypeOfFurballSnackFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type FurballStatsSortInput = {
  createdAt: Maybe<SortEnumType>;
  definition: Maybe<FurballDefinitionSortInput>;
  expRate: Maybe<SortEnumType>;
  furRate: Maybe<SortEnumType>;
  furball: Maybe<FurballSortInput>;
  id: Maybe<SortEnumType>;
  modifiers: Maybe<FurballRewardModifiersSortInput>;
  updatedAt: Maybe<SortEnumType>;
};

export type FurballToken = {
  __typename?: 'FurballToken';
  assets: Array<EditionAsset>;
  backgroundColor: Scalars['String'];
  backgroundIdx: Scalars['Int'];
  body: Maybe<EditionAsset>;
  bodyIdx: Scalars['Int'];
  bytes: Array<Scalars['Byte']>;
  ears: Maybe<EditionAsset>;
  earsIdx: Scalars['Int'];
  edition: EditionBase;
  editionIdx: Scalars['Int'];
  eyes: Maybe<EditionAsset>;
  eyesIdx: Scalars['Int'];
  hat: Maybe<EditionAsset>;
  hatIdx: Scalars['Int'];
  mouth: Maybe<EditionAsset>;
  mouthIdx: Scalars['Int'];
  offHand: Maybe<EditionAsset>;
  offHandIdx: Scalars['Int'];
  outfit: Maybe<EditionAsset>;
  outfitIdx: Scalars['Int'];
  paletteColorPrimary: Scalars['String'];
  paletteColorSecondary: Scalars['String'];
  paletteIdx: Scalars['Int'];
  replaceColors: Scalars['String'];
  slotAsset: Maybe<EditionAsset>;
  slots: Array<Maybe<EditionAsset>>;
  slotsWithRealmAffiliation: Array<EditionAsset>;
  tail: Maybe<EditionAsset>;
  tailIdx: Scalars['Int'];
  tattoo: Maybe<EditionAsset>;
  tattooIdx: Scalars['Int'];
  tokenId: Scalars['String'];
  totalRarityBoost: Scalars['Int'];
  weapon: Maybe<EditionAsset>;
  weaponIdx: Scalars['Int'];
  wings: Maybe<EditionAsset>;
  wingsIndex: Scalars['Int'];
};

export type FurballTokenReplaceColorsArgs = {
  svgStr: Scalars['String'];
};

export type FurballTokenSlotAssetArgs = {
  slot: FurballSlot;
};

export type FurballTokenSlotsWithRealmAffiliationArgs = {
  realmType: RealmType;
};

export type FurballTokenFilterInput = {
  and: Maybe<Array<FurballTokenFilterInput>>;
  assets: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  backgroundColor: Maybe<StringOperationFilterInput>;
  backgroundIdx: Maybe<IntOperationFilterInput>;
  body: Maybe<EditionAssetFilterInput>;
  bodyIdx: Maybe<IntOperationFilterInput>;
  bytes: Maybe<ListByteOperationFilterInput>;
  ears: Maybe<EditionAssetFilterInput>;
  earsIdx: Maybe<IntOperationFilterInput>;
  edition: Maybe<EditionBaseFilterInput>;
  editionIdx: Maybe<IntOperationFilterInput>;
  eyes: Maybe<EditionAssetFilterInput>;
  eyesIdx: Maybe<IntOperationFilterInput>;
  hat: Maybe<EditionAssetFilterInput>;
  hatIdx: Maybe<IntOperationFilterInput>;
  mouth: Maybe<EditionAssetFilterInput>;
  mouthIdx: Maybe<IntOperationFilterInput>;
  offHand: Maybe<EditionAssetFilterInput>;
  offHandIdx: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurballTokenFilterInput>>;
  outfit: Maybe<EditionAssetFilterInput>;
  outfitIdx: Maybe<IntOperationFilterInput>;
  paletteColorPrimary: Maybe<StringOperationFilterInput>;
  paletteColorSecondary: Maybe<StringOperationFilterInput>;
  paletteIdx: Maybe<IntOperationFilterInput>;
  slots: Maybe<ListFilterInputTypeOfEditionAssetFilterInput>;
  tail: Maybe<EditionAssetFilterInput>;
  tailIdx: Maybe<IntOperationFilterInput>;
  tattoo: Maybe<EditionAssetFilterInput>;
  tattooIdx: Maybe<IntOperationFilterInput>;
  tokenId: Maybe<StringOperationFilterInput>;
  totalRarityBoost: Maybe<IntOperationFilterInput>;
  weapon: Maybe<EditionAssetFilterInput>;
  weaponIdx: Maybe<IntOperationFilterInput>;
  wings: Maybe<EditionAssetFilterInput>;
  wingsIndex: Maybe<IntOperationFilterInput>;
};

export type FurballTokenSortInput = {
  backgroundColor: Maybe<SortEnumType>;
  backgroundIdx: Maybe<SortEnumType>;
  body: Maybe<EditionAssetSortInput>;
  bodyIdx: Maybe<SortEnumType>;
  ears: Maybe<EditionAssetSortInput>;
  earsIdx: Maybe<SortEnumType>;
  edition: Maybe<EditionBaseSortInput>;
  editionIdx: Maybe<SortEnumType>;
  eyes: Maybe<EditionAssetSortInput>;
  eyesIdx: Maybe<SortEnumType>;
  hat: Maybe<EditionAssetSortInput>;
  hatIdx: Maybe<SortEnumType>;
  mouth: Maybe<EditionAssetSortInput>;
  mouthIdx: Maybe<SortEnumType>;
  offHand: Maybe<EditionAssetSortInput>;
  offHandIdx: Maybe<SortEnumType>;
  outfit: Maybe<EditionAssetSortInput>;
  outfitIdx: Maybe<SortEnumType>;
  paletteColorPrimary: Maybe<SortEnumType>;
  paletteColorSecondary: Maybe<SortEnumType>;
  paletteIdx: Maybe<SortEnumType>;
  tail: Maybe<EditionAssetSortInput>;
  tailIdx: Maybe<SortEnumType>;
  tattoo: Maybe<EditionAssetSortInput>;
  tattooIdx: Maybe<SortEnumType>;
  tokenId: Maybe<SortEnumType>;
  totalRarityBoost: Maybe<SortEnumType>;
  weapon: Maybe<EditionAssetSortInput>;
  weaponIdx: Maybe<SortEnumType>;
  wings: Maybe<EditionAssetSortInput>;
  wingsIndex: Maybe<SortEnumType>;
};

export type FurballTraitsFilterInput = {
  options: Array<Scalars['Short']>;
  slot: FurballSlot;
};

/** Represents a (potential) change made to the on-chain data of a Furball */
export type FurballUpgrade = {
  __typename?: 'FurballUpgrade';
  canUnSchedule: Scalars['Boolean'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** The Timekeeper round date */
  date: Scalars['Int'];
  /** The client-provided deadline for the signature, if any */
  deadline: Scalars['UnsignedLong'];
  /** The earliest time at which the Furball may leave the zone (only applicable once signed) */
  endsAt: Maybe<Scalars['DateTime']>;
  /** All equipment to modify */
  equipment: Array<FurballUpgradeEquippedItem>;
  /** All equipment stats to modify */
  equipmentStats: Array<FurballUpgradeBattleStats>;
  /** The fuel cost of writing back */
  fuel: Scalars['UnsignedInt'];
  furball: Furball;
  furballId: Scalars['String'];
  id: Scalars['ID'];
  /** True if there is nothing to write back to the chain */
  isEmpty: Scalars['Boolean'];
  isPending: Scalars['Boolean'];
  /** The core progress data to set */
  progress: Maybe<FurballUpgradeProgress>;
  /** The player account which made the change (owner at the time) */
  sender: FurAccount;
  senderId: Scalars['String'];
  /** The client's signature, if accepted for write-back */
  signature: Maybe<Scalars['String']>;
  /** The time at which it was signed */
  signedAt: Maybe<Scalars['DateTime']>;
  /** All skills to modify */
  skills: Array<FurballUpgradeSkill>;
  /** If the write-back is complete, what was the transaction? */
  transaction: Maybe<BlockchainTransaction>;
  /** If the write-back is complete, the transaction hash / ID */
  transactionId: Maybe<Scalars['String']>;
  /** True if there is something that needs to be written back */
  writeBackRequired: Scalars['Boolean'];
};

export type FurballUpgradeBattleStats = {
  __typename?: 'FurballUpgradeBattleStats';
  attackPower: Scalars['UnsignedShort'];
  buff: Scalars['UnsignedShort'];
  critAttackPower: Scalars['UnsignedShort'];
  critRate: Scalars['Byte'];
  deBuff: Scalars['UnsignedShort'];
  defencePower: Scalars['UnsignedShort'];
  heal: Scalars['UnsignedShort'];
  maxHealth: Scalars['UnsignedShort'];
  speed: Scalars['Byte'];
};

export type FurballUpgradeEquippedItem = {
  __typename?: 'FurballUpgradeEquippedItem';
  durabilityTime: Scalars['UnsignedInt'];
  equippedAt: Scalars['UnsignedLong'];
  itemDefinitionId: Scalars['UnsignedLong'];
  slotIndex: Scalars['Byte'];
};

/** Represents a (potential) change made to the on-chain data of a Furball */
export type FurballUpgradeFilterInput = {
  and: Maybe<Array<FurballUpgradeFilterInput>>;
  canUnSchedule: Maybe<BooleanOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** The Timekeeper round date */
  date: Maybe<IntOperationFilterInput>;
  /** The earliest time at which the Furball may leave the zone (only applicable once signed) */
  endsAt: Maybe<DateTimeOperationFilterInput>;
  furball: Maybe<FurballFilterInput>;
  furballId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** True if there is nothing to write back to the chain */
  isEmpty: Maybe<BooleanOperationFilterInput>;
  isPending: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<FurballUpgradeFilterInput>>;
  /** The player account which made the change (owner at the time) */
  sender: Maybe<FurAccountFilterInput>;
  senderId: Maybe<StringOperationFilterInput>;
  /** The client's signature, if accepted for write-back */
  signature: Maybe<StringOperationFilterInput>;
  /** The time at which it was signed */
  signedAt: Maybe<DateTimeOperationFilterInput>;
  /** If the write-back is complete, what was the transaction? */
  transaction: Maybe<BlockchainTransactionFilterInput>;
  /** If the write-back is complete, the transaction hash / ID */
  transactionId: Maybe<StringOperationFilterInput>;
  /** True if there is something that needs to be written back */
  writeBackRequired: Maybe<BooleanOperationFilterInput>;
};

export type FurballUpgradeProgress = {
  __typename?: 'FurballUpgradeProgress';
  battleStats: FurballUpgradeBattleStats;
  expRate: Scalars['UnsignedShort'];
  furRate: Scalars['UnsignedShort'];
  level: Scalars['UnsignedShort'];
};

export type FurballUpgradeSkill = {
  __typename?: 'FurballUpgradeSkill';
  level: Scalars['Byte'];
  maxUses: Scalars['Byte'];
  skillId: Scalars['UnsignedShort'];
  slotIndex: Scalars['Byte'];
};

export type FurballZoneReward = {
  __typename?: 'FurballZoneReward';
  createdAt: Maybe<Scalars['DateTime']>;
  experienceGained: Scalars['Int'];
  furEarned: Scalars['Int'];
  furball: Furball;
  id: Scalars['ID'];
  mode: Scalars['Int'];
  rarity: Scalars['Int'];
  timestamp: Scalars['Long'];
  updatedAt: Maybe<Scalars['DateTime']>;
  zoneOffset: Scalars['Int'];
};

export type FurballZoneRewardFilterInput = {
  and: Maybe<Array<FurballZoneRewardFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  experienceGained: Maybe<IntOperationFilterInput>;
  furEarned: Maybe<IntOperationFilterInput>;
  furball: Maybe<FurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  mode: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<FurballZoneRewardFilterInput>>;
  rarity: Maybe<IntOperationFilterInput>;
  timestamp: Maybe<LongOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  zoneOffset: Maybe<IntOperationFilterInput>;
};

export type FurballZoneRewardSortInput = {
  createdAt: Maybe<SortEnumType>;
  experienceGained: Maybe<SortEnumType>;
  furEarned: Maybe<SortEnumType>;
  furball: Maybe<FurballSortInput>;
  id: Maybe<SortEnumType>;
  mode: Maybe<SortEnumType>;
  rarity: Maybe<SortEnumType>;
  timestamp: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  zoneOffset: Maybe<SortEnumType>;
};

export type FurballsMeta = {
  __typename?: 'FurballsMeta';
  activeTournaments: Array<Tournament>;
  /** The CI-generated build number */
  buildNumber: Scalars['UnsignedLong'];
  /** System generated info on how to handle the client */
  clientSpec: ClientSpecification;
  /** Production, Staging, or Development (from ASP.NET) */
  environment: Scalars['String'];
  ethPrice: Scalars['Float'];
  ethPriceUsd: Scalars['Float'];
  /** The current version of the game, as fed into the server build */
  gameBuild: GameBuild;
  /** Just the top level domain name */
  hostname: Scalars['String'];
  lootBox: LootBox;
  mintAuction: Maybe<DutchAuction>;
  mintAuctionPurchaseCount: Scalars['Long'];
  /** The subdomain, like www or goerli */
  subdomain: Maybe<Scalars['String']>;
  timekeeperUserState: Maybe<TimekeeperUserState>;
};

export type FurballsMetaActiveTournamentsArgs = {
  at: Maybe<Scalars['DateTime']>;
  includeFuture?: Scalars['Boolean'];
};

export type GameBattle = {
  __typename?: 'GameBattle';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  id: Scalars['ID'];
  isComplete: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
};

export type GameBattleCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type GameBattleMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GameBattleBoss = {
  __typename?: 'GameBattleBoss';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  aiDamageTotal: Scalars['Long'];
  /** [LL] the party for the AI */
  aiParty: GameParty;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  boss: StatEntity;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  bossDamageTotal: Scalars['Long'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  fur: Scalars['Long'];
  furEarned: Scalars['Long'];
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  gameBoss: GameBoss;
  gamePlayerId: Scalars['String'];
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  isTrialGame: Scalars['Boolean'];
  /** [LL] items earned during the game */
  itemQuantitiesEarned: Array<ItemQuantity>;
  itemsEarned: Array<GameItem>;
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  /** Group for this game */
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  percentileRank: Scalars['Float'];
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  player: FurPlayer;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  playerDamageTotal: Scalars['Long'];
  playerId: Scalars['String'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Scalars['Int'];
  /** [LL] the party for the player */
  playerParty: GameParty;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  reward: Maybe<ItemReward>;
  rewardId: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
  /** Based on how long we want games to last, what percentage are we at? */
  targetPercentComplete: Scalars['Float'];
  /** [LL] the boss */
  worldBoss: GameBoss;
  worldBossId: Scalars['String'];
};

export type GameBattleBossCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type GameBattleBossMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GameBattleBossFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  aiParty: Maybe<GamePartyFilterInput>;
  and: Maybe<Array<GameBattleBossFilterInput>>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  boss: Maybe<StatEntityFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  fur: Maybe<LongOperationFilterInput>;
  furEarned: Maybe<LongOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  gameBoss: Maybe<GameBossFilterInput>;
  gamePlayerId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  isTrialGame: Maybe<BooleanOperationFilterInput>;
  itemQuantitiesEarned: Maybe<ListFilterInputTypeOfItemQuantityFilterInput>;
  itemsEarned: Maybe<ListFilterInputTypeOfGameItemFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  /** Group for this game */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattleBossFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  percentileRank: Maybe<FloatOperationFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Maybe<IntOperationFilterInput>;
  playerParty: Maybe<GamePartyFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  reward: Maybe<ItemRewardFilterInput>;
  rewardId: Maybe<StringOperationFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
  /** Based on how long we want games to last, what percentage are we at? */
  targetPercentComplete: Maybe<FloatOperationFilterInput>;
  worldBoss: Maybe<GameBossFilterInput>;
  worldBossId: Maybe<StringOperationFilterInput>;
};

export type GameBattleBossSortInput = {
  activateAt: Maybe<SortEnumType>;
  activeHost: Maybe<HostNodeSortInput>;
  activeHostName: Maybe<SortEnumType>;
  aiParty: Maybe<GamePartySortInput>;
  battleState: Maybe<SortEnumType>;
  battleType: Maybe<SortEnumType>;
  boss: Maybe<StatEntitySortInput>;
  combo: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerSortInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<SortEnumType>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<SortEnumType>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<SortEnumType>;
  fur: Maybe<SortEnumType>;
  furEarned: Maybe<SortEnumType>;
  furballIdsList: Maybe<SortEnumType>;
  gameBoss: Maybe<GameBossSortInput>;
  gamePlayerId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isComplete: Maybe<SortEnumType>;
  isPracticeGame: Maybe<SortEnumType>;
  isTrialGame: Maybe<SortEnumType>;
  lastAt: Maybe<SortEnumType>;
  lastEndsAt: Maybe<SortEnumType>;
  lastMoveEndsAt: Maybe<SortEnumType>;
  /** Group for this game */
  leaderboardGroup: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  loadedAt: Maybe<SortEnumType>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<SortEnumType>;
  /** Total number of moves made */
  moveCount: Maybe<SortEnumType>;
  nextAt: Maybe<SortEnumType>;
  ntp: Maybe<SortEnumType>;
  pausedAt: Maybe<SortEnumType>;
  percentRank: Maybe<SortEnumType>;
  percentileRank: Maybe<SortEnumType>;
  playerId: Maybe<SortEnumType>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Maybe<SortEnumType>;
  playerParty: Maybe<GamePartySortInput>;
  realm: Maybe<SortEnumType>;
  reward: Maybe<ItemRewardSortInput>;
  rewardId: Maybe<SortEnumType>;
  score: Maybe<SortEnumType>;
  scoreHash: Maybe<SortEnumType>;
  /** Based on how long we want games to last, what percentage are we at? */
  targetPercentComplete: Maybe<SortEnumType>;
  worldBoss: Maybe<GameBossSortInput>;
  worldBossId: Maybe<SortEnumType>;
};

/** A Duel is a 1v1 battle between two players, which may have an Ante + specific Rules set by the creator */
export type GameBattleDuel = {
  __typename?: 'GameBattleDuel';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  /** [DB] the Ante required for both players, if any */
  ante: Maybe<AntePool>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: RealmType;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  /** Group for this game */
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** [C] simple alias for CreatorId */
  playerOneId: Scalars['String'];
  playerOneParty: GameParty;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwo: Maybe<FurPlayer>;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwoId: Maybe<Scalars['String']>;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  /** [DB] rules describing limitations on participants */
  rules: Maybe<GameBattleRules>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
};

/** A Duel is a 1v1 battle between two players, which may have an Ante + specific Rules set by the creator */
export type GameBattleDuelCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

/** A Duel is a 1v1 battle between two players, which may have an Ante + specific Rules set by the creator */
export type GameBattleDuelMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

/** A Duel is a 1v1 battle between two players, which may have an Ante + specific Rules set by the creator */
export type GameBattleDuelFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<GameBattleDuelFilterInput>>;
  /** [DB] the Ante required for both players, if any */
  ante: Maybe<AntePoolFilterInput>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: Maybe<RealmTypeOperationFilterInput>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  /** Group for this game */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattleDuelFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** [C] simple alias for CreatorId */
  playerOneId: Maybe<StringOperationFilterInput>;
  playerOneParty: Maybe<GamePartyFilterInput>;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwo: Maybe<FurPlayerFilterInput>;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwoId: Maybe<StringOperationFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  /** [DB] rules describing limitations on participants */
  rules: Maybe<GameBattleRulesFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
};

/** A Duel is a 1v1 battle between two players, which may have an Ante + specific Rules set by the creator */
export type GameBattleDuelSortInput = {
  activateAt: Maybe<SortEnumType>;
  activeHost: Maybe<HostNodeSortInput>;
  activeHostName: Maybe<SortEnumType>;
  /** [DB] the Ante required for both players, if any */
  ante: Maybe<AntePoolSortInput>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: Maybe<SortEnumType>;
  battleState: Maybe<SortEnumType>;
  battleType: Maybe<SortEnumType>;
  combo: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerSortInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<SortEnumType>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<SortEnumType>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<SortEnumType>;
  furballIdsList: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isComplete: Maybe<SortEnumType>;
  isPracticeGame: Maybe<SortEnumType>;
  lastAt: Maybe<SortEnumType>;
  lastEndsAt: Maybe<SortEnumType>;
  lastMoveEndsAt: Maybe<SortEnumType>;
  /** Group for this game */
  leaderboardGroup: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  loadedAt: Maybe<SortEnumType>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<SortEnumType>;
  /** Total number of moves made */
  moveCount: Maybe<SortEnumType>;
  nextAt: Maybe<SortEnumType>;
  ntp: Maybe<SortEnumType>;
  pausedAt: Maybe<SortEnumType>;
  percentRank: Maybe<SortEnumType>;
  /** [C] simple alias for CreatorId */
  playerOneId: Maybe<SortEnumType>;
  playerOneParty: Maybe<GamePartySortInput>;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwo: Maybe<FurPlayerSortInput>;
  /** [C] if the second player has joined and is loaded, their ID */
  playerTwoId: Maybe<SortEnumType>;
  realm: Maybe<SortEnumType>;
  /** [DB] rules describing limitations on participants */
  rules: Maybe<GameBattleRulesSortInput>;
  score: Maybe<SortEnumType>;
  scoreHash: Maybe<SortEnumType>;
};

export type GameBattleFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<GameBattleFilterInput>>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattleFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
};

export type GameBattleMonsters = {
  __typename?: 'GameBattleMonsters';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  aiDamageTotal: Scalars['Long'];
  /** [LL] the party for the AI */
  aiParty: GameParty;
  /** [DB] the realm in which the battle takes place */
  battleRealm: RealmType;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** [DB] The level within the realm (or wave number, for monster attacks). Used to determine how many monsters spawned. */
  difficultyLevel: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  fur: Scalars['Long'];
  furEarned: Scalars['Long'];
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  /** The FurPlayer making moves (owner of the party) */
  gamePlayer: FurPlayer;
  gamePlayerId: Scalars['String'];
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  isMonsterAttack: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  isTrialGame: Scalars['Boolean'];
  isVoyageBattle: Scalars['Boolean'];
  /** [LL] items earned during the game */
  itemQuantitiesEarned: Array<ItemQuantity>;
  itemsEarned: Array<GameItem>;
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  /** Group for this game */
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  monstersKilled: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  percentileRank: Scalars['Float'];
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  player: FurPlayer;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  playerDamageTotal: Scalars['Long'];
  playerId: Scalars['String'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Scalars['Int'];
  /** [LL] the party for the player */
  playerParty: GameParty;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  reward: Maybe<ItemReward>;
  rewardId: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
  wave: Scalars['Int'];
};

export type GameBattleMonstersCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type GameBattleMonstersMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GameBattleMonstersFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  aiParty: Maybe<GamePartyFilterInput>;
  and: Maybe<Array<GameBattleMonstersFilterInput>>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: Maybe<RealmTypeOperationFilterInput>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** [DB] The level within the realm (or wave number, for monster attacks). Used to determine how many monsters spawned. */
  difficultyLevel: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  fur: Maybe<LongOperationFilterInput>;
  furEarned: Maybe<LongOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  /** The FurPlayer making moves (owner of the party) */
  gamePlayer: Maybe<FurPlayerFilterInput>;
  gamePlayerId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isMonsterAttack: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  isTrialGame: Maybe<BooleanOperationFilterInput>;
  isVoyageBattle: Maybe<BooleanOperationFilterInput>;
  itemQuantitiesEarned: Maybe<ListFilterInputTypeOfItemQuantityFilterInput>;
  itemsEarned: Maybe<ListFilterInputTypeOfGameItemFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  /** Group for this game */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  monstersKilled: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattleMonstersFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  percentileRank: Maybe<FloatOperationFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Maybe<IntOperationFilterInput>;
  playerParty: Maybe<GamePartyFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  reward: Maybe<ItemRewardFilterInput>;
  rewardId: Maybe<StringOperationFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
  wave: Maybe<IntOperationFilterInput>;
};

export type GameBattleMonstersSortInput = {
  activateAt: Maybe<SortEnumType>;
  activeHost: Maybe<HostNodeSortInput>;
  activeHostName: Maybe<SortEnumType>;
  aiParty: Maybe<GamePartySortInput>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: Maybe<SortEnumType>;
  battleState: Maybe<SortEnumType>;
  battleType: Maybe<SortEnumType>;
  combo: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerSortInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<SortEnumType>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<SortEnumType>;
  /** [DB] The level within the realm (or wave number, for monster attacks). Used to determine how many monsters spawned. */
  difficultyLevel: Maybe<SortEnumType>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<SortEnumType>;
  fur: Maybe<SortEnumType>;
  furEarned: Maybe<SortEnumType>;
  furballIdsList: Maybe<SortEnumType>;
  /** The FurPlayer making moves (owner of the party) */
  gamePlayer: Maybe<FurPlayerSortInput>;
  gamePlayerId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isComplete: Maybe<SortEnumType>;
  isMonsterAttack: Maybe<SortEnumType>;
  isPracticeGame: Maybe<SortEnumType>;
  isTrialGame: Maybe<SortEnumType>;
  isVoyageBattle: Maybe<SortEnumType>;
  lastAt: Maybe<SortEnumType>;
  lastEndsAt: Maybe<SortEnumType>;
  lastMoveEndsAt: Maybe<SortEnumType>;
  /** Group for this game */
  leaderboardGroup: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  loadedAt: Maybe<SortEnumType>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<SortEnumType>;
  monstersKilled: Maybe<SortEnumType>;
  /** Total number of moves made */
  moveCount: Maybe<SortEnumType>;
  nextAt: Maybe<SortEnumType>;
  ntp: Maybe<SortEnumType>;
  pausedAt: Maybe<SortEnumType>;
  percentRank: Maybe<SortEnumType>;
  percentileRank: Maybe<SortEnumType>;
  playerId: Maybe<SortEnumType>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Maybe<SortEnumType>;
  playerParty: Maybe<GamePartySortInput>;
  realm: Maybe<SortEnumType>;
  reward: Maybe<ItemRewardSortInput>;
  rewardId: Maybe<SortEnumType>;
  score: Maybe<SortEnumType>;
  scoreHash: Maybe<SortEnumType>;
  wave: Maybe<SortEnumType>;
};

export type GameBattlePvE = {
  __typename?: 'GameBattlePvE';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  aiDamageTotal: Scalars['Long'];
  /** [LL] the party for the AI */
  aiParty: GameParty;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  fur: Scalars['Long'];
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  isTrialGame: Scalars['Boolean'];
  /** [LL] items earned during the game */
  itemQuantitiesEarned: Array<ItemQuantity>;
  itemsEarned: Array<GameItem>;
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  /** Group for this game */
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  percentileRank: Scalars['Float'];
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  player: FurPlayer;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  playerDamageTotal: Scalars['Long'];
  playerId: Scalars['String'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Scalars['Int'];
  /** [LL] the party for the player */
  playerParty: GameParty;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  /** [LL] if the game is over, uses entity outcome summaries to compute damage totals */
  reward: Maybe<ItemReward>;
  rewardId: Maybe<Scalars['String']>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
};

export type GameBattlePvECalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type GameBattlePvEMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GameBattlePvEFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  aiParty: Maybe<GamePartyFilterInput>;
  and: Maybe<Array<GameBattlePvEFilterInput>>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  fur: Maybe<LongOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  isTrialGame: Maybe<BooleanOperationFilterInput>;
  itemQuantitiesEarned: Maybe<ListFilterInputTypeOfItemQuantityFilterInput>;
  itemsEarned: Maybe<ListFilterInputTypeOfGameItemFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  /** Group for this game */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattlePvEFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  percentileRank: Maybe<FloatOperationFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** Total number of PLAYER moves made */
  playerMoveCount: Maybe<IntOperationFilterInput>;
  playerParty: Maybe<GamePartyFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  reward: Maybe<ItemRewardFilterInput>;
  rewardId: Maybe<StringOperationFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
};

export type GameBattlePvP = {
  __typename?: 'GameBattlePvP';
  activateAt: Maybe<Scalars['DateTime']>;
  activeHost: Maybe<HostNode>;
  activeHostName: Maybe<Scalars['String']>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: RealmType;
  battleState: GameBattleState;
  battleType: Scalars['String'];
  calculateNtpOffset: Scalars['String'];
  combo: Scalars['Int'];
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Array<ConsumableItem>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] player who created the game */
  creator: FurPlayer;
  /** [DB] player who created the game */
  creatorId: Scalars['String'];
  /** The actual current duration of the game in seconds */
  currentDuration: Scalars['Int'];
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Scalars['Int'];
  entityIds: Array<Scalars['String']>;
  /** List of FurballIds involved in this battle */
  furballIds: Array<Scalars['String']>;
  furballIdsList: Scalars['String'];
  /** [LL] furballs who were in the game */
  furballs: Array<Furball>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  isPracticeGame: Scalars['Boolean'];
  lastAt: Maybe<Scalars['DateTime']>;
  lastEndsAt: Maybe<Scalars['DateTime']>;
  lastMoveEndsAt: Scalars['DateTime'];
  /** Group for this game */
  leaderboardGroup: LeaderboardGroup;
  level: Scalars['Int'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Scalars['Int'];
  /** Total number of moves made */
  moveCount: Scalars['Int'];
  /** Paginate through the moves in the game */
  moves: Maybe<MovesConnection>;
  nextAt: Maybe<Scalars['DateTime']>;
  ntp: Scalars['String'];
  /** [LL] get all the outcomes (empty if game is not over or finished successfully) */
  outcomeSummaries: Array<GameEntitySummary>;
  /** [LL] the parties in this game */
  parties: Array<GameParty>;
  partyIds: Array<Scalars['String']>;
  pausedAt: Maybe<Scalars['DateTime']>;
  percentRank: Scalars['Float'];
  /** List of PlayerIds involved in this battle */
  playerIds: Array<Scalars['String']>;
  /** [LL] players who were in the game */
  players: Array<FurPlayer>;
  realm: RealmType;
  /** Those involved rental agreements */
  rentalAgreements: Array<RentalAgreement>;
  score: Maybe<Scalars['Long']>;
  scoreHash: Scalars['String'];
};

export type GameBattlePvPCalculateNtpOffsetArgs = {
  ntpClientPacket?: Scalars['String'];
};

export type GameBattlePvPMovesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type GameBattlePvPFilterInput = {
  activateAt: Maybe<DateTimeOperationFilterInput>;
  activeHost: Maybe<HostNodeFilterInput>;
  activeHostName: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<GameBattlePvPFilterInput>>;
  /** [DB] the realm in which the battle takes place */
  battleRealm: Maybe<RealmTypeOperationFilterInput>;
  battleState: Maybe<GameBattleStateOperationFilterInput>;
  battleType: Maybe<StringOperationFilterInput>;
  combo: Maybe<IntOperationFilterInput>;
  /** Direct access to the player's consumables, for use in the potions / elixir menu */
  consumableItems: Maybe<ListFilterInputTypeOfConsumableItemFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerFilterInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<StringOperationFilterInput>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<IntOperationFilterInput>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<IntOperationFilterInput>;
  entityIds: Maybe<ListStringOperationFilterInput>;
  /** List of FurballIds involved in this battle */
  furballIds: Maybe<ListStringOperationFilterInput>;
  furballIdsList: Maybe<StringOperationFilterInput>;
  /** All Furballs which were involved in the battle */
  furballs: Maybe<ListFilterInputTypeOfFurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  isPracticeGame: Maybe<BooleanOperationFilterInput>;
  lastAt: Maybe<DateTimeOperationFilterInput>;
  lastEndsAt: Maybe<DateTimeOperationFilterInput>;
  lastMoveEndsAt: Maybe<DateTimeOperationFilterInput>;
  /** Group for this game */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  level: Maybe<IntOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<IntOperationFilterInput>;
  /** Total number of moves made */
  moveCount: Maybe<IntOperationFilterInput>;
  moves: Maybe<ListFilterInputTypeOfBattleMoveFilterInput>;
  nextAt: Maybe<DateTimeOperationFilterInput>;
  ntp: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBattlePvPFilterInput>>;
  /** [DB] the actual outcomes from each entity */
  outcomeSummaries: Maybe<ListFilterInputTypeOfGameEntitySummaryFilterInput>;
  parties: Maybe<ListFilterInputTypeOfGamePartyFilterInput>;
  partyIds: Maybe<ListStringOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  percentRank: Maybe<FloatOperationFilterInput>;
  /** List of PlayerIds involved in this battle */
  playerIds: Maybe<ListStringOperationFilterInput>;
  /** All players which were involved in the battle (only one for PvE/boss fights) */
  players: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  /** Those involved rental agreements */
  rentalAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  score: Maybe<LongOperationFilterInput>;
  scoreHash: Maybe<StringOperationFilterInput>;
};

/** Rules which limit who may participate in a battle, or what they may use */
export type GameBattleRules = {
  __typename?: 'GameBattleRules';
  id: Scalars['String'];
  /** [DB] the max level for any monster/furball */
  maxLevel: Maybe<Scalars['Int']>;
  /** [DB] the max percent (0-1) any Furball can have upgraded skills */
  maxSkillUpgradePercent: Maybe<Scalars['Float']>;
  /** [DB] the realm in which it takes place */
  realm: Maybe<RealmType>;
};

/** Rules which limit who may participate in a battle, or what they may use */
export type GameBattleRulesFilterInput = {
  and: Maybe<Array<GameBattleRulesFilterInput>>;
  id: Maybe<StringOperationFilterInput>;
  /** [DB] the max level for any monster/furball */
  maxLevel: Maybe<IntOperationFilterInput>;
  /** [DB] the max percent (0-1) any Furball can have upgraded skills */
  maxSkillUpgradePercent: Maybe<FloatOperationFilterInput>;
  or: Maybe<Array<GameBattleRulesFilterInput>>;
  /** [DB] the realm in which it takes place */
  realm: Maybe<NullableOfRealmTypeOperationFilterInput>;
};

/** Rules which limit who may participate in a battle, or what they may use */
export type GameBattleRulesSortInput = {
  id: Maybe<SortEnumType>;
  /** [DB] the max level for any monster/furball */
  maxLevel: Maybe<SortEnumType>;
  /** [DB] the max percent (0-1) any Furball can have upgraded skills */
  maxSkillUpgradePercent: Maybe<SortEnumType>;
  /** [DB] the realm in which it takes place */
  realm: Maybe<SortEnumType>;
};

export type GameBattleSortInput = {
  activateAt: Maybe<SortEnumType>;
  activeHost: Maybe<HostNodeSortInput>;
  activeHostName: Maybe<SortEnumType>;
  battleState: Maybe<SortEnumType>;
  battleType: Maybe<SortEnumType>;
  combo: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** [DB] player who created the game */
  creator: Maybe<FurPlayerSortInput>;
  /** [DB] player who created the game */
  creatorId: Maybe<SortEnumType>;
  /** The actual current duration of the game in seconds */
  currentDuration: Maybe<SortEnumType>;
  /** Updated only when paused or ended, this tracks the total time of the game in seconds */
  duration: Maybe<SortEnumType>;
  furballIdsList: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isComplete: Maybe<SortEnumType>;
  isPracticeGame: Maybe<SortEnumType>;
  lastAt: Maybe<SortEnumType>;
  lastEndsAt: Maybe<SortEnumType>;
  lastMoveEndsAt: Maybe<SortEnumType>;
  leaderboardGroup: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  loadedAt: Maybe<SortEnumType>;
  /** Idle-detection: goes up each time the background thread fails to find work */
  lockFailures: Maybe<SortEnumType>;
  /** Total number of moves made */
  moveCount: Maybe<SortEnumType>;
  nextAt: Maybe<SortEnumType>;
  ntp: Maybe<SortEnumType>;
  pausedAt: Maybe<SortEnumType>;
  percentRank: Maybe<SortEnumType>;
  realm: Maybe<SortEnumType>;
  score: Maybe<SortEnumType>;
  scoreHash: Maybe<SortEnumType>;
};

export enum GameBattleState {
  Active = 'ACTIVE',
  AutoBattle = 'AUTO_BATTLE',
  Complete = 'COMPLETE',
  Forfeit = 'FORFEIT',
  Killshot = 'KILLSHOT',
  Loaded = 'LOADED',
  Loading = 'LOADING',
  Lost = 'LOST',
  None = 'NONE',
  Paused = 'PAUSED',
  Won = 'WON',
}

export type GameBattleStateOperationFilterInput = {
  eq: Maybe<GameBattleState>;
  in: Maybe<Array<GameBattleState>>;
  neq: Maybe<GameBattleState>;
  nin: Maybe<Array<GameBattleState>>;
};

/** A connection to a list of items. */
export type GameBattlesConnection = {
  __typename?: 'GameBattlesConnection';
  /** A list of edges. */
  edges: Maybe<Array<GameBattlesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattle>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type GameBattlesEdge = {
  __typename?: 'GameBattlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattle;
};

export type GameBoss = {
  __typename?: 'GameBoss';
  createdAt: Maybe<Scalars['DateTime']>;
  /** If this boss is dead, when did it happen? */
  defeatedAt: Maybe<Scalars['DateTime']>;
  /** Multiplier on top of certain equations to make more balanced; starts at boss' level and grows fixed % each turn */
  difficulty: Scalars['Float'];
  /** [LL] the currently equipped items */
  equipment: Array<EquipmentItem>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Array<StatEntity>;
  /** The **global** HP, affected by ALL battle instances */
  health: Scalars['Long'];
  hearts: Scalars['Int'];
  id: Scalars['ID'];
  /** [LL] the inventory of this entity */
  inventory: ContainerItem;
  /** Which level of the max is the boss currently on? */
  level: Scalars['Int'];
  /** Generated when the boss resets based on recent gameplay numbers */
  maxHealth: Scalars['Long'];
  /** Max level for the boss (constant for all bosses) */
  maxLevel: Scalars['Int'];
  /** Derived from the realm */
  name: Scalars['String'];
  partyId: Maybe<Scalars['String']>;
  /** The realm defines which boss this is (e.g., Boneyard = Skullumbo */
  realm: RealmType;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type GameBossFilterInput = {
  and: Maybe<Array<GameBossFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** If this boss is dead, when did it happen? */
  defeatedAt: Maybe<DateTimeOperationFilterInput>;
  /** Multiplier on top of certain equations to make more balanced; starts at boss' level and grows fixed % each turn */
  difficulty: Maybe<FloatOperationFilterInput>;
  /** The actual equipped items are stored separate from the inventory */
  equipment: Maybe<ListFilterInputTypeOfEquipmentItemFilterInput>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Maybe<ListFilterInputTypeOfStatEntityFilterInput>;
  /** The **global** HP, affected by ALL battle instances */
  health: Maybe<LongOperationFilterInput>;
  hearts: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  inventory: Maybe<ContainerItemFilterInput>;
  /** Which level of the max is the boss currently on? */
  level: Maybe<IntOperationFilterInput>;
  /** Generated when the boss resets based on recent gameplay numbers */
  maxHealth: Maybe<LongOperationFilterInput>;
  /** Max level for the boss (constant for all bosses) */
  maxLevel: Maybe<IntOperationFilterInput>;
  /** Derived from the realm */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameBossFilterInput>>;
  partyId: Maybe<StringOperationFilterInput>;
  /** The realm defines which boss this is (e.g., Boneyard = Skullumbo */
  realm: Maybe<RealmTypeOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type GameBossSortInput = {
  createdAt: Maybe<SortEnumType>;
  /** If this boss is dead, when did it happen? */
  defeatedAt: Maybe<SortEnumType>;
  /** Multiplier on top of certain equations to make more balanced; starts at boss' level and grows fixed % each turn */
  difficulty: Maybe<SortEnumType>;
  /** The **global** HP, affected by ALL battle instances */
  health: Maybe<SortEnumType>;
  hearts: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  inventory: Maybe<ContainerItemSortInput>;
  /** Which level of the max is the boss currently on? */
  level: Maybe<SortEnumType>;
  /** Generated when the boss resets based on recent gameplay numbers */
  maxHealth: Maybe<SortEnumType>;
  /** Max level for the boss (constant for all bosses) */
  maxLevel: Maybe<SortEnumType>;
  /** Derived from the realm */
  name: Maybe<SortEnumType>;
  partyId: Maybe<SortEnumType>;
  /** The realm defines which boss this is (e.g., Boneyard = Skullumbo */
  realm: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export type GameBuild = {
  __typename?: 'GameBuild';
  /** The CI/build number (may be zero for manual) */
  buildNumber: Scalars['UnsignedLong'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** Is the build healthy? (skip if not) */
  health: BuildHealth;
  id: Scalars['ID'];
  /** Full semantic version (major.minor.patch-tag.buildNumber) */
  semver: Scalars['String'];
  /** The type of build */
  tag: BuildTag;
  /** Semver's first digit */
  versionMajor: Scalars['UnsignedShort'];
  /** Semver's second digit */
  versionMinor: Scalars['UnsignedShort'];
  /** Semver's third digit */
  versionPatch: Scalars['UnsignedShort'];
  /** The web app's code unityweb file */
  webCodeFileHash: Scalars['String'];
  /** The web app's data unityweb file */
  webDataFileHash: Scalars['String'];
  /** The web app's framework unityweb file */
  webFrameworkFileHash: Scalars['String'];
};

export type GameEntity = Furball | GameBoss | GameMonster;

export type GameEntityFilterInput = {
  and: Maybe<Array<GameEntityFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** The actual equipped items are stored separate from the inventory */
  equipment: Maybe<ListFilterInputTypeOfEquipmentItemFilterInput>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Maybe<ListFilterInputTypeOfStatEntityFilterInput>;
  hearts: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  inventory: Maybe<ContainerItemFilterInput>;
  or: Maybe<Array<GameEntityFilterInput>>;
  partyId: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type GameEntitySortInput = {
  createdAt: Maybe<SortEnumType>;
  hearts: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  inventory: Maybe<ContainerItemSortInput>;
  partyId: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export type GameEntitySummary = {
  __typename?: 'GameEntitySummary';
  /** How much damage the entity received in the game */
  damageTaken: Scalars['Long'];
  /** How much damage the entity did in the game */
  damageTotal: Scalars['Long'];
  /** [DB] total EXP gained thru the fight, including additional EXP given at end */
  expGained: Scalars['Long'];
  gameBattle: GameBattle;
  /** The ID of the game in which this took place */
  gameBattleId: Scalars['String'];
  gameEntity: Maybe<GameEntity>;
  /** The ID of the entity which participated; may be null for minions */
  gameEntityId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** [DB] total levels gained thru fight, including EXP at end */
  levelsGained: Scalars['Int'];
  /** How many moves the entity made */
  moveCount: Scalars['Int'];
  statEntity: StatEntity;
  /** The ID of the in-game creature which participated */
  statEntityId: Scalars['String'];
};

export type GameEntitySummaryFilterInput = {
  and: Maybe<Array<GameEntitySummaryFilterInput>>;
  /** How much damage the entity received in the game */
  damageTaken: Maybe<LongOperationFilterInput>;
  /** How much damage the entity did in the game */
  damageTotal: Maybe<LongOperationFilterInput>;
  /** [DB] total EXP gained thru the fight, including additional EXP given at end */
  expGained: Maybe<LongOperationFilterInput>;
  gameBattle: Maybe<GameBattleFilterInput>;
  /** The ID of the game in which this took place */
  gameBattleId: Maybe<StringOperationFilterInput>;
  gameEntity: Maybe<GameEntityFilterInput>;
  /** The ID of the entity which participated; may be null for minions */
  gameEntityId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** [DB] total levels gained thru fight, including EXP at end */
  levelsGained: Maybe<IntOperationFilterInput>;
  /** How many moves the entity made */
  moveCount: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<GameEntitySummaryFilterInput>>;
  statEntity: Maybe<StatEntityFilterInput>;
  /** The ID of the in-game creature which participated */
  statEntityId: Maybe<StringOperationFilterInput>;
};

/** Base class for all game items */
export type GameItem =
  | ConsumableItem
  | ContainerItem
  | EquipmentItem
  | LootItem
  | MaterialItem
  | SpecialItem;

/** Base class for all game items */
export type GameItemFilterInput = {
  and: Maybe<Array<GameItemFilterInput>>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** How many items can be stacked? */
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<GameItemFilterInput>>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

/** Base class for all game items */
export type GameItemSortInput = {
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemSortInput>;
  /** The parent container, if any */
  containerId: Maybe<SortEnumType>;
  /** When was the item/stack created? */
  createdAt: Maybe<SortEnumType>;
  definition: Maybe<ItemDefinitionSortInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<SortEnumType>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<SortEnumType>;
  gameBattlePvEId: Maybe<SortEnumType>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<SortEnumType>;
  itemDefinition: Maybe<ItemDefinitionSortInput>;
  itemDiscriminator: Maybe<SortEnumType>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<SortEnumType>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<SortEnumType>;
  /** How many items can be stacked? */
  maxStack: Maybe<SortEnumType>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<SortEnumType>;
  playerPendingReward: Maybe<PlayerPendingRewardSortInput>;
  playerPendingRewardId: Maybe<SortEnumType>;
  /** The rarity level, derived from definition */
  rarity: Maybe<SortEnumType>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<SortEnumType>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<SortEnumType>;
};

export type GameMonster = {
  __typename?: 'GameMonster';
  createdAt: Maybe<Scalars['DateTime']>;
  /** If this boss is dead, when did it happen? */
  defeatedAt: Maybe<Scalars['DateTime']>;
  definition: MonsterDefinition;
  /** [LL] the currently equipped items */
  equipment: Array<EquipmentItem>;
  /** The link is established on the GameEntity level as 1:N, but on Furballs it is 1:1 */
  gameStats: Array<StatEntity>;
  hearts: Scalars['Int'];
  id: Scalars['ID'];
  /** [LL] the inventory of this entity */
  inventory: ContainerItem;
  /** Which level of the max is the boss currently on? */
  level: Scalars['Int'];
  /** The ID of the underlying monster definition */
  monsterDefinitionId: Scalars['String'];
  partyId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type GameObject = Furball | GameBoss | GameMonster;

export type GameParty = {
  __typename?: 'GameParty';
  aiEnabled: Scalars['Boolean'];
  battles: Array<GameBattle>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** [LL] the current battle */
  currentBattle: Maybe<GameBattle>;
  currentBattleId: Maybe<Scalars['String']>;
  /** [LL] the current battle */
  currentBossBattle: Maybe<GameBattleBoss>;
  /** [LL] the current battle */
  currentMonsterBattle: Maybe<GameBattleMonsters>;
  filledPositions: Array<FormationPosition>;
  formation: FormationDefinition;
  id: Scalars['ID'];
  isInBattle: Scalars['Boolean'];
  isPlayerParty: Scalars['Boolean'];
  /** [LL] the max level for each realm, indexed by realm type; -1 means boss is available */
  maxLevels: Array<Scalars['Int']>;
  maxSize: Scalars['Int'];
  name: Scalars['String'];
  openPositions: Array<FormationPosition>;
  partyMemberIds: Array<Scalars['String']>;
  /** [LL] the entities in this party */
  partyMembers: Array<StatEntity>;
  player: Maybe<FurPlayer>;
  playerId: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  slotNumber: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type GamePartyFilterInput = {
  aiEnabled: Maybe<BooleanOperationFilterInput>;
  and: Maybe<Array<GamePartyFilterInput>>;
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  currentBattle: Maybe<GameBattleFilterInput>;
  currentBattleId: Maybe<StringOperationFilterInput>;
  filledPositions: Maybe<ListFormationPositionOperationFilterInput>;
  formation: Maybe<FormationDefinitionFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isInBattle: Maybe<BooleanOperationFilterInput>;
  isPlayerParty: Maybe<BooleanOperationFilterInput>;
  maxSize: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  openPositions: Maybe<ListFormationPositionOperationFilterInput>;
  or: Maybe<Array<GamePartyFilterInput>>;
  partyMemberIds: Maybe<ListStringOperationFilterInput>;
  partyMembers: Maybe<ListFilterInputTypeOfStatEntityFilterInput>;
  player: Maybe<FurPlayerFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  size: Maybe<IntOperationFilterInput>;
  slotNumber: Maybe<IntOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type GamePartySortInput = {
  aiEnabled: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  currentBattle: Maybe<GameBattleSortInput>;
  currentBattleId: Maybe<SortEnumType>;
  formation: Maybe<FormationDefinitionSortInput>;
  id: Maybe<SortEnumType>;
  isInBattle: Maybe<SortEnumType>;
  isPlayerParty: Maybe<SortEnumType>;
  maxSize: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  player: Maybe<FurPlayerSortInput>;
  playerId: Maybe<SortEnumType>;
  size: Maybe<SortEnumType>;
  slotNumber: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export type GamePlayRulesInput = {
  /** [DB] the max level for any monster/furball */
  maxLevel: Maybe<Scalars['Int']>;
  /** [DB] the max percent (0-1) any Furball can have upgraded skills */
  maxSkillUpgradePercent: Maybe<Scalars['Float']>;
  /** [DB] the realm in which it takes place */
  realm: Maybe<RealmType>;
};

/** A connection to a list of items. */
export type HistoryConnection = {
  __typename?: 'HistoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<HistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<PlayerHistoryDay>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type HistoryEdge = {
  __typename?: 'HistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlayerHistoryDay;
};

export type HostNode = {
  __typename?: 'HostNode';
  createdAt: Maybe<Scalars['DateTime']>;
  hostState: HostState;
  id: Scalars['String'];
  name: Scalars['String'];
  taskCount: Scalars['Int'];
  /** Tasks assigned to the worker */
  tasks: Array<HostTask>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type HostNodeFilterInput = {
  and: Maybe<Array<HostNodeFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  hostState: Maybe<HostStateOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<HostNodeFilterInput>>;
  taskCount: Maybe<IntOperationFilterInput>;
  /** Tasks assigned to the worker */
  tasks: Maybe<ListFilterInputTypeOfHostTaskFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type HostNodeSortInput = {
  createdAt: Maybe<SortEnumType>;
  hostState: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  taskCount: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export enum HostState {
  Alive = 'ALIVE',
  Errored = 'ERRORED',
  Offline = 'OFFLINE',
  Shutdown = 'SHUTDOWN',
  Startup = 'STARTUP',
}

export type HostStateOperationFilterInput = {
  eq: Maybe<HostState>;
  in: Maybe<Array<HostState>>;
  neq: Maybe<HostState>;
  nin: Maybe<Array<HostState>>;
};

export type HostTask = {
  __typename?: 'HostTask';
  /** The host which has been assigned the work */
  assignedHost: HostNode;
  assignedHostName: Scalars['String'];
  beganAt: Maybe<Scalars['DateTime']>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  failureCount: Scalars['Int'];
  id: Scalars['String'];
  taskName: HostTaskName;
  timeoutAt: Scalars['DateTime'];
};

export type HostTaskFilterInput = {
  and: Maybe<Array<HostTaskFilterInput>>;
  /** The host which has been assigned the work */
  assignedHost: Maybe<HostNodeFilterInput>;
  assignedHostName: Maybe<StringOperationFilterInput>;
  beganAt: Maybe<DateTimeOperationFilterInput>;
  completedAt: Maybe<DateTimeOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  failureCount: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<HostTaskFilterInput>>;
  taskName: Maybe<HostTaskNameOperationFilterInput>;
  timeoutAt: Maybe<DateTimeOperationFilterInput>;
};

export enum HostTaskName {
  Cleanup = 'CLEANUP',
  GameLoopOccasional = 'GAME_LOOP_OCCASIONAL',
  GiveRentalOwnerRewards = 'GIVE_RENTAL_OWNER_REWARDS',
  Heartbeat = 'HEARTBEAT',
  Notify = 'NOTIFY',
  RenameFurballs = 'RENAME_FURBALLS',
  RunTimekeeper = 'RUN_TIMEKEEPER',
  Unknown = 'UNKNOWN',
  UpdateBossLeaderboards = 'UPDATE_BOSS_LEADERBOARDS',
  UpdateCoreMetrics = 'UPDATE_CORE_METRICS',
  UpdateDirty = 'UPDATE_DIRTY',
  WrapPendingBalances = 'WRAP_PENDING_BALANCES',
}

export type HostTaskNameOperationFilterInput = {
  eq: Maybe<HostTaskName>;
  in: Maybe<Array<HostTaskName>>;
  neq: Maybe<HostTaskName>;
  nin: Maybe<Array<HostTaskName>>;
};

export type IBattleEffect = {
  decay: Scalars['Float'];
  operand: EntityEffectOperand;
  stat: StatEffectTarget;
  value: Scalars['Int'];
};

export type IBattleEffectFilterInput = {
  and: Maybe<Array<IBattleEffectFilterInput>>;
  decay: Maybe<FloatOperationFilterInput>;
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<IBattleEffectFilterInput>>;
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  value: Maybe<IntOperationFilterInput>;
};

export type IFurPlayer = {
  activeSession: Maybe<FurSession>;
  /** The fully-qualified URL for the player's avatar */
  avatar: Maybe<Scalars['String']>;
  battles: Array<GameBattle>;
  bossBattleCount: Scalars['Int'];
  bossBattles: Array<GameBattleBoss>;
  communityFlags: Array<CommunityFlag>;
  craftingAttempts: Array<CraftingAttempt>;
  /** Each slot represents the ability to craft a single item (recipe) at a time */
  craftingSlots: Array<CraftSlot>;
  createdAt: Maybe<Scalars['DateTime']>;
  discordId: Maybe<Scalars['UnsignedLong']>;
  historyDays: Array<PlayerHistoryDay>;
  id: Scalars['ID'];
  inventory: ContainerItem;
  itemListings: Array<PlayerItemListing>;
  numRentalCancellationsOwner: Scalars['Int'];
  numRentalCancellationsPlayer: Scalars['Int'];
  onboarding: Array<PlayerOnboardingStep>;
  onboardingSequence: Scalars['Int'];
  payouts: Array<PlayerPayout>;
  pendingRewards: Array<PlayerPendingReward>;
  quests: Array<Quest>;
  realmsPlayed: Array<RealmType>;
  realmsPlayedBoss: Array<RealmType>;
  realmsPlayedMonsters: Array<RealmType>;
  referralCount: Scalars['UnsignedInt'];
  referrals: Array<FurPlayer>;
  rentedFurballCount: Scalars['Int'];
  roleFlags: Array<Scalars['String']>;
  scholarshipAgreements: Array<RentalAgreement>;
  sessions: Array<FurSession>;
  socialName: Scalars['String'];
  tix: Scalars['Float'];
  transactions: Array<BlockchainTokenTransaction>;
  username: Maybe<Scalars['String']>;
  wFur: Scalars['Long'];
  waitlistFlags: Array<WaitlistFlag>;
  writtenRoles: Array<Scalars['String']>;
};

export type IFurPlayerFilterInput = {
  activeSession: Maybe<FurSessionFilterInput>;
  and: Maybe<Array<IFurPlayerFilterInput>>;
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  bossBattleCount: Maybe<IntOperationFilterInput>;
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  communityFlags: Maybe<ListCommunityFlagOperationFilterInput>;
  craftingAttempts: Maybe<ListFilterInputTypeOfCraftingAttemptFilterInput>;
  historyDays: Maybe<ListFilterInputTypeOfPlayerHistoryDayFilterInput>;
  itemListings: Maybe<ListFilterInputTypeOfPlayerItemListingFilterInput>;
  numRentalCancellationsOwner: Maybe<IntOperationFilterInput>;
  numRentalCancellationsPlayer: Maybe<IntOperationFilterInput>;
  onboarding: Maybe<ListFilterInputTypeOfPlayerOnboardingStepFilterInput>;
  onboardingSequence: Maybe<IntOperationFilterInput>;
  or: Maybe<Array<IFurPlayerFilterInput>>;
  payouts: Maybe<ListFilterInputTypeOfPlayerPayoutFilterInput>;
  pendingRewards: Maybe<ListFilterInputTypeOfPlayerPendingRewardFilterInput>;
  quests: Maybe<ListFilterInputTypeOfQuestFilterInput>;
  realmsPlayed: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedBoss: Maybe<ListRealmTypeOperationFilterInput>;
  realmsPlayedMonsters: Maybe<ListRealmTypeOperationFilterInput>;
  referrals: Maybe<ListFilterInputTypeOfFurPlayerFilterInput>;
  rentedFurballCount: Maybe<IntOperationFilterInput>;
  roleFlags: Maybe<ListStringOperationFilterInput>;
  scholarshipAgreements: Maybe<ListFilterInputTypeOfRentalAgreementFilterInput>;
  sessions: Maybe<ListFilterInputTypeOfFurSessionFilterInput>;
  socialName: Maybe<StringOperationFilterInput>;
  tix: Maybe<FloatOperationFilterInput>;
  transactions: Maybe<ListFilterInputTypeOfBlockchainTokenTransactionFilterInput>;
  wFur: Maybe<LongOperationFilterInput>;
  waitlistFlags: Maybe<ListWaitlistFlagOperationFilterInput>;
  writtenRoles: Maybe<ListStringOperationFilterInput>;
};

export type IFurPlayerSortInput = {
  activeSession: Maybe<FurSessionSortInput>;
  bossBattleCount: Maybe<SortEnumType>;
  discordId: Maybe<SortEnumType>;
  numRentalCancellationsOwner: Maybe<SortEnumType>;
  numRentalCancellationsPlayer: Maybe<SortEnumType>;
  onboardingSequence: Maybe<SortEnumType>;
  referralCount: Maybe<SortEnumType>;
  rentedFurballCount: Maybe<SortEnumType>;
  socialName: Maybe<SortEnumType>;
  tix: Maybe<SortEnumType>;
  wFur: Maybe<SortEnumType>;
};

export type IntOperationFilterInput = {
  eq: Maybe<Scalars['Int']>;
  gt: Maybe<Scalars['Int']>;
  gte: Maybe<Scalars['Int']>;
  in: Maybe<Array<Maybe<Scalars['Int']>>>;
  lt: Maybe<Scalars['Int']>;
  lte: Maybe<Scalars['Int']>;
  neq: Maybe<Scalars['Int']>;
  ngt: Maybe<Scalars['Int']>;
  ngte: Maybe<Scalars['Int']>;
  nin: Maybe<Array<Maybe<Scalars['Int']>>>;
  nlt: Maybe<Scalars['Int']>;
  nlte: Maybe<Scalars['Int']>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  createdAt: Maybe<Scalars['DateTime']>;
  definition: FurballDefinition;
  id: Scalars['String'];
  itemId: Scalars['Int'];
  migratedToItemId: Maybe<Scalars['String']>;
  offChain: Scalars['Boolean'];
  rarity: Scalars['Int'];
  stack: Scalars['Int'];
  stat: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type InventoryItemFilterInput = {
  and: Maybe<Array<InventoryItemFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<FurballDefinitionFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  itemId: Maybe<IntOperationFilterInput>;
  migratedToItemId: Maybe<StringOperationFilterInput>;
  offChain: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<InventoryItemFilterInput>>;
  rarity: Maybe<IntOperationFilterInput>;
  stack: Maybe<IntOperationFilterInput>;
  stat: Maybe<IntOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type ItemDefInput = {
  itemId: Scalars['Long'];
  percentChance: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type ItemDefinition = {
  __typename?: 'ItemDefinition';
  allowInFurballInventory: Scalars['Boolean'];
  durabilityTime: Scalars['Int'];
  /** For consumables and inherent effects */
  effects: Array<SkillStatEffect>;
  icon: Scalars['String'];
  id: Scalars['Long'];
  itemGroup: ItemGroup;
  itemId: Scalars['String'];
  name: Scalars['String'];
  /** For equipment items that have a rolled effect range */
  passives: Array<PassiveEffectRange>;
  rarity: RarityLevel;
  /** If there is any affiliation to a realm (e.g., bonus in that realm, comes from that realm) */
  realmAffiliation: RealmType;
  /** If craftable, what is the recipe to craft it? */
  recipe: Maybe<CraftingRecipe>;
  untradeable: Scalars['Boolean'];
  upgradeToItemId: Scalars['Long'];
  valueMultiplier: Scalars['Float'];
};

export type ItemDefinitionFilterInput = {
  allowInFurballInventory: Maybe<BooleanOperationFilterInput>;
  and: Maybe<Array<ItemDefinitionFilterInput>>;
  durabilityTime: Maybe<IntOperationFilterInput>;
  /** For consumables and inherent effects */
  effects: Maybe<ListFilterInputTypeOfSkillStatEffectFilterInput>;
  icon: Maybe<StringOperationFilterInput>;
  id: Maybe<LongOperationFilterInput>;
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  itemId: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<ItemDefinitionFilterInput>>;
  /** For equipment items that have a rolled effect range */
  passives: Maybe<ListFilterInputTypeOfPassiveEffectRangeFilterInput>;
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** If there is any affiliation to a realm (e.g., bonus in that realm, comes from that realm) */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** If craftable, what is the recipe to craft it? */
  recipe: Maybe<CraftingRecipeFilterInput>;
  untradeable: Maybe<BooleanOperationFilterInput>;
  upgradeToItemId: Maybe<LongOperationFilterInput>;
  valueMultiplier: Maybe<FloatOperationFilterInput>;
};

export type ItemDefinitionSortInput = {
  allowInFurballInventory: Maybe<SortEnumType>;
  durabilityTime: Maybe<SortEnumType>;
  icon: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  itemGroup: Maybe<SortEnumType>;
  itemId: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  rarity: Maybe<SortEnumType>;
  /** If there is any affiliation to a realm (e.g., bonus in that realm, comes from that realm) */
  realmAffiliation: Maybe<SortEnumType>;
  /** If craftable, what is the recipe to craft it? */
  recipe: Maybe<CraftingRecipeSortInput>;
  untradeable: Maybe<SortEnumType>;
  upgradeToItemId: Maybe<SortEnumType>;
  valueMultiplier: Maybe<SortEnumType>;
};

export enum ItemDiscriminator {
  /** In-battle consumable (i.e., health potion) */
  Consumable = 'CONSUMABLE',
  /** Generic reserved for containers (inventories, treasure chests, etc.) */
  Container = 'CONTAINER',
  Equipment = 'EQUIPMENT',
  /** Original on-chain items (shoes, frisbees, ...) */
  Loot = 'LOOT',
  /** Used in crafting (dust, etc.) */
  Material = 'MATERIAL',
  /** Holiday & boss loot */
  Special = 'SPECIAL',
  Unknown = 'UNKNOWN',
}

export type ItemDiscriminatorOperationFilterInput = {
  eq: Maybe<ItemDiscriminator>;
  in: Maybe<Array<ItemDiscriminator>>;
  neq: Maybe<ItemDiscriminator>;
  nin: Maybe<Array<ItemDiscriminator>>;
};

/** Sub-divides the long hex itemId into top level categories */
export enum ItemGroup {
  /** In-battle consumable (i.e., health potion) */
  Consumable = 'CONSUMABLE',
  /** Generic reserved for containers (inventories, treasure chests, etc.) */
  Container = 'CONTAINER',
  /** The second of the various equipment slots */
  EquipmentBody = 'EQUIPMENT_BODY',
  /** The third of the various equipment slots */
  EquipmentHead = 'EQUIPMENT_HEAD',
  /** The fifth of the various equipment slots */
  EquipmentOffHand = 'EQUIPMENT_OFF_HAND',
  /** The first of the various equipment slots */
  EquipmentRing = 'EQUIPMENT_RING',
  /** The fourth of the various equipment slots */
  EquipmentWeapon = 'EQUIPMENT_WEAPON',
  /** Original on-chain items (shoes, frisbees, ...) */
  Loot = 'LOOT',
  /** Used in crafting (dust, etc.) */
  Material = 'MATERIAL',
  /** Holiday & boss loot */
  Special = 'SPECIAL',
}

export type ItemGroupOperationFilterInput = {
  eq: Maybe<ItemGroup>;
  in: Maybe<Array<ItemGroup>>;
  neq: Maybe<ItemGroup>;
  nin: Maybe<Array<ItemGroup>>;
};

/** A connection to a list of items. */
export type ItemListingsConnection = {
  __typename?: 'ItemListingsConnection';
  /** A list of edges. */
  edges: Maybe<Array<ItemListingsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<PlayerItemListing>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ItemListingsEdge = {
  __typename?: 'ItemListingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlayerItemListing;
};

export type ItemQuantity = {
  __typename?: 'ItemQuantity';
  /** The item this quantity represents */
  itemId: Scalars['Long'];
  /** Count of ALL stacks of this item ID */
  quantity: Scalars['Int'];
};

export type ItemQuantityFilterInput = {
  and: Maybe<Array<ItemQuantityFilterInput>>;
  /** The item this quantity represents */
  itemId: Maybe<LongOperationFilterInput>;
  or: Maybe<Array<ItemQuantityFilterInput>>;
  /** Count of ALL stacks of this item ID */
  quantity: Maybe<IntOperationFilterInput>;
};

export type ItemReward = {
  __typename?: 'ItemReward';
  id: Scalars['String'];
  /** Derived from ItemId */
  itemDefinition: ItemDefinition;
  /** The definition of the item */
  itemId: Scalars['Long'];
  /** How many of the item to give */
  quantity: Scalars['Int'];
};

export type ItemRewardFilterInput = {
  and: Maybe<Array<ItemRewardFilterInput>>;
  id: Maybe<StringOperationFilterInput>;
  /** Derived from ItemId */
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  /** The definition of the item */
  itemId: Maybe<LongOperationFilterInput>;
  or: Maybe<Array<ItemRewardFilterInput>>;
  /** How many of the item to give */
  quantity: Maybe<IntOperationFilterInput>;
};

export type ItemRewardSortInput = {
  id: Maybe<SortEnumType>;
  /** Derived from ItemId */
  itemDefinition: Maybe<ItemDefinitionSortInput>;
  /** The definition of the item */
  itemId: Maybe<SortEnumType>;
  /** How many of the item to give */
  quantity: Maybe<SortEnumType>;
};

export type JoinBattleResponse = {
  __typename?: 'JoinBattleResponse';
  battle: GameBattle;
  bossBattle: Maybe<GameBattleBoss>;
  createDuel: GameBattleDuel;
  duelBattle: Maybe<GameBattleDuel>;
  joinBossBattle: GameBattleBoss;
  joinDuel: GameBattleDuel;
  joinMonsterBattle: GameBattleMonsters;
  monsterBattle: Maybe<GameBattleMonsters>;
  partyId: Scalars['String'];
  player: FurPlayer;
  pvEBattle: Maybe<GameBattlePvE>;
  pvPBattle: Maybe<GameBattlePvP>;
  realm: RealmType;
};

export type JoinBattleResponseCreateDuelArgs = {
  ante: Maybe<AnteInput>;
  rules: Maybe<GamePlayRulesInput>;
};

export type JoinBattleResponseJoinBossBattleArgs = {
  practiceGame?: Scalars['Boolean'];
};

export type JoinBattleResponseJoinDuelArgs = {
  duelId: Scalars['String'];
};

export type JoinBattleResponseJoinMonsterBattleArgs = {
  level: Maybe<Scalars['Int']>;
};

export type KeyValuePairOfInt64AndInt32 = {
  __typename?: 'KeyValuePairOfInt64AndInt32';
  key: Scalars['Long'];
  value: Scalars['Int'];
};

export type KeyValuePairOfStringAndAnimationAsset = {
  __typename?: 'KeyValuePairOfStringAndAnimationAsset';
  key: Scalars['String'];
  value: AnimationAsset;
};

export type KeyValuePairOfStringAndAnimationAssetFilterInput = {
  and: Maybe<Array<KeyValuePairOfStringAndAnimationAssetFilterInput>>;
  key: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<KeyValuePairOfStringAndAnimationAssetFilterInput>>;
  value: Maybe<AnimationAssetFilterInput>;
};

export type Leaderboard = {
  __typename?: 'Leaderboard';
  /** The leaderboard is only active after this date */
  activeAfter: Maybe<Scalars['DateTime']>;
  /** The leaderboard is only active before this date */
  activeBefore: Maybe<Scalars['DateTime']>;
  /** 2/4 unique keys: the type of entity (player vs furball) */
  entityType: LeaderboardEntityType;
  id: Scalars['String'];
  /** 4/4 unique keys: a sub-group specific to context */
  leaderGroup: LeaderboardGroup;
  /** DO NOT USE except for json loader */
  reward: Array<LeaderboardTierRewards>;
  /** What rewards are obtained from being in this leaderboard? */
  rewards: Array<LeaderboardTierRewards>;
  /** Each time rewards are given, new slot & tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Scalars['Int'];
  /** Paginate through the slots in the leaderboard (each one represents a score; can have ties) */
  slots: Maybe<SlotsConnection>;
  /** 1/4 unique keys: the stat being tracked */
  stat: LeaderboardStat;
  /** Paginate through the tiers in the leaderboard (each one represents a reward; can have many per each tier) */
  tiers: Maybe<TiersConnection>;
  /** 3/4 unique keys: the timeframe */
  timeframe: LeaderboardTimeframe;
  /** If part of a tournament... which? */
  tournament: Maybe<Tournament>;
  /** If part of a tournament */
  tournamentId: Maybe<Scalars['String']>;
};

export type LeaderboardSlotsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export type LeaderboardTiersArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

export enum LeaderboardEntityType {
  Furball = 'FURBALL',
  Player = 'PLAYER',
}

export type LeaderboardEntityTypeOperationFilterInput = {
  eq: Maybe<LeaderboardEntityType>;
  in: Maybe<Array<LeaderboardEntityType>>;
  neq: Maybe<LeaderboardEntityType>;
  nin: Maybe<Array<LeaderboardEntityType>>;
};

export type LeaderboardFilterInput = {
  /** The leaderboard is only active after this date */
  activeAfter: Maybe<DateTimeOperationFilterInput>;
  /** The leaderboard is only active before this date */
  activeBefore: Maybe<DateTimeOperationFilterInput>;
  and: Maybe<Array<LeaderboardFilterInput>>;
  /** 2/4 unique keys: the type of entity (player vs furball) */
  entityType: Maybe<LeaderboardEntityTypeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** 4/4 unique keys: a sub-group specific to context */
  leaderGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  or: Maybe<Array<LeaderboardFilterInput>>;
  /** DO NOT USE except for json loader */
  reward: Maybe<ListFilterInputTypeOfLeaderboardTierRewardsFilterInput>;
  /** Each time rewards are given, new slot & tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Maybe<IntOperationFilterInput>;
  /** 1/4 unique keys: the stat being tracked */
  stat: Maybe<LeaderboardStatOperationFilterInput>;
  /** 3/4 unique keys: the timeframe */
  timeframe: Maybe<LeaderboardTimeframeOperationFilterInput>;
  /** If part of a tournament... which? */
  tournament: Maybe<TournamentFilterInput>;
  /** If part of a tournament */
  tournamentId: Maybe<StringOperationFilterInput>;
};

export enum LeaderboardGroup {
  Everybody = 'EVERYBODY',
  PartySize1 = 'PARTY_SIZE1',
  PartySize3 = 'PARTY_SIZE3',
  PartySize5 = 'PARTY_SIZE5',
  PartySizePlus = 'PARTY_SIZE_PLUS',
}

export type LeaderboardGroupOperationFilterInput = {
  eq: Maybe<LeaderboardGroup>;
  in: Maybe<Array<LeaderboardGroup>>;
  neq: Maybe<LeaderboardGroup>;
  nin: Maybe<Array<LeaderboardGroup>>;
};

export type LeaderboardPosition =
  | LeaderboardSlotFurball
  | LeaderboardSlotPlayer;

export type LeaderboardRankFurball = {
  __typename?: 'LeaderboardRankFurball';
  /** Additional stored data about this rank */
  extraData: Scalars['String'];
  furball: Furball;
  furballId: Scalars['String'];
  id: Scalars['String'];
  slot: LeaderboardSlotFurball;
};

export type LeaderboardRankPlayer = {
  __typename?: 'LeaderboardRankPlayer';
  /** Additional stored data about this rank */
  extraData: Scalars['String'];
  id: Scalars['String'];
  playerId: Scalars['String'];
  slot: LeaderboardSlotPlayer;
};

export type LeaderboardSlot = {
  __typename?: 'LeaderboardSlot';
  createdAt: Maybe<Scalars['DateTime']>;
  entityType: LeaderboardEntityType;
  furballs: Array<Furball>;
  leaderGroup: LeaderboardGroup;
  leaderboardTier: Maybe<LeaderboardTier>;
  leaderboardTierId: Maybe<Scalars['String']>;
  overall: Scalars['Int'];
  players: Array<FurPlayer>;
  position: Scalars['Int'];
  score: Scalars['Long'];
  /** Each time rewards are given, new slot & tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Scalars['Int'];
  stat: LeaderboardStat;
  ties: Scalars['Int'];
  timeframe: LeaderboardTimeframe;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type LeaderboardSlotFurball = {
  __typename?: 'LeaderboardSlotFurball';
  createdAt: Maybe<Scalars['DateTime']>;
  entityType: LeaderboardEntityType;
  furballRanks: Array<LeaderboardRankFurball>;
  furballs: Array<Furball>;
  leaderGroup: LeaderboardGroup;
  leaderboardTier: Maybe<LeaderboardTier>;
  leaderboardTierId: Maybe<Scalars['String']>;
  overall: Scalars['Int'];
  players: Array<FurPlayer>;
  position: Scalars['Int'];
  score: Scalars['Long'];
  /** Each time rewards are given, new slot & tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Scalars['Int'];
  stat: LeaderboardStat;
  ties: Scalars['Int'];
  timeframe: LeaderboardTimeframe;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type LeaderboardSlotPlayer = {
  __typename?: 'LeaderboardSlotPlayer';
  createdAt: Maybe<Scalars['DateTime']>;
  entityType: LeaderboardEntityType;
  furballs: Array<Furball>;
  leaderGroup: LeaderboardGroup;
  leaderboardTier: Maybe<LeaderboardTier>;
  leaderboardTierId: Maybe<Scalars['String']>;
  overall: Scalars['Int'];
  playerRanks: Array<LeaderboardRankPlayer>;
  players: Array<FurPlayer>;
  position: Scalars['Int'];
  score: Scalars['Long'];
  /** Each time rewards are given, new slot & tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Scalars['Int'];
  stat: LeaderboardStat;
  ties: Scalars['Int'];
  timeframe: LeaderboardTimeframe;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum LeaderboardStat {
  Any = 'ANY',
  DamageSebastian = 'DAMAGE_SEBASTIAN',
  DamageSkullumbo = 'DAMAGE_SKULLUMBO',
  DamageTrashy = 'DAMAGE_TRASHY',
  ExpRate = 'EXP_RATE',
  FurRate = 'FUR_RATE',
  Level = 'LEVEL',
  Tournament = 'TOURNAMENT',
}

export type LeaderboardStatOperationFilterInput = {
  eq: Maybe<LeaderboardStat>;
  in: Maybe<Array<LeaderboardStat>>;
  neq: Maybe<LeaderboardStat>;
  nin: Maybe<Array<LeaderboardStat>>;
};

export type LeaderboardTier = {
  __typename?: 'LeaderboardTier';
  detachedLeaderboardId: Maybe<Scalars['String']>;
  /** How many entities are in this tier so far? (derived from slots) */
  entityCount: Scalars['Int'];
  /** How many entities can this tier hold? (derived from rewards) */
  entityMax: Scalars['Int'];
  id: Scalars['String'];
  /** [parent] The leaderboard to which the tier belongs */
  leaderboard: Maybe<Leaderboard>;
  leaderboardId: Maybe<Scalars['String']>;
  /** 0-indexed position within the leaderboard */
  position: Scalars['Int'];
  /** When this tier was given its rewards (if null, no rewards given yet) */
  rewardedAt: Maybe<Scalars['DateTime']>;
  /** What rewards are obtained from being in this tier? */
  rewards: LeaderboardTierRewards;
  /** The max score in any slot (derived from slots) */
  scoreMax: Scalars['Long'];
  /** The min score in any slot (derived from slots) */
  scoreMin: Scalars['Long'];
  /** Each time rewards are given, new tier objects are created with an incremented number (e.g., week number) */
  sequenceNumber: Scalars['Int'];
  /** The specific slots in this tier (each one representing a single score, and one or more entities) */
  slots: Array<LeaderboardSlot>;
};

export type LeaderboardTierRewardChance = {
  __typename?: 'LeaderboardTierRewardChance';
  compact: Scalars['String'];
  /** From 0-100, what is the percent chance of this drop? */
  percentChance: Scalars['Int'];
  /** The lootID, if an explicit loot drop (as opposed a RewardType that will be expanded later) */
  rewardId: Scalars['Long'];
  /** How many of this item */
  rewardQuantity: Scalars['Int'];
  /** What kind of reward, i.e., loot vs. mint */
  rewardType: TierRewardType;
};

export type LeaderboardTierRewardChanceFilterInput = {
  and: Maybe<Array<LeaderboardTierRewardChanceFilterInput>>;
  or: Maybe<Array<LeaderboardTierRewardChanceFilterInput>>;
  /** From 0-100, what is the percent chance of this drop? */
  percentChance: Maybe<IntOperationFilterInput>;
  /** The lootID, if an explicit loot drop (as opposed a RewardType that will be expanded later) */
  rewardId: Maybe<LongOperationFilterInput>;
  /** How many of this item */
  rewardQuantity: Maybe<IntOperationFilterInput>;
  /** What kind of reward, i.e., loot vs. mint */
  rewardType: Maybe<TierRewardTypeOperationFilterInput>;
};

export type LeaderboardTierRewardChanceInput = {
  /** From 0-100, what is the percent chance of this drop? */
  percentChance: Scalars['Int'];
  /** The lootID, if an explicit loot drop (as opposed a RewardType that will be expanded later) */
  rewardId: Scalars['Long'];
  /** How many of this item */
  rewardQuantity: Scalars['Int'];
  /** What kind of reward, i.e., loot vs. mint */
  rewardType: TierRewardType;
};

export type LeaderboardTierRewards = {
  __typename?: 'LeaderboardTierRewards';
  /** Reward: boss dust appropriate to the zone */
  bossDustAmount: Scalars['Long'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** How many entities (players) get this reward? */
  entityMax: Scalars['Int'];
  /** Reward: FUR */
  furAmount: Scalars['Long'];
  id: Scalars['String'];
  /** Different groups (team sizes) can get different rewards; which does this apply to? */
  leaderboardGroup: LeaderboardGroup;
  /** Different timeframes can get different rewards; which does this apply to? */
  leaderboardTimeframe: LeaderboardTimeframe;
  /** If there's a pool of rewards, how much does this tier get? */
  poolSplit: Scalars['Float'];
  possibleRewardList: Scalars['String'];
  /** Additional rewards (loot/mints) that might be dropped */
  possibleRewards: Array<LeaderboardTierRewardChance>;
  /** What LeaderboardTier.position does this apply to? */
  tierPosition: Scalars['Int'];
};

export type LeaderboardTierRewardsFilterInput = {
  and: Maybe<Array<LeaderboardTierRewardsFilterInput>>;
  /** Reward: boss dust appropriate to the zone */
  bossDustAmount: Maybe<LongOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** How many entities (players) get this reward? */
  entityMax: Maybe<IntOperationFilterInput>;
  /** Reward: FUR */
  furAmount: Maybe<LongOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** Different groups (team sizes) can get different rewards; which does this apply to? */
  leaderboardGroup: Maybe<LeaderboardGroupOperationFilterInput>;
  /** Different timeframes can get different rewards; which does this apply to? */
  leaderboardTimeframe: Maybe<LeaderboardTimeframeOperationFilterInput>;
  or: Maybe<Array<LeaderboardTierRewardsFilterInput>>;
  /** If there's a pool of rewards, how much does this tier get? */
  poolSplit: Maybe<FloatOperationFilterInput>;
  possibleRewardList: Maybe<StringOperationFilterInput>;
  /** Additional rewards (loot/mints) that might be dropped */
  possibleRewards: Maybe<ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput>;
  /** What LeaderboardTier.position does this apply to? */
  tierPosition: Maybe<IntOperationFilterInput>;
};

export enum LeaderboardTimeframe {
  AllTime = 'ALL_TIME',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Seasonal = 'SEASONAL',
  Weekly = 'WEEKLY',
}

export type LeaderboardTimeframeOperationFilterInput = {
  eq: Maybe<LeaderboardTimeframe>;
  in: Maybe<Array<LeaderboardTimeframe>>;
  neq: Maybe<LeaderboardTimeframe>;
  nin: Maybe<Array<LeaderboardTimeframe>>;
};

export type ListByteOperationFilterInput = {
  all: Maybe<ByteOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<ByteOperationFilterInput>;
  some: Maybe<ByteOperationFilterInput>;
};

export type ListCommunityFlagOperationFilterInput = {
  all: Maybe<CommunityFlagOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<CommunityFlagOperationFilterInput>;
  some: Maybe<CommunityFlagOperationFilterInput>;
};

export type ListFilterInputTypeOfBattleEffectPassiveFilterInput = {
  all: Maybe<BattleEffectPassiveFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<BattleEffectPassiveFilterInput>;
  some: Maybe<BattleEffectPassiveFilterInput>;
};

export type ListFilterInputTypeOfBattleMoveFilterInput = {
  all: Maybe<BattleMoveFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<BattleMoveFilterInput>;
  some: Maybe<BattleMoveFilterInput>;
};

export type ListFilterInputTypeOfBattleMoveOutcomeFilterInput = {
  all: Maybe<BattleMoveOutcomeFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<BattleMoveOutcomeFilterInput>;
  some: Maybe<BattleMoveOutcomeFilterInput>;
};

export type ListFilterInputTypeOfBlockchainTokenTransactionFilterInput = {
  all: Maybe<BlockchainTokenTransactionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<BlockchainTokenTransactionFilterInput>;
  some: Maybe<BlockchainTokenTransactionFilterInput>;
};

export type ListFilterInputTypeOfConsumableItemFilterInput = {
  all: Maybe<ConsumableItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<ConsumableItemFilterInput>;
  some: Maybe<ConsumableItemFilterInput>;
};

export type ListFilterInputTypeOfContainerItemFilterInput = {
  all: Maybe<ContainerItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<ContainerItemFilterInput>;
  some: Maybe<ContainerItemFilterInput>;
};

export type ListFilterInputTypeOfCraftRecipeIngredientFilterInput = {
  all: Maybe<CraftRecipeIngredientFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<CraftRecipeIngredientFilterInput>;
  some: Maybe<CraftRecipeIngredientFilterInput>;
};

export type ListFilterInputTypeOfCraftSlotFilterInput = {
  all: Maybe<CraftSlotFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<CraftSlotFilterInput>;
  some: Maybe<CraftSlotFilterInput>;
};

export type ListFilterInputTypeOfCraftingAttemptFilterInput = {
  all: Maybe<CraftingAttemptFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<CraftingAttemptFilterInput>;
  some: Maybe<CraftingAttemptFilterInput>;
};

export type ListFilterInputTypeOfEditionAssetFilterInput = {
  all: Maybe<EditionAssetFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<EditionAssetFilterInput>;
  some: Maybe<EditionAssetFilterInput>;
};

export type ListFilterInputTypeOfEntityEffectFilterInput = {
  all: Maybe<EntityEffectFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<EntityEffectFilterInput>;
  some: Maybe<EntityEffectFilterInput>;
};

export type ListFilterInputTypeOfEntitySkillFilterInput = {
  all: Maybe<EntitySkillFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<EntitySkillFilterInput>;
  some: Maybe<EntitySkillFilterInput>;
};

export type ListFilterInputTypeOfEquipmentItemFilterInput = {
  all: Maybe<EquipmentItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<EquipmentItemFilterInput>;
  some: Maybe<EquipmentItemFilterInput>;
};

export type ListFilterInputTypeOfFurPlayerFilterInput = {
  all: Maybe<FurPlayerFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurPlayerFilterInput>;
  some: Maybe<FurPlayerFilterInput>;
};

export type ListFilterInputTypeOfFurSessionFilterInput = {
  all: Maybe<FurSessionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurSessionFilterInput>;
  some: Maybe<FurSessionFilterInput>;
};

export type ListFilterInputTypeOfFurballAttributeFilterInput = {
  all: Maybe<FurballAttributeFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurballAttributeFilterInput>;
  some: Maybe<FurballAttributeFilterInput>;
};

export type ListFilterInputTypeOfFurballFilterInput = {
  all: Maybe<FurballFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurballFilterInput>;
  some: Maybe<FurballFilterInput>;
};

export type ListFilterInputTypeOfFurballSnackFilterInput = {
  all: Maybe<FurballSnackFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurballSnackFilterInput>;
  some: Maybe<FurballSnackFilterInput>;
};

export type ListFilterInputTypeOfFurballUpgradeFilterInput = {
  all: Maybe<FurballUpgradeFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurballUpgradeFilterInput>;
  some: Maybe<FurballUpgradeFilterInput>;
};

export type ListFilterInputTypeOfGameBattleBossFilterInput = {
  all: Maybe<GameBattleBossFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<GameBattleBossFilterInput>;
  some: Maybe<GameBattleBossFilterInput>;
};

export type ListFilterInputTypeOfGameBattleFilterInput = {
  all: Maybe<GameBattleFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<GameBattleFilterInput>;
  some: Maybe<GameBattleFilterInput>;
};

export type ListFilterInputTypeOfGameEntitySummaryFilterInput = {
  all: Maybe<GameEntitySummaryFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<GameEntitySummaryFilterInput>;
  some: Maybe<GameEntitySummaryFilterInput>;
};

export type ListFilterInputTypeOfGameItemFilterInput = {
  all: Maybe<GameItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<GameItemFilterInput>;
  some: Maybe<GameItemFilterInput>;
};

export type ListFilterInputTypeOfGamePartyFilterInput = {
  all: Maybe<GamePartyFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<GamePartyFilterInput>;
  some: Maybe<GamePartyFilterInput>;
};

export type ListFilterInputTypeOfHostTaskFilterInput = {
  all: Maybe<HostTaskFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<HostTaskFilterInput>;
  some: Maybe<HostTaskFilterInput>;
};

export type ListFilterInputTypeOfIBattleEffectFilterInput = {
  all: Maybe<IBattleEffectFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<IBattleEffectFilterInput>;
  some: Maybe<IBattleEffectFilterInput>;
};

export type ListFilterInputTypeOfInventoryItemFilterInput = {
  all: Maybe<InventoryItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<InventoryItemFilterInput>;
  some: Maybe<InventoryItemFilterInput>;
};

export type ListFilterInputTypeOfItemQuantityFilterInput = {
  all: Maybe<ItemQuantityFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<ItemQuantityFilterInput>;
  some: Maybe<ItemQuantityFilterInput>;
};

export type ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput =
  {
    all: Maybe<KeyValuePairOfStringAndAnimationAssetFilterInput>;
    any: Maybe<Scalars['Boolean']>;
    none: Maybe<KeyValuePairOfStringAndAnimationAssetFilterInput>;
    some: Maybe<KeyValuePairOfStringAndAnimationAssetFilterInput>;
  };

export type ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput = {
  all: Maybe<LeaderboardTierRewardChanceFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<LeaderboardTierRewardChanceFilterInput>;
  some: Maybe<LeaderboardTierRewardChanceFilterInput>;
};

export type ListFilterInputTypeOfLeaderboardTierRewardsFilterInput = {
  all: Maybe<LeaderboardTierRewardsFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<LeaderboardTierRewardsFilterInput>;
  some: Maybe<LeaderboardTierRewardsFilterInput>;
};

export type ListFilterInputTypeOfLootItemFilterInput = {
  all: Maybe<LootItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<LootItemFilterInput>;
  some: Maybe<LootItemFilterInput>;
};

export type ListFilterInputTypeOfMaterialItemFilterInput = {
  all: Maybe<MaterialItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<MaterialItemFilterInput>;
  some: Maybe<MaterialItemFilterInput>;
};

export type ListFilterInputTypeOfMonsterSkillDefinitionFilterInput = {
  all: Maybe<MonsterSkillDefinitionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<MonsterSkillDefinitionFilterInput>;
  some: Maybe<MonsterSkillDefinitionFilterInput>;
};

export type ListFilterInputTypeOfPassiveEffectRangeFilterInput = {
  all: Maybe<PassiveEffectRangeFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PassiveEffectRangeFilterInput>;
  some: Maybe<PassiveEffectRangeFilterInput>;
};

export type ListFilterInputTypeOfPlayerAnteFilterInput = {
  all: Maybe<PlayerAnteFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerAnteFilterInput>;
  some: Maybe<PlayerAnteFilterInput>;
};

export type ListFilterInputTypeOfPlayerHistoryDayFilterInput = {
  all: Maybe<PlayerHistoryDayFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerHistoryDayFilterInput>;
  some: Maybe<PlayerHistoryDayFilterInput>;
};

export type ListFilterInputTypeOfPlayerItemListingFilterInput = {
  all: Maybe<PlayerItemListingFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerItemListingFilterInput>;
  some: Maybe<PlayerItemListingFilterInput>;
};

export type ListFilterInputTypeOfPlayerOnboardingStepFilterInput = {
  all: Maybe<PlayerOnboardingStepFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerOnboardingStepFilterInput>;
  some: Maybe<PlayerOnboardingStepFilterInput>;
};

export type ListFilterInputTypeOfPlayerPayoutFilterInput = {
  all: Maybe<PlayerPayoutFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerPayoutFilterInput>;
  some: Maybe<PlayerPayoutFilterInput>;
};

export type ListFilterInputTypeOfPlayerPendingRewardFilterInput = {
  all: Maybe<PlayerPendingRewardFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<PlayerPendingRewardFilterInput>;
  some: Maybe<PlayerPendingRewardFilterInput>;
};

export type ListFilterInputTypeOfQuestFilterInput = {
  all: Maybe<QuestFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<QuestFilterInput>;
  some: Maybe<QuestFilterInput>;
};

export type ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput = {
  all: Maybe<QuestWaypointBracketDefinitionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<QuestWaypointBracketDefinitionFilterInput>;
  some: Maybe<QuestWaypointBracketDefinitionFilterInput>;
};

export type ListFilterInputTypeOfQuestWaypointCompletionFilterInput = {
  all: Maybe<QuestWaypointCompletionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<QuestWaypointCompletionFilterInput>;
  some: Maybe<QuestWaypointCompletionFilterInput>;
};

export type ListFilterInputTypeOfQuestWaypointDefinitionFilterInput = {
  all: Maybe<QuestWaypointDefinitionFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<QuestWaypointDefinitionFilterInput>;
  some: Maybe<QuestWaypointDefinitionFilterInput>;
};

export type ListFilterInputTypeOfRentalAgreementFilterInput = {
  all: Maybe<RentalAgreementFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<RentalAgreementFilterInput>;
  some: Maybe<RentalAgreementFilterInput>;
};

export type ListFilterInputTypeOfRentalTerminationFilterInput = {
  all: Maybe<RentalTerminationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<RentalTerminationFilterInput>;
  some: Maybe<RentalTerminationFilterInput>;
};

export type ListFilterInputTypeOfShopPurchaseFilterInput = {
  all: Maybe<ShopPurchaseFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<ShopPurchaseFilterInput>;
  some: Maybe<ShopPurchaseFilterInput>;
};

export type ListFilterInputTypeOfSkillStatEffectFilterInput = {
  all: Maybe<SkillStatEffectFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<SkillStatEffectFilterInput>;
  some: Maybe<SkillStatEffectFilterInput>;
};

export type ListFilterInputTypeOfSocialOAuthTokenFilterInput = {
  all: Maybe<SocialOAuthTokenFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<SocialOAuthTokenFilterInput>;
  some: Maybe<SocialOAuthTokenFilterInput>;
};

export type ListFilterInputTypeOfSocialProfileFilterInput = {
  all: Maybe<SocialProfileFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<SocialProfileFilterInput>;
  some: Maybe<SocialProfileFilterInput>;
};

export type ListFilterInputTypeOfSocialRoleFilterInput = {
  all: Maybe<SocialRoleFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<SocialRoleFilterInput>;
  some: Maybe<SocialRoleFilterInput>;
};

export type ListFilterInputTypeOfSpecialItemFilterInput = {
  all: Maybe<SpecialItemFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<SpecialItemFilterInput>;
  some: Maybe<SpecialItemFilterInput>;
};

export type ListFilterInputTypeOfStatEntityFilterInput = {
  all: Maybe<StatEntityFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<StatEntityFilterInput>;
  some: Maybe<StatEntityFilterInput>;
};

export type ListFilterInputTypeOfTournamentParticipantFilterInput = {
  all: Maybe<TournamentParticipantFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<TournamentParticipantFilterInput>;
  some: Maybe<TournamentParticipantFilterInput>;
};

export type ListFilterInputTypeOfVoyageEncounterFilterInput = {
  all: Maybe<VoyageEncounterFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<VoyageEncounterFilterInput>;
  some: Maybe<VoyageEncounterFilterInput>;
};

export type ListFilterInputTypeOfVoyageFilterInput = {
  all: Maybe<VoyageFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<VoyageFilterInput>;
  some: Maybe<VoyageFilterInput>;
};

export type ListFormationPositionOperationFilterInput = {
  all: Maybe<FormationPositionOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FormationPositionOperationFilterInput>;
  some: Maybe<FormationPositionOperationFilterInput>;
};

export type ListFurballSlotOperationFilterInput = {
  all: Maybe<FurballSlotOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<FurballSlotOperationFilterInput>;
  some: Maybe<FurballSlotOperationFilterInput>;
};

export type ListLongOperationFilterInput = {
  all: Maybe<LongOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<LongOperationFilterInput>;
  some: Maybe<LongOperationFilterInput>;
};

export type ListRealmTypeOperationFilterInput = {
  all: Maybe<RealmTypeOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<RealmTypeOperationFilterInput>;
  some: Maybe<RealmTypeOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all: Maybe<StringOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<StringOperationFilterInput>;
  some: Maybe<StringOperationFilterInput>;
};

export type ListWaitlistFlagOperationFilterInput = {
  all: Maybe<WaitlistFlagOperationFilterInput>;
  any: Maybe<Scalars['Boolean']>;
  none: Maybe<WaitlistFlagOperationFilterInput>;
  some: Maybe<WaitlistFlagOperationFilterInput>;
};

export enum ListingStyle {
  FixedPrice = 'FIXED_PRICE',
}

export type ListingStyleOperationFilterInput = {
  eq: Maybe<ListingStyle>;
  in: Maybe<Array<ListingStyle>>;
  neq: Maybe<ListingStyle>;
  nin: Maybe<Array<ListingStyle>>;
};

/** Group of logs sent by a client */
export type LogBundleInput = {
  entries: Array<LogEntryInput>;
};

/** A single log entry */
export type LogEntryInput = {
  context: Scalars['String'];
  exception: Maybe<FurExceptionInput>;
  logLevel: LogLevel;
  messages: Array<Scalars['String']>;
  timestamp: Scalars['DateTime'];
};

export enum LogLevel {
  Debug = 'DEBUG',
  Error = 'ERROR',
  Info = 'INFO',
  Verbose = 'VERBOSE',
  Warn = 'WARN',
}

export type LoginEmailResponse = {
  __typename?: 'LoginEmailResponse';
  currentPlayer: Maybe<FurPlayer>;
  outcome: LoginOutcome;
  secret: Maybe<Scalars['String']>;
  session: Maybe<FurSession>;
};

export enum LoginOutcome {
  Failure = 'FAILURE',
  Success = 'SUCCESS',
  Validating = 'VALIDATING',
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  secret: Scalars['String'];
  session: FurSession;
};

export type LongOperationFilterInput = {
  eq: Maybe<Scalars['Long']>;
  gt: Maybe<Scalars['Long']>;
  gte: Maybe<Scalars['Long']>;
  in: Maybe<Array<Maybe<Scalars['Long']>>>;
  lt: Maybe<Scalars['Long']>;
  lte: Maybe<Scalars['Long']>;
  neq: Maybe<Scalars['Long']>;
  ngt: Maybe<Scalars['Long']>;
  ngte: Maybe<Scalars['Long']>;
  nin: Maybe<Array<Maybe<Scalars['Long']>>>;
  nlt: Maybe<Scalars['Long']>;
  nlte: Maybe<Scalars['Long']>;
};

export type LootBox = {
  __typename?: 'LootBox';
  myLastPurchaseAt: Maybe<Scalars['DateTime']>;
  possibleRewards: Array<LeaderboardTierRewardChance>;
  totalChance: Scalars['Int'];
  with: LootBox;
};

export type LootBoxWithArgs = {
  chance: LeaderboardTierRewardChanceInput;
};

export type LootItem = {
  __typename?: 'LootItem';
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  /** A top level grouping derived from the ItemId */
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  /** How many items can be stacked? */
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
};

export type LootItemFilterInput = {
  and: Maybe<Array<LootItemFilterInput>>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** How many items can be stacked? */
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<LootItemFilterInput>>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

export type MarketListing = {
  __typename?: 'MarketListing';
  amount: Scalars['Long'];
  contractAddress: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  deListed: Scalars['Boolean'];
  deListedAt: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  listedAt: Scalars['DateTime'];
  marketplace: MarketplaceName;
  price: Scalars['Decimal'];
  schema: TokenSchema;
  tokenId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum MarketplaceName {
  OpenSea = 'OPEN_SEA',
}

/** Represents crafting materials, which are consumed during an E2 crafting */
export type MaterialItem = {
  __typename?: 'MaterialItem';
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  /** How many items can be stacked? */
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
};

/** Represents crafting materials, which are consumed during an E2 crafting */
export type MaterialItemFilterInput = {
  and: Maybe<Array<MaterialItemFilterInput>>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** How many items can be stacked? */
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<MaterialItemFilterInput>>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

/** A connection to a list of items. */
export type MonsterBattlesConnection = {
  __typename?: 'MonsterBattlesConnection';
  /** A list of edges. */
  edges: Maybe<Array<MonsterBattlesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<GameBattleMonsters>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MonsterBattlesEdge = {
  __typename?: 'MonsterBattlesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: GameBattleMonsters;
};

export type MonsterDefinition = {
  __typename?: 'MonsterDefinition';
  aggressivenessModifier: Scalars['Int'];
  animationCollection: EntityAnimationCollection;
  baseStats: BattleStats;
  maxLevel: Scalars['Int'];
  minLevel: Scalars['Int'];
  name: Scalars['String'];
  realmAffiliation: RealmType;
  skills: Array<MonsterSkillDefinition>;
  slug: Scalars['String'];
};

export type MonsterDefinitionFilterInput = {
  aggressivenessModifier: Maybe<IntOperationFilterInput>;
  and: Maybe<Array<MonsterDefinitionFilterInput>>;
  animationCollection: Maybe<EntityAnimationCollectionFilterInput>;
  baseStats: Maybe<BattleStatsFilterInput>;
  maxLevel: Maybe<IntOperationFilterInput>;
  minLevel: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<MonsterDefinitionFilterInput>>;
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  skills: Maybe<ListFilterInputTypeOfMonsterSkillDefinitionFilterInput>;
  slug: Maybe<StringOperationFilterInput>;
};

export type MonsterDefinitionSortInput = {
  aggressivenessModifier: Maybe<SortEnumType>;
  animationCollection: Maybe<EntityAnimationCollectionSortInput>;
  baseStats: Maybe<BattleStatsSortInput>;
  maxLevel: Maybe<SortEnumType>;
  minLevel: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  realmAffiliation: Maybe<SortEnumType>;
  slug: Maybe<SortEnumType>;
};

export type MonsterSkillDefinition = {
  __typename?: 'MonsterSkillDefinition';
  frequency: UsageFrequency;
  rules: Maybe<TargetingRule>;
  skillId: Scalars['Long'];
  targeting: Maybe<TargetingStrategy>;
};

export type MonsterSkillDefinitionFilterInput = {
  and: Maybe<Array<MonsterSkillDefinitionFilterInput>>;
  frequency: Maybe<UsageFrequencyOperationFilterInput>;
  or: Maybe<Array<MonsterSkillDefinitionFilterInput>>;
  rules: Maybe<NullableOfTargetingRuleOperationFilterInput>;
  skillId: Maybe<LongOperationFilterInput>;
  targeting: Maybe<NullableOfTargetingStrategyOperationFilterInput>;
};

export type MoveItemResult = {
  __typename?: 'MoveItemResult';
  fromFurball: Maybe<Furball>;
  fromInventory: ContainerItem;
  item: GameItem;
  player: FurPlayer;
  toFurball: Maybe<Furball>;
};

export type MoveZoneRequestInput = {
  dryRun: Maybe<Scalars['Boolean']>;
  realm: Maybe<RealmType>;
  snackIds: Maybe<Array<Scalars['Long']>>;
  tokenId: Scalars['String'];
  zone: Scalars['Int'];
};

export type MoveZoneResponse = {
  __typename?: 'MoveZoneResponse';
  expGained: Scalars['Int'];
  furGained: Scalars['Long'];
  furSpent: Scalars['Long'];
  furball: Furball;
  happiness: Scalars['Int'];
  intervals: Scalars['Float'];
  items: Array<GameItem>;
  levelsGained: Scalars['Int'];
};

/** A connection to a list of items. */
export type MovesConnection = {
  __typename?: 'MovesConnection';
  /** A list of edges. */
  edges: Maybe<Array<MovesEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<BattleMove>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type MovesEdge = {
  __typename?: 'MovesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BattleMove;
};

export type Mutation = {
  __typename?: 'Mutation';
  advanceBattle: BattleMoveResponse;
  approveAirdrops: Scalars['Boolean'];
  bidAuction: DutchAuctionResult;
  bossAdvanceBattle: BattleMoveResponse;
  bossClaimReward: GameBattleBoss;
  bossForfeitBattle: GameBattleBoss;
  bossJoinBattle: GameBattleBoss;
  buyBossKeys: PlayerPurchase;
  buyItemListing: PlayerItemListing;
  buyLootBox: PlayerPendingReward;
  cancelItemListing: PlayerItemListing;
  changeUsername: IFurPlayer;
  claimPendingReward: FurPlayer;
  claimRentalAgreement: RentalAgreement;
  cloneTournament: Tournament;
  completeOnboardingStep: IFurPlayer;
  /** Craft an elixir (instant) using any items the player wants */
  craftElixir: ConsumableItem;
  craftFaster: CraftSlot;
  craftRecipe: CraftSlot;
  createDuelBattle: GameBattleDuel;
  createItemListing: PlayerItemListing;
  createRandomParty: RandomParty;
  createRentalAgreement: RentalAgreement;
  createTournament: Tournament;
  disconnectSocialProfile: FurAccount;
  editItemListing: PlayerItemListing;
  editRentalAgreement: RentalAgreement;
  endRentalAgreement: RentalAgreement;
  equipFurball: Furball;
  fixListings: Array<PlayerItemListing>;
  forfeitBattle: GameBattle;
  fundFur: FurFundingLot;
  /** [TIMED] background loop */
  gameLoopRun: Array<GameBattle>;
  giftAccountsTix: Array<BlockchainTokenTransaction>;
  giveLeaderboardReward: Array<PlayerPendingReward>;
  giveLeaderboardRewards: Array<PlayerPendingReward>;
  givePendingReward: PlayerPendingReward;
  /** [TIMED] heartbeat */
  heartbeat: HostNode;
  joinBattle: JoinBattleResponse;
  joinBossBattle: GameBattleBoss;
  joinDuelBattle: GameBattleDuel;
  joinMonstersBattle: GameBattleMonsters;
  joinTournament: Tournament;
  joinWaitlist: IFurPlayer;
  /** [TIMED] background loop */
  lockedRun: Maybe<BlockWork>;
  login: LoginResponse;
  loginAppleCallback: LoginResponse;
  loginDiscordCallback: LoginResponse;
  loginEmail: LoginEmailResponse;
  loginEmailCallback: LoginResponse;
  modifyFurball: FurballModification;
  moveItem: MoveItemResult;
  moveZones: Array<MoveZoneResponse>;
  /** Upload one or more log entries at once, writing them to the main pipeline. */
  pipeLogs: Scalars['Boolean'];
  /** System task that processes moves in a game battle */
  processGameBattle: GameBattle;
  purchaseFur: FurPurchasePlayer;
  readNotifications: Array<Notification>;
  refillHearts: GameParty;
  renameFurballAccept: FurballNameChange;
  requestAirdrop: AirdropRequest;
  restart: Array<HostNode>;
  rollSkill: Furball;
  /** System task to run a specific background task ID */
  runHostTask: HostTask;
  sanityCheckFurballs: Array<Furball>;
  sendNotification: Notification;
  setParty: GameParty;
  setTournamentRewards: Tournament;
  specializeFurball: Furball;
  submitVoyageEncounter: VoyageEncounter;
  takePayout: FurPlayer;
  /** [TIMED] background loop */
  tasksRun: Array<HostTask>;
  timekeeperExecuteAdHoc: TimekeeperAdHoc;
  timekeeperReComputeExp: Maybe<TimekeeperRound>;
  timekeeperSaveAction: Array<TimekeeperRequest>;
  timekeeperSaveAdHoc: TimekeeperAdHoc;
  timekeeperSignRequest: TimekeeperRequest;
  timekeeperToggleAccept: TimekeeperToggle;
  timekeeperToggleCompute: TimekeeperToggle;
  timekeeperTransferTixAccept: TimekeeperTransferTix;
  timekeeperTransferTixCompute: TimekeeperTransferTix;
  upgradeFurball: Furball;
  upgradeLoot: FurballLootUpgradeResponse;
  upgradeSkill: Furball;
  verifyReceipt: FurPlayer;
  wrapFur: TimekeeperWrapFur;
  wrapFurAccept: TimekeeperWrapFur;
  wrapFurCompute: TimekeeperWrapFur;
};

export type MutationAdvanceBattleArgs = {
  id: Scalars['String'];
  move: Maybe<BattleMoveRequestInput>;
  setState: Maybe<GameBattleState>;
};

export type MutationApproveAirdropsArgs = {
  args: Array<Scalars['String']>;
  signatureToken: FurOAuthTokenInput;
};

export type MutationBossAdvanceBattleArgs = {
  id: Scalars['String'];
  move: Maybe<BattleMoveRequestInput>;
  setState: Maybe<GameBattleState>;
};

export type MutationBossClaimRewardArgs = {
  choice: Scalars['Int'];
  id: Scalars['String'];
};

export type MutationBossForfeitBattleArgs = {
  id: Scalars['String'];
};

export type MutationBossJoinBattleArgs = {
  partyId?: Scalars['String'];
  playerId?: Scalars['String'];
  realmType: RealmType;
  trialGame?: Scalars['Boolean'];
};

export type MutationBuyBossKeysArgs = {
  furballIds: Array<Scalars['String']>;
  realms: Array<RealmType>;
};

export type MutationBuyItemListingArgs = {
  listingId: Scalars['String'];
};

export type MutationBuyLootBoxArgs = {
  useTix: Scalars['Boolean'];
};

export type MutationCancelItemListingArgs = {
  listingId: Scalars['String'];
};

export type MutationChangeUsernameArgs = {
  username: Scalars['String'];
};

export type MutationClaimPendingRewardArgs = {
  pendingRewardId: Scalars['String'];
};

export type MutationClaimRentalAgreementArgs = {
  rentalAgreementId: Scalars['String'];
};

export type MutationCloneTournamentArgs = {
  duration: Maybe<Scalars['TimeSpan']>;
  fuelFee: Maybe<Scalars['Long']>;
  id: Scalars['String'];
  scoringStyle: Maybe<TournamentScoringStyle>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type MutationCompleteOnboardingStepArgs = {
  isComplete: Scalars['Boolean'];
  sequence: OnboardingSequenceType;
  stepProgress: Scalars['Int'];
};

export type MutationCraftElixirArgs = {
  itemIds: Array<Scalars['String']>;
};

export type MutationCraftFasterArgs = {
  craftingAttemptId: Scalars['String'];
  numHours: Scalars['Int'];
};

export type MutationCraftRecipeArgs = {
  recipeId: Maybe<Scalars['String']>;
  slotIndex?: Scalars['Int'];
  unlockSlot?: Scalars['Boolean'];
  useTix?: Scalars['Boolean'];
};

export type MutationCreateDuelBattleArgs = {
  ante: Maybe<AnteInput>;
  partyId?: Scalars['String'];
  rules: Maybe<GamePlayRulesInput>;
};

export type MutationCreateItemListingArgs = {
  expiry: Maybe<Scalars['DateTime']>;
  itemId: Scalars['String'];
  price: Scalars['UnsignedLong'];
  quantity?: Scalars['UnsignedInt'];
  reservedPlayerId: Maybe<Scalars['String']>;
};

export type MutationCreateRentalAgreementArgs = {
  terms: RentalTermsInput;
};

export type MutationCreateTournamentArgs = {
  backgroundColor: Maybe<Scalars['String']>;
  endsAt: Scalars['DateTime'];
  fuelFee?: Scalars['Long'];
  heroUrl: Scalars['String'];
  name: Scalars['String'];
  positions: Array<TournamentRewardArgsInput>;
  realm: Maybe<RealmType>;
  scoringStyle: Maybe<TournamentScoringStyle>;
  startsAt: Scalars['DateTime'];
};

export type MutationDisconnectSocialProfileArgs = {
  token: FurOAuthTokenInput;
};

export type MutationEditItemListingArgs = {
  listingId: Scalars['String'];
  price: Scalars['UnsignedLong'];
};

export type MutationEditRentalAgreementArgs = {
  rentalAgreementId: Scalars['String'];
  terms: RentalTermsInput;
};

export type MutationEndRentalAgreementArgs = {
  rentalAgreementId: Scalars['String'];
};

export type MutationEquipFurballArgs = {
  furballId: Scalars['String'];
  itemIds: Array<Maybe<Scalars['String']>>;
};

export type MutationForfeitBattleArgs = {
  id: Scalars['String'];
};

export type MutationFundFurArgs = {
  pricePer: Scalars['Float'];
  quantity: Scalars['Long'];
};

export type MutationGameLoopRunArgs = {
  deltaTime: Scalars['Long'];
};

export type MutationGiftAccountsTixArgs = {
  reason: Scalars['String'];
  tixCount: Scalars['UnsignedLong'];
  walletIds: Array<Scalars['String']>;
};

export type MutationGiveLeaderboardRewardArgs = {
  leaderboardId: Scalars['String'];
  playerId: Maybe<Scalars['String']>;
  tierIndex?: Scalars['Int'];
};

export type MutationGiveLeaderboardRewardsArgs = {
  bypassRewarded: Scalars['Boolean'];
  leaderboardId: Scalars['String'];
  seqNum: Scalars['Int'];
  skipDust: Scalars['Boolean'];
  tierPositions: Array<Scalars['Int']>;
};

export type MutationGivePendingRewardArgs = {
  notificationTitle: Maybe<Scalars['String']>;
  playerId: Maybe<Scalars['String']>;
  rewards: Array<PendingRewardRequestInput>;
  source?: RewardSource;
  sourceId: Maybe<Scalars['String']>;
  sourcePosition: Maybe<Scalars['Int']>;
};

export type MutationHeartbeatArgs = {
  deltaTime: Scalars['Long'];
};

export type MutationJoinBattleArgs = {
  partyId?: Scalars['String'];
  realmType: RealmType;
};

export type MutationJoinBossBattleArgs = {
  partyId?: Scalars['String'];
  playerId?: Scalars['String'];
  realmType: RealmType;
  trialGame?: Scalars['Boolean'];
};

export type MutationJoinDuelBattleArgs = {
  gameBattleId: Scalars['String'];
  partyId?: Scalars['String'];
};

export type MutationJoinMonstersBattleArgs = {
  level: Maybe<Scalars['Int']>;
  partyId?: Scalars['String'];
  realmType: RealmType;
};

export type MutationJoinTournamentArgs = {
  tournamentId: Scalars['String'];
};

export type MutationJoinWaitlistArgs = {
  playerId: Scalars['String'];
  waitlist?: WaitlistFlag;
};

export type MutationLockedRunArgs = {
  deltaTime: Scalars['Long'];
};

export type MutationLoginArgs = {
  token: FurOAuthTokenInput;
};

export type MutationLoginAppleCallbackArgs = {
  dto: AppleOAuthDtoInput;
  referrerId: Maybe<Scalars['String']>;
  walletAddr: Maybe<Scalars['String']>;
};

export type MutationLoginDiscordCallbackArgs = {
  code: Scalars['String'];
  referrerId: Maybe<Scalars['String']>;
  walletAddr: Maybe<Scalars['String']>;
};

export type MutationLoginEmailArgs = {
  code: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Maybe<Scalars['String']>;
};

export type MutationLoginEmailCallbackArgs = {
  code: Scalars['String'];
  playerId: Scalars['String'];
};

export type MutationModifyFurballArgs = {
  args: FurballModificationArgsInput;
  signatureToken: FurOAuthTokenInput;
};

export type MutationMoveItemArgs = {
  itemId: Scalars['String'];
  quantity: Maybe<Scalars['UnsignedInt']>;
  toFurballId: Maybe<Scalars['String']>;
};

export type MutationMoveZonesArgs = {
  requests: Array<MoveZoneRequestInput>;
};

export type MutationPipeLogsArgs = {
  logBundle: LogBundleInput;
};

export type MutationProcessGameBattleArgs = {
  gameBattleId: Scalars['String'];
  gameBattleType: Maybe<Scalars['String']>;
};

export type MutationPurchaseFurArgs = {
  fuel: Scalars['Long'];
};

export type MutationReadNotificationsArgs = {
  notificationIds: Array<Scalars['String']>;
};

export type MutationRefillHeartsArgs = {
  partyId: Scalars['String'];
};

export type MutationRenameFurballAcceptArgs = {
  approve: Scalars['Boolean'];
  renameId: Scalars['String'];
};

export type MutationRequestAirdropArgs = {
  args: AirdropRequestArgsInput;
  signatureToken: FurOAuthTokenInput;
};

export type MutationRollSkillArgs = {
  furballId: Scalars['String'];
  slotIndex?: Scalars['Int'];
};

export type MutationRunHostTaskArgs = {
  taskId: Scalars['String'];
};

export type MutationSanityCheckFurballsArgs = {
  furballIds: Maybe<Array<Scalars['String']>>;
  limit?: Scalars['Int'];
  playerId: Maybe<Scalars['String']>;
};

export type MutationSendNotificationArgs = {
  desc: Maybe<Scalars['String']>;
  playerId: Maybe<Scalars['String']>;
  thumb: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MutationSetPartyArgs = {
  args: SetGamePartyInput;
};

export type MutationSetTournamentRewardsArgs = {
  positions: Array<TournamentRewardArgsInput>;
  tournamentId: Scalars['String'];
};

export type MutationSpecializeFurballArgs = {
  furballId: Scalars['String'];
  renameArgs: Maybe<RenameFurballArgsInput>;
  renameSignature: Maybe<Scalars['String']>;
  specialization: Specialization;
};

export type MutationSubmitVoyageEncounterArgs = {
  complete: Scalars['Boolean'];
  decision: Scalars['Int'];
  tokenId: Scalars['String'];
};

export type MutationTakePayoutArgs = {
  id: Scalars['String'];
  method: PayoutMethod;
};

export type MutationTasksRunArgs = {
  deltaTime: Scalars['Long'];
};

export type MutationTimekeeperExecuteAdHocArgs = {
  id: Scalars['String'];
};

export type MutationTimekeeperReComputeExpArgs = {
  date: Scalars['Int'];
};

export type MutationTimekeeperSaveActionArgs = {
  action: TimekeeperInteractionArgInput;
};

export type MutationTimekeeperSaveAdHocArgs = {
  action: TimekeeperAdHocArgsInput;
  signature: Scalars['String'];
};

export type MutationTimekeeperSignRequestArgs = {
  changes: TimekeeperArgsInput;
  partialIndex: Scalars['Int'];
  signature: Scalars['String'];
};

export type MutationTimekeeperToggleAcceptArgs = {
  id: Scalars['String'];
  signature: Scalars['String'];
};

export type MutationTimekeeperToggleComputeArgs = {
  args: TimekeeperToggleArgsInput;
};

export type MutationTimekeeperTransferTixAcceptArgs = {
  id: Scalars['String'];
  signature: Scalars['String'];
};

export type MutationTimekeeperTransferTixComputeArgs = {
  args: TimekeeperTransferTixArgsInput;
};

export type MutationUpgradeFurballArgs = {
  furballId: Scalars['String'];
  signature: Maybe<Scalars['String']>;
};

export type MutationUpgradeLootArgs = {
  chances: Scalars['Int'];
  furballId: Maybe<Scalars['String']>;
  itemId: Scalars['String'];
};

export type MutationUpgradeSkillArgs = {
  furballId: Scalars['String'];
  levelPartials: Scalars['Int'];
  maxUsesPartials: Scalars['Int'];
  skillId: Scalars['String'];
};

export type MutationVerifyReceiptArgs = {
  platform: Scalars['String'];
  receiptData: Scalars['String'];
};

export type MutationWrapFurArgs = {
  args: TimekeeperWrapFurArgsInput;
  signature: Scalars['String'];
};

export type MutationWrapFurAcceptArgs = {
  id: Scalars['String'];
  signature: Scalars['String'];
};

export type MutationWrapFurComputeArgs = {
  args: TimekeeperWrapFurArgsInput;
};

export type Notification = {
  __typename?: 'Notification';
  /** Hardcoded true for now. Flag that may enforce message permanence. */
  canBeDismissed: Scalars['Boolean'];
  createdAt: Maybe<Scalars['DateTime']>;
  data: Maybe<Scalars['String']>;
  /** Payload */
  description: Maybe<Scalars['String']>;
  /** [LL] the furball associated with the notification */
  furball: Maybe<Furball>;
  furballId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Payload */
  imageUrl: Maybe<Scalars['String']>;
  /** Derived from PlayerId == null */
  isGlobal: Scalars['Boolean'];
  /** Payload */
  message: Maybe<Scalars['String']>;
  /** [LL] the various actual notifications to send */
  notificationInstances: Array<NotificationInstance>;
  /** [LL] the reward associated with the notification */
  pendingReward: Maybe<PlayerPendingReward>;
  /** Aka recipient; NULL=everybody */
  player: Maybe<FurPlayer>;
  /** When PlayerId is NULL, it is a system-wide notification. */
  playerId: Maybe<Scalars['String']>;
  /** functional grouping -- the general reason this notification exists */
  purpose: NotificationPurpose;
  readAt: Maybe<Scalars['DateTime']>;
  /** Accessor for PlayerId */
  recipient: Maybe<Scalars['String']>;
  sendCompleteAt: Maybe<Scalars['DateTime']>;
  /** If some other player initiated the notification... */
  sender: Maybe<Scalars['String']>;
  /** Payload */
  thumbnailUrl: Maybe<Scalars['String']>;
  /** Payload */
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type NotificationFilterInput = {
  and: Maybe<Array<NotificationFilterInput>>;
  /** Hardcoded true for now. Flag that may enforce message permanence. */
  canBeDismissed: Maybe<BooleanOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  data: Maybe<StringOperationFilterInput>;
  /** Payload */
  description: Maybe<StringOperationFilterInput>;
  furballId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** Payload */
  imageUrl: Maybe<StringOperationFilterInput>;
  /** Derived from PlayerId == null */
  isGlobal: Maybe<BooleanOperationFilterInput>;
  /** Payload */
  message: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<NotificationFilterInput>>;
  /** Aka recipient; NULL=everybody */
  player: Maybe<FurPlayerFilterInput>;
  /** When PlayerId is NULL, it is a system-wide notification. */
  playerId: Maybe<StringOperationFilterInput>;
  /** functional grouping -- the general reason this notification exists */
  purpose: Maybe<NotificationPurposeOperationFilterInput>;
  readAt: Maybe<DateTimeOperationFilterInput>;
  /** Accessor for PlayerId */
  recipient: Maybe<StringOperationFilterInput>;
  sendCompleteAt: Maybe<DateTimeOperationFilterInput>;
  /** If some other player initiated the notification... */
  sender: Maybe<StringOperationFilterInput>;
  /** Payload */
  thumbnailUrl: Maybe<StringOperationFilterInput>;
  /** Payload */
  title: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type NotificationInstance = {
  __typename?: 'NotificationInstance';
  /** If going to a specific channel in this destination */
  channelId: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** If sent, the ID of the msg result on the recipient network/whatever */
  destinationId: Maybe<Scalars['String']>;
  /** ID */
  id: Scalars['String'];
  /** Parent to which it belongs */
  notification: Notification;
  notificationId: Scalars['String'];
  /** The notification platform, i.e., technology thru which it is sent */
  platform: NotificationPlatform;
  /** The current state of the notification */
  receipt: ReceiptState;
  /** Some identifier of whom is to be notified on this platform (local userID?) */
  recipientId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum NotificationPlatform {
  Discord = 'DISCORD',
  NumPlatforms = 'NUM_PLATFORMS',
  Unknown = 'UNKNOWN',
  Web = 'WEB',
}

export enum NotificationPurpose {
  DilemmaMinigame = 'DILEMMA_MINIGAME',
  Listing = 'LISTING',
  Loot = 'LOOT',
  PendingReward = 'PENDING_REWARD',
  Rental = 'RENTAL',
  Timekeeper = 'TIMEKEEPER',
  Unknown = 'UNKNOWN',
}

export type NotificationPurposeOperationFilterInput = {
  eq: Maybe<NotificationPurpose>;
  in: Maybe<Array<NotificationPurpose>>;
  neq: Maybe<NotificationPurpose>;
  nin: Maybe<Array<NotificationPurpose>>;
};

export type NotificationSortInput = {
  /** Hardcoded true for now. Flag that may enforce message permanence. */
  canBeDismissed: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  data: Maybe<SortEnumType>;
  /** Payload */
  description: Maybe<SortEnumType>;
  furballId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** Payload */
  imageUrl: Maybe<SortEnumType>;
  /** Derived from PlayerId == null */
  isGlobal: Maybe<SortEnumType>;
  /** Payload */
  message: Maybe<SortEnumType>;
  /** Aka recipient; NULL=everybody */
  player: Maybe<FurPlayerSortInput>;
  /** When PlayerId is NULL, it is a system-wide notification. */
  playerId: Maybe<SortEnumType>;
  /** functional grouping -- the general reason this notification exists */
  purpose: Maybe<SortEnumType>;
  readAt: Maybe<SortEnumType>;
  /** Accessor for PlayerId */
  recipient: Maybe<SortEnumType>;
  sendCompleteAt: Maybe<SortEnumType>;
  /** If some other player initiated the notification... */
  sender: Maybe<SortEnumType>;
  /** Payload */
  thumbnailUrl: Maybe<SortEnumType>;
  /** Payload */
  title: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type NotificationsConnection = {
  __typename?: 'NotificationsConnection';
  /** A list of edges. */
  edges: Maybe<Array<NotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type NotificationsEdge = {
  __typename?: 'NotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Notification;
};

export type NullableOfCommunityFlagOperationFilterInput = {
  eq: Maybe<CommunityFlag>;
  in: Maybe<Array<Maybe<CommunityFlag>>>;
  neq: Maybe<CommunityFlag>;
  nin: Maybe<Array<Maybe<CommunityFlag>>>;
};

export type NullableOfRarityLevelOperationFilterInput = {
  eq: Maybe<RarityLevel>;
  in: Maybe<Array<Maybe<RarityLevel>>>;
  neq: Maybe<RarityLevel>;
  nin: Maybe<Array<Maybe<RarityLevel>>>;
};

export type NullableOfRealmTypeOperationFilterInput = {
  eq: Maybe<RealmType>;
  in: Maybe<Array<Maybe<RealmType>>>;
  neq: Maybe<RealmType>;
  nin: Maybe<Array<Maybe<RealmType>>>;
};

export type NullableOfTargetingRuleOperationFilterInput = {
  eq: Maybe<TargetingRule>;
  in: Maybe<Array<Maybe<TargetingRule>>>;
  neq: Maybe<TargetingRule>;
  nin: Maybe<Array<Maybe<TargetingRule>>>;
};

export type NullableOfTargetingStrategyOperationFilterInput = {
  eq: Maybe<TargetingStrategy>;
  in: Maybe<Array<Maybe<TargetingStrategy>>>;
  neq: Maybe<TargetingStrategy>;
  nin: Maybe<Array<Maybe<TargetingStrategy>>>;
};

export type NullableOfUsageFrequencyOperationFilterInput = {
  eq: Maybe<UsageFrequency>;
  in: Maybe<Array<Maybe<UsageFrequency>>>;
  neq: Maybe<UsageFrequency>;
  nin: Maybe<Array<Maybe<UsageFrequency>>>;
};

export enum OnboardingSequenceType {
  BossFight = 'BOSS_FIGHT',
  Cauldron = 'CAULDRON',
  FirstBattle = 'FIRST_BATTLE',
  FirstLogin = 'FIRST_LOGIN',
  MonsterMap = 'MONSTER_MAP',
  Reset = 'RESET',
  RewardsScreen = 'REWARDS_SCREEN',
  SecondBattle = 'SECOND_BATTLE',
  Unknown = 'UNKNOWN',
  WorldMap = 'WORLD_MAP',
}

export type OnboardingSequenceTypeOperationFilterInput = {
  eq: Maybe<OnboardingSequenceType>;
  in: Maybe<Array<OnboardingSequenceType>>;
  neq: Maybe<OnboardingSequenceType>;
  nin: Maybe<Array<OnboardingSequenceType>>;
};

export enum OutcomeFlag {
  Crit = 'CRIT',
  CritKillshot = 'CRIT_KILLSHOT',
  CritKillshotRevive = 'CRIT_KILLSHOT_REVIVE',
  Killshot = 'KILLSHOT',
  KillshotRevive = 'KILLSHOT_REVIVE',
  None = 'NONE',
  Revive = 'REVIVE',
}

export type OutcomeFlagOperationFilterInput = {
  eq: Maybe<OutcomeFlag>;
  in: Maybe<Array<OutcomeFlag>>;
  neq: Maybe<OutcomeFlag>;
  nin: Maybe<Array<OutcomeFlag>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PassiveEffectRange = {
  __typename?: 'PassiveEffectRange';
  operand: EntityEffectOperand;
  stat: StatEffectTarget;
  toPassive: BattleEffectPassive;
  valueBase: Scalars['Int'];
  valueRange: Scalars['Int'];
};

export type PassiveEffectRangeToPassiveArgs = {
  id: Scalars['String'];
};

export type PassiveEffectRangeFilterInput = {
  and: Maybe<Array<PassiveEffectRangeFilterInput>>;
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<PassiveEffectRangeFilterInput>>;
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  valueBase: Maybe<IntOperationFilterInput>;
  valueRange: Maybe<IntOperationFilterInput>;
};

export enum PayoutMethod {
  Eth = 'ETH',
  Tix = 'TIX',
  Undecided = 'UNDECIDED',
}

export type PayoutMethodOperationFilterInput = {
  eq: Maybe<PayoutMethod>;
  in: Maybe<Array<PayoutMethod>>;
  neq: Maybe<PayoutMethod>;
  nin: Maybe<Array<PayoutMethod>>;
};

/** A connection to a list of items. */
export type PendingFurWrapsConnection = {
  __typename?: 'PendingFurWrapsConnection';
  /** A list of edges. */
  edges: Maybe<Array<PendingFurWrapsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<TimekeeperWrapFur>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PendingFurWrapsEdge = {
  __typename?: 'PendingFurWrapsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TimekeeperWrapFur;
};

export type PendingRewardRequestInput = {
  /** What item to give? */
  itemId: Scalars['Long'];
  /** How many to give? */
  stack: Scalars['Int'];
};

export type PlayerAnte = {
  __typename?: 'PlayerAnte';
  /** [DB} the ante object */
  ante: AntePool;
  /** [DB] */
  anteId: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** [DB] if this was a token transaction, the token deposit */
  furDeposit: Maybe<BlockchainTokenTransaction>;
  /** [DB] if this was a token transaction, the token deposit */
  furDepositId: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** [DB] */
  player: FurPlayer;
  /** [DB] */
  playerId: Scalars['String'];
};

export type PlayerAnteFilterInput = {
  and: Maybe<Array<PlayerAnteFilterInput>>;
  /** [DB} the ante object */
  ante: Maybe<AntePoolFilterInput>;
  /** [DB] */
  anteId: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** [DB] if this was a token transaction, the token deposit */
  furDeposit: Maybe<BlockchainTokenTransactionFilterInput>;
  /** [DB] if this was a token transaction, the token deposit */
  furDepositId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<PlayerAnteFilterInput>>;
  /** [DB] */
  player: Maybe<FurPlayerFilterInput>;
  /** [DB] */
  playerId: Maybe<StringOperationFilterInput>;
};

/** A connection to a list of items. */
export type PlayerHistoryConnection = {
  __typename?: 'PlayerHistoryConnection';
  /** A list of edges. */
  edges: Maybe<Array<PlayerHistoryEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<PlayerHistoryDay>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** Represents a single day (UTC) for a player, and their performance therein */
export type PlayerHistoryDay = {
  __typename?: 'PlayerHistoryDay';
  /** All the boss battles which happened on that day */
  bossBattles: Array<GameBattleBoss>;
  id: Scalars['String'];
  /** The contribution to the leaderboard (TOP3 scores) */
  leaderboardTotalScore: Scalars['UnsignedLong'];
  /** When the next day begins (exclusive) */
  nextDate: Scalars['DateTime'];
  /** The overall percentile rank for the day, averaged between games */
  percentileRankOverall: Scalars['Float'];
  /** The TOP3 percentile rank for the day, averaged */
  percentileRankTop: Scalars['Float'];
  /** The player to whom it applies */
  player: FurPlayer;
  /** The player to whom it applies */
  playerId: Scalars['String'];
  /** When the history begins (inclusive) */
  startDate: Scalars['DateTime'];
};

/** Represents a single day (UTC) for a player, and their performance therein */
export type PlayerHistoryDayFilterInput = {
  and: Maybe<Array<PlayerHistoryDayFilterInput>>;
  /** All the boss battles which happened on that day */
  bossBattles: Maybe<ListFilterInputTypeOfGameBattleBossFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** When the next day begins (exclusive) */
  nextDate: Maybe<DateTimeOperationFilterInput>;
  or: Maybe<Array<PlayerHistoryDayFilterInput>>;
  /** The overall percentile rank for the day, averaged between games */
  percentileRankOverall: Maybe<FloatOperationFilterInput>;
  /** The TOP3 percentile rank for the day, averaged */
  percentileRankTop: Maybe<FloatOperationFilterInput>;
  /** The player to whom it applies */
  player: Maybe<FurPlayerFilterInput>;
  /** The player to whom it applies */
  playerId: Maybe<StringOperationFilterInput>;
  /** When the history begins (inclusive) */
  startDate: Maybe<DateTimeOperationFilterInput>;
};

/** An edge in a connection. */
export type PlayerHistoryEdge = {
  __typename?: 'PlayerHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlayerHistoryDay;
};

export type PlayerItemListing = {
  __typename?: 'PlayerItemListing';
  /** The ID of the player who sold */
  buyer: Maybe<FurPlayer>;
  /** The ID of the player who purchased */
  buyerId: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** The currency used in the sale (usually FUR) */
  currency: CurrencyType;
  /** The price for which the item will be (or was) sold. */
  currentPrice: Scalars['UnsignedLong'];
  /** The price per quantity */
  currentPriceEach: Scalars['UnsignedLong'];
  /** When the listing becomes inactive */
  expiresAt: Scalars['DateTime'];
  id: Scalars['String'];
  /** The item bought/sold */
  item: GameItem;
  /** The underlying item definition, for easy searching */
  itemDefinitionId: Scalars['Long'];
  itemId: Scalars['String'];
  /** The number of items to sell */
  quantity: Scalars['UnsignedInt'];
  /** The player for whom its reserved */
  reservedPlayer: Maybe<FurPlayer>;
  /** The player for whom the listing is reserved */
  reservedPlayerId: Maybe<Scalars['String']>;
  seller: FurPlayer;
  sellerId: Scalars['String'];
  /** The time at which the listing sold */
  soldAt: Maybe<Scalars['DateTime']>;
  /** Fixed price? Dutch auction? */
  style: ListingStyle;
};

export type PlayerItemListingFilterInput = {
  and: Maybe<Array<PlayerItemListingFilterInput>>;
  /** The ID of the player who sold */
  buyer: Maybe<FurPlayerFilterInput>;
  /** The ID of the player who purchased */
  buyerId: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** The currency used in the sale (usually FUR) */
  currency: Maybe<CurrencyTypeOperationFilterInput>;
  /** When the listing becomes inactive */
  expiresAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** The item bought/sold */
  item: Maybe<GameItemFilterInput>;
  /** The underlying item definition, for easy searching */
  itemDefinitionId: Maybe<LongOperationFilterInput>;
  itemId: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<PlayerItemListingFilterInput>>;
  /** The player for whom its reserved */
  reservedPlayer: Maybe<FurPlayerFilterInput>;
  /** The player for whom the listing is reserved */
  reservedPlayerId: Maybe<StringOperationFilterInput>;
  seller: Maybe<FurPlayerFilterInput>;
  sellerId: Maybe<StringOperationFilterInput>;
  /** The time at which the listing sold */
  soldAt: Maybe<DateTimeOperationFilterInput>;
  /** Fixed price? Dutch auction? */
  style: Maybe<ListingStyleOperationFilterInput>;
};

export type PlayerItemListingSortInput = {
  /** The ID of the player who sold */
  buyer: Maybe<FurPlayerSortInput>;
  /** The ID of the player who purchased */
  buyerId: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** The currency used in the sale (usually FUR) */
  currency: Maybe<SortEnumType>;
  /** The price for which the item will be (or was) sold. */
  currentPrice: Maybe<SortEnumType>;
  /** The price per quantity */
  currentPriceEach: Maybe<SortEnumType>;
  /** When the listing becomes inactive */
  expiresAt: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** The item bought/sold */
  item: Maybe<GameItemSortInput>;
  /** The underlying item definition, for easy searching */
  itemDefinitionId: Maybe<SortEnumType>;
  itemId: Maybe<SortEnumType>;
  /** The number of items to sell */
  quantity: Maybe<SortEnumType>;
  /** The player for whom its reserved */
  reservedPlayer: Maybe<FurPlayerSortInput>;
  /** The player for whom the listing is reserved */
  reservedPlayerId: Maybe<SortEnumType>;
  seller: Maybe<FurPlayerSortInput>;
  sellerId: Maybe<SortEnumType>;
  /** The time at which the listing sold */
  soldAt: Maybe<SortEnumType>;
  /** Fixed price? Dutch auction? */
  style: Maybe<SortEnumType>;
};

export type PlayerOnboardingStep = {
  __typename?: 'PlayerOnboardingStep';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isComplete: Scalars['Boolean'];
  /** The player of the quest */
  player: FurPlayer;
  /** The player of the quest */
  playerId: Scalars['String'];
  /** The general sequence to which this belongs */
  sequence: OnboardingSequenceType;
  stepProgress: Scalars['Int'];
};

export type PlayerOnboardingStepFilterInput = {
  and: Maybe<Array<PlayerOnboardingStepFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isComplete: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<PlayerOnboardingStepFilterInput>>;
  /** The player of the quest */
  player: Maybe<FurPlayerFilterInput>;
  /** The player of the quest */
  playerId: Maybe<StringOperationFilterInput>;
  /** The general sequence to which this belongs */
  sequence: Maybe<OnboardingSequenceTypeOperationFilterInput>;
  stepProgress: Maybe<IntOperationFilterInput>;
};

export type PlayerPayout = {
  __typename?: 'PlayerPayout';
  /** If false, it's TIX only */
  canPayEth: Scalars['Boolean'];
  createdAt: Maybe<Scalars['DateTime']>;
  /** The actual ETH value */
  ethAmount: Scalars['Float'];
  id: Scalars['String'];
  /** How the player has decided on payout */
  payoutMethod: PayoutMethod;
  /** The player to whom it applies */
  player: FurPlayer;
  /** The player to whom it applies */
  playerId: Scalars['String'];
  /** Where this came from */
  reason: Maybe<Scalars['String']>;
  /** If > 1, gives more TIX. If < 1, cannot be paid in tix. */
  tixRatio: Scalars['Float'];
  /** Either blockchain hash, or Token transaction */
  transactionId: Maybe<Scalars['String']>;
};

export type PlayerPayoutFilterInput = {
  and: Maybe<Array<PlayerPayoutFilterInput>>;
  /** If false, it's TIX only */
  canPayEth: Maybe<BooleanOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** The actual ETH value */
  ethAmount: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<PlayerPayoutFilterInput>>;
  /** How the player has decided on payout */
  payoutMethod: Maybe<PayoutMethodOperationFilterInput>;
  /** The player to whom it applies */
  player: Maybe<FurPlayerFilterInput>;
  /** The player to whom it applies */
  playerId: Maybe<StringOperationFilterInput>;
  /** Where this came from */
  reason: Maybe<StringOperationFilterInput>;
  /** If > 1, gives more TIX. If < 1, cannot be paid in tix. */
  tixRatio: Maybe<FloatOperationFilterInput>;
  /** Either blockchain hash, or Token transaction */
  transactionId: Maybe<StringOperationFilterInput>;
};

export type PlayerPendingReward = {
  __typename?: 'PlayerPendingReward';
  claimedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  /** If not null, this reward will disappear (not be available to claim) after this time */
  expiresAt: Maybe<Scalars['DateTime']>;
  /** Polymorphic accessor */
  furPlayer: IFurPlayer;
  id: Scalars['String'];
  /** [LL] the items to give the player */
  items: Array<GameItem>;
  /** The notification sent to the player about this reward */
  notification: Maybe<Notification>;
  /** The notification ID */
  notificationId: Maybe<Scalars['String']>;
  /** The player for whom the reward applies */
  player: FurPlayer;
  /** The playerId for whom it applies */
  playerId: Scalars['String'];
  /** The rental agreement from which these rewards came */
  rentalAgreement: Maybe<RentalAgreement>;
  /** The agreement ID */
  rentalAgreementId: Maybe<Scalars['String']>;
  /** Where the reward came from */
  source: RewardSource;
  /** A reference to the specific source (i.e., the leaderboard tier) */
  sourceId: Maybe<Scalars['String']>;
  /** What position the reward had within that source, if applicable (i.e., leaderboard tier position) */
  sourcePosition: Maybe<Scalars['Int']>;
};

export type PlayerPendingRewardFilterInput = {
  and: Maybe<Array<PlayerPendingRewardFilterInput>>;
  claimedAt: Maybe<DateTimeOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** If not null, this reward will disappear (not be available to claim) after this time */
  expiresAt: Maybe<DateTimeOperationFilterInput>;
  /** Polymorphic accessor */
  furPlayer: Maybe<IFurPlayerFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** The notification sent to the player about this reward */
  notification: Maybe<NotificationFilterInput>;
  /** The notification ID */
  notificationId: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<PlayerPendingRewardFilterInput>>;
  /** The player for whom the reward applies */
  player: Maybe<FurPlayerFilterInput>;
  /** The playerId for whom it applies */
  playerId: Maybe<StringOperationFilterInput>;
  /** The rental agreement from which these rewards came */
  rentalAgreement: Maybe<RentalAgreementFilterInput>;
  /** The agreement ID */
  rentalAgreementId: Maybe<StringOperationFilterInput>;
  /** Where the reward came from */
  source: Maybe<RewardSourceOperationFilterInput>;
  /** A reference to the specific source (i.e., the leaderboard tier) */
  sourceId: Maybe<StringOperationFilterInput>;
  /** What position the reward had within that source, if applicable (i.e., leaderboard tier position) */
  sourcePosition: Maybe<IntOperationFilterInput>;
};

export type PlayerPendingRewardSortInput = {
  claimedAt: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  /** If not null, this reward will disappear (not be available to claim) after this time */
  expiresAt: Maybe<SortEnumType>;
  /** Polymorphic accessor */
  furPlayer: Maybe<IFurPlayerSortInput>;
  id: Maybe<SortEnumType>;
  /** The notification sent to the player about this reward */
  notification: Maybe<NotificationSortInput>;
  /** The notification ID */
  notificationId: Maybe<SortEnumType>;
  /** The player for whom the reward applies */
  player: Maybe<FurPlayerSortInput>;
  /** The playerId for whom it applies */
  playerId: Maybe<SortEnumType>;
  /** The rental agreement from which these rewards came */
  rentalAgreement: Maybe<RentalAgreementSortInput>;
  /** The agreement ID */
  rentalAgreementId: Maybe<SortEnumType>;
  /** Where the reward came from */
  source: Maybe<SortEnumType>;
  /** A reference to the specific source (i.e., the leaderboard tier) */
  sourceId: Maybe<SortEnumType>;
  /** What position the reward had within that source, if applicable (i.e., leaderboard tier position) */
  sourcePosition: Maybe<SortEnumType>;
};

export type PlayerPurchase = {
  __typename?: 'PlayerPurchase';
  furballs: Array<Furball>;
  player: FurPlayer;
  remainingFuel: Scalars['Long'];
  spentFuel: Scalars['UnsignedLong'];
};

/** A connection to a list of items. */
export type PlayerTransactionsConnection = {
  __typename?: 'PlayerTransactionsConnection';
  /** A list of edges. */
  edges: Maybe<Array<PlayerTransactionsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<BlockchainTokenTransaction>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PlayerTransactionsEdge = {
  __typename?: 'PlayerTransactionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BlockchainTokenTransaction;
};

/** A connection to a list of items. */
export type PriorRewardsConnection = {
  __typename?: 'PriorRewardsConnection';
  /** A list of edges. */
  edges: Maybe<Array<PriorRewardsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<PlayerPendingReward>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type PriorRewardsEdge = {
  __typename?: 'PriorRewardsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PlayerPendingReward;
};

export type Query = {
  __typename?: 'Query';
  account: FurAccount;
  bossBattle: GameBattleBoss;
  bossBattles: Maybe<BossBattlesConnection>;
  /** Get a specific container by its ID, so that more data may be loaded */
  container: ContainerItem;
  currentBossBattles: Array<GameBattleBoss>;
  currentPlayer: FurPlayer;
  duelBattles: Maybe<DuelBattlesConnection>;
  dutchAuction: DutchAuction;
  editions: Array<EditionBase>;
  exchangeRates: CurrencyExchangeRates;
  findFurball: Furball;
  furball: Furball;
  /** Get any name changes */
  furballNameChanges: Maybe<FurballNameChangesConnection>;
  furballs: Array<Furball>;
  itemDefinitions: Array<ItemDefinition>;
  /** Paginate through the items available for sale */
  itemListings: Maybe<ItemListingsConnection>;
  leaderboard: Leaderboard;
  leaderboardRanksPlayer: Array<LeaderboardRankPlayer>;
  /** Search all rewards via filter syntax */
  leaderboardTierRewards: Array<LeaderboardTierRewards>;
  /** Search all leaderboards via filter syntax */
  leaderboards: Array<Leaderboard>;
  listAirdropRequests: Array<AirdropRequest>;
  lootBox: LootBox;
  me: IFurPlayer;
  /** Get the active Furballs build meta */
  meta: FurballsMeta;
  monsterBattles: Maybe<MonsterBattlesConnection>;
  monsterDefinitions: Array<MonsterDefinition>;
  /** Get all items listed by the current player */
  myItemListings: Array<PlayerItemListing>;
  notifications: Maybe<NotificationsConnection>;
  party: GameParty;
  player: FurPlayer;
  /** Paginate through the history of the current player */
  playerHistory: Maybe<PlayerHistoryConnection>;
  playerIds: Array<FurPlayer>;
  playerParties: Array<GameParty>;
  /** Get the transactions made by a player */
  playerTransactions: Maybe<PlayerTransactionsConnection>;
  /** Get a searchable/pageable list of prior (claimed) rewards for this player */
  priorRewards: Maybe<PriorRewardsConnection>;
  questDefinitions: Array<QuestDefinition>;
  ranks: Array<FurballRank>;
  recentBossBattles: Array<GameBattleBoss>;
  rentalAgreement: RentalAgreement;
  scholar: FurPlayer;
  searchFurballs: Maybe<SearchFurballsConnection>;
  /** Search all tournaments via filter syntax */
  searchTournaments: Array<Tournament>;
  shopSnacks: Array<ShopSnack>;
  shopWares: Array<ShopWares>;
  skillDefinitions: Array<SkillDefinition>;
  timekeeperAdHocs: Array<TimekeeperAdHoc>;
  timekeeperPrevious: Array<TimekeeperRequest>;
  timekeeperPreviousRequests: Array<TimekeeperRequest>;
  timekeeperPreviousResolutions: Array<TimekeeperResolution>;
  timekeeperRound: TimekeeperRound;
  timekeeperState: TimekeeperUserState;
  topBossBattles: Array<RecentBossBattles>;
  tournament: Tournament;
  unreadNotifications: Maybe<UnreadNotificationsConnection>;
  updates: FurUpdates;
  usableFurballs: Array<Furball>;
};

export type QueryAccountArgs = {
  id: Scalars['String'];
  maxCache: Maybe<Scalars['Int']>;
};

export type QueryBossBattleArgs = {
  id: Scalars['String'];
};

export type QueryBossBattlesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleBossSortInput>>;
  where: Maybe<GameBattleBossFilterInput>;
};

export type QueryContainerArgs = {
  containerId: Scalars['String'];
};

export type QueryCurrentBossBattlesArgs = {
  playerId: Maybe<Scalars['String']>;
  realm: Maybe<RealmType>;
};

export type QueryCurrentPlayerArgs = {
  maxCache: Maybe<Scalars['Int']>;
};

export type QueryDuelBattlesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleDuelSortInput>>;
  where: Maybe<GameBattleDuelFilterInput>;
};

export type QueryFindFurballArgs = {
  query: Scalars['String'];
};

export type QueryFurballArgs = {
  tokenId: Scalars['String'];
};

export type QueryFurballNameChangesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<FurballNameChangeSortInput>>;
  where: Maybe<FurballNameChangeFilterInput>;
};

export type QueryFurballsArgs = {
  tokenIds: Array<Scalars['String']>;
};

export type QueryItemListingsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerItemListingSortInput>>;
  where: Maybe<PlayerItemListingFilterInput>;
};

export type QueryLeaderboardArgs = {
  id: Scalars['String'];
};

export type QueryLeaderboardRanksPlayerArgs = {
  playerId: Maybe<Scalars['String']>;
};

export type QueryLeaderboardTierRewardsArgs = {
  where: Maybe<LeaderboardTierRewardsFilterInput>;
};

export type QueryLeaderboardsArgs = {
  where: Maybe<LeaderboardFilterInput>;
};

export type QueryMeArgs = {
  maxCache: Maybe<Scalars['Int']>;
};

export type QueryMonsterBattlesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleMonstersSortInput>>;
  where: Maybe<GameBattleMonstersFilterInput>;
};

export type QueryNotificationsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<NotificationSortInput>>;
  where: Maybe<NotificationFilterInput>;
};

export type QueryPartyArgs = {
  partyId: Scalars['String'];
};

export type QueryPlayerArgs = {
  query: Scalars['String'];
};

export type QueryPlayerHistoryArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  playerId: Maybe<Scalars['String']>;
};

export type QueryPlayerIdsArgs = {
  playerIds: Array<Scalars['String']>;
};

export type QueryPlayerPartiesArgs = {
  playerId: Maybe<Scalars['String']>;
};

export type QueryPlayerTransactionsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<BlockchainTokenTransactionSortInput>>;
  playerId: Maybe<Scalars['String']>;
  where: Maybe<BlockchainTokenTransactionFilterInput>;
};

export type QueryPriorRewardsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<PlayerPendingRewardSortInput>>;
  where: Maybe<PlayerPendingRewardFilterInput>;
};

export type QueryRanksArgs = {
  tokenIds: Array<Scalars['String']>;
};

export type QueryRecentBossBattlesArgs = {
  leaderboardGroup: Maybe<LeaderboardGroup>;
  limit: Maybe<Scalars['Int']>;
  playerId: Maybe<Scalars['String']>;
  realm: Maybe<RealmType>;
  startAt: Maybe<Scalars['DateTime']>;
};

export type QueryRentalAgreementArgs = {
  id: Scalars['String'];
};

export type QueryScholarArgs = {
  query: Scalars['String'];
};

export type QuerySearchFurballsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  filters: Maybe<FurballFiltersInput>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<FurballSortInput>>;
  where: Maybe<FurballFilterInput>;
};

export type QuerySearchTournamentsArgs = {
  where: Maybe<TournamentFilterInput>;
};

export type QueryTimekeeperAdHocsArgs = {
  sender: Scalars['String'];
};

export type QueryTimekeeperPreviousArgs = {
  sender: Scalars['String'];
};

export type QueryTimekeeperPreviousRequestsArgs = {
  date: Scalars['Int'];
  sender: Scalars['String'];
};

export type QueryTimekeeperPreviousResolutionsArgs = {
  tokenId: Scalars['String'];
};

export type QueryTimekeeperRoundArgs = {
  date: Maybe<Scalars['Int']>;
};

export type QueryTimekeeperStateArgs = {
  sender: Scalars['String'];
};

export type QueryTournamentArgs = {
  id: Scalars['String'];
};

export type QueryUnreadNotificationsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<NotificationSortInput>>;
  where: Maybe<NotificationFilterInput>;
};

export type QueryUpdatesArgs = {
  since: Scalars['DateTime'];
};

export type Quest = {
  __typename?: 'Quest';
  /** When the quest ended */
  completedAt: Maybe<Scalars['DateTime']>;
  /** The names of those QuestWaypointDefintitions that are complete */
  completedWaypointNames: Array<Scalars['String']>;
  /** Those waypoints which have been completed */
  completedWaypoints: Array<QuestWaypointCompletion>;
  /** When the quest began */
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The player of the quest */
  player: FurPlayer;
  /** The player of the quest */
  playerId: Scalars['String'];
  questDefinition: QuestDefinition;
  /** Refers to the QuestDefinition name */
  questName: Scalars['String'];
  /** The names of those QuestWaypointDefintitions that are not complete */
  remainingWaypointNames: Array<Scalars['String']>;
  /** The reward which was given, if any */
  reward: Maybe<PlayerPendingReward>;
  /** The reward Id */
  rewardId: Maybe<Scalars['String']>;
};

/** Each quest represents many things to be done in sequence... a flexible format for any type of player-assigned task */
export type QuestDefinition = {
  __typename?: 'QuestDefinition';
  /** The name of the quest */
  id: Scalars['String'];
  /** Zero = infinitely repeatable; 1 = can only be done once... */
  maxCompletions: Scalars['UnsignedInt'];
  name: Scalars['String'];
  /** If > 0, then represents the number of seconds between respawns (repeat quest) */
  respawn: Scalars['UnsignedLong'];
  /** Each objective along the way is encoded within a waypoint */
  waypoints: Array<QuestWaypointDefinition>;
};

/** Each quest represents many things to be done in sequence... a flexible format for any type of player-assigned task */
export type QuestDefinitionFilterInput = {
  and: Maybe<Array<QuestDefinitionFilterInput>>;
  /** The name of the quest */
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<QuestDefinitionFilterInput>>;
  /** Each objective along the way is encoded within a waypoint */
  waypoints: Maybe<ListFilterInputTypeOfQuestWaypointDefinitionFilterInput>;
};

export type QuestFilterInput = {
  and: Maybe<Array<QuestFilterInput>>;
  /** When the quest ended */
  completedAt: Maybe<DateTimeOperationFilterInput>;
  /** The names of those QuestWaypointDefintitions that are complete */
  completedWaypointNames: Maybe<ListStringOperationFilterInput>;
  /** Those waypoints which have been completed */
  completedWaypoints: Maybe<ListFilterInputTypeOfQuestWaypointCompletionFilterInput>;
  /** When the quest began */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<QuestFilterInput>>;
  /** The player of the quest */
  player: Maybe<FurPlayerFilterInput>;
  /** The player of the quest */
  playerId: Maybe<StringOperationFilterInput>;
  questDefinition: Maybe<QuestDefinitionFilterInput>;
  /** Refers to the QuestDefinition name */
  questName: Maybe<StringOperationFilterInput>;
  /** The names of those QuestWaypointDefintitions that are not complete */
  remainingWaypointNames: Maybe<ListStringOperationFilterInput>;
  /** The reward which was given, if any */
  reward: Maybe<PlayerPendingRewardFilterInput>;
  /** The reward Id */
  rewardId: Maybe<StringOperationFilterInput>;
};

export type QuestWaypointBracketDefinition = {
  __typename?: 'QuestWaypointBracketDefinition';
  /** Reward: FUR */
  furAmount: Scalars['Long'];
  /** Must have <= this percentileRank to get in this bracket */
  percentileRank: Scalars['Int'];
  possibleRewardList: Scalars['String'];
  /** Additional rewards (loot/mints) that might be dropped */
  possibleRewards: Array<LeaderboardTierRewardChance>;
};

export type QuestWaypointBracketDefinitionFilterInput = {
  and: Maybe<Array<QuestWaypointBracketDefinitionFilterInput>>;
  /** Reward: FUR */
  furAmount: Maybe<LongOperationFilterInput>;
  or: Maybe<Array<QuestWaypointBracketDefinitionFilterInput>>;
  /** Must have <= this percentileRank to get in this bracket */
  percentileRank: Maybe<IntOperationFilterInput>;
  possibleRewardList: Maybe<StringOperationFilterInput>;
  /** Additional rewards (loot/mints) that might be dropped */
  possibleRewards: Maybe<ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput>;
};

export type QuestWaypointCompletion = {
  __typename?: 'QuestWaypointCompletion';
  /** When the waypoint was completed */
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  /** The quest which it is a part of */
  quest: Quest;
  /** The quest Id */
  questId: Scalars['String'];
  /** The reward which was given, if any */
  reward: Maybe<PlayerPendingReward>;
  /** The reward Id */
  rewardId: Maybe<Scalars['String']>;
  /** Refers to the QuestWaypointDefinition name */
  waypointName: Scalars['String'];
};

export type QuestWaypointCompletionFilterInput = {
  and: Maybe<Array<QuestWaypointCompletionFilterInput>>;
  /** When the waypoint was completed */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<QuestWaypointCompletionFilterInput>>;
  /** The quest which it is a part of */
  quest: Maybe<QuestFilterInput>;
  /** The quest Id */
  questId: Maybe<StringOperationFilterInput>;
  /** The reward which was given, if any */
  reward: Maybe<PlayerPendingRewardFilterInput>;
  /** The reward Id */
  rewardId: Maybe<StringOperationFilterInput>;
  /** Refers to the QuestWaypointDefinition name */
  waypointName: Maybe<StringOperationFilterInput>;
};

/** The milestones along the journey which is a quest... each is completed when its name is emitted in game code... */
export type QuestWaypointDefinition = {
  __typename?: 'QuestWaypointDefinition';
  brackets: Array<QuestWaypointBracketDefinition>;
  /** Some recognizable name for the player */
  id: Scalars['String'];
  name: Scalars['String'];
  quest: QuestDefinition;
  style: WaypointStyle;
};

/** The milestones along the journey which is a quest... each is completed when its name is emitted in game code... */
export type QuestWaypointDefinitionFilterInput = {
  and: Maybe<Array<QuestWaypointDefinitionFilterInput>>;
  brackets: Maybe<ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput>;
  /** Some recognizable name for the player */
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<QuestWaypointDefinitionFilterInput>>;
  quest: Maybe<QuestDefinitionFilterInput>;
  style: Maybe<WaypointStyleOperationFilterInput>;
};

export type RandomParty = {
  __typename?: 'RandomParty';
  furballs: Array<Furball>;
  party: GameParty;
  realm: RealmType;
};

export enum RarityLevel {
  Common = 'COMMON',
  Elite = 'ELITE',
  Legendary = 'LEGENDARY',
  Mythic = 'MYTHIC',
  NumRarities = 'NUM_RARITIES',
  Ultimate = 'ULTIMATE',
}

export type RarityLevelOperationFilterInput = {
  eq: Maybe<RarityLevel>;
  in: Maybe<Array<RarityLevel>>;
  neq: Maybe<RarityLevel>;
  nin: Maybe<Array<RarityLevel>>;
};

export enum RealmType {
  Boneyard = 'BONEYARD',
  None = 'NONE',
  NumRealms = 'NUM_REALMS',
  TurtleSea = 'TURTLE_SEA',
  WizardsPath = 'WIZARDS_PATH',
}

export type RealmTypeOperationFilterInput = {
  eq: Maybe<RealmType>;
  in: Maybe<Array<RealmType>>;
  neq: Maybe<RealmType>;
  nin: Maybe<Array<RealmType>>;
};

export enum ReceiptState {
  Canceled = 'CANCELED',
  Dismissed = 'DISMISSED',
  Error = 'ERROR',
  None = 'NONE',
  Opened = 'OPENED',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Sent = 'SENT',
  Unknown = 'UNKNOWN',
}

export type RecentBossBattles = {
  __typename?: 'RecentBossBattles';
  battles: Maybe<BattlesConnection>;
  boss: GameBoss;
};

export type RecentBossBattlesBattlesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  order: Maybe<Array<GameBattleBossSortInput>>;
  where: Maybe<GameBattleBossFilterInput>;
};

export type RenameFurballArgsInput = {
  deadline: Scalars['Long'];
  furballId: Scalars['String'];
  name: Scalars['String'];
  sender: Scalars['String'];
};

/** RentalTerms which are in the database, attached to a RentalAgreement */
export type RentalAgreement = {
  __typename?: 'RentalAgreement';
  /** Time in seconds it lasts */
  autoRenew: Scalars['Boolean'];
  /** [LL] the renter, as a FurPlayer */
  battles: Array<GameBattle>;
  /** When the agreement took effect (if null, and EndedAt is null, it is available/open for claiming) */
  beganAt: Maybe<Scalars['DateTime']>;
  /** Gets updated only when the agreement is changed or claimed */
  changedAt: Maybe<Scalars['DateTime']>;
  /** Time in seconds it lasts */
  duration: Scalars['Long'];
  /** When the agreement ended (if null, it is either not started or not ended) */
  endedAt: Maybe<Scalars['DateTime']>;
  /** The Furball which is being rented */
  furball: Furball;
  /** The Furball token ID */
  furballId: Scalars['String'];
  id: Scalars['String'];
  /** Derived from BeganAt != null and EndedAt == null */
  isActive: Scalars['Boolean'];
  /** The FurAccount which actually owns the furball */
  owner: FurAccount;
  ownerId: Scalars['String'];
  /** [LL] the owner, as a FurPlayer */
  ownerPlayer: FurPlayer;
  /** If the owner has canceled, but we are waiting for the end of the contract */
  pendingCancellation: Scalars['Boolean'];
  /** What percent of rewards does the player get? */
  percent: Scalars['Int'];
  /** If already rented OR if it is 'reserved' for a specific player... */
  player: Maybe<FurPlayer>;
  playerId: Maybe<Scalars['String']>;
  /** Changes only when a new player rents (not auto-renew) */
  rentedAt: Maybe<Scalars['DateTime']>;
  /** [LL] the renter, as a FurPlayer */
  renterPlayer: Maybe<FurPlayer>;
  rewardCount: Scalars['Int'];
  /** The Timekeeper-style date of the last time this agreement was rewarded by a background task */
  rewardDate: Scalars['Int'];
  /** [LL] the pending rewards associated with the notification */
  rewards: Array<PlayerPendingReward>;
  /** The Signature of the owner */
  signature: Scalars['String'];
  /** When the rental begins */
  startsAt: Scalars['Long'];
  /** All terminations that have occurred of this contract */
  terminations: Array<RentalTermination>;
  /** The amount of wasted hearts by the Player */
  unusedHearts: Scalars['Int'];
  /** How much WFur was earned by this contract */
  wFurEarned: Scalars['Long'];
  willEndAt: Maybe<Scalars['DateTime']>;
};

/** RentalTerms which are in the database, attached to a RentalAgreement */
export type RentalAgreementFilterInput = {
  and: Maybe<Array<RentalAgreementFilterInput>>;
  /** Time in seconds it lasts */
  autoRenew: Maybe<BooleanOperationFilterInput>;
  /** Mapping between battles and involved rentals */
  battles: Maybe<ListFilterInputTypeOfGameBattleFilterInput>;
  /** When the agreement took effect (if null, and EndedAt is null, it is available/open for claiming) */
  beganAt: Maybe<DateTimeOperationFilterInput>;
  /** Gets updated only when the agreement is changed or claimed */
  changedAt: Maybe<DateTimeOperationFilterInput>;
  /** Time in seconds it lasts */
  duration: Maybe<LongOperationFilterInput>;
  /** When the agreement ended (if null, it is either not started or not ended) */
  endedAt: Maybe<DateTimeOperationFilterInput>;
  /** The Furball which is being rented */
  furball: Maybe<FurballFilterInput>;
  /** The Furball token ID */
  furballId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  /** Derived from BeganAt != null and EndedAt == null */
  isActive: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<RentalAgreementFilterInput>>;
  /** The FurAccount which actually owns the furball */
  owner: Maybe<FurAccountFilterInput>;
  ownerId: Maybe<StringOperationFilterInput>;
  /** If the owner has canceled, but we are waiting for the end of the contract */
  pendingCancellation: Maybe<BooleanOperationFilterInput>;
  /** What percent of rewards does the player get? */
  percent: Maybe<IntOperationFilterInput>;
  /** If already rented OR if it is 'reserved' for a specific player... */
  player: Maybe<FurPlayerFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** Changes only when a new player rents (not auto-renew) */
  rentedAt: Maybe<DateTimeOperationFilterInput>;
  rewardCount: Maybe<IntOperationFilterInput>;
  /** The Timekeeper-style date of the last time this agreement was rewarded by a background task */
  rewardDate: Maybe<IntOperationFilterInput>;
  /** The Signature of the owner */
  signature: Maybe<StringOperationFilterInput>;
  /** When the rental begins */
  startsAt: Maybe<LongOperationFilterInput>;
  /** All terminations that have occurred of this contract */
  terminations: Maybe<ListFilterInputTypeOfRentalTerminationFilterInput>;
  /** The amount of wasted hearts by the Player */
  unusedHearts: Maybe<IntOperationFilterInput>;
  /** How much WFur was earned by this contract */
  wFurEarned: Maybe<LongOperationFilterInput>;
  willEndAt: Maybe<DateTimeOperationFilterInput>;
};

/** RentalTerms which are in the database, attached to a RentalAgreement */
export type RentalAgreementSortInput = {
  /** Time in seconds it lasts */
  autoRenew: Maybe<SortEnumType>;
  /** When the agreement took effect (if null, and EndedAt is null, it is available/open for claiming) */
  beganAt: Maybe<SortEnumType>;
  /** Gets updated only when the agreement is changed or claimed */
  changedAt: Maybe<SortEnumType>;
  /** Time in seconds it lasts */
  duration: Maybe<SortEnumType>;
  /** When the agreement ended (if null, it is either not started or not ended) */
  endedAt: Maybe<SortEnumType>;
  /** The Furball which is being rented */
  furball: Maybe<FurballSortInput>;
  /** The Furball token ID */
  furballId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** Derived from BeganAt != null and EndedAt == null */
  isActive: Maybe<SortEnumType>;
  /** The FurAccount which actually owns the furball */
  owner: Maybe<FurAccountSortInput>;
  ownerId: Maybe<SortEnumType>;
  /** If the owner has canceled, but we are waiting for the end of the contract */
  pendingCancellation: Maybe<SortEnumType>;
  /** What percent of rewards does the player get? */
  percent: Maybe<SortEnumType>;
  /** If already rented OR if it is 'reserved' for a specific player... */
  player: Maybe<FurPlayerSortInput>;
  playerId: Maybe<SortEnumType>;
  /** Changes only when a new player rents (not auto-renew) */
  rentedAt: Maybe<SortEnumType>;
  rewardCount: Maybe<SortEnumType>;
  /** The Timekeeper-style date of the last time this agreement was rewarded by a background task */
  rewardDate: Maybe<SortEnumType>;
  /** The Signature of the owner */
  signature: Maybe<SortEnumType>;
  /** When the rental begins */
  startsAt: Maybe<SortEnumType>;
  /** The amount of wasted hearts by the Player */
  unusedHearts: Maybe<SortEnumType>;
  /** How much WFur was earned by this contract */
  wFurEarned: Maybe<SortEnumType>;
  willEndAt: Maybe<SortEnumType>;
};

/** A connection to a list of items. */
export type RentalAgreementsConnection = {
  __typename?: 'RentalAgreementsConnection';
  /** A list of edges. */
  edges: Maybe<Array<RentalAgreementsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<RentalAgreement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type RentalAgreementsEdge = {
  __typename?: 'RentalAgreementsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RentalAgreement;
};

export type RentalTermination = {
  __typename?: 'RentalTermination';
  createdAt: Scalars['DateTime'];
  reason: Scalars['String'];
};

export type RentalTerminationFilterInput = {
  and: Maybe<Array<RentalTerminationFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  or: Maybe<Array<RentalTerminationFilterInput>>;
  reason: Maybe<StringOperationFilterInput>;
};

/** The terms as they are represented on-chain */
export type RentalTermsInput = {
  /** Time in seconds it lasts */
  autoRenew: Scalars['Boolean'];
  /** Time in seconds it lasts */
  duration: Scalars['Long'];
  /** The Furball token ID */
  furballId: Scalars['String'];
  /** The account which owns the Furball */
  ownerId: Scalars['String'];
  /** What percent the player gets */
  percent: Scalars['Int'];
  /** The player who has rented */
  playerId: Maybe<Scalars['String']>;
  /** The Signature of the owner */
  signature: Scalars['String'];
  /** When the rental begins */
  startsAt: Scalars['Long'];
};

export enum RewardSource {
  CraftingFurball = 'CRAFTING_FURBALL',
  CraftingPlayer = 'CRAFTING_PLAYER',
  LootBox = 'LOOT_BOX',
  Quest = 'QUEST',
  RentalAgreement = 'RENTAL_AGREEMENT',
  SeasonalBossLeaderboardSebastian = 'SEASONAL_BOSS_LEADERBOARD_SEBASTIAN',
  SeasonalBossLeaderboardSkullumbo = 'SEASONAL_BOSS_LEADERBOARD_SKULLUMBO',
  SeasonalBossLeaderboardTrashy = 'SEASONAL_BOSS_LEADERBOARD_TRASHY',
  Tournament = 'TOURNAMENT',
  Unknown = 'UNKNOWN',
  WeeklyBossLeaderboardSebastian = 'WEEKLY_BOSS_LEADERBOARD_SEBASTIAN',
  WeeklyBossLeaderboardSkullumbo = 'WEEKLY_BOSS_LEADERBOARD_SKULLUMBO',
  WeeklyBossLeaderboardTrashy = 'WEEKLY_BOSS_LEADERBOARD_TRASHY',
}

export type RewardSourceOperationFilterInput = {
  eq: Maybe<RewardSource>;
  in: Maybe<Array<RewardSource>>;
  neq: Maybe<RewardSource>;
  nin: Maybe<Array<RewardSource>>;
};

/** A connection to a list of items. */
export type ScholarsDiscordConnection = {
  __typename?: 'ScholarsDiscordConnection';
  /** A list of edges. */
  edges: Maybe<Array<ScholarsDiscordEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<FurScholarDiscord>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScholarsDiscordEdge = {
  __typename?: 'ScholarsDiscordEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FurScholarDiscord;
};

/** A connection to a list of items. */
export type ScholarsEmailConnection = {
  __typename?: 'ScholarsEmailConnection';
  /** A list of edges. */
  edges: Maybe<Array<ScholarsEmailEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<FurScholarEmail>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ScholarsEmailEdge = {
  __typename?: 'ScholarsEmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FurScholarEmail;
};

/** A connection to a list of items. */
export type SearchFurballsConnection = {
  __typename?: 'SearchFurballsConnection';
  /** A list of edges. */
  edges: Maybe<Array<SearchFurballsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<Furball>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SearchFurballsEdge = {
  __typename?: 'SearchFurballsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Furball;
};

/** Input DTO for CRUD on a GameParty. */
export type SetGamePartyInput = {
  /** True to delete the party (ID must not be null) */
  deleteParty: Maybe<Scalars['Boolean']>;
  /** If provided, must match the size of FurballIds array. */
  formationPositions: Maybe<Array<FormationPosition>>;
  /** REQUIRED; contains the Furballs for the party. */
  furballIds: Array<Scalars['String']>;
  /** NULL to create a party, otherwise the ID of the party to update */
  id: Maybe<Scalars['String']>;
  /** If set, will change the name of the party. */
  name: Maybe<Scalars['String']>;
  /** The player who is to control / use this party */
  playerId: Scalars['String'];
  /** If set, will change the order in which parties appear (monotonic incrementation enforced) */
  slotNumber: Maybe<Scalars['Int']>;
};

export type SfxAsset = {
  __typename?: 'SfxAsset';
  duration: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SfxAssetFilterInput = {
  and: Maybe<Array<SfxAssetFilterInput>>;
  duration: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<SfxAssetFilterInput>>;
};

export type ShopPurchase = {
  __typename?: 'ShopPurchase';
  createdAt: Maybe<Scalars['DateTime']>;
  furball: Furball;
  id: Scalars['String'];
  transaction: BlockchainTransaction;
  updatedAt: Maybe<Scalars['DateTime']>;
  wares: ShopWares;
};

export type ShopPurchaseFilterInput = {
  and: Maybe<Array<ShopPurchaseFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  furball: Maybe<FurballFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<ShopPurchaseFilterInput>>;
  transaction: Maybe<BlockchainTransactionFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  wares: Maybe<ShopWaresFilterInput>;
};

export type ShopSnack = {
  __typename?: 'ShopSnack';
  energy: Scalars['Int'];
  fuelCost1: Scalars['Long'];
  fuelCostN: Scalars['Long'];
  furCost: Scalars['Long'];
  happiness: Scalars['Int'];
  id: Scalars['Long'];
  name: Scalars['String'];
  saleBeginsAt: Maybe<Scalars['DateTime']>;
  saleEndsAt: Maybe<Scalars['DateTime']>;
};

export type ShopWares = {
  __typename?: 'ShopWares';
  fuelCost1: Scalars['Long'];
  fuelCostN: Scalars['Long'];
  furCost: Scalars['Long'];
  id: Scalars['Int'];
  itemIds: Array<Scalars['Long']>;
  itemList: Scalars['String'];
  maxPerFurball: Scalars['Int'];
  name: Scalars['String'];
  purchases: Array<ShopPurchase>;
  saleBeginsAt: Maybe<Scalars['DateTime']>;
  saleEndsAt: Maybe<Scalars['DateTime']>;
};

export type ShopWaresFilterInput = {
  and: Maybe<Array<ShopWaresFilterInput>>;
  fuelCost1: Maybe<LongOperationFilterInput>;
  fuelCostN: Maybe<LongOperationFilterInput>;
  furCost: Maybe<LongOperationFilterInput>;
  id: Maybe<IntOperationFilterInput>;
  itemIds: Maybe<ListLongOperationFilterInput>;
  itemList: Maybe<StringOperationFilterInput>;
  maxPerFurball: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<ShopWaresFilterInput>>;
  purchases: Maybe<ListFilterInputTypeOfShopPurchaseFilterInput>;
  saleBeginsAt: Maybe<DateTimeOperationFilterInput>;
  saleEndsAt: Maybe<DateTimeOperationFilterInput>;
};

export type ShortOperationFilterInput = {
  eq: Maybe<Scalars['Short']>;
  gt: Maybe<Scalars['Short']>;
  gte: Maybe<Scalars['Short']>;
  in: Maybe<Array<Maybe<Scalars['Short']>>>;
  lt: Maybe<Scalars['Short']>;
  lte: Maybe<Scalars['Short']>;
  neq: Maybe<Scalars['Short']>;
  ngt: Maybe<Scalars['Short']>;
  ngte: Maybe<Scalars['Short']>;
  nin: Maybe<Array<Maybe<Scalars['Short']>>>;
  nlt: Maybe<Scalars['Short']>;
  nlte: Maybe<Scalars['Short']>;
};

export type SkillDefinition = {
  __typename?: 'SkillDefinition';
  animationId: Scalars['String'];
  attacksOpponent: Scalars['Boolean'];
  baseUses: Scalars['Int'];
  defendsAlly: Scalars['Boolean'];
  description: Scalars['String'];
  effectBonuses: Array<SkillStatEffect>;
  icon: Scalars['String'];
  id: Scalars['Long'];
  isInstant: Scalars['Boolean'];
  levelNumPartialsPerTier: Scalars['Int'];
  levelPartialCost: Scalars['Int'];
  levelUpgradeTotalCost: Scalars['Int'];
  maxLevel: Scalars['Int'];
  maxUses: Scalars['Int'];
  maxUsesBoost: Scalars['Int'];
  maxUsesNumPartialsPerTier: Scalars['Int'];
  maxUsesPartialCost: Scalars['Int'];
  maxUsesUpgradeTotalCost: Scalars['Int'];
  name: Scalars['String'];
  realmAffiliations: Array<RealmType>;
  summary: Scalars['String'];
  targetingRule: Maybe<TargetingRule>;
  targetingStrategy: Maybe<TargetingStrategy>;
  vfx: Maybe<VfxAsset>;
  vfxId: Maybe<Scalars['String']>;
};

export type SkillDefinitionFilterInput = {
  and: Maybe<Array<SkillDefinitionFilterInput>>;
  animationId: Maybe<StringOperationFilterInput>;
  attacksOpponent: Maybe<BooleanOperationFilterInput>;
  baseUses: Maybe<IntOperationFilterInput>;
  defendsAlly: Maybe<BooleanOperationFilterInput>;
  description: Maybe<StringOperationFilterInput>;
  effectBonuses: Maybe<ListFilterInputTypeOfSkillStatEffectFilterInput>;
  icon: Maybe<StringOperationFilterInput>;
  id: Maybe<LongOperationFilterInput>;
  isInstant: Maybe<BooleanOperationFilterInput>;
  levelNumPartialsPerTier: Maybe<IntOperationFilterInput>;
  levelPartialCost: Maybe<IntOperationFilterInput>;
  levelUpgradeTotalCost: Maybe<IntOperationFilterInput>;
  maxLevel: Maybe<IntOperationFilterInput>;
  maxUses: Maybe<IntOperationFilterInput>;
  maxUsesBoost: Maybe<IntOperationFilterInput>;
  maxUsesNumPartialsPerTier: Maybe<IntOperationFilterInput>;
  maxUsesPartialCost: Maybe<IntOperationFilterInput>;
  maxUsesUpgradeTotalCost: Maybe<IntOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<SkillDefinitionFilterInput>>;
  realmAffiliations: Maybe<ListRealmTypeOperationFilterInput>;
  summary: Maybe<StringOperationFilterInput>;
  targetingRule: Maybe<NullableOfTargetingRuleOperationFilterInput>;
  targetingStrategy: Maybe<NullableOfTargetingStrategyOperationFilterInput>;
  vfx: Maybe<VfxAssetFilterInput>;
  vfxId: Maybe<StringOperationFilterInput>;
};

export type SkillDefinitionSortInput = {
  animationId: Maybe<SortEnumType>;
  attacksOpponent: Maybe<SortEnumType>;
  baseUses: Maybe<SortEnumType>;
  defendsAlly: Maybe<SortEnumType>;
  description: Maybe<SortEnumType>;
  icon: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isInstant: Maybe<SortEnumType>;
  levelNumPartialsPerTier: Maybe<SortEnumType>;
  levelPartialCost: Maybe<SortEnumType>;
  levelUpgradeTotalCost: Maybe<SortEnumType>;
  maxLevel: Maybe<SortEnumType>;
  maxUses: Maybe<SortEnumType>;
  maxUsesBoost: Maybe<SortEnumType>;
  maxUsesNumPartialsPerTier: Maybe<SortEnumType>;
  maxUsesPartialCost: Maybe<SortEnumType>;
  maxUsesUpgradeTotalCost: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  summary: Maybe<SortEnumType>;
  targetingRule: Maybe<SortEnumType>;
  targetingStrategy: Maybe<SortEnumType>;
  vfx: Maybe<VfxAssetSortInput>;
  vfxId: Maybe<SortEnumType>;
};

export type SkillStatEffect = {
  __typename?: 'SkillStatEffect';
  animationId: Scalars['String'];
  attacksOpponent: Scalars['Boolean'];
  bonusStat: StatEffectBonus;
  /** 0 = neutral, 1 = buff, -1 = debuff */
  buffType: Scalars['Int'];
  canTargetAlly: Scalars['Boolean'];
  canTargetOpponent: Scalars['Boolean'];
  defendsAlly: Scalars['Boolean'];
  /** The effect can only be used from this position (empty=any) */
  fromPosition: FormationPosition;
  /** Icon asset */
  icon: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBuff: Scalars['Boolean'];
  isDamage: Scalars['Boolean'];
  isDeBuff: Scalars['Boolean'];
  isDefensive: Scalars['Boolean'];
  isHeal: Scalars['Boolean'];
  isHealthChange: Scalars['Boolean'];
  /** Derived from Seconds; if == 0 */
  isInstant: Scalars['Boolean'];
  isOffensive: Scalars['Boolean'];
  isTargetAlly: Scalars['Boolean'];
  isTargetOpponent: Scalars['Boolean'];
  /** At max upgrade / boost, how many uses? */
  maxUses: Scalars['Int'];
  /** What to do with the value -- add? subtract? */
  operand: EntityEffectOperand;
  /** If non-instant, in which phases of the turn does it (re)apply? */
  phase: BattleTurnPhase;
  /** Does this effect get applied every turn? */
  reApply: Scalars['Boolean'];
  /** negative = permanent, 0 = instant, > 1 is DoT or timed effect */
  seconds: Scalars['Int'];
  sfx: Maybe<SfxAsset>;
  sfxId: Maybe<Scalars['String']>;
  /** If this is a non-instant, can it be stacked with others of the same ID? */
  stackable: Scalars['Boolean'];
  /** What it affects; HP? AP? */
  stat: StatEffectTarget;
  /** Used for minion spawning skill (or other cases we need a full set of stats for some reason?) */
  stats: Maybe<BattleStats>;
  summary: Scalars['String'];
  summaryPosition: Maybe<Scalars['String']>;
  summaryStat: Scalars['String'];
  summaryStatTemplate: Scalars['String'];
  summaryTarget: Scalars['String'];
  /** Usually a selection/single-target, but could be AoE, Team, Self, etc. */
  target: SkillTarget;
  /** Number of entities to target (or in the case of AoE, the radius) */
  targetAmount: Scalars['Int'];
  /** Generally, which team does it target? */
  targetTeam: SkillTargetTeam;
  /** The effect can only target these positions (empty=any) */
  toPositions: Array<FormationPosition>;
  /** 0 = unlimited, > 0 = limited */
  uses: Scalars['Int'];
  /** The amount of damage, stat points, or whatever to apply */
  value: Scalars['Int'];
  /** Added to value, per each level */
  valuePerLevel: Scalars['Int'];
  vfx: Maybe<VfxAsset>;
  vfxId: Maybe<Scalars['String']>;
};

export type SkillStatEffectSummaryArgs = {
  level: Maybe<Scalars['Int']>;
  stats: Maybe<BattleStatsInput>;
};

export type SkillStatEffectFilterInput = {
  and: Maybe<Array<SkillStatEffectFilterInput>>;
  animationId: Maybe<StringOperationFilterInput>;
  attacksOpponent: Maybe<BooleanOperationFilterInput>;
  bonusStat: Maybe<StatEffectBonusOperationFilterInput>;
  /** 0 = neutral, 1 = buff, -1 = debuff */
  buffType: Maybe<IntOperationFilterInput>;
  canTargetAlly: Maybe<BooleanOperationFilterInput>;
  canTargetOpponent: Maybe<BooleanOperationFilterInput>;
  defendsAlly: Maybe<BooleanOperationFilterInput>;
  /** Parent skill definition */
  definition: Maybe<SkillDefinitionFilterInput>;
  /** The effect can only be used from this position (empty=any) */
  fromPosition: Maybe<FormationPositionOperationFilterInput>;
  /** Icon asset */
  icon: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isBuff: Maybe<BooleanOperationFilterInput>;
  isDamage: Maybe<BooleanOperationFilterInput>;
  isDeBuff: Maybe<BooleanOperationFilterInput>;
  isDefensive: Maybe<BooleanOperationFilterInput>;
  isHeal: Maybe<BooleanOperationFilterInput>;
  isHealthChange: Maybe<BooleanOperationFilterInput>;
  /** Derived from Seconds; if == 0 */
  isInstant: Maybe<BooleanOperationFilterInput>;
  isOffensive: Maybe<BooleanOperationFilterInput>;
  isTargetAlly: Maybe<BooleanOperationFilterInput>;
  isTargetOpponent: Maybe<BooleanOperationFilterInput>;
  /** At max upgrade / boost, how many uses? */
  maxUses: Maybe<IntOperationFilterInput>;
  /** What to do with the value -- add? subtract? */
  operand: Maybe<EntityEffectOperandOperationFilterInput>;
  or: Maybe<Array<SkillStatEffectFilterInput>>;
  /** If non-instant, in which phases of the turn does it (re)apply? */
  phase: Maybe<BattleTurnPhaseOperationFilterInput>;
  /** Does this effect get applied every turn? */
  reApply: Maybe<BooleanOperationFilterInput>;
  /** negative = permanent, 0 = instant, > 1 is DoT or timed effect */
  seconds: Maybe<IntOperationFilterInput>;
  sfx: Maybe<SfxAssetFilterInput>;
  sfxId: Maybe<StringOperationFilterInput>;
  /** If this is a non-instant, can it be stacked with others of the same ID? */
  stackable: Maybe<BooleanOperationFilterInput>;
  /** What it affects; HP? AP? */
  stat: Maybe<StatEffectTargetOperationFilterInput>;
  /** Used for minion spawning skill (or other cases we need a full set of stats for some reason?) */
  stats: Maybe<BattleStatsFilterInput>;
  summaryPosition: Maybe<StringOperationFilterInput>;
  summaryStat: Maybe<StringOperationFilterInput>;
  summaryStatTemplate: Maybe<StringOperationFilterInput>;
  summaryTarget: Maybe<StringOperationFilterInput>;
  /** Usually a selection/single-target, but could be AoE, Team, Self, etc. */
  target: Maybe<SkillTargetOperationFilterInput>;
  /** Number of entities to target (or in the case of AoE, the radius) */
  targetAmount: Maybe<IntOperationFilterInput>;
  /** Generally, which team does it target? */
  targetTeam: Maybe<SkillTargetTeamOperationFilterInput>;
  /** The effect can only target these positions (empty=any) */
  toPositions: Maybe<ListFormationPositionOperationFilterInput>;
  /** 0 = unlimited, > 0 = limited */
  uses: Maybe<IntOperationFilterInput>;
  /** The amount of damage, stat points, or whatever to apply */
  value: Maybe<IntOperationFilterInput>;
  /** Added to value, per each level */
  valuePerLevel: Maybe<IntOperationFilterInput>;
  vfx: Maybe<VfxAssetFilterInput>;
  vfxId: Maybe<StringOperationFilterInput>;
};

export enum SkillTarget {
  Area = 'AREA',
  Entity = 'ENTITY',
  Select = 'SELECT',
  Self = 'SELF',
  Team = 'TEAM',
}

export type SkillTargetOperationFilterInput = {
  eq: Maybe<SkillTarget>;
  in: Maybe<Array<SkillTarget>>;
  neq: Maybe<SkillTarget>;
  nin: Maybe<Array<SkillTarget>>;
};

export enum SkillTargetTeam {
  Ally = 'ALLY',
  Any = 'ANY',
  Opponent = 'OPPONENT',
}

export type SkillTargetTeamOperationFilterInput = {
  eq: Maybe<SkillTargetTeam>;
  in: Maybe<Array<SkillTargetTeam>>;
  neq: Maybe<SkillTargetTeam>;
  nin: Maybe<Array<SkillTargetTeam>>;
};

/** A connection to a list of items. */
export type SlotsConnection = {
  __typename?: 'SlotsConnection';
  /** A list of edges. */
  edges: Maybe<Array<SlotsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<LeaderboardSlot>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type SlotsEdge = {
  __typename?: 'SlotsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LeaderboardSlot;
};

export type SocialOAuthToken = {
  __typename?: 'SocialOAuthToken';
  accessToken: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  expiresIn: Scalars['Int'];
  isValid: Scalars['Boolean'];
  profile: SocialProfile;
  refreshToken: Scalars['String'];
  tokenType: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type SocialOAuthTokenFilterInput = {
  accessToken: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<SocialOAuthTokenFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  expiresIn: Maybe<IntOperationFilterInput>;
  isValid: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<SocialOAuthTokenFilterInput>>;
  profile: Maybe<SocialProfileFilterInput>;
  refreshToken: Maybe<StringOperationFilterInput>;
  tokenType: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type SocialProfile = {
  __typename?: 'SocialProfile';
  account: FurAccount;
  /** The player to whom it applies */
  accountId: Scalars['String'];
  avatar: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['String'];
  roles: Array<SocialRole>;
  /** The name of the social network or remote source */
  source: Scalars['String'];
  tokens: Array<SocialOAuthToken>;
  updatedAt: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
};

export type SocialProfileFilterInput = {
  account: Maybe<FurAccountFilterInput>;
  /** The player to whom it applies */
  accountId: Maybe<StringOperationFilterInput>;
  and: Maybe<Array<SocialProfileFilterInput>>;
  avatar: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  email: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<SocialProfileFilterInput>>;
  roles: Maybe<ListFilterInputTypeOfSocialRoleFilterInput>;
  /** The name of the social network or remote source */
  source: Maybe<StringOperationFilterInput>;
  tokens: Maybe<ListFilterInputTypeOfSocialOAuthTokenFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  username: Maybe<StringOperationFilterInput>;
};

export type SocialProfileSortInput = {
  account: Maybe<FurAccountSortInput>;
  /** The player to whom it applies */
  accountId: Maybe<SortEnumType>;
  avatar: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  email: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  /** The name of the social network or remote source */
  source: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  username: Maybe<SortEnumType>;
};

export type SocialRole = {
  __typename?: 'SocialRole';
  colorStr: Scalars['String'];
  communityFlag: Maybe<CommunityFlag>;
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
  socialProfile: SocialProfile;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type SocialRoleFilterInput = {
  and: Maybe<Array<SocialRoleFilterInput>>;
  colorStr: Maybe<StringOperationFilterInput>;
  communityFlag: Maybe<NullableOfCommunityFlagOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<SocialRoleFilterInput>>;
  socialProfile: Maybe<SocialProfileFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SpecialItem = {
  __typename?: 'SpecialItem';
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItem>;
  /** The parent container, if any */
  containerId: Maybe<Scalars['String']>;
  /** When was the item/stack created? */
  createdAt: Maybe<Scalars['DateTime']>;
  definition: ItemDefinition;
  /** Soft delete parameter */
  destroyedAt: Maybe<Scalars['DateTime']>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Scalars['Int'];
  gameBattlePvEId: Maybe<Scalars['String']>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Scalars['ID'];
  itemDefinition: Maybe<ItemDefinition>;
  itemDiscriminator: ItemDiscriminator;
  /** A top level grouping derived from the ItemId */
  itemGroup: ItemGroup;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Scalars['Long'];
  /** How many items can be stacked? */
  maxStack: Scalars['Int'];
  /** For the moment, just a passthrough to the item definition */
  name: Scalars['String'];
  playerPendingReward: Maybe<PlayerPendingReward>;
  playerPendingRewardId: Maybe<Scalars['String']>;
  /** The rarity level, derived from definition */
  rarity: RarityLevel;
  /** The realm affiliation, derived from definition */
  realmAffiliation: RealmType;
  /** How many items in this 'pile' of items? */
  stack: Scalars['Int'];
};

export type SpecialItemFilterInput = {
  and: Maybe<Array<SpecialItemFilterInput>>;
  /** May be the inventory of a player/furball */
  container: Maybe<ContainerItemFilterInput>;
  /** The parent container, if any */
  containerId: Maybe<StringOperationFilterInput>;
  /** When was the item/stack created? */
  createdAt: Maybe<DateTimeOperationFilterInput>;
  definition: Maybe<ItemDefinitionFilterInput>;
  /** Soft delete parameter */
  destroyedAt: Maybe<DateTimeOperationFilterInput>;
  /** A computed value representing how much value (in dust) this item would require to use as a crafting ingredient */
  dustValue: Maybe<IntOperationFilterInput>;
  gameBattlePvEId: Maybe<StringOperationFilterInput>;
  /** 1:1 with a Furball.id or FurPlayer.id */
  id: Maybe<StringOperationFilterInput>;
  itemDefinition: Maybe<ItemDefinitionFilterInput>;
  itemDiscriminator: Maybe<ItemDiscriminatorOperationFilterInput>;
  /** A top level grouping derived from the ItemId */
  itemGroup: Maybe<ItemGroupOperationFilterInput>;
  /** Long hex itemID which maps to artwork and general stats in the ItemLibrary */
  itemId: Maybe<LongOperationFilterInput>;
  /** How many items can be stacked? */
  maxStack: Maybe<IntOperationFilterInput>;
  /** For the moment, just a passthrough to the item definition */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<SpecialItemFilterInput>>;
  playerPendingReward: Maybe<PlayerPendingRewardFilterInput>;
  playerPendingRewardId: Maybe<StringOperationFilterInput>;
  /** The rarity level, derived from definition */
  rarity: Maybe<RarityLevelOperationFilterInput>;
  /** The realm affiliation, derived from definition */
  realmAffiliation: Maybe<RealmTypeOperationFilterInput>;
  /** How many items in this 'pile' of items? */
  stack: Maybe<IntOperationFilterInput>;
};

export enum Specialization {
  Defense = 'DEFENSE',
  Hero = 'HERO',
  None = 'NONE',
  Offense = 'OFFENSE',
}

export type SpecializationOperationFilterInput = {
  eq: Maybe<Specialization>;
  in: Maybe<Array<Specialization>>;
  neq: Maybe<Specialization>;
  nin: Maybe<Array<Specialization>>;
};

export enum StatEffectBonus {
  Buff = 'BUFF',
  DeBuff = 'DE_BUFF',
  Heal = 'HEAL',
  None = 'NONE',
}

export type StatEffectBonusOperationFilterInput = {
  eq: Maybe<StatEffectBonus>;
  in: Maybe<Array<StatEffectBonus>>;
  neq: Maybe<StatEffectBonus>;
  nin: Maybe<Array<StatEffectBonus>>;
};

export enum StatEffectTarget {
  AttackPower = 'ATTACK_POWER',
  Buff = 'BUFF',
  CritAttackPower = 'CRIT_ATTACK_POWER',
  CritRate = 'CRIT_RATE',
  Damage = 'DAMAGE',
  DefencePower = 'DEFENCE_POWER',
  DeBuff = 'DE_BUFF',
  Exp = 'EXP',
  Fur = 'FUR',
  Health = 'HEALTH',
  Luck = 'LUCK',
  MaxHealth = 'MAX_HEALTH',
  None = 'NONE',
  Pause = 'PAUSE',
  Revive = 'REVIVE',
  Spawn = 'SPAWN',
  Speed = 'SPEED',
  Vamp = 'VAMP',
}

export type StatEffectTargetOperationFilterInput = {
  eq: Maybe<StatEffectTarget>;
  in: Maybe<Array<StatEffectTarget>>;
  neq: Maybe<StatEffectTarget>;
  nin: Maybe<Array<StatEffectTarget>>;
};

/** A wrapper around Furballs & GameBosses (entities) that can participate in a BossBattle */
export type StatEntity = {
  __typename?: 'StatEntity';
  allowedMoveAt: Scalars['DateTime'];
  /** when a furball attacks, it does this much baseline damage to boss HP */
  attackPower: Scalars['Int'];
  /** [LL] the game entity */
  battleStats: Maybe<GameEntity>;
  /** this is the default BUFF stat; Buffs can be used to Buff AP, DP or Crit AP  */
  buff: Scalars['Int'];
  buffTotal: Scalars['Int'];
  clockPausedUntil: Maybe<Scalars['DateTime']>;
  /** his stat increases the damage that CRIT RATE does (represented as an additive #); if baseline CRIT AP from a crit strike is 50 and Furball gets an additional 50 AP from CRIT AP stat, total CRIT AP added to Furball’s AP is 100 AP */
  critAttackPower: Scalars['Int'];
  /** this stat is a % chance that the Furball can do a critical strike against boss; critical strike does 50% (baseline) more damage against boss as a baseline */
  critRate: Scalars['Int'];
  curHealth: Scalars['Long'];
  /** Used by battle computations to apply restricted effects */
  currentRealm: RealmType;
  currentStats: BattleStats;
  /** this is the default DEBUFF stat; Debuffs can be used to Debuff AP, DP or Crit AP */
  deBuff: Scalars['Int'];
  deBuffTotal: Scalars['Int'];
  /** this stat mitigates some of the boss damage (boss reduces less HP when your furball gets attacked by the boss) */
  defencePower: Scalars['Int'];
  /** [LL] the active effects */
  effects: Array<EntityEffect>;
  /** [LL] the equipped elixir */
  elixir: Maybe<ConsumableItem>;
  /** [DB] the ID of the equipped elixir */
  elixirId: Maybe<Scalars['String']>;
  /** [LL] the game entity */
  entity: Maybe<GameEntity>;
  entityId: Maybe<Scalars['String']>;
  /** [LL] the game entity's equipment (dropped when killed) */
  equipment: Array<EquipmentItem>;
  /** when a furball heals, this is how much HP it heals on a specific Furball */
  heal: Scalars['Int'];
  hearts: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isAi: Scalars['Boolean'];
  isBoss: Scalars['Boolean'];
  isFurball: Scalars['Boolean'];
  isMinion: Scalars['Boolean'];
  isMonster: Scalars['Boolean'];
  /** [LL] any items that are dropped when killed */
  itemQuantities: Array<ItemQuantity>;
  /** [LL] any items that are dropped when killed */
  items: Array<GameItem>;
  karma: Scalars['Int'];
  lastMoveAt: Maybe<Scalars['DateTime']>;
  level: Scalars['UnsignedInt'];
  loadedAt: Maybe<Scalars['DateTime']>;
  /** [LL] any items that are dropped when killed */
  loot: Array<GameItem>;
  /** [LL] any items that are dropped when killed */
  lootQuantities: Array<ItemQuantity>;
  /** furball’s life; bosses do dmg that reduces HP */
  maxHealth: Scalars['Long'];
  monsterDefinition: Maybe<MonsterDefinition>;
  monsterSlug: Maybe<Scalars['String']>;
  moveCount: Scalars['Int'];
  /** [LL] the next move */
  nextMove: Maybe<BattleMoveNext>;
  nextMoveAt: Maybe<Scalars['DateTime']>;
  partyId: Maybe<Scalars['String']>;
  passive: Scalars['String'];
  /** Passive effects this entity has; recomputed on zone/battle change */
  passives: Array<BattleEffectPassive>;
  pauseDuration: Scalars['Float'];
  pausedAt: Maybe<Scalars['DateTime']>;
  phase: BattleTurnPhase;
  position: FormationPosition;
  revive: Scalars['Int'];
  rewardDust: Scalars['Int'];
  rewardIntervals: Scalars['Float'];
  /** [LL] the skills the entity has */
  skills: Array<EntitySkill>;
  /** this stat is an integer that represents how quickly a Furball can make a move (how quickly the circle around the avatar fills) */
  speed: Scalars['Int'];
  vamp: Scalars['Int'];
  zone: Scalars['UnsignedInt'];
};

export type StatEntityFilterInput = {
  allowedMoveAt: Maybe<DateTimeOperationFilterInput>;
  and: Maybe<Array<StatEntityFilterInput>>;
  attackPower: Maybe<IntOperationFilterInput>;
  buff: Maybe<IntOperationFilterInput>;
  buffTotal: Maybe<IntOperationFilterInput>;
  clockPausedUntil: Maybe<DateTimeOperationFilterInput>;
  critAttackPower: Maybe<IntOperationFilterInput>;
  critRate: Maybe<IntOperationFilterInput>;
  curHealth: Maybe<LongOperationFilterInput>;
  /** Used by battle computations to apply restricted effects */
  currentRealm: Maybe<RealmTypeOperationFilterInput>;
  currentStats: Maybe<BattleStatsFilterInput>;
  deBuff: Maybe<IntOperationFilterInput>;
  deBuffTotal: Maybe<IntOperationFilterInput>;
  defencePower: Maybe<IntOperationFilterInput>;
  effects: Maybe<ListFilterInputTypeOfEntityEffectFilterInput>;
  /** [DB] the equipped elixir usable by the entity */
  elixir: Maybe<ConsumableItemFilterInput>;
  /** [DB] the ID of the equipped elixir */
  elixirId: Maybe<StringOperationFilterInput>;
  entity: Maybe<GameEntityFilterInput>;
  entityId: Maybe<StringOperationFilterInput>;
  heal: Maybe<IntOperationFilterInput>;
  hearts: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isAi: Maybe<BooleanOperationFilterInput>;
  isBoss: Maybe<BooleanOperationFilterInput>;
  isFurball: Maybe<BooleanOperationFilterInput>;
  isMinion: Maybe<BooleanOperationFilterInput>;
  isMonster: Maybe<BooleanOperationFilterInput>;
  karma: Maybe<IntOperationFilterInput>;
  lastMoveAt: Maybe<DateTimeOperationFilterInput>;
  loadedAt: Maybe<DateTimeOperationFilterInput>;
  maxHealth: Maybe<LongOperationFilterInput>;
  monsterDefinition: Maybe<MonsterDefinitionFilterInput>;
  monsterSlug: Maybe<StringOperationFilterInput>;
  moveCount: Maybe<IntOperationFilterInput>;
  nextMove: Maybe<BattleMoveNextFilterInput>;
  nextMoveAt: Maybe<DateTimeOperationFilterInput>;
  or: Maybe<Array<StatEntityFilterInput>>;
  party: Maybe<GamePartyFilterInput>;
  partyId: Maybe<StringOperationFilterInput>;
  passive: Maybe<StringOperationFilterInput>;
  /** Passive effects this entity has; recomputed on zone/battle change */
  passives: Maybe<ListFilterInputTypeOfBattleEffectPassiveFilterInput>;
  pauseDuration: Maybe<FloatOperationFilterInput>;
  pausedAt: Maybe<DateTimeOperationFilterInput>;
  phase: Maybe<BattleTurnPhaseOperationFilterInput>;
  position: Maybe<FormationPositionOperationFilterInput>;
  revive: Maybe<IntOperationFilterInput>;
  rewardDust: Maybe<IntOperationFilterInput>;
  rewardIntervals: Maybe<FloatOperationFilterInput>;
  skills: Maybe<ListFilterInputTypeOfEntitySkillFilterInput>;
  speed: Maybe<IntOperationFilterInput>;
  vamp: Maybe<IntOperationFilterInput>;
};

export type StatEntitySortInput = {
  allowedMoveAt: Maybe<SortEnumType>;
  attackPower: Maybe<SortEnumType>;
  buff: Maybe<SortEnumType>;
  buffTotal: Maybe<SortEnumType>;
  clockPausedUntil: Maybe<SortEnumType>;
  critAttackPower: Maybe<SortEnumType>;
  critRate: Maybe<SortEnumType>;
  curHealth: Maybe<SortEnumType>;
  /** Used by battle computations to apply restricted effects */
  currentRealm: Maybe<SortEnumType>;
  currentStats: Maybe<BattleStatsSortInput>;
  deBuff: Maybe<SortEnumType>;
  deBuffTotal: Maybe<SortEnumType>;
  defencePower: Maybe<SortEnumType>;
  /** [DB] the equipped elixir usable by the entity */
  elixir: Maybe<ConsumableItemSortInput>;
  /** [DB] the ID of the equipped elixir */
  elixirId: Maybe<SortEnumType>;
  entity: Maybe<GameEntitySortInput>;
  entityId: Maybe<SortEnumType>;
  heal: Maybe<SortEnumType>;
  hearts: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isAi: Maybe<SortEnumType>;
  isBoss: Maybe<SortEnumType>;
  isFurball: Maybe<SortEnumType>;
  isMinion: Maybe<SortEnumType>;
  isMonster: Maybe<SortEnumType>;
  karma: Maybe<SortEnumType>;
  lastMoveAt: Maybe<SortEnumType>;
  level: Maybe<SortEnumType>;
  loadedAt: Maybe<SortEnumType>;
  maxHealth: Maybe<SortEnumType>;
  monsterDefinition: Maybe<MonsterDefinitionSortInput>;
  monsterSlug: Maybe<SortEnumType>;
  moveCount: Maybe<SortEnumType>;
  nextMove: Maybe<BattleMoveNextSortInput>;
  nextMoveAt: Maybe<SortEnumType>;
  party: Maybe<GamePartySortInput>;
  partyId: Maybe<SortEnumType>;
  passive: Maybe<SortEnumType>;
  pauseDuration: Maybe<SortEnumType>;
  pausedAt: Maybe<SortEnumType>;
  phase: Maybe<SortEnumType>;
  position: Maybe<SortEnumType>;
  revive: Maybe<SortEnumType>;
  rewardDust: Maybe<SortEnumType>;
  rewardIntervals: Maybe<SortEnumType>;
  speed: Maybe<SortEnumType>;
  vamp: Maybe<SortEnumType>;
  zone: Maybe<SortEnumType>;
};

export type StringOperationFilterInput = {
  and: Maybe<Array<StringOperationFilterInput>>;
  contains: Maybe<Scalars['String']>;
  endsWith: Maybe<Scalars['String']>;
  eq: Maybe<Scalars['String']>;
  in: Maybe<Array<Maybe<Scalars['String']>>>;
  ncontains: Maybe<Scalars['String']>;
  nendsWith: Maybe<Scalars['String']>;
  neq: Maybe<Scalars['String']>;
  nin: Maybe<Array<Maybe<Scalars['String']>>>;
  nstartsWith: Maybe<Scalars['String']>;
  or: Maybe<Array<StringOperationFilterInput>>;
  startsWith: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  bossBattleGameLoop: BattleMoveResponse;
  gameBattleLoop: BattleMoveResponse;
  notify: Notification;
};

export type SubscriptionBossBattleGameLoopArgs = {
  bossBattlePlayerId: Scalars['String'];
};

export type SubscriptionGameBattleLoopArgs = {
  gameBattleId: Scalars['String'];
};

export type SubscriptionNotifyArgs = {
  playerId: Scalars['String'];
};

export enum TargetingRule {
  AllyTeamNotFull = 'ALLY_TEAM_NOT_FULL',
  EarlyGame = 'EARLY_GAME',
  EndGame = 'END_GAME',
  HasAllies = 'HAS_ALLIES',
  HasDamagedAllies = 'HAS_DAMAGED_ALLIES',
  HasDamagedAlly = 'HAS_DAMAGED_ALLY',
  HasDamagedOpponent = 'HAS_DAMAGED_OPPONENT',
  HasDeadAlly = 'HAS_DEAD_ALLY',
  HasDeadOpponent = 'HAS_DEAD_OPPONENT',
  HasDeBuff = 'HAS_DE_BUFF',
  HasMultipleDeBuffs = 'HAS_MULTIPLE_DE_BUFFS',
  LateGame = 'LATE_GAME',
  MidGame = 'MID_GAME',
  MultipleOpponents = 'MULTIPLE_OPPONENTS',
  None = 'NONE',
  NoAllies = 'NO_ALLIES',
  OpponentHasBuff = 'OPPONENT_HAS_BUFF',
  OpponentHasMultipleBuffed = 'OPPONENT_HAS_MULTIPLE_BUFFED',
  Overtime = 'OVERTIME',
  SoloOpponent = 'SOLO_OPPONENT',
}

export enum TargetingStrategy {
  AoE = 'AO_E',
  BackRow = 'BACK_ROW',
  Damaged = 'DAMAGED',
  FrontRow = 'FRONT_ROW',
  HighestAp = 'HIGHEST_AP',
  HighestCritAp = 'HIGHEST_CRIT_AP',
  HighestDp = 'HIGHEST_DP',
  HighestHp = 'HIGHEST_HP',
  LowestDp = 'LOWEST_DP',
  LowestHp = 'LOWEST_HP',
  MostBuffed = 'MOST_BUFFED',
}

export enum TierRewardType {
  EliteBossLoot = 'ELITE_BOSS_LOOT',
  EliteBossMaterials = 'ELITE_BOSS_MATERIALS',
  LegendaryBossLoot = 'LEGENDARY_BOSS_LOOT',
  LegendaryBossMaterials = 'LEGENDARY_BOSS_MATERIALS',
  Loot = 'LOOT',
  Mint = 'MINT',
  MythicBossLoot = 'MYTHIC_BOSS_LOOT',
  MythicBossMaterials = 'MYTHIC_BOSS_MATERIALS',
  NumRewardTypes = 'NUM_REWARD_TYPES',
}

export type TierRewardTypeOperationFilterInput = {
  eq: Maybe<TierRewardType>;
  in: Maybe<Array<TierRewardType>>;
  neq: Maybe<TierRewardType>;
  nin: Maybe<Array<TierRewardType>>;
};

/** A connection to a list of items. */
export type TiersConnection = {
  __typename?: 'TiersConnection';
  /** A list of edges. */
  edges: Maybe<Array<TiersEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<LeaderboardTier>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type TiersEdge = {
  __typename?: 'TiersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LeaderboardTier;
};

export type TimeSpanOperationFilterInput = {
  eq: Maybe<Scalars['TimeSpan']>;
  gt: Maybe<Scalars['TimeSpan']>;
  gte: Maybe<Scalars['TimeSpan']>;
  in: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  lt: Maybe<Scalars['TimeSpan']>;
  lte: Maybe<Scalars['TimeSpan']>;
  neq: Maybe<Scalars['TimeSpan']>;
  ngt: Maybe<Scalars['TimeSpan']>;
  ngte: Maybe<Scalars['TimeSpan']>;
  nin: Maybe<Array<Maybe<Scalars['TimeSpan']>>>;
  nlt: Maybe<Scalars['TimeSpan']>;
  nlte: Maybe<Scalars['TimeSpan']>;
};

export type TimekeeperAdHoc = {
  __typename?: 'TimekeeperAdHoc';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperAdHocArgsInput = {
  deadline: Scalars['Long'];
  fuelCost: Scalars['Int'];
  gained: Scalars['Int'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
};

export type TimekeeperArgsInput = {
  collectStayList: Scalars['String'];
  deadline: Scalars['Long'];
  fuelCost: Scalars['Int'];
  gained: Scalars['Int'];
  mintCount: Scalars['Short'];
  mintEdition: Scalars['Short'];
  movementList: Scalars['String'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
};

export type TimekeeperChangeSet = {
  __typename?: 'TimekeeperChangeSet';
  collectFur: Scalars['Boolean'];
  collectStayList: Scalars['String'];
  createdAt: Maybe<Scalars['DateTime']>;
  date: Scalars['Int'];
  deadline: Scalars['Long'];
  fuelCost: Scalars['Int'];
  furballIds: Array<Scalars['String']>;
  gained: Scalars['Int'];
  id: Scalars['String'];
  interactions: Array<TimekeeperInteraction>;
  mintCount: Scalars['Short'];
  mintEdition: Scalars['Short'];
  movementList: Scalars['String'];
  request: Maybe<TimekeeperRequest>;
  sender: Scalars['String'];
  spent: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type TimekeeperCosts = {
  __typename?: 'TimekeeperCosts';
  collectExp1: Scalars['Int'];
  collectExpN: Scalars['Int'];
  collectFur: Scalars['Int'];
  completeVoyage: Scalars['Int'];
  date: Scalars['Int'];
  dropLoot: Scalars['Int'];
  enterVoyages: Scalars['Int'];
  equipment: Scalars['UnsignedInt'];
  factor: Scalars['Int'];
  functionCall: Scalars['UnsignedInt'];
  gasPrice: Scalars['Int'];
  maxPlayersPerTransaction: Scalars['Int'];
  mint1: Scalars['Int'];
  mintN: Scalars['Int'];
  move1: Scalars['Int'];
  moveN: Scalars['Int'];
  progress: Scalars['UnsignedInt'];
  skill: Scalars['UnsignedInt'];
  snack1: Scalars['Int'];
  snackN: Scalars['Int'];
  spendFur: Scalars['Int'];
  spendTickets: Scalars['Int'];
  toggle1: Scalars['Int'];
  toggleN: Scalars['Int'];
  upgrade1: Scalars['Int'];
  upgradeN: Scalars['Int'];
  upgradeSkill: Scalars['Int'];
};

export type TimekeeperInstant = {
  __typename?: 'TimekeeperInstant';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperInstantFilterInput = {
  and: Maybe<Array<TimekeeperInstantFilterInput>>;
  /** If it's started executing, when that execution started */
  beganAt: Maybe<DateTimeOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  deadline: Maybe<LongOperationFilterInput>;
  delta: Maybe<FloatOperationFilterInput>;
  ethCost: Maybe<FloatOperationFilterInput>;
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNodeFilterInput>;
  executingHostName: Maybe<StringOperationFilterInput>;
  failureCount: Maybe<IntOperationFilterInput>;
  fuelCost: Maybe<LongOperationFilterInput>;
  gained: Maybe<IntOperationFilterInput>;
  gasEstimate: Maybe<LongOperationFilterInput>;
  gasPrice: Maybe<IntOperationFilterInput>;
  hasRun: Maybe<BooleanOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isAccepted: Maybe<BooleanOperationFilterInput>;
  or: Maybe<Array<TimekeeperInstantFilterInput>>;
  resolvedAt: Maybe<DateTimeOperationFilterInput>;
  reverted: Maybe<BooleanOperationFilterInput>;
  sender: Maybe<StringOperationFilterInput>;
  spent: Maybe<IntOperationFilterInput>;
  timekeeperRoundDate: Maybe<IntOperationFilterInput>;
  tixEth: Maybe<FloatOperationFilterInput>;
  transaction: Maybe<BlockchainTransactionFilterInput>;
  transactionHash: Maybe<StringOperationFilterInput>;
  transactionMessage: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  value: Maybe<LongOperationFilterInput>;
};

export type TimekeeperInstantSortInput = {
  /** If it's started executing, when that execution started */
  beganAt: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  deadline: Maybe<SortEnumType>;
  delta: Maybe<SortEnumType>;
  ethCost: Maybe<SortEnumType>;
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNodeSortInput>;
  executingHostName: Maybe<SortEnumType>;
  failureCount: Maybe<SortEnumType>;
  fuelCost: Maybe<SortEnumType>;
  gained: Maybe<SortEnumType>;
  gasEstimate: Maybe<SortEnumType>;
  gasPrice: Maybe<SortEnumType>;
  hasRun: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isAccepted: Maybe<SortEnumType>;
  resolvedAt: Maybe<SortEnumType>;
  reverted: Maybe<SortEnumType>;
  sender: Maybe<SortEnumType>;
  spent: Maybe<SortEnumType>;
  timekeeperRoundDate: Maybe<SortEnumType>;
  tixEth: Maybe<SortEnumType>;
  transaction: Maybe<BlockchainTransactionSortInput>;
  transactionHash: Maybe<SortEnumType>;
  transactionMessage: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  value: Maybe<SortEnumType>;
};

export type TimekeeperInteraction = {
  __typename?: 'TimekeeperInteraction';
  changeSet: TimekeeperChangeSet;
  changeSetId: Scalars['String'];
  count: Maybe<Scalars['Int']>;
  date: Scalars['Int'];
  expectedValue: Maybe<Scalars['Long']>;
  fuelCost: Scalars['Int'];
  furballFuel: Array<Scalars['Long']>;
  furballIds: Array<Scalars['String']>;
  gasEstimate: Scalars['Int'];
  id: Scalars['String'];
  interactionType: TimekeeperInteractionType;
  sender: Scalars['String'];
  sourceInteractionId: Maybe<Scalars['String']>;
};

export enum TimekeeperInteractionAction {
  Append = 'APPEND',
  Delete = 'DELETE',
  Replace = 'REPLACE',
}

export type TimekeeperInteractionArgInput = {
  action: TimekeeperInteractionAction;
  count: Maybe<Scalars['Int']>;
  expectedValue: Maybe<Scalars['Long']>;
  furballIds: Array<Scalars['String']>;
  gasEstimate: Scalars['Int'];
  interactionType: TimekeeperInteractionType;
  sender: Scalars['String'];
};

export enum TimekeeperInteractionType {
  CollectAllFur = 'COLLECT_ALL_FUR',
  CollectMove = 'COLLECT_MOVE',
  CollectStay = 'COLLECT_STAY',
  FeedSnack = 'FEED_SNACK',
  Mint = 'MINT',
  ShopPurchase = 'SHOP_PURCHASE',
  SkillUpgradeLevel = 'SKILL_UPGRADE_LEVEL',
  SkillUpgradeMaxUses = 'SKILL_UPGRADE_MAX_USES',
  UpgradeLoot = 'UPGRADE_LOOT',
  VoyageBegin = 'VOYAGE_BEGIN',
}

export type TimekeeperModeChange = {
  __typename?: 'TimekeeperModeChange';
  id: Scalars['String'];
  lastTimestamp: Scalars['Long'];
  toggle: TimekeeperToggle;
  tokenId: Scalars['String'];
};

export type TimekeeperRenameFurball = {
  __typename?: 'TimekeeperRenameFurball';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  /** [DB] the Furball whose name is changed */
  furball: Furball;
  /** [DB] the Furball whose name is changed */
  furballId: Scalars['String'];
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  name: Scalars['String'];
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperRenameFurballFilterInput = {
  and: Maybe<Array<TimekeeperRenameFurballFilterInput>>;
  /** If it's started executing, when that execution started */
  beganAt: Maybe<DateTimeOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  deadline: Maybe<LongOperationFilterInput>;
  delta: Maybe<FloatOperationFilterInput>;
  ethCost: Maybe<FloatOperationFilterInput>;
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNodeFilterInput>;
  executingHostName: Maybe<StringOperationFilterInput>;
  failureCount: Maybe<IntOperationFilterInput>;
  fuelCost: Maybe<LongOperationFilterInput>;
  /** [DB] the Furball whose name is changed */
  furball: Maybe<FurballFilterInput>;
  /** [DB] the Furball whose name is changed */
  furballId: Maybe<StringOperationFilterInput>;
  gained: Maybe<IntOperationFilterInput>;
  gasEstimate: Maybe<LongOperationFilterInput>;
  gasPrice: Maybe<IntOperationFilterInput>;
  hasRun: Maybe<BooleanOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  isAccepted: Maybe<BooleanOperationFilterInput>;
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<TimekeeperRenameFurballFilterInput>>;
  resolvedAt: Maybe<DateTimeOperationFilterInput>;
  reverted: Maybe<BooleanOperationFilterInput>;
  sender: Maybe<StringOperationFilterInput>;
  spent: Maybe<IntOperationFilterInput>;
  timekeeperRoundDate: Maybe<IntOperationFilterInput>;
  tixEth: Maybe<FloatOperationFilterInput>;
  transaction: Maybe<BlockchainTransactionFilterInput>;
  transactionHash: Maybe<StringOperationFilterInput>;
  transactionMessage: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  value: Maybe<LongOperationFilterInput>;
};

export type TimekeeperRenameFurballSortInput = {
  /** If it's started executing, when that execution started */
  beganAt: Maybe<SortEnumType>;
  createdAt: Maybe<SortEnumType>;
  deadline: Maybe<SortEnumType>;
  delta: Maybe<SortEnumType>;
  ethCost: Maybe<SortEnumType>;
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNodeSortInput>;
  executingHostName: Maybe<SortEnumType>;
  failureCount: Maybe<SortEnumType>;
  fuelCost: Maybe<SortEnumType>;
  /** [DB] the Furball whose name is changed */
  furball: Maybe<FurballSortInput>;
  /** [DB] the Furball whose name is changed */
  furballId: Maybe<SortEnumType>;
  gained: Maybe<SortEnumType>;
  gasEstimate: Maybe<SortEnumType>;
  gasPrice: Maybe<SortEnumType>;
  hasRun: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  isAccepted: Maybe<SortEnumType>;
  name: Maybe<SortEnumType>;
  resolvedAt: Maybe<SortEnumType>;
  reverted: Maybe<SortEnumType>;
  sender: Maybe<SortEnumType>;
  spent: Maybe<SortEnumType>;
  timekeeperRoundDate: Maybe<SortEnumType>;
  tixEth: Maybe<SortEnumType>;
  transaction: Maybe<BlockchainTransactionSortInput>;
  transactionHash: Maybe<SortEnumType>;
  transactionMessage: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
  value: Maybe<SortEnumType>;
};

export type TimekeeperRequest = {
  __typename?: 'TimekeeperRequest';
  changeSet: Maybe<TimekeeperChangeSet>;
  changes: Array<TimekeeperChangeSet>;
  computedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  date: Scalars['Int'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  fuelCost: Scalars['Long'];
  furGained: Maybe<Scalars['Int']>;
  furReal: Scalars['Long'];
  furSpent: Maybe<Scalars['Int']>;
  furballIds: Array<Scalars['String']>;
  gasIncremental: Scalars['Long'];
  hasChanges: Scalars['Boolean'];
  isComplete: Scalars['Boolean'];
  isPending: Scalars['Boolean'];
  numFurballs: Scalars['Int'];
  partialIndex: Scalars['Short'];
  ratio: Scalars['Float'];
  resolutions: Array<TimekeeperResolution>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  round: TimekeeperRound;
  sender: Scalars['String'];
  signedRequest: Maybe<TimekeeperChangeSet>;
  state: Scalars['Short'];
  tixEth: Scalars['Float'];
  transaction: Maybe<TimekeeperTransaction>;
  transactionId: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type TimekeeperResolution = {
  __typename?: 'TimekeeperResolution';
  chainDropItem: Maybe<Scalars['Long']>;
  completedAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  date: Scalars['Int'];
  energy: Scalars['Int'];
  expGained: Scalars['Int'];
  furGained: Scalars['Int'];
  happiness: Scalars['Int'];
  hasBlockchainData: Scalars['Boolean'];
  intervals: Scalars['Float'];
  isEmpty: Scalars['Boolean'];
  isMove: Scalars['Boolean'];
  isTimestampedAction: Scalars['Boolean'];
  itemList: Scalars['String'];
  lootItems: Array<Scalars['Long']>;
  offChainDropIds: Array<Scalars['String']>;
  request: TimekeeperRequest;
  round: TimekeeperRound;
  scaledIntervals: Scalars['Float'];
  sender: Scalars['String'];
  skillLevelUpgradeMap: Array<KeyValuePairOfInt64AndInt32>;
  skillLevelUpgrades: Maybe<Scalars['String']>;
  skillUsesUpgradeMap: Array<KeyValuePairOfInt64AndInt32>;
  skillUsesUpgrades: Maybe<Scalars['String']>;
  snackStacks: Array<Scalars['Long']>;
  snackStacksList: Scalars['String'];
  tokenId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upgraded: Maybe<Scalars['Long']>;
  zoneKind: Maybe<Scalars['Long']>;
  zoneNumber: Maybe<Scalars['Long']>;
};

export type TimekeeperRound = {
  __typename?: 'TimekeeperRound';
  costs: TimekeeperCosts;
  createdAt: Maybe<Scalars['DateTime']>;
  date: Scalars['Int'];
  delta: Scalars['Float'];
  deltaI: Scalars['Float'];
  deltaP: Scalars['Float'];
  deltaT: Scalars['Float'];
  detectedAt: Maybe<Scalars['DateTime']>;
  ethCost: Scalars['Float'];
  ethCostI: Scalars['Float'];
  ethCostT: Scalars['Float'];
  ethPrice: Scalars['Int'];
  fuelCost: Scalars['Long'];
  /** The upgrades being done on all Furballs */
  furballUpgrades: Array<FurballUpgrade>;
  gasCheckCount: Scalars['Int'];
  gasPrice: Scalars['Int'];
  gasUsed: Scalars['Long'];
  instants: Array<TimekeeperInstant>;
  instantsCompleted: Array<TimekeeperInstant>;
  instantsCount: Scalars['Int'];
  isComplete: Scalars['Boolean'];
  isPending: Scalars['Boolean'];
  processing: Scalars['Boolean'];
  requests: Array<TimekeeperRequest>;
  requestsAccepted: Array<TimekeeperRequest>;
  requestsAcceptedCount: Scalars['Int'];
  requestsPending: Array<TimekeeperRequest>;
  requestsPendingCount: Scalars['Int'];
  startedAt: Maybe<Scalars['DateTime']>;
  startedLastAt: Maybe<Scalars['DateTime']>;
  state: Scalars['Short'];
  tixEth: Scalars['Float'];
  tixEthI: Scalars['Float'];
  tixEthT: Scalars['Float'];
  transactionId: Maybe<Scalars['String']>;
  transactions: Array<TimekeeperTransaction>;
  transactionsCount: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type TimekeeperToggle = {
  __typename?: 'TimekeeperToggle';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  furballIds: Array<Scalars['String']>;
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  mode: Scalars['Short'];
  modeChanges: Array<TimekeeperModeChange>;
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperDisabled: Scalars['Boolean'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperToggleArgsInput = {
  deadline: Scalars['Long'];
  furballList: Scalars['String'];
  sender: Scalars['String'];
  timekeeperDisabled: Scalars['Boolean'];
};

export type TimekeeperTransaction = {
  __typename?: 'TimekeeperTransaction';
  blockNumber: Scalars['Long'];
  contractAddress: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  cumulativeGasUsed: Scalars['Long'];
  delta: Scalars['Float'];
  effectiveGasPrice: Scalars['Long'];
  ethCost: Scalars['Float'];
  fuelCost: Scalars['Long'];
  gasEstimate: Maybe<Scalars['Long']>;
  gasProjection: Scalars['Long'];
  gasUsed: Scalars['Long'];
  gweiToGas: Scalars['Float'];
  hash: Scalars['String'];
  id: Scalars['String'];
  index: Scalars['Long'];
  instant: Maybe<TimekeeperInstant>;
  logsBloom: Scalars['String'];
  name: Scalars['String'];
  ratio: Scalars['Float'];
  requests: Array<TimekeeperRequest>;
  round: TimekeeperRound;
  status: Scalars['Long'];
  tixEth: Scalars['Float'];
  type: Scalars['Long'];
  updatedAt: Maybe<Scalars['DateTime']>;
  upgrades: Array<FurballUpgrade>;
};

export type TimekeeperTransferTix = {
  __typename?: 'TimekeeperTransferTix';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  recipient: Scalars['String'];
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperTransferTixArgsInput = {
  deadline: Scalars['Long'];
  recipient: Scalars['String'];
  sender: Scalars['String'];
};

export type TimekeeperUserState = {
  __typename?: 'TimekeeperUserState';
  current: Array<TimekeeperRequest>;
  pending: Array<TimekeeperRequest>;
  requestsOnDate: Array<TimekeeperRequest>;
  round: TimekeeperRound;
  sender: Scalars['String'];
};

export type TimekeeperUserStateRequestsOnDateArgs = {
  date: Scalars['Int'];
};

export type TimekeeperWrapFur = {
  __typename?: 'TimekeeperWrapFur';
  /** If it's started executing, when that execution started */
  beganAt: Maybe<Scalars['DateTime']>;
  createdAt: Maybe<Scalars['DateTime']>;
  deadline: Scalars['Long'];
  delta: Scalars['Float'];
  ethCost: Scalars['Float'];
  /** If it's started executing, the host which is executing */
  executingHost: Maybe<HostNode>;
  executingHostName: Maybe<Scalars['String']>;
  failureCount: Scalars['Int'];
  fuelCost: Scalars['Long'];
  gained: Scalars['Int'];
  gasEstimate: Scalars['Long'];
  gasPrice: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  id: Scalars['String'];
  isAccepted: Scalars['Boolean'];
  resolvedAt: Maybe<Scalars['DateTime']>;
  reverted: Scalars['Boolean'];
  sender: Scalars['String'];
  spent: Scalars['Int'];
  timekeeperRoundDate: Scalars['Int'];
  tixEth: Scalars['Float'];
  transaction: Maybe<BlockchainTransaction>;
  transactionHash: Maybe<Scalars['String']>;
  transactionMessage: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['DateTime']>;
  value: Scalars['Long'];
};

export type TimekeeperWrapFurArgsInput = {
  amount: Scalars['Long'];
  deadline: Scalars['Long'];
  sender: Scalars['String'];
};

export type TixExchange = {
  __typename?: 'TixExchange';
  costBuy: CurrencyCosts;
};

export type TokenModelOneToOne = {
  __typename?: 'TokenModelOneToOne';
  createdAt: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export enum TokenSchema {
  Erc20 = 'ERC20',
  Erc721 = 'ERC721',
}

export type TokenSchemaOperationFilterInput = {
  eq: Maybe<TokenSchema>;
  in: Maybe<Array<TokenSchema>>;
  neq: Maybe<TokenSchema>;
  nin: Maybe<Array<TokenSchema>>;
};

export type Tournament = {
  __typename?: 'Tournament';
  /** Tournament BK color */
  backgroundColor: Maybe<Scalars['String']>;
  currentParticipant: Maybe<TournamentParticipant>;
  /** Required; exact time which it ends */
  endsAt: Scalars['DateTime'];
  /** How much FUEL it costs to enter */
  entryFeeFuel: Scalars['Long'];
  /** How much TIX it costs to enter */
  entryFeeTix: Scalars['Long'];
  /** How much each entry contributes, based on the fuel cost, to ETH spent by players */
  ethPerEntry: Scalars['Float'];
  /** The exact multiplier of how much goes to the pool */
  ethPoolFactor: Scalars['Float'];
  /** At the time of spending, how much ETH in TOTAL was spent on TIX? */
  ethSpentViaTix: Scalars['Float'];
  /** The image URL to the hero */
  heroUrl: Scalars['String'];
  /** Required; derived from slug-ification of Name */
  id: Scalars['String'];
  isParticipant: Scalars['Boolean'];
  /** [parent] The leaderboard to which the tournament belongs */
  leaderboard: Leaderboard;
  leaderboardId: Scalars['String'];
  /** Required; used as a unique key (slug) too */
  name: Scalars['String'];
  /** Accessor for Participants.Count */
  participantCount: Scalars['Int'];
  /** All the participants in the tournament (players) */
  participants: Array<TournamentParticipant>;
  /** How much ETH will be paid out to winners? */
  payoutPoolEth: Scalars['Float'];
  /** If provided, only this realm applies to scoring */
  realm: Maybe<RealmType>;
  /** How the tournament is scored */
  scoringStyle: TournamentScoringStyle;
  /** Required; exact time which it starts */
  startsAt: Scalars['DateTime'];
};

export type TournamentCurrentParticipantArgs = {
  playerId: Maybe<Scalars['String']>;
};

export type TournamentFilterInput = {
  and: Maybe<Array<TournamentFilterInput>>;
  /** Tournament BK color */
  backgroundColor: Maybe<StringOperationFilterInput>;
  /** Required; exact time which it ends */
  endsAt: Maybe<DateTimeOperationFilterInput>;
  /** How much FUEL it costs to enter */
  entryFeeFuel: Maybe<LongOperationFilterInput>;
  /** How much TIX it costs to enter */
  entryFeeTix: Maybe<LongOperationFilterInput>;
  /** How much each entry contributes, based on the fuel cost, to ETH spent by players */
  ethPerEntry: Maybe<FloatOperationFilterInput>;
  /** The exact multiplier of how much goes to the pool */
  ethPoolFactor: Maybe<FloatOperationFilterInput>;
  /** At the time of spending, how much ETH in TOTAL was spent on TIX? */
  ethSpentViaTix: Maybe<FloatOperationFilterInput>;
  /** The image URL to the hero */
  heroUrl: Maybe<StringOperationFilterInput>;
  /** Required; derived from slug-ification of Name */
  id: Maybe<StringOperationFilterInput>;
  /** [parent] The leaderboard to which the tournament belongs */
  leaderboard: Maybe<LeaderboardFilterInput>;
  leaderboardId: Maybe<StringOperationFilterInput>;
  /** Required; used as a unique key (slug) too */
  name: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<TournamentFilterInput>>;
  /** Accessor for Participants.Count */
  participantCount: Maybe<IntOperationFilterInput>;
  /** All the participants in the tournament (players) */
  participants: Maybe<ListFilterInputTypeOfTournamentParticipantFilterInput>;
  /** How much ETH will be paid out to winners? */
  payoutPoolEth: Maybe<FloatOperationFilterInput>;
  /** If provided, only this realm applies to scoring */
  realm: Maybe<NullableOfRealmTypeOperationFilterInput>;
  /** How the tournament is scored */
  scoringStyle: Maybe<TournamentScoringStyleOperationFilterInput>;
  /** Required; exact time which it starts */
  startsAt: Maybe<DateTimeOperationFilterInput>;
};

export type TournamentParticipant = {
  __typename?: 'TournamentParticipant';
  createdAt: Maybe<Scalars['DateTime']>;
  /** How much ETH was earned during the tournament */
  ethEarned: Scalars['Float'];
  id: Scalars['String'];
  /** The player who is participating */
  player: FurPlayer;
  playerId: Scalars['String'];
  /** All rewards earned during the tournament */
  rewards: Array<PlayerPendingReward>;
  /** The total number of points/score earned in the tournament */
  totalScore: Scalars['UnsignedLong'];
  /** The tournament to which it belongs */
  tournament: Tournament;
  tournamentId: Scalars['String'];
  /** If ETH has been paid out, this is the TXN id */
  transaction: Maybe<BlockchainTransaction>;
  transactionId: Maybe<Scalars['String']>;
  /** How much WFur was earned during the tournament */
  wFurEarned: Scalars['Long'];
};

export type TournamentParticipantFilterInput = {
  and: Maybe<Array<TournamentParticipantFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  /** How much ETH was earned during the tournament */
  ethEarned: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<TournamentParticipantFilterInput>>;
  /** The player who is participating */
  player: Maybe<FurPlayerFilterInput>;
  playerId: Maybe<StringOperationFilterInput>;
  /** All rewards earned during the tournament */
  rewards: Maybe<ListFilterInputTypeOfPlayerPendingRewardFilterInput>;
  /** The tournament to which it belongs */
  tournament: Maybe<TournamentFilterInput>;
  tournamentId: Maybe<StringOperationFilterInput>;
  /** If ETH has been paid out, this is the TXN id */
  transaction: Maybe<BlockchainTransactionFilterInput>;
  transactionId: Maybe<StringOperationFilterInput>;
  /** How much WFur was earned during the tournament */
  wFurEarned: Maybe<LongOperationFilterInput>;
};

export type TournamentRewardArgsInput = {
  capacity: Maybe<Scalars['Int']>;
  dust: Maybe<Scalars['Int']>;
  fur: Maybe<Scalars['Int']>;
  itemIds: Maybe<Array<ItemDefInput>>;
  split: Maybe<Scalars['Float']>;
};

export enum TournamentScoringStyle {
  /** Maximum number of damage done by the boss */
  MaxNormalizedDamageFromBoss = 'MAX_NORMALIZED_DAMAGE_FROM_BOSS',
  /** Maximum number of damage done by furballs */
  MaxNormalizedDamageFromPlayer = 'MAX_NORMALIZED_DAMAGE_FROM_PLAYER',
  /** Maximum number of damage done by furballs, divided by number of turns */
  MaxNormalizedDamageFromPlayerPerTurn = 'MAX_NORMALIZED_DAMAGE_FROM_PLAYER_PER_TURN',
  /** Maximum number of (normalized) points in any ONE battle */
  MaxNormalizedScore = 'MAX_NORMALIZED_SCORE',
  /** Maximum done PER TURN by the player */
  MaxPlayerDamagePerTurn = 'MAX_PLAYER_DAMAGE_PER_TURN',
  /** Top3 battles' points are divided by number of party members */
  TopNormalizedScore = 'TOP_NORMALIZED_SCORE',
}

export type TournamentScoringStyleOperationFilterInput = {
  eq: Maybe<TournamentScoringStyle>;
  in: Maybe<Array<TournamentScoringStyle>>;
  neq: Maybe<TournamentScoringStyle>;
  nin: Maybe<Array<TournamentScoringStyle>>;
};

export type UInt32FilterInput = {
  and: Maybe<Array<UInt32FilterInput>>;
  or: Maybe<Array<UInt32FilterInput>>;
};

export type UInt64FilterInput = {
  and: Maybe<Array<UInt64FilterInput>>;
  or: Maybe<Array<UInt64FilterInput>>;
};

/** A connection to a list of items. */
export type UnreadNotificationsConnection = {
  __typename?: 'UnreadNotificationsConnection';
  /** A list of edges. */
  edges: Maybe<Array<UnreadNotificationsEdge>>;
  /** A flattened list of the nodes. */
  nodes: Maybe<Array<Notification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Identifies the total count of items in the connection. */
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type UnreadNotificationsEdge = {
  __typename?: 'UnreadNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Notification;
};

export enum UsageFrequency {
  Guaranteed = 'GUARANTEED',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Never = 'NEVER',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW',
}

export type UsageFrequencyOperationFilterInput = {
  eq: Maybe<UsageFrequency>;
  in: Maybe<Array<UsageFrequency>>;
  neq: Maybe<UsageFrequency>;
  nin: Maybe<Array<UsageFrequency>>;
};

export type VfxAsset = {
  __typename?: 'VfxAsset';
  duration: Scalars['Float'];
  id: Scalars['String'];
};

export type VfxAssetFilterInput = {
  and: Maybe<Array<VfxAssetFilterInput>>;
  duration: Maybe<FloatOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<VfxAssetFilterInput>>;
};

export type VfxAssetSortInput = {
  duration: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
};

export type Voyage = {
  __typename?: 'Voyage';
  activeEncounter: Maybe<VoyageEncounter>;
  createdAt: Maybe<Scalars['DateTime']>;
  daysComplete: Scalars['Int'];
  daysDuration: Scalars['Int'];
  daysRemaining: Scalars['Int'];
  /** [LL] the furball on the voyage */
  encounters: Array<VoyageEncounter>;
  /** [LL] the total fuel spent in voyage */
  encountersFuelCost: Scalars['Int'];
  /** [LL] the total FUR gained in all encounters */
  encountersFurGained: Scalars['Int'];
  /** [LL] the furball on the voyage */
  furball: Furball;
  /** The Furball token ID */
  furballId: Scalars['String'];
  id: Scalars['String'];
  lastEncounter: Maybe<VoyageEncounter>;
  /** [LL] the max loot items it can return */
  maxLootItems: Scalars['Int'];
  percentComplete: Scalars['Float'];
  realm: RealmType;
  /** [LL] the scorce boost in current realm */
  realmBoost: Scalars['Int'];
  sender: Scalars['String'];
  state: VoyageState;
  /** [LL] the total fur cost including all voyages */
  totalFurCost: Scalars['Int'];
  updatedAt: Maybe<Scalars['DateTime']>;
};

export type VoyageEncounter = {
  __typename?: 'VoyageEncounter';
  broadcastedOutcome: Maybe<Scalars['String']>;
  createdAt: Maybe<Scalars['DateTime']>;
  date: Scalars['Int'];
  decision: Maybe<Scalars['Int']>;
  didPlayerComplete: Scalars['Boolean'];
  encounterType: VoyageEncounterType;
  fuelCost: Maybe<Scalars['Int']>;
  furCost: Maybe<Scalars['Int']>;
  furGained: Scalars['Int'];
  id: Scalars['String'];
  itemGained: Scalars['Long'];
  match: Maybe<VoyageEncounter>;
  matchedEncounterId: Maybe<Scalars['String']>;
  outcome: Maybe<Scalars['Int']>;
  sender: Scalars['String'];
  subType: Maybe<Scalars['Int']>;
  submitScore: VoyageEncounter;
  tokenId: Scalars['String'];
  updatedAt: Maybe<Scalars['DateTime']>;
  voyage: Voyage;
  voyageDayNumber: Scalars['Int'];
  voyageId: Scalars['String'];
};

export type VoyageEncounterSubmitScoreArgs = {
  decision: Scalars['Int'];
  signature: Maybe<Scalars['String']>;
};

export type VoyageEncounterFilterInput = {
  and: Maybe<Array<VoyageEncounterFilterInput>>;
  broadcastedOutcome: Maybe<StringOperationFilterInput>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  date: Maybe<IntOperationFilterInput>;
  decision: Maybe<IntOperationFilterInput>;
  didPlayerComplete: Maybe<BooleanOperationFilterInput>;
  encounterType: Maybe<VoyageEncounterTypeOperationFilterInput>;
  fuelCost: Maybe<IntOperationFilterInput>;
  furCost: Maybe<IntOperationFilterInput>;
  furGained: Maybe<IntOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  itemGained: Maybe<LongOperationFilterInput>;
  matchedEncounterId: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<VoyageEncounterFilterInput>>;
  outcome: Maybe<IntOperationFilterInput>;
  sender: Maybe<StringOperationFilterInput>;
  subType: Maybe<IntOperationFilterInput>;
  tokenId: Maybe<StringOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
  voyage: Maybe<VoyageFilterInput>;
  voyageDayNumber: Maybe<IntOperationFilterInput>;
  voyageId: Maybe<StringOperationFilterInput>;
};

export enum VoyageEncounterType {
  Fur = 'FUR',
  Loot = 'LOOT',
}

export type VoyageEncounterTypeOperationFilterInput = {
  eq: Maybe<VoyageEncounterType>;
  in: Maybe<Array<VoyageEncounterType>>;
  neq: Maybe<VoyageEncounterType>;
  nin: Maybe<Array<VoyageEncounterType>>;
};

export type VoyageFilterInput = {
  and: Maybe<Array<VoyageFilterInput>>;
  createdAt: Maybe<DateTimeOperationFilterInput>;
  daysComplete: Maybe<IntOperationFilterInput>;
  daysDuration: Maybe<IntOperationFilterInput>;
  daysRemaining: Maybe<IntOperationFilterInput>;
  encounters: Maybe<ListFilterInputTypeOfVoyageEncounterFilterInput>;
  furball: Maybe<FurballFilterInput>;
  /** The Furball token ID */
  furballId: Maybe<StringOperationFilterInput>;
  id: Maybe<StringOperationFilterInput>;
  or: Maybe<Array<VoyageFilterInput>>;
  percentComplete: Maybe<FloatOperationFilterInput>;
  realm: Maybe<RealmTypeOperationFilterInput>;
  sender: Maybe<StringOperationFilterInput>;
  state: Maybe<VoyageStateOperationFilterInput>;
  updatedAt: Maybe<DateTimeOperationFilterInput>;
};

export type VoyageSortInput = {
  createdAt: Maybe<SortEnumType>;
  daysComplete: Maybe<SortEnumType>;
  daysDuration: Maybe<SortEnumType>;
  daysRemaining: Maybe<SortEnumType>;
  furball: Maybe<FurballSortInput>;
  /** The Furball token ID */
  furballId: Maybe<SortEnumType>;
  id: Maybe<SortEnumType>;
  percentComplete: Maybe<SortEnumType>;
  realm: Maybe<SortEnumType>;
  sender: Maybe<SortEnumType>;
  state: Maybe<SortEnumType>;
  updatedAt: Maybe<SortEnumType>;
};

export enum VoyageState {
  Complete = 'COMPLETE',
  Exited = 'EXITED',
  Quit = 'QUIT',
  Started = 'STARTED',
}

export type VoyageStateOperationFilterInput = {
  eq: Maybe<VoyageState>;
  in: Maybe<Array<VoyageState>>;
  neq: Maybe<VoyageState>;
  nin: Maybe<Array<VoyageState>>;
};

export enum WaitlistFlag {
  Scholar = 'SCHOLAR',
}

export type WaitlistFlagOperationFilterInput = {
  eq: Maybe<WaitlistFlag>;
  in: Maybe<Array<WaitlistFlag>>;
  neq: Maybe<WaitlistFlag>;
  nin: Maybe<Array<WaitlistFlag>>;
};

export enum WaypointStyle {
  Optional = 'OPTIONAL',
  RequiredAnyOrder = 'REQUIRED_ANY_ORDER',
  RequiredInOrder = 'REQUIRED_IN_ORDER',
}

export type WaypointStyleOperationFilterInput = {
  eq: Maybe<WaypointStyle>;
  in: Maybe<Array<WaypointStyle>>;
  neq: Maybe<WaypointStyle>;
  nin: Maybe<Array<WaypointStyle>>;
};

export enum WorkLockState {
  Locked = 'LOCKED',
  None = 'NONE',
  Unlocked = 'UNLOCKED',
}

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> =
  | LegacyStitchingResolver<TResult, TParent, TContext, TArgs>
  | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<
    { [key in TKey]: TResult },
    TParent,
    TContext,
    TArgs
  >;
  resolve?: SubscriptionResolveFn<
    TResult,
    { [key in TKey]: TResult },
    TContext,
    TArgs
  >;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((
      ...args: any[]
    ) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo,
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo,
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<
  TResult = {},
  TParent = {},
  TContext = {},
  TArgs = {},
> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo,
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountsConnection: ResolverTypeWrapper<AccountsConnection>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  AccountsEdge: ResolverTypeWrapper<AccountsEdge>;
  String: ResolverTypeWrapper<Scalars['String']>;
  AirdropRequest: ResolverTypeWrapper<AirdropRequest>;
  AirdropRequestArgsInput: AirdropRequestArgsInput;
  AnimationAsset: ResolverTypeWrapper<AnimationAsset>;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  AnimationAssetFilterInput: AnimationAssetFilterInput;
  AnimationAssetInput: AnimationAssetInput;
  AnimationAssetSortInput: AnimationAssetSortInput;
  AnteInput: AnteInput;
  AntePool: ResolverTypeWrapper<AntePool>;
  AntePoolFilterInput: AntePoolFilterInput;
  AntePoolSortInput: AntePoolSortInput;
  AppleOAuthDTOInput: AppleOAuthDtoInput;
  ApplyPolicy: ApplyPolicy;
  AuctionKind: AuctionKind;
  AuctionOutcome: AuctionOutcome;
  BattleEffectPassive: ResolverTypeWrapper<BattleEffectPassive>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  BattleEffectPassiveFilterInput: BattleEffectPassiveFilterInput;
  BattleMove: ResolverTypeWrapper<
    Omit<BattleMove, 'item'> & { item: Maybe<ResolversTypes['GameItem']> }
  >;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BattleMoveFilterInput: BattleMoveFilterInput;
  BattleMoveKind: BattleMoveKind;
  BattleMoveKindOperationFilterInput: BattleMoveKindOperationFilterInput;
  BattleMoveNext: ResolverTypeWrapper<BattleMoveNext>;
  BattleMoveNextFilterInput: BattleMoveNextFilterInput;
  BattleMoveNextSortInput: BattleMoveNextSortInput;
  BattleMoveOutcome: ResolverTypeWrapper<BattleMoveOutcome>;
  BattleMoveOutcomeFilterInput: BattleMoveOutcomeFilterInput;
  BattleMoveRequestInput: BattleMoveRequestInput;
  BattleMoveResponse: ResolverTypeWrapper<BattleMoveResponse>;
  BattlePercentiles: ResolverTypeWrapper<BattlePercentiles>;
  BattleStats: ResolverTypeWrapper<BattleStats>;
  BattleStatsFilterInput: BattleStatsFilterInput;
  BattleStatsInput: BattleStatsInput;
  BattleStatsSortInput: BattleStatsSortInput;
  BattleTurnPhase: BattleTurnPhase;
  BattleTurnPhaseOperationFilterInput: BattleTurnPhaseOperationFilterInput;
  BattlesConnection: ResolverTypeWrapper<BattlesConnection>;
  BattlesEdge: ResolverTypeWrapper<BattlesEdge>;
  BlockWork: ResolverTypeWrapper<BlockWork>;
  BlockchainModel: ResolverTypeWrapper<BlockchainModel>;
  BlockchainToken: ResolverTypeWrapper<BlockchainToken>;
  BlockchainTokenFilterInput: BlockchainTokenFilterInput;
  BlockchainTokenSortInput: BlockchainTokenSortInput;
  BlockchainTokenTransaction: ResolverTypeWrapper<BlockchainTokenTransaction>;
  BlockchainTokenTransactionFilterInput: BlockchainTokenTransactionFilterInput;
  BlockchainTokenTransactionSortInput: BlockchainTokenTransactionSortInput;
  BlockchainTransaction: ResolverTypeWrapper<BlockchainTransaction>;
  BlockchainTransactionFilterInput: BlockchainTransactionFilterInput;
  BlockchainTransactionSortInput: BlockchainTransactionSortInput;
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  BossBattlesConnection: ResolverTypeWrapper<BossBattlesConnection>;
  BossBattlesEdge: ResolverTypeWrapper<BossBattlesEdge>;
  BossFightsConnection: ResolverTypeWrapper<BossFightsConnection>;
  BossFightsEdge: ResolverTypeWrapper<BossFightsEdge>;
  BuildHealth: BuildHealth;
  BuildTag: BuildTag;
  Byte: ResolverTypeWrapper<Scalars['Byte']>;
  ByteOperationFilterInput: ByteOperationFilterInput;
  ClientSpecification: ResolverTypeWrapper<ClientSpecification>;
  CommunityFlag: CommunityFlag;
  CommunityFlagOperationFilterInput: CommunityFlagOperationFilterInput;
  ConsumableItem: ResolverTypeWrapper<ConsumableItem>;
  ConsumableItemFilterInput: ConsumableItemFilterInput;
  ConsumableItemSortInput: ConsumableItemSortInput;
  ContainerItem: ResolverTypeWrapper<
    Omit<ContainerItem, 'contents' | 'entity' | 'items'> & {
      contents: Array<ResolversTypes['GameItem']>;
      entity: Maybe<ResolversTypes['GameEntity']>;
      items: Array<ResolversTypes['GameItem']>;
    }
  >;
  ContainerItemFilterInput: ContainerItemFilterInput;
  ContainerItemSortInput: ContainerItemSortInput;
  CraftRecipeIngredient: ResolverTypeWrapper<CraftRecipeIngredient>;
  CraftRecipeIngredientFilterInput: CraftRecipeIngredientFilterInput;
  CraftRecipeItemType: CraftRecipeItemType;
  CraftRecipeItemTypeOperationFilterInput: CraftRecipeItemTypeOperationFilterInput;
  CraftSlot: ResolverTypeWrapper<CraftSlot>;
  CraftSlotFilterInput: CraftSlotFilterInput;
  CraftingAttempt: ResolverTypeWrapper<CraftingAttempt>;
  CraftingAttemptFilterInput: CraftingAttemptFilterInput;
  CraftingRecipe: ResolverTypeWrapper<CraftingRecipe>;
  CraftingRecipeFilterInput: CraftingRecipeFilterInput;
  CraftingRecipeSortInput: CraftingRecipeSortInput;
  CurrencyCost: ResolverTypeWrapper<CurrencyCost>;
  CurrencyCosts: ResolverTypeWrapper<CurrencyCosts>;
  CurrencyExchangeRates: ResolverTypeWrapper<CurrencyExchangeRates>;
  CurrencyType: CurrencyType;
  CurrencyTypeOperationFilterInput: CurrencyTypeOperationFilterInput;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: ResolverTypeWrapper<Scalars['Decimal']>;
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DuelBattlesConnection: ResolverTypeWrapper<DuelBattlesConnection>;
  DuelBattlesEdge: ResolverTypeWrapper<DuelBattlesEdge>;
  DutchAuction: ResolverTypeWrapper<DutchAuction>;
  DutchAuctionResult: ResolverTypeWrapper<DutchAuctionResult>;
  EditionAsset: ResolverTypeWrapper<EditionAsset>;
  EditionAssetFilterInput: EditionAssetFilterInput;
  EditionAssetSortInput: EditionAssetSortInput;
  EditionBase: ResolverTypeWrapper<EditionBase>;
  EditionBaseFilterInput: EditionBaseFilterInput;
  EditionBaseSortInput: EditionBaseSortInput;
  EntityAnimationCollection: ResolverTypeWrapper<EntityAnimationCollection>;
  EntityAnimationCollectionFilterInput: EntityAnimationCollectionFilterInput;
  EntityAnimationCollectionSortInput: EntityAnimationCollectionSortInput;
  EntityEffect: ResolverTypeWrapper<
    Omit<EntityEffect, 'item'> & { item: Maybe<ResolversTypes['GameItem']> }
  >;
  EntityEffectFilterInput: EntityEffectFilterInput;
  EntityEffectOperand: EntityEffectOperand;
  EntityEffectOperandOperationFilterInput: EntityEffectOperandOperationFilterInput;
  EntitySkill: ResolverTypeWrapper<EntitySkill>;
  EntitySkillFilterInput: EntitySkillFilterInput;
  EquipmentItem: ResolverTypeWrapper<
    Omit<EquipmentItem, 'entity'> & {
      entity: Maybe<ResolversTypes['GameEntity']>;
    }
  >;
  EquipmentItemFilterInput: EquipmentItemFilterInput;
  EquipmentSlot: EquipmentSlot;
  EquipmentSlotOperationFilterInput: EquipmentSlotOperationFilterInput;
  EthExchange: ResolverTypeWrapper<EthExchange>;
  FloatOperationFilterInput: FloatOperationFilterInput;
  FormationDefinition: ResolverTypeWrapper<FormationDefinition>;
  FormationDefinitionFilterInput: FormationDefinitionFilterInput;
  FormationDefinitionSortInput: FormationDefinitionSortInput;
  FormationPosition: FormationPosition;
  FormationPositionOperationFilterInput: FormationPositionOperationFilterInput;
  FurAccount: ResolverTypeWrapper<FurAccount>;
  FurAccountFilterInput: FurAccountFilterInput;
  FurAccountSortInput: FurAccountSortInput;
  FurExceptionInput: FurExceptionInput;
  FurExchange: ResolverTypeWrapper<FurExchange>;
  FurFundingLot: ResolverTypeWrapper<FurFundingLot>;
  FurOAuthToken: ResolverTypeWrapper<FurOAuthToken>;
  FurOAuthTokenFilterInput: FurOAuthTokenFilterInput;
  FurOAuthTokenInput: FurOAuthTokenInput;
  FurOAuthTokenSortInput: FurOAuthTokenSortInput;
  FurPlayer: ResolverTypeWrapper<FurPlayer>;
  FurPlayerFilterInput: FurPlayerFilterInput;
  FurPlayerSortInput: FurPlayerSortInput;
  FurPurchasePlayer: ResolverTypeWrapper<FurPurchasePlayer>;
  FurScholar: ResolverTypeWrapper<FurScholar>;
  FurScholarDiscord: ResolverTypeWrapper<FurScholarDiscord>;
  FurScholarDiscordFilterInput: FurScholarDiscordFilterInput;
  FurScholarDiscordSortInput: FurScholarDiscordSortInput;
  FurScholarEmail: ResolverTypeWrapper<FurScholarEmail>;
  FurScholarEmailFilterInput: FurScholarEmailFilterInput;
  FurScholarEmailSortInput: FurScholarEmailSortInput;
  FurSession: ResolverTypeWrapper<FurSession>;
  FurSessionFilterInput: FurSessionFilterInput;
  FurSessionSortInput: FurSessionSortInput;
  FurUpdates: ResolverTypeWrapper<FurUpdates>;
  Furball: ResolverTypeWrapper<Furball>;
  FurballAttribute: ResolverTypeWrapper<FurballAttribute>;
  FurballAttributeFilterInput: FurballAttributeFilterInput;
  FurballDefinition: ResolverTypeWrapper<FurballDefinition>;
  FurballDefinitionFilterInput: FurballDefinitionFilterInput;
  FurballDefinitionSortInput: FurballDefinitionSortInput;
  FurballFilterInput: FurballFilterInput;
  FurballFiltersInput: FurballFiltersInput;
  FurballInfo: ResolverTypeWrapper<FurballInfo>;
  FurballInfoFilterInput: FurballInfoFilterInput;
  FurballInfoSortInput: FurballInfoSortInput;
  FurballLootUpgradeResponse: ResolverTypeWrapper<
    Omit<FurballLootUpgradeResponse, 'upgradedItem'> & {
      upgradedItem: Maybe<ResolversTypes['GameItem']>;
    }
  >;
  FurballModification: ResolverTypeWrapper<FurballModification>;
  FurballModificationArgsInput: FurballModificationArgsInput;
  FurballNameChange: ResolverTypeWrapper<FurballNameChange>;
  FurballNameChangeFilterInput: FurballNameChangeFilterInput;
  FurballNameChangeSortInput: FurballNameChangeSortInput;
  FurballNameChangesConnection: ResolverTypeWrapper<FurballNameChangesConnection>;
  FurballNameChangesEdge: ResolverTypeWrapper<FurballNameChangesEdge>;
  FurballNumberFilterInput: FurballNumberFilterInput;
  FurballRank: ResolverTypeWrapper<FurballRank>;
  FurballRewardModifiers: ResolverTypeWrapper<FurballRewardModifiers>;
  FurballRewardModifiersFilterInput: FurballRewardModifiersFilterInput;
  FurballRewardModifiersSortInput: FurballRewardModifiersSortInput;
  FurballSlot: FurballSlot;
  FurballSlotOperationFilterInput: FurballSlotOperationFilterInput;
  FurballSnack: ResolverTypeWrapper<FurballSnack>;
  FurballSnackFilterInput: FurballSnackFilterInput;
  FurballSortInput: FurballSortInput;
  FurballState: ResolverTypeWrapper<FurballState>;
  FurballStats: ResolverTypeWrapper<FurballStats>;
  FurballStatsFilterInput: FurballStatsFilterInput;
  FurballStatsSortInput: FurballStatsSortInput;
  FurballToken: ResolverTypeWrapper<FurballToken>;
  FurballTokenFilterInput: FurballTokenFilterInput;
  FurballTokenSortInput: FurballTokenSortInput;
  FurballTraitsFilterInput: FurballTraitsFilterInput;
  FurballUpgrade: ResolverTypeWrapper<FurballUpgrade>;
  FurballUpgradeBattleStats: ResolverTypeWrapper<FurballUpgradeBattleStats>;
  FurballUpgradeEquippedItem: ResolverTypeWrapper<FurballUpgradeEquippedItem>;
  FurballUpgradeFilterInput: FurballUpgradeFilterInput;
  FurballUpgradeProgress: ResolverTypeWrapper<FurballUpgradeProgress>;
  FurballUpgradeSkill: ResolverTypeWrapper<FurballUpgradeSkill>;
  FurballZoneReward: ResolverTypeWrapper<FurballZoneReward>;
  FurballZoneRewardFilterInput: FurballZoneRewardFilterInput;
  FurballZoneRewardSortInput: FurballZoneRewardSortInput;
  FurballsMeta: ResolverTypeWrapper<FurballsMeta>;
  GameBattle: ResolverTypeWrapper<GameBattle>;
  GameBattleBoss: ResolverTypeWrapper<
    Omit<GameBattleBoss, 'itemsEarned'> & {
      itemsEarned: Array<ResolversTypes['GameItem']>;
    }
  >;
  GameBattleBossFilterInput: GameBattleBossFilterInput;
  GameBattleBossSortInput: GameBattleBossSortInput;
  GameBattleDuel: ResolverTypeWrapper<GameBattleDuel>;
  GameBattleDuelFilterInput: GameBattleDuelFilterInput;
  GameBattleDuelSortInput: GameBattleDuelSortInput;
  GameBattleFilterInput: GameBattleFilterInput;
  GameBattleMonsters: ResolverTypeWrapper<
    Omit<GameBattleMonsters, 'itemsEarned'> & {
      itemsEarned: Array<ResolversTypes['GameItem']>;
    }
  >;
  GameBattleMonstersFilterInput: GameBattleMonstersFilterInput;
  GameBattleMonstersSortInput: GameBattleMonstersSortInput;
  GameBattlePvE: ResolverTypeWrapper<
    Omit<GameBattlePvE, 'itemsEarned'> & {
      itemsEarned: Array<ResolversTypes['GameItem']>;
    }
  >;
  GameBattlePvEFilterInput: GameBattlePvEFilterInput;
  GameBattlePvP: ResolverTypeWrapper<GameBattlePvP>;
  GameBattlePvPFilterInput: GameBattlePvPFilterInput;
  GameBattleRules: ResolverTypeWrapper<GameBattleRules>;
  GameBattleRulesFilterInput: GameBattleRulesFilterInput;
  GameBattleRulesSortInput: GameBattleRulesSortInput;
  GameBattleSortInput: GameBattleSortInput;
  GameBattleState: GameBattleState;
  GameBattleStateOperationFilterInput: GameBattleStateOperationFilterInput;
  GameBattlesConnection: ResolverTypeWrapper<GameBattlesConnection>;
  GameBattlesEdge: ResolverTypeWrapper<GameBattlesEdge>;
  GameBoss: ResolverTypeWrapper<GameBoss>;
  GameBossFilterInput: GameBossFilterInput;
  GameBossSortInput: GameBossSortInput;
  GameBuild: ResolverTypeWrapper<GameBuild>;
  GameEntity:
    | ResolversTypes['Furball']
    | ResolversTypes['GameBoss']
    | ResolversTypes['GameMonster'];
  GameEntityFilterInput: GameEntityFilterInput;
  GameEntitySortInput: GameEntitySortInput;
  GameEntitySummary: ResolverTypeWrapper<
    Omit<GameEntitySummary, 'gameEntity'> & {
      gameEntity: Maybe<ResolversTypes['GameEntity']>;
    }
  >;
  GameEntitySummaryFilterInput: GameEntitySummaryFilterInput;
  GameItem:
    | ResolversTypes['ConsumableItem']
    | ResolversTypes['ContainerItem']
    | ResolversTypes['EquipmentItem']
    | ResolversTypes['LootItem']
    | ResolversTypes['MaterialItem']
    | ResolversTypes['SpecialItem'];
  GameItemFilterInput: GameItemFilterInput;
  GameItemSortInput: GameItemSortInput;
  GameMonster: ResolverTypeWrapper<GameMonster>;
  GameObject:
    | ResolversTypes['Furball']
    | ResolversTypes['GameBoss']
    | ResolversTypes['GameMonster'];
  GameParty: ResolverTypeWrapper<GameParty>;
  GamePartyFilterInput: GamePartyFilterInput;
  GamePartySortInput: GamePartySortInput;
  GamePlayRulesInput: GamePlayRulesInput;
  HistoryConnection: ResolverTypeWrapper<HistoryConnection>;
  HistoryEdge: ResolverTypeWrapper<HistoryEdge>;
  HostNode: ResolverTypeWrapper<HostNode>;
  HostNodeFilterInput: HostNodeFilterInput;
  HostNodeSortInput: HostNodeSortInput;
  HostState: HostState;
  HostStateOperationFilterInput: HostStateOperationFilterInput;
  HostTask: ResolverTypeWrapper<HostTask>;
  HostTaskFilterInput: HostTaskFilterInput;
  HostTaskName: HostTaskName;
  HostTaskNameOperationFilterInput: HostTaskNameOperationFilterInput;
  IBattleEffect:
    | ResolversTypes['BattleEffectPassive']
    | ResolversTypes['BattleMoveOutcome']
    | ResolversTypes['EntityEffect'];
  IBattleEffectFilterInput: IBattleEffectFilterInput;
  IFurPlayer:
    | ResolversTypes['FurAccount']
    | ResolversTypes['FurPlayer']
    | ResolversTypes['FurScholar']
    | ResolversTypes['FurScholarDiscord']
    | ResolversTypes['FurScholarEmail'];
  IFurPlayerFilterInput: IFurPlayerFilterInput;
  IFurPlayerSortInput: IFurPlayerSortInput;
  IntOperationFilterInput: IntOperationFilterInput;
  InventoryItem: ResolverTypeWrapper<InventoryItem>;
  InventoryItemFilterInput: InventoryItemFilterInput;
  ItemDefInput: ItemDefInput;
  ItemDefinition: ResolverTypeWrapper<ItemDefinition>;
  ItemDefinitionFilterInput: ItemDefinitionFilterInput;
  ItemDefinitionSortInput: ItemDefinitionSortInput;
  ItemDiscriminator: ItemDiscriminator;
  ItemDiscriminatorOperationFilterInput: ItemDiscriminatorOperationFilterInput;
  ItemGroup: ItemGroup;
  ItemGroupOperationFilterInput: ItemGroupOperationFilterInput;
  ItemListingsConnection: ResolverTypeWrapper<ItemListingsConnection>;
  ItemListingsEdge: ResolverTypeWrapper<ItemListingsEdge>;
  ItemQuantity: ResolverTypeWrapper<ItemQuantity>;
  ItemQuantityFilterInput: ItemQuantityFilterInput;
  ItemReward: ResolverTypeWrapper<ItemReward>;
  ItemRewardFilterInput: ItemRewardFilterInput;
  ItemRewardSortInput: ItemRewardSortInput;
  JoinBattleResponse: ResolverTypeWrapper<JoinBattleResponse>;
  KeyValuePairOfInt64AndInt32: ResolverTypeWrapper<KeyValuePairOfInt64AndInt32>;
  KeyValuePairOfStringAndAnimationAsset: ResolverTypeWrapper<KeyValuePairOfStringAndAnimationAsset>;
  KeyValuePairOfStringAndAnimationAssetFilterInput: KeyValuePairOfStringAndAnimationAssetFilterInput;
  Leaderboard: ResolverTypeWrapper<Leaderboard>;
  LeaderboardEntityType: LeaderboardEntityType;
  LeaderboardEntityTypeOperationFilterInput: LeaderboardEntityTypeOperationFilterInput;
  LeaderboardFilterInput: LeaderboardFilterInput;
  LeaderboardGroup: LeaderboardGroup;
  LeaderboardGroupOperationFilterInput: LeaderboardGroupOperationFilterInput;
  LeaderboardPosition:
    | ResolversTypes['LeaderboardSlotFurball']
    | ResolversTypes['LeaderboardSlotPlayer'];
  LeaderboardRankFurball: ResolverTypeWrapper<LeaderboardRankFurball>;
  LeaderboardRankPlayer: ResolverTypeWrapper<LeaderboardRankPlayer>;
  LeaderboardSlot: ResolverTypeWrapper<LeaderboardSlot>;
  LeaderboardSlotFurball: ResolverTypeWrapper<LeaderboardSlotFurball>;
  LeaderboardSlotPlayer: ResolverTypeWrapper<LeaderboardSlotPlayer>;
  LeaderboardStat: LeaderboardStat;
  LeaderboardStatOperationFilterInput: LeaderboardStatOperationFilterInput;
  LeaderboardTier: ResolverTypeWrapper<LeaderboardTier>;
  LeaderboardTierRewardChance: ResolverTypeWrapper<LeaderboardTierRewardChance>;
  LeaderboardTierRewardChanceFilterInput: LeaderboardTierRewardChanceFilterInput;
  LeaderboardTierRewardChanceInput: LeaderboardTierRewardChanceInput;
  LeaderboardTierRewards: ResolverTypeWrapper<LeaderboardTierRewards>;
  LeaderboardTierRewardsFilterInput: LeaderboardTierRewardsFilterInput;
  LeaderboardTimeframe: LeaderboardTimeframe;
  LeaderboardTimeframeOperationFilterInput: LeaderboardTimeframeOperationFilterInput;
  ListByteOperationFilterInput: ListByteOperationFilterInput;
  ListCommunityFlagOperationFilterInput: ListCommunityFlagOperationFilterInput;
  ListFilterInputTypeOfBattleEffectPassiveFilterInput: ListFilterInputTypeOfBattleEffectPassiveFilterInput;
  ListFilterInputTypeOfBattleMoveFilterInput: ListFilterInputTypeOfBattleMoveFilterInput;
  ListFilterInputTypeOfBattleMoveOutcomeFilterInput: ListFilterInputTypeOfBattleMoveOutcomeFilterInput;
  ListFilterInputTypeOfBlockchainTokenTransactionFilterInput: ListFilterInputTypeOfBlockchainTokenTransactionFilterInput;
  ListFilterInputTypeOfConsumableItemFilterInput: ListFilterInputTypeOfConsumableItemFilterInput;
  ListFilterInputTypeOfContainerItemFilterInput: ListFilterInputTypeOfContainerItemFilterInput;
  ListFilterInputTypeOfCraftRecipeIngredientFilterInput: ListFilterInputTypeOfCraftRecipeIngredientFilterInput;
  ListFilterInputTypeOfCraftSlotFilterInput: ListFilterInputTypeOfCraftSlotFilterInput;
  ListFilterInputTypeOfCraftingAttemptFilterInput: ListFilterInputTypeOfCraftingAttemptFilterInput;
  ListFilterInputTypeOfEditionAssetFilterInput: ListFilterInputTypeOfEditionAssetFilterInput;
  ListFilterInputTypeOfEntityEffectFilterInput: ListFilterInputTypeOfEntityEffectFilterInput;
  ListFilterInputTypeOfEntitySkillFilterInput: ListFilterInputTypeOfEntitySkillFilterInput;
  ListFilterInputTypeOfEquipmentItemFilterInput: ListFilterInputTypeOfEquipmentItemFilterInput;
  ListFilterInputTypeOfFurPlayerFilterInput: ListFilterInputTypeOfFurPlayerFilterInput;
  ListFilterInputTypeOfFurSessionFilterInput: ListFilterInputTypeOfFurSessionFilterInput;
  ListFilterInputTypeOfFurballAttributeFilterInput: ListFilterInputTypeOfFurballAttributeFilterInput;
  ListFilterInputTypeOfFurballFilterInput: ListFilterInputTypeOfFurballFilterInput;
  ListFilterInputTypeOfFurballSnackFilterInput: ListFilterInputTypeOfFurballSnackFilterInput;
  ListFilterInputTypeOfFurballUpgradeFilterInput: ListFilterInputTypeOfFurballUpgradeFilterInput;
  ListFilterInputTypeOfGameBattleBossFilterInput: ListFilterInputTypeOfGameBattleBossFilterInput;
  ListFilterInputTypeOfGameBattleFilterInput: ListFilterInputTypeOfGameBattleFilterInput;
  ListFilterInputTypeOfGameEntitySummaryFilterInput: ListFilterInputTypeOfGameEntitySummaryFilterInput;
  ListFilterInputTypeOfGameItemFilterInput: ListFilterInputTypeOfGameItemFilterInput;
  ListFilterInputTypeOfGamePartyFilterInput: ListFilterInputTypeOfGamePartyFilterInput;
  ListFilterInputTypeOfHostTaskFilterInput: ListFilterInputTypeOfHostTaskFilterInput;
  ListFilterInputTypeOfIBattleEffectFilterInput: ListFilterInputTypeOfIBattleEffectFilterInput;
  ListFilterInputTypeOfInventoryItemFilterInput: ListFilterInputTypeOfInventoryItemFilterInput;
  ListFilterInputTypeOfItemQuantityFilterInput: ListFilterInputTypeOfItemQuantityFilterInput;
  ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput: ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput;
  ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput: ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput;
  ListFilterInputTypeOfLeaderboardTierRewardsFilterInput: ListFilterInputTypeOfLeaderboardTierRewardsFilterInput;
  ListFilterInputTypeOfLootItemFilterInput: ListFilterInputTypeOfLootItemFilterInput;
  ListFilterInputTypeOfMaterialItemFilterInput: ListFilterInputTypeOfMaterialItemFilterInput;
  ListFilterInputTypeOfMonsterSkillDefinitionFilterInput: ListFilterInputTypeOfMonsterSkillDefinitionFilterInput;
  ListFilterInputTypeOfPassiveEffectRangeFilterInput: ListFilterInputTypeOfPassiveEffectRangeFilterInput;
  ListFilterInputTypeOfPlayerAnteFilterInput: ListFilterInputTypeOfPlayerAnteFilterInput;
  ListFilterInputTypeOfPlayerHistoryDayFilterInput: ListFilterInputTypeOfPlayerHistoryDayFilterInput;
  ListFilterInputTypeOfPlayerItemListingFilterInput: ListFilterInputTypeOfPlayerItemListingFilterInput;
  ListFilterInputTypeOfPlayerOnboardingStepFilterInput: ListFilterInputTypeOfPlayerOnboardingStepFilterInput;
  ListFilterInputTypeOfPlayerPayoutFilterInput: ListFilterInputTypeOfPlayerPayoutFilterInput;
  ListFilterInputTypeOfPlayerPendingRewardFilterInput: ListFilterInputTypeOfPlayerPendingRewardFilterInput;
  ListFilterInputTypeOfQuestFilterInput: ListFilterInputTypeOfQuestFilterInput;
  ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput: ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput;
  ListFilterInputTypeOfQuestWaypointCompletionFilterInput: ListFilterInputTypeOfQuestWaypointCompletionFilterInput;
  ListFilterInputTypeOfQuestWaypointDefinitionFilterInput: ListFilterInputTypeOfQuestWaypointDefinitionFilterInput;
  ListFilterInputTypeOfRentalAgreementFilterInput: ListFilterInputTypeOfRentalAgreementFilterInput;
  ListFilterInputTypeOfRentalTerminationFilterInput: ListFilterInputTypeOfRentalTerminationFilterInput;
  ListFilterInputTypeOfShopPurchaseFilterInput: ListFilterInputTypeOfShopPurchaseFilterInput;
  ListFilterInputTypeOfSkillStatEffectFilterInput: ListFilterInputTypeOfSkillStatEffectFilterInput;
  ListFilterInputTypeOfSocialOAuthTokenFilterInput: ListFilterInputTypeOfSocialOAuthTokenFilterInput;
  ListFilterInputTypeOfSocialProfileFilterInput: ListFilterInputTypeOfSocialProfileFilterInput;
  ListFilterInputTypeOfSocialRoleFilterInput: ListFilterInputTypeOfSocialRoleFilterInput;
  ListFilterInputTypeOfSpecialItemFilterInput: ListFilterInputTypeOfSpecialItemFilterInput;
  ListFilterInputTypeOfStatEntityFilterInput: ListFilterInputTypeOfStatEntityFilterInput;
  ListFilterInputTypeOfTournamentParticipantFilterInput: ListFilterInputTypeOfTournamentParticipantFilterInput;
  ListFilterInputTypeOfVoyageEncounterFilterInput: ListFilterInputTypeOfVoyageEncounterFilterInput;
  ListFilterInputTypeOfVoyageFilterInput: ListFilterInputTypeOfVoyageFilterInput;
  ListFormationPositionOperationFilterInput: ListFormationPositionOperationFilterInput;
  ListFurballSlotOperationFilterInput: ListFurballSlotOperationFilterInput;
  ListLongOperationFilterInput: ListLongOperationFilterInput;
  ListRealmTypeOperationFilterInput: ListRealmTypeOperationFilterInput;
  ListStringOperationFilterInput: ListStringOperationFilterInput;
  ListWaitlistFlagOperationFilterInput: ListWaitlistFlagOperationFilterInput;
  ListingStyle: ListingStyle;
  ListingStyleOperationFilterInput: ListingStyleOperationFilterInput;
  LogBundleInput: LogBundleInput;
  LogEntryInput: LogEntryInput;
  LogLevel: LogLevel;
  LoginEmailResponse: ResolverTypeWrapper<LoginEmailResponse>;
  LoginOutcome: LoginOutcome;
  LoginResponse: ResolverTypeWrapper<LoginResponse>;
  Long: ResolverTypeWrapper<Scalars['Long']>;
  LongOperationFilterInput: LongOperationFilterInput;
  LootBox: ResolverTypeWrapper<LootBox>;
  LootItem: ResolverTypeWrapper<LootItem>;
  LootItemFilterInput: LootItemFilterInput;
  MarketListing: ResolverTypeWrapper<MarketListing>;
  MarketplaceName: MarketplaceName;
  MaterialItem: ResolverTypeWrapper<MaterialItem>;
  MaterialItemFilterInput: MaterialItemFilterInput;
  MonsterBattlesConnection: ResolverTypeWrapper<MonsterBattlesConnection>;
  MonsterBattlesEdge: ResolverTypeWrapper<MonsterBattlesEdge>;
  MonsterDefinition: ResolverTypeWrapper<MonsterDefinition>;
  MonsterDefinitionFilterInput: MonsterDefinitionFilterInput;
  MonsterDefinitionSortInput: MonsterDefinitionSortInput;
  MonsterSkillDefinition: ResolverTypeWrapper<MonsterSkillDefinition>;
  MonsterSkillDefinitionFilterInput: MonsterSkillDefinitionFilterInput;
  MoveItemResult: ResolverTypeWrapper<
    Omit<MoveItemResult, 'item'> & { item: ResolversTypes['GameItem'] }
  >;
  MoveZoneRequestInput: MoveZoneRequestInput;
  MoveZoneResponse: ResolverTypeWrapper<
    Omit<MoveZoneResponse, 'items'> & {
      items: Array<ResolversTypes['GameItem']>;
    }
  >;
  MovesConnection: ResolverTypeWrapper<MovesConnection>;
  MovesEdge: ResolverTypeWrapper<MovesEdge>;
  Mutation: ResolverTypeWrapper<{}>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationFilterInput: NotificationFilterInput;
  NotificationInstance: ResolverTypeWrapper<NotificationInstance>;
  NotificationPlatform: NotificationPlatform;
  NotificationPurpose: NotificationPurpose;
  NotificationPurposeOperationFilterInput: NotificationPurposeOperationFilterInput;
  NotificationSortInput: NotificationSortInput;
  NotificationsConnection: ResolverTypeWrapper<NotificationsConnection>;
  NotificationsEdge: ResolverTypeWrapper<NotificationsEdge>;
  NullableOfCommunityFlagOperationFilterInput: NullableOfCommunityFlagOperationFilterInput;
  NullableOfRarityLevelOperationFilterInput: NullableOfRarityLevelOperationFilterInput;
  NullableOfRealmTypeOperationFilterInput: NullableOfRealmTypeOperationFilterInput;
  NullableOfTargetingRuleOperationFilterInput: NullableOfTargetingRuleOperationFilterInput;
  NullableOfTargetingStrategyOperationFilterInput: NullableOfTargetingStrategyOperationFilterInput;
  NullableOfUsageFrequencyOperationFilterInput: NullableOfUsageFrequencyOperationFilterInput;
  OnboardingSequenceType: OnboardingSequenceType;
  OnboardingSequenceTypeOperationFilterInput: OnboardingSequenceTypeOperationFilterInput;
  OutcomeFlag: OutcomeFlag;
  OutcomeFlagOperationFilterInput: OutcomeFlagOperationFilterInput;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PassiveEffectRange: ResolverTypeWrapper<PassiveEffectRange>;
  PassiveEffectRangeFilterInput: PassiveEffectRangeFilterInput;
  PayoutMethod: PayoutMethod;
  PayoutMethodOperationFilterInput: PayoutMethodOperationFilterInput;
  PendingFurWrapsConnection: ResolverTypeWrapper<PendingFurWrapsConnection>;
  PendingFurWrapsEdge: ResolverTypeWrapper<PendingFurWrapsEdge>;
  PendingRewardRequestInput: PendingRewardRequestInput;
  PlayerAnte: ResolverTypeWrapper<PlayerAnte>;
  PlayerAnteFilterInput: PlayerAnteFilterInput;
  PlayerHistoryConnection: ResolverTypeWrapper<PlayerHistoryConnection>;
  PlayerHistoryDay: ResolverTypeWrapper<PlayerHistoryDay>;
  PlayerHistoryDayFilterInput: PlayerHistoryDayFilterInput;
  PlayerHistoryEdge: ResolverTypeWrapper<PlayerHistoryEdge>;
  PlayerItemListing: ResolverTypeWrapper<
    Omit<PlayerItemListing, 'item'> & { item: ResolversTypes['GameItem'] }
  >;
  PlayerItemListingFilterInput: PlayerItemListingFilterInput;
  PlayerItemListingSortInput: PlayerItemListingSortInput;
  PlayerOnboardingStep: ResolverTypeWrapper<PlayerOnboardingStep>;
  PlayerOnboardingStepFilterInput: PlayerOnboardingStepFilterInput;
  PlayerPayout: ResolverTypeWrapper<PlayerPayout>;
  PlayerPayoutFilterInput: PlayerPayoutFilterInput;
  PlayerPendingReward: ResolverTypeWrapper<
    Omit<PlayerPendingReward, 'items'> & {
      items: Array<ResolversTypes['GameItem']>;
    }
  >;
  PlayerPendingRewardFilterInput: PlayerPendingRewardFilterInput;
  PlayerPendingRewardSortInput: PlayerPendingRewardSortInput;
  PlayerPurchase: ResolverTypeWrapper<PlayerPurchase>;
  PlayerTransactionsConnection: ResolverTypeWrapper<PlayerTransactionsConnection>;
  PlayerTransactionsEdge: ResolverTypeWrapper<PlayerTransactionsEdge>;
  PriorRewardsConnection: ResolverTypeWrapper<PriorRewardsConnection>;
  PriorRewardsEdge: ResolverTypeWrapper<PriorRewardsEdge>;
  Query: ResolverTypeWrapper<{}>;
  Quest: ResolverTypeWrapper<Quest>;
  QuestDefinition: ResolverTypeWrapper<QuestDefinition>;
  QuestDefinitionFilterInput: QuestDefinitionFilterInput;
  QuestFilterInput: QuestFilterInput;
  QuestWaypointBracketDefinition: ResolverTypeWrapper<QuestWaypointBracketDefinition>;
  QuestWaypointBracketDefinitionFilterInput: QuestWaypointBracketDefinitionFilterInput;
  QuestWaypointCompletion: ResolverTypeWrapper<QuestWaypointCompletion>;
  QuestWaypointCompletionFilterInput: QuestWaypointCompletionFilterInput;
  QuestWaypointDefinition: ResolverTypeWrapper<QuestWaypointDefinition>;
  QuestWaypointDefinitionFilterInput: QuestWaypointDefinitionFilterInput;
  RandomParty: ResolverTypeWrapper<RandomParty>;
  RarityLevel: RarityLevel;
  RarityLevelOperationFilterInput: RarityLevelOperationFilterInput;
  RealmType: RealmType;
  RealmTypeOperationFilterInput: RealmTypeOperationFilterInput;
  ReceiptState: ReceiptState;
  RecentBossBattles: ResolverTypeWrapper<RecentBossBattles>;
  RenameFurballArgsInput: RenameFurballArgsInput;
  RentalAgreement: ResolverTypeWrapper<RentalAgreement>;
  RentalAgreementFilterInput: RentalAgreementFilterInput;
  RentalAgreementSortInput: RentalAgreementSortInput;
  RentalAgreementsConnection: ResolverTypeWrapper<RentalAgreementsConnection>;
  RentalAgreementsEdge: ResolverTypeWrapper<RentalAgreementsEdge>;
  RentalTermination: ResolverTypeWrapper<RentalTermination>;
  RentalTerminationFilterInput: RentalTerminationFilterInput;
  RentalTermsInput: RentalTermsInput;
  RewardSource: RewardSource;
  RewardSourceOperationFilterInput: RewardSourceOperationFilterInput;
  ScholarsDiscordConnection: ResolverTypeWrapper<ScholarsDiscordConnection>;
  ScholarsDiscordEdge: ResolverTypeWrapper<ScholarsDiscordEdge>;
  ScholarsEmailConnection: ResolverTypeWrapper<ScholarsEmailConnection>;
  ScholarsEmailEdge: ResolverTypeWrapper<ScholarsEmailEdge>;
  SearchFurballsConnection: ResolverTypeWrapper<SearchFurballsConnection>;
  SearchFurballsEdge: ResolverTypeWrapper<SearchFurballsEdge>;
  SetGamePartyInput: SetGamePartyInput;
  SfxAsset: ResolverTypeWrapper<SfxAsset>;
  SfxAssetFilterInput: SfxAssetFilterInput;
  ShopPurchase: ResolverTypeWrapper<ShopPurchase>;
  ShopPurchaseFilterInput: ShopPurchaseFilterInput;
  ShopSnack: ResolverTypeWrapper<ShopSnack>;
  ShopWares: ResolverTypeWrapper<ShopWares>;
  ShopWaresFilterInput: ShopWaresFilterInput;
  Short: ResolverTypeWrapper<Scalars['Short']>;
  ShortOperationFilterInput: ShortOperationFilterInput;
  SkillDefinition: ResolverTypeWrapper<SkillDefinition>;
  SkillDefinitionFilterInput: SkillDefinitionFilterInput;
  SkillDefinitionSortInput: SkillDefinitionSortInput;
  SkillStatEffect: ResolverTypeWrapper<SkillStatEffect>;
  SkillStatEffectFilterInput: SkillStatEffectFilterInput;
  SkillTarget: SkillTarget;
  SkillTargetOperationFilterInput: SkillTargetOperationFilterInput;
  SkillTargetTeam: SkillTargetTeam;
  SkillTargetTeamOperationFilterInput: SkillTargetTeamOperationFilterInput;
  SlotsConnection: ResolverTypeWrapper<SlotsConnection>;
  SlotsEdge: ResolverTypeWrapper<SlotsEdge>;
  SocialOAuthToken: ResolverTypeWrapper<SocialOAuthToken>;
  SocialOAuthTokenFilterInput: SocialOAuthTokenFilterInput;
  SocialProfile: ResolverTypeWrapper<SocialProfile>;
  SocialProfileFilterInput: SocialProfileFilterInput;
  SocialProfileSortInput: SocialProfileSortInput;
  SocialRole: ResolverTypeWrapper<SocialRole>;
  SocialRoleFilterInput: SocialRoleFilterInput;
  SortEnumType: SortEnumType;
  SpecialItem: ResolverTypeWrapper<SpecialItem>;
  SpecialItemFilterInput: SpecialItemFilterInput;
  Specialization: Specialization;
  SpecializationOperationFilterInput: SpecializationOperationFilterInput;
  StatEffectBonus: StatEffectBonus;
  StatEffectBonusOperationFilterInput: StatEffectBonusOperationFilterInput;
  StatEffectTarget: StatEffectTarget;
  StatEffectTargetOperationFilterInput: StatEffectTargetOperationFilterInput;
  StatEntity: ResolverTypeWrapper<
    Omit<StatEntity, 'battleStats' | 'entity' | 'items' | 'loot'> & {
      battleStats: Maybe<ResolversTypes['GameEntity']>;
      entity: Maybe<ResolversTypes['GameEntity']>;
      items: Array<ResolversTypes['GameItem']>;
      loot: Array<ResolversTypes['GameItem']>;
    }
  >;
  StatEntityFilterInput: StatEntityFilterInput;
  StatEntitySortInput: StatEntitySortInput;
  StringOperationFilterInput: StringOperationFilterInput;
  Subscription: ResolverTypeWrapper<{}>;
  TargetingRule: TargetingRule;
  TargetingStrategy: TargetingStrategy;
  TierRewardType: TierRewardType;
  TierRewardTypeOperationFilterInput: TierRewardTypeOperationFilterInput;
  TiersConnection: ResolverTypeWrapper<TiersConnection>;
  TiersEdge: ResolverTypeWrapper<TiersEdge>;
  TimeSpan: ResolverTypeWrapper<Scalars['TimeSpan']>;
  TimeSpanOperationFilterInput: TimeSpanOperationFilterInput;
  TimekeeperAdHoc: ResolverTypeWrapper<TimekeeperAdHoc>;
  TimekeeperAdHocArgsInput: TimekeeperAdHocArgsInput;
  TimekeeperArgsInput: TimekeeperArgsInput;
  TimekeeperChangeSet: ResolverTypeWrapper<TimekeeperChangeSet>;
  TimekeeperCosts: ResolverTypeWrapper<TimekeeperCosts>;
  TimekeeperInstant: ResolverTypeWrapper<TimekeeperInstant>;
  TimekeeperInstantFilterInput: TimekeeperInstantFilterInput;
  TimekeeperInstantSortInput: TimekeeperInstantSortInput;
  TimekeeperInteraction: ResolverTypeWrapper<TimekeeperInteraction>;
  TimekeeperInteractionAction: TimekeeperInteractionAction;
  TimekeeperInteractionArgInput: TimekeeperInteractionArgInput;
  TimekeeperInteractionType: TimekeeperInteractionType;
  TimekeeperModeChange: ResolverTypeWrapper<TimekeeperModeChange>;
  TimekeeperRenameFurball: ResolverTypeWrapper<TimekeeperRenameFurball>;
  TimekeeperRenameFurballFilterInput: TimekeeperRenameFurballFilterInput;
  TimekeeperRenameFurballSortInput: TimekeeperRenameFurballSortInput;
  TimekeeperRequest: ResolverTypeWrapper<TimekeeperRequest>;
  TimekeeperResolution: ResolverTypeWrapper<TimekeeperResolution>;
  TimekeeperRound: ResolverTypeWrapper<TimekeeperRound>;
  TimekeeperToggle: ResolverTypeWrapper<TimekeeperToggle>;
  TimekeeperToggleArgsInput: TimekeeperToggleArgsInput;
  TimekeeperTransaction: ResolverTypeWrapper<TimekeeperTransaction>;
  TimekeeperTransferTix: ResolverTypeWrapper<TimekeeperTransferTix>;
  TimekeeperTransferTixArgsInput: TimekeeperTransferTixArgsInput;
  TimekeeperUserState: ResolverTypeWrapper<TimekeeperUserState>;
  TimekeeperWrapFur: ResolverTypeWrapper<TimekeeperWrapFur>;
  TimekeeperWrapFurArgsInput: TimekeeperWrapFurArgsInput;
  TixExchange: ResolverTypeWrapper<TixExchange>;
  TokenModelOneToOne: ResolverTypeWrapper<TokenModelOneToOne>;
  TokenSchema: TokenSchema;
  TokenSchemaOperationFilterInput: TokenSchemaOperationFilterInput;
  Tournament: ResolverTypeWrapper<Tournament>;
  TournamentFilterInput: TournamentFilterInput;
  TournamentParticipant: ResolverTypeWrapper<TournamentParticipant>;
  TournamentParticipantFilterInput: TournamentParticipantFilterInput;
  TournamentRewardArgsInput: TournamentRewardArgsInput;
  TournamentScoringStyle: TournamentScoringStyle;
  TournamentScoringStyleOperationFilterInput: TournamentScoringStyleOperationFilterInput;
  UInt32FilterInput: UInt32FilterInput;
  UInt64FilterInput: UInt64FilterInput;
  UnreadNotificationsConnection: ResolverTypeWrapper<UnreadNotificationsConnection>;
  UnreadNotificationsEdge: ResolverTypeWrapper<UnreadNotificationsEdge>;
  UnsignedInt: ResolverTypeWrapper<Scalars['UnsignedInt']>;
  UnsignedLong: ResolverTypeWrapper<Scalars['UnsignedLong']>;
  UnsignedShort: ResolverTypeWrapper<Scalars['UnsignedShort']>;
  UsageFrequency: UsageFrequency;
  UsageFrequencyOperationFilterInput: UsageFrequencyOperationFilterInput;
  VfxAsset: ResolverTypeWrapper<VfxAsset>;
  VfxAssetFilterInput: VfxAssetFilterInput;
  VfxAssetSortInput: VfxAssetSortInput;
  Voyage: ResolverTypeWrapper<Voyage>;
  VoyageEncounter: ResolverTypeWrapper<VoyageEncounter>;
  VoyageEncounterFilterInput: VoyageEncounterFilterInput;
  VoyageEncounterType: VoyageEncounterType;
  VoyageEncounterTypeOperationFilterInput: VoyageEncounterTypeOperationFilterInput;
  VoyageFilterInput: VoyageFilterInput;
  VoyageSortInput: VoyageSortInput;
  VoyageState: VoyageState;
  VoyageStateOperationFilterInput: VoyageStateOperationFilterInput;
  WaitlistFlag: WaitlistFlag;
  WaitlistFlagOperationFilterInput: WaitlistFlagOperationFilterInput;
  WaypointStyle: WaypointStyle;
  WaypointStyleOperationFilterInput: WaypointStyleOperationFilterInput;
  WorkLockState: WorkLockState;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountsConnection: AccountsConnection;
  Int: Scalars['Int'];
  AccountsEdge: AccountsEdge;
  String: Scalars['String'];
  AirdropRequest: AirdropRequest;
  AirdropRequestArgsInput: AirdropRequestArgsInput;
  AnimationAsset: AnimationAsset;
  Float: Scalars['Float'];
  AnimationAssetFilterInput: AnimationAssetFilterInput;
  AnimationAssetInput: AnimationAssetInput;
  AnimationAssetSortInput: AnimationAssetSortInput;
  AnteInput: AnteInput;
  AntePool: AntePool;
  AntePoolFilterInput: AntePoolFilterInput;
  AntePoolSortInput: AntePoolSortInput;
  AppleOAuthDTOInput: AppleOAuthDtoInput;
  BattleEffectPassive: BattleEffectPassive;
  ID: Scalars['ID'];
  BattleEffectPassiveFilterInput: BattleEffectPassiveFilterInput;
  BattleMove: Omit<BattleMove, 'item'> & {
    item: Maybe<ResolversParentTypes['GameItem']>;
  };
  Boolean: Scalars['Boolean'];
  BattleMoveFilterInput: BattleMoveFilterInput;
  BattleMoveKindOperationFilterInput: BattleMoveKindOperationFilterInput;
  BattleMoveNext: BattleMoveNext;
  BattleMoveNextFilterInput: BattleMoveNextFilterInput;
  BattleMoveNextSortInput: BattleMoveNextSortInput;
  BattleMoveOutcome: BattleMoveOutcome;
  BattleMoveOutcomeFilterInput: BattleMoveOutcomeFilterInput;
  BattleMoveRequestInput: BattleMoveRequestInput;
  BattleMoveResponse: BattleMoveResponse;
  BattlePercentiles: BattlePercentiles;
  BattleStats: BattleStats;
  BattleStatsFilterInput: BattleStatsFilterInput;
  BattleStatsInput: BattleStatsInput;
  BattleStatsSortInput: BattleStatsSortInput;
  BattleTurnPhaseOperationFilterInput: BattleTurnPhaseOperationFilterInput;
  BattlesConnection: BattlesConnection;
  BattlesEdge: BattlesEdge;
  BlockWork: BlockWork;
  BlockchainModel: BlockchainModel;
  BlockchainToken: BlockchainToken;
  BlockchainTokenFilterInput: BlockchainTokenFilterInput;
  BlockchainTokenSortInput: BlockchainTokenSortInput;
  BlockchainTokenTransaction: BlockchainTokenTransaction;
  BlockchainTokenTransactionFilterInput: BlockchainTokenTransactionFilterInput;
  BlockchainTokenTransactionSortInput: BlockchainTokenTransactionSortInput;
  BlockchainTransaction: BlockchainTransaction;
  BlockchainTransactionFilterInput: BlockchainTransactionFilterInput;
  BlockchainTransactionSortInput: BlockchainTransactionSortInput;
  BooleanOperationFilterInput: BooleanOperationFilterInput;
  BossBattlesConnection: BossBattlesConnection;
  BossBattlesEdge: BossBattlesEdge;
  BossFightsConnection: BossFightsConnection;
  BossFightsEdge: BossFightsEdge;
  Byte: Scalars['Byte'];
  ByteOperationFilterInput: ByteOperationFilterInput;
  ClientSpecification: ClientSpecification;
  CommunityFlagOperationFilterInput: CommunityFlagOperationFilterInput;
  ConsumableItem: ConsumableItem;
  ConsumableItemFilterInput: ConsumableItemFilterInput;
  ConsumableItemSortInput: ConsumableItemSortInput;
  ContainerItem: Omit<ContainerItem, 'contents' | 'entity' | 'items'> & {
    contents: Array<ResolversParentTypes['GameItem']>;
    entity: Maybe<ResolversParentTypes['GameEntity']>;
    items: Array<ResolversParentTypes['GameItem']>;
  };
  ContainerItemFilterInput: ContainerItemFilterInput;
  ContainerItemSortInput: ContainerItemSortInput;
  CraftRecipeIngredient: CraftRecipeIngredient;
  CraftRecipeIngredientFilterInput: CraftRecipeIngredientFilterInput;
  CraftRecipeItemTypeOperationFilterInput: CraftRecipeItemTypeOperationFilterInput;
  CraftSlot: CraftSlot;
  CraftSlotFilterInput: CraftSlotFilterInput;
  CraftingAttempt: CraftingAttempt;
  CraftingAttemptFilterInput: CraftingAttemptFilterInput;
  CraftingRecipe: CraftingRecipe;
  CraftingRecipeFilterInput: CraftingRecipeFilterInput;
  CraftingRecipeSortInput: CraftingRecipeSortInput;
  CurrencyCost: CurrencyCost;
  CurrencyCosts: CurrencyCosts;
  CurrencyExchangeRates: CurrencyExchangeRates;
  CurrencyTypeOperationFilterInput: CurrencyTypeOperationFilterInput;
  DateTime: Scalars['DateTime'];
  DateTimeOperationFilterInput: DateTimeOperationFilterInput;
  Decimal: Scalars['Decimal'];
  DecimalOperationFilterInput: DecimalOperationFilterInput;
  DuelBattlesConnection: DuelBattlesConnection;
  DuelBattlesEdge: DuelBattlesEdge;
  DutchAuction: DutchAuction;
  DutchAuctionResult: DutchAuctionResult;
  EditionAsset: EditionAsset;
  EditionAssetFilterInput: EditionAssetFilterInput;
  EditionAssetSortInput: EditionAssetSortInput;
  EditionBase: EditionBase;
  EditionBaseFilterInput: EditionBaseFilterInput;
  EditionBaseSortInput: EditionBaseSortInput;
  EntityAnimationCollection: EntityAnimationCollection;
  EntityAnimationCollectionFilterInput: EntityAnimationCollectionFilterInput;
  EntityAnimationCollectionSortInput: EntityAnimationCollectionSortInput;
  EntityEffect: Omit<EntityEffect, 'item'> & {
    item: Maybe<ResolversParentTypes['GameItem']>;
  };
  EntityEffectFilterInput: EntityEffectFilterInput;
  EntityEffectOperandOperationFilterInput: EntityEffectOperandOperationFilterInput;
  EntitySkill: EntitySkill;
  EntitySkillFilterInput: EntitySkillFilterInput;
  EquipmentItem: Omit<EquipmentItem, 'entity'> & {
    entity: Maybe<ResolversParentTypes['GameEntity']>;
  };
  EquipmentItemFilterInput: EquipmentItemFilterInput;
  EquipmentSlotOperationFilterInput: EquipmentSlotOperationFilterInput;
  EthExchange: EthExchange;
  FloatOperationFilterInput: FloatOperationFilterInput;
  FormationDefinition: FormationDefinition;
  FormationDefinitionFilterInput: FormationDefinitionFilterInput;
  FormationDefinitionSortInput: FormationDefinitionSortInput;
  FormationPositionOperationFilterInput: FormationPositionOperationFilterInput;
  FurAccount: FurAccount;
  FurAccountFilterInput: FurAccountFilterInput;
  FurAccountSortInput: FurAccountSortInput;
  FurExceptionInput: FurExceptionInput;
  FurExchange: FurExchange;
  FurFundingLot: FurFundingLot;
  FurOAuthToken: FurOAuthToken;
  FurOAuthTokenFilterInput: FurOAuthTokenFilterInput;
  FurOAuthTokenInput: FurOAuthTokenInput;
  FurOAuthTokenSortInput: FurOAuthTokenSortInput;
  FurPlayer: FurPlayer;
  FurPlayerFilterInput: FurPlayerFilterInput;
  FurPlayerSortInput: FurPlayerSortInput;
  FurPurchasePlayer: FurPurchasePlayer;
  FurScholar: FurScholar;
  FurScholarDiscord: FurScholarDiscord;
  FurScholarDiscordFilterInput: FurScholarDiscordFilterInput;
  FurScholarDiscordSortInput: FurScholarDiscordSortInput;
  FurScholarEmail: FurScholarEmail;
  FurScholarEmailFilterInput: FurScholarEmailFilterInput;
  FurScholarEmailSortInput: FurScholarEmailSortInput;
  FurSession: FurSession;
  FurSessionFilterInput: FurSessionFilterInput;
  FurSessionSortInput: FurSessionSortInput;
  FurUpdates: FurUpdates;
  Furball: Furball;
  FurballAttribute: FurballAttribute;
  FurballAttributeFilterInput: FurballAttributeFilterInput;
  FurballDefinition: FurballDefinition;
  FurballDefinitionFilterInput: FurballDefinitionFilterInput;
  FurballDefinitionSortInput: FurballDefinitionSortInput;
  FurballFilterInput: FurballFilterInput;
  FurballFiltersInput: FurballFiltersInput;
  FurballInfo: FurballInfo;
  FurballInfoFilterInput: FurballInfoFilterInput;
  FurballInfoSortInput: FurballInfoSortInput;
  FurballLootUpgradeResponse: Omit<
    FurballLootUpgradeResponse,
    'upgradedItem'
  > & { upgradedItem: Maybe<ResolversParentTypes['GameItem']> };
  FurballModification: FurballModification;
  FurballModificationArgsInput: FurballModificationArgsInput;
  FurballNameChange: FurballNameChange;
  FurballNameChangeFilterInput: FurballNameChangeFilterInput;
  FurballNameChangeSortInput: FurballNameChangeSortInput;
  FurballNameChangesConnection: FurballNameChangesConnection;
  FurballNameChangesEdge: FurballNameChangesEdge;
  FurballNumberFilterInput: FurballNumberFilterInput;
  FurballRank: FurballRank;
  FurballRewardModifiers: FurballRewardModifiers;
  FurballRewardModifiersFilterInput: FurballRewardModifiersFilterInput;
  FurballRewardModifiersSortInput: FurballRewardModifiersSortInput;
  FurballSlotOperationFilterInput: FurballSlotOperationFilterInput;
  FurballSnack: FurballSnack;
  FurballSnackFilterInput: FurballSnackFilterInput;
  FurballSortInput: FurballSortInput;
  FurballState: FurballState;
  FurballStats: FurballStats;
  FurballStatsFilterInput: FurballStatsFilterInput;
  FurballStatsSortInput: FurballStatsSortInput;
  FurballToken: FurballToken;
  FurballTokenFilterInput: FurballTokenFilterInput;
  FurballTokenSortInput: FurballTokenSortInput;
  FurballTraitsFilterInput: FurballTraitsFilterInput;
  FurballUpgrade: FurballUpgrade;
  FurballUpgradeBattleStats: FurballUpgradeBattleStats;
  FurballUpgradeEquippedItem: FurballUpgradeEquippedItem;
  FurballUpgradeFilterInput: FurballUpgradeFilterInput;
  FurballUpgradeProgress: FurballUpgradeProgress;
  FurballUpgradeSkill: FurballUpgradeSkill;
  FurballZoneReward: FurballZoneReward;
  FurballZoneRewardFilterInput: FurballZoneRewardFilterInput;
  FurballZoneRewardSortInput: FurballZoneRewardSortInput;
  FurballsMeta: FurballsMeta;
  GameBattle: GameBattle;
  GameBattleBoss: Omit<GameBattleBoss, 'itemsEarned'> & {
    itemsEarned: Array<ResolversParentTypes['GameItem']>;
  };
  GameBattleBossFilterInput: GameBattleBossFilterInput;
  GameBattleBossSortInput: GameBattleBossSortInput;
  GameBattleDuel: GameBattleDuel;
  GameBattleDuelFilterInput: GameBattleDuelFilterInput;
  GameBattleDuelSortInput: GameBattleDuelSortInput;
  GameBattleFilterInput: GameBattleFilterInput;
  GameBattleMonsters: Omit<GameBattleMonsters, 'itemsEarned'> & {
    itemsEarned: Array<ResolversParentTypes['GameItem']>;
  };
  GameBattleMonstersFilterInput: GameBattleMonstersFilterInput;
  GameBattleMonstersSortInput: GameBattleMonstersSortInput;
  GameBattlePvE: Omit<GameBattlePvE, 'itemsEarned'> & {
    itemsEarned: Array<ResolversParentTypes['GameItem']>;
  };
  GameBattlePvEFilterInput: GameBattlePvEFilterInput;
  GameBattlePvP: GameBattlePvP;
  GameBattlePvPFilterInput: GameBattlePvPFilterInput;
  GameBattleRules: GameBattleRules;
  GameBattleRulesFilterInput: GameBattleRulesFilterInput;
  GameBattleRulesSortInput: GameBattleRulesSortInput;
  GameBattleSortInput: GameBattleSortInput;
  GameBattleStateOperationFilterInput: GameBattleStateOperationFilterInput;
  GameBattlesConnection: GameBattlesConnection;
  GameBattlesEdge: GameBattlesEdge;
  GameBoss: GameBoss;
  GameBossFilterInput: GameBossFilterInput;
  GameBossSortInput: GameBossSortInput;
  GameBuild: GameBuild;
  GameEntity:
    | ResolversParentTypes['Furball']
    | ResolversParentTypes['GameBoss']
    | ResolversParentTypes['GameMonster'];
  GameEntityFilterInput: GameEntityFilterInput;
  GameEntitySortInput: GameEntitySortInput;
  GameEntitySummary: Omit<GameEntitySummary, 'gameEntity'> & {
    gameEntity: Maybe<ResolversParentTypes['GameEntity']>;
  };
  GameEntitySummaryFilterInput: GameEntitySummaryFilterInput;
  GameItem:
    | ResolversParentTypes['ConsumableItem']
    | ResolversParentTypes['ContainerItem']
    | ResolversParentTypes['EquipmentItem']
    | ResolversParentTypes['LootItem']
    | ResolversParentTypes['MaterialItem']
    | ResolversParentTypes['SpecialItem'];
  GameItemFilterInput: GameItemFilterInput;
  GameItemSortInput: GameItemSortInput;
  GameMonster: GameMonster;
  GameObject:
    | ResolversParentTypes['Furball']
    | ResolversParentTypes['GameBoss']
    | ResolversParentTypes['GameMonster'];
  GameParty: GameParty;
  GamePartyFilterInput: GamePartyFilterInput;
  GamePartySortInput: GamePartySortInput;
  GamePlayRulesInput: GamePlayRulesInput;
  HistoryConnection: HistoryConnection;
  HistoryEdge: HistoryEdge;
  HostNode: HostNode;
  HostNodeFilterInput: HostNodeFilterInput;
  HostNodeSortInput: HostNodeSortInput;
  HostStateOperationFilterInput: HostStateOperationFilterInput;
  HostTask: HostTask;
  HostTaskFilterInput: HostTaskFilterInput;
  HostTaskNameOperationFilterInput: HostTaskNameOperationFilterInput;
  IBattleEffect:
    | ResolversParentTypes['BattleEffectPassive']
    | ResolversParentTypes['BattleMoveOutcome']
    | ResolversParentTypes['EntityEffect'];
  IBattleEffectFilterInput: IBattleEffectFilterInput;
  IFurPlayer:
    | ResolversParentTypes['FurAccount']
    | ResolversParentTypes['FurPlayer']
    | ResolversParentTypes['FurScholar']
    | ResolversParentTypes['FurScholarDiscord']
    | ResolversParentTypes['FurScholarEmail'];
  IFurPlayerFilterInput: IFurPlayerFilterInput;
  IFurPlayerSortInput: IFurPlayerSortInput;
  IntOperationFilterInput: IntOperationFilterInput;
  InventoryItem: InventoryItem;
  InventoryItemFilterInput: InventoryItemFilterInput;
  ItemDefInput: ItemDefInput;
  ItemDefinition: ItemDefinition;
  ItemDefinitionFilterInput: ItemDefinitionFilterInput;
  ItemDefinitionSortInput: ItemDefinitionSortInput;
  ItemDiscriminatorOperationFilterInput: ItemDiscriminatorOperationFilterInput;
  ItemGroupOperationFilterInput: ItemGroupOperationFilterInput;
  ItemListingsConnection: ItemListingsConnection;
  ItemListingsEdge: ItemListingsEdge;
  ItemQuantity: ItemQuantity;
  ItemQuantityFilterInput: ItemQuantityFilterInput;
  ItemReward: ItemReward;
  ItemRewardFilterInput: ItemRewardFilterInput;
  ItemRewardSortInput: ItemRewardSortInput;
  JoinBattleResponse: JoinBattleResponse;
  KeyValuePairOfInt64AndInt32: KeyValuePairOfInt64AndInt32;
  KeyValuePairOfStringAndAnimationAsset: KeyValuePairOfStringAndAnimationAsset;
  KeyValuePairOfStringAndAnimationAssetFilterInput: KeyValuePairOfStringAndAnimationAssetFilterInput;
  Leaderboard: Leaderboard;
  LeaderboardEntityTypeOperationFilterInput: LeaderboardEntityTypeOperationFilterInput;
  LeaderboardFilterInput: LeaderboardFilterInput;
  LeaderboardGroupOperationFilterInput: LeaderboardGroupOperationFilterInput;
  LeaderboardPosition:
    | ResolversParentTypes['LeaderboardSlotFurball']
    | ResolversParentTypes['LeaderboardSlotPlayer'];
  LeaderboardRankFurball: LeaderboardRankFurball;
  LeaderboardRankPlayer: LeaderboardRankPlayer;
  LeaderboardSlot: LeaderboardSlot;
  LeaderboardSlotFurball: LeaderboardSlotFurball;
  LeaderboardSlotPlayer: LeaderboardSlotPlayer;
  LeaderboardStatOperationFilterInput: LeaderboardStatOperationFilterInput;
  LeaderboardTier: LeaderboardTier;
  LeaderboardTierRewardChance: LeaderboardTierRewardChance;
  LeaderboardTierRewardChanceFilterInput: LeaderboardTierRewardChanceFilterInput;
  LeaderboardTierRewardChanceInput: LeaderboardTierRewardChanceInput;
  LeaderboardTierRewards: LeaderboardTierRewards;
  LeaderboardTierRewardsFilterInput: LeaderboardTierRewardsFilterInput;
  LeaderboardTimeframeOperationFilterInput: LeaderboardTimeframeOperationFilterInput;
  ListByteOperationFilterInput: ListByteOperationFilterInput;
  ListCommunityFlagOperationFilterInput: ListCommunityFlagOperationFilterInput;
  ListFilterInputTypeOfBattleEffectPassiveFilterInput: ListFilterInputTypeOfBattleEffectPassiveFilterInput;
  ListFilterInputTypeOfBattleMoveFilterInput: ListFilterInputTypeOfBattleMoveFilterInput;
  ListFilterInputTypeOfBattleMoveOutcomeFilterInput: ListFilterInputTypeOfBattleMoveOutcomeFilterInput;
  ListFilterInputTypeOfBlockchainTokenTransactionFilterInput: ListFilterInputTypeOfBlockchainTokenTransactionFilterInput;
  ListFilterInputTypeOfConsumableItemFilterInput: ListFilterInputTypeOfConsumableItemFilterInput;
  ListFilterInputTypeOfContainerItemFilterInput: ListFilterInputTypeOfContainerItemFilterInput;
  ListFilterInputTypeOfCraftRecipeIngredientFilterInput: ListFilterInputTypeOfCraftRecipeIngredientFilterInput;
  ListFilterInputTypeOfCraftSlotFilterInput: ListFilterInputTypeOfCraftSlotFilterInput;
  ListFilterInputTypeOfCraftingAttemptFilterInput: ListFilterInputTypeOfCraftingAttemptFilterInput;
  ListFilterInputTypeOfEditionAssetFilterInput: ListFilterInputTypeOfEditionAssetFilterInput;
  ListFilterInputTypeOfEntityEffectFilterInput: ListFilterInputTypeOfEntityEffectFilterInput;
  ListFilterInputTypeOfEntitySkillFilterInput: ListFilterInputTypeOfEntitySkillFilterInput;
  ListFilterInputTypeOfEquipmentItemFilterInput: ListFilterInputTypeOfEquipmentItemFilterInput;
  ListFilterInputTypeOfFurPlayerFilterInput: ListFilterInputTypeOfFurPlayerFilterInput;
  ListFilterInputTypeOfFurSessionFilterInput: ListFilterInputTypeOfFurSessionFilterInput;
  ListFilterInputTypeOfFurballAttributeFilterInput: ListFilterInputTypeOfFurballAttributeFilterInput;
  ListFilterInputTypeOfFurballFilterInput: ListFilterInputTypeOfFurballFilterInput;
  ListFilterInputTypeOfFurballSnackFilterInput: ListFilterInputTypeOfFurballSnackFilterInput;
  ListFilterInputTypeOfFurballUpgradeFilterInput: ListFilterInputTypeOfFurballUpgradeFilterInput;
  ListFilterInputTypeOfGameBattleBossFilterInput: ListFilterInputTypeOfGameBattleBossFilterInput;
  ListFilterInputTypeOfGameBattleFilterInput: ListFilterInputTypeOfGameBattleFilterInput;
  ListFilterInputTypeOfGameEntitySummaryFilterInput: ListFilterInputTypeOfGameEntitySummaryFilterInput;
  ListFilterInputTypeOfGameItemFilterInput: ListFilterInputTypeOfGameItemFilterInput;
  ListFilterInputTypeOfGamePartyFilterInput: ListFilterInputTypeOfGamePartyFilterInput;
  ListFilterInputTypeOfHostTaskFilterInput: ListFilterInputTypeOfHostTaskFilterInput;
  ListFilterInputTypeOfIBattleEffectFilterInput: ListFilterInputTypeOfIBattleEffectFilterInput;
  ListFilterInputTypeOfInventoryItemFilterInput: ListFilterInputTypeOfInventoryItemFilterInput;
  ListFilterInputTypeOfItemQuantityFilterInput: ListFilterInputTypeOfItemQuantityFilterInput;
  ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput: ListFilterInputTypeOfKeyValuePairOfStringAndAnimationAssetFilterInput;
  ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput: ListFilterInputTypeOfLeaderboardTierRewardChanceFilterInput;
  ListFilterInputTypeOfLeaderboardTierRewardsFilterInput: ListFilterInputTypeOfLeaderboardTierRewardsFilterInput;
  ListFilterInputTypeOfLootItemFilterInput: ListFilterInputTypeOfLootItemFilterInput;
  ListFilterInputTypeOfMaterialItemFilterInput: ListFilterInputTypeOfMaterialItemFilterInput;
  ListFilterInputTypeOfMonsterSkillDefinitionFilterInput: ListFilterInputTypeOfMonsterSkillDefinitionFilterInput;
  ListFilterInputTypeOfPassiveEffectRangeFilterInput: ListFilterInputTypeOfPassiveEffectRangeFilterInput;
  ListFilterInputTypeOfPlayerAnteFilterInput: ListFilterInputTypeOfPlayerAnteFilterInput;
  ListFilterInputTypeOfPlayerHistoryDayFilterInput: ListFilterInputTypeOfPlayerHistoryDayFilterInput;
  ListFilterInputTypeOfPlayerItemListingFilterInput: ListFilterInputTypeOfPlayerItemListingFilterInput;
  ListFilterInputTypeOfPlayerOnboardingStepFilterInput: ListFilterInputTypeOfPlayerOnboardingStepFilterInput;
  ListFilterInputTypeOfPlayerPayoutFilterInput: ListFilterInputTypeOfPlayerPayoutFilterInput;
  ListFilterInputTypeOfPlayerPendingRewardFilterInput: ListFilterInputTypeOfPlayerPendingRewardFilterInput;
  ListFilterInputTypeOfQuestFilterInput: ListFilterInputTypeOfQuestFilterInput;
  ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput: ListFilterInputTypeOfQuestWaypointBracketDefinitionFilterInput;
  ListFilterInputTypeOfQuestWaypointCompletionFilterInput: ListFilterInputTypeOfQuestWaypointCompletionFilterInput;
  ListFilterInputTypeOfQuestWaypointDefinitionFilterInput: ListFilterInputTypeOfQuestWaypointDefinitionFilterInput;
  ListFilterInputTypeOfRentalAgreementFilterInput: ListFilterInputTypeOfRentalAgreementFilterInput;
  ListFilterInputTypeOfRentalTerminationFilterInput: ListFilterInputTypeOfRentalTerminationFilterInput;
  ListFilterInputTypeOfShopPurchaseFilterInput: ListFilterInputTypeOfShopPurchaseFilterInput;
  ListFilterInputTypeOfSkillStatEffectFilterInput: ListFilterInputTypeOfSkillStatEffectFilterInput;
  ListFilterInputTypeOfSocialOAuthTokenFilterInput: ListFilterInputTypeOfSocialOAuthTokenFilterInput;
  ListFilterInputTypeOfSocialProfileFilterInput: ListFilterInputTypeOfSocialProfileFilterInput;
  ListFilterInputTypeOfSocialRoleFilterInput: ListFilterInputTypeOfSocialRoleFilterInput;
  ListFilterInputTypeOfSpecialItemFilterInput: ListFilterInputTypeOfSpecialItemFilterInput;
  ListFilterInputTypeOfStatEntityFilterInput: ListFilterInputTypeOfStatEntityFilterInput;
  ListFilterInputTypeOfTournamentParticipantFilterInput: ListFilterInputTypeOfTournamentParticipantFilterInput;
  ListFilterInputTypeOfVoyageEncounterFilterInput: ListFilterInputTypeOfVoyageEncounterFilterInput;
  ListFilterInputTypeOfVoyageFilterInput: ListFilterInputTypeOfVoyageFilterInput;
  ListFormationPositionOperationFilterInput: ListFormationPositionOperationFilterInput;
  ListFurballSlotOperationFilterInput: ListFurballSlotOperationFilterInput;
  ListLongOperationFilterInput: ListLongOperationFilterInput;
  ListRealmTypeOperationFilterInput: ListRealmTypeOperationFilterInput;
  ListStringOperationFilterInput: ListStringOperationFilterInput;
  ListWaitlistFlagOperationFilterInput: ListWaitlistFlagOperationFilterInput;
  ListingStyleOperationFilterInput: ListingStyleOperationFilterInput;
  LogBundleInput: LogBundleInput;
  LogEntryInput: LogEntryInput;
  LoginEmailResponse: LoginEmailResponse;
  LoginResponse: LoginResponse;
  Long: Scalars['Long'];
  LongOperationFilterInput: LongOperationFilterInput;
  LootBox: LootBox;
  LootItem: LootItem;
  LootItemFilterInput: LootItemFilterInput;
  MarketListing: MarketListing;
  MaterialItem: MaterialItem;
  MaterialItemFilterInput: MaterialItemFilterInput;
  MonsterBattlesConnection: MonsterBattlesConnection;
  MonsterBattlesEdge: MonsterBattlesEdge;
  MonsterDefinition: MonsterDefinition;
  MonsterDefinitionFilterInput: MonsterDefinitionFilterInput;
  MonsterDefinitionSortInput: MonsterDefinitionSortInput;
  MonsterSkillDefinition: MonsterSkillDefinition;
  MonsterSkillDefinitionFilterInput: MonsterSkillDefinitionFilterInput;
  MoveItemResult: Omit<MoveItemResult, 'item'> & {
    item: ResolversParentTypes['GameItem'];
  };
  MoveZoneRequestInput: MoveZoneRequestInput;
  MoveZoneResponse: Omit<MoveZoneResponse, 'items'> & {
    items: Array<ResolversParentTypes['GameItem']>;
  };
  MovesConnection: MovesConnection;
  MovesEdge: MovesEdge;
  Mutation: {};
  Notification: Notification;
  NotificationFilterInput: NotificationFilterInput;
  NotificationInstance: NotificationInstance;
  NotificationPurposeOperationFilterInput: NotificationPurposeOperationFilterInput;
  NotificationSortInput: NotificationSortInput;
  NotificationsConnection: NotificationsConnection;
  NotificationsEdge: NotificationsEdge;
  NullableOfCommunityFlagOperationFilterInput: NullableOfCommunityFlagOperationFilterInput;
  NullableOfRarityLevelOperationFilterInput: NullableOfRarityLevelOperationFilterInput;
  NullableOfRealmTypeOperationFilterInput: NullableOfRealmTypeOperationFilterInput;
  NullableOfTargetingRuleOperationFilterInput: NullableOfTargetingRuleOperationFilterInput;
  NullableOfTargetingStrategyOperationFilterInput: NullableOfTargetingStrategyOperationFilterInput;
  NullableOfUsageFrequencyOperationFilterInput: NullableOfUsageFrequencyOperationFilterInput;
  OnboardingSequenceTypeOperationFilterInput: OnboardingSequenceTypeOperationFilterInput;
  OutcomeFlagOperationFilterInput: OutcomeFlagOperationFilterInput;
  PageInfo: PageInfo;
  PassiveEffectRange: PassiveEffectRange;
  PassiveEffectRangeFilterInput: PassiveEffectRangeFilterInput;
  PayoutMethodOperationFilterInput: PayoutMethodOperationFilterInput;
  PendingFurWrapsConnection: PendingFurWrapsConnection;
  PendingFurWrapsEdge: PendingFurWrapsEdge;
  PendingRewardRequestInput: PendingRewardRequestInput;
  PlayerAnte: PlayerAnte;
  PlayerAnteFilterInput: PlayerAnteFilterInput;
  PlayerHistoryConnection: PlayerHistoryConnection;
  PlayerHistoryDay: PlayerHistoryDay;
  PlayerHistoryDayFilterInput: PlayerHistoryDayFilterInput;
  PlayerHistoryEdge: PlayerHistoryEdge;
  PlayerItemListing: Omit<PlayerItemListing, 'item'> & {
    item: ResolversParentTypes['GameItem'];
  };
  PlayerItemListingFilterInput: PlayerItemListingFilterInput;
  PlayerItemListingSortInput: PlayerItemListingSortInput;
  PlayerOnboardingStep: PlayerOnboardingStep;
  PlayerOnboardingStepFilterInput: PlayerOnboardingStepFilterInput;
  PlayerPayout: PlayerPayout;
  PlayerPayoutFilterInput: PlayerPayoutFilterInput;
  PlayerPendingReward: Omit<PlayerPendingReward, 'items'> & {
    items: Array<ResolversParentTypes['GameItem']>;
  };
  PlayerPendingRewardFilterInput: PlayerPendingRewardFilterInput;
  PlayerPendingRewardSortInput: PlayerPendingRewardSortInput;
  PlayerPurchase: PlayerPurchase;
  PlayerTransactionsConnection: PlayerTransactionsConnection;
  PlayerTransactionsEdge: PlayerTransactionsEdge;
  PriorRewardsConnection: PriorRewardsConnection;
  PriorRewardsEdge: PriorRewardsEdge;
  Query: {};
  Quest: Quest;
  QuestDefinition: QuestDefinition;
  QuestDefinitionFilterInput: QuestDefinitionFilterInput;
  QuestFilterInput: QuestFilterInput;
  QuestWaypointBracketDefinition: QuestWaypointBracketDefinition;
  QuestWaypointBracketDefinitionFilterInput: QuestWaypointBracketDefinitionFilterInput;
  QuestWaypointCompletion: QuestWaypointCompletion;
  QuestWaypointCompletionFilterInput: QuestWaypointCompletionFilterInput;
  QuestWaypointDefinition: QuestWaypointDefinition;
  QuestWaypointDefinitionFilterInput: QuestWaypointDefinitionFilterInput;
  RandomParty: RandomParty;
  RarityLevelOperationFilterInput: RarityLevelOperationFilterInput;
  RealmTypeOperationFilterInput: RealmTypeOperationFilterInput;
  RecentBossBattles: RecentBossBattles;
  RenameFurballArgsInput: RenameFurballArgsInput;
  RentalAgreement: RentalAgreement;
  RentalAgreementFilterInput: RentalAgreementFilterInput;
  RentalAgreementSortInput: RentalAgreementSortInput;
  RentalAgreementsConnection: RentalAgreementsConnection;
  RentalAgreementsEdge: RentalAgreementsEdge;
  RentalTermination: RentalTermination;
  RentalTerminationFilterInput: RentalTerminationFilterInput;
  RentalTermsInput: RentalTermsInput;
  RewardSourceOperationFilterInput: RewardSourceOperationFilterInput;
  ScholarsDiscordConnection: ScholarsDiscordConnection;
  ScholarsDiscordEdge: ScholarsDiscordEdge;
  ScholarsEmailConnection: ScholarsEmailConnection;
  ScholarsEmailEdge: ScholarsEmailEdge;
  SearchFurballsConnection: SearchFurballsConnection;
  SearchFurballsEdge: SearchFurballsEdge;
  SetGamePartyInput: SetGamePartyInput;
  SfxAsset: SfxAsset;
  SfxAssetFilterInput: SfxAssetFilterInput;
  ShopPurchase: ShopPurchase;
  ShopPurchaseFilterInput: ShopPurchaseFilterInput;
  ShopSnack: ShopSnack;
  ShopWares: ShopWares;
  ShopWaresFilterInput: ShopWaresFilterInput;
  Short: Scalars['Short'];
  ShortOperationFilterInput: ShortOperationFilterInput;
  SkillDefinition: SkillDefinition;
  SkillDefinitionFilterInput: SkillDefinitionFilterInput;
  SkillDefinitionSortInput: SkillDefinitionSortInput;
  SkillStatEffect: SkillStatEffect;
  SkillStatEffectFilterInput: SkillStatEffectFilterInput;
  SkillTargetOperationFilterInput: SkillTargetOperationFilterInput;
  SkillTargetTeamOperationFilterInput: SkillTargetTeamOperationFilterInput;
  SlotsConnection: SlotsConnection;
  SlotsEdge: SlotsEdge;
  SocialOAuthToken: SocialOAuthToken;
  SocialOAuthTokenFilterInput: SocialOAuthTokenFilterInput;
  SocialProfile: SocialProfile;
  SocialProfileFilterInput: SocialProfileFilterInput;
  SocialProfileSortInput: SocialProfileSortInput;
  SocialRole: SocialRole;
  SocialRoleFilterInput: SocialRoleFilterInput;
  SpecialItem: SpecialItem;
  SpecialItemFilterInput: SpecialItemFilterInput;
  SpecializationOperationFilterInput: SpecializationOperationFilterInput;
  StatEffectBonusOperationFilterInput: StatEffectBonusOperationFilterInput;
  StatEffectTargetOperationFilterInput: StatEffectTargetOperationFilterInput;
  StatEntity: Omit<StatEntity, 'battleStats' | 'entity' | 'items' | 'loot'> & {
    battleStats: Maybe<ResolversParentTypes['GameEntity']>;
    entity: Maybe<ResolversParentTypes['GameEntity']>;
    items: Array<ResolversParentTypes['GameItem']>;
    loot: Array<ResolversParentTypes['GameItem']>;
  };
  StatEntityFilterInput: StatEntityFilterInput;
  StatEntitySortInput: StatEntitySortInput;
  StringOperationFilterInput: StringOperationFilterInput;
  Subscription: {};
  TierRewardTypeOperationFilterInput: TierRewardTypeOperationFilterInput;
  TiersConnection: TiersConnection;
  TiersEdge: TiersEdge;
  TimeSpan: Scalars['TimeSpan'];
  TimeSpanOperationFilterInput: TimeSpanOperationFilterInput;
  TimekeeperAdHoc: TimekeeperAdHoc;
  TimekeeperAdHocArgsInput: TimekeeperAdHocArgsInput;
  TimekeeperArgsInput: TimekeeperArgsInput;
  TimekeeperChangeSet: TimekeeperChangeSet;
  TimekeeperCosts: TimekeeperCosts;
  TimekeeperInstant: TimekeeperInstant;
  TimekeeperInstantFilterInput: TimekeeperInstantFilterInput;
  TimekeeperInstantSortInput: TimekeeperInstantSortInput;
  TimekeeperInteraction: TimekeeperInteraction;
  TimekeeperInteractionArgInput: TimekeeperInteractionArgInput;
  TimekeeperModeChange: TimekeeperModeChange;
  TimekeeperRenameFurball: TimekeeperRenameFurball;
  TimekeeperRenameFurballFilterInput: TimekeeperRenameFurballFilterInput;
  TimekeeperRenameFurballSortInput: TimekeeperRenameFurballSortInput;
  TimekeeperRequest: TimekeeperRequest;
  TimekeeperResolution: TimekeeperResolution;
  TimekeeperRound: TimekeeperRound;
  TimekeeperToggle: TimekeeperToggle;
  TimekeeperToggleArgsInput: TimekeeperToggleArgsInput;
  TimekeeperTransaction: TimekeeperTransaction;
  TimekeeperTransferTix: TimekeeperTransferTix;
  TimekeeperTransferTixArgsInput: TimekeeperTransferTixArgsInput;
  TimekeeperUserState: TimekeeperUserState;
  TimekeeperWrapFur: TimekeeperWrapFur;
  TimekeeperWrapFurArgsInput: TimekeeperWrapFurArgsInput;
  TixExchange: TixExchange;
  TokenModelOneToOne: TokenModelOneToOne;
  TokenSchemaOperationFilterInput: TokenSchemaOperationFilterInput;
  Tournament: Tournament;
  TournamentFilterInput: TournamentFilterInput;
  TournamentParticipant: TournamentParticipant;
  TournamentParticipantFilterInput: TournamentParticipantFilterInput;
  TournamentRewardArgsInput: TournamentRewardArgsInput;
  TournamentScoringStyleOperationFilterInput: TournamentScoringStyleOperationFilterInput;
  UInt32FilterInput: UInt32FilterInput;
  UInt64FilterInput: UInt64FilterInput;
  UnreadNotificationsConnection: UnreadNotificationsConnection;
  UnreadNotificationsEdge: UnreadNotificationsEdge;
  UnsignedInt: Scalars['UnsignedInt'];
  UnsignedLong: Scalars['UnsignedLong'];
  UnsignedShort: Scalars['UnsignedShort'];
  UsageFrequencyOperationFilterInput: UsageFrequencyOperationFilterInput;
  VfxAsset: VfxAsset;
  VfxAssetFilterInput: VfxAssetFilterInput;
  VfxAssetSortInput: VfxAssetSortInput;
  Voyage: Voyage;
  VoyageEncounter: VoyageEncounter;
  VoyageEncounterFilterInput: VoyageEncounterFilterInput;
  VoyageEncounterTypeOperationFilterInput: VoyageEncounterTypeOperationFilterInput;
  VoyageFilterInput: VoyageFilterInput;
  VoyageSortInput: VoyageSortInput;
  VoyageStateOperationFilterInput: VoyageStateOperationFilterInput;
  WaitlistFlagOperationFilterInput: WaitlistFlagOperationFilterInput;
  WaypointStyleOperationFilterInput: WaypointStyleOperationFilterInput;
};

export type AccountsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountsConnection'] = ResolversParentTypes['AccountsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['AccountsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['FurAccount']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccountsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AccountsEdge'] = ResolversParentTypes['AccountsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AirdropRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AirdropRequest'] = ResolversParentTypes['AirdropRequest'],
> = {
  approvedBy: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  fur: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipient: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signature: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tix: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AnimationAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AnimationAsset'] = ResolversParentTypes['AnimationAsset'],
> = {
  damageAt: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AntePoolResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['AntePool'] = ResolversParentTypes['AntePool'],
> = {
  furAmount: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furPayout: Resolver<
    Maybe<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  furPayoutId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerAntes: Resolver<
    Array<ResolversTypes['PlayerAnte']>,
    ParentType,
    ContextType
  >;
  playerCount: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleEffectPassiveResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleEffectPassive'] = ResolversParentTypes['BattleEffectPassive'],
> = {
  compact: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decay: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleMoveResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleMove'] = ResolversParentTypes['BattleMove'],
> = {
  actor: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  actorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  allEntities: Resolver<
    Array<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  animation: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  animationDuration: Resolver<
    ResolversTypes['TimeSpan'],
    ParentType,
    ContextType
  >;
  battle: Resolver<ResolversTypes['GameBattle'], ParentType, ContextType>;
  battleId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  bossBattle: Resolver<
    Maybe<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<BattleMoveCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  count: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  duelBattle: Resolver<
    Maybe<ResolversTypes['GameBattleDuel']>,
    ParentType,
    ContextType
  >;
  effect: Resolver<
    Maybe<ResolversTypes['EntityEffect']>,
    ParentType,
    ContextType
  >;
  effectId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effectsDuration: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  endsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  entityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entityMoveNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expGained: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expectedStartTime: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isBackground: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOffensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPlayerAction: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['GameItem']>, ParentType, ContextType>;
  itemId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  levelsGained: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  monsterBattle: Resolver<
    Maybe<ResolversTypes['GameBattleMonsters']>,
    ParentType,
    ContextType
  >;
  moveId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moveKind: Resolver<ResolversTypes['BattleMoveKind'], ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomes: Resolver<
    Array<ResolversTypes['BattleMoveOutcome']>,
    ParentType,
    ContextType
  >;
  pvEBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvE']>,
    ParentType,
    ContextType
  >;
  pvPBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvP']>,
    ParentType,
    ContextType
  >;
  ranAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rebroadcastCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skill: Resolver<
    Maybe<ResolversTypes['EntitySkill']>,
    ParentType,
    ContextType
  >;
  skillId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  targetIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  targetIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetsAiTeam: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  useJumpAnimation: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  vfx: Resolver<Maybe<ResolversTypes['VfxAsset']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleMoveNextResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleMoveNext'] = ResolversParentTypes['BattleMoveNext'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entity: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  entityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  entityMoveNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expectedStartTime: Resolver<
    ResolversTypes['DateTime'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  moveId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  moveKind: Resolver<ResolversTypes['BattleMoveKind'], ParentType, ContextType>;
  targetIdList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetIds: Resolver<
    Maybe<Array<ResolversTypes['String']>>,
    ParentType,
    ContextType
  >;
  targetIdsList: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleMoveOutcomeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleMoveOutcome'] = ResolversParentTypes['BattleMoveOutcome'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  damage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  decay: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  effect: Resolver<
    Maybe<ResolversTypes['EntityEffect']>,
    ParentType,
    ContextType
  >;
  effectId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entity: Resolver<
    Maybe<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  entityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  expIntervalsGained: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  flags: Resolver<ResolversTypes['OutcomeFlag'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  ids: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  idsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isDamage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isHeal: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOffensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  move: Resolver<ResolversTypes['BattleMove'], ParentType, ContextType>;
  moveId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  score: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleMoveResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleMoveResponse'] = ResolversParentTypes['BattleMoveResponse'],
> = {
  activeBossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  actor: Resolver<Maybe<ResolversTypes['StatEntity']>, ParentType, ContextType>;
  actorId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battle: Resolver<ResolversTypes['GameBattle'], ParentType, ContextType>;
  bossBattle: Resolver<
    Maybe<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  duelBattle: Resolver<
    Maybe<ResolversTypes['GameBattleDuel']>,
    ParentType,
    ContextType
  >;
  monsterBattle: Resolver<
    Maybe<ResolversTypes['GameBattleMonsters']>,
    ParentType,
    ContextType
  >;
  move: Resolver<Maybe<ResolversTypes['BattleMove']>, ParentType, ContextType>;
  percentiles: Resolver<
    ResolversTypes['BattlePercentiles'],
    ParentType,
    ContextType
  >;
  pvEBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvE']>,
    ParentType,
    ContextType
  >;
  pvPBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvP']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattlePercentilesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattlePercentiles'] = ResolversParentTypes['BattlePercentiles'],
> = {
  bossLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  maxScore: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  minScore: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  pctRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pctile: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  percentile: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentileRank: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  scores: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  spread: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattleStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattleStats'] = ResolversParentTypes['BattleStats'],
> = {
  attackPower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  buff: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  critAttackPower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  critRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deBuff: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defencePower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  heal: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  karma: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxHealth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  revive: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  speed: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vamp: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattlesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattlesConnection'] = ResolversParentTypes['BattlesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['BattlesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattleBoss']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BattlesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BattlesEdge'] = ResolversParentTypes['BattlesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattleBoss'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockWorkResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockWork'] = ResolversParentTypes['BlockWork'],
> = {
  averageBaseFee: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averageTransactionFee: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  completedBlockNumber: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  curBlockNumber: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  gasPrice: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  hasStarted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  host: Resolver<ResolversTypes['HostNode'], ParentType, ContextType>;
  hostName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lockState: Resolver<ResolversTypes['WorkLockState'], ParentType, ContextType>;
  numBlocks: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  startBlockNumber: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  stopBlockNumber: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainModelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainModel'] = ResolversParentTypes['BlockchainModel'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainToken'] = ResolversParentTypes['BlockchainToken'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isWrapped: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  schema: Resolver<ResolversTypes['TokenSchema'], ParentType, ContextType>;
  symbol: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalSupply: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  unwrappedToken: Resolver<
    Maybe<ResolversTypes['BlockchainToken']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  wrappedToken: Resolver<
    Maybe<ResolversTypes['BlockchainToken']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainTokenTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainTokenTransaction'] = ResolversParentTypes['BlockchainTokenTransaction'],
> = {
  amount: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  from: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  furball: Resolver<Maybe<ResolversTypes['Furball']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  instanceId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  instanceType: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  itemId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  occurredAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  to: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token: Resolver<ResolversTypes['BlockchainToken'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  validation: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wrappedTransactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  writeBack: Resolver<
    Maybe<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BlockchainTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BlockchainTransaction'] = ResolversParentTypes['BlockchainTransaction'],
> = {
  blockNumber: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  contractAddress: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  cumulativeGasUsed: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  effectiveGasPrice: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gasEstimate: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  gasUsed: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gweiToGas: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  index: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  instant: Resolver<
    Maybe<ResolversTypes['TimekeeperInstant']>,
    ParentType,
    ContextType
  >;
  logsBloom: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BossBattlesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BossBattlesConnection'] = ResolversParentTypes['BossBattlesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['BossBattlesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattleBoss']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BossBattlesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BossBattlesEdge'] = ResolversParentTypes['BossBattlesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattleBoss'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BossFightsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BossFightsConnection'] = ResolversParentTypes['BossFightsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['BossFightsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattleBoss']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BossFightsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['BossFightsEdge'] = ResolversParentTypes['BossFightsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattleBoss'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ByteScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Byte'], any> {
  name: 'Byte';
}

export type ClientSpecificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ClientSpecification'] = ResolversParentTypes['ClientSpecification'],
> = {
  referenceDpi: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumableItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ConsumableItem'] = ResolversParentTypes['ConsumableItem'],
> = {
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ContainerItem'] = ResolversParentTypes['ContainerItem'],
> = {
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  containerItems: Resolver<
    Array<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  contents: Resolver<
    Array<ResolversTypes['GameItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entity: Resolver<
    Maybe<ResolversTypes['GameEntity']>,
    ParentType,
    ContextType
  >;
  entityId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipmentItems: Resolver<
    Array<ResolversTypes['EquipmentItem']>,
    ParentType,
    ContextType
  >;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  hasItemQuantities: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  itemQuantities: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  items: Resolver<Array<ResolversTypes['GameItem']>, ParentType, ContextType>;
  lootItems: Resolver<
    Array<ResolversTypes['LootItem']>,
    ParentType,
    ContextType
  >;
  materialItems: Resolver<
    Array<ResolversTypes['MaterialItem']>,
    ParentType,
    ContextType
  >;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  playerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  quantities: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  specialItems: Resolver<
    Array<ResolversTypes['SpecialItem']>,
    ParentType,
    ContextType
  >;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalDustCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraftRecipeIngredientResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraftRecipeIngredient'] = ResolversParentTypes['CraftRecipeIngredient'],
> = {
  amount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  itemType: Resolver<
    ResolversTypes['CraftRecipeItemType'],
    ParentType,
    ContextType
  >;
  rarity: Resolver<
    Maybe<ResolversTypes['RarityLevel']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<Maybe<ResolversTypes['RealmType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraftSlotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraftSlot'] = ResolversParentTypes['CraftSlot'],
> = {
  crafterId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentAttempt: Resolver<
    Maybe<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  currentAttemptId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isLocked: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  slotIndex: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unlockCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  unlockedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraftingAttemptResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraftingAttempt'] = ResolversParentTypes['CraftingAttempt'],
> = {
  completesAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipe: Resolver<ResolversTypes['CraftingRecipe'], ParentType, ContextType>;
  recipeId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slot: Resolver<Maybe<ResolversTypes['CraftSlot']>, ParentType, ContextType>;
  speedUpCostPerHour: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  speedUpDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CraftingRecipeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CraftingRecipe'] = ResolversParentTypes['CraftingRecipe'],
> = {
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ingredients: Resolver<
    Array<ResolversTypes['CraftRecipeIngredient']>,
    ParentType,
    ContextType
  >;
  item: Resolver<ResolversTypes['ItemDefinition'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyCostResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrencyCost'] = ResolversParentTypes['CurrencyCost'],
> = {
  eth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fuel: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tix: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  usd: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyCostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrencyCosts'] = ResolversParentTypes['CurrencyCosts'],
> = {
  costBase: Resolver<ResolversTypes['CurrencyCost'], ParentType, ContextType>;
  costEach: Resolver<ResolversTypes['CurrencyCost'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyExchangeRatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CurrencyExchangeRates'] = ResolversParentTypes['CurrencyExchangeRates'],
> = {
  eth: Resolver<ResolversTypes['EthExchange'], ParentType, ContextType>;
  fur: Resolver<ResolversTypes['FurExchange'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tix: Resolver<ResolversTypes['TixExchange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DecimalScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal';
}

export type DuelBattlesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DuelBattlesConnection'] = ResolversParentTypes['DuelBattlesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['DuelBattlesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattleDuel']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DuelBattlesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DuelBattlesEdge'] = ResolversParentTypes['DuelBattlesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattleDuel'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DutchAuctionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DutchAuction'] = ResolversParentTypes['DutchAuction'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  currentCost: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  kind: Resolver<ResolversTypes['AuctionKind'], ParentType, ContextType>;
  lastCost: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  player: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  playerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reducedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  sequence: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  soldAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startingCost: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  targetDays: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DutchAuctionResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DutchAuctionResult'] = ResolversParentTypes['DutchAuctionResult'],
> = {
  account: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  auction: Resolver<ResolversTypes['DutchAuction'], ParentType, ContextType>;
  outcome: Resolver<ResolversTypes['AuctionOutcome'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditionAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditionAsset'] = ResolversParentTypes['EditionAsset'],
> = {
  assetIndex: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  componentName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  editionNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  filePath: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rarityLevel: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  skill: Resolver<
    Maybe<ResolversTypes['SkillDefinition']>,
    ParentType,
    ContextType
  >;
  slot: Resolver<ResolversTypes['FurballSlot'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EditionBaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EditionBase'] = ResolversParentTypes['EditionBase'],
> = {
  allAssets: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  asset: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType,
    RequireFields<EditionBaseAssetArgs, 'offset' | 'tokenByte'>
  >;
  assetSlots: Resolver<
    Array<ResolversTypes['FurballSlot']>,
    ParentType,
    ContextType
  >;
  backgroundColors: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  bodies: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  byteNumber: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType,
    RequireFields<EditionBaseByteNumberArgs, 'slot'>
  >;
  ears: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  editionIndex: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  eyes: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  hats: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  mouths: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  offHands: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  outfits: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  paletteColors: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  slotAssets: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType,
    RequireFields<EditionBaseSlotAssetsArgs, 'slot'>
  >;
  tails: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  tattoos: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  weapons: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  wings: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityAnimationCollectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntityAnimationCollection'] = ResolversParentTypes['EntityAnimationCollection'],
> = {
  animationGroup: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  animationKey: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<EntityAnimationCollectionAnimationKeyArgs, 'animationAsset'>
  >;
  animations: Resolver<
    Array<ResolversTypes['KeyValuePairOfStringAndAnimationAsset']>,
    ParentType,
    ContextType
  >;
  attack: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  attackRanged: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  buffAP: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  buffCritAP: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  buffDP: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  death: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  debuffAP: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  debuffCritAP: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  debuffDP: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  find: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType,
    RequireFields<EntityAnimationCollectionFindArgs, 'animationId'>
  >;
  heal: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  idle: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  idleVariation: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  invulnerability: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  jumpLand: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  jumpStart: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  none: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  paralyze: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  revive: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  reviveTarget: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  slow: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  summon: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  takeDamage: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  vfxGroup: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityEffectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntityEffect'] = ResolversParentTypes['EntityEffect'],
> = {
  activeAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  actor: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  animation: Resolver<
    ResolversTypes['AnimationAsset'],
    ParentType,
    ContextType
  >;
  animationId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buffType: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  created: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  decay: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deductAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entity: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  entityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  icon: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInstant: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  item: Resolver<Maybe<ResolversTypes['GameItem']>, ParentType, ContextType>;
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  phase: Resolver<ResolversTypes['BattleTurnPhase'], ParentType, ContextType>;
  reApply: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  removedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  sfx: Resolver<Maybe<ResolversTypes['SfxAsset']>, ParentType, ContextType>;
  skill: Resolver<
    Maybe<ResolversTypes['EntitySkill']>,
    ParentType,
    ContextType
  >;
  skillEffect: Resolver<
    ResolversTypes['SkillStatEffect'],
    ParentType,
    ContextType
  >;
  skillEffectId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  skillEffectName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  skillId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  summaryStat: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timeRemaining: Resolver<
    Maybe<ResolversTypes['TimeSpan']>,
    ParentType,
    ContextType
  >;
  turnsRemaining: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vfx: Resolver<Maybe<ResolversTypes['VfxAsset']>, ParentType, ContextType>;
  vfxId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntitySkillResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EntitySkill'] = ResolversParentTypes['EntitySkill'],
> = {
  aiTargetingRules: Resolver<
    Maybe<ResolversTypes['TargetingRule']>,
    ParentType,
    ContextType
  >;
  aiTargetingStrategy: Resolver<
    Maybe<ResolversTypes['TargetingStrategy']>,
    ParentType,
    ContextType
  >;
  aiUsageFrequency: Resolver<
    Maybe<ResolversTypes['UsageFrequency']>,
    ParentType,
    ContextType
  >;
  buffType: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['SkillDefinition'],
    ParentType,
    ContextType
  >;
  effectsSummary: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  entity: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  entityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  levelIsUpgradeable: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  levelNumPartialsPerTier: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  levelPartialCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  levelPartialUpgrade: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  levelPartialsPurchased: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  levelTotalPartialsRemaining: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  levelTotalSpent: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  levelUpgradeTotalCost: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  levelUpgradedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUses: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesIsUpgradeable: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  maxUsesNumPartialsPerTier: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesPartialCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesPartialUpgrade: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesPartialsPurchased: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesTotalPartialsRemaining: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesTotalSpent: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxUsesUpgradeTotalCost: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesUpgradedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  skillDefinitionId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  slotIndex: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  summary: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalFurSpent: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  useCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  valueUnits: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EquipmentItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EquipmentItem'] = ResolversParentTypes['EquipmentItem'],
> = {
  breakAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  durabilityTime: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  effects: Resolver<
    Array<ResolversTypes['IBattleEffect']>,
    ParentType,
    ContextType
  >;
  entity: Resolver<
    Maybe<ResolversTypes['GameEntity']>,
    ParentType,
    ContextType
  >;
  entityId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equippedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  equippedBy: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  equippedById: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  fusedFurball: Resolver<
    Maybe<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  fusedFurballId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passive: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passives: Resolver<
    Array<ResolversTypes['BattleEffectPassive']>,
    ParentType,
    ContextType
  >;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  slot: Resolver<ResolversTypes['EquipmentSlot'], ParentType, ContextType>;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EthExchangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['EthExchange'] = ResolversParentTypes['EthExchange'],
> = {
  usdPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FormationDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FormationDefinition'] = ResolversParentTypes['FormationDefinition'],
> = {
  comparator: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isValid: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  positions: Resolver<
    Array<ResolversTypes['FormationPosition']>,
    ParentType,
    ContextType
  >;
  size: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurAccountResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurAccount'] = ResolversParentTypes['FurAccount'],
> = {
  activeAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsOwner: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsPlayer: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  balance: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  balanceFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  balanceFurballs: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurAccountCountBossBattlesArgs, 'since'>
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdTimestamp: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dirtyAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  discordProfile: Resolver<
    Maybe<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  displayName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fuel: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelEarned: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  fuelPurchased: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelSpent: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  hasSetUsername: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history: Resolver<
    Maybe<ResolversTypes['HistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurAccountHistoryArgs, never>
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  isAdmin: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isModerator: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  leaderboardRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  maxFurballs: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCheckAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numFurballs: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  payoutsPending: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingFurWraps: Resolver<
    Maybe<ResolversTypes['PendingFurWrapsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurAccountPendingFurWrapsArgs, never>
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingWraps: Resolver<
    Array<ResolversTypes['TimekeeperWrapFur']>,
    ParentType,
    ContextType
  >;
  permissions: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurAccountPriorRewardsArgs, never>
  >;
  profiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  reputation: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  standing: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBought: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixSpent: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  today: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  writtenRolesList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurExchangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurExchange'] = ResolversParentTypes['FurExchange'],
> = {
  amountAvailable: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  costBuy: Resolver<ResolversTypes['CurrencyCosts'], ParentType, ContextType>;
  costSwap: Resolver<ResolversTypes['CurrencyCosts'], ParentType, ContextType>;
  fundingLots: Resolver<
    Array<ResolversTypes['FurFundingLot']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurFundingLotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurFundingLot'] = ResolversParentTypes['FurFundingLot'],
> = {
  amountRemaining: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  closedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  fuelCostBase: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelCostEach: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  funding: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fundingTransactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maximumPurchaseQuantity: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType
  >;
  minimumPurchaseQuantity: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  playerPurchases: Resolver<
    Array<ResolversTypes['FurPurchasePlayer']>,
    ParentType,
    ContextType
  >;
  total: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurOAuthTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurOAuthToken'] = ResolversParentTypes['FurOAuthToken'],
> = {
  access: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deadline: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signature: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurPlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurPlayer'] = ResolversParentTypes['FurPlayer'],
> = {
  activeAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsOwner: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsPlayer: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurPlayerCountBossBattlesArgs, 'since'>
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  furBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasSetUsername: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history: Resolver<
    Maybe<ResolversTypes['HistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurPlayerHistoryArgs, never>
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  leaderboardRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCheckAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  payoutsPending: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingWraps: Resolver<
    Array<ResolversTypes['TimekeeperWrapFur']>,
    ParentType,
    ContextType
  >;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurPlayerPriorRewardsArgs, never>
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBought: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixSpent: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  today: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlagList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  writtenRolesList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurPurchasePlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurPurchasePlayer'] = ResolversParentTypes['FurPurchasePlayer'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fundingLots: Resolver<
    Array<ResolversTypes['FurFundingLot']>,
    ParentType,
    ContextType
  >;
  furTransaction: Resolver<
    ResolversTypes['BlockchainTokenTransaction'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  tixCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixTransaction: Resolver<
    ResolversTypes['BlockchainTokenTransaction'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurScholarResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurScholar'] = ResolversParentTypes['FurScholar'],
> = {
  account: Resolver<
    Maybe<ResolversTypes['FurAccount']>,
    ParentType,
    ContextType
  >;
  accountId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activeAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsOwner: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsPlayer: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurScholarCountBossBattlesArgs, 'since'>
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  furBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasSetUsername: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history: Resolver<
    Maybe<ResolversTypes['HistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarHistoryArgs, never>
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  leaderboardRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCheckAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  payoutsPending: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingWraps: Resolver<
    Array<ResolversTypes['TimekeeperWrapFur']>,
    ParentType,
    ContextType
  >;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarPriorRewardsArgs, never>
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBalance: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBought: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixConsumed: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixPurchased: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixSpent: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  today: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurScholarDiscordResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurScholarDiscord'] = ResolversParentTypes['FurScholarDiscord'],
> = {
  account: Resolver<
    Maybe<ResolversTypes['FurAccount']>,
    ParentType,
    ContextType
  >;
  accountId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activeAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsOwner: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsPlayer: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurScholarDiscordCountBossBattlesArgs, 'since'>
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  furBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasSetUsername: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history: Resolver<
    Maybe<ResolversTypes['HistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarDiscordHistoryArgs, never>
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  leaderboardRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCheckAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  payoutsPending: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingWraps: Resolver<
    Array<ResolversTypes['TimekeeperWrapFur']>,
    ParentType,
    ContextType
  >;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarDiscordPriorRewardsArgs, never>
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBalance: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBought: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixConsumed: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixPurchased: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixSpent: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  today: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurScholarEmailResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurScholarEmail'] = ResolversParentTypes['FurScholarEmail'],
> = {
  account: Resolver<
    Maybe<ResolversTypes['FurAccount']>,
    ParentType,
    ContextType
  >;
  accountId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  activeAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsOwner: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeAgreementsPlayer: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  codeGeneratedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurScholarEmailCountBossBattlesArgs, 'since'>
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  furBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballBalance: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasSetUsername: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  history: Resolver<
    Maybe<ResolversTypes['HistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarEmailHistoryArgs, never>
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  isCodeExpired: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  leaderboardRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextCheckAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  payoutsPending: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  pendingWraps: Resolver<
    Array<ResolversTypes['TimekeeperWrapFur']>,
    ParentType,
    ContextType
  >;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurScholarEmailPriorRewardsArgs, never>
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  referrerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfiles: Resolver<
    Array<ResolversTypes['SocialProfile']>,
    ParentType,
    ContextType
  >;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBalance: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixBought: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixConsumed: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixPurchased: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixSpent: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  today: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurSessionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurSession'] = ResolversParentTypes['FurSession'],
> = {
  accountId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isActive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  oAuthToken: Resolver<
    Maybe<ResolversTypes['FurOAuthToken']>,
    ParentType,
    ContextType
  >;
  oAuthTokenId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerType: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurUpdatesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurUpdates'] = ResolversParentTypes['FurUpdates'],
> = {
  accounts: Resolver<
    Maybe<ResolversTypes['AccountsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurUpdatesAccountsArgs, never>
  >;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  rentalAgreements: Resolver<
    Maybe<ResolversTypes['RentalAgreementsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurUpdatesRentalAgreementsArgs, never>
  >;
  scholarsDiscord: Resolver<
    Maybe<ResolversTypes['ScholarsDiscordConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurUpdatesScholarsDiscordArgs, never>
  >;
  scholarsEmail: Resolver<
    Maybe<ResolversTypes['ScholarsEmailConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurUpdatesScholarsEmailArgs, never>
  >;
  updatedSince: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Furball'] = ResolversParentTypes['Furball'],
> = {
  activeRentalAgreement: Resolver<
    Maybe<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  activeVoyage: Resolver<
    Maybe<ResolversTypes['Voyage']>,
    ParentType,
    ContextType
  >;
  backgroundColor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseModifiersAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  battleCount: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  battleStats: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  battleStatsId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  birth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossFights: Resolver<
    Maybe<ResolversTypes['BossFightsConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurballBossFightsArgs, never>
  >;
  bossKeys: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  chainMode: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  countBossBattles: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType,
    RequireFields<FurballCountBossBattlesArgs, 'since'>
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dailyBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dirtyAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  equipment: Resolver<
    Array<ResolversTypes['EquipmentItem']>,
    ParentType,
    ContextType
  >;
  expBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gameBattles: Resolver<
    Maybe<ResolversTypes['GameBattlesConnection']>,
    ParentType,
    ContextType,
    RequireFields<FurballGameBattlesArgs, never>
  >;
  gameStats: Resolver<
    Array<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  hasDayReset: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  haveHeartsReset: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hearts: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  info: Resolver<ResolversTypes['FurballInfo'], ParentType, ContextType>;
  intervals: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  isListedForSale: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  karma: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  last: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastBattleAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastBossBattleAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastHeartLostAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastResolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastTradeAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastVoyage: Resolver<
    Maybe<ResolversTypes['Voyage']>,
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  marketDeListedAt: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  marketListedAt: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  marketListingCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxDailyBattles: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxExp: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxHearts: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numVoyages: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  number: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  openRentalAgreement: Resolver<
    Maybe<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  owner: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  ownerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partyId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pendingExp: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  pendingFur: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  pendingUpgrade: Resolver<
    Maybe<ResolversTypes['FurballUpgrade']>,
    ParentType,
    ContextType
  >;
  purchases: Resolver<
    Array<ResolversTypes['ShopPurchase']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  skillRollCost: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  skillUpgradesAvailable: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  specialization: Resolver<
    ResolversTypes['Specialization'],
    ParentType,
    ContextType
  >;
  state: Resolver<ResolversTypes['FurballState'], ParentType, ContextType>;
  stats: Resolver<ResolversTypes['FurballStats'], ParentType, ContextType>;
  timekeeperDisabled: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  token: Resolver<ResolversTypes['FurballToken'], ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  upgrades: Resolver<
    Array<ResolversTypes['FurballUpgrade']>,
    ParentType,
    ContextType
  >;
  voyages: Resolver<Array<ResolversTypes['Voyage']>, ParentType, ContextType>;
  wExp: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zone: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zoneReward: Resolver<
    Maybe<ResolversTypes['FurballZoneReward']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballAttributeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballAttribute'] = ResolversParentTypes['FurballAttribute'],
> = {
  displayType: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  traitType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballDefinition'] = ResolversParentTypes['FurballDefinition'],
> = {
  birth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  count: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  experience: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<
    Array<ResolversTypes['InventoryItem']>,
    ParentType,
    ContextType
  >;
  last: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  moves: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  number: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rarity: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  stats: Resolver<ResolversTypes['FurballStats'], ParentType, ContextType>;
  trade: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  weight: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  zone: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballInfo'] = ResolversParentTypes['FurballInfo'],
> = {
  attributeData: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attributes: Resolver<
    Array<ResolversTypes['FurballAttribute']>,
    ParentType,
    ContextType
  >;
  backgroundColor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  image: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rareGenesCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballLootUpgradeResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballLootUpgradeResponse'] = ResolversParentTypes['FurballLootUpgradeResponse'],
> = {
  furball: Resolver<Maybe<ResolversTypes['Furball']>, ParentType, ContextType>;
  upgradedItem: Resolver<
    Maybe<ResolversTypes['GameItem']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballModificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballModification'] = ResolversParentTypes['FurballModification'],
> = {
  addEncounterType: Resolver<
    Maybe<ResolversTypes['VoyageEncounterType']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  drops: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  hash: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pickups: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  setActiveVoyageDays: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  setBondingDays: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  setMarketListingCount: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  signature: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballNameChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballNameChange'] = ResolversParentTypes['FurballNameChange'],
> = {
  approved: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  approvedBy: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  approvedById: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  executedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  request: Resolver<
    ResolversTypes['TimekeeperRenameFurball'],
    ParentType,
    ContextType
  >;
  requestId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballNameChangesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballNameChangesConnection'] = ResolversParentTypes['FurballNameChangesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['FurballNameChangesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['FurballNameChange']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballNameChangesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballNameChangesEdge'] = ResolversParentTypes['FurballNameChangesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['FurballNameChange'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballRankResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballRank'] = ResolversParentTypes['FurballRank'],
> = {
  expRate: Resolver<
    Maybe<ResolversTypes['LeaderboardSlot']>,
    ParentType,
    ContextType
  >;
  furRate: Resolver<
    Maybe<ResolversTypes['LeaderboardSlot']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  level: Resolver<
    Maybe<ResolversTypes['LeaderboardSlot']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballRewardModifiersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballRewardModifiers'] = ResolversParentTypes['FurballRewardModifiers'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  energyPoints: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  happinessPoints: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  luckPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stats: Resolver<ResolversTypes['FurballStats'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  zone: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballSnackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballSnack'] = ResolversParentTypes['FurballSnack'],
> = {
  count: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  energy: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fed: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  happiness: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  snackId: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stats: Resolver<ResolversTypes['FurballStats'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballState'] = ResolversParentTypes['FurballState'],
> = {
  bonded: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bondingDays: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  daysSinceTrade: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  estimatedExperience: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimatedFur: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  estimatedLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expPercentBase: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furPercentBase: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furRateBase: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  happiness: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastTradeAt: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  luckPercent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  luckPercentBase: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  ranks: Resolver<ResolversTypes['FurballRank'], ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  teamSize: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalExp: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalLuck: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalRarity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zone: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballStats'] = ResolversParentTypes['FurballStats'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['FurballDefinition'],
    ParentType,
    ContextType
  >;
  expRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  modifiers: Resolver<
    ResolversTypes['FurballRewardModifiers'],
    ParentType,
    ContextType
  >;
  snacks: Resolver<
    Array<ResolversTypes['FurballSnack']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballToken'] = ResolversParentTypes['FurballToken'],
> = {
  assets: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  backgroundColor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  backgroundIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  body: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  bodyIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bytes: Resolver<Array<ResolversTypes['Byte']>, ParentType, ContextType>;
  ears: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  earsIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  edition: Resolver<ResolversTypes['EditionBase'], ParentType, ContextType>;
  editionIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  eyes: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  eyesIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hat: Resolver<Maybe<ResolversTypes['EditionAsset']>, ParentType, ContextType>;
  hatIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mouth: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  mouthIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offHand: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  offHandIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  outfit: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  outfitIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  paletteColorPrimary: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  paletteColorSecondary: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  paletteIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  replaceColors: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<FurballTokenReplaceColorsArgs, 'svgStr'>
  >;
  slotAsset: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType,
    RequireFields<FurballTokenSlotAssetArgs, 'slot'>
  >;
  slots: Resolver<
    Array<Maybe<ResolversTypes['EditionAsset']>>,
    ParentType,
    ContextType
  >;
  slotsWithRealmAffiliation: Resolver<
    Array<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType,
    RequireFields<FurballTokenSlotsWithRealmAffiliationArgs, 'realmType'>
  >;
  tail: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  tailIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tattoo: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  tattooIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  totalRarityBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  weapon: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  weaponIdx: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wings: Resolver<
    Maybe<ResolversTypes['EditionAsset']>,
    ParentType,
    ContextType
  >;
  wingsIndex: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballUpgradeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballUpgrade'] = ResolversParentTypes['FurballUpgrade'],
> = {
  canUnSchedule: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deadline: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  endsAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  equipment: Resolver<
    Array<ResolversTypes['FurballUpgradeEquippedItem']>,
    ParentType,
    ContextType
  >;
  equipmentStats: Resolver<
    Array<ResolversTypes['FurballUpgradeBattleStats']>,
    ParentType,
    ContextType
  >;
  fuel: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isEmpty: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPending: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  progress: Resolver<
    Maybe<ResolversTypes['FurballUpgradeProgress']>,
    ParentType,
    ContextType
  >;
  sender: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  senderId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signature: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  skills: Resolver<
    Array<ResolversTypes['FurballUpgradeSkill']>,
    ParentType,
    ContextType
  >;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  writeBackRequired: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballUpgradeBattleStatsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballUpgradeBattleStats'] = ResolversParentTypes['FurballUpgradeBattleStats'],
> = {
  attackPower: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  buff: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  critAttackPower: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  critRate: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  deBuff: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  defencePower: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  heal: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  maxHealth: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  speed: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballUpgradeEquippedItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballUpgradeEquippedItem'] = ResolversParentTypes['FurballUpgradeEquippedItem'],
> = {
  durabilityTime: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  equippedAt: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  itemDefinitionId: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  slotIndex: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballUpgradeProgressResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballUpgradeProgress'] = ResolversParentTypes['FurballUpgradeProgress'],
> = {
  battleStats: Resolver<
    ResolversTypes['FurballUpgradeBattleStats'],
    ParentType,
    ContextType
  >;
  expRate: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  furRate: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballUpgradeSkillResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballUpgradeSkill'] = ResolversParentTypes['FurballUpgradeSkill'],
> = {
  level: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  maxUses: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  skillId: Resolver<ResolversTypes['UnsignedShort'], ParentType, ContextType>;
  slotIndex: Resolver<ResolversTypes['Byte'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballZoneRewardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballZoneReward'] = ResolversParentTypes['FurballZoneReward'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  experienceGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furEarned: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mode: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rarity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  zoneOffset: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FurballsMetaResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FurballsMeta'] = ResolversParentTypes['FurballsMeta'],
> = {
  activeTournaments: Resolver<
    Array<ResolversTypes['Tournament']>,
    ParentType,
    ContextType,
    RequireFields<FurballsMetaActiveTournamentsArgs, 'includeFuture'>
  >;
  buildNumber: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  clientSpec: Resolver<
    ResolversTypes['ClientSpecification'],
    ParentType,
    ContextType
  >;
  environment: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ethPrice: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethPriceUsd: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gameBuild: Resolver<ResolversTypes['GameBuild'], ParentType, ContextType>;
  hostname: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lootBox: Resolver<ResolversTypes['LootBox'], ParentType, ContextType>;
  mintAuction: Resolver<
    Maybe<ResolversTypes['DutchAuction']>,
    ParentType,
    ContextType
  >;
  mintAuctionPurchaseCount: Resolver<
    ResolversTypes['Long'],
    ParentType,
    ContextType
  >;
  subdomain: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timekeeperUserState: Resolver<
    Maybe<ResolversTypes['TimekeeperUserState']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattle'] = ResolversParentTypes['GameBattle'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattleCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattleMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattleBossResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattleBoss'] = ResolversParentTypes['GameBattleBoss'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  aiDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  aiParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  boss: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  bossDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattleBossCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  fur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furEarned: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  gameBoss: Resolver<ResolversTypes['GameBoss'], ParentType, ContextType>;
  gamePlayerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTrialGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemQuantitiesEarned: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  itemsEarned: Resolver<
    Array<ResolversTypes['GameItem']>,
    ParentType,
    ContextType
  >;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattleBossMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  percentileRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  playerMoveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  playerParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  reward: Resolver<
    Maybe<ResolversTypes['ItemReward']>,
    ParentType,
    ContextType
  >;
  rewardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetPercentComplete: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  worldBoss: Resolver<ResolversTypes['GameBoss'], ParentType, ContextType>;
  worldBossId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattleDuelResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattleDuel'] = ResolversParentTypes['GameBattleDuel'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  ante: Resolver<Maybe<ResolversTypes['AntePool']>, ParentType, ContextType>;
  battleRealm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattleDuelCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattleDuelMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  playerOneId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerOneParty: Resolver<
    ResolversTypes['GameParty'],
    ParentType,
    ContextType
  >;
  playerTwo: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  playerTwoId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  rules: Resolver<
    Maybe<ResolversTypes['GameBattleRules']>,
    ParentType,
    ContextType
  >;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattleMonstersResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattleMonsters'] = ResolversParentTypes['GameBattleMonsters'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  aiDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  aiParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  battleRealm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattleMonstersCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  difficultyLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  fur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furEarned: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  gamePlayer: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  gamePlayerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMonsterAttack: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTrialGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isVoyageBattle: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemQuantitiesEarned: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  itemsEarned: Resolver<
    Array<ResolversTypes['GameItem']>,
    ParentType,
    ContextType
  >;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  monstersKilled: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattleMonstersMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  percentileRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  playerMoveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  playerParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  reward: Resolver<
    Maybe<ResolversTypes['ItemReward']>,
    ParentType,
    ContextType
  >;
  rewardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  wave: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattlePvEResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattlePvE'] = ResolversParentTypes['GameBattlePvE'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  aiDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  aiParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattlePvECalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  fur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTrialGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemQuantitiesEarned: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  itemsEarned: Resolver<
    Array<ResolversTypes['GameItem']>,
    ParentType,
    ContextType
  >;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattlePvEMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  percentileRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerDamageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  playerMoveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  playerParty: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  reward: Resolver<
    Maybe<ResolversTypes['ItemReward']>,
    ParentType,
    ContextType
  >;
  rewardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattlePvPResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattlePvP'] = ResolversParentTypes['GameBattlePvP'],
> = {
  activateAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  activeHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  battleRealm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  battleState: Resolver<
    ResolversTypes['GameBattleState'],
    ParentType,
    ContextType
  >;
  battleType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  calculateNtpOffset: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<GameBattlePvPCalculateNtpOffsetArgs, 'ntpClientPacket'>
  >;
  combo: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  consumableItems: Resolver<
    Array<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  creator: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  creatorId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  currentDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  duration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furballIdsList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPracticeGame: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lastMoveEndsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  lockFailures: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moves: Resolver<
    Maybe<ResolversTypes['MovesConnection']>,
    ParentType,
    ContextType,
    RequireFields<GameBattlePvPMovesArgs, never>
  >;
  nextAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ntp: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  outcomeSummaries: Resolver<
    Array<ResolversTypes['GameEntitySummary']>,
    ParentType,
    ContextType
  >;
  parties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType
  >;
  partyIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  percentRank: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  playerIds: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  rentalAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  score: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  scoreHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattleRulesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattleRules'] = ResolversParentTypes['GameBattleRules'],
> = {
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxLevel: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  maxSkillUpgradePercent: Resolver<
    Maybe<ResolversTypes['Float']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<Maybe<ResolversTypes['RealmType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattlesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattlesConnection'] = ResolversParentTypes['GameBattlesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['GameBattlesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattle']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBattlesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBattlesEdge'] = ResolversParentTypes['GameBattlesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBossResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBoss'] = ResolversParentTypes['GameBoss'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  defeatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  difficulty: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  equipment: Resolver<
    Array<ResolversTypes['EquipmentItem']>,
    ParentType,
    ContextType
  >;
  gameStats: Resolver<
    Array<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  health: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hearts: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxHealth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  partyId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameBuildResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameBuild'] = ResolversParentTypes['GameBuild'],
> = {
  buildNumber: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  health: Resolver<ResolversTypes['BuildHealth'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  semver: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tag: Resolver<ResolversTypes['BuildTag'], ParentType, ContextType>;
  versionMajor: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  versionMinor: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  versionPatch: Resolver<
    ResolversTypes['UnsignedShort'],
    ParentType,
    ContextType
  >;
  webCodeFileHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  webDataFileHash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  webFrameworkFileHash: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameEntity'] = ResolversParentTypes['GameEntity'],
> = {
  __resolveType: TypeResolveFn<
    'Furball' | 'GameBoss' | 'GameMonster',
    ParentType,
    ContextType
  >;
};

export type GameEntitySummaryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameEntitySummary'] = ResolversParentTypes['GameEntitySummary'],
> = {
  damageTaken: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  damageTotal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  expGained: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gameBattle: Resolver<ResolversTypes['GameBattle'], ParentType, ContextType>;
  gameBattleId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gameEntity: Resolver<
    Maybe<ResolversTypes['GameEntity']>,
    ParentType,
    ContextType
  >;
  gameEntityId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  levelsGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  statEntity: Resolver<ResolversTypes['StatEntity'], ParentType, ContextType>;
  statEntityId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameItem'] = ResolversParentTypes['GameItem'],
> = {
  __resolveType: TypeResolveFn<
    | 'ConsumableItem'
    | 'ContainerItem'
    | 'EquipmentItem'
    | 'LootItem'
    | 'MaterialItem'
    | 'SpecialItem',
    ParentType,
    ContextType
  >;
};

export type GameMonsterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameMonster'] = ResolversParentTypes['GameMonster'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  defeatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['MonsterDefinition'],
    ParentType,
    ContextType
  >;
  equipment: Resolver<
    Array<ResolversTypes['EquipmentItem']>,
    ParentType,
    ContextType
  >;
  gameStats: Resolver<
    Array<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  hearts: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  level: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  monsterDefinitionId: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  partyId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GameObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameObject'] = ResolversParentTypes['GameObject'],
> = {
  __resolveType: TypeResolveFn<
    'Furball' | 'GameBoss' | 'GameMonster',
    ParentType,
    ContextType
  >;
};

export type GamePartyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GameParty'] = ResolversParentTypes['GameParty'],
> = {
  aiEnabled: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  currentBattle: Resolver<
    Maybe<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  currentBattleId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentBossBattle: Resolver<
    Maybe<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  currentMonsterBattle: Resolver<
    Maybe<ResolversTypes['GameBattleMonsters']>,
    ParentType,
    ContextType
  >;
  filledPositions: Resolver<
    Array<ResolversTypes['FormationPosition']>,
    ParentType,
    ContextType
  >;
  formation: Resolver<
    ResolversTypes['FormationDefinition'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isInBattle: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPlayerParty: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  maxLevels: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  maxSize: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openPositions: Resolver<
    Array<ResolversTypes['FormationPosition']>,
    ParentType,
    ContextType
  >;
  partyMemberIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  partyMembers: Resolver<
    Array<ResolversTypes['StatEntity']>,
    ParentType,
    ContextType
  >;
  player: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  playerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slotNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryConnection'] = ResolversParentTypes['HistoryConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['HistoryEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['PlayerHistoryDay']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HistoryEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HistoryEdge'] = ResolversParentTypes['HistoryEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostNodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HostNode'] = ResolversParentTypes['HostNode'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  hostState: Resolver<ResolversTypes['HostState'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tasks: Resolver<Array<ResolversTypes['HostTask']>, ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HostTaskResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['HostTask'] = ResolversParentTypes['HostTask'],
> = {
  assignedHost: Resolver<ResolversTypes['HostNode'], ParentType, ContextType>;
  assignedHostName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  completedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  taskName: Resolver<ResolversTypes['HostTaskName'], ParentType, ContextType>;
  timeoutAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IBattleEffectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IBattleEffect'] = ResolversParentTypes['IBattleEffect'],
> = {
  __resolveType: TypeResolveFn<
    'BattleEffectPassive' | 'BattleMoveOutcome' | 'EntityEffect',
    ParentType,
    ContextType
  >;
  decay: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
};

export type IFurPlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IFurPlayer'] = ResolversParentTypes['IFurPlayer'],
> = {
  __resolveType: TypeResolveFn<
    | 'FurAccount'
    | 'FurPlayer'
    | 'FurScholar'
    | 'FurScholarDiscord'
    | 'FurScholarEmail',
    ParentType,
    ContextType
  >;
  activeSession: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  avatar: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  bossBattleCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  communityFlags: Resolver<
    Array<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  craftingAttempts: Resolver<
    Array<ResolversTypes['CraftingAttempt']>,
    ParentType,
    ContextType
  >;
  craftingSlots: Resolver<
    Array<ResolversTypes['CraftSlot']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  discordId: Resolver<
    Maybe<ResolversTypes['UnsignedLong']>,
    ParentType,
    ContextType
  >;
  historyDays: Resolver<
    Array<ResolversTypes['PlayerHistoryDay']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  inventory: Resolver<ResolversTypes['ContainerItem'], ParentType, ContextType>;
  itemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  numRentalCancellationsOwner: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  numRentalCancellationsPlayer: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  onboarding: Resolver<
    Array<ResolversTypes['PlayerOnboardingStep']>,
    ParentType,
    ContextType
  >;
  onboardingSequence: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  payouts: Resolver<
    Array<ResolversTypes['PlayerPayout']>,
    ParentType,
    ContextType
  >;
  pendingRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  quests: Resolver<Array<ResolversTypes['Quest']>, ParentType, ContextType>;
  realmsPlayed: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedBoss: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  realmsPlayedMonsters: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  referralCount: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  referrals: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  rentedFurballCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roleFlags: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scholarshipAgreements: Resolver<
    Array<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  sessions: Resolver<
    Array<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  socialName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transactions: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  username: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  wFur: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  waitlistFlags: Resolver<
    Array<ResolversTypes['WaitlistFlag']>,
    ParentType,
    ContextType
  >;
  writtenRoles: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
};

export type InventoryItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['InventoryItem'] = ResolversParentTypes['InventoryItem'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['FurballDefinition'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  migratedToItemId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  offChain: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  rarity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemDefinition'] = ResolversParentTypes['ItemDefinition'],
> = {
  allowInFurballInventory: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  durabilityTime: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  effects: Resolver<
    Array<ResolversTypes['SkillStatEffect']>,
    ParentType,
    ContextType
  >;
  icon: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passives: Resolver<
    Array<ResolversTypes['PassiveEffectRange']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  recipe: Resolver<
    Maybe<ResolversTypes['CraftingRecipe']>,
    ParentType,
    ContextType
  >;
  untradeable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  upgradeToItemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  valueMultiplier: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemListingsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemListingsConnection'] = ResolversParentTypes['ItemListingsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['ItemListingsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['PlayerItemListing']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemListingsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemListingsEdge'] = ResolversParentTypes['ItemListingsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['PlayerItemListing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemQuantityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemQuantity'] = ResolversParentTypes['ItemQuantity'],
> = {
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ItemRewardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ItemReward'] = ResolversParentTypes['ItemReward'],
> = {
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemDefinition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JoinBattleResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['JoinBattleResponse'] = ResolversParentTypes['JoinBattleResponse'],
> = {
  battle: Resolver<ResolversTypes['GameBattle'], ParentType, ContextType>;
  bossBattle: Resolver<
    Maybe<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  createDuel: Resolver<
    ResolversTypes['GameBattleDuel'],
    ParentType,
    ContextType,
    RequireFields<JoinBattleResponseCreateDuelArgs, never>
  >;
  duelBattle: Resolver<
    Maybe<ResolversTypes['GameBattleDuel']>,
    ParentType,
    ContextType
  >;
  joinBossBattle: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<JoinBattleResponseJoinBossBattleArgs, 'practiceGame'>
  >;
  joinDuel: Resolver<
    ResolversTypes['GameBattleDuel'],
    ParentType,
    ContextType,
    RequireFields<JoinBattleResponseJoinDuelArgs, 'duelId'>
  >;
  joinMonsterBattle: Resolver<
    ResolversTypes['GameBattleMonsters'],
    ParentType,
    ContextType,
    RequireFields<JoinBattleResponseJoinMonsterBattleArgs, never>
  >;
  monsterBattle: Resolver<
    Maybe<ResolversTypes['GameBattleMonsters']>,
    ParentType,
    ContextType
  >;
  partyId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  pvEBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvE']>,
    ParentType,
    ContextType
  >;
  pvPBattle: Resolver<
    Maybe<ResolversTypes['GameBattlePvP']>,
    ParentType,
    ContextType
  >;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValuePairOfInt64AndInt32Resolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeyValuePairOfInt64AndInt32'] = ResolversParentTypes['KeyValuePairOfInt64AndInt32'],
> = {
  key: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type KeyValuePairOfStringAndAnimationAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['KeyValuePairOfStringAndAnimationAsset'] = ResolversParentTypes['KeyValuePairOfStringAndAnimationAsset'],
> = {
  key: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['AnimationAsset'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Leaderboard'] = ResolversParentTypes['Leaderboard'],
> = {
  activeAfter: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  activeBefore: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entityType: Resolver<
    ResolversTypes['LeaderboardEntityType'],
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  reward: Resolver<
    Array<ResolversTypes['LeaderboardTierRewards']>,
    ParentType,
    ContextType
  >;
  rewards: Resolver<
    Array<ResolversTypes['LeaderboardTierRewards']>,
    ParentType,
    ContextType
  >;
  sequenceNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slots: Resolver<
    Maybe<ResolversTypes['SlotsConnection']>,
    ParentType,
    ContextType,
    RequireFields<LeaderboardSlotsArgs, never>
  >;
  stat: Resolver<ResolversTypes['LeaderboardStat'], ParentType, ContextType>;
  tiers: Resolver<
    Maybe<ResolversTypes['TiersConnection']>,
    ParentType,
    ContextType,
    RequireFields<LeaderboardTiersArgs, never>
  >;
  timeframe: Resolver<
    ResolversTypes['LeaderboardTimeframe'],
    ParentType,
    ContextType
  >;
  tournament: Resolver<
    Maybe<ResolversTypes['Tournament']>,
    ParentType,
    ContextType
  >;
  tournamentId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardPositionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardPosition'] = ResolversParentTypes['LeaderboardPosition'],
> = {
  __resolveType: TypeResolveFn<
    'LeaderboardSlotFurball' | 'LeaderboardSlotPlayer',
    ParentType,
    ContextType
  >;
};

export type LeaderboardRankFurballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardRankFurball'] = ResolversParentTypes['LeaderboardRankFurball'],
> = {
  extraData: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slot: Resolver<
    ResolversTypes['LeaderboardSlotFurball'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardRankPlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardRankPlayer'] = ResolversParentTypes['LeaderboardRankPlayer'],
> = {
  extraData: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slot: Resolver<
    ResolversTypes['LeaderboardSlotPlayer'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardSlotResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardSlot'] = ResolversParentTypes['LeaderboardSlot'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entityType: Resolver<
    ResolversTypes['LeaderboardEntityType'],
    ParentType,
    ContextType
  >;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  leaderGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  leaderboardTier: Resolver<
    Maybe<ResolversTypes['LeaderboardTier']>,
    ParentType,
    ContextType
  >;
  leaderboardTierId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overall: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sequenceNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['LeaderboardStat'], ParentType, ContextType>;
  ties: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeframe: Resolver<
    ResolversTypes['LeaderboardTimeframe'],
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardSlotFurballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardSlotFurball'] = ResolversParentTypes['LeaderboardSlotFurball'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entityType: Resolver<
    ResolversTypes['LeaderboardEntityType'],
    ParentType,
    ContextType
  >;
  furballRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankFurball']>,
    ParentType,
    ContextType
  >;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  leaderGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  leaderboardTier: Resolver<
    Maybe<ResolversTypes['LeaderboardTier']>,
    ParentType,
    ContextType
  >;
  leaderboardTierId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overall: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sequenceNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['LeaderboardStat'], ParentType, ContextType>;
  ties: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeframe: Resolver<
    ResolversTypes['LeaderboardTimeframe'],
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardSlotPlayerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardSlotPlayer'] = ResolversParentTypes['LeaderboardSlotPlayer'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entityType: Resolver<
    ResolversTypes['LeaderboardEntityType'],
    ParentType,
    ContextType
  >;
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  leaderGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  leaderboardTier: Resolver<
    Maybe<ResolversTypes['LeaderboardTier']>,
    ParentType,
    ContextType
  >;
  leaderboardTierId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  overall: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  playerRanks: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType
  >;
  players: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  score: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sequenceNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['LeaderboardStat'], ParentType, ContextType>;
  ties: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timeframe: Resolver<
    ResolversTypes['LeaderboardTimeframe'],
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardTierResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardTier'] = ResolversParentTypes['LeaderboardTier'],
> = {
  detachedLeaderboardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  entityCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  entityMax: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderboard: Resolver<
    Maybe<ResolversTypes['Leaderboard']>,
    ParentType,
    ContextType
  >;
  leaderboardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  position: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  rewards: Resolver<
    ResolversTypes['LeaderboardTierRewards'],
    ParentType,
    ContextType
  >;
  scoreMax: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  scoreMin: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  sequenceNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  slots: Resolver<
    Array<ResolversTypes['LeaderboardSlot']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardTierRewardChanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardTierRewardChance'] = ResolversParentTypes['LeaderboardTierRewardChance'],
> = {
  compact: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  percentChance: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  rewardQuantity: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardType: Resolver<
    ResolversTypes['TierRewardType'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LeaderboardTierRewardsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LeaderboardTierRewards'] = ResolversParentTypes['LeaderboardTierRewards'],
> = {
  bossDustAmount: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  entityMax: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furAmount: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderboardGroup: Resolver<
    ResolversTypes['LeaderboardGroup'],
    ParentType,
    ContextType
  >;
  leaderboardTimeframe: Resolver<
    ResolversTypes['LeaderboardTimeframe'],
    ParentType,
    ContextType
  >;
  poolSplit: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  possibleRewardList: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  possibleRewards: Resolver<
    Array<ResolversTypes['LeaderboardTierRewardChance']>,
    ParentType,
    ContextType
  >;
  tierPosition: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginEmailResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoginEmailResponse'] = ResolversParentTypes['LoginEmailResponse'],
> = {
  currentPlayer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  outcome: Resolver<ResolversTypes['LoginOutcome'], ParentType, ContextType>;
  secret: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  session: Resolver<
    Maybe<ResolversTypes['FurSession']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LoginResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LoginResponse'] = ResolversParentTypes['LoginResponse'],
> = {
  secret: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  session: Resolver<ResolversTypes['FurSession'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type LootBoxResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LootBox'] = ResolversParentTypes['LootBox'],
> = {
  myLastPurchaseAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  possibleRewards: Resolver<
    Array<ResolversTypes['LeaderboardTierRewardChance']>,
    ParentType,
    ContextType
  >;
  totalChance: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  with: Resolver<
    ResolversTypes['LootBox'],
    ParentType,
    ContextType,
    RequireFields<LootBoxWithArgs, 'chance'>
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LootItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['LootItem'] = ResolversParentTypes['LootItem'],
> = {
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MarketListingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MarketListing'] = ResolversParentTypes['MarketListing'],
> = {
  amount: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  contractAddress: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deListed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  deListedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  expiresAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listedAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  marketplace: Resolver<
    ResolversTypes['MarketplaceName'],
    ParentType,
    ContextType
  >;
  price: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  schema: Resolver<ResolversTypes['TokenSchema'], ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MaterialItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MaterialItem'] = ResolversParentTypes['MaterialItem'],
> = {
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonsterBattlesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonsterBattlesConnection'] = ResolversParentTypes['MonsterBattlesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['MonsterBattlesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['GameBattleMonsters']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonsterBattlesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonsterBattlesEdge'] = ResolversParentTypes['MonsterBattlesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['GameBattleMonsters'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonsterDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonsterDefinition'] = ResolversParentTypes['MonsterDefinition'],
> = {
  aggressivenessModifier: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  animationCollection: Resolver<
    ResolversTypes['EntityAnimationCollection'],
    ParentType,
    ContextType
  >;
  baseStats: Resolver<ResolversTypes['BattleStats'], ParentType, ContextType>;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  minLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  skills: Resolver<
    Array<ResolversTypes['MonsterSkillDefinition']>,
    ParentType,
    ContextType
  >;
  slug: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonsterSkillDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MonsterSkillDefinition'] = ResolversParentTypes['MonsterSkillDefinition'],
> = {
  frequency: Resolver<
    ResolversTypes['UsageFrequency'],
    ParentType,
    ContextType
  >;
  rules: Resolver<
    Maybe<ResolversTypes['TargetingRule']>,
    ParentType,
    ContextType
  >;
  skillId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  targeting: Resolver<
    Maybe<ResolversTypes['TargetingStrategy']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveItemResultResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MoveItemResult'] = ResolversParentTypes['MoveItemResult'],
> = {
  fromFurball: Resolver<
    Maybe<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  fromInventory: Resolver<
    ResolversTypes['ContainerItem'],
    ParentType,
    ContextType
  >;
  item: Resolver<ResolversTypes['GameItem'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  toFurball: Resolver<
    Maybe<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoveZoneResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MoveZoneResponse'] = ResolversParentTypes['MoveZoneResponse'],
> = {
  expGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furGained: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furSpent: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  happiness: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  intervals: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  items: Resolver<Array<ResolversTypes['GameItem']>, ParentType, ContextType>;
  levelsGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MovesConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MovesConnection'] = ResolversParentTypes['MovesConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['MovesEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['BattleMove']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MovesEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MovesEdge'] = ResolversParentTypes['MovesEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['BattleMove'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  advanceBattle: Resolver<
    ResolversTypes['BattleMoveResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAdvanceBattleArgs, 'id'>
  >;
  approveAirdrops: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationApproveAirdropsArgs, 'args' | 'signatureToken'>
  >;
  bidAuction: Resolver<
    ResolversTypes['DutchAuctionResult'],
    ParentType,
    ContextType
  >;
  bossAdvanceBattle: Resolver<
    ResolversTypes['BattleMoveResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationBossAdvanceBattleArgs, 'id'>
  >;
  bossClaimReward: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<MutationBossClaimRewardArgs, 'choice' | 'id'>
  >;
  bossForfeitBattle: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<MutationBossForfeitBattleArgs, 'id'>
  >;
  bossJoinBattle: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<
      MutationBossJoinBattleArgs,
      'partyId' | 'playerId' | 'realmType' | 'trialGame'
    >
  >;
  buyBossKeys: Resolver<
    ResolversTypes['PlayerPurchase'],
    ParentType,
    ContextType,
    RequireFields<MutationBuyBossKeysArgs, 'furballIds' | 'realms'>
  >;
  buyItemListing: Resolver<
    ResolversTypes['PlayerItemListing'],
    ParentType,
    ContextType,
    RequireFields<MutationBuyItemListingArgs, 'listingId'>
  >;
  buyLootBox: Resolver<
    ResolversTypes['PlayerPendingReward'],
    ParentType,
    ContextType,
    RequireFields<MutationBuyLootBoxArgs, 'useTix'>
  >;
  cancelItemListing: Resolver<
    ResolversTypes['PlayerItemListing'],
    ParentType,
    ContextType,
    RequireFields<MutationCancelItemListingArgs, 'listingId'>
  >;
  changeUsername: Resolver<
    ResolversTypes['IFurPlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeUsernameArgs, 'username'>
  >;
  claimPendingReward: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationClaimPendingRewardArgs, 'pendingRewardId'>
  >;
  claimRentalAgreement: Resolver<
    ResolversTypes['RentalAgreement'],
    ParentType,
    ContextType,
    RequireFields<MutationClaimRentalAgreementArgs, 'rentalAgreementId'>
  >;
  cloneTournament: Resolver<
    ResolversTypes['Tournament'],
    ParentType,
    ContextType,
    RequireFields<MutationCloneTournamentArgs, 'id'>
  >;
  completeOnboardingStep: Resolver<
    ResolversTypes['IFurPlayer'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCompleteOnboardingStepArgs,
      'isComplete' | 'sequence' | 'stepProgress'
    >
  >;
  craftElixir: Resolver<
    ResolversTypes['ConsumableItem'],
    ParentType,
    ContextType,
    RequireFields<MutationCraftElixirArgs, 'itemIds'>
  >;
  craftFaster: Resolver<
    ResolversTypes['CraftSlot'],
    ParentType,
    ContextType,
    RequireFields<MutationCraftFasterArgs, 'craftingAttemptId' | 'numHours'>
  >;
  craftRecipe: Resolver<
    ResolversTypes['CraftSlot'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCraftRecipeArgs,
      'slotIndex' | 'unlockSlot' | 'useTix'
    >
  >;
  createDuelBattle: Resolver<
    ResolversTypes['GameBattleDuel'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateDuelBattleArgs, 'partyId'>
  >;
  createItemListing: Resolver<
    ResolversTypes['PlayerItemListing'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateItemListingArgs,
      'itemId' | 'price' | 'quantity'
    >
  >;
  createRandomParty: Resolver<
    ResolversTypes['RandomParty'],
    ParentType,
    ContextType
  >;
  createRentalAgreement: Resolver<
    ResolversTypes['RentalAgreement'],
    ParentType,
    ContextType,
    RequireFields<MutationCreateRentalAgreementArgs, 'terms'>
  >;
  createTournament: Resolver<
    ResolversTypes['Tournament'],
    ParentType,
    ContextType,
    RequireFields<
      MutationCreateTournamentArgs,
      'endsAt' | 'fuelFee' | 'heroUrl' | 'name' | 'positions' | 'startsAt'
    >
  >;
  disconnectSocialProfile: Resolver<
    ResolversTypes['FurAccount'],
    ParentType,
    ContextType,
    RequireFields<MutationDisconnectSocialProfileArgs, 'token'>
  >;
  editItemListing: Resolver<
    ResolversTypes['PlayerItemListing'],
    ParentType,
    ContextType,
    RequireFields<MutationEditItemListingArgs, 'listingId' | 'price'>
  >;
  editRentalAgreement: Resolver<
    ResolversTypes['RentalAgreement'],
    ParentType,
    ContextType,
    RequireFields<
      MutationEditRentalAgreementArgs,
      'rentalAgreementId' | 'terms'
    >
  >;
  endRentalAgreement: Resolver<
    ResolversTypes['RentalAgreement'],
    ParentType,
    ContextType,
    RequireFields<MutationEndRentalAgreementArgs, 'rentalAgreementId'>
  >;
  equipFurball: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<MutationEquipFurballArgs, 'furballId' | 'itemIds'>
  >;
  fixListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  forfeitBattle: Resolver<
    ResolversTypes['GameBattle'],
    ParentType,
    ContextType,
    RequireFields<MutationForfeitBattleArgs, 'id'>
  >;
  fundFur: Resolver<
    ResolversTypes['FurFundingLot'],
    ParentType,
    ContextType,
    RequireFields<MutationFundFurArgs, 'pricePer' | 'quantity'>
  >;
  gameLoopRun: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType,
    RequireFields<MutationGameLoopRunArgs, 'deltaTime'>
  >;
  giftAccountsTix: Resolver<
    Array<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationGiftAccountsTixArgs,
      'reason' | 'tixCount' | 'walletIds'
    >
  >;
  giveLeaderboardReward: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationGiveLeaderboardRewardArgs,
      'leaderboardId' | 'tierIndex'
    >
  >;
  giveLeaderboardRewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType,
    RequireFields<
      MutationGiveLeaderboardRewardsArgs,
      | 'bypassRewarded'
      | 'leaderboardId'
      | 'seqNum'
      | 'skipDust'
      | 'tierPositions'
    >
  >;
  givePendingReward: Resolver<
    ResolversTypes['PlayerPendingReward'],
    ParentType,
    ContextType,
    RequireFields<MutationGivePendingRewardArgs, 'rewards' | 'source'>
  >;
  heartbeat: Resolver<
    ResolversTypes['HostNode'],
    ParentType,
    ContextType,
    RequireFields<MutationHeartbeatArgs, 'deltaTime'>
  >;
  joinBattle: Resolver<
    ResolversTypes['JoinBattleResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinBattleArgs, 'partyId' | 'realmType'>
  >;
  joinBossBattle: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<
      MutationJoinBossBattleArgs,
      'partyId' | 'playerId' | 'realmType' | 'trialGame'
    >
  >;
  joinDuelBattle: Resolver<
    ResolversTypes['GameBattleDuel'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinDuelBattleArgs, 'gameBattleId' | 'partyId'>
  >;
  joinMonstersBattle: Resolver<
    ResolversTypes['GameBattleMonsters'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinMonstersBattleArgs, 'partyId' | 'realmType'>
  >;
  joinTournament: Resolver<
    ResolversTypes['Tournament'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinTournamentArgs, 'tournamentId'>
  >;
  joinWaitlist: Resolver<
    ResolversTypes['IFurPlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationJoinWaitlistArgs, 'playerId' | 'waitlist'>
  >;
  lockedRun: Resolver<
    Maybe<ResolversTypes['BlockWork']>,
    ParentType,
    ContextType,
    RequireFields<MutationLockedRunArgs, 'deltaTime'>
  >;
  login: Resolver<
    ResolversTypes['LoginResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginArgs, 'token'>
  >;
  loginAppleCallback: Resolver<
    ResolversTypes['LoginResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginAppleCallbackArgs, 'dto'>
  >;
  loginDiscordCallback: Resolver<
    ResolversTypes['LoginResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginDiscordCallbackArgs, 'code'>
  >;
  loginEmail: Resolver<
    ResolversTypes['LoginEmailResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginEmailArgs, 'email'>
  >;
  loginEmailCallback: Resolver<
    ResolversTypes['LoginResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationLoginEmailCallbackArgs, 'code' | 'playerId'>
  >;
  modifyFurball: Resolver<
    ResolversTypes['FurballModification'],
    ParentType,
    ContextType,
    RequireFields<MutationModifyFurballArgs, 'args' | 'signatureToken'>
  >;
  moveItem: Resolver<
    ResolversTypes['MoveItemResult'],
    ParentType,
    ContextType,
    RequireFields<MutationMoveItemArgs, 'itemId'>
  >;
  moveZones: Resolver<
    Array<ResolversTypes['MoveZoneResponse']>,
    ParentType,
    ContextType,
    RequireFields<MutationMoveZonesArgs, 'requests'>
  >;
  pipeLogs: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType,
    RequireFields<MutationPipeLogsArgs, 'logBundle'>
  >;
  processGameBattle: Resolver<
    ResolversTypes['GameBattle'],
    ParentType,
    ContextType,
    RequireFields<MutationProcessGameBattleArgs, 'gameBattleId'>
  >;
  purchaseFur: Resolver<
    ResolversTypes['FurPurchasePlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationPurchaseFurArgs, 'fuel'>
  >;
  readNotifications: Resolver<
    Array<ResolversTypes['Notification']>,
    ParentType,
    ContextType,
    RequireFields<MutationReadNotificationsArgs, 'notificationIds'>
  >;
  refillHearts: Resolver<
    ResolversTypes['GameParty'],
    ParentType,
    ContextType,
    RequireFields<MutationRefillHeartsArgs, 'partyId'>
  >;
  renameFurballAccept: Resolver<
    ResolversTypes['FurballNameChange'],
    ParentType,
    ContextType,
    RequireFields<MutationRenameFurballAcceptArgs, 'approve' | 'renameId'>
  >;
  requestAirdrop: Resolver<
    ResolversTypes['AirdropRequest'],
    ParentType,
    ContextType,
    RequireFields<MutationRequestAirdropArgs, 'args' | 'signatureToken'>
  >;
  restart: Resolver<Array<ResolversTypes['HostNode']>, ParentType, ContextType>;
  rollSkill: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<MutationRollSkillArgs, 'furballId' | 'slotIndex'>
  >;
  runHostTask: Resolver<
    ResolversTypes['HostTask'],
    ParentType,
    ContextType,
    RequireFields<MutationRunHostTaskArgs, 'taskId'>
  >;
  sanityCheckFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType,
    RequireFields<MutationSanityCheckFurballsArgs, 'limit'>
  >;
  sendNotification: Resolver<
    ResolversTypes['Notification'],
    ParentType,
    ContextType,
    RequireFields<MutationSendNotificationArgs, 'title'>
  >;
  setParty: Resolver<
    ResolversTypes['GameParty'],
    ParentType,
    ContextType,
    RequireFields<MutationSetPartyArgs, 'args'>
  >;
  setTournamentRewards: Resolver<
    ResolversTypes['Tournament'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSetTournamentRewardsArgs,
      'positions' | 'tournamentId'
    >
  >;
  specializeFurball: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<MutationSpecializeFurballArgs, 'furballId' | 'specialization'>
  >;
  submitVoyageEncounter: Resolver<
    ResolversTypes['VoyageEncounter'],
    ParentType,
    ContextType,
    RequireFields<
      MutationSubmitVoyageEncounterArgs,
      'complete' | 'decision' | 'tokenId'
    >
  >;
  takePayout: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationTakePayoutArgs, 'id' | 'method'>
  >;
  tasksRun: Resolver<
    Array<ResolversTypes['HostTask']>,
    ParentType,
    ContextType,
    RequireFields<MutationTasksRunArgs, 'deltaTime'>
  >;
  timekeeperExecuteAdHoc: Resolver<
    ResolversTypes['TimekeeperAdHoc'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperExecuteAdHocArgs, 'id'>
  >;
  timekeeperReComputeExp: Resolver<
    Maybe<ResolversTypes['TimekeeperRound']>,
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperReComputeExpArgs, 'date'>
  >;
  timekeeperSaveAction: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperSaveActionArgs, 'action'>
  >;
  timekeeperSaveAdHoc: Resolver<
    ResolversTypes['TimekeeperAdHoc'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperSaveAdHocArgs, 'action' | 'signature'>
  >;
  timekeeperSignRequest: Resolver<
    ResolversTypes['TimekeeperRequest'],
    ParentType,
    ContextType,
    RequireFields<
      MutationTimekeeperSignRequestArgs,
      'changes' | 'partialIndex' | 'signature'
    >
  >;
  timekeeperToggleAccept: Resolver<
    ResolversTypes['TimekeeperToggle'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperToggleAcceptArgs, 'id' | 'signature'>
  >;
  timekeeperToggleCompute: Resolver<
    ResolversTypes['TimekeeperToggle'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperToggleComputeArgs, 'args'>
  >;
  timekeeperTransferTixAccept: Resolver<
    ResolversTypes['TimekeeperTransferTix'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperTransferTixAcceptArgs, 'id' | 'signature'>
  >;
  timekeeperTransferTixCompute: Resolver<
    ResolversTypes['TimekeeperTransferTix'],
    ParentType,
    ContextType,
    RequireFields<MutationTimekeeperTransferTixComputeArgs, 'args'>
  >;
  upgradeFurball: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<MutationUpgradeFurballArgs, 'furballId'>
  >;
  upgradeLoot: Resolver<
    ResolversTypes['FurballLootUpgradeResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpgradeLootArgs, 'chances' | 'itemId'>
  >;
  upgradeSkill: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<
      MutationUpgradeSkillArgs,
      'furballId' | 'levelPartials' | 'maxUsesPartials' | 'skillId'
    >
  >;
  verifyReceipt: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<MutationVerifyReceiptArgs, 'platform' | 'receiptData'>
  >;
  wrapFur: Resolver<
    ResolversTypes['TimekeeperWrapFur'],
    ParentType,
    ContextType,
    RequireFields<MutationWrapFurArgs, 'args' | 'signature'>
  >;
  wrapFurAccept: Resolver<
    ResolversTypes['TimekeeperWrapFur'],
    ParentType,
    ContextType,
    RequireFields<MutationWrapFurAcceptArgs, 'id' | 'signature'>
  >;
  wrapFurCompute: Resolver<
    ResolversTypes['TimekeeperWrapFur'],
    ParentType,
    ContextType,
    RequireFields<MutationWrapFurComputeArgs, 'args'>
  >;
};

export type NotificationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification'],
> = {
  canBeDismissed: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  data: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  furball: Resolver<Maybe<ResolversTypes['Furball']>, ParentType, ContextType>;
  furballId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  imageUrl: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isGlobal: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  message: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationInstances: Resolver<
    Array<ResolversTypes['NotificationInstance']>,
    ParentType,
    ContextType
  >;
  pendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  player: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  playerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  purpose: Resolver<
    ResolversTypes['NotificationPurpose'],
    ParentType,
    ContextType
  >;
  readAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  recipient: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendCompleteAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  sender: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  thumbnailUrl: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  title: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationInstanceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationInstance'] = ResolversParentTypes['NotificationInstance'],
> = {
  channelId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  destinationId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notification: Resolver<
    ResolversTypes['Notification'],
    ParentType,
    ContextType
  >;
  notificationId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  platform: Resolver<
    ResolversTypes['NotificationPlatform'],
    ParentType,
    ContextType
  >;
  receipt: Resolver<ResolversTypes['ReceiptState'], ParentType, ContextType>;
  recipientId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationsConnection'] = ResolversParentTypes['NotificationsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['NotificationsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['Notification']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['NotificationsEdge'] = ResolversParentTypes['NotificationsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo'],
> = {
  endCursor: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PassiveEffectRangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PassiveEffectRange'] = ResolversParentTypes['PassiveEffectRange'],
> = {
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  toPassive: Resolver<
    ResolversTypes['BattleEffectPassive'],
    ParentType,
    ContextType,
    RequireFields<PassiveEffectRangeToPassiveArgs, 'id'>
  >;
  valueBase: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  valueRange: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingFurWrapsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingFurWrapsConnection'] = ResolversParentTypes['PendingFurWrapsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['PendingFurWrapsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['TimekeeperWrapFur']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PendingFurWrapsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PendingFurWrapsEdge'] = ResolversParentTypes['PendingFurWrapsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['TimekeeperWrapFur'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerAnteResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerAnte'] = ResolversParentTypes['PlayerAnte'],
> = {
  ante: Resolver<ResolversTypes['AntePool'], ParentType, ContextType>;
  anteId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  furDeposit: Resolver<
    Maybe<ResolversTypes['BlockchainTokenTransaction']>,
    ParentType,
    ContextType
  >;
  furDepositId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerHistoryConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerHistoryConnection'] = ResolversParentTypes['PlayerHistoryConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['PlayerHistoryEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['PlayerHistoryDay']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerHistoryDayResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerHistoryDay'] = ResolversParentTypes['PlayerHistoryDay'],
> = {
  bossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  leaderboardTotalScore: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  nextDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  percentileRankOverall: Resolver<
    ResolversTypes['Float'],
    ParentType,
    ContextType
  >;
  percentileRankTop: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startDate: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerHistoryEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerHistoryEdge'] = ResolversParentTypes['PlayerHistoryEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['PlayerHistoryDay'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerItemListingResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerItemListing'] = ResolversParentTypes['PlayerItemListing'],
> = {
  buyer: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  buyerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  currency: Resolver<ResolversTypes['CurrencyType'], ParentType, ContextType>;
  currentPrice: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  currentPriceEach: Resolver<
    ResolversTypes['UnsignedLong'],
    ParentType,
    ContextType
  >;
  expiresAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  item: Resolver<ResolversTypes['GameItem'], ParentType, ContextType>;
  itemDefinitionId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  reservedPlayer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  reservedPlayerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  seller: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  sellerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  soldAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  style: Resolver<ResolversTypes['ListingStyle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerOnboardingStepResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerOnboardingStep'] = ResolversParentTypes['PlayerOnboardingStep'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sequence: Resolver<
    ResolversTypes['OnboardingSequenceType'],
    ParentType,
    ContextType
  >;
  stepProgress: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerPayoutResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerPayout'] = ResolversParentTypes['PlayerPayout'],
> = {
  canPayEth: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  ethAmount: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  payoutMethod: Resolver<
    ResolversTypes['PayoutMethod'],
    ParentType,
    ContextType
  >;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tixRatio: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerPendingRewardResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerPendingReward'] = ResolversParentTypes['PlayerPendingReward'],
> = {
  claimedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  expiresAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  furPlayer: Resolver<ResolversTypes['IFurPlayer'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  items: Resolver<Array<ResolversTypes['GameItem']>, ParentType, ContextType>;
  notification: Resolver<
    Maybe<ResolversTypes['Notification']>,
    ParentType,
    ContextType
  >;
  notificationId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rentalAgreement: Resolver<
    Maybe<ResolversTypes['RentalAgreement']>,
    ParentType,
    ContextType
  >;
  rentalAgreementId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  source: Resolver<ResolversTypes['RewardSource'], ParentType, ContextType>;
  sourceId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sourcePosition: Resolver<
    Maybe<ResolversTypes['Int']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerPurchaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerPurchase'] = ResolversParentTypes['PlayerPurchase'],
> = {
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  remainingFuel: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  spentFuel: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerTransactionsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerTransactionsConnection'] = ResolversParentTypes['PlayerTransactionsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['PlayerTransactionsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['BlockchainTokenTransaction']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlayerTransactionsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PlayerTransactionsEdge'] = ResolversParentTypes['PlayerTransactionsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<
    ResolversTypes['BlockchainTokenTransaction'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriorRewardsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriorRewardsConnection'] = ResolversParentTypes['PriorRewardsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['PriorRewardsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['PlayerPendingReward']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PriorRewardsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['PriorRewardsEdge'] = ResolversParentTypes['PriorRewardsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<
    ResolversTypes['PlayerPendingReward'],
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  account: Resolver<
    ResolversTypes['FurAccount'],
    ParentType,
    ContextType,
    RequireFields<QueryAccountArgs, 'id'>
  >;
  bossBattle: Resolver<
    ResolversTypes['GameBattleBoss'],
    ParentType,
    ContextType,
    RequireFields<QueryBossBattleArgs, 'id'>
  >;
  bossBattles: Resolver<
    Maybe<ResolversTypes['BossBattlesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryBossBattlesArgs, never>
  >;
  container: Resolver<
    ResolversTypes['ContainerItem'],
    ParentType,
    ContextType,
    RequireFields<QueryContainerArgs, 'containerId'>
  >;
  currentBossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType,
    RequireFields<QueryCurrentBossBattlesArgs, never>
  >;
  currentPlayer: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<QueryCurrentPlayerArgs, never>
  >;
  duelBattles: Resolver<
    Maybe<ResolversTypes['DuelBattlesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryDuelBattlesArgs, never>
  >;
  dutchAuction: Resolver<
    ResolversTypes['DutchAuction'],
    ParentType,
    ContextType
  >;
  editions: Resolver<
    Array<ResolversTypes['EditionBase']>,
    ParentType,
    ContextType
  >;
  exchangeRates: Resolver<
    ResolversTypes['CurrencyExchangeRates'],
    ParentType,
    ContextType
  >;
  findFurball: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<QueryFindFurballArgs, 'query'>
  >;
  furball: Resolver<
    ResolversTypes['Furball'],
    ParentType,
    ContextType,
    RequireFields<QueryFurballArgs, 'tokenId'>
  >;
  furballNameChanges: Resolver<
    Maybe<ResolversTypes['FurballNameChangesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryFurballNameChangesArgs, never>
  >;
  furballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType,
    RequireFields<QueryFurballsArgs, 'tokenIds'>
  >;
  itemDefinitions: Resolver<
    Array<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemListings: Resolver<
    Maybe<ResolversTypes['ItemListingsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryItemListingsArgs, never>
  >;
  leaderboard: Resolver<
    ResolversTypes['Leaderboard'],
    ParentType,
    ContextType,
    RequireFields<QueryLeaderboardArgs, 'id'>
  >;
  leaderboardRanksPlayer: Resolver<
    Array<ResolversTypes['LeaderboardRankPlayer']>,
    ParentType,
    ContextType,
    RequireFields<QueryLeaderboardRanksPlayerArgs, never>
  >;
  leaderboardTierRewards: Resolver<
    Array<ResolversTypes['LeaderboardTierRewards']>,
    ParentType,
    ContextType,
    RequireFields<QueryLeaderboardTierRewardsArgs, never>
  >;
  leaderboards: Resolver<
    Array<ResolversTypes['Leaderboard']>,
    ParentType,
    ContextType,
    RequireFields<QueryLeaderboardsArgs, never>
  >;
  listAirdropRequests: Resolver<
    Array<ResolversTypes['AirdropRequest']>,
    ParentType,
    ContextType
  >;
  lootBox: Resolver<ResolversTypes['LootBox'], ParentType, ContextType>;
  me: Resolver<
    ResolversTypes['IFurPlayer'],
    ParentType,
    ContextType,
    RequireFields<QueryMeArgs, never>
  >;
  meta: Resolver<ResolversTypes['FurballsMeta'], ParentType, ContextType>;
  monsterBattles: Resolver<
    Maybe<ResolversTypes['MonsterBattlesConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryMonsterBattlesArgs, never>
  >;
  monsterDefinitions: Resolver<
    Array<ResolversTypes['MonsterDefinition']>,
    ParentType,
    ContextType
  >;
  myItemListings: Resolver<
    Array<ResolversTypes['PlayerItemListing']>,
    ParentType,
    ContextType
  >;
  notifications: Resolver<
    Maybe<ResolversTypes['NotificationsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryNotificationsArgs, never>
  >;
  party: Resolver<
    ResolversTypes['GameParty'],
    ParentType,
    ContextType,
    RequireFields<QueryPartyArgs, 'partyId'>
  >;
  player: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<QueryPlayerArgs, 'query'>
  >;
  playerHistory: Resolver<
    Maybe<ResolversTypes['PlayerHistoryConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlayerHistoryArgs, never>
  >;
  playerIds: Resolver<
    Array<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlayerIdsArgs, 'playerIds'>
  >;
  playerParties: Resolver<
    Array<ResolversTypes['GameParty']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlayerPartiesArgs, never>
  >;
  playerTransactions: Resolver<
    Maybe<ResolversTypes['PlayerTransactionsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryPlayerTransactionsArgs, never>
  >;
  priorRewards: Resolver<
    Maybe<ResolversTypes['PriorRewardsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryPriorRewardsArgs, never>
  >;
  questDefinitions: Resolver<
    Array<ResolversTypes['QuestDefinition']>,
    ParentType,
    ContextType
  >;
  ranks: Resolver<
    Array<ResolversTypes['FurballRank']>,
    ParentType,
    ContextType,
    RequireFields<QueryRanksArgs, 'tokenIds'>
  >;
  recentBossBattles: Resolver<
    Array<ResolversTypes['GameBattleBoss']>,
    ParentType,
    ContextType,
    RequireFields<QueryRecentBossBattlesArgs, never>
  >;
  rentalAgreement: Resolver<
    ResolversTypes['RentalAgreement'],
    ParentType,
    ContextType,
    RequireFields<QueryRentalAgreementArgs, 'id'>
  >;
  scholar: Resolver<
    ResolversTypes['FurPlayer'],
    ParentType,
    ContextType,
    RequireFields<QueryScholarArgs, 'query'>
  >;
  searchFurballs: Resolver<
    Maybe<ResolversTypes['SearchFurballsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchFurballsArgs, never>
  >;
  searchTournaments: Resolver<
    Array<ResolversTypes['Tournament']>,
    ParentType,
    ContextType,
    RequireFields<QuerySearchTournamentsArgs, never>
  >;
  shopSnacks: Resolver<
    Array<ResolversTypes['ShopSnack']>,
    ParentType,
    ContextType
  >;
  shopWares: Resolver<
    Array<ResolversTypes['ShopWares']>,
    ParentType,
    ContextType
  >;
  skillDefinitions: Resolver<
    Array<ResolversTypes['SkillDefinition']>,
    ParentType,
    ContextType
  >;
  timekeeperAdHocs: Resolver<
    Array<ResolversTypes['TimekeeperAdHoc']>,
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperAdHocsArgs, 'sender'>
  >;
  timekeeperPrevious: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperPreviousArgs, 'sender'>
  >;
  timekeeperPreviousRequests: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperPreviousRequestsArgs, 'date' | 'sender'>
  >;
  timekeeperPreviousResolutions: Resolver<
    Array<ResolversTypes['TimekeeperResolution']>,
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperPreviousResolutionsArgs, 'tokenId'>
  >;
  timekeeperRound: Resolver<
    ResolversTypes['TimekeeperRound'],
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperRoundArgs, never>
  >;
  timekeeperState: Resolver<
    ResolversTypes['TimekeeperUserState'],
    ParentType,
    ContextType,
    RequireFields<QueryTimekeeperStateArgs, 'sender'>
  >;
  topBossBattles: Resolver<
    Array<ResolversTypes['RecentBossBattles']>,
    ParentType,
    ContextType
  >;
  tournament: Resolver<
    ResolversTypes['Tournament'],
    ParentType,
    ContextType,
    RequireFields<QueryTournamentArgs, 'id'>
  >;
  unreadNotifications: Resolver<
    Maybe<ResolversTypes['UnreadNotificationsConnection']>,
    ParentType,
    ContextType,
    RequireFields<QueryUnreadNotificationsArgs, never>
  >;
  updates: Resolver<
    ResolversTypes['FurUpdates'],
    ParentType,
    ContextType,
    RequireFields<QueryUpdatesArgs, 'since'>
  >;
  usableFurballs: Resolver<
    Array<ResolversTypes['Furball']>,
    ParentType,
    ContextType
  >;
};

export type QuestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Quest'] = ResolversParentTypes['Quest'],
> = {
  completedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  completedWaypointNames: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  completedWaypoints: Resolver<
    Array<ResolversTypes['QuestWaypointCompletion']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  questDefinition: Resolver<
    ResolversTypes['QuestDefinition'],
    ParentType,
    ContextType
  >;
  questName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  remainingWaypointNames: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  reward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  rewardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestDefinition'] = ResolversParentTypes['QuestDefinition'],
> = {
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxCompletions: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  respawn: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  waypoints: Resolver<
    Array<ResolversTypes['QuestWaypointDefinition']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestWaypointBracketDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestWaypointBracketDefinition'] = ResolversParentTypes['QuestWaypointBracketDefinition'],
> = {
  furAmount: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  percentileRank: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  possibleRewardList: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  possibleRewards: Resolver<
    Array<ResolversTypes['LeaderboardTierRewardChance']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestWaypointCompletionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestWaypointCompletion'] = ResolversParentTypes['QuestWaypointCompletion'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quest: Resolver<ResolversTypes['Quest'], ParentType, ContextType>;
  questId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  rewardId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waypointName: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuestWaypointDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['QuestWaypointDefinition'] = ResolversParentTypes['QuestWaypointDefinition'],
> = {
  brackets: Resolver<
    Array<ResolversTypes['QuestWaypointBracketDefinition']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quest: Resolver<ResolversTypes['QuestDefinition'], ParentType, ContextType>;
  style: Resolver<ResolversTypes['WaypointStyle'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RandomPartyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RandomParty'] = ResolversParentTypes['RandomParty'],
> = {
  furballs: Resolver<Array<ResolversTypes['Furball']>, ParentType, ContextType>;
  party: Resolver<ResolversTypes['GameParty'], ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RecentBossBattlesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RecentBossBattles'] = ResolversParentTypes['RecentBossBattles'],
> = {
  battles: Resolver<
    Maybe<ResolversTypes['BattlesConnection']>,
    ParentType,
    ContextType,
    RequireFields<RecentBossBattlesBattlesArgs, never>
  >;
  boss: Resolver<ResolversTypes['GameBoss'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalAgreementResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RentalAgreement'] = ResolversParentTypes['RentalAgreement'],
> = {
  autoRenew: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  battles: Resolver<
    Array<ResolversTypes['GameBattle']>,
    ParentType,
    ContextType
  >;
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  changedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  duration: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  endedAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  owner: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  ownerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ownerPlayer: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  pendingCancellation: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  percent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  player: Resolver<Maybe<ResolversTypes['FurPlayer']>, ParentType, ContextType>;
  playerId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rentedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  renterPlayer: Resolver<
    Maybe<ResolversTypes['FurPlayer']>,
    ParentType,
    ContextType
  >;
  rewardCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  signature: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  startsAt: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  terminations: Resolver<
    Array<ResolversTypes['RentalTermination']>,
    ParentType,
    ContextType
  >;
  unusedHearts: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wFurEarned: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  willEndAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalAgreementsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RentalAgreementsConnection'] = ResolversParentTypes['RentalAgreementsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['RentalAgreementsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['RentalAgreement']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalAgreementsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RentalAgreementsEdge'] = ResolversParentTypes['RentalAgreementsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['RentalAgreement'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RentalTerminationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['RentalTermination'] = ResolversParentTypes['RentalTermination'],
> = {
  createdAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  reason: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScholarsDiscordConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScholarsDiscordConnection'] = ResolversParentTypes['ScholarsDiscordConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['ScholarsDiscordEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['FurScholarDiscord']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScholarsDiscordEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScholarsDiscordEdge'] = ResolversParentTypes['ScholarsDiscordEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['FurScholarDiscord'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScholarsEmailConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScholarsEmailConnection'] = ResolversParentTypes['ScholarsEmailConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['ScholarsEmailEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['FurScholarEmail']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ScholarsEmailEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ScholarsEmailEdge'] = ResolversParentTypes['ScholarsEmailEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['FurScholarEmail'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchFurballsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchFurballsConnection'] = ResolversParentTypes['SearchFurballsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['SearchFurballsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['Furball']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchFurballsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SearchFurballsEdge'] = ResolversParentTypes['SearchFurballsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SfxAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SfxAsset'] = ResolversParentTypes['SfxAsset'],
> = {
  duration: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopPurchaseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShopPurchase'] = ResolversParentTypes['ShopPurchase'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transaction: Resolver<
    ResolversTypes['BlockchainTransaction'],
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  wares: Resolver<ResolversTypes['ShopWares'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopSnackResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShopSnack'] = ResolversParentTypes['ShopSnack'],
> = {
  energy: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost1: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelCostN: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  happiness: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  saleBeginsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  saleEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShopWaresResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['ShopWares'] = ResolversParentTypes['ShopWares'],
> = {
  fuelCost1: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelCostN: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  itemIds: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  itemList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  maxPerFurball: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  purchases: Resolver<
    Array<ResolversTypes['ShopPurchase']>,
    ParentType,
    ContextType
  >;
  saleBeginsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  saleEndsAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ShortScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['Short'], any> {
  name: 'Short';
}

export type SkillDefinitionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkillDefinition'] = ResolversParentTypes['SkillDefinition'],
> = {
  animationId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attacksOpponent: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  baseUses: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defendsAlly: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  description: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  effectBonuses: Resolver<
    Array<ResolversTypes['SkillStatEffect']>,
    ParentType,
    ContextType
  >;
  icon: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  isInstant: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  levelNumPartialsPerTier: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  levelPartialCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  levelUpgradeTotalCost: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUses: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesNumPartialsPerTier: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  maxUsesPartialCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxUsesUpgradeTotalCost: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  realmAffiliations: Resolver<
    Array<ResolversTypes['RealmType']>,
    ParentType,
    ContextType
  >;
  summary: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  targetingRule: Resolver<
    Maybe<ResolversTypes['TargetingRule']>,
    ParentType,
    ContextType
  >;
  targetingStrategy: Resolver<
    Maybe<ResolversTypes['TargetingStrategy']>,
    ParentType,
    ContextType
  >;
  vfx: Resolver<Maybe<ResolversTypes['VfxAsset']>, ParentType, ContextType>;
  vfxId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillStatEffectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SkillStatEffect'] = ResolversParentTypes['SkillStatEffect'],
> = {
  animationId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  attacksOpponent: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bonusStat: Resolver<
    ResolversTypes['StatEffectBonus'],
    ParentType,
    ContextType
  >;
  buffType: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  canTargetAlly: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canTargetOpponent: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  defendsAlly: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  fromPosition: Resolver<
    ResolversTypes['FormationPosition'],
    ParentType,
    ContextType
  >;
  icon: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBuff: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDamage: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDeBuff: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isDefensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isHeal: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isHealthChange: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isInstant: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOffensive: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTargetAlly: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTargetOpponent: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  maxUses: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  operand: Resolver<
    ResolversTypes['EntityEffectOperand'],
    ParentType,
    ContextType
  >;
  phase: Resolver<ResolversTypes['BattleTurnPhase'], ParentType, ContextType>;
  reApply: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  seconds: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sfx: Resolver<Maybe<ResolversTypes['SfxAsset']>, ParentType, ContextType>;
  sfxId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  stackable: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  stat: Resolver<ResolversTypes['StatEffectTarget'], ParentType, ContextType>;
  stats: Resolver<
    Maybe<ResolversTypes['BattleStats']>,
    ParentType,
    ContextType
  >;
  summary: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<SkillStatEffectSummaryArgs, never>
  >;
  summaryPosition: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  summaryStat: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  summaryStatTemplate: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType
  >;
  summaryTarget: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  target: Resolver<ResolversTypes['SkillTarget'], ParentType, ContextType>;
  targetAmount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  targetTeam: Resolver<
    ResolversTypes['SkillTargetTeam'],
    ParentType,
    ContextType
  >;
  toPositions: Resolver<
    Array<ResolversTypes['FormationPosition']>,
    ParentType,
    ContextType
  >;
  uses: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  value: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  valuePerLevel: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vfx: Resolver<Maybe<ResolversTypes['VfxAsset']>, ParentType, ContextType>;
  vfxId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlotsConnection'] = ResolversParentTypes['SlotsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['SlotsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['LeaderboardSlot']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlotsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SlotsEdge'] = ResolversParentTypes['SlotsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['LeaderboardSlot'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialOAuthTokenResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialOAuthToken'] = ResolversParentTypes['SocialOAuthToken'],
> = {
  accessToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  expiresIn: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isValid: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  profile: Resolver<ResolversTypes['SocialProfile'], ParentType, ContextType>;
  refreshToken: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenType: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialProfileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialProfile'] = ResolversParentTypes['SocialProfile'],
> = {
  account: Resolver<ResolversTypes['FurAccount'], ParentType, ContextType>;
  accountId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  avatar: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  email: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  roles: Resolver<Array<ResolversTypes['SocialRole']>, ParentType, ContextType>;
  source: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokens: Resolver<
    Array<ResolversTypes['SocialOAuthToken']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  username: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SocialRoleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SocialRole'] = ResolversParentTypes['SocialRole'],
> = {
  colorStr: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  communityFlag: Resolver<
    Maybe<ResolversTypes['CommunityFlag']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  socialProfile: Resolver<
    ResolversTypes['SocialProfile'],
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SpecialItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SpecialItem'] = ResolversParentTypes['SpecialItem'],
> = {
  container: Resolver<
    Maybe<ResolversTypes['ContainerItem']>,
    ParentType,
    ContextType
  >;
  containerId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  definition: Resolver<
    ResolversTypes['ItemDefinition'],
    ParentType,
    ContextType
  >;
  destroyedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  dustValue: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gameBattlePvEId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  itemDefinition: Resolver<
    Maybe<ResolversTypes['ItemDefinition']>,
    ParentType,
    ContextType
  >;
  itemDiscriminator: Resolver<
    ResolversTypes['ItemDiscriminator'],
    ParentType,
    ContextType
  >;
  itemGroup: Resolver<ResolversTypes['ItemGroup'], ParentType, ContextType>;
  itemId: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxStack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  playerPendingReward: Resolver<
    Maybe<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  playerPendingRewardId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  rarity: Resolver<ResolversTypes['RarityLevel'], ParentType, ContextType>;
  realmAffiliation: Resolver<
    ResolversTypes['RealmType'],
    ParentType,
    ContextType
  >;
  stack: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StatEntityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['StatEntity'] = ResolversParentTypes['StatEntity'],
> = {
  allowedMoveAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  attackPower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  battleStats: Resolver<
    Maybe<ResolversTypes['GameEntity']>,
    ParentType,
    ContextType
  >;
  buff: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  buffTotal: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  clockPausedUntil: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  critAttackPower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  critRate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  curHealth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  currentRealm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  currentStats: Resolver<
    ResolversTypes['BattleStats'],
    ParentType,
    ContextType
  >;
  deBuff: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deBuffTotal: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defencePower: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  effects: Resolver<
    Array<ResolversTypes['EntityEffect']>,
    ParentType,
    ContextType
  >;
  elixir: Resolver<
    Maybe<ResolversTypes['ConsumableItem']>,
    ParentType,
    ContextType
  >;
  elixirId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entity: Resolver<
    Maybe<ResolversTypes['GameEntity']>,
    ParentType,
    ContextType
  >;
  entityId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  equipment: Resolver<
    Array<ResolversTypes['EquipmentItem']>,
    ParentType,
    ContextType
  >;
  heal: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hearts: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isAi: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isBoss: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFurball: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMinion: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMonster: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  itemQuantities: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  items: Resolver<Array<ResolversTypes['GameItem']>, ParentType, ContextType>;
  karma: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lastMoveAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  level: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  loadedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  loot: Resolver<Array<ResolversTypes['GameItem']>, ParentType, ContextType>;
  lootQuantities: Resolver<
    Array<ResolversTypes['ItemQuantity']>,
    ParentType,
    ContextType
  >;
  maxHealth: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  monsterDefinition: Resolver<
    Maybe<ResolversTypes['MonsterDefinition']>,
    ParentType,
    ContextType
  >;
  monsterSlug: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  moveCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nextMove: Resolver<
    Maybe<ResolversTypes['BattleMoveNext']>,
    ParentType,
    ContextType
  >;
  nextMoveAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  partyId: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passive: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  passives: Resolver<
    Array<ResolversTypes['BattleEffectPassive']>,
    ParentType,
    ContextType
  >;
  pauseDuration: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pausedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  phase: Resolver<ResolversTypes['BattleTurnPhase'], ParentType, ContextType>;
  position: Resolver<
    ResolversTypes['FormationPosition'],
    ParentType,
    ContextType
  >;
  revive: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardDust: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rewardIntervals: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  skills: Resolver<
    Array<ResolversTypes['EntitySkill']>,
    ParentType,
    ContextType
  >;
  speed: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  vamp: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  zone: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription'],
> = {
  bossBattleGameLoop: SubscriptionResolver<
    ResolversTypes['BattleMoveResponse'],
    'bossBattleGameLoop',
    ParentType,
    ContextType,
    RequireFields<SubscriptionBossBattleGameLoopArgs, 'bossBattlePlayerId'>
  >;
  gameBattleLoop: SubscriptionResolver<
    ResolversTypes['BattleMoveResponse'],
    'gameBattleLoop',
    ParentType,
    ContextType,
    RequireFields<SubscriptionGameBattleLoopArgs, 'gameBattleId'>
  >;
  notify: SubscriptionResolver<
    ResolversTypes['Notification'],
    'notify',
    ParentType,
    ContextType,
    RequireFields<SubscriptionNotifyArgs, 'playerId'>
  >;
};

export type TiersConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiersConnection'] = ResolversParentTypes['TiersConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['TiersEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['LeaderboardTier']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TiersEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TiersEdge'] = ResolversParentTypes['TiersEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['LeaderboardTier'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TimeSpanScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['TimeSpan'], any> {
  name: 'TimeSpan';
}

export type TimekeeperAdHocResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperAdHoc'] = ResolversParentTypes['TimekeeperAdHoc'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperChangeSetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperChangeSet'] = ResolversParentTypes['TimekeeperChangeSet'],
> = {
  collectFur: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  collectStayList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interactions: Resolver<
    Array<ResolversTypes['TimekeeperInteraction']>,
    ParentType,
    ContextType
  >;
  mintCount: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  mintEdition: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  movementList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  request: Resolver<
    Maybe<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperCostsResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperCosts'] = ResolversParentTypes['TimekeeperCosts'],
> = {
  collectExp1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  collectExpN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  collectFur: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  completeVoyage: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dropLoot: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  enterVoyages: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  equipment: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  factor: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  functionCall: Resolver<
    ResolversTypes['UnsignedInt'],
    ParentType,
    ContextType
  >;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  maxPlayersPerTransaction: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  mint1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mintN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  move1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  moveN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  progress: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  skill: Resolver<ResolversTypes['UnsignedInt'], ParentType, ContextType>;
  snack1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  snackN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spendFur: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  spendTickets: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  toggle1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  toggleN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upgrade1: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upgradeN: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  upgradeSkill: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperInstantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperInstant'] = ResolversParentTypes['TimekeeperInstant'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperInteractionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperInteraction'] = ResolversParentTypes['TimekeeperInteraction'],
> = {
  changeSet: Resolver<
    ResolversTypes['TimekeeperChangeSet'],
    ParentType,
    ContextType
  >;
  changeSetId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  count: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expectedValue: Resolver<
    Maybe<ResolversTypes['Long']>,
    ParentType,
    ContextType
  >;
  fuelCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furballFuel: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gasEstimate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interactionType: Resolver<
    ResolversTypes['TimekeeperInteractionType'],
    ParentType,
    ContextType
  >;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sourceInteractionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperModeChangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperModeChange'] = ResolversParentTypes['TimekeeperModeChange'],
> = {
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastTimestamp: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  toggle: Resolver<ResolversTypes['TimekeeperToggle'], ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperRenameFurballResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperRenameFurball'] = ResolversParentTypes['TimekeeperRenameFurball'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperRequestResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperRequest'] = ResolversParentTypes['TimekeeperRequest'],
> = {
  changeSet: Resolver<
    Maybe<ResolversTypes['TimekeeperChangeSet']>,
    ParentType,
    ContextType
  >;
  changes: Resolver<
    Array<ResolversTypes['TimekeeperChangeSet']>,
    ParentType,
    ContextType
  >;
  computedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furGained: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  furReal: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furSpent: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gasIncremental: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  hasChanges: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPending: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  numFurballs: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  partialIndex: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  ratio: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  resolutions: Resolver<
    Array<ResolversTypes['TimekeeperResolution']>,
    ParentType,
    ContextType
  >;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  round: Resolver<ResolversTypes['TimekeeperRound'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedRequest: Resolver<
    Maybe<ResolversTypes['TimekeeperChangeSet']>,
    ParentType,
    ContextType
  >;
  state: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['TimekeeperTransaction']>,
    ParentType,
    ContextType
  >;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperResolutionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperResolution'] = ResolversParentTypes['TimekeeperResolution'],
> = {
  chainDropItem: Resolver<
    Maybe<ResolversTypes['Long']>,
    ParentType,
    ContextType
  >;
  completedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  energy: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  happiness: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasBlockchainData: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  intervals: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isEmpty: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMove: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isTimestampedAction: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  itemList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lootItems: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  offChainDropIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  request: Resolver<
    ResolversTypes['TimekeeperRequest'],
    ParentType,
    ContextType
  >;
  round: Resolver<ResolversTypes['TimekeeperRound'], ParentType, ContextType>;
  scaledIntervals: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  skillLevelUpgradeMap: Resolver<
    Array<ResolversTypes['KeyValuePairOfInt64AndInt32']>,
    ParentType,
    ContextType
  >;
  skillLevelUpgrades: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  skillUsesUpgradeMap: Resolver<
    Array<ResolversTypes['KeyValuePairOfInt64AndInt32']>,
    ParentType,
    ContextType
  >;
  skillUsesUpgrades: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  snackStacks: Resolver<Array<ResolversTypes['Long']>, ParentType, ContextType>;
  snackStacksList: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  upgraded: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  zoneKind: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  zoneNumber: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperRoundResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperRound'] = ResolversParentTypes['TimekeeperRound'],
> = {
  costs: Resolver<ResolversTypes['TimekeeperCosts'], ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deltaI: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deltaP: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  deltaT: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  detectedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCostI: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCostT: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballUpgrades: Resolver<
    Array<ResolversTypes['FurballUpgrade']>,
    ParentType,
    ContextType
  >;
  gasCheckCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasUsed: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  instants: Resolver<
    Array<ResolversTypes['TimekeeperInstant']>,
    ParentType,
    ContextType
  >;
  instantsCompleted: Resolver<
    Array<ResolversTypes['TimekeeperInstant']>,
    ParentType,
    ContextType
  >;
  instantsCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isComplete: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPending: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  processing: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requests: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  requestsAccepted: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  requestsAcceptedCount: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  requestsPending: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  requestsPendingCount: Resolver<
    ResolversTypes['Int'],
    ParentType,
    ContextType
  >;
  startedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  startedLastAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  state: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixEthI: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  tixEthT: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactions: Resolver<
    Array<ResolversTypes['TimekeeperTransaction']>,
    ParentType,
    ContextType
  >;
  transactionsCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperToggleResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperToggle'] = ResolversParentTypes['TimekeeperToggle'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  furballIds: Resolver<
    Array<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  mode: Resolver<ResolversTypes['Short'], ParentType, ContextType>;
  modeChanges: Resolver<
    Array<ResolversTypes['TimekeeperModeChange']>,
    ParentType,
    ContextType
  >;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperDisabled: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperTransactionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperTransaction'] = ResolversParentTypes['TimekeeperTransaction'],
> = {
  blockNumber: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  contractAddress: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  cumulativeGasUsed: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  effectiveGasPrice: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasEstimate: Resolver<Maybe<ResolversTypes['Long']>, ParentType, ContextType>;
  gasProjection: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasUsed: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gweiToGas: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hash: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  index: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  instant: Resolver<
    Maybe<ResolversTypes['TimekeeperInstant']>,
    ParentType,
    ContextType
  >;
  logsBloom: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ratio: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  requests: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  round: Resolver<ResolversTypes['TimekeeperRound'], ParentType, ContextType>;
  status: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  type: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  upgrades: Resolver<
    Array<ResolversTypes['FurballUpgrade']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperTransferTixResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperTransferTix'] = ResolversParentTypes['TimekeeperTransferTix'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  recipient: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperUserStateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperUserState'] = ResolversParentTypes['TimekeeperUserState'],
> = {
  current: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  pending: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType
  >;
  requestsOnDate: Resolver<
    Array<ResolversTypes['TimekeeperRequest']>,
    ParentType,
    ContextType,
    RequireFields<TimekeeperUserStateRequestsOnDateArgs, 'date'>
  >;
  round: Resolver<ResolversTypes['TimekeeperRound'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimekeeperWrapFurResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TimekeeperWrapFur'] = ResolversParentTypes['TimekeeperWrapFur'],
> = {
  beganAt: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  deadline: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  delta: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethCost: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  executingHost: Resolver<
    Maybe<ResolversTypes['HostNode']>,
    ParentType,
    ContextType
  >;
  executingHostName: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  failureCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fuelCost: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  gasEstimate: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  gasPrice: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  hasRun: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isAccepted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resolvedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  reverted: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  spent: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timekeeperRoundDate: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  tixEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionHash: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  transactionMessage: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  value: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TixExchangeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TixExchange'] = ResolversParentTypes['TixExchange'],
> = {
  costBuy: Resolver<ResolversTypes['CurrencyCosts'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenModelOneToOneResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TokenModelOneToOne'] = ResolversParentTypes['TokenModelOneToOne'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  id: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tournament'] = ResolversParentTypes['Tournament'],
> = {
  backgroundColor: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  currentParticipant: Resolver<
    Maybe<ResolversTypes['TournamentParticipant']>,
    ParentType,
    ContextType,
    RequireFields<TournamentCurrentParticipantArgs, never>
  >;
  endsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  entryFeeFuel: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  entryFeeTix: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  ethPerEntry: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethPoolFactor: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ethSpentViaTix: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  heroUrl: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isParticipant: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  leaderboard: Resolver<ResolversTypes['Leaderboard'], ParentType, ContextType>;
  leaderboardId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  participantCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  participants: Resolver<
    Array<ResolversTypes['TournamentParticipant']>,
    ParentType,
    ContextType
  >;
  payoutPoolEth: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  realm: Resolver<Maybe<ResolversTypes['RealmType']>, ParentType, ContextType>;
  scoringStyle: Resolver<
    ResolversTypes['TournamentScoringStyle'],
    ParentType,
    ContextType
  >;
  startsAt: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TournamentParticipantResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TournamentParticipant'] = ResolversParentTypes['TournamentParticipant'],
> = {
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  ethEarned: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  player: Resolver<ResolversTypes['FurPlayer'], ParentType, ContextType>;
  playerId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rewards: Resolver<
    Array<ResolversTypes['PlayerPendingReward']>,
    ParentType,
    ContextType
  >;
  totalScore: Resolver<ResolversTypes['UnsignedLong'], ParentType, ContextType>;
  tournament: Resolver<ResolversTypes['Tournament'], ParentType, ContextType>;
  tournamentId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transaction: Resolver<
    Maybe<ResolversTypes['BlockchainTransaction']>,
    ParentType,
    ContextType
  >;
  transactionId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  wFurEarned: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadNotificationsConnectionResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnreadNotificationsConnection'] = ResolversParentTypes['UnreadNotificationsConnection'],
> = {
  edges: Resolver<
    Maybe<Array<ResolversTypes['UnreadNotificationsEdge']>>,
    ParentType,
    ContextType
  >;
  nodes: Resolver<
    Maybe<Array<ResolversTypes['Notification']>>,
    ParentType,
    ContextType
  >;
  pageInfo: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UnreadNotificationsEdgeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UnreadNotificationsEdge'] = ResolversParentTypes['UnreadNotificationsEdge'],
> = {
  cursor: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node: Resolver<ResolversTypes['Notification'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UnsignedIntScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedInt'], any> {
  name: 'UnsignedInt';
}

export interface UnsignedLongScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedLong'], any> {
  name: 'UnsignedLong';
}

export interface UnsignedShortScalarConfig
  extends GraphQLScalarTypeConfig<ResolversTypes['UnsignedShort'], any> {
  name: 'UnsignedShort';
}

export type VfxAssetResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VfxAsset'] = ResolversParentTypes['VfxAsset'],
> = {
  duration: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Voyage'] = ResolversParentTypes['Voyage'],
> = {
  activeEncounter: Resolver<
    Maybe<ResolversTypes['VoyageEncounter']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  daysComplete: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  daysDuration: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  daysRemaining: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  encounters: Resolver<
    Array<ResolversTypes['VoyageEncounter']>,
    ParentType,
    ContextType
  >;
  encountersFuelCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  encountersFurGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  furball: Resolver<ResolversTypes['Furball'], ParentType, ContextType>;
  furballId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastEncounter: Resolver<
    Maybe<ResolversTypes['VoyageEncounter']>,
    ParentType,
    ContextType
  >;
  maxLootItems: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  percentComplete: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  realm: Resolver<ResolversTypes['RealmType'], ParentType, ContextType>;
  realmBoost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state: Resolver<ResolversTypes['VoyageState'], ParentType, ContextType>;
  totalFurCost: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoyageEncounterResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['VoyageEncounter'] = ResolversParentTypes['VoyageEncounter'],
> = {
  broadcastedOutcome: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  createdAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  date: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  decision: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  didPlayerComplete: Resolver<
    ResolversTypes['Boolean'],
    ParentType,
    ContextType
  >;
  encounterType: Resolver<
    ResolversTypes['VoyageEncounterType'],
    ParentType,
    ContextType
  >;
  fuelCost: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  furCost: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  furGained: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  itemGained: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  match: Resolver<
    Maybe<ResolversTypes['VoyageEncounter']>,
    ParentType,
    ContextType
  >;
  matchedEncounterId: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType
  >;
  outcome: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sender: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  subType: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  submitScore: Resolver<
    ResolversTypes['VoyageEncounter'],
    ParentType,
    ContextType,
    RequireFields<VoyageEncounterSubmitScoreArgs, 'decision'>
  >;
  tokenId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt: Resolver<
    Maybe<ResolversTypes['DateTime']>,
    ParentType,
    ContextType
  >;
  voyage: Resolver<ResolversTypes['Voyage'], ParentType, ContextType>;
  voyageDayNumber: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  voyageId: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccountsConnection: AccountsConnectionResolvers<ContextType>;
  AccountsEdge: AccountsEdgeResolvers<ContextType>;
  AirdropRequest: AirdropRequestResolvers<ContextType>;
  AnimationAsset: AnimationAssetResolvers<ContextType>;
  AntePool: AntePoolResolvers<ContextType>;
  BattleEffectPassive: BattleEffectPassiveResolvers<ContextType>;
  BattleMove: BattleMoveResolvers<ContextType>;
  BattleMoveNext: BattleMoveNextResolvers<ContextType>;
  BattleMoveOutcome: BattleMoveOutcomeResolvers<ContextType>;
  BattleMoveResponse: BattleMoveResponseResolvers<ContextType>;
  BattlePercentiles: BattlePercentilesResolvers<ContextType>;
  BattleStats: BattleStatsResolvers<ContextType>;
  BattlesConnection: BattlesConnectionResolvers<ContextType>;
  BattlesEdge: BattlesEdgeResolvers<ContextType>;
  BlockWork: BlockWorkResolvers<ContextType>;
  BlockchainModel: BlockchainModelResolvers<ContextType>;
  BlockchainToken: BlockchainTokenResolvers<ContextType>;
  BlockchainTokenTransaction: BlockchainTokenTransactionResolvers<ContextType>;
  BlockchainTransaction: BlockchainTransactionResolvers<ContextType>;
  BossBattlesConnection: BossBattlesConnectionResolvers<ContextType>;
  BossBattlesEdge: BossBattlesEdgeResolvers<ContextType>;
  BossFightsConnection: BossFightsConnectionResolvers<ContextType>;
  BossFightsEdge: BossFightsEdgeResolvers<ContextType>;
  Byte: GraphQLScalarType;
  ClientSpecification: ClientSpecificationResolvers<ContextType>;
  ConsumableItem: ConsumableItemResolvers<ContextType>;
  ContainerItem: ContainerItemResolvers<ContextType>;
  CraftRecipeIngredient: CraftRecipeIngredientResolvers<ContextType>;
  CraftSlot: CraftSlotResolvers<ContextType>;
  CraftingAttempt: CraftingAttemptResolvers<ContextType>;
  CraftingRecipe: CraftingRecipeResolvers<ContextType>;
  CurrencyCost: CurrencyCostResolvers<ContextType>;
  CurrencyCosts: CurrencyCostsResolvers<ContextType>;
  CurrencyExchangeRates: CurrencyExchangeRatesResolvers<ContextType>;
  DateTime: GraphQLScalarType;
  Decimal: GraphQLScalarType;
  DuelBattlesConnection: DuelBattlesConnectionResolvers<ContextType>;
  DuelBattlesEdge: DuelBattlesEdgeResolvers<ContextType>;
  DutchAuction: DutchAuctionResolvers<ContextType>;
  DutchAuctionResult: DutchAuctionResultResolvers<ContextType>;
  EditionAsset: EditionAssetResolvers<ContextType>;
  EditionBase: EditionBaseResolvers<ContextType>;
  EntityAnimationCollection: EntityAnimationCollectionResolvers<ContextType>;
  EntityEffect: EntityEffectResolvers<ContextType>;
  EntitySkill: EntitySkillResolvers<ContextType>;
  EquipmentItem: EquipmentItemResolvers<ContextType>;
  EthExchange: EthExchangeResolvers<ContextType>;
  FormationDefinition: FormationDefinitionResolvers<ContextType>;
  FurAccount: FurAccountResolvers<ContextType>;
  FurExchange: FurExchangeResolvers<ContextType>;
  FurFundingLot: FurFundingLotResolvers<ContextType>;
  FurOAuthToken: FurOAuthTokenResolvers<ContextType>;
  FurPlayer: FurPlayerResolvers<ContextType>;
  FurPurchasePlayer: FurPurchasePlayerResolvers<ContextType>;
  FurScholar: FurScholarResolvers<ContextType>;
  FurScholarDiscord: FurScholarDiscordResolvers<ContextType>;
  FurScholarEmail: FurScholarEmailResolvers<ContextType>;
  FurSession: FurSessionResolvers<ContextType>;
  FurUpdates: FurUpdatesResolvers<ContextType>;
  Furball: FurballResolvers<ContextType>;
  FurballAttribute: FurballAttributeResolvers<ContextType>;
  FurballDefinition: FurballDefinitionResolvers<ContextType>;
  FurballInfo: FurballInfoResolvers<ContextType>;
  FurballLootUpgradeResponse: FurballLootUpgradeResponseResolvers<ContextType>;
  FurballModification: FurballModificationResolvers<ContextType>;
  FurballNameChange: FurballNameChangeResolvers<ContextType>;
  FurballNameChangesConnection: FurballNameChangesConnectionResolvers<ContextType>;
  FurballNameChangesEdge: FurballNameChangesEdgeResolvers<ContextType>;
  FurballRank: FurballRankResolvers<ContextType>;
  FurballRewardModifiers: FurballRewardModifiersResolvers<ContextType>;
  FurballSnack: FurballSnackResolvers<ContextType>;
  FurballState: FurballStateResolvers<ContextType>;
  FurballStats: FurballStatsResolvers<ContextType>;
  FurballToken: FurballTokenResolvers<ContextType>;
  FurballUpgrade: FurballUpgradeResolvers<ContextType>;
  FurballUpgradeBattleStats: FurballUpgradeBattleStatsResolvers<ContextType>;
  FurballUpgradeEquippedItem: FurballUpgradeEquippedItemResolvers<ContextType>;
  FurballUpgradeProgress: FurballUpgradeProgressResolvers<ContextType>;
  FurballUpgradeSkill: FurballUpgradeSkillResolvers<ContextType>;
  FurballZoneReward: FurballZoneRewardResolvers<ContextType>;
  FurballsMeta: FurballsMetaResolvers<ContextType>;
  GameBattle: GameBattleResolvers<ContextType>;
  GameBattleBoss: GameBattleBossResolvers<ContextType>;
  GameBattleDuel: GameBattleDuelResolvers<ContextType>;
  GameBattleMonsters: GameBattleMonstersResolvers<ContextType>;
  GameBattlePvE: GameBattlePvEResolvers<ContextType>;
  GameBattlePvP: GameBattlePvPResolvers<ContextType>;
  GameBattleRules: GameBattleRulesResolvers<ContextType>;
  GameBattlesConnection: GameBattlesConnectionResolvers<ContextType>;
  GameBattlesEdge: GameBattlesEdgeResolvers<ContextType>;
  GameBoss: GameBossResolvers<ContextType>;
  GameBuild: GameBuildResolvers<ContextType>;
  GameEntity: GameEntityResolvers<ContextType>;
  GameEntitySummary: GameEntitySummaryResolvers<ContextType>;
  GameItem: GameItemResolvers<ContextType>;
  GameMonster: GameMonsterResolvers<ContextType>;
  GameObject: GameObjectResolvers<ContextType>;
  GameParty: GamePartyResolvers<ContextType>;
  HistoryConnection: HistoryConnectionResolvers<ContextType>;
  HistoryEdge: HistoryEdgeResolvers<ContextType>;
  HostNode: HostNodeResolvers<ContextType>;
  HostTask: HostTaskResolvers<ContextType>;
  IBattleEffect: IBattleEffectResolvers<ContextType>;
  IFurPlayer: IFurPlayerResolvers<ContextType>;
  InventoryItem: InventoryItemResolvers<ContextType>;
  ItemDefinition: ItemDefinitionResolvers<ContextType>;
  ItemListingsConnection: ItemListingsConnectionResolvers<ContextType>;
  ItemListingsEdge: ItemListingsEdgeResolvers<ContextType>;
  ItemQuantity: ItemQuantityResolvers<ContextType>;
  ItemReward: ItemRewardResolvers<ContextType>;
  JoinBattleResponse: JoinBattleResponseResolvers<ContextType>;
  KeyValuePairOfInt64AndInt32: KeyValuePairOfInt64AndInt32Resolvers<ContextType>;
  KeyValuePairOfStringAndAnimationAsset: KeyValuePairOfStringAndAnimationAssetResolvers<ContextType>;
  Leaderboard: LeaderboardResolvers<ContextType>;
  LeaderboardPosition: LeaderboardPositionResolvers<ContextType>;
  LeaderboardRankFurball: LeaderboardRankFurballResolvers<ContextType>;
  LeaderboardRankPlayer: LeaderboardRankPlayerResolvers<ContextType>;
  LeaderboardSlot: LeaderboardSlotResolvers<ContextType>;
  LeaderboardSlotFurball: LeaderboardSlotFurballResolvers<ContextType>;
  LeaderboardSlotPlayer: LeaderboardSlotPlayerResolvers<ContextType>;
  LeaderboardTier: LeaderboardTierResolvers<ContextType>;
  LeaderboardTierRewardChance: LeaderboardTierRewardChanceResolvers<ContextType>;
  LeaderboardTierRewards: LeaderboardTierRewardsResolvers<ContextType>;
  LoginEmailResponse: LoginEmailResponseResolvers<ContextType>;
  LoginResponse: LoginResponseResolvers<ContextType>;
  Long: GraphQLScalarType;
  LootBox: LootBoxResolvers<ContextType>;
  LootItem: LootItemResolvers<ContextType>;
  MarketListing: MarketListingResolvers<ContextType>;
  MaterialItem: MaterialItemResolvers<ContextType>;
  MonsterBattlesConnection: MonsterBattlesConnectionResolvers<ContextType>;
  MonsterBattlesEdge: MonsterBattlesEdgeResolvers<ContextType>;
  MonsterDefinition: MonsterDefinitionResolvers<ContextType>;
  MonsterSkillDefinition: MonsterSkillDefinitionResolvers<ContextType>;
  MoveItemResult: MoveItemResultResolvers<ContextType>;
  MoveZoneResponse: MoveZoneResponseResolvers<ContextType>;
  MovesConnection: MovesConnectionResolvers<ContextType>;
  MovesEdge: MovesEdgeResolvers<ContextType>;
  Mutation: MutationResolvers<ContextType>;
  Notification: NotificationResolvers<ContextType>;
  NotificationInstance: NotificationInstanceResolvers<ContextType>;
  NotificationsConnection: NotificationsConnectionResolvers<ContextType>;
  NotificationsEdge: NotificationsEdgeResolvers<ContextType>;
  PageInfo: PageInfoResolvers<ContextType>;
  PassiveEffectRange: PassiveEffectRangeResolvers<ContextType>;
  PendingFurWrapsConnection: PendingFurWrapsConnectionResolvers<ContextType>;
  PendingFurWrapsEdge: PendingFurWrapsEdgeResolvers<ContextType>;
  PlayerAnte: PlayerAnteResolvers<ContextType>;
  PlayerHistoryConnection: PlayerHistoryConnectionResolvers<ContextType>;
  PlayerHistoryDay: PlayerHistoryDayResolvers<ContextType>;
  PlayerHistoryEdge: PlayerHistoryEdgeResolvers<ContextType>;
  PlayerItemListing: PlayerItemListingResolvers<ContextType>;
  PlayerOnboardingStep: PlayerOnboardingStepResolvers<ContextType>;
  PlayerPayout: PlayerPayoutResolvers<ContextType>;
  PlayerPendingReward: PlayerPendingRewardResolvers<ContextType>;
  PlayerPurchase: PlayerPurchaseResolvers<ContextType>;
  PlayerTransactionsConnection: PlayerTransactionsConnectionResolvers<ContextType>;
  PlayerTransactionsEdge: PlayerTransactionsEdgeResolvers<ContextType>;
  PriorRewardsConnection: PriorRewardsConnectionResolvers<ContextType>;
  PriorRewardsEdge: PriorRewardsEdgeResolvers<ContextType>;
  Query: QueryResolvers<ContextType>;
  Quest: QuestResolvers<ContextType>;
  QuestDefinition: QuestDefinitionResolvers<ContextType>;
  QuestWaypointBracketDefinition: QuestWaypointBracketDefinitionResolvers<ContextType>;
  QuestWaypointCompletion: QuestWaypointCompletionResolvers<ContextType>;
  QuestWaypointDefinition: QuestWaypointDefinitionResolvers<ContextType>;
  RandomParty: RandomPartyResolvers<ContextType>;
  RecentBossBattles: RecentBossBattlesResolvers<ContextType>;
  RentalAgreement: RentalAgreementResolvers<ContextType>;
  RentalAgreementsConnection: RentalAgreementsConnectionResolvers<ContextType>;
  RentalAgreementsEdge: RentalAgreementsEdgeResolvers<ContextType>;
  RentalTermination: RentalTerminationResolvers<ContextType>;
  ScholarsDiscordConnection: ScholarsDiscordConnectionResolvers<ContextType>;
  ScholarsDiscordEdge: ScholarsDiscordEdgeResolvers<ContextType>;
  ScholarsEmailConnection: ScholarsEmailConnectionResolvers<ContextType>;
  ScholarsEmailEdge: ScholarsEmailEdgeResolvers<ContextType>;
  SearchFurballsConnection: SearchFurballsConnectionResolvers<ContextType>;
  SearchFurballsEdge: SearchFurballsEdgeResolvers<ContextType>;
  SfxAsset: SfxAssetResolvers<ContextType>;
  ShopPurchase: ShopPurchaseResolvers<ContextType>;
  ShopSnack: ShopSnackResolvers<ContextType>;
  ShopWares: ShopWaresResolvers<ContextType>;
  Short: GraphQLScalarType;
  SkillDefinition: SkillDefinitionResolvers<ContextType>;
  SkillStatEffect: SkillStatEffectResolvers<ContextType>;
  SlotsConnection: SlotsConnectionResolvers<ContextType>;
  SlotsEdge: SlotsEdgeResolvers<ContextType>;
  SocialOAuthToken: SocialOAuthTokenResolvers<ContextType>;
  SocialProfile: SocialProfileResolvers<ContextType>;
  SocialRole: SocialRoleResolvers<ContextType>;
  SpecialItem: SpecialItemResolvers<ContextType>;
  StatEntity: StatEntityResolvers<ContextType>;
  Subscription: SubscriptionResolvers<ContextType>;
  TiersConnection: TiersConnectionResolvers<ContextType>;
  TiersEdge: TiersEdgeResolvers<ContextType>;
  TimeSpan: GraphQLScalarType;
  TimekeeperAdHoc: TimekeeperAdHocResolvers<ContextType>;
  TimekeeperChangeSet: TimekeeperChangeSetResolvers<ContextType>;
  TimekeeperCosts: TimekeeperCostsResolvers<ContextType>;
  TimekeeperInstant: TimekeeperInstantResolvers<ContextType>;
  TimekeeperInteraction: TimekeeperInteractionResolvers<ContextType>;
  TimekeeperModeChange: TimekeeperModeChangeResolvers<ContextType>;
  TimekeeperRenameFurball: TimekeeperRenameFurballResolvers<ContextType>;
  TimekeeperRequest: TimekeeperRequestResolvers<ContextType>;
  TimekeeperResolution: TimekeeperResolutionResolvers<ContextType>;
  TimekeeperRound: TimekeeperRoundResolvers<ContextType>;
  TimekeeperToggle: TimekeeperToggleResolvers<ContextType>;
  TimekeeperTransaction: TimekeeperTransactionResolvers<ContextType>;
  TimekeeperTransferTix: TimekeeperTransferTixResolvers<ContextType>;
  TimekeeperUserState: TimekeeperUserStateResolvers<ContextType>;
  TimekeeperWrapFur: TimekeeperWrapFurResolvers<ContextType>;
  TixExchange: TixExchangeResolvers<ContextType>;
  TokenModelOneToOne: TokenModelOneToOneResolvers<ContextType>;
  Tournament: TournamentResolvers<ContextType>;
  TournamentParticipant: TournamentParticipantResolvers<ContextType>;
  UnreadNotificationsConnection: UnreadNotificationsConnectionResolvers<ContextType>;
  UnreadNotificationsEdge: UnreadNotificationsEdgeResolvers<ContextType>;
  UnsignedInt: GraphQLScalarType;
  UnsignedLong: GraphQLScalarType;
  UnsignedShort: GraphQLScalarType;
  VfxAsset: VfxAssetResolvers<ContextType>;
  Voyage: VoyageResolvers<ContextType>;
  VoyageEncounter: VoyageEncounterResolvers<ContextType>;
};

/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;

type EquipmentEffect_BattleEffectPassive_Fragment = {
  __typename?: 'BattleEffectPassive';
} & BattleEffectPassiveFragment;

type EquipmentEffect_BattleMoveOutcome_Fragment = {
  __typename?: 'BattleMoveOutcome';
} & Pick<BattleMoveOutcome, 'id' | 'stat' | 'value' | 'operand'>;

type EquipmentEffect_EntityEffect_Fragment = {
  __typename?: 'EntityEffect';
} & Pick<EntityEffect, 'id' | 'stat' | 'value' | 'operand'>;

export type EquipmentEffectFragment =
  | EquipmentEffect_BattleEffectPassive_Fragment
  | EquipmentEffect_BattleMoveOutcome_Fragment
  | EquipmentEffect_EntityEffect_Fragment;

export type EquipmentItemFragment = { __typename?: 'EquipmentItem' } & Pick<
  EquipmentItem,
  | 'id'
  | 'itemId'
  | 'stack'
  | 'maxStack'
  | 'itemGroup'
  | 'slot'
  | 'position'
  | 'durabilityTime'
  | 'breakAt'
  | 'rarity'
  | 'equippedById'
  | 'equippedAt'
> & {
    passives: Array<
      { __typename?: 'BattleEffectPassive' } & BattleEffectPassiveFragment
    >;
  };

export type ContainerItemFragment = { __typename?: 'ContainerItem' } & Pick<
  ContainerItem,
  'id' | 'itemId' | 'stack' | 'maxStack' | 'rarity' | 'itemGroup'
>;

export type ConsumableItemFragment = { __typename?: 'ConsumableItem' } & Pick<
  ConsumableItem,
  'id' | 'itemId' | 'name' | 'stack' | 'maxStack' | 'rarity' | 'itemGroup'
>;

export type LootItemFragment = { __typename?: 'LootItem' } & Pick<
  LootItem,
  'id' | 'itemId' | 'stack' | 'maxStack' | 'rarity' | 'itemGroup'
>;

export type MaterialItemFragment = { __typename?: 'MaterialItem' } & Pick<
  MaterialItem,
  'id' | 'itemId' | 'stack' | 'maxStack' | 'rarity' | 'itemGroup'
>;

export type SpecialItemFragment = { __typename?: 'SpecialItem' } & Pick<
  SpecialItem,
  'id' | 'itemId' | 'stack' | 'maxStack' | 'rarity' | 'itemGroup'
>;

type Items_ConsumableItem_Fragment = {
  __typename: 'ConsumableItem';
} & ConsumableItemFragment;

type Items_ContainerItem_Fragment = {
  __typename: 'ContainerItem';
} & ContainerItemFragment;

type Items_EquipmentItem_Fragment = {
  __typename: 'EquipmentItem';
} & EquipmentItemFragment;

type Items_LootItem_Fragment = { __typename: 'LootItem' } & LootItemFragment;

type Items_MaterialItem_Fragment = {
  __typename: 'MaterialItem';
} & MaterialItemFragment;

type Items_SpecialItem_Fragment = {
  __typename: 'SpecialItem';
} & SpecialItemFragment;

export type ItemsFragment =
  | Items_ConsumableItem_Fragment
  | Items_ContainerItem_Fragment
  | Items_EquipmentItem_Fragment
  | Items_LootItem_Fragment
  | Items_MaterialItem_Fragment
  | Items_SpecialItem_Fragment;

export type InventoryItemsFragment = { __typename: 'ContainerItem' } & Pick<
  ContainerItem,
  'id'
> & {
    items: Array<
      | ({ __typename?: 'ConsumableItem' } & Items_ConsumableItem_Fragment)
      | ({ __typename?: 'ContainerItem' } & Items_ContainerItem_Fragment)
      | ({ __typename?: 'EquipmentItem' } & Items_EquipmentItem_Fragment)
      | ({ __typename?: 'LootItem' } & Items_LootItem_Fragment)
      | ({ __typename?: 'MaterialItem' } & Items_MaterialItem_Fragment)
      | ({ __typename?: 'SpecialItem' } & Items_SpecialItem_Fragment)
    >;
  };

export type QuestWaypointCompletionFragment = {
  __typename?: 'QuestWaypointCompletion';
} & Pick<QuestWaypointCompletion, 'id' | 'waypointName' | 'createdAt'> & {
    reward: Maybe<
      { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
    >;
  };

export type QuestFragment = { __typename?: 'Quest' } & Pick<
  Quest,
  'id' | 'questName' | 'createdAt'
> & {
    completedWaypoints: Array<
      {
        __typename?: 'QuestWaypointCompletion';
      } & QuestWaypointCompletionFragment
    >;
    reward: Maybe<
      { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
    >;
  };

export type SocialProfileFragment = { __typename: 'SocialProfile' } & Pick<
  SocialProfile,
  'id' | 'username' | 'avatar' | 'source'
>;

export type FurSessionFragment = { __typename?: 'FurSession' } & Pick<
  FurSession,
  'id' | 'accountId'
> & {
    oAuthToken: Maybe<{ __typename?: 'FurOAuthToken' } & FurOAuthTokenFragment>;
  };

export type ItemQuantityFragment = { __typename?: 'ItemQuantity' } & Pick<
  ItemQuantity,
  'itemId' | 'quantity'
>;

export type InventoryQuantitiesFragment = {
  __typename: 'ContainerItem';
} & Pick<ContainerItem, 'id' | 'totalDustCount'> & {
    itemQuantities: Array<
      { __typename?: 'ItemQuantity' } & ItemQuantityFragment
    >;
  };

export type PayoutFragment = { __typename?: 'PlayerPayout' } & Pick<
  PlayerPayout,
  | 'id'
  | 'ethAmount'
  | 'tixRatio'
  | 'payoutMethod'
  | 'transactionId'
  | 'createdAt'
>;

type FurPlayerMinimal_FurAccount_Fragment = {
  __typename?: 'FurAccount';
} & Pick<FurAccount, 'id' | 'socialName' | 'username' | 'avatar'>;

type FurPlayerMinimal_FurPlayer_Fragment = { __typename?: 'FurPlayer' } & Pick<
  FurPlayer,
  'id' | 'socialName' | 'username' | 'avatar'
>;

type FurPlayerMinimal_FurScholar_Fragment = {
  __typename?: 'FurScholar';
} & Pick<FurScholar, 'id' | 'socialName' | 'username' | 'avatar'>;

type FurPlayerMinimal_FurScholarDiscord_Fragment = {
  __typename?: 'FurScholarDiscord';
} & Pick<FurScholarDiscord, 'id' | 'socialName' | 'username' | 'avatar'>;

type FurPlayerMinimal_FurScholarEmail_Fragment = {
  __typename?: 'FurScholarEmail';
} & Pick<FurScholarEmail, 'id' | 'socialName' | 'username' | 'avatar'>;

export type FurPlayerMinimalFragment =
  | FurPlayerMinimal_FurAccount_Fragment
  | FurPlayerMinimal_FurPlayer_Fragment
  | FurPlayerMinimal_FurScholar_Fragment
  | FurPlayerMinimal_FurScholarDiscord_Fragment
  | FurPlayerMinimal_FurScholarEmail_Fragment;

export type FurPlayerBaseFragment = { __typename: 'FurPlayer' } & Pick<
  FurPlayer,
  | 'id'
  | 'socialName'
  | 'username'
  | 'avatar'
  | 'wFur'
  | 'tix'
  | 'waitlistFlags'
  | 'communityFlags'
  | 'bossBattleCount'
  | 'referralCount'
  | 'rentedFurballCount'
  | 'numRentalCancellationsOwner'
  | 'numRentalCancellationsPlayer'
  | 'createdAt'
>;

export type FurPlayerFragment = {
  __typename?: 'FurPlayer';
} & FurPlayerBaseFragment;

export type OtherPlayerFragment = { __typename?: 'FurPlayer' } & Pick<
  FurPlayer,
  'id'
> & {
    furballs: Array<{ __typename?: 'Furball' } & FurballFragment>;
  } & FurPlayerBaseFragment;

export type FurPlayerBalanceFragment = { __typename?: 'FurPlayer' } & Pick<
  FurPlayer,
  'tix' | 'tixBought' | 'tixSpent' | 'furBalance' | 'furballBalance'
> &
  FurPlayerBaseFragment;

export type OnboardingFragment = { __typename?: 'PlayerOnboardingStep' } & Pick<
  PlayerOnboardingStep,
  'id' | 'playerId' | 'sequence' | 'stepProgress' | 'isComplete' | 'createdAt'
>;

export type CurrentPlayerFragment = { __typename?: 'FurPlayer' } & {
  payouts: Array<{ __typename?: 'PlayerPayout' } & PayoutFragment>;
  craftingSlots: Array<{ __typename?: 'CraftSlot' } & CraftSlotFragment>;
  inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
  quests: Array<{ __typename?: 'Quest' } & QuestFragment>;
  socialProfiles: Array<
    { __typename?: 'SocialProfile' } & SocialProfileFragment
  >;
  onboarding: Array<
    { __typename?: 'PlayerOnboardingStep' } & OnboardingFragment
  >;
} & FurPlayerBalanceFragment;

export type PlayerInventoryQuantityFragment = {
  __typename?: 'FurPlayer';
} & Pick<FurPlayer, 'id'> & {
    inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
  };

export type FurOAuthTokenFragment = { __typename?: 'FurOAuthToken' } & Pick<
  FurOAuthToken,
  'id' | 'owner' | 'access' | 'deadline' | 'signature'
>;

export type PlayerSessionFragment = { __typename?: 'FurSession' } & Pick<
  FurSession,
  'id' | 'accountId'
> & {
    oAuthToken: Maybe<
      { __typename?: 'FurOAuthToken' } & Pick<
        FurOAuthToken,
        'id' | 'owner' | 'signature' | 'access' | 'deadline'
      >
    >;
  };

export type LoginResponseFragment = { __typename?: 'LoginResponse' } & Pick<
  LoginResponse,
  'secret'
> & { session: { __typename?: 'FurSession' } & PlayerSessionFragment };

export type LoginMutationVariables = Exact<{
  token: FurOAuthTokenInput;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'LoginResponse' } & LoginResponseFragment;
};

export type DisconnectSocialProfileMutationVariables = Exact<{
  token: FurOAuthTokenInput;
}>;

export type DisconnectSocialProfileMutation = { __typename?: 'Mutation' } & {
  disconnectSocialProfile: { __typename?: 'FurAccount' } & Pick<
    FurAccount,
    'id'
  >;
};

export type MeQueryVariables = Exact<{
  maxCache: Maybe<Scalars['Int']>;
}>;

export type MeQuery = { __typename?: 'Query' } & {
  currentPlayer: { __typename?: 'FurPlayer' } & CurrentPlayerFragment;
};

export type MyFurballsQueryVariables = Exact<{ [key: string]: never }>;

export type MyFurballsQuery = { __typename?: 'Query' } & {
  usableFurballs: Array<{ __typename?: 'Furball' } & FurballFragment>;
};

export type PlayerQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type PlayerQuery = { __typename?: 'Query' } & {
  player: { __typename?: 'FurPlayer' } & {
    furballs: Array<{ __typename?: 'Furball' } & FurballFragment>;
  } & OtherPlayerFragment;
};

export type ScholarQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type ScholarQuery = { __typename?: 'Query' } & {
  scholar: { __typename?: 'FurPlayer' } & OtherPlayerFragment;
};

export type GivePendingRewardMutationVariables = Exact<{
  playerId: Maybe<Scalars['String']>;
  notificationTitle: Maybe<Scalars['String']>;
  rewards: Array<PendingRewardRequestInput> | PendingRewardRequestInput;
}>;

export type GivePendingRewardMutation = { __typename?: 'Mutation' } & {
  givePendingReward: { __typename?: 'PlayerPendingReward' } & Pick<
    PlayerPendingReward,
    'id'
  >;
};

export type UpgradeFurballMutationVariables = Exact<{
  furballId: Scalars['String'];
  signature: Maybe<Scalars['String']>;
}>;

export type UpgradeFurballMutation = { __typename?: 'Mutation' } & {
  upgradeFurball: { __typename?: 'Furball' } & FurballFragment;
};

export type DutchAuctionFragment = { __typename?: 'DutchAuction' } & Pick<
  DutchAuction,
  | 'id'
  | 'playerId'
  | 'currentCost'
  | 'startingCost'
  | 'lastCost'
  | 'soldAt'
  | 'sequence'
>;

export type LoadMintAuctionQueryVariables = Exact<{ [key: string]: never }>;

export type LoadMintAuctionQuery = { __typename?: 'Query' } & {
  dutchAuction: { __typename?: 'DutchAuction' } & DutchAuctionFragment;
};

export type BidMintAuctionMutationVariables = Exact<{ [key: string]: never }>;

export type BidMintAuctionMutation = { __typename?: 'Mutation' } & {
  bidAuction: { __typename?: 'DutchAuctionResult' } & Pick<
    DutchAuctionResult,
    'outcome'
  > & { auction: { __typename?: 'DutchAuction' } & DutchAuctionFragment };
};

export type TimekeeperWrapFurFragment = {
  __typename?: 'TimekeeperWrapFur';
} & Pick<
  TimekeeperWrapFur,
  | 'id'
  | 'fuelCost'
  | 'isAccepted'
  | 'hasRun'
  | 'gained'
  | 'spent'
  | 'value'
  | 'resolvedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'failureCount'
  | 'transactionHash'
>;

export type PendingFurWrapsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingFurWrapsQuery = { __typename?: 'Query' } & {
  currentPlayer: { __typename?: 'FurPlayer' } & Pick<FurPlayer, 'id'> & {
      pendingWraps: Array<
        { __typename?: 'TimekeeperWrapFur' } & TimekeeperWrapFurFragment
      >;
    };
};

export type GameBuildFragment = { __typename?: 'GameBuild' } & Pick<
  GameBuild,
  | 'id'
  | 'webCodeFileHash'
  | 'webDataFileHash'
  | 'webFrameworkFileHash'
  | 'semver'
  | 'createdAt'
>;

export type TournamentParticipantFragment = {
  __typename?: 'TournamentParticipant';
} & Pick<
  TournamentParticipant,
  'id' | 'playerId' | 'wFurEarned' | 'totalScore'
>;

export type TournamentFragment = { __typename?: 'Tournament' } & Pick<
  Tournament,
  | 'id'
  | 'name'
  | 'realm'
  | 'scoringStyle'
  | 'startsAt'
  | 'endsAt'
  | 'entryFeeFuel'
  | 'entryFeeTix'
  | 'payoutPoolEth'
  | 'heroUrl'
  | 'backgroundColor'
  | 'isParticipant'
> & {
    leaderboard: { __typename?: 'Leaderboard' } & LeaderboardFragment;
    currentParticipant: Maybe<
      { __typename?: 'TournamentParticipant' } & TournamentParticipantFragment
    >;
  };

export type FurballsMetaFragment = { __typename?: 'FurballsMeta' } & Pick<
  FurballsMeta,
  | 'buildNumber'
  | 'environment'
  | 'hostname'
  | 'subdomain'
  | 'mintAuctionPurchaseCount'
  | 'ethPrice'
> & {
    gameBuild: { __typename?: 'GameBuild' } & GameBuildFragment;
    activeTournaments: Array<
      { __typename?: 'Tournament' } & TournamentFragment
    >;
    timekeeperUserState: Maybe<
      { __typename?: 'TimekeeperUserState' } & TimekeeperUserStateFragment
    >;
    mintAuction: Maybe<{ __typename?: 'DutchAuction' } & DutchAuctionFragment>;
    lootBox: { __typename?: 'LootBox' } & Pick<LootBox, 'myLastPurchaseAt'>;
    clientSpec: { __typename?: 'ClientSpecification' } & Pick<
      ClientSpecification,
      'referenceDpi'
    >;
  };

export type FurballsMetaQueryVariables = Exact<{ [key: string]: never }>;

export type FurballsMetaQuery = { __typename?: 'Query' } & {
  meta: { __typename?: 'FurballsMeta' } & FurballsMetaFragment;
};

export type TakePayoutMutationVariables = Exact<{
  id: Scalars['String'];
  method: PayoutMethod;
}>;

export type TakePayoutMutation = { __typename?: 'Mutation' } & {
  takePayout: { __typename?: 'FurPlayer' } & Pick<FurPlayer, 'id'>;
};

export type MyPriorRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type MyPriorRewardsQuery = { __typename?: 'Query' } & {
  priorRewards: Maybe<
    { __typename?: 'PriorRewardsConnection' } & {
      nodes: Maybe<
        Array<
          { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
    }
  >;
};

export type BuyLootBoxMutationVariables = Exact<{
  useTix: Scalars['Boolean'];
}>;

export type BuyLootBoxMutation = { __typename?: 'Mutation' } & {
  buyLootBox: { __typename?: 'PlayerPendingReward' } & {
    player: { __typename?: 'FurPlayer' } & CurrentPlayerFragment;
  } & PlayerPendingRewardFragment;
};

export type LoginEmailMutationVariables = Exact<{
  email: Scalars['String'];
  code: Maybe<Scalars['String']>;
}>;

export type LoginEmailMutation = { __typename?: 'Mutation' } & {
  loginEmail: { __typename?: 'LoginEmailResponse' } & Pick<
    LoginEmailResponse,
    'outcome' | 'secret'
  > & {
      session: Maybe<{ __typename?: 'FurSession' } & PlayerSessionFragment>;
      currentPlayer: Maybe<
        { __typename?: 'FurPlayer' } & Pick<FurPlayer, 'id'>
      >;
    };
};

export type VerifyReceiptMutationVariables = Exact<{
  receiptData: Scalars['String'];
  platform: Scalars['String'];
}>;

export type VerifyReceiptMutation = { __typename?: 'Mutation' } & {
  verifyReceipt: { __typename?: 'FurPlayer' } & CurrentPlayerFragment;
};

export type FurQuoteFragment = { __typename?: 'FurFundingLot' } & Pick<
  FurFundingLot,
  | 'id'
  | 'total'
  | 'funding'
  | 'amountRemaining'
  | 'fuelCostEach'
  | 'minimumPurchaseQuantity'
>;

export type CurrencyCostFragment = { __typename?: 'CurrencyCost' } & Pick<
  CurrencyCost,
  'fuel' | 'tix' | 'usd'
>;

export type CurrencyCostsFragment = { __typename?: 'CurrencyCosts' } & {
  costBase: { __typename?: 'CurrencyCost' } & CurrencyCostFragment;
  costEach: { __typename?: 'CurrencyCost' } & CurrencyCostFragment;
};

export type EthExchangeFragment = { __typename?: 'EthExchange' } & Pick<
  EthExchange,
  'usdPrice'
>;

export type FurExchangeFragment = { __typename?: 'FurExchange' } & Pick<
  FurExchange,
  'amountAvailable'
> & {
    costBuy: { __typename?: 'CurrencyCosts' } & CurrencyCostsFragment;
    costSwap: { __typename?: 'CurrencyCosts' } & CurrencyCostsFragment;
  };

export type TixExchangeFragment = { __typename?: 'TixExchange' } & {
  costBuy: { __typename?: 'CurrencyCosts' } & CurrencyCostsFragment;
};

export type ExchangeRatesFragment = {
  __typename?: 'CurrencyExchangeRates';
} & Pick<CurrencyExchangeRates, 'gasPrice'> & {
    eth: { __typename?: 'EthExchange' } & EthExchangeFragment;
    fur: { __typename?: 'FurExchange' } & FurExchangeFragment;
    tix: { __typename?: 'TixExchange' } & TixExchangeFragment;
  };

export type ExchangeRatesQueryVariables = Exact<{ [key: string]: never }>;

export type ExchangeRatesQuery = { __typename?: 'Query' } & {
  exchangeRates: {
    __typename?: 'CurrencyExchangeRates';
  } & ExchangeRatesFragment;
};

export type PurchaseFurMutationVariables = Exact<{
  fuel: Scalars['Long'];
}>;

export type PurchaseFurMutation = { __typename?: 'Mutation' } & {
  purchaseFur: { __typename?: 'FurPurchasePlayer' } & Pick<
    FurPurchasePlayer,
    'id' | 'quantity' | 'tixCost'
  >;
};

export type SearchResultRentalAgreementFragment = {
  __typename?: 'RentalAgreement';
} & Pick<RentalAgreement, 'id' | 'playerId'>;

export type FurRentalAgreementFragment = {
  __typename?: 'RentalAgreement';
} & Pick<
  RentalAgreement,
  | 'id'
  | 'startsAt'
  | 'duration'
  | 'autoRenew'
  | 'percent'
  | 'rewardCount'
  | 'playerId'
  | 'beganAt'
  | 'endedAt'
  | 'signature'
  | 'furballId'
  | 'wFurEarned'
  | 'pendingCancellation'
> & {
    ownerPlayer: {
      __typename?: 'FurPlayer';
    } & FurPlayerMinimal_FurPlayer_Fragment;
    renterPlayer: Maybe<
      { __typename?: 'FurPlayer' } & FurPlayerMinimal_FurPlayer_Fragment
    >;
  };

export type FurRentalAgreementRewardsFragment = {
  __typename?: 'RentalAgreement';
} & {
  rewards: Array<
    { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
  >;
} & FurRentalAgreementFragment;

export type RentalAgreementQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type RentalAgreementQuery = { __typename?: 'Query' } & {
  rentalAgreement: {
    __typename?: 'RentalAgreement';
  } & FurRentalAgreementRewardsFragment;
};

export type BattleHistoryPartyFragment = { __typename?: 'GameParty' } & Pick<
  GameParty,
  'id' | 'name' | 'size'
>;

export type BattleHistoryWorldBossFragment = { __typename?: 'GameBoss' } & Pick<
  GameBoss,
  'id' | 'name' | 'level' | 'realm'
>;

export type BossBattleHistoryPlayerFragment = {
  __typename?: 'GameBattleBoss';
} & Pick<
  GameBattleBoss,
  'id' | 'score' | 'furballIds' | 'isTrialGame' | 'duration' | 'createdAt'
> & {
    playerParty: { __typename?: 'GameParty' } & BattleHistoryPartyFragment;
    worldBoss: { __typename?: 'GameBoss' } & BattleHistoryWorldBossFragment;
  };

export type HistoryDaysFragment = { __typename?: 'PlayerHistoryDay' } & Pick<
  PlayerHistoryDay,
  | 'id'
  | 'leaderboardTotalScore'
  | 'percentileRankOverall'
  | 'percentileRankTop'
  | 'startDate'
  | 'nextDate'
> & {
    bossBattles: Array<
      { __typename?: 'GameBattleBoss' } & BossBattleHistoryPlayerFragment
    >;
  };

export type HistoryFragment = { __typename?: 'PlayerHistoryConnection' } & Pick<
  PlayerHistoryConnection,
  'totalCount'
> & {
    nodes: Maybe<
      Array<{ __typename?: 'PlayerHistoryDay' } & HistoryDaysFragment>
    >;
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
  };

export type BossBattleHistoryPlayerQueryVariables = Exact<{
  playerId: Scalars['String'];
}>;

export type BossBattleHistoryPlayerQuery = { __typename?: 'Query' } & {
  playerHistory: Maybe<
    { __typename?: 'PlayerHistoryConnection' } & HistoryFragment
  >;
};

export type BossLeaderboardSlotFragment = {
  __typename?: 'LeaderboardSlot';
} & Pick<LeaderboardSlot, 'stat' | 'score' | 'position'> & {
    players: Array<{ __typename?: 'FurPlayer' } & FurPlayerBaseFragment>;
  };

export type JoinTournamentMutationVariables = Exact<{
  tournamentId: Scalars['String'];
}>;

export type JoinTournamentMutation = { __typename?: 'Mutation' } & {
  joinTournament: { __typename?: 'Tournament' } & TournamentFragment;
};

export type BossLeaderboardSlotConnectionFragment = {
  __typename?: 'SlotsConnection';
} & Pick<SlotsConnection, 'totalCount'> & {
    nodes: Maybe<
      Array<{ __typename?: 'LeaderboardSlot' } & BossLeaderboardSlotFragment>
    >;
    pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
  };

export type LeaderboardTierRewardChanceFragment = {
  __typename?: 'LeaderboardTierRewardChance';
} & Pick<
  LeaderboardTierRewardChance,
  'rewardId' | 'rewardType' | 'rewardQuantity' | 'percentChance'
>;

export type TierRewardsFragment = {
  __typename?: 'LeaderboardTierRewards';
} & Pick<
  LeaderboardTierRewards,
  'id' | 'bossDustAmount' | 'furAmount' | 'poolSplit' | 'entityMax'
> & {
    possibleRewards: Array<
      {
        __typename?: 'LeaderboardTierRewardChance';
      } & LeaderboardTierRewardChanceFragment
    >;
  };

export type BossLeaderboardTiersConnectionFragment = {
  __typename?: 'TiersConnection';
} & {
  nodes: Maybe<
    Array<
      { __typename?: 'LeaderboardTier' } & Pick<
        LeaderboardTier,
        | 'id'
        | 'position'
        | 'entityCount'
        | 'entityMax'
        | 'scoreMax'
        | 'scoreMin'
      > & {
          rewards: {
            __typename?: 'LeaderboardTierRewards';
          } & TierRewardsFragment;
          slots: Array<
            { __typename?: 'LeaderboardSlot' } & BossLeaderboardSlotFragment
          >;
        }
    >
  >;
};

export type LeaderboardFragment = { __typename?: 'Leaderboard' } & Pick<
  Leaderboard,
  'id'
> & {
    rewards: Array<
      { __typename?: 'LeaderboardTierRewards' } & TierRewardsFragment
    >;
    tiers: Maybe<
      {
        __typename?: 'TiersConnection';
      } & BossLeaderboardTiersConnectionFragment
    >;
  };

export type LeaderboardSlotPlayerFragment = {
  __typename?: 'LeaderboardSlotPlayer';
} & Pick<LeaderboardSlotPlayer, 'leaderGroup' | 'score' | 'stat' | 'timeframe'>;

export type LeaderboardRankPlayerFragment = {
  __typename?: 'LeaderboardRankPlayer';
} & Pick<LeaderboardRankPlayer, 'id'> & {
    slot: {
      __typename?: 'LeaderboardSlotPlayer';
    } & LeaderboardSlotPlayerFragment;
  };

export type BossLeaderboardPlayerBestScoreGroupQueryVariables = Exact<{
  playerId: Scalars['String'];
}>;

export type BossLeaderboardPlayerBestScoreGroupQuery = {
  __typename?: 'Query';
} & {
  leaderboardRanksPlayer: Array<
    { __typename?: 'LeaderboardRankPlayer' } & LeaderboardRankPlayerFragment
  >;
};

export type BossLeaderboardQueryVariables = Exact<{
  boss: LeaderboardStat;
  timeframe: LeaderboardTimeframe;
  group: LeaderboardGroup;
}>;

export type BossLeaderboardQuery = { __typename?: 'Query' } & {
  leaderboards: Array<{ __typename?: 'Leaderboard' } & LeaderboardFragment>;
};

export type ClaimPendingRewardsMutationVariables = Exact<{
  pendingRewardId: Scalars['String'];
}>;

export type ClaimPendingRewardsMutation = { __typename?: 'Mutation' } & {
  claimPendingReward: { __typename?: 'FurPlayer' } & {
    pendingRewards: Array<
      { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
    >;
  } & PlayerInventoryQuantityFragment;
};

export type LoadPendingRewardsQueryVariables = Exact<{ [key: string]: never }>;

export type LoadPendingRewardsQuery = { __typename?: 'Query' } & {
  currentPlayer: { __typename: 'FurPlayer' } & Pick<FurPlayer, 'id'> & {
      pendingRewards: Array<
        { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
      >;
      inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
    };
};

export type PlayerPendingRewardFragment = {
  __typename?: 'PlayerPendingReward';
} & Pick<
  PlayerPendingReward,
  | 'id'
  | 'expiresAt'
  | 'source'
  | 'sourcePosition'
  | 'notificationId'
  | 'claimedAt'
  | 'createdAt'
> & {
    items: Array<
      | ({ __typename?: 'ConsumableItem' } & Items_ConsumableItem_Fragment)
      | ({ __typename?: 'ContainerItem' } & Items_ContainerItem_Fragment)
      | ({ __typename?: 'EquipmentItem' } & Items_EquipmentItem_Fragment)
      | ({ __typename?: 'LootItem' } & Items_LootItem_Fragment)
      | ({ __typename?: 'MaterialItem' } & Items_MaterialItem_Fragment)
      | ({ __typename?: 'SpecialItem' } & Items_SpecialItem_Fragment)
    >;
  };

export type GameEntitySkillFragment = { __typename?: 'EntitySkill' } & Pick<
  EntitySkill,
  | 'id'
  | 'level'
  | 'useCount'
  | 'slotIndex'
  | 'maxUsesBoost'
  | 'skillDefinitionId'
>;

export type BattleEffectPassiveFragment = {
  __typename?: 'BattleEffectPassive';
} & Pick<BattleEffectPassive, 'id' | 'value' | 'operand' | 'stat'>;

export type GameEntityEffectFragment = { __typename?: 'EntityEffect' } & Pick<
  EntityEffect,
  | 'id'
  | 'value'
  | 'turnsRemaining'
  | 'createdAt'
  | 'deductAt'
  | 'activeAt'
  | 'removedAt'
  | 'skillId'
  | 'skillEffectId'
>;

export type NextMoveFragment = { __typename?: 'BattleMoveNext' } & Pick<
  BattleMoveNext,
  'id' | 'moveKind' | 'moveId' | 'targetIds' | 'entityMoveNumber' | 'createdAt'
>;

export type BattleStatsFragment = { __typename?: 'BattleStats' } & Pick<
  BattleStats,
  | 'maxHealth'
  | 'attackPower'
  | 'defencePower'
  | 'heal'
  | 'buff'
  | 'deBuff'
  | 'critRate'
  | 'critAttackPower'
  | 'speed'
  | 'vamp'
  | 'revive'
>;

export type BattleStatEntityFragment = { __typename?: 'StatEntity' } & Pick<
  StatEntity,
  | 'id'
  | 'curHealth'
  | 'maxHealth'
  | 'attackPower'
  | 'defencePower'
  | 'heal'
  | 'buff'
  | 'deBuff'
  | 'critRate'
  | 'critAttackPower'
  | 'speed'
  | 'vamp'
  | 'revive'
  | 'position'
  | 'nextMoveAt'
  | 'lastMoveAt'
  | 'pauseDuration'
  | 'pausedAt'
  | 'clockPausedUntil'
  | 'moveCount'
  | 'karma'
  | 'currentRealm'
  | 'partyId'
  | 'hearts'
  | 'loadedAt'
> & {
    nextMove: Maybe<{ __typename?: 'BattleMoveNext' } & NextMoveFragment>;
    currentStats: { __typename?: 'BattleStats' } & BattleStatsFragment;
    effects: Array<{ __typename?: 'EntityEffect' } & GameEntityEffectFragment>;
  };

export type GamePartyFragment = { __typename?: 'GameParty' } & Pick<
  GameParty,
  'id' | 'playerId' | 'name' | 'aiEnabled'
> & {
    partyMembers: Array<
      { __typename?: 'StatEntity' } & BattleStatEntityFragment
    >;
  };

export type GameBossFragment = { __typename?: 'GameBoss' } & Pick<
  GameBoss,
  'id' | 'realm' | 'level' | 'name'
>;

export type MonsterBattleFragment = {
  __typename?: 'GameBattleMonsters';
} & Pick<
  GameBattleMonsters,
  | 'id'
  | 'playerId'
  | 'furEarned'
  | 'battleState'
  | 'score'
  | 'combo'
  | 'leaderboardGroup'
  | 'ntp'
  | 'difficultyLevel'
  | 'battleRealm'
  | 'monstersKilled'
> & {
    parties: Array<
      { __typename?: 'GameParty' } & Pick<GameParty, 'id'> & {
          partyMembers: Array<
            { __typename?: 'StatEntity' } & BattleStatEntityFragment
          >;
        }
    >;
    outcomeSummaries: Array<
      { __typename?: 'GameEntitySummary' } & GameEntitySummaryFragment
    >;
    itemQuantitiesEarned: Array<
      { __typename?: 'ItemQuantity' } & ItemQuantityFragment
    >;
  };

export type AnteFragment = { __typename?: 'AntePool' } & Pick<
  AntePool,
  'id' | 'furAmount' | 'furPayoutId'
>;

export type GameBattleRulesFragment = { __typename?: 'GameBattleRules' } & Pick<
  GameBattleRules,
  'id' | 'maxLevel' | 'maxSkillUpgradePercent'
>;

export type DuelBattleFragment = { __typename?: 'GameBattleDuel' } & Pick<
  GameBattleDuel,
  | 'id'
  | 'creatorId'
  | 'battleState'
  | 'score'
  | 'combo'
  | 'leaderboardGroup'
  | 'ntp'
  | 'battleRealm'
> & {
    parties: Array<
      { __typename?: 'GameParty' } & Pick<GameParty, 'id'> & {
          partyMembers: Array<
            { __typename?: 'StatEntity' } & BattleStatEntityFragment
          >;
        }
    >;
    outcomeSummaries: Array<
      { __typename?: 'GameEntitySummary' } & GameEntitySummaryFragment
    >;
  };

export type BossBattleFragment = { __typename?: 'GameBattleBoss' } & Pick<
  GameBattleBoss,
  | 'id'
  | 'playerId'
  | 'furEarned'
  | 'battleState'
  | 'score'
  | 'combo'
  | 'leaderboardGroup'
  | 'ntp'
> & {
    parties: Array<
      { __typename?: 'GameParty' } & Pick<GameParty, 'id'> & {
          partyMembers: Array<
            { __typename?: 'StatEntity' } & BattleStatEntityFragment
          >;
        }
    >;
    outcomeSummaries: Array<
      { __typename?: 'GameEntitySummary' } & GameEntitySummaryFragment
    >;
  };

export type BattleMoveOutcomeFragment = {
  __typename?: 'BattleMoveOutcome';
} & Pick<
  BattleMoveOutcome,
  'id' | 'value' | 'operand' | 'stat' | 'flags' | 'entityId'
> & {
    effect: Maybe<{ __typename?: 'EntityEffect' } & GameEntityEffectFragment>;
  };

export type BattleMoveFragment = { __typename?: 'BattleMove' } & Pick<
  BattleMove,
  | 'id'
  | 'battleState'
  | 'moveKind'
  | 'actorId'
  | 'skillId'
  | 'itemId'
  | 'createdAt'
  | 'expGained'
  | 'levelsGained'
  | 'startsAt'
  | 'endsAt'
  | 'updatedAt'
  | 'ranAt'
  | 'combo'
  | 'entityMoveNumber'
  | 'ntp'
  | 'loadedAt'
> & {
    actor: { __typename?: 'StatEntity' } & Pick<StatEntity, 'id'> & {
        skills: Array<{ __typename?: 'EntitySkill' } & GameEntitySkillFragment>;
      };
    skill: Maybe<{ __typename?: 'EntitySkill' } & GameEntitySkillFragment>;
    outcomes: Array<
      { __typename?: 'BattleMoveOutcome' } & BattleMoveOutcomeFragment
    >;
  };

export type PercentilesFragment = { __typename?: 'BattlePercentiles' } & Pick<
  BattlePercentiles,
  'minScore' | 'maxScore' | 'percentile' | 'position'
>;

export type ActiveBossFightFragment = { __typename?: 'GameBattleBoss' } & Pick<
  GameBattleBoss,
  'id' | 'score'
> & {
    player: { __typename: 'FurPlayer' } & FurPlayerMinimal_FurPlayer_Fragment;
  };

export type BattleMoveResponseFragment = {
  __typename?: 'BattleMoveResponse';
} & Pick<BattleMoveResponse, 'createdAt'> & {
    bossBattle: Maybe<{ __typename?: 'GameBattleBoss' } & BossBattleFragment>;
    monsterBattle: Maybe<
      { __typename?: 'GameBattleMonsters' } & MonsterBattleFragment
    >;
    duelBattle: Maybe<{ __typename?: 'GameBattleDuel' } & DuelBattleFragment>;
    move: Maybe<{ __typename?: 'BattleMove' } & BattleMoveFragment>;
    percentiles: { __typename?: 'BattlePercentiles' } & PercentilesFragment;
    activeBossBattles: Array<
      { __typename?: 'GameBattleBoss' } & ActiveBossFightFragment
    >;
    consumableItems: Array<
      { __typename?: 'ConsumableItem' } & ConsumableItemFragment
    >;
  };

export type GameBattleLoopSubscriptionVariables = Exact<{
  gameBattleId: Scalars['String'];
}>;

export type GameBattleLoopSubscription = { __typename?: 'Subscription' } & {
  gameBattleLoop: {
    __typename?: 'BattleMoveResponse';
  } & BattleMoveResponseFragment;
};

export type CreateDuelBattleMutationVariables = Exact<{
  partyId: Scalars['String'];
  ante: Maybe<AnteInput>;
  rules: Maybe<GamePlayRulesInput>;
}>;

export type CreateDuelBattleMutation = { __typename?: 'Mutation' } & {
  createDuelBattle: { __typename?: 'GameBattleDuel' } & DuelBattleFragment;
};

export type BossBattlePlayerOverviewFragment = {
  __typename?: 'GameBattleBoss';
} & Pick<GameBattleBoss, 'id' | 'score' | 'createdAt' | 'furballIds'> & {
    player: { __typename?: 'FurPlayer' } & FurPlayerBaseFragment;
  };

export type BossBattlePlayerFragment = { __typename?: 'GameBattleBoss' } & Pick<
  GameBattleBoss,
  'playerId' | 'furEarned' | 'playerDamageTotal'
> & {
    worldBoss: { __typename?: 'GameBoss' } & GameBossFragment;
  } & BossBattlePlayerOverviewFragment;

export type BossFragment = { __typename?: 'GameBoss' } & Pick<
  GameBoss,
  'id' | 'name' | 'realm' | 'level' | 'health' | 'maxHealth'
>;

export type PageInfoFragment = { __typename?: 'PageInfo' } & Pick<
  PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export type TopBossBattlesQueryVariables = Exact<{ [key: string]: never }>;

export type TopBossBattlesQuery = { __typename?: 'Query' } & {
  topBossBattles: Array<
    { __typename?: 'RecentBossBattles' } & {
      battles: Maybe<
        { __typename?: 'BattlesConnection' } & Pick<
          BattlesConnection,
          'totalCount'
        > & {
            nodes: Maybe<
              Array<
                {
                  __typename?: 'GameBattleBoss';
                } & BossBattlePlayerOverviewFragment
              >
            >;
            pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
          }
      >;
      boss: { __typename?: 'GameBoss' } & BossFragment;
    }
  >;
};

export type BossBattlesQueryVariables = Exact<{
  endCursor: Scalars['String'];
  realm: RealmType;
}>;

export type BossBattlesQuery = { __typename?: 'Query' } & {
  bossBattles: Maybe<
    { __typename?: 'BossBattlesConnection' } & Pick<
      BossBattlesConnection,
      'totalCount'
    > & {
        nodes: Maybe<
          Array<
            { __typename?: 'GameBattleBoss' } & BossBattlePlayerOverviewFragment
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
      }
  >;
};

export type IngredientFragment = {
  __typename?: 'CraftRecipeIngredient';
} & Pick<CraftRecipeIngredient, 'itemType' | 'rarity' | 'amount' | 'realm'>;

export type RecipeFragment = { __typename?: 'CraftingRecipe' } & Pick<
  CraftingRecipe,
  'id' | 'duration' | 'itemId'
> & {
    ingredients: Array<
      { __typename?: 'CraftRecipeIngredient' } & IngredientFragment
    >;
  };

export type PassiveEffectRangeFragment = {
  __typename?: 'PassiveEffectRange';
} & Pick<PassiveEffectRange, 'valueBase' | 'valueRange' | 'operand' | 'stat'>;

export type ItemDefinitionFragment = { __typename?: 'ItemDefinition' } & Pick<
  ItemDefinition,
  | 'id'
  | 'icon'
  | 'itemId'
  | 'itemGroup'
  | 'durabilityTime'
  | 'name'
  | 'rarity'
  | 'realmAffiliation'
  | 'untradeable'
  | 'allowInFurballInventory'
  | 'upgradeToItemId'
> & {
    passives: Array<
      { __typename?: 'PassiveEffectRange' } & Pick<
        PassiveEffectRange,
        'valueBase' | 'valueRange' | 'operand' | 'stat'
      >
    >;
    recipe: Maybe<{ __typename?: 'CraftingRecipe' } & RecipeFragment>;
  };

export type CraftingAttemptFragment = { __typename?: 'CraftingAttempt' } & Pick<
  CraftingAttempt,
  | 'id'
  | 'completesAt'
  | 'createdAt'
  | 'duration'
  | 'isComplete'
  | 'speedUpCostPerHour'
  | 'speedUpDuration'
  | 'playerId'
  | 'recipeId'
  | 'pendingRewardId'
> & {
    recipe: { __typename?: 'CraftingRecipe' } & RecipeFragment;
    pendingReward: Maybe<
      { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
    >;
  };

export type CraftSlotBaseFragment = { __typename?: 'CraftSlot' } & Pick<
  CraftSlot,
  | 'id'
  | 'crafterId'
  | 'isActive'
  | 'isLocked'
  | 'slotIndex'
  | 'fuelCost'
  | 'unlockCost'
  | 'unlockedAt'
>;

export type CraftSlotFragment = { __typename?: 'CraftSlot' } & {
  currentAttempt: Maybe<
    { __typename?: 'CraftingAttempt' } & CraftingAttemptFragment
  >;
} & CraftSlotBaseFragment;

export type CraftingAttemptChangeFragment = {
  __typename?: 'CraftingAttempt';
} & {
  player: { __typename?: 'FurPlayer' } & Pick<FurPlayer, 'id'> & {
      inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
    };
} & CraftingAttemptFragment;

export type CraftSlotChangeFragment = { __typename?: 'CraftSlot' } & {
  currentAttempt: Maybe<
    { __typename?: 'CraftingAttempt' } & CraftingAttemptChangeFragment
  >;
} & CraftSlotBaseFragment;

export type CraftRecipeMutationVariables = Exact<{
  slotIndex: Scalars['Int'];
  unlockSlot: Scalars['Boolean'];
  recipeId: Maybe<Scalars['String']>;
  useTix: Scalars['Boolean'];
}>;

export type CraftRecipeMutation = { __typename?: 'Mutation' } & {
  craftRecipe: { __typename?: 'CraftSlot' } & CraftSlotChangeFragment;
};

export type ClaimCraftedItemMutationVariables = Exact<{
  pendingRewardId: Scalars['String'];
}>;

export type ClaimCraftedItemMutation = { __typename?: 'Mutation' } & {
  claimPendingReward: { __typename?: 'FurPlayer' } & {
    craftingSlots: Array<
      { __typename?: 'CraftSlot' } & CraftSlotChangeFragment
    >;
    inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
  };
};

export type CraftFasterMutationVariables = Exact<{
  craftingAttemptId: Scalars['String'];
  numHours: Scalars['Int'];
}>;

export type CraftFasterMutation = { __typename?: 'Mutation' } & {
  craftFaster: { __typename?: 'CraftSlot' } & CraftSlotChangeFragment;
};

export type CraftingSlotsPendingRewardQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CraftingSlotsPendingRewardQuery = { __typename?: 'Query' } & {
  currentPlayer: { __typename?: 'FurPlayer' } & Pick<FurPlayer, 'id'> & {
      craftingSlots: Array<
        { __typename?: 'CraftSlot' } & Pick<CraftSlot, 'id'> & {
            currentAttempt: Maybe<
              { __typename?: 'CraftingAttempt' } & Pick<
                CraftingAttempt,
                'id'
              > & {
                  pendingReward: Maybe<
                    { __typename?: 'PlayerPendingReward' } & Pick<
                      PlayerPendingReward,
                      'id' | 'notificationId'
                    >
                  >;
                }
            >;
          }
      >;
    };
};

export type FurballDefinitionFragment = {
  __typename?: 'FurballDefinition';
} & Pick<
  FurballDefinition,
  | 'id'
  | 'number'
  | 'count'
  | 'rarity'
  | 'experience'
  | 'zone'
  | 'level'
  | 'weight'
  | 'birth'
  | 'trade'
  | 'last'
  | 'moves'
>;

export type FurballAttributeFragment = {
  __typename?: 'FurballAttribute';
} & Pick<FurballAttribute, 'traitType' | 'displayType' | 'value'>;

export type EditionAssetFragment = { __typename: 'EditionAsset' } & Pick<
  EditionAsset,
  | 'id'
  | 'assetIndex'
  | 'name'
  | 'slot'
  | 'rarityLevel'
  | 'percentBoost'
  | 'realmAffiliation'
>;

export type FurballStateFragment = { __typename?: 'FurballState' } & Pick<
  FurballState,
  | 'id'
  | 'totalRarity'
  | 'totalExp'
  | 'furRate'
  | 'furRateBase'
  | 'expRate'
  | 'happiness'
  | 'bonded'
  | 'bondingDays'
  | 'teamSize'
  | 'expPercentBase'
  | 'furPercentBase'
>;

export type FurballBattleStatsFragment = { __typename?: 'StatEntity' } & Pick<
  StatEntity,
  'id'
> & {
    elixir: Maybe<{ __typename?: 'ConsumableItem' } & ConsumableItemFragment>;
    skills: Array<{ __typename?: 'EntitySkill' } & EntitySkillFragment>;
  } & EntityBattleStatsFragment;

export type FurballCoreInfoFragment = { __typename?: 'Furball' } & Pick<
  Furball,
  | 'id'
  | 'tokenId'
  | 'partyId'
  | 'ownerId'
  | 'specialization'
  | 'dailyBattleCount'
  | 'maxDailyBattles'
  | 'name'
  | 'level'
  | 'maxLevel'
  | 'maxExp'
  | 'expRate'
  | 'furRate'
  | 'backgroundColor'
  | 'zone'
  | 'wExp'
  | 'karma'
  | 'marketListingCount'
  | 'battleCount'
  | 'lastBattleAt'
  | 'hearts'
  | 'lastHeartLostAt'
  | 'skillRollCost'
  | 'skillUpgradesAvailable'
  | 'pendingExp'
  | 'pendingFur'
  | 'number'
  | 'birth'
  | 'last'
  | 'realm'
  | 'rarity'
  | 'intervals'
  | 'timekeeperDisabled'
>;

export type FurballEquipmentFragment = { __typename?: 'EquipmentItem' } & Pick<
  EquipmentItem,
  | 'id'
  | 'itemId'
  | 'slot'
  | 'position'
  | 'durabilityTime'
  | 'breakAt'
  | 'rarity'
  | 'stack'
  | 'equippedById'
  | 'equippedAt'
> & {
    passives: Array<
      { __typename?: 'BattleEffectPassive' } & BattleEffectPassiveFragment
    >;
  };

export type FurballBaseFragment = { __typename?: 'Furball' } & {
  inventory: { __typename?: 'ContainerItem' } & InventoryItemsFragment;
  equipment: Array<{ __typename?: 'EquipmentItem' } & FurballEquipmentFragment>;
  battleStats: { __typename?: 'StatEntity' } & FurballBattleStatsFragment;
  state: { __typename?: 'FurballState' } & FurballStateFragment;
  openRentalAgreement: Maybe<
    { __typename?: 'RentalAgreement' } & FurRentalAgreementFragment
  >;
  activeRentalAgreement: Maybe<
    { __typename?: 'RentalAgreement' } & FurRentalAgreementFragment
  >;
  pendingUpgrade: Maybe<
    { __typename?: 'FurballUpgrade' } & FurballUpgradeFragment
  >;
} & FurballCoreInfoFragment;

export type FurballUpgradeBattleStatsFragment = {
  __typename?: 'FurballUpgradeBattleStats';
} & Pick<
  FurballUpgradeBattleStats,
  | 'maxHealth'
  | 'attackPower'
  | 'defencePower'
  | 'heal'
  | 'buff'
  | 'deBuff'
  | 'critAttackPower'
  | 'critRate'
  | 'speed'
>;

export type FurballUpgradeProgressFragment = {
  __typename?: 'FurballUpgradeProgress';
} & Pick<FurballUpgradeProgress, 'level' | 'expRate' | 'furRate'> & {
    battleStats: {
      __typename?: 'FurballUpgradeBattleStats';
    } & FurballUpgradeBattleStatsFragment;
  };

export type FurballUpgradeSkillFragment = {
  __typename?: 'FurballUpgradeSkill';
} & Pick<FurballUpgradeSkill, 'skillId' | 'level' | 'maxUses' | 'slotIndex'>;

export type FurballUpgradeEquipmentFragment = {
  __typename?: 'FurballUpgradeEquippedItem';
} & Pick<
  FurballUpgradeEquippedItem,
  'itemDefinitionId' | 'durabilityTime' | 'equippedAt' | 'slotIndex'
>;

export type FurballUpgradeFragment = { __typename?: 'FurballUpgrade' } & Pick<
  FurballUpgrade,
  | 'id'
  | 'date'
  | 'furballId'
  | 'senderId'
  | 'deadline'
  | 'fuel'
  | 'createdAt'
  | 'signedAt'
  | 'isEmpty'
  | 'isPending'
  | 'writeBackRequired'
  | 'canUnSchedule'
  | 'endsAt'
> & {
    progress: Maybe<
      { __typename?: 'FurballUpgradeProgress' } & FurballUpgradeProgressFragment
    >;
    skills: Array<
      { __typename?: 'FurballUpgradeSkill' } & FurballUpgradeSkillFragment
    >;
    equipment: Array<
      {
        __typename?: 'FurballUpgradeEquippedItem';
      } & FurballUpgradeEquipmentFragment
    >;
    equipmentStats: Array<
      {
        __typename?: 'FurballUpgradeBattleStats';
      } & FurballUpgradeBattleStatsFragment
    >;
  };

export type FurballFragment = { __typename?: 'Furball' } & {
  activeVoyage: Maybe<{ __typename?: 'Voyage' } & VoyageFragment>;
  lastVoyage: Maybe<{ __typename?: 'Voyage' } & VoyageFragment>;
} & FurballBaseFragment;

export type FurballQueryVariables = Exact<{
  tokenId: Scalars['String'];
}>;

export type FurballQuery = { __typename?: 'Query' } & {
  furball: { __typename?: 'Furball' } & FurballFragment;
};

export type FurballsQueryVariables = Exact<{
  tokenIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type FurballsQuery = { __typename?: 'Query' } & {
  furballs: Array<{ __typename?: 'Furball' } & FurballFragment>;
};

export type FurballPreviousResolutionsQueryVariables = Exact<{
  tokenId: Scalars['String'];
}>;

export type FurballPreviousResolutionsQuery = { __typename?: 'Query' } & {
  timekeeperPreviousResolutions: Array<
    { __typename?: 'TimekeeperResolution' } & TimekeeperResolutionRoundFragment
  >;
};

export type OwnerFurballFragment = { __typename?: 'Furball' } & {
  owner: { __typename?: 'FurAccount' } & Pick<
    FurAccount,
    'id' | 'avatar' | 'username' | 'socialName'
  >;
} & FurballFragment;

export type FindFurballQueryVariables = Exact<{
  query: Scalars['String'];
}>;

export type FindFurballQuery = { __typename?: 'Query' } & {
  furball: { __typename?: 'Furball' } & OwnerFurballFragment;
};

export type FurballModificationFragment = {
  __typename?: 'FurballModification';
} & Pick<
  FurballModification,
  | 'id'
  | 'tokenId'
  | 'pickups'
  | 'drops'
  | 'sender'
  | 'setActiveVoyageDays'
  | 'addEncounterType'
  | 'signature'
> & { furball: { __typename?: 'Furball' } & OwnerFurballFragment };

export type ModifyFurballMutationVariables = Exact<{
  mods: FurballModificationArgsInput;
  token: FurOAuthTokenInput;
}>;

export type ModifyFurballMutation = { __typename?: 'Mutation' } & {
  modification: {
    __typename?: 'FurballModification';
  } & FurballModificationFragment;
};

export type FurballWithPlayerInventoryFragment = { __typename?: 'Furball' } & {
  player: { __typename?: 'FurAccount' } & {
    inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
  } & FurPlayerMinimal_FurAccount_Fragment;
  activeRentalAgreement: Maybe<
    { __typename?: 'RentalAgreement' } & Pick<RentalAgreement, 'id'> & {
        player: Maybe<
          { __typename?: 'FurPlayer' } & {
            inventory: {
              __typename?: 'ContainerItem';
            } & InventoryQuantitiesFragment;
          } & FurPlayerMinimal_FurPlayer_Fragment
        >;
      }
  >;
} & FurballFragment;

export type EquipFurballMutationVariables = Exact<{
  furballId: Scalars['String'];
  itemIds: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;

export type EquipFurballMutation = { __typename?: 'Mutation' } & {
  equipFurball: { __typename?: 'Furball' } & FurballWithPlayerInventoryFragment;
};

export type RequestAirdropMutationVariables = Exact<{
  args: AirdropRequestArgsInput;
  token: FurOAuthTokenInput;
}>;

export type RequestAirdropMutation = { __typename?: 'Mutation' } & {
  requestAirdrop: { __typename?: 'AirdropRequest' } & Pick<
    AirdropRequest,
    'tix' | 'fur'
  >;
};

export type AirdropRequestFragment = { __typename?: 'AirdropRequest' } & Pick<
  AirdropRequest,
  'id' | 'tix' | 'fur' | 'recipient' | 'approvedBy' | 'transactionId'
>;

export type ListAirdropRequestsQueryVariables = Exact<{ [key: string]: never }>;

export type ListAirdropRequestsQuery = { __typename?: 'Query' } & {
  listAirdropRequests: Array<
    { __typename?: 'AirdropRequest' } & AirdropRequestFragment
  >;
};

export type ApproveAirdropsMutationVariables = Exact<{
  args: Array<Scalars['String']> | Scalars['String'];
  token: FurOAuthTokenInput;
}>;

export type ApproveAirdropsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'approveAirdrops'
>;

export type GameEntitySummaryFragment = {
  __typename?: 'GameEntitySummary';
} & Pick<
  GameEntitySummary,
  | 'id'
  | 'statEntityId'
  | 'gameEntityId'
  | 'gameBattleId'
  | 'damageTotal'
  | 'damageTaken'
  | 'moveCount'
  | 'expGained'
  | 'levelsGained'
>;

export type RecentFurballBossFightFragment = {
  __typename?: 'GameBattleBoss';
} & Pick<
  GameBattleBoss,
  | 'id'
  | 'playerId'
  | 'createdAt'
  | 'duration'
  | 'playerDamageTotal'
  | 'worldBossId'
> & {
    worldBoss: { __typename?: 'GameBoss' } & Pick<
      GameBoss,
      'name' | 'level' | 'realm'
    >;
    outcomeSummaries: Array<
      { __typename?: 'GameEntitySummary' } & GameEntitySummaryFragment
    >;
  };

export type RecentFurballBossFightsQueryVariables = Exact<{
  number: Scalars['String'];
}>;

export type RecentFurballBossFightsQuery = { __typename?: 'Query' } & {
  findFurball: { __typename?: 'Furball' } & {
    bossFights: Maybe<
      { __typename?: 'BossFightsConnection' } & {
        nodes: Maybe<
          Array<
            { __typename?: 'GameBattleBoss' } & RecentFurballBossFightFragment
          >
        >;
      }
    >;
  };
};

export type MoveItemFragmentFragment = { __typename?: 'MoveItemResult' } & {
  fromFurball: Maybe<{ __typename?: 'Furball' } & FurballFragment>;
  toFurball: Maybe<{ __typename?: 'Furball' } & FurballFragment>;
  player: { __typename?: 'FurPlayer' } & BuyerSellerPlayerFragment;
};

export type MoveItemMutationVariables = Exact<{
  itemId: Scalars['String'];
  quantity: Maybe<Scalars['UnsignedInt']>;
  toFurballId: Maybe<Scalars['String']>;
}>;

export type MoveItemMutation = { __typename?: 'Mutation' } & {
  moveItem: { __typename?: 'MoveItemResult' } & MoveItemFragmentFragment;
};

export type InventoryQueryVariables = Exact<{
  containerId: Scalars['String'];
}>;

export type InventoryQuery = { __typename?: 'Query' } & {
  inventory: { __typename?: 'ContainerItem' } & InventoryItemsFragment;
};

export type NotificationInstanceFragment = {
  __typename?: 'NotificationInstance';
} & Pick<NotificationInstance, 'id' | 'platform' | 'receipt' | 'recipientId'>;

export type NotificationFragment = { __typename?: 'Notification' } & Pick<
  Notification,
  | 'id'
  | 'data'
  | 'message'
  | 'recipient'
  | 'sender'
  | 'title'
  | 'purpose'
  | 'description'
  | 'thumbnailUrl'
  | 'imageUrl'
  | 'furballId'
  | 'createdAt'
> & {
    pendingReward: Maybe<
      { __typename?: 'PlayerPendingReward' } & PlayerPendingRewardFragment
    >;
  };

export type NotifySubscriptionVariables = Exact<{
  playerId: Scalars['String'];
}>;

export type NotifySubscription = { __typename?: 'Subscription' } & {
  notify: { __typename?: 'Notification' } & NotificationFragment;
};

export type UnreadNotificationsQueryVariables = Exact<{
  after: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
}>;

export type UnreadNotificationsQuery = { __typename?: 'Query' } & {
  unreadNotifications: Maybe<
    { __typename?: 'UnreadNotificationsConnection' } & {
      nodes: Maybe<
        Array<{ __typename?: 'Notification' } & NotificationFragment>
      >;
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
    }
  >;
};

export type ReadNotificationMutationVariables = Exact<{
  notificationIds: Array<Scalars['String']> | Scalars['String'];
}>;

export type ReadNotificationMutation = { __typename?: 'Mutation' } & {
  readNotifications: Array<
    { __typename?: 'Notification' } & Pick<Notification, 'id'>
  >;
};

export type QuestWaypointBracketDefinitionFragment = {
  __typename?: 'QuestWaypointBracketDefinition';
} & Pick<QuestWaypointBracketDefinition, 'percentileRank' | 'furAmount'> & {
    possibleRewards: Array<
      {
        __typename?: 'LeaderboardTierRewardChance';
      } & LeaderboardTierRewardChanceFragment
    >;
  };

export type QuestWaypointDefinitionFragment = {
  __typename?: 'QuestWaypointDefinition';
} & Pick<QuestWaypointDefinition, 'name' | 'style'> & {
    brackets: Array<
      {
        __typename?: 'QuestWaypointBracketDefinition';
      } & QuestWaypointBracketDefinitionFragment
    >;
  };

export type QuestDefinitionFragment = { __typename?: 'QuestDefinition' } & Pick<
  QuestDefinition,
  'name' | 'respawn' | 'maxCompletions'
> & {
    waypoints: Array<
      {
        __typename?: 'QuestWaypointDefinition';
      } & QuestWaypointDefinitionFragment
    >;
  };

export type ShopWaresFragment = { __typename?: 'ShopWares' } & Pick<
  ShopWares,
  | 'id'
  | 'name'
  | 'furCost'
  | 'fuelCost1'
  | 'fuelCostN'
  | 'maxPerFurball'
  | 'saleBeginsAt'
  | 'saleEndsAt'
  | 'itemIds'
  | 'itemList'
>;

export type ShopWaresQueryVariables = Exact<{ [key: string]: never }>;

export type ShopWaresQuery = { __typename?: 'Query' } & {
  shopWares: Array<{ __typename?: 'ShopWares' } & ShopWaresFragment>;
};

export type ShopSnackFragment = { __typename?: 'ShopSnack' } & Pick<
  ShopSnack,
  | 'id'
  | 'name'
  | 'furCost'
  | 'fuelCost1'
  | 'fuelCostN'
  | 'happiness'
  | 'energy'
  | 'saleBeginsAt'
  | 'saleEndsAt'
>;

export type ShopSnacksQueryVariables = Exact<{ [key: string]: never }>;

export type ShopSnacksQuery = { __typename?: 'Query' } & {
  shopSnacks: Array<{ __typename?: 'ShopSnack' } & ShopSnackFragment>;
};

export type InteractionCostFragment = {
  __typename?: 'TimekeeperInteraction';
} & Pick<
  TimekeeperInteraction,
  | 'furballIds'
  | 'furballFuel'
  | 'interactionType'
  | 'fuelCost'
  | 'gasEstimate'
  | 'expectedValue'
  | 'count'
  | 'id'
  | 'sourceInteractionId'
>;

export type TimekeeperResolutionFragment = {
  __typename?: 'TimekeeperResolution';
} & Pick<
  TimekeeperResolution,
  | 'tokenId'
  | 'date'
  | 'furGained'
  | 'expGained'
  | 'intervals'
  | 'zoneNumber'
  | 'isEmpty'
  | 'itemList'
  | 'createdAt'
  | 'updatedAt'
  | 'completedAt'
>;

export type TimekeeperResolutionRoundFragment = {
  __typename?: 'TimekeeperResolution';
} & {
  request: { __typename?: 'TimekeeperRequest' } & TimekeeperRequestBaseFragment;
} & TimekeeperResolutionFragment;

export type ChangeSetFragment = { __typename?: 'TimekeeperChangeSet' } & Pick<
  TimekeeperChangeSet,
  | 'date'
  | 'fuelCost'
  | 'gained'
  | 'spent'
  | 'mintEdition'
  | 'mintCount'
  | 'movementList'
  | 'collectStayList'
> & {
    interactions: Array<
      { __typename?: 'TimekeeperInteraction' } & InteractionCostFragment
    >;
  };

export type TimekeeperRequestBaseFragment = {
  __typename?: 'TimekeeperRequest';
} & Pick<
  TimekeeperRequest,
  | 'date'
  | 'sender'
  | 'partialIndex'
  | 'state'
  | 'hasChanges'
  | 'furReal'
  | 'resolvedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
    changeSet: Maybe<
      { __typename?: 'TimekeeperChangeSet' } & ChangeSetFragment
    >;
    signedRequest: Maybe<
      { __typename?: 'TimekeeperChangeSet' } & ChangeSetFragment
    >;
  };

export type TimekeeperRequestFragment = { __typename?: 'TimekeeperRequest' } & {
  resolutions: Array<
    { __typename?: 'TimekeeperResolution' } & TimekeeperResolutionFragment
  >;
} & TimekeeperRequestBaseFragment;

export type TimekeeperCostsFragment = { __typename?: 'TimekeeperCosts' } & Pick<
  TimekeeperCosts,
  | 'date'
  | 'factor'
  | 'spendTickets'
  | 'spendFur'
  | 'collectFur'
  | 'collectExp1'
  | 'collectExpN'
  | 'move1'
  | 'moveN'
  | 'snack1'
  | 'snackN'
  | 'upgrade1'
  | 'upgradeN'
  | 'mint1'
  | 'mintN'
  | 'dropLoot'
  | 'completeVoyage'
  | 'enterVoyages'
  | 'upgradeSkill'
>;

export type TimekeeperRoundFragment = { __typename?: 'TimekeeperRound' } & Pick<
  TimekeeperRound,
  'date' | 'state' | 'ethPrice' | 'gasPrice'
> & { costs: { __typename?: 'TimekeeperCosts' } & TimekeeperCostsFragment };

export type TimekeeperUserStateFragment = {
  __typename?: 'TimekeeperUserState';
} & {
  round: { __typename?: 'TimekeeperRound' } & TimekeeperRoundFragment;
  current: Array<
    { __typename?: 'TimekeeperRequest' } & TimekeeperRequestFragment
  >;
  pending: Array<
    { __typename?: 'TimekeeperRequest' } & TimekeeperRequestFragment
  >;
};

export type TimekeeperStateQueryVariables = Exact<{
  sender: Scalars['String'];
}>;

export type TimekeeperStateQuery = { __typename?: 'Query' } & {
  timekeeperState: {
    __typename?: 'TimekeeperUserState';
  } & TimekeeperUserStateFragment;
};

export type TimekeeperRoundQueryVariables = Exact<{ [key: string]: never }>;

export type TimekeeperRoundQuery = { __typename?: 'Query' } & {
  timekeeperRound: { __typename?: 'TimekeeperRound' } & TimekeeperRoundFragment;
};

export type TimekeeperPreviousQueryVariables = Exact<{
  sender: Scalars['String'];
}>;

export type TimekeeperPreviousQuery = { __typename?: 'Query' } & {
  timekeeperPrevious: Array<
    { __typename?: 'TimekeeperRequest' } & TimekeeperRequestFragment
  >;
};

export type TimekeeperSaveActionMutationVariables = Exact<{
  interaction: TimekeeperInteractionArgInput;
}>;

export type TimekeeperSaveActionMutation = { __typename?: 'Mutation' } & {
  timekeeperSaveAction: Array<
    { __typename?: 'TimekeeperRequest' } & TimekeeperRequestFragment
  >;
};

export type TimekeeperSignRequestMutationVariables = Exact<{
  req: TimekeeperArgsInput;
  sig: Scalars['String'];
  partialIndex: Scalars['Int'];
}>;

export type TimekeeperSignRequestMutation = { __typename?: 'Mutation' } & {
  timekeeperSignRequest: {
    __typename?: 'TimekeeperRequest';
  } & TimekeeperRequestFragment;
};

export type TransactionFragment = {
  __typename?: 'BlockchainTransaction';
} & Pick<BlockchainTransaction, 'id' | 'hash' | 'gasEstimate' | 'gasUsed'>;

export type TimekeeperToggleFragment = {
  __typename?: 'TimekeeperToggle';
} & Pick<
  TimekeeperToggle,
  | 'id'
  | 'fuelCost'
  | 'furballIds'
  | 'mode'
  | 'isAccepted'
  | 'hasRun'
  | 'timekeeperDisabled'
> & {
    modeChanges: Array<
      { __typename?: 'TimekeeperModeChange' } & Pick<
        TimekeeperModeChange,
        'tokenId' | 'lastTimestamp'
      >
    >;
    transaction: Maybe<
      { __typename?: 'BlockchainTransaction' } & TransactionFragment
    >;
  };

export type TimekeeperTransferTixFragment = {
  __typename?: 'TimekeeperTransferTix';
} & Pick<
  TimekeeperTransferTix,
  'id' | 'fuelCost' | 'isAccepted' | 'hasRun' | 'recipient'
> & {
    transaction: Maybe<
      { __typename?: 'BlockchainTransaction' } & TransactionFragment
    >;
  };

export type TimekeeperToggleComputeMutationVariables = Exact<{
  toggleArgs: TimekeeperToggleArgsInput;
}>;

export type TimekeeperToggleComputeMutation = { __typename?: 'Mutation' } & {
  timekeeperToggleCompute: {
    __typename?: 'TimekeeperToggle';
  } & TimekeeperToggleFragment;
};

export type TimekeeperToggleAcceptMutationVariables = Exact<{
  id: Scalars['String'];
  signature: Scalars['String'];
}>;

export type TimekeeperToggleAcceptMutation = { __typename?: 'Mutation' } & {
  timekeeperToggleAccept: {
    __typename?: 'TimekeeperToggle';
  } & TimekeeperToggleFragment;
};

export type TimekeeperTransferTixComputeMutationVariables = Exact<{
  xferArgs: TimekeeperTransferTixArgsInput;
}>;

export type TimekeeperTransferTixComputeMutation = {
  __typename?: 'Mutation';
} & {
  timekeeperTransferTixCompute: {
    __typename?: 'TimekeeperTransferTix';
  } & TimekeeperTransferTixFragment;
};

export type TimekeeperTransferTixAcceptMutationVariables = Exact<{
  id: Scalars['String'];
  signature: Scalars['String'];
}>;

export type TimekeeperTransferTixAcceptMutation = {
  __typename?: 'Mutation';
} & {
  timekeeperTransferTixAccept: {
    __typename?: 'TimekeeperTransferTix';
  } & TimekeeperTransferTixFragment;
};

export type TimekeeperAdHocFragment = { __typename?: 'TimekeeperAdHoc' } & Pick<
  TimekeeperAdHoc,
  | 'id'
  | 'sender'
  | 'fuelCost'
  | 'gained'
  | 'spent'
  | 'isAccepted'
  | 'hasRun'
  | 'createdAt'
  | 'updatedAt'
  | 'transactionHash'
>;

export type TimekeeperSaveAdHocMutationVariables = Exact<{
  args: TimekeeperAdHocArgsInput;
  signature: Scalars['String'];
}>;

export type TimekeeperSaveAdHocMutation = { __typename?: 'Mutation' } & {
  timekeeperSaveAdHoc: {
    __typename?: 'TimekeeperAdHoc';
  } & TimekeeperAdHocFragment;
};

export type TimekeeperAdHocsQueryVariables = Exact<{
  sender: Scalars['String'];
}>;

export type TimekeeperAdHocsQuery = { __typename?: 'Query' } & {
  timekeeperAdHocs: Array<
    { __typename?: 'TimekeeperAdHoc' } & TimekeeperAdHocFragment
  >;
};

export type TimekeeperWrapFurComputeMutationVariables = Exact<{
  wrapFurArgs: TimekeeperWrapFurArgsInput;
}>;

export type TimekeeperWrapFurComputeMutation = { __typename?: 'Mutation' } & {
  wrapFurCompute: { __typename?: 'TimekeeperWrapFur' } & Pick<
    TimekeeperWrapFur,
    'spent' | 'gained'
  > &
    TimekeeperWrapFurFragment;
};

export type TimekeeperWrapFurAcceptMutationVariables = Exact<{
  id: Scalars['String'];
  signature: Scalars['String'];
}>;

export type TimekeeperWrapFurAcceptMutation = { __typename?: 'Mutation' } & {
  wrapFurAccept: {
    __typename?: 'TimekeeperWrapFur';
  } & TimekeeperWrapFurFragment;
};

export type WrapFurMutationVariables = Exact<{
  wrapFurArgs: TimekeeperWrapFurArgsInput;
  signature: Scalars['String'];
}>;

export type WrapFurMutation = { __typename?: 'Mutation' } & {
  wrapFur: { __typename?: 'TimekeeperWrapFur' } & TimekeeperWrapFurFragment;
};

export type SpecializeMutationVariables = Exact<{
  furballId: Scalars['String'];
  specialization: Specialization;
  renameArgs: Maybe<RenameFurballArgsInput>;
  renameSignature: Maybe<Scalars['String']>;
}>;

export type SpecializeMutation = { __typename?: 'Mutation' } & {
  specializeFurball: { __typename?: 'Furball' } & FurballFragment;
};

export type TimekeeperRenameFurballFragment = {
  __typename?: 'TimekeeperRenameFurball';
} & Pick<
  TimekeeperRenameFurball,
  'id' | 'furballId' | 'name' | 'transactionMessage' | 'transactionHash'
> & {
    transaction: Maybe<
      { __typename?: 'BlockchainTransaction' } & TransactionFragment
    >;
  };

export type FurballNameChangeFragment = {
  __typename?: 'FurballNameChange';
} & Pick<
  FurballNameChange,
  'id' | 'name' | 'approved' | 'executedAt' | 'createdAt'
> & {
    player: { __typename?: 'FurPlayer' } & FurPlayerFragment;
    furball: { __typename?: 'Furball' } & Pick<Furball, 'id' | 'name'>;
    approvedBy: Maybe<{ __typename?: 'FurPlayer' } & FurPlayerFragment>;
    request: {
      __typename?: 'TimekeeperRenameFurball';
    } & TimekeeperRenameFurballFragment;
  };

export type FurballNameChangesQueryVariables = Exact<{ [key: string]: never }>;

export type FurballNameChangesQuery = { __typename?: 'Query' } & {
  furballNameChanges: Maybe<
    { __typename?: 'FurballNameChangesConnection' } & {
      nodes: Maybe<
        Array<{ __typename?: 'FurballNameChange' } & FurballNameChangeFragment>
      >;
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
    }
  >;
};

export type ApproveNameMutationVariables = Exact<{
  renameId: Scalars['String'];
  approve: Scalars['Boolean'];
}>;

export type ApproveNameMutation = { __typename?: 'Mutation' } & {
  renameFurballAccept: {
    __typename?: 'FurballNameChange';
  } & FurballNameChangeFragment;
};

export type VoyageEncounterBaseFragment = {
  __typename?: 'VoyageEncounter';
} & Pick<
  VoyageEncounter,
  | 'id'
  | 'date'
  | 'encounterType'
  | 'voyageDayNumber'
  | 'subType'
  | 'outcome'
  | 'decision'
  | 'sender'
  | 'tokenId'
  | 'fuelCost'
  | 'furCost'
  | 'furGained'
  | 'didPlayerComplete'
  | 'createdAt'
  | 'updatedAt'
>;

export type VoyageEncounterFragment = { __typename?: 'VoyageEncounter' } & {
  match: Maybe<
    { __typename?: 'VoyageEncounter' } & VoyageEncounterBaseFragment
  >;
} & VoyageEncounterBaseFragment;

export type VoyageBaseFragment = { __typename?: 'Voyage' } & Pick<
  Voyage,
  | 'id'
  | 'sender'
  | 'realm'
  | 'state'
  | 'totalFurCost'
  | 'daysComplete'
  | 'daysDuration'
  | 'percentComplete'
  | 'encountersFuelCost'
  | 'encountersFurGained'
  | 'maxLootItems'
>;

export type VoyageFragment = { __typename?: 'Voyage' } & {
  activeEncounter: Maybe<
    { __typename?: 'VoyageEncounter' } & VoyageEncounterFragment
  >;
  lastEncounter: Maybe<
    { __typename?: 'VoyageEncounter' } & VoyageEncounterFragment
  >;
  encounters: Array<
    { __typename?: 'VoyageEncounter' } & VoyageEncounterFragment
  >;
} & VoyageBaseFragment;

export type MoveZonesResponseFragment = {
  __typename?: 'MoveZoneResponse';
} & Pick<MoveZoneResponse, 'expGained' | 'furSpent' | 'furGained'> & {
    furball: { __typename?: 'Furball' } & FurballFragment;
    items: Array<
      | ({ __typename?: 'ConsumableItem' } & Items_ConsumableItem_Fragment)
      | ({ __typename?: 'ContainerItem' } & Items_ContainerItem_Fragment)
      | ({ __typename?: 'EquipmentItem' } & Items_EquipmentItem_Fragment)
      | ({ __typename?: 'LootItem' } & Items_LootItem_Fragment)
      | ({ __typename?: 'MaterialItem' } & Items_MaterialItem_Fragment)
      | ({ __typename?: 'SpecialItem' } & Items_SpecialItem_Fragment)
    >;
  };

export type SubmitVoyageEncounterMutationVariables = Exact<{
  complete: Scalars['Boolean'];
  tokenId: Scalars['String'];
  decision: Scalars['Int'];
}>;

export type SubmitVoyageEncounterMutation = { __typename?: 'Mutation' } & {
  submitVoyageEncounter: {
    __typename?: 'VoyageEncounter';
  } & VoyageEncounterFragment;
};

export type MoveZonesMutationVariables = Exact<{
  requests: Array<MoveZoneRequestInput> | MoveZoneRequestInput;
}>;

export type MoveZonesMutation = { __typename?: 'Mutation' } & {
  moveZones: Array<
    { __typename?: 'MoveZoneResponse' } & MoveZonesResponseFragment
  >;
};

export type TransactionHistoryQueryVariables = Exact<{
  endCursor: Maybe<Scalars['String']>;
  token: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type TransactionHistoryQuery = { __typename?: 'Query' } & {
  playerTransactions: Maybe<
    { __typename?: 'PlayerTransactionsConnection' } & Pick<
      PlayerTransactionsConnection,
      'totalCount'
    > & {
        nodes: Maybe<
          Array<
            { __typename?: 'BlockchainTokenTransaction' } & Pick<
              BlockchainTokenTransaction,
              'id' | 'amount' | 'validation' | 'createdAt'
            > & {
                token: { __typename?: 'BlockchainToken' } & Pick<
                  BlockchainToken,
                  'name'
                >;
              }
          >
        >;
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export type ChangeUsernameMutationVariables = Exact<{
  username: Scalars['String'];
}>;

export type ChangeUsernameMutation = { __typename?: 'Mutation' } & {
  changeUsername:
    | ({ __typename?: 'FurAccount' } & Pick<
        FurAccount,
        'id' | 'username' | 'socialName'
      >)
    | ({ __typename?: 'FurPlayer' } & Pick<
        FurPlayer,
        'id' | 'username' | 'socialName'
      >)
    | ({ __typename?: 'FurScholar' } & Pick<
        FurScholar,
        'id' | 'username' | 'socialName'
      >)
    | ({ __typename?: 'FurScholarDiscord' } & Pick<
        FurScholarDiscord,
        'id' | 'username' | 'socialName'
      >)
    | ({ __typename?: 'FurScholarEmail' } & Pick<
        FurScholarEmail,
        'id' | 'username' | 'socialName'
      >);
};

export type FurballPartyMemberFragment = { __typename?: 'StatEntity' } & {
  entity: Maybe<
    | ({ __typename?: 'Furball' } & FurballFragment)
    | { __typename?: 'GameBoss' }
    | { __typename?: 'GameMonster' }
  >;
} & FurballBattleStatsFragment;

export type CurrentBattleFragment = { __typename: 'GameBattle' } & Pick<
  GameBattle,
  'id' | 'score' | 'battleType' | 'realm'
>;

export type PlayerPartyFragment = { __typename?: 'GameParty' } & Pick<
  GameParty,
  | 'id'
  | 'playerId'
  | 'size'
  | 'name'
  | 'aiEnabled'
  | 'slotNumber'
  | 'filledPositions'
  | 'openPositions'
  | 'isInBattle'
  | 'currentBattleId'
  | 'maxLevels'
> & {
    currentBattle: Maybe<{ __typename?: 'GameBattle' } & CurrentBattleFragment>;
    partyMembers: Array<
      { __typename?: 'StatEntity' } & FurballBattleStatsFragment
    >;
    formation: { __typename?: 'FormationDefinition' } & Pick<
      FormationDefinition,
      'positions'
    >;
    player: Maybe<{ __typename?: 'FurPlayer' } & FurPlayerBaseFragment>;
  };

export type PlayerPartyFurballsFragment = { __typename?: 'GameParty' } & {
  partyMembers: Array<
    { __typename?: 'StatEntity' } & FurballPartyMemberFragment
  >;
} & PlayerPartyFragment;

export type BossesQueryVariables = Exact<{ [key: string]: never }>;

export type BossesQuery = { __typename?: 'Query' } & {
  topBossBattles: Array<
    { __typename?: 'RecentBossBattles' } & {
      boss: { __typename?: 'GameBoss' } & Pick<
        GameBoss,
        'id' | 'name' | 'realm' | 'level' | 'health' | 'maxHealth'
      >;
    }
  >;
};

export type PlayerPartiesQueryVariables = Exact<{
  playerId: Scalars['String'];
}>;

export type PlayerPartiesQuery = { __typename?: 'Query' } & {
  playerParties: Array<{ __typename?: 'GameParty' } & PlayerPartyFragment>;
};

export type PartyQueryVariables = Exact<{
  partyId: Scalars['String'];
}>;

export type PartyQuery = { __typename?: 'Query' } & {
  party: { __typename?: 'GameParty' } & PlayerPartyFurballsFragment;
};

export type PartyCompleteQueryVariables = Exact<{
  partyId: Scalars['String'];
}>;

export type PartyCompleteQuery = { __typename?: 'Query' } & {
  party: { __typename?: 'GameParty' } & Pick<
    GameParty,
    'name' | 'partyMemberIds' | 'size'
  >;
};

export type BossBattleQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type BossBattleQuery = { __typename?: 'Query' } & {
  bossBattle: { __typename?: 'GameBattleBoss' } & BossBattlePlayerFragment;
};

export type SetpartyMutationVariables = Exact<{
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  slotNumber: Maybe<Scalars['Int']>;
  formationPositions: Maybe<Array<FormationPosition> | FormationPosition>;
  furballIds: Array<Scalars['String']> | Scalars['String'];
  playerId: Scalars['String'];
  deleteParty: Maybe<Scalars['Boolean']>;
}>;

export type SetpartyMutation = { __typename?: 'Mutation' } & {
  setParty: { __typename?: 'GameParty' } & PlayerPartyFragment;
};

export type ForfeitBattleMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type ForfeitBattleMutation = { __typename?: 'Mutation' } & {
  forfeitBattle: { __typename?: 'GameBattle' } & Pick<GameBattle, 'id'>;
};

export type JoinBattleMutationVariables = Exact<{
  realm: RealmType;
  partyId: Scalars['String'];
  trialGame: Scalars['Boolean'];
}>;

export type JoinBattleMutation = { __typename?: 'Mutation' } & {
  joinBattle: { __typename?: 'JoinBattleResponse' } & Pick<
    JoinBattleResponse,
    'partyId'
  > & {
      joinBossBattle: {
        __typename?: 'GameBattleBoss';
      } & BossBattlePlayerFragment;
    };
};

export type BuyBossKeysMutationVariables = Exact<{
  furballIds: Array<Scalars['String']> | Scalars['String'];
  realms: Array<RealmType> | RealmType;
}>;

export type BuyBossKeysMutation = { __typename?: 'Mutation' } & {
  buyBossKeys: { __typename?: 'PlayerPurchase' } & Pick<
    PlayerPurchase,
    'remainingFuel' | 'spentFuel'
  > & { furballs: Array<{ __typename?: 'Furball' } & FurballFragment> };
};

export type RefillHeartsMutationVariables = Exact<{
  partyId: Scalars['String'];
}>;

export type RefillHeartsMutation = { __typename?: 'Mutation' } & {
  party: { __typename?: 'GameParty' } & PlayerPartyFurballsFragment;
};

export type CreateDuelMutationVariables = Exact<{
  partyId: Scalars['String'];
  rules: Maybe<GamePlayRulesInput>;
  ante: Maybe<AnteInput>;
}>;

export type CreateDuelMutation = { __typename?: 'Mutation' } & {
  duel: { __typename?: 'GameBattleDuel' } & DuelBattleFragment;
};

export type JoinDuelMutationVariables = Exact<{
  gameBattleId: Scalars['String'];
  partyId: Scalars['String'];
}>;

export type JoinDuelMutation = { __typename?: 'Mutation' } & {
  duel: { __typename?: 'GameBattleDuel' } & DuelBattleFragment;
};

export type ListOpenDuelsQueryVariables = Exact<{ [key: string]: never }>;

export type ListOpenDuelsQuery = { __typename?: 'Query' } & {
  duelBattles: Maybe<
    { __typename?: 'DuelBattlesConnection' } & {
      nodes: Maybe<
        Array<{ __typename?: 'GameBattleDuel' } & DuelBattleFragment>
      >;
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
    }
  >;
};

export type BuyerSellerPlayerFragment = { __typename?: 'FurPlayer' } & Pick<
  FurPlayer,
  'username' | 'socialName' | 'wFur' | 'avatar'
> &
  PlayerInventoryQuantityFragment;

export type ItemListingFragment = { __typename?: 'PlayerItemListing' } & Pick<
  PlayerItemListing,
  | 'id'
  | 'itemDefinitionId'
  | 'itemId'
  | 'quantity'
  | 'currentPriceEach'
  | 'style'
  | 'currency'
  | 'currentPrice'
  | 'expiresAt'
  | 'soldAt'
  | 'createdAt'
> & {
    item:
      | ({ __typename?: 'ConsumableItem' } & Items_ConsumableItem_Fragment)
      | ({ __typename?: 'ContainerItem' } & Items_ContainerItem_Fragment)
      | ({ __typename?: 'EquipmentItem' } & Items_EquipmentItem_Fragment)
      | ({ __typename?: 'LootItem' } & Items_LootItem_Fragment)
      | ({ __typename?: 'MaterialItem' } & Items_MaterialItem_Fragment)
      | ({ __typename?: 'SpecialItem' } & Items_SpecialItem_Fragment);
    seller: { __typename?: 'FurPlayer' } & BuyerSellerPlayerFragment;
    buyer: Maybe<{ __typename?: 'FurPlayer' } & BuyerSellerPlayerFragment>;
  };

export type LootMarketplaceQueryVariables = Exact<{
  itemIds: Array<Scalars['Long']> | Scalars['Long'];
}>;

export type LootMarketplaceQuery = { __typename?: 'Query' } & {
  itemListings: Maybe<
    { __typename?: 'ItemListingsConnection' } & {
      nodes: Maybe<
        Array<{ __typename?: 'PlayerItemListing' } & ItemListingFragment>
      >;
      pageInfo: { __typename?: 'PageInfo' } & PageInfoFragment;
    }
  >;
};

export type MyLootListingsQueryVariables = Exact<{ [key: string]: never }>;

export type MyLootListingsQuery = { __typename?: 'Query' } & {
  myItemListings: Array<
    { __typename?: 'PlayerItemListing' } & ItemListingFragment
  >;
};

export type CreateLootListingMutationVariables = Exact<{
  itemId: Scalars['String'];
  price: Scalars['UnsignedLong'];
  quantity: Scalars['UnsignedInt'];
}>;

export type CreateLootListingMutation = { __typename?: 'Mutation' } & {
  listing: { __typename?: 'PlayerItemListing' } & {
    seller: { __typename?: 'FurPlayer' } & BuyerSellerPlayerFragment;
  } & ItemListingFragment;
};

export type EditLootListingMutationVariables = Exact<{
  id: Scalars['String'];
  price: Scalars['UnsignedLong'];
}>;

export type EditLootListingMutation = { __typename?: 'Mutation' } & {
  listing: { __typename?: 'PlayerItemListing' } & ItemListingFragment;
};

export type CancelLootListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type CancelLootListingMutation = { __typename?: 'Mutation' } & {
  listing: { __typename?: 'PlayerItemListing' } & ItemListingFragment;
};

export type BuyLootListingMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type BuyLootListingMutation = { __typename?: 'Mutation' } & {
  listing: { __typename?: 'PlayerItemListing' } & ItemListingFragment;
};

export type FurballSearchResultFragment = { __typename?: 'Furball' } & Pick<
  Furball,
  | 'id'
  | 'number'
  | 'name'
  | 'backgroundColor'
  | 'level'
  | 'expRate'
  | 'furRate'
  | 'dailyBattleCount'
  | 'maxDailyBattles'
> & {
    rentalAgreements: Array<
      { __typename?: 'RentalAgreement' } & SearchResultRentalAgreementFragment
    >;
    openRentalAgreement: Maybe<
      { __typename?: 'RentalAgreement' } & SearchResultRentalAgreementFragment
    >;
    inventory: { __typename?: 'ContainerItem' } & InventoryQuantitiesFragment;
    battleStats: { __typename?: 'StatEntity' } & FurballBattleStatsFragment;
  };

export type SearchResultsFragment = {
  __typename?: 'SearchFurballsConnection';
} & {
  nodes: Maybe<Array<{ __typename?: 'Furball' } & FurballSearchResultFragment>>;
  pageInfo: { __typename?: 'PageInfo' } & Pick<
    PageInfo,
    'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'
  >;
};

export type SearchFurballsQueryVariables = Exact<{
  inventory: Maybe<Array<Scalars['Long']> | Scalars['Long']>;
  skills: Maybe<Array<Scalars['Long']> | Scalars['Long']>;
  weapon: Array<Scalars['Short']> | Scalars['Short'];
  geneBoostMin: Maybe<Scalars['Int']>;
  geneBoostMax: Maybe<Scalars['Int']>;
  furBoostMin: Maybe<Scalars['Int']>;
  furBoostMax: Maybe<Scalars['Int']>;
  expBoostMin: Maybe<Scalars['Int']>;
  expBoostMax: Maybe<Scalars['Int']>;
  minLevel: Maybe<Scalars['Int']>;
  maxLevel: Maybe<Scalars['Int']>;
  endCursor: Maybe<Scalars['String']>;
  sort: Maybe<Array<FurballSortInput> | FurballSortInput>;
}>;

export type SearchFurballsQuery = { __typename?: 'Query' } & {
  searchFurballs: Maybe<
    { __typename?: 'SearchFurballsConnection' } & SearchResultsFragment
  >;
};

export type MarketPlaceFurballQueryVariables = Exact<{
  tokenId: Scalars['String'];
}>;

export type MarketPlaceFurballQuery = { __typename?: 'Query' } & {
  furball: { __typename?: 'Furball' } & Pick<Furball, 'id' | 'tokenId'> & {
      state: { __typename?: 'FurballState' } & FurballStateFragment;
      inventory: { __typename?: 'ContainerItem' } & InventoryItemsFragment;
      battleStats: { __typename?: 'StatEntity' } & Pick<StatEntity, 'id'> & {
          skills: Array<{ __typename?: 'EntitySkill' } & EntitySkillFragment>;
        } & EntityBattleStatsFragment;
      openRentalAgreement: Maybe<
        { __typename?: 'RentalAgreement' } & FurRentalAgreementFragment
      >;
    };
};

export type SearchFurballByTokenIdQueryVariables = Exact<{
  tokenId: Maybe<Scalars['String']>;
}>;

export type SearchFurballByTokenIdQuery = { __typename?: 'Query' } & {
  searchFurballs: Maybe<
    { __typename?: 'SearchFurballsConnection' } & SearchResultsFragment
  >;
};

export type SearchFurballByNumberQueryVariables = Exact<{
  number: Maybe<Scalars['Int']>;
}>;

export type SearchFurballByNumberQuery = { __typename?: 'Query' } & {
  searchFurballs: Maybe<
    { __typename?: 'SearchFurballsConnection' } & SearchResultsFragment
  >;
};

export type CreateRentalAgreementMutationVariables = Exact<{
  duration: Scalars['Long'];
  signature: Scalars['String'];
  autoRenew: Scalars['Boolean'];
  furballId: Scalars['String'];
  ownerId: Scalars['String'];
  playerId: Maybe<Scalars['String']>;
  startsAt: Scalars['Long'];
  percent: Scalars['Int'];
}>;

export type CreateRentalAgreementMutation = { __typename?: 'Mutation' } & {
  createRentalAgreement: { __typename?: 'RentalAgreement' } & Pick<
    RentalAgreement,
    'id' | 'duration' | 'autoRenew'
  > & {
      owner: { __typename?: 'FurAccount' } & Pick<
        FurAccount,
        'id' | 'username'
      >;
    };
};

export type EditRentalAgreementMutationVariables = Exact<{
  rentalAgreementId: Scalars['String'];
  terms: RentalTermsInput;
}>;

export type EditRentalAgreementMutation = { __typename?: 'Mutation' } & {
  editRentalAgreement: { __typename?: 'RentalAgreement' } & Pick<
    RentalAgreement,
    'id' | 'duration' | 'autoRenew'
  > & {
      owner: { __typename?: 'FurAccount' } & Pick<
        FurAccount,
        'id' | 'username'
      >;
    };
};

export type ClaimRentalAgreementMutationVariables = Exact<{
  rentalAgreementId: Scalars['String'];
}>;

export type ClaimRentalAgreementMutation = { __typename?: 'Mutation' } & {
  claimRentalAgreement: {
    __typename?: 'RentalAgreement';
  } & FurRentalAgreementFragment;
};

export type EndRentalAgreementMutationVariables = Exact<{
  rentalAgreementId: Scalars['String'];
}>;

export type EndRentalAgreementMutation = { __typename?: 'Mutation' } & {
  endRentalAgreement: {
    __typename?: 'RentalAgreement';
  } & FurRentalAgreementFragment;
};

export type CreateRandomPartyMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateRandomPartyMutation = { __typename?: 'Mutation' } & {
  createRandomParty: { __typename?: 'RandomParty' } & Pick<
    RandomParty,
    'realm'
  > & { party: { __typename?: 'GameParty' } & PlayerPartyFurballsFragment };
};

export type LeaderboardSlotFragment = { __typename?: 'LeaderboardSlot' } & Pick<
  LeaderboardSlot,
  | 'stat'
  | 'timeframe'
  | 'entityType'
  | 'overall'
  | 'score'
  | 'position'
  | 'ties'
>;

export type LeaderboardSlotFurballsFragment = {
  __typename?: 'LeaderboardSlot';
} & {
  furballs: Array<{ __typename?: 'Furball' } & FurballFragment>;
} & LeaderboardSlotFragment;

export type FurballRankFragment = { __typename?: 'FurballRank' } & Pick<
  FurballRank,
  'id'
> & {
    level: Maybe<{ __typename?: 'LeaderboardSlot' } & LeaderboardSlotFragment>;
    expRate: Maybe<
      { __typename?: 'LeaderboardSlot' } & LeaderboardSlotFragment
    >;
    furRate: Maybe<
      { __typename?: 'LeaderboardSlot' } & LeaderboardSlotFragment
    >;
  };

export type SkillDefinitionFragment = { __typename?: 'SkillDefinition' } & Pick<
  SkillDefinition,
  | 'id'
  | 'name'
  | 'summary'
  | 'description'
  | 'icon'
  | 'maxLevel'
  | 'animationId'
  | 'maxUsesBoost'
>;

export type EntityBattleStatsFragment = { __typename?: 'StatEntity' } & Pick<
  StatEntity,
  | 'id'
  | 'position'
  | 'phase'
  | 'maxHealth'
  | 'attackPower'
  | 'defencePower'
  | 'heal'
  | 'buff'
  | 'deBuff'
  | 'critRate'
  | 'critAttackPower'
  | 'speed'
  | 'level'
  | 'zone'
>;

export type EntitySkillFragment = { __typename?: 'EntitySkill' } & Pick<
  EntitySkill,
  | 'id'
  | 'slotIndex'
  | 'skillDefinitionId'
  | 'summary'
  | 'level'
  | 'levelIsUpgradeable'
  | 'levelPartialCost'
  | 'levelTotalPartialsRemaining'
  | 'levelPartialUpgrade'
  | 'levelNumPartialsPerTier'
  | 'levelUpgradedAt'
  | 'maxUsesBoost'
  | 'maxUsesPartialUpgrade'
  | 'maxUsesPartialCost'
  | 'maxUsesTotalPartialsRemaining'
  | 'maxUsesIsUpgradeable'
  | 'maxUses'
  | 'maxUsesNumPartialsPerTier'
  | 'maxUsesUpgradedAt'
  | 'valueUnits'
  | 'value'
  | 'buffType'
>;

export type BattleStatsQueryVariables = Exact<{
  tokenId: Scalars['String'];
}>;

export type BattleStatsQuery = { __typename?: 'Query' } & {
  furball: { __typename?: 'Furball' } & Pick<Furball, 'id'> & {
      battleStats: { __typename?: 'StatEntity' } & {
        skills: Array<{ __typename?: 'EntitySkill' } & EntitySkillFragment>;
      } & EntityBattleStatsFragment;
    };
};

export type UpgradeSkillMutationVariables = Exact<{
  furballId: Scalars['String'];
  skillId: Scalars['String'];
  levelPartials: Scalars['Int'];
  maxUsesPartials: Scalars['Int'];
}>;

export type UpgradeSkillMutation = { __typename?: 'Mutation' } & {
  upgradeSkill: { __typename?: 'Furball' } & {
    battleStats: { __typename?: 'StatEntity' } & {
      skills: Array<{ __typename?: 'EntitySkill' } & EntitySkillFragment>;
    };
  };
};

export type RollSkillMutationVariables = Exact<{
  furballId: Scalars['String'];
  slotIndex: Scalars['Int'];
}>;

export type RollSkillMutation = { __typename?: 'Mutation' } & {
  rollSkill: { __typename?: 'Furball' } & Pick<Furball, 'id'> & {
      battleStats: { __typename?: 'StatEntity' } & {
        skills: Array<{ __typename?: 'EntitySkill' } & EntitySkillFragment>;
      };
    };
};

export type UpgradeLootMutationVariables = Exact<{
  chances: Scalars['Int'];
  itemId: Scalars['String'];
}>;

export type UpgradeLootMutation = { __typename?: 'Mutation' } & {
  upgradeLoot: { __typename?: 'FurballLootUpgradeResponse' } & {
    furball: Maybe<{ __typename?: 'Furball' } & Pick<Furball, 'id'>>;
    upgradedItem: Maybe<
      | ({ __typename?: 'ConsumableItem' } & Items_ConsumableItem_Fragment)
      | ({ __typename?: 'ContainerItem' } & Items_ContainerItem_Fragment)
      | ({ __typename?: 'EquipmentItem' } & Items_EquipmentItem_Fragment)
      | ({ __typename?: 'LootItem' } & Items_LootItem_Fragment)
      | ({ __typename?: 'MaterialItem' } & Items_MaterialItem_Fragment)
      | ({ __typename?: 'SpecialItem' } & Items_SpecialItem_Fragment)
    >;
  };
};

export type PipeLogsMutationVariables = Exact<{
  logBundle: LogBundleInput;
}>;

export type PipeLogsMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'pipeLogs'
>;

export const BattleEffectPassiveFragmentDoc = gql`
  fragment BattleEffectPassive on BattleEffectPassive {
    id
    value
    operand
    stat
  }
`;
export const EquipmentEffectFragmentDoc = gql`
  fragment EquipmentEffect on IBattleEffect {
    ... on BattleEffectPassive {
      ...BattleEffectPassive
    }
    ... on BattleMoveOutcome {
      id
      stat
      value
      operand
    }
    ... on EntityEffect {
      id
      stat
      value
      operand
    }
  }
  ${BattleEffectPassiveFragmentDoc}
`;
export const FurOAuthTokenFragmentDoc = gql`
  fragment FurOAuthToken on FurOAuthToken {
    id
    owner
    access
    deadline
    signature
  }
`;
export const FurSessionFragmentDoc = gql`
  fragment FurSession on FurSession {
    id
    accountId
    oAuthToken {
      ...FurOAuthToken
    }
  }
  ${FurOAuthTokenFragmentDoc}
`;
export const FurPlayerBaseFragmentDoc = gql`
  fragment FurPlayerBase on FurPlayer {
    __typename
    id
    socialName
    username
    avatar
    wFur
    tix
    waitlistFlags
    communityFlags
    bossBattleCount
    referralCount
    rentedFurballCount
    numRentalCancellationsOwner
    numRentalCancellationsPlayer
    createdAt
  }
`;
export const FurballCoreInfoFragmentDoc = gql`
  fragment FurballCoreInfo on Furball {
    id
    tokenId
    partyId
    ownerId
    specialization
    dailyBattleCount
    maxDailyBattles
    name
    level
    maxLevel
    maxExp
    expRate
    furRate
    backgroundColor
    zone
    wExp
    karma
    marketListingCount
    battleCount
    lastBattleAt
    hearts
    lastHeartLostAt
    skillRollCost
    skillUpgradesAvailable
    pendingExp
    pendingFur
    number
    birth
    name
    backgroundColor
    last
    realm
    rarity
    intervals
    timekeeperDisabled
  }
`;
export const ContainerItemFragmentDoc = gql`
  fragment ContainerItem on ContainerItem {
    id
    itemId
    stack
    maxStack
    rarity
    itemGroup
  }
`;
export const ConsumableItemFragmentDoc = gql`
  fragment ConsumableItem on ConsumableItem {
    id
    itemId
    name
    stack
    maxStack
    rarity
    itemGroup
  }
`;
export const EquipmentItemFragmentDoc = gql`
  fragment EquipmentItem on EquipmentItem {
    id
    itemId
    stack
    maxStack
    itemGroup
    slot
    position
    durabilityTime
    breakAt
    rarity
    equippedById
    equippedAt
    passives {
      ...BattleEffectPassive
    }
  }
  ${BattleEffectPassiveFragmentDoc}
`;
export const LootItemFragmentDoc = gql`
  fragment LootItem on LootItem {
    id
    itemId
    stack
    maxStack
    rarity
    itemGroup
  }
`;
export const MaterialItemFragmentDoc = gql`
  fragment MaterialItem on MaterialItem {
    id
    itemId
    stack
    maxStack
    rarity
    itemGroup
  }
`;
export const SpecialItemFragmentDoc = gql`
  fragment SpecialItem on SpecialItem {
    id
    itemId
    stack
    maxStack
    rarity
    itemGroup
  }
`;
export const ItemsFragmentDoc = gql`
  fragment Items on GameItem {
    __typename
    ... on ContainerItem {
      ...ContainerItem
    }
    ... on ConsumableItem {
      ...ConsumableItem
    }
    ... on EquipmentItem {
      ...EquipmentItem
    }
    ... on LootItem {
      ...LootItem
    }
    ... on MaterialItem {
      ...MaterialItem
    }
    ... on SpecialItem {
      ...SpecialItem
    }
  }
  ${ContainerItemFragmentDoc}
  ${ConsumableItemFragmentDoc}
  ${EquipmentItemFragmentDoc}
  ${LootItemFragmentDoc}
  ${MaterialItemFragmentDoc}
  ${SpecialItemFragmentDoc}
`;
export const InventoryItemsFragmentDoc = gql`
  fragment InventoryItems on ContainerItem {
    __typename
    id
    items {
      ...Items
    }
  }
  ${ItemsFragmentDoc}
`;
export const FurballEquipmentFragmentDoc = gql`
  fragment FurballEquipment on EquipmentItem {
    id
    itemId
    slot
    position
    durabilityTime
    breakAt
    rarity
    stack
    equippedById
    equippedAt
    passives {
      ...BattleEffectPassive
    }
  }
  ${BattleEffectPassiveFragmentDoc}
`;
export const EntitySkillFragmentDoc = gql`
  fragment EntitySkill on EntitySkill {
    id
    slotIndex
    skillDefinitionId
    summary
    level
    levelIsUpgradeable
    levelPartialCost
    levelTotalPartialsRemaining
    levelPartialUpgrade
    levelNumPartialsPerTier
    levelUpgradedAt
    maxUsesBoost
    maxUsesPartialUpgrade
    maxUsesPartialCost
    maxUsesTotalPartialsRemaining
    maxUsesIsUpgradeable
    maxUses
    maxUsesNumPartialsPerTier
    maxUsesUpgradedAt
    valueUnits
    value
    buffType
    skillDefinitionId
  }
`;
export const EntityBattleStatsFragmentDoc = gql`
  fragment EntityBattleStats on StatEntity {
    id
    position
    phase
    maxHealth
    attackPower
    defencePower
    heal
    buff
    deBuff
    critRate
    critAttackPower
    speed
    level
    zone
  }
`;
export const FurballBattleStatsFragmentDoc = gql`
  fragment FurballBattleStats on StatEntity {
    id
    elixir {
      ...ConsumableItem
    }
    skills {
      ...EntitySkill
    }
    ...EntityBattleStats
  }
  ${ConsumableItemFragmentDoc}
  ${EntitySkillFragmentDoc}
  ${EntityBattleStatsFragmentDoc}
`;
export const FurballStateFragmentDoc = gql`
  fragment FurballState on FurballState {
    id
    totalRarity
    totalExp
    furRate
    furRateBase
    expRate
    happiness
    bonded
    bondingDays
    teamSize
    expPercentBase
    furPercentBase
  }
`;
export const FurPlayerMinimalFragmentDoc = gql`
  fragment FurPlayerMinimal on IFurPlayer {
    id
    socialName
    username
    avatar
  }
`;
export const FurRentalAgreementFragmentDoc = gql`
  fragment FurRentalAgreement on RentalAgreement {
    id
    startsAt
    duration
    autoRenew
    percent
    ownerPlayer {
      ...FurPlayerMinimal
    }
    renterPlayer {
      ...FurPlayerMinimal
    }
    rewardCount
    playerId
    beganAt
    endedAt
    signature
    furballId
    wFurEarned
    pendingCancellation
  }
  ${FurPlayerMinimalFragmentDoc}
`;
export const FurballUpgradeBattleStatsFragmentDoc = gql`
  fragment FurballUpgradeBattleStats on FurballUpgradeBattleStats {
    maxHealth
    attackPower
    defencePower
    heal
    buff
    deBuff
    critAttackPower
    critRate
    speed
  }
`;
export const FurballUpgradeProgressFragmentDoc = gql`
  fragment FurballUpgradeProgress on FurballUpgradeProgress {
    level
    expRate
    furRate
    battleStats {
      ...FurballUpgradeBattleStats
    }
  }
  ${FurballUpgradeBattleStatsFragmentDoc}
`;
export const FurballUpgradeSkillFragmentDoc = gql`
  fragment FurballUpgradeSkill on FurballUpgradeSkill {
    skillId
    level
    maxUses
    slotIndex
  }
`;
export const FurballUpgradeEquipmentFragmentDoc = gql`
  fragment FurballUpgradeEquipment on FurballUpgradeEquippedItem {
    itemDefinitionId
    durabilityTime
    equippedAt
    slotIndex
  }
`;
export const FurballUpgradeFragmentDoc = gql`
  fragment FurballUpgrade on FurballUpgrade {
    id
    date
    furballId
    senderId
    deadline
    fuel
    createdAt
    signedAt
    isEmpty
    isPending
    writeBackRequired
    canUnSchedule
    endsAt
    progress {
      ...FurballUpgradeProgress
    }
    skills {
      ...FurballUpgradeSkill
    }
    equipment {
      ...FurballUpgradeEquipment
    }
    equipmentStats {
      ...FurballUpgradeBattleStats
    }
  }
  ${FurballUpgradeProgressFragmentDoc}
  ${FurballUpgradeSkillFragmentDoc}
  ${FurballUpgradeEquipmentFragmentDoc}
  ${FurballUpgradeBattleStatsFragmentDoc}
`;
export const FurballBaseFragmentDoc = gql`
  fragment FurballBase on Furball {
    ...FurballCoreInfo
    inventory {
      ...InventoryItems
    }
    equipment {
      ...FurballEquipment
    }
    battleStats {
      ...FurballBattleStats
    }
    state {
      ...FurballState
    }
    openRentalAgreement {
      ...FurRentalAgreement
    }
    activeRentalAgreement {
      ...FurRentalAgreement
    }
    pendingUpgrade {
      ...FurballUpgrade
    }
  }
  ${FurballCoreInfoFragmentDoc}
  ${InventoryItemsFragmentDoc}
  ${FurballEquipmentFragmentDoc}
  ${FurballBattleStatsFragmentDoc}
  ${FurballStateFragmentDoc}
  ${FurRentalAgreementFragmentDoc}
  ${FurballUpgradeFragmentDoc}
`;
export const VoyageBaseFragmentDoc = gql`
  fragment VoyageBase on Voyage {
    id
    sender
    realm
    state
    totalFurCost
    daysComplete
    daysDuration
    percentComplete
    encountersFuelCost
    encountersFurGained
    maxLootItems
  }
`;
export const VoyageEncounterBaseFragmentDoc = gql`
  fragment VoyageEncounterBase on VoyageEncounter {
    id
    date
    encounterType
    voyageDayNumber
    subType
    outcome
    decision
    sender
    tokenId
    fuelCost
    furCost
    furGained
    didPlayerComplete
    createdAt
    updatedAt
  }
`;
export const VoyageEncounterFragmentDoc = gql`
  fragment VoyageEncounter on VoyageEncounter {
    ...VoyageEncounterBase
    match {
      ...VoyageEncounterBase
    }
  }
  ${VoyageEncounterBaseFragmentDoc}
`;
export const VoyageFragmentDoc = gql`
  fragment Voyage on Voyage {
    ...VoyageBase
    activeEncounter {
      ...VoyageEncounter
    }
    lastEncounter {
      ...VoyageEncounter
    }
    encounters {
      ...VoyageEncounter
    }
  }
  ${VoyageBaseFragmentDoc}
  ${VoyageEncounterFragmentDoc}
`;
export const FurballFragmentDoc = gql`
  fragment Furball on Furball {
    ...FurballBase
    activeVoyage {
      ...Voyage
    }
    lastVoyage {
      ...Voyage
    }
  }
  ${FurballBaseFragmentDoc}
  ${VoyageFragmentDoc}
`;
export const OtherPlayerFragmentDoc = gql`
  fragment OtherPlayer on FurPlayer {
    id
    ...FurPlayerBase
    furballs: usableFurballs {
      ...Furball
    }
  }
  ${FurPlayerBaseFragmentDoc}
  ${FurballFragmentDoc}
`;
export const FurPlayerBalanceFragmentDoc = gql`
  fragment FurPlayerBalance on FurPlayer {
    ...FurPlayerBase
    tix
    tixBought
    tixSpent
    furBalance
    furballBalance
  }
  ${FurPlayerBaseFragmentDoc}
`;
export const PayoutFragmentDoc = gql`
  fragment Payout on PlayerPayout {
    id
    ethAmount
    tixRatio
    payoutMethod
    transactionId
    createdAt
  }
`;
export const CraftSlotBaseFragmentDoc = gql`
  fragment CraftSlotBase on CraftSlot {
    id
    crafterId
    isActive
    isLocked
    slotIndex
    fuelCost
    unlockCost
    unlockedAt
  }
`;
export const IngredientFragmentDoc = gql`
  fragment Ingredient on CraftRecipeIngredient {
    itemType
    rarity
    amount
    realm
  }
`;
export const RecipeFragmentDoc = gql`
  fragment Recipe on CraftingRecipe {
    id
    duration
    itemId
    ingredients {
      ...Ingredient
    }
  }
  ${IngredientFragmentDoc}
`;
export const PlayerPendingRewardFragmentDoc = gql`
  fragment PlayerPendingReward on PlayerPendingReward {
    id
    expiresAt
    source
    sourcePosition
    notificationId
    claimedAt
    items {
      ...Items
    }
    createdAt
  }
  ${ItemsFragmentDoc}
`;
export const CraftingAttemptFragmentDoc = gql`
  fragment CraftingAttempt on CraftingAttempt {
    id
    completesAt
    createdAt
    duration
    isComplete
    speedUpCostPerHour
    speedUpDuration
    playerId
    recipeId
    recipe {
      ...Recipe
    }
    pendingRewardId
    pendingReward {
      ...PlayerPendingReward
    }
  }
  ${RecipeFragmentDoc}
  ${PlayerPendingRewardFragmentDoc}
`;
export const CraftSlotFragmentDoc = gql`
  fragment CraftSlot on CraftSlot {
    ...CraftSlotBase
    currentAttempt {
      ...CraftingAttempt
    }
  }
  ${CraftSlotBaseFragmentDoc}
  ${CraftingAttemptFragmentDoc}
`;
export const ItemQuantityFragmentDoc = gql`
  fragment ItemQuantity on ItemQuantity {
    itemId
    quantity
  }
`;
export const InventoryQuantitiesFragmentDoc = gql`
  fragment InventoryQuantities on ContainerItem {
    __typename
    id
    totalDustCount
    itemQuantities {
      ...ItemQuantity
    }
  }
  ${ItemQuantityFragmentDoc}
`;
export const QuestWaypointCompletionFragmentDoc = gql`
  fragment QuestWaypointCompletion on QuestWaypointCompletion {
    id
    waypointName
    reward {
      ...PlayerPendingReward
    }
    createdAt
  }
  ${PlayerPendingRewardFragmentDoc}
`;
export const QuestFragmentDoc = gql`
  fragment Quest on Quest {
    id
    questName
    completedWaypoints {
      ...QuestWaypointCompletion
    }
    reward {
      ...PlayerPendingReward
    }
    createdAt
  }
  ${QuestWaypointCompletionFragmentDoc}
  ${PlayerPendingRewardFragmentDoc}
`;
export const SocialProfileFragmentDoc = gql`
  fragment SocialProfile on SocialProfile {
    id
    username
    avatar
    source
    __typename
  }
`;
export const OnboardingFragmentDoc = gql`
  fragment Onboarding on PlayerOnboardingStep {
    id
    playerId
    sequence
    stepProgress
    isComplete
    createdAt
  }
`;
export const CurrentPlayerFragmentDoc = gql`
  fragment CurrentPlayer on FurPlayer {
    ...FurPlayerBalance
    payouts {
      ...Payout
    }
    craftingSlots {
      ...CraftSlot
    }
    inventory {
      ...InventoryQuantities
    }
    quests {
      ...Quest
    }
    socialProfiles {
      ...SocialProfile
    }
    onboarding {
      ...Onboarding
    }
  }
  ${FurPlayerBalanceFragmentDoc}
  ${PayoutFragmentDoc}
  ${CraftSlotFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
  ${QuestFragmentDoc}
  ${SocialProfileFragmentDoc}
  ${OnboardingFragmentDoc}
`;
export const PlayerSessionFragmentDoc = gql`
  fragment PlayerSession on FurSession {
    id
    accountId
    oAuthToken {
      id
      owner
      signature
      access
      deadline
    }
  }
`;
export const LoginResponseFragmentDoc = gql`
  fragment LoginResponse on LoginResponse {
    secret
    session {
      ...PlayerSession
    }
  }
  ${PlayerSessionFragmentDoc}
`;
export const TimekeeperWrapFurFragmentDoc = gql`
  fragment TimekeeperWrapFur on TimekeeperWrapFur {
    id
    fuelCost
    isAccepted
    hasRun
    gained
    spent
    value
    resolvedAt
    createdAt
    updatedAt
    failureCount
    transactionHash
  }
`;
export const GameBuildFragmentDoc = gql`
  fragment GameBuild on GameBuild {
    id
    webCodeFileHash
    webDataFileHash
    webFrameworkFileHash
    semver
    createdAt
  }
`;
export const LeaderboardTierRewardChanceFragmentDoc = gql`
  fragment LeaderboardTierRewardChance on LeaderboardTierRewardChance {
    rewardId
    rewardType
    rewardQuantity
    percentChance
  }
`;
export const TierRewardsFragmentDoc = gql`
  fragment TierRewards on LeaderboardTierRewards {
    id
    bossDustAmount
    furAmount
    poolSplit
    entityMax
    possibleRewards {
      ...LeaderboardTierRewardChance
    }
  }
  ${LeaderboardTierRewardChanceFragmentDoc}
`;
export const BossLeaderboardSlotFragmentDoc = gql`
  fragment BossLeaderboardSlot on LeaderboardSlot {
    stat
    score
    position
    players {
      ...FurPlayerBase
    }
  }
  ${FurPlayerBaseFragmentDoc}
`;
export const BossLeaderboardTiersConnectionFragmentDoc = gql`
  fragment BossLeaderboardTiersConnection on TiersConnection {
    nodes {
      id
      position
      entityCount
      entityMax
      scoreMax
      scoreMin
      rewards {
        ...TierRewards
      }
      slots {
        ...BossLeaderboardSlot
      }
    }
  }
  ${TierRewardsFragmentDoc}
  ${BossLeaderboardSlotFragmentDoc}
`;
export const LeaderboardFragmentDoc = gql`
  fragment Leaderboard on Leaderboard {
    id
    rewards {
      ...TierRewards
    }
    tiers {
      ...BossLeaderboardTiersConnection
    }
  }
  ${TierRewardsFragmentDoc}
  ${BossLeaderboardTiersConnectionFragmentDoc}
`;
export const TournamentParticipantFragmentDoc = gql`
  fragment TournamentParticipant on TournamentParticipant {
    id
    playerId
    wFurEarned
    totalScore
  }
`;
export const TournamentFragmentDoc = gql`
  fragment Tournament on Tournament {
    id
    name
    realm
    leaderboard {
      ...Leaderboard
    }
    scoringStyle
    startsAt
    endsAt
    entryFeeFuel
    entryFeeTix
    payoutPoolEth
    heroUrl
    backgroundColor
    currentParticipant {
      ...TournamentParticipant
    }
    isParticipant
  }
  ${LeaderboardFragmentDoc}
  ${TournamentParticipantFragmentDoc}
`;
export const TimekeeperCostsFragmentDoc = gql`
  fragment TimekeeperCosts on TimekeeperCosts {
    date
    factor
    spendTickets
    spendFur
    collectFur
    collectExp1
    collectExpN
    move1
    moveN
    snack1
    snackN
    upgrade1
    upgradeN
    mint1
    mintN
    dropLoot
    completeVoyage
    enterVoyages
    upgradeSkill
  }
`;
export const TimekeeperRoundFragmentDoc = gql`
  fragment TimekeeperRound on TimekeeperRound {
    date
    state
    ethPrice
    gasPrice
    costs {
      ...TimekeeperCosts
    }
  }
  ${TimekeeperCostsFragmentDoc}
`;
export const InteractionCostFragmentDoc = gql`
  fragment InteractionCost on TimekeeperInteraction {
    furballIds
    furballFuel
    interactionType
    fuelCost
    gasEstimate
    expectedValue
    count
    id
    sourceInteractionId
  }
`;
export const ChangeSetFragmentDoc = gql`
  fragment ChangeSet on TimekeeperChangeSet {
    date
    fuelCost
    gained
    spent
    mintEdition
    mintCount
    movementList
    collectStayList
    interactions {
      ...InteractionCost
    }
  }
  ${InteractionCostFragmentDoc}
`;
export const TimekeeperRequestBaseFragmentDoc = gql`
  fragment TimekeeperRequestBase on TimekeeperRequest {
    date
    sender
    partialIndex
    state
    hasChanges
    furReal
    changeSet {
      ...ChangeSet
    }
    signedRequest {
      ...ChangeSet
    }
    resolvedAt
    createdAt
    updatedAt
  }
  ${ChangeSetFragmentDoc}
`;
export const TimekeeperResolutionFragmentDoc = gql`
  fragment TimekeeperResolution on TimekeeperResolution {
    tokenId
    date
    furGained
    expGained
    intervals
    zoneNumber
    isEmpty
    itemList
    createdAt
    updatedAt
    completedAt
  }
`;
export const TimekeeperRequestFragmentDoc = gql`
  fragment TimekeeperRequest on TimekeeperRequest {
    ...TimekeeperRequestBase
    resolutions {
      ...TimekeeperResolution
    }
  }
  ${TimekeeperRequestBaseFragmentDoc}
  ${TimekeeperResolutionFragmentDoc}
`;
export const TimekeeperUserStateFragmentDoc = gql`
  fragment TimekeeperUserState on TimekeeperUserState {
    round {
      ...TimekeeperRound
    }
    current {
      ...TimekeeperRequest
    }
    pending {
      ...TimekeeperRequest
    }
  }
  ${TimekeeperRoundFragmentDoc}
  ${TimekeeperRequestFragmentDoc}
`;
export const DutchAuctionFragmentDoc = gql`
  fragment DutchAuction on DutchAuction {
    id
    playerId
    currentCost
    startingCost
    lastCost
    soldAt
    sequence
  }
`;
export const FurballsMetaFragmentDoc = gql`
  fragment FurballsMeta on FurballsMeta {
    buildNumber
    environment
    hostname
    subdomain
    gameBuild {
      ...GameBuild
    }
    activeTournaments {
      ...Tournament
    }
    timekeeperUserState {
      ...TimekeeperUserState
    }
    mintAuction {
      ...DutchAuction
    }
    mintAuctionPurchaseCount
    lootBox {
      myLastPurchaseAt
    }
    ethPrice
    clientSpec {
      referenceDpi
    }
  }
  ${GameBuildFragmentDoc}
  ${TournamentFragmentDoc}
  ${TimekeeperUserStateFragmentDoc}
  ${DutchAuctionFragmentDoc}
`;
export const FurQuoteFragmentDoc = gql`
  fragment FurQuote on FurFundingLot {
    id
    total
    funding
    amountRemaining
    fuelCostEach
    minimumPurchaseQuantity
  }
`;
export const EthExchangeFragmentDoc = gql`
  fragment EthExchange on EthExchange {
    usdPrice
  }
`;
export const CurrencyCostFragmentDoc = gql`
  fragment CurrencyCost on CurrencyCost {
    fuel
    tix
    usd
  }
`;
export const CurrencyCostsFragmentDoc = gql`
  fragment CurrencyCosts on CurrencyCosts {
    costBase {
      ...CurrencyCost
    }
    costEach {
      ...CurrencyCost
    }
  }
  ${CurrencyCostFragmentDoc}
`;
export const FurExchangeFragmentDoc = gql`
  fragment FurExchange on FurExchange {
    amountAvailable
    costBuy {
      ...CurrencyCosts
    }
    costSwap {
      ...CurrencyCosts
    }
  }
  ${CurrencyCostsFragmentDoc}
`;
export const TixExchangeFragmentDoc = gql`
  fragment TixExchange on TixExchange {
    costBuy {
      ...CurrencyCosts
    }
  }
  ${CurrencyCostsFragmentDoc}
`;
export const ExchangeRatesFragmentDoc = gql`
  fragment ExchangeRates on CurrencyExchangeRates {
    eth {
      ...EthExchange
    }
    fur {
      ...FurExchange
    }
    tix {
      ...TixExchange
    }
    gasPrice
  }
  ${EthExchangeFragmentDoc}
  ${FurExchangeFragmentDoc}
  ${TixExchangeFragmentDoc}
`;
export const FurRentalAgreementRewardsFragmentDoc = gql`
  fragment FurRentalAgreementRewards on RentalAgreement {
    ...FurRentalAgreement
    rewards {
      ...PlayerPendingReward
    }
  }
  ${FurRentalAgreementFragmentDoc}
  ${PlayerPendingRewardFragmentDoc}
`;
export const BattleHistoryPartyFragmentDoc = gql`
  fragment BattleHistoryParty on GameParty {
    id
    name
    size
  }
`;
export const BattleHistoryWorldBossFragmentDoc = gql`
  fragment BattleHistoryWorldBoss on GameBoss {
    id
    name
    level
    realm
  }
`;
export const BossBattleHistoryPlayerFragmentDoc = gql`
  fragment BossBattleHistoryPlayer on GameBattleBoss {
    id
    score
    furballIds
    isTrialGame
    duration
    createdAt
    playerParty {
      ...BattleHistoryParty
    }
    worldBoss {
      ...BattleHistoryWorldBoss
    }
  }
  ${BattleHistoryPartyFragmentDoc}
  ${BattleHistoryWorldBossFragmentDoc}
`;
export const HistoryDaysFragmentDoc = gql`
  fragment HistoryDays on PlayerHistoryDay {
    id
    leaderboardTotalScore
    percentileRankOverall
    percentileRankTop
    startDate
    nextDate
    bossBattles {
      ...BossBattleHistoryPlayer
    }
  }
  ${BossBattleHistoryPlayerFragmentDoc}
`;
export const PageInfoFragmentDoc = gql`
  fragment PageInfo on PageInfo {
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
export const HistoryFragmentDoc = gql`
  fragment History on PlayerHistoryConnection {
    totalCount
    nodes {
      ...HistoryDays
    }
    pageInfo {
      ...PageInfo
    }
  }
  ${HistoryDaysFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export const BossLeaderboardSlotConnectionFragmentDoc = gql`
  fragment BossLeaderboardSlotConnection on SlotsConnection {
    nodes {
      ...BossLeaderboardSlot
    }
    pageInfo {
      ...PageInfo
    }
    totalCount
  }
  ${BossLeaderboardSlotFragmentDoc}
  ${PageInfoFragmentDoc}
`;
export const LeaderboardSlotPlayerFragmentDoc = gql`
  fragment LeaderboardSlotPlayer on LeaderboardSlotPlayer {
    leaderGroup
    score
    stat
    timeframe
  }
`;
export const LeaderboardRankPlayerFragmentDoc = gql`
  fragment LeaderboardRankPlayer on LeaderboardRankPlayer {
    id
    slot {
      ...LeaderboardSlotPlayer
    }
  }
  ${LeaderboardSlotPlayerFragmentDoc}
`;
export const NextMoveFragmentDoc = gql`
  fragment NextMove on BattleMoveNext {
    id
    moveKind
    moveId
    targetIds
    entityMoveNumber
    createdAt
  }
`;
export const BattleStatsFragmentDoc = gql`
  fragment BattleStats on BattleStats {
    maxHealth
    attackPower
    defencePower
    heal
    buff
    deBuff
    critRate
    critAttackPower
    speed
    vamp
    revive
  }
`;
export const GameEntityEffectFragmentDoc = gql`
  fragment GameEntityEffect on EntityEffect {
    id
    value
    turnsRemaining
    createdAt
    deductAt
    activeAt
    removedAt
    skillId
    skillEffectId
  }
`;
export const BattleStatEntityFragmentDoc = gql`
  fragment BattleStatEntity on StatEntity {
    id
    curHealth
    maxHealth
    attackPower
    defencePower
    heal
    buff
    deBuff
    critRate
    critAttackPower
    speed
    vamp
    revive
    position
    nextMoveAt
    lastMoveAt
    pauseDuration
    pausedAt
    clockPausedUntil
    moveCount
    karma
    currentRealm
    partyId
    hearts
    nextMove {
      ...NextMove
    }
    currentStats {
      ...BattleStats
    }
    effects {
      ...GameEntityEffect
    }
    loadedAt
  }
  ${NextMoveFragmentDoc}
  ${BattleStatsFragmentDoc}
  ${GameEntityEffectFragmentDoc}
`;
export const GamePartyFragmentDoc = gql`
  fragment GameParty on GameParty {
    id
    playerId
    name
    aiEnabled
    partyMembers {
      ...BattleStatEntity
    }
  }
  ${BattleStatEntityFragmentDoc}
`;
export const AnteFragmentDoc = gql`
  fragment Ante on AntePool {
    id
    furAmount
    furPayoutId
  }
`;
export const GameBattleRulesFragmentDoc = gql`
  fragment GameBattleRules on GameBattleRules {
    id
    maxLevel
    maxSkillUpgradePercent
  }
`;
export const GameEntitySummaryFragmentDoc = gql`
  fragment GameEntitySummary on GameEntitySummary {
    id
    statEntityId
    gameEntityId
    gameBattleId
    damageTotal
    damageTaken
    moveCount
    expGained
    levelsGained
  }
`;
export const BossBattleFragmentDoc = gql`
  fragment BossBattle on GameBattleBoss {
    id
    playerId
    furEarned
    battleState
    score
    combo
    leaderboardGroup
    parties {
      id
      partyMembers {
        ...BattleStatEntity
      }
    }
    outcomeSummaries {
      ...GameEntitySummary
    }
    ntp
  }
  ${BattleStatEntityFragmentDoc}
  ${GameEntitySummaryFragmentDoc}
`;
export const MonsterBattleFragmentDoc = gql`
  fragment MonsterBattle on GameBattleMonsters {
    id
    playerId
    furEarned
    battleState
    score
    combo
    leaderboardGroup
    parties {
      id
      partyMembers {
        ...BattleStatEntity
      }
    }
    outcomeSummaries {
      ...GameEntitySummary
    }
    itemQuantitiesEarned {
      ...ItemQuantity
    }
    ntp
    difficultyLevel
    battleRealm
    monstersKilled
  }
  ${BattleStatEntityFragmentDoc}
  ${GameEntitySummaryFragmentDoc}
  ${ItemQuantityFragmentDoc}
`;
export const DuelBattleFragmentDoc = gql`
  fragment DuelBattle on GameBattleDuel {
    id
    creatorId
    battleState
    score
    combo
    leaderboardGroup
    parties {
      id
      partyMembers {
        ...BattleStatEntity
      }
    }
    outcomeSummaries {
      ...GameEntitySummary
    }
    ntp
    battleRealm
  }
  ${BattleStatEntityFragmentDoc}
  ${GameEntitySummaryFragmentDoc}
`;
export const GameEntitySkillFragmentDoc = gql`
  fragment GameEntitySkill on EntitySkill {
    id
    level
    useCount
    slotIndex
    maxUsesBoost
    skillDefinitionId
  }
`;
export const BattleMoveOutcomeFragmentDoc = gql`
  fragment BattleMoveOutcome on BattleMoveOutcome {
    id
    value
    operand
    stat
    flags
    entityId
    effect {
      ...GameEntityEffect
    }
  }
  ${GameEntityEffectFragmentDoc}
`;
export const BattleMoveFragmentDoc = gql`
  fragment BattleMove on BattleMove {
    id
    battleState
    moveKind
    actorId
    actor {
      id
      skills {
        ...GameEntitySkill
      }
    }
    skillId
    skill {
      ...GameEntitySkill
    }
    itemId
    createdAt
    outcomes {
      ...BattleMoveOutcome
    }
    expGained
    levelsGained
    startsAt
    endsAt
    createdAt
    updatedAt
    ranAt
    combo
    entityMoveNumber
    ntp
    loadedAt
  }
  ${GameEntitySkillFragmentDoc}
  ${BattleMoveOutcomeFragmentDoc}
`;
export const PercentilesFragmentDoc = gql`
  fragment Percentiles on BattlePercentiles {
    minScore
    maxScore
    percentile
    position
  }
`;
export const ActiveBossFightFragmentDoc = gql`
  fragment ActiveBossFight on GameBattleBoss {
    id
    score
    player {
      ...FurPlayerMinimal
      __typename
    }
  }
  ${FurPlayerMinimalFragmentDoc}
`;
export const BattleMoveResponseFragmentDoc = gql`
  fragment BattleMoveResponse on BattleMoveResponse {
    bossBattle {
      ...BossBattle
    }
    monsterBattle {
      ...MonsterBattle
    }
    duelBattle {
      ...DuelBattle
    }
    move {
      ...BattleMove
    }
    percentiles {
      ...Percentiles
    }
    activeBossBattles {
      ...ActiveBossFight
    }
    consumableItems {
      ...ConsumableItem
    }
    createdAt
  }
  ${BossBattleFragmentDoc}
  ${MonsterBattleFragmentDoc}
  ${DuelBattleFragmentDoc}
  ${BattleMoveFragmentDoc}
  ${PercentilesFragmentDoc}
  ${ActiveBossFightFragmentDoc}
  ${ConsumableItemFragmentDoc}
`;
export const BossBattlePlayerOverviewFragmentDoc = gql`
  fragment BossBattlePlayerOverview on GameBattleBoss {
    id
    score
    createdAt
    furballIds
    player {
      ...FurPlayerBase
    }
  }
  ${FurPlayerBaseFragmentDoc}
`;
export const GameBossFragmentDoc = gql`
  fragment GameBoss on GameBoss {
    id
    realm
    level
    name
  }
`;
export const BossBattlePlayerFragmentDoc = gql`
  fragment BossBattlePlayer on GameBattleBoss {
    ...BossBattlePlayerOverview
    playerId
    furEarned
    playerDamageTotal
    worldBoss {
      ...GameBoss
    }
  }
  ${BossBattlePlayerOverviewFragmentDoc}
  ${GameBossFragmentDoc}
`;
export const BossFragmentDoc = gql`
  fragment Boss on GameBoss {
    id
    name
    realm
    level
    health
    maxHealth
  }
`;
export const PassiveEffectRangeFragmentDoc = gql`
  fragment PassiveEffectRange on PassiveEffectRange {
    valueBase
    valueRange
    operand
    stat
  }
`;
export const ItemDefinitionFragmentDoc = gql`
  fragment ItemDefinition on ItemDefinition {
    id
    icon
    itemId
    itemGroup
    durabilityTime
    name
    rarity
    realmAffiliation
    untradeable
    allowInFurballInventory
    upgradeToItemId
    passives {
      valueBase
      valueRange
      operand
      stat
    }
    recipe {
      ...Recipe
    }
  }
  ${RecipeFragmentDoc}
`;
export const CraftingAttemptChangeFragmentDoc = gql`
  fragment CraftingAttemptChange on CraftingAttempt {
    ...CraftingAttempt
    player {
      id
      inventory {
        ...InventoryQuantities
      }
    }
  }
  ${CraftingAttemptFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
`;
export const CraftSlotChangeFragmentDoc = gql`
  fragment CraftSlotChange on CraftSlot {
    ...CraftSlotBase
    currentAttempt {
      ...CraftingAttemptChange
    }
  }
  ${CraftSlotBaseFragmentDoc}
  ${CraftingAttemptChangeFragmentDoc}
`;
export const FurballDefinitionFragmentDoc = gql`
  fragment FurballDefinition on FurballDefinition {
    id
    number
    count
    rarity
    experience
    zone
    level
    weight
    birth
    trade
    last
    moves
  }
`;
export const FurballAttributeFragmentDoc = gql`
  fragment FurballAttribute on FurballAttribute {
    traitType
    displayType
    value
  }
`;
export const EditionAssetFragmentDoc = gql`
  fragment EditionAsset on EditionAsset {
    id
    assetIndex
    name
    slot
    rarityLevel
    percentBoost
    realmAffiliation
    __typename
  }
`;
export const OwnerFurballFragmentDoc = gql`
  fragment OwnerFurball on Furball {
    ...Furball
    owner {
      id
      avatar
      username
      socialName
    }
  }
  ${FurballFragmentDoc}
`;
export const FurballModificationFragmentDoc = gql`
  fragment FurballModification on FurballModification {
    id
    tokenId
    pickups
    drops
    sender
    setActiveVoyageDays
    addEncounterType
    signature
    furball {
      ...OwnerFurball
    }
  }
  ${OwnerFurballFragmentDoc}
`;
export const FurballWithPlayerInventoryFragmentDoc = gql`
  fragment FurballWithPlayerInventory on Furball {
    ...Furball
    player: owner {
      ...FurPlayerMinimal
      inventory {
        ...InventoryQuantities
      }
    }
    activeRentalAgreement {
      id
      player {
        ...FurPlayerMinimal
        inventory {
          ...InventoryQuantities
        }
      }
    }
  }
  ${FurballFragmentDoc}
  ${FurPlayerMinimalFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
`;
export const AirdropRequestFragmentDoc = gql`
  fragment AirdropRequest on AirdropRequest {
    id
    tix
    fur
    recipient
    approvedBy
    transactionId
  }
`;
export const RecentFurballBossFightFragmentDoc = gql`
  fragment RecentFurballBossFight on GameBattleBoss {
    id
    playerId
    createdAt
    duration
    playerDamageTotal
    worldBossId
    worldBoss {
      name
      level
      realm
    }
    outcomeSummaries {
      ...GameEntitySummary
    }
  }
  ${GameEntitySummaryFragmentDoc}
`;
export const PlayerInventoryQuantityFragmentDoc = gql`
  fragment PlayerInventoryQuantity on FurPlayer {
    id
    inventory {
      ...InventoryQuantities
    }
  }
  ${InventoryQuantitiesFragmentDoc}
`;
export const BuyerSellerPlayerFragmentDoc = gql`
  fragment BuyerSellerPlayer on FurPlayer {
    ...PlayerInventoryQuantity
    username
    socialName
    wFur
    avatar
  }
  ${PlayerInventoryQuantityFragmentDoc}
`;
export const MoveItemFragmentFragmentDoc = gql`
  fragment MoveItemFragment on MoveItemResult {
    fromFurball {
      ...Furball
    }
    toFurball {
      ...Furball
    }
    player {
      ...BuyerSellerPlayer
    }
  }
  ${FurballFragmentDoc}
  ${BuyerSellerPlayerFragmentDoc}
`;
export const NotificationInstanceFragmentDoc = gql`
  fragment NotificationInstance on NotificationInstance {
    id
    platform
    receipt
    recipientId
  }
`;
export const NotificationFragmentDoc = gql`
  fragment Notification on Notification {
    id
    data
    message
    recipient
    sender
    title
    purpose
    description
    thumbnailUrl
    imageUrl
    pendingReward {
      ...PlayerPendingReward
    }
    furballId
    createdAt
  }
  ${PlayerPendingRewardFragmentDoc}
`;
export const QuestWaypointBracketDefinitionFragmentDoc = gql`
  fragment QuestWaypointBracketDefinition on QuestWaypointBracketDefinition {
    percentileRank
    furAmount
    possibleRewards {
      ...LeaderboardTierRewardChance
    }
  }
  ${LeaderboardTierRewardChanceFragmentDoc}
`;
export const QuestWaypointDefinitionFragmentDoc = gql`
  fragment QuestWaypointDefinition on QuestWaypointDefinition {
    name
    style
    brackets {
      ...QuestWaypointBracketDefinition
    }
  }
  ${QuestWaypointBracketDefinitionFragmentDoc}
`;
export const QuestDefinitionFragmentDoc = gql`
  fragment QuestDefinition on QuestDefinition {
    name
    respawn
    maxCompletions
    waypoints {
      ...QuestWaypointDefinition
    }
  }
  ${QuestWaypointDefinitionFragmentDoc}
`;
export const ShopWaresFragmentDoc = gql`
  fragment ShopWares on ShopWares {
    id
    name
    furCost
    fuelCost1
    fuelCostN
    maxPerFurball
    saleBeginsAt
    saleEndsAt
    itemIds
    itemList
  }
`;
export const ShopSnackFragmentDoc = gql`
  fragment ShopSnack on ShopSnack {
    id
    name
    furCost
    fuelCost1
    fuelCostN
    happiness
    energy
    saleBeginsAt
    saleEndsAt
  }
`;
export const TimekeeperResolutionRoundFragmentDoc = gql`
  fragment TimekeeperResolutionRound on TimekeeperResolution {
    ...TimekeeperResolution
    request {
      ...TimekeeperRequestBase
    }
  }
  ${TimekeeperResolutionFragmentDoc}
  ${TimekeeperRequestBaseFragmentDoc}
`;
export const TransactionFragmentDoc = gql`
  fragment Transaction on BlockchainTransaction {
    id
    hash
    gasEstimate
    gasUsed
  }
`;
export const TimekeeperToggleFragmentDoc = gql`
  fragment TimekeeperToggle on TimekeeperToggle {
    id
    fuelCost
    furballIds
    mode
    isAccepted
    hasRun
    timekeeperDisabled
    modeChanges {
      tokenId
      lastTimestamp
    }
    transaction {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`;
export const TimekeeperTransferTixFragmentDoc = gql`
  fragment TimekeeperTransferTix on TimekeeperTransferTix {
    id
    fuelCost
    isAccepted
    hasRun
    recipient
    transaction {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`;
export const TimekeeperAdHocFragmentDoc = gql`
  fragment TimekeeperAdHoc on TimekeeperAdHoc {
    id
    sender
    fuelCost
    gained
    spent
    isAccepted
    hasRun
    createdAt
    updatedAt
    transactionHash
  }
`;
export const FurPlayerFragmentDoc = gql`
  fragment FurPlayer on FurPlayer {
    ...FurPlayerBase
  }
  ${FurPlayerBaseFragmentDoc}
`;
export const TimekeeperRenameFurballFragmentDoc = gql`
  fragment TimekeeperRenameFurball on TimekeeperRenameFurball {
    id
    furballId
    name
    transactionMessage
    transactionHash
    transaction {
      ...Transaction
    }
  }
  ${TransactionFragmentDoc}
`;
export const FurballNameChangeFragmentDoc = gql`
  fragment FurballNameChange on FurballNameChange {
    id
    name
    player {
      ...FurPlayer
    }
    furball {
      id
      name
    }
    approved
    approvedBy {
      ...FurPlayer
    }
    request {
      ...TimekeeperRenameFurball
    }
    executedAt
    createdAt
  }
  ${FurPlayerFragmentDoc}
  ${TimekeeperRenameFurballFragmentDoc}
`;
export const MoveZonesResponseFragmentDoc = gql`
  fragment MoveZonesResponse on MoveZoneResponse {
    furball {
      ...Furball
    }
    expGained
    furSpent
    furGained
    items {
      ...Items
    }
  }
  ${FurballFragmentDoc}
  ${ItemsFragmentDoc}
`;
export const CurrentBattleFragmentDoc = gql`
  fragment CurrentBattle on GameBattle {
    id
    score
    battleType
    realm
    __typename
  }
`;
export const PlayerPartyFragmentDoc = gql`
  fragment PlayerParty on GameParty {
    id
    playerId
    size
    name
    aiEnabled
    slotNumber
    filledPositions
    openPositions
    isInBattle
    currentBattleId
    maxLevels
    currentBattle {
      ...CurrentBattle
    }
    partyMembers {
      ...FurballBattleStats
    }
    formation {
      positions
    }
    player {
      ...FurPlayerBase
    }
  }
  ${CurrentBattleFragmentDoc}
  ${FurballBattleStatsFragmentDoc}
  ${FurPlayerBaseFragmentDoc}
`;
export const FurballPartyMemberFragmentDoc = gql`
  fragment FurballPartyMember on StatEntity {
    ...FurballBattleStats
    entity {
      ... on Furball {
        ...Furball
      }
    }
  }
  ${FurballBattleStatsFragmentDoc}
  ${FurballFragmentDoc}
`;
export const PlayerPartyFurballsFragmentDoc = gql`
  fragment PlayerPartyFurballs on GameParty {
    ...PlayerParty
    partyMembers {
      ...FurballPartyMember
    }
  }
  ${PlayerPartyFragmentDoc}
  ${FurballPartyMemberFragmentDoc}
`;
export const ItemListingFragmentDoc = gql`
  fragment ItemListing on PlayerItemListing {
    id
    itemDefinitionId
    itemId
    item {
      ...Items
    }
    seller {
      ...BuyerSellerPlayer
    }
    buyer {
      ...BuyerSellerPlayer
    }
    quantity
    currentPriceEach
    style
    currency
    currentPrice
    expiresAt
    soldAt
    createdAt
  }
  ${ItemsFragmentDoc}
  ${BuyerSellerPlayerFragmentDoc}
`;
export const SearchResultRentalAgreementFragmentDoc = gql`
  fragment SearchResultRentalAgreement on RentalAgreement {
    id
    playerId
  }
`;
export const FurballSearchResultFragmentDoc = gql`
  fragment FurballSearchResult on Furball {
    id
    number
    name
    backgroundColor
    level
    expRate
    furRate
    dailyBattleCount
    maxDailyBattles
    rentalAgreements {
      ...SearchResultRentalAgreement
    }
    openRentalAgreement {
      ...SearchResultRentalAgreement
    }
    inventory {
      ...InventoryQuantities
    }
    battleStats {
      ...FurballBattleStats
    }
  }
  ${SearchResultRentalAgreementFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
  ${FurballBattleStatsFragmentDoc}
`;
export const SearchResultsFragmentDoc = gql`
  fragment SearchResults on SearchFurballsConnection {
    nodes {
      ...FurballSearchResult
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
  ${FurballSearchResultFragmentDoc}
`;
export const LeaderboardSlotFragmentDoc = gql`
  fragment LeaderboardSlot on LeaderboardSlot {
    stat
    timeframe
    entityType
    overall
    score
    position
    ties
  }
`;
export const LeaderboardSlotFurballsFragmentDoc = gql`
  fragment LeaderboardSlotFurballs on LeaderboardSlot {
    ...LeaderboardSlot
    furballs {
      ...Furball
    }
  }
  ${LeaderboardSlotFragmentDoc}
  ${FurballFragmentDoc}
`;
export const FurballRankFragmentDoc = gql`
  fragment FurballRank on FurballRank {
    id
    level {
      ...LeaderboardSlot
    }
    expRate {
      ...LeaderboardSlot
    }
    furRate {
      ...LeaderboardSlot
    }
  }
  ${LeaderboardSlotFragmentDoc}
`;
export const SkillDefinitionFragmentDoc = gql`
  fragment SkillDefinition on SkillDefinition {
    id
    name
    summary
    description
    icon
    maxLevel
    animationId
    maxUsesBoost
  }
`;
export const LoginDocument = gql`
  mutation Login($token: FurOAuthTokenInput!) {
    login(token: $token) {
      ...LoginResponse
    }
  }
  ${LoginResponseFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const DisconnectSocialProfileDocument = gql`
  mutation DisconnectSocialProfile($token: FurOAuthTokenInput!) {
    disconnectSocialProfile(token: $token) {
      id
    }
  }
`;
export type DisconnectSocialProfileMutationFn = Apollo.MutationFunction<
  DisconnectSocialProfileMutation,
  DisconnectSocialProfileMutationVariables
>;

/**
 * __useDisconnectSocialProfileMutation__
 *
 * To run a mutation, you first call `useDisconnectSocialProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectSocialProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectSocialProfileMutation, { data, loading, error }] = useDisconnectSocialProfileMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDisconnectSocialProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectSocialProfileMutation,
    DisconnectSocialProfileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectSocialProfileMutation,
    DisconnectSocialProfileMutationVariables
  >(DisconnectSocialProfileDocument, options);
}
export type DisconnectSocialProfileMutationHookResult = ReturnType<
  typeof useDisconnectSocialProfileMutation
>;
export type DisconnectSocialProfileMutationResult =
  Apollo.MutationResult<DisconnectSocialProfileMutation>;
export type DisconnectSocialProfileMutationOptions = Apollo.BaseMutationOptions<
  DisconnectSocialProfileMutation,
  DisconnectSocialProfileMutationVariables
>;
export const MeDocument = gql`
  query Me($maxCache: Int) {
    currentPlayer(maxCache: $maxCache) {
      ...CurrentPlayer
    }
  }
  ${CurrentPlayerFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *      maxCache: // value for 'maxCache'
 *   },
 * });
 */
export function useMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MyFurballsDocument = gql`
  query MyFurballs {
    usableFurballs {
      ...Furball
    }
  }
  ${FurballFragmentDoc}
`;

/**
 * __useMyFurballsQuery__
 *
 * To run a query within a React component, call `useMyFurballsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFurballsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFurballsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFurballsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyFurballsQuery,
    MyFurballsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyFurballsQuery, MyFurballsQueryVariables>(
    MyFurballsDocument,
    options,
  );
}
export function useMyFurballsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyFurballsQuery,
    MyFurballsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyFurballsQuery, MyFurballsQueryVariables>(
    MyFurballsDocument,
    options,
  );
}
export type MyFurballsQueryHookResult = ReturnType<typeof useMyFurballsQuery>;
export type MyFurballsLazyQueryHookResult = ReturnType<
  typeof useMyFurballsLazyQuery
>;
export type MyFurballsQueryResult = Apollo.QueryResult<
  MyFurballsQuery,
  MyFurballsQueryVariables
>;
export const PlayerDocument = gql`
  query Player($query: String!) {
    player(query: $query) {
      ...OtherPlayer
      furballs: usableFurballs {
        ...Furball
      }
    }
  }
  ${OtherPlayerFragmentDoc}
  ${FurballFragmentDoc}
`;

/**
 * __usePlayerQuery__
 *
 * To run a query within a React component, call `usePlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function usePlayerQuery(
  baseOptions: Apollo.QueryHookOptions<PlayerQuery, PlayerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlayerQuery, PlayerQueryVariables>(
    PlayerDocument,
    options,
  );
}
export function usePlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PlayerQuery, PlayerQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlayerQuery, PlayerQueryVariables>(
    PlayerDocument,
    options,
  );
}
export type PlayerQueryHookResult = ReturnType<typeof usePlayerQuery>;
export type PlayerLazyQueryHookResult = ReturnType<typeof usePlayerLazyQuery>;
export type PlayerQueryResult = Apollo.QueryResult<
  PlayerQuery,
  PlayerQueryVariables
>;
export const ScholarDocument = gql`
  query Scholar($query: String!) {
    scholar(query: $query) {
      ...OtherPlayer
    }
  }
  ${OtherPlayerFragmentDoc}
`;

/**
 * __useScholarQuery__
 *
 * To run a query within a React component, call `useScholarQuery` and pass it any options that fit your needs.
 * When your component renders, `useScholarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScholarQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useScholarQuery(
  baseOptions: Apollo.QueryHookOptions<ScholarQuery, ScholarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ScholarQuery, ScholarQueryVariables>(
    ScholarDocument,
    options,
  );
}
export function useScholarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScholarQuery,
    ScholarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ScholarQuery, ScholarQueryVariables>(
    ScholarDocument,
    options,
  );
}
export type ScholarQueryHookResult = ReturnType<typeof useScholarQuery>;
export type ScholarLazyQueryHookResult = ReturnType<typeof useScholarLazyQuery>;
export type ScholarQueryResult = Apollo.QueryResult<
  ScholarQuery,
  ScholarQueryVariables
>;
export const GivePendingRewardDocument = gql`
  mutation GivePendingReward(
    $playerId: String
    $notificationTitle: String
    $rewards: [PendingRewardRequestInput!]!
  ) {
    givePendingReward(
      playerId: $playerId
      notificationTitle: $notificationTitle
      rewards: $rewards
    ) {
      id
    }
  }
`;
export type GivePendingRewardMutationFn = Apollo.MutationFunction<
  GivePendingRewardMutation,
  GivePendingRewardMutationVariables
>;

/**
 * __useGivePendingRewardMutation__
 *
 * To run a mutation, you first call `useGivePendingRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGivePendingRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [givePendingRewardMutation, { data, loading, error }] = useGivePendingRewardMutation({
 *   variables: {
 *      playerId: // value for 'playerId'
 *      notificationTitle: // value for 'notificationTitle'
 *      rewards: // value for 'rewards'
 *   },
 * });
 */
export function useGivePendingRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GivePendingRewardMutation,
    GivePendingRewardMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GivePendingRewardMutation,
    GivePendingRewardMutationVariables
  >(GivePendingRewardDocument, options);
}
export type GivePendingRewardMutationHookResult = ReturnType<
  typeof useGivePendingRewardMutation
>;
export type GivePendingRewardMutationResult =
  Apollo.MutationResult<GivePendingRewardMutation>;
export type GivePendingRewardMutationOptions = Apollo.BaseMutationOptions<
  GivePendingRewardMutation,
  GivePendingRewardMutationVariables
>;
export const UpgradeFurballDocument = gql`
  mutation UpgradeFurball($furballId: String!, $signature: String) {
    upgradeFurball(furballId: $furballId, signature: $signature) {
      ...Furball
    }
  }
  ${FurballFragmentDoc}
`;
export type UpgradeFurballMutationFn = Apollo.MutationFunction<
  UpgradeFurballMutation,
  UpgradeFurballMutationVariables
>;

/**
 * __useUpgradeFurballMutation__
 *
 * To run a mutation, you first call `useUpgradeFurballMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeFurballMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeFurballMutation, { data, loading, error }] = useUpgradeFurballMutation({
 *   variables: {
 *      furballId: // value for 'furballId'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useUpgradeFurballMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeFurballMutation,
    UpgradeFurballMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpgradeFurballMutation,
    UpgradeFurballMutationVariables
  >(UpgradeFurballDocument, options);
}
export type UpgradeFurballMutationHookResult = ReturnType<
  typeof useUpgradeFurballMutation
>;
export type UpgradeFurballMutationResult =
  Apollo.MutationResult<UpgradeFurballMutation>;
export type UpgradeFurballMutationOptions = Apollo.BaseMutationOptions<
  UpgradeFurballMutation,
  UpgradeFurballMutationVariables
>;
export const LoadMintAuctionDocument = gql`
  query LoadMintAuction {
    dutchAuction {
      ...DutchAuction
    }
  }
  ${DutchAuctionFragmentDoc}
`;

/**
 * __useLoadMintAuctionQuery__
 *
 * To run a query within a React component, call `useLoadMintAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadMintAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadMintAuctionQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadMintAuctionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadMintAuctionQuery,
    LoadMintAuctionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LoadMintAuctionQuery, LoadMintAuctionQueryVariables>(
    LoadMintAuctionDocument,
    options,
  );
}
export function useLoadMintAuctionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadMintAuctionQuery,
    LoadMintAuctionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadMintAuctionQuery,
    LoadMintAuctionQueryVariables
  >(LoadMintAuctionDocument, options);
}
export type LoadMintAuctionQueryHookResult = ReturnType<
  typeof useLoadMintAuctionQuery
>;
export type LoadMintAuctionLazyQueryHookResult = ReturnType<
  typeof useLoadMintAuctionLazyQuery
>;
export type LoadMintAuctionQueryResult = Apollo.QueryResult<
  LoadMintAuctionQuery,
  LoadMintAuctionQueryVariables
>;
export const BidMintAuctionDocument = gql`
  mutation BidMintAuction {
    bidAuction {
      outcome
      auction {
        ...DutchAuction
      }
    }
  }
  ${DutchAuctionFragmentDoc}
`;
export type BidMintAuctionMutationFn = Apollo.MutationFunction<
  BidMintAuctionMutation,
  BidMintAuctionMutationVariables
>;

/**
 * __useBidMintAuctionMutation__
 *
 * To run a mutation, you first call `useBidMintAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBidMintAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bidMintAuctionMutation, { data, loading, error }] = useBidMintAuctionMutation({
 *   variables: {
 *   },
 * });
 */
export function useBidMintAuctionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BidMintAuctionMutation,
    BidMintAuctionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BidMintAuctionMutation,
    BidMintAuctionMutationVariables
  >(BidMintAuctionDocument, options);
}
export type BidMintAuctionMutationHookResult = ReturnType<
  typeof useBidMintAuctionMutation
>;
export type BidMintAuctionMutationResult =
  Apollo.MutationResult<BidMintAuctionMutation>;
export type BidMintAuctionMutationOptions = Apollo.BaseMutationOptions<
  BidMintAuctionMutation,
  BidMintAuctionMutationVariables
>;
export const PendingFurWrapsDocument = gql`
  query PendingFurWraps {
    currentPlayer {
      id
      pendingWraps {
        ...TimekeeperWrapFur
      }
    }
  }
  ${TimekeeperWrapFurFragmentDoc}
`;

/**
 * __usePendingFurWrapsQuery__
 *
 * To run a query within a React component, call `usePendingFurWrapsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingFurWrapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingFurWrapsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingFurWrapsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PendingFurWrapsQuery,
    PendingFurWrapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingFurWrapsQuery, PendingFurWrapsQueryVariables>(
    PendingFurWrapsDocument,
    options,
  );
}
export function usePendingFurWrapsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingFurWrapsQuery,
    PendingFurWrapsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PendingFurWrapsQuery,
    PendingFurWrapsQueryVariables
  >(PendingFurWrapsDocument, options);
}
export type PendingFurWrapsQueryHookResult = ReturnType<
  typeof usePendingFurWrapsQuery
>;
export type PendingFurWrapsLazyQueryHookResult = ReturnType<
  typeof usePendingFurWrapsLazyQuery
>;
export type PendingFurWrapsQueryResult = Apollo.QueryResult<
  PendingFurWrapsQuery,
  PendingFurWrapsQueryVariables
>;
export const FurballsMetaDocument = gql`
  query FurballsMeta {
    meta {
      ...FurballsMeta
    }
  }
  ${FurballsMetaFragmentDoc}
`;

/**
 * __useFurballsMetaQuery__
 *
 * To run a query within a React component, call `useFurballsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useFurballsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFurballsMetaQuery({
 *   variables: {
 *   },
 * });
 */
export function useFurballsMetaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FurballsMetaQuery,
    FurballsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FurballsMetaQuery, FurballsMetaQueryVariables>(
    FurballsMetaDocument,
    options,
  );
}
export function useFurballsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FurballsMetaQuery,
    FurballsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FurballsMetaQuery, FurballsMetaQueryVariables>(
    FurballsMetaDocument,
    options,
  );
}
export type FurballsMetaQueryHookResult = ReturnType<
  typeof useFurballsMetaQuery
>;
export type FurballsMetaLazyQueryHookResult = ReturnType<
  typeof useFurballsMetaLazyQuery
>;
export type FurballsMetaQueryResult = Apollo.QueryResult<
  FurballsMetaQuery,
  FurballsMetaQueryVariables
>;
export const TakePayoutDocument = gql`
  mutation TakePayout($id: String!, $method: PayoutMethod!) {
    takePayout(id: $id, method: $method) {
      id
    }
  }
`;
export type TakePayoutMutationFn = Apollo.MutationFunction<
  TakePayoutMutation,
  TakePayoutMutationVariables
>;

/**
 * __useTakePayoutMutation__
 *
 * To run a mutation, you first call `useTakePayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakePayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takePayoutMutation, { data, loading, error }] = useTakePayoutMutation({
 *   variables: {
 *      id: // value for 'id'
 *      method: // value for 'method'
 *   },
 * });
 */
export function useTakePayoutMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakePayoutMutation,
    TakePayoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TakePayoutMutation, TakePayoutMutationVariables>(
    TakePayoutDocument,
    options,
  );
}
export type TakePayoutMutationHookResult = ReturnType<
  typeof useTakePayoutMutation
>;
export type TakePayoutMutationResult =
  Apollo.MutationResult<TakePayoutMutation>;
export type TakePayoutMutationOptions = Apollo.BaseMutationOptions<
  TakePayoutMutation,
  TakePayoutMutationVariables
>;
export const MyPriorRewardsDocument = gql`
  query MyPriorRewards {
    priorRewards(
      order: { createdAt: DESC }
      first: 100
      where: { source: { neq: RENTAL_AGREEMENT } }
    ) {
      nodes {
        ...PlayerPendingReward
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PlayerPendingRewardFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useMyPriorRewardsQuery__
 *
 * To run a query within a React component, call `useMyPriorRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyPriorRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyPriorRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyPriorRewardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyPriorRewardsQuery,
    MyPriorRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyPriorRewardsQuery, MyPriorRewardsQueryVariables>(
    MyPriorRewardsDocument,
    options,
  );
}
export function useMyPriorRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyPriorRewardsQuery,
    MyPriorRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyPriorRewardsQuery, MyPriorRewardsQueryVariables>(
    MyPriorRewardsDocument,
    options,
  );
}
export type MyPriorRewardsQueryHookResult = ReturnType<
  typeof useMyPriorRewardsQuery
>;
export type MyPriorRewardsLazyQueryHookResult = ReturnType<
  typeof useMyPriorRewardsLazyQuery
>;
export type MyPriorRewardsQueryResult = Apollo.QueryResult<
  MyPriorRewardsQuery,
  MyPriorRewardsQueryVariables
>;
export const BuyLootBoxDocument = gql`
  mutation BuyLootBox($useTix: Boolean!) {
    buyLootBox(useTix: $useTix) {
      ...PlayerPendingReward
      player {
        ...CurrentPlayer
      }
    }
  }
  ${PlayerPendingRewardFragmentDoc}
  ${CurrentPlayerFragmentDoc}
`;
export type BuyLootBoxMutationFn = Apollo.MutationFunction<
  BuyLootBoxMutation,
  BuyLootBoxMutationVariables
>;

/**
 * __useBuyLootBoxMutation__
 *
 * To run a mutation, you first call `useBuyLootBoxMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyLootBoxMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyLootBoxMutation, { data, loading, error }] = useBuyLootBoxMutation({
 *   variables: {
 *      useTix: // value for 'useTix'
 *   },
 * });
 */
export function useBuyLootBoxMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyLootBoxMutation,
    BuyLootBoxMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuyLootBoxMutation, BuyLootBoxMutationVariables>(
    BuyLootBoxDocument,
    options,
  );
}
export type BuyLootBoxMutationHookResult = ReturnType<
  typeof useBuyLootBoxMutation
>;
export type BuyLootBoxMutationResult =
  Apollo.MutationResult<BuyLootBoxMutation>;
export type BuyLootBoxMutationOptions = Apollo.BaseMutationOptions<
  BuyLootBoxMutation,
  BuyLootBoxMutationVariables
>;
export const LoginEmailDocument = gql`
  mutation LoginEmail($email: String!, $code: String) {
    loginEmail(email: $email, code: $code) {
      outcome
      secret
      session {
        ...PlayerSession
      }
      currentPlayer {
        id
      }
    }
  }
  ${PlayerSessionFragmentDoc}
`;
export type LoginEmailMutationFn = Apollo.MutationFunction<
  LoginEmailMutation,
  LoginEmailMutationVariables
>;

/**
 * __useLoginEmailMutation__
 *
 * To run a mutation, you first call `useLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailMutation, { data, loading, error }] = useLoginEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginEmailMutation,
    LoginEmailMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginEmailMutation, LoginEmailMutationVariables>(
    LoginEmailDocument,
    options,
  );
}
export type LoginEmailMutationHookResult = ReturnType<
  typeof useLoginEmailMutation
>;
export type LoginEmailMutationResult =
  Apollo.MutationResult<LoginEmailMutation>;
export type LoginEmailMutationOptions = Apollo.BaseMutationOptions<
  LoginEmailMutation,
  LoginEmailMutationVariables
>;
export const VerifyReceiptDocument = gql`
  mutation VerifyReceipt($receiptData: String!, $platform: String!) {
    verifyReceipt(receiptData: $receiptData, platform: $platform) {
      ...CurrentPlayer
    }
  }
  ${CurrentPlayerFragmentDoc}
`;
export type VerifyReceiptMutationFn = Apollo.MutationFunction<
  VerifyReceiptMutation,
  VerifyReceiptMutationVariables
>;

/**
 * __useVerifyReceiptMutation__
 *
 * To run a mutation, you first call `useVerifyReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyReceiptMutation, { data, loading, error }] = useVerifyReceiptMutation({
 *   variables: {
 *      receiptData: // value for 'receiptData'
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useVerifyReceiptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyReceiptMutation,
    VerifyReceiptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyReceiptMutation,
    VerifyReceiptMutationVariables
  >(VerifyReceiptDocument, options);
}
export type VerifyReceiptMutationHookResult = ReturnType<
  typeof useVerifyReceiptMutation
>;
export type VerifyReceiptMutationResult =
  Apollo.MutationResult<VerifyReceiptMutation>;
export type VerifyReceiptMutationOptions = Apollo.BaseMutationOptions<
  VerifyReceiptMutation,
  VerifyReceiptMutationVariables
>;
export const ExchangeRatesDocument = gql`
  query ExchangeRates {
    exchangeRates {
      ...ExchangeRates
    }
  }
  ${ExchangeRatesFragmentDoc}
`;

/**
 * __useExchangeRatesQuery__
 *
 * To run a query within a React component, call `useExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(
    ExchangeRatesDocument,
    options,
  );
}
export function useExchangeRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExchangeRatesQuery,
    ExchangeRatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExchangeRatesQuery, ExchangeRatesQueryVariables>(
    ExchangeRatesDocument,
    options,
  );
}
export type ExchangeRatesQueryHookResult = ReturnType<
  typeof useExchangeRatesQuery
>;
export type ExchangeRatesLazyQueryHookResult = ReturnType<
  typeof useExchangeRatesLazyQuery
>;
export type ExchangeRatesQueryResult = Apollo.QueryResult<
  ExchangeRatesQuery,
  ExchangeRatesQueryVariables
>;
export const PurchaseFurDocument = gql`
  mutation PurchaseFur($fuel: Long!) {
    purchaseFur(fuel: $fuel) {
      id
      quantity
      tixCost
    }
  }
`;
export type PurchaseFurMutationFn = Apollo.MutationFunction<
  PurchaseFurMutation,
  PurchaseFurMutationVariables
>;

/**
 * __usePurchaseFurMutation__
 *
 * To run a mutation, you first call `usePurchaseFurMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseFurMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseFurMutation, { data, loading, error }] = usePurchaseFurMutation({
 *   variables: {
 *      fuel: // value for 'fuel'
 *   },
 * });
 */
export function usePurchaseFurMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseFurMutation,
    PurchaseFurMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PurchaseFurMutation, PurchaseFurMutationVariables>(
    PurchaseFurDocument,
    options,
  );
}
export type PurchaseFurMutationHookResult = ReturnType<
  typeof usePurchaseFurMutation
>;
export type PurchaseFurMutationResult =
  Apollo.MutationResult<PurchaseFurMutation>;
export type PurchaseFurMutationOptions = Apollo.BaseMutationOptions<
  PurchaseFurMutation,
  PurchaseFurMutationVariables
>;
export const RentalAgreementDocument = gql`
  query RentalAgreement($id: String!) {
    rentalAgreement(id: $id) {
      ...FurRentalAgreementRewards
    }
  }
  ${FurRentalAgreementRewardsFragmentDoc}
`;

/**
 * __useRentalAgreementQuery__
 *
 * To run a query within a React component, call `useRentalAgreementQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalAgreementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalAgreementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRentalAgreementQuery(
  baseOptions: Apollo.QueryHookOptions<
    RentalAgreementQuery,
    RentalAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RentalAgreementQuery, RentalAgreementQueryVariables>(
    RentalAgreementDocument,
    options,
  );
}
export function useRentalAgreementLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RentalAgreementQuery,
    RentalAgreementQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RentalAgreementQuery,
    RentalAgreementQueryVariables
  >(RentalAgreementDocument, options);
}
export type RentalAgreementQueryHookResult = ReturnType<
  typeof useRentalAgreementQuery
>;
export type RentalAgreementLazyQueryHookResult = ReturnType<
  typeof useRentalAgreementLazyQuery
>;
export type RentalAgreementQueryResult = Apollo.QueryResult<
  RentalAgreementQuery,
  RentalAgreementQueryVariables
>;
export const BossBattleHistoryPlayerDocument = gql`
  query BossBattleHistoryPlayer($playerId: String!) {
    playerHistory(playerId: $playerId) {
      ...History
    }
  }
  ${HistoryFragmentDoc}
`;

/**
 * __useBossBattleHistoryPlayerQuery__
 *
 * To run a query within a React component, call `useBossBattleHistoryPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossBattleHistoryPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossBattleHistoryPlayerQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useBossBattleHistoryPlayerQuery(
  baseOptions: Apollo.QueryHookOptions<
    BossBattleHistoryPlayerQuery,
    BossBattleHistoryPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BossBattleHistoryPlayerQuery,
    BossBattleHistoryPlayerQueryVariables
  >(BossBattleHistoryPlayerDocument, options);
}
export function useBossBattleHistoryPlayerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BossBattleHistoryPlayerQuery,
    BossBattleHistoryPlayerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BossBattleHistoryPlayerQuery,
    BossBattleHistoryPlayerQueryVariables
  >(BossBattleHistoryPlayerDocument, options);
}
export type BossBattleHistoryPlayerQueryHookResult = ReturnType<
  typeof useBossBattleHistoryPlayerQuery
>;
export type BossBattleHistoryPlayerLazyQueryHookResult = ReturnType<
  typeof useBossBattleHistoryPlayerLazyQuery
>;
export type BossBattleHistoryPlayerQueryResult = Apollo.QueryResult<
  BossBattleHistoryPlayerQuery,
  BossBattleHistoryPlayerQueryVariables
>;
export const JoinTournamentDocument = gql`
  mutation joinTournament($tournamentId: String!) {
    joinTournament(tournamentId: $tournamentId) {
      ...Tournament
    }
  }
  ${TournamentFragmentDoc}
`;
export type JoinTournamentMutationFn = Apollo.MutationFunction<
  JoinTournamentMutation,
  JoinTournamentMutationVariables
>;

/**
 * __useJoinTournamentMutation__
 *
 * To run a mutation, you first call `useJoinTournamentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinTournamentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinTournamentMutation, { data, loading, error }] = useJoinTournamentMutation({
 *   variables: {
 *      tournamentId: // value for 'tournamentId'
 *   },
 * });
 */
export function useJoinTournamentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinTournamentMutation,
    JoinTournamentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinTournamentMutation,
    JoinTournamentMutationVariables
  >(JoinTournamentDocument, options);
}
export type JoinTournamentMutationHookResult = ReturnType<
  typeof useJoinTournamentMutation
>;
export type JoinTournamentMutationResult =
  Apollo.MutationResult<JoinTournamentMutation>;
export type JoinTournamentMutationOptions = Apollo.BaseMutationOptions<
  JoinTournamentMutation,
  JoinTournamentMutationVariables
>;
export const BossLeaderboardPlayerBestScoreGroupDocument = gql`
  query BossLeaderboardPlayerBestScoreGroup($playerId: String!) {
    leaderboardRanksPlayer(playerId: $playerId) {
      ...LeaderboardRankPlayer
    }
  }
  ${LeaderboardRankPlayerFragmentDoc}
`;

/**
 * __useBossLeaderboardPlayerBestScoreGroupQuery__
 *
 * To run a query within a React component, call `useBossLeaderboardPlayerBestScoreGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossLeaderboardPlayerBestScoreGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossLeaderboardPlayerBestScoreGroupQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useBossLeaderboardPlayerBestScoreGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    BossLeaderboardPlayerBestScoreGroupQuery,
    BossLeaderboardPlayerBestScoreGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    BossLeaderboardPlayerBestScoreGroupQuery,
    BossLeaderboardPlayerBestScoreGroupQueryVariables
  >(BossLeaderboardPlayerBestScoreGroupDocument, options);
}
export function useBossLeaderboardPlayerBestScoreGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BossLeaderboardPlayerBestScoreGroupQuery,
    BossLeaderboardPlayerBestScoreGroupQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BossLeaderboardPlayerBestScoreGroupQuery,
    BossLeaderboardPlayerBestScoreGroupQueryVariables
  >(BossLeaderboardPlayerBestScoreGroupDocument, options);
}
export type BossLeaderboardPlayerBestScoreGroupQueryHookResult = ReturnType<
  typeof useBossLeaderboardPlayerBestScoreGroupQuery
>;
export type BossLeaderboardPlayerBestScoreGroupLazyQueryHookResult = ReturnType<
  typeof useBossLeaderboardPlayerBestScoreGroupLazyQuery
>;
export type BossLeaderboardPlayerBestScoreGroupQueryResult = Apollo.QueryResult<
  BossLeaderboardPlayerBestScoreGroupQuery,
  BossLeaderboardPlayerBestScoreGroupQueryVariables
>;
export const BossLeaderboardDocument = gql`
  query BossLeaderboard(
    $boss: LeaderboardStat!
    $timeframe: LeaderboardTimeframe!
    $group: LeaderboardGroup!
  ) {
    leaderboards(
      where: {
        stat: { eq: $boss }
        timeframe: { eq: $timeframe }
        leaderGroup: { eq: $group }
      }
    ) {
      ...Leaderboard
    }
  }
  ${LeaderboardFragmentDoc}
`;

/**
 * __useBossLeaderboardQuery__
 *
 * To run a query within a React component, call `useBossLeaderboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossLeaderboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossLeaderboardQuery({
 *   variables: {
 *      boss: // value for 'boss'
 *      timeframe: // value for 'timeframe'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useBossLeaderboardQuery(
  baseOptions: Apollo.QueryHookOptions<
    BossLeaderboardQuery,
    BossLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BossLeaderboardQuery, BossLeaderboardQueryVariables>(
    BossLeaderboardDocument,
    options,
  );
}
export function useBossLeaderboardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BossLeaderboardQuery,
    BossLeaderboardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    BossLeaderboardQuery,
    BossLeaderboardQueryVariables
  >(BossLeaderboardDocument, options);
}
export type BossLeaderboardQueryHookResult = ReturnType<
  typeof useBossLeaderboardQuery
>;
export type BossLeaderboardLazyQueryHookResult = ReturnType<
  typeof useBossLeaderboardLazyQuery
>;
export type BossLeaderboardQueryResult = Apollo.QueryResult<
  BossLeaderboardQuery,
  BossLeaderboardQueryVariables
>;
export const ClaimPendingRewardsDocument = gql`
  mutation ClaimPendingRewards($pendingRewardId: String!) {
    claimPendingReward(pendingRewardId: $pendingRewardId) {
      ...PlayerInventoryQuantity
      pendingRewards {
        ...PlayerPendingReward
      }
    }
  }
  ${PlayerInventoryQuantityFragmentDoc}
  ${PlayerPendingRewardFragmentDoc}
`;
export type ClaimPendingRewardsMutationFn = Apollo.MutationFunction<
  ClaimPendingRewardsMutation,
  ClaimPendingRewardsMutationVariables
>;

/**
 * __useClaimPendingRewardsMutation__
 *
 * To run a mutation, you first call `useClaimPendingRewardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimPendingRewardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimPendingRewardsMutation, { data, loading, error }] = useClaimPendingRewardsMutation({
 *   variables: {
 *      pendingRewardId: // value for 'pendingRewardId'
 *   },
 * });
 */
export function useClaimPendingRewardsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimPendingRewardsMutation,
    ClaimPendingRewardsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimPendingRewardsMutation,
    ClaimPendingRewardsMutationVariables
  >(ClaimPendingRewardsDocument, options);
}
export type ClaimPendingRewardsMutationHookResult = ReturnType<
  typeof useClaimPendingRewardsMutation
>;
export type ClaimPendingRewardsMutationResult =
  Apollo.MutationResult<ClaimPendingRewardsMutation>;
export type ClaimPendingRewardsMutationOptions = Apollo.BaseMutationOptions<
  ClaimPendingRewardsMutation,
  ClaimPendingRewardsMutationVariables
>;
export const LoadPendingRewardsDocument = gql`
  query LoadPendingRewards {
    currentPlayer {
      __typename
      id
      pendingRewards {
        ...PlayerPendingReward
      }
      inventory {
        ...InventoryQuantities
      }
    }
  }
  ${PlayerPendingRewardFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
`;

/**
 * __useLoadPendingRewardsQuery__
 *
 * To run a query within a React component, call `useLoadPendingRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadPendingRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadPendingRewardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoadPendingRewardsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LoadPendingRewardsQuery,
    LoadPendingRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LoadPendingRewardsQuery,
    LoadPendingRewardsQueryVariables
  >(LoadPendingRewardsDocument, options);
}
export function useLoadPendingRewardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LoadPendingRewardsQuery,
    LoadPendingRewardsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LoadPendingRewardsQuery,
    LoadPendingRewardsQueryVariables
  >(LoadPendingRewardsDocument, options);
}
export type LoadPendingRewardsQueryHookResult = ReturnType<
  typeof useLoadPendingRewardsQuery
>;
export type LoadPendingRewardsLazyQueryHookResult = ReturnType<
  typeof useLoadPendingRewardsLazyQuery
>;
export type LoadPendingRewardsQueryResult = Apollo.QueryResult<
  LoadPendingRewardsQuery,
  LoadPendingRewardsQueryVariables
>;
export const GameBattleLoopDocument = gql`
  subscription GameBattleLoop($gameBattleId: String!) {
    gameBattleLoop(gameBattleId: $gameBattleId) {
      ...BattleMoveResponse
    }
  }
  ${BattleMoveResponseFragmentDoc}
`;

/**
 * __useGameBattleLoopSubscription__
 *
 * To run a query within a React component, call `useGameBattleLoopSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGameBattleLoopSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameBattleLoopSubscription({
 *   variables: {
 *      gameBattleId: // value for 'gameBattleId'
 *   },
 * });
 */
export function useGameBattleLoopSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GameBattleLoopSubscription,
    GameBattleLoopSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GameBattleLoopSubscription,
    GameBattleLoopSubscriptionVariables
  >(GameBattleLoopDocument, options);
}
export type GameBattleLoopSubscriptionHookResult = ReturnType<
  typeof useGameBattleLoopSubscription
>;
export type GameBattleLoopSubscriptionResult =
  Apollo.SubscriptionResult<GameBattleLoopSubscription>;
export const CreateDuelBattleDocument = gql`
  mutation CreateDuelBattle(
    $partyId: String!
    $ante: AnteInput
    $rules: GamePlayRulesInput
  ) {
    createDuelBattle(partyId: $partyId, ante: $ante, rules: $rules) {
      ...DuelBattle
    }
  }
  ${DuelBattleFragmentDoc}
`;
export type CreateDuelBattleMutationFn = Apollo.MutationFunction<
  CreateDuelBattleMutation,
  CreateDuelBattleMutationVariables
>;

/**
 * __useCreateDuelBattleMutation__
 *
 * To run a mutation, you first call `useCreateDuelBattleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuelBattleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuelBattleMutation, { data, loading, error }] = useCreateDuelBattleMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      ante: // value for 'ante'
 *      rules: // value for 'rules'
 *   },
 * });
 */
export function useCreateDuelBattleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDuelBattleMutation,
    CreateDuelBattleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDuelBattleMutation,
    CreateDuelBattleMutationVariables
  >(CreateDuelBattleDocument, options);
}
export type CreateDuelBattleMutationHookResult = ReturnType<
  typeof useCreateDuelBattleMutation
>;
export type CreateDuelBattleMutationResult =
  Apollo.MutationResult<CreateDuelBattleMutation>;
export type CreateDuelBattleMutationOptions = Apollo.BaseMutationOptions<
  CreateDuelBattleMutation,
  CreateDuelBattleMutationVariables
>;
export const TopBossBattlesDocument = gql`
  query TopBossBattles {
    topBossBattles {
      battles(
        order: { createdAt: DESC }
        first: 5
        where: { battleState: { nin: [ACTIVE, NONE] }, score: { gt: 0 } }
      ) {
        nodes {
          ...BossBattlePlayerOverview
        }
        pageInfo {
          ...PageInfo
        }
        totalCount
      }
      boss {
        ...Boss
      }
    }
  }
  ${BossBattlePlayerOverviewFragmentDoc}
  ${PageInfoFragmentDoc}
  ${BossFragmentDoc}
`;

/**
 * __useTopBossBattlesQuery__
 *
 * To run a query within a React component, call `useTopBossBattlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopBossBattlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopBossBattlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopBossBattlesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TopBossBattlesQuery,
    TopBossBattlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopBossBattlesQuery, TopBossBattlesQueryVariables>(
    TopBossBattlesDocument,
    options,
  );
}
export function useTopBossBattlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TopBossBattlesQuery,
    TopBossBattlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopBossBattlesQuery, TopBossBattlesQueryVariables>(
    TopBossBattlesDocument,
    options,
  );
}
export type TopBossBattlesQueryHookResult = ReturnType<
  typeof useTopBossBattlesQuery
>;
export type TopBossBattlesLazyQueryHookResult = ReturnType<
  typeof useTopBossBattlesLazyQuery
>;
export type TopBossBattlesQueryResult = Apollo.QueryResult<
  TopBossBattlesQuery,
  TopBossBattlesQueryVariables
>;
export const BossBattlesDocument = gql`
  query BossBattles($endCursor: String!, $realm: RealmType!) {
    bossBattles(
      after: $endCursor
      order: { createdAt: DESC }
      where: {
        battleState: { nin: [ACTIVE, NONE] }
        worldBoss: { realm: { eq: $realm } }
        score: { gt: 0 }
      }
    ) {
      nodes {
        ...BossBattlePlayerOverview
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${BossBattlePlayerOverviewFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useBossBattlesQuery__
 *
 * To run a query within a React component, call `useBossBattlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossBattlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossBattlesQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      realm: // value for 'realm'
 *   },
 * });
 */
export function useBossBattlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    BossBattlesQuery,
    BossBattlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BossBattlesQuery, BossBattlesQueryVariables>(
    BossBattlesDocument,
    options,
  );
}
export function useBossBattlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BossBattlesQuery,
    BossBattlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BossBattlesQuery, BossBattlesQueryVariables>(
    BossBattlesDocument,
    options,
  );
}
export type BossBattlesQueryHookResult = ReturnType<typeof useBossBattlesQuery>;
export type BossBattlesLazyQueryHookResult = ReturnType<
  typeof useBossBattlesLazyQuery
>;
export type BossBattlesQueryResult = Apollo.QueryResult<
  BossBattlesQuery,
  BossBattlesQueryVariables
>;
export const CraftRecipeDocument = gql`
  mutation CraftRecipe(
    $slotIndex: Int!
    $unlockSlot: Boolean!
    $recipeId: String
    $useTix: Boolean!
  ) {
    craftRecipe(
      slotIndex: $slotIndex
      unlockSlot: $unlockSlot
      recipeId: $recipeId
      useTix: $useTix
    ) {
      ...CraftSlotChange
    }
  }
  ${CraftSlotChangeFragmentDoc}
`;
export type CraftRecipeMutationFn = Apollo.MutationFunction<
  CraftRecipeMutation,
  CraftRecipeMutationVariables
>;

/**
 * __useCraftRecipeMutation__
 *
 * To run a mutation, you first call `useCraftRecipeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCraftRecipeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [craftRecipeMutation, { data, loading, error }] = useCraftRecipeMutation({
 *   variables: {
 *      slotIndex: // value for 'slotIndex'
 *      unlockSlot: // value for 'unlockSlot'
 *      recipeId: // value for 'recipeId'
 *      useTix: // value for 'useTix'
 *   },
 * });
 */
export function useCraftRecipeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CraftRecipeMutation,
    CraftRecipeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CraftRecipeMutation, CraftRecipeMutationVariables>(
    CraftRecipeDocument,
    options,
  );
}
export type CraftRecipeMutationHookResult = ReturnType<
  typeof useCraftRecipeMutation
>;
export type CraftRecipeMutationResult =
  Apollo.MutationResult<CraftRecipeMutation>;
export type CraftRecipeMutationOptions = Apollo.BaseMutationOptions<
  CraftRecipeMutation,
  CraftRecipeMutationVariables
>;
export const ClaimCraftedItemDocument = gql`
  mutation ClaimCraftedItem($pendingRewardId: String!) {
    claimPendingReward(pendingRewardId: $pendingRewardId) {
      craftingSlots {
        ...CraftSlotChange
      }
      inventory {
        ...InventoryQuantities
      }
    }
  }
  ${CraftSlotChangeFragmentDoc}
  ${InventoryQuantitiesFragmentDoc}
`;
export type ClaimCraftedItemMutationFn = Apollo.MutationFunction<
  ClaimCraftedItemMutation,
  ClaimCraftedItemMutationVariables
>;

/**
 * __useClaimCraftedItemMutation__
 *
 * To run a mutation, you first call `useClaimCraftedItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimCraftedItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimCraftedItemMutation, { data, loading, error }] = useClaimCraftedItemMutation({
 *   variables: {
 *      pendingRewardId: // value for 'pendingRewardId'
 *   },
 * });
 */
export function useClaimCraftedItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimCraftedItemMutation,
    ClaimCraftedItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimCraftedItemMutation,
    ClaimCraftedItemMutationVariables
  >(ClaimCraftedItemDocument, options);
}
export type ClaimCraftedItemMutationHookResult = ReturnType<
  typeof useClaimCraftedItemMutation
>;
export type ClaimCraftedItemMutationResult =
  Apollo.MutationResult<ClaimCraftedItemMutation>;
export type ClaimCraftedItemMutationOptions = Apollo.BaseMutationOptions<
  ClaimCraftedItemMutation,
  ClaimCraftedItemMutationVariables
>;
export const CraftFasterDocument = gql`
  mutation CraftFaster($craftingAttemptId: String!, $numHours: Int!) {
    craftFaster(craftingAttemptId: $craftingAttemptId, numHours: $numHours) {
      ...CraftSlotChange
    }
  }
  ${CraftSlotChangeFragmentDoc}
`;
export type CraftFasterMutationFn = Apollo.MutationFunction<
  CraftFasterMutation,
  CraftFasterMutationVariables
>;

/**
 * __useCraftFasterMutation__
 *
 * To run a mutation, you first call `useCraftFasterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCraftFasterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [craftFasterMutation, { data, loading, error }] = useCraftFasterMutation({
 *   variables: {
 *      craftingAttemptId: // value for 'craftingAttemptId'
 *      numHours: // value for 'numHours'
 *   },
 * });
 */
export function useCraftFasterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CraftFasterMutation,
    CraftFasterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CraftFasterMutation, CraftFasterMutationVariables>(
    CraftFasterDocument,
    options,
  );
}
export type CraftFasterMutationHookResult = ReturnType<
  typeof useCraftFasterMutation
>;
export type CraftFasterMutationResult =
  Apollo.MutationResult<CraftFasterMutation>;
export type CraftFasterMutationOptions = Apollo.BaseMutationOptions<
  CraftFasterMutation,
  CraftFasterMutationVariables
>;
export const CraftingSlotsPendingRewardDocument = gql`
  query CraftingSlotsPendingReward {
    currentPlayer {
      id
      craftingSlots {
        id
        currentAttempt {
          id
          pendingReward {
            id
            notificationId
          }
        }
      }
    }
  }
`;

/**
 * __useCraftingSlotsPendingRewardQuery__
 *
 * To run a query within a React component, call `useCraftingSlotsPendingRewardQuery` and pass it any options that fit your needs.
 * When your component renders, `useCraftingSlotsPendingRewardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCraftingSlotsPendingRewardQuery({
 *   variables: {
 *   },
 * });
 */
export function useCraftingSlotsPendingRewardQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CraftingSlotsPendingRewardQuery,
    CraftingSlotsPendingRewardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CraftingSlotsPendingRewardQuery,
    CraftingSlotsPendingRewardQueryVariables
  >(CraftingSlotsPendingRewardDocument, options);
}
export function useCraftingSlotsPendingRewardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CraftingSlotsPendingRewardQuery,
    CraftingSlotsPendingRewardQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CraftingSlotsPendingRewardQuery,
    CraftingSlotsPendingRewardQueryVariables
  >(CraftingSlotsPendingRewardDocument, options);
}
export type CraftingSlotsPendingRewardQueryHookResult = ReturnType<
  typeof useCraftingSlotsPendingRewardQuery
>;
export type CraftingSlotsPendingRewardLazyQueryHookResult = ReturnType<
  typeof useCraftingSlotsPendingRewardLazyQuery
>;
export type CraftingSlotsPendingRewardQueryResult = Apollo.QueryResult<
  CraftingSlotsPendingRewardQuery,
  CraftingSlotsPendingRewardQueryVariables
>;
export const FurballDocument = gql`
  query Furball($tokenId: String!) {
    furball(tokenId: $tokenId) {
      ...Furball
    }
  }
  ${FurballFragmentDoc}
`;

/**
 * __useFurballQuery__
 *
 * To run a query within a React component, call `useFurballQuery` and pass it any options that fit your needs.
 * When your component renders, `useFurballQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFurballQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useFurballQuery(
  baseOptions: Apollo.QueryHookOptions<FurballQuery, FurballQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FurballQuery, FurballQueryVariables>(
    FurballDocument,
    options,
  );
}
export function useFurballLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FurballQuery,
    FurballQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FurballQuery, FurballQueryVariables>(
    FurballDocument,
    options,
  );
}
export type FurballQueryHookResult = ReturnType<typeof useFurballQuery>;
export type FurballLazyQueryHookResult = ReturnType<typeof useFurballLazyQuery>;
export type FurballQueryResult = Apollo.QueryResult<
  FurballQuery,
  FurballQueryVariables
>;
export const FurballsDocument = gql`
  query Furballs($tokenIds: [String!]!) {
    furballs(tokenIds: $tokenIds) {
      ...Furball
    }
  }
  ${FurballFragmentDoc}
`;

/**
 * __useFurballsQuery__
 *
 * To run a query within a React component, call `useFurballsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFurballsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFurballsQuery({
 *   variables: {
 *      tokenIds: // value for 'tokenIds'
 *   },
 * });
 */
export function useFurballsQuery(
  baseOptions: Apollo.QueryHookOptions<FurballsQuery, FurballsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FurballsQuery, FurballsQueryVariables>(
    FurballsDocument,
    options,
  );
}
export function useFurballsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FurballsQuery,
    FurballsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FurballsQuery, FurballsQueryVariables>(
    FurballsDocument,
    options,
  );
}
export type FurballsQueryHookResult = ReturnType<typeof useFurballsQuery>;
export type FurballsLazyQueryHookResult = ReturnType<
  typeof useFurballsLazyQuery
>;
export type FurballsQueryResult = Apollo.QueryResult<
  FurballsQuery,
  FurballsQueryVariables
>;
export const FurballPreviousResolutionsDocument = gql`
  query FurballPreviousResolutions($tokenId: String!) {
    timekeeperPreviousResolutions(tokenId: $tokenId) {
      ...TimekeeperResolutionRound
    }
  }
  ${TimekeeperResolutionRoundFragmentDoc}
`;

/**
 * __useFurballPreviousResolutionsQuery__
 *
 * To run a query within a React component, call `useFurballPreviousResolutionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFurballPreviousResolutionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFurballPreviousResolutionsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useFurballPreviousResolutionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FurballPreviousResolutionsQuery,
    FurballPreviousResolutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FurballPreviousResolutionsQuery,
    FurballPreviousResolutionsQueryVariables
  >(FurballPreviousResolutionsDocument, options);
}
export function useFurballPreviousResolutionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FurballPreviousResolutionsQuery,
    FurballPreviousResolutionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FurballPreviousResolutionsQuery,
    FurballPreviousResolutionsQueryVariables
  >(FurballPreviousResolutionsDocument, options);
}
export type FurballPreviousResolutionsQueryHookResult = ReturnType<
  typeof useFurballPreviousResolutionsQuery
>;
export type FurballPreviousResolutionsLazyQueryHookResult = ReturnType<
  typeof useFurballPreviousResolutionsLazyQuery
>;
export type FurballPreviousResolutionsQueryResult = Apollo.QueryResult<
  FurballPreviousResolutionsQuery,
  FurballPreviousResolutionsQueryVariables
>;
export const FindFurballDocument = gql`
  query FindFurball($query: String!) {
    furball: findFurball(query: $query) {
      ...OwnerFurball
    }
  }
  ${OwnerFurballFragmentDoc}
`;

/**
 * __useFindFurballQuery__
 *
 * To run a query within a React component, call `useFindFurballQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindFurballQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindFurballQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useFindFurballQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindFurballQuery,
    FindFurballQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindFurballQuery, FindFurballQueryVariables>(
    FindFurballDocument,
    options,
  );
}
export function useFindFurballLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindFurballQuery,
    FindFurballQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindFurballQuery, FindFurballQueryVariables>(
    FindFurballDocument,
    options,
  );
}
export type FindFurballQueryHookResult = ReturnType<typeof useFindFurballQuery>;
export type FindFurballLazyQueryHookResult = ReturnType<
  typeof useFindFurballLazyQuery
>;
export type FindFurballQueryResult = Apollo.QueryResult<
  FindFurballQuery,
  FindFurballQueryVariables
>;
export const ModifyFurballDocument = gql`
  mutation ModifyFurball(
    $mods: FurballModificationArgsInput!
    $token: FurOAuthTokenInput!
  ) {
    modification: modifyFurball(args: $mods, signatureToken: $token) {
      ...FurballModification
    }
  }
  ${FurballModificationFragmentDoc}
`;
export type ModifyFurballMutationFn = Apollo.MutationFunction<
  ModifyFurballMutation,
  ModifyFurballMutationVariables
>;

/**
 * __useModifyFurballMutation__
 *
 * To run a mutation, you first call `useModifyFurballMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyFurballMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyFurballMutation, { data, loading, error }] = useModifyFurballMutation({
 *   variables: {
 *      mods: // value for 'mods'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useModifyFurballMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyFurballMutation,
    ModifyFurballMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModifyFurballMutation,
    ModifyFurballMutationVariables
  >(ModifyFurballDocument, options);
}
export type ModifyFurballMutationHookResult = ReturnType<
  typeof useModifyFurballMutation
>;
export type ModifyFurballMutationResult =
  Apollo.MutationResult<ModifyFurballMutation>;
export type ModifyFurballMutationOptions = Apollo.BaseMutationOptions<
  ModifyFurballMutation,
  ModifyFurballMutationVariables
>;
export const EquipFurballDocument = gql`
  mutation EquipFurball($furballId: String!, $itemIds: [String]!) {
    equipFurball(furballId: $furballId, itemIds: $itemIds) {
      ...FurballWithPlayerInventory
    }
  }
  ${FurballWithPlayerInventoryFragmentDoc}
`;
export type EquipFurballMutationFn = Apollo.MutationFunction<
  EquipFurballMutation,
  EquipFurballMutationVariables
>;

/**
 * __useEquipFurballMutation__
 *
 * To run a mutation, you first call `useEquipFurballMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEquipFurballMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [equipFurballMutation, { data, loading, error }] = useEquipFurballMutation({
 *   variables: {
 *      furballId: // value for 'furballId'
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useEquipFurballMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EquipFurballMutation,
    EquipFurballMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EquipFurballMutation,
    EquipFurballMutationVariables
  >(EquipFurballDocument, options);
}
export type EquipFurballMutationHookResult = ReturnType<
  typeof useEquipFurballMutation
>;
export type EquipFurballMutationResult =
  Apollo.MutationResult<EquipFurballMutation>;
export type EquipFurballMutationOptions = Apollo.BaseMutationOptions<
  EquipFurballMutation,
  EquipFurballMutationVariables
>;
export const RequestAirdropDocument = gql`
  mutation RequestAirdrop(
    $args: AirdropRequestArgsInput!
    $token: FurOAuthTokenInput!
  ) {
    requestAirdrop(args: $args, signatureToken: $token) {
      tix
      fur
    }
  }
`;
export type RequestAirdropMutationFn = Apollo.MutationFunction<
  RequestAirdropMutation,
  RequestAirdropMutationVariables
>;

/**
 * __useRequestAirdropMutation__
 *
 * To run a mutation, you first call `useRequestAirdropMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestAirdropMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestAirdropMutation, { data, loading, error }] = useRequestAirdropMutation({
 *   variables: {
 *      args: // value for 'args'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useRequestAirdropMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestAirdropMutation,
    RequestAirdropMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestAirdropMutation,
    RequestAirdropMutationVariables
  >(RequestAirdropDocument, options);
}
export type RequestAirdropMutationHookResult = ReturnType<
  typeof useRequestAirdropMutation
>;
export type RequestAirdropMutationResult =
  Apollo.MutationResult<RequestAirdropMutation>;
export type RequestAirdropMutationOptions = Apollo.BaseMutationOptions<
  RequestAirdropMutation,
  RequestAirdropMutationVariables
>;
export const ListAirdropRequestsDocument = gql`
  query ListAirdropRequests {
    listAirdropRequests {
      ...AirdropRequest
    }
  }
  ${AirdropRequestFragmentDoc}
`;

/**
 * __useListAirdropRequestsQuery__
 *
 * To run a query within a React component, call `useListAirdropRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAirdropRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAirdropRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAirdropRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListAirdropRequestsQuery,
    ListAirdropRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ListAirdropRequestsQuery,
    ListAirdropRequestsQueryVariables
  >(ListAirdropRequestsDocument, options);
}
export function useListAirdropRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListAirdropRequestsQuery,
    ListAirdropRequestsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ListAirdropRequestsQuery,
    ListAirdropRequestsQueryVariables
  >(ListAirdropRequestsDocument, options);
}
export type ListAirdropRequestsQueryHookResult = ReturnType<
  typeof useListAirdropRequestsQuery
>;
export type ListAirdropRequestsLazyQueryHookResult = ReturnType<
  typeof useListAirdropRequestsLazyQuery
>;
export type ListAirdropRequestsQueryResult = Apollo.QueryResult<
  ListAirdropRequestsQuery,
  ListAirdropRequestsQueryVariables
>;
export const ApproveAirdropsDocument = gql`
  mutation ApproveAirdrops($args: [String!]!, $token: FurOAuthTokenInput!) {
    approveAirdrops(args: $args, signatureToken: $token)
  }
`;
export type ApproveAirdropsMutationFn = Apollo.MutationFunction<
  ApproveAirdropsMutation,
  ApproveAirdropsMutationVariables
>;

/**
 * __useApproveAirdropsMutation__
 *
 * To run a mutation, you first call `useApproveAirdropsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveAirdropsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveAirdropsMutation, { data, loading, error }] = useApproveAirdropsMutation({
 *   variables: {
 *      args: // value for 'args'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useApproveAirdropsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveAirdropsMutation,
    ApproveAirdropsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveAirdropsMutation,
    ApproveAirdropsMutationVariables
  >(ApproveAirdropsDocument, options);
}
export type ApproveAirdropsMutationHookResult = ReturnType<
  typeof useApproveAirdropsMutation
>;
export type ApproveAirdropsMutationResult =
  Apollo.MutationResult<ApproveAirdropsMutation>;
export type ApproveAirdropsMutationOptions = Apollo.BaseMutationOptions<
  ApproveAirdropsMutation,
  ApproveAirdropsMutationVariables
>;
export const RecentFurballBossFightsDocument = gql`
  query RecentFurballBossFights($number: String!) {
    findFurball(query: $number) {
      bossFights(
        order: { createdAt: DESC }
        where: { score: { gt: 0 } }
        first: 10
      ) {
        nodes {
          ...RecentFurballBossFight
        }
      }
    }
  }
  ${RecentFurballBossFightFragmentDoc}
`;

/**
 * __useRecentFurballBossFightsQuery__
 *
 * To run a query within a React component, call `useRecentFurballBossFightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentFurballBossFightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentFurballBossFightsQuery({
 *   variables: {
 *      number: // value for 'number'
 *   },
 * });
 */
export function useRecentFurballBossFightsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RecentFurballBossFightsQuery,
    RecentFurballBossFightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecentFurballBossFightsQuery,
    RecentFurballBossFightsQueryVariables
  >(RecentFurballBossFightsDocument, options);
}
export function useRecentFurballBossFightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecentFurballBossFightsQuery,
    RecentFurballBossFightsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecentFurballBossFightsQuery,
    RecentFurballBossFightsQueryVariables
  >(RecentFurballBossFightsDocument, options);
}
export type RecentFurballBossFightsQueryHookResult = ReturnType<
  typeof useRecentFurballBossFightsQuery
>;
export type RecentFurballBossFightsLazyQueryHookResult = ReturnType<
  typeof useRecentFurballBossFightsLazyQuery
>;
export type RecentFurballBossFightsQueryResult = Apollo.QueryResult<
  RecentFurballBossFightsQuery,
  RecentFurballBossFightsQueryVariables
>;
export const MoveItemDocument = gql`
  mutation MoveItem(
    $itemId: String!
    $quantity: UnsignedInt
    $toFurballId: String
  ) {
    moveItem(itemId: $itemId, quantity: $quantity, toFurballId: $toFurballId) {
      ...MoveItemFragment
    }
  }
  ${MoveItemFragmentFragmentDoc}
`;
export type MoveItemMutationFn = Apollo.MutationFunction<
  MoveItemMutation,
  MoveItemMutationVariables
>;

/**
 * __useMoveItemMutation__
 *
 * To run a mutation, you first call `useMoveItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveItemMutation, { data, loading, error }] = useMoveItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      quantity: // value for 'quantity'
 *      toFurballId: // value for 'toFurballId'
 *   },
 * });
 */
export function useMoveItemMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveItemMutation,
    MoveItemMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveItemMutation, MoveItemMutationVariables>(
    MoveItemDocument,
    options,
  );
}
export type MoveItemMutationHookResult = ReturnType<typeof useMoveItemMutation>;
export type MoveItemMutationResult = Apollo.MutationResult<MoveItemMutation>;
export type MoveItemMutationOptions = Apollo.BaseMutationOptions<
  MoveItemMutation,
  MoveItemMutationVariables
>;
export const InventoryDocument = gql`
  query Inventory($containerId: String!) {
    inventory: container(containerId: $containerId) {
      ...InventoryItems
    }
  }
  ${InventoryItemsFragmentDoc}
`;

/**
 * __useInventoryQuery__
 *
 * To run a query within a React component, call `useInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInventoryQuery({
 *   variables: {
 *      containerId: // value for 'containerId'
 *   },
 * });
 */
export function useInventoryQuery(
  baseOptions: Apollo.QueryHookOptions<InventoryQuery, InventoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InventoryQuery, InventoryQueryVariables>(
    InventoryDocument,
    options,
  );
}
export function useInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InventoryQuery,
    InventoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InventoryQuery, InventoryQueryVariables>(
    InventoryDocument,
    options,
  );
}
export type InventoryQueryHookResult = ReturnType<typeof useInventoryQuery>;
export type InventoryLazyQueryHookResult = ReturnType<
  typeof useInventoryLazyQuery
>;
export type InventoryQueryResult = Apollo.QueryResult<
  InventoryQuery,
  InventoryQueryVariables
>;
export const NotifyDocument = gql`
  subscription Notify($playerId: String!) {
    notify(playerId: $playerId) {
      ...Notification
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useNotifySubscription__
 *
 * To run a query within a React component, call `useNotifySubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotifySubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifySubscription({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useNotifySubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    NotifySubscription,
    NotifySubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotifySubscription,
    NotifySubscriptionVariables
  >(NotifyDocument, options);
}
export type NotifySubscriptionHookResult = ReturnType<
  typeof useNotifySubscription
>;
export type NotifySubscriptionResult =
  Apollo.SubscriptionResult<NotifySubscription>;
export const UnreadNotificationsDocument = gql`
  query UnreadNotifications($after: String, $limit: Int) {
    unreadNotifications(after: $after, first: $limit) {
      nodes {
        ...Notification
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${NotificationFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useUnreadNotificationsQuery__
 *
 * To run a query within a React component, call `useUnreadNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useUnreadNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UnreadNotificationsQuery,
    UnreadNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    UnreadNotificationsQuery,
    UnreadNotificationsQueryVariables
  >(UnreadNotificationsDocument, options);
}
export function useUnreadNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UnreadNotificationsQuery,
    UnreadNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    UnreadNotificationsQuery,
    UnreadNotificationsQueryVariables
  >(UnreadNotificationsDocument, options);
}
export type UnreadNotificationsQueryHookResult = ReturnType<
  typeof useUnreadNotificationsQuery
>;
export type UnreadNotificationsLazyQueryHookResult = ReturnType<
  typeof useUnreadNotificationsLazyQuery
>;
export type UnreadNotificationsQueryResult = Apollo.QueryResult<
  UnreadNotificationsQuery,
  UnreadNotificationsQueryVariables
>;
export const ReadNotificationDocument = gql`
  mutation ReadNotification($notificationIds: [String!]!) {
    readNotifications(notificationIds: $notificationIds) {
      id
    }
  }
`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      notificationIds: // value for 'notificationIds'
 *   },
 * });
 */
export function useReadNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReadNotificationMutation,
    ReadNotificationMutationVariables
  >(ReadNotificationDocument, options);
}
export type ReadNotificationMutationHookResult = ReturnType<
  typeof useReadNotificationMutation
>;
export type ReadNotificationMutationResult =
  Apollo.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = Apollo.BaseMutationOptions<
  ReadNotificationMutation,
  ReadNotificationMutationVariables
>;
export const ShopWaresDocument = gql`
  query ShopWares {
    shopWares {
      ...ShopWares
    }
  }
  ${ShopWaresFragmentDoc}
`;

/**
 * __useShopWaresQuery__
 *
 * To run a query within a React component, call `useShopWaresQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopWaresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopWaresQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopWaresQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShopWaresQuery,
    ShopWaresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopWaresQuery, ShopWaresQueryVariables>(
    ShopWaresDocument,
    options,
  );
}
export function useShopWaresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopWaresQuery,
    ShopWaresQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopWaresQuery, ShopWaresQueryVariables>(
    ShopWaresDocument,
    options,
  );
}
export type ShopWaresQueryHookResult = ReturnType<typeof useShopWaresQuery>;
export type ShopWaresLazyQueryHookResult = ReturnType<
  typeof useShopWaresLazyQuery
>;
export type ShopWaresQueryResult = Apollo.QueryResult<
  ShopWaresQuery,
  ShopWaresQueryVariables
>;
export const ShopSnacksDocument = gql`
  query ShopSnacks {
    shopSnacks {
      ...ShopSnack
    }
  }
  ${ShopSnackFragmentDoc}
`;

/**
 * __useShopSnacksQuery__
 *
 * To run a query within a React component, call `useShopSnacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopSnacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopSnacksQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopSnacksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ShopSnacksQuery,
    ShopSnacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ShopSnacksQuery, ShopSnacksQueryVariables>(
    ShopSnacksDocument,
    options,
  );
}
export function useShopSnacksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopSnacksQuery,
    ShopSnacksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ShopSnacksQuery, ShopSnacksQueryVariables>(
    ShopSnacksDocument,
    options,
  );
}
export type ShopSnacksQueryHookResult = ReturnType<typeof useShopSnacksQuery>;
export type ShopSnacksLazyQueryHookResult = ReturnType<
  typeof useShopSnacksLazyQuery
>;
export type ShopSnacksQueryResult = Apollo.QueryResult<
  ShopSnacksQuery,
  ShopSnacksQueryVariables
>;
export const TimekeeperStateDocument = gql`
  query TimekeeperState($sender: String!) {
    timekeeperState(sender: $sender) {
      ...TimekeeperUserState
    }
  }
  ${TimekeeperUserStateFragmentDoc}
`;

/**
 * __useTimekeeperStateQuery__
 *
 * To run a query within a React component, call `useTimekeeperStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimekeeperStateQuery({
 *   variables: {
 *      sender: // value for 'sender'
 *   },
 * });
 */
export function useTimekeeperStateQuery(
  baseOptions: Apollo.QueryHookOptions<
    TimekeeperStateQuery,
    TimekeeperStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimekeeperStateQuery, TimekeeperStateQueryVariables>(
    TimekeeperStateDocument,
    options,
  );
}
export function useTimekeeperStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimekeeperStateQuery,
    TimekeeperStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimekeeperStateQuery,
    TimekeeperStateQueryVariables
  >(TimekeeperStateDocument, options);
}
export type TimekeeperStateQueryHookResult = ReturnType<
  typeof useTimekeeperStateQuery
>;
export type TimekeeperStateLazyQueryHookResult = ReturnType<
  typeof useTimekeeperStateLazyQuery
>;
export type TimekeeperStateQueryResult = Apollo.QueryResult<
  TimekeeperStateQuery,
  TimekeeperStateQueryVariables
>;
export const TimekeeperRoundDocument = gql`
  query TimekeeperRound {
    timekeeperRound {
      ...TimekeeperRound
    }
  }
  ${TimekeeperRoundFragmentDoc}
`;

/**
 * __useTimekeeperRoundQuery__
 *
 * To run a query within a React component, call `useTimekeeperRoundQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperRoundQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimekeeperRoundQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimekeeperRoundQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TimekeeperRoundQuery,
    TimekeeperRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimekeeperRoundQuery, TimekeeperRoundQueryVariables>(
    TimekeeperRoundDocument,
    options,
  );
}
export function useTimekeeperRoundLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimekeeperRoundQuery,
    TimekeeperRoundQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimekeeperRoundQuery,
    TimekeeperRoundQueryVariables
  >(TimekeeperRoundDocument, options);
}
export type TimekeeperRoundQueryHookResult = ReturnType<
  typeof useTimekeeperRoundQuery
>;
export type TimekeeperRoundLazyQueryHookResult = ReturnType<
  typeof useTimekeeperRoundLazyQuery
>;
export type TimekeeperRoundQueryResult = Apollo.QueryResult<
  TimekeeperRoundQuery,
  TimekeeperRoundQueryVariables
>;
export const TimekeeperPreviousDocument = gql`
  query TimekeeperPrevious($sender: String!) {
    timekeeperPrevious(sender: $sender) {
      ...TimekeeperRequest
    }
  }
  ${TimekeeperRequestFragmentDoc}
`;

/**
 * __useTimekeeperPreviousQuery__
 *
 * To run a query within a React component, call `useTimekeeperPreviousQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperPreviousQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimekeeperPreviousQuery({
 *   variables: {
 *      sender: // value for 'sender'
 *   },
 * });
 */
export function useTimekeeperPreviousQuery(
  baseOptions: Apollo.QueryHookOptions<
    TimekeeperPreviousQuery,
    TimekeeperPreviousQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TimekeeperPreviousQuery,
    TimekeeperPreviousQueryVariables
  >(TimekeeperPreviousDocument, options);
}
export function useTimekeeperPreviousLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimekeeperPreviousQuery,
    TimekeeperPreviousQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimekeeperPreviousQuery,
    TimekeeperPreviousQueryVariables
  >(TimekeeperPreviousDocument, options);
}
export type TimekeeperPreviousQueryHookResult = ReturnType<
  typeof useTimekeeperPreviousQuery
>;
export type TimekeeperPreviousLazyQueryHookResult = ReturnType<
  typeof useTimekeeperPreviousLazyQuery
>;
export type TimekeeperPreviousQueryResult = Apollo.QueryResult<
  TimekeeperPreviousQuery,
  TimekeeperPreviousQueryVariables
>;
export const TimekeeperSaveActionDocument = gql`
  mutation TimekeeperSaveAction($interaction: TimekeeperInteractionArgInput!) {
    timekeeperSaveAction(action: $interaction) {
      ...TimekeeperRequest
    }
  }
  ${TimekeeperRequestFragmentDoc}
`;
export type TimekeeperSaveActionMutationFn = Apollo.MutationFunction<
  TimekeeperSaveActionMutation,
  TimekeeperSaveActionMutationVariables
>;

/**
 * __useTimekeeperSaveActionMutation__
 *
 * To run a mutation, you first call `useTimekeeperSaveActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperSaveActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperSaveActionMutation, { data, loading, error }] = useTimekeeperSaveActionMutation({
 *   variables: {
 *      interaction: // value for 'interaction'
 *   },
 * });
 */
export function useTimekeeperSaveActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperSaveActionMutation,
    TimekeeperSaveActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperSaveActionMutation,
    TimekeeperSaveActionMutationVariables
  >(TimekeeperSaveActionDocument, options);
}
export type TimekeeperSaveActionMutationHookResult = ReturnType<
  typeof useTimekeeperSaveActionMutation
>;
export type TimekeeperSaveActionMutationResult =
  Apollo.MutationResult<TimekeeperSaveActionMutation>;
export type TimekeeperSaveActionMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperSaveActionMutation,
  TimekeeperSaveActionMutationVariables
>;
export const TimekeeperSignRequestDocument = gql`
  mutation TimekeeperSignRequest(
    $req: TimekeeperArgsInput!
    $sig: String!
    $partialIndex: Int!
  ) {
    timekeeperSignRequest(
      changes: $req
      signature: $sig
      partialIndex: $partialIndex
    ) {
      ...TimekeeperRequest
    }
  }
  ${TimekeeperRequestFragmentDoc}
`;
export type TimekeeperSignRequestMutationFn = Apollo.MutationFunction<
  TimekeeperSignRequestMutation,
  TimekeeperSignRequestMutationVariables
>;

/**
 * __useTimekeeperSignRequestMutation__
 *
 * To run a mutation, you first call `useTimekeeperSignRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperSignRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperSignRequestMutation, { data, loading, error }] = useTimekeeperSignRequestMutation({
 *   variables: {
 *      req: // value for 'req'
 *      sig: // value for 'sig'
 *      partialIndex: // value for 'partialIndex'
 *   },
 * });
 */
export function useTimekeeperSignRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperSignRequestMutation,
    TimekeeperSignRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperSignRequestMutation,
    TimekeeperSignRequestMutationVariables
  >(TimekeeperSignRequestDocument, options);
}
export type TimekeeperSignRequestMutationHookResult = ReturnType<
  typeof useTimekeeperSignRequestMutation
>;
export type TimekeeperSignRequestMutationResult =
  Apollo.MutationResult<TimekeeperSignRequestMutation>;
export type TimekeeperSignRequestMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperSignRequestMutation,
  TimekeeperSignRequestMutationVariables
>;
export const TimekeeperToggleComputeDocument = gql`
  mutation TimekeeperToggleCompute($toggleArgs: TimekeeperToggleArgsInput!) {
    timekeeperToggleCompute(args: $toggleArgs) {
      ...TimekeeperToggle
    }
  }
  ${TimekeeperToggleFragmentDoc}
`;
export type TimekeeperToggleComputeMutationFn = Apollo.MutationFunction<
  TimekeeperToggleComputeMutation,
  TimekeeperToggleComputeMutationVariables
>;

/**
 * __useTimekeeperToggleComputeMutation__
 *
 * To run a mutation, you first call `useTimekeeperToggleComputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperToggleComputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperToggleComputeMutation, { data, loading, error }] = useTimekeeperToggleComputeMutation({
 *   variables: {
 *      toggleArgs: // value for 'toggleArgs'
 *   },
 * });
 */
export function useTimekeeperToggleComputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperToggleComputeMutation,
    TimekeeperToggleComputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperToggleComputeMutation,
    TimekeeperToggleComputeMutationVariables
  >(TimekeeperToggleComputeDocument, options);
}
export type TimekeeperToggleComputeMutationHookResult = ReturnType<
  typeof useTimekeeperToggleComputeMutation
>;
export type TimekeeperToggleComputeMutationResult =
  Apollo.MutationResult<TimekeeperToggleComputeMutation>;
export type TimekeeperToggleComputeMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperToggleComputeMutation,
  TimekeeperToggleComputeMutationVariables
>;
export const TimekeeperToggleAcceptDocument = gql`
  mutation TimekeeperToggleAccept($id: String!, $signature: String!) {
    timekeeperToggleAccept(id: $id, signature: $signature) {
      ...TimekeeperToggle
    }
  }
  ${TimekeeperToggleFragmentDoc}
`;
export type TimekeeperToggleAcceptMutationFn = Apollo.MutationFunction<
  TimekeeperToggleAcceptMutation,
  TimekeeperToggleAcceptMutationVariables
>;

/**
 * __useTimekeeperToggleAcceptMutation__
 *
 * To run a mutation, you first call `useTimekeeperToggleAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperToggleAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperToggleAcceptMutation, { data, loading, error }] = useTimekeeperToggleAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useTimekeeperToggleAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperToggleAcceptMutation,
    TimekeeperToggleAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperToggleAcceptMutation,
    TimekeeperToggleAcceptMutationVariables
  >(TimekeeperToggleAcceptDocument, options);
}
export type TimekeeperToggleAcceptMutationHookResult = ReturnType<
  typeof useTimekeeperToggleAcceptMutation
>;
export type TimekeeperToggleAcceptMutationResult =
  Apollo.MutationResult<TimekeeperToggleAcceptMutation>;
export type TimekeeperToggleAcceptMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperToggleAcceptMutation,
  TimekeeperToggleAcceptMutationVariables
>;
export const TimekeeperTransferTixComputeDocument = gql`
  mutation TimekeeperTransferTixCompute(
    $xferArgs: TimekeeperTransferTixArgsInput!
  ) {
    timekeeperTransferTixCompute(args: $xferArgs) {
      ...TimekeeperTransferTix
    }
  }
  ${TimekeeperTransferTixFragmentDoc}
`;
export type TimekeeperTransferTixComputeMutationFn = Apollo.MutationFunction<
  TimekeeperTransferTixComputeMutation,
  TimekeeperTransferTixComputeMutationVariables
>;

/**
 * __useTimekeeperTransferTixComputeMutation__
 *
 * To run a mutation, you first call `useTimekeeperTransferTixComputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperTransferTixComputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperTransferTixComputeMutation, { data, loading, error }] = useTimekeeperTransferTixComputeMutation({
 *   variables: {
 *      xferArgs: // value for 'xferArgs'
 *   },
 * });
 */
export function useTimekeeperTransferTixComputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperTransferTixComputeMutation,
    TimekeeperTransferTixComputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperTransferTixComputeMutation,
    TimekeeperTransferTixComputeMutationVariables
  >(TimekeeperTransferTixComputeDocument, options);
}
export type TimekeeperTransferTixComputeMutationHookResult = ReturnType<
  typeof useTimekeeperTransferTixComputeMutation
>;
export type TimekeeperTransferTixComputeMutationResult =
  Apollo.MutationResult<TimekeeperTransferTixComputeMutation>;
export type TimekeeperTransferTixComputeMutationOptions =
  Apollo.BaseMutationOptions<
    TimekeeperTransferTixComputeMutation,
    TimekeeperTransferTixComputeMutationVariables
  >;
export const TimekeeperTransferTixAcceptDocument = gql`
  mutation TimekeeperTransferTixAccept($id: String!, $signature: String!) {
    timekeeperTransferTixAccept(id: $id, signature: $signature) {
      ...TimekeeperTransferTix
    }
  }
  ${TimekeeperTransferTixFragmentDoc}
`;
export type TimekeeperTransferTixAcceptMutationFn = Apollo.MutationFunction<
  TimekeeperTransferTixAcceptMutation,
  TimekeeperTransferTixAcceptMutationVariables
>;

/**
 * __useTimekeeperTransferTixAcceptMutation__
 *
 * To run a mutation, you first call `useTimekeeperTransferTixAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperTransferTixAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperTransferTixAcceptMutation, { data, loading, error }] = useTimekeeperTransferTixAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useTimekeeperTransferTixAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperTransferTixAcceptMutation,
    TimekeeperTransferTixAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperTransferTixAcceptMutation,
    TimekeeperTransferTixAcceptMutationVariables
  >(TimekeeperTransferTixAcceptDocument, options);
}
export type TimekeeperTransferTixAcceptMutationHookResult = ReturnType<
  typeof useTimekeeperTransferTixAcceptMutation
>;
export type TimekeeperTransferTixAcceptMutationResult =
  Apollo.MutationResult<TimekeeperTransferTixAcceptMutation>;
export type TimekeeperTransferTixAcceptMutationOptions =
  Apollo.BaseMutationOptions<
    TimekeeperTransferTixAcceptMutation,
    TimekeeperTransferTixAcceptMutationVariables
  >;
export const TimekeeperSaveAdHocDocument = gql`
  mutation TimekeeperSaveAdHoc(
    $args: TimekeeperAdHocArgsInput!
    $signature: String!
  ) {
    timekeeperSaveAdHoc(action: $args, signature: $signature) {
      ...TimekeeperAdHoc
    }
  }
  ${TimekeeperAdHocFragmentDoc}
`;
export type TimekeeperSaveAdHocMutationFn = Apollo.MutationFunction<
  TimekeeperSaveAdHocMutation,
  TimekeeperSaveAdHocMutationVariables
>;

/**
 * __useTimekeeperSaveAdHocMutation__
 *
 * To run a mutation, you first call `useTimekeeperSaveAdHocMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperSaveAdHocMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperSaveAdHocMutation, { data, loading, error }] = useTimekeeperSaveAdHocMutation({
 *   variables: {
 *      args: // value for 'args'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useTimekeeperSaveAdHocMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperSaveAdHocMutation,
    TimekeeperSaveAdHocMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperSaveAdHocMutation,
    TimekeeperSaveAdHocMutationVariables
  >(TimekeeperSaveAdHocDocument, options);
}
export type TimekeeperSaveAdHocMutationHookResult = ReturnType<
  typeof useTimekeeperSaveAdHocMutation
>;
export type TimekeeperSaveAdHocMutationResult =
  Apollo.MutationResult<TimekeeperSaveAdHocMutation>;
export type TimekeeperSaveAdHocMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperSaveAdHocMutation,
  TimekeeperSaveAdHocMutationVariables
>;
export const TimekeeperAdHocsDocument = gql`
  query TimekeeperAdHocs($sender: String!) {
    timekeeperAdHocs(sender: $sender) {
      ...TimekeeperAdHoc
    }
  }
  ${TimekeeperAdHocFragmentDoc}
`;

/**
 * __useTimekeeperAdHocsQuery__
 *
 * To run a query within a React component, call `useTimekeeperAdHocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperAdHocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimekeeperAdHocsQuery({
 *   variables: {
 *      sender: // value for 'sender'
 *   },
 * });
 */
export function useTimekeeperAdHocsQuery(
  baseOptions: Apollo.QueryHookOptions<
    TimekeeperAdHocsQuery,
    TimekeeperAdHocsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TimekeeperAdHocsQuery, TimekeeperAdHocsQueryVariables>(
    TimekeeperAdHocsDocument,
    options,
  );
}
export function useTimekeeperAdHocsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TimekeeperAdHocsQuery,
    TimekeeperAdHocsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TimekeeperAdHocsQuery,
    TimekeeperAdHocsQueryVariables
  >(TimekeeperAdHocsDocument, options);
}
export type TimekeeperAdHocsQueryHookResult = ReturnType<
  typeof useTimekeeperAdHocsQuery
>;
export type TimekeeperAdHocsLazyQueryHookResult = ReturnType<
  typeof useTimekeeperAdHocsLazyQuery
>;
export type TimekeeperAdHocsQueryResult = Apollo.QueryResult<
  TimekeeperAdHocsQuery,
  TimekeeperAdHocsQueryVariables
>;
export const TimekeeperWrapFurComputeDocument = gql`
  mutation TimekeeperWrapFurCompute($wrapFurArgs: TimekeeperWrapFurArgsInput!) {
    wrapFurCompute(args: $wrapFurArgs) {
      spent
      gained
      ...TimekeeperWrapFur
    }
  }
  ${TimekeeperWrapFurFragmentDoc}
`;
export type TimekeeperWrapFurComputeMutationFn = Apollo.MutationFunction<
  TimekeeperWrapFurComputeMutation,
  TimekeeperWrapFurComputeMutationVariables
>;

/**
 * __useTimekeeperWrapFurComputeMutation__
 *
 * To run a mutation, you first call `useTimekeeperWrapFurComputeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperWrapFurComputeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperWrapFurComputeMutation, { data, loading, error }] = useTimekeeperWrapFurComputeMutation({
 *   variables: {
 *      wrapFurArgs: // value for 'wrapFurArgs'
 *   },
 * });
 */
export function useTimekeeperWrapFurComputeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperWrapFurComputeMutation,
    TimekeeperWrapFurComputeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperWrapFurComputeMutation,
    TimekeeperWrapFurComputeMutationVariables
  >(TimekeeperWrapFurComputeDocument, options);
}
export type TimekeeperWrapFurComputeMutationHookResult = ReturnType<
  typeof useTimekeeperWrapFurComputeMutation
>;
export type TimekeeperWrapFurComputeMutationResult =
  Apollo.MutationResult<TimekeeperWrapFurComputeMutation>;
export type TimekeeperWrapFurComputeMutationOptions =
  Apollo.BaseMutationOptions<
    TimekeeperWrapFurComputeMutation,
    TimekeeperWrapFurComputeMutationVariables
  >;
export const TimekeeperWrapFurAcceptDocument = gql`
  mutation TimekeeperWrapFurAccept($id: String!, $signature: String!) {
    wrapFurAccept(id: $id, signature: $signature) {
      ...TimekeeperWrapFur
    }
  }
  ${TimekeeperWrapFurFragmentDoc}
`;
export type TimekeeperWrapFurAcceptMutationFn = Apollo.MutationFunction<
  TimekeeperWrapFurAcceptMutation,
  TimekeeperWrapFurAcceptMutationVariables
>;

/**
 * __useTimekeeperWrapFurAcceptMutation__
 *
 * To run a mutation, you first call `useTimekeeperWrapFurAcceptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTimekeeperWrapFurAcceptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [timekeeperWrapFurAcceptMutation, { data, loading, error }] = useTimekeeperWrapFurAcceptMutation({
 *   variables: {
 *      id: // value for 'id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useTimekeeperWrapFurAcceptMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TimekeeperWrapFurAcceptMutation,
    TimekeeperWrapFurAcceptMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TimekeeperWrapFurAcceptMutation,
    TimekeeperWrapFurAcceptMutationVariables
  >(TimekeeperWrapFurAcceptDocument, options);
}
export type TimekeeperWrapFurAcceptMutationHookResult = ReturnType<
  typeof useTimekeeperWrapFurAcceptMutation
>;
export type TimekeeperWrapFurAcceptMutationResult =
  Apollo.MutationResult<TimekeeperWrapFurAcceptMutation>;
export type TimekeeperWrapFurAcceptMutationOptions = Apollo.BaseMutationOptions<
  TimekeeperWrapFurAcceptMutation,
  TimekeeperWrapFurAcceptMutationVariables
>;
export const WrapFurDocument = gql`
  mutation WrapFur(
    $wrapFurArgs: TimekeeperWrapFurArgsInput!
    $signature: String!
  ) {
    wrapFur(args: $wrapFurArgs, signature: $signature) {
      ...TimekeeperWrapFur
    }
  }
  ${TimekeeperWrapFurFragmentDoc}
`;
export type WrapFurMutationFn = Apollo.MutationFunction<
  WrapFurMutation,
  WrapFurMutationVariables
>;

/**
 * __useWrapFurMutation__
 *
 * To run a mutation, you first call `useWrapFurMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWrapFurMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wrapFurMutation, { data, loading, error }] = useWrapFurMutation({
 *   variables: {
 *      wrapFurArgs: // value for 'wrapFurArgs'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useWrapFurMutation(
  baseOptions?: Apollo.MutationHookOptions<
    WrapFurMutation,
    WrapFurMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WrapFurMutation, WrapFurMutationVariables>(
    WrapFurDocument,
    options,
  );
}
export type WrapFurMutationHookResult = ReturnType<typeof useWrapFurMutation>;
export type WrapFurMutationResult = Apollo.MutationResult<WrapFurMutation>;
export type WrapFurMutationOptions = Apollo.BaseMutationOptions<
  WrapFurMutation,
  WrapFurMutationVariables
>;
export const SpecializeDocument = gql`
  mutation Specialize(
    $furballId: String!
    $specialization: Specialization!
    $renameArgs: RenameFurballArgsInput
    $renameSignature: String
  ) {
    specializeFurball(
      furballId: $furballId
      specialization: $specialization
      renameArgs: $renameArgs
      renameSignature: $renameSignature
    ) {
      ...Furball
    }
  }
  ${FurballFragmentDoc}
`;
export type SpecializeMutationFn = Apollo.MutationFunction<
  SpecializeMutation,
  SpecializeMutationVariables
>;

/**
 * __useSpecializeMutation__
 *
 * To run a mutation, you first call `useSpecializeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSpecializeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [specializeMutation, { data, loading, error }] = useSpecializeMutation({
 *   variables: {
 *      furballId: // value for 'furballId'
 *      specialization: // value for 'specialization'
 *      renameArgs: // value for 'renameArgs'
 *      renameSignature: // value for 'renameSignature'
 *   },
 * });
 */
export function useSpecializeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SpecializeMutation,
    SpecializeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SpecializeMutation, SpecializeMutationVariables>(
    SpecializeDocument,
    options,
  );
}
export type SpecializeMutationHookResult = ReturnType<
  typeof useSpecializeMutation
>;
export type SpecializeMutationResult =
  Apollo.MutationResult<SpecializeMutation>;
export type SpecializeMutationOptions = Apollo.BaseMutationOptions<
  SpecializeMutation,
  SpecializeMutationVariables
>;
export const FurballNameChangesDocument = gql`
  query FurballNameChanges {
    furballNameChanges(order: { createdAt: DESC }) {
      nodes {
        ...FurballNameChange
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${FurballNameChangeFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useFurballNameChangesQuery__
 *
 * To run a query within a React component, call `useFurballNameChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFurballNameChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFurballNameChangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFurballNameChangesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FurballNameChangesQuery,
    FurballNameChangesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FurballNameChangesQuery,
    FurballNameChangesQueryVariables
  >(FurballNameChangesDocument, options);
}
export function useFurballNameChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FurballNameChangesQuery,
    FurballNameChangesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FurballNameChangesQuery,
    FurballNameChangesQueryVariables
  >(FurballNameChangesDocument, options);
}
export type FurballNameChangesQueryHookResult = ReturnType<
  typeof useFurballNameChangesQuery
>;
export type FurballNameChangesLazyQueryHookResult = ReturnType<
  typeof useFurballNameChangesLazyQuery
>;
export type FurballNameChangesQueryResult = Apollo.QueryResult<
  FurballNameChangesQuery,
  FurballNameChangesQueryVariables
>;
export const ApproveNameDocument = gql`
  mutation ApproveName($renameId: String!, $approve: Boolean!) {
    renameFurballAccept(renameId: $renameId, approve: $approve) {
      ...FurballNameChange
    }
  }
  ${FurballNameChangeFragmentDoc}
`;
export type ApproveNameMutationFn = Apollo.MutationFunction<
  ApproveNameMutation,
  ApproveNameMutationVariables
>;

/**
 * __useApproveNameMutation__
 *
 * To run a mutation, you first call `useApproveNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveNameMutation, { data, loading, error }] = useApproveNameMutation({
 *   variables: {
 *      renameId: // value for 'renameId'
 *      approve: // value for 'approve'
 *   },
 * });
 */
export function useApproveNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveNameMutation,
    ApproveNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApproveNameMutation, ApproveNameMutationVariables>(
    ApproveNameDocument,
    options,
  );
}
export type ApproveNameMutationHookResult = ReturnType<
  typeof useApproveNameMutation
>;
export type ApproveNameMutationResult =
  Apollo.MutationResult<ApproveNameMutation>;
export type ApproveNameMutationOptions = Apollo.BaseMutationOptions<
  ApproveNameMutation,
  ApproveNameMutationVariables
>;
export const SubmitVoyageEncounterDocument = gql`
  mutation SubmitVoyageEncounter(
    $complete: Boolean!
    $tokenId: String!
    $decision: Int!
  ) {
    submitVoyageEncounter(
      complete: $complete
      tokenId: $tokenId
      decision: $decision
    ) {
      ...VoyageEncounter
    }
  }
  ${VoyageEncounterFragmentDoc}
`;
export type SubmitVoyageEncounterMutationFn = Apollo.MutationFunction<
  SubmitVoyageEncounterMutation,
  SubmitVoyageEncounterMutationVariables
>;

/**
 * __useSubmitVoyageEncounterMutation__
 *
 * To run a mutation, you first call `useSubmitVoyageEncounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitVoyageEncounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitVoyageEncounterMutation, { data, loading, error }] = useSubmitVoyageEncounterMutation({
 *   variables: {
 *      complete: // value for 'complete'
 *      tokenId: // value for 'tokenId'
 *      decision: // value for 'decision'
 *   },
 * });
 */
export function useSubmitVoyageEncounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SubmitVoyageEncounterMutation,
    SubmitVoyageEncounterMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SubmitVoyageEncounterMutation,
    SubmitVoyageEncounterMutationVariables
  >(SubmitVoyageEncounterDocument, options);
}
export type SubmitVoyageEncounterMutationHookResult = ReturnType<
  typeof useSubmitVoyageEncounterMutation
>;
export type SubmitVoyageEncounterMutationResult =
  Apollo.MutationResult<SubmitVoyageEncounterMutation>;
export type SubmitVoyageEncounterMutationOptions = Apollo.BaseMutationOptions<
  SubmitVoyageEncounterMutation,
  SubmitVoyageEncounterMutationVariables
>;
export const MoveZonesDocument = gql`
  mutation MoveZones($requests: [MoveZoneRequestInput!]!) {
    moveZones(requests: $requests) {
      ...MoveZonesResponse
    }
  }
  ${MoveZonesResponseFragmentDoc}
`;
export type MoveZonesMutationFn = Apollo.MutationFunction<
  MoveZonesMutation,
  MoveZonesMutationVariables
>;

/**
 * __useMoveZonesMutation__
 *
 * To run a mutation, you first call `useMoveZonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveZonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveZonesMutation, { data, loading, error }] = useMoveZonesMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useMoveZonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveZonesMutation,
    MoveZonesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MoveZonesMutation, MoveZonesMutationVariables>(
    MoveZonesDocument,
    options,
  );
}
export type MoveZonesMutationHookResult = ReturnType<
  typeof useMoveZonesMutation
>;
export type MoveZonesMutationResult = Apollo.MutationResult<MoveZonesMutation>;
export type MoveZonesMutationOptions = Apollo.BaseMutationOptions<
  MoveZonesMutation,
  MoveZonesMutationVariables
>;
export const TransactionHistoryDocument = gql`
  query TransactionHistory($endCursor: String, $token: [String]) {
    playerTransactions(
      first: 20
      after: $endCursor
      where: { token: { name: { in: $token } } }
    ) {
      totalCount
      nodes {
        id
        amount
        validation
        createdAt
        token {
          name
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

/**
 * __useTransactionHistoryQuery__
 *
 * To run a query within a React component, call `useTransactionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionHistoryQuery({
 *   variables: {
 *      endCursor: // value for 'endCursor'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useTransactionHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TransactionHistoryQuery,
    TransactionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    TransactionHistoryQuery,
    TransactionHistoryQueryVariables
  >(TransactionHistoryDocument, options);
}
export function useTransactionHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TransactionHistoryQuery,
    TransactionHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TransactionHistoryQuery,
    TransactionHistoryQueryVariables
  >(TransactionHistoryDocument, options);
}
export type TransactionHistoryQueryHookResult = ReturnType<
  typeof useTransactionHistoryQuery
>;
export type TransactionHistoryLazyQueryHookResult = ReturnType<
  typeof useTransactionHistoryLazyQuery
>;
export type TransactionHistoryQueryResult = Apollo.QueryResult<
  TransactionHistoryQuery,
  TransactionHistoryQueryVariables
>;
export const ChangeUsernameDocument = gql`
  mutation ChangeUsername($username: String!) {
    changeUsername(username: $username) {
      id
      username
      socialName
    }
  }
`;
export type ChangeUsernameMutationFn = Apollo.MutationFunction<
  ChangeUsernameMutation,
  ChangeUsernameMutationVariables
>;

/**
 * __useChangeUsernameMutation__
 *
 * To run a mutation, you first call `useChangeUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUsernameMutation, { data, loading, error }] = useChangeUsernameMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useChangeUsernameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUsernameMutation,
    ChangeUsernameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeUsernameMutation,
    ChangeUsernameMutationVariables
  >(ChangeUsernameDocument, options);
}
export type ChangeUsernameMutationHookResult = ReturnType<
  typeof useChangeUsernameMutation
>;
export type ChangeUsernameMutationResult =
  Apollo.MutationResult<ChangeUsernameMutation>;
export type ChangeUsernameMutationOptions = Apollo.BaseMutationOptions<
  ChangeUsernameMutation,
  ChangeUsernameMutationVariables
>;
export const BossesDocument = gql`
  query Bosses {
    topBossBattles {
      boss {
        id
        name
        realm
        level
        health
        maxHealth
      }
    }
  }
`;

/**
 * __useBossesQuery__
 *
 * To run a query within a React component, call `useBossesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBossesQuery(
  baseOptions?: Apollo.QueryHookOptions<BossesQuery, BossesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BossesQuery, BossesQueryVariables>(
    BossesDocument,
    options,
  );
}
export function useBossesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BossesQuery, BossesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BossesQuery, BossesQueryVariables>(
    BossesDocument,
    options,
  );
}
export type BossesQueryHookResult = ReturnType<typeof useBossesQuery>;
export type BossesLazyQueryHookResult = ReturnType<typeof useBossesLazyQuery>;
export type BossesQueryResult = Apollo.QueryResult<
  BossesQuery,
  BossesQueryVariables
>;
export const PlayerPartiesDocument = gql`
  query PlayerParties($playerId: String!) {
    playerParties(playerId: $playerId) {
      ...PlayerParty
    }
  }
  ${PlayerPartyFragmentDoc}
`;

/**
 * __usePlayerPartiesQuery__
 *
 * To run a query within a React component, call `usePlayerPartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerPartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerPartiesQuery({
 *   variables: {
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function usePlayerPartiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    PlayerPartiesQuery,
    PlayerPartiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PlayerPartiesQuery, PlayerPartiesQueryVariables>(
    PlayerPartiesDocument,
    options,
  );
}
export function usePlayerPartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PlayerPartiesQuery,
    PlayerPartiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PlayerPartiesQuery, PlayerPartiesQueryVariables>(
    PlayerPartiesDocument,
    options,
  );
}
export type PlayerPartiesQueryHookResult = ReturnType<
  typeof usePlayerPartiesQuery
>;
export type PlayerPartiesLazyQueryHookResult = ReturnType<
  typeof usePlayerPartiesLazyQuery
>;
export type PlayerPartiesQueryResult = Apollo.QueryResult<
  PlayerPartiesQuery,
  PlayerPartiesQueryVariables
>;
export const PartyDocument = gql`
  query Party($partyId: String!) {
    party(partyId: $partyId) {
      ...PlayerPartyFurballs
    }
  }
  ${PlayerPartyFurballsFragmentDoc}
`;

/**
 * __usePartyQuery__
 *
 * To run a query within a React component, call `usePartyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartyQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function usePartyQuery(
  baseOptions: Apollo.QueryHookOptions<PartyQuery, PartyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartyQuery, PartyQueryVariables>(
    PartyDocument,
    options,
  );
}
export function usePartyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PartyQuery, PartyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartyQuery, PartyQueryVariables>(
    PartyDocument,
    options,
  );
}
export type PartyQueryHookResult = ReturnType<typeof usePartyQuery>;
export type PartyLazyQueryHookResult = ReturnType<typeof usePartyLazyQuery>;
export type PartyQueryResult = Apollo.QueryResult<
  PartyQuery,
  PartyQueryVariables
>;
export const PartyCompleteDocument = gql`
  query PartyComplete($partyId: String!) {
    party(partyId: $partyId) {
      name
      partyMemberIds
      size
    }
  }
`;

/**
 * __usePartyCompleteQuery__
 *
 * To run a query within a React component, call `usePartyCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartyCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartyCompleteQuery({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function usePartyCompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    PartyCompleteQuery,
    PartyCompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartyCompleteQuery, PartyCompleteQueryVariables>(
    PartyCompleteDocument,
    options,
  );
}
export function usePartyCompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PartyCompleteQuery,
    PartyCompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartyCompleteQuery, PartyCompleteQueryVariables>(
    PartyCompleteDocument,
    options,
  );
}
export type PartyCompleteQueryHookResult = ReturnType<
  typeof usePartyCompleteQuery
>;
export type PartyCompleteLazyQueryHookResult = ReturnType<
  typeof usePartyCompleteLazyQuery
>;
export type PartyCompleteQueryResult = Apollo.QueryResult<
  PartyCompleteQuery,
  PartyCompleteQueryVariables
>;
export const BossBattleDocument = gql`
  query BossBattle($id: String!) {
    bossBattle(id: $id) {
      ...BossBattlePlayer
    }
  }
  ${BossBattlePlayerFragmentDoc}
`;

/**
 * __useBossBattleQuery__
 *
 * To run a query within a React component, call `useBossBattleQuery` and pass it any options that fit your needs.
 * When your component renders, `useBossBattleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBossBattleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBossBattleQuery(
  baseOptions: Apollo.QueryHookOptions<
    BossBattleQuery,
    BossBattleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BossBattleQuery, BossBattleQueryVariables>(
    BossBattleDocument,
    options,
  );
}
export function useBossBattleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BossBattleQuery,
    BossBattleQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BossBattleQuery, BossBattleQueryVariables>(
    BossBattleDocument,
    options,
  );
}
export type BossBattleQueryHookResult = ReturnType<typeof useBossBattleQuery>;
export type BossBattleLazyQueryHookResult = ReturnType<
  typeof useBossBattleLazyQuery
>;
export type BossBattleQueryResult = Apollo.QueryResult<
  BossBattleQuery,
  BossBattleQueryVariables
>;
export const SetpartyDocument = gql`
  mutation Setparty(
    $id: String
    $name: String
    $slotNumber: Int
    $formationPositions: [FormationPosition!]
    $furballIds: [String!]!
    $playerId: String!
    $deleteParty: Boolean
  ) {
    setParty(
      args: {
        id: $id
        name: $name
        slotNumber: $slotNumber
        formationPositions: $formationPositions
        furballIds: $furballIds
        playerId: $playerId
        deleteParty: $deleteParty
      }
    ) {
      ...PlayerParty
    }
  }
  ${PlayerPartyFragmentDoc}
`;
export type SetpartyMutationFn = Apollo.MutationFunction<
  SetpartyMutation,
  SetpartyMutationVariables
>;

/**
 * __useSetpartyMutation__
 *
 * To run a mutation, you first call `useSetpartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetpartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setpartyMutation, { data, loading, error }] = useSetpartyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      slotNumber: // value for 'slotNumber'
 *      formationPositions: // value for 'formationPositions'
 *      furballIds: // value for 'furballIds'
 *      playerId: // value for 'playerId'
 *      deleteParty: // value for 'deleteParty'
 *   },
 * });
 */
export function useSetpartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetpartyMutation,
    SetpartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetpartyMutation, SetpartyMutationVariables>(
    SetpartyDocument,
    options,
  );
}
export type SetpartyMutationHookResult = ReturnType<typeof useSetpartyMutation>;
export type SetpartyMutationResult = Apollo.MutationResult<SetpartyMutation>;
export type SetpartyMutationOptions = Apollo.BaseMutationOptions<
  SetpartyMutation,
  SetpartyMutationVariables
>;
export const ForfeitBattleDocument = gql`
  mutation ForfeitBattle($id: String!) {
    forfeitBattle(id: $id) {
      id
    }
  }
`;
export type ForfeitBattleMutationFn = Apollo.MutationFunction<
  ForfeitBattleMutation,
  ForfeitBattleMutationVariables
>;

/**
 * __useForfeitBattleMutation__
 *
 * To run a mutation, you first call `useForfeitBattleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForfeitBattleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forfeitBattleMutation, { data, loading, error }] = useForfeitBattleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useForfeitBattleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForfeitBattleMutation,
    ForfeitBattleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ForfeitBattleMutation,
    ForfeitBattleMutationVariables
  >(ForfeitBattleDocument, options);
}
export type ForfeitBattleMutationHookResult = ReturnType<
  typeof useForfeitBattleMutation
>;
export type ForfeitBattleMutationResult =
  Apollo.MutationResult<ForfeitBattleMutation>;
export type ForfeitBattleMutationOptions = Apollo.BaseMutationOptions<
  ForfeitBattleMutation,
  ForfeitBattleMutationVariables
>;
export const JoinBattleDocument = gql`
  mutation JoinBattle(
    $realm: RealmType!
    $partyId: String!
    $trialGame: Boolean!
  ) {
    joinBattle(realmType: $realm, partyId: $partyId) {
      partyId
      joinBossBattle(practiceGame: $trialGame) {
        ...BossBattlePlayer
      }
    }
  }
  ${BossBattlePlayerFragmentDoc}
`;
export type JoinBattleMutationFn = Apollo.MutationFunction<
  JoinBattleMutation,
  JoinBattleMutationVariables
>;

/**
 * __useJoinBattleMutation__
 *
 * To run a mutation, you first call `useJoinBattleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinBattleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinBattleMutation, { data, loading, error }] = useJoinBattleMutation({
 *   variables: {
 *      realm: // value for 'realm'
 *      partyId: // value for 'partyId'
 *      trialGame: // value for 'trialGame'
 *   },
 * });
 */
export function useJoinBattleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinBattleMutation,
    JoinBattleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinBattleMutation, JoinBattleMutationVariables>(
    JoinBattleDocument,
    options,
  );
}
export type JoinBattleMutationHookResult = ReturnType<
  typeof useJoinBattleMutation
>;
export type JoinBattleMutationResult =
  Apollo.MutationResult<JoinBattleMutation>;
export type JoinBattleMutationOptions = Apollo.BaseMutationOptions<
  JoinBattleMutation,
  JoinBattleMutationVariables
>;
export const BuyBossKeysDocument = gql`
  mutation BuyBossKeys($furballIds: [String!]!, $realms: [RealmType!]!) {
    buyBossKeys(furballIds: $furballIds, realms: $realms) {
      remainingFuel
      spentFuel
      furballs {
        ...Furball
      }
    }
  }
  ${FurballFragmentDoc}
`;
export type BuyBossKeysMutationFn = Apollo.MutationFunction<
  BuyBossKeysMutation,
  BuyBossKeysMutationVariables
>;

/**
 * __useBuyBossKeysMutation__
 *
 * To run a mutation, you first call `useBuyBossKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyBossKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyBossKeysMutation, { data, loading, error }] = useBuyBossKeysMutation({
 *   variables: {
 *      furballIds: // value for 'furballIds'
 *      realms: // value for 'realms'
 *   },
 * });
 */
export function useBuyBossKeysMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyBossKeysMutation,
    BuyBossKeysMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BuyBossKeysMutation, BuyBossKeysMutationVariables>(
    BuyBossKeysDocument,
    options,
  );
}
export type BuyBossKeysMutationHookResult = ReturnType<
  typeof useBuyBossKeysMutation
>;
export type BuyBossKeysMutationResult =
  Apollo.MutationResult<BuyBossKeysMutation>;
export type BuyBossKeysMutationOptions = Apollo.BaseMutationOptions<
  BuyBossKeysMutation,
  BuyBossKeysMutationVariables
>;
export const RefillHeartsDocument = gql`
  mutation RefillHearts($partyId: String!) {
    party: refillHearts(partyId: $partyId) {
      ...PlayerPartyFurballs
    }
  }
  ${PlayerPartyFurballsFragmentDoc}
`;
export type RefillHeartsMutationFn = Apollo.MutationFunction<
  RefillHeartsMutation,
  RefillHeartsMutationVariables
>;

/**
 * __useRefillHeartsMutation__
 *
 * To run a mutation, you first call `useRefillHeartsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefillHeartsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refillHeartsMutation, { data, loading, error }] = useRefillHeartsMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useRefillHeartsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefillHeartsMutation,
    RefillHeartsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefillHeartsMutation,
    RefillHeartsMutationVariables
  >(RefillHeartsDocument, options);
}
export type RefillHeartsMutationHookResult = ReturnType<
  typeof useRefillHeartsMutation
>;
export type RefillHeartsMutationResult =
  Apollo.MutationResult<RefillHeartsMutation>;
export type RefillHeartsMutationOptions = Apollo.BaseMutationOptions<
  RefillHeartsMutation,
  RefillHeartsMutationVariables
>;
export const CreateDuelDocument = gql`
  mutation CreateDuel(
    $partyId: String!
    $rules: GamePlayRulesInput
    $ante: AnteInput
  ) {
    duel: createDuelBattle(partyId: $partyId, rules: $rules, ante: $ante) {
      ...DuelBattle
    }
  }
  ${DuelBattleFragmentDoc}
`;
export type CreateDuelMutationFn = Apollo.MutationFunction<
  CreateDuelMutation,
  CreateDuelMutationVariables
>;

/**
 * __useCreateDuelMutation__
 *
 * To run a mutation, you first call `useCreateDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDuelMutation, { data, loading, error }] = useCreateDuelMutation({
 *   variables: {
 *      partyId: // value for 'partyId'
 *      rules: // value for 'rules'
 *      ante: // value for 'ante'
 *   },
 * });
 */
export function useCreateDuelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDuelMutation,
    CreateDuelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateDuelMutation, CreateDuelMutationVariables>(
    CreateDuelDocument,
    options,
  );
}
export type CreateDuelMutationHookResult = ReturnType<
  typeof useCreateDuelMutation
>;
export type CreateDuelMutationResult =
  Apollo.MutationResult<CreateDuelMutation>;
export type CreateDuelMutationOptions = Apollo.BaseMutationOptions<
  CreateDuelMutation,
  CreateDuelMutationVariables
>;
export const JoinDuelDocument = gql`
  mutation JoinDuel($gameBattleId: String!, $partyId: String!) {
    duel: joinDuelBattle(gameBattleId: $gameBattleId, partyId: $partyId) {
      ...DuelBattle
    }
  }
  ${DuelBattleFragmentDoc}
`;
export type JoinDuelMutationFn = Apollo.MutationFunction<
  JoinDuelMutation,
  JoinDuelMutationVariables
>;

/**
 * __useJoinDuelMutation__
 *
 * To run a mutation, you first call `useJoinDuelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinDuelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinDuelMutation, { data, loading, error }] = useJoinDuelMutation({
 *   variables: {
 *      gameBattleId: // value for 'gameBattleId'
 *      partyId: // value for 'partyId'
 *   },
 * });
 */
export function useJoinDuelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinDuelMutation,
    JoinDuelMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinDuelMutation, JoinDuelMutationVariables>(
    JoinDuelDocument,
    options,
  );
}
export type JoinDuelMutationHookResult = ReturnType<typeof useJoinDuelMutation>;
export type JoinDuelMutationResult = Apollo.MutationResult<JoinDuelMutation>;
export type JoinDuelMutationOptions = Apollo.BaseMutationOptions<
  JoinDuelMutation,
  JoinDuelMutationVariables
>;
export const ListOpenDuelsDocument = gql`
  query ListOpenDuels {
    duelBattles(where: { battleState: { eq: LOADING } }) {
      nodes {
        ...DuelBattle
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${DuelBattleFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useListOpenDuelsQuery__
 *
 * To run a query within a React component, call `useListOpenDuelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOpenDuelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOpenDuelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOpenDuelsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListOpenDuelsQuery,
    ListOpenDuelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOpenDuelsQuery, ListOpenDuelsQueryVariables>(
    ListOpenDuelsDocument,
    options,
  );
}
export function useListOpenDuelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListOpenDuelsQuery,
    ListOpenDuelsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOpenDuelsQuery, ListOpenDuelsQueryVariables>(
    ListOpenDuelsDocument,
    options,
  );
}
export type ListOpenDuelsQueryHookResult = ReturnType<
  typeof useListOpenDuelsQuery
>;
export type ListOpenDuelsLazyQueryHookResult = ReturnType<
  typeof useListOpenDuelsLazyQuery
>;
export type ListOpenDuelsQueryResult = Apollo.QueryResult<
  ListOpenDuelsQuery,
  ListOpenDuelsQueryVariables
>;
export const LootMarketplaceDocument = gql`
  query LootMarketplace($itemIds: [Long!]!) {
    itemListings(
      where: { itemDefinitionId: { in: $itemIds } }
      order: { currentPriceEach: ASC }
    ) {
      nodes {
        ...ItemListing
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${ItemListingFragmentDoc}
  ${PageInfoFragmentDoc}
`;

/**
 * __useLootMarketplaceQuery__
 *
 * To run a query within a React component, call `useLootMarketplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useLootMarketplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLootMarketplaceQuery({
 *   variables: {
 *      itemIds: // value for 'itemIds'
 *   },
 * });
 */
export function useLootMarketplaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    LootMarketplaceQuery,
    LootMarketplaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LootMarketplaceQuery, LootMarketplaceQueryVariables>(
    LootMarketplaceDocument,
    options,
  );
}
export function useLootMarketplaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LootMarketplaceQuery,
    LootMarketplaceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LootMarketplaceQuery,
    LootMarketplaceQueryVariables
  >(LootMarketplaceDocument, options);
}
export type LootMarketplaceQueryHookResult = ReturnType<
  typeof useLootMarketplaceQuery
>;
export type LootMarketplaceLazyQueryHookResult = ReturnType<
  typeof useLootMarketplaceLazyQuery
>;
export type LootMarketplaceQueryResult = Apollo.QueryResult<
  LootMarketplaceQuery,
  LootMarketplaceQueryVariables
>;
export const MyLootListingsDocument = gql`
  query MyLootListings {
    myItemListings {
      ...ItemListing
    }
  }
  ${ItemListingFragmentDoc}
`;

/**
 * __useMyLootListingsQuery__
 *
 * To run a query within a React component, call `useMyLootListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyLootListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyLootListingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyLootListingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MyLootListingsQuery,
    MyLootListingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyLootListingsQuery, MyLootListingsQueryVariables>(
    MyLootListingsDocument,
    options,
  );
}
export function useMyLootListingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyLootListingsQuery,
    MyLootListingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyLootListingsQuery, MyLootListingsQueryVariables>(
    MyLootListingsDocument,
    options,
  );
}
export type MyLootListingsQueryHookResult = ReturnType<
  typeof useMyLootListingsQuery
>;
export type MyLootListingsLazyQueryHookResult = ReturnType<
  typeof useMyLootListingsLazyQuery
>;
export type MyLootListingsQueryResult = Apollo.QueryResult<
  MyLootListingsQuery,
  MyLootListingsQueryVariables
>;
export const CreateLootListingDocument = gql`
  mutation CreateLootListing(
    $itemId: String!
    $price: UnsignedLong!
    $quantity: UnsignedInt!
  ) {
    listing: createItemListing(
      itemId: $itemId
      price: $price
      quantity: $quantity
    ) {
      ...ItemListing
      seller {
        ...BuyerSellerPlayer
      }
    }
  }
  ${ItemListingFragmentDoc}
  ${BuyerSellerPlayerFragmentDoc}
`;
export type CreateLootListingMutationFn = Apollo.MutationFunction<
  CreateLootListingMutation,
  CreateLootListingMutationVariables
>;

/**
 * __useCreateLootListingMutation__
 *
 * To run a mutation, you first call `useCreateLootListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLootListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLootListingMutation, { data, loading, error }] = useCreateLootListingMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      price: // value for 'price'
 *      quantity: // value for 'quantity'
 *   },
 * });
 */
export function useCreateLootListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLootListingMutation,
    CreateLootListingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLootListingMutation,
    CreateLootListingMutationVariables
  >(CreateLootListingDocument, options);
}
export type CreateLootListingMutationHookResult = ReturnType<
  typeof useCreateLootListingMutation
>;
export type CreateLootListingMutationResult =
  Apollo.MutationResult<CreateLootListingMutation>;
export type CreateLootListingMutationOptions = Apollo.BaseMutationOptions<
  CreateLootListingMutation,
  CreateLootListingMutationVariables
>;
export const EditLootListingDocument = gql`
  mutation EditLootListing($id: String!, $price: UnsignedLong!) {
    listing: editItemListing(listingId: $id, price: $price) {
      ...ItemListing
    }
  }
  ${ItemListingFragmentDoc}
`;
export type EditLootListingMutationFn = Apollo.MutationFunction<
  EditLootListingMutation,
  EditLootListingMutationVariables
>;

/**
 * __useEditLootListingMutation__
 *
 * To run a mutation, you first call `useEditLootListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditLootListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editLootListingMutation, { data, loading, error }] = useEditLootListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useEditLootListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditLootListingMutation,
    EditLootListingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditLootListingMutation,
    EditLootListingMutationVariables
  >(EditLootListingDocument, options);
}
export type EditLootListingMutationHookResult = ReturnType<
  typeof useEditLootListingMutation
>;
export type EditLootListingMutationResult =
  Apollo.MutationResult<EditLootListingMutation>;
export type EditLootListingMutationOptions = Apollo.BaseMutationOptions<
  EditLootListingMutation,
  EditLootListingMutationVariables
>;
export const CancelLootListingDocument = gql`
  mutation CancelLootListing($id: String!) {
    listing: cancelItemListing(listingId: $id) {
      ...ItemListing
    }
  }
  ${ItemListingFragmentDoc}
`;
export type CancelLootListingMutationFn = Apollo.MutationFunction<
  CancelLootListingMutation,
  CancelLootListingMutationVariables
>;

/**
 * __useCancelLootListingMutation__
 *
 * To run a mutation, you first call `useCancelLootListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelLootListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelLootListingMutation, { data, loading, error }] = useCancelLootListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelLootListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelLootListingMutation,
    CancelLootListingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelLootListingMutation,
    CancelLootListingMutationVariables
  >(CancelLootListingDocument, options);
}
export type CancelLootListingMutationHookResult = ReturnType<
  typeof useCancelLootListingMutation
>;
export type CancelLootListingMutationResult =
  Apollo.MutationResult<CancelLootListingMutation>;
export type CancelLootListingMutationOptions = Apollo.BaseMutationOptions<
  CancelLootListingMutation,
  CancelLootListingMutationVariables
>;
export const BuyLootListingDocument = gql`
  mutation BuyLootListing($id: String!) {
    listing: buyItemListing(listingId: $id) {
      ...ItemListing
    }
  }
  ${ItemListingFragmentDoc}
`;
export type BuyLootListingMutationFn = Apollo.MutationFunction<
  BuyLootListingMutation,
  BuyLootListingMutationVariables
>;

/**
 * __useBuyLootListingMutation__
 *
 * To run a mutation, you first call `useBuyLootListingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyLootListingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyLootListingMutation, { data, loading, error }] = useBuyLootListingMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBuyLootListingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BuyLootListingMutation,
    BuyLootListingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    BuyLootListingMutation,
    BuyLootListingMutationVariables
  >(BuyLootListingDocument, options);
}
export type BuyLootListingMutationHookResult = ReturnType<
  typeof useBuyLootListingMutation
>;
export type BuyLootListingMutationResult =
  Apollo.MutationResult<BuyLootListingMutation>;
export type BuyLootListingMutationOptions = Apollo.BaseMutationOptions<
  BuyLootListingMutation,
  BuyLootListingMutationVariables
>;
export const SearchFurballsDocument = gql`
  query SearchFurballs(
    $inventory: [Long!]
    $skills: [Long!]
    $weapon: [Short!]!
    $geneBoostMin: Int
    $geneBoostMax: Int
    $furBoostMin: Int
    $furBoostMax: Int
    $expBoostMin: Int
    $expBoostMax: Int
    $minLevel: Int
    $maxLevel: Int
    $endCursor: String
    $sort: [FurballSortInput!]
  ) {
    searchFurballs(
      first: 12
      after: $endCursor
      filters: {
        rentable: true
        traits: { options: $weapon, slot: WEAPON }
        totalRarity: { gt: $geneBoostMin, lt: $geneBoostMax }
      }
      where: {
        id: { neq: "" }
        level: { gt: $minLevel, lt: $maxLevel }
        furBoost: { gt: $furBoostMin, lt: $furBoostMax }
        expBoost: { gt: $expBoostMin, lt: $expBoostMax }
        inventory: { items: { some: { itemId: { in: $inventory } } } }
        battleStats: {
          skills: { some: { skillDefinitionId: { in: $skills } } }
        }
      }
      order: $sort
    ) {
      ...SearchResults
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useSearchFurballsQuery__
 *
 * To run a query within a React component, call `useSearchFurballsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFurballsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFurballsQuery({
 *   variables: {
 *      inventory: // value for 'inventory'
 *      skills: // value for 'skills'
 *      weapon: // value for 'weapon'
 *      geneBoostMin: // value for 'geneBoostMin'
 *      geneBoostMax: // value for 'geneBoostMax'
 *      furBoostMin: // value for 'furBoostMin'
 *      furBoostMax: // value for 'furBoostMax'
 *      expBoostMin: // value for 'expBoostMin'
 *      expBoostMax: // value for 'expBoostMax'
 *      minLevel: // value for 'minLevel'
 *      maxLevel: // value for 'maxLevel'
 *      endCursor: // value for 'endCursor'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchFurballsQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchFurballsQuery,
    SearchFurballsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchFurballsQuery, SearchFurballsQueryVariables>(
    SearchFurballsDocument,
    options,
  );
}
export function useSearchFurballsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFurballsQuery,
    SearchFurballsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchFurballsQuery, SearchFurballsQueryVariables>(
    SearchFurballsDocument,
    options,
  );
}
export type SearchFurballsQueryHookResult = ReturnType<
  typeof useSearchFurballsQuery
>;
export type SearchFurballsLazyQueryHookResult = ReturnType<
  typeof useSearchFurballsLazyQuery
>;
export type SearchFurballsQueryResult = Apollo.QueryResult<
  SearchFurballsQuery,
  SearchFurballsQueryVariables
>;
export const MarketPlaceFurballDocument = gql`
  query MarketPlaceFurball($tokenId: String!) {
    furball(tokenId: $tokenId) {
      id
      tokenId
      state {
        ...FurballState
      }
      inventory {
        ...InventoryItems
      }
      battleStats {
        id
        skills {
          ...EntitySkill
        }
        ...EntityBattleStats
      }
      openRentalAgreement {
        ...FurRentalAgreement
      }
    }
  }
  ${FurballStateFragmentDoc}
  ${InventoryItemsFragmentDoc}
  ${EntitySkillFragmentDoc}
  ${EntityBattleStatsFragmentDoc}
  ${FurRentalAgreementFragmentDoc}
`;

/**
 * __useMarketPlaceFurballQuery__
 *
 * To run a query within a React component, call `useMarketPlaceFurballQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketPlaceFurballQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketPlaceFurballQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useMarketPlaceFurballQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketPlaceFurballQuery,
    MarketPlaceFurballQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MarketPlaceFurballQuery,
    MarketPlaceFurballQueryVariables
  >(MarketPlaceFurballDocument, options);
}
export function useMarketPlaceFurballLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketPlaceFurballQuery,
    MarketPlaceFurballQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MarketPlaceFurballQuery,
    MarketPlaceFurballQueryVariables
  >(MarketPlaceFurballDocument, options);
}
export type MarketPlaceFurballQueryHookResult = ReturnType<
  typeof useMarketPlaceFurballQuery
>;
export type MarketPlaceFurballLazyQueryHookResult = ReturnType<
  typeof useMarketPlaceFurballLazyQuery
>;
export type MarketPlaceFurballQueryResult = Apollo.QueryResult<
  MarketPlaceFurballQuery,
  MarketPlaceFurballQueryVariables
>;
export const SearchFurballByTokenIdDocument = gql`
  query SearchFurballByTokenId($tokenId: String) {
    searchFurballs(where: { id: { eq: $tokenId } }) {
      ...SearchResults
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useSearchFurballByTokenIdQuery__
 *
 * To run a query within a React component, call `useSearchFurballByTokenIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFurballByTokenIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFurballByTokenIdQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useSearchFurballByTokenIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchFurballByTokenIdQuery,
    SearchFurballByTokenIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchFurballByTokenIdQuery,
    SearchFurballByTokenIdQueryVariables
  >(SearchFurballByTokenIdDocument, options);
}
export function useSearchFurballByTokenIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFurballByTokenIdQuery,
    SearchFurballByTokenIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchFurballByTokenIdQuery,
    SearchFurballByTokenIdQueryVariables
  >(SearchFurballByTokenIdDocument, options);
}
export type SearchFurballByTokenIdQueryHookResult = ReturnType<
  typeof useSearchFurballByTokenIdQuery
>;
export type SearchFurballByTokenIdLazyQueryHookResult = ReturnType<
  typeof useSearchFurballByTokenIdLazyQuery
>;
export type SearchFurballByTokenIdQueryResult = Apollo.QueryResult<
  SearchFurballByTokenIdQuery,
  SearchFurballByTokenIdQueryVariables
>;
export const SearchFurballByNumberDocument = gql`
  query SearchFurballByNumber($number: Int) {
    searchFurballs(
      where: {
        id: { neq: "" }
        stats: { definition: { number: { eq: $number } } }
      }
    ) {
      ...SearchResults
    }
  }
  ${SearchResultsFragmentDoc}
`;

/**
 * __useSearchFurballByNumberQuery__
 *
 * To run a query within a React component, call `useSearchFurballByNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchFurballByNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchFurballByNumberQuery({
 *   variables: {
 *      number: // value for 'number'
 *   },
 * });
 */
export function useSearchFurballByNumberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchFurballByNumberQuery,
    SearchFurballByNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SearchFurballByNumberQuery,
    SearchFurballByNumberQueryVariables
  >(SearchFurballByNumberDocument, options);
}
export function useSearchFurballByNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchFurballByNumberQuery,
    SearchFurballByNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SearchFurballByNumberQuery,
    SearchFurballByNumberQueryVariables
  >(SearchFurballByNumberDocument, options);
}
export type SearchFurballByNumberQueryHookResult = ReturnType<
  typeof useSearchFurballByNumberQuery
>;
export type SearchFurballByNumberLazyQueryHookResult = ReturnType<
  typeof useSearchFurballByNumberLazyQuery
>;
export type SearchFurballByNumberQueryResult = Apollo.QueryResult<
  SearchFurballByNumberQuery,
  SearchFurballByNumberQueryVariables
>;
export const CreateRentalAgreementDocument = gql`
  mutation CreateRentalAgreement(
    $duration: Long!
    $signature: String!
    $autoRenew: Boolean!
    $furballId: String!
    $ownerId: String!
    $playerId: String
    $startsAt: Long!
    $percent: Int!
  ) {
    createRentalAgreement(
      terms: {
        duration: $duration
        signature: $signature
        autoRenew: $autoRenew
        furballId: $furballId
        ownerId: $ownerId
        playerId: $playerId
        startsAt: $startsAt
        percent: $percent
      }
    ) {
      id
      duration
      autoRenew
      owner {
        id
        username
      }
    }
  }
`;
export type CreateRentalAgreementMutationFn = Apollo.MutationFunction<
  CreateRentalAgreementMutation,
  CreateRentalAgreementMutationVariables
>;

/**
 * __useCreateRentalAgreementMutation__
 *
 * To run a mutation, you first call `useCreateRentalAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRentalAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRentalAgreementMutation, { data, loading, error }] = useCreateRentalAgreementMutation({
 *   variables: {
 *      duration: // value for 'duration'
 *      signature: // value for 'signature'
 *      autoRenew: // value for 'autoRenew'
 *      furballId: // value for 'furballId'
 *      ownerId: // value for 'ownerId'
 *      playerId: // value for 'playerId'
 *      startsAt: // value for 'startsAt'
 *      percent: // value for 'percent'
 *   },
 * });
 */
export function useCreateRentalAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRentalAgreementMutation,
    CreateRentalAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRentalAgreementMutation,
    CreateRentalAgreementMutationVariables
  >(CreateRentalAgreementDocument, options);
}
export type CreateRentalAgreementMutationHookResult = ReturnType<
  typeof useCreateRentalAgreementMutation
>;
export type CreateRentalAgreementMutationResult =
  Apollo.MutationResult<CreateRentalAgreementMutation>;
export type CreateRentalAgreementMutationOptions = Apollo.BaseMutationOptions<
  CreateRentalAgreementMutation,
  CreateRentalAgreementMutationVariables
>;
export const EditRentalAgreementDocument = gql`
  mutation EditRentalAgreement(
    $rentalAgreementId: String!
    $terms: RentalTermsInput!
  ) {
    editRentalAgreement(rentalAgreementId: $rentalAgreementId, terms: $terms) {
      id
      duration
      autoRenew
      owner {
        id
        username
      }
    }
  }
`;
export type EditRentalAgreementMutationFn = Apollo.MutationFunction<
  EditRentalAgreementMutation,
  EditRentalAgreementMutationVariables
>;

/**
 * __useEditRentalAgreementMutation__
 *
 * To run a mutation, you first call `useEditRentalAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRentalAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRentalAgreementMutation, { data, loading, error }] = useEditRentalAgreementMutation({
 *   variables: {
 *      rentalAgreementId: // value for 'rentalAgreementId'
 *      terms: // value for 'terms'
 *   },
 * });
 */
export function useEditRentalAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditRentalAgreementMutation,
    EditRentalAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EditRentalAgreementMutation,
    EditRentalAgreementMutationVariables
  >(EditRentalAgreementDocument, options);
}
export type EditRentalAgreementMutationHookResult = ReturnType<
  typeof useEditRentalAgreementMutation
>;
export type EditRentalAgreementMutationResult =
  Apollo.MutationResult<EditRentalAgreementMutation>;
export type EditRentalAgreementMutationOptions = Apollo.BaseMutationOptions<
  EditRentalAgreementMutation,
  EditRentalAgreementMutationVariables
>;
export const ClaimRentalAgreementDocument = gql`
  mutation ClaimRentalAgreement($rentalAgreementId: String!) {
    claimRentalAgreement(rentalAgreementId: $rentalAgreementId) {
      ...FurRentalAgreement
    }
  }
  ${FurRentalAgreementFragmentDoc}
`;
export type ClaimRentalAgreementMutationFn = Apollo.MutationFunction<
  ClaimRentalAgreementMutation,
  ClaimRentalAgreementMutationVariables
>;

/**
 * __useClaimRentalAgreementMutation__
 *
 * To run a mutation, you first call `useClaimRentalAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRentalAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRentalAgreementMutation, { data, loading, error }] = useClaimRentalAgreementMutation({
 *   variables: {
 *      rentalAgreementId: // value for 'rentalAgreementId'
 *   },
 * });
 */
export function useClaimRentalAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClaimRentalAgreementMutation,
    ClaimRentalAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClaimRentalAgreementMutation,
    ClaimRentalAgreementMutationVariables
  >(ClaimRentalAgreementDocument, options);
}
export type ClaimRentalAgreementMutationHookResult = ReturnType<
  typeof useClaimRentalAgreementMutation
>;
export type ClaimRentalAgreementMutationResult =
  Apollo.MutationResult<ClaimRentalAgreementMutation>;
export type ClaimRentalAgreementMutationOptions = Apollo.BaseMutationOptions<
  ClaimRentalAgreementMutation,
  ClaimRentalAgreementMutationVariables
>;
export const EndRentalAgreementDocument = gql`
  mutation EndRentalAgreement($rentalAgreementId: String!) {
    endRentalAgreement(rentalAgreementId: $rentalAgreementId) {
      ...FurRentalAgreement
    }
  }
  ${FurRentalAgreementFragmentDoc}
`;
export type EndRentalAgreementMutationFn = Apollo.MutationFunction<
  EndRentalAgreementMutation,
  EndRentalAgreementMutationVariables
>;

/**
 * __useEndRentalAgreementMutation__
 *
 * To run a mutation, you first call `useEndRentalAgreementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndRentalAgreementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endRentalAgreementMutation, { data, loading, error }] = useEndRentalAgreementMutation({
 *   variables: {
 *      rentalAgreementId: // value for 'rentalAgreementId'
 *   },
 * });
 */
export function useEndRentalAgreementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndRentalAgreementMutation,
    EndRentalAgreementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EndRentalAgreementMutation,
    EndRentalAgreementMutationVariables
  >(EndRentalAgreementDocument, options);
}
export type EndRentalAgreementMutationHookResult = ReturnType<
  typeof useEndRentalAgreementMutation
>;
export type EndRentalAgreementMutationResult =
  Apollo.MutationResult<EndRentalAgreementMutation>;
export type EndRentalAgreementMutationOptions = Apollo.BaseMutationOptions<
  EndRentalAgreementMutation,
  EndRentalAgreementMutationVariables
>;
export const CreateRandomPartyDocument = gql`
  mutation CreateRandomParty {
    createRandomParty {
      realm
      party {
        ...PlayerPartyFurballs
      }
    }
  }
  ${PlayerPartyFurballsFragmentDoc}
`;
export type CreateRandomPartyMutationFn = Apollo.MutationFunction<
  CreateRandomPartyMutation,
  CreateRandomPartyMutationVariables
>;

/**
 * __useCreateRandomPartyMutation__
 *
 * To run a mutation, you first call `useCreateRandomPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRandomPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRandomPartyMutation, { data, loading, error }] = useCreateRandomPartyMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateRandomPartyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRandomPartyMutation,
    CreateRandomPartyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateRandomPartyMutation,
    CreateRandomPartyMutationVariables
  >(CreateRandomPartyDocument, options);
}
export type CreateRandomPartyMutationHookResult = ReturnType<
  typeof useCreateRandomPartyMutation
>;
export type CreateRandomPartyMutationResult =
  Apollo.MutationResult<CreateRandomPartyMutation>;
export type CreateRandomPartyMutationOptions = Apollo.BaseMutationOptions<
  CreateRandomPartyMutation,
  CreateRandomPartyMutationVariables
>;
export const BattleStatsDocument = gql`
  query BattleStats($tokenId: String!) {
    furball(tokenId: $tokenId) {
      id
      battleStats {
        skills {
          ...EntitySkill
        }
        ...EntityBattleStats
      }
    }
  }
  ${EntitySkillFragmentDoc}
  ${EntityBattleStatsFragmentDoc}
`;

/**
 * __useBattleStatsQuery__
 *
 * To run a query within a React component, call `useBattleStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBattleStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBattleStatsQuery({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useBattleStatsQuery(
  baseOptions: Apollo.QueryHookOptions<
    BattleStatsQuery,
    BattleStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<BattleStatsQuery, BattleStatsQueryVariables>(
    BattleStatsDocument,
    options,
  );
}
export function useBattleStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BattleStatsQuery,
    BattleStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<BattleStatsQuery, BattleStatsQueryVariables>(
    BattleStatsDocument,
    options,
  );
}
export type BattleStatsQueryHookResult = ReturnType<typeof useBattleStatsQuery>;
export type BattleStatsLazyQueryHookResult = ReturnType<
  typeof useBattleStatsLazyQuery
>;
export type BattleStatsQueryResult = Apollo.QueryResult<
  BattleStatsQuery,
  BattleStatsQueryVariables
>;
export const UpgradeSkillDocument = gql`
  mutation UpgradeSkill(
    $furballId: String!
    $skillId: String!
    $levelPartials: Int!
    $maxUsesPartials: Int!
  ) {
    upgradeSkill(
      furballId: $furballId
      skillId: $skillId
      levelPartials: $levelPartials
      maxUsesPartials: $maxUsesPartials
    ) {
      battleStats {
        skills {
          ...EntitySkill
        }
      }
    }
  }
  ${EntitySkillFragmentDoc}
`;
export type UpgradeSkillMutationFn = Apollo.MutationFunction<
  UpgradeSkillMutation,
  UpgradeSkillMutationVariables
>;

/**
 * __useUpgradeSkillMutation__
 *
 * To run a mutation, you first call `useUpgradeSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeSkillMutation, { data, loading, error }] = useUpgradeSkillMutation({
 *   variables: {
 *      furballId: // value for 'furballId'
 *      skillId: // value for 'skillId'
 *      levelPartials: // value for 'levelPartials'
 *      maxUsesPartials: // value for 'maxUsesPartials'
 *   },
 * });
 */
export function useUpgradeSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeSkillMutation,
    UpgradeSkillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpgradeSkillMutation,
    UpgradeSkillMutationVariables
  >(UpgradeSkillDocument, options);
}
export type UpgradeSkillMutationHookResult = ReturnType<
  typeof useUpgradeSkillMutation
>;
export type UpgradeSkillMutationResult =
  Apollo.MutationResult<UpgradeSkillMutation>;
export type UpgradeSkillMutationOptions = Apollo.BaseMutationOptions<
  UpgradeSkillMutation,
  UpgradeSkillMutationVariables
>;
export const RollSkillDocument = gql`
  mutation RollSkill($furballId: String!, $slotIndex: Int!) {
    rollSkill(furballId: $furballId, slotIndex: $slotIndex) {
      id
      battleStats {
        skills {
          ...EntitySkill
        }
      }
    }
  }
  ${EntitySkillFragmentDoc}
`;
export type RollSkillMutationFn = Apollo.MutationFunction<
  RollSkillMutation,
  RollSkillMutationVariables
>;

/**
 * __useRollSkillMutation__
 *
 * To run a mutation, you first call `useRollSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRollSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rollSkillMutation, { data, loading, error }] = useRollSkillMutation({
 *   variables: {
 *      furballId: // value for 'furballId'
 *      slotIndex: // value for 'slotIndex'
 *   },
 * });
 */
export function useRollSkillMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RollSkillMutation,
    RollSkillMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RollSkillMutation, RollSkillMutationVariables>(
    RollSkillDocument,
    options,
  );
}
export type RollSkillMutationHookResult = ReturnType<
  typeof useRollSkillMutation
>;
export type RollSkillMutationResult = Apollo.MutationResult<RollSkillMutation>;
export type RollSkillMutationOptions = Apollo.BaseMutationOptions<
  RollSkillMutation,
  RollSkillMutationVariables
>;
export const UpgradeLootDocument = gql`
  mutation UpgradeLoot($chances: Int!, $itemId: String!) {
    upgradeLoot(chances: $chances, itemId: $itemId) {
      furball {
        id
      }
      upgradedItem {
        ...Items
      }
    }
  }
  ${ItemsFragmentDoc}
`;
export type UpgradeLootMutationFn = Apollo.MutationFunction<
  UpgradeLootMutation,
  UpgradeLootMutationVariables
>;

/**
 * __useUpgradeLootMutation__
 *
 * To run a mutation, you first call `useUpgradeLootMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeLootMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeLootMutation, { data, loading, error }] = useUpgradeLootMutation({
 *   variables: {
 *      chances: // value for 'chances'
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useUpgradeLootMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpgradeLootMutation,
    UpgradeLootMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpgradeLootMutation, UpgradeLootMutationVariables>(
    UpgradeLootDocument,
    options,
  );
}
export type UpgradeLootMutationHookResult = ReturnType<
  typeof useUpgradeLootMutation
>;
export type UpgradeLootMutationResult =
  Apollo.MutationResult<UpgradeLootMutation>;
export type UpgradeLootMutationOptions = Apollo.BaseMutationOptions<
  UpgradeLootMutation,
  UpgradeLootMutationVariables
>;
export const PipeLogsDocument = gql`
  mutation PipeLogs($logBundle: LogBundleInput!) {
    pipeLogs(logBundle: $logBundle)
  }
`;
export type PipeLogsMutationFn = Apollo.MutationFunction<
  PipeLogsMutation,
  PipeLogsMutationVariables
>;

/**
 * __usePipeLogsMutation__
 *
 * To run a mutation, you first call `usePipeLogsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePipeLogsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pipeLogsMutation, { data, loading, error }] = usePipeLogsMutation({
 *   variables: {
 *      logBundle: // value for 'logBundle'
 *   },
 * });
 */
export function usePipeLogsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PipeLogsMutation,
    PipeLogsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PipeLogsMutation, PipeLogsMutationVariables>(
    PipeLogsDocument,
    options,
  );
}
export type PipeLogsMutationHookResult = ReturnType<typeof usePipeLogsMutation>;
export type PipeLogsMutationResult = Apollo.MutationResult<PipeLogsMutation>;
export type PipeLogsMutationOptions = Apollo.BaseMutationOptions<
  PipeLogsMutation,
  PipeLogsMutationVariables
>;
