export const assets = [
  {
    id: 'Tail-0',
    slot: 'TAIL',
    assetIndex: 0,
    name: 'Dinosaur',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Dinosaur.svg',
  },
  {
    id: 'Tail-1',
    slot: 'TAIL',
    assetIndex: 1,
    name: 'Squirrel',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Squirrel.svg',
  },
  {
    id: 'Tail-2',
    slot: 'TAIL',
    assetIndex: 2,
    name: 'Lion',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Lion.svg',
  },
  {
    id: 'Tail-3',
    slot: 'TAIL',
    assetIndex: 3,
    name: 'Rabbit',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Rabbit.svg',
  },
  {
    id: 'Tail-4',
    slot: 'TAIL',
    assetIndex: 4,
    name: 'Dog',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Dog.svg',
  },
  {
    id: 'Tail-5',
    slot: 'TAIL',
    assetIndex: 5,
    name: 'Rat',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Rat.svg',
  },
  {
    id: 'Tail-6',
    slot: 'TAIL',
    assetIndex: 6,
    name: 'Fox',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Fox.svg',
  },
  {
    id: 'Tail-7',
    slot: 'TAIL',
    assetIndex: 7,
    name: 'Unicorn',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Unicorn.svg',
  },
  {
    id: 'Tail-8',
    slot: 'TAIL',
    assetIndex: 8,
    name: 'Poodle',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Poodle.svg',
  },
  {
    id: 'Tail-9',
    slot: 'TAIL',
    assetIndex: 9,
    name: 'Sprinkle Pony',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Sprinkle Pony.svg',
  },
  {
    id: 'Tail-10',
    slot: 'TAIL',
    assetIndex: 10,
    name: 'Racoon',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tail/Racoon.svg',
  },
  {
    id: 'Weapon-0',
    slot: 'WEAPON',
    assetIndex: 0,
    name: 'Asparagus Spear',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Asparagus Spear.svg',
  },
  {
    id: 'Weapon-1',
    slot: 'WEAPON',
    assetIndex: 1,
    name: 'Butter Knife',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Butter Knife.svg',
  },
  {
    id: 'Weapon-2',
    slot: 'WEAPON',
    assetIndex: 2,
    name: 'Poop Squasher',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Poop Squasher.svg',
  },
  {
    id: 'Weapon-3',
    slot: 'WEAPON',
    assetIndex: 3,
    name: 'Sad Pencil',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Sad Pencil.svg',
  },
  {
    id: 'Weapon-4',
    slot: 'WEAPON',
    assetIndex: 4,
    name: 'Pyro Stick',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Pyro Stick.svg',
  },
  {
    id: 'Weapon-5',
    slot: 'WEAPON',
    assetIndex: 5,
    name: 'Straw of Death',
    rarityLevel: 'ELITE',
    realmAffiliation: 'TURTLE_SEA',
    filePath: 'images/edition0/Weapon/Straw of Death.svg',
  },
  {
    id: 'Weapon-6',
    slot: 'WEAPON',
    assetIndex: 6,
    name: 'Spork Dork',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Spork Dork.svg',
  },
  {
    id: 'Weapon-7',
    slot: 'WEAPON',
    assetIndex: 7,
    name: 'Whimsy Wand',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'WIZARDS_PATH',
    filePath: 'images/edition0/Weapon/Whimsy Wand.svg',
  },
  {
    id: 'Weapon-8',
    slot: 'WEAPON',
    assetIndex: 8,
    name: 'Fur Reaper',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'BONEYARD',
    filePath: 'images/edition0/Weapon/Fur Reaper.svg',
  },
  {
    id: 'Weapon-9',
    slot: 'WEAPON',
    assetIndex: 9,
    name: 'Stabby Thing',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Stabby Thing.svg',
  },
  {
    id: 'Weapon-10',
    slot: 'WEAPON',
    assetIndex: 10,
    name: 'ExeQtioner',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/ExeQtioner.svg',
  },
  {
    id: 'Weapon-11',
    slot: 'WEAPON',
    assetIndex: 11,
    name: 'Bubble Wand',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Weapon/Bubble Wand.svg',
  },
  {
    id: 'Body-0',
    slot: 'BODY',
    assetIndex: 0,
    name: 'Corgi',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Corgi.svg',
  },
  {
    id: 'Body-1',
    slot: 'BODY',
    assetIndex: 1,
    name: 'Cat',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Cat.svg',
  },
  {
    id: 'Body-2',
    slot: 'BODY',
    assetIndex: 2,
    name: 'Cow',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Cow.svg',
  },
  {
    id: 'Body-3',
    slot: 'BODY',
    assetIndex: 3,
    name: 'Gerbil',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Gerbil.svg',
  },
  {
    id: 'Body-4',
    slot: 'BODY',
    assetIndex: 4,
    name: 'Tiger',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Tiger.svg',
  },
  {
    id: 'Body-5',
    slot: 'BODY',
    assetIndex: 5,
    name: 'Furdenza',
    rarityLevel: 'ULTIMATE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Furdenza.svg',
  },
  {
    id: 'Body-6',
    slot: 'BODY',
    assetIndex: 6,
    name: 'Hippo',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Hippo.svg',
  },
  {
    id: 'Body-7',
    slot: 'BODY',
    assetIndex: 7,
    name: 'Mammoth',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Mammoth.svg',
  },
  {
    id: 'Body-8',
    slot: 'BODY',
    assetIndex: 8,
    name: 'Seal',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Seal.svg',
  },
  {
    id: 'Body-9',
    slot: 'BODY',
    assetIndex: 9,
    name: 'Pinata',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Pinata.svg',
  },
  {
    id: 'Body-10',
    slot: 'BODY',
    assetIndex: 10,
    name: 'Rainbow Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Rainbow Power.svg',
  },
  {
    id: 'Body-11',
    slot: 'BODY',
    assetIndex: 11,
    name: 'Psychedelic Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Psychedelic Power.svg',
  },
  {
    id: 'Body-12',
    slot: 'BODY',
    assetIndex: 12,
    name: 'Dalmatian Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Dalmatian Power.svg',
  },
  {
    id: 'Body-13',
    slot: 'BODY',
    assetIndex: 13,
    name: 'Disco Ball Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Disco Ball Power.svg',
  },
  {
    id: 'Body-14',
    slot: 'BODY',
    assetIndex: 14,
    name: 'Merman Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Body/Merman Power.svg',
  },
  {
    id: 'Tattoo-0',
    slot: 'TATTOO',
    assetIndex: 0,
    name: 'Scar',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Scar.svg',
  },
  {
    id: 'Tattoo-1',
    slot: 'TATTOO',
    assetIndex: 1,
    name: 'Tears',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Tears.svg',
  },
  {
    id: 'Tattoo-2',
    slot: 'TATTOO',
    assetIndex: 2,
    name: 'Blush',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Blush.svg',
  },
  {
    id: 'Tattoo-3',
    slot: 'TATTOO',
    assetIndex: 3,
    name: 'Freckles',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Freckles.svg',
  },
  {
    id: 'Tattoo-4',
    slot: 'TATTOO',
    assetIndex: 4,
    name: 'Bandaid',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Bandaid.svg',
  },
  {
    id: 'Tattoo-5',
    slot: 'TATTOO',
    assetIndex: 5,
    name: 'Paw',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Tattoo/Paw.svg',
  },
  {
    id: 'Ears-0',
    slot: 'EARS',
    assetIndex: 0,
    name: 'Corgi',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Corgi.svg',
  },
  {
    id: 'Ears-1',
    slot: 'EARS',
    assetIndex: 1,
    name: 'Cat',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Cat.svg',
  },
  {
    id: 'Ears-2',
    slot: 'EARS',
    assetIndex: 2,
    name: 'Gremlin',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Gremlin.svg',
  },
  {
    id: 'Ears-3',
    slot: 'EARS',
    assetIndex: 3,
    name: 'Koala',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Koala.svg',
  },
  {
    id: 'Ears-4',
    slot: 'EARS',
    assetIndex: 4,
    name: 'Bunny',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Bunny.svg',
  },
  {
    id: 'Ears-5',
    slot: 'EARS',
    assetIndex: 5,
    name: 'Stag',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Stag.svg',
  },
  {
    id: 'Ears-6',
    slot: 'EARS',
    assetIndex: 6,
    name: 'Bear',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Bear.svg',
  },
  {
    id: 'Ears-7',
    slot: 'EARS',
    assetIndex: 7,
    name: 'Gorilla',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Gorilla.svg',
  },
  {
    id: 'Ears-8',
    slot: 'EARS',
    assetIndex: 8,
    name: 'Dog',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Dog.svg',
  },
  {
    id: 'Ears-9',
    slot: 'EARS',
    assetIndex: 9,
    name: 'Llama',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Ears/Llama.svg',
  },
  {
    id: 'Eyes-0',
    slot: 'EYES',
    assetIndex: 0,
    name: 'Googley',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Googley.svg',
  },
  {
    id: 'Eyes-1',
    slot: 'EYES',
    assetIndex: 1,
    name: 'Angry',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Angry.svg',
  },
  {
    id: 'Eyes-2',
    slot: 'EYES',
    assetIndex: 2,
    name: 'Lazy',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Lazy.svg',
  },
  {
    id: 'Eyes-3',
    slot: 'EYES',
    assetIndex: 3,
    name: 'Creepy',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Creepy.svg',
  },
  {
    id: 'Eyes-4',
    slot: 'EYES',
    assetIndex: 4,
    name: 'Monocle',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Monocle.svg',
  },
  {
    id: 'Eyes-5',
    slot: 'EYES',
    assetIndex: 5,
    name: 'Vapor',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Vapor.svg',
  },
  {
    id: 'Eyes-6',
    slot: 'EYES',
    assetIndex: 6,
    name: 'Skeptical Blue',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Skeptical Blue.svg',
  },
  {
    id: 'Eyes-7',
    slot: 'EYES',
    assetIndex: 7,
    name: 'Skeptical Red',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Skeptical Red.svg',
  },
  {
    id: 'Eyes-8',
    slot: 'EYES',
    assetIndex: 8,
    name: 'Skeptical Rainbow',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Skeptical Rainbow.svg',
  },
  {
    id: 'Eyes-9',
    slot: 'EYES',
    assetIndex: 9,
    name: 'Sparkle Blue',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Sparkle Blue.svg',
  },
  {
    id: 'Eyes-10',
    slot: 'EYES',
    assetIndex: 10,
    name: 'Sparkle Pink',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Sparkle Pink.svg',
  },
  {
    id: 'Eyes-11',
    slot: 'EYES',
    assetIndex: 11,
    name: 'Spider',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Spider.svg',
  },
  {
    id: 'Eyes-12',
    slot: 'EYES',
    assetIndex: 12,
    name: 'Stars',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Stars.svg',
  },
  {
    id: 'Eyes-13',
    slot: 'EYES',
    assetIndex: 13,
    name: 'Oh No',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Oh No.svg',
  },
  {
    id: 'Eyes-14',
    slot: 'EYES',
    assetIndex: 14,
    name: 'Evil',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Evil.svg',
  },
  {
    id: 'Eyes-15',
    slot: 'EYES',
    assetIndex: 15,
    name: 'Goofy',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Goofy.svg',
  },
  {
    id: 'Eyes-16',
    slot: 'EYES',
    assetIndex: 16,
    name: 'Chill',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Chill.svg',
  },
  {
    id: 'Eyes-17',
    slot: 'EYES',
    assetIndex: 17,
    name: 'Worry Green Purple',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Worry Green Purple.svg',
  },
  {
    id: 'Eyes-18',
    slot: 'EYES',
    assetIndex: 18,
    name: 'Worry Silver',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Eyes/Worry Silver.svg',
  },
  {
    id: 'Mouth-0',
    slot: 'MOUTH',
    assetIndex: 0,
    name: 'Corgi',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Corgi.svg',
  },
  {
    id: 'Mouth-1',
    slot: 'MOUTH',
    assetIndex: 1,
    name: 'Drool',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Drool.svg',
  },
  {
    id: 'Mouth-2',
    slot: 'MOUTH',
    assetIndex: 2,
    name: 'Grumpy',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Grumpy.svg',
  },
  {
    id: 'Mouth-3',
    slot: 'MOUTH',
    assetIndex: 3,
    name: 'Snaggle Tooth',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Snaggle Tooth.svg',
  },
  {
    id: 'Mouth-4',
    slot: 'MOUTH',
    assetIndex: 4,
    name: 'Happy Heart',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Happy Heart.svg',
  },
  {
    id: 'Mouth-5',
    slot: 'MOUTH',
    assetIndex: 5,
    name: 'Bubble Gum',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Bubble Gum.svg',
  },
  {
    id: 'Mouth-6',
    slot: 'MOUTH',
    assetIndex: 6,
    name: 'Pug',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Pug.svg',
  },
  {
    id: 'Mouth-7',
    slot: 'MOUTH',
    assetIndex: 7,
    name: 'Sloth',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Sloth.svg',
  },
  {
    id: 'Mouth-8',
    slot: 'MOUTH',
    assetIndex: 8,
    name: 'Fangs',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Fangs.svg',
  },
  {
    id: 'Mouth-9',
    slot: 'MOUTH',
    assetIndex: 9,
    name: 'Surprised',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Surprised.svg',
  },
  {
    id: 'Mouth-10',
    slot: 'MOUTH',
    assetIndex: 10,
    name: 'Pipe',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Pipe.svg',
  },
  {
    id: 'Mouth-11',
    slot: 'MOUTH',
    assetIndex: 11,
    name: 'Teeth',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Teeth.svg',
  },
  {
    id: 'Mouth-12',
    slot: 'MOUTH',
    assetIndex: 12,
    name: 'Cigar',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Cigar.svg',
  },
  {
    id: 'Mouth-13',
    slot: 'MOUTH',
    assetIndex: 13,
    name: 'Walrus',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Walrus.svg',
  },
  {
    id: 'Mouth-14',
    slot: 'MOUTH',
    assetIndex: 14,
    name: 'Side Smile',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Mouth/Side Smile.svg',
  },
  {
    id: 'Hat-0',
    slot: 'HAT',
    assetIndex: 0,
    name: 'Tophat',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Tophat.svg',
  },
  {
    id: 'Hat-1',
    slot: 'HAT',
    assetIndex: 1,
    name: 'Tuft',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Tuft.svg',
  },
  {
    id: 'Hat-2',
    slot: 'HAT',
    assetIndex: 2,
    name: 'Horns',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Horns.svg',
  },
  {
    id: 'Hat-3',
    slot: 'HAT',
    assetIndex: 3,
    name: 'Cowlick',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Cowlick.svg',
  },
  {
    id: 'Hat-4',
    slot: 'HAT',
    assetIndex: 4,
    name: 'Toupee',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Toupee.svg',
  },
  {
    id: 'Hat-5',
    slot: 'HAT',
    assetIndex: 5,
    name: 'Updo',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Updo.svg',
  },
  {
    id: 'Hat-6',
    slot: 'HAT',
    assetIndex: 6,
    name: 'Unicorn Horn',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Unicorn Horn.svg',
  },
  {
    id: 'Hat-7',
    slot: 'HAT',
    assetIndex: 7,
    name: 'Bowler Hat',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Bowler Hat.svg',
  },
  {
    id: 'Hat-8',
    slot: 'HAT',
    assetIndex: 8,
    name: 'Cap',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Cap.svg',
  },
  {
    id: 'Hat-9',
    slot: 'HAT',
    assetIndex: 9,
    name: 'Crown',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Hat/Crown.svg',
  },
  {
    id: 'Outfit-0',
    slot: 'OUTFIT',
    assetIndex: 0,
    name: 'Dapper Shirt',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Dapper Shirt.svg',
  },
  {
    id: 'Outfit-1',
    slot: 'OUTFIT',
    assetIndex: 1,
    name: 'Triple Chin',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Triple Chin.svg',
  },
  {
    id: 'Outfit-2',
    slot: 'OUTFIT',
    assetIndex: 2,
    name: 'Chin',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Chin.svg',
  },
  {
    id: 'Outfit-3',
    slot: 'OUTFIT',
    assetIndex: 3,
    name: 'Spiked Collar',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Spiked Collar.svg',
  },
  {
    id: 'Outfit-4',
    slot: 'OUTFIT',
    assetIndex: 4,
    name: 'Bowtie',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Bowtie.svg',
  },
  {
    id: 'Outfit-5',
    slot: 'OUTFIT',
    assetIndex: 5,
    name: 'Cone of Shame',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Cone of Shame.svg',
  },
  {
    id: 'Outfit-6',
    slot: 'OUTFIT',
    assetIndex: 6,
    name: 'Pirate',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Pirate.svg',
  },
  {
    id: 'Outfit-7',
    slot: 'OUTFIT',
    assetIndex: 7,
    name: 'Fur',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Fur.svg',
  },
  {
    id: 'Outfit-8',
    slot: 'OUTFIT',
    assetIndex: 8,
    name: 'Dog Tag',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Dog Tag.svg',
  },
  {
    id: 'Outfit-9',
    slot: 'OUTFIT',
    assetIndex: 9,
    name: 'ET Hoodie',
    rarityLevel: 'ELITE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/ET Hoodie.svg',
  },
  {
    id: 'Outfit-10',
    slot: 'OUTFIT',
    assetIndex: 10,
    name: 'Grey Hoodie',
    rarityLevel: 'LEGENDARY',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Grey Hoodie.svg',
  },
  {
    id: 'Outfit-11',
    slot: 'OUTFIT',
    assetIndex: 11,
    name: 'Furdenza Hoodie',
    rarityLevel: 'ULTIMATE',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Furdenza Hoodie.svg',
  },
  {
    id: 'Outfit-12',
    slot: 'OUTFIT',
    assetIndex: 12,
    name: 'Royal Cape',
    rarityLevel: 'MYTHIC',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Royal Cape.svg',
  },
  {
    id: 'Outfit-13',
    slot: 'OUTFIT',
    assetIndex: 13,
    name: 'Harness Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Harness Power.svg',
  },
  {
    id: 'Outfit-14',
    slot: 'OUTFIT',
    assetIndex: 14,
    name: 'DejaVu Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/DejaVu Power.svg',
  },
  {
    id: 'Outfit-15',
    slot: 'OUTFIT',
    assetIndex: 15,
    name: 'Frosting Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Frosting Power.svg',
  },
  {
    id: 'Outfit-16',
    slot: 'OUTFIT',
    assetIndex: 16,
    name: 'Killer Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Killer Power.svg',
  },
  {
    id: 'Outfit-17',
    slot: 'OUTFIT',
    assetIndex: 17,
    name: 'Beard Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Beard Power.svg',
  },
  {
    id: 'Outfit-18',
    slot: 'OUTFIT',
    assetIndex: 18,
    name: 'Fur Coat Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Fur Coat Power.svg',
  },
  {
    id: 'Outfit-19',
    slot: 'OUTFIT',
    assetIndex: 19,
    name: 'Robot Power',
    rarityLevel: 'COMMON',
    realmAffiliation: 'NONE',
    filePath: 'images/edition0/Outfit/Robot Power.svg',
  },
];
