import React from 'react';
import { IRenderPart } from '../part';
import Tophat from './00_Tophat';
import Tuft from './01_Tuft';
import Horns from './02_Horns';
import Cowlick from './03_Cowlick';
import Toupee from './04_Toupee';
import Updo from './05_Updo';
import UnicornHorn from './06_UnicornHorn';
import BowlerHat from './07_BowlerHat';
import Cap from './08_Cap';
import Crown from './09_Crown';

export function renderEdition0Hat(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Tophat renderer={renderer} />;
  if (renderer.partNumber === 2) return <Tuft renderer={renderer} />;
  if (renderer.partNumber === 3) return <Horns renderer={renderer} />;
  if (renderer.partNumber === 4) return <Cowlick renderer={renderer} />;
  if (renderer.partNumber === 5) return <Toupee renderer={renderer} />;
  if (renderer.partNumber === 6) return <Updo renderer={renderer} />;
  if (renderer.partNumber === 7) return <UnicornHorn renderer={renderer} />;
  if (renderer.partNumber === 8) return <BowlerHat renderer={renderer} />;
  if (renderer.partNumber === 9) return <Cap renderer={renderer} />;
  if (renderer.partNumber === 10) return <Crown renderer={renderer} />;
  console.warn('renderEdition0Hat', renderer);
}
