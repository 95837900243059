import { mobileVendor } from 'react-device-detect';

const androidPublicBetaUrl =
  'https://play.google.com/store/apps/details?id=com.LFG_Gaming_LLC.Furballs';
const iOsPublicBetaUrl = 'https://testflight.apple.com/join/DH08dSx1';

export function useAppDownloadUrl() {
  if (mobileVendor.toLowerCase().includes('android'))
    return androidPublicBetaUrl;
  return iOsPublicBetaUrl;
}
