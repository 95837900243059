import React, { FunctionComponent, useState } from 'react';
import { IToken } from '../../wallet/WalletTypes';
import { useLoadedFurball } from '../../wallet';
import { CircularProgress, Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import SvgCardBack from '../../assets/SvgCardBack';
import GenePool from './GenePool';
import Inventory from './Inventory';
import clsx from 'clsx';
import { useWalletSelector } from '../../hooks';
import Rankings from './Rankings';
import FurballRenderer from './FurballRenderer';
import FurballBackground from './Backgrounds/FurballBackground';
import SvgVoyageWidgetBkBoneyard from '../../assets/voyages/VoyageWidgetBkBoneyard';
import Skills from './Skills';
import FurballButtons, { ExpandMode } from './FurballButtons';
import { inIframe } from '../../wallet/Wallet';
import { StatEntity } from '../schema';
import FurballCardStats from './FurballCardStats';
import useCommonStyles from '../useCommonStyles';

export const width = 300;
export const height = 290;
export const expansionHeight = 550;
export const stroke = 3;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    position: 'relative',
    textAlign: 'center',
    // minHeight: `${height}px`,
    width: `${width}px`,
  },
  cardExpanded: {
    height: `${height + expansionHeight}px !important`,
  },
  progress: {
    // width: '100%',
    // position: 'absolute',
    // bottom: '0px',
    // height: '10px',
    position: 'relative',
    left: '10px',
    top: '10px',
  },
  cardBack: {
    position: 'absolute',
    width: `${width}px`,
    top: 0,
    left: 0,
  },
  buttonSelected: {
    backgroundColor: theme.palette.secondary.dark,
  },
  cardTop: {
    // position: 'relative',
    // top: `-${height + stroke*2}px`,
    width: `${width}px`,
    // height: `${height}px`,
  },
  imgWrap: {
    marginRight: 4,
    marginLeft: 4,
    paddingTop: 4,
    maxWidth: `${width}px`,
    maxHeight: `${height}px`,
    height: height - 4,
    pointerEvents: 'none',
    overflow: 'hidden',
  },
  furball: {
    bottom: 200,
  },
  furballId: {
    position: 'absolute',
    top: '0.2rem',
    left: '0.2rem',
    color: '#000',

    '& button': {
      borderTop: 'none !important',
      borderLeft: 'none !important',
      borderRadius: '40px 0 30px 0',
    },
  },
  furballZone: {
    position: 'absolute',
    right: '-34px',
    bottom: '-220px',
    textAlign: 'left',
  },
  stats: {
    // backgroundColor: '#000000',
    color: '#ffffff',
    position: 'absolute',
    border: `${stroke}px solid #000000`,
    left: 0,
    right: 0,
    // bottom: `${0}px`,
    top: `${stroke + 220}px`,
    // left: `${stroke}px`,
    // right: `${stroke}px`,
  },
  expansion: {
    background: '#FFFFFF',
    // paddingTop: theme.spacing(1),
    border: `${stroke}px solid #000000`,
    // maxHeight: '300px',
    // overflowY: 'scroll',
    // height: `${expansionHeight}px`,
  },
}));

type Props = IToken & {
  disableExpansion?: boolean;
  expanded?: ExpandMode;
  onClickDetails?: () => void;
};

export const FurballCardContent: FunctionComponent<Props> = (props) => {
  const { tokenId, disableExpansion, expanded, onClickDetails } = props;
  const furball = useLoadedFurball(tokenId);
  const common = useCommonStyles();
  const classes = useStyles();
  const iframe = inIframe();
  const disableAnimation = iframe
    ? false
    : localStorage.getItem('disableAnimation') === '1';

  const [mode, setMode] = useState<ExpandMode | undefined>(undefined);

  function toggleMode(newMode: ExpandMode) {
    setMode(newMode === mode ? undefined : newMode);
  }

  if (!furball) return null;

  return (
    <div className={clsx(classes.cardTop)}>
      {!disableExpansion && (
        <FurballButtons furball={furball} mode={mode} toggleMode={toggleMode} />
      )}
      <div className={classes.imgWrap}>
        <FurballBackground
          furball={furball}
          disableAnimation={disableAnimation}
        />
        <FurballRenderer
          tokenId={furball.tokenId}
          className={classes.furball}
          size={250}
        />
      </div>

      <div className={classes.furballId}>
        <Fab
          color={onClickDetails ? 'secondary' : undefined}
          disabled={!onClickDetails}
          className={common.actionSecondary}
          style={{
            border: `${stroke}px solid #000000`,
            color: onClickDetails ? 'white' : 'black',
          }}
          onClick={onClickDetails}>
          <Typography variant={'h6'}>
            #{furball.number.toLocaleString()}
          </Typography>
        </Fab>
      </div>
      <FurballCardStats furball={furball} className={classes.stats} />
      {mode && !disableExpansion && (
        <div className={classes.expansion}>
          {mode === 'genes' && <GenePool furball={furball} />}
          {mode === 'loot' && <Inventory furball={furball} />}
          {mode === 'ranks' && <Rankings furball={furball} />}
          {mode === 'skills' && (
            <Skills
              tokenId={tokenId}
              battleStats={furball.battleStats as StatEntity}
              equipment={furball.equipment}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const FurballCard: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const furball = useWalletSelector((s) => s.furballs[props.tokenId]);
  const bk = furball?.backgroundColor.substring(1) ?? undefined;

  return (
    <div className={classes.card}>
      <SvgCardBack className={classes.cardBack} backgroundColor={bk}>
        <SvgVoyageWidgetBkBoneyard />
      </SvgCardBack>
      <React.Suspense
        fallback={<CircularProgress className={classes.progress} />}>
        <FurballCardContent {...props} />
      </React.Suspense>
    </div>
  );
};

export default FurballCard;
