import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';
import AdminGiftFur from './AdminGiftFur';
import AdminGiftTix from './AdminGiftTix';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IUnknown {}

type Props = IUnknown;

interface IGiftAmount {
  tokenId: string;
  amount: string;
}

export interface IGiftData {
  tokenIds: string[];
  amounts: number[];
}

export const AdminGift: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const [furGifts, setFurGifts] = React.useState<IGiftAmount[]>([]);
  const [furGift, setFurGift] = React.useState<IGiftData | undefined>(
    undefined,
  );

  const [tixGifts, setTixGifts] = React.useState<IGiftAmount[]>([]);
  const [tixGift, setTixGift] = React.useState<IGiftData | undefined>(
    undefined,
  );

  function renderGifts(
    title: string,
    gifts: IGiftAmount[],
    setter: (v: IGiftAmount[]) => void,
    send: (v: IGiftData) => void,
  ): React.ReactNode {
    function doSet(vals: IGiftAmount[]) {
      vals = vals.filter((v) => v.tokenId.length > 0 || v.amount.length > 0);
      setter(vals);
    }

    function doSend() {
      const gift: IGiftData = { tokenIds: [], amounts: [] };
      for (let i = 0; i < gifts.length; i++) {
        const amount = Number.parseInt(gifts[i].amount);
        if (gifts[i].tokenId.length === 0 || !amount) continue;
        gift.tokenIds.push(gifts[i].tokenId);
        gift.amounts.push(amount);
      }
      send(gift);
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>{title}</Typography>
        </Grid>
        {gifts.map((gift, i) => {
          return (
            <React.Fragment key={i}>
              <Grid item xs={1}>
                Player #{i}
              </Grid>
              <Grid item xs={5}>
                <TextField
                  style={{ width: '100%' }}
                  value={gift.tokenId}
                  onChange={(e) => {
                    gifts[i].tokenId = e.target.value;
                    doSet(gifts);
                  }}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  style={{ width: '100%' }}
                  value={gift.amount}
                  onChange={(e) => {
                    gifts[i].amount = e.target.value;
                    doSet(gifts);
                  }}
                />
              </Grid>
              <Grid item xs={1} />
            </React.Fragment>
          );
        })}
        <Grid item xs={1} />
        <Grid item xs={5}>
          <Button
            variant={'contained'}
            color={'secondary'}
            style={{ width: '100%' }}
            onClick={() => {
              gifts.push({ tokenId: '', amount: '0' });
              doSet(gifts);
            }}>
            ADD ANOTHER
          </Button>
        </Grid>
        <Grid item xs={5}>
          {gifts.length > 0 && (
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ width: '100%' }}
              onClick={doSend}>
              SEND GIFT
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={clsx(classes.root)}>
      <Typography variant={'h5'}>Send Gift</Typography>
      <Paper className={common.paddingSm}>
        {renderGifts('Send $FUR', furGifts, setFurGifts, setFurGift)}
      </Paper>
      <br />
      <Paper className={common.paddingSm}>
        {renderGifts('Send TIX', tixGifts, setTixGifts, setTixGift)}
      </Paper>
      <AdminGiftFur gift={furGift} onClose={() => setFurGift(undefined)} />
      <AdminGiftTix gift={tixGift} onClose={() => setTixGift(undefined)} />
    </div>
  );
};

export default AdminGift;
