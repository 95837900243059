import React, { FunctionComponent } from 'react';
import { Theme, Paper, Typography, Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurball } from '../../wallet/WalletTypes';
import { darkBlue } from '../../theme';
import {
  canUpgradeInZone,
  getFurballDisplayZone,
  ZONE_NUM_BATTLE,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_EXPLORE,
} from '../Zones/ZoneHelpers';
import { getTimeBetween } from '../../utils';
import SvgScroll from '../../assets/SvgScroll';
import AlertProvider from '../Alert/AlertProvider';
import { ScholarshipDetailsDialogue } from '../Scholarships';
import { approxTimeSince, useInterval } from '../../wallet';
import { useWalletSelector } from '../../hooks';
import { IActiveRentalData } from './SelectableFurball';
import UpgradeDialog from '../Town/Upgrade/UpgradeDialog';
import FurballHearts from './FurballHearts';

export const calloutHeight = 40;

const useStyles = makeStyles((theme: Theme) => ({
  callout: {
    position: 'absolute',
    bottom: '170px',
    minHeight: `${calloutHeight}px`,
    textAlign: 'center',
    width: '100%',
    paddingLeft: '25px',
    paddingRight: '25px',
  },
  calloutZone: {
    position: 'absolute',
    bottom: '160px',
    minHeight: `${calloutHeight}px`,
    textAlign: 'center',
    width: '100%',
    paddingLeft: '0px',
  },
  disabledCallout: {
    backgroundColor: '#DDD !important',
  },
  calloutInner: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    border: '3px solid black',
  },
  calloutInnerZone: {
    padding: 0,
  },
  calloutTime: {
    marginTop: '-10px !important',
    // marginBottom: '-4px !important',
    color: darkBlue,
  },
  calloutBtn: {
    // margin: '0.25rem auto !important',
    // width: '110px',
    // minWidth: '110px',
    // fontSize: '0.875rem !important',
    // lineHeight: '1.75rem !important',
    width: '100%',
    marginLeft: '2px !important',
  },
  canInteractIn: {
    fontSize: '0.75rem !important',
    color: '#757575',
  },
  subtext: {
    fontSize: '1rem',
    color: theme.palette.grey.A700,
  },
  hearts: {
    marginBottom: 8,
  },
}));

interface ISelectableFurballCallout {
  furball: IFurball;
  canMove: boolean;
  activeRentalData?: IActiveRentalData;
}

export const SelectableFurballCallout: FunctionComponent<
  ISelectableFurballCallout
> = (props) => {
  const { furball, canMove, activeRentalData } = props;
  const classes = useStyles();
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const [showScholarshipDialog, setShowScholarshipDialog] =
    React.useState<string>();
  const [furballToUpgrade, setFurballToUpgrade] = React.useState<
    string | undefined
  >();

  const zoneNumber = getFurballDisplayZone(furball, playerId);
  const interval = useInterval();
  const activeVoyage = furball?.activeVoyage;
  const activeEncounter = activeVoyage?.activeEncounter;
  const furballLast = furball ? Math.max(furball.last, furball.birth) : 0;
  const timeSince = furball ? approxTimeSince(furballLast, interval) : ['', ''];
  const furballNumber = furball?.number ?? 0;
  const showTimeInZone = [ZONE_NUM_BATTLE, ZONE_NUM_EXPLORE].includes(
    zoneNumber,
  );
  const canUpgradeZone = canUpgradeInZone(zoneNumber);

  // useFrameRate(!showScholarshipDialog && showTimeInZone);

  function getTimeUntilCanUpgradeOrMove() {
    if (!furball?.pendingUpgrade?.endsAt) return null;

    const now = new Date();
    const timeLeftInMs =
      now.getTime() - new Date(furball?.pendingUpgrade?.endsAt).getTime();
    const endDate = new Date(now.getTime() + timeLeftInMs);
    const delta = Math.abs(endDate.getTime() - now.getTime()) / 1000;
    const { hours, mins, secs } = getTimeBetween(delta);

    return `${hours}h ${mins}m ${secs}s`;
  }

  // function renderTimeUntilLeaveZone(): React.ReactNode {
  //   if (zoneNumber !== ZONE_NUM_BOSS_FIGHTS) return null;
  //
  //   const { days, hours, mins, secs } = getTimeAgo(
  //     furball?.lastBossBattleAt as any,
  //     new Date(),
  //   );
  //   if (days >= 1 || hours >= 24) return null;
  //
  //   const hrsRemain = 24 - hours;
  //
  //   return (
  //     <>
  //       <Typography variant={'h4'} className={classes.calloutTime}>
  //         {hrsRemain}
  //         <span className={classes.subtext}>hours</span>
  //       </Typography>
  //       <Typography variant={'subtitle2'}>before leaving zone</Typography>
  //     </>
  //   );
  // }

  // here
  function renderIsSelected() {
    return (
      <React.Fragment>
        <Typography variant={'h6'} style={{ marginBottom: '6px' }}>
          #{furballNumber.toLocaleString()}
        </Typography>
        {showTimeInZone && (
          <>
            <Typography variant={'h4'} className={classes.calloutTime}>
              {timeSince[0]}
              <span className={classes.subtext}>{timeSince[1]}</span>
            </Typography>
          </>
        )}
        {zoneNumber === ZONE_NUM_BOSS_FIGHTS && (
          <FurballHearts furball={furball} className={classes.hearts} />
        )}
      </React.Fragment>
    );
  }

  function renderScholarshipControls(isOwner?: boolean) {
    return (
      <>
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          paddingBottom='10px'
          gap='10px'>
          <Typography variant={'h6'} fontWeight={600}>
            {isOwner ? 'LEASED' : 'RENTED'}
          </Typography>
          <Typography
            variant={'h4'}
            fontSize='1.2rem'
            className={classes.calloutTime}>
            {activeRentalData?.timeRemaining}{' '}
            <span className={classes.subtext}>left</span>
          </Typography>
          <Button
            variant='contained'
            size='small'
            onClick={() => setShowScholarshipDialog(furball?.tokenId)}
            className={classes.calloutBtn}
            startIcon={<SvgScroll width='0.8rem' height='0.8rem' />}>
            Rental Details
          </Button>
        </Grid>
        <AlertProvider>
          <ScholarshipDetailsDialogue
            tokenId={furball.tokenId}
            open={furball.tokenId === showScholarshipDialog}
            setOpen={() => setShowScholarshipDialog(undefined)}
          />
        </AlertProvider>
      </>
    );
  }

  function unSelectAfterUpgrade() {
    // const withoutUpgraded = [...selectedFurballs].filter(
    //   (fb) => fb.id !== furballToUpgrade,
    // );
    // setSelectedFurballs(withoutUpgraded);
    setFurballToUpgrade(undefined);
  }

  return (
    <div className={classes.callout}>
      <Paper
        className={clsx(classes.calloutInner, {
          [classes.disabledCallout]: activeVoyage && !activeEncounter,
        })}>
        {activeRentalData?.isRented
          ? renderScholarshipControls()
          : activeRentalData?.isRentedOut
          ? renderScholarshipControls(true)
          : renderIsSelected()}
        {!canMove && (
          <Typography variant={'body1'} className={classes.canInteractIn}>
            Upgrade Cooldown
          </Typography>
        )}
        <Button
          variant={'contained'}
          size={'small'}
          disabled={!canMove}
          className={classes.calloutBtn}
          onClick={() => setFurballToUpgrade(furball.tokenId)}>
          {canUpgradeZone
            ? canMove
              ? 'Upgrade Furball'
              : getTimeUntilCanUpgradeOrMove()
            : 'Furball Stats'}
        </Button>
        <UpgradeDialog
          show={!!furballToUpgrade}
          tokenId={furballToUpgrade}
          onClose={() => setFurballToUpgrade(undefined)}
          onAction={unSelectAfterUpgrade}
          setTokenId={setFurballToUpgrade}
        />
      </Paper>
    </div>
  );
};

export default SelectableFurballCallout;
