import * as React from 'react';

function SvgBonded00(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20.78 18.69'
      {...props}>
      <path
        fill='#FFF'
        stroke='#FFF'
        strokeMiterlimit={10}
        d='M10.47 17.61l-.09.09 7.3-7.3.57-.57c.12-.12.21-.25.31-.38h-8.1v8.16z'
      />
      <path
        fill='#FFF'
        stroke='#FFF'
        strokeMiterlimit={10}
        d='M10.47 9.43H2.2c.1.13.19.26.31.38l.57.57 7.3 7.3.09-.09V9.43zM18.26 2.51a5.154 5.154 0 00-7.3 0l-.49.49v6.43h8.1c1.68-2.02 1.59-5.02-.31-6.92z'
      />
      <path fill='#FFF' d='M10.47 3l-.08.09.08-.09z' />
      <path
        fill='#FFF'
        stroke='#FFF'
        strokeMiterlimit={10}
        d='M10.47 3l-.08.08-.57-.57a5.154 5.154 0 00-7.3 0C.62 4.41.53 7.4 2.21 9.43h8.27V3z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M18.26 2.51h0a5.154 5.154 0 00-7.3 0l-.57.57-.57-.57a5.154 5.154 0 00-7.3 0 5.154 5.154 0 000 7.3l.57.57 7.3 7.3 7.3-7.3.57-.57a5.154 5.154 0 000-7.3z'
      />
    </svg>
  );
}

export default SvgBonded00;
