import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  // Button,
  Card,
  Container,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { cdnRoot } from '../../theme';
import InfoCardList from './InfoCardList';
import { black, white } from '../../themev2';
// import SvgScholarBubble from '../../assets/SvgScholarBubble';
// import clsx from 'clsx';
// import RentFurballs from './RentFurballs';
import { useWindowSize } from '../../components/useWindowSize';
import { Link } from 'react-router-dom';
import { OutboundLink } from '../../components/Navigation';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: white,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.dark,
    marginTop: '0px !important',
    paddingBottom: '120px !important',
    paddingTop: '100px !important', //'400px !important',
    marginBottom: '-1px',
    overflowX: 'hidden',
    width: '32vw',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
      paddingTop: '100px !important',
    },
  },
  purpleBackground: {
    backgroundColor: theme.palette.secondary.dark,
    width: '100%',
  },
  cards: {
    position: 'absolute',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    width: '100% !important',
    left: '0',
    textAlign: 'center',
    justifyContent: 'center',
    overflow: 'hidden !important',
    marginTop: '-85px !important',

    ['@media (min-width: 1800px)']: {
      maxWidth: '1650px !important',
      right: '0',
    },

    [theme.breakpoints.down('lg')]: {
      position: 'relative',
      paddingTop: '85px',
      marginTop: '0px !important',
    },
  },
  cardContainer: {
    paddingLeft: '0px !important',
  },
  actionCardPaper: {
    display: 'block',
    border: `8px solid ${black}`,
    backgroundColor: white,
    textAlign: 'center',
    width: '90vw',
    height: '400px',
    padding: '50px 25px',
    margin: 'auto',
    marginTop: '30px',
    borderRadius: '30px !important',
    ['@media (min-width:1200px)']: {
      marginTop: '0px',
      padding: '20px 30px 20px 30px',
      height: '410px',
      border: `4px solid ${black}`,
      width: '32vw',
      maxWidth: '475px',
    },
  },
  actionCardTitle: {
    color: theme.palette.secondary.dark,
    paddingBottom: '30px',
    ['@media (min-width:1200px)']: {
      paddingBottom: '5px',
    },
  },
  actionCardDescription: {
    maxWidth: '380px',
    margin: '0 auto !important',
  },
  button: {
    marginTop: '30px !important',
    backgroundColor: theme.palette.primary.main,
    color: black,
    border: `3px solid ${black} !important`,
    whiteSpace: 'nowrap',
    width: '250px',
    height: '55px',
    borderRadius: '50px !important',
    fontSize: '18px !important',
    marginBottom: '20px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem !important',
      width: '275px',
      height: '65px',
      marginTop: '40px !important',
    },
    '&:hover': {
      backgroundColor: `${black} !important`,
      borderColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.main,
    },
  },
  scholarBubble: {
    marginTop: '-20px !important',
    ['@media (min-width:1379px)']: {
      marginTop: '0px !important',
    },
  },
  imageContent: {
    display: 'none',
    ['@media (min-width:1200px)']: {
      display: 'block',
      maxWidth: '380px',
      margin: 'auto',
    },
  },
  howToPlayImage: {
    width: '20vw',
    maxWidth: '350px',
    marginBottom: '10px',
    [theme.breakpoints.down('lg')]: {
      width: '100%',
    },
  },
  discordPrompt: {
    position: 'absolute',
    marginTop: '-120px',
    width: '200px',
    height: '180px',
    right: '0px',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },
  blueLink: {
    color: 'white !important',
    textDecoration: 'underline',
  },
}));

interface OwnProps {}

type Props = OwnProps;

type CardProps = {
  title: string;
  description: string;
  content: JSX.Element;
  gridProps: { [key: string]: string | number | any };
};

const ActionCard: FunctionComponent<CardProps> = (props) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.cardContainer} {...props.gridProps}>
      <Card className={classes.actionCardPaper}>
        <Typography variant={'h2'} className={classes.actionCardTitle}>
          {props.title}
        </Typography>
        <Typography variant={'body2'} className={classes.actionCardDescription}>
          {props.description}
        </Typography>
        {props.content}
      </Card>
    </Grid>
  );
};

const HowToPlay: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();

  const stepDef = [
    {
      image: (
        <img
          src={`${cdnRoot}/images/card-voyages.png`}
          className={classes.howToPlayImage}
        />
      ),
      title: '1. Borrow Furballs',
      subtitle: (
        <>
          To play for free,{' '}
          <Link className={classes.blueLink} to={'/marketplace/furballs'}>
            borrow Furballs
          </Link>{' '}
          in order to{' '}
          <Link className={classes.blueLink} to={'/boss/party/new'}>
            create a party
          </Link>
          .
          <br />
          <br />
          You can also{' '}
          <OutboundLink
            className={classes.blueLink}
            to={'https://opensea.io/collection/furballs-com-official'}>
            buying/sell from other players on OpenSea
          </OutboundLink>
          .
        </>
      ),
    },
    {
      image: (
        <img
          src={`${cdnRoot}/images/card-bosses.png`}
          className={classes.howToPlayImage}
        />
      ),
      title: '2. Fight Bosses',
      subtitle: (
        <>
          Cooperate against{' '}
          <Link className={classes.blueLink} to={'/boss'}>
            bosses
          </Link>{' '}
          to climb{' '}
          <Link className={classes.blueLink} to={'/leaderboards'}>
            leaderboards
          </Link>
          , earning Dust and rare items.
          <br />
          <br />
          Soon you'll be crafting powerful Elixirs and completing quests to
          strengthen your Furballs.
        </>
      ),
    },
    // {
    //   image: (
    //     <img
    //       src={`${cdnRoot}/images/card-explore.png`}
    //       className={classes.howToPlayImage}
    //     />
    //   ),
    //   title: '3. Equip Loot',
    //   subtitle: (
    //     <>
    //       Buy and sell loot items for wFUR on the{' '}
    //       <Link className={classes.blueLink} to={'/market/loot'}>
    //         Loot Marketplace
    //       </Link>.
    //     </>
    //   ),
    // },
    {
      image: (
        <img
          src={`${cdnRoot}/images/card-battle.png`}
          className={classes.howToPlayImage}
        />
      ),
      title: '3. Earn Prizes',
      subtitle: (
        <>
          Buy and sell loot items for wFUR on the{' '}
          <Link className={classes.blueLink} to={'/market/loot'}>
            Loot Marketplace
          </Link>
          .
          <br />
          <br />
          Earn the{' '}
          <Link className={classes.blueLink} to={'/swap'}>
            tradable token, $FUR
          </Link>
          , and other in-game items.
        </>
      ),
    },
  ];

  function adopt() {
    window.open('https://opensea.io/collection/furballs-com-official');
  }

  return (
    <div className={classes.purpleBackground}>
      <Container maxWidth={'lg'}>
        {/*{discordLinkComponent}*/}
        {/* <Grid
          container
          className={classes.cards}
          justifyContent={'center'}
          alignItems={'center'}>
          <ActionCard
            title={'Buy Furballs'}
            description={
              'Buy a Furball on OpenSea with Ethereum. Connect your wallet to our site and play!'
            }
            content={
              <>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={classes.button}
                  onClick={adopt}>
                  Adopt Furball
                </Button>
                <img
                  src={`${cdnRoot}/images/jars-furballs.png`}
                  width={'100%'}
                  className={classes.imageContent}
                />
              </>
            }
            gridProps={{ xs: 12, lg: 4 }}
          />
          <ActionCard
            title={'Rent Furballs'}
            description={
              'Rent and play for free as a scholar! Scholars can compete to win Edition 2 NFTs. '
            }
            content={
              <>
                <RentFurballs buttonClass={classes.button} />
                <div
                  className={clsx(classes.scholarBubble, classes.imageContent)}>
                  <SvgScholarBubble width={'100%'} />
                </div>
              </>
            }
            gridProps={{ xs: 12, lg: 4 }}
          />
        </Grid> */}
        <Grid container className={classes.root} sx={{ p: 4 }}>
          <Grid item xs={12} sx={{ mb: 6 }}>
            <Typography variant={'h1'} sx={{ mb: 4 }}>
              How To Play
            </Typography>
          </Grid>
          <InfoCardList
            gridSizing={{ md: 6, lg: 4, sm: 12 }}
            infoCards={stepDef}
            subtitleWidth={windowSize.width < 1200 ? '430px' : '255px'}
          />
        </Grid>
      </Container>
    </div>
  );
};

export default HowToPlay;
