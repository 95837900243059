import * as React from 'react';

type CardProps = React.SVGProps<SVGSVGElement> & {
  backgroundColor?: string;
  children?: React.ReactNode;
};

function SvgCardBack(props: CardProps) {
  const { backgroundColor, ...p } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='15.94 14.68 257.61 239.19'
      {...p}>
      <path
        fill={`#${backgroundColor ?? '5B50D9'}`}
        d='M237.39 16.18H52.09c-19.14 0-34.65 15.52-34.65 34.65v201.54h254.61V50.83c0-19.14-15.52-34.65-34.66-34.65z'
      />
      {props.children && (
        <clipPath id='clip-card-back'>
          <path d='M237.39 16.18H52.09c-19.14 0-34.65 15.52-34.65 34.65v201.54h254.61V50.83c0-19.14-15.52-34.65-34.66-34.65z' />
          {props.children}
        </clipPath>
      )}
      <path
        fill='none'
        stroke='#000'
        strokeWidth={3}
        strokeMiterlimit={10}
        d='M237.39 16.18H52.09c-19.14 0-34.65 15.52-34.65 34.65v201.54h254.61V50.83c0-19.14-15.52-34.65-34.66-34.65z'
      />
    </svg>
  );
}

export default SvgCardBack;
