import { assets } from '../data';
import { cdnRoot } from '../../../theme';
import { Dispatch, SetStateAction } from 'react';
import { SkillLibrary } from '../../Skills/SkillLibrary';
import { defaultBossKeys, getAllLootItems } from '../../../components/Loot';
import { renderEdition0Weapon } from '../../../assets/edition0/Weapon';
import { FILTERKEYS, IFilterGroup, IFilterState } from './RentMarketTypes';

const lootItems = getAllLootItems().filter(
  (item) => !defaultBossKeys.includes(item.lootId),
);

const bossKeys = getAllLootItems().filter((item) =>
  defaultBossKeys.includes(item.lootId),
);

export const weapons = assets.filter((asset) => asset.slot === 'WEAPON');

export const getFilters = (
  state: IFilterState,
  setState: Dispatch<SetStateAction<IFilterState>>,
) => {
  const handleChange = (filterId: string, key: FILTERKEYS) => {
    const filter = state[key] || [];
    const index = filter.indexOf(filterId);
    index === -1 ? filter.push(filterId) : filter.splice(index, 1);

    setState({ ...state, [key]: filter });
  };

  const handleRadioChange = (filterId: string, key: FILTERKEYS) => {
    let filter = state[key] || [];
    const index = filter.indexOf(filterId);
    filter = index === -1 ? [filterId] : [];

    setState({ ...state, [key]: filter });
  };

  const handleSliderChange = (value: string, key: FILTERKEYS) => {
    const filter = [value];
    setState({ ...state, [key]: filter });
  };

  const filters: IFilterGroup[] = [
    {
      title: 'Skill',
      checkedOptions: state[FILTERKEYS.SKILL],
      options: SkillLibrary.map((item) => ({
        id: item.id,
        title: item.name,
        description: item.summary,
        icon: `${cdnRoot}/images/icons/skills/${item.icon}.png`,
      })),
      updater: (id: string) => handleChange(id, FILTERKEYS.SKILL),
    },
    {
      title: 'Loot',
      checkedOptions: state[FILTERKEYS.LOOT],
      options: lootItems.map((item) => ({
        id: item.lootId,
        title: item.name,
        description: item.description,
        icon: item.icon,
      })),
      updater: (id: string) => handleChange(id, FILTERKEYS.LOOT),
    },
    {
      title: 'Weapon',
      checkedOptions: state[FILTERKEYS.WEAPON],
      options: weapons.map((item) => ({
        id: item.assetIndex + 1,
        title: item.name,
        description: item.rarityLevel.toLowerCase(),
        icon: renderEdition0Weapon({
          partNumber: item.assetIndex + 1,
          color1: '',
          color2: '',
        }),
      })),
      updater: (id: string) => handleChange(id, FILTERKEYS.WEAPON),
    },
    {
      title: 'Level',
      slider: 200,
      checkedOptions: state[FILTERKEYS.LEVEL],
      options: [],
      updater: (id: string) => handleSliderChange(id, FILTERKEYS.LEVEL),
    },
    {
      title: 'Boss keys',
      checkedOptions: state[FILTERKEYS.BOSSKEY],
      options: bossKeys.map((item) => ({
        id: item.lootId,
        title: item.name,
        description: item.description,
        icon: item.icon,
      })),
      updater: (id: string) => handleChange(id, FILTERKEYS.BOSSKEY),
    },
    {
      title: 'Rare gene boost',
      slider: 100,
      checkedOptions: state[FILTERKEYS.GENE],
      options: [],
      updater: (id: string) => handleSliderChange(id, FILTERKEYS.GENE),
    },
    {
      title: 'EXP rate',
      slider: 2000,
      checkedOptions: state[FILTERKEYS.EXP],
      options: [],
      updater: (id: string) => handleSliderChange(id, FILTERKEYS.EXP),
    },
    {
      title: 'FUR rate',
      slider: 1000,
      checkedOptions: state[FILTERKEYS.FUR],
      options: [],
      updater: (id: string) => handleSliderChange(id, FILTERKEYS.FUR),
    },
  ];

  return filters;
};
