import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getTimeBetween } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IEquipmentBreakable {
  breakAt: Date | null;
  durabilityTime: number;
}

interface IEquipmentTimeRemaining {
  equipment: IEquipmentBreakable;
  textOnly?: boolean;
}

export const EquipmentTimeRemaining: FunctionComponent<
  IEquipmentTimeRemaining
> = (props) => {
  const { equipment } = props;
  const classes = useStyles();
  const { durabilityTime } = equipment;
  const isUnbreakable = durabilityTime === 0;
  const now = new Date();
  const untilBreak = equipment.breakAt
    ? Math.abs(new Date(equipment.breakAt).getTime() - now.getTime()) / 1000
    : durabilityTime;
  const breaksAfter = equipment.breakAt ? 'after' : 'in'; // say "after" if not equipped

  if (isUnbreakable) {
    return props.textOnly ? <>Unbreakable!</> : <span>Unbreakable!</span>;
  }

  // const endDate = new Date(now.getTime() + timeLeftInMs);
  const { days, hours, mins } = getTimeBetween(untilBreak);
  const time =
    days > 1
      ? `${days} day${days === 1 ? '' : 's'}`
      : `${hours} hour${hours === 1 ? '' : 's'} ${mins} min${
          mins === 1 ? '' : 's'
        }`;

  return props.textOnly ? (
    <>{time}</>
  ) : (
    <>
      Breaks {breaksAfter} <span>{time}</span>
    </>
  );
};

export default EquipmentTimeRemaining;
