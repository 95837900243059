import * as React from 'react';

function SvgLootFlowers(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='loot-flowers_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 200 153.66'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.loot-flowers_svg__st10,.loot-flowers_svg__st13,.loot-flowers_svg__st14,.loot-flowers_svg__st15,.loot-flowers_svg__st16,.loot-flowers_svg__st22,.loot-flowers_svg__st7{fill:none;stroke:#000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.loot-flowers_svg__st10,.loot-flowers_svg__st13,.loot-flowers_svg__st14,.loot-flowers_svg__st15,.loot-flowers_svg__st16,.loot-flowers_svg__st22{fill:#ffcdff}.loot-flowers_svg__st13,.loot-flowers_svg__st14,.loot-flowers_svg__st15,.loot-flowers_svg__st16,.loot-flowers_svg__st22{fill:#fff0ab}.loot-flowers_svg__st14,.loot-flowers_svg__st15,.loot-flowers_svg__st16,.loot-flowers_svg__st22{stroke-width:4}.loot-flowers_svg__st15,.loot-flowers_svg__st16,.loot-flowers_svg__st22{fill:#93cdff}.loot-flowers_svg__st16,.loot-flowers_svg__st22{fill:#c4f8c3}.loot-flowers_svg__st22{fill:#ffcdff}'
        }
      </style>
      <path
        className='loot-flowers_svg__st7'
        d='M87.77 49.23l16.21 99.85M103.98 149.08C84.7 79.18 63.11 88.89 63.11 88.89M104.3 148.51c9.74-57.87 23.39-61.63 23.39-61.63M106.69 86.17l-2.39 62.34'
      />
      <path
        d='M84.49 45.05a5.229 5.229 0 01-1.99-6.25c.04-.1.07-.19.11-.29 1.3-3.62-1.85-7.36-5.64-6.69l-.3.06c-2.43.48-4.82-.75-5.83-3.02-.04-.09-.08-.19-.13-.28-1.64-3.48-6.52-3.9-8.72-.74-.08.12-.16.23-.24.35a5.028 5.028 0 01-6.07 1.93c-.04-.01-.07-.03-.11-.04a5.13 5.13 0 00-6.92 5.84c.01.04.02.08.02.12.49 2.36-.72 4.69-2.93 5.66l-.39.18c-3.48 1.64-3.9 6.52-.74 8.72.08.06.17.12.25.17a5.229 5.229 0 011.99 6.25c-.04.1-.07.19-.11.29-1.3 3.62 1.85 7.36 5.64 6.69l.3-.06c2.43-.48 4.82.75 5.83 3.02.04.09.08.19.13.28 1.64 3.48 6.52 3.9 8.72.74'
        fill='#fff'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
      />
      <circle
        transform='rotate(-37.422 64.67 47.904)'
        className='loot-flowers_svg__st10'
        cx={64.67}
        cy={47.91}
        r={10.87}
      />
      <circle
        cx={64.67}
        cy={47.91}
        r={3.21}
        fill='#ffdcb8'
        stroke='#000'
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
      />
      <path
        d='M95.37 86.33h0a6.416 6.416 0 00-3.34-4.5c1.91.41 3.92-.06 5.45-1.32h0c3.31-2.72 3.07-7.86-.46-10.27h0a6.405 6.405 0 00-5.55-.82 6.432 6.432 0 002.92-4.79h0c.41-4.26-3.38-7.73-7.59-6.94h0c-1.95.37-3.6 1.61-4.5 3.34.41-1.91-.06-3.92-1.32-5.45h0c-2.72-3.31-7.86-3.07-10.27.46a6.405 6.405 0 00-.82 5.55 6.432 6.432 0 00-4.79-2.92h0c-4.26-.41-7.73 3.38-6.94 7.59h0c.37 1.95 1.61 3.6 3.34 4.5-1.91-.41-3.92.06-5.45 1.32h0c-3.31 2.72-3.07 7.86.46 10.27a6.405 6.405 0 005.55.82 6.432 6.432 0 00-2.92 4.79h0c-.41 4.26 3.38 7.73 7.59 6.94 1.95-.37 3.6-1.61 4.5-3.34-.41 1.91.06 3.92 1.32 5.45h0c2.72 3.31 7.86 3.07 10.27-.46a6.405 6.405 0 00.82-5.55 6.432 6.432 0 004.79 2.92h0c4.27.41 7.74-3.39 6.94-7.59zm-18.6-10.04h0z'
        fill='#b6fbfc'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
      />
      <circle
        transform='rotate(-1.985 76.76 76.29)'
        className='loot-flowers_svg__st13'
        cx={76.77}
        cy={76.29}
        r={6.16}
      />
      <path
        className='loot-flowers_svg__st14'
        d='M99.91 13.37l3.27 17.32-14.63-9.82a7.364 7.364 0 01-3.25-6.58c.3-4.91 5.23-8.16 9.85-6.5 2.46.88 4.28 3 4.76 5.58zM106.45 48l-3.27-17.32 14.63 9.82a7.364 7.364 0 013.25 6.58c-.3 4.91-5.23 8.16-9.85 6.5a7.36 7.36 0 01-4.76-5.58z'
      />
      <path
        className='loot-flowers_svg__st14'
        d='M120.5 27.42l-17.32 3.27L113 16.05a7.364 7.364 0 016.58-3.25c4.91.3 8.16 5.23 6.5 9.86a7.395 7.395 0 01-5.58 4.76zM85.86 33.95l17.32-3.27-9.82 14.63a7.364 7.364 0 01-6.58 3.25c-4.91-.3-8.16-5.23-6.5-9.86a7.402 7.402 0 015.58-4.75z'
      />
      <path
        className='loot-flowers_svg__st15'
        d='M113.12 16.13l-9.94 14.56-3.4-17.29c-.51-2.57.39-5.22 2.35-6.95 3.68-3.26 9.46-2.07 11.56 2.37 1.12 2.36.9 5.14-.57 7.31z'
      />
      <g>
        <path
          className='loot-flowers_svg__st15'
          d='M117.74 40.62l-14.56-9.94 17.29-3.4c2.57-.51 5.22.39 6.95 2.35 3.26 3.68 2.07 9.46-2.37 11.56a7.335 7.335 0 01-7.31-.57zM88.62 20.75l14.56 9.94-17.29 3.4c-2.57.51-5.22-.39-6.95-2.35-3.26-3.68-2.07-9.46 2.37-11.56a7.335 7.335 0 017.31.57z'
        />
      </g>
      <circle
        transform='rotate(-31.832 103.173 30.686)'
        className='loot-flowers_svg__st10'
        cx={103.18}
        cy={30.68}
        r={7.07}
      />
      <g>
        <path
          className='loot-flowers_svg__st16'
          d='M133.27 124.91c-4.89-3.63-11.52-4.63-17.5-2.04-5.99 2.59-9.81 8.1-10.52 14.15 4.89 3.63 11.52 4.63 17.5 2.04 5.99-2.59 9.81-8.1 10.52-14.15z'
        />
        <path className='loot-flowers_svg__st7' d='M105.25 137.02l15.91-6.73' />
      </g>
      <g>
        <path
          className='loot-flowers_svg__st16'
          d='M63.11 121.77c1.26 8.5 6.88 16.13 15.44 19.52s17.88 1.71 24.63-3.62c-1.26-8.5-6.88-16.12-15.44-19.52-8.56-3.4-17.88-1.71-24.63 3.62z'
        />
        <path
          className='loot-flowers_svg__st7'
          d='M103.18 137.67l-5.14-3.64a35.514 35.514 0 00-20.57-6.56'
        />
      </g>
      <g
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path
          d='M127.13 70.57s13.97-.48 15.83.08h0c4.01 1.21 8.06-1.76 8.11-5.95h0c.02-1.94-.85-3.76-2.34-4.96 1.75.76 3.77.7 5.48-.22h0c3.69-1.98 4.46-6.95 1.53-9.94h0a6.249 6.249 0 00-5.16-1.85c1.78-.7 3.16-2.17 3.72-4.03v0c1.21-4.01-1.76-8.06-5.95-8.11h0c-1.94-.02-3.76.85-4.96 2.34.76-1.75.7-3.77-.22-5.48h0c-1.98-3.69-6.95-4.46-9.94-1.53a6.249 6.249 0 00-1.85 5.16 6.272 6.272 0 00-4.03-3.72h0c-4.01-1.21-8.06 1.76-8.11 5.95h0c-.02 1.94.85 3.76 2.34 4.96-1.75-.76-3.77-.7-5.48.22h0c-3.69 1.98-4.46 6.95-1.53 9.94a6.249 6.249 0 005.16 1.85c-1.78.7-5.87 9.41-5.87 9.41m21.29-13.18h0z'
          fill='#ffdcb8'
          strokeWidth={4}
        />
        <circle
          transform='rotate(-17.899 135.146 51.514)'
          cx={135.15}
          cy={51.51}
          fill='#b6fbfc'
          strokeWidth={3}
          r={6.03}
        />
      </g>
      <g>
        <circle
          className='loot-flowers_svg__st14'
          cx={132.65}
          cy={77.65}
          r={14.13}
        />
        <circle
          cx={132.65}
          cy={77.65}
          r={4.36}
          fill='#fff'
          stroke='#000'
          strokeWidth={3}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
        />
      </g>
      <g
        stroke='#000'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path
          d='M118.69 75.28c.38-3.67-.61-7.44-2.91-10.51 3.71-.94 6.85-3.25 8.86-6.35a14.873 14.873 0 00-9.49-5.37c1.96-3.29 2.54-7.14 1.77-10.76-3.67-.38-7.44.61-10.51 2.91-.94-3.71-3.25-6.85-6.35-8.86a14.873 14.873 0 00-5.37 9.49 14.941 14.941 0 00-10.76-1.77c-.38 3.67.61 7.44 2.91 10.51-3.71.94-6.85 3.25-8.86 6.35 2.33 2.87 5.69 4.84 9.49 5.37a14.941 14.941 0 00-1.77 10.76c3.67.38 7.44-.61 10.51-2.91.94 3.71 3.25 6.85 6.35 8.86 2.87-2.33 4.84-5.69 5.37-9.49 3.29 1.96 7.14 2.53 10.76 1.77z'
          fill='#dfe0fd'
          strokeWidth={4}
        />
        <circle
          transform='rotate(-69.169 101.322 59.664)'
          cx={101.31}
          cy={59.66}
          fill='#ffa6cb'
          strokeWidth={3}
          r={3.29}
        />
      </g>
      <g>
        <path
          className='loot-flowers_svg__st22'
          d='M108.07 70.97l1.22 13.62-10.57-8.69a5.734 5.734 0 01-2.02-5.33c.6-3.77 4.65-5.9 8.1-4.28 1.85.88 3.09 2.65 3.27 4.68zM110.5 98.21l-1.22-13.62 10.56 8.69a5.734 5.734 0 012.02 5.33c-.6 3.77-4.65 5.9-8.1 4.28a5.748 5.748 0 01-3.26-4.68z'
        />
        <path
          className='loot-flowers_svg__st22'
          d='M122.9 83.37l-13.62 1.22 8.69-10.56a5.734 5.734 0 015.33-2.02c3.77.6 5.9 4.65 4.28 8.1a5.748 5.748 0 01-4.68 3.26zM95.66 85.81l13.62-1.22-8.69 10.56a5.734 5.734 0 01-5.33 2.02c-3.77-.6-5.9-4.65-4.28-8.1a5.762 5.762 0 014.68-3.26z'
        />
        <path
          className='loot-flowers_svg__st22'
          d='M118.05 74.1l-8.77 10.49-1.32-13.61c-.2-2.02.69-4 2.34-5.19 3.09-2.24 7.46-.89 8.75 2.7.69 1.92.31 4.05-1 5.61zM100.51 95.08l8.77-10.49 1.32 13.61c.2 2.02-.69 4-2.34 5.19-3.09 2.24-7.46.89-8.75-2.7-.68-1.92-.3-4.05 1-5.61z'
        />
        <g>
          <path
            className='loot-flowers_svg__st22'
            d='M119.77 93.36l-10.49-8.77 13.61-1.32c2.02-.2 4 .69 5.19 2.34 2.24 3.09.89 7.46-2.71 8.75-1.9.69-4.04.31-5.6-1zM98.79 75.82l10.49 8.77-13.61 1.32c-2.02.2-4-.69-5.19-2.34-2.24-3.09-.89-7.46 2.7-8.75 1.92-.69 4.05-.31 5.61 1z'
          />
        </g>
        <circle
          transform='rotate(-71.331 109.284 84.588)'
          className='loot-flowers_svg__st13'
          cx={109.28}
          cy={84.59}
          r={10.22}
        />
      </g>
    </svg>
  );
}

export default SvgLootFlowers;
