import React, { FunctionComponent } from 'react';
import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ItemListingFragment,
  RarityLevel,
  useBuyLootListingMutation,
} from '../../../components/schema';
import OkDialog from '../../../components/OkDialog';
import { getItemDef } from '../../../components/BossFights/BossRewards/bossRewardsUtils';
import {
  ALERT_TYPES,
  AlertContext,
} from '../../../components/Alert/AlertContext';
import { useWalletSelector } from '../../../hooks';
import { getRarityLevelName } from '../../Play';
import PlayerLink from '../../../components/Players/PlayerLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IPurchaseLootListingDialog {
  listing?: ItemListingFragment;
  setListing: (l?: ItemListingFragment) => void;
  onPurchased: (l: ItemListingFragment) => void;
}

export const PurchaseLootListingDialog: FunctionComponent<
  IPurchaseLootListingDialog
> = (props) => {
  const { listing, setListing, onPurchased } = props;
  const classes = useStyles();
  const [purchase, { data, loading }] = useBuyLootListingMutation();
  const itemDef = listing ? getItemDef(listing.itemDefinitionId) : undefined;
  const { addAlert } = React.useContext(AlertContext);
  const quantity = listing?.quantity ?? 0;
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const insufficientAmount = (listing?.currentPrice ?? 0) - wFurBalance;
  const rarity = getRarityLevelName(itemDef?.rarity ?? RarityLevel.Common);
  const fullName = `${quantity} ${itemDef?.name}${
    quantity === 1 ? '' : 's'
  } (${rarity})`;

  async function accept() {
    if (!listing) return;
    try {
      const { data } = await purchase({ variables: { id: listing.id } });

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Purchased ${itemDef?.name}!`,
      });
      onPurchased(data?.listing ?? listing);
      setListing(undefined);
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to purchase item',
      });
    }
  }

  return (
    <OkDialog
      open={!!listing}
      onClose={() => setListing(undefined)}
      title={`Purchase ${fullName}?`}>
      {listing && itemDef && (
        <>
          <Typography variant={'body1'}>
            Are you sure you want to buy {fullName}?
          </Typography>
          <Typography variant={'subtitle2'}>
            This item is sold by <PlayerLink player={listing.seller} /> for{' '}
            {listing.currentPrice.toLocaleString()} wFUR
          </Typography>
          <Button
            style={{ width: '100%', marginTop: 10 }}
            variant={'contained'}
            disabled={loading || insufficientAmount > 0}
            onClick={accept}>
            Purchase {itemDef.name}
          </Button>
          {insufficientAmount > 0 && (
            <Typography
              variant={'subtitle2'}
              style={{ marginTop: 10, textAlign: 'center' }}>
              {insufficientAmount.toLocaleString()} more wFUR required!
            </Typography>
          )}
        </>
      )}
    </OkDialog>
  );
};

export default PurchaseLootListingDialog;
