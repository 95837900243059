import * as React from 'react';

function SvgBossKeyBoneyard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 169.32 173.14'
      {...props}>
      <g fill='#EBFFF8'>
        <path d='M40.19 130.59l.16-.16-.01-.01c-.05.06-.1.12-.15.17zM45.41 125.35l.17-.15c-.05.05-.11.1-.17.15z' />
        <path d='M68.62 152.55c1.07-.02 2.13-.43 2.96-1.23 1.74-1.67 1.79-4.54.12-6.27a4.43 4.43 0 00-6.31-.06c-.05.05-.1.11-.15.17l-19.82-19.82-5.07 5.07 19.82 19.82c-.06.05-.12.1-.17.15a4.413 4.413 0 00.06 6.31c1.73 1.67 4.6 1.61 6.27-.12.79-.83 1.2-1.89 1.23-2.96 0-.58.46-1.04 1.06-1.06z' />
      </g>
      <g
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path d='M40.19 130.59l.16-.16-.01-.01c-.05.06-.1.12-.15.17zM45.41 125.35h0l.17-.15c-.05.05-.11.1-.17.15z' />
        <path d='M68.62 152.55c1.07-.02 2.13-.43 2.96-1.23 1.74-1.67 1.79-4.54.12-6.27a4.43 4.43 0 00-6.31-.06c-.05.05-.1.11-.15.17l-19.82-19.82-5.07 5.07 19.82 19.82c-.06.05-.12.1-.17.15a4.413 4.413 0 00.06 6.31c1.73 1.67 4.6 1.61 6.27-.12.79-.83 1.2-1.89 1.23-2.96 0-.58.46-1.04 1.06-1.06z' />
      </g>
      <g fill='#EBFFF8'>
        <path d='M26.82 149.01l.16-.16-.01-.01c-.05.05-.1.11-.15.17zM32.04 143.77l.17-.15c-.05.05-.11.09-.17.15zM45.93 161.64c1.07-.02 2.13-.43 2.96-1.23 1.74-1.67 1.79-4.54.12-6.27a4.43 4.43 0 00-6.31-.06c-.05.05-.1.11-.15.17l-10.49-10.49-5.07 5.07 10.49 10.49c-.06.05-.12.1-.17.15a4.413 4.413 0 00.06 6.31c1.73 1.67 4.6 1.61 6.27-.12.79-.83 1.2-1.89 1.23-2.96-.01-.58.45-1.05 1.06-1.06z' />
      </g>
      <g
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path d='M26.82 149.01l.16-.16-.01-.01c-.05.05-.1.11-.15.17zM32.04 143.77h0l.17-.15c-.05.05-.11.09-.17.15zM45.93 161.64c1.07-.02 2.13-.43 2.96-1.23 1.74-1.67 1.79-4.54.12-6.27a4.43 4.43 0 00-6.31-.06c-.05.05-.1.11-.15.17l-10.49-10.49-5.07 5.07 10.49 10.49c-.06.05-.12.1-.17.15a4.413 4.413 0 00.06 6.31c1.73 1.67 4.6 1.61 6.27-.12.79-.83 1.2-1.89 1.23-2.96-.01-.58.45-1.05 1.06-1.06z' />
      </g>
      <path
        fill='#EBFFF8'
        d='M120.2 52.46l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.65 3.72-3.53zM68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53z'
      />
      <path
        fill='#FFF'
        d='M58.52 101.1c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79z'
      />
      <path
        fill='#EBFFF8'
        d='M34.63 133.69l-4.46-4.46c-2.27-2.27-2-6.02.56-7.95l6.24-4.69a5.272 5.272 0 016.9.49l2.91 2.91c1.86 1.86 2.07 4.8.49 6.9l-4.69 6.24c-1.93 2.57-5.69 2.83-7.95.56z'
      />
      <path
        fill='#FFF'
        d='M33.43 123.99l6-4.94c1.79-1.47 3.91-1.87 5.25-1.06-.1-.17-.22-.33-.36-.47l-1.98-1.98c-1.26-1.26-3.7-.97-5.72.69l-6 4.94c-2.46 2.03-3.28 5.22-1.74 6.77l2.81 2.81c-1.54-1.54-.72-4.73 1.74-6.76z'
      />
      <path
        fill='#EBFFF8'
        d='M62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73L53 109.8l1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53z'
      />
      <path
        fill='#FFF'
        d='M52.49 107.13c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79zM43.51 115.22c-.45-1.11.61-2.94 2.15-3.67l4.81-2.27c.85-.4 1.64-.37 2.08.07l-2.81-2.81c-.44-.44-1.23-.47-2.08-.07l-4.81 2.27c-1.55.73-2.61 2.56-2.15 3.67.91 2.22 2.34 4.03 4.21 5.35-.55-.78-1.02-1.62-1.4-2.54z'
      />
      <path
        fill='#EBFFF8'
        d='M56.16 112.96l-5.27-5.27c-.65-.65-1.63-.87-2.58-.57l-5.4 1.68c-1.74.54-2.62 2.54-1.8 4.02 2.32 4.18 5.74 7.6 9.92 9.92 1.48.82 3.48-.06 4.02-1.8l1.68-5.4c.3-.95.08-1.93-.57-2.58z'
      />
      <path
        fill='#BCEBDA'
        d='M68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.07.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53zM62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.06.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53z'
      />
      <path
        fill='#BCEBDA'
        d='M55.06 120.94l1.68-5.4c.3-.95.08-1.93-.57-2.58l-3.32-3.32c.65.65.87 1.63.57 2.58l-1.68 5.4c-.54 1.74-2.54 2.62-4.02 1.8-1.4-.78-2.7-1.68-3.91-2.69 2.01 2.42 4.44 4.46 7.23 6.01 1.47.82 3.48-.06 4.02-1.8zM42.57 133.13l4.69-6.24a5.272 5.272 0 00-.49-6.9l-2.91-2.91c-.07-.07-.14-.13-.21-.19a5.278 5.278 0 01.29 6.69l-4.69 6.24c-1.93 2.56-5.68 2.83-7.95.56l3.32 3.32c2.27 2.26 6.03 2 7.95-.57z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73L53 109.8l1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53zM68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53z'
      />
      <path
        fill='#EBFFF8'
        d='M68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53z'
      />
      <path
        fill='#FFF'
        d='M58.52 101.1c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79z'
      />
      <path
        fill='#EBFFF8'
        d='M62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73L53 109.8l1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53z'
      />
      <path
        fill='#FFF'
        d='M52.49 107.13c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79z'
      />
      <path
        fill='#BCEBDA'
        d='M68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.07.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53zM62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.06.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M62.63 110.02l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73L53 109.8l1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.91-.2 3.43-1.65 3.73-3.53zM68.66 103.99l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.64 3.72-3.53z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M56.16 112.96l-5.27-5.27c-.65-.65-1.63-.87-2.58-.57l-5.4 1.68c-1.74.54-2.62 2.54-1.8 4.02 2.32 4.18 5.74 7.6 9.92 9.92 1.48.82 3.48-.06 4.02-1.8l1.68-5.4c.3-.95.08-1.93-.57-2.58z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M34.63 133.69l-4.46-4.46c-2.27-2.27-2-6.02.56-7.95l6.24-4.69a5.272 5.272 0 016.9.49l2.91 2.91c1.86 1.86 2.07 4.8.49 6.9l-4.69 6.24c-1.93 2.57-5.69 2.83-7.95.56z'
      />
      <path
        fill='#EBFFF8'
        d='M18.53 151l-5.67-5.67c-2.88-2.88-2.55-7.65.71-10.1l7.94-5.96a6.703 6.703 0 018.77.62l3.7 3.7c2.36 2.36 2.63 6.1.62 8.77l-5.96 7.94c-2.45 3.24-7.22 3.58-10.11.7z'
      />
      <path
        fill='#FFF'
        d='M16.79 138.44l7.62-6.28c2.48-2.05 5.47-2.46 7.11-1.02-.05-.05-.09-.11-.14-.16l-2.52-2.52c-1.61-1.61-4.71-1.23-7.27.88l-7.62 6.28c-3.13 2.58-4.18 6.64-2.21 8.6l2.81 2.81c-1.95-1.95-.91-6.01 2.22-8.59z'
      />
      <path
        fill='#BCEBDA'
        d='M28.64 150.28l5.96-7.94a6.703 6.703 0 00-.62-8.77l-3.32-3.32c2.36 2.36 2.63 6.1.62 8.77l-5.96 7.94c-2.45 3.26-7.22 3.6-10.1.71l3.32 3.32c2.88 2.89 7.65 2.55 10.1-.71z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M18.53 151l-5.67-5.67c-2.88-2.88-2.55-7.65.71-10.1l7.94-5.96a6.703 6.703 0 018.77.62l3.7 3.7c2.36 2.36 2.63 6.1.62 8.77l-5.96 7.94c-2.45 3.24-7.22 3.58-10.11.7z'
      />
      <g>
        <path
          fill='#EBFFF8'
          d='M88.79 63.6c-1.17-1.35-28.39-33.34-13.64-51.42 4.42-5.42 9.44-6.26 12.86-6.01 15.1 1.1 30.09 25.47 31.74 28.25l-6.17 4.68c-5.46-9.12-17.84-24.44-26.15-25.04-1.46-.11-3.69.1-6.15 3.12-5.26 6.45-1.59 17.02 2.42 24.74 4.73 9.1 11.02 16.44 11.08 16.51l-5.99 5.17zM101.08 75.89l5.17-5.99c7.82 6.73 30.49 22.28 41.25 13.5 3.02-2.47 3.23-4.69 3.12-6.16-.61-8.33-17.16-21.94-26.28-27.39l5.92-4.93c2.78 1.66 27.15 16.64 28.25 31.74.25 3.43-.59 8.44-6.01 12.86-18.09 14.76-50.07-12.47-51.42-13.63z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M88.79 63.6c-1.17-1.35-28.39-33.34-13.64-51.42 4.42-5.42 9.44-6.26 12.86-6.01 15.1 1.1 30.09 25.47 31.74 28.25l-5.55 5.3c-5.46-9.12-18.46-25.06-26.78-25.66-1.46-.11-3.69.1-6.15 3.12-5.26 6.45-1.59 17.02 2.42 24.74 4.73 9.1 11.02 16.44 11.08 16.51l-5.98 5.17zM101.08 75.89l5.17-5.99c7.82 6.73 30.49 22.28 41.25 13.5 3.02-2.47 3.23-4.69 3.12-6.16-.61-8.33-15.3-20.08-24.42-25.53l4.06-6.79c2.78 1.66 27.15 16.64 28.25 31.74.25 3.43-.59 8.44-6.01 12.86-18.09 14.76-50.07-12.47-51.42-13.63zM78.68 24.59s-4.63-2.58-7.9.89M140.35 86.25s1.96 4-1.51 7.28'
        />
      </g>
      <g>
        <path
          fill='#EBFFF8'
          d='M113.62 59.03l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.65 3.72-3.53z'
        />
        <path
          fill='#FFF'
          d='M103.48 56.14c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79z'
        />
        <path
          fill='#BCEBDA'
          d='M113.62 59.03l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.07.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.65 3.72-3.53z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M113.62 59.03l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.2 3.42-1.65 3.72-3.53z'
        />
      </g>
      <g>
        <path
          fill='#EBFFF8'
          d='M88.43 91.46a3.124 3.124 0 002.54-2.83l.28-3.62c.1-1.34 1.05-2.46 2.35-2.79l3.15-.8a3.124 3.124 0 002.29-2.38l.91-4.29a3.13 3.13 0 013.02-2.48l1.93-.02c1.45-.02 2.7-1.03 3.01-2.44l.45-2.02c.12-.56.38-1.09.79-1.48.63-.61.98-1.45.96-2.33l-.01-.29a3.018 3.018 0 00-1.69-2.64c-2.43-1.2-4.4-3.17-5.6-5.6l-.01-.02c-.49-1-1.5-1.64-2.62-1.67l-.31-.01c-.86-.02-1.68.32-2.29.92-.42.41-.94.7-1.52.83l-2.02.45a3.13 3.13 0 00-2.44 3.01l-.02 1.93a3.13 3.13 0 01-2.48 3.02l-4.29.91a3.11 3.11 0 00-2.38 2.29l-.8 3.15a3.122 3.122 0 01-2.79 2.35l-3.62.28a3.124 3.124 0 00-2.83 2.54l-.76 4.02a3.11 3.11 0 01-2.41 2.47l-1.11.24a3.127 3.127 0 00-2.45 3.37l.27 2.64c.1.94-.23 1.87-.9 2.53l-3.48 3.48a3.126 3.126 0 000 4.42l3.69 3.69a3.126 3.126 0 004.42 0l3.48-3.48c.67-.67 1.59-1 2.53-.9l2.64.27a3.12 3.12 0 003.37-2.45l.24-1.11a3.124 3.124 0 012.47-2.41l4.04-.75z'
        />
        <g fill='#BCEBDA'>
          <path d='M103.65 56.89c-.31-.46-.6-.93-.84-1.44l-.01-.02a2.976 2.976 0 00-2.32-1.64c.86 1.22 1.94 2.26 3.17 3.1zM88.43 91.46a3.124 3.124 0 002.54-2.83l.28-3.62c.1-1.34 1.05-2.46 2.35-2.79l3.15-.8a3.124 3.124 0 002.29-2.38l.91-4.29a3.13 3.13 0 013.02-2.48l1.93-.02c1.45-.02 2.7-1.03 3.01-2.44l.45-2.02c.12-.56.38-1.09.79-1.48.63-.61.98-1.45.96-2.33l-.01-.29a3.018 3.018 0 00-1.69-2.64c-.58-.29-1.13-.62-1.66-.99.01.11.03.21.03.32l.01.29c.02.87-.33 1.72-.96 2.33-.41.4-.67.92-.79 1.48l-.45 2.02a3.13 3.13 0 01-3.01 2.44l-1.93.02a3.13 3.13 0 00-3.02 2.48l-.91 4.29a3.11 3.11 0 01-2.29 2.38l-3.15.8c-1.3.33-2.25 1.45-2.35 2.79l-.28 3.62a3.124 3.124 0 01-2.54 2.83l-4.02.76a3.11 3.11 0 00-2.47 2.41l-.24 1.11a3.127 3.127 0 01-3.37 2.45l-2.64-.27c-.94-.1-1.86.23-2.53.9l-3.48 3.48a3.126 3.126 0 01-4.42 0l3.32 3.32a3.126 3.126 0 004.42 0l3.48-3.48c.67-.67 1.59-1 2.53-.9l2.64.27a3.12 3.12 0 003.37-2.45l.24-1.11a3.124 3.124 0 012.47-2.41l4.02-.77z' />
        </g>
        <path
          fill='#FFF'
          d='M69.55 82.25l-1 .35c-1.4.49-2.51 1.89-2.56 3.26l-.1 2.27c-.03.8-.44 1.66-1.1 2.32l-3.48 3.48c-.25.25-.44.52-.61.79l.08-.02c.6-.18 1.17-.07 1.53.29l1.6 1.6.46.46.03-.03 3.48-3.48c.67-.67 1.07-1.52 1.1-2.32l.1-2.27c.06-1.36 1.16-2.77 2.56-3.26l1-.35c1.11-.39 2.06-1.36 2.42-2.47l1.17-3.61c.4-1.25 1.55-2.3 2.79-2.57l3.2-.7c1.18-.26 2.28-1.23 2.73-2.4l1.09-2.85c.4-1.05 1.32-1.94 2.37-2.3l3.86-1.33c1.29-.44 2.35-1.67 2.55-2.95l.26-1.68c.2-1.27 1.24-2.48 2.51-2.94l1.82-.65c.52-.18 1.01-.5 1.43-.92.61-.61 1.37-1.01 2.12-1.1l.27-.03h.08c-.45-.57-.82-1.22-1.09-1.94l-.01-.01c-.3-.81-1.1-1.25-2.07-1.13l-.27.03c-.75.09-1.51.49-2.12 1.1-.42.41-.91.73-1.43.92l-1.82.65c-1.27.46-2.32 1.67-2.51 2.94l-.26 1.68c-.2 1.28-1.26 2.51-2.55 2.95l-3.86 1.33c-1.05.36-1.97 1.26-2.37 2.3l-1.09 2.85c-.45 1.18-1.55 2.15-2.73 2.4l-3.2.7c-1.24.27-2.39 1.33-2.79 2.57l-1.17 3.61c-.36 1.1-1.31 2.07-2.42 2.46z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M88.43 91.46a3.124 3.124 0 002.54-2.83l.28-3.62c.1-1.34 1.05-2.46 2.35-2.79l3.15-.8a3.124 3.124 0 002.29-2.38l.91-4.29a3.13 3.13 0 013.02-2.48l1.93-.02c1.45-.02 2.7-1.03 3.01-2.44l.45-2.02c.12-.56.38-1.09.79-1.48.63-.61.98-1.45.96-2.33l-.01-.29a3.018 3.018 0 00-1.69-2.64h0c-2.43-1.2-4.4-3.17-5.6-5.6l-.01-.02c-.49-1-1.5-1.64-2.62-1.67l-.31-.01c-.86-.02-1.68.32-2.29.92-.42.41-.94.7-1.52.83l-2.02.45a3.13 3.13 0 00-2.44 3.01l-.02 1.93a3.13 3.13 0 01-2.48 3.02l-4.29.91a3.11 3.11 0 00-2.38 2.29l-.8 3.15a3.122 3.122 0 01-2.79 2.35l-3.62.28a3.124 3.124 0 00-2.83 2.54l-.76 4.02a3.11 3.11 0 01-2.41 2.47l-1.11.24a3.127 3.127 0 00-2.45 3.37l.27 2.64c.1.94-.23 1.87-.9 2.53l-3.48 3.48a3.126 3.126 0 000 4.42l3.69 3.69a3.126 3.126 0 004.42 0l3.48-3.48c.67-.67 1.59-1 2.53-.9l2.64.27a3.12 3.12 0 003.37-2.45l.24-1.11a3.124 3.124 0 012.47-2.41l4.04-.75z'
        />
      </g>
      <g>
        <path
          fill='#FFF'
          d='M109.51 50.11c.47-1.62 1.93-3.13 3.56-3.69l1.05-.36c.58-.2 1.12-.12 1.45.21l-.64-.64-.72-.72-1.46-1.46c-.33-.33-.87-.41-1.45-.21l-1.05.36c-1.63.55-3.09 2.06-3.56 3.69l-.23.8c-.16.55-.07 1.05.24 1.36l1.49 1.49.72.72.6.6c-.31-.31-.4-.81-.24-1.36l.24-.79z'
        />
        <path
          fill='#BCEBDA'
          d='M119.65 53.01l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-.12-.12c.48.48.7 1.17.6 1.84l-.19 1.21a4.214 4.214 0 01-3.72 3.53l-.93.1a2.13 2.13 0 01-1.73-.61l.07.07 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.21 3.42-1.65 3.72-3.53z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M119.65 53.01l.19-1.21c.11-.67-.11-1.36-.6-1.84l-2.14-2.14-1.05-1.05-2.14-2.14a2.16 2.16 0 00-1.84-.6l-1.21.19c-1.88.3-3.33 1.82-3.53 3.72l-.1.93c-.07.64.16 1.28.61 1.73l2.19 2.19 1.05 1.05 2.19 2.19c.46.46 1.09.68 1.73.61l.93-.1c1.9-.21 3.42-1.65 3.72-3.53z'
        />
      </g>
      <g>
        <path
          fill='#EBFFF8'
          d='M116.34 47.77a8.207 8.207 0 01-1.73-2.56l-1.9-4.43a4.52 4.52 0 01.06-3.69 4.516 4.516 0 012.79-2.41l4.01-1.21c2.36-.71 4.88.63 5.61 2.98.37 1.18 1.29 2.1 2.46 2.46 2.35.73 3.69 3.25 2.98 5.61l-1.21 4.01a4.516 4.516 0 01-6.1 2.85l-4.43-1.9c-.95-.4-1.81-.98-2.54-1.71z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M116.34 47.77a8.207 8.207 0 01-1.73-2.56l-1.9-4.43a4.52 4.52 0 01.06-3.69 4.516 4.516 0 012.79-2.41l4.01-1.21c2.36-.71 4.88.63 5.61 2.98.37 1.18 1.29 2.1 2.46 2.46 2.35.73 3.69 3.25 2.98 5.61l-1.21 4.01a4.516 4.516 0 01-6.1 2.85l-4.43-1.9c-.95-.4-1.81-.98-2.54-1.71z'
        />
      </g>
    </svg>
  );
}

export default SvgBossKeyBoneyard;
