import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Mammoth = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.04c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path d='M128.08 199.51c-.65-.9-1.04-1.96-1.22-3.04a9.69 9.69 0 0 1 .03-3.31c.22-1.11.61-2.23 1.41-3.26.39-.52.92-.97 1.56-1.31.31-.17.68-.25 1.02-.36.35-.06.71-.07 1.06-.08.79-.01 1.43.63 1.44 1.42 0 .7-.5 1.29-1.16 1.41l-.27.05c-.57.11-1.01.28-1.55.74-.52.46-.96 1.18-1.25 1.98-.58 1.6-.75 3.54-.09 5.18l.01.03c.12.29-.02.62-.31.74-.26.11-.54.02-.68-.19z' />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M126.94 200.98c-8.64-2.04-19.68.24-19.68.24 4.29.37 7.9 5.63 7.68 5.52-3.47-1.82-11.64-3.24-27.36 1.44 0 0 10.17 1.75 13.92 6.36.16.2-15.54-2.84-29.64 7.32 0 0 9.84-.37 13.08 2 .41.3.2.96-.32.94-4.1-.12-14.91-1.64-25.97 14.82 0 0 6.38-4.03 15.28-1.27.17.05.13.3-.04.3-2.55.08-8.5-1.71-15.47 10.2-3.72 6.36-10.08 7.08-10.08 7.08s3.65 8.04 10.04 6.14c6.41-1.91-7.9 3.66-9.8 20.62 0 0 3.62-5.01 12.46-5.01 0 0 1.1 6.21-7.3 14.01 0 0 19.2 5.76 24.24 1.32'
    />
    <path
      fill={props.renderer.color1}
      d='M137.02 152.14c-9.72.84-11.76 9-11.76 9s5.4-2.16 5.88-2.04c1.08.27-8.52-.96-7.92 18 0 0 3.36-.72 7.08-1.92'
    />
    <path d='M131.63 158.82c.79.47-.87.94-.87.94-2.09.49-3.76 2.16-4.72 4.12-1 1.96-1.44 4.2-1.61 6.43-.16 2.24.57 4.69.57 4.69s3.7-.88 4.84-1.25h.01c.79-.25 1.63.18 1.89.97.25.79-.18 1.63-.97 1.89-1.19.38-9.09 2.37-9.09 2.37s1.33-12.98 3.28-15.69c1.05-1.29-1.52 1.52-1.52 1.52s1.57-11.8 13.46-14.16c.83-.07 1.55.54 1.62 1.37s-.54 1.55-1.37 1.62c-2.39.21-4.69.96-6.53 2.36 0 0-2.69 2.17-4.16 4.59 0 0 3.54-2.59 5.17-1.77z' />
    <path d='M127.69 170.77c-.69-2.77 2.78-8.13 3.51-8.73-5.2 3.96-4.75 9.76-4.71 9.84.13.3.48.44.78.31 0 0 4.63-1.41 7.17-1.51-1.22-.43-6.43-.03-6.75.09z' />
    <path
      fill='none'
      d='M92.2 225.28s-3.67-6.23-11.76-8.41c0 0 5.4-1.56 11.49 1.94 0 0-3.17-4.32-5.6-5.28M66.71 272.78s-8.66-.81-14.09.77c0 0 6.17-5.54 11.86-6.29M60.78 289.74s11.62 2.37 15.73-3.99'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Mammoth;
