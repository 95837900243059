import { SvgEditionSlot } from '../part';
import * as React from 'react';

const RobotPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#b2b8d1'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M176.59 214.33s-24.81-5.08-39.09-20.66l-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75l32.79 20.2h55.25c-.24-1.25-.41-2.52-.41-3.56 0-11.06 8.97-20.31 20.03-20.03 21.46.53 19.82 22.67 19.82 22.67v.53c1.34-3.91 11.19-35.83-16-48.54z'
    />
    <path
      fill='none'
      stroke='#5d6894'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m81 233 9-5 43-24M149 216l-55 30'
    />
    <path
      fill='#e9eaf0'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path
      fill='#60698c'
      d='M106.35 231.46c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4zM117.77 225.23c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM129.19 219c2.57 0 2.58-4 0-4s-2.58 4 0 4z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M267.13 217.97s-5.46-2.07-7.15 5.46M265.25 214.2s-5.46-2.07-7.15 5.46'
    />
    <path fill='#60698c' d='M108 250h38v13h-38z' />
    <path
      fill='#e9eaf0'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M60.45 272.12c1.85 10.07 5.1 15.6 7.67 18.55 1.75 2.01 4.3 3.12 6.96 3.12h32.75v-1.28c0-6.08-3.33-12.12-12.93-13.25'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M101.68 292.52c.16-4.96-6.75-8.63-7.34-8.73M94.99 292.52c0-3.56-1.4-6.02-3.78-7.45'
    />
    <path
      fill='#b2b8d1'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M242.33 203.77c-12.33 7.64-28.41 12.88-46.01 12.88-30.16 0-55.87-15.32-65.83-31.89-2.14 1.76-4.54 4.23-5.11 5.58-6.79 16.02 56.59 59.06 118.12 26.46 2.66-1.41 4.24-4.21 3.15-7.01-.73-1.87-2.45-4.7-4.32-6.02z'
    />
    <path
      fill='#e9eaf0'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.92 19.53c.89 3.34 3.98 5.67 7.51 5.67l23.86-.57c0-6.08-4.95-11.72-11.18-11.72'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M186.12 292.52c-.29-6.57-5.22-9.83-10.83-9.83'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M180.88 292.52c0-5.61-3.77-9.19-9.37-9.19'
    />
    <path
      fill='#b2b8d1'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M167.44 234.74c-16.61 3.12-27.54 19.11-24.42 35.72l60.14-11.3c-3.12-16.61-19.11-27.54-35.72-24.42z'
    />
    <circle
      transform='rotate(-39.929 173.01 263.16)'
      fill='#e9eaf0'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      cx={173}
      cy={263}
      r={20}
    />
    <path
      fill='#60698c'
      d='M167.73 249.81c-6.27 2.51-10.09 9.17-8.79 16.05 1.31 6.88 7.31 11.68 14.06 11.72l-5.27-27.77zM173.18 248.78l5.27 27.77c6.27-2.51 10.09-9.17 8.79-16.05-1.31-6.89-7.31-11.68-14.06-11.72z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M109 250h36M113 257h30'
    />
    <circle cx={172} cy={263} r={6} />
    <path
      fill='none'
      stroke='#00ff28'
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M178.34 263.01c-1.79.36-1.45 2.15-3.24 2.51-1.79.36-2.14-1.44-3.93-1.08-1.8.36-1.45 2.15-3.24 2.51M177.02 259.02c-1.76.36-1.43 2.15-3.2 2.51-1.76.36-2.11-1.44-3.87-1.08-1.78.36-1.43 2.15-3.2 2.51'
    />
    <circle
      fill='none'
      stroke='#60698c'
      strokeWidth={2}
      strokeMiterlimit={10}
      cx={172}
      cy={263}
      r={6}
    />
    <path
      fill='#60698c'
      d='M196.36 209.3c-35.27 0-64.36-16.82-68.62-32.1-.36 1.21-.56 2.44-.56 3.7 0 16.13 30.97 35.87 69.18 35.87 38.2 0 69.18-19.74 69.18-35.87 0-1.25-.19-2.49-.56-3.7-4.27 15.28-33.35 32.1-68.62 32.1z'
    />
    <path
      fill='#b2b8d1'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M94.32 232.76c-17.1-5.99-35.82 3.02-41.81 20.12l61.93 21.69c5.99-17.1-3.02-35.82-20.12-41.81z'
    />
    <circle
      fill='#e9eaf0'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      cx={83}
      cy={263}
      r={24}
    />
    <path
      fill='#60698c'
      d='M85.85 247.21c-7.74-1.12-15.44 3.34-18.12 11-2.68 7.66.55 15.95 7.3 19.91l10.82-30.91zM91.92 249.34l-10.82 30.9c7.74 1.12 15.44-3.34 18.12-11 2.69-7.66-.55-15.95-7.3-19.9z'
    />
    <circle cx={83} cy={263} r={7} />
    <path
      fill='none'
      stroke='#00ff28'
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M88.4 267.02c-1.71-.74-2.44.94-4.15.2s-.99-2.42-2.69-3.16c-1.72-.75-2.44.94-4.15.2M89.64 262.54c-1.71-.74-2.44.94-4.15.2s-.99-2.42-2.69-3.16c-1.72-.75-2.44.94-4.15.2'
    />
    <circle
      fill='none'
      stroke='#60698c'
      strokeWidth={2}
      strokeMiterlimit={10}
      cx={83}
      cy={263}
      r={7}
    />
    <path
      fill='none'
      stroke='#00ff28'
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m143 195-3 5M163 205l-2 5M185 209l-1 5M207 208l1 7M229 204l2 6M250 195l2 4'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196.36 209.3c-35.27 0-64.36-16.01-68.62-31.29-.36 1.21-.56 1.63-.56 2.89 0 16.13 30.97 35.87 69.18 35.87 38.2 0 69.18-19.74 69.18-35.87 0-1.25-.19-2.49-.56-3.7-4.27 15.28-33.35 32.1-68.62 32.1z'
    />
  </svg>
);

export default RobotPower;
