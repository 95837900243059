import React from 'react';

const SvgIconCircleChecked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
      focusable='false'
      viewBox='0 0 24 24'
      aria-hidden='true'
      data-testid='CheckCircleIcon'
      fill='currentColor'
      {...props}>
      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'></path>
    </svg>
  );
};

export default SvgIconCircleChecked;
