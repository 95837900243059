import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import {
  FurballRenderDisabledSlots,
  IBodyPart,
  IRenderFurball,
} from '../../Furballs/FurballRenderer';
import { IRenderPart } from '../../../assets/edition0/part';
import {
  renderEdition0,
  edition0SlotNames,
  edition0PaletteColors,
} from '../../../assets/edition0';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  slotLayer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    top: 0,
    right: 0,
  },
}));

type Props = IRenderFurball & {
  size: number | string;
  className?: string;
  disableSlots?: FurballRenderDisabledSlots;
};

export const Edition0Renderer: FunctionComponent<Props> = (props) => {
  const { slots, paletteIndex, className, size } = props;
  const disableSlotNames = props.disableSlots ?? {};
  const classes = useStyles();
  const style = { width: size, height: size };

  function renderable(bp: IBodyPart, paletteIndex: number): IRenderPart {
    return {
      partNumber: bp.partNumber,
      color1: edition0PaletteColors[paletteIndex * 2],
      color2: edition0PaletteColors[paletteIndex * 2 + 1],
    };
  }

  function renderPart(bp: IBodyPart): React.ReactNode {
    if (disableSlotNames[bp.slot]) return null;
    const partRenderer = renderable(bp, paletteIndex);
    const slotIdx = edition0SlotNames.indexOf(bp.slot);
    return (
      <span key={bp.slot} className={classes.slotLayer} style={style}>
        {renderEdition0(slotIdx, partRenderer)}
      </span>
    );
  }

  return (
    <div className={clsx(classes.root, className)} style={style}>
      {slots.map(renderPart)}
    </div>
  );
};

export default Edition0Renderer;

// const palettes = [
//   '#97c7cf', '#63a8b5',
// '#f0e7ea', '#f4d6fd',
// '#c6c6ff', '#ffc9fd',
// '#dd9962', '#f5d0b3',
// '#ffdd5e', '#FFF2BD',
// '#FF96E5', '#FFC7EB',
// '#65e8fb', '#c1f3fb',
// '#FFB569', '#FFDA99',
// '#70737c', '#a4a9b5',
// '#afe39d', '#d4ffc5',
// '#f2Dbbd', '#fff6ec',
// '#f2afa4', '#f2d4bf',
// '#f2a3c8', '#f2cee4',
// '#cea393', '#f0d2c7',
// '#B594F2', '#DAC7FF',
// '#6cc4cf', '#e9bbf2',
// '#84b5ff', '#ffefc2',
// '#e3eefc', '#bfcffa',
// '#81DE91', '#B9EDC0',
// '#c9b5a3', '#e8dcd1',
// '#e9af6e', '#fdd8b0',
// '#edeae4', '#d1cec6',
// '#966548', '#BF9276',
// '#95a72f', '#c6d55c',
// '#ffb0ff', '#ffcf80',
// '#ff6b6c', '#ff9c9c',
// '#E0AE45', '#F7D280',
// '#ebe4d8', '#E3DC9C',
// '#b4ffc6', '#e8ff9c',
// '#5c985c', '#a7ac73',
//   ];
