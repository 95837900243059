import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import SvgRedX01 from '../assets/SvgRedX';
import OkDialog from '../components/OkDialog';
import { NetworkType } from '../wallet/WalletTypes';
import WalletContext from '../wallet/WalletContext';

type Props = {
  error?: string;
  onClose: () => void;
};

const BlockchainBannerDialog: FunctionComponent<Props> = (props) => {
  const { error, onClose } = props;
  const context = React.useContext(WalletContext);
  const network = context?.network;
  const name = network?.name;
  const type = network?.type;
  const mismatch = network?.subdomainMismatch;

  function getTitle(): string {
    return 'Wrong blockchain!';
  }

  function mismatchToString(): string {
    if (mismatch) {
      if (['www', 'furballs', 'prod'].includes(mismatch)) {
        return 'ethereum mainnet';
      } else if (mismatch === 'localhost') {
        return 'goerli testnet';
      }
      return mismatch + ' testnet';
    }
    return '';
  }

  function getBody(): string {
    if (error) {
      return type === NetworkType.MainNet
        ? 'Your wallet is connected to ' +
            name +
            ' mainnet and needs to be connected to ' +
            mismatchToString() +
            ' to proceed.'
        : 'Your wallet is connected to ' +
            name +
            ' testnet and needs to be connected to ' +
            mismatchToString() +
            ' to proceed.';
    }
    return '';
  }

  return (
    <OkDialog
      title={error ? getTitle() : ''}
      open={!!error}
      topRight={false}
      blockchainBanner={true}
      titleBg='#1A0273'
      onClose={onClose}>
      {error && (
        <Grid
          item
          xs={12}
          lg={6}
          style={{ textAlign: 'center', maxWidth: 400, paddingTop: 25 }}>
          <SvgRedX01 style={{ maxWidth: 100, maxHeight: 100 }}></SvgRedX01>
          <Typography
            variant='h3'
            style={{ fontWeight: 'bolder', paddingBottom: 10, paddingTop: 10 }}>
            Oopsie Poopsies!
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingLeft: 50, paddingRight: 50 }}>
            {getBody()}
          </Typography>
        </Grid>
      )}
    </OkDialog>
  );
};

export default BlockchainBannerDialog;
