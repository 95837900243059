import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, SwipeableDrawer, Theme } from '@mui/material';
import SvgDrawerOpen from '../../assets/SvgDrawerOpen';
import { yellow } from '../../themev2';
import SvgDrawerClose from '../../assets/SvgDrawerClose';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: `${yellow} !important`,
    width: '340px',
    marginTop: theme.mixins.toolbar.height,
    padding: '15px 0',
    overflow: 'initial !important',
  },
  drawerCont: {
    position: 'fixed',
    height: '100vh',
    minWidth: '8px !important',
    backgroundColor: `${yellow} !important`,
    zIndex: 1000,
  },
  content: {
    padding: '40px 0',
    overflowY: 'auto',
    maxHeight: '100vh',
  },
  closedStateButton: {
    border: 'none !important',
    left: '-3px',
    top: '22.5px',
  },
  openStateButton: {
    right: '-50px',
    top: '47.5px',
    justifyContent: 'flex-end',
    border: 'none !important',
    ['@media (max-width:380px)']: {
      display: 'none !important',
    },
  },
}));

interface OwnProps {
  drawerContent: React.ReactNode;
}

type Props = OwnProps;

const MobileDrawer: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    return history.listen(() => closeSkillsDrawerOnNewFurball());
  }, []);

  function closeSkillsDrawerOnNewFurball() {
    const path = location.pathname;
    if (path.includes('/skills/0x')) {
      setIsOpen(false);
    }
  }

  function renderButton() {
    return (
      <Button
        variant={'text'}
        className={isOpen ? classes.openStateButton : classes.closedStateButton}
        sx={{
          position: 'absolute',
          '&.MuiButtonBase-root:hover': {
            bgcolor: 'transparent',
          },
        }}
        disableRipple
        onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <SvgDrawerClose width={48} height={48} />
        ) : (
          <SvgDrawerOpen width={48} height={48} />
        )}
      </Button>
    );
  }

  function renderClosedDrawer() {
    return (
      <div className={classes.drawerCont}>{!isOpen && renderButton()}</div>
    );
  }

  return (
    <>
      <SwipeableDrawer
        anchor={'left'}
        open={isOpen}
        disableSwipeToOpen
        classes={{ paper: classes.paper }}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}>
        <div className={classes.content}>
          {renderButton()}
          {props.drawerContent}
        </div>
      </SwipeableDrawer>
      {renderClosedDrawer()}
    </>
  );
};

export default MobileDrawer;
