import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Tooltip, Typography } from '@mui/material';

import clsx from 'clsx';
import Carousel from '../../components/Carousel';
import { EntitySkill, Specialization } from '../../components/schema';
import SvgRedo from '../../assets/SvgRedo';
import { getSkillDefinitionById } from './SkillLibrary';
import { cdnRoot } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  tabBox: {
    backgroundColor: '#3832A0',
    border: '2px solid #000',
    width: '100%',
    height: '100%',
    borderRadius: '18px',
    boxShadow: '0 0 10px 0 #0000002c',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5rem',
    padding: '0 0.5rem',
    position: 'relative',
    transition: 'all 0.3s linear',

    [theme.breakpoints.down('sm')]: {
      width: '220px !important',
    },
  },

  tabBoxHighlighted: {
    borderColor: '#7DF5B9',
    boxShadow: 'inset 0 0 0 3px #29258F, 0 0 0 1px #7DF5B9',
    transition: 'all 0.2s linear',
  },

  tabLogo: {
    width: '3.7rem',
    height: '3.7rem',
    // backgroundColor: theme.palette.common.white,
    borderRadius: '100%',
    display: 'grid',
    placeItems: 'center',
  },

  tabText: {
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: '100% !important',
    color: '#ececec',
    fontSize: '0.95rem !important',
  },

  skillIcon: {
    width: '100%',
    height: '100%',
    position: 'relative',
    top: '0.8rem',
    transform: 'scale(1.8)',
  },

  rerollContainer: {
    position: 'absolute',
    bottom: '0',
    right: '-0.75rem',

    '&[data-disabled="true"]': {
      filter: 'grayScale(70%)',
      pointerEvents: 'none',
    },
  },

  rerollButton: {
    width: '1.5rem !important',
    height: '1.5rem !important',
    minWidth: 'unset !important',
    borderRadius: '100% !important',
    padding: '0 !important',
    backgroundColor: '#3832A0 !important',
    borderColor: '#7DF5B9 !important',
    display: 'grid !important',
    placeItems: 'center !important',
  },
}));

interface IProps {
  skills: EntitySkill[];
  currentTab: number;
  disabled: boolean;
  showReroll: boolean;
  isRented?: boolean;
  toggleReroll: () => void;
  setCurrentTab: (index: number) => void;
  specialization: Specialization;
}

export function isSkillUpgradeable(
  slotIndex: number,
  spec?: Specialization,
): boolean {
  if (slotIndex === 2) return true;
  if (!spec) return false;
  if (
    slotIndex === 1 &&
    [Specialization.Offense, Specialization.Hero].includes(spec)
  )
    return true;
  if (
    slotIndex === 0 &&
    [Specialization.Defense, Specialization.Hero].includes(spec)
  )
    return true;
  return false;
}

export const SkillsCarousel: FunctionComponent<IProps> = ({
  skills,
  currentTab,
  setCurrentTab,
  toggleReroll,
  showReroll,
  disabled,
  isRented,
  specialization,
}) => {
  const classes = useStyles();

  return (
    <>
      <Carousel
        initialIndex={currentTab}
        showArrows={!!skills}
        disabled={disabled}
        gap={55}
        visibleAtOnce={1}
        scaleCurrentSlide={false}
        onSlideChange={(index) => setCurrentTab(index)}>
        {skills?.map((skill, index) => {
          const def = getSkillDefinitionById(skill.skillDefinitionId);
          return (
            <div
              key={index}
              className={clsx(
                classes.tabBox,
                index === currentTab && classes.tabBoxHighlighted,
              )}>
              <div className={classes.tabLogo}>
                <img
                  className={classes.skillIcon}
                  src={`${cdnRoot}/images/icons/skills/${def?.icon}.png`}
                  alt={def?.name}
                />
              </div>

              <Typography
                className={classes.tabText}
                variant='h4'
                style={{
                  fontSize: (def?.name ?? '').length < 8 ? '1.1rem' : '0.85rem',
                }}>
                {def?.name}
              </Typography>

              {isSkillUpgradeable(skill.slotIndex, specialization) &&
                !isRented && (
                  <div
                    className={classes.rerollContainer}
                    data-disabled={disabled}>
                    <Tooltip
                      hidden={isRented}
                      title={showReroll ? 'Cancel Reroll' : 'Reroll skill'}>
                      <Button
                        variant='contained'
                        disabled={disabled}
                        className={classes.rerollButton}
                        onClick={toggleReroll}>
                        {showReroll ? (
                          <Typography
                            marginTop='0.05rem'
                            lineHeight='1'
                            fontSize='0.7rem'
                            color='#7DF5B9'>
                            &#x2715;
                          </Typography>
                        ) : (
                          <SvgRedo
                            width='0.6rem'
                            height='0.6rem'
                            fill='#7DF5B9'
                          />
                        )}
                      </Button>
                    </Tooltip>
                  </div>
                )}
            </div>
          );
        })}
        {skills.length < 3 && (
          <div
            className={clsx(
              classes.tabBox,
              currentTab === 2 && classes.tabBoxHighlighted,
            )}>
            <div className={classes.tabLogo}>
              <img
                className={classes.skillIcon}
                src={`${cdnRoot}/svgs/roll-skill.svg`}
                alt='roll a skill'
              />
            </div>

            <Typography className={classes.tabText} variant='h4'>
              ???
            </Typography>
          </div>
        )}
      </Carousel>
    </>
  );
};

export default SkillsCarousel;
