import * as React from 'react';
import TicketPurchaseForm from '../../pages/Tickets/TicketPurchaseForm';
import SvgIconTix from '../../assets/SvgIconTix';
import CurrencyCard, { ICurrencyCardChoice } from './CurrencyCard';
import WalletContext from '../../wallet/WalletContext';
import { useFurverseContext } from '../../pages/Furverse/Furverse';

interface ITixCard {}

const choiceUsd: ICurrencyCardChoice = {
  title: 'With USD',
  render: () => <TicketPurchaseForm currency={'USD'} />,
};

const choiceEth: ICurrencyCardChoice = {
  title: 'With ETH',
  render: () => <TicketPurchaseForm currency={'ETH'} />,
};

const TixCard: React.FunctionComponent<ITixCard> = (props) => {
  const context = React.useContext(WalletContext);
  const { vuplexReady } = useFurverseContext();
  const choices =
    vuplexReady || !context ? [choiceUsd] : [choiceEth, choiceUsd];

  return (
    <CurrencyCard
      title={'Buy TIX'}
      icon={<SvgIconTix width={32} height={32} />}
      // renderChoice={(c) => <TicketPurchaseForm currency={c === 'ETH' ? 'ETH' : 'USD'} />}
      choices={choices}
    />
  );
};

export default TixCard;
