import * as React from 'react';
import { IPurchasedTickets } from './TicketPurchaseForm';
import TicketPurchaseStripe from './Stripe/TicketPurchaseStripe';
import { Container } from '@mui/material';

type ITicketPurchaseCreditCard = IPurchasedTickets;

const TicketPurchaseCreditCard: React.FunctionComponent<
  ITicketPurchaseCreditCard
> = (props) => {
  return (
    <Container>
      <TicketPurchaseStripe {...props} />
    </Container>
  );
};

export default TicketPurchaseCreditCard;
