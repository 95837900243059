import { SvgEditionSlot } from '../part';
import * as React from 'react';

const KillerPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M224.16 205.09s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      fill='#3e485c'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M242.59 204.51c-12.33 7.64-28.41 12.88-46.01 12.88-30.16 0-55.11-13.6-67.54-34.68-.54.44-4.14 4.89.75 16.56 12.45 29.68 66.64 31.91 77.55 68.8.01-.01 39.54-40.57 35.25-63.56z'
    />
    <path
      fill='#ebfff8'
      d='M221.1 232.46c-.15-1.18-.85-2.26-2.02-3.12-.48-.35-.83-.83-1.02-1.38-1.24-3.66-4.92-6.12-9.15-6.12-.48 0-.97.03-1.45.1-4.74.64-8.38 4.26-8.64 8.61a2.89 2.89 0 0 1-.62 1.6c-.9 1.14-1.29 2.36-1.13 3.54a3.35 3.35 0 0 0 3.31 2.9c.15 0 1.08-.05 1.08-.05l.06.52c.11.94.9 1.64 1.84 1.64.01 0 .35-.04.9-.12l-.31-2.29c-.06-.46.33-.9.88-.97s1.04.24 1.1.7l.31 2.3c.32-.04.65-.09 1-.13l-.31-2.28c-.06-.46.33-.9.88-.97.55-.07 1.04.24 1.1.7l.31 2.29c.33-.04.67-.09 1-.13l-.31-2.27c-.06-.46.33-.9.88-.97.55-.07 1.04.24 1.1.7l.31 2.28c.35-.05.69-.09 1-.13l-.31-2.26c-.06-.46.33-.9.88-.97s1.04.24 1.1.7l.31 2.27c.17-.02.26-.04.26-.04.5-.06.94-.32 1.24-.72.3-.4.42-.89.35-1.39l-.02-.59 1.22-.16c1.83-.28 3.12-1.96 2.87-3.79z'
    />
    <path
      fill='#ebfff8'
      d='M223.41 222.49c-.22-.28-.54-.46-.87-.51a.338.338 0 0 1-.29-.38c.03-.33-.05-.68-.26-.97-.45-.61-1.34-.75-1.95-.3-.63.46-.76 1.35-.29 1.97.02.02-23.15 17.65-23.16 17.63-.46-.61-1.34-.73-1.94-.25-.58.46-.68 1.35-.22 1.93.22.28.53.45.86.5.19.03.31.19.29.38-.04.33.04.67.25.96.44.6 1.32.74 1.92.3.62-.46.74-1.33.28-1.94-.02-.01 23.17-17.63 23.18-17.61.47.62 1.36.73 1.97.25.6-.47.7-1.37.23-1.96z'
    />
    <path
      fill='#ebfff8'
      d='M224.65 235.67c-.71-.31-1.53.02-1.83.73l-26.98-10.94.03-.06a1.37 1.37 0 0 0-.77-1.8c-.69-.27-1.51.09-1.78.78-.13.33-.12.68 0 .99.07.17-.01.36-.18.44-.3.13-.56.38-.7.7-.3.68.03 1.51.71 1.81.71.3 1.52-.03 1.81-.74.01-.02.02-.04.02-.07 0 0 26.96 10.99 26.95 11.02-.3.72.03 1.66.75 1.95.71.28 1.53-.09 1.81-.8.13-.34.12-.69 0-1.01a.35.35 0 0 1 .18-.45c.31-.14.57-.38.71-.72.3-.69-.03-1.54-.73-1.83z'
    />
    <circle fill='#3e485c' cx={213} cy={229} r={3} />
    <circle fill='#3e485c' cx={204} cy={230} r={3} />
    <path
      fill='#3e485c'
      d='m207.92 231.61-1.04 2.65c-.16.41.3.78.67.54l.93-.6a.46.46 0 0 1 .38-.05l1.06.34c.42.13.76-.35.5-.7l-1.71-2.28a.455.455 0 0 0-.79.1z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M129.04 186.09s-5.65 21.46 53.08 39.53M242.53 210.4s-.03 4.8-12.76 9.3'
    />
  </svg>
);

export default KillerPower;
