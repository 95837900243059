import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Angry = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='m185.45 125.61-11.58-8.68 13.5 5.22a1.994 1.994 0 1 1-1.44 3.72 2.23 2.23 0 0 1-.48-.26zM207.2 122.52l10.67-5.6-8.28 8.75c-.76.81-2.03.84-2.84.08s-.84-2.03-.08-2.84c.16-.15.34-.29.53-.39z' />
    <ellipse opacity={0.13} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <linearGradient
      id='Angry__a'
      gradientUnits='userSpaceOnUse'
      x1={239}
      y1={191}
      x2={239}
      y2={223}
      gradientTransform='matrix(1 0 0 -1 0 350)'>
      <stop
        offset={0.062}
        style={{
          stopColor: '#dfd0ff',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#ff8787',
        }}
      />
    </linearGradient>
    <circle fill='url(#Angry__a)' cx={239} cy={142} r={15} />
    <circle cx={239} cy={142} r={11} />
    <path
      fill='#FFF'
      d='M249.29 127.44c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09a23.34 23.34 0 0 0-5.29-9.46z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <ellipse opacity={0.13} cx={163} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={160} cy={143} r={23} />
    <linearGradient
      id='Angry__b'
      gradientUnits='userSpaceOnUse'
      x1={168}
      y1={191}
      x2={168}
      y2={223}
      gradientTransform='matrix(1 0 0 -1 0 350)'>
      <stop
        offset={0.062}
        style={{
          stopColor: '#dfd0ff',
        }}
      />
      <stop
        offset={0.512}
        style={{
          stopColor: '#ff8787',
        }}
      />
    </linearGradient>
    <circle fill='url(#Angry__b)' cx={168} cy={142} r={15} />
    <circle cx={168} cy={142} r={11} />
    <path
      fill='#FFF'
      d='M177.69 127.44c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09-1-3.57-2.84-6.8-5.29-9.46z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={160}
      cy={143}
      r={23}
    />
  </svg>
);

export default Angry;
