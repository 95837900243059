import * as React from 'react';

function SvgIconCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 5.79 4.01'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      {...props}>
      <path d='M.164 1.624a.564.564 0 01.795 0L1.987 2.65C2.589 2.162 3.803 1.208 5.189.12c.425-.336.825.105.454.466L2.384 3.844a.564.564 0 01-.795 0L.164 2.419a.564.564 0 010-.795z' />
    </svg>
  );
}

export default SvgIconCheck;
