import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme } from '@mui/material';

import { NotificationPurpose } from '../schema';

import NotificationItem from './NotificationItem';

import clsx from 'clsx';

import { useAuthContext } from '../Auth/AuthContext';
import { useFurComponent } from '../../utils';
import { useNotifications } from './NotificationsContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  notificationDrawerInner: {
    overflowY: 'auto',
  },

  readAll: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& button': {
      width: '100% !important',
      height: '45px !important',
      border: 'none !important',
      borderRadius: '0px !important',
    },
  },
}));

interface INotificationList {
  visible?: boolean;
  setVisible?: (v: boolean) => void;
  className?: string;
  maxHeight?: string;
}

const NotificationsList: React.FunctionComponent<INotificationList> = (
  props,
) => {
  const { log } = useFurComponent(NotificationsList);
  const { setVisible, className, maxHeight } = props;
  const visible = props.visible === undefined ? true : props.visible;
  const { isSignedIn, addr } = useAuthContext();
  const classes = useStyles({ visible });
  const { isMutating, markNotificationsAsRead, unreadNotifications } =
    useNotifications();

  async function markAllAsRead() {
    if (setVisible) setVisible(false);

    const ids = unreadNotifications?.map(({ id }) => id) ?? [];
    await markNotificationsAsRead(ids);
  }

  if (!isSignedIn) return null;

  return (
    <>
      <div className={clsx(classes.root, className)}>
        <div className={classes.notificationDrawerInner} style={{ maxHeight }}>
          {unreadNotifications
            ?.filter((item) => {
              if (item.purpose === NotificationPurpose.PendingReward) {
                const hasPendingReward =
                  item.pendingReward && !item.pendingReward.claimedAt;
                return hasPendingReward;
              }

              return true;
            })
            .map((item, index) => (
              <NotificationItem
                key={index}
                notification={item}
                setVisible={setVisible}
                markAsRead={markNotificationsAsRead}
              />
            ))}
        </div>
        {!!unreadNotifications?.length && (
          <div className={classes.readAll}>
            <Button
              variant={'contained'}
              disabled={isMutating}
              onClick={markAllAsRead}>
              Mark all as read
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsList;
