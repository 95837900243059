import React, { FunctionComponent } from 'react';
import {
  Theme,
  Container,
  CardHeader,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { usePageTitle } from '../../Analytics';
import { cdnRoot } from '../../theme';
import useCommonStyles from '../../components/useCommonStyles';
import WalletButton from '../../wallet/WalletButton';
import SpendTixButton from '../../components/Account/SpendTixButton';
import { useFuelFactor } from '../../components/Timekeeper/TimekeeperHooks';
import {
  InventoryDocument,
  ItemsFragment,
  useBuyLootBoxMutation,
} from '../../components/schema';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import InventorySlot from '../../components/Inventory/InventorySlot';
import { Link } from 'react-router-dom';
import AccountHeader from '../../AccountHeader';
import WalletSlice from '../../wallet/WalletSlice';
import { IFurballWalletContents } from '../../wallet/WalletTypes';
import { useApolloClient } from '@apollo/client';
import TimekeeperSlice, {
  useTimekeeperSelector,
} from '../../components/Timekeeper/TimekeeperSlice';
import { useAuthContext } from '../../components/Auth/AuthContext';
import { getTimeBetween } from '../../utils';
import { formatTimeDurationMinutes } from '../../components/BossFights/BossLeaderboards/LeaderboardResetCountdown';
import SpendWFurButton from '../../components/Account/SpendWFurButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    color: 'white',
  },
  swapCard: {
    maxWidth: '466px',
    margin: '8rem auto',
    boxShadow:
      'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important',

    [theme.breakpoints.down('md')]: {
      margin: '1rem auto',
    },
  },
  swapCardContent: {
    minHeight: '304px',
    backgroundColor: 'rgba(233, 233, 255, 1) !important',
    paddingTop: '8px !important',
  },
  buttons: {
    width: '100%',
  },
  rewardSlot: {
    // display: 'block',
  },
  rewardContainer: {
    display: 'grid',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(156px, 1fr))',
  },
}));

interface ILootBox {}

export const LootBox: FunctionComponent<ILootBox> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const fuelCost = 2 * useFuelFactor();
  const wFurCost = 200000;
  const { isSignedIn } = useAuthContext();
  const meta = useTimekeeperSelector((tk) => tk.meta);
  const wallet: IFurballWalletContents | undefined = useWalletSelector(
    (s) => s.contents,
  );
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');
  const [buy, { loading }] = useBuyLootBoxMutation();
  const itemName = 'Loot Box';
  const [rewards, setRewards] = React.useState<ItemsFragment[]>([]);
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const lastLootBoxAt = meta?.lootBox?.myLastPurchaseAt;
  const day = 3600000 * 24;
  const timeElapsed = new Date().getTime() - new Date(lastLootBoxAt).getTime();
  const lootBoxEligible = (!lastLootBoxAt || timeElapsed >= day) && isSignedIn;
  const timeRemaining = getTimeBetween(Math.max(0, (day - timeElapsed) / 1000));
  const remainText = formatTimeDurationMinutes(timeRemaining);

  usePageTitle(itemName);

  async function buyLootBox(useTix: boolean): Promise<void> {
    if (!wallet || !meta) return;

    const { data } = await buy({ variables: { useTix } });
    if (data?.buyLootBox) setRewards(data.buyLootBox.items);
    if (data?.buyLootBox.player) {
      const account = data.buyLootBox?.player;
      dispatch(
        WalletSlice.actions.setWalletContents({
          ...wallet,
          wFur: data.buyLootBox.player.wFur ?? wallet.wFur,
          tix: account?.tix ?? wallet.tix,
        }),
      );
      dispatch(
        TimekeeperSlice.actions.setMeta({
          ...meta,
          lootBox: {
            ...meta.lootBox,
            myLastPurchaseAt: new Date().getTime(),
          },
        }),
      );
      client.refetchQueries({ include: [InventoryDocument] });
    }
  }

  function renderLootBox(): React.ReactNode {
    return (
      <Grid container spacing={2} style={{ paddingTop: 20 }}>
        <Grid item xs={12} lg={4}>
          <img
            src={`${cdnRoot}/images/chest.png`}
            style={{ maxWidth: '100%' }}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Typography variant={'h5'}>Possible Rewards Include...</Typography>
          <Typography variant={'body1'}>
            - Elixirs & Crafting Materials
            <br />
            - Rare / Unique Equipment
            <br />
            - TIX, ETH, or... ???
            <br />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ textAlign: 'center', paddingBottom: 10 }}></Grid>
        {lootBoxEligible && (
          <>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography variant={'h5'}>Choose a Purchase Method:</Typography>
              <Typography variant={'subtitle2'}>
                After each purchase, the loot box will disappear for 24 hours.
              </Typography>
            </Grid>
            <Grid item xs={12} lg={6}>
              {/*<Button*/}
              {/*  variant={'contained'}*/}
              {/*  color={'primary'}*/}
              {/*  className={clsx(common.actionButton, classes.buttons)}*/}
              {/*  disabled={loading || !hasEnoughFur}*/}
              {/*  onClick={() => buyLootBox(false)}>*/}
              {/*  <Typography variant={'h5'}>*/}
              {/*    {wFurCost.toLocaleString()}*/}
              {/*    <SvgIconWrappedFur*/}
              {/*      fill={'#000000'}*/}
              {/*      style={{*/}
              {/*        width: 24,*/}
              {/*        position: 'relative',*/}
              {/*        top: 4,*/}
              {/*        marginRight: 4,*/}
              {/*        marginLeft: 4,*/}
              {/*      }}*/}
              {/*    />*/}
              {/*    wFUR*/}
              {/*  </Typography>*/}
              {/*</Button>*/}
              <SpendWFurButton
                onAccepted={() => buyLootBox(false)}
                itemName={itemName}
                title={''}
                cost={wFurCost}
                isLoading={loading}
                className={classes.buttons}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SpendTixButton
                onAccepted={() => buyLootBox(true)}
                itemName={itemName}
                title={''}
                cost={fuelCost}
                isLoading={loading}
                className={classes.buttons}
              />
            </Grid>
          </>
        )}
        {!lootBoxEligible && (
          <Grid item xs={12}>
            <Typography variant={'h4'}>Time remaining</Typography>
            <Typography variant={'h6'}>{remainText}</Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  function renderRewards(): React.ReactNode {
    return (
      <Grid container>
        {rewards.map((reward) => {
          const def = reward.itemId;
          if (!def) return null;
          // n.b. all chests have only 1 reward...
          return (
            <Grid key={reward.id} item xs={12} style={{}}>
              <Typography variant={'h2'}>Congratulations!</Typography>
              <Typography variant={'h5'}>You've Won:</Typography>
              <div className={classes.rewardContainer}>
                <InventorySlot
                  containerId={playerId}
                  item={reward}
                  className={classes.rewardSlot}
                />
              </div>
              <Typography variant={'h6'}>
                Visit <Link to={'/account'}>My Account</Link> to see your items.
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <div className={clsx(common.fullScreen, common.furBk, classes.root)}>
      <Container>
        <AccountHeader />
        <Card className={clsx(common.cardRoot, classes.swapCard)}>
          <CardHeader
            className={common.cardHeader}
            sx={{ minHeight: '62px' }}
            title={'Daily Loot Box'}
          />
          <WalletButton analyticsPathComponent={'/loot-box'}>
            <CardContent
              className={clsx(common.cardContent, classes.swapCardContent)}>
              {rewards.length === 0 && renderLootBox()}
              {rewards.length > 0 && renderRewards()}
            </CardContent>
          </WalletButton>
        </Card>
      </Container>
    </div>
  );
};

export default LootBox;
