import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SkepticalRed = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.2} cx={158} cy={150} rx={21} ry={22} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <linearGradient
      id='SkepticalRed__a'
      gradientUnits='userSpaceOnUse'
      x1={161}
      y1={159}
      x2={161}
      y2={127}>
      <stop
        offset={0}
        style={{
          stopColor: '#f54a4a',
        }}
      />
      <stop
        offset={0.384}
        style={{
          stopColor: '#fb5391',
        }}
      />
      <stop
        offset={0.764}
        style={{
          stopColor: '#fe896d',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ffa659',
        }}
      />
    </linearGradient>
    <circle fill='url(#SkepticalRed__a)' cx={161} cy={143} r={15} />
    <circle cx={161} cy={143} r={11} />
    <path
      fill='#FFF'
      d='M170.98 128.04c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09a23.34 23.34 0 0 0-5.29-9.46z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <path d='m217.25 119.79 18.42 1.19-17.81 4.85a3.078 3.078 0 1 1-.61-6.04z' />
    <path
      fill='#FFF'
      d='M250.87 131.17c-12.17-3.56-25.31-3.56-37.48 0h-.01c-2.89 3.44-4.59 7.71-4.59 12.33 0 4.37 1.53 8.41 4.13 11.75l18.25-3.29 20.17 3.29c2.6-3.34 4.13-7.39 4.13-11.75-.01-4.62-1.72-8.89-4.6-12.33z'
    />
    <linearGradient
      id='SkepticalRed__b'
      gradientUnits='userSpaceOnUse'
      x1={232}
      y1={153}
      x2={232}
      y2={127}>
      <stop
        offset={0}
        style={{
          stopColor: '#f54a4a',
        }}
      />
      <stop
        offset={0.384}
        style={{
          stopColor: '#fb5391',
        }}
      />
      <stop
        offset={0.764}
        style={{
          stopColor: '#fe896d',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#ffa659',
        }}
      />
    </linearGradient>
    <path
      fill='url(#SkepticalRed__b)'
      d='M232.13 127.44c-8.73 0-15.81 7.08-15.81 15.81 0 3.94 1.44 7.54 3.82 10.3l24.09-.11c2.32-2.75 3.73-6.31 3.73-10.2-.01-8.72-7.09-15.8-15.83-15.8z'
    />
    <circle cx={232} cy={143} r={7} />
    <circle fill='#FFF' cx={236} cy={140} r={2} />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M251.33 155.25c2.6-3.34 4.13-7.39 4.13-11.75 0-4.62-1.71-8.89-4.59-12.33-12.17-3.56-25.31-3.56-37.48 0h-.01c-2.89 3.44-4.59 7.71-4.59 12.33 0 4.37 1.53 8.41 4.13 11.75'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M211.96 157.46c3.96-3.27 11.62-5.48 20.41-5.48s16.45 2.21 20.41 5.48'
    />
  </svg>
);

export default SkepticalRed;
