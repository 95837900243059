import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { Card } from '@mui/material';
import * as React from 'react';
import ReactPlayer from 'react-player';
import { inIframe } from '../../wallet/Wallet';
import useCommonStyles from '../../components/useCommonStyles';
import { useHistory } from 'react-router-dom';
import { RealmType } from '../../components/schema';

interface ISignupPromptCard {}

const cutsceneUrls = {
  [RealmType.TurtleSea]: 'https://www.youtube.com/watch?v=iV_kmkg79KE',
  [RealmType.Boneyard]: 'https://www.youtube.com/watch?v=X-zNbpwfh-s',
  [RealmType.WizardsPath]: 'https://www.youtube.com/watch?v=hR3mXWGUDzo',
};

const SignupPromptCard: React.FunctionComponent<ISignupPromptCard> = (
  props,
) => {
  const common = useCommonStyles();
  const history = useHistory();

  function renderLink() {
    if (inIframe())
      return (
        <Typography variant={'h2'}>
          Visit Furballs.com to Get Started!
        </Typography>
      );

    return (
      <Button onClick={() => history.push('/onboard/defend')}>
        Form a Party to Defend Furburbia!
      </Button>
    );
  }

  function renderVideo() {
    return <ReactPlayer url='https://www.youtube.com/watch?v=_IMwEWd3dxM' />;
  }

  return (
    <Card className={common.cardRoot}>
      <CardHeader
        className={common.cardHeader}
        title={'Only 1,200 Furballs Remain!'}
      />
      <CardContent className={common.cardContent}>
        <Typography variant={'body1'}>
          Help defend Furburbia from the Monster Attacks!
        </Typography>
      </CardContent>
      <CardActions className={common.cardActions}>{renderLink()}</CardActions>
    </Card>
  );
};

export default SignupPromptCard;
