import * as React from 'react';

function SvgSnackButterballs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
      <path
        fill='#F8E9CB'
        d='M153.47 65.26c.46-6.31-4.13-11.77-10.25-12.22-1.34-.1-2.63.07-3.85.43.25-.82.42-1.68.49-2.58.46-6.31-4.13-11.77-10.25-12.22-5.24-.38-9.89 3.06-11.4 8.02.01-.11.03-.23.04-.34.57-7.94-5.2-14.83-12.9-15.38-6.79-.49-12.8 4.11-14.52 10.65-1.67-3.55-5.07-6.11-9.17-6.41-6.11-.44-11.44 4.31-11.9 10.62-.06.89-.02 1.77.11 2.61-1.15-.53-2.4-.87-3.73-.97-6.11-.44-11.44 4.31-11.9 10.62-.04.61-.03 1.21.02 1.8a6.333 6.333 0 00-3.61-1.47c-3.58-.26-6.7 4.41-6.96 8.1'
      />
      <path
        fill='#F8D891'
        d='M149.78 75.8c.41-5.72-4.52-12.95-10.06-13.35-.43-.03-.85.02-1.26-.01-4.8-.33-3.97-3.81-3.97-3.82 0-7.74-6.82-8.06-10.81-6.9-16.51 4.82-10.06-7.65-17.38-8.73-6.17-.91-6.16 2.42-8.48 4.14-3.09 2.3-7.05 3.18-10.69 1.92a9.75 9.75 0 00-2.49-.51c-5.55-.4-10.38 3.91-10.79 9.63v.02a1.786 1.786 0 01-2.14 1.62c-.37-.07-.75-.12-1.14-.15-5.55-.4-10.38 3.91-10.79 9.63-.04.55-.02 1.1.02 1.63-.9-.75-2.03-1.24-3.28-1.33-3.25-.23-6.07 2.29-6.32 5.64'
      />
      <path fill='#FF817F' d='M137.46 169.06H59.48l-15.5-93.49h108.98z' />
      <g fill='#FFF'>
        <path d='M61.73 83.72l3.74 85.34h12.54V83.72zM91.35 83.72h14.24v85.34H91.35zM118.93 83.72v85.34h12.17l4.11-85.34z' />
      </g>
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M137.46 169.06H59.48l-15.5-93.49h108.98z'
      />
      <path
        opacity={0.2}
        fill='#662E2E'
        d='M70.72 116l-11.25 53.06-15.5-93.49 106.45 9.98-55.45 8.41c-11.41 1.73-20.97 9.87-24 21-.08.35-.17.69-.25 1.04z'
      />
      <circle
        fill='#F8D891'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        cx={98.08}
        cy={121.49}
        r={18.38}
      />
      <path
        fill='#FFF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M155.59 83.72H44.41c-4.5 0-8.15-3.65-8.15-8.15v0c0-4.5 3.65-8.15 8.15-8.15h111.18c4.5 0 8.15 3.65 8.15 8.15v0c0 4.5-3.65 8.15-8.15 8.15z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M153.47 65.26c.46-6.31-4.13-11.77-10.25-12.22-1.34-.1-2.63.07-3.85.43.25-.82.42-1.68.49-2.58.46-6.31-4.13-11.77-10.25-12.22-5.24-.38-9.89 3.06-11.4 8.02.01-.11.03-.23.04-.34.57-7.94-5.2-14.83-12.9-15.38-6.79-.49-12.8 4.11-14.52 10.65-1.67-3.55-5.07-6.11-9.17-6.41-6.11-.44-11.44 4.31-11.9 10.62-.06.89-.02 1.77.11 2.61-1.15-.53-2.4-.87-3.73-.97-6.11-.44-11.44 4.31-11.9 10.62-.04.61-.03 1.21.02 1.8-.99-.83-2.23-1.55-3.61-1.47-6.3.36-6.7 5.31-6.96 9'
      />
    </svg>
  );
}

export default SvgSnackButterballs;
