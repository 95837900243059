import * as React from 'react';

function SvgIconPower(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='1125.37 439.28 25.34 28.24'
      {...props}>
      <g stroke='#000' strokeMiterlimit={10}>
        <path d='M1138.04 467.02c-6.71 0-12.17-5.57-12.17-12.42a12.41 12.41 0 014.81-9.89c.74-.57 1.8-.44 2.37.3.01.01.02.03.03.04.57.77.43 1.86-.33 2.45a8.896 8.896 0 00-3.45 7.11c0 4.9 3.92 8.92 8.74 8.92s8.74-4 8.74-8.92a8.905 8.905 0 00-3.46-7.11 1.77 1.77 0 01-.33-2.45c.55-.76 1.61-.92 2.36-.37.02.01.03.02.05.04 3.06 2.37 4.84 6.03 4.81 9.89 0 6.84-5.46 12.41-12.17 12.41z' />
        <path d='M1138.04 455.83c-.99-.05-1.76-.89-1.71-1.88h0v-12.3a1.79 1.79 0 011.71-1.87c.99.05 1.76.88 1.71 1.87v12.3c.05.99-.71 1.83-1.71 1.88h0z' />
      </g>
    </svg>
  );
}

export default SvgIconPower;
