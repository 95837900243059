import { SvgEditionSlot } from '../part';
import * as React from 'react';

const FurCoatPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#e6d1c6'
      d='M206.39 260.2c-2.83-36.31-91.16-60.78-91.16-60.78s-13.32-.38-18.41 2.79l8.25 2.14s-10.78 1-16.05 10.79l6.21.19s-5.13 6.17-5.13 9.37l3.76-.94s-.66 5.36 3.58 12.05l1.25-5.08s1.26 8.47 3.96 12.33l1.58-4.61s-.54 7.76 4.13 12.99l2.35-4.33s-.28 8.54 1.51 13.12l3.67-3.99s-2.73 6.68-1.98 11.2l3.09-1.04s-1.4 7.06-.08 10.92l4.55-.38s-2.42 5.09-2.71 10.45l5.5-1.86s-1.96 4-3.17 7.89h39.31c-.61-.89-1.48-3.78-1.77-4.86l-5.91-19.4c-.34-1.14.05-2 1.2-2.34 1.14-.34 1.63.13 1.97 1.27 0 0 3.81 1.63 9.12-3.49 1.28-1.23 2.54 3.31 5.14 4.52 3.25 1.51 7.99-.05 10.43-.14 7.33-.28 10.71-6.58 10.71-6.61-.05-1.78 1.39-1.75 1.41-1.75 1.22-.02 1.7.61 1.73 1.78.01.46.09 10.81-6.67 21.64 1.7 2.06 2.79 5.08 2.98 7.91.33-.02 18.09-10.46 15.65-31.75z'
    />
    <path
      fill='#d6b8a9'
      d='M120.94 291.67c2.15-4.11 2.96-5.27 2.96-5.27l-5.88 1.12c.28-5.36 3.45-10.59 3.45-10.59l-4.55.38c-1.32-3.86.08-10.92.08-10.92l-3.09 1.04c-.75-4.52 1.98-11.2 1.98-11.2l-3.67 3.99c-1.79-4.58-1.51-13.12-1.51-13.12l-2.35 4.33c-4.67-5.23-4.13-12.99-4.13-12.99l-1.58 4.61c-2.7-3.86-3.96-12.33-3.96-12.33l-1.25 5.08c-4.24-6.68-3.58-12.05-3.58-12.05l-3.76.94c0-3.2 5.32-9.37 5.32-9.37l-6.78-.38c1.36-2.44 7.02-11.25 16.85-10.03-.31.36-11.28 7-11.48 7.37h6.44s-3 5.01-3 8.21l3.43-1.62s-1.05 6.53 1.76 12.52l5.06-8.31s-1.05 16.04.66 21.53l5.45-9.49s-.8 10.45.66 16.37l7.54-8.16s-2.25 8.79-2.25 13.32l4.13-3.26s-4.26 6.28-3.51 10.8l4.91-3.38s-4.03 7.81-2.71 11.67l6.34-1.77s-2.82 4.15-3.1 9.52l8.88-1.91s-5.57 4.62-6.78 9.67c0 0 4.09-2.97 10.07-4.31 0 0-4.92 3.6-1.46 7.97'
    />
    <path
      fill='#e6d1c6'
      d='M225.21 248.64s5.49 7.36-6.58 29.26c0 0 19.16-21.11 20.86-49.81l-11.8 5.84-2.48 14.71z'
    />
    <path
      fill='#e6d1c6'
      d='M255.13 198.18c0-6.41-12.62 5.74-18.18 9.44-22.09 14.67-11.92 44.89-11.92 44.89s1.43-11.25 6.72-17.89c1.07 6.6-1.61 9.29-1.61 9.29 21.84-11.04 24.99-45.73 24.99-45.73z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M236.95 239.86c18.18-20.02 18.33-45.86 18.33-45.86M214.08 282.94s8.05-11.07 10.68-24.08c.93-4.58.6-7.26-.03-10.06-6.12-27.28 6.81-37.65 12.21-40.77 13.38-7.74 18.19-14.35 18.19-14.35M206.39 259.2s.7 24.95-17 34.65'
    />
    <path d='M223.9 202.79s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57zM224.06 247.77c1.57-4.91 1.74-8.18 7.68-13.15 0 0-4.66 6.29-6.34 17.32-.28 1.84 0-1.51-1.34-4.17zM242.52 235.98c-1.53 2.7-6.07 7.58-12.4 7.93 0 0 4.96-3.67 10.05-10.34 1.13-1.48 4.21-.8 2.35 2.41z' />
    <path
      fill='#e6d1c6'
      d='M178.81 213.07c-54.96-13.36-52.8-38.46-53.46-39.81-12.52 6.96-15.48 27.44-9.88 28.38 0 0 .73 5.62 1.65 5.41 1.53-.36 2.92-1.84 2.87-1.6-.85 4.25-.1 10.43.85 11.39.75.76 5.04-2.94 5.74-5.27 0 0-2.73 5.18 5.08 12.71 0 0 .48-5.16 2.59-6.31 0 0 3.78 13.82 9.87 15.98l1.99-8.21s1.45 10.91 12.3 14.3c0 0-2.95-7.64.45-8.99 0 0 1.79 6.87 15.15 8.94.47.07-.59-3.5-.12-3.59 28.26-5.45 32.52 23.82 32.52 23.82s10.84-37.81-27.6-47.15z'
    />
    <path
      fill='none'
      stroke='#FFF'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M135.3 216.05c.49 11.51 7.69 15.53 7.69 15.53M131.28 222.01s-4.24-4.42-4.99-9.79'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M112.64 199.42c-5.65-.66-14.96 2.96-14.96 2.96l7.39 1.97c-12.61 1.88-16.8 10.97-16.8 10.97h6.78s-4.94 6.17-4.94 9.37l3.76-.94s-.66 5.36 3.58 12.05l1.25-5.08s1.26 8.47 3.96 12.33l1.58-4.61s-.54 7.76 4.13 12.99l2.35-4.33s-.28 8.54 1.51 13.12l3.67-4.99s-2.73 7.68-1.98 12.2l3.09-1.04s-1.4 7.06-.08 10.92l4.55-.38s-3.17 5.23-3.45 10.59l6.24-2s-2.53 3.82-3.86 7.12h40.69M135.87 214.71c-4.16 4.31-4.38 9.67-4.38 9.67s-5.76-4.82-5.86-11.22'
    />
    <path
      fill='#e6d1c6'
      d='M239.72 232.63s1.44 3.84 6.84 2.67c0 0-3.3-4.06-2.84-7.44'
    />
    <path
      fill='none'
      stroke='#FFF'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M173.1 235.45s27.28-7.82 33.14 22.25M147 226.24c2.26 8.85 9.13 11.01 9.13 11.01M159.61 229.63c3.67 8.56 13.74 8.47 13.74 8.47'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M241.04 234s1.55 2.82 5.88 2.07c0 0-2.77-4.21-2.31-7.59'
    />
    <path
      fill='#e6d1c6'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M154.48 268.21s3.65 4.46 10.53-3.6c0 0 3.88 3.22 6.75 8.16l8.82-3.78s4.41 6.65 8.16 8.37c0 0 4.46-6.21 4.04-15.25'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M134.01 217.77s.24 12.19 10.1 16.17c0 0-.6-4.4 2.6-8.54'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M159.7 229.35c-4.61 4.33-1.6 10.45-1.6 10.45s-10.87-3.06-12.42-12.52'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M158.95 230.86s3.78 9.24 16.2 9.8l-1.14-3.78s21.65-5.55 28.75 13.78l3.56 10.78s11.79-37.8-27.32-48.28c-28.66-5.79-50.07-21.36-51.84-37.33-1.25-2.4-2.66-3.05-5.55-1.71-3.16 1.68-7.36 11.13-7.74 12.97-4.68 10.89 1.6 14.55 1.6 14.55'
    />
    <path
      fill='#e6d1c6'
      d='M126.34 174.62c-4.38-2.82-7.3-4.4-7.3-4.4-.4 2.41.29 3.38.58 4.78 0 0-4.32 1.61-7.19.4 0 0 1.54 4.68 4.86 5.82 0 0-2.9.74-8.26 2.53 0 0 4.61 5.62 8.84 3.08'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M126.01 172.97c-3.92-.52-6.97-2.75-6.97-2.75-.19 1.71-.08 3.39.58 4.78-1.08.39-4.6.8-7.19.4 0 0 .69 3.84 3.98 5.71 0 0-3.65 1.7-7.14 1.69 0 0 2.67 4.01 5.53 5.65'
    />
    <path d='M145.41 218.67c-3.18 2.3-3.44 6.73-3.44 6.73-4.28-6.28-1.15-8.87-1.15-8.87s-.03 5.65 1.04 4.27c1.86-2.39 3.55-2.13 3.55-2.13zM233.53 226.3c2.7 3.51 1.38 8.97 1.38 8.97 7.03-6.74 4.49-10.58 4.49-10.58s-2.01 6.9-2.7 4.99c-1.2-3.34-3.17-3.38-3.17-3.38zM133.49 204.06c-2.68 2.46-2.45 8.49-2.45 8.49-4.56-9.62-1.96-12.48-1.96-12.48s.55 7.77 1.39 6.12c1.45-2.87 3.02-2.13 3.02-2.13zM198.59 238.13c2.94.91 4.96 5.6 4.96 5.6-.01-9.01-3.04-10.25-3.04-10.25s2.4 6.14 1.16 5.18c-2.15-1.67-3.08-.53-3.08-.53zM126.67 169.96s-4.91 5.74-3.01 15.53c3.39 17.51 46.68 26.54 46.68 26.54s-39.34-15.25-43.67-42.07z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M126.48 210.62c-.66 4.24-5.46 6.31-5.46 6.31-2.35-4.89-1.59-9.94-.74-12.04M122.01 201.91c-1.69 4.09-5.22 5.13-5.22 5.13s-1.23-1.66-1.32-5.4'
    />
  </svg>
);

export default FurCoatPower;
