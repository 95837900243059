import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

export const headerFont = 'GemunuLibre';
export const bodyFont = 'Ubuntu';
const bodyFontBold = 'Ubuntu Medium';

export const mint = {
  light: '#C9FFD4',
  main: '#AFFFB2',
  dark: '#C9FFD4',
  contrastText: '#000',
};

export const purple = {
  light: '#5430E0',
  main: '#978EE4',
  dark: '#1A0273',
  contrastText: '#000',
};

export const black = '#000000';
export const white = '#ffffff';
export const gray = '#616161';
export const lightGray = '#FAFAFA';
export const darkBlue = '#362CD1';
export const yellow = '#FDE99A';

export const zIndicies = {
  appBar: 1251,
  drawer: 1250,
};

const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  zIndex: zIndicies,
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 1, //600,
  //     md: 2, // 900,
  //     lg: 1500, // 1200
  //     xl: 1536,
  //   }
  // },
  palette: {
    primary: mint,
    secondary: purple,
    // error: Colors.error,
    background: {
      paper: white,
      default: lightGray,
    },
  },
  shape: {
    borderRadius: 20,
  },
  mixins: {
    toolbar: {
      height: '56px',
    },
  },
  typography: {
    fontFamily: [bodyFont, headerFont, 'sans-serif'].join(','),
    h1: {
      fontFamily: headerFont,
      fontSize: '4.4rem',
    },
    h2: {
      fontFamily: headerFont,
      fontSize: '3.5rem',
    },
    h3: {
      fontFamily: headerFont,
      fontSize: '2.5rem',
    },
    h4: {
      fontSize: '1.6rem',
      fontFamily: bodyFontBold,
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem',
      fontFamily: headerFont,
    },
    h6: {
      fontSize: '1.1rem',
      fontFamily: headerFont,
    },
    button: {
      fontFamily: headerFont,
      borderColor: black,
      border: '2px solid',
      fontSize: '0.875rem',
    },
    body1: {
      fontFamily: bodyFont,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: bodyFont, // Used implicitly by listMenu
      fontSize: '1.2rem',
      lineHeight: '2rem',
    },
    caption: {
      fontFamily: bodyFont,
      fontSize: '0.5rem',
    },
    //button
    overline: {
      fontFamily: bodyFont,
      // fontSize: '0.75rem',
    },
    subtitle1: {
      fontFamily: bodyFont,
      fontWeight: 'bold',
    },
    subtitle2: {
      fontFamily: bodyFont,
      fontStyle: 'italic',
    },
  },
});

theme.mixins.toolbar[theme.breakpoints.down('lg')] = {
  height: '80px',
  padding: '0px',
  paddingLeft: theme.spacing(2),
};

theme.typography.button[theme.breakpoints.down('lg')] = {
  // fontSize: '2.0rem',
  // lineHeight: '2.4rem',
  fontSize: '0.9rem',
};
theme.typography.h2[theme.breakpoints.down('lg')] = {
  fontSize: '3.8rem',
};
theme.typography.h5[theme.breakpoints.down('lg')] = {
  fontSize: '1.5rem',
};
theme.typography.h6[theme.breakpoints.down('lg')] = {
  fontSize: '1.1rem',
};
theme.typography.subtitle1[theme.breakpoints.down('lg')] = {
  fontSize: '1rem',
};
theme.typography.subtitle2[theme.breakpoints.down('lg')] = {
  fontSize: '0.9rem',
};

// Mobile
theme.typography.h1[theme.breakpoints.down('sm')] = {
  fontSize: '3rem',
};
theme.typography.h2[theme.breakpoints.down('sm')] = {
  fontSize: '2.85rem',
};
theme.typography.h3[theme.breakpoints.down('sm')] = {
  fontSize: '2.25rem',
};
theme.typography.body1[theme.breakpoints.down('sm')] = {
  fontSize: '1.15rem',
};
theme.typography.body2[theme.breakpoints.down('sm')] = {
  fontSize: '1.2rem',
};

export const shadeColor = (col: string, amt: number) => {
  let usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export default theme;
