import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Unity } from 'react-unity-webgl';
import {
  useDevicePixelRatio,
  useUnityGameBuild,
} from '../../components/useUnity';
import { GameBuildFragment } from '../../components/schema';
import { getGameCdnShard } from '../../theme';
import { useFurComponent } from '../../utils';
import GameBattleSubscription from './Battles/GameBattleSubscription';
import { useHistory } from 'react-router-dom';
import { IGameMessage, useFurverseContext } from './Furverse';

const useStyles = makeStyles((theme: Theme) => ({
  unity: {
    backgroundColor: 'white',
    zIndex: 10000,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: 'calc(100vh)',
    outline: 'none',
  },
}));
interface IFurverseUnityGame {
  containerRef: React.RefObject<HTMLDivElement>;
  gameBuild: GameBuildFragment;
}

const FurverseWebGlGame: React.FunctionComponent<IFurverseUnityGame> = (
  props,
) => {
  const { containerRef, gameBuild } = props;
  const classes = useStyles();
  const { log } = useFurComponent(FurverseWebGlGame);
  // const containerRef = React.useRef<HTMLDivElement>(null);
  const {
    gameActive,
    // setUnityContext,
    currentBattleId,
    currentBattle,
    endCurrentBattle,
    beginBattleId,
    outbox,
    setOutbox,
    sendGameMessage,
    setLoadProgress,
    gameReady,
    handleGameMessage,
  } = useFurverseContext();
  // const paused = false; // usePaused();
  const visibility = gameActive ? 'visible' : 'hidden';

  const paths = location.pathname.split('/');
  const cdn = ['alpha', 'beta'].includes(paths[1].toLowerCase())
    ? getGameCdnShard(paths[1])
    : undefined;

  const devicePixelRatio = useDevicePixelRatio();
  const unityContext = useUnityGameBuild(gameBuild, cdn);
  const score = currentBattle?.score;
  const [isShutDown, setShutDown] = React.useState(false);
  const [isSubscriptionLoaded, setSubscriptionLoaded] = React.useState(false);
  // const history = useHistory();
  const { unityProvider, loadingProgression } = unityContext;
  const unityInstance = unityContext.UNSAFE__unityInstance;
  const canvas: HTMLCanvasElement | undefined = React.useMemo(
    () => unityInstance?.Module.canvas,
    [unityInstance],
  );
  const history = useHistory();
  const percentLoaded = Math.floor(loadingProgression * 100);

  function webglSendMsg(msg: IGameMessage): void {
    // log.info('[MSG] webGL', msg);
    unityContext?.sendMessage(
      'ClientBridge',
      'HandleMessage',
      JSON.stringify(msg),
    );
  }

  React.useEffect(() => {
    if (outbox.length <= 0 || !gameReady) return;
    log.info('[MSG] webgl queue process', outbox.length);
    outbox.forEach(webglSendMsg);
    setOutbox([]);
  }, [outbox, gameReady]);

  function onGameOver(scoreHash?: string) {
    // setBattleBossName(undefined);
    // maintain same boss/X/realm/party-name path components...
    const path = window.location.pathname
      .replace('/fight/', '/complete/')
      .split('#')[0];
    // const hash = score ? `#score=${score}&level=${level ?? 0}` : '';
    history.push(path + (scoreHash ?? ''));
  }

  const gracefulShutdownUnity = React.useCallback(
    (scoreHash?: string) => {
      if (isShutDown) return;
      log.info('[UNITY] gracefully shutting down; score', scoreHash, score);

      endCurrentBattle();
      setSubscriptionLoaded(false);
      // unityContext.quitUnityInstance();
      // document.exitFullscreen();
      onGameOver(scoreHash);
      setShutDown(true);
    },
    [isShutDown, score],
  );

  // function handleGameOver() {
  //   // Nothing to do here; shutdown already happens
  //   log.info('[UNITY] game over');
  //   // gracefulShutdownUnity();
  // }

  React.useEffect(() => {
    setSubscriptionLoaded(false);
  }, [currentBattleId]);

  // unityContext.addEventListener('OpenDiscordChat', () => {
  //   log.info('[UNITY] OpenDiscordChat');
  //   setBossChatOpen(true);
  //   setSs(ss + 1);
  // });

  // useUnityLoadProgress(unityContext, setLoadProgress);
  React.useEffect(() => {
    // if (!unityContext) return;
    // setUnityContext(unityContext);

    const onSubscribe = (bid: string): void => {
      beginBattleId(bid);
      setShutDown(false);
    };

    const onDisconnect = (): void => {
      log.info('[UNITY] BossBattleDisconnect: disconnecting...');
      setSubscriptionLoaded(false);
      endCurrentBattle(); // if client sends a disconnect
    };

    unityContext.addEventListener('BossBattleSubscribe', onSubscribe);
    unityContext.addEventListener('BossBattleDisconnect', onDisconnect);
    unityContext.addEventListener(
      'BossBattleReturnToLobby',
      gracefulShutdownUnity,
    );
    unityContext.addEventListener(
      'ReturnToFurversePage',
      gracefulShutdownUnity,
    );
    unityContext.addEventListener('SendWebMessage', handleGameMessage);

    // unityContext.addEventListener('GameOver', handleGameOver);
    unityContext.addEventListener('quitted', gracefulShutdownUnity);
    // window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      // setUnityContext(undefined);
      unityContext.removeEventListener('BossBattleSubscribe', onSubscribe);
      unityContext.removeEventListener('BossBattleDisconnect', onDisconnect);
      unityContext.removeEventListener(
        'BossBattleReturnToLobby',
        gracefulShutdownUnity,
      );
      unityContext.removeEventListener('SendWebMessage', handleGameMessage);
      unityContext.removeEventListener('quitted', gracefulShutdownUnity);
      // window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []); // The unity context should never actually change except on redraw

  // React.useEffect(() => {
  //   setUnityContext(unityContext);
  // }, [unityContext]);

  React.useEffect(() => {
    setLoadProgress(percentLoaded);
  }, [percentLoaded]);

  React.useEffect(() => {
    if (gameActive) {
      canvas?.setAttribute('tabIndex', '1');
      canvas?.focus();
      setShutDown(false);
      sendGameMessage({
        topic: 'NavigateApp',
        data: { id: window.location.pathname },
      });
    }
  }, [gameActive, canvas]);

  function onSubscriptionLoaded() {
    if (isSubscriptionLoaded) return;
    if (unityContext.loadingProgression >= 1) {
      unityContext.sendMessage('ClientBridge', 'OnBossBattleSubscribed');
      log.info('[UNITY] subscribe started', currentBattleId);
      setSubscriptionLoaded(true);
      setShutDown(false);
    } else {
      log.warn('no loaded game for bridge');
    }
  }

  return (
    <div
      className={clsx(classes.unity)}
      ref={containerRef}
      style={{ visibility }}>
      {currentBattleId && (
        <GameBattleSubscription
          unityContext={unityContext}
          gameBattleId={currentBattleId}
          onLoaded={onSubscriptionLoaded}
        />
      )}
      <Unity
        unityProvider={unityProvider}
        className={classes.unity}
        matchWebGLToCanvasSize={true}
        style={{ backgroundColor: '#000', visibility }}
        devicePixelRatio={devicePixelRatio}
      />
    </div>
  );
};

export default FurverseWebGlGame;
