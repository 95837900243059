import { SvgEditionSlot } from '../part';
import * as React from 'react';

const HarnessPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      fill='#e2fb86'
      d='M154 271.27c-19.2-10.59-29.11-21.53-36.1-42.81l-7.98 6.98s6.81 33.22 47.23 46.2'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M154.71 270.1c-18.54-8.09-29.81-20.35-36.8-41.63l-7.98 6.98s6.81 33.22 47.23 46.2'
    />
    <path
      fill='#e2fb86'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M120.02 196.93c-.35.15-.69.34-1.03.54l-22.44 12.98s-7.38 4.39-5.77 14.25c1.61 9.85 6.53 25.85 20.93 20.75l18.67-10.8c3.92-2.27 5.43-7.24 3.3-11.24-.19-.35-.4-.72-.63-1.09-4.42-6.99-7.21-14.18-.24-18.95l-3.6-6.22c0-.02-4.48-2.27-9.19-.22z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M129.21 197.13s-10.15 4.08-9.36 15.52a5.1 5.1 0 0 1-2.55 4.76L92.61 231.7'
    />
    <path
      fill='#ffed84'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m101.39 223.81 1.88 4.98a2.105 2.105 0 0 0 3.01 1.09l5.58-3.15c.9-.51 1.29-1.59.94-2.56l-1.9-5.17a2.105 2.105 0 0 0-3.06-1.08l-5.56 3.34a2.11 2.11 0 0 0-.89 2.55z'
    />
    <path
      fill='#e2fb86'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M188.45 282.71s20.78.18 32.75-6.92l6.71-8.75c-3.25 1.91-6.68 1.32-6.68 1.32s-.01-9.59.29-17.42c.19-4.94 1.79-9.72 4.6-13.8L238 219.89l-66.07 5.65 27.39 15.67c7.12 4.22 8.38 10.77 8.12 19.04l-.18 6.95c-.04 1.42-.63 2.79-1.72 3.69-2.83 2.34-7.65 2.84-13.57 3.07'
    />
    <path
      fill='#e2fb86'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M242.33 203.77c-12.33 7.64-28.41 12.88-46.01 12.88-30.16 0-55.87-15.32-65.83-31.89-2.14 1.76-4.54 4.23-5.11 5.58-6.79 16.02 56.59 59.06 118.12 26.46 2.66-1.41 4.24-4.21 3.15-7.01-.73-1.87-2.45-4.7-4.32-6.02z'
    />
  </svg>
);

export default HarnessPower;
