import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SadPencil = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffa6d3'
      d='m265.22 107.41 36.18 12.07-55.87 167.45c-2.11 6.32-8.95 9.74-15.27 7.63l-13.29-4.43c-6.32-2.11-9.74-8.95-7.63-15.27l55.88-167.45z'
    />
    <path
      fill='#FFF'
      d='M233.35 263.22c6.59 2.2 13.1 3.27 19 3.31l56.4-169.04-.91-55.99c-.01-.49-.63-.7-.94-.31l-34.34 44.23-56.4 169.04c4.75 3.51 10.59 6.56 17.19 8.76z'
    />
    <path d='m307.98 64.33-.15-22.77c0-.5-.64-.71-.94-.31l-13.8 18.12' />
    <path
      fill='#ffa6d3'
      d='m265.22 107.41 36.18 12.07-55.87 167.45c-2.11 6.32-8.95 9.74-15.27 7.63l-13.29-4.43c-6.32-2.11-9.74-8.95-7.63-15.27l55.88-167.45z'
    />
    <path
      fill='#f0faff'
      d='M233.35 263.22c6.59 2.2 13.1 3.27 19 3.31l50.85-152.41-36.18-12.07-50.85 152.41c4.74 3.51 10.58 6.56 17.18 8.76z'
    />
    <path
      fill='#ffb261'
      d='m270.17 92.57-49.04 146.98c4.75 3.5 10.59 6.56 17.18 8.76s13.1 3.27 19 3.31l46.5-152.04c-1.74-3.49-6.22-4.54-9.33-2.19l-1.51 1.14c-3.4 2.57-8.32 1.04-9.67-3l-.6-1.79c-1.29-3.87-5.88-5.48-9.3-3.26l-3.23 2.09z'
    />
    <path
      fill='#ffc2e1'
      d='m234.44 283.23 55.87-167.45 11.13 3.71-55.87 167.45c-2.11 6.32-8.95 9.74-15.27 7.63l-11.13-3.71c6.32 2.11 13.16-1.31 15.27-7.63z'
    />
    <path
      fill='#FFF'
      d='m294.94 101.88 13.83-4.38-56.4 169.04c-3.77-.03-7.78-.48-11.91-1.37l54.48-163.29z'
    />
    <path
      fill='#ffb261'
      d='M245.65 250.3c.03.01.05.01.08.02-.03-.01-.06-.01-.08-.02z'
    />
    <path
      fill='#ffc68a'
      d='M245.44 250.26c.07.02.14.03.21.04-.07-.01-.14-.03-.21-.04z'
    />
    <path
      fill='#ffc68a'
      d='m257.32 251.62 49.04-146.98-2.53-5.07c-1.34-2.69-4.31-3.93-7.03-3.28l-1.55 4.64-49.04 146.98-.78 2.34c.07.01.14.03.21.05.03.01.05.01.08.02 4.02.85 7.93 1.27 11.6 1.3z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m259.73 244.4 47.96-143.87c.7-2.09 1.05-4.28 1.03-6.49l-.79-36.33-.11-16.19c0-.5-.63-.71-.93-.32l-32.22 41.45a19.87 19.87 0 0 0-3.07 5.81l-62.24 186.4c-2.11 6.32 1.31 13.16 7.63 15.27l13.29 4.43c6.32 2.11 13.16-1.31 15.27-7.63l6.81-20.4 7.37-22.13z'
    />
    <circle cx={285} cy={82} r={2} />
    <circle cx={300} cy={87} r={2} />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M295.2 92.02c-.09-1.45-1.49-2.95-3.52-3.63-2.03-.68-4.05-.31-4.99.79'
    />
  </svg>
);

export default SadPencil;
