import { SvgEditionSlot } from '../part';
import * as React from 'react';

const DiscoBallPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <linearGradient
      id='DiscoBallPower__a'
      gradientUnits='userSpaceOnUse'
      x1={59}
      y1={292}
      x2={232}
      y2={213}>
      <stop
        offset={0}
        style={{
          stopColor: '#e9eaeb',
        }}
      />
      <stop
        offset={0.187}
        style={{
          stopColor: '#a6a7a9',
        }}
      />
      <stop
        offset={0.347}
        style={{
          stopColor: '#e0e2e2',
        }}
      />
      <stop
        offset={0.616}
        style={{
          stopColor: '#a9abac',
        }}
      />
      <stop
        offset={0.656}
        style={{
          stopColor: '#b2b4b4',
        }}
      />
      <stop
        offset={0.845}
        style={{
          stopColor: '#d6d8d8',
        }}
      />
      <stop
        offset={0.946}
        style={{
          stopColor: '#e4e6e6',
        }}
      />
    </linearGradient>
    <path
      fill='url(#DiscoBallPower__a)'
      d='m241.25 203.99-104.38-10.36-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h113.95c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73z'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M89.63 293.42c-3.93-5.81-16.68-24.15-9.08-43.8 3.46-8.94 10.25-14.86 18.28-21.39 0 0 22.4-17.7 47.63-31.44M227.97 211.9s10.88 10.27 10.32 23.82'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M95.75 282.17c-3.19-8.24-4.29-18.7.5-31.08 3.46-8.94 9.11-12.8 17.28-19.57l37.03-26.96'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M64.41 247.79c8.93 14.57 17.94 25.8 35.64 34.15M116.92 292.99s-17.76-32.05 21.63-65.61l21.74-17.95M59.77 264.87c2.34 4.01 13 20.66 32.54 28.33'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M135.6 292.99s-8.4-26.52 8.26-48.29l25.21-32.14'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M125.21 200.59c13.42 14.26 56.8 42.41 113.27 25.22'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M113.11 207.23c12.72 15.84 59.67 51.67 124.45 34.44'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M100.01 214.01c15.62 24.47 55.07 54.07 131.91 44.76'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M86.01 222.09c15.49 28.36 53.76 59.07 136.16 52.86'
    />
    <path
      fill='none'
      stroke='#e8e8eb'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M72.08 233.68c19.04 26.04 37.95 48.1 86.49 53.27M154.48 268.47c.8-6.83 3.1-14.8 8.07-23.57l18.44-30.88M176.31 292.99s-11.06-17.61 2.68-48.29l12.92-28.25M200 216l-8 47M208.35 215.28s10.31 30.92-5.79 74.26M217.49 213.6s16.04 21.48 6.11 59.88'
    />
    <path
      fill='#e8e8eb'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M105.16 258.57s7.15 16.77-6.47 24.11'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01M154.48 268.6l5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
  </svg>
);

export default DiscoBallPower;
