import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Cigar = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M205.21 161.51s-4.24-4.08-14.36-2.34c0 0-2.39 4.41 1.6 3.37 3.33-.86 5.69-1.85 12.76-1.03z' />
    <path
      fill='#9c6652'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M192.66 160.11s-2.12-.64-7.2 3.13c-4.27 3.16-14.22 14.01-17.01 17.5l4.93 5.52s1.32-1.05 3.27-2.86c1.52-1.41 15.81-16.53 16.08-23.34l-.07.05z'
    />
    <path
      fill='#9c9c9c'
      d='M172.67 186.43c-1.66-.03-3.64-1.64-4.34-3.52-.32-.86-.31-1.66.02-2.13.24-.34.63-.51 1.16-.51 1.7.03 3.68 1.64 4.38 3.52.32.86.31 1.66-.02 2.13-.24.34-.63.51-1.16.51h-.04z'
    />
    <path d='M169.51 181.35h.02c1.09.02 2.76 1.22 3.34 2.81.27.73.14 1.1.11 1.14-.02.01-.1.05-.3.05-1.09-.02-2.76-1.22-3.34-2.81-.25-.67-.16-1.07-.11-1.14.02-.01.11-.05.28-.05m0-2.16c-2.06 0-3.04 1.82-2.2 4.09.85 2.3 3.24 4.19 5.33 4.22h.06c2.06 0 3.04-1.82 2.2-4.09-.85-2.3-3.24-4.19-5.33-4.22h-.06zM204.11 149.62c0-1.49-3.26-2.7-7.29-2.7s-7.29 1.21-7.29 2.7c0 .96 1.34 1.86 3.38 2.28 1.97.4 2.94 3.05 2.94 3.05.39.94 1.75.71 1.93 0 0 0 1.04-2.56 2.94-3.05 2.03-.52 3.39-1.32 3.39-2.28z' />
    <path
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M176.64 183.4s1.53-5.33-.3-10.26M181.75 177.58s1.25-5.42-.83-10.26M186.8 171.41s.7-3.85-1.19-8.19'
    />
  </svg>
);

export default Cigar;
