import React, { FunctionComponent } from 'react';
import walletSlice from '../../wallet/WalletSlice';
import { getInventoryQuantities } from '../Furballs';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { useAuthContext } from './AuthContext';
import { useMyFurballsLazyQuery } from '../schema';
import { useFurComponent } from '../../utils';
import { inIframe } from '../../wallet/Wallet';
import { IFurball } from '../../wallet/WalletTypes';
// @ts-ignore
import * as lz from 'lz-string';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { useFurverseContext } from '../../pages/Furverse/Furverse';

interface IPlayerFurballLoader {}

const LS_FURBALLS = 'furballs';

function loadFurballsFromStorage(
  playerId: string,
  dispatch: Dispatch<AnyAction>,
) {
  if (inIframe()) return;
  try {
    const comp = localStorage.getItem(LS_FURBALLS);
    if (!comp || comp.length === 0) return;
    const json = lz.decompress(comp);
    const data = JSON.parse(json);
    const furballs = data[playerId] as IFurball[];
    console.log('[LS] restored', furballs?.length, 'furballs from storage');
    if (furballs && furballs.length > 0)
      dispatch(walletSlice.actions.setUsableFurballs(furballs));
  } catch (e) {
    console.warn('[LS] failed to decode storage', e);
  }
}

export function saveFurballsToStorage(
  playerId: string,
  furballs: IFurball[],
): void {
  if (inIframe()) return;
  try {
    const data = JSON.stringify({ [playerId]: furballs });
    const comp = lz.compress(data);
    console.log(
      '[LS] saved',
      data.length,
      'chars',
      comp.length,
      'compressed for',
      furballs.length,
      'furballs',
    );
    localStorage.setItem(LS_FURBALLS, comp);
  } catch (e) {
    console.warn('[LS] failed to set', e);
  }
}

export const PlayerFurballLoader: FunctionComponent<IPlayerFurballLoader> = (
  props,
) => {
  const pid = useWalletSelector((s) => s.contents?.id ?? '');
  const { isSignedIn } = useAuthContext();
  const playerId = isSignedIn ? pid : '';
  const [loadingPlayer, setLoadingPlayerId] = React.useState('');
  const [readyPlayer, setReadyPlayerId] = React.useState<string>();
  const [loadFurballs, { data, loading, error }] = useMyFurballsLazyQuery();
  const dispatch = useAppDispatch();
  const usableFurballs = data?.usableFurballs ?? [];
  const hasLoaded = !!data?.usableFurballs;
  const hasLoadedCurrentPlayer = hasLoaded && playerId === loadingPlayer;
  const { sendGameMessage, clientArgs } = useFurverseContext();
  const usableFurballIds = usableFurballs.map((f) => f.id);
  const { log } = useFurComponent(PlayerFurballLoader);
  const iframe = inIframe();

  React.useEffect(() => {
    if (playerId.length > 0) {
      if (playerId !== loadingPlayer) {
        log.info('[AUTH] loading Furballs for', playerId);
        setLoadingPlayerId(playerId);
        loadFurballsFromStorage(playerId, dispatch);
        loadFurballs();
      }

      // debounce any changes during startup
      // const to = setTimeout(() => {
      // }, 500);
      // return () => clearTimeout(to);
    } else {
      log.info('[AUTH] player ready without Furballs', isSignedIn);
      // onPlayerReady();
      setReadyPlayerId('');
    }
  }, [loadingPlayer, playerId, isSignedIn]);

  // Player is loaded and ready
  React.useEffect(() => {
    if (loadingPlayer === readyPlayer) {
      const furballs = usableFurballs.map((fb) => {
        return { ...fb, inventory: getInventoryQuantities(fb.inventory) };
      });
      // Will auto-save to storage
      dispatch(walletSlice.actions.setUsableFurballs(furballs));

      log.info('[AUTH] loaded Furballs for', playerId);
      // sendVuplexMessage({ topic: 'LoadedFurballs', data: { usableFurballs } });
      sendGameMessage({
        topic: 'PlayerReady',
        data: { id: playerId, clientArgs },
      });
    }
  }, [loadingPlayer, readyPlayer]);

  // Trigger load
  React.useEffect(() => {
    if (hasLoadedCurrentPlayer) {
      if (playerId !== readyPlayer) {
        log.info('[AUTH] loader ready for', playerId);
        setReadyPlayerId(playerId);
      }
    } else if (loading) {
    } else if (error) {
      log.error('[AUTH] failed loading', error);
    } else if (usableFurballs.length > 0) {
      dispatch(walletSlice.actions.clearUsableFurballs());
      log.info('[AUTH] cleared Furballs for', playerId);
    } else {
    }
  }, [hasLoadedCurrentPlayer, usableFurballIds]);

  return <></>;
};

export default PlayerFurballLoader;
