import React, { FunctionComponent, useState } from 'react';

import {
  Button,
  ButtonGroup,
  Container,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MarketplaceFilters } from './RentMarketFilters';
import { IFilterGroup } from './RentMarketTypes';
import { SvgIconSearch } from '../../../assets/SvgIconSearch';
import theme from '../../../themev2';
import { IMarketSortParams, MarketSort } from './RentMarketSort';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0.5rem 0.5rem 2rem ',
  },

  sortings: {
    marginTop: '2rem',

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      filter: 'grayScale(100%)',
      opacity: 0.5,
    },
  },

  sortBy: {
    '& p': {
      fontSize: '0.85rem !important',
    },
  },

  button: {
    backgroundColor: `${theme.palette.grey[400]} !important`,
    borderColor: `${theme.palette.grey[400]} !important`,

    '&[data-active="true"]': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      borderColor: `${theme.palette.common.black} !important`,
      borderWidth: '2px !important',
    },
  },

  searchContainer: {
    position: 'relative',
    // marginRight: '-16px',
    // left: '-16px',
    // width: '100%',
    maxWidth: '100%',
    overflowX: 'hidden',
  },

  textField: {
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
    },

    '& .MuiInputBase-input': {
      borderRadius: '6px !important',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      padding: '10px 15px',
      // minWidth: '15.35rem',
      paddingLeft: '2.5rem',

      '&:focus': {
        borderRadius: '6px !important',
      },
    },
  },

  searchIcon: {
    position: 'absolute',
    left: '1rem',
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 5,
  },
}));

interface IProps extends IMarketSortParams {
  loading: boolean;
  tab: '1' | '2' | '3' | '4';
  filters: IFilterGroup[];
  query: string;
  setQuery: (q: string) => void;
}

// const [sortBy, setSortBy] = useState<SORTKEYS>();
// const [sortOrder, setSortOrder] = useState<SortEnumType>(SortEnumType.Asc);

export const MarketplaceDrawer: FunctionComponent<IProps> = ({
  filters,
  tab,
  query,
  setQuery,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
}) => {
  const [collection, setCollection] = useState<'genesis' | 'gen-ii'>('genesis');
  const classes = useStyles();
  const showE2 = false; // TODO: Show this once we release E2
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Container className={classes.root}>
      <Typography variant='h4'>Furball Market</Typography>
      <Typography variant={'body1'}>
        Borrow furballs for boss fights.
      </Typography>

      {showE2 && (
        <Grid
          container
          direction='column'
          gap='0.5rem'
          className={classes.sortings}>
          <Typography variant='h4' fontSize='1.2rem'>
            Collection
          </Typography>
          <ButtonGroup variant='contained' fullWidth>
            <Button
              data-active={collection === 'genesis'}
              onClick={() => setCollection('genesis')}
              className={classes.button}>
              Genesis
            </Button>
            <Button
              data-active={collection === 'gen-ii'}
              onClick={() => setCollection('gen-ii')}
              className={classes.button}>
              Gen II
            </Button>
          </ButtonGroup>
        </Grid>
      )}

      <Grid
        container
        direction='column'
        gap='0.5rem'
        data-disabled={tab === '3'}
        className={classes.sortings}>
        <Typography variant='h4' fontSize='1.2rem'>
          Filters
        </Typography>
        <MarketplaceFilters filters={filters} />
        {isSmallScreen && (
          <Grid item>
            <div className={classes.searchContainer}>
              <SvgIconSearch
                width='1rem'
                height='1rem'
                fill={theme.palette.secondary.main}
                className={classes.searchIcon}
              />
              <TextField
                className={classes.textField}
                variant='outlined'
                color='secondary'
                id='rent-reserve'
                value={query}
                name='reserved'
                fullWidth
                placeholder='Search by token or number'
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <br />
            <div className={classes.searchContainer}>
              <MarketSort
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </div>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
