import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SporkDork = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#dbeff3'
      d='M327.69 98.79c4.88-14.9 3.76-28.58.09-43.88-.37-1.56-2.39-1.98-3.34-.69l-9.09 12.26 1.41-19.7c.08-1.18-1.44-1.73-2.12-.76l-11.82 16.75.37-20.49c.02-1.19-1.53-1.65-2.16-.64l-10.53 16.7.17-15.44c.02-1.61-1.86-2.49-3.09-1.45-12.11 10.15-21.22 20.64-26.14 35.65-5.69 17.37-3.77 34.74 3.79 45.97 4.24 6.3 5.59 14.1 3.23 21.31l-28 85.55 16.56 5.42 28.68-87.63c2.22-6.79 7.39-12.1 13.93-14.96 11.89-5.21 22.7-17.6 28.06-33.97z'
    />
    <path
      fill='#c3d8db'
      d='M326.85 53.07c-.37-1.56-1.46-.15-2.41 1.14l-.51.69c3.28 15.23 3.43 26.22-1.3 40.7-5.36 16.38-16.17 28.77-28.06 33.97-6.55 2.86-11.71 8.17-13.93 14.96l-28.68 87.63 4.14 1.36 28.68-87.63c2.22-6.79 7.39-12.1 13.93-14.96 11.89-5.2 22.7-17.59 28.06-33.97 4.87-14.91 3.75-28.58.08-43.89z'
    />
    <path
      fill='#f6f6f6'
      d='M270.74 124.87c-7.56-11.22-9.47-28.6-3.79-45.97 4.58-14 12.45-22.97 23.68-33.47l.03-2.54c.02-1.61-1.86-2.49-3.09-1.45-12.11 10.15-21.22 20.64-26.14 35.65-5.69 17.37-3.77 34.74 3.79 45.97 4.24 6.3 5.59 14.1 3.23 21.31l-28 85.55 5.52 1.81 28-85.55c2.37-7.21 1.02-15.01-3.23-21.31z'
    />
    <ellipse
      transform='rotate(-71.874 284.406 76.968)'
      fill='#FFF'
      cx={284}
      cy={76}
      rx={7}
      ry={2}
    />
    <path
      fill='#c3d8db'
      d='M319.41 86.1c-.21.94-.43 1.89-.69 2.84-6.05 22.05-24.26 36.27-40.68 31.76-1.12-.31-2.19-.7-3.24-1.16 2.6 2.81 5.79 4.87 9.47 5.88 13.54 3.71 28.55-8.01 33.54-26.19 1.23-4.47 1.74-8.92 1.6-13.13z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M327.69 98.79c4.88-14.9 3.76-28.58.09-43.88-.37-1.56-2.39-1.98-3.34-.69l-7.85 11.22c-.46.66-1.51.28-1.43-.52l1.6-18.14c.08-1.18-1.44-1.73-2.12-.76L303.9 61.86c-.45.66-1.47.32-1.44-.47l.72-19.12c.02-1.19-1.53-1.65-2.16-.64l-9.39 15.5c-.42.7-1.5.38-1.47-.44l.51-13.78c.02-1.61-1.86-2.49-3.09-1.45-12.11 10.15-21.22 20.64-26.14 35.65-5.69 17.37-3.77 34.74 3.79 45.97 4.24 6.3 5.59 14.1 3.23 21.31l-28 85.55 16.56 5.42 28.68-87.63c2.22-6.79 7.39-12.1 13.93-14.96 11.89-5.22 22.7-17.61 28.06-33.98z'
    />
    <path
      fill='#9effc0'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m236.69 297.8-17.91-5.86c-2.82-.92-4.36-3.96-3.44-6.79l35.03-107.03c.92-2.82 3.96-4.36 6.79-3.44l17.91 5.86c2.82.92 4.36 3.96 3.44 6.79l-35.03 107.03a5.394 5.394 0 0 1-6.79 3.44z'
    />
  </svg>
);

export default SporkDork;
