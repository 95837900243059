import React, { FunctionComponent } from 'react';
import {
  Theme,
  Typography,
  CircularProgress,
  Button,
  Grid,
  CardContent,
  Card,
  CardHeader,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { PlayerPartyFragment, RealmType } from '../../../components/schema';
import { getBossNameFromRealm, useFurComponent } from '../../../utils';
import { Link, useHistory } from 'react-router-dom';
import useCommonStyles from '../../../components/useCommonStyles';
import { useBossBattle } from '../useBossBattle';
import SpendWFurButton from '../../../components/Account/SpendWFurButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100% !important',
  },
  notice: {},
}));

interface IBossGameComplete {
  realm?: RealmType;
  party?: PlayerPartyFragment;
  refillHearts: () => Promise<void>;
  // screenshots: string[];
}

// export function useRefillHeartsFuelCost(numFurballs: number) {
//   const fuelFactor = useFuelFactor();
//   return numFurballs * fuelFactor * 2;
// }

interface IHaveHealth {
  hearts: number | null;
  maxDailyBattles: number | null;
}

export function useRefillHeartsFurCost(partyMembers: IHaveHealth[]): number {
  // const fuelFactor = useFuelFactor();
  const members = partyMembers.filter(
    (m) => (m.hearts ?? 0) < (m.maxDailyBattles ?? 3),
  );
  let ret = 0;
  // const furPerHp = 1;
  for (let i = 0; i < members.length; i++) {
    ret +=
      ((members[i].maxDailyBattles ?? 3) - (members[i].hearts ?? 0)) * 50000;
  }
  return ret;
}

export const BossGameComplete: FunctionComponent<IBossGameComplete> = (
  props,
) => {
  const { realm, party, refillHearts } = props;
  // const hasDeadMember = props.canRefillHearts;
  const { log } = useFurComponent(BossGameComplete);
  const { reloadParty, loading, currentPartyFurballs } = useBossBattle();
  const hasDeadMember = !!currentPartyFurballs.find((fb) => fb.hearts <= 0);
  const partyId = party?.id;
  const [reloadingPartyId, setReloadingPartyId] = React.useState<
    string | undefined
  >();
  const tokenIds = React.useMemo(
    () => party?.partyMembers?.filter((m) => !!m).map((m) => m!.id) ?? [],
    [partyId],
  );
  const classes = useStyles();
  const common = useCommonStyles();
  const bossName =
    (realm ? getBossNameFromRealm(realm, true) : undefined) ?? '?';
  const refillFurCost = useRefillHeartsFurCost(currentPartyFurballs);
  const history = useHistory();

  function fightAgain() {
    history.push(window.location.pathname.replace('/complete/', '/fight/'));
  }

  React.useEffect(() => {
    // Reloading the party updates furballs AND party contents
    if (partyId && partyId !== reloadingPartyId) {
      setReloadingPartyId(partyId);
      reloadParty(partyId);
    }
  }, [partyId, reloadingPartyId]);

  // React.useEffect(() => {
  //   if (!loading && partyId === reloadingPartyId && party) {
  //     // back to lobby after load
  //     const partySlug = slugify(party.name);
  //     history.push(`/boss/lobby/${partySlug}`);
  //   }
  //   if (!loading) setReloadingPartyId(undefined);
  // }, [loading, partyId, reloadingPartyId]);

  return (
    <Grid container className={clsx(classes.root)} spacing={2}>
      <Grid item xs={1} lg={4}></Grid>
      <Grid item xs={10} lg={4} style={{ marginTop: 20, textAlign: 'center' }}>
        <Card
          className={common.cardRoot}
          style={{
            width: '100%',
          }}
          // maxWidth={'xs'}
        >
          <CardHeader
            className={common.cardHeader}
            classes={{ title: common.cardTitle }}
            sx={{ minHeight: '62px' }}
            title={`${bossName} Game Complete`}
          />
          <CardContent className={common.cardContent}>
            {/*<Typography variant={'h5'}>*/}
            {/*  You have {dustBalance.toLocaleString()} Dust*/}
            {/*</Typography>*/}
            {/*{mintAuction && (*/}
            {/*  <>*/}
            {/*    <Typography variant={'body1'}>*/}
            {/*      To <Link to={'/adopt'}>mint a Furball</Link>, you need:{' '}*/}
            {/*      {mintAuction.currentCost.toLocaleString()} Dust.*/}
            {/*    </Typography>*/}
            {/*    <Typography variant={'subtitle2'}>*/}
            {/*      These Furballs are the best in the game, and tend to*/}
            {/*      sell on secondary markets for thousands of $USD each.*/}
            {/*    </Typography>*/}
            {/*  </>*/}
            {/*)}*/}
            {!party && (
              <>
                <CircularProgress color={'secondary'} size={60} />
              </>
            )}

            <div style={{ textAlign: 'center', padding: 20 }}>
              {/* eslint-disable-next-line react/jsx-no-undef */}
              {loading && <LinearProgress color={'secondary'} />}
              {!loading && (
                <>
                  {!hasDeadMember && (
                    <Button
                      variant={'contained'}
                      className={common.actionButton}
                      onClick={() => fightAgain()}>
                      <Typography variant={'h5'}>
                        Fight {bossName} Again?
                      </Typography>
                    </Button>
                  )}
                  {hasDeadMember && (
                    <SpendWFurButton
                      itemName={'Refill Hearts'}
                      cost={refillFurCost}
                      onAccepted={refillHearts}
                    />
                  )}
                </>
              )}
              {/*{!loading && hasDeadMember && (*/}
              {/*  <Button*/}
              {/*    variant={'contained'}*/}
              {/*    className={common.actionButton}*/}
              {/*    onClick={() => fightAgain()}>*/}
              {/*    Fight {bossName[0].toUpperCase() + bossName.substr(1)} Again?*/}
              {/*  </Button>*/}
              {/*)}*/}
            </div>

            {/*<div*/}
            {/*  style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>*/}
            {/*  <ShareReferButton />*/}
            {/*</div>*/}

            <Typography variant={'body1'} style={{ textAlign: 'center' }}>
              Or, <Link to={'/boss/'}>return to boss lobby</Link>.
            </Typography>

            {/*<Typography variant={'subtitle2'} className={classes.notice}>*/}
            {/*  Leaderboards may take several minutes to update after a battle is*/}
            {/*  finished.*/}
            {/*</Typography>*/}
          </CardContent>
        </Card>
      </Grid>
      {/*<Grid item xs={12} lg={9}>*/}
      {/*  <BossBattleHistory />*/}
      {/*</Grid>*/}
      {/*{party && (*/}
      {/*  <>*/}
      {/*    <Typography variant={'h5'}>*/}
      {/*      Party Size: {party.partyMembers?.length ?? 0}*/}
      {/*      <br />*/}
      {/*      Mint a Furball with Dust*/}
      {/*      <br />*/}
      {/*      Team dead? Resurrect for TIX.*/}
      {/*      <br />*/}
      {/*      Play same game again?*/}
      {/*    </Typography>*/}
      {/*  </>*/}
      {/*)}*/}
    </Grid>
  );
};

export default BossGameComplete;
