import * as React from 'react';

function SvgBossKeyTurtleSea(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 169.32 173.14'
      {...props}>
      <path
        fill='#FFF0AB'
        stroke='#000'
        strokeWidth={3.99996}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M140.462 29.015l14.036 14.036-5.352 5.353-14.036-14.036zM150.586 18.88l14.036 14.037-5.353 5.353-14.035-14.036z'
      />
      <path
        fill='#FFF0AB'
        d='M132.9 126.04s-17.57 12.91-42.46 4.82c0 0 2.18 6.69 8.55 10.73 0 0-19.13 7.62-35.3-11.66a9.641 9.641 0 01-.31-13.31l91.38-97.27a5.053 5.053 0 00-7.14-7.14L50.36 103.6a9.641 9.641 0 01-13.31-.31c-19.28-16.17-11.66-35.3-11.66-35.3 4.04 6.38 10.73 8.55 10.73 8.55-8.09-24.88 4.82-42.46 4.82-42.46C11.39 41.54 4.7 68.91 4.7 68.91c2.88.23 11.35-3.73 11.35-3.73-8.71 18.66-3.11 43.55 2.8 56.3 4.47 9.64 2.33 19.78 1.02 24.16-.27.9.56 1.74 1.47 1.47 4.38-1.31 14.52-3.45 24.16 1.02 12.75 5.91 37.64 11.51 56.3 2.8 0 0-3.97 8.48-3.73 11.35 0 0 27.37-6.69 34.83-36.24z'
      />
      <path
        fill='#FFFBED'
        d='M131.1 129.16c-10.56 13.8-25.37 10.83-31.74 8.66s-8.92-6.95-8.92-6.95l2.63.53a74.493 74.493 0 0036.26-1.71l1.77-.53zM37.86 35.92c-13.8 10.56-10.83 25.37-8.66 31.74s6.95 8.92 6.95 8.92l-.53-2.63a74.493 74.493 0 011.71-36.26l.53-1.77z'
      />
      <path
        fill='#FFF0C5'
        d='M66.81 134.85c16.17 19.28 35.3 11.66 35.3 11.66-2.03-1.29-3.64-2.85-4.88-4.37-5 1.31-19.78 3.48-32.86-11.44a9.702 9.702 0 002.44 4.15z'
      />
      <path
        fill='#FFFBED'
        d='M40.17 108.2a9.641 9.641 0 0013.31.31l97.27-91.38c1.48-1.38 3.58-1.69 5.35-.97.06-1.38-.41-2.77-1.46-3.81a5.053 5.053 0 00-7.01-.13L50.36 103.6a9.641 9.641 0 01-13.31-.31c-2.65-2.22-4.78-4.51-6.51-6.78 2.01 3.86 5.06 7.86 9.63 11.69z'
      />
      <path
        fill='#FFF0C5'
        d='M21.97 126.4c-5.91-12.75-11.51-37.64-2.8-56.3 0 0-2.82 1.32-5.75 2.39-4.66 17.43.27 37.85 5.43 48.99 4.47 9.64 2.33 19.78 1.02 24.16-.27.9.56 1.74 1.47 1.47.73-.22 1.63-.46 2.65-.69.96-5.15 1.36-12.73-2.02-20.02zM104.92 155.84c-1.94.9-3.95 1.65-6 2.26-.54 1.66-.93 3.23-.85 4.18 0 0 1.91-.47 4.84-1.7.98-2.54 2.01-4.74 2.01-4.74z'
      />
      <path
        fill='#FFD974'
        d='M108.08 147.7l.06-.15c.7-1.86-1.04-3.73-2.94-3.13-17.88 5.73-39.59.6-51.21-4.78-9.64-4.47-19.78-2.33-24.16-1.02-.9.27-1.74-.56-1.47-1.47 1.31-4.38 3.45-14.52-1.02-24.16-5.39-11.62-10.52-33.33-4.78-51.21.61-1.89-1.26-3.64-3.13-2.94l-.15.06c-2.62.98-5.14-1.66-4.01-4.22a53.6 53.6 0 016.9-11.51C8.36 53.99 4.7 68.91 4.7 68.91c2.88.23 11.35-3.73 11.35-3.73-8.71 18.66-3.11 43.55 2.8 56.3 4.47 9.64 2.33 19.78 1.02 24.16-.27.9.56 1.74 1.47 1.47 4.38-1.31 14.52-3.45 24.16 1.02 12.75 5.91 37.64 11.51 56.3 2.8 0 0-3.97 8.48-3.73 11.35 0 0 14.92-3.66 25.74-17.47a53.842 53.842 0 01-11.51 6.9c-2.57 1.13-5.21-1.39-4.22-4.01z'
      />
      <path
        fill='#FFF0AB'
        d='M33.04 75.1c1.79 1.01 3.08 1.43 3.08 1.43-1.85-5.69-2.59-10.99-2.65-15.8-.67 2.35-1.81 7.77-.43 14.37zM106.24 133.51c-4.8-.06-10.11-.8-15.8-2.65 0 0 .42 1.29 1.43 3.08 6.61 1.38 12.03.24 14.37-.43z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M132.9 126.04s-17.57 12.91-42.46 4.82c0 0 2.18 6.69 8.55 10.73 0 0-19.13 7.62-35.3-11.66h0a9.641 9.641 0 01-.31-13.31l91.38-97.27a5.053 5.053 0 00-.13-7.01h0a5.053 5.053 0 00-7.01-.13L50.36 103.6a9.641 9.641 0 01-13.31-.31h0c-19.28-16.17-11.66-35.3-11.66-35.3 4.04 6.38 10.73 8.55 10.73 8.55-8.09-24.88 4.82-42.46 4.82-42.46C11.39 41.54 4.7 68.91 4.7 68.91c2.88.23 11.35-3.73 11.35-3.73-8.71 18.66-3.11 43.55 2.8 56.3 4.47 9.64 2.33 19.78 1.02 24.16-.27.9.56 1.74 1.47 1.47 4.38-1.31 14.52-3.45 24.16 1.02 12.75 5.91 37.64 11.51 56.3 2.8 0 0-3.97 8.48-3.73 11.35 0 0 27.37-6.69 34.83-36.24z'
      />
      <g>
        <path
          fill='#FFF0AB'
          d='M121.23 102.68l-30.8-26.13-26.13-30.8 7.46-7.46 30.8 26.13 26.13 30.8z'
        />
        <path
          fill='#FFD974'
          d='M124.92 99l-3.69 3.68-30.8-26.13-26.13-30.8 3.68-3.69'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M121.23 102.68l-30.8-26.13-26.13-30.8 7.46-7.46 30.8 26.13 26.13 30.8z'
        />
      </g>
      <circle fill='#FFD974' cx={96.4} cy={70.09} r={7.77} />
      <g>
        <path
          fill='#FFF0AB'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M107.26 59.72c-5.95-5.95-15.58-5.94-21.53 0-5.94 5.94-5.95 15.58 0 21.53 5.95 5.95 15.58 5.94 21.53 0 5.94-5.94 5.94-15.58 0-21.53zm-16.5 16.5a8.106 8.106 0 010-11.47 8.106 8.106 0 0111.47 0 8.106 8.106 0 010 11.47 8.106 8.106 0 01-11.47 0z'
        />
      </g>
    </svg>
  );
}

export default SvgBossKeyTurtleSea;
