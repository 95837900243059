import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip, Typography } from '@mui/material';
import { useWalletSelector } from '../../hooks';
import { questLibrary } from './questLibrary';
import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';
import { shadeColor } from '../../themev2';
import {
  QuestDefinitionFragment,
  TierRewardType,
  WaypointStyle,
} from '../schema';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import { cdnRoot } from '../../theme';
import { IPlayerQuest } from '../../wallet/WalletTypes';
import SvgIconCircleChecked from '../../assets/SvgIconCircleChecked';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '450px',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '0.75rem',
    marginTop: '2.5rem',
    position: 'relative',

    '&::-webkit-scrollbar': {
      [theme.breakpoints.down('lg')]: {
        width: '0',
      },
    },

    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0',
    },
  },
  quests: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '2rem',
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      gap: '1rem',
    },
  },
  quest: {
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '12px',
    width: '340px',
    height: '390px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',

    [theme.breakpoints.down(400)]: {
      width: '290px',
    },
  },
  waypoints: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.7rem',
    padding: '0.8rem',
    minWidth: '100%',
    borderBottomRightRadius: '12px',
    borderBottomLeftRadius: '12px',
    backgroundColor: shadeColor(theme.palette.secondary.light, 20),

    [theme.breakpoints.down(400)]: {
      width: '290px',
      gap: '0.5rem',
    },
  },
  waypoint: {
    height: '52.5px',
    width: '95px',
    border: '2px solid white',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 600,
    cursor: 'pointer',

    '& span': {
      fontSize: '10px',
    },
  },
  rewards: {
    display: 'flex',
    gap: '0.8rem',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    marginBottom: '1rem',
    marginTop: '0.85rem',
  },
  reward: {
    height: '55px',
    width: '100%',
    borderRadius: '6px',
    padding: '0.5rem 1rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rewardInfo: {
    display: 'flex',
    gap: '0.7rem',
    alignItems: 'center',
  },
  rewardName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  checkmark: {
    position: 'absolute',
    top: '12px',
    left: '12px',

    '& svg': {
      height: '40px',
      width: '40px',
      fill: theme.palette.primary.main,
    },
  },
  completedWaypoint: {
    color: 'black',
    backgroundColor: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.light}`,
  },
  sub: {
    fontSize: '11px !important',
  },
  name: {
    fontSize: '16px !important',
  },
}));

const getWaypointTooltip = (style: WaypointStyle) => {
  if (style === WaypointStyle.Optional) return 'Step is optional';

  if (style === WaypointStyle.RequiredAnyOrder) {
    return 'Can be completed in any order';
  }

  if (style === WaypointStyle.RequiredInOrder) {
    return 'Must be completed in the correct order';
  }

  return '';
};

const getQuestProgress = (
  questName: string,
  playerQuests: IPlayerQuest[] | undefined,
) => {
  if (!playerQuests) return null;

  const questProgress = playerQuests.find(
    (quest) => quest.questName === questName,
  );
  const questDef = questLibrary.find((quest) => quest.name === questName);
  if (!questProgress || !questDef) return null;

  const { completedWaypoints } = questProgress;
  const completed =
    !!completedWaypoints &&
    completedWaypoints.length === questDef.waypoints.length;

  const questStatus: IQuestStatus = {
    completed,
    steps: {},
  };

  questDef.waypoints.forEach((waypoint) => {
    questStatus.steps[waypoint.name] = !!completedWaypoints?.find(
      ({ waypointName }) => waypointName === waypoint.name,
    );
  });

  return questStatus;
};

interface IQuestStatus {
  completed: boolean;
  steps: { [key: string]: boolean };
}

interface IReward {
  rewardId: number;
  rewardType: TierRewardType;
  rewardQuantity: number;
  percentChance: number;
}

interface IQuests {}

const Quests: FunctionComponent<IQuests> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const account = useWalletSelector((s) => s.contents);

  function renderRewards(rewards: IReward[]) {
    return rewards.map((reward, i) => {
      const itemDef = getItemDef(reward.rewardId);
      if (!itemDef) return null;

      const { name, icon, rarity } = itemDef;

      return (
        <div key={`reward-${reward.rewardId}-${i}`} className={classes.reward}>
          <div className={classes.rewardInfo}>
            <img
              src={`${cdnRoot}/images/icons/boss_loot/${icon}.png`}
              height={35}
              width={35}
            />
            <div className={classes.rewardName}>
              <Typography
                variant={'body1'}
                className={classes.name}
                fontWeight={600}>
                {name}
              </Typography>
              <Typography
                variant={'body2'}
                className={classes.sub}
                lineHeight={'0.9rem'}>
                {rarity} {reward.rewardType}
              </Typography>
            </div>
          </div>
          <Tooltip
            title={`${reward.percentChance}% chance to earn this reward`}>
            <Typography
              variant={'body1'}
              fontWeight={600}
              style={{ cursor: 'pointer' }}>
              x {reward.rewardQuantity}
            </Typography>
          </Tooltip>
        </div>
      );
    });
  }

  function renderWaypoints(
    quest: QuestDefinitionFragment,
    questProgress: IQuestStatus | null,
  ) {
    const rewards: IReward[] = [];

    const waypoints = quest.waypoints.map((waypoint, i) => {
      rewards.push(...waypoint.brackets[0].possibleRewards);
      const isCompletedStep = !questProgress
        ? false
        : questProgress.steps[waypoint.name];

      return (
        <Tooltip key={waypoint.name} title={getWaypointTooltip(waypoint.style)}>
          <div
            className={clsx(classes.waypoint, {
              [classes.completedWaypoint]: isCompletedStep,
            })}>
            {i + 1}
            <br />
            <span>{waypoint.name}</span>
          </div>
        </Tooltip>
      );
    });

    return (
      <>
        <div className={classes.rewards}>{renderRewards(rewards)}</div>
        <div className={classes.waypoints}>{waypoints}</div>
      </>
    );
  }

  function renderQuests() {
    return questLibrary.map((quest) => {
      const questProgress = getQuestProgress(quest.name, account?.quests);
      const isCompleted = !!questProgress && questProgress.completed;

      return (
        <div key={quest.name} className={classes.quest}>
          {isCompleted && (
            <div className={classes.checkmark}>
              <SvgIconCircleChecked />
            </div>
          )}
          <div>
            <Typography
              variant={'h5'}
              color={'white'}
              style={{ marginTop: '1rem' }}>
              {quest.name}
            </Typography>
            <Typography
              variant={'body1'}
              color={'white'}
              style={{ opacity: 0.8 }}>
              {isCompleted ? 'Quest Completed' : 'Not Completed'}
            </Typography>
          </div>
          {renderWaypoints(quest, questProgress)}
        </div>
      );
    });
  }

  return (
    <div className={clsx(classes.root, common.styledScrollBar)}>
      <div className={classes.quests}>{renderQuests()}</div>
    </div>
  );
};

export default Quests;
