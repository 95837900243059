import React, { FunctionComponent } from 'react';
import { LinearProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  LeaderboardFragment,
  LeaderboardTierRewards,
  LeaderboardTimeframe,
  FurPlayerBaseFragment,
} from '../../schema';
import Tier, { TierPlaceholder } from './Tier';
import TierPlacing from './TierPlacing';
import { useWalletSelector } from '../../../hooks';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  placeholder: {
    display: 'flex',
    justifyContent: 'center',

    '& p': {
      fontSize: '0.85rem',
      color: '#9E9E9E',
      marginTop: '2.5rem',
    },
  },
}));

interface ILeaderboardTiers {
  leaderboard: LeaderboardFragment;
  boss: string;
  timeframe: LeaderboardTimeframe;
  loading?: boolean;
  emptyMessage?: string;
  playerPlacingRef: (playerPlacingNode: HTMLDivElement) => void;
}

export const LeaderboardTiers: FunctionComponent<ILeaderboardTiers> = (
  props,
) => {
  const {
    leaderboard,
    loading,
    boss,
    timeframe,
    emptyMessage,
    playerPlacingRef,
  } = props;
  const classes = useStyles();
  const addr = useWalletSelector((s) => s.address)?.toUpperCase();

  function renderPlaceholders(num: number) {
    return [...Array(num)].map((a, i) => (
      <TierPlaceholder key={`placeholder-${i}`} />
    ));
  }

  function renderNoData() {
    return (
      <div className={classes.placeholder}>
        <p>{emptyMessage ?? 'No leaderboard data available'}</p>
      </div>
    );
  }

  const { tiers } = leaderboard;
  const numTiers = tiers?.nodes?.length ?? 0;

  if (loading || numTiers <= 0)
    return (
      <>
        {loading && <LinearProgress color={'secondary'} />}
        {numTiers <= 0 && !loading && renderNoData()}
        {renderPlaceholders(3)}
      </>
    );

  let noTierDataCounter = 0;
  let lastTierEndRank = 1;
  const leaderBoardTiers =
    tiers?.nodes?.map((tier) => {
      const playerCount = _.sum(tier.slots.map((s) => s.players.length));
      if (playerCount <= 0) {
        noTierDataCounter++;
      }

      let isInTier = false;
      const tierScores: { [k: string]: number } = {};
      const sortedSlots = [...tier.slots].sort(
        (a, b) => a.position - b.position,
      );
      const players = sortedSlots
        .map(({ score, players }) => {
          players.forEach(({ id }) => {
            if (!id) return;
            tierScores[id] = score;

            if (!isInTier) {
              isInTier = id.toUpperCase() === addr;
            }
          });

          return players;
        })
        .flat() as FurPlayerBaseFragment[];

      const tierEle = (
        <Tier
          key={`${boss}-${leaderboard.id}-${tier.id}-${timeframe}`}
          tier={tier.position}
          isInTier={isInTier}
          boss={boss}
          lastTier={tier.position + 1 === lastTierEndRank}
          rewards={tier.rewards as LeaderboardTierRewards}
          playerCount={playerCount}
          players={
            <TierPlacing
              players={players}
              scores={tierScores}
              currentUserId={addr}
              lastTierEndRank={lastTierEndRank}
              playerPlacingRef={playerPlacingRef}
            />
          }
        />
      );

      lastTierEndRank += players.length;
      return tierEle;
    }) ?? [];

  // if (noTierDataCounter === leaderBoardTiers.length) {
  //   return renderNoData();
  // }

  return <>{leaderBoardTiers}</>;
};

export default LeaderboardTiers;
