import { SvgEditionSlot } from '../part';
import * as React from 'react';

const MermanPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#a6fad0'
      d='M241.91 204.04c-55.63 29.76-98.12-3.98-104.4-10.36l-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53 0-.04.01-.07.01-.11.02-.17.05-.34.07-.51.57-4.02 1.5-6.96 2.58-9.11z'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M119.86 203.08c.76 9.82 9.27 13.08 18.11 5.74l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-1.48 12.86 8.07 18.02 18.03 9.75v.02c-1.48 12.86 8.07 18.02 18.02 9.75l.01-.01v.03c-1.48 12.85 8.07 18.02 18.02 9.75l.02-.02v.04c-1.48 12.85 8.06 18.02 18.02 9.76l.04-.03-.01.05'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M233.09 256.8c-5.76.07-9.77-5.09-8.78-13.74l.01-.05-.04.03c-9.95 8.26-19.49 3.09-18.02-9.76v-.04l-.02.02c-9.96 8.27-19.5 3.1-18.02-9.75v-.03l-.01.01c-9.96 8.27-19.5 3.1-18.02-9.75v-.02c-9.88 8.21-19.35 3.19-18.05-9.45l-.21-.12c-9.53 7.73-20.94 1.89-15.72-10.96'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M185.13 216.48c2.31 6.99 9.75 8.62 17.44 2.24l.01-.01v.03c-1.48 12.85 8.07 18.02 18.02 9.75l.02-.02v.04c-1.48 12.85 8.06 18.02 18.02 9.76'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M238.9 236.08c-3.05-2.06-4.78-6.39-4.09-12.41l.01-.05-.04.03c-9.61 7.97-18.83 3.44-18.13-8.44M156.75 281.66v-.03l-.01.01c-9.96 8.27-19.5 3.1-18.02-9.75v-.02c-9.96 8.27-19.51 3.11-18.03-9.75v-.01l-.02.02c-9.96 8.26-19.5 3.1-18.02-9.75v-.03l-.02.02c-9.96 8.26-19.5 3.1-18.02-9.76v-.03l-.02.01c-7.86 6.53-15.46 4.67-17.58-2.72'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M61.43 251.43c2.73-.11 5.84-1.38 8.99-3.99l.01-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.75l.02-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.76l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-1.48 12.86 8.07 18.02 18.03 9.75v.02c-.33 2.85-.11 5.32.53 7.35M86.39 290.36c-5.57-.14-9.39-5.27-8.42-13.75v-.03l-.02.01c-9.96 8.27-19.5 3.1-18.02-9.75'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M59.15 263.81c3.41 3.24 9.11 3.01 14.95-1.84l.02-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.76l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-.69 5.97 1.01 10.28 4.01 12.36M92.57 217.61c2.8 5.77 9.73 6.76 16.87.83l.02-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.76l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-1.48 12.86 8.07 18.02 18.03 9.75v.02c-1.48 12.86 8.07 18.02 18.02 9.75l.01-.01v.03c-1.48 12.85 8.07 18.02 18.02 9.75l.02-.02v.04c-1.48 12.85 8.06 18.02 18.02 9.76l.04-.03-.01.05'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M221.1 276.08c-4.94-.77-8.18-5.76-7.28-13.64l.01-.05-.04.03c-9.95 8.26-19.49 3.09-18.02-9.76v-.04l-.02.02c-9.96 8.27-19.5 3.1-18.02-9.75v-.03l-.01.01c-9.96 8.27-19.5 3.1-18.02-9.75v-.02c-9.96 8.27-19.51 3.11-18.03-9.75v-.01l-.02.02c-9.96 8.26-19.5 3.1-18.02-9.75v-.03l-.02.02c-7.99 6.63-15.71 4.61-17.68-3.09M80.32 225.2c3.43 3.07 9.03 2.77 14.77-1.99l.02-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.76l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-1.48 12.86 8.07 18.02 18.03 9.75v.02c-1.48 12.86 8.07 18.02 18.02 9.75l.01-.01v.03c-1.48 12.85 8.07 18.02 18.02 9.75l.02-.02v.04c-1.48 12.85 8.06 18.02 18.02 9.76l.04-.03-.01.05c-.35 3.08-.07 5.71.7 7.83'
    />
    <path
      fill='none'
      stroke='#64dc97'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m80.91 228.06.01-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.75l.02-.01v.03c-1.48 12.86 8.06 18.02 18.02 9.76l.02-.02v.03c-1.48 12.85 8.06 18.02 18.02 9.75l.02-.02v.01c-1.48 12.86 8.07 18.02 18.03 9.75v.02c-1.48 12.86 8.07 18.02 18.02 9.75l.01-.01v.03c-1.48 12.85 8.07 18.02 18.02 9.75l.02-.02v.04M239.28 222.81c-4.76-.58-8.02-5.01-7.68-12.13'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path d='M91.75 281c10.01 1.14 11.92 12.9 11.92 12.9h-24.2c-1.15 0-3.17-4.75-4.15-7.36-.29-.77.15-1.63.95-1.85.36-.1.74-.06 1.06.13 1.17.67 4.36 2.22 7.67 1.42 5.51-1.34 6.6-5.25 6.75-5.24zM161.98 290.02c.51 1.6 2.04 2.69 3.66 2.69h22.47c.55 0 .98-.47.92-1.01-.06-10.73-11.02-12.53-11.73-12.19-3.24 1.52-12.55 4.25-16.12 3.34a.824.824 0 0 0-1 1.03l1.8 6.14z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01M154.48 268.6l5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <linearGradient
      id='MermanPower__a'
      gradientUnits='userSpaceOnUse'
      x1={223}
      y1={-635}
      x2={94}
      y2={-732}
      gradientTransform='matrix(1 0 0 -1 0 -388)'>
      <stop
        offset={0}
        style={{
          stopColor: '#f9c6f6',
        }}
      />
      <stop
        offset={0.181}
        style={{
          stopColor: '#a4d0ff',
        }}
      />
      <stop
        offset={0.301}
        style={{
          stopColor: '#9dfadf',
        }}
      />
      <stop
        offset={0.429}
        style={{
          stopColor: '#bafbb8',
        }}
      />
      <stop
        offset={0.581}
        style={{
          stopColor: '#ffef9c',
        }}
      />
      <stop
        offset={0.737}
        style={{
          stopColor: '#ffd3f4',
        }}
      />
      <stop
        offset={0.935}
        style={{
          stopColor: '#d8d9fd',
        }}
      />
    </linearGradient>
    <path
      fill='url(#MermanPower__a)'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
  </svg>
);

export default MermanPower;
