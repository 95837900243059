import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip } from '@mui/material';
// import clsx from 'clsx';
import SelectCustom, { ISelectProps } from '../../../components/SelectCustom';
import SvgIconSortOrder from '../../../assets/SvgIconSortOrder';
import { SortEnumType } from '../../../components/schema';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.3rem',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  select: {
    minWidth: '15rem !important',

    '&[data-placeholder="true"]': {
      '& p': {
        color: theme.palette.grey[500],
      },
    },
  },

  sortIcon: {
    display: 'grid',
    placeItems: 'center',
    pointerEvents: 'auto',
    transition: 'all 0.3s linear',
    padding: '0.2rem',
    borderRadius: '10px',

    '&[data-descending="true"]': {
      transform: 'rotate(180deg)',
    },

    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },

  tooltipTitle: {
    textTransform: 'capitalize',
  },
}));

export enum SORTKEYS {
  NUMBER = 'number',
  LEVEL = 'level',
  MAXHEALTH = 'maxHealth',
  ATTACKPOWER = 'attackPower',
  DEFENCEPOWER = 'defencePower',
  BUFF = 'buff',
  HEAL = 'heal',
  DEBUFF = 'deBuff',
  CRITRATE = 'critRate',
  CRITATTACKPOWER = 'critAttackPower',
  SPEED = 'speed',
}

export const sortKeys: SORTKEYS[] = Object.values(SORTKEYS);

export interface IMarketSortParams {
  sortBy?: string;
  setSortBy: React.Dispatch<React.SetStateAction<SORTKEYS | undefined>>;
  sortOrder?: SortEnumType;
  setSortOrder: React.Dispatch<React.SetStateAction<SortEnumType>>;
}

interface IMarketSortProps extends ISelectProps, IMarketSortParams {
  noMargin?: boolean;
  minWidth?: number;
  offsetRight?: string;
  smallSelect?: boolean;
}

export const MarketSort: FunctionComponent<IMarketSortProps> = ({
  sortBy,
  sortOrder,
  setSortBy,
  setSortOrder,
  noMargin,
  minWidth,
  offsetRight,
  smallSelect,
  ...selectProps
}) => {
  const classes = useStyles();

  const sortOptions = ['Sort By', ...sortKeys].map((key, index) => ({
    label: key,
    value: key,
    hidden: index === 0,
  }));

  return (
    <div className={classes.container}>
      <SelectCustom
        iconColor='secondary'
        labelId='furball-sort-by'
        id='furball-sort-by'
        options={sortOptions}
        value={sortBy}
        fullWidth
        defaultValue='Sort By'
        data-placeholder={!sortBy}
        className={classes.select}
        icon={
          <Tooltip
            title={
              <span
                className={
                  classes.tooltipTitle
                }>{`${sortOrder?.toLowerCase()}ending`}</span>
            }>
            <span
              role='button'
              className={classes.sortIcon}
              data-descending={sortOrder === SortEnumType.Desc}
              onClick={() =>
                setSortOrder(
                  sortOrder === SortEnumType.Asc
                    ? SortEnumType.Desc
                    : SortEnumType.Asc,
                )
              }>
              <SvgIconSortOrder width='1.5rem' height='1.5rem' />
            </span>
          </Tooltip>
        }
        onChange={(e) => setSortBy(e.target.value as SORTKEYS)}
        {...selectProps}
      />
    </div>
  );
};
