import * as React from 'react';

function SvgMobileMenu(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 74.72 61.79'
      {...props}>
      <defs>
        <style>{'.mobile-menu_svg__cls-1{fill:#5430e0}'}</style>
      </defs>
      <g id='mobile-menu_svg__Layer_2' data-name='Layer 2'>
        <g id='mobile-menu_svg__menu'>
          <rect
            className='mobile-menu_svg__cls-1'
            y={52.92}
            width={74.72}
            height={8.88}
            rx={4.44}
            fill='#5430e0'
            stroke='#5430e0'
          />
          <rect
            className='mobile-menu_svg__cls-1'
            y={26.46}
            width={74.72}
            height={8.88}
            rx={4.44}
          />
          <rect
            className='mobile-menu_svg__cls-1'
            width={74.72}
            height={8.88}
            rx={4.44}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgMobileMenu;
