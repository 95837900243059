import { ILogManager, ILogSink, ILogEntry, LogLevel } from './types';
import Logger from 'js-logger';

class JsLogSink implements ILogSink {
  public readonly defaultSinkKey = 'js';

  public logManager: ILogManager;

  public constructor(logManager: ILogManager) {
    Logger.useDefaults();
    this.logManager = logManager;
  }

  public logEntry(entry: ILogEntry): void {
    // The electron app decides if it is filtered or not (separate minimum levels)
    // ElectronApp.log(logEntry);
    const logger = Logger.get(entry.context);
    const parts = this.getLogEntryParts(entry);
    const logLevel = entry.level;
    if (logLevel === LogLevel.verbose) {
      logger.trace(...parts);
    } else if (logLevel === LogLevel.debug) {
      logger.debug(...parts);
    } else if (logLevel === LogLevel.info) {
      logger.info(...parts);
    } else if (logLevel === LogLevel.warn) {
      logger.warn(...parts);
    } else if (logLevel === LogLevel.error) {
      logger.error(...parts);
    } else {
      logger.log(...parts);
    }
  }

  // JsLogger doesn't need the context or level due to better color-coding.
  protected getLogEntryParts(entry: ILogEntry): unknown[] {
    const parts: unknown[] = [
      `${entry.timestamp}`,
      `<${LogLevel[entry.level]}>`,
    ];
    if (entry.context) parts.push(`[${entry.context}]`);
    return parts.concat(entry.messages);
  }
}

export default JsLogSink;
