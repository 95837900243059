import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import useCommonStyles from '../../useCommonStyles';
import { bodyFont, white } from '../../../themev2';
import { cdnRoot } from '../../../theme';
import BossRewardsReveal from './BossRewardsReveal';
import {
  ItemDefinitionFragment,
  useClaimPendingRewardsMutation,
  useReadNotificationMutation,
} from '../../schema';
import { useAppDispatch } from '../../../hooks';
import { IClaimedRewards, IPendingRewards } from '../../../wallet/WalletTypes';
import WalletSlice from '../../../wallet/WalletSlice';
import useBossFightsStyles from '../useBossFightsStyles';
import {
  getBossIconSrc,
  getItemDefs,
  isSeasonalReward,
} from './bossRewardsUtils';
import { getTopIllustrationPos } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    backgroundColor: (props: any) =>
      props.onLeaderboardPage
        ? theme.palette.secondary.main
        : theme.palette.secondary.light,
    backgroundImage: (props: any) =>
      `url(${cdnRoot}/images/backgrounds/leaderboard_${props.bossName}.svg)`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: (props: any) => (props.onLeaderboardPage ? '550px' : '475px'),
  },
  rewardsCont: {
    marginTop: 'auto',
    borderRadius: '12px',
    height: '300px',
    width: '100%',
    maxWidth: '415px',
    backgroundColor: white,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '12px',
    position: 'relative',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
  text: {
    textAlign: 'center',
    maxWidth: '220px',
    margin: '15px auto !important',
    lineHeight: '23px !important',
    fontSize: '1rem !important',
    fontFamily: `${bodyFont} !important`,

    '& span': {
      color: theme.palette.secondary.main,
    },
  },
  bossTop: {
    zIndex: 1000,
    position: 'absolute',
  },
  button: {
    height: '45px',
    width: '220px',
  },
  preload: {
    display: 'none',
  },
  seasonalBanner: {
    zIndex: 1001,
    position: 'absolute',
    left: -17,
    top: -23,
  },
  rightTopIllustration: {
    right: 20,
  },
}));

export enum BossName {
  Trashy = 'trashy',
  Sebastian = 'sebastian',
  Skullumbo = 'skullumbo',
}

export interface IBossRewards {
  bossName: string;
  pendingReward: IPendingRewards;
}

interface IBossRewardsClaim extends IBossRewards {
  onLeaderboardPage: boolean | undefined;
}

const SHAKE_ANIM_DELAY = 3000;

const BossRewardsClaim: FunctionComponent<IBossRewardsClaim> = (props) => {
  const { bossName, pendingReward, onLeaderboardPage } = props;
  const classes = useStyles({ bossName, onLeaderboardPage });
  const bossStyles = useBossFightsStyles();
  const common = useCommonStyles();

  const dispatch = useAppDispatch();
  const seasonal = isSeasonalReward(pendingReward.source);
  const iconSrc = getBossIconSrc(seasonal);

  const itemDefsRef = useRef<{ [p: string]: ItemDefinitionFragment }>(
    getItemDefs(pendingReward.items),
  );
  const [openReveal, setOpenReveal] = useState<boolean>(false);
  const [runShakeAnim, setRunShakeAnim] = useState<boolean>(false);
  const [claim, { data, loading }] = useClaimPendingRewardsMutation();
  const [markAsRead] = useReadNotificationMutation({
    refetchQueries: ['UnreadNotifications'],
  });

  useEffect(() => {
    const animTimeoutId = setTimeout(() => {
      setRunShakeAnim(!runShakeAnim);
    }, SHAKE_ANIM_DELAY);

    return () => clearTimeout(animTimeoutId);
  }, [runShakeAnim]);

  useEffect(() => {
    itemDefsRef.current = getItemDefs(pendingReward.items);
  }, [pendingReward]);

  function onClose() {
    dispatch(
      WalletSlice.actions.updateUserAfterClaimedRewards({
        ...(data?.claimPendingReward as IClaimedRewards),
      }),
    );

    setOpenReveal(false);
  }

  async function claimRewards() {
    const { id, notificationId } = pendingReward;

    try {
      await claim({ variables: { pendingRewardId: id } });
      setOpenReveal(true);

      if (!!notificationId) {
        await markAsRead({ variables: { notificationIds: [notificationId] } });
      }
    } catch (e) {
      console.error('error', e);
    }
  }

  function renderPreloadIcons() {
    // by 'preloading' icons we avoid bad animations that can
    // occur if the icon finished loading mid or after reveal animations
    return Object.keys(itemDefsRef.current).map((key) => {
      return (
        <img
          src={`${cdnRoot}/images/icons/boss_loot/${itemDefsRef.current[key].icon}.png`}
          key={`preload-${key}-${bossName}`}
          className={classes.preload}
        />
      );
    });
  }

  return (
    <>
      <div className={clsx(classes.root, bossStyles.bossCard)}>
        <div className={classes.rewardsCont}>
          {seasonal && (
            <div className={classes.seasonalBanner}>
              <img
                src={`${cdnRoot}/svgs/seasonal_loot_banner.svg`}
                height={60}
              />
            </div>
          )}
          <div
            className={clsx(classes.bossTop, {
              [classes.rightTopIllustration]: seasonal,
            })}
            style={{
              top: getTopIllustrationPos(bossName),
            }}>
            <img
              src={`${cdnRoot}/images/leaderboard_top_${bossName}.png`}
              height={150}
            />
          </div>
          <img
            className={clsx({
              [bossStyles.shakeAnimation]: runShakeAnim,
            })}
            src={iconSrc}
            height={100}
            width={100}
          />
          <Typography variant={'h6'} className={classes.text} fontWeight={800}>
            Congratulations, you <br /> ended the
            {seasonal ? ' season' : ' week'} in{' '}
            <span>
              Tier{' '}
              {pendingReward.sourcePosition === null
                ? 'UNKNOWN'
                : pendingReward.sourcePosition + 1}
            </span>
          </Typography>
          <Button
            variant={'contained'}
            className={clsx(classes.button, common.actionButton)}
            disabled={loading}
            style={{
              opacity: loading ? 0.5 : 1,
            }}
            color={'primary'}
            onClick={claimRewards}>
            {loading ? (
              <CircularProgress color='inherit' size={20} />
            ) : (
              <Typography variant={'h6'}>Claim Rewards!</Typography>
            )}
          </Button>
        </div>
      </div>
      {openReveal && (
        <BossRewardsReveal
          bossName={bossName}
          onClose={onClose}
          itemDefs={itemDefsRef.current}
          pendingReward={pendingReward}
          iconSrc={iconSrc}
        />
      )}
      {renderPreloadIcons()}
    </>
  );
};

export default BossRewardsClaim;
