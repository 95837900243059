import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme, Typography } from '@mui/material';
import Sky from '../../components/Sky';
import ShopCard from './ShopCard';

import SvgShopInventory from '../../assets/SvgShopInventory';
import SvgShopFurball from '../../assets/SvgFurball';
import useCommonStyles from '../../components/useCommonStyles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { useWalletSelector } from '../../hooks';
import { cdnRoot } from '../../theme';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'scroll',
    textAlign: 'center',
  },
  mountains: {
    backgroundImage: `url(${cdnRoot}/svgs/mountains.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: '300px',
  },
}));

interface OwnProps {}

type Props = OwnProps;

const Shop: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const history = useHistory();
  const { log } = useFurComponent(Shop);
  const furballBalance = useWalletSelector(
    (s) => s.contents?.furballBalance ?? 0,
  );
  const hasFb = furballBalance > 0;

  function pushUpgrade(page: string) {
    log.info(furballBalance);
  }

  return (
    <Sky className={clsx(classes.root, common.fullScreen, common.fullPadding)}>
      <div className={classes.mountains} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h4'}>Redeem $wFUR</Typography>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShopCard title={'Furball'} callback={() => history.push('/adopt')}>
            <SvgShopFurball />
            <Typography variant={'subtitle2'}>
              In each edition, many of the Furballs may{' '}
              <span style={{ fontWeight: 900, textDecoration: 'underline' }}>
                only
              </span>{' '}
              be minted using $FUR.
            </Typography>
          </ShopCard>
        </Grid>
        <Grid item xs={12} lg={4}>
          <ShopCard
            title={'Upgrade'}
            callback={!hasFb ? undefined : () => history.push('/shop/upgrade')}>
            <SvgShopInventory />
            <Typography variant={'subtitle2'}>
              Get a chance to upgrade a single loot item to the next rarity
              level!
            </Typography>
          </ShopCard>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} style={{ position: 'relative' }}>
            $wFUR is an in-game "utility-token" (without real value).
            <br />
            It is meant{' '}
            <span style={{ textDecoration: 'underline' }}>only</span> to enhance
            the gameplay experience.
          </Typography>
        </Grid>
      </Grid>
    </Sky>
  );
};

export default Shop;
