import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Cat = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.37 203.59c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h113.96c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.56-4.34 1.53-7.47 2.66-9.73z'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M154.48 239.96s-3.96 8.42-16.16 6c0 0 7.97 6.36 18.24 3.04M138.95 241.04s14.84-4.75 14.69-13.78M148.59 230.22s2.58-8 .71-10.83c0 0-8.04 16.6-17.44 21.79M143.79 209.54s-6.86 6.51-5.47 15.84c0 0 5.55-5.72 5.47-7.47M138.32 208.9s-7.48 2.04-8.6 18.08M133.77 226.97s3.19 11.91-10.42 14.41M117.29 242.6s9.04-5.36 6.22-21.06c-2.31-12.84 6.22-18.56 6.22-18.56s-4.42 6.5-1.89 12.73M118.74 220.92s-6.41-10.39-3.93-14.02c0 0-6.25 6.43-3.38 14.02M103.9 211.57s-8.19 10.44-.41 11.63c7.18 1.1 6.67 6.78 6.67 6.78M105.53 231.33s3.86 5.75 4.08 9.52c.22 3.77 0 9.95 5.2 11.6'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M116.09 226.97s3.73 9.61-6.34 16.69M102.64 242.6s2.88-11.27-6.11-14.75c-3.68-1.42-3.31-8.49-4.52-9.94 0 0-4.09 8.97 1.16 14.74 0 0-4.51-3.69-7.48-2.87s-5.78-3.37-5.46-4.52'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M74.37 231.33s5.32 4.23 11.03 2.38c5.71-1.84 7.76 7.47 7.76 7.47M96.7 233.08s-2.09 10.87 8.47 15.92M86.7 240.85s2.35 8.74 9.83 9.67M85.31 247.2s-1.86-12.61-13.95-12.42'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M77.66 239.17s4.08 4.34 3.65 9.83M86.87 252.34s-8.02 0-7.65 10.89M78.33 249.92s-3.45-1.75-3.96-6.18c-.57-4.9-9.26-.68-9.26-.68s2.94 12.63 10.95 9.18M73.3 267.17s-3.27-8.09-12.75-6.28c0 0 6.8-6.14 12.75-2.49 3.1 1.9 3.54 5.91 3.54 5.91M65.12 265.48s2.79 3.46 2.7 8.39c-.09 4.93 7.29 12.14 7.29 12.14s-2.37-5.17-.73-8.98c1.63-3.81-3.02-6.51-3.02-6.51M117.29 248.38s2.23 16.62 12.99 16.95c10.77.34 11.63-.53 11.63-.53s-1.95-6.42-10.31-5.73-9.68-13.18-9.68-13.18 5.04 9.08 12.13 6.55M133.2 244.44s.21 6.95 8.11 8.47M143.16 258.4s1.55-3.23 10.48-1.16c8.93 2.07 10.83-8.86 10.83-8.86s7.41 11.99-5.93 13.07c-8.08.65-9.7 5.53-9.7 5.53M159.73 244.08s5.95-10.96 14.97-11.01c0 0-6.69 3.77-6.23 11.54M156.18 234.78s2-6.4 8.12-6.54c6.12-.14 10.4-7.32 10.4-7.32s1.1 6.56-11.17 12.97M156.57 228.5s4.6-4.01 3.86-10.59M174.14 242.6s5.45-14.1 15.69-14.1c0 0-4.35 12.35-13.85 20.02 0 0 15.33-2.84 19.82-15.44 4.48-12.6 13.88-12.61 13.88-12.61M197.55 238.64c3.87-12.25 12.95-12.03 12.95-12.03s-11.05 6.51-9.56 21.9M182.98 253.23s5.18-8.72 13.33-7.39M180.97 256.97s9.36.15 12.24-6.27'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M198.24 248.52s-3.17 5.31 1.76 11.7c0 0 8.74-12.55 8.42-23.68M212.25 244.7s-1.43-10.34 6.08-16.46M232.7 216.64s0 10.62-10.86 16.01c-6.2 3.08-4.07 10.67-4.07 10.67M210.1 253.1s12.96-.44 14.83-13.93c0 0 3.38 15.31-3.09 17.8-6.47 2.5-11.32 2.56-14.02 8.36M237.9 227.26s-9.62 5.27-7.39 18.64'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M207.82 258.36s-3.17 4.01-4.56 10.24c-1.4 6.3-8.09 7.14-8.09 7.14s11.68.82 16.19-8.57M193.46 223.82s-3.81-4.5-.86-7.18c0 0-8.11 1.29-14.96 11.86M147.42 271.39s-8.53-6.6-10.35.93c-1.52 6.3-6.58 3.28-6.58 3.28M100.14 259.08s-5.69 2.2-6.35 9.64c-.94 10.6-8.18 10.7-8.18 10.7M111.36 256.28s1.92 10.9 13.59 11.86M88.14 272.96s-8.38 1.42-8.37 8.95M81.66 270.37s12.29-3.41 10.59-15.39M178.55 275.67s6.81-1.41 6.93-9.46'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill='none'
      stroke={props.renderer.color2}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M160.83 277.49s11.54 1.46 13.87-7.9c.95-3.83 8.51-6.94 9.8-8.32 0 0-9.9-.34-14.98 5.58 0 0 3.1-4.93 1.91-7.78-1.19-2.85 2.62-6.16 3.8-5.98'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Cat;
