import { ItemDefinitionFragment } from '../schema';
import itemDefinitions from './itemDefinitions.json';

/**
 * Use the following query to update the itemDefinitions.json...
 */

/*
fragment Ingredient on CraftRecipeIngredient {
  itemType
  rarity
  amount
  realm
}

fragment Recipe on CraftingRecipe {
  id
  duration
  itemId
  ingredients { ...Ingredient }
}

fragment PassiveEffectRange on PassiveEffectRange {
  valueBase
  valueRange
  operand
  stat
}

fragment ItemDefinition on ItemDefinition {
  id
  icon
  itemId
  itemGroup
  durabilityTime
  name
  rarity
  realmAffiliation
  untradeable
  allowInFurballInventory
  upgradeToItemId
  passives { ...PassiveEffectRange }
  recipe { ...Recipe }
}

query {
  itemDefinitions {
    ...ItemDefinition
  }
}
*/

export const itemLibrary = itemDefinitions.data
  .itemDefinitions as ItemDefinitionFragment[];
