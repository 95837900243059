import React from 'react';
import { ILootItem } from './LootTypes';
import SvgLootFrisbee from '../../assets/SvgLootFrisbee';
import SvgLootShoe from '../../assets/SvgLootShoe';
import SvgLootLaserPointer from '../../assets/SvgLaserPointer';
import SvgLootFurspray from '../../assets/SvgLootFurspray';
import SvgLootFurtilizer from '../../assets/SvgLootFurtilizer';
import SvgLootFurgaine from '../../assets/SvgLootFurgaine';
import SvgLootSnowballs from '../../assets/loot/LootSnowballs';
import SvgLootPresent from '../../assets/loot/LootPresent';
import SvgLootNet from '../../assets/loot/LootNet';
import SvgLootFlowers from '../../assets/loot/LootFlowers';
import SvgLootGhostPotion from '../../assets/loot/LootGhostPotion';
import SvgBossKeyTurtleSea from '../../assets/voyages/SvgRealmBossKeyTurtleSea';
import SvgRealmBossKeyWizardsPath from '../../assets/voyages/SvgRealmBossKeyWizardsPath';
import SvgRealmBossKeyBoneyard from '../../assets/voyages/SvgRealmBossKeyBoneyard';
import SvgIconLootNone from '../../assets/voyages/SvgIconLootNone';
import _ from 'lodash';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import { getRarityLevelNumber } from '../../pages/Play';
import { cdnRoot } from '../../theme';
import SvgKeyTrashy from '../../assets/SvgKeyTrashy';
import SvgKeySebastien from '../../assets/SvgKeySebastien';
import SvgKeySkullumbo from '../../assets/SvgKeySkullumbo';
import { RealmType } from '../schema';
import { IFurball } from '../../wallet/WalletTypes';

export const LOOT_ID_EXP_1_SHOE = 0x10000;
export const LOOT_ID_EXP_2_FRISBEE = 0x20000;
export const LOOT_ID_EXP_3_LASER_POINTER = 0x30000;

export const LOOT_ID_FUR_1_SPRAY = 0x10100;
export const LOOT_ID_FUR_2_TILIZER = 0x20100;
export const LOOT_ID_FUR_3_GAINE = 0x30100;

export const LOOT_ID_KEY_TURTLE_SEA = 0x010401;
export const LOOT_ID_KEY_WIZARDS_PATH = 0x010501;
export const LOOT_ID_KEY_BONEYARD = 0x010601;

export const LOOT_ID_LUCK_1_SNOWBALLS = 0x10200;

export const LOOT_ID_MYSTERY_BOX = 0x000101;

export const LOOT_IDS_STAT_EXP = [
  LOOT_ID_EXP_1_SHOE,
  LOOT_ID_EXP_2_FRISBEE,
  LOOT_ID_EXP_3_LASER_POINTER,
];

export const LOOT_IDS_STAT_FUR = [
  LOOT_ID_FUR_1_SPRAY,
  LOOT_ID_FUR_2_TILIZER,
  LOOT_ID_FUR_3_GAINE,
];

export const LOOT_IDS_STAT_LUCK = [LOOT_ID_LUCK_1_SNOWBALLS];

export const LOOT_IDS_HOLIDAY_GIFTS = [
  LOOT_ID_LUCK_1_SNOWBALLS,
  LOOT_ID_MYSTERY_BOX,
];

export const LOOT_IDS_BOSS_KEYS = [
  LOOT_ID_KEY_TURTLE_SEA,
  LOOT_ID_KEY_WIZARDS_PATH,
  LOOT_ID_KEY_BONEYARD,
];

export const LOOT_IDS_STATS = [
  ...LOOT_IDS_STAT_EXP,
  ...LOOT_IDS_STAT_FUR,
  ...LOOT_IDS_STAT_LUCK,
];
export const LOOT_IDS_ALL = _.uniq([
  ...LOOT_IDS_STATS,
  ...LOOT_IDS_HOLIDAY_GIFTS,
  ...LOOT_IDS_BOSS_KEYS,
]);

export enum BossKeyDefinition {
  TURTLE_SEA = LOOT_ID_KEY_TURTLE_SEA,
  BONE_YARD = LOOT_ID_KEY_BONEYARD,
  WIZARDS_PATH = LOOT_ID_KEY_WIZARDS_PATH,
}

export const defaultBossKeys: BossKeyDefinition[] = [
  BossKeyDefinition.TURTLE_SEA,
  BossKeyDefinition.WIZARDS_PATH,
  BossKeyDefinition.BONE_YARD,
];

export function getBossKeySvg(keyName: BossKeyDefinition): React.ReactNode {
  if (keyName === BossKeyDefinition.TURTLE_SEA) return <SvgKeyTrashy />;
  if (keyName === BossKeyDefinition.WIZARDS_PATH) return <SvgKeySebastien />;
  if (keyName === BossKeyDefinition.BONE_YARD) return <SvgKeySkullumbo />;
  return null;
}

export function getBossKeyRealm(keyName: BossKeyDefinition): RealmType {
  if (keyName === BossKeyDefinition.TURTLE_SEA) return RealmType.TurtleSea;
  if (keyName === BossKeyDefinition.WIZARDS_PATH) return RealmType.WizardsPath;
  if (keyName === BossKeyDefinition.BONE_YARD) return RealmType.Boneyard;
  return RealmType.TurtleSea;
}

export const verifyBossKeys = (
  furball: IFurball,
  keyIds: BossKeyDefinition[] = [...defaultBossKeys],
) => {
  const furballBossKeys: number[] = [];

  keyIds.forEach((keyId) => {
    const furballHasThisKey = furball.inventory.items.find(
      (item) => item.itemId === keyId,
    );

    furballHasThisKey && furballBossKeys.push(keyId);
  });

  return furballBossKeys;
};

export function getAllLootItems(): ILootItem[] {
  return LOOT_IDS_ALL.map((i) => getLootItem(i));
}

export function getLootItem(hex: number): ILootItem {
  const lootId = hex;
  // BUG with TK loot drops early
  if (hex === 1 || hex === 0x100)
    return {
      lootId: 0x30100,
      stat: 'fur',
      name: 'Fur-gaine',
      rarity: 3,
      icon: <SvgLootFurgaine />,
    };

  if (hex === LOOT_ID_EXP_1_SHOE)
    return {
      lootId,
      stat: 'exp',
      name: 'Shoe',
      rarity: 1,
      icon: <SvgLootShoe />,
    };
  if (hex === LOOT_ID_EXP_2_FRISBEE)
    return {
      lootId,
      stat: 'exp',
      name: 'Frisbee',
      rarity: 2,
      icon: <SvgLootFrisbee />,
    };
  if (hex === LOOT_ID_EXP_3_LASER_POINTER)
    return {
      lootId,
      stat: 'exp',
      name: 'Laser Pointer',
      rarity: 3,
      icon: <SvgLootLaserPointer />,
    };
  if (hex === LOOT_ID_FUR_1_SPRAY)
    return {
      lootId,
      stat: 'fur',
      name: 'Fur-spray',
      rarity: 1,
      icon: <SvgLootFurspray />,
    };
  if (hex === LOOT_ID_FUR_2_TILIZER)
    return {
      lootId,
      stat: 'fur',
      name: 'Fur-tilizer',
      rarity: 2,
      icon: <SvgLootFurtilizer />,
    };
  if (hex === LOOT_ID_FUR_3_GAINE)
    return {
      lootId,
      stat: 'fur',
      name: 'Fur-gaine',
      rarity: 3,
      icon: <SvgLootFurgaine />,
    };
  if (hex === LOOT_ID_LUCK_1_SNOWBALLS)
    return {
      lootId,
      stat: 'luck',
      name: 'Snowballs',
      rarity: 1,
      icon: <SvgLootSnowballs />,
      description:
        'Boosts Luck (chance at finding/upgrading loot) and gives a special holiday background.',
    };

  if (hex === LOOT_ID_MYSTERY_BOX)
    return {
      lootId,
      name: 'Mystery Box',
      rarity: 3,
      icon: <SvgLootPresent />,
      description:
        'Contains one of three unique loot items; each will aid you in defeating one of the three bosses.',
    };

  if (hex === 0x010101)
    return { lootId, name: 'Trash Net', rarity: 1, icon: <SvgLootNet /> };
  if (hex === 0x020101)
    return { lootId, name: 'Trash Net', rarity: 2, icon: <SvgLootNet /> };
  if (hex === 0x030101)
    return { lootId, name: 'Trash Net', rarity: 3, icon: <SvgLootNet /> };

  if (hex === 0x010201)
    return { lootId, name: 'Flowers', rarity: 1, icon: <SvgLootFlowers /> };
  if (hex === 0x020201)
    return { lootId, name: 'Flowers', rarity: 2, icon: <SvgLootFlowers /> };
  if (hex === 0x030201)
    return { lootId, name: 'Flowers', rarity: 3, icon: <SvgLootFlowers /> };

  if (hex === 0x010301)
    return {
      lootId,
      name: 'Ghost Potion',
      rarity: 1,
      icon: <SvgLootGhostPotion />,
    };
  if (hex === 0x020301)
    return {
      lootId,
      name: 'Ghost Potion',
      rarity: 2,
      icon: <SvgLootGhostPotion />,
    };
  if (hex === 0x030301)
    return {
      lootId,
      name: 'Ghost Potion',
      rarity: 3,
      icon: <SvgLootGhostPotion />,
    };

  if (hex === LOOT_ID_KEY_TURTLE_SEA)
    return {
      lootId,
      name: 'Turtle Sea Key',
      rarity: 3,
      icon: <SvgBossKeyTurtleSea style={{ position: 'relative', bottom: 4 }} />,
    };
  if (hex === LOOT_ID_KEY_WIZARDS_PATH)
    return {
      lootId,
      name: 'Wizards Path Key',
      rarity: 3,
      icon: (
        <SvgRealmBossKeyWizardsPath
          style={{ position: 'relative', bottom: 6 }}
        />
      ),
    };
  if (hex === LOOT_ID_KEY_BONEYARD)
    return {
      lootId,
      name: 'Boneyard Key',
      rarity: 3,
      icon: (
        <SvgRealmBossKeyBoneyard style={{ position: 'relative', bottom: 6 }} />
      ),
    };

  const def = getItemDef(lootId);
  if (def) {
    return {
      lootId,
      stat: undefined,
      name: def.name,
      rarity: getRarityLevelNumber(def.rarity),
      icon: (
        <img
          src={`${cdnRoot}/images/icons/boss_loot/${def.icon}.png`}
          style={{ width: '100%', position: 'relative', bottom: 5 }}
        />
      ),
    };
  }

  return {
    lootId,
    stat: 'exp',
    name: '???',
    rarity: 0,
    unknown: true,
    icon: <SvgIconLootNone style={{ position: 'relative', bottom: 2 }} />,
  };
}
