import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import {
  getTimeDurationMinutes,
  getTimeUntil,
  ITimeDurationMinutes,
} from '../../../utils';
import { useFrameRate } from '../../useAnimation';
import clsx from 'clsx';
import { LeaderboardTimeframe } from '../../schema';

export const furballsEpoch = 1636070400000; // Midnight UTC, Thurs/Friday Nov 5th

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',

    '& h2, h3, p': {
      color: 'white !important',
    },

    '& h2': {
      lineHeight: (props: any) => `${props.small ? 1.25 : 3.5}rem`,
      fontSize: (props: any) => `${props.small ? 2.25 : 3.5}rem`,
      marginBottom: '-0.5rem',
      marginTop: '-0.5rem',
    },

    '& h3': {
      lineHeight: (props: any) => `${props.small ? 0.8 : 1.6}rem`,
      fontSize: (props: any) => `${props.small ? 1.45 : 2.5}rem`,
    },

    '& p': {
      opacity: 0.8,
      fontSize: (props: any) => `${props.small ? 0.7 : 0.9}rem`,
    },
  },
  countdown: {
    marginBottom: '1rem',
  },
}));

export interface ILeaderboardCountdown {
  valueOverride?: string;
  resetAt?: number | Date | string;
  resetMessage?: string;
  heartsMessage?: string;
  className?: string;
  timeframe?: LeaderboardTimeframe;
}

interface ILeaderboardResetCountdown extends ILeaderboardCountdown {
  small?: boolean;
}

function getSequenceDuration(timeframe: LeaderboardTimeframe): number {
  if (timeframe === LeaderboardTimeframe.Weekly) return 7 * 24 * 3600;
  if (timeframe === LeaderboardTimeframe.Seasonal) return 30 * 24 * 3600;
  return 0;
}

function getNextLeaderboardResetTimeWeek(dur: number): number {
  if (dur <= 0) return 0;
  const milliseconds = 1000 * dur;
  const now = new Date().getTime();
  const timeSinceEpoch = now - furballsEpoch;
  const remainingInWeek = milliseconds - (timeSinceEpoch % milliseconds);
  return now + remainingInWeek;
}

function getNextLeaderboardResetTime(timeframe?: LeaderboardTimeframe): number {
  return getNextLeaderboardResetTimeWeek(
    getSequenceDuration(timeframe ?? LeaderboardTimeframe.Weekly),
  );
}

// Hearts reset every day (midnight UTC)
function getNextHeartResetTime(): number {
  // const now = new Date();
  const end = new Date();
  const nextUTCMidnight = new Date(end.setUTCHours(24, 0, 0, 0));
  return nextUTCMidnight.getTime();
}

export function formatTimeDurationMinutes(
  time: ITimeDurationMinutes,
  hideDays = false,
  // hideSecs = false,
) {
  const { days, hours, mins } = time;

  const d = hideDays ? '' : `${days}d `;
  // const s = hideSecs ? '' : ` ${secs}s`;
  return `${d}${hours}h ${mins}m`;
}

export function formatTimeDuration(time: ITimeDurationMinutes): string {
  const { days, hours, mins } = time;

  if (days > 0) return `${days}d ${hours}h`;
  if (hours > 0) return `${hours}h ${mins}m`;
  return `${mins}m`;
}

const LeaderboardResetCountdown: FunctionComponent<
  ILeaderboardResetCountdown
> = (props) => {
  const {
    small,
    resetMessage,
    heartsMessage,
    valueOverride,
    className,
    timeframe,
  } = props;
  const classes = useStyles({ small });
  const resetAt = new Date(
    props.resetAt ?? getNextLeaderboardResetTime(timeframe),
  );
  const timeUntilLbReset = getTimeDurationMinutes(getTimeUntil(resetAt));
  const timeUntilHeartsReset = getTimeDurationMinutes(
    getTimeUntil(getNextHeartResetTime()),
  );

  // Will cause 1m interval refreshes
  useFrameRate(true, 60000);

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.countdown}>
        <Typography variant={'body2'}>
          {resetMessage ?? 'Reset and rewards in'}
        </Typography>
        <Typography variant={'h2'}>
          {valueOverride ?? formatTimeDurationMinutes(timeUntilLbReset)}
        </Typography>
      </div>
      {heartsMessage && (
        <div>
          <Typography variant={'body2'}>{heartsMessage}</Typography>
          <Typography variant={'h3'}>
            {formatTimeDurationMinutes(timeUntilHeartsReset, true)}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default LeaderboardResetCountdown;
