import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { cdnRoot } from '../theme';

const useStyles = makeStyles((theme: Theme) => ({
  sky: {
    backgroundImage: `url(${cdnRoot}/svgs/sky-clouds.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  // [theme.breakpoints.up('lg')]: {
  //   sky: {
  //     paddingTop: '280px',
  //   }
  // },
  // [theme.breakpoints.up('xl')]: {
  //   sky: {
  //     paddingTop: '300px',
  //   }
  // }
}));

type Props = {
  className?: string;
  children: React.ReactNode;
};

const Sky: FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.sky, props.className)}>{props.children}</div>
  );
};

export default Sky;
