import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Grid, TextField, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import OkDialog from '../../../components/OkDialog';
import {
  ItemListingFragment,
  useCancelLootListingMutation,
  useCreateLootListingMutation,
  useEditLootListingMutation,
} from '../../../components/schema';
import SelectOwnedItem from './SelectOwnedItem';
import { getItemDef } from '../../../components/BossFights/BossRewards/bossRewardsUtils';
import {
  ALERT_TYPES,
  AlertContext,
} from '../../../components/Alert/AlertContext';
import theme from '../../../themev2';
import {
  IOwnedItem,
  useInventoryHelpers,
} from '../../../components/Inventory/InventoryHelpers';
import InputItemQuantity from './InputItemQuantity';
import { useFuelFactor } from '../../../components/Timekeeper/TimekeeperHooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  footer: {
    textAlign: 'center',
    paddingTop: 10,
  },
  numberInput: {
    width: '100%',
  },
  cancelBtn: {
    border: 'none !important',
    backgroundColor: 'transparent !important',
  },
  dialogRoot: {
    position: 'relative',
    width: '495px',
    maxWidth: '495px',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  createListing: {
    width: '100%',
  },
}));

interface ICreateLootListing {
  open: boolean;
  close: () => void;
  listing?: ItemListingFragment;
  noDialog?: boolean;
  itemToList?: IOwnedItem | null;
}

export const LootListingDialog: FunctionComponent<ICreateLootListing> = (
  props,
) => {
  const classes = useStyles();

  const { open, close, listing, noDialog, itemToList } = props;
  const { updatePlayerFurballInventory } = useInventoryHelpers();

  const listingFuelCost = useFuelFactor();
  const [price, setPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [selectedItemId, setSelectedItemId] = useState(0);
  const [showInventory, setShowInventory] = useState(false);
  const [item, setItem] = useState<IOwnedItem | undefined>(
    !!itemToList ? itemToList : undefined,
  );
  const [createListing, createdListing] = useCreateLootListingMutation();
  const [editListing, editedListing] = useEditLootListingMutation();
  const [deleteListing, deletedListing] = useCancelLootListingMutation();
  const { addAlert } = React.useContext(AlertContext);
  const loading =
    createdListing.loading || editedListing.loading || deletedListing.loading;
  const [result, setResult] = React.useState<ItemListingFragment>();
  const hasChanges = !!item || (listing && listing.currentPrice !== price);

  useEffect(() => {
    if (listing) {
      setPrice(listing.currentPrice);
      setQuantity(listing.quantity);
    }
  }, [listing]);

  useEffect(() => {
    if (!open) {
      setPrice(0);
      setQuantity(1);
      setItem(!!itemToList ? itemToList : undefined);
    }
  }, [open]);

  async function remove() {
    if (!listing) return;

    try {
      const res = (await deleteListing({ variables: { id: listing.id } })).data
        ?.listing;

      if (!!res) {
        updatePlayerFurballInventory(res, item);
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Successfully de-listed item',
      });
      close();
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to de-list item',
      });
    }
  }

  async function save() {
    if (!item && !listing) return;

    try {
      const res = listing
        ? (await editListing({ variables: { id: listing.id, price } }))?.data
            ?.listing
        : (
            await createListing({
              variables: { itemId: item!.item!.id, price, quantity },
            })
          )?.data?.listing;
      setResult(res);

      if (!listing && !!res) {
        updatePlayerFurballInventory(res, item);
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Successfully listed item',
      });
      close();
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to list item',
      });
    }
  }

  function renderContent() {
    return (
      <Grid container>
        <Grid container alignItems='center' columnSpacing={2} rowSpacing={3}>
          {listing ? (
            <Grid item xs={6}>
              <Typography variant={'h5'}>
                {getItemDef(listing.item.itemId)?.name ?? '?'} (x
                {listing.item.stack})
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={12} mt='10px' mb='10px'>
                <SelectOwnedItem
                  item={item}
                  setItem={(i) =>
                    setItem(!i || i.id === 'empty' ? undefined : i)
                  }
                  selectedItemId={selectedItemId}
                  setSelectedItemId={(id) => setSelectedItemId(id)}
                  showInventory={showInventory}
                  setShowInventory={(show) => setShowInventory(show)}
                  selectedFromInventory={!!itemToList}
                />
              </Grid>

              <Grid item xs={12}>
                <InputItemQuantity
                  quantity={quantity}
                  setQuantity={setQuantity}
                  selectedItem={item}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <TextField
              id='outlined-number'
              label='wFUR Price'
              type='number'
              color='secondary'
              value={price}
              className={classes.numberInput}
              onChange={(e) =>
                setPrice(Math.max(0, Number.parseInt(e.target.value)))
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>

        <Grid container direction='column' gap='0.5rem' mt='1rem'>
          <Typography
            variant={'subtitle2'}
            fontWeight='500'
            textAlign='center'
            color={theme.palette.warning.dark}>
            The item will be removed from your inventory while listed for sale.
          </Typography>

          <Grid container spacing={1} justifyContent={'flex-end'} mt='0.5rem'>
            <Grid item xs={12} md={8}>
              {!listing && (
                // <SpendTixButton
                //   itemName={'Loot Listing'}
                //   disabled={
                //     !hasChanges || loading || quantity <= 0 || price <= 0
                //   }
                //   cost={listingFuelCost}
                //   onAccepted={save}
                //   title={'Create Listing'}
                //   className={classes.createListing}
                // />

                <Button
                  variant={'contained'}
                  fullWidth
                  disabled={
                    !hasChanges || loading || quantity <= 0 || price <= 0
                  }
                  onClick={save}
                  className={classes.createListing}>
                  <Typography variant={'h5'}>
                    {loading && 'Saving...'}
                    {!loading && 'Create Listing'}
                  </Typography>
                </Button>
              )}
              {listing && (
                <Button
                  variant={'contained'}
                  fullWidth
                  disabled={
                    !hasChanges || loading || quantity <= 0 || price <= 0
                  }
                  onClick={save}
                  className={classes.createListing}>
                  <Typography variant={'h5'}>
                    {loading && 'Saving...'}
                    {!loading && 'Update Listing'}
                  </Typography>
                </Button>
              )}
            </Grid>

            {listing && (
              <Grid item xs={12} md={4}>
                <Button
                  fullWidth
                  variant={'contained'}
                  color={'secondary'}
                  disabled={loading}
                  onClick={remove}>
                  {loading && 'Saving...'}
                  {!loading && 'Delete Listing'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return noDialog ? (
    renderContent()
  ) : (
    <OkDialog
      open={open}
      onClose={() => {
        showInventory && !!selectedItemId
          ? setSelectedItemId(0)
          : showInventory
          ? setShowInventory(false)
          : close();
      }}
      backIcon={showInventory || (showInventory && !!selectedItemId)}
      className={clsx(classes.root)}
      contentClassName={classes.dialogRoot}
      title={listing ? 'Edit Listing' : 'Create Listing'}>
      {renderContent()}
    </OkDialog>
  );
};

export default LootListingDialog;
