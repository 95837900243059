import * as React from 'react';

function SvgIconDiscord(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='1157.54 6313.96 56.82 43.07'
      {...props}>
      <path
        fill={props.fill ?? '#000000'}
        d='M1205.66 6317.57a47.095 47.095 0 00-11.72-3.61c-.55.99-1.06 2.01-1.5 3.05-4.31-.65-8.7-.65-13.01 0-.45-1.04-.95-2.06-1.5-3.05a47.32 47.32 0 00-11.73 3.62c-7.42 10.98-9.43 21.68-8.42 32.23 4.34 3.21 9.21 5.65 14.38 7.22 1.16-1.57 2.19-3.23 3.08-4.97-1.68-.63-3.3-1.4-4.85-2.31.41-.29.8-.6 1.19-.89a33.77 33.77 0 0028.74 0c.39.32.79.62 1.19.89-1.55.91-3.17 1.69-4.86 2.32.88 1.74 1.91 3.4 3.08 4.96 5.17-1.56 10.04-4 14.39-7.21 1.17-12.24-2.03-22.84-8.46-32.25zm-29.16 25.76c-2.8 0-5.12-2.54-5.12-5.67s2.23-5.69 5.11-5.69 5.17 2.57 5.12 5.69c-.05 3.12-2.25 5.67-5.11 5.67zm18.88 0c-2.81 0-5.11-2.54-5.11-5.67s2.23-5.69 5.11-5.69 5.16 2.57 5.11 5.69c-.05 3.12-2.26 5.67-5.11 5.67z'
      />
    </svg>
  );
}

export default SvgIconDiscord;
