import React, { FunctionComponent } from 'react';
import { Theme, Autocomplete, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { itemLibrary } from '../../components/Inventory/ItemLibrary';
import { ItemDefinitionFragment } from '../../components/schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IItemAutocomplete {
  itemId: number;
  setItemId: (n: number) => void;
}

export const ItemAutocomplete: FunctionComponent<IItemAutocomplete> = (
  props,
) => {
  const classes = useStyles();
  const { itemId, setItemId } = props;
  const item = itemLibrary.find((i) => i.id === itemId);

  return (
    <Autocomplete
      className={clsx(classes.root)}
      options={itemLibrary.sort(
        (a, b) => -b.itemGroup.localeCompare(a.itemGroup),
      )}
      groupBy={(option) => option.itemGroup.toString()}
      value={item}
      onChange={(event: any, newValue: ItemDefinitionFragment | null) => {
        setItemId(newValue?.id ?? 0);
      }}
      getOptionLabel={(option) => `${option.name} (${option.rarity})`}
      renderInput={(params) => <TextField {...params} label='Choose Item' />}
    />
  );
};

export default ItemAutocomplete;
