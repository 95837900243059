import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import OkDialog, { ICloseDialog } from '../../OkDialog';
import { RealmType } from '../../schema';
import { cdnRoot } from '../../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    margin: -24,
    marginBottom: 8,
    minWidth: 494,
    paddingLeft: 50,
    width: '100%',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

interface IRealmCreatureDialog extends ICloseDialog {
  realmType: RealmType;
  title: string;
  children: React.ReactNode;
  header: React.ReactNode;
  className?: string;
}

export const RealmHeaderDialog: FunctionComponent<IRealmCreatureDialog> = (
  props,
) => {
  const { realmType, title, open, onClose, className } = props;
  const classes = useStyles();
  const backgroundImage = `url(${cdnRoot}/svgs/voyage-header-${realmType.toLowerCase()}.svg)`;

  return (
    <OkDialog
      className={clsx(classes.root, className)}
      title={title}
      open={open}
      onClose={onClose}
      maxWidth={'sm'}>
      <div style={{ backgroundImage }} className={classes.header}>
        {props.header}
      </div>
      {props.children}
    </OkDialog>
  );
};

export default RealmHeaderDialog;
