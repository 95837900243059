import React from 'react';
import { IRenderPart } from '../part';
import Dinosaur from './00_Dinosaur';
import Squirrel from './01_Squirrel';
import Lion from './02_Lion';
import Rabbit from './03_Rabbit';
import Dog from './04_Dog';
import Rat from './05_Rat';
import Fox from './06_Fox';
import Unicorn from './07_Unicorn';
import Poodle from './08_Poodle';
import SprinklePony from './09_SprinklePony';
import Racoon from './10_Racoon';

export function renderEdition0Tail(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Dinosaur renderer={renderer} />;
  if (renderer.partNumber === 2) return <Squirrel renderer={renderer} />;
  if (renderer.partNumber === 3) return <Lion renderer={renderer} />;
  if (renderer.partNumber === 4) return <Rabbit renderer={renderer} />;
  if (renderer.partNumber === 5) return <Dog renderer={renderer} />;
  if (renderer.partNumber === 6) return <Rat renderer={renderer} />;
  if (renderer.partNumber === 7) return <Fox renderer={renderer} />;
  if (renderer.partNumber === 8) return <Unicorn renderer={renderer} />;
  if (renderer.partNumber === 9) return <Poodle renderer={renderer} />;
  if (renderer.partNumber === 10) return <SprinklePony renderer={renderer} />;
  if (renderer.partNumber === 11) return <Racoon renderer={renderer} />;
  console.warn('renderEdition0Tail', renderer);
}
