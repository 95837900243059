import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Horns = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M207.19 101.2c3.09-3.68 8.88-11.88 9.44-22.25.05-.84.63-1.42 1.32-1.58.32.15.61.4.82.75 3.72 6.28 11.58 18.72 5.22 33.89-4.25.45-12.19.33-16.96-6-1.07-1.44-.99-3.44.16-4.81z'
    />
    <path
      fill='#fff2db'
      d='M218 78c4.52 5.03 13.76 16.03 10.34 31.1-.3 1.33-9.34 5.9-8.34 3.9 9.08-18.16-3.02-36.14-2-35z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M207.19 101.2c3.09-3.68 8.88-11.88 9.44-22.25.08-1.53 1.96-2.2 2.98-1.06 4.52 5.03 12.14 16.14 8.72 31.22a3.918 3.918 0 0 1-2.57 2.85c-3.82 1.28-12.21 2.71-18.73-5.94a3.906 3.906 0 0 1 .16-4.82z'
    />
    <path
      fill='#FFF'
      d='M185.28 101.2c-3.09-3.68-8.88-11.88-9.44-22.25-.05-.84-.63-1.42-1.32-1.58-.32.15-.61.4-.82.75-3.72 6.28-11.58 18.72-5.22 33.89 4.25.45 12.19.33 16.96-6 1.07-1.44 1-3.44-.16-4.81z'
    />
    <path
      fill='#fff2db'
      d='M174.47 78c-4.52 5.03-13.76 16.03-10.34 31.1.3 1.33 9.34 5.9 8.34 3.9-9.08-18.16 3.03-36.14 2-35z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M185.28 101.2c-3.09-3.68-8.88-11.88-9.44-22.25-.08-1.53-1.96-2.2-2.98-1.06-4.52 5.03-12.14 16.14-8.72 31.22.3 1.33 1.27 2.41 2.57 2.85 3.82 1.28 12.21 2.71 18.73-5.94 1.07-1.45 1-3.45-.16-4.82z'
    />
  </svg>
);

export default Horns;
