import * as React from 'react';

function SvgUpgradeIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24.68 21.75'
      {...props}>
      <path d='M12.39 0L0 8.49l.07 5.83 12.32-8.49 12.29 8.13-.07-5.83z' />
      <path d='M12.39 7.86v.04l-8.11 5.54.06 4.97 8.05-5.57 8.1 5.34-.06-4.98z' />
      <path d='M12.39 14.76v.02l-4.73 3.2.05 3.77 4.68-3.22 4.65 3.09-.05-3.78z' />
    </svg>
  );
}

export default SvgUpgradeIcon;
