import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip, Typography } from '@mui/material';
import { ItemQuantityFragment, ItemsFragment } from '../schema';
import { cdnRoot } from '../../theme';
import clsx from 'clsx';
import SvgMobileMenuX from '../../assets/SvgMobileMenuX';
import { formatNumber } from '../../utils';
import SvgKeyTrashy from '../../assets/SvgKeyTrashy';
import SvgKeySebastien from '../../assets/SvgKeySebastien';
import SvgKeySkullumbo from '../../assets/SvgKeySkullumbo';
import { IFurball } from '../../wallet/WalletTypes';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import FurballHead from '../Furballs/FurballHead';
import { shadeColor } from '../../themev2';
import { getFurballBackgroundColorCss } from '../Furballs/Backgrounds/FurballBackground';
import SvgIconCheck from '../../assets/SvgIconCheck';
import { MaybeFurball } from '../../wallet';

const size = (small: boolean, fluidSize: boolean) => {
  return fluidSize
    ? undefined
    : small
    ? '136px !important'
    : '150px !important';
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    userSelect: 'none',
    padding: '8px',
    margin: '5px !important',
    borderRadius: '16px',
    aspectRatio: '1/1',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: (props: any) =>
      props.furball
        ? `${props.furball.backgroundColor}`
        : props.isListing
        ? theme.palette.common.white
        : props.isSingleItemStack
        ? theme.palette.secondary.main
        : theme.palette.secondary.light,

    height: (props: any) => size(props.small, props.fluidSize),
    maxHeight: (props: any) => size(props.small, props.fluidSize),
    maxWidth: (props: any) => size(props.small, props.fluidSize),
    minWidth: (props: any) => size(props.small, props.fluidSize),
  },

  selectable: {
    cursor: 'pointer',
  },

  inner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    height: '100%',

    '& h6': {
      fontSize: (props: any) =>
        props.small ? '0.8rem !important' : '0.9rem !important',
      fontWeight: 600,
      marginTop: '7.5px !important',

      '@media (max-width: 972px)': {
        fontSize: '0.8rem !important',
      },
    },
  },

  amount: {
    position: 'absolute',
    top: 0,
    right: 5,
    fontWeight: 600,
    fontSize: '0.9rem',
    minWidth: '16.5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column-reverse',
    gap: '0.2rem',
  },

  img: {
    height: (props: any) => (props.small ? '55px' : '70px'),
    width: (props: any) => (props.small ? '55px' : '70px'),

    '@media (max-width: 972px)': {
      height: '55px',
      width: '55px',
    },
  },

  rarityIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '4px',
    borderRadius: '50%',
  },

  icon: {
    display: 'grid',
    placeItems: 'center',
    marginTop: (props: any) => (props.small ? '0px' : '5px'),

    '& svg': {
      height: (props: any) => (props.small ? '45px' : '60px'),
      width: (props: any) => (props.small ? '45px' : '60px'),

      '@media (max-width: 972px)': {
        height: '45px',
        width: '45px',
      },
    },
  },

  furball: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '50%',

    '& svg': {
      position: 'absolute',
      height: '16px',
      width: '16px',
      right: 4,
      bottom: -18,
    },
  },
}));

export interface IInventorySlot {
  containerId: string;
  item?: ItemsFragment;
  itemQuantity?: ItemQuantityFragment;
  className?: string;
  hideRarity?: boolean;
  hideAmount?: boolean;
  onSelectItem?: (item: ItemsFragment | null) => void;
  onSelectItemId?: (id: number) => void;
  removeCurrentItem?: boolean;
  small?: boolean;
  furball?: IFurball;
  isListing?: boolean;
  fluidSize?: boolean;
  disableTooltip?: boolean;
}

export function getKeySvgByItemDefIcon(icon: string) {
  if (icon === 'turtle_sea_key') return <SvgKeyTrashy />;
  if (icon === 'wizards_path_key') return <SvgKeySebastien />;
  if (icon === 'boneyard_key') return <SvgKeySkullumbo />;
}

const InventorySlot: FunctionComponent<IInventorySlot> = (props) => {
  const {
    item,
    itemQuantity,
    className,
    hideAmount,
    hideRarity,
    onSelectItem,
    onSelectItemId,
    removeCurrentItem,
    small,
    furball,
    isListing,
    fluidSize,
  } = props;

  const itemId = item?.itemId ?? itemQuantity?.itemId ?? 0;
  const itemDef = getItemDef(itemId);
  const isEquipped = isEquippedItem(furball, item);
  const itemStack = item?.stack ?? itemQuantity?.quantity ?? 0;
  const isSingleItemStack = !!item;

  const classes = useStyles({
    furball,
    small,
    isSingleItemStack,
    isListing,
    fluidSize,
    onSelectItem: !isEquipped && onSelectItem,
  });
  const selectable = (!!onSelectItemId || !!onSelectItem) && !isEquipped;

  function isEquippedItem(
    furball: MaybeFurball,
    item: ItemsFragment | undefined,
  ): boolean {
    if (!item || !furball) return false;
    return !!furball.equipment.find((e) => e.id === item.id);
  }

  function onSelect() {
    if (item) {
      onSelectItem && onSelectItem(item);
      return;
    }

    onSelectItemId && onSelectItemId(itemId);
  }

  function renderItem() {
    if (removeCurrentItem) return renderRemoveItem();
    if (!itemDef) return null;

    const isDust = itemDef.name.includes('Dust');
    const isKey = itemDef.name.includes('Key');

    return (
      <div className={classes.inner} onClick={onSelect}>
        {!hideRarity && !isDust && !isKey && (
          <Tooltip title={itemDef.rarity}>
            <img
              src={`${cdnRoot}/svgs/zone_icons_elixir_${itemDef.rarity.toLowerCase()}.svg`}
              height={27.5}
              width={27.5}
              className={classes.rarityIcon}
            />
          </Tooltip>
        )}

        {!hideAmount && !isEquipped && itemStack > 1 && (
          <Tooltip
            title={
              isSingleItemStack ? 'No of items in this stack' : 'Total quantity'
            }>
            <div className={classes.amount}>
              {isSingleItemStack && 'x'}
              {itemStack && formatNumber(itemStack)}
            </div>
          </Tooltip>
        )}
        {!!furball && (
          <Tooltip
            title={`${
              isEquipped ? 'Equipped by' : 'In inventory of'
            } Furball #${furball.number}`}>
            <div
              className={classes.furball}
              style={{
                backgroundColor: shadeColor(
                  getFurballBackgroundColorCss(furball),
                  -40,
                ),
              }}>
              {isEquipped && <SvgIconCheck />}
              <FurballHead
                tokenId={furball.tokenId}
                size={25}
                borderRadius={100}
              />
            </div>
          </Tooltip>
        )}
        <div className={classes.icon}>
          {isKey ? (
            getKeySvgByItemDefIcon(itemDef.icon)
          ) : (
            <img
              src={`${cdnRoot}/images/icons/boss_loot/${itemDef.icon}.png`}
              className={classes.img}
            />
          )}
        </div>
        <Typography variant='subtitle2'>
          {itemDef.name}
          {!isSingleItemStack && 's'}
        </Typography>
      </div>
    );
  }

  function renderRemoveItem() {
    return (
      <div
        className={classes.inner}
        onClick={() => !!onSelectItem && onSelectItem(null)}>
        <SvgMobileMenuX height={40} width={40} stroke={'#A5B4FC'} />
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.root, className, {
        [classes.selectable]: isSingleItemStack,
      })}>
      {renderItem()}
    </div>
  );
};

export default InventorySlot;
