import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, LinearProgress, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';
import { contractTransaction } from '../../wallet';
import { useWalletSelector } from '../../hooks';
import WalletContext from '../../wallet/WalletContext';
import { ethers } from 'ethers';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import { ethPerTix } from './TimekeeperSlice';
import { Link } from 'react-router-dom';
import { useFurComponent } from '../../utils';
import { useMeLazyQuery } from '../schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '24px !important',
    paddingRight: '24px !important',
  },
}));

interface IUnknown {}

type Props = {
  amount: number;
  onState: (s: TransactionState, e?: ITransactionError) => void;
  disabled?: boolean;
};

export const BuyTicketsButton: FunctionComponent<Props> = (props) => {
  const { amount, onState, disabled } = props;
  // const dispatch = useAppDispatch();
  const { log } = useFurComponent(BuyTicketsButton);
  const common = useCommonStyles();
  const context = React.useContext(WalletContext);
  const [loadAccount, account] = useMeLazyQuery();
  const walletAddr = useWalletSelector((s) => s.address) ?? '';
  const classes = useStyles();
  // const history = useHistory();
  const [state, setState] = React.useState(TransactionState.None);
  const isLoading =
    state > TransactionState.None && state < TransactionState.Complete;
  const isDisabled = isLoading || disabled;
  const [error, setError] = React.useState<string>();

  async function submit() {
    const fuel = context?.network.contracts?.fuel;
    console.log('submit', fuel, 'with', walletAddr);
    if (!fuel || !walletAddr) {
      setError('Please log in to MetaMask first.');
      return;
    }

    const value = ethers.utils.parseEther((amount * ethPerTix).toString());
    console.log('value', value);

    await contractTransaction(
      log,
      async (contracts) => {
        return await fuel.estimateGas.deposit(walletAddr, { value });
      },
      async (opts, contracts) => {
        return await fuel.deposit(walletAddr, { ...opts, value });
      },
      async (contracts) => {
        // This data will be thrown away; it's simply telling the server to refresh.
        await loadAccount({ variables: { maxCache: 0 } });
      },
      (e, s) => {
        setState(e);
        onState(e, s);
      },
      context?.network.contracts,
    );
  }

  return (
    <React.Fragment>
      {!isLoading && (
        <Button
          variant={'contained'}
          className={clsx(classes.root, common.actionButton)}
          onClick={() => void submit()}
          disabled={isDisabled}>
          <Typography variant={'h5'}>Confirm Purchase</Typography>
        </Button>
      )}
      {error && (
        <>
          <Typography variant={'h5'} style={{ color: 'red' }}>
            {error}
          </Typography>
        </>
      )}
      {isLoading && <LinearProgress color={'secondary'} />}
      {state === TransactionState.Complete && (
        <React.Fragment>
          <br />
          <br />
          <Typography variant={'subtitle2'}>
            You may now press "back" or{' '}
            <Link to={'/timekeeper'}>return to Timekeeper</Link>.
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BuyTicketsButton;
