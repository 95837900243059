import * as React from 'react';

function SvgShopInventory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 400 400' {...props}>
      <path
        fill='#D3FF91'
        stroke='#000'
        strokeWidth={5}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M233.13 92.22L200 49.9l-33.13 42.32h15.46v165.29h35.34V92.22zM153.55 130.81l-33.13-42.32-33.13 42.32h15.46v151.41c0 7.66 6.21 13.88 13.88 13.88h21.47V130.81h15.45zM312.71 130.81l-33.13-42.32-33.13 42.32h15.46v193.83h4.37c17.11 0 30.97-13.87 30.97-30.97V130.81h15.46z'
      />
      <path
        fill='#8A82E8'
        stroke='#000'
        strokeWidth={5}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M200 142.43c-12.9 0-23.36 12.83-23.36 29.89h10.86c0-9.66 2.83-17.5 12.5-17.5s12.5 7.83 12.5 17.5h10.86c0-17.06-10.46-29.89-23.36-29.89z'
      />
      <g
        stroke='#000'
        strokeWidth={5}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path
          fill='#ABA3FF'
          d='M262.04 355.22H137.96c-7.71 0-13.95-6.25-13.95-13.95V225.36H276v115.91c0 7.7-6.25 13.95-13.96 13.95z'
        />
        <g fill='#ABA3FF'>
          <path d='M276.42 336.57h10.33c10.54 0 19.08-8.54 19.08-19.08v-46.81h-29.41v65.89zM123.58 336.58h-10.34c-10.54 0-19.08-8.54-19.08-19.08v-46.81h29.41v65.89z' />
        </g>
        <path
          fill='#6558F0'
          d='M259.87 240.06H140.13c-13.83 0-25.04-11.21-25.04-25.04v-49.7H284.9v49.7c0 13.83-11.21 25.04-25.03 25.04z'
        />
        <g fill='#5B50D9'>
          <path d='M166.5 165.89v121.89M168.33 311.32h-3.67a5.46 5.46 0 01-5.46-5.46v-14.81a5.46 5.46 0 015.46-5.46h3.67a5.46 5.46 0 015.46 5.46v14.81a5.46 5.46 0 01-5.46 5.46z' />
          <g>
            <path d='M233.5 165.89v121.89M235.34 311.32h-3.67a5.46 5.46 0 01-5.46-5.46v-14.81a5.46 5.46 0 015.46-5.46h3.67a5.46 5.46 0 015.46 5.46v14.81c-.01 3.02-2.45 5.46-5.46 5.46z' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgShopInventory;
