import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Fab,
  Grid,
  Theme,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { RecentBossBattles, useTopBossBattlesLazyQuery } from '../../schema';
import BossGame from './BossGame';
import useBossFightsStyles from '../useBossFightsStyles';
import OkDialog from '../../OkDialog';
import clsx from 'clsx';
import theme from '../../../themev2';
import SvgIconLiveFeed from '../../../assets/SvgIconLiveFeed';
import SvgIconMinus from '../../../assets/SvgIconMinus';

const useStyles = makeStyles((theme: Theme) => ({
  gridCont: {
    height: '475px',
  },

  dialog: {
    padding: '0 !important',
  },

  container: {
    maxWidth: '1300px',
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },

  loading: {
    margin: '3rem auto 0 auto',
  },

  buttonCont: {
    // position: 'fixed',
    // left: '10px',
    // bottom: '7.3rem',
  },

  button: {},
  dialogPaper: {
    backgroundColor: 'transparent !important',
    overflow: 'hidden',
    minWidth: '1370px',
    display: 'grid !important',
    placeItems: 'center',
    // height: '900px !important',

    '@media (max-width: 1300px)': {
      height: '85vh !important',
      overflow: 'auto',
      minWidth: '90% !important',
    },

    [theme.breakpoints.down('sm')]: {
      height: '82vh !important',
      placeItems: 'unset',
    },
  },

  dialogLoading: {
    height: '100vh !important',
  },

  closeBtn: {
    position: 'absolute',
    top: '-3rem',
  },
}));

interface ILiveFightFeed {}

const LiveFightFeed: FunctionComponent<ILiveFightFeed> = (props) => {
  const classes = useStyles();
  const bossStyles = useBossFightsStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [show, setShow] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [getBossBattles, { data, loading }] = useTopBossBattlesLazyQuery();

  useEffect(() => {
    const loadBattles = async () => {
      await getBossBattles();
      setLoaded(false);
    };

    if (show && !loaded) {
      loadBattles();
    }
  }, [show]);

  function renderLoading() {
    return (
      <div className={classes.loading}>
        <CircularProgress size={60} color={'secondary'} />
      </div>
    );
  }

  function renderBossGames() {
    return data?.topBossBattles.map((recentBossBattles, i) => {
      return (
        <BossGame
          key={`boss-battle-${i}`}
          recentBossBattles={recentBossBattles as RecentBossBattles}
        />
      );
    });
  }

  function renderButton() {
    return (
      <Tooltip placement='right-end' title='Live Fights Feed'>
        <Fab
          size='medium'
          color={'secondary'}
          onClick={() => setShow(true)}
          className={classes.button}>
          <SvgIconLiveFeed width='1.5rem' />
        </Fab>
      </Tooltip>
    );
  }

  return (
    <>
      {renderButton()}
      <OkDialog
        open={show}
        topRight={false}
        onClose={() => setShow(false)}
        hideScrollbar={true}
        classes={{
          paper: clsx(classes.dialogPaper, {
            [classes.dialogLoading]: loading,
          }),
        }}
        contentClassName={classes.dialog}>
        <Grid container className={classes.gridCont}>
          {loading && renderLoading()}
          <Fab color='secondary' className={classes.closeBtn}>
            <SvgIconMinus width='1.5rem' />
          </Fab>
          <Grid
            container
            className={clsx(bossStyles.bossLobbyGrid, classes.container)}>
            {!loading && renderBossGames()}
          </Grid>
        </Grid>
      </OkDialog>
    </>
  );
};

export default LiveFightFeed;
