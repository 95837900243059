import * as React from 'react';

function SvgSnackFurgonzola(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
      <path
        fill='#64B4B4'
        d='M168.39 81.03a7.358 7.358 0 00-.98-2.95c-10.69-18-36.14-25.34-50.19-28.1-5.91-1.16-9.5-1.42-14.71 1.61-1.45.85-3.32 1.75-4.57 2.47L157.39 133s3.55-.6 6.82-1.25a5.356 5.356 0 004.31-4.86c.63-8.61 1.79-29.42-.13-45.86z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M168.39 81.03a7.358 7.358 0 00-.98-2.95c-10.69-18-36.14-25.34-50.19-28.1-5.91-1.16-9.5-1.42-14.71 1.61-1.45.85-7.55 4.93-8.8 5.64l63.68 75.76s3.55-.6 6.82-1.25a5.356 5.356 0 004.31-4.86c.63-8.6 1.79-29.41-.13-45.85z'
      />
      <path
        fill='#F6E9CE'
        d='M30.55 104.6v46.23s80.87-5.42 126.84-17.85c0 0 2.65-29.98 0-51.4L30.55 104.6z'
      />
      <path
        fill='#64B4B4'
        d='M126.22 87.56v14.64c0 2.83 2.3 5.13 5.13 5.13s5.13-2.3 5.13-5.13v-3.46c0-1.89 1.53-3.42 3.42-3.42s3.42 1.53 3.42 3.42l-.02.22c-.15 1.47 1 2.75 2.48 2.75 1.36 0 2.47-1.09 2.49-2.45l.27-15.52M95.61 128.27s-4.66-4.37.68-8.15c5.34-3.78 2.68-6.19 4.43-8.89 2.46-3.77 10.38-5.42 14.38 3.78 4 9.19-13.51 19.73-19.49 13.26zM50.97 113.06l-1.4 1.23c-3.56 3.13-3.85 8.59-.63 12.08l9.29 10.06a5.52 5.52 0 007.93.19c2.6-2.56 2.05-6.88-1.11-8.71l-2.02-1.17a5.505 5.505 0 01-2.75-4.62l-.15-5.07c-.13-4.68-5.65-7.08-9.16-3.99z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M30.55 104.6v46.23s80.87-5.42 126.84-17.85c0 0 2.65-29.98 0-51.4L30.55 104.6z'
      />
      <path
        fill='#F6E1B5'
        d='M30.55 104.6c8.8-13.84 64.61-47.97 64.61-47.97s33.33-3.92 62.23 24.96l-14.91 3.94a584.664 584.664 0 01-80.39 15.33l-31.54 3.74z'
      />
      <path
        fill='#559C9C'
        d='M126.22 87.56s-5.65-5.56 3.1-7.63c10.74-2.55 19.2 3.82 19.2 3.82l-22.3 3.81zM79.92 74.96c-.13.03-.26.06-.39.1l-.53.16c-2.73.86-3.7 3.08-2.19 5 .54.68.53 1.48-.05 2.09l-.58.61c-1.69 1.79-.15 4.34 3.08 5.11l.21.04c1.26.3 2.55.32 3.69.08 1.13-.23 2.12-.71 2.8-1.39l2.23-2.28c.36-.38.92-.64 1.55-.77.06-.01.13-.02.19-.04l2.34-.35.33-.06c2.5-.51 3.74-2.32 2.7-4.07-.98-1.64-3.65-2.64-6.13-2.26l-1.59.25c-1.4.21-2.93-.26-3.72-1.13-.85-.95-2.51-1.38-3.94-1.09M108.28 64.61c-.43.07-.82.24-1.09.5l-4.15 4.01c-2.18 2.1.79 5.25 4.72 5l5.88-.38c.18-.01.35-.03.52-.06 1.82-.3 2.95-1.49 2.59-2.84l-.06-.22c-.41-1.55-1.7-3.01-3.58-4.08l-2.81-1.58c-.61-.34-1.37-.45-2.02-.35'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M30.55 104.6c8.8-13.84 63.16-47.38 63.16-47.38s40.87-1.33 63.68 24.37l-14.91 3.94a584.664 584.664 0 01-80.39 15.33l-31.54 3.74z'
      />
    </svg>
  );
}

export default SvgSnackFurgonzola;
