import * as React from 'react';

function SvgIconTicket(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-ticket_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 20 22'
      xmlSpace='preserve'
      {...props}>
      <style />
      <path d='M16.5 9.84L8.84 17.5l3.5 3.5 1.81-1.81a2.849 2.849 0 010-4.04 2.849 2.849 0 014.04 0L20 13.34l-3.5-3.5zM7.66 1L5.85 2.81a2.849 2.849 0 010 4.04 2.849 2.849 0 01-4.04 0L0 8.66l7.7 7.7 7.66-7.66L7.66 1z' />
    </svg>
  );
}

export default SvgIconTicket;
