import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Vapor = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.16} cx={234} cy={151} rx={19} ry={20} />
    <ellipse opacity={0.16} cx={158} cy={150} rx={21} ry={22} />
    <circle fill='#FFF' cx={161} cy={141} r={24} />
    <circle cx={161} cy={141} r={19} />
    <path
      fill='#FFF'
      d='M173.29 123.89c-.05 0-.09-.77-.13-.77-4.27 0-7.74 3.47-7.74 7.74s3.47 7.74 7.74 7.74c2.84 0 5.31-1.53 6.66-3.81-1.25-4.41-3.51-7.63-6.53-10.9z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={141}
      r={24}
    />
    <circle fill='#FFF' cx={232} cy={141} r={24} />
    <circle cx={232} cy={141} r={19} />
    <path
      fill='#FFF'
      d='M244.21 123.89c-.05 0-.09-.77-.13-.77-4.27 0-7.74 3.47-7.74 7.74s3.47 7.74 7.74 7.74c2.84 0 5.31-1.53 6.66-3.81-1.25-4.41-3.51-7.63-6.53-10.9z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={141}
      r={24}
    />
  </svg>
);

export default Vapor;
