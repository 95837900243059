import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../components/Auth/AuthContext';
import ForceLoginDialog from './ForceLoginDialog';
import { useWalletSelector } from '../hooks';
import { isVuplexExpected } from '../pages/App/vuplexBridge';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IBeforeLeavePrompt {}

export const BeforeLeavePrompt: FunctionComponent<IBeforeLeavePrompt> = (
  props,
) => {
  const classes = useStyles();
  const { isSignedIn } = useAuthContext();
  const triedLogin = useWalletSelector((s) => !!s.triedLogin);
  const [mouseLeft, setMouseLeft] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const isEmbedded = isVuplexExpected();
  const [everSignedIn, setEverSignedIn] = React.useState(isSignedIn);

  React.useEffect(() => {
    if (isSignedIn) setEverSignedIn(true);
  }, [isSignedIn]);

  const loadedAt = React.useMemo(() => new Date().getTime(), []);

  function onMouseLeave(e: MouseEvent): void {
    const elapsed = new Date().getTime() - loadedAt;
    if (elapsed < 3000) return;
    setMouseLeft(true);
  }

  React.useEffect(() => {
    document.addEventListener('mouseleave', onMouseLeave);
    return () => {
      document.removeEventListener('mouseleave', onMouseLeave);
    };
  }, []);

  React.useEffect(() => {
    if (
      !everSignedIn &&
      !isEmbedded &&
      !open &&
      mouseLeft &&
      !triedLogin &&
      !isSignedIn
    ) {
      setOpen(true);
      setMouseLeft(false);
    } else {
      setOpen(false);
    }
  }, [open, mouseLeft, triedLogin, isSignedIn, isEmbedded, everSignedIn]);

  return (
    <ForceLoginDialog
      open={open}
      onClose={() => setOpen(false)}
      analyticsPathComponent={'/prompt'}
      title={"Don't Miss Out!"}
      subtitle={'Tournament winners often earn $50 or more!'}
    />
  );
};

export default BeforeLeavePrompt;
