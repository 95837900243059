import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SideSmile = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ebebeb'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m190.54 145.12-8.55 13.7c-8.62 14.07 2.84 21.69 2.93 21.78 2.07 1.87 16.83 7.8 25.8-4.36 2.86-3.88 3.04-8.19 1.84-12.55-.75-2.72-10.87-17.46-10.87-17.46-4.25-5.58-8.53-5.24-11.15-1.11z'
    />
    <path d='M196 167c.36-1.33 2.16-2.35 3.48-3.57 2.69-2.48 9.25-4.47 6.63-7.45-.86-.98-2.15-1.51-3.49-1.51h-15.39s-5.43 1.99 0 5.42c3.41 2.15 8.61 7.69 8.77 7.11z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M195 165v9'
    />
    <path d='M189.96 170.38s8.52 6.46 15.76-6.63c0 0 1.32 12.73-12.93 13.17-11.47.36-7.55-8.9-2.83-6.54z' />
  </svg>
);

export default SideSmile;
