import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Theme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import useCommonStyles from '../../components/useCommonStyles';
import { useWalletSelector } from '../../hooks';
import clsx from 'clsx';
import { purple } from '../../theme';

import { IFurballContext, IFurballContracts } from '../../wallet/WalletTypes';
import AdminGift from './AdminGift';
import FurballEditor from './FurballEditor/FurballEditor';
import SvgCaret from '../../assets/SvgCaret';
import { makeStyles } from '@mui/styles';
import AirdropRequestList from './AirdropRequestList';
import { CommunityFlag } from '../../components/schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    backgroundColor: 'white',
  },
}));

interface OwnProps {}

type Props = OwnProps;

const Admin: FunctionComponent<Props> = (props) => {
  const flags = useWalletSelector((s) => s.contents?.communityFlags ?? []);
  const classes = useStyles();
  const common = useCommonStyles();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [pickupTokenId, setPickupTokenId] = React.useState<string>('');
  const [pickupLootItem, setPickupLootItem] = React.useState<string>('');

  async function initFurballs(
    contracts: IFurballContracts,
    context: IFurballContext,
  ) {
    const addresses = context.network.deployment?.addresses;
    if (!addresses) throw 'no addresses';
    await contracts.furballs.init(
      addresses.fur,
      addresses.engine,
      addresses.governance,
      addresses.editions[0].edition,
    );
  }

  async function pickupLoot(
    contracts: IFurballContracts,
    context: IFurballContext,
  ) {
    const id = Number.parseInt(pickupLootItem, 16);
    await contracts.furballs.pickup(pickupTokenId, id);
  }

  if (!flags.includes(CommunityFlag.Admin)) return null;

  function renderAccordion(
    title: string,
    child: React.ReactNode,
  ): React.ReactNode {
    return (
      <Accordion
        // key={`${tab.interactionType ?? ''}-${tab.title}`}
        className={common.cardRoot}
        // defaultExpanded={tab.expanded}
      >
        <AccordionSummary
          className={common.cardHeader}
          expandIcon={
            <React.Fragment>
              <SvgCaret fill={'#FFF'} className={common.expandIcon} />
            </React.Fragment>
          }>
          <Typography variant={'h5'} style={{ color: 'white' }}>
            {title}
          </Typography>
          {/*{tab.tix && <Typography variant={'h6'} className={classes.tix}>{tab.tix} TIX</Typography>}*/}
        </AccordionSummary>
        <AccordionDetails className={classes.content}>{child}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Grid
      container
      className={clsx(common.fullPadding, common.fullScreen)}
      style={{ backgroundColor: purple.dark }}>
      <Grid item xs={12}>
        {renderAccordion('Send Gift', <AdminGift />)}
        {renderAccordion('Aidrop Requests', <AirdropRequestList />)}
        {renderAccordion('Edit Furball', <FurballEditor />)}
        {/*<Typography variant={'h4'} >*/}
        {/*  Admin Tools*/}
        {/*</Typography>*/}
        {/*<Paper className={common.paddingSm} >*/}
        {/*  <TransactionButton invoke={initFurballs} setError={setError} >Init</TransactionButton>*/}
        {/*  <Grid container>*/}
        {/*    <Grid item xs={6}>*/}
        {/*      <TextField value={pickupTokenId} onChange={(e) => setPickupTokenId(e.target.value)} />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={4}>*/}
        {/*      <TextField value={pickupLootItem} onChange={(e) => setPickupLootItem(e.target.value)} />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={2}>*/}
        {/*      <TransactionButton invoke={pickupLoot} setError={setError} >Pickup Loot</TransactionButton>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*  <Typography variant={'subtitle2'} >*/}
        {/*    {error}*/}
        {/*  </Typography>*/}
        {/*</Paper>*/}
      </Grid>
    </Grid>
  );
};

export default Admin;
