import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Scar = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M237.56 197.6s-1.98-1.76-2.64-4.48M241.59 194.97s-1.98-1.76-2.64-4.48M245.62 192.33s-1.98-1.76-2.64-4.48'
    />
    <path d='M250.21 185.33c-1.04 1.61-2.29 3.03-3.62 4.36a36.478 36.478 0 0 1-4.26 3.69c-1.52 1.12-3.1 2.16-4.8 3-1.69.85-3.49 1.57-5.39 1.86l8.83-6.69c1.48-1.09 2.95-2.19 4.49-3.23 1.51-1.05 3.07-2.07 4.75-2.99z' />
  </svg>
);

export default Scar;
