import {
  IFurballContracts,
  ITransactionError,
  NetworkType,
  SetTransactionState,
  TransactionState,
  TxnErrReason,
} from './WalletTypes';
import { BigNumber } from 'ethers';
import { ILogger } from '../utils';

export function getRevertedReason(msg: string | undefined): string | undefined {
  if (!msg) return undefined;
  const rs = "reverted with reason string '";
  if (msg.startsWith(rs)) {
    const ss = msg.substr(rs.length);
    return ss.substr(0, ss.length - 1);
  }
  const rs2 = 'execution reverted: ';
  if (msg.startsWith(rs2)) {
    const ss = msg.substr(rs2.length);
    return ss.substr(0, ss.length - 1);
  }
}

export function getTransactionError(
  log: ILogger,
  e: unknown,
): ITransactionError {
  const message = getTransactionErrorMessage(log, e);
  const msg = message.toLowerCase();
  let reason = TxnErrReason.Unknown;

  // transaction failed
  if (msg.includes('replacement fee')) {
    reason = TxnErrReason.Replacement;
  } else if (msg.includes('user denied')) {
    reason = TxnErrReason.UserDenied;
  } else if (msg.includes('exceeds block gas limit')) {
    reason = TxnErrReason.MaxGas;
  } else if (msg.includes('out of gas')) {
    reason = TxnErrReason.OutOfGas;
  } else if (msg.includes('transaction was replaced')) {
    reason = TxnErrReason.Replaced;
  } else if (
    msg.includes('nonce too high') ||
    msg.includes('nonce has already')
  ) {
    reason = TxnErrReason.Nonce;
  } else if (
    msg.includes("reverted with reason string 'pre'") ||
    msg.includes('execution reverted: pre')
  ) {
    reason = TxnErrReason.Pre;
  } else if (msg.includes('erc20: burn amount exceeds balance')) {
    reason = TxnErrReason.Balance;
  } else if (msg.includes('cannot estimate gas')) {
    reason = TxnErrReason.ErrGas;
  }

  return { message, reason };
}

export interface ITransactionOpts {
  gasPrice?: BigNumber;
  gasLimit: number;
}

export function getTransactionErrorMessage(log: ILogger, e: unknown): string {
  // const { log } = useFurComponent(getTransactionError.name);
  // @ts-ignore
  const msg: string | undefined = e.data?.message;
  const rr = getRevertedReason(msg);
  if (rr) return rr;
  if (msg) return msg;
  // @ts-ignore
  const msg2: string = e.message ?? e.toString();
  if (msg2.startsWith("[ethjs-query] while formatting outputs from RPC '")) {
    const json = msg2.substr(
      "[ethjs-query] while formatting outputs from RPC '".length,
    );
    try {
      const data = JSON.parse(json.substr(0, json.length - 1));
      log.info('json', data);
      if (data.value?.data?.message) return data.value?.data?.message;
      else if (data) return JSON.stringify(data);
    } catch (e) {
      log.error('not json', json);
    }
  }
  return msg2;
}

export function getNetworkType(networkId: number) {
  if (networkId === 1) return NetworkType.MainNet;
  if (networkId === 31337 || networkId === 1337) return NetworkType.DevNet;
  return NetworkType.TestNet;
}

export function getNetworkName(networkId: number): string {
  if (networkId === 31337) return 'Dev';
  if (networkId === 1) return 'ethereum';
  if (networkId === 3) return 'ropsten';
  if (networkId === 4) return 'rinkeby';
  if (networkId === 5) return 'goerli';
  if (networkId === 42) return 'kovan';
  return `#${networkId.toString()}`;
}

export async function contractTransaction(
  log: ILogger,
  estimateGas: (contracts: IFurballContracts) => Promise<BigNumber>,
  run: (opts: ITransactionOpts, contracts: IFurballContracts) => Promise<any>,
  update: (contracts: IFurballContracts) => Promise<any>,
  setTransactionState: SetTransactionState,
  contracts?: IFurballContracts,
  gasMult = 1.3,
  fallbackGasLimit = 5000000,
): Promise<number> {
  if (!contracts) return 0;
  setTransactionState(TransactionState.EstimatingGas);

  const opts: ITransactionOpts = { gasLimit: fallbackGasLimit };
  try {
    opts.gasLimit = Math.round(
      (await estimateGas(contracts)).toNumber() * gasMult,
    );
    // opts.gasPrice = (await contracts.furballs.provider.getGasPrice()); // .mul(11).div(10)
  } catch (e) {
    const te = getTransactionError(log, e);
    log.error('GAS', te, e);
    if (te.reason > TxnErrReason.Unknown) {
      setTransactionState(TransactionState.None, te);
      return 0;
    }
  }
  let tx;
  try {
    log.info('TXN OPTS', opts);
    setTransactionState(TransactionState.SendingRequest);
    tx = await run(opts, contracts);
    log.info('TXN OBJ', tx);
    setTransactionState(TransactionState.AwaitingConfirmations);
    const res = await tx.wait();
    const gasUsed = res.gasUsed.toNumber();
    log.info(
      'GAS USED',
      gasUsed,
      '=>',
      Math.round((100 * gasUsed) / (opts.gasLimit / gasMult)),
      '%',
    );
    setTransactionState(TransactionState.QueryingUpdate);
    log.info('UPDATE');
    await update(contracts);
    log.info('COMPLETE');
    setTransactionState(TransactionState.Complete);
    return gasUsed;
  } catch (e) {
    const te = getTransactionError(log, e);
    log.error('TRANSACTION ERROR', te, tx);
    setTransactionState(TransactionState.None, te);
  }
  return 0;
}

export function getPurchaseFur(
  purchaseFur: { [key: string]: number },
  count: number,
): number {
  const breaks: number[] = Object.keys(purchaseFur)
    .map((k) => Number.parseInt(k))
    .sort();
  for (let i = 0; i < breaks.length; i++) {
    if (count < breaks[i]) {
      return purchaseFur[`${breaks[i]}`];
    }
  }
  return purchaseFur[`${breaks[breaks.length - 1]}`];
}
//
// export function isNetworkLiveForWallet(networkType: NetworkType, walletAddress: string | undefined): boolean {
//   if (networkType == NetworkType.MainNet) return false;
//   if (networkType == NetworkType.DevNet) return true;
//   if (!walletAddress) return false;
//   return walletAddress === wallets.owner || wallets.admin.includes(walletAddress);
// }

export function secSinceBlock(ts: number, interval?: number): number {
  return (
    (Math.floor(new Date().getTime() / 1000) - ts) * (3600 / (interval ?? 3600))
  );
}

type Tuple = [string, string];

function tup(num: number, name: string, plural: string): Tuple {
  const v = Math.round(num * 10) / 10;
  return [v.toString(), v === 1 ? name : plural];
}

export function approxDuration(sec: number): Tuple {
  if (sec < 60) return ['mere', 'seconds'];
  const min = Math.floor(sec / 60);

  const hrs = min / 60;
  const days = hrs / 24;
  const months = days / 30;

  if (months >= 1) {
    return tup(months, 'mon', 'mon');
  }

  if (days >= 1) {
    return tup(days, 'day', 'days');
  }

  if (hrs >= 1) {
    return tup(hrs, 'hour', 'hours');
  }

  return tup(min, 'min', 'min');
}

export function approxTimeSince(ts: number, interval: number): Tuple {
  const sec = secSinceBlock(ts, interval);
  return approxDuration(sec);
}

export function intervalTime(intervals: number): Tuple {
  return approxDuration(intervals * 3600);
}
