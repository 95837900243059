import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Theme } from '@mui/material';

import OkDialog from '../../components/OkDialog';
import { IGiftData } from './AdminGift';
import WalletContext from '../../wallet/WalletContext';
import { contractTransaction } from '../../wallet';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import { BigNumber } from 'ethers';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IUnknown {}

type Props = {
  gift?: IGiftData;
  onClose: () => void;
};

export const AdminGiftTix: FunctionComponent<Props> = (props) => {
  const { gift, onClose } = props;
  const classes = useStyles();
  const { log } = useFurComponent(AdminGiftTix);
  const context = React.useContext(WalletContext);
  const [state, setState] = React.useState<string | undefined>(undefined);

  async function sendGift() {
    if (!gift) return;

    const fuels = gift.amounts.map((v) => v * 10000);

    await contractTransaction(
      log,
      async (contracts) => {
        if (!contracts.fuel) return BigNumber.from(0);
        return await contracts.fuel.estimateGas.gift(gift.tokenIds, fuels);
      },
      async (opts, contracts) => {
        if (!contracts.fuel) return;
        return await contracts.fuel.gift(gift.tokenIds, fuels, opts);
      },
      async (contracts) => {
        return;
      },
      (s: TransactionState, e?: ITransactionError) => {
        if (e) setState(e.message);
        else setState(TransactionState[s]);
      },
      context?.network.contracts,
    );
  }

  React.useEffect(() => {
    if (gift) setState(undefined);
  }, [gift]);

  return (
    <OkDialog
      title={'Confirm TIX Gift(s)'}
      open={!!gift}
      onClose={onClose}
      topRight={true}>
      <Grid container style={{ minWidth: 500 }}>
        {gift &&
          gift.tokenIds.map((tokenId, i) => {
            return (
              <React.Fragment key={i}>
                <Grid item xs={8}>
                  {tokenId}
                </Grid>
                <Grid item xs={4}>
                  {(gift.amounts[i] * 10000).toLocaleString()} FUEL (10k / TIX)
                </Grid>
              </React.Fragment>
            );
          })}
        <Grid item xs={12}>
          {!state && (
            <Button
              variant={'contained'}
              color={'primary'}
              style={{ width: '100%' }}
              onClick={sendGift}>
              SEND NOW
            </Button>
          )}
          {state ? state : null}
        </Grid>
      </Grid>
    </OkDialog>
  );
};

export default AdminGiftTix;
