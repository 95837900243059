import React, { FunctionComponent, HTMLAttributes, ReactNode } from 'react';

import { Typography, Theme, Checkbox, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0.7rem 0',
    userSelect: 'none',

    '&[data-skill="true"]': {
      '& img': {
        position: 'relative',
        top: '0.3rem',
      },
    },

    '&[data-weapon="true"]': {
      '& div svg': {
        position: 'relative !important',
        right: '0.7rem',
        bottom: '0.1rem',
        transform: 'scale(1.4) rotate(10deg)',
      },
    },
  },

  iconContainer: {
    width: '2.8rem',
    height: '2.8rem',
    borderRadius: '100%',
    backgroundColor: '#D9D9D9',
    display: 'grid',
    placeItems: 'center',
    flexShrink: 0,
    position: 'relative',

    '& svg': {
      maxWidth: '1.8rem',
      maxHeight: '1.8rem',
      position: 'static !important',
    },
  },

  icon: {
    width: '100%',
    height: '100%',
  },
}));

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  description?: string;
  icon?: string | ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
}

export const MarketplaceFilterItem: FunctionComponent<IProps> = ({
  title,
  description,
  icon,
  isChecked,
  isDisabled,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      wrap='nowrap'
      gap='0rem'
      alignItems='center'
      className={classes.root}
      style={{ cursor: isDisabled ? 'normal' : 'pointer' }}
      {...props}>
      <Checkbox color='secondary' checked={isChecked} disabled={isDisabled} />
      <div>
        <Grid container wrap='nowrap' gap='0.6rem' alignItems='center'>
          {icon && (
            <div className={classes.iconContainer}>
              {typeof icon === 'string' ? (
                <img className={classes.icon} src={icon} alt={title} />
              ) : (
                icon
              )}
            </div>
          )}
          <div>
            <Grid
              container
              direction='column'
              gap='0.1rem'
              justifyItems='center'>
              <Typography
                variant='body1'
                fontSize='0.9rem !important'
                fontWeight={700}>
                {title}
              </Typography>
              <Typography variant='body1' fontSize='0.6rem !important'>
                {description}
              </Typography>
            </Grid>
          </div>
        </Grid>
      </div>
    </Grid>
  );
};
