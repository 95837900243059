import _ from 'lodash';
import React, { useMemo } from 'react';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { getRarityLevelName } from '../../pages/Play/helpers';
import { rarityInNum } from '../../utils';
import { usePlayersReadyFurballs } from '../../wallet';
import WalletSlice from '../../wallet/WalletSlice';
import { IEquipment, IFurball, IGameItemId } from '../../wallet/WalletTypes';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import {
  ItemGroup,
  ItemQuantityFragment,
  ItemsFragment,
  useInventoryQuery,
  ItemListingFragment,
} from '../schema';
import { IFilterOption, ITEMGROUP_FILTER_OPTIONS } from './InventoryFilters';
import { getInventoryQuantities } from '../Furballs';

export interface IOwnedItem {
  id: string;
  name: string;
  item?: ItemsFragment;
  furball?: IFurball;
}

export function isEquippedItemGroup(group?: ItemGroup): boolean {
  if (!group) return false;
  const equipTypes = [
    ItemGroup.EquipmentBody,
    ItemGroup.EquipmentHead,
    ItemGroup.EquipmentWeapon,
    ItemGroup.EquipmentRing,
    ItemGroup.EquipmentOffHand,
  ];
  return equipTypes.includes(group);
}

export const getItemName = (
  item: ItemsFragment | IEquipment,
  furball?: IFurball,
): string => {
  // const own = furball ? ` ${furball.name}` : '';
  return `${getItemDef(item.itemId)?.name ?? '?'} (${getRarityLevelName(
    item.rarity,
  )})`;
};

export const getFurballInventoryItems = (
  furball: IFurball,
  includeEquipped?: boolean,
): IOwnedItem[] => {
  // if (furball.activeRentalAgreement) return []; // Active rental = inaccessible item
  const items = furball.inventory.items.map((item) => {
    return { id: item.id, name: getItemName(item, furball), item, furball };
  });

  if (includeEquipped) {
    furball.equipment.forEach((item) => {
      const itemDef = getItemDef(item.itemId);

      items.push({
        id: item.id,
        name: getItemName(item, furball),
        item: {
          ...item,
          itemGroup: itemDef && itemDef.itemGroup,
        },
        furball,
      });
    });
  }

  return items;
};

export const sortItemIdsInGroups = (a: number, b: number): number => {
  const ia = getItemDef(a);
  const ib = getItemDef(b);
  if (!ia && !ib) return 0;
  if (!ia) return 1;
  if (!ib) return -1;
  return `${ia.itemGroup}_${ia.name}`.localeCompare(
    `${ib.itemGroup}_${ib.name}`,
  );
};

export const getItemQuantityList = (
  quantities: ItemQuantityFragment[],
  items: IGameItemId[],
  onlyTradable?: boolean,
): ItemQuantityFragment[] => {
  const qs = [...quantities];
  const its = [...items];

  const itemIds = _.uniq([
    ...qs.map((i) => i.itemId),
    ...its.map((i) => i.itemId ?? 0),
  ]).sort(sortItemIdsInGroups);

  const filtered = itemIds.filter((id) => {
    if (!onlyTradable) return true;

    const def = getItemDef(id);
    return !!def && !def.untradeable;
  });

  return filtered.map((itemId) => {
    const q = qs.find((qi) => qi.itemId === itemId);
    const others = _.sum(
      its.filter((i) => i.itemId === itemId).map((i) => i.stack),
    );

    return { itemId, quantity: (q?.quantity ?? 0) + others };
  });
};

export const sortInventoryItems = (a: IOwnedItem, b: IOwnedItem) => {
  const aItemGroup = a.item?.itemGroup ?? ItemGroup.Loot;
  const bItemGroup = b.item?.itemGroup ?? ItemGroup.Loot;

  if (aItemGroup === bItemGroup) {
    const aItemDef = getItemDef(a.item?.itemId);
    const bItemDef = getItemDef(b.item?.itemId);

    if (!aItemDef || !bItemDef) {
      return aItemGroup.localeCompare(bItemGroup);
    }

    if (aItemDef.name === bItemDef.name) {
      const aRarity = rarityInNum(aItemDef.rarity);
      const bRarity = rarityInNum(bItemDef.rarity);
      if (aRarity < bRarity) return 1;
      if (aRarity > bRarity) return -1;
    }

    return aItemDef?.name.localeCompare(bItemDef?.name);
  }

  return aItemGroup.localeCompare(bItemGroup);
};

export const filterItemsOnItemGroup = (
  item: ItemsFragment | undefined,
  filterOnItemGroup: IFilterOption,
) => {
  if (!item) return false;
  if (filterOnItemGroup.value === ITEMGROUP_FILTER_OPTIONS[0].value) {
    return true;
  }

  return item.itemGroup === filterOnItemGroup.value;
};

export const getFilteredItems = (
  inventoryItems: IOwnedItem[],
  selectedItemId: number,
  filterOnItemGroup: IFilterOption,
) => {
  const items: (IOwnedItem | null)[] = [...inventoryItems].filter(({ item }) =>
    selectedItemId > 0
      ? item?.itemId === selectedItemId
      : filterItemsOnItemGroup(item, filterOnItemGroup),
  );

  return items;
};

export const useFurballItemList = ({
  playerOnly,
  includePlayerInventory,
  filterOnInventoryFor,
}: {
  playerOnly?: boolean;
  includePlayerInventory?: boolean;
  filterOnInventoryFor?: IFilterOption;
}) => {
  const fbsInventory = useFurballInventoryItems(true);

  if (playerOnly) return [];

  const specificFurballId = !includePlayerInventory
    ? filterOnInventoryFor?.value
    : undefined;

  return fbsInventory
    .filter(
      (i) =>
        !!i.item &&
        i.furball &&
        (!specificFurballId || i.furball.tokenId === specificFurballId),
    )
    .map((i) => i.item as IGameItemId);
};

export const useFurballInventoryItems = (
  includeEquipped?: boolean,
): IOwnedItem[] => {
  const furballs = usePlayersReadyFurballs();
  const numFurballs = furballs?.length;

  return useMemo(() => {
    const items: IOwnedItem[] = [];
    furballs
      // .filter(({ activeRentalAgreement }) => !activeRentalAgreement)
      .map((furball) => {
        const fbItems = getFurballInventoryItems(furball, includeEquipped);
        items.push(...fbItems);
      });

    return items;
  }, [numFurballs]); // Only recompute when loading/changing furballs
};

export const useInventoryHelpers = (containerId?: string) => {
  const dispatch = useAppDispatch();
  const furballs = usePlayersReadyFurballs();
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');
  const id = containerId ?? playerId;

  // playerInventory
  const { data, loading } = useInventoryQuery({
    variables: { containerId: id },
  });
  const loadedInventory = data?.inventory;

  const playerInventory = () => {
    return loadedInventory?.id === id ? loadedInventory : undefined;
  };

  React.useEffect(() => {
    if (!loadedInventory) return;
    // When inventory is loaded from server, manually recompute quantities
    const quants = getInventoryQuantities(loadedInventory);
    dispatch(WalletSlice.actions.setInventory(quants));
  }, [loadedInventory]);

  const playerInventoryItems = (onlyTradable?: boolean): IOwnedItem[] => {
    return [...(playerInventory()?.items ?? [])]
      .filter((item) => {
        if (!onlyTradable) return true;

        const def = getItemDef(item.itemId);
        return !!def && !def.untradeable;
      })
      .map((item) => {
        return { id: item.id, name: getItemName(item), item };
      });
  };

  // all inventory
  const allInventoryItems = (
    onlyTradable?: boolean,
    includeEquipped?: boolean,
  ) => {
    const numFurballs = furballs?.length;
    const playerInventory = playerInventoryItems(onlyTradable);

    return useMemo(() => {
      const fbInventory: IOwnedItem[] = [];

      furballs
        // .filter(({ activeRentalAgreement }) => !activeRentalAgreement)
        .map((furball) => {
          const fbItems = getFurballInventoryItems(furball, includeEquipped);
          fbInventory.push(...fbItems);
        });

      return [...playerInventory, ...fbInventory];
    }, [numFurballs, data]);
  };

  const inventoryItemQuantities = useWalletSelector(
    (c) => c.contents?.inventory.itemQuantities ?? [],
  );

  const updatePlayerFurballInventory = (
    listing: ItemListingFragment,
    item?: IOwnedItem,
  ): void => {
    dispatch(WalletSlice.actions.setInventory(listing.seller.inventory));

    const fbToUpdate = item?.furball;
    const quantity = listing.quantity;

    if (!!fbToUpdate) {
      const items = fbToUpdate.inventory.items;
      const updatedItems = items
        .map((fbItem) => {
          if (fbItem.id === item?.id) {
            const quantityAfterListing = fbItem.stack - quantity;
            return quantityAfterListing === 0
              ? null
              : {
                  ...fbItem,
                  stack: quantityAfterListing,
                };
          }

          return fbItem;
        })
        .filter((fbItem) => !!fbItem);

      dispatch(
        WalletSlice.actions.setFurballs({
          [fbToUpdate.tokenId]: {
            ...fbToUpdate,
            inventory: {
              ...fbToUpdate.inventory,
              items: updatedItems,
            },
          },
        }),
      );
    }
  };

  return {
    playerInventory,
    playerInventoryItems,
    allInventoryItems,
    inventoryItemQuantities,
    updatePlayerFurballInventory,
  };
};
