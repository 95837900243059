import * as React from 'react';
import Preloader from '../Preloader';
import { useAppDownloadUrl } from './appHooks';
import { mobileVendor, mobileModel } from 'react-device-detect';
import { useFurComponent } from '../../utils';
interface IAppDownload {}

const AppDownload: React.FunctionComponent<IAppDownload> = (props) => {
  // const { isMobile } = useClientHardware();
  const url = useAppDownloadUrl();
  const { log } = useFurComponent(AppDownload);

  React.useEffect(() => {
    log.info('[APP]', mobileVendor, mobileModel, 'redirecting to', url);
    window.location.replace(url);
  }, []);

  return <Preloader title={'Opening AppStore...'} />;
};

export default AppDownload;
