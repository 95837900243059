import { SvgEditionSlot } from '../part';
import * as React from 'react';

const ExeQtioner = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M321.76 72.38c-3.64 12.55-17.02 38.65-32.98 34.02-16.01-4.64-13.31-33.84-9.67-46.39s16.46-19.61 27.85-16.31 18.44 16.12 14.8 28.68z'
    />
    <path
      fill='#eceef0'
      d='M279.1 60.01c-3.64 12.55-6.34 41.75 9.67 46.39l18.12-38.82c3.53-7.56 3.55-16.3.06-23.88-11.39-3.3-24.21 3.76-27.85 16.31z'
    />
    <path
      fill='#cfdbd9'
      d='M285.75 58.02c-10.08 36.5 22.02 53.89 3.03 48.38-16.01-4.64-13.31-33.84-9.67-46.39s16.46-19.61 27.85-16.31-15.92-4.86-21.21 14.32z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M321.76 72.38c-3.64 12.55-17.02 38.65-32.98 34.02-16.01-4.64-13.31-33.84-9.67-46.39s16.46-19.61 27.85-16.31 18.44 16.12 14.8 28.68z'
    />
    <path
      fill='#FFF'
      d='M260.87 282.59c3.63-12.53 6.89-43.8-9.08-48.43-16.01-4.64-29.94 23.53-33.57 36.06-3.63 12.53 3.42 25.34 14.81 28.64s24.21-3.74 27.84-16.27z'
    />
    <path
      fill='#eceef0'
      d='M218.22 270.22c3.63-12.53 17.57-40.71 33.57-36.06l-5.98 47.6c-1.06 8.27-5.78 12.58-12.78 17.11-11.39-3.31-18.44-16.11-14.81-28.65z'
    />
    <path
      fill='#cfdbd9'
      d='M227.57 270.22c11.51-43.8 28.23-34.2 24.23-36.06-15.11-7.04-29.94 23.53-33.57 36.06-3.63 12.53 3.42 25.34 14.81 28.64s-10.52-9.42-5.47-28.64z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M260.87 282.59c3.63-12.53 9.8-42.92-9.08-48.43-18.74-5.47-29.94 23.53-33.57 36.06-3.63 12.53 3.42 25.34 14.81 28.64s24.21-3.74 27.84-16.27z'
    />
    <path
      fill='#c7dcf0'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M248.3 246.99c-5.2-1.51-8.42-6.13-7.21-10.32l39.34-135.7c3.31-7.57 20.78-1.31 18.82 5.46l-39.34 135.7c-1.21 4.18-6.41 6.36-11.61 4.86z'
    />
    <path
      opacity={0.29}
      fill='#447e6e'
      d='M243.65 245.64c-2.63-.76-3.77-4.78-2.55-8.97l39.34-135.7c2.8-7.72 11.47-4.01 9.51 2.76l-39.34 135.7c-1.22 4.19-4.34 6.97-6.96 6.21z'
    />
  </svg>
);

export default ExeQtioner;
