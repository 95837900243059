import { Container, Typography } from '@mui/material';
import * as React from 'react';

interface IAppInstallPrompt {}

const AppInstallPrompt: React.FunctionComponent<IAppInstallPrompt> = (
  props,
) => {
  return (
    <Container maxWidth={'sm'} style={{ textAlign: 'center', marginTop: 40 }}>
      <Typography variant={'h5'}>Our mobile app is in beta...</Typography>
      <Typography variant={'body1'}>
        Please join our Discord to receive an invite.
        <br />
        <br />
        Or, visit Furballs.com on a computer to play in a web browser.
      </Typography>
    </Container>
  );
};

export default AppInstallPrompt;
