import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Stag = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M138.62 115.92c-6.55-7.67-17.13-10.85-27.29-10.85s-16.49-3.67-16.49-3.67 13.84 34.53 33.65 35.36l6.16.62c2.52.25 4.75 1.72 6.01 3.91 0 0 11.58 2.6 14.93-20.12l-3.51-3.29s-6.91 5.7-13.46-1.96z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M140.79 140.92c-1.26-2.2-3.61-3.3-6.13-3.55l-6.16-.62c-19.81-.83-33.65-35.36-33.65-35.36s6.34 3.67 16.49 3.67 20.48 3.41 27.29 10.85c7.94 8.68 13.7 2.68 13.7 2.68'
    />
    <path
      fill='#e8bcd9'
      d='M142.72 126.76s-4.14.09-9.96-6.24c-5.08-5.53-14.4-8.88-21.84-7.56 0 0 8.47 25.19 31.8 13.8z'
    />
    <path
      fill='#c27aa9'
      d='M130 124.48c-6.84-8.7-18.12-10.32-18.12-10.32 10.99-3.65 17.76 2.4 20.92 6.37 5.36 6.73 11.34 5.95 11.34 5.95-7.83 3.82-15.83 1.23-21.22-1.04 6.18 1.39 8.4.72 7.08-.96z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M142.7 126.75s-5.17.44-10.4-5.46c-4.05-4.57-12.2-9.11-22.85-8.33 0 0 11.86 24.24 33.25 13.79z'
    />
    <path
      fill={props.renderer.color1}
      d='M254 115.92c6.55-7.67 17.13-10.85 27.29-10.85s16.49-3.67 16.49-3.67-13.84 34.53-33.65 35.36l-6.16.62a7.841 7.841 0 0 0-6.01 3.91s-27.62-3.11-14.93-20.12l3.51-3.29s6.92 5.7 13.46-1.96z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M240.3 118.6s5.76 6 13.7-2.68c6.81-7.44 17.13-10.85 27.29-10.85s16.49-3.67 16.49-3.67-13.84 34.53-33.65 35.36l-6.16.62c-2.52.25-4.87 1.36-6.13 3.55'
    />
    <path
      fill='#e8bcd9'
      d='M249.9 126.76s4.14.09 9.96-6.24c5.08-5.53 14.4-8.88 21.84-7.56 0 0-8.47 25.19-31.8 13.8z'
    />
    <path
      fill='#c27aa9'
      d='M262.62 124.48c6.84-8.7 18.12-10.32 18.12-10.32-10.99-3.65-17.76 2.4-20.92 6.37-5.36 6.73-11.34 5.95-11.34 5.95 7.83 3.82 15.83 1.23 21.22-1.04-6.18 1.39-8.4.72-7.08-.96z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M249.92 126.75s5.17.44 10.4-5.46c4.05-4.57 12.2-9.11 22.85-8.33 0 0-11.85 24.24-33.25 13.79z'
    />
    <path
      fill='#ffc970'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m165.65 106.2-3.36-5.57c-.43-.55-.55-1.28-.31-1.94l1-2.73c1.24-3.38 1.83-6.96 1.74-10.55l-.06-2.48c.16-.88-.97-1.39-1.52-.67l-5.13 9.8c-.29.37-.88.18-.89-.29l-.46-10.91c-.11-6.6-1.01-13.17-2.67-19.56l-6.01-16.86c-.31-1.31-2.12-1.46-2.63-.21-.23.57-.43 1.18-.6 1.81-.72 2.6-.84 5.32-.38 7.98 1.08 6.27 3.28 13.73 3.27 18.65-.01 3.18-.63 4.68-1.24 5.39-.41.47-1.06.66-1.67.52-.9-.21-1.89-.44-2.96-.68-.93-.21-1.66-.91-1.9-1.83l-1.43-5.37c-1.11-3.62-1.29-4.74-4.16-7.2L129 58.98l.82 2.13c1.75 4.52 3.23 9.86 1.67 14.43-4.37-1.08-9.23-3.5-12.6-4.97-16.93-7.41-6.94-18.7-6.94-18.7-8.23.35-12.7 12.11-5.23 19.69 4.62 4.69 29.49 13.2 29.49 13.2l7.39 2.44c1.83.6 3.16 2.19 3.43 4.1l.8 4.81c.17 1.17-.54 2.28-1.67 2.62l-.04.01c-.3.09-.61.12-.92.1-5.23-.46-11.61-3.27-15.15-5.01-1.67-.82-3.2-1.9-4.53-3.2l-4.57-4.45s-2.22-1.99-3.4-.94.05 2.77.05 2.77l2.85 3.65c4.2 5.37 9.93 9.32 16.44 11.33l5.73 2.12c4.87 1.5 9.61 7.37 11.4 12.14 0 0 10.29 0 12.5-5.55l.09-.7c.26-1.68-.09-3.36-.96-4.8z'
    />
    <path d='M150.1 63.22c5.37 13.26 2.69 32.4 2.22 38.92-.47-6.52 1.08-18.05-2.22-38.92zM155.5 95.62c-1.75 11.85 3 14.67 4.94 17.1-1.03-1.17-5.05-8.87-4.94-17.1zM136.49 80.08l-.89-7.32.06 7.06c-1.81-.55-9.45-2.69-11.49-3.14 3.32 2.06 21.78 6.95 23.82 7.41-1.93-.78-9.62-3.42-11.5-4.01zM106.12 61.14c-2.22 8.94 11.84 13.24 13.5 13.72-1.61-.6-12.7-5.2-13.5-13.72zM149.56 61.48c-.26-.58-1.67-6.66-1.69-7.3.26.58 1.9 3.43 1.69 7.3zM145.3 101.98c7.84-.98 9.78 5.33 9.76 6.43-2.74-5.55-8.73-6.1-9.76-6.43z' />
    <path
      fill='#ffc970'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m227.07 106.2 3.36-5.57c.43-.55.55-1.28.31-1.94l-1-2.73A28.557 28.557 0 0 1 228 85.41l.06-2.48c-.16-.88.97-1.39 1.52-.67l5.13 9.8c.29.37.88.18.89-.29l.46-10.91c.11-6.6 1.01-13.17 2.67-19.56l6.01-16.86c.31-1.31 2.12-1.46 2.63-.21.23.57.43 1.18.6 1.81.72 2.6.84 5.32.38 7.98-1.08 6.27-3.28 13.73-3.27 18.65.01 3.18.63 4.68 1.24 5.39.41.47 1.06.66 1.67.52.9-.21 1.89-.44 2.96-.68.93-.21 1.66-.91 1.9-1.83l1.43-5.37c1.11-3.62 1.29-4.74 4.16-7.2l5.26-4.51-.82 2.13c-1.75 4.52-3.23 9.86-1.67 14.43 4.37-1.08 9.23-3.5 12.6-4.97 16.93-7.41 6.94-18.7 6.94-18.7 8.23.35 12.7 12.11 5.23 19.69-4.62 4.69-29.49 13.2-29.49 13.2l-7.39 2.44c-1.83.6-3.16 2.19-3.43 4.1l-.8 4.81c-.17 1.17.54 2.28 1.67 2.62l.04.01c.3.09.61.12.92.1 5.23-.46 11.61-3.27 15.15-5.01 1.67-.82 3.2-1.9 4.53-3.2l4.57-4.45s2.22-1.99 3.4-.94c1.18 1.05-.05 2.77-.05 2.77l-2.85 3.65c-4.2 5.37-9.93 9.32-16.44 11.33l-5.73 2.12c-4.87 1.5-9.61 7.37-11.4 12.14 0 0-4.23 4.19-10.68-.88-.02-.03-5.32-4.4-.93-10.18z'
    />
    <path d='M242.62 63.22c-5.37 13.26-2.69 32.4-2.22 38.92.47-6.52-1.08-18.05 2.22-38.92zM237.22 95.62c1.75 11.85-3 14.67-4.94 17.1 1.03-1.17 5.05-8.87 4.94-17.1zM256.23 80.08l.89-7.32-.06 7.06c1.81-.55 9.45-2.69 11.49-3.14-3.32 2.06-21.78 6.95-23.82 7.41 1.93-.78 9.62-3.42 11.5-4.01zM286.6 61.14c2.22 8.94-11.84 13.24-13.5 13.72 1.61-.6 12.7-5.2 13.5-13.72zM243.16 61.48c.26-.58 1.67-6.66 1.69-7.3-.26.58-1.9 3.43-1.69 7.3zM247.42 101.98c-7.84-.98-9.78 5.33-9.76 6.43 2.74-5.55 8.73-6.1 9.76-6.43z' />
  </svg>
);

export default Stag;
