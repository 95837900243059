import React, { FunctionComponent } from 'react';
import { Theme, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { white } from '../../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: `${theme.palette.secondary.dark} !important`,
    border: `2.5px solid ${theme.palette.secondary.main} !important`,
    borderBottom: `4px solid ${theme.palette.secondary.main} !important`,
    borderRadius: '20px !important',
    textAlign: 'center',
    marginRight: '12px !important',
    height: '50px',
    minWidth: '175px !important',
    fontSize: '0.875rem !important',
    [theme.breakpoints.down('md')]: {
      marginRight: '0px !important',
      marginBottom: '12px !important',
      minWidth: '250px !important',
    },
    '&:hover': {
      backgroundColor: '#242385 !important',
      textDecoration: 'underline !important',
      textDecorationColor: '#fde99a !important',
    },
  },
  iconWrap: {
    width: '25px',
    height: '25px',
    marginRight: '10px',
    marginBottom: '2px',
  },
  textWrapper: {
    backgroundColor: '#242385',
    minWidth: '95%',
    maxHeight: '22.5px',
    borderRadius: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'center',
  },
}));

interface IBalanceButton {
  balance: string;
  path: string;
  units?: string;
  color?: string;
  icon?: React.ReactNode;
}

export const BalanceButton: FunctionComponent<IBalanceButton> = (props) => {
  const { balance, units, icon, path } = props;
  const color = props.color ?? white;
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const samePage = location.pathname === path;

  return (
    <Button
      variant={'text'}
      className={classes.root}
      onClick={() => history.push(path)}
      disabled={samePage}>
      <div className={classes.textWrapper}>
        {icon && <div className={classes.iconWrap}>{icon}</div>}
        <Typography
          variant={'h6'}
          style={{ color, fontSize: '20px', marginBottom: '1px' }}>
          {`${units ? units : ''}${balance}`}
        </Typography>
      </div>
    </Button>
  );
};

export default BalanceButton;
