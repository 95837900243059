import { Box } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import useCommonStyles from '../../../components/useCommonStyles';
import DuelActiveGamesCard from './DuelActiveGamesCard';
import DuelOpenGamesCard from './DuelOpenGamesCard';

interface IDuelLobby {}

const DuelLobby: React.FunctionComponent<IDuelLobby> = (props) => {
  const common = useCommonStyles();

  return (
    <Box className={clsx(common.fullCenter, common.furBk)}>
      <Box m={'auto'} maxWidth={'md'}>
        <DuelOpenGamesCard />
        <br />
        <br />
        <DuelActiveGamesCard />
      </Box>
    </Box>
  );
};

export default DuelLobby;
