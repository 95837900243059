import React, { FunctionComponent } from 'react';
import { usePageTitle } from '../../Analytics';
import { Redirect } from 'react-router-dom';
import { useWalletSelector } from '../../hooks';
import Preloader from '../Preloader';
import OkDialog from '../../components/OkDialog';
import useCommonStyles from '../../components/useCommonStyles';
import { Typography } from '@mui/material';
import { useTimekeeperSelector } from '../../components/Timekeeper/TimekeeperSlice';
import { useAuthContext } from '../../components/Auth/AuthContext';
import AppLogin from './AppLogin';
import { useFurverseContext } from '../Furverse/Furverse';
import { usePlayGamePath } from '../../wallet';

export const AppLanding: FunctionComponent = (props) => {
  const common = useCommonStyles();
  const { vuplexReady, vuplexFailed } = useFurverseContext();
  const { isSignedIn } = useAuthContext();
  const isMetaReady = useTimekeeperSelector((t) => !!t.meta);
  const usableFurballsLoaded = useWalletSelector(
    (s) => s.usableFurballsLoaded ?? false,
  );
  const redirectPath = usePlayGamePath();

  function getPageSection(): string {
    if (vuplexFailed) return 'App Load Failure';
    if (!vuplexReady) return 'Loading App';
    if (!usableFurballsLoaded) return 'Loading Player';
    if (!isMetaReady) return 'Loading Meta';
    return 'Redirecting';
  }

  usePageTitle(getPageSection() + ' | Furballs');

  // Start on the login page...
  if (!isSignedIn) return <AppLogin />;

  // App startup complete...
  if (usableFurballsLoaded && vuplexReady && isMetaReady)
    return <Redirect to={redirectPath} />;

  return (
    <div className={common.fullFur}>
      {!vuplexFailed && <Preloader title={getPageSection() + '...'} />}
      <OkDialog
        title={'Failed to Load App'}
        open={vuplexFailed}
        onClose={() => window.location.reload()}>
        <Typography variant={'h6'}>
          This page is used by the Furballs apps...
        </Typography>
        <Typography variant={'subtitle2'}>
          ... but we couldn't "talk to" the app. Make sure you have the official
          app open, and try restarting.
        </Typography>
        <br />
        <Typography variant={'body1'}>
          Closing this window will refresh the page.
        </Typography>
      </OkDialog>
    </div>
  );
};

export default AppLanding;
