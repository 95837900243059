import React from 'react';
import { IRenderPart } from '../part';
import AsparagusSpear from './00_AsparagusSpear';
import ButterKnife from './01_ButterKnife';
import PoopSquasher from './02_PoopSquasher';
import SadPencil from './03_SadPencil';
import PyroStick from './04_PyroStick';
import StrawofDeath from './05_StrawofDeath';
import SporkDork from './06_SporkDork';
import WhimsyWand from './07_WhimsyWand';
import FurReaper from './08_FurReaper';
import StabbyThing from './09_StabbyThing';
import ExeQtioner from './10_ExeQtioner';
import BubbleWand from './11_BubbleWand';

export function renderEdition0Weapon(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <AsparagusSpear renderer={renderer} />;
  if (renderer.partNumber === 2) return <ButterKnife renderer={renderer} />;
  if (renderer.partNumber === 3) return <PoopSquasher renderer={renderer} />;
  if (renderer.partNumber === 4) return <SadPencil renderer={renderer} />;
  if (renderer.partNumber === 5) return <PyroStick renderer={renderer} />;
  if (renderer.partNumber === 6) return <StrawofDeath renderer={renderer} />;
  if (renderer.partNumber === 7) return <SporkDork renderer={renderer} />;
  if (renderer.partNumber === 8) return <WhimsyWand renderer={renderer} />;
  if (renderer.partNumber === 9) return <FurReaper renderer={renderer} />;
  if (renderer.partNumber === 10) return <StabbyThing renderer={renderer} />;
  if (renderer.partNumber === 11) return <ExeQtioner renderer={renderer} />;
  if (renderer.partNumber === 12) return <BubbleWand renderer={renderer} />;
  console.warn('renderEdition0Weapon', renderer);
}
