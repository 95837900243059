import React, { FunctionComponent } from 'react';
import { usePageTitle } from '../../Analytics';
import { Redirect } from 'react-router-dom';
import { useAuthContext } from '../../components/Auth/AuthContext';

interface ILoginHome {}

// Used for an explicit redirect enforcing a login...
export const LoginHome: FunctionComponent<ILoginHome> = (props) => {
  const { isSignedIn } = useAuthContext();

  usePageTitle('Login | Furballs');

  // This should only be used behind AuthenticatedRoute. If so, we can now redirect back to welcome.
  return <Redirect to={isSignedIn ? '/welcome' : '/'} />;
};

export default LoginHome;
