import { SvgEditionSlot } from '../part';
import * as React from 'react';

const RoyalCape = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#fa4442'
      d='M196.32 217.85s-46.57 48.98-94.6-10.18c0 0 6.84-12.79 20.57-12.09-.01.01 28.62 25.02 74.03 22.27z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M101.72 207.68s6.06-12.18 18.98-12.18'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M96.95 214.2c-2.03-2.46-1.59-6.12.89-8.12 2.49-2 6.12-1.59 8.12.89 13.49 16.83 39.18 37.03 82.7 19.26 7.82-3.19 10.53 1.37 10.53 1.37-.22.22-56.3 42.2-102.24-13.4z'
    />
    <path
      fill='#fa4442'
      d='M203.39 216.99s5.65 16.78 17.26 20.73c22.11 7.52 31.97 6.89 30.85-.25 0 0 5.51-24.34-10.58-32.22 0 0-8.47 7.79-37.53 11.74z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M251.51 237.48s5.51-24.1-10.58-31.98'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M249.81 245.25c-50.62-3.24-47.98-22-47.98-22 .03.04 25.16 12.66 47.98 12.52 2.47-.02 4.46 2.13 4.46 4.75 0 2.61-2 4.73-4.46 4.73z'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M253.49 207.46a8.059 8.059 0 0 1-3.66 4.92c-6.56 3.97-24.01 15.44-52.43 10.38 0 0-.58-7.35 6.91-6.62 13.6 1.31 37.08-10.28 43.14-14.61 1.88-1.34 4.63-1.38 6.11 1.83.49 1.07.29 2.66-.07 4.1zM122.28 195.59s23.53 22.27 57.73 29.06c8.99 1.78 14.64-1.74 15.24-2 0 0 .79-6.91-4.24-7.1-38.25-1.49-50.88-23.95-56.94-28.28-1.88-1.34-4.86-3.57-9.31 1.07-1.81 1.88-4.35 5.9-2.48 7.25z'
    />
    <path
      fill='#ffe083'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M207.42 222.19c0 5.1-4.13 9.23-9.23 9.23-5.1 0-9.23-4.13-9.23-9.23s4.13-8.1 9.23-8.1c5.1 0 9.23 3 9.23 8.1z'
    />
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
  </svg>
);

export default RoyalCape;
