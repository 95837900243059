import React, { FunctionComponent } from 'react';
import { Theme, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { RealmType } from '../../schema';
import OkDialog, { ICloseDialog } from '../../OkDialog';
import RealmCreature from './RealmCreature';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  cont: {
    minWidth: '900px',
  },
  children: {
    width: '475px',
  },
}));

interface IRealmCreatureDialog extends ICloseDialog {
  realmType: RealmType;
  title: React.ReactNode;
  children: React.ReactNode;
  creature?: React.ReactNode;
  className?: string;
}

export const RealmCreatureDialog: FunctionComponent<IRealmCreatureDialog> = (
  props,
) => {
  const { realmType, title, open, onClose, className } = props;
  const classes = useStyles();

  return (
    <OkDialog
      className={clsx(classes.root, className)}
      title={title}
      open={open}
      onClose={onClose}>
      <Grid container spacing={2} className={classes.cont}>
        <Grid item xs={5}>
          {props.creature ? (
            props.creature
          ) : (
            <RealmCreature realmType={realmType} />
          )}
        </Grid>
        <Grid item xs={7}>
          <div className={classes.children}>{props.children}</div>
        </Grid>
      </Grid>
    </OkDialog>
  );
};

export default RealmCreatureDialog;
