import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import FurballRenderer, { IRenderToken } from './FurballRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  furball: {
    position: 'relative',
  },
}));

export const FurballImg: FunctionComponent<IRenderToken> = (props) => {
  const classes = useStyles();
  const childProps = { ...props, className: classes.furball };

  return (
    <div className={clsx(classes.root, props.className)}>
      <FurballRenderer {...childProps} />
    </div>
  );
};

export default FurballImg;
