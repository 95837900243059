import React, { FunctionComponent } from 'react';
import SvgIconWrappedFur from '../../assets/SvgIconWrappedFur';
import CurrencyCard, { ICurrencyCardChoice } from './CurrencyCard';
import ExchangeCurrencies from './ExchangeCurrencies';

interface ISwapCard {}

const choiceSwap: ICurrencyCardChoice = {
  title: 'Swap wFUR',
  render: () => <ExchangeCurrencies />,
};

const FurSwapCard: FunctionComponent<ISwapCard> = (props) => {
  const choices = [choiceSwap];

  return (
    <CurrencyCard
      title={'wFUR'}
      icon={<SvgIconWrappedFur width={32} height={32} />}
      choices={choices}
    />
  );
};

export default FurSwapCard;
