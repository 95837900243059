import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Tears = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#bdefff'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M231.58 190.27a3.417 3.417 0 0 1-.91-3.2l3.32-9.27 3.32 9.27c.27 1.15-.07 2.36-.91 3.2-1.32 1.33-3.49 1.33-4.82 0zM239.91 182.08c-.61-.61-.85-1.48-.66-2.32l2.41-6.71 2.41 6.71c.2.83-.05 1.71-.66 2.32-.96.97-2.53.97-3.5 0zM225.82 177.72c-.64-.64-.9-1.57-.7-2.45l2.55-7.1 2.55 7.1c.21.88-.06 1.81-.7 2.45a2.601 2.601 0 0 1-3.7 0z'
    />
  </svg>
);

export default Tears;
