import React, { FunctionComponent } from 'react';
import { Theme, Grid, TextField, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useCommonStyles from '../../../components/useCommonStyles';
import {
  FurOAuthTokenInput,
  useRequestAirdropMutation,
} from '../../../components/schema';
import { signOAuthToken } from '../../../components/Auth/OAuthSig';
import { useWalletSelector } from '../../../hooks';
import WalletContext from '../../../wallet/WalletContext';
import AirdropRequestList from '../AirdropRequestList';
import { useFurComponent } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  textField: {
    width: '100%',
  },
}));

interface IAirdropRequester {}

interface IAirdropRequest {
  walletId: string;
  fur: string;
  tix: string;
}

const defaultReq: IAirdropRequest = { walletId: '', fur: '', tix: '' };

interface AirdropRequestArgs {
  recipient: string;
  fur: number;
  tix: number;
}

function getAirdropRequestArgs(req: IAirdropRequest): AirdropRequestArgs {
  return {
    recipient: req.walletId,
    fur: Number.parseInt(req.fur),
    tix: Number.parseInt(req.tix),
  };
}

export const AirdropRequester: FunctionComponent<IAirdropRequester> = (
  props,
) => {
  const classes = useStyles();
  const { log } = useFurComponent(AirdropRequester);
  const common = useCommonStyles();
  const walletAddress = useWalletSelector((s) => s.address);
  const context = React.useContext(WalletContext);
  const [airdropRequest, setAirdropRequest] = React.useState<IAirdropRequest>({
    ...defaultReq,
  });
  const args = getAirdropRequestArgs(airdropRequest);
  const [modify, { data, loading, error }] = useRequestAirdropMutation();
  const hasChanges =
    args.recipient.startsWith('0x') && (args.fur > 0 || args.tix > 0);

  async function saveChanges() {
    if (!walletAddress || !context || !hasChanges) return;
    const access = args.fur + args.tix;
    const token: FurOAuthTokenInput = await signOAuthToken(
      walletAddress,
      context,
      access,
    );
    const variables = { token, args };
    try {
      const res = await modify({ variables });
      log.info('saved', res);
    } catch (e) {
      log.error(e, 'Edit');
    }
  }

  function renderAirdropRequest(req: IAirdropRequest): React.ReactNode {
    return (
      <React.Fragment>
        <Grid item xs={8}>
          <TextField
            className={classes.textField}
            placeholder={'Wallet Address'}
            value={req.walletId}
            onChange={(e) => {
              setAirdropRequest({
                ...airdropRequest,
                walletId: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className={classes.textField}
            placeholder={'$FUR'}
            value={req.fur}
            onChange={(e) => {
              setAirdropRequest({ ...airdropRequest, fur: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            className={classes.textField}
            placeholder={'TIX'}
            value={req.tix}
            onChange={(e) => {
              setAirdropRequest({ ...airdropRequest, tix: e.target.value });
            }}
          />
        </Grid>
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Existing Requests</Typography>
        <AirdropRequestList />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h5'}>New Request</Typography>
      </Grid>
      {renderAirdropRequest(airdropRequest)}
      <Grid item xs={12}>
        <Button
          variant={'contained'}
          color={'primary'}
          className={common.actionButton}
          onClick={saveChanges}
          disabled={loading || !hasChanges}>
          Request Airdrop
        </Button>
        {data && <Typography variant={'subtitle2'}>Saved!</Typography>}
        {error && (
          <Typography variant={'subtitle2'}>{error.message}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AirdropRequester;
