import { EventEmitter } from 'events';
import { MaybeFurball } from '../wallet';
import { ZONE_NUM_TOWN } from '../components/Zones/ZoneHelpers';
import { LeaderboardStat, RarityLevel, RealmType } from '../components/schema';
import { itemLibrary } from '../components/Inventory/ItemLibrary';
import { BossName } from '../components/BossFights/BossRewards/BossRewardsClaim';

export const eventEmitter = new EventEmitter();
const SEC_ARRAY = [
  60, // 60 seconds in 1 min
  60, // 60 mins in 1 hour
  24, // 24 hours in 1 day
  7, // 7 days in 1 week
  365 / 7 / 12, // 4.345238095238096 weeks in 1 month
  12, // 12 months in 1 year
];

export function slugify(text: string): string {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-'); // Replace multiple - with single -
}

export interface ITimeDurationMinutes {
  days: number;
  hours: number;
  mins: number;
}

export interface ITimeDuration extends ITimeDurationMinutes {
  secs: number;
}

export function getTimeDurationMinutes(
  dur: ITimeDuration,
): ITimeDurationMinutes {
  return { days: dur.days, hours: dur.hours, mins: dur.mins };
}

export const getTimeBetween = (delta: number): ITimeDuration => {
  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const mins = Math.floor(delta / 60) % 60;
  delta -= mins * 60;

  // what's left is seconds
  const secs = Math.floor(delta % 60);

  return {
    days,
    hours,
    mins,
    secs,
  };
};

export function getTimeUntil(startTime: Date | number | string): ITimeDuration {
  const dur = new Date(startTime).getTime() - new Date().getTime();
  return getTimeBetween(dur / 1000);
}

export const getTimeRemaining = (
  start: any,
  duration: number,
): ITimeDuration => {
  if (!start) {
    return {
      days: 0,
      hours: 0,
      mins: 0,
      secs: 0,
    };
  }

  const startDate = new Date(start);

  // add duration in days to start date
  startDate.setDate(startDate.getDate() + duration);
  const endDate = startDate;

  // get total seconds between end date and present date
  const delta = Math.abs(endDate.getTime() - new Date().getTime()) / 1000;

  // returns difference in days from end of contract to present date
  return getTimeBetween(delta);
};

export const getTimeAgo = (
  timestamp: string | number,
  currentTime: any,
): ITimeDuration => {
  const time = Number(timestamp);
  let date: any;

  isNaN(time) ? (date = new Date(timestamp)) : (date = new Date(time * 1000));

  // get total seconds between current date and benchmark date
  const delta = Math.abs(currentTime - date) / 1000;

  // returns difference in days from present date to benchmark date
  return getTimeBetween(delta);
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date: Date, separator: '/' | '-' | '.' = '-') {
  const ddmmyy = [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join(separator);

  const time = [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(':');

  return { ddmmyy, time };
}

export const fbHaveUpgradeCd = (fb: MaybeFurball) => {
  if (!fb) return false;
  if (fb.zone !== ZONE_NUM_TOWN) return false;

  const { pendingUpgrade } = fb;
  const haveUpgradeCooldown = pendingUpgrade && pendingUpgrade.endsAt != null;
  if (!haveUpgradeCooldown) return false;

  const now = new Date().getTime();
  const cdEndsAt = new Date(pendingUpgrade?.endsAt).getTime();
  const cdIsActive = cdEndsAt > now;

  return cdIsActive;
};

export interface IBossInfo {
  leaderboardStat: LeaderboardStat;
  name: string;
  realm: RealmType;
  lore: string;
}

export const getBosses = (): IBossInfo[] => {
  return [
    {
      leaderboardStat: LeaderboardStat.DamageTrashy,
      name: 'trashy',
      realm: RealmType.TurtleSea,
      lore: 'A smelly pile of goo that spontaneously formed off the coast, Trashy spawns mutant minions to assist him in fighting Furballs. Players who wish to defeat Trashy will benefit from strategies that can target multiple opponents at once...',
    },
    {
      leaderboardStat: LeaderboardStat.DamageSebastian,
      name: 'sebastian',
      realm: RealmType.WizardsPath,
      lore: 'When he accidentally ate his wizard friend, Sebastian began crying rainbow tears. Dont be fooled, though — Sebastian buffs himself (use>s skills which improve his stats) and can deal extraordinary amounts of damage with each attack.',
    },
    {
      leaderboardStat: LeaderboardStat.DamageSkullumbo,
      name: 'skullumbo',
      realm: RealmType.Boneyard,
      lore: 'Deep in the caves, Skullumbo is a big meanie who likes to put others down. He uses lots of debuffs (skills that reduce the stats of your Furballs) to weaken his opponents before hitting them with AOEs (Area of Effect skills, which target multiple opponents at once).',
    },
  ];
};

export const getBossNameFromRealm = (realm: RealmType, capitalize = false) => {
  const bossFromRealm = getBosses().find((boss) => boss.realm === realm);
  const ret = !bossFromRealm ? 'UNKNOWN' : bossFromRealm.name;
  return capitalize ? ret[0].toUpperCase() + ret.substr(1) : ret;
};

export const getEquipableHolidayLoot = () => {
  const flowers = 'Flowers';
  const ghost_potion = 'Ghost Potion';
  const trash_net = 'Trash Net';
  return itemLibrary.filter(
    ({ name }) =>
      name === flowers || name === ghost_potion || name === trash_net,
  );
};

export const rarityInNum = (rarity: RarityLevel) => {
  if (rarity === RarityLevel.Legendary) return 3;
  if (rarity === RarityLevel.Mythic) return 2;
  if (rarity === RarityLevel.Elite) return 1;
  return 0;
};

export const formatNumber = (
  num: number,
  locales?: string,
  notation?: string,
) => {
  const formatter = Intl.NumberFormat(locales ?? 'en', {
    notation: notation ?? 'compact',
  });
  return formatter.format(num);
};

export const secToMinAndSec = (sec: number): string => {
  const mins = Math.floor(sec / 60);
  const secs = sec - mins * 60;
  return `${mins}m ${secs}s`;
};

const DESKTOP_WIDTH_ACCOUNT_PAGE = 1485;
const DESKTOP_WIDTH_PLAYER_PAGE = 1150;
const TABLET_WIDTH_ACCOUNT_PAGE = 1145;
const TABLET_WIDTH_PLAYER_PAGE = 820;
const MOBILE_WIDTH_ACCOUNT_PAGE = 950;
const MOBILE_WIDTH_PLAYER_PAGE = 600;

// BossBattleHistory Component:
// For responsiveness, the component requires
// different breakpoints due to the different
// container width between account and player page
export const getResponsiveWidth = (isOnAccountPage: boolean) => {
  const desktop = isOnAccountPage
    ? DESKTOP_WIDTH_ACCOUNT_PAGE
    : DESKTOP_WIDTH_PLAYER_PAGE;
  const tablet = isOnAccountPage
    ? TABLET_WIDTH_ACCOUNT_PAGE
    : TABLET_WIDTH_PLAYER_PAGE;
  const mobile = isOnAccountPage
    ? MOBILE_WIDTH_ACCOUNT_PAGE
    : MOBILE_WIDTH_PLAYER_PAGE;

  return { desktop, tablet, mobile };
};

export const getAverageNum = (numbers: number[]): number => {
  const totalSum = numbers.reduce((sum, current) => sum + current, 0);
  return Math.round(totalSum / numbers.length);
};

export const getTopIllustrationPos = (bossName: string, off = 0) => {
  const lowercaseBossName = bossName.toLowerCase();
  if (lowercaseBossName === BossName.Trashy) return `${-140 - off}px`;
  if (lowercaseBossName === BossName.Sebastian) return `${-100 - off}px`;
  return `${-122 - off}px`;
};

export const getRealmTypeFromUrl = (realm: string) =>
  realm.toUpperCase().replace('-', '_') as RealmType;
