import {
  ITimekeeperInteraction,
  ITimekeeperRequest,
  ITimekeeperUserState,
  useTimekeeperSelector,
} from './TimekeeperSlice';

import _ from 'lodash';

export function useCurrentTimekeeperRequest(): ITimekeeperUserState {
  return useTimekeeperSelector((s) => s.userState);
}

export function useFuelFactor(): number {
  return 10000;
}

export function useTixPrice(fuelCost: number): number {
  return Math.ceil(fuelCost / useFuelFactor());
}

export function getAllTimekeeperInteractions(
  reqs: ITimekeeperRequest[],
): ITimekeeperInteraction[] {
  const ret: ITimekeeperInteraction[] = [];
  for (let i = 0; i < reqs.length; i++) {
    const interactions = reqs[i].changeSet?.interactions ?? [];
    ret.push(...interactions);
  }
  return ret;
}

export function getTotalTimekeeperTixCost(
  reqs: ITimekeeperRequest[],
  costFactor: number,
): number {
  return _.sumBy(reqs, (r) =>
    Math.ceil((r.changeSet?.fuelCost ?? 0) / costFactor),
  );
}

export function getTotalTimekeeperFurCost(reqs: ITimekeeperRequest[]): number {
  return _.sumBy(reqs, (c) => c.changeSet?.spent ?? 0);
}
