import React, { ChangeEvent, FunctionComponent } from 'react';
import { makeStyles, styled } from '@mui/styles';
import { InputBase, Theme } from '@mui/material';
import themev2, { bodyFont } from '../../themev2';
import { UpdateCurrencyAmount } from './CurrencyExchangeRow';
import { getSwapCurrencyDecimals, SwapCurrency } from './ExchangeCurrencies';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '265px',
    minWidth: '265px',
    textAlign: 'left',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '210px',
      minWidth: '210px',
    },
  },
  balance: {
    margin: '0 !important',
    fontWeight: 700,
    fontSize: '47.5px !important',
    lineHeight: '42.5px !important',
  },
}));

export const SwapAmountTextField = styled(InputBase)((theme) => ({
  '& .MuiInputBase-input': {
    backgroundColor: 'transparent',
    fontSize: '47.5px',
    lineHeight: '42.5px',
    padding: '0',
    height: '50px',
    fontFamily: bodyFont,
    caretColor: `${themev2.palette.secondary.main} !important`,
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::placeholder': {
      color: '#aaaaff',
    },
  },
}));

export interface ICurrencyInput {
  value: number;
  valueStr: string;
  error?: string;
  currency: SwapCurrency;
  updateAmount: UpdateCurrencyAmount;
  setFocused: (f: boolean) => void;
}

// Format in standard US localization
export function formatCurrencyValue(v: number, currency: SwapCurrency): string {
  let s = v.toString();
  const numDecimals = getSwapCurrencyDecimals(currency);
  const parts = s.split('.');
  let decimalStr = parts.length > 1 ? parts[1] : '';
  while (decimalStr.length < numDecimals) decimalStr += '0';
  let ret = numDecimals <= 0 ? '' : '.' + decimalStr;
  // console.log(parts);
  s = parts[0];
  while (s.length > 3) {
    ret = ',' + s.substr(s.length - 3, 3) + ret;
    s = s.substr(0, s.length - 3);
  }
  return s + ret;
}

const CurrencyAmountInput: FunctionComponent<ICurrencyInput> = (props) => {
  const { value, error, valueStr, currency, updateAmount, setFocused } = props;
  const classes = useStyles();
  const allowDecimals = getSwapCurrencyDecimals(currency) > 0;
  const regexGroup = allowDecimals ? '[0-9]*\\.?[0-9]' : '[0-9]';
  const pattern = `^${regexGroup}*$`;
  const regex = new RegExp(pattern);

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    // console.log(e.target.value);
    const str = e.target.value;
    const numVal = str.replaceAll(',', '');

    if (regex.test(numVal)) {
      updateAmount(str, currency);
    }
  }

  return (
    <SwapAmountTextField
      className={classes.root}
      type='text'
      error={!!error}
      // helperText={error}
      placeholder={allowDecimals ? '0.0' : '0'}
      value={valueStr}
      onChange={handleChange}
      inputProps={{ pattern }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
  );
};

export default CurrencyAmountInput;
