import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SkepticalRainbow = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.2} cx={158} cy={150} rx={21} ry={22} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <path d='m217.25 119.79 18.42 1.19-17.81 4.85a3.078 3.078 0 1 1-.61-6.04z' />
    <path
      fill='#FFF'
      d='M250.87 131.17c-12.17-3.56-25.31-3.56-37.48 0h-.01c-2.89 3.44-4.59 7.71-4.59 12.33 0 4.37 1.53 8.41 4.13 11.75l18.25-3.29 20.17 3.29c2.6-3.34 4.13-7.39 4.13-11.75-.01-4.62-1.72-8.89-4.6-12.33z'
    />
    <path
      fill='#6c5fb3'
      d='M161.46 159.07h.02c-.01-.01-.01-.01-.02 0 0-.01 0-.01 0 0z'
    />
    <path
      fill='#d2ff7a'
      d='M153.94 157.23s0 .01.01.01h.01c-.01 0-.02 0-.02-.01z'
    />
    <path
      fill='#d2ff7a'
      d='M153.4 148.03a4.713 4.713 0 0 1-4.71 4.62h-.08c1.4 1.9 3.22 3.47 5.32 4.59.01 0 .01.01.02.01a4.7 4.7 0 0 0 3.35-3.43c.24-.98-3.75-5.77-3.9-5.79z'
    />
    <path
      fill='#7bffff'
      d='M150.7 145.07c-1.19 0-4.29.29-5.12-.44a15.69 15.69 0 0 0 2.51 7.26c.17.26.34.51.52.75h.08c2.57 0 4.66-2.06 4.71-4.62 0-.02-1.45-2.95-2.7-2.95z'
    />
    <path
      fill='#d2c9ff'
      d='M150.94 139.56c-1.49 0-3.63-.57-4.49-1.65-.6 1.67-.93 3.47-.93 5.34 0 .39.05 1.31.06 1.38.83.73 1.92 1.06 3.11 1.17 4.41.43 3.25-6.24 2.25-6.24z'
    />
    <path
      fill='#d2ff7a'
      d='M150.19 132.04a15.849 15.849 0 0 0-3.74 5.88c.86 1.08 2.74 1.89 4.23 1.89 1 0 3.85-2.72 4.08-3.96a4.714 4.714 0 0 1-4.57-3.81z'
    />
    <path
      fill='#7bffff'
      d='M157.91 127.81a15.836 15.836 0 0 0-7.73 4.22c.42 2.16 2.3 3.79 4.57 3.81.02 0 3.63-1.15 4.36-2.8a4.722 4.722 0 0 1-1.2-5.23z'
    />
    <path
      fill='#d2ff7a'
      d='M166.69 128.37c-1.67-.6-3.47-.93-5.35-.93-1.11 0-3.36.36-3.42.37-1.36 2.4.71 5.28.86 5.35 7.6 3.36 7.68-4.59 7.91-4.79zM166.86 128.43c-.01-.06-.03-.12-.05-.18-.04.04-.09.07-.13.11.06.03.12.05.18.07z'
    />
    <path
      fill='#7bffff'
      d='M177.02 141.4c-.15-1.06-.42-2.09-.81-3.08-6.13.52-4.93 6.01-4.33 7.16 1.3-.41 3.03-1.05 4.41-.57.25.09.49.19.72.3.1-.64.14-1.3.14-1.96 0-.62-.05-1.24-.13-1.85z'
    />
    <path
      fill='#d2c9ff'
      d='M176.43 144.91a4.66 4.66 0 0 0-3.48-.06c-1.17.44-3.1 4.13-3.02 4.89.91-.2 1.89-.13 2.81.26.84.35 1.53.92 2.02 1.62.77-1.23 1.38-2.58 1.79-4.01.22-.77.38-1.57.49-2.39-.2-.12-.4-.22-.61-.31z'
    />
    <path
      fill='#d2ff7a'
      d='M172.73 150c-2.7-1.25-5.06.7-5.75 1.75 1.95.84 3.05 2.85 2.81 4.87 1.26-.8 2.4-1.77 3.38-2.88.59-.66 1.12-1.37 1.59-2.12-.5-.7-1.2-1.24-2.03-1.62z'
    />
    <path
      fill='#d2c9ff'
      d='M161.98 152.56c-.62-.76-4.43.28-4.67 1.25a4.7 4.7 0 0 1-3.35 3.43c2.2 1.16 4.71 1.83 7.38 1.83h.12l.01-.01c.69-.61 1.2-1.43 1.44-2.4.36-1.49-.03-2.99-.93-4.1z'
    />
    <path
      fill='#7bffff'
      d='M166.98 151.75c-.02-.01-.03-.01-.05-.02a4.702 4.702 0 0 0-4.96.83c.9 1.11 1.3 2.61.93 4.1a4.76 4.76 0 0 1-1.44 2.4s0 .01.01.01a15.779 15.779 0 0 0 8.32-2.45c.24-2.02-.86-4.03-2.81-4.87z'
    />
    <circle cx={161} cy={143} r={11} />
    <path
      fill='#FFF'
      d='M171.12 126.73c-.04 0-.08-.01-.12-.01-3.95 0-7.16 3.21-7.16 7.16s3.21 7.16 7.16 7.16c2.62 0 4.91-1.42 6.16-3.52a26.467 26.467 0 0 0-6.04-10.79z'
    />
    <path
      fill='#7bffff'
      d='M226.67 144.9a6.24 6.24 0 0 1-5.59 3.46c-1.59 0-4.38-1.94-4.42-1.84.6 2.43 1.9 5.2 3.48 7.03 0 0 8.62-2.49 8.62-4 0-1.84-.81-3.51-2.09-4.65z'
    />
    <path
      fill='#d2c9ff'
      d='M227.19 140.83a6.23 6.23 0 0 1-3.47 1.05c-2.99 0-5.49-2.11-6.1-4.92a15.73 15.73 0 0 0-1.3 6.29c0 1.12.12 2.21.34 3.27.09.09.18.18.27.26 1.1.98 2.55 1.58 4.15 1.58a6.25 6.25 0 0 0 5.59-3.46c.42-.84.65-1.78.65-2.78 0-.44-.05-.87-.13-1.29z'
    />
    <path
      fill='#d2ff7a'
      d='M217.64 136.91c-.01.01-.02.01-.03.02 0 .01.01.02.01.03.01-.01.02-.03.02-.05z'
    />
    <path
      fill='#d2ff7a'
      d='M225.88 133.24c0-1.12 3.26-3.51-1.45-3.8-3 1.68-5.4 4.3-6.79 7.47-.01.02-.01.03-.02.05.61 2.81 3.11 4.92 6.1 4.92 1.28 0 2.47-.39 3.47-1.05.8-.54 1.48-1.26 1.96-2.1a6.241 6.241 0 0 1-3.27-5.49z'
    />
    <path
      fill='#d2c9ff'
      d='M235.09 138.73c.52.86 2.52 2.34 2.68 2.42.12-.74.37-1.47.77-2.15 1.61-2.75 5-3.81 7.86-2.58a15.843 15.843 0 0 0-6.78-7.1c-.75.1-1.48.33-2.15.7.56.94-.43 7.65-2.38 8.71z'
    />
    <path
      fill='#d2ff7a'
      d='M246.55 136.74c.02-.08.03-.15.04-.23-.06-.03-.13-.06-.19-.09.05.11.1.21.15.32z'
    />
    <path
      fill='#d2ff7a'
      d='M246.55 136.74c-.05-.11-.1-.21-.15-.32a6.233 6.233 0 0 0-7.86 2.58c-.4.68-.8 4.58-.32 5.68a6.19 6.19 0 0 1 3.73-1.24c2.44 0 4.54 1.4 5.57 3.43.27-1.16.42-2.37.42-3.62.01-2.32-.49-4.53-1.39-6.51z'
    />
    <path
      fill='#7bffff'
      d='M237.46 130.01c-.59-.98-10.17-.9-10.77.14a6.252 6.252 0 0 0 2.44 8.58c.88.48 5.06.48 5.94 0a6.241 6.241 0 0 0 3.27-5.49 6.05 6.05 0 0 0-.88-3.23zM247.42 147.32c.08-.06.16-.11.25-.17-.04-.09-.09-.19-.13-.28l-.12.45z'
    />
    <path
      fill='#7bffff'
      d='M241.96 143.44c-1.4 0-2.69.46-3.73 1.24-1.52 1.14-4.87 6.11-1.21 8.8l7.21-.03c1.48-1.76 3.27-6.43 3.31-6.58-1.04-2.03-3.14-3.43-5.58-3.43z'
    />
    <circle cx={232} cy={143} r={7} />
    <circle fill='#FFF' cx={235} cy={141} r={2} />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M251.33 155.25c2.6-3.34 4.13-7.39 4.13-11.75 0-4.62-1.71-8.89-4.59-12.33-12.17-3.56-25.31-3.56-37.48 0h-.01c-2.89 3.44-4.59 7.71-4.59 12.33 0 4.37 1.53 8.41 4.13 11.75'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M211.96 157.46c3.96-3.27 11.62-5.48 20.41-5.48s16.45 2.21 20.41 5.48'
    />
  </svg>
);

export default SkepticalRainbow;
