import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import * as React from 'react';
import useCommonStyles from '../../../components/useCommonStyles';
import { Link } from 'react-router-dom';
import OpenSeaLink from '../../../components/Blockchain/OpenSeaLink';
import SvgIconOpenSea from '../../../assets/SvgIconOpenSea';
import themev2, { purple } from '../../../themev2';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';
import { placeholderFurballId } from '../../../wallet';
interface IRentMarketGetFurballs {}

const RentMarketGetFurballs: React.FunctionComponent<IRentMarketGetFurballs> = (
  props,
) => {
  const common = useCommonStyles();
  // const theme = useTheme ();
  const isSmallScreen = useMediaQuery(themev2.breakpoints.down('md'));

  return (
    <div
      className={common.fullFur}
      style={{ paddingTop: 100, textAlign: 'center', width: '100%' }}>
      <Container maxWidth={'md'}>
        <Grid container maxWidth={'md'} spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'h3'}>
              There are 2 Ways to Get Furballs:
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader className={common.cardHeader} title={'1. Borrow'} />
              <CardContent>
                <Link to={'/market/furballs'}>
                  <div
                    style={{
                      maxHeight: 164,
                      position: 'relative',
                      left: isSmallScreen ? '25%' : '8%',
                      bottom: 24,
                    }}>
                    <FurballRenderer
                      tokenId={placeholderFurballId}
                      size={220}
                    />
                  </div>
                  <Typography variant={'h6'}>Explore Furballs</Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
          {/*<Grid item xs={12} md={4}>*/}
          {/*  <Card>*/}
          {/*    <CardHeader className={common.cardHeader} title={'2. Earn'} />*/}
          {/*    <CardContent>*/}
          {/*      <Link to={'/adopt'}>*/}
          {/*        <SvgHouse />*/}
          {/*        <Typography variant={'h6'}>Mint a Furball</Typography>*/}
          {/*      </Link>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={6}>
            <Card>
              <CardHeader className={common.cardHeader} title={'2. Buy'} />
              <CardContent>
                <OpenSeaLink>
                  <SvgIconOpenSea
                    fill={purple.dark}
                    style={{ maxHeight: 160 }}
                  />
                  <Typography variant={'h6'}>Visit OpenSea</Typography>
                </OpenSeaLink>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default RentMarketGetFurballs;
