import * as React from 'react';

function SvgZoneIconsInventory(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' {...props}>
      <g>
        <path d='M1.5 8.3h17v11h-17zM18.6 4.7H1.4C.8 4.7.3 5.2.3 5.8v1.6h19.4V5.9c0-.7-.5-1.2-1.1-1.2z' />
        <path d='M4.2 8.3h2v2.6h-2zM13.9 8.3h2v2.6h-2zM15.6 6.8H4.4V3.2C4.4 1.8 5.5.7 6.9.7h6.3c1.4 0 2.5 1.1 2.5 2.5v3.6zM5.9 5.3h8.3V3.2c0-.5-.4-1-1-1H6.8c-.5 0-1 .4-1 1v2.1z' />
      </g>
    </svg>
  );
}

export default SvgZoneIconsInventory;
