import * as React from 'react';
import { FormationDefinition } from '../pages/Furverse/FormationLibrary';
import { FormationPosition } from '../components/schema';

interface Ownprops extends React.SVGProps<SVGSVGElement> {
  formation?: FormationDefinition;
}

function SvgIconDice(props: Ownprops) {
  const { formation } = props;

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 485 485'
      xmlSpace='preserve'
      {...props}>
      <g>
        <path
          strokeWidth='20px'
          stroke={props.fill}
          opacity={formation?.includes(FormationPosition.BottomBack) ? 1 : 0.1}
          d='M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z'
        />

        <path
          strokeWidth='20px'
          stroke={props.fill}
          opacity={formation?.includes(FormationPosition.TopBack) ? 1 : 0.1}
          d='M118.75,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,153.75,118.75,153.75z'
        />

        <path
          strokeWidth='20px'
          stroke={props.fill}
          opacity={formation?.includes(FormationPosition.Center) ? 1 : 0.1}
          d='M242.5,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S223.201,277.5,242.5,277.5z'
        />

        <path
          strokeWidth='20px'
          stroke={props.fill}
          opacity={formation?.includes(FormationPosition.BottomFront) ? 1 : 0.1}
          d='M366.25,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,401.25,366.25,401.25z'
        />

        <path
          strokeWidth='20px'
          stroke={props.fill}
          opacity={formation?.includes(FormationPosition.TopFront) ? 1 : 0.1}
          d='M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z'
        />
      </g>
    </svg>
  );
}

export default SvgIconDice;
