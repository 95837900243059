import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Drool = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M206.29 151.3c-.31 2.24-3.83 8.44-8.56 8.44s-8.77-6.19-8.56-8.44c.56-6.05 17.83-5.15 17.12 0z' />
    <path
      fill='#ffa6d3'
      d='M189.83 164.57c.02-.72-.43-.58 0 0s.14.23 0 .94c-.44 2.27-.53 7.94-.81 11.7-.09 1.16 1.37 1.74 2.11.84.58-.72 1.09-1.54 1.59-2.31 1.26-1.95 2.77-3.74 4.46-5.33.28-.27.68-.41 1.07-.33.66.14.74.73.75 1.25.11 4.01-1.75 7.99-1.02 11.94.05.26.12.53.32.7.31.25.77.13 1.12-.05 1.65-.84 2.58-2.6 3.35-4.28a86.6 86.6 0 0 0 3.99-10.32c.19-.6.22-1.26.02-1.86-.51-1.53-11.78-4.1-16.95-2.89'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M189 164h17'
    />
    <path
      fill='#ffa6d3'
      d='m193.07 181.33-3.9 6.15c-.56.88-3.88 5.9 1.16 6.2 0 0 3.67.38 3.5-3.19l-.76-9.16z'
    />
  </svg>
);

export default Drool;
