import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SprinklePony = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ff96f1'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M72.99 239.5s.01-5.93-12.23-6.75c-7.39-.49-15.41 5.78-19.68 14.92-7.44 15.94 8.76 20.94 3.6 33.06-2.92 6.87-11.71 9.93-11.71 9.93s21.79 1.75 27.19-8.61 12.83-42.55 12.83-42.55z'
    />
    <path
      fill='#ff96f1'
      d='M72.99 239.5s.01-5.93-12.23-6.75c-7.39-.49-15.41 5.78-19.68 14.92-7.44 15.94 8.76 20.94 3.6 33.06-2.92 6.87-11.71 9.93-11.71 9.93s21.79 1.75 27.19-8.61 12.83-42.55 12.83-42.55z'
    />
    <path
      fill='#FFF'
      d='M37.89 289.93s8.78-3.06 11.71-9.93c5.16-12.12-11.04-17.12-3.6-33.06 3.27-7.01 8.76-12.34 14.47-14.21-7.31-.32-15.18 5.91-19.39 14.93-7.44 15.94 8.76 20.94 3.6 33.06-2.92 6.87-11.71 9.93-11.71 9.93l4.92-.72z'
      opacity={0.4}
    />
    <path
      opacity={0.4}
      fill='#d95bc8'
      d='M60.04 279.88s-7.76-12.84-4.44-30.96c2.88-15.72 12.12-12.84 12.12-12.84s5.81-1.72 5.27 3.42-12.95 40.38-12.95 40.38z'
    />
    <path
      fill='none'
      stroke='#96ffea'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m50 253-3-2M52 268l2 2'
    />
    <path
      fill='none'
      stroke='#ff9175'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M53 248h0'
    />
    <path
      fill='none'
      stroke='#fffa96'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m51 261-1 2M50 244h0M50 282h0'
    />
    <path
      fill='none'
      stroke='#9f96ff'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M48 259h0M56 238l-2 2'
    />
    <path
      fill='none'
      stroke='#ff9175'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m54 275-1 3'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M72.99 239.5s.01-5.93-12.23-6.75c-7.39-.49-15.41 5.78-19.68 14.92-7.44 15.94 8.76 20.94 3.6 33.06-2.92 6.87-11.71 9.93-11.71 9.93s21.79 1.75 27.19-8.61 12.83-42.55 12.83-42.55z'
    />
  </svg>
);

export default SprinklePony;
