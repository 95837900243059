import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Paw = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse
      transform='rotate(-60.561 157.306 178.827)'
      cx={157}
      cy={178}
      rx={2}
      ry={1}
    />
    <ellipse
      transform='rotate(-72.638 152.762 176.861)'
      cx={152}
      cy={176}
      rx={2}
      ry={1}
    />
    <ellipse
      transform='rotate(-54.043 158.888 184.155)'
      cx={158}
      cy={184}
      rx={2}
      ry={1}
    />
    <ellipse
      transform='rotate(-79.158 147.783 179.36)'
      cx={147}
      cy={179}
      rx={2}
      ry={1}
    />
    <path d='M155.31 184.62c.04-.09.08-.18.11-.27.46-1.33-.14-2.83-1.38-3.48-1.49-.78-3.3-.15-3.99 1.36 0 0 0 .01-.01.01-.33.73-1.02 1.24-1.82 1.29-1.1.06-2.44.35-2.82 1.18-.68 1.51.04 3.3 1.61 3.89 1.32.5 1.93-.32 3.23.22 1.16.48 1.05 1.62 2.29 2.28 1.49.78 3.3.15 3.99-1.36.38-.84-.3-2.04-.99-2.91-.49-.64-.55-1.49-.22-2.21z' />
  </svg>
);

export default Paw;
