import { SvgEditionSlot } from '../part';
import * as React from 'react';

const PyroStick = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffe67f'
      d='M295 41.32s25.92 8.37 17.26 37.63c-3.82 12.89 1.84 16.57 4.81 16.2 1.28-.16 2.36-.9 2.92-1.89l6.01-9.4s26.25 52.05-31.99 58.69c0 0-28.73-.54-29.33-30.07-.26-12.61 7.1-28.3 7.1-26.9 0 1.4 8.66-9.47 20.92-22.78s2.3-21.48 2.3-21.48z'
    />
    <path
      fill='#ffbd7f'
      d='M304.52 77.27c.84-5.39-11.92-5.02-18.68 4.98-5.52 8.17-14 22.56-15.07 30.29-1.38 10.02 4.92 19.94 5.39 21.06 7.93 2.43 18.87 8.39 18.87 8.39 7.72-.81-.41.12 4.97-1.75.31-.6 16.42-4.64 20.94-17.69 7.3-21.07-19.82-23.39-16.42-45.28z'
    />
    <path
      fill='#c5c5fe'
      d='M294.04 95.44c-12.5 0-20.19 10.4-21.65 21.02-1.19 8.6 4.22 17.12 4.63 18.08 6.81 2.09 16.2 7.21 16.2 7.21 6.63-.7-.35.11 4.27-1.5.26-.52 13.9-3.99 17.77-15.19 6.26-18.1-8.72-29.62-21.22-29.62z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M295 41.32s25.47 6.38 19.2 33.24c-4.39 18.83-.08 20.03 2.88 19.56 6.84-1.08 8.92-10.26 8.92-10.26s21.55 53.32-26.9 58.35c-6.38.66-12.83-.57-18.43-3.7-6.89-3.85-14.22-11.37-16-26.03-2.42-20.01 11.73-33.95 24.28-45.31C302.38 55.02 295 41.32 295 41.32z'
    />
    <path fill='#c7673c' d='m242 296-7 4-2-2 58-174 7 3z' />
    <path fill='#ffb88c' d='m235 299-17-7 67-169 7 3z' />
    <path
      opacity={0.57}
      fill='#ba7447'
      d='M275.33 158.87c7.14-2.24 6.98.98 9.84 1.81l7.84-24.31-13.28-4.94s-13.97 30.44-4.4 27.44z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m286.44 108.95 4.08.72-70.57 178.69a4.64 4.64 0 0 0 2.62 6.02l12.37 4.87c1.01.4 2.15.24 3.02-.41l2.49-1.87a8.461 8.461 0 0 0 2.93-4.06l57.75-173.23'
    />
    <path d='M298.21 140.13c5-4.41 9.31-10.41 10.97-14.87 3.27-8.81-1.21-18.61-10.02-21.88-8.81-3.27-18.61 1.21-21.88 10.02-1.66 4.46-2.31 11.82-1.41 18.43.41 3.01.09 6.07-.97 8.91l-1.4 3.77c-.58 1.57-.63 3.32-.01 4.88.66 1.68 2.05 3.03 4.92 1.2 5.4-3.45 5.96 2.26 9.89 2.02 3.19-.2 4.82-5.09 4.82-5.09 1.06-2.85 2.82-5.38 5.09-7.39z' />
    <path
      fill='#475254'
      d='M305.24 119.52c2.06-.57 3.04-2.54 2.23-4.44-1.53-3.59-4.78-6.75-9.15-8.37-4.33-1.61-8.82-1.37-12.31.32-1.93.93-2.48 3.12-1.23 4.95 1.75 2.56 5.02 5.01 9.16 6.55 4.17 1.54 8.3 1.82 11.3.99z'
    />
  </svg>
);

export default PyroStick;
