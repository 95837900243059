import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';

import Play from './pages/Play';
import Admin from './pages/Admin';
import Shop from './pages/Shop';
import Upgrade from './pages/Upgrade';
import Preloader from './pages/Preloader';
import HeaderBar from './HeaderBar';
import EmbeddedFurball from './pages/Embed';
import TimekeeperStateLoader from './components/Timekeeper/TimekeeperStateLoader';
import ToggleTimekeeper from './components/Timekeeper/ToggleTimekeeper';
import VoyageSpinsGame from './components/Zones/VoyageSpinsGame';
import PlayerHome from './components/Players/PlayerHome';
import TkAdHocAuthorization from './pages/Timekeeper/TkAdHocAuthorization';
import CommunityLeads from './pages/Admin/CommunityLeads';
import HomeV2 from './pages/HomeV2/HomeV2';
import { Furball } from './pages/Furball';
import { Account } from './pages/Account';
import { Tickets } from './pages/Tickets';
import { Boss, gameBattleRootPath } from './pages/Furverse';
import { AuthProvider } from './components/Auth/AuthContext';
import { AuthenticatedRoute } from './components/Auth/AuthenticatedRoute';
import LeaderboardsV2 from './pages/Ranks/LeaderboardsV2';
import SwapCurrencies from './pages/Swap/SwapCurrencies';
import PressKit from './components/Press/PressKit';
import MarketRoutes from './pages/Marketplace/MarketRoutes';
import Welcome from './pages/Account/Welcome';
import LoginHome from './pages/HomeV2/LoginHome';
import GetFurballs from './pages/Home/GetFurballs';
import LootBox from './pages/Account/LootBox';
import AlertProvider from './components/Alert/AlertProvider';
import BeforeLeavePrompt from './wallet/BeforeLeavePrompt';
import AppLanding from './pages/App/AppLanding';
import PlayerFurballLoader from './components/Auth/PlayerFurballLoader';
import AppLink from './pages/App/AppLink';
import AppDownload from './pages/App/AppDownload';
import AppLogin from './pages/App/AppLogin';
import SignupFurballs from './pages/Home/SignupFurballs';
import Furverse from './pages/Furverse/Furverse';
import FurversePage from './pages/Furverse/FurversePage';
import NewsPage from './pages/App/NewsPage';
import UnifursityPage from './pages/App/UnifursityPage';
import { NotificationsProvider } from './components/Notifications/NotificationsContext';

interface OwnProps {}

type Props = OwnProps;

const App: FunctionComponent<Props> = (props) => {
  return (
    <React.Suspense fallback={<Preloader />}>
      <AlertProvider>
        <AuthProvider>
          <Furverse>
            <NotificationsProvider>
              <PlayerFurballLoader />
              <TimekeeperStateLoader /> {/* actually, this is metadata */}
              <BeforeLeavePrompt />
              <Switch>
                <Route path={'/(embed|e)/:tokenId'}>
                  <EmbeddedFurball />
                </Route>
                <Route path='/get/furballs/:source?'>
                  <GetFurballs />
                </Route>
                <Route path='/signup/:source?'>
                  <SignupFurballs />
                </Route>
                <Route path='/app/download'>
                  <AppDownload />
                </Route>
                <Route path='/app/link'>
                  <AppLink />
                </Route>
                <Route path='/app/login'>
                  <AppLogin />
                </Route>
                <Route path='/app'>
                  <AppLanding />
                </Route>
                <AuthenticatedRoute path={'/furverse'}>
                  <FurversePage />
                </AuthenticatedRoute>
                <Route path={'/'}>
                  <HeaderBar />
                  <Switch>
                    <AuthenticatedRoute path={'/admin'}>
                      <Admin />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={'/mods/:query?'}>
                      <CommunityLeads />
                    </AuthenticatedRoute>
                    <Route path='/news'>
                      <NewsPage />
                    </Route>
                    <Route path='/(guide|unifursity)'>
                      <UnifursityPage />
                    </Route>
                    <Route path={'/welcome/:source?'}>
                      <Welcome />
                    </Route>
                    <AuthenticatedRoute path={'/account'}>
                      <Account />
                    </AuthenticatedRoute>
                    {/*<AuthenticatedRoute path={'/adopt'}>*/}
                    {/*  <Adopt />*/}
                    {/*</AuthenticatedRoute>*/}
                    <AuthenticatedRoute path={'/play'} furballsRequired={true}>
                      <Play />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={`/${gameBattleRootPath}`}>
                      <Boss />
                    </AuthenticatedRoute>
                    {/*<Route path={'/guide'}>*/}
                    {/*  <Guide />*/}
                    {/*</Route>*/}
                    <AuthenticatedRoute path={'/shop/upgrade/:lootName'}>
                      <Upgrade />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={'/shop'}>
                      <Shop />
                    </AuthenticatedRoute>
                    <Route path={'/leaderboards'}>
                      <LeaderboardsV2 />
                    </Route>
                    <Route path={'/player/:query'}>
                      <PlayerHome />
                    </Route>
                    <AuthenticatedRoute path={'/voyages/:realm/:tokenId'}>
                      <VoyageSpinsGame />
                    </AuthenticatedRoute>
                    <Route path={'/(furball|fb)/:tokenId?'}>
                      <Furball />
                    </Route>
                    <AuthenticatedRoute path={'/timekeeper/toggle/:tokenIds?'}>
                      <ToggleTimekeeper />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={'/timekeeper/authorize'}>
                      <TkAdHocAuthorization />
                    </AuthenticatedRoute>
                    {/*<Route path={'/timekeeper/:round?'}>*/}
                    {/*  <Timekeeper />*/}
                    {/*</Route>*/}
                    <AuthenticatedRoute path={'/tix/:transfer?'}>
                      <Tickets />
                    </AuthenticatedRoute>
                    {/*<Route path={'/scholar'}>*/}
                    {/*  <Raffle />*/}
                    {/*</Route>*/}
                    <AuthenticatedRoute
                      path={`${gameBattleRootPath}`}
                      furballsRequired={true}>
                      <Boss />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path={'/(swap|buy)'}>
                      <SwapCurrencies />
                    </AuthenticatedRoute>
                    <AuthenticatedRoute path='/market'>
                      <MarketRoutes />
                    </AuthenticatedRoute>
                    <Route path='/(press|media)'>
                      <PressKit />
                    </Route>
                    <AuthenticatedRoute path={'/loot-box'}>
                      <LootBox />
                    </AuthenticatedRoute>
                    <Route path={'/login/:source?'}>
                      <LoginHome />
                    </Route>
                    <Route path={'/'}>
                      <HomeV2 />
                    </Route>
                  </Switch>
                </Route>
              </Switch>
            </NotificationsProvider>
          </Furverse>
        </AuthProvider>
      </AlertProvider>
    </React.Suspense>
  );
};

export default App;
