import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Fox = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M82 221.2c-23.02-14.32-26.52-63.42-7.56-75.84 0 0-18.55-3.66-22.75 23.49l-4.24-19.43S34 171.15 42.3 184.78l-11.9-14.12s-2.77 33.22 16.13 47.72l-13.45-6.81s6.66 30.57 37.74 36.92c0 0 33.57-13.36 11.18-27.29z'
    />
    <g opacity={0.12}>
      <path d='M73.4 146.1c.34-.26.68-.51 1.04-.74 0 0-2.82-.56-6.51.37 2.56-.23 4.57.15 5.47.37zM34.37 212.22l-1.29-.65s.09.4.29 1.13l3.57 2.42c-.93-.93-1.78-1.9-2.57-2.9zM83 222.2c-21.2-8.09-27.64-60.47-11.81-76.1-18.32 13.12-25.31 60 5.5 87.89 10.55 9.57 11.11-9.96 6.31-11.79z' />
    </g>
    <path
      fill={props.renderer.color2}
      d='M55.77 170.05c3-19.38 10.51-21.69 16.51-23.97-2.84-.29-16.08.6-20.04 21.72-.37 1.97 1.92 3.96 3.53 2.25zM43.07 184.81c-6.54-16.07.96-28.32 4.37-32.73 0 0-4.12 33.35-4.37 32.73zM79.57 243.97c-29.79-3.72-45.2-31.76-45.2-31.76s6.12 30.75 37.2 37.11l8-5.35zM50.8 216.28C34.96 195.76 31.48 172 31.48 172s-1.56 30.6 15 46.44c0 0 6.28.37 4.32-2.16z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M83 222.2c-22.32-8.52-27.52-64.42-8.56-76.84 0 0-18.55-3.66-22.75 23.49l-4.24-19.43S34 171.15 42.3 184.78l-11.9-14.12s-2.77 33.22 16.13 47.72l-13.45-6.81s6.66 30.57 37.74 36.92'
    />
  </svg>
);

export default Fox;
