import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import Wallet from './wallet';
import { CssBaseline, ThemeProvider } from '@mui/material';
import FurballClient from './FurballClient';
import themev2 from './themev2';
import Preloader from './pages/Preloader';

const container = document.createElement('div');
document.body.appendChild(container);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={themev2}>
      <CssBaseline />
      <React.Suspense fallback={<Preloader title={'Connecting...'} />}>
        <FurballClient>
          <Router>
            <React.Suspense fallback={<Preloader title={'Loading...'} />}>
              <Wallet>
                <App />
              </Wallet>
            </React.Suspense>
          </Router>
        </FurballClient>
      </React.Suspense>
    </ThemeProvider>
  </Provider>,
  container,
);
