import React, { FunctionComponent } from 'react';
import { Theme, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import OkDialog from '../../OkDialog';
import TimekeeperSlice, {
  // isNewlyCompletedDilemmaEncounter,
  // setCompletedDilemmaEncounter,
  useTimekeeperSelector,
} from '../../Timekeeper/TimekeeperSlice';
import { useAppDispatch } from '../../../hooks';
import { IVoyageEncounter } from '../../../wallet/WalletTypes';
import FurballImg from '../../Furballs/FurballImg';
import { cdnRoot } from '../../../theme';

import PlayerFurballLink from '../../Players/PlayerFurballLink';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  furballContainer: {
    backgroundSize: 'cover',
  },
  furballImg: {
    transform: 'scale(-1,1)',
    marginRight: 20,
  },
}));

interface IRealmDilemmaResultDialog {}

export interface IRealmDilemmaNotification {
  title: string;
  subtitle: string;
  summary: string;
  description: React.ReactNode;
}

export function dilemmaNotificationFromEncounter(
  encounter: IVoyageEncounter,
  furballName?: React.ReactNode,
): IRealmDilemmaNotification {
  return {
    title: dilemmaTitle(encounter),
    subtitle: dilemmaSubtitle(encounter),
    summary: dilemmaSummary(encounter),
    description: dilemmaDesc(encounter, furballName ?? null),
  };
}

export function dilemmaSummary(encounter: IVoyageEncounter): string {
  if (encounter.outcome === null) {
    return 'Debating with';
  }
  const otherDecision = encounter.match?.decision ?? 0;
  if (encounter.didPlayerComplete) {
    if (encounter.decision === 1) {
      return otherDecision === 1 ? 'Traveled together with' : 'Betrayed by';
    } else {
      return otherDecision === 1 ? 'Ignored' : 'Mutually ignored by';
    }
  } else {
    return 'Did not make any decision with';
  }
}

export function dilemmaTitle(encounter: IVoyageEncounter) {
  const otherDecision = encounter.match?.decision ?? 0;
  if (encounter.decision === 1) {
    return otherDecision === 1 ? 'We Frens!' : 'Bummer!';
  } else {
    return otherDecision === 1 ? 'Sucker!' : 'Nope, byeee!';
  }
}

export function dilemmaSubtitle(encounter: IVoyageEncounter) {
  const otherDecision = encounter.match?.decision ?? 0;
  if (encounter.decision === 1) {
    return otherDecision === 1 ? "Isn't that sweet?" : 'That sneaky fox!';
  } else {
    return otherDecision === 1 ? "Got 'em!" : 'Much Shame.';
  }
}

export function dilemmaDesc(
  encounter: IVoyageEncounter,
  fbName: React.ReactNode,
): React.ReactNode {
  const otherDecision = encounter.match?.decision ?? 0;
  if (encounter.decision === 1) {
    return otherDecision === 1 ? (
      <React.Fragment>
        You and {fbName} both agreed to travel together; each receive 15k $FUR!
      </React.Fragment>
    ) : (
      <React.Fragment>
        Looks like {fbName} pulled one over on you, but you still get 5k $FUR!
      </React.Fragment>
    );
  } else {
    return otherDecision === 1 ? (
      <React.Fragment>
        You've duped {fbName} and win the 30k $FUR for yourself!
      </React.Fragment>
    ) : (
      <React.Fragment>
        You and {fbName} ignored each other and neither receives any $FUR.
      </React.Fragment>
    );
  }
}

export const RealmDilemmaResultDialog: FunctionComponent<
  IRealmDilemmaResultDialog
> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const voyageEncounterReward = useTimekeeperSelector(
    (s) => s.voyageEncounterReward,
  );
  const encounterMatch = voyageEncounterReward?.match;
  const backgroundImage = `url(${cdnRoot}/svgs/pd-bk-${
    voyageEncounterReward?.decision ?? 0
  }_${encounterMatch?.decision ?? 0}.svg)`;
  const link = encounterMatch ? (
    <PlayerFurballLink tokenId={encounterMatch.tokenId} />
  ) : (
    'a wild Furball'
  );

  function onClose() {
    if (voyageEncounterReward)
      dispatch(TimekeeperSlice.actions.updateActiveEncounter(undefined));
  }

  return (
    <OkDialog
      className={clsx(classes.root)}
      title={
        voyageEncounterReward ? dilemmaTitle(voyageEncounterReward) : '???'
      }
      open={!!voyageEncounterReward}
      onClose={onClose}
      maxWidth={'sm'}>
      {voyageEncounterReward && encounterMatch && (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography variant={'h4'}>
              {dilemmaSubtitle(voyageEncounterReward)}
            </Typography>
            <Typography variant={'body1'}>
              {dilemmaDesc(voyageEncounterReward, link)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Container
              className={classes.furballContainer}
              style={{ backgroundImage }}>
              <FurballImg
                size={180}
                className={classes.furballImg}
                tokenId={encounterMatch.tokenId}
              />
            </Container>
          </Grid>
        </Grid>
      )}
    </OkDialog>
  );
};

export default RealmDilemmaResultDialog;
