import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Corgi = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.04c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <path
      fill={props.renderer.color2}
      d='M164.11 210.63s27.58 37.63-6.99 53.77c-16.98 7.93-29.12 11.22-50.52-1.99 0 0 0 16.13-7.69 19.96l-.23.27 4.99 11.22h85.72c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.04-15.41 39.87-50.01 3.93-15.39-1.16-35.4-1.16-35.4-11.05 5.33-39.12 16.28-72.7 3.73z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17'
    />
    <path
      fill={props.renderer.color1}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color2}
      d='M217.51 166.52c-3.76-5.81-8.28-12.61-11.49-18.85-4.59-8.92-5.18-13.28-2.23-22.86.05-.17.11-.34.16-.51 1.3-4.14-3.91-6.37-6.88-6.49-.79-.03-.29-.04-.81-.03-2.94.08-8.17 2.5-6.99 6.38l.03.11c2.79 9.11 3.08 12.94-.96 21.58-5.08 10.87-15.15 25.8-29.65 34.95-7.15 4.51-19.74 7.91-22.69 10.21 67 59 128.83-4.49 123-5-15.83-1.39-32.86-6.15-41.49-19.49z'
    />
    <path
      fill={props.renderer.color2}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill={props.renderer.color2}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Corgi;
