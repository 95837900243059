import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Hippo = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.04c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <path
      fill={props.renderer.color2}
      d='m134.72 243.74 3.01-2.8c2.15-2 5.2-2.71 8.01-1.85l15.57 4.77c1.83.56 3.4 1.74 4.45 3.33 2.77 4.2 1.16 9.88-3.41 12l-5.96 2.75a17.349 17.349 0 0 1-20.95-5.08l-1.6-2.05a8.219 8.219 0 0 1 .88-11.07zM132 225.54l.12-2.41a4.822 4.822 0 0 1 2.63-4.05l8.52-4.35c1-.51 2.14-.66 3.24-.41 2.89.64 4.53 3.7 3.46 6.46l-1.39 3.6a10.198 10.198 0 0 1-10.92 6.42l-1.51-.21a4.86 4.86 0 0 1-4.15-5.05zM63.52 249.52l5.03-1.36c1.2-.33 2.48-.29 3.64.16.08.03 2.75 1.86 3.34 3.28.47 1.14 1.15 2.9 1.65 4.69.81 2.91-.06 6.03-2.23 8.13l-4.3 4.17c-1.08 1.39-4.59 2.58-4.59 2.58-3.86.56-7.03.04-7.38-3.84-.33-3.8 2.4-17.77 4.84-17.81zM87.14 249.81c-.55 2.46-3.67 4.83-6.13 4.28-2.46-.55-3.21-3.8-2.66-6.25s2.19-3.2 4.65-2.65 4.69 2.17 4.14 4.62zM128.77 216.4c1.25.71 2.04 2.81 1.33 4.06-.71 1.25-2.61 1.13-3.86.42s-1.39-1.73-.68-2.99c.7-1.25 1.95-2.2 3.21-1.49zM122 203.97l1.48 5.47a7.464 7.464 0 0 0 9.5 4.21l8.19-3.72-8.4-12.96-10.77 7z'
    />
    <path
      fill={props.renderer.color2}
      d='M114.26 208.01c-34.18 11.51-48.9 34.7-48.9 34.7 8.24-8.67 14.19-3.22 14.19-3.22 8.61 7.58 24.64 17.96 37.79 5.36 18.99-18.19-3.08-36.84-3.08-36.84z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m132.95 196.17-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h113.95c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill={props.renderer.color1}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color2}
      d='m140.68 141.76-.03-.2-.03.17c-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 12.4 11.25 26.46 28.59 35.76 1.4-4.32 2.14-8.63 2.14-12.8 0-8.66-5.59-14.92-11.66-21.6-15.61-17.17-3.66-24.85-4.45-30.13zM257.06 161.91l-2.26 1.87c-2.34 1.94-2.82 5.35-1.1 7.85l1.05 1.53c2.4 3.49 6.34 5.42 10.38 5.36.95-2.7 1.45-5.38 1.45-7.99 0-3.08-.7-6.08-2.01-8.95l-1.73-.64a5.824 5.824 0 0 0-5.78.97zM261.36 186.03l-.26-1.47c-.62-3.54-4.25-5.69-7.65-4.55a5.927 5.927 0 0 0-3.11 2.46l-6.14 9.87a5.866 5.866 0 0 0-.19 5.87l1.39 2.59c.13.24.27.47.43.68 6.61-4.62 11.93-9.94 15.53-15.45z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Hippo;
