import { SvgEditionSlot } from '../part';
import * as React from 'react';

const RainbowPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <linearGradient
      id='RainbowPower__a'
      gradientUnits='userSpaceOnUse'
      x1={61}
      y1={-678}
      x2={231}
      y2={-602}
      gradientTransform='matrix(1 0 0 -1 0 -388)'>
      <stop
        offset={0}
        style={{
          stopColor: '#b0fbc6',
        }}
      />
      <stop
        offset={0.079}
        style={{
          stopColor: '#f1f1a2',
        }}
      />
      <stop
        offset={0.165}
        style={{
          stopColor: '#f9c6f6',
        }}
      />
      <stop
        offset={0.263}
        style={{
          stopColor: '#a4d0ff',
        }}
      />
      <stop
        offset={0.37}
        style={{
          stopColor: '#9dfadf',
        }}
      />
      <stop
        offset={0.498}
        style={{
          stopColor: '#bafbb8',
        }}
      />
      <stop
        offset={0.616}
        style={{
          stopColor: '#ffef9c',
        }}
      />
      <stop
        offset={0.737}
        style={{
          stopColor: '#ffd3f4',
        }}
      />
      <stop
        offset={0.872}
        style={{
          stopColor: '#ded8fd',
        }}
      />
      <stop
        offset={0.973}
        style={{
          stopColor: '#95b0fc',
        }}
      />
    </linearGradient>
    <path
      fill='url(#RainbowPower__a)'
      d='M241.91 204.04c-55.63 29.76-98.12-3.98-104.4-10.36l-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53 0-.04.01-.07.01-.11.02-.17.05-.34.07-.51.57-4.02 1.5-6.96 2.58-9.11z'
    />
    <g opacity={0.15}>
      <path d='M158.97 284.01c-20.59.54-45.82-1.12-52.37-11.67-8.54 7.71-2.93 21.51-2.93 21.51s32.24-.18 59.06-.55l-3.76-9.29zM188.79 292.81c5.34-.15 8.81-.33 9.32-.51 9.92-3.67 47.92-26.93 40.96-68-4.97 19.03-10.77 50.26-52.17 57.82l1.89 10.69z' />
    </g>
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01M154.48 268.6l5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <linearGradient
      id='RainbowPower__b'
      gradientUnits='userSpaceOnUse'
      x1={223}
      y1={-635}
      x2={94}
      y2={-732}
      gradientTransform='matrix(1 0 0 -1 0 -388)'>
      <stop
        offset={0}
        style={{
          stopColor: '#f9c6f6',
        }}
      />
      <stop
        offset={0.181}
        style={{
          stopColor: '#a4d0ff',
        }}
      />
      <stop
        offset={0.301}
        style={{
          stopColor: '#9dfadf',
        }}
      />
      <stop
        offset={0.429}
        style={{
          stopColor: '#bafbb8',
        }}
      />
      <stop
        offset={0.581}
        style={{
          stopColor: '#ffef9c',
        }}
      />
      <stop
        offset={0.737}
        style={{
          stopColor: '#ffd3f4',
        }}
      />
      <stop
        offset={0.935}
        style={{
          stopColor: '#d8d9fd',
        }}
      />
    </linearGradient>
    <path
      fill='url(#RainbowPower__b)'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
  </svg>
);

export default RainbowPower;
