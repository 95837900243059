import React, { useCallback, useContext } from 'react';
import { useWalletSelector } from '../../hooks';
import { getTimeAgo, getTimeRemaining, ILogger } from '../../utils';
import { useEndRentalAgreementMutation } from '../schema';
import { useLoadedFurball } from '../../wallet';
import { IFurball } from '../../wallet/WalletTypes';
import { AlertContext, ALERT_TYPES } from '../Alert/AlertContext';
import { useFurballHelpers } from '../Furballs';
import PlayerLink from '../Players/PlayerLink';

export const REFRESH_EVENT = 'REFRESH-RENT-SEARCH-RESULTS';
export const useScholarshipHelpers = (
  log: ILogger,
  tokenId: string,
  active?: boolean,
  fb?: IFurball,
) => {
  const furball = fb ?? useLoadedFurball(tokenId);
  const account = useWalletSelector((s) => s.contents);

  // const [rewards, setRewards] = useState<IRewardObject[]>([]);
  const [endAgreement, { loading }] = useEndRentalAgreementMutation();

  const { addAlert } = useContext(AlertContext);
  const { removeFurballs } = useFurballHelpers(log);

  const agreement = active
    ? furball?.activeRentalAgreement
    : furball?.openRentalAgreement ?? furball?.activeRentalAgreement;

  const isOwner = agreement?.ownerPlayer?.id === account?.id;
  const isPlayer = agreement?.playerId === account?.id;
  const reservedForAnotherPlayer = !!agreement?.playerId && !isPlayer;
  const duration = agreement ? agreement?.duration / 60 / 60 / 24 : 0;

  const endRentalAgreement = async (
    // setLoading: (value: React.SetStateAction<boolean>) => void,
    callback?: () => void,
  ) => {
    const agreementId = agreement?.id;
    if (!agreementId || !furball) return;
    // setLoading(true);

    try {
      const { data } = await endAgreement({
        variables: { rentalAgreementId: agreementId },
      });

      if (!data?.endRentalAgreement.pendingCancellation) {
        removeFurballs([furball.tokenId]);
        !!callback && callback();
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Successfully deleted rental contract for ${furball.name}`,
      });

      return data?.endRentalAgreement.pendingCancellation;
    } catch (error: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message:
          error.message ?? "Opps! Couldn't make that happen, please retry.",
      });
    }
  };

  const timeRemaining = useCallback(() => {
    if (!agreement) {
      return '-';
    }

    const duration = agreement?.duration / 60 / 60 / 24;
    const { days, hours, mins, secs } = getTimeRemaining(
      agreement.beganAt,
      duration,
    );

    return !!days
      ? `${days} ${days === 1 ? 'day' : 'days'}`
      : !!hours
      ? `${hours} hrs`
      : !!mins
      ? `${mins} mins`
      : `${secs} secs`;
  }, [agreement]);

  const lastBattle = useCallback(() => {
    if (!agreement || !furball?.lastBattleAt) {
      return '-';
    }

    const { days, hours, mins, secs } = getTimeAgo(
      furball?.lastBattleAt as any,
      new Date(),
    );

    return !!days
      ? days === 1
        ? 'Yesterday'
        : `${days} days ago`
      : !!hours
      ? `${hours} hours ago`
      : !!mins
      ? `${mins} minutes ago`
      : `${secs} seconds ago`;
  }, [agreement]);

  // useEffect(() => {
  //   const rewardsItemQuantities: { [x: number]: IRewardObject } = {};
  //
  //   agreement?.rewards?.forEach((reward) => {
  //     reward.items.forEach((item) => {
  //       const exists = rewardsItemQuantities[item.itemId];
  //
  //       if (exists) {
  //         rewardsItemQuantities[item.itemId] = {
  //           ...exists,
  //           quantity: exists.quantity + item.stack,
  //         };
  //       } else {
  //         const def = getItemDef(item.itemId);
  //
  //         if (def) {
  //           rewardsItemQuantities[item.itemId] = {
  //             ...def,
  //             quantity: item.stack,
  //           };
  //         }
  //       }
  //     });
  //   });
  //
  //   setRewards(Object.values(rewardsItemQuantities));
  // }, [agreement?.rewards]);

  const playerLink = (
    <PlayerLink style={{ color: 'inherit' }} player={agreement?.renterPlayer} />
  );

  const ownerLink = (
    <PlayerLink style={{ color: 'inherit' }} player={agreement?.ownerPlayer} />
  );

  return {
    timeRemaining,
    endRentalAgreement,
    isOwner,
    playerLink,
    ownerLink,
    agreement,
    furball,
    duration,
    lastBattle,
    loading,
    // rewards,
    reservedForAnotherPlayer,
    pendingCancellation: agreement?.pendingCancellation,
    isActiveRental: !!furball?.activeRentalAgreement,
  };
};
