import React, { FunctionComponent, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { itemLibrary } from '../../../components/Inventory/ItemLibrary';
import _ from 'lodash';
import {
  ItemDefinitionFragment,
  RarityLevel,
} from '../../../components/schema';
import SvgCaret from '../../../assets/SvgCaret';
import { getRarityLevelName } from '../../Play';
import { getLootDisplayGroup, getLootDisplayName } from './LootHelpers';
import { cdnRoot } from '../../../theme';
import { getBossKeySvg } from '../../../components/Loot/LootItems';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0.5rem 0.5rem 2rem ',
  },

  filtersContainer: {
    margin: '0 -24px',

    [theme.breakpoints.down('sm')]: {
      margin: '0 -16px',
    },
  },

  accordion: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderLeft: 'none',
    borderRight: 'none',

    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },

  accordionSummary: {
    position: 'relative',

    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.grey[700]}`,
    },
  },

  expandIcon: {
    transform: 'rotate(90deg)',
  },

  accordionDetails: {
    backgroundColor: '#F5F5F5',
    maxHeight: '20rem',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  check: {
    width: '100%',
    margin: '0.3rem 0',
  },

  sortings: {
    marginTop: '2rem',

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      filter: 'grayScale(100%)',
      opacity: 0.5,
    },
  },

  iconContainer: {
    width: '2.8rem',
    height: '2.8rem',
    borderRadius: '100%',
    backgroundColor: '#D9D9D9',
    display: 'grid',
    placeItems: 'center',
    flexShrink: 0,
    position: 'relative',

    '& svg': {
      maxWidth: '1.5rem',
      maxHeight: '1.5rem',
      position: 'static !important',
    },
  },

  icon: {
    width: '1.5rem',
    height: '1.5rem',
  },
}));

interface ILootFilters {
  allowedItems: ItemDefinitionFragment[];
  setAllowedItems: (d: ItemDefinitionFragment[]) => void;
}

interface ILootFilterSelection {
  def?: ItemDefinitionFragment;
  selected: boolean;
}

export function useTradeableLoot() {
  return React.useMemo(() => {
    const tradeableLoot = itemLibrary.filter((i) => !i.untradeable);
    const lootGroups = _.uniq(
      tradeableLoot.map((i) => getLootDisplayGroup(i.itemGroup)),
    );

    const groupedLoot: {
      [key: string]: { def?: ItemDefinitionFragment; name: string }[];
    } = {};

    const defaultItemSelections: {
      [key: string]: {
        [key: string]: ILootFilterSelection;
      };
    } = {};

    const lootNames = _.flatten(
      lootGroups.map((g) => {
        groupedLoot[g] = _.uniq(
          tradeableLoot
            .filter((i) => getLootDisplayGroup(i.itemGroup) === g)
            .map((i) => ({
              name: getLootDisplayName(i),
              def: i,
            })),
        ).sort();

        defaultItemSelections[g] = {};

        groupedLoot[g].map((n) => {
          defaultItemSelections[g][n.name] = {
            def: n.def,
            selected: false,
          };
        });

        return groupedLoot[g];
      }),
    ).sort();

    const rarities = [
      RarityLevel.Elite,
      RarityLevel.Mythic,
      RarityLevel.Legendary,
    ];

    const defaultRaritySelections: {
      [key: string]: {
        selected: boolean;
      };
    } = {};

    const rarityNames = rarities.map((l) => {
      const n = getRarityLevelName(l);
      defaultRaritySelections[n] = {
        selected: false,
      };

      return n;
    });

    return {
      tradeableLoot,
      lootGroups,
      groupedLoot,
      rarityNames,
      lootNames,
      defaultItemSelections,
      defaultRaritySelections,
    };
  }, []);
}

export const LootFilters: FunctionComponent<ILootFilters> = (props) => {
  const classes = useStyles();
  const { allowedItems, setAllowedItems } = props;
  const {
    tradeableLoot,
    lootGroups,
    defaultItemSelections,
    defaultRaritySelections,
  } = useTradeableLoot();

  const [itemSelections, setItemSelections] = useState(defaultItemSelections);
  const [raritySelections, setRaritySelections] = useState(
    defaultRaritySelections,
  );
  const [first, setFirst] = useState(true);
  // const [items, setItems] = React.useState(tradeableLoot);

  React.useEffect(() => {
    const itemNames = _.flatten(
      Object.values(itemSelections).map((d) =>
        Object.keys(d).filter((i) => d[i].selected),
      ),
    );

    const rarityLevels = Object.keys(raritySelections).filter(
      (i) => raritySelections[i].selected,
    );

    const allowed = tradeableLoot.filter((i) => {
      if (itemNames.length > 0 && !itemNames.includes(getLootDisplayName(i)))
        return false;
      if (
        rarityLevels.length > 0 &&
        !rarityLevels.includes(getRarityLevelName(i.rarity))
      )
        return false;
      return true;
    });

    if (!first || allowedItems.length === 0) {
      setAllowedItems(allowed);
    }
    setFirst(false);
  }, [itemSelections, raritySelections]);

  function renderGroup(
    title: string,
    options: {
      [key: string]: ILootFilterSelection;
    },
    update: (v: { [key: string]: ILootFilterSelection }) => void,
  ) {
    return (
      <Accordion
        key={title}
        square
        disableGutters
        className={classes.accordion}>
        <AccordionSummary
          expandIcon={
            <SvgCaret
              width='0.9rem'
              height='0.9rem'
              className={classes.expandIcon}
            />
          }
          className={classes.accordionSummary}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          {Object.keys(options).map((o) => {
            const lootDef = options[o]?.def;
            const isKey = lootDef?.name.includes('Key');

            return (
              <FormControlLabel
                key={o}
                className={classes.check}
                control={
                  <Checkbox
                    color={'secondary'}
                    checked={options[o].selected}
                    onChange={(e) =>
                      update({
                        ...options,
                        [o]: {
                          def: options[o].def,
                          selected: !options[o].selected,
                        },
                      })
                    }
                  />
                }
                label={
                  <Grid
                    container
                    wrap='nowrap'
                    gap='0.6rem'
                    alignItems='center'>
                    {lootDef?.icon && (
                      <div className={classes.iconContainer}>
                        {isKey ? (
                          getBossKeySvg(lootDef.id as any)
                        ) : (
                          <img
                            className={classes.icon}
                            src={`${cdnRoot}/images/icons/boss_loot/${lootDef.icon}.png`}
                            alt={lootDef.name}
                          />
                        )}
                      </div>
                    )}
                    <div>
                      <Grid
                        container
                        direction='column'
                        gap='0.1rem'
                        justifyItems='center'>
                        <Typography
                          variant='body1'
                          fontSize='0.9rem !important'
                          fontWeight={700}>
                          {lootDef?.name ?? o}
                        </Typography>
                      </Grid>
                    </div>
                  </Grid>
                }
              />
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Container className={clsx(classes.root)}>
      <Typography variant='h4'>Loot Market</Typography>
      <Typography variant={'body1'}>Buy/sell loot for wFUR.</Typography>

      <Grid
        container
        direction='column'
        gap='0.5rem'
        className={classes.sortings}>
        <Typography variant='h4' fontSize='1.2rem'>
          Filters
        </Typography>

        <div className={classes.filtersContainer}>
          {lootGroups.map((g) =>
            renderGroup(g, itemSelections[g], (v) =>
              setItemSelections({
                ...itemSelections,
                [g]: v,
              }),
            ),
          )}
          {renderGroup('Rarity', raritySelections, setRaritySelections)}
        </div>
      </Grid>
    </Container>
  );
};

export default LootFilters;
