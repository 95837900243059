import React, { FunctionComponent, useState } from 'react';
import {
  Avatar,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import OkDialog from '../OkDialog';
import {
  EquipmentItemFragment,
  ItemGroup,
  useMoveItemMutation,
} from '../schema';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import { cdnRoot } from '../../theme';
import { IFurball, IFurballPlayer } from '../../wallet/WalletTypes';
import FurballHead from '../Furballs/FurballHead';
import { getKeySvgByItemDefIcon } from './InventorySlot';
import { shadeColor } from '../../themev2';
import { getFurballBackgroundColorCss } from '../Furballs/Backgrounds/FurballBackground';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletSlice from '../../wallet/WalletSlice';
import LootListingDialog from '../../pages/Marketplace/Loot/LootListingDialog';
import useCommonStyles from '../useCommonStyles';
import clsx from 'clsx';
import FurballRenderer from '../Furballs/FurballRenderer';
import PlayerLink from '../Players/PlayerLink';
import FurballLink from '../Players/FurballLink';
import GamePlayerAvatar from '../BossFights/LiveFightFeed/GamePlayerAvatar';
import EquipmentStats from '../Town/Upgrade/EquipmentStats';
import EquipmentTimeRemaining from '../Town/Upgrade/EquipmentTimeRemaining';
import InputItemQuantity from '../../pages/Marketplace/Loot/InputItemQuantity';
import { IOwnedItem } from './InventoryHelpers';
import { usePlayersReadyFurballs } from '../../wallet';
import { getInventoryQuantities } from '../Furballs';
import { useHistory } from 'react-router';
import { slugify } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '100px',
    minWidth: '520px',
    maxWidth: '520px',
    maxHeight: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },

  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  dialog: {
    position: 'relative',
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    height: '45px',

    '& h3': {
      fontSize: '2rem !important',
      marginTop: '2px !important',
      // marginLeft: '1rem !important',
    },

    // '& svg': {
    //   height: '40px',
    //   width: '40px',
    // },
  },

  titleAvatar: {
    position: 'relative',
  },

  titleArrow: {
    margin: '0 1rem',
  },

  actionBtn: {
    margin: '1rem',

    '& button': {
      minWidth: '150px !important',
      width: '150px !important',
    },
  },

  selectFb: {
    textAlign: 'center',
    margin: '0.415rem !important',
    maxWidth: '160px !important',

    '& button': {
      minWidth: '100% !important',
      width: '100% !important',
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '6px !important',
      paddingLeft: '15px !important',
    },

    '& h6': {
      fontSize: '0.8rem !important',
    },
  },
  fbIcon: {
    borderRadius: '50%',
  },

  lootListing: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardFooter: {
    backgroundColor: 'black',
    textAlign: 'center',
    width: '100%',
    paddingBottom: '8px !important',
  },
  cardActions: {
    display: 'block',
  },
  quantityFilterCont: {
    marginTop: '1rem',
    marginBottom: '1.5rem',
    width: '100%',
  },

  itemIcon: {
    width: '7.5rem',
    height: '7.5rem',
    display: 'grid',
    placeItems: 'center',

    '& > div': {
      padding: '1rem',
      width: '100% !important',
      height: '100% !important',
    },

    '& svg': {
      padding: '1rem',
      width: '90% !important',
      height: '90% !important',
    },
  },

  lootStats: {
    padding: '1.1rem',
    margin: '0 1rem',
    borderRadius: 25,
    border: `1px solid ${theme.palette.grey[300]}`,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },

  spacer: {
    height: '1px',
    width: '100%',
    borderRadius: '30px',
    margin: '1.5rem 0',
    backgroundColor: theme.palette.grey[300],
  },

  furballLabel: {
    display: 'grid',
    padding: '0.3rem 0.6rem',
    placeItems: 'center',
    borderRadius: '30px',
    backgroundColor: theme.palette.grey[400],
  },

  statTextLabel: {
    color: theme.palette.grey[700],

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem !important',
    },
  },

  statText: {
    textTransform: 'capitalize',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem !important',
    },
  },

  heldBy: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0.5rem',
    },
  },
}));

export interface IItemBase {
  id: string;
  itemId: number;
}

interface IInventoryItemDialog {
  open: boolean;
  close: () => void;
  selectedItem: IOwnedItem | null;
}

export const InventoryItemDialog: FunctionComponent<IInventoryItemDialog> = (
  props,
) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const dispatch = useAppDispatch();
  const furballs = usePlayersReadyFurballs();
  const history = useHistory();

  const { open, close, selectedItem } = props;
  const { addAlert } = React.useContext(AlertContext);
  const heldByFurball = selectedItem?.furball;

  const player = useWalletSelector(
    ({ contents }) => contents as IFurballPlayer,
  );

  const equipmentItem =
    selectedItem?.item?.__typename === 'EquipmentItem'
      ? (selectedItem.item as EquipmentItemFragment)
      : undefined;

  const [quantity, setQuantity] = useState<number>(1);
  const [sendTo, setSendTo] = useState<boolean>(false);
  const [listItem, setListItem] = useState<boolean>(false);
  const [moveItem, { loading }] = useMoveItemMutation();

  async function moveItemToFb(tokenId: string) {
    if (!selectedItem || !selectedItem.item) return;

    try {
      const { data } = await moveItem({
        variables: {
          itemId: selectedItem.item.id,
          quantity: quantity,
          toFurballId: tokenId,
        },
      });

      if (!data || !data.moveItem) return;
      const { fromFurball, toFurball, player } = data.moveItem;

      dispatch(WalletSlice.actions.setInventory(player.inventory));

      const updatedFbs: { [key: string]: IFurball } = {};
      if (fromFurball)
        updatedFbs[fromFurball.tokenId] = {
          ...fromFurball,
          inventory: getInventoryQuantities(fromFurball.inventory),
        };
      if (toFurball)
        updatedFbs[toFurball.tokenId] = {
          ...toFurball,
          inventory: getInventoryQuantities(toFurball.inventory),
        };

      dispatch(WalletSlice.actions.setFurballs(updatedFbs));
      resetAndClose();
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Item was moved',
      });
    } catch (e: any) {
      console.error('error', e);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to move item',
      });
    }
  }

  function onClose() {
    if (listItem) return setListItem(false);
    if (sendTo) return setSendTo(false);
    setQuantity(1);
    return close();
  }

  function resetAndClose() {
    setSendTo(false);
    setListItem(false);
    setQuantity(1);
    close();
  }

  function isTradableItem() {
    if (!selectedItem || !selectedItem.item) return false;

    const itemDef = getItemDef(selectedItem.item.itemId);
    if (!itemDef) return false;

    return !itemDef.untradeable && itemDef.allowInFurballInventory;
  }

  function getItemTitle() {
    if (!selectedItem || !selectedItem.item) return null;

    const { itemId, stack } = selectedItem.item;
    const itemDef = getItemDef(itemId);

    if (!itemDef) return null;

    const { rarity, name, icon } = itemDef;
    const isKey = name.includes('Key');

    const rarityTxt =
      name.includes('Dust') || isKey ? '' : `${rarity.toLowerCase()} `;

    const title = listItem
      ? 'List for Sale'
      : sendTo
      ? 'Send to furball'
      : `${rarityTxt}${name}${stack === 1 ? '' : 's'}`;

    return (
      <div className={classes.title}>
        <Typography variant={'h3'}>{title}</Typography>
      </div>
    );
  }

  function renderFurballs() {
    if (!selectedItem) return;

    return furballs
      .filter(
        ({ tokenId }) =>
          !(!!selectedItem.furball && tokenId === selectedItem.furball.tokenId),
      )
      .map((furball) => {
        const { tokenId, backgroundColor, number } = furball;

        return (
          <Grid
            item
            xs={4}
            key={`send-to-fb-${tokenId}`}
            className={classes.selectFb}>
            <Button
              variant={'contained'}
              onClick={() => moveItemToFb(tokenId)}
              style={{
                backgroundColor: `#${backgroundColor}`,
              }}>
              <div
                className={classes.fbIcon}
                style={{
                  backgroundColor: shadeColor(
                    getFurballBackgroundColorCss(furball),
                    -40,
                  ),
                }}>
                <FurballHead tokenId={tokenId} size={32} borderRadius={100} />
              </div>
              <Typography variant={'h6'}>Furball #{number}</Typography>
            </Button>
          </Grid>
        );
      });
  }

  function getItemGroupDescription(group: ItemGroup): string {
    switch (group) {
      case ItemGroup.Special:
        return 'Boss Keys allow a Furball to fight a Boss. They can be sent between Furballs.';
        break;
      case ItemGroup.Consumable:
        return 'Elixirs are crafted items that can be consumed during battle..';
        break;
      case ItemGroup.Material:
        return 'Crafting materials are used for Elixirs, as well as Minting more Furballs.';
        break;
      case ItemGroup.Material:
        return 'Crafting materials are used for Elixirs, as well as Minting more Furballs.';
        break;
    }

    return equipmentItem?.equippedById
      ? 'This item is equipped by the Furball!'
      : 'Equipment items grant special powers when equipped by a Furball (via the Upgrade menu).';
  }

  function renderContent() {
    if (!selectedItem?.item) return null;
    const itemDef = getItemDef(selectedItem.item.itemId);
    if (!itemDef) return null;

    const { name, icon, rarity, realmAffiliation, itemGroup } = itemDef;
    const isKey = itemDef?.name.includes('Key');

    if (loading) {
      return <CircularProgress color={'secondary'} />;
    }

    if (sendTo) {
      return (
        <>
          <div className={classes.quantityFilterCont}>
            <InputItemQuantity
              quantity={quantity}
              setQuantity={setQuantity}
              selectedItem={selectedItem}
            />
          </div>
          <Grid
            container
            style={{
              justifyContent: 'center',
            }}>
            {renderFurballs()}
          </Grid>
        </>
      );
    }

    if (listItem) {
      return (
        <div className={classes.lootListing}>
          <LootListingDialog
            open={false}
            close={close}
            noDialog={true}
            itemToList={selectedItem}
          />
        </div>
      );
    }

    return (
      <>
        <Grid container p='0 1rem 2rem'>
          <Grid container justifyContent='center' alignItems='center'>
            <div className={classes.itemIcon}>
              {isKey ? (
                getKeySvgByItemDefIcon(icon)
              ) : (
                <Avatar src={`${cdnRoot}/images/icons/boss_loot/${icon}.png`} />
              )}
            </div>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Typography variant='h6' fontSize='1.4rem' lineHeight='100%'>
              {itemDef.name}{' '}
              {selectedItem.item.stack > 1 && `(x${selectedItem.item.stack})`}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowGap='0.8rem' className={classes.lootStats}>
          <Grid container justifyContent='space-between'>
            <Typography className={classes.statTextLabel} fontWeight={600}>
              Rarity:
            </Typography>
            <Typography className={classes.statText}>
              {name.includes('Dust') || isKey
                ? 'N/A'
                : `${rarity.toLowerCase()} `}
            </Typography>
          </Grid>

          <Grid container justifyContent='space-between'>
            <Typography className={classes.statTextLabel} fontWeight={600}>
              Group:
            </Typography>
            <Typography className={classes.statText}>
              {itemGroup.toLowerCase().replaceAll('_', ' ')}
            </Typography>
          </Grid>

          {equipmentItem && (
            <Grid container justifyContent='space-between'>
              <Typography className={classes.statTextLabel} fontWeight={600}>
                Breaks After:
              </Typography>
              <Typography className={classes.statText}>
                <EquipmentTimeRemaining equipment={equipmentItem} textOnly />
              </Typography>
            </Grid>
          )}

          <Grid container justifyContent='space-between'>
            <Typography className={classes.statTextLabel} fontWeight={600}>
              Realm Affiliation:
            </Typography>
            <Typography className={classes.statText}>
              {realmAffiliation.toLowerCase().replaceAll('_', ' ')}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='center' p='3rem 0'>
          <Grid
            item
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            xs={4}
            lg={4}
            className={classes.heldBy}>
            <div style={{ position: 'relative' }}>
              {heldByFurball && (
                <FurballRenderer tokenId={heldByFurball.tokenId} size='6rem' />
              )}
              {!heldByFurball && (
                <GamePlayerAvatar src={player.avatar} size='6rem' />
              )}
            </div>

            <div className={classes.furballLabel}>
              <Typography
                fontSize='0.7rem'
                textAlign='center'
                variant={'subtitle2'}>
                {heldByFurball ? 'Held' : 'Owned'} by {heldBy}
              </Typography>
            </div>
          </Grid>

          <Grid
            item
            container
            direction='column'
            justifyContent='flex-end'
            rowGap='0.5rem'
            xs={8}
            lg={6}>
            {equipmentItem && (
              <div>
                <EquipmentStats equipment={equipmentItem} />
              </div>
            )}

            <Typography variant={'subtitle2'}>
              {selectedItem?.item &&
                getItemGroupDescription(selectedItem.item.itemGroup)}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  function renderActions() {
    if (!selectedItem?.item || listItem || sendTo) return null;

    const itemDef = getItemDef(selectedItem.item.itemId);
    const isKey = itemDef?.name.includes('Key');

    if (heldByFurball && !isKey && !isTradableItem()) {
      return null;
    }

    return (
      <div className={classes.cardActions}>
        <ButtonGroup className={classes.buttons}>
          {(!heldByFurball || isKey) && (
            <Button
              variant={'contained'}
              color={'secondary'}
              className={common.secondaryButton}
              onClick={() => setListItem(true)}>
              List for Sale
            </Button>
          )}

          {itemDef?.upgradeToItemId && !heldByFurball && (
            <Button
              variant={'contained'}
              onClick={() =>
                history.push(
                  `/shop/upgrade/${slugify(itemDef.name)}?id=${
                    selectedItem.id
                  }`,
                )
              }>
              Upgrade
            </Button>
          )}

          {isTradableItem() && (
            <Button variant={'contained'} onClick={() => setSendTo(true)}>
              Send to Furball
            </Button>
          )}
        </ButtonGroup>
      </div>
    );
  }

  const heldBy: React.ReactNode = heldByFurball ? (
    <FurballLink furball={heldByFurball} />
  ) : (
    <PlayerLink player={player} />
  );

  return (
    <OkDialog
      open={open}
      onClose={onClose}
      backIcon={sendTo || listItem}
      contentClassName={classes.dialog}
      disabled={loading}
      onBackdropClick={resetAndClose}
      title={getItemTitle()}
      actions={renderActions()}
      actionsClassName={clsx(classes.cardFooter)}>
      <Grid container className={classes.root}>
        {renderContent()}
      </Grid>
    </OkDialog>
  );
};

export default InventoryItemDialog;
