import * as React from 'react';

function SvgLootFrisbee(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <path
        fill='#FF7E83'
        d='M170.8 76.9c0-26.3-31.7-50.3-70.8-50.3s-70.8 24-70.8 50.3 31.7 44.3 70.8 44.3 70.8-18 70.8-44.3z'
      />
      <path
        fill='#FFA2A6'
        d='M169.1 68.5c0-24.5-30-41-69.1-41S30.9 44 30.9 68.5s30 43.6 69.1 43.6 69.1-19.1 69.1-43.6z'
      />
      <path
        fill='#FFC7CF'
        d='M151.8 64.3c0-14.2-20.5-25.8-46.8-27.1-1.6-.1-3.3-.1-5-.1-21.1 0-39.2 6.6-47.3 16.1-2.9 3.4-4.5 7.2-4.5 11.1 0 15.1 23.2 27.3 51.8 27.3 6.1 0 11.9-.5 17.3-1.6 9.5-1.8 17.6-4.9 23.7-9 6.7-4.6 10.8-10.4 10.8-16.7z'
      />
      <path
        fill='#FFC7CF'
        d='M141 81c6.8-4.6 10.8-10.4 10.8-16.7 0-14.2-20.5-25.8-46.8-27.1-1.6-.1-3.3-.1-5-.1'
      />
      <path d='M139.6 78.9c2.8-1.8 5.3-3.9 7.2-6.4 1.9-2.5 3.1-5.5 3.1-8.5 0-3.1-1.1-6.1-3-8.8-1.9-2.7-4.4-5-7.2-6.9-5.6-3.9-12.2-6.5-18.9-8.3-6.7-1.8-13.7-2.7-20.8-2.9 7-.2 14.1.3 21 1.7s13.8 3.7 20 7.4c3.1 1.9 6 4.2 8.4 7.1 2.3 2.9 4.1 6.6 4.3 10.6.3 4-1.1 8-3.3 11.1-2.2 3.2-5 5.8-8 8-1.1.8-2.7.6-3.5-.5s-.6-2.7.5-3.5c.1 0 .1 0 .2-.1z' />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M170.8 72.8c0-24.5-31.7-46.2-70.8-46.2S29.2 48.2 29.2 72.8v4.1c0 26.3 31.7 43.4 70.8 43.4s70.8-17.1 70.8-43.4v-4.1z'
      />
      <path
        fill='#FF7E83'
        d='M102.3 49.7l5.4 6.7c.2.3.6.5 1 .5l11.1 1c1.3.1 1.7 1.5.6 2.1l-7.3 4.4c-.5.3-.7.8-.6 1.2l1.8 6.4c.3.9-.8 1.7-1.8 1.4l-10.9-3.5c-.3-.1-.7-.1-1 0l-10.9 3.5c-1 .3-2.1-.5-1.8-1.4l1.8-6.4c.1-.5-.1-1-.6-1.2L81.9 60c-1-.6-.6-2 .6-2.1l11.1-1c.4 0 .8-.2 1-.5l5.4-6.7c.5-.7 1.7-.7 2.3 0z'
      />
    </svg>
  );
}

export default SvgLootFrisbee;
