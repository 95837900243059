import {
  AppBar,
  Button,
  Grid,
  IconButton,
  // Popover,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import SvgIconDiscord from './assets/SvgIconDiscord';
import SvgIconTwitter from './assets/SvgIconTwitter';
import SvgLogo from './assets/SvgLogo';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { cdnRoot, furColor, mint } from './theme';
import { Link } from 'react-router-dom';
import SvgIconOpensea from './assets/SvgIconOpenSea';
import { OutboundLink } from './components/Navigation';
import SvgMobileMenuX from './assets/SvgMobileMenuX';
import SvgMobileMenu from './assets/SvgMobileMenu';
import { Box } from '@mui/system';
import MobileMenu from './MobileMenu';
import themev2, {
  black,
  darkBlue,
  gray,
  shadeColor,
  white,
  yellow,
} from './themev2';
import NotificationIcon from './components/Notifications/NotificationIcon';
import PopupState, { bindPopover, bindHover } from 'material-ui-popup-state';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import { useHeaderMenuOptions } from './headerMenu';
import { useAuthContext } from './components/Auth/AuthContext';
import theme from './themev2';
import WalletButton from './wallet/WalletButton';
import { getAvatarUrl } from './components/BossFights/LiveFightFeed/GamePlayerAvatar';
import AccountHeaderStatus from './components/Auth/AccountHeaderStatus';
import { useWalletSelector } from './hooks';
import { useClientHardware } from './pages/App/clientHardware';
import useCommonStyles, { useAppBarHeight } from './components/useCommonStyles';
import { usePlayGamePath } from './wallet';
import { useFurComponent } from './utils';
import { playGameVerb } from './pages/Furverse/Lobby/BossGamePrepare';
import { discordChannelInviteUrl } from './pages/HomeV2/StickyDiscordInvite';
import { useFurverseContext } from './pages/Furverse/Furverse';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    padding: '0px',
    justifyContent: 'space-between',
  },

  logo: {
    maxHeight: '36px',
    marginTop: '6px',
    marginRight: '24px',
    width: '120px',
  },

  fur: {
    color: furColor.dark,
  },

  scrolled: {
    display: 'block',
  },

  socialIcon: {
    width: '24px',
    marginRight: '12px',
    marginTop: '4px',
  },

  submenuLink: {
    width: '100%',
    textDecoration: 'none !important',
    color: darkBlue,
  },

  link: {
    fontSize: '0.9rem',
    color: darkBlue,
    margin: '0 !important',
    paddingRight: '14px !important',
    paddingLeft: '14px !important',
    cursor: 'pointer',
  },

  linkCurrent: {
    color: theme.palette.grey.A700,
  },

  [theme.breakpoints.down('lg')]: {
    socialIcon: {
      height: '44px',
      width: '44px',
    },
    logo: {
      width: '140px',
      maxHeight: '48px',
      height: '48px',
    },
    link: {
      marginRight: '24px !important',
      lineHeight: '1.4rem !important',
    },
  },

  menuIcon: {
    width: '32px',
  },

  menuButton: {
    display: 'none',
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(1),
      display: 'block',
    },
  },

  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    textDecoration: 'none',
    color: `${gray} !important`,
    filter: 'grayScale(100%)',
    opacity: 0.6,
  },

  linkCont: {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
  },

  hideOnMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },

  menuPopover: {
    borderRadius: '0 0 10px 10px !important',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: '0rem 1.2rem 3.2rem rgba(0, 0, 0, 10%) !important',
  },

  menuItem: {
    cursor: 'pointer',
    borderRadius: '10px',
    transition: 'background-color 0.2s ease-out',

    '&:hover': {
      backgroundColor: shadeColor(theme.palette.secondary.main, 80),
    },
  },

  signInButtonContainer: {
    padding: '0 0 0 24px',
    display: 'grid',
    placeItems: 'center',
    // borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },

  playButton: {
    // padding: '20px 10px !important',
    backgroundColor: `${yellow} !important`,

    '&:hover': {
      backgroundColor: `${mint.light} !important`,
      color: 'black !important',
    },
  },
}));

interface OwnProps {}

type Props = OwnProps;

const HeaderBar: FunctionComponent<Props> = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const { log } = useFurComponent(HeaderBar);
  const headerMenu = useHeaderMenuOptions();
  const classes = useStyles();
  const location = useLocation();
  const isDesktop = useMediaQuery(themev2.breakpoints.up('lg'));
  const walletContents = useWalletSelector((s) => s.contents);
  const clientHardware = useClientHardware();
  const common = useCommonStyles();
  const { isSignedIn } = useAuthContext();
  const usableTokenIds = useWalletSelector((s) => s.contents?.tokenIds ?? []);
  const hasFurballs = usableTokenIds.length > 0;
  const history = useHistory();
  const appBarHeight = useAppBarHeight();
  const playGamePath = usePlayGamePath();
  const { gameReady } = useFurverseContext();

  const headerCss: React.CSSProperties = {
    backgroundColor: white,
    color: black,
    margin: 0,
    padding: 0,
    height: appBarHeight,
  };

  const toolbarCss: React.CSSProperties = {
    paddingTop: clientHardware.cornerPadding + 'pt',
    height: appBarHeight,
  };

  // edge case if screen size changes from
  // menu breakpoint while menu is open
  useEffect(() => {
    if (isDesktop && mobileMenuOpen) {
      setMobileMenuOpen(false);
    }
  }, [isDesktop]);

  function renderLink(
    path: string,
    item: React.ReactNode,
    className: string,
  ): React.ReactNode {
    const ext = path.toLowerCase().startsWith('http');
    if (ext) {
      return (
        <OutboundLink to={path} className={className}>
          {item}
        </OutboundLink>
      );
    }

    return (
      <Link to={path} className={className}>
        {item}
      </Link>
    );
  }

  function headerText(
    text: React.ReactNode,
    path: string,
    icon: string | null | undefined,
    classes: ClassNameMap,
    isDesktop?: boolean,
  ): React.ReactNode {
    const external = path.toLowerCase().startsWith('http');
    const basePath = location.pathname
      .split('/')
      .filter((p) => p.length > 0)
      .join('/');
    const pathName = path
      .split('/')
      .filter((p) => p.length > 0)
      .join('/');
    const samePage = !external && basePath === pathName;
    const imgSrc =
      icon === ''
        ? getAvatarUrl(walletContents?.avatar)
        : icon?.startsWith('http')
        ? icon
        : `${cdnRoot}/svgs/main_nav_icons_${icon ?? text}.svg`;

    const item = (
      <Grid
        container
        padding='0.4rem 0.8rem'
        gap='0.8rem'
        alignItems='center'
        className={classes.menuItem}>
        <img width='17px' height='17px' src={imgSrc} alt={text as string} />
        <Typography
          variant={isDesktop ? 'h6' : 'h4'}
          fontSize={'1rem'}
          fontWeight='500'
          color={theme.palette.grey[800]}>
          {text}
        </Typography>
      </Grid>
    );

    return renderLink(
      path,
      item,
      clsx(classes.submenuLink, {
        [classes.disabled]: samePage,
      }),
    );
  }

  function renderSocials() {
    return (
      <div key={'socials'} className={classes.hideOnMobile}>
        <OutboundLink
          to={'https://opensea.io/collection/furballs-com-official'}>
          <SvgIconOpensea className={classes.socialIcon} />
        </OutboundLink>
        <OutboundLink to={'https://twitter.com/furballsNFT'}>
          <SvgIconTwitter className={classes.socialIcon} />
        </OutboundLink>
        <OutboundLink to={discordChannelInviteUrl}>
          <SvgIconDiscord className={classes.socialIcon} />
        </OutboundLink>
      </div>
    );
  }

  function renderNotifications() {
    // render mobile notifications
    if (!isDesktop || !isSignedIn) {
      return null;
    }

    return (
      <div key={'notifications'} className={classes.hideOnMobile}>
        <NotificationIcon />
      </div>
    );
  }

  function renderSignInButton() {
    if (!isDesktop || isSignedIn) {
      return null;
    }

    return (
      <div
        key={'signin'}
        className={clsx(classes.signInButtonContainer, classes.hideOnMobile)}>
        <WalletButton analyticsPathComponent={'header'} />
      </div>
    );
  }

  function setIcon() {
    if (mobileMenuOpen) {
      return <SvgMobileMenuX className={classes.menuIcon} />;
    }

    return <SvgMobileMenu className={classes.menuIcon} />;
  }

  function renderLinks() {
    return (
      <Grid container alignItems='center' gap='0.2rem'>
        {headerMenu.map((item, index) => {
          const renderedIcon = item.icon && item.icon.length > 0 && (
            <img
              src={item.icon}
              style={{
                width: 32,
                position: 'relative',
                top: 10,
                marginRight: 10,
              }}
            />
          );
          const renderedTitle = (
            <Typography
              fontWeight={theme.typography.fontWeightBold}
              variant={isDesktop ? 'h6' : 'h4'}
              className={classes.link}
              style={{ paddingBottom: renderedIcon ? 10 : 0 }}>
              {renderedIcon}
              {item.title}
            </Typography>
          );
          const renderedOut = (
            <React.Fragment key={index}>
              {item.url
                ? renderLink(item.url, renderedTitle, classes.link)
                : renderedTitle}
            </React.Fragment>
          );

          if (item.url && item.subMenu.length === 0) {
            return renderedOut;
          }

          return (
            <PopupState key={index} variant='popover'>
              {(state) => (
                <Grid
                  item
                  container
                  alignItems='center'
                  height='100%'
                  width='fit-content'
                  {...bindHover(state)}>
                  {renderedOut}{' '}
                  <HoverPopover
                    {...bindPopover(state)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    classes={{
                      paper: classes.menuPopover,
                    }}>
                    <Grid
                      container
                      width='14rem'
                      rowGap='0.2rem'
                      padding='1rem 0.6rem'
                      justifyContent='stretch'>
                      {item.subMenu.map((menuItem, index) => (
                        <React.Fragment key={index}>
                          {headerText(
                            menuItem.title,
                            menuItem.url,
                            menuItem.icon,
                            classes,
                            true,
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>
                  </HoverPopover>
                </Grid>
              )}
            </PopupState>
          );
        })}
      </Grid>
    );
  }

  const path = history.location.pathname;
  const isPlaying =
    path.startsWith('/boss') ||
    path.startsWith('/dungeon') ||
    path.startsWith('/monsters') ||
    path.startsWith('/play') ||
    path.startsWith('/home') ||
    path === '/';

  return (
    <AppBar elevation={1} position='fixed' style={headerCss}>
      <Toolbar className={classes.toolbar} style={toolbarCss}>
        <Link to={gameReady && isSignedIn ? '/furverse' : '/home'}>
          <SvgLogo className={classes.logo} />
        </Link>
        <Box className={clsx(classes.linkCont, classes.hideOnMobile)}>
          {renderLinks()}
        </Box>
        <AccountHeaderStatus />
        {isSignedIn && !isPlaying && (
          <div>
            <Button
              variant={'contained'}
              className={clsx(common.actionButton, classes.playButton)}
              onClick={() => history.push(playGamePath)}>
              {playGameVerb}
            </Button>
          </div>
        )}
        {/*{renderSocials()}*/}
        {renderSignInButton()}
        {renderNotifications()}
        <div className={classes.menuButton}>
          <IconButton
            sx={{
              height: '45px',
              width: '45px',
              '&:hover': {
                backgroundColor: white,
              },
            }}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            {setIcon()}
          </IconButton>
        </div>
      </Toolbar>
      <MobileMenu
        isOpen={mobileMenuOpen}
        isSignedIn={isSignedIn}
        headerText={headerText}
        close={() => setMobileMenuOpen(false)}
      />
    </AppBar>
  );
};

export default HeaderBar;
