import * as React from 'react';

function SvgKeySebastien(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 128 128'
      enableBackground='new 0 0 128 128'
      xmlSpace='preserve'
      {...props}>
      <g>
        <g>
          <linearGradient
            id='SVGID_1_'
            gradientUnits='userSpaceOnUse'
            x1='1.9198'
            y1='42.7634'
            x2='82.2982'
            y2='42.7634'
            gradientTransform='matrix(0.7071 0.7071 -0.7071 0.7071 54.9248 12.5723)'>
            <stop offset='0' style={{ stopColor: '#FDB3B1' }} />
            <stop offset='0.1395' style={{ stopColor: '#FDD09F' }} />
            <stop offset='0.2732' style={{ stopColor: '#FFECA7' }} />
            <stop offset='0.4335' style={{ stopColor: '#C0F4BF' }} />
            <stop offset='0.6229' style={{ stopColor: '#B2F7F9' }} />
            <stop offset='0.821' style={{ stopColor: '#ADD5FF' }} />
            <stop offset='0.8738' style={{ stopColor: '#B0C5FF' }} />
            <stop offset='0.9416' style={{ stopColor: '#B2B7FF' }} />
            <stop offset='1' style={{ stopColor: '#B3B2FF' }} />
          </linearGradient>
          <path
            fill='url(#SVGID_1_)'
            d='M6,124.5c-0.9,0-1.8-0.4-2.5-1c-0.7-0.7-1.1-1.7-1-2.7l3.1-42.9c0.1-1.7,1.5-3.1,3.2-3.2l24.6-1.8
			l10.9-10.9c1.9-1.9,4.4-2.9,7-2.9c1,0,1.9,0.1,2.8,0.4L66.8,47l-3-3c-0.4,0.2-1.3,0.7-2.5,1.9l-0.6,0.6c-0.7,0.7-1.5,1-2.5,1
			c-0.9,0-1.8-0.4-2.5-1L42.2,33c-1.4-1.4-1.4-3.6,0-4.9l12.6-12.6c0.7-0.7,1.5-1,2.5-1s1.8,0.4,2.5,1l1.7,1.7L74,4.5
			c0.7-0.7,1.6-1,2.5-1s1.8,0.3,2.5,1l8.7,8.7c1.3,1.3,1.4,3.5,0.1,4.9l-0.6,0.6c-0.9,1-1.7,2.5-1.6,3c0,0,0,0,0.1,0.1l3.7,3.7
			c0.3-0.1,0.6-0.1,0.9-0.1c0.4,0,0.9,0.1,1.3,0.2l5.1-5.1c1-1,2.3-1.6,3.8-1.6s2.8,0.6,3.8,1.6l2.3,2.3c2.1,2.1,2.1,5.5,0,7.5
			l-5.1,5.1c0.2,0.7,0.2,1.5,0.1,2.2l3.7,3.7c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.8,0,2.2-0.8,3-1.6l0.6-0.6c0.7-0.7,1.6-1,2.4-1
			c0.9,0,1.8,0.3,2.5,1l8.7,8.7c0.7,0.7,1,1.5,1,2.5s-0.4,1.8-1,2.5l-12.6,12.6l1.7,1.7c0.7,0.7,1,1.5,1,2.5s-0.4,1.8-1,2.5L99,84.9
			c-0.7,0.7-1.6,1-2.5,1s-1.8-0.3-2.5-1L80.6,71.4c-1.4-1.4-1.4-3.6,0-4.9l0.6-0.6c1.2-1.2,1.7-2.1,1.9-2.5l-3-3L67.5,72.9
			c1,3.4,0.1,7.2-2.5,9.8L54.1,93.6l-1.8,24.6c-0.1,1.7-1.5,3.1-3.2,3.2l-42.9,3.1C6.2,124.5,6.1,124.5,6,124.5z'
          />
          <path
            d='M76.5,7l8.7,8.7l-0.6,0.6c0,0-4.6,4.8-1.4,8l5.6,5.6l0.5-0.5c0.2-0.2,0.5-0.4,0.8-0.4c0.1,0,0.2,0,0.3,0
			c0.4,0,0.8,0.1,1.1,0.4l0.7,0.7l7.1-7.1c0.4-0.4,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5l2.3,2.3c0.7,0.7,0.7,1.9,0,2.6L96.9,35
			l0.7,0.7c0.4,0.4,0.5,0.9,0.4,1.3c-0.1,0.3-0.2,0.6-0.4,0.8l-0.5,0.5l5.6,5.6c0.8,0.8,1.7,1.1,2.6,1.1c2.6,0,5.3-2.5,5.3-2.5
			l0.6-0.6l8.7,8.7l-13.6,13.6l-4.3-4.3l0.5-0.5c0.2-0.4,0.9-2.2-0.6-3.7c-0.2-0.2-0.4-0.3-0.6-0.5c-0.5-0.3-1.1-0.5-1.7-0.5
			c-0.8,0-1.6,0.3-2.3,0.9c-1.3,1.3-1.3,3.3,0,4.5c0.1,0.1,0.2,0.1,0.2,0.2c0.2,0.1,0.4,0.3,0.6,0.4c0.5,0.2,1,0.4,1.5,0.4
			c0.1,0,0.1,0,0.2,0l0.4,0l8.8,8.8L96.5,82.4L83,69l0.6-0.6c2.8-2.8,3.2-4.6,3.1-5.6c-0.1-1.1-0.9-1.6-0.9-1.6l-5.7-5.7L63.3,72.2
			c1.7,2.5,1.4,5.9-0.8,8.1L51.4,91.4c-0.2,0.2-0.5,0.4-0.7,0.6l-1.9,26L6,121l3.1-42.9L29,76.8l6.1-0.4c0.2-0.2,0.4-0.5,0.6-0.7
			l11.1-11.1c1.3-1.3,2.9-1.9,4.5-1.9c1.3,0,2.5,0.4,3.6,1.1L71.7,47L66,41.2c0,0-0.5-0.8-1.6-0.9c-0.1,0-0.2,0-0.3,0
			c-1.1,0-2.8,0.6-5.3,3.1L58.1,44L44.6,30.5l12.6-12.6l8.8,8.8l0,0.4c0,0.6,0.1,1.2,0.4,1.7c0.1,0.2,0.2,0.4,0.4,0.6
			c0.1,0.1,0.1,0.2,0.2,0.2c0.6,0.6,1.4,0.9,2.3,0.9s1.6-0.3,2.3-0.9c1.1-1.1,1.2-2.7,0.5-4c-0.1-0.2-0.3-0.4-0.5-0.6
			c-0.7-0.7-1.5-1-2.2-1c-0.7,0-1.3,0.2-1.5,0.3L67.2,25l-4.3-4.3L76.5,7 M6,121L6,121L6,121 M76.5,0c-1.8,0-3.6,0.7-4.9,2.1
			L61.3,12.3c-1.2-0.9-2.6-1.3-4.1-1.3c-1.9,0-3.6,0.7-4.9,2.1L39.7,25.6c-1.3,1.3-2.1,3.1-2.1,4.9c0,1.9,0.7,3.6,2.1,4.9l13.5,13.5
			c1.3,1.3,2.9,2,4.6,2L53,55.8c-0.6-0.1-1.1-0.1-1.7-0.1c-3.6,0-7,1.4-9.5,3.9l-9.9,9.9l-3.4,0.2L8.6,71.2c-3.5,0.2-6.2,3-6.5,6.5
			L-1,120.5c-0.1,2,0.6,4,2,5.4c1.3,1.3,3.1,2.1,4.9,2.1c0.2,0,0.3,0,0.5,0l42.9-3.1c3.5-0.2,6.2-3,6.5-6.5l1.7-23.3l9.9-9.9
			c3-3,4.3-7.2,3.8-11.2l4.8-4.8c0.1,1.7,0.8,3.4,2,4.6l13.5,13.5c1.4,1.4,3.2,2.1,4.9,2.1s3.6-0.7,4.9-2.1l12.6-12.6
			c2.5-2.5,2.7-6.3,0.7-9L125,55.5c2.7-2.7,2.7-7.2,0-9.9l-8.7-8.7c-1.4-1.4-3.2-2.1-5-2.1c-1.7,0-3.5,0.6-4.8,1.9l-0.5,0.5
			l-0.7-0.7l3.7-3.7c3.4-3.4,3.4-9,0-12.5l-2.3-2.3c-1.7-1.7-3.9-2.6-6.2-2.6c-2.4,0-4.6,0.9-6.2,2.6l-3.7,3.7l-0.7-0.7l0.5-0.5
			c2.6-2.7,2.6-7.1-0.1-9.8l-8.7-8.7C80.1,0.7,78.3,0,76.5,0L76.5,0z M6,128L6,128L6,128L6,128z'
          />
        </g>
        <g>
          <linearGradient
            id='SVGID_00000130647523160960741340000010963170712249994885_'
            gradientUnits='userSpaceOnUse'
            x1='42.1085'
            y1='111.2952'
            x2='42.109'
            y2='111.2952'
            gradientTransform='matrix(0.7071 0.7071 -0.7071 0.7071 54.9248 12.5723)'>
            <stop offset='0' style={{ stopColor: '#FDB3B1' }} />
            <stop offset='9.229275e-02' style={{ stopColor: '#FDD09F' }} />
            <stop offset='0.1885' style={{ stopColor: '#FFECA7' }} />
            <stop offset='0.3134' style={{ stopColor: '#C0F4BF' }} />
            <stop offset='0.4634' style={{ stopColor: '#B2F7F9' }} />
            <stop offset='0.6381' style={{ stopColor: '#ADD5FF' }} />
            <stop offset='0.7448' style={{ stopColor: '#B0C5FF' }} />
            <stop offset='0.8818' style={{ stopColor: '#B2B7FF' }} />
            <stop offset='1' style={{ stopColor: '#B3B2FF' }} />
          </linearGradient>
          <polygon
            fill='url(#SVGID_00000130647523160960741340000010963170712249994885_)'
            points='6,121 6,121 6,121 		'
          />
          <polyline points='6,121 6,121 6,121 6,121 		' />
        </g>
      </g>
    </svg>
  );
}
export default SvgKeySebastien;
