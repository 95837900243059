import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  Typography,
  Container,
  Button,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { cdnRoot } from '../../theme';
import useCommonStyles from '../../components/useCommonStyles';
import SvgIconClock from '../../assets/SvgIconClock';
import {
  TimekeeperAdHocArgsInput,
  TimekeeperAdHocFragment,
  useTimekeeperAdHocsQuery,
  useTimekeeperSaveAdHocMutation,
} from '../../components/schema';

import { ITimekeeperArgs } from '../../components/Timekeeper/TimekeeperSlice';
import { useCurrentTimekeeperRequest } from '../../components/Timekeeper/TimekeeperHooks';
import { getTicketDisplay } from '../../components/Timekeeper/TimekeeperReq';
import { signTimekeeperRequest } from '../../components/Timekeeper/FurgreementSig';
import WalletContext from '../../wallet/WalletContext';
import { useWalletSelector } from '../../hooks';

import { getTransactionLink } from '../../components/Blockchain/TransactionLink';
import { OutboundLink } from '../../components/Navigation';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  header: {
    textAlign: 'center',
    backgroundImage: `url(${cdnRoot}/svgs/timekeeper-bk.svg)`,
    backgroundSize: 'cover',
    // backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'bottom',
    padding: theme.spacing(4),
  },
  bar: {
    height: 40,
    backgroundColor: 'black',
    paddingTop: 2,
  },
  main: {
    textAlign: 'center',
    paddingTop: theme.spacing(2),
  },
  container: {
    border: '1px solid black',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
}));

interface ITkAdHocAuthorization {}

export const TkAdHocAuthorization: FunctionComponent<ITkAdHocAuthorization> = (
  props,
) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { log } = useFurComponent(TkAdHocAuthorization);
  const tkState = useCurrentTimekeeperRequest();
  const [save, { data, loading, error }] = useTimekeeperSaveAdHocMutation();
  const sender = useWalletSelector((w) => w.address);
  const context = React.useContext(WalletContext);
  const params = new URLSearchParams(window.location.search);
  const fuelCost = Number.parseInt(params.get('fuelCost') ?? '0');
  const gained = Number.parseInt(params.get('gained') ?? '0');
  const spent = Number.parseInt(params.get('spent') ?? '0');
  const tkArgs: ITimekeeperArgs = {
    fuelCost,
    gained,
    spent,
    mintEdition: 0,
    mintCount: 0,
    movementList: '',
    collectStayList: '',
  };
  const isSaved = data?.timekeeperSaveAdHoc.fuelCost === fuelCost;
  const history = useTimekeeperAdHocsQuery({
    variables: { sender: sender ?? '' },
  });
  const [tkHistory, setTkHistory] = React.useState<TimekeeperAdHocFragment[]>(
    [],
  );

  React.useEffect(() => {
    setTkHistory(history.data?.timekeeperAdHocs ?? []);
  }, [history]);

  async function run() {
    if (!context || !sender) return;
    try {
      const [req, signature] = await signTimekeeperRequest(
        tkArgs,
        sender,
        context,
      );
      const args: TimekeeperAdHocArgsInput = {
        fuelCost,
        gained,
        spent,
        sender,
        deadline: req.deadline,
      };

      const accpt = await save({ variables: { args, signature } });
      log.info(accpt.data);
      if (accpt.data) {
        setTkHistory([accpt.data.timekeeperSaveAdHoc, ...tkHistory]);
      }
    } catch (e) {
      log.error(e);
    }
  }

  return (
    <Grid container className={clsx(classes.root, common.underHeaderBar)}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant={'h1'}>
          <SvgIconClock
            style={{ width: 40, marginRight: 10 }}
            fill={'#000000'}
          />
          {'AUTHORIZE TIMEKEEPER'}
        </Typography>
        <Typography variant={'h5'}>
          Sign the Request for an Ad-Hoc Transaction
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.bar}>
        {' '}
      </Grid>
      <Grid item xs={12} className={classes.main}>
        <Container maxWidth={'sm'} className={classes.container}>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant={'h5'} style={{ color: '#888' }}>
                TIX Cost
              </Typography>
            </Grid>
            <Grid item xs={8} style={{ textAlign: 'left' }}>
              <Typography variant={'h5'}>
                {getTicketDisplay(fuelCost, tkState.round?.costs)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h5'} style={{ color: '#888' }}>
                $FUR Spent
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'left' }}>
              <Typography variant={'h5'}>{spent.toLocaleString()}</Typography>
            </Grid>
            <Grid item xs={4}>
              {!isSaved && !loading && (
                <Button
                  variant={'contained'}
                  color={'primary'}
                  className={common.actionButton}
                  disabled={fuelCost < 10000 || loading}
                  onClick={run}>
                  Sign & Save
                </Button>
              )}
              {isSaved && !loading && <React.Fragment>Saved!</React.Fragment>}
              {loading && <CircularProgress />}
              {error && (
                <Typography style={{ color: 'red' }}>
                  {error.message}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Container>
        {tkHistory.length > 0 && (
          <React.Fragment>
            <Typography variant={'h4'}>Ad-Hoc History</Typography>
            <Container maxWidth={'sm'} className={classes.container}>
              <Grid container>
                {tkHistory.map((tk) => {
                  log.info('tk', tk);
                  const link = tk.transactionHash
                    ? getTransactionLink(tk.transactionHash, context)
                    : undefined;

                  return (
                    <React.Fragment key={tk.id}>
                      <Grid item xs={4}>
                        {new Date(tk.createdAt).toLocaleString()}
                      </Grid>
                      <Grid item xs={2}>
                        {getTicketDisplay(tk.fuelCost, tkState.round?.costs)}{' '}
                        TIX
                      </Grid>
                      <Grid item xs={3}>
                        {tk.spent.toLocaleString()} $FUR
                      </Grid>
                      <Grid item xs={3}>
                        {link && (
                          <OutboundLink to={link}>Complete</OutboundLink>
                        )}
                        {!link && 'Pending'}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </Grid>
            </Container>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default TkAdHocAuthorization;
