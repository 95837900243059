import React, { FunctionComponent, useEffect } from 'react';

import {
  loadFurball,
  placeholderFurballId,
  useLoadedFurball,
} from '../../wallet';

import { makeStyles } from '@mui/styles';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import { cdnRoot, darkBlue } from '../../theme';
import { useWalletSelector, useAppDispatch } from '../../hooks';
import { RealmType, VoyageEncounterType } from '../schema';
import { IToken, IVoyageEncounter } from '../../wallet/WalletTypes';

import { useAnimationFrames } from '../../components/useAnimation';

import {
  getRealmColor,
  useActiveVoyageRealms,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_SCHOLARSHIPS,
  ZONE_NUM_VOYAGE,
} from '../Zones/ZoneHelpers';

import clsx from 'clsx';
import FurballImg from './FurballImg';
import SvgSelectHover from '../../assets/SvgSelectHover';
import FurballRenderer from './FurballRenderer';
import VoyageProgressBar from '../Zones/VoyageProgressBar';
import SvgRealmCreatureTurtleSea from '../../assets/voyages/SvgCreatureRealmTurtleSea';
import SvgRealmCreatureBoneyard from '../../assets/voyages/SvgCreatureRealmBoneyard';
import SvgRealmCreatureWizardsPath from '../../assets/voyages/SvgCreatureRealmWizardsPath';
import RealmCreatureVendorGameDialog from '../Zones/Realms/RealmCreatureVendorGameDialog';
import RealmDilemmaDialog from '../Zones/Realms/RealmDilemmaDialog';
import VoyageHistoryDialog from '../Zones/Realms/VoyageHistoryDialog';
import SvgTimer from '../../assets/SvgTimer';
import theme from '../../themev2';
import { useApolloClient } from '@apollo/client';
import WalletSlice from '../../wallet/WalletSlice';
import {
  calloutHeight,
  SelectableFurballCallout,
} from './SelectableFurballCallout';
import BossKey from './BossKey';

const width = 195;
const smallHeight = 242 + calloutHeight;
const height = 202 + calloutHeight;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: `${width}px`,
    height: `${height}px`,
    position: 'relative',
    display: 'inline-block',
  },
  rootEnabled: {
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${cdnRoot}/svgs/select-hover.svg)`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
    },
  },
  rootClickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundImage: `url(${cdnRoot}/svgs/select.svg)`,
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
    },
  },
  select: {
    position: 'absolute',
    top: `${calloutHeight}px`,
    left: 0,
    right: 0,
    bottom: 0,
  },
  furball: {
    position: 'absolute',
    bottom: -10,
  },
  zoneLoading: {
    filter: 'grayscale(100%)',
  },
  furballJump1: {
    bottom: '60px',
    transform: 'scale(1, 1.1)',
    transition: theme.transitions.create(['bottom', 'transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  furballJump2: {
    bottom: '-24px',
    transition: theme.transitions.create(['bottom', 'transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  furballJump3: {
    transform: 'scale(1.05, 0.8)',
    bottom: '-36px',
    transition: theme.transitions.create(['bottom', 'transform'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  voyageProgressBar: {
    position: 'absolute',
    bottom: -15,
    paddingLeft: 30,
  },
  calloutReward: {
    position: 'absolute',
    bottom: '160px',
    minHeight: `${calloutHeight}px`,
    textAlign: 'center',
    width: '100%',
    paddingLeft: '55px',
    paddingRight: '55px',
  },
  calloutInnerReward: {
    padding: 0,
    paddingTop: 4,
    border: '3px solid black',
    cursor: 'pointer',
    opacity: 0.8,
    height: 64,
    width: 64,
    '&:hover': {
      opacity: 1,
    },
  },

  [theme.breakpoints.down('lg')]: {
    root: {
      height: `${smallHeight}px`,
    },
    callout: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    select: {
      position: 'absolute',
      top: `${calloutHeight * 2}px`,
    },
    calloutTime: {
      marginTop: '-16px !important',
      marginBottom: '-6px !important',
      color: darkBlue,
    },
  },

  rentedtag: {
    position: 'absolute',
    bottom: '1rem',
    right: '1.1rem',
  },
}));

export interface IActiveRentalData {
  isRented?: boolean;
  isRentedOut?: boolean;
  timeRemaining: string;
}

type Props = IToken & {
  isSelected: boolean;
  onSelected: (selected: boolean) => void;
  disabled?: boolean;
  className?: string;
  zoneNumber?: number;
  activeRentalData?: IActiveRentalData;
};

enum FurballPopupState {
  None,
  CreatureVendor,
  PrisonnersDilemma,
  VoyageInfo,
  Complete,
}

export const SelectableFurball: FunctionComponent<Props> = (props) => {
  const {
    tokenId,
    onSelected,
    className,
    isSelected,
    disabled,
    zoneNumber,
    activeRentalData,
  } = props;

  const furball = useLoadedFurball(tokenId);
  const activeVoyage = furball?.activeVoyage;

  const lastVoyage =
    furball?.zone === ZONE_NUM_VOYAGE ? furball?.lastVoyage : undefined;
  const zoningId = useWalletSelector((s) => s.pending[tokenId]?.zoning);
  // const liveAt = useWalletSelector((s) => s.edition?.liveAt ?? 0);
  const classes = useStyles();
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  // const now = Math.round(new Date().getTime() / 1000);
  // const isLive = liveAt > 0 && liveAt < now;
  const realms = useActiveVoyageRealms();
  const isZoning = zoningId !== undefined;
  const isDisabled = disabled || isZoning;
  const activeEncounter = lastVoyage?.activeEncounter;
  const isBouncing = isZoning;
  const [popupState, setPopupState] = React.useState(FurballPopupState.None);
  const isComplete = (lastVoyage?.percentComplete ?? 0) >= 100;
  const canMove = canUpgradeOrMove();

  useEffect(() => {
    if (!!furball?.pendingUpgrade?.endsAt && canMove) {
      loadSyncedFb();
    }
  }, [canMove]);

  async function loadSyncedFb() {
    const loadedFb = await loadFurball(tokenId, client, dispatch);
    if (loadedFb) dispatch(WalletSlice.actions.setFurball(loadedFb));
  }

  const frameMap = useAnimationFrames(
    [classes.furballJump1, classes.furballJump2, classes.furballJump3],
    isBouncing,
    200,
  );

  function canUpgradeOrMove() {
    if (!furball?.pendingUpgrade?.endsAt) return true;

    const now = new Date().getTime();
    const canMoveAt = new Date(furball?.pendingUpgrade?.endsAt).getTime();
    return now > canMoveAt;
  }

  function onClickEncounter(encounter: IVoyageEncounter) {
    if (encounter.encounterType === VoyageEncounterType.Loot) {
      setPopupState(FurballPopupState.CreatureVendor);
    } else if (encounter.encounterType === VoyageEncounterType.Fur) {
      setPopupState(FurballPopupState.PrisonnersDilemma);
    } else {
      console.warn('ENCOUNTER', encounter);
    }
  }

  function onClick() {
    if (isDisabled) return;
    else if (activeEncounter && !activeEncounter.didPlayerComplete)
      onClickEncounter(activeEncounter);
    // else if (isComplete) onClickComplete();
    else if (furball?.zone === ZONE_NUM_VOYAGE && furball?.activeVoyage)
      setPopupState(FurballPopupState.VoyageInfo);
    else onSelected(!isSelected);
  }

  function renderReward(encounter?: IVoyageEncounter): React.ReactNode {
    // const isComplete = activeEncounter.
    const realmColor = getRealmColor(lastVoyage?.realm ?? RealmType.TurtleSea);
    const backgroundColor = realmColor.light;
    const hasEncounterPopup = encounter && !encounter.didPlayerComplete;

    const isOpen = isComplete || hasEncounterPopup;
    if (!isOpen) return null;

    return (
      <Paper
        className={clsx(classes.calloutInnerReward)}
        style={{ backgroundColor }}
        onClick={onClick}>
        {isComplete && !hasEncounterPopup && (
          <BossKey realm={activeVoyage?.realm} />
        )}
        {encounter && hasEncounterPopup && (
          <React.Fragment>
            {encounter.encounterType === VoyageEncounterType.Loot &&
              renderLootMinigameCallout(encounter.subType ?? 1)}
            {encounter.encounterType === VoyageEncounterType.Fur && (
              <div style={{ padding: 0, margin: -4, transform: 'scale(-1,1)' }}>
                <FurballImg
                  tokenId={encounter.match?.tokenId ?? placeholderFurballId}
                  size={60}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </Paper>
    );
  }

  function renderLootMinigameCallout(realmTypeNum: number): React.ReactNode {
    const realm = realms[realmTypeNum - 1];
    const size = 60;
    const style = { maxWidth: size, maxHeight: size };
    return (
      <div>
        {realm === RealmType.TurtleSea && (
          <SvgRealmCreatureTurtleSea style={style} />
        )}
        {realm === RealmType.Boneyard && (
          <SvgRealmCreatureBoneyard style={style} />
        )}
        {realm === RealmType.WizardsPath && (
          <SvgRealmCreatureWizardsPath style={style} />
        )}
      </div>
    );
  }

  const showReward = (activeEncounter || isComplete) && !isSelected;
  const showCallout = !canMove || (isSelected && !showReward);

  return (
    <span
      className={clsx(classes.root, className, {
        [classes.rootEnabled]: !isDisabled && !activeVoyage,
        [classes.rootClickable]: !!activeVoyage,
      })}>
      {isSelected && <SvgSelectHover className={classes.select} />}
      {showCallout && furball && (
        <SelectableFurballCallout
          furball={furball}
          canMove={canMove}
          activeRentalData={activeRentalData}
        />
      )}
      {showReward && (
        <div className={classes.calloutReward}>
          {renderReward(activeEncounter ?? undefined)}
        </div>
      )}

      {furball && (
        <span onClick={() => onClick()}>
          <FurballRenderer
            tokenId={furball.tokenId}
            size={180}
            className={clsx(classes.furball, frameMap)}
          />
        </span>
      )}

      {furball?.zone === ZONE_NUM_SCHOLARSHIPS &&
        zoneNumber === ZONE_NUM_BOSS_FIGHTS && (
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            direction='column'
            width='fit-content'
            className={classes.rentedtag}>
            <SvgTimer
              width='1.3rem'
              height='1.3rem'
              fill={theme.palette.grey[900]}
            />
            <Typography fontSize='0.7rem !important' fontWeight={600}>
              Rented
            </Typography>
          </Grid>
        )}

      {furball && lastVoyage && (
        <React.Fragment>
          <div className={classes.voyageProgressBar}>
            <VoyageProgressBar voyage={lastVoyage} />
          </div>
          <RealmCreatureVendorGameDialog
            open={popupState === FurballPopupState.CreatureVendor}
            onClose={() => setPopupState(FurballPopupState.None)}
            realmType={lastVoyage.realm}
            furball={furball}
          />
          <RealmDilemmaDialog
            open={popupState === FurballPopupState.PrisonnersDilemma}
            onClose={() => setPopupState(FurballPopupState.None)}
            realmType={lastVoyage.realm}
            furball={furball}
            otherTokenId={activeEncounter?.match?.tokenId}
          />
          <VoyageHistoryDialog
            open={popupState === FurballPopupState.VoyageInfo}
            onClose={() => setPopupState(FurballPopupState.None)}
            furball={furball}
          />
        </React.Fragment>
      )}
    </span>
  );
};

export default SelectableFurball;
