import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Chill = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#96fff4'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m248.77 130.43 3.5-5.15v-6.91s-24.03-.15-24.96-.15c-8.62 0-19.96 4.4-24.45 11.06h-11.67c-4.49-6.67-16.19-11.06-24.81-11.06-.93 0-24.96.15-24.96.15v6.91l3.5 5.15c-2.23 3.74-3.51 8.1-3.51 12.76 0 13.77 11.2 24.97 24.97 24.97s24.97-11.2 24.97-24.97c0-2.31.28-4.06-.3-6.18 3.53-2.78 9.91-1.46 11.71-.42-.66 2.24-.42 4.14-.42 6.6 0 13.77 11.2 24.97 24.97 24.97s24.97-11.2 24.97-24.97a24.8 24.8 0 0 0-3.51-12.76z'
    />
    <circle
      transform='matrix(.00398 -1 1 .00398 22.518 309.218)'
      fill='#162625'
      cx={166}
      cy={143}
      r={19}
    />
    <circle
      transform='matrix(1 -.00421 .00421 1 -.603 .96)'
      fill='#162625'
      cx={227}
      cy={143}
      r={19}
    />
    <path
      opacity={0.6}
      fill='#354f4d'
      d='m167 124.2-19.14 23.45c1.17 5.02 4.31 9.27 8.56 11.91l23.96-29.37A19.022 19.022 0 0 0 167 124.2zM221.03 161.55l22.78-27.92c-1.46-2.4-3.43-4.45-5.75-6l-24.7 28.81a18.98 18.98 0 0 0 7.67 5.11zM246.57 143.54c0-2.09-.34-4.11-.96-6l-20.38 24.98c.73.08 1.47.13 2.23.13 1.33 0 2.63-.14 3.89-.4l15.22-18.66v-.05z'
    />
    <circle
      transform='matrix(.00398 -1 1 .00398 22.518 309.218)'
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={166}
      cy={143}
      r={19}
    />
    <circle
      transform='matrix(1 -.00421 .00421 1 -.603 .96)'
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={227}
      cy={143}
      r={19}
    />
  </svg>
);

export default Chill;
