import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Unicorn = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#9cfffd'
      d='M66.47 132.28s16.35 16.35-13.62 45.63-17.03 67.42 8.17 70.83l16.37-8.85s-12.1-10.08 2.02-29.97c14.98-21.11 27.24-60.61-12.94-77.64z'
    />
    <path
      fill='#4affc7'
      d='M38.25 199.97c2.72 12 11.4 38.14 34.61 31.21 1.04 5.78 4.53 8.7 4.53 8.7l-16.37 8.85c-19.14-2.58-31.2-25.2-22.77-48.76z'
    />
    <path
      fill='#e1cfff'
      d='M58.8 171.58c7.71-9.01 10.9-16.59 11.82-22.61 3.72 3.79 9.74 12.36 8.79 27.58-.95 15.17-2.22 26.37.3 32.94-.1.15-.2.29-.3.44-2.54 3.58-4.22 6.84-5.29 9.78-13.96-12.14-15.69-34.54-15.32-48.13z'
    />
    <path
      fill='#e1cfff'
      d='M58.8 171.58c7.71-9.01 10.9-16.59 11.82-22.61 3.72 3.79 9.74 12.36 8.79 27.58-.95 15.17-2.22 26.37.3 32.94-.1.15-.2.29-.3.44-2.54 3.58-4.22 6.84-5.29 9.78-13.96-12.14-15.69-34.54-15.32-48.13z'
    />
    <path
      fill='#e1bdff'
      d='M58.8 171.58c7.71-9.01 10.9-16.59 11.82-22.61 3.72 3.79 9.74 12.36 8.79 27.58-.95 15.17-.48 22.72 2.04 29.29-.1.15-1.94 3.94-2.04 4.09-2.54 3.58-4.22 6.84-5.29 9.78-13.96-12.14-15.69-34.54-15.32-48.13z'
    />
    <path
      fill='#e8ff8c'
      d='M79.41 176.55c.85-13.55-4.91-22.9-8.81-27.46 1.71-10.96-4.13-16.8-4.13-16.8 38.26 16.21 28.97 52.8 15.06 74.49-1.95-6.76-2.95-16.98-2.12-30.23z'
    />
    <path
      opacity={0.6}
      fill='#FFF'
      d='M67.14 244.11s-13.1-11.32 1.01-31.2c13.73-19.34 30-56.75 1.23-75.85-1.29-3.15-2.91-4.78-2.91-4.78 40.18 17.03 27.92 56.53 12.94 77.64-14.11 19.89-2.02 29.97-2.02 29.97l-16.37 8.85c-1.03-.14-2.04-.34-3.03-.59l9.15-4.04z'
    />
    <path
      opacity={0.16}
      d='M52.85 177.91c29.97-29.29 13.62-45.63 13.62-45.63.55.23 1.09.47 1.62.71-.15-.06-.3-.13-.45-.19 0 0 22.59 15.83-7.38 45.11-27.85 27.22-18.64 62.08 3.03 69.6l-2.27 1.23c-25.2-3.41-38.14-41.54-8.17-70.83z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M66.47 132.28s16.35 16.35-13.62 45.63-17.03 67.42 8.17 70.83l16.37-8.85s-12.1-10.08 2.02-29.97c14.98-21.11 27.24-60.61-12.94-77.64z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M39.08 198.82c.27 12.76 10.22 39.29 33.42 32.37M70.62 148.97c3.72 3.79 9.74 12.36 8.79 27.58-.95 15.17-2.22 25.77.3 32.34M73.52 219.7c-13.96-12.13-15.09-33.94-14.72-47.53'
    />
  </svg>
);

export default Unicorn;
