import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import SvgMobileMenuX from '../../../assets/SvgMobileMenuX';
import UpgradeCard from './UpgradeCard';
import SvgIconBackArrow from '../../../assets/SvgIconBackArrow';
import FurballHead from '../../Furballs/FurballHead';
import OkDialog from '../../OkDialog';
import clsx from 'clsx';
import useCommonStyles from '../../useCommonStyles';

import { useAppDispatch, useWalletSelector } from '../../../hooks';
import { IFurball } from '../../../wallet/WalletTypes';
import { loadFurball, usePlayersReadyFurballs } from '../../../wallet';
import FurballLink from '../../Players/FurballLink';
import { useClientHardware } from '../../../pages/App/clientHardware';
import usePromise from 'react-promise-suspense';
import { useApolloClient } from '@apollo/client';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '100%',
    minHeight: '550px',
    backgroundColor: '#141B76',

    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
    },
  },

  dialog: {
    padding: '0 !important',
    display: 'grid',
    placeItems: 'center',
  },

  close: {
    '& button': {
      padding: '10px !important',
      height: '40px !important',
      width: '40px !important',
      minWidth: '40px !important',
      borderRadius: '50% !important',
      backgroundColor: 'transparent !important',
      border: 'none !important',
      opacity: 0.7,

      '& svg': {
        marginTop: '7.5px',
      },
    },
  },

  container: {
    minWidth: '495px',
    maxWidth: '495px',
    borderRadius: '12px',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.dark,
    boxShadow:
      'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
      borderRadius: '0px',
      border: 'none',
    },
  },

  header: {
    height: '50px',
    position: 'relative',
    padding: '1rem 0.5rem 1rem 0.75rem',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    userSelect: 'none',

    '& h3': {
      color: 'white !important',
      fontSize: '22px !important',
      marginLeft: '10px !important',
    },

    [theme.breakpoints.down('sm')]: {
      borderTopRightRadius: '0px',
      borderTopLeftRadius: '0px',
    },
  },

  headerInfo: {
    display: 'flex',
    alignItems: 'center',
  },

  furball: {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-12.5px',
    left: '-20px',
  },

  furballFigure: {
    position: 'relative',
  },

  cardContent: {
    padding: '0.25rem 0',
    margin: '5px',
    display: 'flex',
    overflowY: 'auto',
    flexDirection: 'column',
    overflowX: 'hidden',
    maxHeight: '695px',
    minHeight: '695px',
    '&::-webkit-scrollbar': {
      width: '0.7em',
    },

    [theme.breakpoints.down('sm')]: {
      maxHeight: 'calc(100vh - 50px)',
      minHeight: 'calc(100vh - 50px)',
      margin: '0',

      '&::-webkit-scrollbar': {
        width: '0em',
      },
    },
  },

  subScreens: {
    maxHeight: '695px',
    minHeight: '695px',
    margin: '5px',

    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 50px)',
      maxHeight: 'calc(100vh - 50px)',
      margin: '0',
    },
  },

  headerLink: {
    color: 'white !important',
    textDecoration: 'underline !important',
  },
}));

interface IUpgrade {
  show: boolean;
  onClose: () => void;
  onAction: () => void;
  tokenId?: string;
  tokenIds?: string[];
  setTokenId?: (tokenId: string | undefined) => void;
  rentFurball?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

interface IUpgradeFurball extends IUpgrade {
  furball: IFurball;
}

export enum UpgradeMode {
  None,
  Skills,
  Equipment,
  Specialization,
  Rental,
}

export const UpgradeContent: FunctionComponent<IUpgradeFurball> = (props) => {
  const { tokenIds, onClose, onAction, setTokenId, rentFurball, furball } =
    props;
  const classes = useStyles();
  const common = useCommonStyles();
  const contRef = React.useRef<HTMLDivElement>(null);
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const furballs = usePlayersReadyFurballs();
  const { cornerPadding } = useClientHardware();
  const fbTokenIds = tokenIds ?? furballs.map((fb) => fb.tokenId);
  // const isOwner = furball?.ownerId === playerId;
  const canUpgradeFurball = !rentFurball && !!furball;

  const [upgradeMode, setUpgradeMode] = useState<UpgradeMode>(UpgradeMode.None);

  React.useEffect(() => {
    if (!!contRef && !!contRef.current && !onMainScreen) {
      contRef.current.scrollTo(0, 0);
    }
  }, [upgradeMode]);

  const onMainScreen = upgradeMode === UpgradeMode.None;

  function close() {
    if (upgradeMode != UpgradeMode.None) {
      setUpgradeMode(UpgradeMode.None);
    } else {
      onClose();
    }
  }

  function renderCloseBtn() {
    return (
      <div className={classes.close}>
        <Button variant={'outlined'} onClick={close}>
          <Typography variant={'h6'}>
            {!onMainScreen ? (
              <SvgIconBackArrow height={18} width={18} stroke={'#EEF2FF'} />
            ) : (
              <SvgMobileMenuX height={18} width={18} stroke={'#EEF2FF'} />
            )}
          </Typography>
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div
        className={classes.header}
        style={{ height: 50 + cornerPadding, paddingTop: 12 + cornerPadding }}>
        <div className={classes.headerInfo}>
          {furball && (
            <FurballHead
              tokenId={furball.tokenId}
              size={33}
              borderRadius={100}
              backgroundColor={`#${furball.backgroundColor}`}
            />
          )}
          <Typography variant={'h3'}>
            {furball && (
              <FurballLink className={classes.headerLink} furball={furball} />
            )}
          </Typography>
        </div>
        {renderCloseBtn()}
      </div>
      <div
        // ref={contRef}
        className={clsx(classes.cardContent, common.styledScrollBar, {
          [classes.subScreens]: !onMainScreen,
        })}>
        {furball && (
          <UpgradeCard
            furball={furball}
            upgradeMode={upgradeMode}
            setUpgradeMode={setUpgradeMode}
            setTokenId={setTokenId}
            tokenIds={fbTokenIds}
            onAction={onAction}
            canUpgrade={canUpgradeFurball}
            rentFurball={rentFurball}
            close={close}
          />
        )}
      </div>
    </div>
  );
};

const UpgradeLoader: FunctionComponent<IUpgrade> = (props) => {
  const { show, tokenId } = props;
  const loadTokenId = show ? tokenId : undefined;
  const furball = useWalletSelector((w) =>
    tokenId ? (w.furballs ?? {})[tokenId] : undefined,
  );
  const dispatch = useAppDispatch();
  const client = useApolloClient();

  usePromise(async () => {
    if (loadTokenId && furball?.id !== loadTokenId) {
      // await new Promise((r) => setTimeout(r, 5000));
      await loadFurball(loadTokenId, client, dispatch);
    }
  }, [loadTokenId, furball]);

  return furball ? <UpgradeContent {...props} furball={furball} /> : null;
};

export const UpgradeDialog: FunctionComponent<IUpgrade> = (props) => {
  const classes = useStyles();
  const { show, onClose, style, className } = props;

  return (
    <OkDialog
      open={show}
      topRight={false}
      onClose={onClose}
      // hideScrollbar={true}
      transparentBg
      contentClassName={classes.dialog}
      style={style}
      className={className}>
      <React.Suspense
        fallback={
          <div>
            <CircularProgress color={'primary'} size='6rem' />
            <Typography variant={'h6'} color={'white'}>
              Loading Furball...
            </Typography>
          </div>
        }>
        <div className={classes.root}>
          <UpgradeLoader {...props} />
        </div>
      </React.Suspense>
    </OkDialog>
  );
};

export default UpgradeDialog;
