import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, ButtonGroup, Theme } from '@mui/material';
import { IFurballBase } from '../../wallet/WalletTypes';
import SvgIconInventory from '../../assets/SvgIconInventory';
import SvgIconDna from '../../assets/SvgIconDna';
import SvgIconRanks from '../../assets/SvgIconRanks';
import SvgIconSkills from '../../assets/SvgIconSkills';
import BondedIcon from '../../pages/Furball/BondedIcon';

const useStyles = makeStyles((theme: Theme) => ({
  actionIcons: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    maxWidth: '60px',
  },
  svgActionIcon: {
    margin: '0px',
    maxHeight: '24px',
    minWidth: '20px',
  },
  bondedIcon: {
    marginTop: theme.spacing(1),
  },
}));

export type ExpandMode = 'loot' | 'genes' | 'ranks' | 'skills';

interface IFurballButtonsHUD {
  furball: IFurballBase;
  mode: ExpandMode | undefined;
  largeBonded?: boolean | undefined;
  toggleMode: (newMode: ExpandMode) => void;
  hideBonded?: boolean;
  hideRankings?: boolean;
  staticPosition?: boolean;
}

const FurballButtons: FunctionComponent<IFurballButtonsHUD> = (props) => {
  const {
    furball,
    mode,
    toggleMode,
    largeBonded,
    hideBonded,
    hideRankings,
    staticPosition,
  } = props;

  const classes = useStyles();
  const iconFillSelected = '#FFFFFF';
  const iconFillDefault = '#5B50D9';

  return (
    <ButtonGroup
      orientation={'vertical'}
      className={!staticPosition ? classes.actionIcons : ''}>
      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => toggleMode('loot')}
        style={{
          backgroundColor: mode === 'loot' ? iconFillDefault : iconFillSelected,
        }}>
        <SvgIconInventory
          className={classes.svgActionIcon}
          fill={mode === 'loot' ? iconFillSelected : iconFillDefault}
        />
      </Button>

      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => toggleMode('genes')}
        style={{
          backgroundColor:
            mode === 'genes' ? iconFillDefault : iconFillSelected,
        }}>
        <SvgIconDna
          className={classes.svgActionIcon}
          fill={mode === 'genes' ? iconFillSelected : iconFillDefault}
        />
      </Button>

      {!hideRankings && (
        <Button
          variant={'contained'}
          color={'secondary'}
          onClick={() => toggleMode('ranks')}
          style={{
            backgroundColor:
              mode === 'ranks' ? iconFillDefault : iconFillSelected,
          }}>
          <SvgIconRanks
            className={classes.svgActionIcon}
            fill={mode === 'ranks' ? iconFillSelected : iconFillDefault}
          />
        </Button>
      )}

      <Button
        variant={'contained'}
        color={'secondary'}
        onClick={() => toggleMode('skills')}
        style={{
          backgroundColor:
            mode === 'skills' ? iconFillDefault : iconFillSelected,
        }}>
        <SvgIconSkills
          className={classes.svgActionIcon}
          fill={mode === 'skills' ? iconFillSelected : iconFillDefault}
        />
      </Button>

      {!hideBonded && (
        <BondedIcon
          className={classes.bondedIcon}
          marketListingCount={furball.marketListingCount}
          days={furball.state.bondingDays}
          size={largeBonded ? 32 : 24}
        />
      )}
    </ButtonGroup>
  );
};

export default FurballButtons;
