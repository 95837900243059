import React, { FunctionComponent, useState } from 'react';
import { ISelectedFurball, ZoneCard } from '../../components/Zones';
import { IFurball } from '../../wallet/WalletTypes';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useWalletSelector } from '../../hooks';
import {
  getZone,
  ZONE_NUM_SCHOLARSHIPS,
} from '../../components/Zones/ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    minWidth: '100%',
    minHeight: '105px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
}));

interface IBossFights {}

export function isFurballRentedToOtherPlayer(
  fb: IFurball,
  currentPlayerId?: string,
): boolean {
  return (
    !!currentPlayerId &&
    fb.activeRentalAgreement?.ownerPlayer.id === currentPlayerId
  );
}

const Scholarships: FunctionComponent<IBossFights> = (props) => {
  const classes = useStyles();

  const zoneNumber = ZONE_NUM_SCHOLARSHIPS;
  const zone = getZone(zoneNumber);
  const loading = false;

  const [mode, setMode] = React.useState('0');
  const [selectedFurballs, setSelectedFurballs] = useState<ISelectedFurball[]>(
    [],
  );

  const zoning = useWalletSelector((s) => Object.keys(s.pending));
  const account = useWalletSelector((s) => s.contents);
  const isLoading = zoning.length > 0 || loading;

  function isFurballInTab(fb: IFurball, tab: string): boolean {
    return isFurballRentedToOtherPlayer(fb, account?.id);
  }

  const tabs = {
    '0': 'Rented Out',
  };

  return (
    <ZoneCard
      title={zone.title}
      zoneNumber={zoneNumber}
      disabled={isLoading}
      loading={loading}
      selectedFurballs={selectedFurballs}
      setSelectedFurballs={setSelectedFurballs}
      tabs={tabs}
      selectedTab={mode}
      setSelectedTab={setMode}
      background={'boss_fights'}
      isFurballInTab={isFurballInTab}
    />
  );
};

export default Scholarships;
