import { useCallback, useEffect, useState } from 'react';
import { useStyles } from '../SkillLevelControl';
import { IParams, IUpgradeControlHook, SkillType } from '../SkillTypes';
import { useWalletSelector } from '../../../hooks';
import { getSkillDefinitionById } from '../SkillLibrary';

export const useSkillMaxUsesControls = ({
  skill,
  partials,
  setPartials,
  partialsSelected,
  setPartialsSelected,
  furballId,
  numUpgraded,
  maxDailyUpgrades,
}: IUpgradeControlHook) => {
  const defaultCurrentLevel = Math.trunc(
    partials / skill.maxUsesNumPartialsPerTier,
  );
  const def = getSkillDefinitionById(skill.skillDefinitionId);
  const defaultParams = {
    currentLevel: defaultCurrentLevel + 1,
    currentBar:
      defaultCurrentLevel === 0
        ? partials
        : partials % skill.maxUsesNumPartialsPerTier,
    ownedPartials:
      (def?.maxUsesBoost ?? 0) * skill.maxUsesNumPartialsPerTier -
      skill.maxUsesTotalPartialsRemaining,
    isMaxLevel: skill.maxUses === def?.maxUsesBoost,
    isPreMaxLevel: skill.maxUses === (def?.maxUsesBoost ?? 0) - 1,
    noChanges: true,
  };

  const classes = useStyles();
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const canAfford = skill.maxUsesPartialCost <= wFurBalance;
  let startIndicatorFromIndex = 0;

  const [bars, setBars] = useState<number[]>();
  const [params, setparams] = useState<IParams>(defaultParams);
  const [dummyPartials, setDummyPartials] = useState<boolean>(false);
  const [count, setCount] = useState<number | null>();

  const handleUpgrade = (type: 'down' | 'up') => {
    if (type === 'down') {
      const newPartials = partials - 1;
      setCount(newPartials);
      setPartials(newPartials);
      setPartialsSelected(-1, SkillType.Uses);
    }

    if (type === 'up') {
      const newPartials = partials + 1;
      setCount(newPartials);
      setPartials(newPartials);
      setPartialsSelected(1, SkillType.Uses);
    }
  };

  const getBarStatus = useCallback(
    (i: number) => {
      const { ownedPartials, currentLevel } = params;
      const { maxUsesNumPartialsPerTier, maxUsesIsUpgradeable, maxUses } =
        skill;

      const currentLvlIsOwned = currentLevel === maxUses;
      const ownedPartialsOnCurrentLvl = currentLvlIsOwned
        ? ownedPartials % maxUsesNumPartialsPerTier
        : 0;

      const barIsOwned =
        i <= ownedPartialsOnCurrentLvl || !maxUsesIsUpgradeable;
      const barIsNew = i > ownedPartialsOnCurrentLvl;

      const barIsWithinFilledIndexes =
        i <= partials % maxUsesNumPartialsPerTier;
      const barIsPending = barIsNew && barIsWithinFilledIndexes;

      const barsCount = skill.maxUsesNumPartialsPerTier;
      const cummulativeIndex = i + (currentLevel - 1) * barsCount;

      if (barIsOwned && partialsSelected !== maxDailyUpgrades) {
        startIndicatorFromIndex = cummulativeIndex;
      }

      const barClasses = barIsOwned
        ? classes.progressBarFilled
        : barIsPending
        ? classes.progressBarHalfFilled
        : dummyPartials
        ? classes.progressBarHalfFilled
        : params.isMaxLevel
        ? classes.progressBarFilled
        : cummulativeIndex <= maxDailyUpgrades + startIndicatorFromIndex
        ? classes.progressBarIndicator
        : '';

      return barClasses;
    },
    [skill, params, count, numUpgraded, maxDailyUpgrades],
  );

  const getOwnedPartials = useCallback(() => {
    const { maxUsesNumPartialsPerTier, maxUsesTotalPartialsRemaining } = skill;

    const def = getSkillDefinitionById(skill.skillDefinitionId);
    const maxPartials = (def?.maxUsesBoost ?? 0) * maxUsesNumPartialsPerTier;
    const ownedPartials = maxPartials - maxUsesTotalPartialsRemaining;

    return ownedPartials;
  }, [skill, count]);

  useEffect(() => {
    if (!skill) return;

    setCount(undefined);
    setDummyPartials(false);
    setparams(defaultParams);

    const noOfBars = skill.maxUsesNumPartialsPerTier;
    const arrayLength = noOfBars <= 0 ? 8 : noOfBars;
    setBars(Array.from(Array(arrayLength), (_, i) => i + 1));

    const ownedPartials = getOwnedPartials();
    skill.maxUsesIsUpgradeable && setPartials(ownedPartials);
  }, [skill, furballId]);

  useEffect(() => {
    const { maxUses, maxUsesIsUpgradeable, maxUsesNumPartialsPerTier } = skill;

    const def = getSkillDefinitionById(skill.skillDefinitionId);
    const maxUsesBoost = def?.maxUsesBoost ?? 0;

    if (!maxUsesIsUpgradeable) {
      setparams({
        ...params,
        isMaxLevel: true,
        currentLevel: skill.maxUses,
      });

      return;
    }

    const currentLevel = Math.trunc(partials / maxUsesNumPartialsPerTier) + 1;
    const isMaxLevel = currentLevel === maxUsesBoost + 1;
    const isPreMaxLevel = currentLevel + 1 === maxUsesBoost + 1;
    const isLvlZero = currentLevel === 1;
    const currentBar = isLvlZero
      ? partials
      : partials % maxUsesNumPartialsPerTier;

    const ownedPartials = getOwnedPartials();
    const noChanges = maxUses === currentLevel && partials === ownedPartials;

    setparams({
      currentLevel,
      isMaxLevel,
      isPreMaxLevel,
      currentBar,
      noChanges,
      ownedPartials,
    });

    isMaxLevel ? setDummyPartials(true) : setDummyPartials(false);
  }, [partials, skill, furballId]);

  return {
    canAfford,
    bars,
    params,
    dummyPartials,
    count,
    handleUpgrade,
    getBarStatus,
    setCount,
  };
};
