import React, { FunctionComponent, useState } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SkillsCarousel } from './SkillsCarousel';
import { SkillUpgradeBox } from './SkillUpgradeBox';

import { cdnRoot } from '../../theme';
import { EntitySkill, Specialization } from '../../components/schema';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    paddingTop: '16px',
    paddingBottom: '3rem',
    minWidth: '100%',
    display: 'flex !important',
    flexDirection: 'column',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },

  container: {
    marginTop: '3rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    paddingLeft: '16px',
    width: '100%',
    overflow: 'hidden',
  },

  carouselWrapper: {
    width: '33rem',
    zIndex: 2,
    transition: 'all 0.2s linear',
    position: 'absolute',
    maxWidth: '93%',
    top: '0rem',
  },

  notice: {
    height: '100%',
    marginTop: '2rem',
    opacity: 0.9,
    color: theme.palette.common.white,
  },

  skillUpgrade: {
    display: 'grid',
    placeItems: 'center',
    position: 'relative',
    maxWidth: '100%',
    width: '100%',
  },

  bossText: {
    paddingRight: '0',
    backgroundColor: theme.palette.secondary.dark,
    border: '2px solid #fff',
    borderBottom: 'none',
    borderRadius: '20px 20px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '275px',
    flexDirection: 'column',
    padding: '0.4rem 1rem 0.4rem 1rem',
  },

  skillShopTextWrap: {
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    border: '1px solid #fff',
    borderBottom: 'none',
    borderRadius: '20px 20px 0 0',
    boxShadow: '0 0 0 2px #fff',
    display: 'flex',
    justifyContent: 'center',
    height: '4rem',
    padding: '2rem 0 15.5rem',
  },

  skillShopText: {
    width: '10rem',
    height: '2rem',
  },

  boxContainer: {
    maxWidth: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
  },

  funfetti: {
    backgroundRepeat: 'repeat',
    backgroundPosition: 'top',
    backgroundImage: `url(${cdnRoot}/svgs/background-funfetti.svg)`,
  },
}));

interface ISkills {
  tokenId: string;
  isRentedFurball: boolean;
  skills: EntitySkill[];
  setSkills: (skills: EntitySkill[]) => void;
  selectedSkillIndex: number | null;
  specialization: Specialization;
}

export const Skills: FunctionComponent<ISkills> = (props) => {
  const {
    tokenId,
    skills,
    setSkills,
    selectedSkillIndex,
    isRentedFurball,
    specialization,
  } = props;
  const classes = useStyles();

  const [currentTab, setCurrentTab] = useState<number>(selectedSkillIndex ?? 1);
  const [showReroll, setShowReroll] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const updateSkills = async (skill: EntitySkill) => {
    const skls = [...skills];
    const idx = skls.findIndex((s) => s.slotIndex === skill.slotIndex);
    if (idx >= 0) skls.splice(idx, 1);
    skls.push(skill);
    skls.sort((a, b) => a.slotIndex - b.slotIndex);
    setSkills(skls);

    // if (!hasRolledSkill) {
    //   setSkills([...skills, skill]);
    // } else {
    //   const index = skills.findIndex((item) => item.slotIndex === 2);
    //
    //   const copy = [...skills];
    //   copy.splice(index, 1, skill);
    //
    //   setSkills(copy);
    //   setShowReroll(false);
    // }
  };

  function renderSkills() {
    return (
      <div className={classes.skillUpgrade}>
        <div className={classes.carouselWrapper}>
          <SkillsCarousel
            skills={skills}
            specialization={specialization}
            isRented={isRentedFurball}
            currentTab={currentTab}
            showReroll={showReroll}
            toggleReroll={() => setShowReroll(!showReroll)}
            setCurrentTab={(index) => setCurrentTab(index)}
            disabled={disabled}
          />
        </div>
        <div className={classes.boxContainer}>
          <SkillUpgradeBox
            furballId={tokenId}
            specialization={specialization}
            skills={skills}
            setSkills={setSkills}
            currentTab={currentTab}
            updateSkills={updateSkills}
            toggleReroll={() => setShowReroll(!showReroll)}
            showReroll={showReroll}
            setDisabled={(value) => setDisabled(value)}
          />
        </div>
      </div>
    );
  }

  return renderSkills();
};

export default Skills;
