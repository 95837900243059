import * as React from 'react';

interface ISpinProps {
  svg?: React.SVGProps<SVGSVGElement>;
  children?: React.ReactNode;
}

function SvgVoyageSpinBoneyard(props: ISpinProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='584.99 197.67 546.89 475.16'
      {...(props.svg ?? {})}>
      <path
        fill='#BAAFB7'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M1096.33 636.14V385.55c0-30.53-12.74-59.67-35.14-80.41l-45.93-42.52c-34.61-32.03-78.63-48.05-122.65-48.05h-52.49v421.57'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M682.43 299.6c-4.91 4.54-9.32 9.52-13.2 14.84l171.33 115.93v-215.8c-41.28.03-82.55 15.07-115.01 45.11l-43.12 39.92z'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M1011.71 314.45c-3.88-5.32-8.29-10.3-13.2-14.84l-43.12-39.92c-32.45-30.04-73.72-45.08-115.01-45.11v215.8l171.33-115.93z'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M649.44 375.1v188.04l191.12-129.32v-3.44L669.23 314.45a102.902 102.902 0 00-19.79 60.65z'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M840.38 433.82l191.12 129.32V375.1c0-21.97-7.03-43.18-19.79-60.65L840.38 430.38v3.44z'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M840.56 433.82L649.44 563.14v73h191.12z'
      />
      <path
        fill='#D5D5D5'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M1031.5 636.14v-73L840.38 433.82v202.32z'
      />
      {props.children}
      <path
        fill='#3D3129'
        d='M1005.75 618.98c-16.57 0-32.41.88-46.99 2.48-12.87 1.41-26.21-.69-35.99-5.9-15.19-8.1-36.88-9.17-60.98-9.17-23.43 0-44.59.79-59.71 8.5-8.11 4.13-18.31 6.45-28.95 6.43h-.45c-29.62 0-74.34-15.64-90.53-7.38-8.62 4.4-19.93 6.43-31.21 5.88-3.98-.19-8.05-.3-12.2-.3-28.56 0-35.15 25.76-49.32 32.92-8.65 4.36-3.76 13.55 7.11 13.55h523.84c8.91 0 14.45-6.44 9.85-11.49-16.08-17.63-65.72-35.52-124.47-35.52z'
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={728.28}
        cy={235.19}
        r={34.52}
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={945.01}
        cy={235.19}
        r={34.52}
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={740.22}
        cy={635.31}
        r={34.52}
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={940.97}
        cy={635.31}
        r={34.52}
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={1044.68}
        cy={451.39}
        r={34.52}
      />
      <circle
        fill='#72638A'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        cx={637.32}
        cy={451.39}
        r={34.52}
      />
      <g fill='#FFF'>
        <path d='M717.8 230.78v1.75h-10.25v-1.75h10.25zm-4.23 5.88h-1.82v-9.98h1.82v9.98zM722.69 229.39h2.78c1.2 0 2.11.27 2.74.82.62.54.94 1.35.94 2.42v3c0 1.1-.3 1.94-.91 2.5-.61.56-1.51.82-2.71.79l-5.45-.1v-2.14h6.26c.21 0 .31-.1.31-.31v-4.56c0-.21-.1-.31-.31-.31h-6.1v-7.92h8.33v2.14h-5.88v3.67zM731.05 230.16v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.72.49h-2.02c-.77 0-1.33-.16-1.68-.49-.36-.33-.53-.83-.53-1.5zm4.32-5.02h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.27-1.75h1.94l-8.45 15.62h-1.97l8.48-15.62zm-1.44 13.58v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49-.36.32-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48-.36-.32-.53-.82-.53-1.49zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.18 0 .26-.08.26-.24v-4.82c0-.16-.09-.24-.26-.24z' />
      </g>
      <g fill='#E1C54B'>
        <path d='M716.16 249.29h1.55c.65 0 1.14.14 1.46.42s.49.72.49 1.31v.94c0 .59-.16 1.04-.49 1.34-.33.3-.81.45-1.46.44l-2.9-.05v-1.16h3.34c.11 0 .17-.06.17-.17v-1.7c0-.11-.06-.17-.17-.17h-1.55c-.65 0-1.14-.14-1.46-.42-.33-.28-.49-.72-.49-1.32v-.84c0-.58.17-1.03.5-1.35.33-.32.83-.47 1.47-.45l2.78.05v1.16h-3.25c-.11 0-.17.06-.17.17v1.64c.01.11.07.16.18.16zm.51-3.03v-1.43h1.11v1.43h-1.11zm0 8.73v-1.43h1.11v1.43h-1.11zM725.61 249.27v1.14h-3.24l.22-.22v3.72h-1.33v-8.25h1.33v3.83l-.22-.22h3.24zm-3.52-2.46v-1.16h3.76v1.16h-3.76zM730.76 254.01h-2.11c-.62 0-1.09-.13-1.4-.4s-.46-.69-.46-1.27v-6.68h1.35v7.02c0 .1.05.14.14.14h2.85c.09 0 .14-.05.14-.14v-7.02h1.35v6.68c0 .58-.15 1.01-.46 1.27s-.77.4-1.4.4zM735.69 253.91h-1.34v-8.25l3.48-.05c.65-.02 1.14.13 1.47.43.33.3.49.75.49 1.35v1.47c0 .49-.13.89-.39 1.2s-.65.5-1.18.58l.01-.27c.24.05.44.15.58.31.14.15.27.38.37.68l.9 2.56h-1.34l-1.04-3.07a.316.316 0 00-.07-.13c-.03-.03-.07-.04-.14-.04h-2.07l.25-.23.02 3.46zm-.01-7.4v3.24l-.21-.22h2.81c.11 0 .17-.06.17-.17v-2.43c0-.11-.06-.17-.17-.17h-2.79l.19-.25z' />
      </g>
      <g fill='#FFF'>
        <path d='M927.93 230.78v1.75h-10.25v-1.75h10.25zm-4.23 5.88h-1.82v-9.98h1.82v9.98zM938.85 235.6c0 1.07-.32 1.9-.95 2.48s-1.55.86-2.75.83l-5.33-.1v-2.14h6.19c.21 0 .31-.1.31-.31v-4.01c0-.22-.1-.34-.31-.34h-4.78v-2.09h4.66c.21 0 .31-.1.31-.31V226c0-.21-.1-.31-.31-.31h-6v-2.11l5.21-.1c1.18-.03 2.08.23 2.7.79.62.56.92 1.39.92 2.5v1.8c0 1.01-.34 1.74-1.01 2.18-.67.45-1.61.67-2.81.67l.1-.84c1.3 0 2.26.22 2.89.66s.95 1.2.95 2.27v2.09zM942.04 235.92v-9.43c0-1.09.28-1.88.85-2.36.57-.49 1.43-.73 2.58-.73h3.07c1.18 0 2.06.24 2.62.73s.84 1.28.84 2.36v9.43c0 1.07-.28 1.86-.84 2.35-.56.5-1.43.74-2.62.74h-3.07c-1.15 0-2.01-.25-2.58-.74-.57-.5-.85-1.28-.85-2.35zm7.2-10.32h-4.44c-.18 0-.26.09-.26.26v10.68c0 .18.09.26.26.26h4.44c.18 0 .26-.09.26-.26v-10.68c0-.18-.08-.26-.26-.26zM954.38 230.16v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.72.49h-2.02c-.77 0-1.33-.16-1.68-.49-.36-.33-.53-.83-.53-1.5zm4.31-5.02h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.28-1.75h1.94l-8.45 15.62h-1.97l8.48-15.62zm-1.44 13.58v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49-.36.32-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48-.36-.32-.53-.82-.53-1.49zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.18 0 .26-.08.26-.24v-4.82c0-.16-.09-.24-.26-.24z' />
      </g>
      <g fill='#D17CBD'>
        <path d='M939.83 253.91h-4.76v-8.25h4.69v1.16h-3.57l.21-.21v2.74l-.21-.21h3.33v1.14h-3.33l.21-.2v2.87l-.21-.21h3.64v1.17zM944.03 249.58c.39 0 .68.04.88.12s.36.21.48.39c.13.18.27.43.44.75l1.56 3.07h-1.43l-1.68-3.46c-.03-.05-.05-.09-.06-.1-.02-.02-.05-.03-.1-.03h-.65c-.05 0-.09.01-.11.03-.02.02-.04.05-.07.1l-1.66 3.46h-1.35l1.52-3.07c.13-.24.24-.45.33-.61.09-.17.19-.29.3-.39.11-.09.25-.16.43-.2s.41-.06.7-.06h.47zm.31-.55l1.56-3.38h1.34l-1.43 2.99c-.12.25-.23.46-.32.62-.09.16-.19.29-.3.38-.11.09-.25.15-.43.19s-.41.06-.7.06h-.51c-.38 0-.68-.04-.88-.11a.952.952 0 01-.5-.38c-.12-.18-.26-.43-.4-.75l-1.36-2.99h1.42l1.52 3.38c.02.04.04.08.06.1s.05.03.1.03h.66c.07-.01.12-.05.17-.14zM949.46 253.91h-1.34v-8.25l3.46-.05c.65-.02 1.14.13 1.48.43.33.3.5.75.5 1.35v1.66c0 .37-.07.7-.22.97s-.37.48-.66.63c-.29.15-.66.22-1.09.22h-2.38l.25-.23v3.27zm-.01-7.4v3.42l-.21-.22h2.78c.11 0 .17-.06.17-.17v-2.61c0-.11-.06-.17-.17-.17h-2.77l.2-.25z' />
      </g>
      <g fill='#FFF'>
        <path d='M723.14 630.9v1.75h-10.25v-1.75h10.25zm-4.22 5.88h-1.82v-9.98h1.82v9.98zM734.06 635.72c0 1.07-.32 1.9-.95 2.48s-1.55.86-2.75.83l-5.33-.1v-2.14h6.19c.21 0 .31-.1.31-.31v-4.01c0-.22-.1-.34-.31-.34h-4.78v-2.09h4.66c.21 0 .31-.1.31-.31v-3.62c0-.21-.1-.31-.31-.31h-6v-2.11l5.21-.1c1.18-.03 2.08.23 2.7.79.62.56.92 1.39.92 2.5v1.8c0 1.01-.34 1.74-1.01 2.18s-1.61.67-2.81.67l.1-.84c1.3 0 2.26.22 2.89.66.63.44.95 1.2.95 2.27v2.1zM737.25 636.03v-9.43c0-1.09.28-1.88.85-2.36.57-.49 1.43-.73 2.58-.73h3.07c1.18 0 2.06.24 2.62.73s.84 1.28.84 2.36v9.43c0 1.07-.28 1.86-.84 2.35-.56.5-1.43.74-2.62.74h-3.07c-1.15 0-2.01-.25-2.58-.74-.56-.49-.85-1.28-.85-2.35zm7.2-10.32h-4.44c-.18 0-.26.09-.26.26v10.68c0 .18.09.26.26.26h4.44c.18 0 .26-.09.26-.26v-10.68c.01-.17-.08-.26-.26-.26zM749.59 630.27v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.72.49h-2.02c-.77 0-1.33-.16-1.68-.49-.36-.32-.53-.83-.53-1.5zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.27-1.76h1.94l-8.45 15.62h-1.97l8.48-15.62zm-1.44 13.59v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49-.36.32-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48-.36-.32-.53-.82-.53-1.49zm4.32-5.02h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.18 0 .26-.08.26-.24v-4.82c0-.16-.09-.24-.26-.24z' />
      </g>
      <g fill='#E1C54B'>
        <path d='M728.1 649.41h1.55c.65 0 1.14.14 1.46.42s.49.72.49 1.31v.94c0 .59-.16 1.04-.49 1.34-.33.3-.81.45-1.46.44l-2.9-.05v-1.16h3.34c.11 0 .17-.06.17-.17v-1.7c0-.11-.06-.17-.17-.17h-1.55c-.65 0-1.14-.14-1.46-.42-.33-.28-.49-.72-.49-1.32v-.84c0-.58.17-1.03.5-1.35.33-.32.83-.47 1.47-.45l2.78.05v1.16h-3.25c-.11 0-.17.06-.17.17v1.64c.01.1.07.16.18.16zm.51-3.03v-1.43h1.11v1.43h-1.11zm0 8.72v-1.43h1.11v1.43h-1.11zM737.56 649.38v1.14h-3.24l.22-.22v3.72h-1.33v-8.25h1.33v3.83l-.22-.22h3.24zm-3.53-2.46v-1.16h3.76v1.16h-3.76zM742.7 654.13h-2.11c-.62 0-1.09-.13-1.4-.4s-.46-.69-.46-1.27v-6.68h1.35v7.02c0 .1.05.14.14.14h2.85c.09 0 .14-.05.14-.14v-7.02h1.35v6.68c0 .58-.15 1.01-.46 1.27s-.76.4-1.4.4zM747.63 654.02h-1.34v-8.25l3.48-.05c.65-.02 1.14.13 1.47.43.33.3.49.75.49 1.35v1.47c0 .49-.13.89-.39 1.2s-.65.5-1.18.58l.01-.27c.24.05.44.15.58.31.14.15.27.38.37.68l.9 2.56h-1.34l-1.04-3.07a.316.316 0 00-.07-.13c-.03-.03-.07-.04-.14-.04h-2.07l.25-.23.02 3.46zm-.01-7.39v3.24l-.21-.22h2.81c.11 0 .17-.06.17-.17v-2.43c0-.11-.06-.17-.17-.17h-2.79l.19-.25z' />
      </g>
      <g fill='#FFF'>
        <path d='M930.49 630.9v1.75h-10.25v-1.75h10.25zm-4.22 5.88h-1.82v-9.98h1.82v9.98zM935.39 629.5h2.78c1.2 0 2.11.27 2.74.82.62.54.94 1.35.94 2.42v3c0 1.1-.3 1.94-.91 2.5-.61.56-1.51.82-2.71.79l-5.45-.1v-2.14h6.26c.21 0 .31-.1.31-.31v-4.56c0-.21-.1-.31-.31-.31h-6.1v-7.92h8.33v2.14h-5.88v3.67zM943.74 630.27v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.71.49h-2.02c-.77 0-1.33-.16-1.68-.49-.36-.32-.54-.83-.54-1.5zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.27-1.76h1.95l-8.45 15.62h-1.97l8.47-15.62zm-1.44 13.59v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49s-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48s-.53-.82-.53-1.49zm4.32-5.02h-2.18c-.18 0-.27.08-.27.24v4.82c0 .16.09.24.27.24h2.18c.18 0 .26-.08.26-.24v-4.82c.01-.16-.08-.24-.26-.24z' />
      </g>
      <g fill='#D17CBD'>
        <path d='M935.79 654.02h-4.76v-8.25h4.69v1.16h-3.57l.21-.21v2.74l-.21-.21h3.33v1.14h-3.33l.21-.2v2.87l-.21-.21h3.64v1.17zM939.99 649.69c.39 0 .68.04.88.12s.36.21.48.39c.12.18.27.43.44.75l1.56 3.07h-1.43l-1.68-3.46c-.03-.05-.05-.09-.06-.1-.02-.02-.05-.03-.11-.03h-.65c-.05 0-.09.01-.11.03-.02.02-.04.05-.07.1l-1.66 3.46h-1.35l1.52-3.07c.13-.24.24-.45.33-.61.09-.17.19-.29.3-.39.11-.09.25-.16.43-.2s.41-.06.7-.06h.48zm.31-.54l1.56-3.38h1.34l-1.43 2.99c-.12.25-.23.46-.32.62-.09.16-.19.29-.3.38-.11.09-.25.15-.43.19s-.41.06-.7.06h-.51c-.38 0-.68-.04-.88-.11a.952.952 0 01-.5-.38c-.12-.18-.26-.43-.4-.75l-1.37-2.99h1.42l1.52 3.38c.02.04.04.08.06.1s.05.03.1.03h.66c.08-.01.14-.06.18-.14zM945.43 654.02h-1.34v-8.25l3.46-.05c.65-.02 1.14.13 1.47.43.33.3.5.75.5 1.35v1.66c0 .37-.07.7-.22.97s-.37.48-.66.63-.66.22-1.09.22h-2.38l.25-.23.01 3.27zm-.02-7.39v3.42l-.21-.22h2.78c.11 0 .17-.06.17-.17v-2.61c0-.11-.06-.17-.17-.17h-2.77l.2-.25z' />
      </g>
      <g fill='#FFF'>
        <path d='M1028.98 446.98v1.75h-10.25v-1.75h10.25zm-4.23 5.88h-1.82v-9.98h1.82v9.98zM1030.8 442.61l4.39-2.83h2.26v15.24H1035v-12.84l.17.07-3.36 2.04-1.01-1.68zm9.29 10.41v1.99h-8.47v-1.99h8.47zM1044.31 445.58h2.78c1.2 0 2.11.27 2.74.82.62.54.94 1.35.94 2.42v3c0 1.1-.3 1.94-.91 2.5-.61.56-1.51.82-2.71.79l-5.45-.1v-2.14h6.26c.21 0 .31-.1.31-.31V448c0-.21-.1-.31-.31-.31h-6.1v-7.92h8.33v2.14h-5.88v3.67zM1052.66 446.35v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.71.49h-2.02c-.77 0-1.33-.16-1.68-.49-.36-.33-.54-.83-.54-1.5zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.28-1.76h1.95l-8.45 15.62h-1.97l8.47-15.62zm-1.44 13.59v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49s-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48s-.53-.82-.53-1.49zm4.32-5.02h-2.18c-.18 0-.27.08-.27.24v4.82c0 .16.09.24.27.24h2.18c.18 0 .26-.08.26-.24v-4.82c0-.16-.09-.24-.26-.24z' />
      </g>
      <g fill='#E1C54B'>
        <path d='M1032.56 465.49h1.55c.65 0 1.14.14 1.46.42s.49.72.49 1.31v.94c0 .59-.16 1.04-.49 1.34-.33.3-.81.45-1.46.44l-2.9-.05v-1.16h3.34c.11 0 .17-.06.17-.17v-1.7c0-.11-.06-.17-.17-.17H1033c-.65 0-1.14-.14-1.46-.42-.33-.28-.49-.72-.49-1.32v-.84c0-.58.17-1.03.5-1.35.33-.32.83-.47 1.47-.45l2.78.05v1.16h-3.25c-.11 0-.17.06-.17.17v1.64c.01.1.07.16.18.16zm.51-3.03v-1.43h1.11v1.43h-1.11zm0 8.72v-1.43h1.11v1.43h-1.11zM1042.01 465.46v1.14h-3.24l.22-.22v3.72h-1.33v-8.25h1.33v3.83l-.22-.22h3.24zm-3.52-2.46v-1.16h3.76V463h-3.76zM1047.16 470.21h-2.11c-.62 0-1.09-.13-1.4-.4s-.46-.69-.46-1.27v-6.68h1.35v7.02c0 .1.05.14.14.14h2.85c.09 0 .14-.05.14-.14v-7.02h1.35v6.68c0 .58-.15 1.01-.46 1.27s-.77.4-1.4.4zM1052.09 470.1h-1.34v-8.25l3.48-.05c.65-.02 1.14.13 1.47.43.33.3.49.75.49 1.35v1.47c0 .49-.13.89-.39 1.2s-.65.5-1.18.58l.01-.27c.24.05.44.15.58.31.14.15.27.38.37.68l.9 2.56h-1.34l-1.04-3.07a.316.316 0 00-.07-.13c-.03-.03-.07-.04-.14-.04h-2.07l.25-.23.02 3.46zm-.02-7.39v3.24l-.21-.22h2.81c.11 0 .17-.06.17-.17v-2.43c0-.11-.06-.17-.17-.17h-2.79l.19-.25z' />
      </g>
      <g fill='#FFF'>
        <path d='M621.62 446.98v1.75h-10.25v-1.75h10.25zm-4.22 5.88h-1.82v-9.98h1.82v9.98zM623.45 442.61l4.39-2.83h2.26v15.24h-2.45v-12.84l.17.07-3.36 2.04-1.01-1.68zm9.29 10.41v1.99h-8.47v-1.99h8.47zM636.96 445.58h2.78c1.2 0 2.11.27 2.74.82.62.54.94 1.35.94 2.42v3c0 1.1-.3 1.94-.91 2.5-.61.56-1.51.82-2.71.79l-5.45-.1v-2.14h6.26c.21 0 .31-.1.31-.31V448c0-.21-.1-.31-.31-.31h-6.1v-7.92h8.33v2.14h-5.88v3.67zM645.31 446.35v-4.7c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.68.48.35.32.53.82.53 1.51l.07 4.7c0 .67-.19 1.17-.56 1.5-.38.33-.95.49-1.72.49h-2.02c-.77 0-1.33-.16-1.68-.49-.35-.33-.53-.83-.53-1.5zm4.32-5.01h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.16 0 .24-.08.24-.24v-4.82c0-.16-.08-.24-.24-.24zm7.27-1.76h1.94l-8.45 15.62h-1.97l8.48-15.62zm-1.44 13.59v-4.73c0-.69.18-1.19.53-1.51.35-.32.91-.48 1.68-.48h2.02c.77 0 1.33.16 1.69.48.36.32.54.82.54 1.51v4.73c0 .67-.18 1.17-.54 1.49-.36.32-.92.48-1.69.48h-2.02c-.77 0-1.33-.16-1.68-.48-.35-.32-.53-.82-.53-1.49zm4.32-5.02h-2.18c-.18 0-.26.08-.26.24v4.82c0 .16.09.24.26.24h2.18c.18 0 .26-.08.26-.24v-4.82c.01-.16-.08-.24-.26-.24z' />
      </g>
      <g fill='#D17CBD'>
        <path d='M632.14 470.1h-4.76v-8.25h4.69V463h-3.57l.21-.21v2.74l-.21-.21h3.33v1.14h-3.33l.21-.2v2.87l-.21-.21h3.64v1.18zM636.34 465.77c.39 0 .68.04.88.12s.36.21.48.39c.13.18.27.43.44.75l1.56 3.07h-1.43l-1.68-3.46c-.03-.05-.05-.09-.06-.1-.02-.02-.05-.03-.1-.03h-.65c-.05 0-.09.01-.11.03-.02.02-.04.05-.07.1l-1.66 3.46h-1.35l1.52-3.07c.13-.24.24-.45.33-.61.09-.17.19-.29.3-.39.11-.09.25-.16.43-.2s.41-.06.7-.06h.47zm.31-.54l1.56-3.38h1.34l-1.43 2.99c-.12.25-.23.46-.32.62-.09.16-.19.29-.3.38-.11.09-.25.15-.43.19s-.41.06-.7.06h-.51c-.38 0-.68-.04-.88-.11a.952.952 0 01-.5-.38c-.12-.18-.26-.43-.4-.75l-1.36-2.99h1.42l1.52 3.38c.02.04.04.08.06.1s.05.03.1.03h.66c.08-.01.13-.06.17-.14zM641.78 470.1h-1.34v-8.25l3.46-.05c.65-.02 1.14.13 1.48.43.33.3.5.75.5 1.35v1.66c0 .37-.07.7-.22.97s-.37.48-.66.63c-.29.15-.66.22-1.09.22h-2.38l.25-.23v3.27zm-.02-7.39v3.42l-.21-.22h2.78c.11 0 .17-.06.17-.17v-2.61c0-.11-.06-.17-.17-.17h-2.77l.2-.25z' />
      </g>
    </svg>
  );
}

export default SvgVoyageSpinBoneyard;
