import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Grumpy = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ff5252'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M201.49 150.79c-.21 1.55-2.65 5.83-5.92 5.83s-6.06-4.28-5.92-5.83c.39-4.19 12.32-3.56 11.84 0z'
    />
    <path d='M200.69 162.77c4.19.58 4.68 3.87 5.32 6.69.41 1.83 1.23 2.12 1.98 1.97.97-.2 1.7-.67 1.65-1.67-.16-3.14-2.09-8.13-7.43-9.61-5.6-1.55-8.09 4.34-8.09 4.34-7.64-2.08-9.61 1.62-9.91 4.08l-.15.85c-.09.51.29.99.81 1.01.46.02.86-.34.89-.8 0 0 .22-6.04 8.07-3.29 0 0 1.39.37 1.83-.66.44-1.03 1.86-3.35 5.03-2.91z' />
  </svg>
);

export default Grumpy;
