import React, { FunctionComponent, useContext } from 'react';
import {
  Theme,
  Container,
  TextField,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { LoginOutcome, useLoginEmailMutation } from '../components/schema';
import useCommonStyles from '../components/useCommonStyles';
import { ALERT_TYPES, AlertContext } from '../components/Alert/AlertContext';
import { fireLoginBeginEvent } from '../Analytics';
import { useAuthContext } from '../components/Auth/AuthContext';
import { FurAccountTypes } from '../components/Auth/AuthTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  button: {
    width: '100% !important',
    // paddingTop: '10px !important',
    // paddingBottom: '10px !important',
    fontSize: '1.5rem !important',
  },
}));

interface ILoginEmailForm {
  title?: string;
  onSent?: () => void;
  analyticsPathComponent?: string;
}

export const LoginEmailForm: FunctionComponent<ILoginEmailForm> = (props) => {
  const { onSent, analyticsPathComponent } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState<string | null>(null);
  const [outcome, setOutcome] = React.useState<LoginOutcome | undefined>(
    undefined,
  );
  const title = props.title ?? 'Play';
  const { addAlert } = useContext(AlertContext);
  const { handleAuth } = useAuthContext();
  const [login, { data, loading }] = useLoginEmailMutation();
  const successful =
    outcome === LoginOutcome.Validating || outcome === LoginOutcome.Success;
  const emailInvalid = !email.includes('@') || email.length < 4;

  async function doLogin() {
    if (emailInvalid) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'Invalid email address',
      });
      return;
    }
    try {
      const log = await login({ variables: { email, code } });
      let msg = 'Please check your inbox';
      const { secret, currentPlayer, outcome: oc } = log.data?.loginEmail ?? {};
      setOutcome(oc);
      if (oc === LoginOutcome.Success && secret && currentPlayer) {
        msg = 'You are logged in';
        handleAuth(FurAccountTypes.SCHOLAR, secret, currentPlayer.id);
      } else {
        if (onSent) onSent();
        fireLoginBeginEvent('Email', analyticsPathComponent ?? '/login-email');
      }

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: msg,
      });
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Unable to login',
      });
    }
  }

  function onLoginForm(e: React.FormEvent): boolean {
    void doLogin();
    e.preventDefault();
    return false;
  }

  return (
    <Container className={clsx(classes.root)} maxWidth={'md'}>
      {!successful && (
        <form onSubmit={onLoginForm}>
          <Grid
            container
            style={{ marginTop: 20, marginBottom: 0 }}
            spacing={2}>
            <Grid item xs={12} lg={8}>
              <TextField
                style={{ width: '100%' }}
                value={email}
                onChange={(e) => {
                  setEmail((e.target.value as string) ?? '');
                }}
                placeholder='Email address'
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant={'contained'}
                className={clsx(common.actionButton, classes.button)}
                onClick={doLogin}
                disabled={loading || emailInvalid}>
                {loading ? 'Sending...' : title}
              </Button>
            </Grid>
            <Grid item xs={12}>
              {outcome === LoginOutcome.Failure && (
                <>
                  <Typography variant={'h6'}>Invalid Email</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </form>
      )}
      {successful && (
        <>
          {outcome === LoginOutcome.Validating && (
            <div style={{ textAlign: 'center' }}>
              <Typography variant={'h6'}>Email Sent to {email}</Typography>
              <Typography variant={'subtitle2'}>
                Please check your spam folder for an email from
                no-reply@furballs.com
              </Typography>

              <form onSubmit={onLoginForm}>
                <Grid
                  container
                  style={{ marginTop: 20, marginBottom: 0 }}
                  spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <TextField
                      style={{ width: '100%' }}
                      value={code}
                      onChange={(e) => {
                        setCode((e.target.value as string) ?? '');
                      }}
                      placeholder='Code'
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Button
                      variant={'contained'}
                      className={clsx(common.actionButton, classes.button)}
                      onClick={doLogin}
                      disabled={loading}>
                      {loading ? 'Loading...' : title}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
          {outcome === LoginOutcome.Success && (
            <>
              <Typography variant={'h6'}>
                Redirecting to your Furballs...
              </Typography>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default LoginEmailForm;
