import * as React from 'react';

interface ISpinProps {
  svg?: React.SVGProps<SVGSVGElement>;
  children?: React.ReactNode;
}

function SvgVoyageSpinWizardsPath(props: ISpinProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='435.26 118.36 803.32 549.53'
      {...(props.svg ?? {})}>
      <ellipse fill='#D5E5DF' cx={841} cy={654.61} rx={171.56} ry={13.28} />
      <g fill='#EBCA8F' stroke='#C99361' strokeWidth={6} strokeMiterlimit={10}>
        <path d='M800.39 565.58l1.89 12.43a155.59 155.59 0 01-7.76 77h0-71.47s-9.3-22.26 17.62-31.81c0 0 17.44-115.32-28.49-141.02l88.21 83.4z' />
        <path strokeLinecap='round' d='M774.71 627.82s-9.73-7.95-34.05-4.62' />
      </g>
      <g fill='#EBCA8F' stroke='#C99361' strokeWidth={6} strokeMiterlimit={10}>
        <path d='M871.18 565.58l-1.89 12.43a155.59 155.59 0 007.76 77h71.47s9.3-22.26-17.62-31.81c0 0-17.44-115.32 28.49-141.02l-88.21 83.4z' />
        <path strokeLinecap='round' d='M930.9 623.21s-17.27-6.28-31.38 4.62' />
      </g>
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M627.21 226.78l30.76-15.04c-13.38 7.91-33.41 23.47-47.13 50.97l31.08-17.58 195.19 112.03V157.2c.01 0-134.27-48.3-209.9 69.58z'
      />
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M641.92 245.13s-47.75 33.65-50.1 113.42c-2.35 79.77-23.46 84.15-23.46 84.15s34.57 6.64 47.33-36.24c0 0-14.11 57.78 13.14 93.98L640.67 472l196.45-112.75v-2.07l-195.2-112.05z'
      />
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M837.12 359.24L640.67 472c34.93 56.03 29.59 81.56 29.59 81.56 28.8-6.86 17.99-52.38 17.99-52.38S729.51 569 837.12 605.59V359.24z'
      />
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M1047.22 226.78l-30.76-15.04c13.38 7.91 33.41 23.47 47.13 50.97l-31.08-17.58-195.2 112.04V157.2s134.28-48.3 209.91 69.58z'
      />
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M1032.5 245.13s47.75 33.65 50.1 113.42c2.35 79.77 23.46 84.15 23.46 84.15s-34.57 6.64-47.33-36.24c0 0 14.11 57.78-13.14 93.98L1033.76 472 837.31 359.24v-2.07l195.19-112.04z'
      />
      <path
        fill='#FFBD7E'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M837.31 359.24L1033.76 472c-34.93 56.03-29.59 81.56-29.59 81.56-28.8-6.86-17.99-52.38-17.99-52.38S944.92 569 837.31 605.59V359.24z'
      />
      {props.children}
      <g>
        <path
          fill='#FD9401'
          stroke='#000'
          strokeWidth={4}
          strokeMiterlimit={10}
          d='M838.92 358.2l-1.8-1.03v2.07z'
        />
        <path
          fill='#FDE0AD'
          d='M927.57 365.22h13.5c0-44.02-33.14-79.02-83.86-85.3-4.81-.6-14.86 3.96-20.43 4.47h-2.05c-6.38-.51-17.19-4.94-22.73-4.14-49.5 7.14-81.38 41.67-81.38 84.97h13.5c-8.58 12.7-13.5 27.35-13.5 42.96h12.31c7.99 33.48 45.32 41.16 92.91 41.16 47.59 0 84.93-7.68 92.91-41.16h12.31c.01-15.61-4.91-30.26-13.49-42.96z'
        />
        <path
          fill='#FDCF7B'
          d='M771.2 309.15c-20.43 4.24-40.58 12.77-40.58 56.07h13.5c-8.58 12.7-13.5 27.35-13.5 42.96h12.31c4.98 20.89 21.39 34.21 44.58 37.06-.09-.03-38.4-12.85-34.4-79.37 2.08-34.41 18.09-56.72 18.09-56.72zM900.48 309.15c20.43 4.24 40.58 12.77 40.58 56.07h-13.5c8.58 12.7 13.5 27.35 13.5 42.96h-12.31c-4.98 20.89-21.39 34.21-44.58 37.06.09-.03 38.4-12.85 34.4-79.37-2.07-34.41-18.09-56.72-18.09-56.72z'
        />
        <path
          fill='#EFCD90'
          d='M837.72 441.28c-.59-.02-1.22-.02-1.88 0h1.88z'
        />
        <path
          fill='#FDE0AD'
          d='M792.94 293.41c-3.24-11.73-13.06-21.09-25.87-23.09-17.43-2.73-33.78 9.2-36.5 26.63-2.29 14.66 9.43 38.14 27.34 41.6 15.27 4.07 15-41.43 35.03-45.14z'
        />
        <path
          fill='#F4A19F'
          d='M762.93 317.85c-7.24-3.63-13.38-11.61-13.76-19.63-.15-3.11.41-6.08 1.49-8.79-4.38 3.71-7.13 9.26-7.06 15.44.09 7.63 5.64 15.43 12.4 19.13 2.15 1.18 4.83.13 5.63-2.18.39-1.13.89-2.45 1.52-3.9-.08-.02-.15-.03-.22-.07z'
        />
        <path
          fill='#F8ABAA'
          d='M779.58 297.44c1.15-.73 1.53-2.26.8-3.41-3.57-5.66-9.9-9.39-17.09-9.31-11.01.12-19.83 9.15-19.7 20.15.09 7.63 5.64 15.43 12.4 19.13 2.15 1.18 4.83.13 5.63-2.18 2.11-6.04 7.31-17.6 17.96-24.38z'
        />
        <path
          fill='#FDE0AD'
          d='M881.29 293.41c3.24-11.73 13.06-21.09 25.87-23.09 17.43-2.73 33.78 9.2 36.5 26.63 2.29 14.66-9.43 38.14-27.34 41.6-15.27 4.07-15-41.43-35.03-45.14z'
        />
        <path
          fill='none'
          stroke='#C99361'
          strokeWidth={6}
          strokeMiterlimit={10}
          d='M932.58 328.71c8.16-9.06 12.57-22.26 11.08-31.76-2.73-17.43-19.07-29.36-36.5-26.63-10.16 1.59-18.43 7.81-23.03 16.17-8.27-3.14-17.27-5.38-26.92-6.57-4.81-.6-14.86 3.96-20.43 4.47h-2.05c-6.38-.51-17.19-4.94-22.73-4.14-7.92 1.14-15.38 2.99-22.34 5.46-4.68-7.97-12.75-13.85-22.59-15.39-17.43-2.73-33.78 9.2-36.5 26.63-1.38 8.86 2.35 20.93 9.47 29.87-6.11 11.49-9.42 24.49-9.42 38.41h13.5c-8.58 12.7-13.5 27.35-13.5 42.96h12.31c7.99 33.48 45.32 41.16 92.91 41.16 47.59 0 84.93-7.68 92.91-41.16h12.31c0-15.61-4.92-30.26-13.5-42.96h13.5c.01-13.17-2.97-25.5-8.48-36.52z'
        />
        <path
          fill='#F8ABAA'
          d='M894.65 297.44c-1.15-.73-1.53-2.26-.8-3.41 3.57-5.66 9.9-9.39 17.09-9.31 11.01.12 19.83 9.15 19.7 20.15-.09 7.63-5.64 15.43-12.4 19.13-2.15 1.18-4.83.13-5.63-2.18-2.1-6.04-7.31-17.6-17.96-24.38zM779.58 297.44c1.15-.73 1.53-2.26.8-3.41-3.57-5.66-9.9-9.39-17.09-9.31-11.01.12-19.83 9.15-19.7 20.15.09 7.63 5.64 15.43 12.4 19.13 2.15 1.18 4.83.13 5.63-2.18 2.11-6.04 7.31-17.6 17.96-24.38z'
        />
        <path
          fill='#F8ABAA'
          d='M894.65 297.44c-1.15-.73-1.53-2.26-.8-3.41 3.57-5.66 9.9-9.39 17.09-9.31 11.01.12 19.83 9.15 19.7 20.15-.09 7.63-5.64 15.43-12.4 19.13-2.15 1.18-4.83.13-5.63-2.18-2.1-6.04-7.31-17.6-17.96-24.38z'
        />
        <path
          fill='#F4A19F'
          d='M911.31 317.85c7.24-3.63 13.38-11.61 13.76-19.63.15-3.11-.41-6.08-1.49-8.79 4.38 3.71 7.13 9.26 7.06 15.44-.09 7.63-5.64 15.43-12.4 19.13-2.15 1.18-4.83.13-5.63-2.18-.39-1.13-.89-2.45-1.52-3.9.07-.02.15-.03.22-.07z'
        />
        <path
          fill='#C99361'
          d='M892.15 292.96c8.15-12.49 26.84-13.02 35.34-.72 2.64 3.67 3.88 8.22 3.76 12.64-.01.34-.3.61-.64.6a.635.635 0 01-.61-.62c-.11-4.17-1.49-8.28-4.07-11.48-4.89-6.48-14.26-9.02-21.69-5.84-3.66 1.49-6.74 4.21-8.72 7.54-1.42 2.3-4.81.18-3.37-2.12z'
        />
        <g>
          <ellipse
            transform='rotate(-35.706 805.134 353.544)'
            fill='#391615'
            cx={805.09}
            cy={353.53}
            rx={13.89}
            ry={13.9}
          />
          <circle
            transform='rotate(-35.706 797.244 357.101)'
            fill='#FFF'
            cx={797.2}
            cy={357.09}
            r={2.8}
          />
          <ellipse
            transform='rotate(-35.706 866.648 353.544)'
            fill='#391615'
            cx={866.6}
            cy={353.53}
            rx={13.89}
            ry={13.9}
          />
          <circle
            transform='rotate(-35.706 858.758 357.1)'
            fill='#FFF'
            cx={858.71}
            cy={357.09}
            r={2.8}
          />
          <path
            fill='#FFF'
            d='M864.08 396.57c0 14.54-12.48 35.58-27.88 35.58-15.4 0-27.88-21.04-27.88-35.58s11.38-20.96 26.78-20.96 28.98 6.42 28.98 20.96z'
          />
          <g>
            <path d='M849.95 382.93l-13.75 5.24-13.75-5.24a4.265 4.265 0 01-2.75-3.99c0-2.05 1.46-3.82 3.47-4.2l2.26-.43a57.37 57.37 0 0121.54 0l2.26.43c2.02.38 3.47 2.15 3.47 4.2 0 1.77-1.09 3.36-2.75 3.99zM842.44 403.05h7.82l-4.74 8.62c-1.27 2.66-4.79 4.45-8.74 4.45-3.96 0-7.48-1.79-8.74-4.45l-4.74-8.62h7.25l5.73-6.25 6.16 6.25z' />
            <path
              fill='#FFC6DA'
              d='M845.66 411.41c-.76-2.32-4.67-4.08-9.4-4.08-4.06 0-7.52 1.31-8.9 3.15l.66 1.2c1.27 2.66 4.79 4.45 8.74 4.45 3.96 0 7.48-1.79 8.74-4.45l.16-.27z'
            />
            <g
              fill='none'
              stroke='#000'
              strokeWidth={3}
              strokeLinecap='round'
              strokeMiterlimit={10}>
              <path d='M809.85 392.57c0 6.39 5.9 11.58 13.17 11.58s13.17-5.18 13.17-11.58v-8.96M862.54 392.57c0 6.39-5.9 11.58-13.17 11.58s-13.17-5.18-13.17-11.58v-8.96' />
            </g>
          </g>
        </g>
        <g>
          <path
            fill='#C99361'
            d='M778.7 295.1c-5.1-8.93-17.42-11.62-25.95-5.85-5.2 3.41-8.44 9.36-8.55 15.62a.6.6 0 01-.62.6.612.612 0 01-.59-.59c-.12-4.42 1.12-8.96 3.76-12.63 5.22-7.68 15.65-10.97 24.4-7.79 4.41 1.53 8.3 4.56 10.93 8.5 1.45 2.28-1.95 4.43-3.38 2.14z'
          />
        </g>
      </g>
      <g>
        <path
          fill='#FFF'
          stroke='#000'
          strokeWidth={3}
          strokeMiterlimit={10}
          d='M1102.43 593.53H991.58c-9.35 0-16.93-7.58-16.93-16.93h0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93h0c.01 9.35-7.57 16.93-16.93 16.93z'
        />
        <g fill='#0F1430'>
          <path d='M1002.78 582.14v2.25h-7.6v-14.02h2.58v12.17l-.38-.4h5.4zM1012.65 584.39h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM1021.27 584.59h-2.76c-1.16 0-2.03-.25-2.59-.76-.57-.51-.85-1.28-.85-2.33v-8.24c0-1.06.28-1.84.85-2.34.57-.5 1.43-.75 2.59-.75h5.94v2.34h-6.47c-.18 0-.26.09-.26.27v9.21c0 .18.09.27.26.27h3.97c.18 0 .26-.09.26-.27v-3.49l.27.42h-2.23v-2.19h4.46v4.77c0 1.04-.28 1.82-.85 2.33s-1.43.76-2.59.76zM1035.97 584.39h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM1046.46 580.53v-10.16h2.25v14.02h-1.83l-6.65-10.09.44-.09v10.18h-2.23v-14.02h1.81l6.62 10.07-.41.09zM1052 584.39v-14.02l6.18-.11c1.21-.03 2.1.22 2.69.75s.88 1.36.88 2.5v7.75c0 1.13-.3 1.96-.89 2.48-.6.52-1.51.78-2.73.76l-6.13-.11zm6.82-11.88h-4.24v9.74h4.24c.21 0 .31-.1.31-.31v-9.12c0-.2-.1-.31-.31-.31zM1075.3 584.39h-2.58l-.77-3.6.26.22h-5.54l.26-.22-.88 3.6h-2.43l3.09-11.7c.18-.63.36-1.13.55-1.48.19-.35.46-.6.82-.74s.84-.2 1.46-.19l2.61.09 3.15 14.02zm-6.39-11.52l-1.55 6.14-.18-.22h4.55l-.18.22-1.41-6.4h-.97c-.09 0-.15.02-.18.05-.02.03-.05.1-.08.21zM1080.09 584.39h-2.61v-14.02l5.94-.09c1.18-.03 2.07.24 2.68.79.61.56.92 1.37.92 2.43v2.23c0 .85-.23 1.57-.7 2.14s-1.14.93-2.04 1.08v-.51c.46.09.82.26 1.08.53.27.27.49.67.68 1.21l1.48 4.2h-2.61l-1.68-5.01a.828.828 0 00-.12-.25c-.04-.05-.12-.08-.25-.08h-3.2l.42-.42v5.77zm0-12.3v5.12l-.38-.4h4.35c.21 0 .31-.1.31-.29v-3.71c0-.19-.1-.29-.31-.29h-4.35l.38-.43zM1094.66 577l2.43-6.62h2.61l-2.65 6.93c-.22.6-.43 1.06-.64 1.38s-.49.53-.84.64c-.35.11-.87.17-1.54.17h-.2c-.66 0-1.19-.07-1.57-.2-.38-.13-.68-.36-.91-.68-.22-.32-.44-.77-.66-1.33l-2.58-6.91h2.74l2.43 6.62c.04.16.15.24.31.24h.77c.14 0 .24-.08.3-.24zm.51 7.39h-2.61v-6.49h2.61v6.49z' />
        </g>
        <path
          fill='#FFF'
          stroke='#000'
          strokeWidth={3}
          strokeMiterlimit={10}
          d='M564.54 367.7H453.69c-9.35 0-16.93-7.58-16.93-16.93v0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93v0c0 9.34-7.58 16.93-16.93 16.93z'
        />
        <g fill='#0F1430'>
          <path d='M495.42 358.56h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM505.65 356.31v2.25h-7.6v-14.02h2.58v12.17l-.38-.4h5.4zM510.33 358.56h-2.61v-14.02h2.61v14.02zM518.1 358.56h-2.58v-12.14l.38.38h-3.71v-2.25h9.25v2.25h-3.71l.38-.38v12.14zM531.44 358.56h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28z' />
        </g>
        <path
          fill='#FFF'
          stroke='#000'
          strokeWidth={3}
          strokeMiterlimit={10}
          d='M684.78 153.72H573.93c-9.35 0-16.93-7.58-16.93-16.93v0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93v0c0 9.35-7.58 16.93-16.93 16.93z'
        />
        <g fill='#0F1430'>
          <path d='M585.12 142.33v2.25h-7.6v-14.02h2.58v12.17l-.38-.4h5.4zM594.99 144.58h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM603.61 144.78h-2.76c-1.16 0-2.03-.25-2.59-.76-.57-.51-.85-1.28-.85-2.33v-8.24c0-1.06.28-1.84.85-2.34.57-.5 1.43-.75 2.59-.75h5.94v2.34h-6.47c-.18 0-.26.09-.26.27v9.21c0 .18.09.27.26.27h3.97c.18 0 .26-.09.26-.27v-3.49l.27.42h-2.23v-2.19h4.46v4.77c0 1.05-.28 1.82-.85 2.33s-1.43.76-2.59.76zM618.31 144.58h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM628.8 140.72v-10.16h2.25v14.02h-1.83l-6.65-10.09.44-.09v10.18h-2.23v-14.02h1.81l6.62 10.07-.41.09zM634.34 144.58v-14.02l6.18-.11c1.21-.03 2.1.22 2.69.75s.88 1.36.88 2.5v7.75c0 1.13-.3 1.96-.89 2.48-.6.52-1.51.78-2.73.76l-6.13-.11zm6.82-11.88h-4.24v9.74h4.24c.21 0 .31-.1.31-.31v-9.12c0-.2-.1-.31-.31-.31zM657.64 144.58h-2.58l-.77-3.6.26.22H649l.26-.22-.88 3.6h-2.43l3.09-11.7c.18-.63.36-1.13.55-1.48.19-.35.46-.6.82-.74.35-.14.84-.2 1.46-.19l2.61.09 3.16 14.02zm-6.38-11.52l-1.55 6.14-.18-.22h4.55l-.18.22-1.41-6.4h-.97c-.09 0-.15.02-.18.06-.03.02-.06.09-.08.2zM662.43 144.58h-2.61v-14.02l5.94-.09c1.18-.03 2.07.24 2.68.79.61.56.92 1.37.92 2.43v2.23c0 .85-.23 1.57-.7 2.14-.46.57-1.14.94-2.04 1.08v-.51c.46.09.82.27 1.08.53.27.26.49.67.68 1.21l1.48 4.2h-2.61l-1.68-5.01a.828.828 0 00-.12-.25c-.04-.05-.12-.08-.25-.08H662l.42-.42v5.77zm0-12.3v5.12l-.38-.4h4.35c.21 0 .31-.1.31-.29V133c0-.19-.1-.29-.31-.29h-4.35l.38-.43zM677 137.19l2.43-6.62h2.61l-2.65 6.93c-.22.6-.43 1.06-.64 1.38-.21.32-.49.53-.84.64-.35.11-.87.17-1.54.17h-.2c-.66 0-1.19-.07-1.57-.2-.38-.13-.68-.36-.91-.68s-.44-.77-.66-1.32l-2.58-6.91h2.74l2.43 6.62c.04.16.15.24.31.24h.77c.14-.01.24-.09.3-.25zm.51 7.39h-2.61v-6.49h2.61v6.49z' />
        </g>
        <g>
          <path
            fill='#FFF'
            stroke='#000'
            strokeWidth={3}
            strokeMiterlimit={10}
            d='M1108.07 153.72H997.22c-9.35 0-16.93-7.58-16.93-16.93v0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93v0c.01 9.35-7.57 16.93-16.93 16.93z'
          />
          <g fill='#0F1430'>
            <path d='M1038.96 144.58h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28zM1049.18 142.33v2.25h-7.6v-14.02h2.58v12.17l-.38-.4h5.4zM1053.86 144.58h-2.61v-14.02h2.61v14.02zM1061.64 144.58h-2.58v-12.14l.38.38h-3.71v-2.25h9.25v2.25h-3.71l.38-.38v12.14zM1074.97 144.58h-8.26v-14.02h8.15v2.25h-5.94l.38-.38v4.28l-.38-.38h5.52v2.23h-5.52l.38-.38v4.5l-.38-.38h6.05v2.28z' />
          </g>
        </g>
        <g>
          <path
            fill='#FFF'
            stroke='#000'
            strokeWidth={3}
            strokeMiterlimit={10}
            d='M700.89 593.53H590.04c-9.35 0-16.93-7.58-16.93-16.93h0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93h0c.01 9.35-7.57 16.93-16.93 16.93z'
          />
          <g fill='#0F1430'>
            <path d='M623.72 570.37c.59 0 1.01.12 1.26.38.25.25.38.66.38 1.21v12.43h-2.5v-10.86h.44l-3.45 6.67h-1.54l-3.25-6.45h.4v10.64h-2.3v-14.02h1.41c.43 0 .73.07.92.2.18.13.36.36.52.68l3.55 6.73h-.57l3.95-7.62h.78zM633.37 577l2.43-6.62h2.61l-2.65 6.93c-.22.6-.43 1.06-.64 1.38s-.49.53-.84.64c-.35.11-.87.17-1.54.17h-.2c-.66 0-1.19-.07-1.57-.2-.38-.13-.68-.36-.91-.68-.22-.32-.44-.77-.66-1.33l-2.58-6.91h2.74l2.43 6.62c.04.16.15.24.31.24h.77c.14 0 .24-.08.3-.24zm.51 7.39h-2.61v-6.49h2.61v6.49zM645.07 584.39h-2.58v-12.14l.38.38h-3.71v-2.25h9.25v2.25h-3.71l.38-.38v12.14zM657.9 576.53v-6.16h2.58v14.02h-2.58v-6.38l.38.4h-5.76l.38-.4v6.38h-2.58v-14.02h2.58v6.16l-.38-.4h5.76l-.38.4zM666.78 584.39h-2.61v-14.02h2.61v14.02zM678.32 582.05v2.34h-4.66c-1.16 0-2.03-.25-2.59-.75-.57-.5-.85-1.27-.85-2.32v-7.88c0-1.04.28-1.82.85-2.32.57-.5 1.43-.75 2.59-.75h4.66v2.34h-5.21c-.18 0-.27.09-.27.26v8.81c0 .18.09.26.27.26h5.21z' />
          </g>
        </g>
        <g>
          <path
            fill='#FFF'
            stroke='#000'
            strokeWidth={3}
            strokeMiterlimit={10}
            d='M1220.15 367.7H1109.3c-9.35 0-16.93-7.58-16.93-16.93v0c0-9.35 7.58-16.93 16.93-16.93h110.85c9.35 0 16.93 7.58 16.93 16.93v0c0 9.34-7.58 16.93-16.93 16.93z'
          />
          <g fill='#0F1430'>
            <path d='M1142.97 344.54c.59 0 1.01.13 1.26.38s.38.66.38 1.21v12.43h-2.5V347.7h.44l-3.45 6.67h-1.54l-3.25-6.45h.4v10.64h-2.3v-14.02h1.41c.43 0 .73.07.92.2.18.13.36.36.52.68l3.55 6.73h-.57l3.95-7.62h.78zM1152.62 351.16l2.43-6.62h2.61l-2.65 6.93c-.22.6-.43 1.06-.64 1.38-.21.32-.49.53-.84.64-.35.11-.87.17-1.54.17h-.2c-.66 0-1.19-.07-1.57-.2-.38-.13-.68-.36-.91-.68s-.44-.77-.66-1.32l-2.58-6.91h2.74l2.43 6.62c.04.16.15.24.31.24h.77c.14-.01.24-.09.3-.25zm.51 7.4h-2.61v-6.49h2.61v6.49zM1164.32 358.56h-2.58v-12.14l.38.38h-3.71v-2.25h9.25v2.25h-3.71l.38-.38v12.14zM1177.15 350.7v-6.16h2.58v14.02h-2.58v-6.38l.38.4h-5.76l.38-.4v6.38h-2.58v-14.02h2.58v6.16l-.38-.4h5.76l-.38.4zM1186.03 358.56h-2.61v-14.02h2.61v14.02zM1197.58 356.22v2.34h-4.66c-1.16 0-2.03-.25-2.59-.75-.57-.5-.85-1.27-.85-2.32v-7.88c0-1.04.28-1.82.85-2.32s1.43-.75 2.59-.75h4.66v2.34h-5.21c-.18 0-.27.09-.27.27v8.81c0 .18.09.27.27.27h5.21z' />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgVoyageSpinWizardsPath;
