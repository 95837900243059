import { IEquipment, IFurballBase } from '../../wallet/WalletTypes';
import { useWalletSelector } from '../../hooks';
import { useInterval, usePlayersReadyFurballs } from '../../wallet';
import { IFurballReward } from '../Rewards';
import {
  getAllTimekeeperInteractions,
  useCurrentTimekeeperRequest,
} from './TimekeeperHooks';
import { StatEffectTarget, TimekeeperInteractionType } from '../schema';
import { ITimekeeperInteraction } from './TimekeeperSlice';
import _ from 'lodash';
import { getSnack } from '../Food';
import { getFurballIntervals } from './TimekeeperReq';

export interface ITimeRewardInput {
  // liveAt: number;
  interval: number;
  teamSize: number;
  snackHappiness: number;
  snackInteractions: ITimekeeperInteraction[];
}

export interface IFurballWithReward {
  furball: IFurballBase;
  reward: IFurballReward;
}

export interface IEstimatedRewards {
  battling: number;
  exploring: number;
  furEarned: number;
  furballsWithRewards: IFurballWithReward[];
}

// Safety measure: reduce reward estimate by 5 minutes so that rewards are not overshot
const reduceInterval = 5 / 60;

export function expToLevel(exp: number) {
  return Math.floor(
    Math.min(200, Math.sqrt(exp < 100 ? 0 : (exp + exp - 100) / 100)),
  );
}

export function computeExpFurEquipmentProduction(
  equipment: IEquipment,
  expRate: number,
  furRate: number,
  intervals: number,
): [number, number] {
  // const sec = equipment.equippedAt
  //   ? new Date(equipment.equippedAt).getTime()
  //   : 0;
  // if (sec > since) since = sec;

  // const interval = 3600000;
  // const intervals = (new Date().getTime() - since) / interval;

  const exp = _.sum(
    equipment.passives
      .filter((e) => e.stat === StatEffectTarget.Exp)
      .map((e) => expRate * (e.value / 100.0) * intervals),
  );
  const fur = _.sum(
    equipment.passives
      .filter((e) => e.stat === StatEffectTarget.Fur)
      .map((e) => furRate * (e.value / 100.0) * intervals),
  );
  return [exp, fur];
}

function computeAllExpFurEquipmentProduction(
  fb: IFurballBase,
  intervals: number,
): [number, number] {
  let exp = 0,
    fur = 0;
  fb.equipment.map((e) => {
    const [a, b] = computeExpFurEquipmentProduction(
      e,
      fb.state.expPercentBase * 5,
      fb.state.furPercentBase,
      // fb.state.last,
      intervals,
    );
    exp += a;
    fur += b;
  });
  return [Math.floor(exp), Math.floor(fur)];
}

export function estimateFurballRewards(
  furballs: IFurballBase[],
  timeReward: ITimeRewardInput,
  baseRate?: boolean,
): IEstimatedRewards {
  const totals: IEstimatedRewards = {
    battling: 0,
    exploring: 0,
    furEarned: 0,
    furballsWithRewards: [],
  };

  const rewardBase: IFurballReward = {
    furGain: 0,
    experienceGain: 0,
    levelGain: 0,
  };

  furballs.map((fb, i) => {
    const snacks = timeReward.snackInteractions
      .filter((i) => i.furballIds.includes(fb.tokenId))
      .map((s) => getSnack(s.expectedValue ?? 0));

    const intervals = getFurballIntervals(
      fb,
      timeReward.interval,
      reduceInterval,
    );
    const [extraExp, extraFur] = computeAllExpFurEquipmentProduction(
      fb,
      intervals,
    );
    if (fb.zone < 0x10000) {
      const snackHappiness =
        _.sum(snacks.map((s) => s.happiness)) + timeReward.snackHappiness;
      const expRate =
        (fb.state.expPercentBase + fb.state.happiness + snackHappiness) * 5;

      const expGain =
        Math.floor(expRate * intervals) + fb.pendingExp + extraExp;
      const oldLevel = expToLevel(fb.state.totalExp);
      const newLevel = expToLevel(fb.state.totalExp + expGain);
      const levelGain = newLevel - oldLevel;

      totals.exploring++;
      totals.furballsWithRewards.push({
        furball: fb,
        reward: { ...rewardBase, experienceGain: expGain, levelGain },
      });
    } else {
      const furAmount =
        Math.floor(
          intervals * (baseRate ? fb.state.furRateBase : fb.state.furRate),
        ) +
        fb.pendingFur +
        extraFur;

      totals.battling++;
      totals.furEarned += furAmount;
      totals.furballsWithRewards.push({
        furball: fb,
        reward: { ...rewardBase, furGain: furAmount },
      });
    }
  });

  return totals;
}

export function useTimeRewardInput(): ITimeRewardInput {
  const tkState = useCurrentTimekeeperRequest();
  const snackInteractions = getAllTimekeeperInteractions(
    tkState.current,
  ).filter((i) => i.interactionType === TimekeeperInteractionType.FeedSnack);
  return {
    // liveAt: useWalletSelector((s) => s.edition?.liveAt ?? 0),
    interval: useInterval(),
    teamSize: useWalletSelector((s) => s.contents?.furballBalance) ?? 0,
    snackInteractions,
    snackHappiness: 0,
  };
}

export function useEstimatedFurballRewards(
  furballs?: IFurballBase[],
  baseRate?: boolean,
): IEstimatedRewards {
  const playerFurballs = usePlayersReadyFurballs();
  return estimateFurballRewards(
    furballs ?? playerFurballs,
    useTimeRewardInput(),
    baseRate,
  );
}

export function useFurRemaining(): number {
  const tkState = useCurrentTimekeeperRequest();
  const furBalance = useWalletSelector((s) => s.contents?.furBalance ?? 0);
  const furSpent = _.sumBy(tkState.current, (c) => c.changeSet?.spent ?? 0);
  return furBalance - furSpent;
}
