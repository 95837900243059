import { Grid, GridSize, Link, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';

type InfoCard = {
  image: JSX.Element;
  title?: string;
  subtitle?: string | React.ReactNode;
  subtitleComponent?: JSX.Element;
  href?: string;
};

type InfoCardListProps = {
  infoCards: InfoCard[];
  columns?: number;
  subtitleWidth?: number | string;
  gridSizing?: { [size: string]: number };
};

const useStyles = makeStyles((theme: Theme) => ({
  title: { margin: '15px 0 !important' },
}));

const InfoCardList: FunctionComponent<InfoCardListProps> = (props) => {
  const classes = useStyles();
  const { columns, infoCards, subtitleWidth, gridSizing } = props;
  let gridProps = {};
  if (gridSizing) {
    gridProps = gridSizing;
  }
  let lg = 3;
  if (columns) {
    lg = 12 / columns;
  }
  const subtitleMaxWidth = subtitleWidth ?? '20vw !important';

  const children = infoCards.map((card, index) => {
    const titleComponent = card.href ? (
      <Link
        href={card.href ?? '#'}
        target={'_blank'}
        variant={'body1'}
        style={{
          color: 'black',
          textDecoration: 'none',
        }}>
        <Typography variant={'h3'} className={classes.title}>
          {card.title}
        </Typography>
      </Link>
    ) : (
      <Typography variant={'h3'} className={classes.title}>
        {card.title}
      </Typography>
    );
    return (
      <Grid
        item
        xs={12}
        md={12}
        lg={lg as GridSize}
        sx={{ textAlign: 'center' }}
        key={index}
        {...gridProps}>
        {card.image}
        {titleComponent}
        <div
          style={{
            maxWidth: subtitleMaxWidth,
            margin: 'auto',
          }}>
          {card.subtitleComponent && card.subtitleComponent}
          {card.subtitle && (
            <Typography variant={'body1'}>{card.subtitle}</Typography>
          )}
        </div>
      </Grid>
    );
  });

  return (
    <Grid container spacing={4}>
      {children}
    </Grid>
  );
};

export default InfoCardList;
