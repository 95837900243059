import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Googley = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='m210.4 119 11.18-3.62-9.35 7.13c-.88.67-2.14.5-2.82-.38s-.5-2.14.38-2.82c.19-.13.4-.24.61-.31zM183.23 126.15c-.96-1.2-2.1-2.43-3.26-3.56-1.17-1.14-2.4-2.21-3.72-3.17-1.32-.96-2.72-1.8-4.23-2.38-1.51-.56-3.18-.85-4.83-.43 1.53-.74 3.37-.81 5.08-.53 1.73.3 3.39.89 4.98 1.62 1.59.73 3.1 1.6 4.57 2.55 1.47.96 2.85 1.95 4.26 3.13.84.71.95 1.96.24 2.8-.71.84-1.96.95-2.8.24-.1-.08-.19-.18-.27-.27h-.02z' />
    <ellipse opacity={0.2} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <linearGradient
      id='Googley__a'
      gradientUnits='userSpaceOnUse'
      x1={239}
      y1={158}
      x2={239}
      y2={126}>
      <stop
        offset={0.128}
        style={{
          stopColor: '#ffd0e4',
        }}
      />
      <stop
        offset={0.26}
        style={{
          stopColor: '#ffd0e4',
        }}
      />
      <stop
        offset={0.385}
        style={{
          stopColor: '#f6d5e8',
        }}
      />
      <stop
        offset={0.587}
        style={{
          stopColor: '#dee1f2',
        }}
      />
      <stop
        offset={0.766}
        style={{
          stopColor: '#c3effd',
        }}
      />
    </linearGradient>
    <circle fill='url(#Googley__a)' cx={239} cy={142} r={15} />
    <circle cx={239} cy={142} r={11} />
    <path
      fill='#FFF'
      d='M249.48 127.44c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09-1.01-3.57-2.85-6.8-5.29-9.46z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <ellipse opacity={0.2} cx={158} cy={150} rx={21} ry={22} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <linearGradient
      id='Googley__b'
      gradientUnits='userSpaceOnUse'
      x1={154}
      y1={158}
      x2={154}
      y2={126}>
      <stop
        offset={0.128}
        style={{
          stopColor: '#ffd0e4',
        }}
      />
      <stop
        offset={0.26}
        style={{
          stopColor: '#ffd0e4',
        }}
      />
      <stop
        offset={0.385}
        style={{
          stopColor: '#f6d5e8',
        }}
      />
      <stop
        offset={0.587}
        style={{
          stopColor: '#dee1f2',
        }}
      />
      <stop
        offset={0.766}
        style={{
          stopColor: '#c3effd',
        }}
      />
    </linearGradient>
    <circle fill='url(#Googley__b)' cx={154} cy={142} r={15} />
    <circle cx={154} cy={142} r={11} />
    <path
      fill='#FFF'
      d='M164.62 127.44c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09a23.34 23.34 0 0 0-5.29-9.46z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
  </svg>
);

export default Googley;
