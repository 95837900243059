import { SvgEditionSlot } from '../part';
import * as React from 'react';

const WorryGreenPurple = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.2} cx={234} cy={153} rx={19} ry={20} />
    <ellipse opacity={0.2} cx={158} cy={151} rx={21} ry={22} />
    <path
      fill='#FFF'
      d='M174.77 125.31c-1.7-1.19-3.98-1.12-5.65.12-3.85 2.86-8.35 5.09-13.34 6.45a40.262 40.262 0 0 1-11.66 1.42 4.846 4.846 0 0 0-4.65 3.16c-.88 2.43-1.38 5.04-1.4 7.77-.12 12.92 10.39 23.54 23.31 23.56 12.91.02 23.38-10.44 23.38-23.34 0-7.92-3.95-14.92-9.99-19.14z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M174.04 121.71c-5.96 4.43-12.51 12.13-37.13 11.59'
    />
    <linearGradient
      id='WorryGreenPurple__a'
      gradientUnits='userSpaceOnUse'
      x1={167}
      y1={167}
      x2={167}
      y2={135}>
      <stop
        offset={0.147}
        style={{
          stopColor: '#784ce0',
        }}
      />
      <stop
        offset={0.242}
        style={{
          stopColor: '#7564d8',
        }}
      />
      <stop
        offset={0.504}
        style={{
          stopColor: '#6da0c3',
        }}
      />
      <stop
        offset={0.727}
        style={{
          stopColor: '#68cdb3',
        }}
      />
      <stop
        offset={0.899}
        style={{
          stopColor: '#64e8aa',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#63f2a6',
        }}
      />
    </linearGradient>
    <path
      fill='url(#WorryGreenPurple__a)'
      d='M184.48 148.03c-2.17-7.02-8.72-12.13-16.46-12.13-9.51 0-17.22 7.71-17.22 17.22 0 6.1 3.17 11.46 7.96 14.52.86.1 1.73.15 2.61.15 11.7.03 21.39-8.55 23.11-19.76z'
    />
    <circle cx={168} cy={153} r={12} />
    <path
      fill='#FFF'
      d='m168.74 135.45-.09.06a6.085 6.085 0 0 0-.92 8.56 6.085 6.085 0 0 0 8.56.92c1.74-1.4 2.5-3.56 2.2-5.62-2.93-2.08-6.28-3.4-9.75-3.92z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M174.77 125.31c-1.7-1.19-3.98-1.12-5.65.12-3.85 2.86-8.35 5.09-13.34 6.45a40.262 40.262 0 0 1-11.66 1.42 4.846 4.846 0 0 0-4.65 3.16c-.88 2.43-1.38 5.04-1.4 7.77-.12 12.92 10.39 23.54 23.31 23.56 12.91.02 23.38-10.44 23.38-23.34 0-7.92-3.95-14.92-9.99-19.14z'
    />
    <path
      fill='#FFF'
      d='M219.09 125.31c1.7-1.19 3.98-1.12 5.65.12 3.85 2.86 8.35 5.09 13.34 6.45 3.92 1.07 7.85 1.52 11.66 1.42a4.846 4.846 0 0 1 4.65 3.16c.88 2.43 1.38 5.04 1.4 7.77.12 12.92-10.39 23.54-23.31 23.56-12.91.02-23.38-10.44-23.38-23.34 0-7.92 3.95-14.92 9.99-19.14z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M219.81 121.71c5.96 4.43 12.51 12.13 37.13 11.59'
    />
    <linearGradient
      id='WorryGreenPurple__b'
      gradientUnits='userSpaceOnUse'
      x1={-112}
      y1={167}
      x2={-112}
      y2={135}
      gradientTransform='matrix(-1 0 0 1 113.88 0)'>
      <stop
        offset={0.147}
        style={{
          stopColor: '#784ce0',
        }}
      />
      <stop
        offset={0.242}
        style={{
          stopColor: '#7564d8',
        }}
      />
      <stop
        offset={0.504}
        style={{
          stopColor: '#6da0c3',
        }}
      />
      <stop
        offset={0.727}
        style={{
          stopColor: '#68cdb3',
        }}
      />
      <stop
        offset={0.899}
        style={{
          stopColor: '#64e8aa',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#63f2a6',
        }}
      />
    </linearGradient>
    <path
      fill='url(#WorryGreenPurple__b)'
      d='M209.37 148.03c2.17-7.02 8.72-12.13 16.46-12.13 9.51 0 17.22 7.71 17.22 17.22 0 6.1-3.17 11.46-7.96 14.52-.86.1-1.73.15-2.61.15-11.69.03-21.39-8.55-23.11-19.76z'
    />
    <circle cx={225} cy={153} r={12} />
    <path
      fill='#FFF'
      d='m225.12 135.45.09.06a6.085 6.085 0 0 1 .92 8.56 6.085 6.085 0 0 1-8.56.92 6.073 6.073 0 0 1-2.2-5.62c2.93-2.08 6.28-3.4 9.75-3.92z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M219.09 125.31c1.7-1.19 3.98-1.12 5.65.12 3.85 2.86 8.35 5.09 13.34 6.45 3.92 1.07 7.85 1.52 11.66 1.42a4.846 4.846 0 0 1 4.65 3.16c.88 2.43 1.38 5.04 1.4 7.77.12 12.92-10.39 23.54-23.31 23.56-12.91.02-23.38-10.44-23.38-23.34 0-7.92 3.95-14.92 9.99-19.14z'
    />
  </svg>
);

export default WorryGreenPurple;
