import { SvgEditionSlot } from '../part';
import * as React from 'react';

const UnicornHorn = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#bfffff'
      d='M207.07 90.46 196.26 48.4l-10.8 42.02c-.15.63.09 1.28.61 1.68 2.72 2.05 21.14-1.05 21-1.64z'
    />
    <path
      fill='none'
      stroke='#32c0d2'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M187.71 80.14c1.41 3.8 3.89 6.29 6.84 6.87 0 0 6.65.69 7.17 6.3M193.01 62.94c1 1.92 2.36 3.14 3.94 3.5 0 0 4.76.51 5.42 6.13M190.2 70.44c1.46 3.86 3.82 6.48 6.76 7.4 0 0 8.01 1.77 8.87 10.04'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m207.07 90.46-9.82-40.12c-.27-1.1-1.71-1.1-1.98 0l-9.81 40.08c-.15.63 13.59 13.49 22.54 5.98.48-.4-.79-5.35-.93-5.94z'
    />
    <path
      fill='#ffff6a'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M223.15 87.32c-.01-.13-.03-.25-.05-.38 0 0-1.52 11.83-21.78 5.5-24.89-7.78-24.16 10.2-24.16 10.2 12.24-3.96 19.58 1.91 22.59 3.82 4.34 2.76 8.22 3.23 10.94 2.76 13.85-2.42 12.46-21.9 12.46-21.9z'
    />
  </svg>
);

export default UnicornHorn;
