import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Container,
  Fade,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import FullDrawer from '../../../components/Drawer/FullDrawer';
import {
  RoundOutPartialPartyProvider,
  useRoundOutPartialPartyContext,
} from '../RoundOutPartialParty/RoundOutPartialPartyContext';
import { useHistory, useParams } from 'react-router-dom';
import SvgIconBackArrow from '../../../assets/SvgIconBackArrow';
import { usePageTitle } from '../../../Analytics';
import { RealmType } from '../../../components/schema';
import {
  getRealmKey,
  getRealmSlug,
} from '../../../components/Zones/ZoneHelpers';
import {
  ALERT_TYPES,
  AlertContext,
} from '../../../components/Alert/AlertContext';
import AlertProvider from '../../../components/Alert/AlertProvider';
import RentMarketBosses from './RentMarketBosses';
import RentMarketOnboardProgress, {
  OnboardStep,
} from './RentMarketOnboardProgress';
import { useClientHardware } from '../../App/clientHardware';
import { cdnRoot } from '../../../theme';
import { white } from '../../../themev2';
import { getBossNameFromRealm } from '../../../utils';
import clsx from 'clsx';
import useCommonStyles from '../../../components/useCommonStyles';
import { useBossBattle } from '../../Furverse/useBossBattle';
import { usePlayersReadyFurballs } from '../../../wallet';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2.5rem 2rem 2rem 2rem',
    width: '100%',
    maxWidth: '1150px',
    margin: '0 auto',
    color: 'white',
    flexDirection: 'column',
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      padding: '1rem 1.35rem',

      '& h2': {
        maxWidth: '250px !important',
      },
    },
  },
  sub: {
    marginTop: '0.5rem !important',
    opacity: 0.7,

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem !important',
      maxWidth: '310px !important',
    },
  },
  input: {
    backgroundColor: 'white !important',
    // width: '430px !important',
    // height: '55px !important',
    minWidth: 200,
    width: '100%',
    // borderRadius: '30px !important',

    // [theme.breakpoints.down('sm')]: {
    //   width: '275px !important',
    // },
  },
  formCont: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    margin: '2rem auto 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    minWidth: 320,
    backgroundColor: 'white',
    borderRadius: '12px',
    padding: '3rem 0.5rem 3rem 0.5rem',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // minHeight: '210px',
  },
  btn: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: '255px !important',
    minWidth: '255px !important',
    marginTop: '0.7rem !important',

    '&:disabled': {
      backgroundColor: '#eee !important',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    alignItems: 'center',
  },
  back: {
    position: 'absolute',
    top: '80px',
    left: '30px',
    color: 'white',

    '& button': {
      padding: '0.5rem !important',
      borderRadius: '6px !important',
    },

    [theme.breakpoints.down('lg')]: {
      top: '100px',
    },

    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      marginTop: '1.5rem',
      marginLeft: '1.5rem',
    },
  },
  bossCardHeader: {
    backgroundColor: theme.palette.secondary.light,
    color: white,
    position: 'relative',
    maxHeight: '93px !important',
    minHeight: '80px !important',

    '@media (max-width: 1400px)': {
      cursor: 'pointer',
      borderRadius: '6px',
    },
  },
  bossTop: {
    zIndex: 1000,
    position: 'relative',
  },
}));

interface IParams {
  onboard?: string;
}

interface IRentMarketCreateParty {}

const RentMarketCreatePartyForm: FunctionComponent<IRentMarketCreateParty> = (
  props,
) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const history = useHistory();
  const { addAlert } = useContext(AlertContext);
  const { onboard } = useParams<IParams>();
  const furballs = usePlayersReadyFurballs();
  const { isMobile } = useClientHardware();

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedBoss, setSelectedBoss] = useState<RealmType | null>(null);
  const [partyName, setPartyName] = useState<string>('');

  const canCreateParty = onboard ? !!partyName && !!selectedBoss : !!partyName;
  usePageTitle(`Create Party ${onboard ? '| Onboard ' : ''}| Furball Market`);

  const { createNewUnfilledParty } = useRoundOutPartialPartyContext();
  const { allParties } = useBossBattle();

  function onPartyReady(realm: RealmType | null, partyId: string): void {
    history.push({
      pathname: `/market/furballs/${partyId}${
        !!realm ? `/${getRealmSlug(realm)}${onboard ? '/onboard' : ''}` : ''
      }`,
    });
  }
  async function handleCreateNewParty() {
    setLoading(true);

    try {
      const data = await createNewUnfilledParty(partyName);
      if (data.setParty) onPartyReady(selectedBoss, data.setParty.id);
    } catch (error: any) {
      setLoading(false);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Unable to create new party',
      });
    }
  }

  function renderContent() {
    if (loading) {
      return (
        <Fade in={true}>
          <div className={classes.loading}>
            <CircularProgress size={35} color={'secondary'} />
            <Typography variant={'body1'} color={'#757575'} fontSize={14}>
              Creating party...
            </Typography>
          </div>
        </Fade>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <TextField
            disabled={loading}
            autoFocus={!isMobile}
            style={{ width: '100%', height: '100%' }}
            label={'Party Name'}
            placeholder={'The mighty furs'}
            color={'secondary'}
            value={partyName}
            onChange={(e) => setPartyName(e.target.value)}
            InputProps={{
              className: classes.input,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            className={common.actionButton}
            style={{ width: '100%', height: '100%' }}
            // size={'medium'}
            variant={'contained'}
            onClick={() => handleCreateNewParty()}
            disabled={loading || !canCreateParty}>
            <Typography variant={'h6'}>Create</Typography>
          </Button>
        </Grid>
      </Grid>
    );
  }

  function goBack() {
    if (selectedBoss) {
      setSelectedBoss(null);
    } else {
      history.push('/market/furballs');
    }
  }

  function onSelectedBoss(realm: RealmType | null): void {
    if (realm) {
      const emptyParty = allParties?.find(
        (p) => p && (p.partyMembers?.length ?? 0) === 0,
      );
      const bossKey = getRealmKey(realm);
      const usableParty =
        emptyParty ??
        allParties?.find(
          (p) =>
            p &&
            p.partyMembers &&
            p.partyMembers.length < 3 &&
            p.partyMembers.filter((m) => {
              const fb = m && furballs.find((fb) => fb.id === m.id);
              return (
                fb && fb.inventory.items.find((item) => item.itemId === bossKey)
              );
            }).length === p.partyMembers.length,
        );

      if (usableParty) {
        // skip creating a new party
        onPartyReady(realm, usableParty.id);
        return;
      }
    }
    setSelectedBoss(realm);
  }

  const bossName = selectedBoss ? getBossNameFromRealm(selectedBoss) : null;

  function getBossPos() {
    if (selectedBoss === RealmType.TurtleSea) return -62;
    if (selectedBoss === RealmType.WizardsPath) return -22;
    return -44;
  }

  return (
    <FullDrawer drawer={null}>
      {!!onboard && <RentMarketOnboardProgress step={OnboardStep.CREATE} />}
      <div className={classes.back}>
        {(!onboard || selectedBoss) && (
          <ButtonBase onClick={goBack}>
            <SvgIconBackArrow height={30} width={30} />
          </ButtonBase>
        )}
      </div>
      {!selectedBoss && (
        <Fade in={true} timeout={400}>
          <div className={classes.root}>
            <Typography variant={'h2'}>Create new boss party</Typography>
            {isMobile && (
              <Typography variant={'body1'} className={classes.sub}>
                Click an image to see more info on the boss.
              </Typography>
            )}
            {!isMobile && (
              <Typography variant={'body1'} className={classes.sub}>
                To fight bosses, you need a party of furballs!
                <br />
                Pick a boss to fight, and give the party a name
              </Typography>
            )}
            {!selectedBoss && (
              <RentMarketBosses
                onboard={!!onboard}
                selected={selectedBoss}
                setSelectedBoss={onSelectedBoss}
              />
            )}
          </div>
        </Fade>
      )}
      {selectedBoss && (
        <div className={classes.root}>
          <Typography variant={'h2'}>Name Your Party</Typography>
          <div style={{ position: 'relative', marginTop: 40 }}>
            <div
              className={clsx(classes.bossTop)}
              style={{
                top: getBossPos(),
              }}>
              <img
                src={`${cdnRoot}/images/leaderboard_top_${bossName}.png`}
                height={150}
              />
            </div>
            <div style={{ marginTop: -110 }}>
              <Container maxWidth={'sm'} className={classes.formCont}>
                {renderContent()}
              </Container>
            </div>
          </div>
        </div>
      )}
    </FullDrawer>
  );
};

const RentMarketCreateParty = () => (
  <RoundOutPartialPartyProvider>
    <AlertProvider>
      <RentMarketCreatePartyForm />
    </AlertProvider>
  </RoundOutPartialPartyProvider>
);

export default RentMarketCreateParty;
