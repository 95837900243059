import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import SvgRedX01 from '../../assets/SvgRedX';
import OkDialog from '../OkDialog';
import { useHistory, useLocation } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    maxWidth: '32rem',
    padding: '2rem 0',
    position: 'relative',
  },

  button: {
    minWidth: '8rem !important',
  },

  loader: {
    position: 'absolute',
    inset: 0,
    zIndex: 10,
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
}));

interface IProps {
  open: boolean;
  loading: boolean;
  setOpen: () => void;
  signIn: () => void;
}

export const AuthErrorModal: FunctionComponent<IProps> = ({
  open,
  loading,
  setOpen,
  signIn,
}) => {
  const classes = useStyles();

  const { listen } = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!open) return;

    window.addEventListener('beforeunload', setOpen);

    listen((location) => {
      location.pathname !== pathname && setOpen();
    });

    return () => {
      window.removeEventListener('beforeunload', setOpen);
    };
  }, [open]);

  return (
    <OkDialog
      blockchainBanner
      open={open}
      topRight={false}
      onClose={() => null}>
      <Grid container gap='2rem' className={classes.container}>
        {loading && (
          <div className={classes.loader}>
            <CircularProgress size='4rem' color='secondary' />
          </div>
        )}

        <Grid item xs={12}>
          <SvgRedX01 style={{ maxWidth: 100, maxHeight: 100 }}></SvgRedX01>
          <Typography
            variant='h3'
            style={{ fontWeight: 'bolder', paddingBottom: 10, paddingTop: 10 }}>
            Authentication failed!
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingLeft: 50, paddingRight: 50 }}>
            An error occured while trying to sign in, this is either a fault
            from furballs servers or the signature request was aborted. Please
            try again.
          </Typography>
        </Grid>

        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          gap='0.3rem'>
          <Button
            variant='outlined'
            color='error'
            className={classes.button}
            onClick={setOpen}>
            Ignore
          </Button>
          <Button
            variant='contained'
            className={classes.button}
            onClick={signIn}>
            Retry
          </Button>
        </Grid>
      </Grid>
    </OkDialog>
  );
};
