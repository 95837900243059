import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Squirrel = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M32.05 171.82s2.27 1.36 6.13-.41c1.16 4.12 6.57 18.51 25.73 19.59-1.03 5.39-2.76 11.51-5.58 18.35-16.01 38.81 9.98 49.05 9.98 49.05l6.28-4.82a12.766 12.766 0 0 0 3.43-16.25l-2.51-4.58 29.04-46.17 3.89-23.31c-5.34-47.95-50.82-39.72-50.82-39.72s.87 4.5 2.88 8.85c-8.39.85-32.38 6.18-28.45 39.42z'
    />
    <path
      fill={props.renderer.color2}
      d='M34.72 149.13s26.99-15.22 40.39 8.97-15.32 57.84-11.02 72.19c4.29 14.35 7.38 16.91 7.38 16.91l-12.33 3.83s-10.86-11.24 1.43-47.55l4.68-13.52S42 188.05 38.41 172.12l-6.34-.3 2.65-22.69z'
    />
    <path
      opacity={0.13}
      d='M60.76 160.1s-5.9.16-7.44 7.67c-1.3 6.33-12.04 1.72-12.04 1.72l-2.89 2.63c1.54 4.85 7.34 17.86 25.52 18.88 0 0 4.52-17.08-3.15-30.9z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M38.71 172.02S42.02 190 63.8 190.76'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M32.05 171.82s2.27 1.36 6.13-.41c1.16 4.12 6.57 18.51 25.73 19.59-1.03 5.39-2.76 11.51-5.58 18.35-16.01 38.81 9.98 49.05 9.98 49.05l7.2-25.65 24.36-38.72c3.09-4.92 15.82-29.31 3.21-49.86-15.5-25.27-45.45-20.62-45.45-20.62s.87 4.5 2.88 8.85c-8.4.85-32.39 6.18-28.46 39.42z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M57.63 123.55s1.76 13.34 13.65 15.85'
    />
    <path d='M33.55 169.45c.34.07.95.13 1.44.13a10 10 0 0 0 1.55-.07 9.12 9.12 0 0 0 2.91-.82c.46-.2.86-.49 1.28-.75.36-.33.77-.62 1.08-1.03.7-.73 1.16-1.69 1.6-2.67l.07-.15c.34-.75 1.21-1.08 1.96-.75.7.31 1.03 1.11.8 1.82-.43 1.3-.94 2.63-1.82 3.79-.39.61-.93 1.11-1.43 1.64-.56.46-1.13.94-1.76 1.29-1.25.75-2.62 1.26-4 1.55-.69.14-1.39.25-2.1.29-.73.05-1.35.07-2.22-.01a2.15 2.15 0 0 1-1.95-2.35 2.15 2.15 0 0 1 2.35-1.95l.23.03.01.01zM62.05 191s4.4-15.88-1.29-30.9c0 0 10.31 10.7 5.24 31.47' />
    <path
      opacity={0.08}
      d='M57.63 123.55s52.58 13.32 41.4 50.55c-11.74 39.09-22.18 54.4-22.18 54.4 79.33-103.64-19.22-104.95-19.22-104.95z'
    />
  </svg>
);

export default Squirrel;
