import {
  IBlockchainNetwork,
  IFurball,
  IFurballContext,
  IFurballContracts,
  NetworkType,
} from './WalletTypes';
import { useAppDispatch, useWalletSelector } from '../hooks';
import WalletContext from './WalletContext';
import WalletSlice from './WalletSlice';
import React from 'react';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

import {
  FurballFragment,
  FurballsDocument,
  OwnerFurballFragment,
} from '../components/schema';
import { ApolloClient, useApolloClient } from '@apollo/client';
import { getInventoryQuantities } from '../components/Furballs';
import { useFurComponent } from '../utils';

// async function loadCachedTokenUri(
//   tokenId: string,
//   contracts: IFurballContracts,
// ): Promise<IFurballInfo> {
//   const key = `furballUri${tokenId}`;
//   const iframe = inIframe(); // iframes cannot access local storage
//   let data = iframe ? undefined : localStorage.getItem(key);
//
//   if (!data) {
//     const uri = await contracts.furballs.tokenURI(tokenId);
//     const b64 = uri.substr('data:application/json;base64,'.length);
//     data = Buffer.from(b64, 'base64').toString('utf8');
//     if (!iframe) localStorage.setItem(key, data);
//   }
//
//   try {
//     return JSON.parse(data);
//   } catch (e) {
//     throw e;
//   }
// }

export function useNetworkLiveForWallet(): boolean {
  const addr = useWalletSelector((s) => s.address);
  const adoptable = useWalletSelector((s) => s.edition?.maxAdoptable ?? 0);
  const context = React.useContext(WalletContext);
  const networkType = context?.network.type;

  if (networkType == NetworkType.DevNet) return true;
  if (!addr) return false;

  return adoptable > 0;
}

export function useInterval(): number {
  const context = React.useContext(WalletContext);
  return context?.network.interval ?? 3600;
}

export function useBlockchainNetwork(): IBlockchainNetwork {
  return useWalletContext().network;
}

export function useWalletContext(): IFurballContext {
  const context = React.useContext(WalletContext);
  if (!context) throw 'No blockchain context';
  return context;
}

// export async function reloadAllFurballs(
//   tokenIds: string[], contracts: IFurballContracts, dispatch: Dispatch<AnyAction>
// ) {
//   for(let i=0; i<tokenIds.length; i++) {
//     await loadFurball(tokenIds[i], contracts, dispatch);
//   }
// }

// @ts-ignore
export async function reloadAllFurballs(
  tokenIds: string[],
  client: ApolloClient<unknown>,
  dispatch: Dispatch<AnyAction>,
) {
  await loadFurballs(client, dispatch, ...tokenIds);
  // for(let i=0; i<tokenIds.length; i++) {
  //   await loadFurball(tokenIds[i], client, dispatch);
  // }
}

export async function loadFurBalance(
  walletAddr: string,
  contracts: IFurballContracts,
  dispatch: Dispatch<AnyAction>,
) {
  const { log } = useFurComponent(loadFurBalance.name);
  try {
    const furBalance: number = (
      await contracts.fur.balanceOf(walletAddr)
    ).toNumber();
    dispatch(WalletSlice.actions.setFurBalance(furBalance));
  } catch (e) {
    log.error('Failed to update balance', e);
  }
}

// export async function loadFurball(
//   tokenId: string, contracts: IFurballContracts, dispatch: Dispatch<AnyAction>
// ): Promise<IFurball | undefined> {
//   try {
//     const info = await loadCachedTokenUri(tokenId, contracts);
//     const stats = decodeStats(await contracts.furballs.stats(tokenId, true));
//     const furball: IFurball = { tokenId, info, stats };
//
//     dispatch(WalletSlice.actions.setFurball(furball));
//
//     return furball;
//   } catch (e) {
//   }
// }

export async function loadFurball(
  tokenId: string,
  client: ApolloClient<unknown>,
  dispatch: Dispatch<AnyAction>,
): Promise<IFurball | undefined> {
  return (await loadFurballs(client, dispatch, tokenId))[0];
}

export async function loadFurballs(
  client: ApolloClient<unknown>,
  dispatch: Dispatch<AnyAction>,
  ...tokenIds: string[]
): Promise<IFurball[]> {
  // const { log } = useFurComponent(loadFurballs.name);
  if (tokenIds.length <= 0) return [];
  // log.info('[FB] load', tokenIds);
  const { data } = await client.query({
    query: FurballsDocument,
    variables: { tokenIds },
  });

  const fbs = data?.furballs ?? [];
  return fbs.map((f: FurballFragment) => {
    const fb = parseFurball(f);
    dispatch(WalletSlice.actions.setFurball(fb));
    return fb;
  });
}

export type MaybeFurball = IFurball | undefined;

export function parseFurball(
  fbd?: FurballFragment | OwnerFurballFragment | null,
): IFurball {
  if (!fbd) {
    throw new Error('No furball def');
  }
  return { ...fbd, inventory: getInventoryQuantities(fbd.inventory) };
}

export function usePlayersReadyFurballs(): IFurball[] {
  const tokenIds = useAccountFurballIds();
  const furballs = useWalletSelector((s) => s.furballs ?? {});
  return React.useMemo(
    () => Object.values(furballs).filter((fb) => tokenIds.includes(fb.tokenId)),
    [tokenIds, furballs],
  );
}

export function usePlayGamePath(): string {
  // const context = React.useContext(WalletContext);
  // const isMainNet = context?.network.typl === NetworkType.MainNet;

  // const furballs = usePlayersReadyFurballs();
  // return furballs.length >= 3 ? '/boss/lobby' : '/boss/onboard';
  const account = useWalletSelector((s) => s.contents);
  // const furballsLoaded = useWalletSelector((s) => !!s.usableFurballsLoaded);
  const hasEnoughFurballs = (account?.tokenIds.length ?? 0) >= 3;
  // if (!isMainNet)
  return hasEnoughFurballs ? '/furverse' : '/furverse/onboard';
  // return hasEnoughFurballs ? '/boss/lobby' : '/boss/onboard';
}

export function useAccountFurballIds(): string[] {
  return useWalletSelector((s) => s.contents?.tokenIds ?? []);
}

export function useLoadedFurballs(ids: string[]): MaybeFurball[] {
  const tokenIds = ids.filter((id) => id !== placeholderFurballId);
  const dispatch = useAppDispatch();
  const furballs = useWalletSelector((s) => s.furballs);
  const client = useApolloClient();

  const pendingTokenIds: string[] = [];
  const ret: MaybeFurball[] = [];
  for (let i = 0; i < tokenIds.length; i++) {
    const tokenId = tokenIds[i];
    if (furballs[tokenId]) {
      ret.push(furballs[tokenId]);
    } else {
      pendingTokenIds.push(tokenId);
    }
  }

  React.useEffect(() => {
    if (pendingTokenIds.length > 0) {
      void loadFurballs(client, dispatch, ...pendingTokenIds);
    }
  }, [pendingTokenIds]);

  return ret;
}

export function useLoadedFurball(tokenId?: string): MaybeFurball {
  return useLoadedFurballs(tokenId ? [tokenId] : [])[0];
}

export function useFurballs(tokenIds?: string[]): IFurball[] {
  const walletFurballs = useAccountFurballIds();
  const furballs = useWalletSelector((s) => s.furballs);
  return (tokenIds ?? walletFurballs)
    .map((tokenId) => {
      return furballs[tokenId];
    })
    .filter((fb) => !!fb);
}

export const placeholderFurballId = '0x010101010101010101000000';
