import {
  ILogManager,
  ILogSink,
  ILogger,
  LogLevel,
  LogOptions,
  ILogEntry,
} from './types';
import { defaultLogOptions } from './LogOptions';
import ContextualLogger from './ContextualLogger';
import JsLogSink from './JsLogSink';
import { getLogEntry } from './hooks';

class LogManager implements ILogManager {
  private options: LogOptions = { ...defaultLogOptions };

  private _sinks: { [key: string]: ILogSink } = {};

  private _defaultMinLevel: LogLevel;

  public constructor(options?: LogOptions) {
    this.options = Object.assign({}, this.options ?? {}, options);
    this._defaultMinLevel = options?.minLevels[''] ?? LogLevel.debug;
    this.setSink(new JsLogSink(this));
  }

  public setSink(sink: ILogSink): void {
    this._sinks[sink.defaultSinkKey] = sink;
  }

  public pipeToServer(contextRoot: string, logEntry?: ILogEntry): void {
    const serverSink = this.getSink('server');
    if (serverSink) {
      if (!logEntry) {
        serverSink.logEntry(
          getLogEntry('Unity', LogLevel.warn, 'Missing log entry data'),
        );
      } else {
        const cleanContext = logEntry.context.replaceAll('"', ' ').trim();
        serverSink.logEntry({
          ...logEntry,
          context: `${contextRoot}.${cleanContext}`,
        });
      }
    }
  }

  public getSink(key: string): ILogSink | undefined {
    return this._sinks[key];
  }

  public getMinimumLogLevelForContext(context?: string): LogLevel {
    const c = context ?? '';
    return this.options.minLevels[c] ?? this._defaultMinLevel;
  }

  public getLogger(context: string, id?: string): ILogger {
    const ln = id ? `${context}#${id}` : context;
    return new ContextualLogger(this, ln, ...Object.values(this._sinks));
  }

  public getLoggerForFile(filename: string): ILogger {
    const firstPart = filename.split('/').pop() as string;
    return this.getLogger(firstPart.split('.').shift() as string);
  }
}

export { LogManager };

export const defaultLogManager = new LogManager(defaultLogOptions);

export function useDefaultLogManager(): ILogManager {
  return defaultLogManager;
}

export default LogManager;
