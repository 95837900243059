import * as React from 'react';

function SvgLootFurgaine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <path
        fill='#FBCA9F'
        d='M112.3 31.2H87.7L81.8 5.4c11.5-4.6 24.9-4.6 36.4 0l-5.9 25.8z'
      />
      <path fill='#E0B48D' d='M104 2.1c4.9.4 9.7 1.5 14.2 3.3l-5.9 25.8H102' />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M112.3 31.2H87.7L81.8 5.4h0c11.5-4.6 24.9-4.6 36.4 0h0l-5.9 25.8z'
      />
      <path
        fill='#FFF'
        d='M120.7 46.5c-3.2-1.3-5.3-4.4-5.3-7.9v-7.4h1c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3H83.6c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3h1v7.5c0 3.5-2.1 6.6-5.3 7.9-20.7 8.2-35.6 28.3-36.1 51.6-.3 15.7 5.6 29.9 15.6 40.4 4.5 4.8 10.8 7.4 17.4 7.4h47.7c6.5 0 12.8-2.6 17.3-7.3 9.7-10.2 15.7-24 15.7-39.1a57.15 57.15 0 00-36.2-53.1z'
      />
      <path
        fill='#CFD0FD'
        d='M93.8 96.5C70 86.2 53 87.4 44.1 89.4c-.5 2.8-.8 5.8-.9 8.7-.3 15.7 5.6 29.9 15.6 40.4 4.5 4.8 10.8 7.4 17.4 7.4h47.7c6.5 0 12.8-2.6 17.3-7.3 9.7-10.2 15.7-24 15.7-39.1 0-3.7-.4-7.3-1-10.8-12.8 7.7-37.1 18.7-62.1 7.8z'
      />
      <path
        opacity={0.28}
        fill='#FFF'
        d='M69.8 105.1c-3.1-8.7-4.1-41.3 12.3-50.1.9-.5 1-1.6.3-2.3-.4-.3-.9-.4-1.4-.2C63 60 50.2 77.7 49.7 98.1c-.3 13.9 5 26.6 13.9 35.9 4 4.2 9.6 6.6 15.5 6.6h24.8c-5.9 0-24-7.1-34.1-35.5z'
      />
      <g fill='#CFD0FD'>
        <circle cx={86.8} cy={70.1} r={6.9} />
        <circle cx={105.9} cy={63.3} r={3.4} />
        <circle cx={105.9} cy={86.4} r={3.4} />
      </g>
      <path
        opacity={0.34}
        fill='#BDBFF5'
        d='M120.7 46.5c-3.2-1.3-5.3-4.4-5.3-7.9v-7.4h1c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3H102v18c0 3.5 2.1 6.6 5.3 7.9 21.1 8.3 36.1 28.8 36.1 52.9 0 15.2-6 29-15.7 39.1-4.5 4.7-10.8 7.3-17.3 7.3h13.4c6.5 0 12.8-2.6 17.3-7.3 9.7-10.2 15.7-24 15.7-39.1 0-24.1-15-44.6-36.1-52.9z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M120.7 46.5c-3.2-1.3-5.3-4.4-5.3-7.9v-7.4h1c2.9 0 5.3-2.4 5.3-5.3s-2.4-5.3-5.3-5.3H83.6c-2.9 0-5.3 2.4-5.3 5.3s2.4 5.3 5.3 5.3h1v7.5c0 3.5-2.1 6.6-5.3 7.9-20.7 8.2-35.6 28.3-36.1 51.6-.3 15.7 5.6 29.9 15.6 40.4 4.5 4.8 10.8 7.4 17.4 7.4h47.7c6.5 0 12.8-2.6 17.3-7.3 9.7-10.2 15.7-24 15.7-39.1a57.15 57.15 0 00-36.2-53.1z'
      />
    </svg>
  );
}

export default SvgLootFurgaine;
