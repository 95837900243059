import * as React from 'react';

function SvgKeyTrashy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 122.4 116'
      enableBackground='new 0 0 122.4 116'
      xmlSpace='preserve'
      {...props}>
      <g>
        <path
          fill='#FFF17F'
          d='M76.6,112.5c-0.7,0-1.4-0.2-2-0.7c-0.8-0.6-1.4-1.5-1.4-2.6c0-0.1,0-0.3,0-0.5c0,0,0-0.1,0-0.1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.4,0-0.4c0,0,0-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3c0.1-0.4,0.2-0.7,0.3-1.1c-3.4,0.8-7.1,1.2-11.1,1.2
		h-0.1c-0.8,0-1.5,0-2.3-0.1c-0.3,0-0.6,0-0.9,0c-1.4-0.1-2.9-0.2-4.3-0.4c0,0-1.1-0.1-1.4-0.2l-0.5-0.1c-0.8-0.1-1.5-0.2-2.3-0.4
		l-0.7-0.1c-1.6-0.3-3.1-0.7-4.7-1.1c-0.3-0.1-1.2-0.3-1.2-0.3c-0.4-0.1-0.8-0.2-1.2-0.3c0,0-0.1,0-0.1,0c-2.6-0.8-5.1-1.7-7.3-2.8
		c-0.6-0.3-1.1-0.5-1.7-0.7c0,0,0,0,0,0c-0.6-0.2-1.3-0.4-2-0.6c-0.2,0-0.3-0.1-0.5-0.1c-1.3-0.2-2.7-0.4-4-0.4l-0.2,0
		c-1.8,0-3.7,0.2-5.5,0.7c-0.8,0.2-1.4,0.4-1.8,0.5c-0.4,0.1-0.8,0.2-1.3,0.2c-0.5,0-1-0.1-1.4-0.2c-0.2-0.1-0.4-0.1-0.5-0.2
		c-0.1-0.1-0.3-0.2-0.4-0.3c-1.2-0.8-2-2.2-2-3.7c0-0.4,0.1-0.9,0.2-1.2c0.8-2.7,2.3-9.5-0.6-15.8c-0.1-0.3-0.4-0.9-0.4-0.9
		c-0.1-0.3-0.4-1-0.4-1c-0.1-0.3-0.4-0.9-0.4-0.9c-0.2-0.6-0.5-1.2-0.7-1.8l3.3-1.3l-3.3,1.2c-0.1-0.3-0.4-1.1-0.4-1.1
		c-0.1-0.3-0.2-0.6-0.3-0.9l0-0.1c-0.2-0.6-0.4-1.3-0.6-2.1l3.3-1l-3.4,0.9c-0.1-0.4-0.2-0.7-0.3-1.1c0,0-0.3-1.3-0.3-1.3
		c-0.2-0.8-0.4-1.5-0.5-2.3c0,0,0-0.1,0-0.1C9,65.3,8.9,65,8.9,64.6l0-0.2c-0.1-0.4-0.1-0.8-0.2-1.2c0,0,0-0.1,0-0.1
		c-0.1-0.8-0.3-1.6-0.4-2.4c-0.1-0.5-0.1-0.9-0.2-1.3l0-0.2C8,58.9,8,58.4,7.9,58c-0.1-0.9-0.1-1.8-0.2-2.7c0,0,0-0.1,0-0.1
		c0-0.4,0-0.8,0-1.2c0,0,0-0.1,0-0.1c0-0.4,0-0.9,0-1.3c0,0,0-0.1,0-0.1c0-0.9,0-1.8,0.1-2.6c0-0.5,0.1-0.9,0.1-1.4c0,0,0-0.1,0-0.1
		c0-0.4,0.1-0.9,0.1-1.3c0,0,0-0.1,0-0.1c0.1-0.9,0.2-1.8,0.4-2.6c0,0,0-0.1,0-0.1c0.1-0.4,0.1-0.8,0.2-1.2c0,0,0-0.1,0-0.1
		c0.1-0.4,0.2-0.8,0.3-1.3c0,0,0-0.1,0-0.1C7.9,41.8,7,42,6.2,42c-0.2,0-0.3,0-0.5,0c-1-0.1-1.9-0.6-2.5-1.4s-0.8-1.9-0.6-2.9
		c0,0,0-0.2,0-0.2c0.4-1.6,6.5-22.8,29.9-28.7c0.3-0.1,0.6-0.1,0.9-0.1c1.1,0,2.2,0.6,2.9,1.5c0.8,1.2,0.8,2.8-0.1,4
		c0,0-2.3,3.3-3.8,8.7c-1,6.2-0.9,12.5,0.4,18.7l0.4,2c0.3,1.3-0.3,2.7-1.3,3.5c-0.6,0.5-1.4,0.7-2.1,0.7c-0.5,0-1-0.1-1.5-0.3
		c0,0-0.2-0.1-0.2-0.1c-0.2-0.1-0.6-0.2-0.9-0.4c0,0-0.5-0.3-0.5-0.3L26,46.3c-0.6-0.3-1.3-0.7-2-1.2c0.1,3.3,1,7.6,4.2,11.9
		c0,0.1,0.1,0.1,0.1,0.2c0.9,1.2,1.9,2.3,3.1,3.4c0.2,0.2,0.4,0.4,0.6,0.5l-2.2,2.7l2.3-2.6c0.2,0.2,0.4,0.4,0.6,0.5
		c0.1,0.1,0.2,0.1,0.2,0.2c0.7,0.7,1.7,1.1,2.7,1.1c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.6-0.1c0.2,0,0.3-0.1,0.5-0.1l0.1-0.1
		c0.1,0,0.1-0.1,0.2-0.1c0.3-0.1,0.6-0.3,0.8-0.5l0.1-0.1l22.3-20.7c-0.1-1.3-0.1-2.6,0-3.8L48.4,23.2c-1.2-1.4-1.1-3.5,0.2-4.7
		l5.6-5.6c0.7-0.7,1.6-1,2.5-1c0.8,0,1.6,0.3,2.3,0.8l14.3,12.1c0.7-0.1,1.4-0.1,2.1-0.1c1,0,1.9,0.1,2.8,0.3l21-19.5
		c0.4-0.4,0.9-0.8,1.5-1.1c0.2-0.1,0.4-0.2,0.6-0.3c0.9-0.4,1.9-0.6,2.9-0.6c0.5,0,1,0.1,1.5,0.2c0.2,0,0.4,0.1,0.7,0.2
		c0.4,0.1,0.8,0.3,1.1,0.5c0.5,0.2,0.9,0.5,1.3,0.9c0.2,0.2,0.4,0.3,0.5,0.5c0.2,0.2,0.4,0.4,0.5,0.6c0,0,0.1,0.1,0.1,0.2
		c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0.1,0.1,0.1c1.5,2.4,1.5,5.4,0,7.7l6.5,6.5c0.7,0.7,1,1.5,1,2.5s-0.4,1.8-1,2.5l-4,4
		c-0.7,0.7-1.6,1-2.5,1c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0.9-0.4,1.8-1,2.5l-4,4c-0.7,0.7-1.6,1-2.5,1s-1.8-0.3-2.5-1l-5.8-5.8
		l-4.4,4.7c0.3,1.6,0.4,3.3,0.1,5l12.1,14.3c1.2,1.4,1.1,3.5-0.2,4.7l-5.6,5.6c-0.7,0.7-1.6,1-2.5,1c-0.8,0-1.6-0.3-2.3-0.8
		L77.5,54.5c-0.7,0.1-1.4,0.1-2.1,0.1c-0.6,0-1.2,0-1.7-0.1L52.9,76.9c-1.3,1.5-1.3,3.8,0.1,5.2c0.1,0.1,0.1,0.1,0.2,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.1,0.2,0.2c5.7,6.5,11.9,8.1,16.2,8.2c-0.5-0.7-0.9-1.4-1.2-2c0,0-0.4-0.8-0.4-0.8
		c0,0-0.2-0.3-0.2-0.3c-0.2-0.4-0.3-0.7-0.4-0.9c0,0-0.2-0.6-0.2-0.6c-0.4-1.2-0.1-2.5,0.7-3.4c0.7-0.7,1.6-1.2,2.6-1.2
		c0.2,0,0.5,0,0.7,0.1l2,0.4c3.4,0.7,6.9,1,10.4,1c2.6,0,5.3-0.2,7.9-0.6c5.6-1.5,9-3.9,9-3.9c0.6-0.4,1.3-0.7,2.1-0.7
		c0.7,0,1.4,0.2,2,0.6c1.2,0.8,1.8,2.3,1.4,3.7c-6,23.8-27.9,29.7-28.8,29.9c0,0-0.1,0-0.1,0C77.1,112.4,76.8,112.5,76.6,112.5z'
        />
        <path
          d='M104.2,7c0.3,0,0.5,0,0.8,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.5,0.3,0.7,0.5
		c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0,0,0,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0.9,1.4,0.8,3.3-0.4,4.6
		l-1.3,1.5l8.8,8.8l-4,4l-8.6-8.6l-3.5,3.7l8.5,8.5l-4,4L94.4,27l-8.2,8.8c0.8,2.2,0.9,4.6,0.2,6.9l13.2,15.6l-2.9,2.9L94.1,64
		L78.4,50.7c-1,0.3-2,0.4-3.1,0.4c-1,0-2-0.1-3-0.4l-22,23.8c-2.6,2.9-2.5,7.3,0.2,10.1c0.2,0.2,0.3,0.4,0.5,0.6c0,0,0,0,0,0
		c6.7,7.6,14,9.3,19.2,9.3c2.5,0,4.4-0.4,5.7-0.7c0,0,0,0,0,0c0.9-0.2,1.3-0.4,1.3-0.4c-2.8-1.8-4.6-4.3-5.5-6c0,0,0,0,0,0
		c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0
		c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c-0.1-0.3-0.3-0.6-0.3-0.8c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
		c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.1h0l2,0.4c3.7,0.7,7.4,1.1,11.1,1.1c2.9,0,5.8-0.2,8.6-0.7
		c6.5-1.7,10.3-4.5,10.3-4.5c-5.5,21.8-25.3,27.1-26.3,27.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
		c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2
		c0,0,0,0,0-0.1c0.5-2.7,2.7-7.4,2.7-7.4c-5.2,2.4-11.1,3.4-17,3.4c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.7,0-1.4,0-2.1,0
		c-0.3,0-0.6,0-0.8,0c-1.4-0.1-2.7-0.2-4.1-0.4c-0.1,0-0.3,0-0.4-0.1c-0.3,0-0.6-0.1-1-0.1c-0.2,0-0.3,0-0.5-0.1
		c-0.7-0.1-1.5-0.2-2.2-0.4c-0.2,0-0.4-0.1-0.7-0.1c-1.5-0.3-3-0.7-4.4-1c-0.3-0.1-0.6-0.2-1-0.3c-0.1,0-0.1,0-0.2-0.1
		c-0.4-0.1-0.7-0.2-1.1-0.3c0,0-0.1,0-0.1,0c-2.6-0.8-4.9-1.7-6.8-2.6c-0.7-0.3-1.4-0.6-2.1-0.8c0,0,0,0,0,0
		c-0.8-0.3-1.6-0.5-2.4-0.7c-0.2,0-0.4-0.1-0.6-0.1c-1.6-0.3-3.2-0.4-4.7-0.4c-0.1,0-0.2,0-0.3,0c-2.4,0-4.6,0.4-6.2,0.7
		c0,0,0,0,0,0c-0.8,0.2-1.5,0.4-2,0.5c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1
		c-0.2-0.2-0.4-0.4-0.4-0.7c0,0,0,0,0,0c0-0.1,0-0.2,0-0.3c1-3.3,2.6-11-0.8-18.3c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1,0-0.1
		c-0.1-0.2-0.2-0.5-0.3-0.7c0,0,0-0.1-0.1-0.1c-0.1-0.3-0.2-0.5-0.3-0.8c0,0,0-0.1,0-0.1c-0.2-0.6-0.4-1.1-0.6-1.7c0,0,0-0.1,0-0.1
		c-0.1-0.3-0.2-0.6-0.3-0.9c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.6-0.3-0.9c0,0,0-0.1,0-0.1c-0.2-0.6-0.4-1.3-0.6-2c0,0,0-0.1,0-0.1
		c-0.1-0.3-0.2-0.7-0.3-1c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.7-0.3-1c0,0,0-0.1,0-0.1c-0.2-0.7-0.3-1.5-0.5-2.2c0,0,0-0.1,0-0.1
		c-0.1-0.4-0.1-0.7-0.2-1.1c0,0,0-0.1,0-0.1c-0.1-0.4-0.1-0.7-0.2-1.1c0,0,0,0,0-0.1c-0.1-0.8-0.2-1.6-0.4-2.4c0,0,0-0.1,0-0.1
		c0-0.4-0.1-0.8-0.1-1.2c0,0,0-0.1,0-0.1c0-0.4-0.1-0.8-0.1-1.2c0,0,0,0,0-0.1c-0.1-0.8-0.1-1.6-0.2-2.5c0,0,0-0.1,0-0.1
		c0-0.4,0-0.8,0-1.2c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0-1.2c0,0,0,0,0,0c0-0.8,0-1.7,0.1-2.5c0,0,0-0.1,0-0.1c0-0.4,0-0.8,0.1-1.2
		c0,0,0-0.1,0-0.1c0-0.4,0.1-0.8,0.1-1.2c0,0,0,0,0,0c0.1-0.8,0.2-1.7,0.3-2.5c0,0,0-0.1,0-0.1c0.1-0.4,0.1-0.8,0.2-1.2
		c0,0,0-0.1,0-0.1c0.1-0.4,0.2-0.8,0.3-1.2c0,0,0,0,0,0c0.5-2.3,1.3-4.6,2.3-6.7c0,0-6,2.8-8.4,2.8c-0.1,0-0.1,0-0.2,0h0h0
		c0,0,0,0,0,0c0,0,0,0,0,0c0.2-0.9,5.5-20.8,27.4-26.3c0,0-2.7,3.7-4.4,10c-1.1,6.7-1,13.5,0.4,20.1l0.4,2c0,0,0,0-0.1,0
		c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
		c-0.2-0.1-0.5-0.2-0.8-0.3c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0
		c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-1.7-1-4.2-2.7-6-5.5c0,0-4.3,10.8,3.9,21.6
		c0,0,0,0,0,0c1,1.3,2.1,2.6,3.4,3.8c0,0,0,0,0.1,0.1c0.2,0.2,0.4,0.4,0.6,0.5c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.5,0.4,0.7,0.6
		c1.4,1.4,3.3,2.1,5.2,2.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.4,0,0.8-0.1,1.2-0.1c0.3-0.1,0.7-0.1,1-0.3c0.1,0,0.2-0.1,0.3-0.1
		c0.1,0,0.3-0.1,0.4-0.2c0.6-0.3,1.1-0.6,1.6-1c0.1-0.1,0.2-0.2,0.3-0.2l23.8-22c-0.5-2-0.5-4.1,0-6.1L51,20.9l2.8-2.8l2.9-2.9
		l15.6,13.2c1-0.3,2-0.4,3.1-0.4c1.3,0,2.6,0.2,3.8,0.6L101.6,8c0.2-0.2,0.5-0.4,0.8-0.6c0.1-0.1,0.2-0.1,0.3-0.2
		C103.2,7.1,103.7,7,104.2,7 M6,38.4L6,38.4 M6,38.4L6,38.4 M102.9,81.6L102.9,81.6 M104.2,0C104.2,0,104.2,0,104.2,0
		C104.2,0,104.2,0,104.2,0c-1.5,0-2.9,0.3-4.2,0.9c-0.3,0.1-0.6,0.3-1,0.5c-0.8,0.4-1.5,1-2.2,1.6L77.1,21.2
		c-0.6-0.1-1.1-0.1-1.7-0.1c-0.3,0-0.7,0-1,0L61.2,10c-1.3-1.1-2.9-1.7-4.5-1.7c-1.8,0-3.6,0.7-5,2.1l-2.9,2.9L46.1,16
		c-2.6,2.6-2.7,6.7-0.4,9.5l11.2,13.2c0,0.4,0,0.8,0,1.3L35.8,59.5c0,0,0,0-0.1,0l1.2,6.9l-1.3-6.9c0,0,0,0-0.1,0
		c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.3-0.3-0.5-0.4c-0.2-0.2-0.4-0.3-0.6-0.5l-0.1-0.1c-0.2-0.1-0.3-0.3-0.5-0.4l0,0
		c-1-0.9-1.9-1.9-2.7-3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.6-2.5-2.1-3.7c0.3,0,0.6,0.1,0.9,0.1c1.5,0,3-0.5,4.2-1.4
		c2.2-1.6,3.2-4.3,2.7-7l-0.4-2c-1.1-5.7-1.3-11.5-0.4-17.3c1.2-4.4,3-7.1,3.2-7.4c1.8-2.4,1.9-5.5,0.2-8c-1.3-2-3.5-3.1-5.8-3.1
		c-0.6,0-1.2,0.1-1.8,0.2C11.1,10.5,1.6,27.5-0.8,36.7c0,0,0,0,0,0c-0.6,2.1-0.1,4.3,1.2,6c1,1.4,2.5,2.3,4.2,2.6
		c-0.1,0.5-0.1,0.9-0.2,1.4c0,0,0,0,0,0.1l0,0c0,0.5-0.1,0.9-0.1,1.4l0,0.1c0,0.5-0.1,0.9-0.1,1.4l0,0.1c0,0.9-0.1,1.9-0.1,2.8
		c0,0,0,0,0,0.1c0,0.5,0,0.9,0,1.4l0,0.1c0,0,0,0.1,0,0.1c0,0.4,0,0.9,0,1.3l0,0.1c0,0.9,0.1,1.8,0.2,2.7l0,0.1
		c0,0.4,0.1,0.9,0.1,1.3l0,0.1c0,0,0,0.1,0,0.1c0,0.4,0.1,0.9,0.2,1.3c0,0,0,0.1,0,0.1C4.9,62,5,62.9,5.2,63.8l0,0.1
		c0.1,0.4,0.1,0.8,0.2,1.3l0,0.1l0,0.1c0.1,0.4,0.2,0.8,0.2,1.2l0,0.1c0.2,0.8,0.4,1.6,0.5,2.4l0,0.1c0.1,0.3,0.2,0.7,0.3,1.1l0,0.2
		c0.1,0.4,0.2,0.7,0.3,1.1l0,0.1c0.2,0.8,0.4,1.5,0.7,2.2l0,0.1c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.7,0.3,1c0,0,0,0,0,0.1l0,0.1
		c0.1,0.3,0.2,0.6,0.3,0.9l0.1,0.1c0.2,0.6,0.5,1.3,0.7,1.9l6.5-2.5l-6.5,2.5c0,0,0,0,0,0.1c0.1,0.3,0.2,0.6,0.4,0.9l0.1,0.1
		c0.1,0.3,0.2,0.6,0.4,0.9c0,0,0,0.1,0,0.1c0.1,0.3,0.3,0.6,0.4,0.9c2.4,5.2,1.1,11,0.4,13.3c-0.2,0.7-0.3,1.4-0.3,2.2
		c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,2.6,1.3,5.1,3.6,6.5c0.3,0.2,0.5,0.3,0.8,0.5c0.3,0.2,0.6,0.3,0.9,0.4c0.9,0.3,1.7,0.4,2.6,0.4
		c0.8,0,1.5-0.1,2.3-0.3c0.3-0.1,0.8-0.2,1.5-0.4c0,0,0.1,0,0.1,0c1.6-0.3,3.1-0.5,4.6-0.5l0.2,0c1.2,0,2.3,0.1,3.4,0.3
		c0.1,0,0.3,0.1,0.4,0.1c0.6,0.1,1.1,0.3,1.6,0.5c0,0,0,0,0,0c0.5,0.2,0.9,0.3,1.3,0.5c2.3,1.1,4.9,2.1,7.7,2.9l0.1,0
		c0.4,0.1,0.8,0.2,1.2,0.4l0.2,0.1c0.4,0.1,0.7,0.2,1.1,0.3c1.6,0.4,3.3,0.8,4.9,1.1c0.2,0,0.5,0.1,0.7,0.1c0.8,0.2,1.6,0.3,2.4,0.4
		l0.5,0.1c0.4,0.1,0.7,0.1,1.1,0.1l0.5,0.1c1.5,0.2,3.1,0.3,4.5,0.4c0.3,0,0.6,0,0.9,0c0.8,0,1.6,0.1,2.4,0.1c0,0,0.1,0,0.1,0l0.1,0
		c2.5,0,4.9-0.2,7.3-0.5c0.1,0.3,0.1,0.6,0.3,0.9c1,2.9,3.7,4.7,6.6,4.7c0.6,0,1.1-0.1,1.7-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0
		c9.1-2.3,25.9-11.8,31.2-32.2c0.1-0.5,0.2-0.9,0.3-1.4c0.2-2.5-0.9-5-3.1-6.4c-1.2-0.8-2.5-1.2-3.9-1.2c-1.5,0-2.9,0.5-4.1,1.4
		c-0.3,0.2-3.1,2.1-7.6,3.3c-2.4,0.4-4.8,0.5-7.2,0.5c-3.3,0-6.5-0.3-9.7-1l-2-0.4c-0.5-0.1-0.9-0.1-1.4-0.1c-2.2,0-4.4,1.1-5.7,3
		c-0.5,0.7-1.5,2.5-1.2,5c-2.7-1.1-5.2-3-7.5-5.7c-0.1-0.1-0.2-0.2-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.3-0.3-0.4-0.5
		c-0.1-0.1-0.1-0.3,0-0.4l19.5-21.1c0.1,0,0.2,0,0.3,0c0.3,0,0.6,0,1,0l13.2,11.2c1.3,1.1,2.9,1.7,4.5,1.7c1.8,0,3.6-0.7,5-2.1
		l2.8-2.8l2.9-2.9c2.6-2.6,2.7-6.7,0.4-9.5L93.8,40.6c0-0.9,0-1.8-0.1-2.7l0.8-0.8l3.2,3.2c1.4,1.4,3.2,2.1,4.9,2.1s3.6-0.7,4.9-2.1
		l4-4c0.6-0.6,1.1-1.4,1.5-2.1c0.8-0.3,1.5-0.8,2.1-1.5l4-4c2.7-2.7,2.7-7.2,0-9.9l-4.8-4.8c0.9-2.9,0.5-6.1-1.2-8.8
		c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.5-0.5-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.3-0.5-0.6-0.8-0.8c-0.2-0.2-0.5-0.5-0.8-0.7
		c-0.6-0.5-1.3-0.9-2-1.3c-0.5-0.3-1.1-0.5-1.7-0.7c-0.3-0.1-0.7-0.2-1-0.3C105.7,0.1,104.9,0,104.2,0L104.2,0L104.2,0z'
        />
      </g>
    </svg>
  );
}

export default SvgKeyTrashy;
