import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Updo = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M175.73 105.86s-6.55-5.08-4.69-8.5c2.3-4.23 7.92 2.28 7.92 2.28s1.26-10.26 7.14-12.3c1.62-.56.24 7.68 1.92 12.24 0 0 4.2-15.72 10.32-15.72 1.44 0-.18 10.14-.18 10.14s4.95-9.38 10.86-6.78c1.5.66-2.1 7.5-2.1 7.5s6.59-4.98 10.62-3.18c4.56 2.04-5.28 8.04-5.28 8.04s2.99-2.01 5.94-2.19c2.06-.12 3.25 2.3 1.92 3.87-1.22 1.45-3.78 3.43-5.31 5.02'
    />
    <path
      fill='#FFF'
      d='M211.31 100.12c0 4.35 8.14-1.56 8.21-1.56.24-.84-8.21 2.13-8.21 1.56zM173.2 100.36c1.41-2.59 4.07-1.15 5.92.36.9.74 2.27.29 2.56-.84.72-2.83 2.27-7.09 5.35-8.95-.07-2.25-.18-3.84-.92-3.59-5.88 2.04-7.14 12.3-7.14 12.3s-5.62-6.51-7.92-2.28c-.9 1.65.41 3.53 2.01 5.03-.22-.69-.21-1.38.14-2.03zM190.15 100.37c1.53-3.72 5.98-11.92 8.76-13.16-2.11-8.91-11.83 12.58-11.83 12.6.05 1.72 2.41 2.15 3.07.56zM199.98 96.11c2.3-3.08 6.38-7.02 9.34-7.03.38-1.25-5.65-3.84-11.3 6.27-.54.97 1.3 1.65 1.96.76zM210.02 96.8c2.66-1.38 6.15-2.88 8.38-2.61.58-1.06-2.3-4.23-10.76.65-.01.02-.01.04-.02.07-.35 1.4 1.12 2.55 2.4 1.89z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M174.68 104.75s-5.5-3.97-3.64-7.39c2.3-4.23 7.92 2.28 7.92 2.28s1.26-10.26 7.14-12.3c1.62-.56.24 7.68 1.92 12.24 0 0 4.2-15.72 10.32-15.72 1.44 0-.18 10.14-.18 10.14s4.95-9.38 10.86-6.78c1.5.66-2.1 7.5-2.1 7.5s6.59-4.98 10.62-3.18c4.56 2.04-5.28 8.04-5.28 8.04s2.99-2.01 5.94-2.19c2.06-.12 3.25 2.3 1.92 3.87-1.22 1.45-1.53.95-3.06 2.54'
    />
    <path d='M184.33 95.79s-2.08 2.47-.8 5.85c.1.26.43.35.7.31.56-.07.85-.73.49-1.18-.7-.91-1.16-2.17-.39-4.98zM205.02 92.59s-2.88 1.41-3.13 5.01c-.02.27.24.49.5.56.54.16 1.06-.33.93-.88-.26-1.11-.15-2.44 1.7-4.69zM216.1 101.37s-.75 1.77-2.88 2.04c-.16.02-.3-.13-.35-.28-.11-.31.16-.64.49-.58.67.11 1.46 0 2.74-1.18z' />
  </svg>
);

export default Updo;
