export interface IFurException {
  name: string;
  message: string;
  stackTrace: string[];
  innerException: IFurException | null;
}

export interface ILogEntry {
  level: LogLevel;
  context: string;
  messages: unknown[];
  timestamp: string;
  exception?: IFurException | null;
}

export interface LogOptions {
  minLevels: { [context: string]: LogLevel };
}

// export type LogLevel = 'verbose' | 'debug' | 'info' | 'error' | 'warn';
export enum LogLevel {
  verbose = 1,
  debug,
  info,
  error,
  warn,
}

// Providers of logging should implement this
export interface ILogSink {
  logManager: ILogManager;

  // Main handler function. Should always do something with entry (filtering has already happened).
  logEntry: (entry: ILogEntry) => void;

  defaultSinkKey: string;
}

// Generic exposed interface to provide a standard console.log style interface
export interface ILogger {
  logManager: ILogManager;
  log: (logLevel: LogLevel, ...args: unknown[]) => void;
  verbose: (...args: unknown[]) => void;
  debug: (...args: unknown[]) => void;
  info: (...args: unknown[]) => void;
  warn: (...args: unknown[]) => void;
  error: (...args: unknown[]) => void;
}

export interface ILogManager {
  pipeToServer: (contextRoot: string, logEntry?: ILogEntry) => void;
  setSink: (sink: ILogSink) => void;
  getSink: (key: string) => ILogSink | undefined;
  getLogger: (context: string, id?: string) => ILogger;
  getLoggerForFile: (filename: string) => ILogger;
  getMinimumLogLevelForContext: (context?: string) => LogLevel;
}
