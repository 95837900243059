import React, { FunctionComponent, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@mui/material';
import theme from '../../../themev2';
import WalletButton from '../../../wallet/WalletButton';
import { IFurballDrawerProps } from './RentMarketFurballDrawer';
import { IFurball } from '../../../wallet/WalletTypes';
import { eventEmitter, useFurComponent } from '../../../utils';
import {
  SearchFurballByNumberDocument,
  SearchFurballByTokenIdDocument,
  SearchFurballsDocument,
  useClaimRentalAgreementMutation,
} from '../../../components/schema';
import { useAuthContext } from '../../../components/Auth/AuthContext';
import {
  ALERT_TYPES,
  AlertContext,
} from '../../../components/Alert/AlertContext';
import { useRoundOutPartialPartyContext } from '../RoundOutPartialParty/RoundOutPartialPartyContext';
import {
  REFRESH_EVENT,
  useScholarshipHelpers,
} from '../../../components/Scholarships';
import {
  getInventoryQuantities,
  useFurballHelpers,
} from '../../../components/Furballs';
import { useApolloClient } from '@apollo/client';
import { useAppDispatch } from '../../../hooks';
import WalletSlice from '../../../wallet/WalletSlice';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    padding: '1rem 0 0',
    display: 'grid',
    placeItems: 'center',
  },

  contractDetails: {
    margin: '0 auto',
    marginBottom: '0.75rem',
    width: '100%',
    maxWidth: '420px',
    backgroundColor: 'rgba(51, 49, 148, 0.3)',
    borderRadius: '6px',
    padding: '0.5rem 1rem',
    gap: '0.15rem !important',

    '& p': {
      fontSize: '0.8rem !important',
      color: (props: any) => (props.textColor ? props.textColor : 'inherit'),
    },
  },

  expansion: {
    inset: 0,
    position: 'absolute',
    zIndex: 10,
    backgroundColor: theme.palette.common.white,
  },

  walletButton: {
    width: '100% !important',
  },
}));

interface IProps
  extends Omit<
    IFurballDrawerProps,
    'furball' | 'id' | 'open' | 'furRate' | 'expRate' | 'backgroundColor'
  > {
  furball: IFurball;
  textColor?: string;
}

export const MarketplaceContractDetails: FunctionComponent<IProps> = ({
  furball,
  setOpen,
  textColor,
}) => {
  const { log } = useFurComponent(MarketplaceContractDetails);
  const [rentFurball, renting] = useClaimRentalAgreementMutation();

  const classes = useStyles({ textColor });
  const client = useApolloClient();
  const [claiming, setClaiming] = React.useState(false);

  const { isSignedIn } = useAuthContext();
  const { addAlert } = useContext(AlertContext);
  const { addRentedFbToParty } = useRoundOutPartialPartyContext();

  const {
    agreement,
    isOwner,
    isActiveRental,
    duration,
    playerLink,
    ownerLink,
    timeRemaining,
    endRentalAgreement,
    reservedForAnotherPlayer,
    pendingCancellation,
    loading: ending,
  } = useScholarshipHelpers(log, furball.tokenId, false, furball);
  const { refreshFurballs } = useFurballHelpers(log);
  const loading = renting.loading || ending || claiming;
  const dispatch = useAppDispatch();

  const reloadResults = async () => {
    await client.refetchQueries({
      include: [
        SearchFurballsDocument,
        SearchFurballByNumberDocument,
        SearchFurballByTokenIdDocument,
      ],
    });

    eventEmitter.emit(REFRESH_EVENT);
  };

  const refreshAfterRent = async () => {
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: `Sucessfully rented ${furball.name}`,
    });

    await refreshFurballs([furball.id]);
    //   await
    await reloadResults();

    setClaiming(false);
    setOpen();
  };

  const claimAgreement = async () => {
    const agreementId = furball?.openRentalAgreement?.id;
    if (!agreementId || loading) return;

    try {
      setClaiming(true);
      const { data } = await rentFurball({
        variables: { rentalAgreementId: agreementId },
      });

      if (data?.claimRentalAgreement?.id) {
        dispatch(
          WalletSlice.actions.addUsableFurballs([
            {
              ...furball,
              inventory: getInventoryQuantities(furball.inventory),
            },
          ]),
        );

        // auto-add party currently only present via market
        if (!!addRentedFbToParty) {
          const redirected = await addRentedFbToParty(
            furball.id,
            refreshAfterRent,
          );
          if (redirected) return;
        }

        await refreshAfterRent();
      }
    } catch (error: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Oops! Something went wrong, please retry.',
      });
    }
  };

  if (!agreement) {
    return null;
  }

  return (
    <>
      <Grid
        container
        direction='column'
        gap='1rem'
        className={classes.contractDetails}>
        <Grid container gap='0.25rem' justifyContent='space-between'>
          <Typography fontWeight='600'>
            {isOwner && !isActiveRental ? 'Reserved for' : 'Contract With:'}{' '}
          </Typography>

          {isOwner ? (
            !agreement.playerId ? (
              '-'
            ) : (
              <Typography color={theme.palette.secondary.main}>
                {playerLink}
              </Typography>
            )
          ) : (
            <Typography color={theme.palette.secondary.main}>
              {ownerLink}
            </Typography>
          )}
        </Grid>
        <Grid container gap='0.25rem' justifyContent='space-between'>
          <Typography fontWeight='600'>
            {isActiveRental ? 'Time Remaining:' : 'Duration:'}
          </Typography>
          {isActiveRental ? (
            <Typography>{timeRemaining()}</Typography>
          ) : (
            <Typography>{duration} days</Typography>
          )}
        </Grid>
        <Grid container gap='0.25rem' justifyContent='space-between'>
          <Typography fontWeight='600'>Auto Renews:</Typography>
          <Typography>{agreement.autoRenew ? 'Yes' : 'No'}</Typography>
        </Grid>
      </Grid>

      <Grid
        container
        gap='1rem'
        direction='column'
        justifyContent='center'
        alignItems='center'
        flexWrap='nowrap'
        maxWidth='100%'>
        {!isSignedIn ? (
          <WalletButton
            title='Sign in to borrow furball'
            analyticsPathComponent={'rent'}
            className={classes.walletButton}
          />
        ) : isOwner ? (
          <Button
            fullWidth
            variant='contained'
            disabled={loading || pendingCancellation}
            onClick={async () => {
              const pendingCancel = await endRentalAgreement();
              !pendingCancel && setOpen();
            }}>
            {loading ? (
              <CircularProgress size='1.4rem' color={'inherit'} />
            ) : (
              <Typography fontWeight='600' fontSize='0.85rem'>
                {isActiveRental ? 'Terminate Contract' : 'Cancel Listing'}
              </Typography>
            )}
          </Button>
        ) : (
          <Button
            fullWidth
            variant='contained'
            disabled={loading || reservedForAnotherPlayer}
            onClick={claimAgreement}>
            {loading ? (
              <CircularProgress size='1.4rem' color={'inherit'} />
            ) : (
              <Typography
                fontWeight='500'
                fontFamily={theme.typography.fontFamily?.split(',')[1]}
                fontSize='0.85rem'>
                Borrow Furball
              </Typography>
            )}
          </Button>
        )}

        {!isOwner && reservedForAnotherPlayer ? (
          <Typography
            width='25rem'
            textAlign='center'
            margin='auto'
            color={theme.palette.warning.main}>
            You can't claim this rental agreement because it has been reserved
            for another player.
          </Typography>
        ) : pendingCancellation ? (
          <Typography
            width='25rem'
            textAlign='center'
            margin='auto'
            color={theme.palette.warning.main}>
            Contract has been terminated and this agreement will be closed after
            the current contract duration is complete.
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};
