import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Bowtie = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ed053c'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M245.95 201.39c-12.71 8.92-30.26 15.25-49.64 15.25-29.65 0-55.01-14.82-65.33-31.06-.21-.33-5.52 4.26-3.55 9.56 5.75 15.52 33.72 29.89 68.64 29.89 17.04 0 33.66-1.7 44.83-8.97 8.85-5.75 5.05-14.67 5.05-14.67z'
    />
    <path
      fill='#ed053c'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M199.09 217.09s9.5-4.32 24.21-8.06c2.05-.52 4.11.87 3.95 2.65-.27 3.04-1.22 6.58-6.36 12.85 2.76 2.96 4.45 6.51 4.86 10.22l.02.2c.29 1.7-1.47 3.14-3.51 2.85-4.93-.7-14.03-3.85-22.29-9.96'
    />
    <path
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M211.47 220.24s-6.21-.18-9.81 2.3c-1 .69-.76 1.96.44 2.44 3.39 1.37 10.17 2.38 13.87 2.78'
    />
    <path
      fill='#ed053c'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M186.98 216.63s-8.45-4.84-22.78-9.72c-2-.68-4.17.54-4.17 2.34.01 3.05.65 6.44 5.22 13.1-3.01 2.73-5 6.14-5.74 9.81l-.04.2c-.44 1.68 1.19 3.25 3.25 3.12 4.98-.31 14.32-2.74 23.08-8.17'
    />
    <path
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M174.93 219.52s6.3-.4 9.67 2.35c.94.77.59 2.01-.66 2.4-3.5 1.1-10.34 1.57-14.06 1.69'
    />
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      fill='#ed053c'
      d='m195.87 229.93-6.47-.25c-2.65-.1-4.7-2.2-4.6-4.68l.32-7.25c.07-1.66 1.56-2.94 3.33-2.87l9.66.38c1.77.07 3.14 1.47 3.07 3.12l-.32 7.25c-.11 2.47-2.35 4.4-4.99 4.3z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='m195.87 229.93-6.47-.25c-2.65-.1-4.7-2.2-4.6-4.68l.32-7.25c.07-1.66 1.56-2.94 3.33-2.87l9.66.38c1.77.07 3.14 1.47 3.07 3.12l-.32 7.25c-.11 2.47-2.35 4.4-4.99 4.3z'
    />
  </svg>
);

export default Bowtie;
