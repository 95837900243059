import * as React from 'react';
import Preloader from '../Preloader';
import { useFurComponent } from '../../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useClientHardware } from './clientHardware';

interface IAppLink {}

const furballsSchema = 'furballs';

const AppLink: React.FunctionComponent<IAppLink> = (props) => {
  const { log } = useFurComponent(AppLink);
  const location = useLocation();
  const history = useHistory();
  const { isMobile } = useClientHardware();
  const path = location.hash.startsWith('#')
    ? location.hash.substr(1)
    : location.hash;
  const relPath = path.startsWith('/') ? path.substr(1) : path;
  const deepLink = `${furballsSchema}://${relPath}`;
  const redirectPath = isMobile
    ? '/app/download'
    : path.startsWith('/')
    ? path
    : `/${path}`;

  function onDeepLinkFailed() {
    log.info('[APP] not installed; redirecting to', redirectPath);
    history.push(redirectPath);
  }

  React.useEffect(() => {
    log.info('[APP] attempting deep-link', deepLink);
    try {
      window.location.href = deepLink;
    } catch (e: unknown) {
      log.info('[APP] deep link failed; redirect', e);
      onDeepLinkFailed();
    }
    const to = setTimeout(onDeepLinkFailed, 1000);
    return () => {
      clearTimeout(to);
    };
  }, [path]);

  return <Preloader title={'Redirecting...'} />;
};

export default AppLink;
