import React, { FunctionComponent } from 'react';
import { cdnRoot } from '../../theme';
import { useFurComponent } from '../../utils';

type Props = {
  eventLabel?: string;
  to: string;
  children: React.ReactNode;
};

export const CdnLink: FunctionComponent<Props> = (props) => {
  const { to, children } = props;
  const url = `${cdnRoot}${to}`;
  const { log } = useFurComponent(CdnLink);

  function handleClick(): void {
    // analytics.interactionEvent('outbound', { label: eventLabel });
    log.info('[LINK]', '[CDN]', to);
  }

  return (
    <a
      href={url}
      target={'_blank'}
      rel='noopener noreferrer'
      onClick={handleClick}>
      {children}
    </a>
  );
};

export default CdnLink;
