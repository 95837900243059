import * as React from 'react';

function SvgLootLaserPointer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <g
        fill='none'
        stroke='#8FE0A1'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path d='M117 48.4l26.9-46.5M118.5 8.7l32.1 18.6M121.2 21.6l26.7-7.2M131 4.6l7.1 26.8' />
      </g>
      <path
        fill='#A5D0D7'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M101.1 53.4l6-10.3c1.1-1.8 3.4-2.5 5.3-1.4l12.7 7.3c1.8 1.1 2.5 3.4 1.4 5.3l-6 10.3M77.6 145l-24.9-14.5c-3.2-1.8-4.2-5.9-2.4-9l38.2-65.9c1.8-3.2 5.9-4.2 9-2.4l24.9 14.5c3.2 1.8 4.2 5.9 2.4 9l-38.2 65.9c-1.8 3.1-5.8 4.2-9 2.4z'
      />
      <path
        opacity={0.2}
        d='M52.7 130.5c-3.2-1.8-4.2-5.9-2.4-9l38.2-65.9c1.8-3.2 5.9-4.2 9-2.4l11.4 6.6s-14.8-2.1-21.3 9.5-34.9 61.2-34.9 61.2z'
      />
      <path
        fill='#A5D0D7'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M81.4 110.5h0c-3.7-2.1-5-6.9-2.8-10.6l8.8-15.1c2.1-3.7 6.9-5 10.6-2.8h0c3.7 2.1 5 6.9 2.8 10.6l-8.8 15c-2.2 3.7-6.9 5-10.6 2.9z'
      />
      <path
        fill='#9FE4AF'
        d='M94 96.4c-1.4 0-2.7-.4-3.9-1-1.8-1-3.1-2.7-3.6-4.7s-.3-4.1.8-5.9c1.4-2.4 4-3.9 6.7-3.9 1.4 0 2.7.4 3.9 1 3.7 2.1 5 6.9 2.8 10.6-1.4 2.4-3.9 3.9-6.7 3.9z'
      />
      <path d='M94 82.9c1 0 2 .3 2.9.8 2.7 1.6 3.7 5.1 2.1 7.9-1 1.8-2.9 2.9-5 2.9-1 0-2-.3-2.9-.8-1.3-.8-2.3-2-2.7-3.5-.4-1.5-.2-3 .6-4.4 1.1-1.8 3-2.9 5-2.9m0-4c-3.4 0-6.6 1.7-8.5 4.9-2.7 4.7-1.1 10.6 3.5 13.3 1.5.9 3.2 1.3 4.9 1.3 3.4 0 6.6-1.7 8.5-4.9 2.7-4.7 1.1-10.6-3.5-13.3-1.5-.9-3.2-1.3-4.9-1.3z' />
    </svg>
  );
}

export default SvgLootLaserPointer;
