import React from 'react';
import { IRenderPart } from '../part';
import Corgi from './00_Corgi';
import Cat from './01_Cat';
import Gremlin from './02_Gremlin';
import Koala from './03_Koala';
import Bunny from './04_Bunny';
import Stag from './05_Stag';
import Bear from './06_Bear';
import Gorilla from './07_Gorilla';
import Dog from './08_Dog';
import Llama from './09_Llama';

export function renderEdition0Ears(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Corgi renderer={renderer} />;
  if (renderer.partNumber === 2) return <Cat renderer={renderer} />;
  if (renderer.partNumber === 3) return <Gremlin renderer={renderer} />;
  if (renderer.partNumber === 4) return <Koala renderer={renderer} />;
  if (renderer.partNumber === 5) return <Bunny renderer={renderer} />;
  if (renderer.partNumber === 6) return <Stag renderer={renderer} />;
  if (renderer.partNumber === 7) return <Bear renderer={renderer} />;
  if (renderer.partNumber === 8) return <Gorilla renderer={renderer} />;
  if (renderer.partNumber === 9) return <Dog renderer={renderer} />;
  if (renderer.partNumber === 10) return <Llama renderer={renderer} />;
  console.warn('renderEdition0Ears', renderer);
}
