import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Koala = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#d7a6d4'
      d='M120.35 131.83c5.49 7.72 13.06 6.23 21.11 3.18l.84-3.05a29.22 29.22 0 0 1 11.52-16.28l.93-.65c-24.23-32.56-48.01-2.36-34.4 16.8z'
    />
    <path
      fill='#ad86aa'
      d='M154.12 116.56c-26.15-36.54-49.2 1.22-27.6 18.96-.32-.25-9.81-14.74.36-22.32 12.72-9.48 27.24 3.36 27.24 3.36z'
    />
    <path
      fill={props.renderer.color1}
      d='M137.99 89.93c-31.45-9.2-19.96 5.46-19.96 5.46s-23.91 18.77-6.89 42.14c8.73 11.98 21.19 11.12 31.26 7.44 2.4-.88 3.84-3.32 3.49-5.84l-.65-4.57c-7.7 2.92-18.68 4.51-23.93-2.89-12.97-18.25 11.53-48.65 36.41-14.63l6.36-5.52c-22.8-39.72-26.09-21.59-26.09-21.59z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M142.82 135.05c-7.29 2.1-16.96 3.02-21.51-3.38-12.97-18.25 9.37-50.33 34.25-16.31'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M162.76 109.6c-22.8-39.72-24.77-19.67-24.77-19.67-31.45-9.2-19.96 5.46-19.96 5.46s-23.91 18.77-6.89 42.14c8.73 11.98 18.55 11.24 28.62 7.56'
    />
    <path
      fill='#d7a6d4'
      d='M272.23 131.83c-5.49 7.72-13.06 6.23-21.11 3.18l-.84-3.05a29.22 29.22 0 0 0-11.52-16.28l-.93-.65c24.23-32.56 48.02-2.36 34.4 16.8z'
    />
    <path
      fill='#ad86aa'
      d='M237.04 116.56c26.1-36.47 52.2 4.68 28.2 17.76 8.52-13.8 2.33-20.81-.6-23.76-8.79-8.84-27.6 6-27.6 6z'
    />
    <path
      fill={props.renderer.color1}
      d='M254.6 89.93c31.45-9.2 19.96 5.46 19.96 5.46s23.91 18.77 6.89 42.14c-8.73 11.98-21.19 11.12-31.26 7.44a5.419 5.419 0 0 1-3.49-5.84l.65-4.57c7.7 2.92 18.68 4.51 23.93-2.89 12.97-18.25-11.53-48.65-36.41-14.63l-6.36-5.52C251.3 71.8 254.6 89.93 254.6 89.93z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M249.77 135.05c7.29 2.1 16.96 3.02 21.51-3.38 12.97-18.25-9.37-50.33-34.25-16.31'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M229.82 109.6c22.8-39.72 24.77-19.67 24.77-19.67 31.45-9.2 19.96 5.46 19.96 5.46s23.91 18.77 6.89 42.14c-8.73 11.98-18.55 11.24-28.62 7.56'
    />
  </svg>
);

export default Koala;
