import React, { FunctionComponent } from 'react';
import { CircularProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useWalletSelector } from '../../hooks';
import { Link } from 'react-router-dom';
import { darkBlue } from '../../themev2';
import { useAuthContext } from './AuthContext';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  accountCont: {
    marginRight: '16px',
    textAlign: 'right',
    marginTop: '-6px',
  },

  a: {
    color: darkBlue,
  },

  hideOnMobile: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
}));

interface IAccountHeaderStatus {
  linkClass?: string;
  disabledClass?: string;
}

export const AccountHeaderStatus: FunctionComponent<IAccountHeaderStatus> = (
  props,
) => {
  const classes = useStyles();
  const { linkClass, disabledClass } = props;
  const { isSignedIn } = useAuthContext();
  const addr = useWalletSelector((s) => s.address);
  const { log } = useFurComponent(AccountHeaderStatus);
  const walletContents = useWalletSelector((s) => s.contents);
  const awaitingFurballs = useWalletSelector((s) => !s.usableFurballsLoaded);
  const loading = isSignedIn && awaitingFurballs;

  const accountPath = '/account';
  const canBeDisabledClass = clsx(
    classes.a,
    disabledClass
      ? {
          [disabledClass]: location.pathname === accountPath,
        }
      : {},
  );

  function accOrDiscordLink() {
    return (
      <Typography variant={'h6'} className={linkClass}>
        <Link to={accountPath} className={canBeDisabledClass}>
          {walletContents?.socialName}
        </Link>
      </Typography>
    );
  }

  return (
    <div className={clsx(classes.accountCont, classes.hideOnMobile)}>
      {loading && (
        <CircularProgress
          color={'secondary'}
          size={24}
          style={{ marginTop: 12, marginRight: 8 }}
        />
      )}
      {!loading && isSignedIn && accOrDiscordLink()}
      {!loading && isSignedIn && addr && (
        <div style={{ marginTop: -8, paddingTop: 0 }}>
          <Typography variant={'h6'} className={linkClass}>
            <Link to={'/account'} className={canBeDisabledClass}>
              {`${addr?.substr(0, 4)}...${addr?.substr(-4)}`}
            </Link>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default AccountHeaderStatus;
