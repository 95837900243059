import React, { FunctionComponent, useEffect, useState } from 'react';
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { PageInfo, RealmType, useBossBattlesLazyQuery } from '../../schema';
import useCommonStyles from '../../useCommonStyles';
import clsx from 'clsx';
import GameOverviewPlayer, { IBossBattleOverview } from './GameOverviewPlayer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '2.25rem 0.75rem 1rem 0.75rem',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    maxHeight: '400px',
    minHeight: '400px',
  },
  loading: {
    minHeight: '45px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0.5rem 0',
  },
}));

interface IGameOverview {
  battles: IBossBattleOverview[];
  pageInfo: PageInfo;
  realm: RealmType;
}

const GameOverview: FunctionComponent<IGameOverview> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();

  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
  const [game, setGame] = useState<IGameOverview>(props);
  const [getNextBattlesBatch, { loading }] = useBossBattlesLazyQuery({
    variables: { endCursor: getEndCursor(), realm: game.realm },
  });

  const observer = new IntersectionObserver(
    (entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && game.pageInfo.hasNextPage) {
        loadGameBatch();
      }
    },
    {
      rootMargin: '0px',
      threshold: 0,
    },
  );

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  async function loadGameBatch() {
    try {
      const result = await getNextBattlesBatch();

      const { data } = result;
      if (data && data.bossBattles) {
        const { nodes, pageInfo } = data.bossBattles;
        const nextBattles = nodes as IBossBattleOverview[];
        setGame({
          ...game,
          pageInfo,
          battles: [...game.battles, ...nextBattles],
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  function getEndCursor() {
    const { hasNextPage, endCursor } = game.pageInfo;
    if (!hasNextPage || !endCursor) return '';
    return endCursor;
  }

  function renderGames() {
    return game.battles.map((recentBossBattle, i) => {
      return (
        <div
          key={`boss-battle-${recentBossBattle.id}`}
          ref={i === game.battles.length - 1 ? setLastElement : null}>
          <GameOverviewPlayer recentBossBattle={recentBossBattle} />
        </div>
      );
    });
  }

  return (
    <div className={clsx(classes.root, common.hideScrollBar)}>
      {renderGames()}
      {game.pageInfo.hasNextPage && (
        <div className={classes.loading}>
          {loading && <CircularProgress size={30} color='secondary' />}
        </div>
      )}
    </div>
  );
};

export default GameOverview;
