import React, { FunctionComponent } from 'react';

import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { useSkillLevelControls } from './hooks';
import { IUpgradeControlProps } from './SkillTypes';
import { getSkillDefinitionById } from './SkillLibrary';
import { SkillStatEffect } from '../../components/schema';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    backgroundColor: 'rgba(51, 49, 148, 0.3)',
    width: '100%',
    padding: '0 1rem',

    '&:not(:last-child)': {
      borderBottom: '1px solid #010a59a5',
    },
  },

  label: {
    position: 'absolute',
    top: '0',
    left: '0',
    padding: '0.1rem 1rem 0.3rem',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#5B4ED9',
    fontSize: '0.9rem !important',
  },

  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4rem 1rem 4.5rem',
  },

  buttonLabel: {
    position: 'absolute',
    width: '5rem !important',
    top: 'calc(100% + 0.3rem)',
  },

  buttonContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  ctrlButton: {
    width: '3.5rem !important',
    height: '3.5rem !important',
    borderRadius: '8px !important',
    display: 'grid',
    placeItems: 'center',
    padding: '0 !important',
    fontSize: '3rem !important',
    lineHeight: '1 !important',
    color: '#29258F !important',
    transition: 'all 0.2s linear',
    minWidth: 'unset !important',

    '& span': {
      font: 'inherit',
      position: 'relative',
      top: '-0.15rem',
    },

    '&[data-type="minus"]': {
      backgroundColor: '#E5E6FC',
      border: '4px solid #6d70a4 !important',

      '&:hover': {
        backgroundColor: '#E5E6FC !important',
        opacity: '0.8',
        transition: 'all 0.2s linear',
      },
    },

    '&[data-type="plus"]': {
      backgroundColor: '#7DF5B7',
      border: '4px solid #3A9A6A !important',

      '&:hover': {
        backgroundColor: '#7DF5B7 !important',
        opacity: '0.8',
        transition: 'all 0.2s linear',
      },
    },

    '&:disabled': {
      opacity: 0.5,
    },
  },

  progressWrap: {
    width: '100%',
    height: '1.5rem',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gap: '0.25rem',
    position: 'relative',
    justifyContent: 'center',
  },

  progressBarIndicator: {
    background: '#0A1062 !important',
  },

  progressBar: {
    background: 'rgba(159, 168, 218, 0.4)',
    transition: 'all 0.2s linear',
  },

  progressBarFilled: {
    background: '#7DF5B7',
  },

  progressBarHalfFilled: {
    background: `repeating-linear-gradient(
      -45deg,
      #0E1775,
      #0E1775 3px,
      #7DF5B7 3px,
      #7DF5B7 6px
    )`,
  },

  progressBarDim: {
    opacity: 0.7,
  },

  marketButton: {
    display: 'none',
  },

  btnLabel: {
    fontSize: '0.95rem !important',
  },

  pendingText: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '140%',
    width: '100%',
    padding: '0 2rem',
    textAlign: 'center',
    color: '#fff',
    lineHeight: '1.4 !important',
    fontSize: '0.8rem !important',
  },

  pendingLink: {
    color: '#7DF5B7 !important',
    transition: 'all 0.1s linear',

    '&:hover': {
      color: '#7DF5B7 !important',
      textDecoration: 'none',
      opacity: 0.9,
      letterSpacing: '0.02rem',
    },
  },
}));

export const SkillLevelControl: FunctionComponent<IUpgradeControlProps> = ({
  label,
  skill,
  partials,
  setPartials,
  partialsSelected,
  setPartialsSelected,
  furballId,
  isLoading,
  isOnCooldown,
  getCooldownRemaining,
  numUpgraded,
  maxDailyUpgrades,
}) => {
  const { bars, params, canAfford, handleUpgrade, getBarStatus } =
    useSkillLevelControls({
      skill,
      partials,
      setPartials,
      partialsSelected,
      setPartialsSelected,
      furballId,
      numUpgraded,
      maxDailyUpgrades,
    });

  // const skillIsOnCooldown = isOnCooldown(SkillType.Level);
  const skillDefinition = getSkillDefinitionById(skill.skillDefinitionId);
  const { value, valuePerLevel } = skillDefinition
    ?.effectBonuses[0] as SkillStatEffect;
  const upDisabled =
    !canAfford || isLoading || !skill.levelIsUpgradeable || params.isMaxLevel;
  const downDisabled =
    isLoading ||
    !skill.levelIsUpgradeable ||
    params.noChanges ||
    skill.levelTotalPartialsRemaining === 0;
  const curSkillVal = value + params.currentLevel * valuePerLevel;

  const classes = useStyles();

  const [mouseDown, setMouseDown] = React.useState(0);
  React.useEffect(() => {
    if (mouseDown === 0) return;
    const dir = mouseDown < 0 ? -1 : 1;
    const to = setTimeout(
      () => {
        if (dir < 0 && !downDisabled) handleUpgrade('down');
        if (dir > 0 && !upDisabled) handleUpgrade('up');
        setMouseDown(mouseDown + dir);
      },
      Math.abs(mouseDown) === 1 ? 1000 : 30,
    );
    return () => {
      clearTimeout(to);
    };
  }, [mouseDown]);

  function renderHelperText() {
    if (!skill.levelIsUpgradeable) {
      return (
        <Typography
          variant='subtitle2'
          color='#7DF5B7'
          className={classes.pendingText}>
          Not Upgradable!
        </Typography>
      );
    }

    return null;
  }

  return (
    <div className={classes.root}>
      <Typography
        variant='body1'
        fontWeight={500}
        className={classes.label}
        color='#ffff'>
        {label}
      </Typography>
      <div className={classes.wrapper}>
        <Grid
          item
          alignItems='center'
          justifyContent='center'
          className={classes.buttonContainer}>
          <Button
            data-type='minus'
            data-level-minus
            disabled={downDisabled}
            onMouseDown={() => setMouseDown(-1)}
            onMouseUp={() => setMouseDown(0)}
            onMouseLeave={() => setMouseDown(0)}
            className={classes.ctrlButton}
            onClick={() => handleUpgrade('down')}>
            <span>-</span>
          </Button>

          {skill.levelIsUpgradeable &&
            skill.levelTotalPartialsRemaining !== 0 &&
            !params.isMaxLevel && (
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.buttonLabel}>
                <Typography
                  className={classes.btnLabel}
                  textAlign='center'
                  fontWeight='600'
                  color='#8785D2'>
                  LVL {params.currentLevel}
                </Typography>
                <Typography
                  className={classes.btnLabel}
                  textAlign='center'
                  fontWeight='600'
                  color='#8785D2'>
                  ({curSkillVal})
                </Typography>
              </Grid>
            )}
        </Grid>

        <div
          style={{ gridTemplateColumns: `repeat(${bars?.length}, 1fr)` }}
          className={classes.progressWrap}>
          {bars?.map((i) => (
            <div
              key={i}
              className={clsx(classes.progressBar, getBarStatus(i), {
                [classes.progressBarDim]: !skill.levelIsUpgradeable,
              })}></div>
          ))}

          {renderHelperText()}
        </div>

        <Grid
          item
          alignItems='center'
          justifyContent='center'
          className={classes.buttonContainer}>
          <Button
            disabled={upDisabled}
            data-type='plus'
            data-level-plus
            className={classes.ctrlButton}
            onMouseDown={() => setMouseDown(1)}
            onMouseUp={() => setMouseDown(0)}
            onMouseLeave={() => setMouseDown(0)}
            onClick={() => handleUpgrade('up')}>
            <span>
              {isLoading ? <CircularProgress size={20} color='inherit' /> : '+'}
            </span>
          </Button>

          {skill.levelIsUpgradeable && (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.buttonLabel}>
              <Typography
                className={classes.btnLabel}
                textAlign='center'
                fontWeight='600'
                color='#ffff'>
                LVL{' '}
                {params.isMaxLevel
                  ? params.currentLevel
                  : params.currentLevel + 1}
              </Typography>
              <Typography
                className={classes.btnLabel}
                textAlign='center'
                fontWeight='600'
                color='#8785D2'>
                ({curSkillVal + valuePerLevel})
              </Typography>
              {params.isMaxLevel && (
                <Typography
                  className={classes.btnLabel}
                  textAlign='center'
                  fontWeight='600'
                  color='#7DF5B7'>
                  MAX
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
