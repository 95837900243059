import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook } from 'react-redux';
import { useAppSelector } from '../../hooks';
import {
  FurballsMetaFragment,
  // DateTimeScalarConfig,
  Scalars,
  // ShopWaresFragment,
  TimekeeperInteractionType,
  VoyageEncounterType,
} from '../schema';
import { IFurball, IVoyageEncounter } from '../../wallet/WalletTypes';
import { StatAbbreviation } from '../Stats';
import { inIframe } from '../../wallet/Wallet';

export const ethPerTix = 0.001;

export const useTimekeeperSelector: TypedUseSelectorHook<ITimekeeperState> = (
  a,
) => {
  return useAppSelector((s) => a(s.timekeeper));
};

export interface ICostSpread {
  cost1: number;
  costN?: number;
}

export interface ITimekeeperArgs {
  fuelCost: number;
  gained: number;
  spent: number;
  mintEdition: number;
  mintCount: number;
  movementList: string;
  collectStayList: string;
  deadline?: number;
}

export interface ITimekeeperInput {
  fuelCost: number;
  gained: number;
  spent: number;
  mintEdition: number;
  mintCount: number;
  movements: string[];
}

export interface ITimekeeperInteractionBase {
  interactionType: TimekeeperInteractionType;
  furballIds: string[];
  expectedValue: number | null;
  count: number | null;
  id?: string;
  sourceInteractionId?: string | null;
}

export interface IShopSnack {
  id: number;
  name: string;
  furCost: number;
  fuelCost1: number;
  fuelCostN: number;
  happiness: number;
  energy: number;
  saleBeginsAt: number;
  saleEndsAt: number;
}

export interface IShopWares {
  id: number;
  name: string;
  furCost: number;
  fuelCost1: number;
  fuelCostN: number;
  maxPerFurball: number;
  saleBeginsAt: number;
  saleEndsAt: number;
  itemIds: number[];
  itemList: string;
}

export interface ITimekeeperInteraction extends ITimekeeperInteractionBase {
  // sender: string;
  furballFuel: number[];
  fuelCost: number;
  gasEstimate: number;
}
//
// export interface ITimekeeperChanges {
//   date: number;
//   interactions: ITimekeeperInteraction[];
// }

export interface ITimekeeperRequestCore {
  date: number;
  partialIndex: number;
  hasChanges: boolean;
  state: number;
}

export interface ITimekeeperChangeSet extends ITimekeeperArgs {
  interactions: ITimekeeperInteraction[];
}

export interface ITimekeeperResolution {
  tokenId: string;
  date: number;
  furGained: number;
  expGained: number;
  intervals: number;
  zoneNumber: number | null;
  isEmpty: boolean;
  itemList: string;
  createdAt: number;
  updatedAt: number;
  completedAt?: number;
}

export interface ITimekeeperResolutionRequest extends ITimekeeperResolution {
  request: ITimekeeperRequestBase;
}

export interface ITimekeeperRequestBase extends ITimekeeperRequestCore {
  furReal?: number | null;
  state: number;
  changeSet: ITimekeeperChangeSet | null;
  signedRequest?: ITimekeeperChangeSet | null;
  resolvedAt?: Scalars['DateTime'] | null;
}

export interface ITimekeeperRequest extends ITimekeeperRequestBase {
  resolutions: ITimekeeperResolution[];
}

export interface ITimekeeperCosts {
  date: number;
  factor: number;
  spendTickets: number; // Starting point for each player
  spendFur: number; // buying anything w/ fur (upgrade, mint, snack)
  collectFur: number; //
  collectExp1: number;
  collectExpN: number;
  move1: number;
  moveN: number;
  snack1: number;
  snackN: number;
  upgrade1: number;
  upgradeN: number;
  mint1: number;
  mintN: number;
  dropLoot: number;
  completeVoyage: number;
  enterVoyages: number;
  upgradeSkill: number;
}

// date
// state
// ethPrice
// gasPrice
// numRequests
// curRequest
// costs { ...TimekeeperCosts }
export interface ITimekeeperRound {
  date: number;
  state: number;
  ethPrice: number;
  gasPrice: number;
  costs: ITimekeeperCosts;
}

export interface ITimekeeperUserState {
  sender?: string;
  round?: ITimekeeperRound;
  current: ITimekeeperRequest[];
  pending: ITimekeeperRequestCore[];
}

export interface ISnackSelection {
  furballs: IFurball[];
  stats: StatAbbreviation[]; // the stats we care about
  close: () => void;
}

export interface ITimekeeperState {
  loading?: boolean;
  meta?: FurballsMetaFragment;
  userState: ITimekeeperUserState;
  snackSelection?: ISnackSelection;
  reload?: boolean;
  voyageEncounterReward?: IVoyageEncounter;
}

export const emptyChangeSet: ITimekeeperChangeSet = {
  fuelCost: 0,
  gained: 0,
  spent: 0,
  mintEdition: 0,
  mintCount: 0,
  movementList: '',
  collectStayList: '',
  interactions: [],
};

const defaultInput: ITimekeeperArgs = {
  fuelCost: 0,
  gained: 0,
  spent: 0,
  mintCount: 0,
  mintEdition: 0,
  movementList: '',
  collectStayList: '',
};

const initialState: ITimekeeperState = {
  userState: { pending: [], current: [] },
};

export function isNewlyCompletedDilemmaEncounter(
  encounter?: IVoyageEncounter,
): boolean {
  if (inIframe()) return false;
  if (!encounter) return false;
  const isCompletedDilemma =
    encounter.encounterType === VoyageEncounterType.Fur &&
    encounter.outcome !== null &&
    encounter.match;

  if (!isCompletedDilemma) return false;

  const key = `ve_${encounter.id}`;
  return !localStorage.getItem(key);
}

export function setCompletedDilemmaEncounter(
  encounter: IVoyageEncounter,
): void {
  const key = `ve_${encounter.id}`;
  if (!inIframe()) {
    localStorage.setItem(key, '1');
  }
}

const timekeeperSlice = createSlice({
  name: 'timekeeper',
  initialState,
  reducers: {
    // setInput: (state, action: PayloadAction<ITimekeeperArgs | undefined>) => {
    //   const input = { ...(action.payload ?? defaultInput) };
    //   if (state.userState.round?.costs) input.tickets = getFuelCost(input, state.userState.round?.costs);
    //   state.input = input;
    // },
    setSnackSelection: (
      state,
      action: PayloadAction<ISnackSelection | undefined>,
    ) => {
      state.snackSelection = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUserState: (state, action: PayloadAction<ITimekeeperUserState>) => {
      state.userState = action.payload;
    },
    setMeta: (state, action: PayloadAction<FurballsMetaFragment>) => {
      state.meta = action.payload;
    },
    setCurrentRequests: (
      state,
      action: PayloadAction<ITimekeeperRequest[]>,
    ) => {
      state.userState.current = action.payload;
    },
    deleteCurrentRequest: (state, action: PayloadAction<number>) => {
      for (let i = 0; i < state.userState.current.length; i++) {
        if (state.userState.current[i].partialIndex === action.payload) {
          state.userState.current.splice(i, 1);
          return;
        }
      }
    },
    updateCurrentRequest: (
      state,
      action: PayloadAction<ITimekeeperRequest>,
    ) => {
      const req = action.payload;
      for (let i = 0; i < state.userState.current.length; i++) {
        if (
          state.userState.current[i].date === req.date &&
          state.userState.current[i].partialIndex === req.partialIndex
        ) {
          state.userState.current[i] = req;
        }
      }
    },
    setReload: (state, action: PayloadAction<boolean>) => {
      state.reload = action.payload;
    },
    updateActiveEncounter: (
      state,
      action: PayloadAction<IVoyageEncounter | undefined>,
    ) => {
      const encounter = action.payload;

      if (
        state.voyageEncounterReward &&
        state.voyageEncounterReward.id !== encounter?.id
      ) {
        setCompletedDilemmaEncounter(state.voyageEncounterReward);
      }
      if (isNewlyCompletedDilemmaEncounter(encounter)) {
        state.voyageEncounterReward = encounter;
      } else {
        state.voyageEncounterReward = undefined;
      }
    },
  },
});

export default timekeeperSlice;
