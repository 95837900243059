import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Container } from '@mui/material';
import { useAuthContext } from '../../components/Auth/AuthContext';

import WalletButton from '../../wallet/WalletButton';
import useHeroStyles from '../../components/useHeroStyles';
import { usePlayersReadyFurballs, usePlayGamePath } from '../../wallet';
import useCommonStyles from '../../components/useCommonStyles';
import SvgLogo from '../../assets/SvgLogo';
import { useWindowSize } from '../../components/useWindowSize';
import { playGameVerb } from '../Furverse/Lobby/BossGamePrepare';

interface OwnProps {}

type Props = OwnProps;

export interface IHomepageExperimentCopy {
  title: React.ReactNode;
  subtitle: string;
  buyButton: string;
  borrowButton: string;
}

// const homepageCopies: { [key: string]: IHomepageExperimentCopy } = {
//   Original: {
//     title: 'THE 1ST REAL-TIME STRATEGIC NFT',
//     subtitle:
//       'Buy or borrow Furballs, create battle parties, and conquer bosses!',
//     buyButton: 'BUY A FURBALL',
//     borrowButton: 'BORROW FURBALL',
//   },
//   // PlayFree: {
//   //   title: 'PLAY FURBALLS FOR FREE',
//   //   subtitle: 'Earn ETH, $FUR & in-game rewards.',
//   //   buyButton: 'BUY A FURBALL',
//   //   borrowButton: 'BORROW FURBALL',
//   // },
//   // FightStrategy: {
//   //   title: (
//   //     <>
//   //       FIGHT BOSSES.
//   //       <br />
//   //       CLIMB LEADERBOARDS.
//   //       <br />
//   //       EARN PRIZES.
//   //     </>
//   //   ),
//   //   subtitle: 'Buy or Borrow a Furball NFT and play for free!',
//   //   buyButton: 'BUY A FURBALL',
//   //   borrowButton: 'BORROW FOR FREE',
//   // },
// };

const HeroV2: FunctionComponent<Props> = (props) => {
  const size = useWindowSize();
  const classes = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });
  const common = useCommonStyles();
  const history = useHistory();

  const playersFurballs = usePlayersReadyFurballs();
  const hasUsableFurball = playersFurballs.length > 0;
  const { authState, isSignedIn } = useAuthContext();
  const username = authState.playerUsername;
  const possessiveNoun = username ? `@${username}'s` : 'My';
  // const treatmentIdx = useExperiment('iD2cS6n3Q7WGShxuaCfLCw');
  // const treatmentGroup = ['Original', 'PlayFree', 'FightStrategy'][
  //   treatmentIdx
  // ];
  // const treatmentGroup = 'PlayFree';
  // const copies = homepageCopies['Original'];
  const playGamePath = usePlayGamePath();

  return (
    <div className={classes.background}>
      <Container maxWidth={'sm'}>
        <Grid container className={classes.header} justifyContent={'center'}>
          <Grid item xs={12}>
            <SvgLogo />
            <WalletButton
              className={classes.playButton}
              analyticsPathComponent={'hero'}
              title={'Play For Free'}
              hideLoading={true}>
              <Button
                variant={'contained'}
                color={'primary'}
                // disabled={!isNetworkLive}
                className={classes.playButton}
                onClick={() => history.push(playGamePath)}>
                {playGameVerb}
              </Button>
            </WalletButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default HeroV2;
