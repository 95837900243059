import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';
import * as React from 'react';
import useCommonStyles from '../../../components/useCommonStyles';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  DuelBattleFragment,
  useCreateDuelMutation,
  useJoinDuelMutation,
  useListOpenDuelsLazyQuery,
} from '../../../components/schema';
import { useTryCatchAsync, useTryCatchParamAsync } from '../../../utils/Errors';
import { IFightArgs } from './Lobby';
import { useFurverseContext } from '../Furverse';
import { useWalletSelector } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));
interface IDuelOpenGamesCard {}

const DuelOpenGamesCard: React.FunctionComponent<IDuelOpenGamesCard> = (
  props,
) => {
  const common = useCommonStyles();
  const classes = useStyles();
  const { sendGameMessage } = useFurverseContext();
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const [
    loadGames,
    { data: loadedGames, loading: loadingGames, error: loadError },
  ] = useListOpenDuelsLazyQuery();
  const [doCreateGame, createdGame] = useCreateDuelMutation();
  const [doJoinGame, joinedGame] = useJoinDuelMutation();
  const isLoading = loadingGames || createdGame.loading || joinedGame.loading;
  const [games, setGames] = React.useState<DuelBattleFragment[]>([]);
  const pendingGame = games.find((g) => g.creatorId === playerId);
  const partyId = '';

  React.useEffect(() => {
    loadGames();
  }, []);

  React.useEffect(() => {
    setGames(loadedGames?.duelBattles?.nodes ?? []);
  }, [loadedGames]);

  function openGame(g: DuelBattleFragment) {
    const fightArgs: IFightArgs = {
      realm: g.battleRealm,
      partyId,
      battleType: 'Duel',
    };
    // setOptionalArgs({ trialGame, realm, partyId, battleType: gameType });
    sendGameMessage({
      topic: 'Fight',
      data: { fightArgs },
    });
  }

  const createGame = useTryCatchAsync(
    'CreateDuel',
    async () => {
      const d = await doCreateGame({
        variables: { partyId, rules: null, ante: null },
      });
      const game = d.data?.duel;
      if (game) setGames([game, ...games]);
      else throw 'No create duel returned';
    },
    [partyId],
  );

  const joinGame = useTryCatchParamAsync(
    'JoinDuel',
    async (gameBattleId: string) => {
      const d = await doJoinGame({ variables: { partyId, gameBattleId } });
      const game = d.data?.duel;
      if (game) openGame(game);
      else throw 'No join duel returned';
    },
    [partyId],
  );

  return (
    <Card
      className={common.cardRoot}
      style={{
        width: '100%',
      }}>
      <CardHeader
        className={common.cardHeader}
        // classes={{ title: classes.cardTitle }}
        sx={{ minHeight: '62px' }}
        title={games.length > 0 ? 'Join PvP Game' : 'Player vs. Player'}
        action={
          createdGame.loading ? (
            <CircularProgress color={'primary'} style={{ marginTop: 6 }} />
          ) : (
            <Button
              className={common.actionButton}
              style={{ marginTop: 8 }}
              disabled={!!pendingGame || !!joinedGame.loading}
              variant={'contained'}
              color={'primary'}
              onClick={createGame}>
              New Game
            </Button>
          )
        }
      />
      <CardContent className={common.cardContent}>
        {games.length > 0 && <></>}
        {!loadingGames && games.length <= 0 && (
          <>
            <Typography variant={'h4'}>
              There are currently no players waiting for a partner.
            </Typography>
            <Typography variant={'subtitle2'} color={'gray'}>
              Press the button in the upper-right to create a new PvP game.
            </Typography>
          </>
        )}
        {loadingGames && games.length <= 0 && (
          <CircularProgress color={'primary'} />
        )}
      </CardContent>
    </Card>
  );
};

export default DuelOpenGamesCard;
