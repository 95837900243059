import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Cowlick = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M207.55 102.53c-.18-4.77 5.11-7.55 5.11-7.55-4.99-2.35-12.97.6-12.97.6l4.65-5.32c-16.55 1.75-18.85 12.23-18.85 12.23'
    />
  </svg>
);

export default Cowlick;
