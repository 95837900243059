import {
  Container,
  Grid,
  Typography,
  CircularProgress,
  CardHeader,
  Card,
  CardContent,
} from '@mui/material';
import * as React from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useCreateRandomPartyMutation } from '../../../components/schema';
import useCommonStyles, {
  useAppBarHeight,
} from '../../../components/useCommonStyles';
import useHeroStyles from '../../../components/useHeroStyles';
import { useWindowSize } from '../../../components/useWindowSize';
import { purple } from '../../../themev2';
import ZoneButton from '../../../components/Zones/ZoneButton';
import { useFurComponent } from '../../../utils';
import {
  AlertContext,
  ALERT_TYPES,
} from '../../../components/Alert/AlertContext';
import { getFightBossLink } from '../../Furverse/Lobby';
import { useAppDispatch } from '../../../hooks';
import { usePageTitle } from '../../../Analytics';
import { useAuthContext } from '../../../components/Auth/AuthContext';
import Preloader from '../../Preloader';
import { useBossBattle } from '../../Furverse/useBossBattle';
import { StartGameFunc } from '../../Furverse/Lobby';
import { usePlayGamePath } from '../../../wallet';

interface IRentMarketGenerateParty {
  startGame: StartGameFunc;
}

const RentMarketGenerateParty: React.FunctionComponent<
  IRentMarketGenerateParty
> = (props) => {
  const common = useCommonStyles();
  const { isSignedIn } = useAuthContext();
  const [createRandomParty, createdPartyResponse] =
    useCreateRandomPartyMutation();
  const size = useWindowSize();
  const hero = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });
  const barHeight = useAppBarHeight();
  const { log } = useFurComponent(RentMarketGenerateParty);
  const { addAlert } = React.useContext(AlertContext);
  const history = useHistory();
  const playPath = usePlayGamePath();
  const dispatch = useAppDispatch();
  // const playerParties = playerPartiesQuery.data?.playerParties ?? [];

  const { onPartyUpdated, allParties, loading } = useBossBattle();
  const playableParties = allParties.filter(
    (p) => (p.partyMembers?.length ?? 0) >= 3,
  );
  // const doesNotHaveParty = !playerPartiesQuery.loading && playableParties.length <= 0;

  usePageTitle('Quick Start | Furballs');

  async function createParty() {
    try {
      const res = await createRandomParty();
      log.info('party', res);
      if (!res.data) throw 'No data returned';
      const { party, realm } = res.data.createRandomParty;

      // Assigns furball data + party data
      onPartyUpdated(party);

      const link = getFightBossLink(realm, party);
      history.push(link);
    } catch (e: any) {
      log.warn(e, 'Random Party');
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e?.message ?? 'Unable to generate party',
      });
    }
  }

  if (!isSignedIn) return <Redirect to={'/'} />;
  if (playableParties.length >= 1) {
    log.info('[ONBOARD] has', allParties.length, 'parties');
    return <Redirect to={playPath} />;
  }

  return (
    <div className={common.fullFur}>
      {loading && <Preloader title={'Loading Parties...'} />}
      <Container maxWidth={'xs'} style={{ marginTop: barHeight + 20 }}>
        {!loading && (
          <Card>
            <CardHeader
              className={common.cardHeader}
              title={'3+ Furballs Required...'}
            />
            <CardContent>
              <Grid container justifyContent={'center'}>
                {!createdPartyResponse.loading && (
                  <Grid item xs={12}>
                    <ZoneButton
                      onClick={() => void createParty()}
                      text={'Choose For Me'}
                      style={{ width: '100%' }}
                    />
                    <br />
                    <br />
                    <Typography variant={'h5'}>
                      Want to pick your own Furball party?
                    </Typography>
                    <Link to={'/market/furballs/get'}>
                      <Typography
                        variant={'body1'}
                        style={{
                          color: purple.dark,
                          textDecoration: 'underline',
                        }}>
                        Explore the Furball marketplace
                      </Typography>
                    </Link>
                  </Grid>
                )}
                {createdPartyResponse.loading && (
                  <Grid item xs={12}>
                    <CircularProgress color={'secondary'} />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default RentMarketGenerateParty;
