import React, { FunctionComponent } from 'react';
import { IToken } from '../../wallet/WalletTypes';
import { useLoadedFurball } from '../../wallet';
import { Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { IRasterizeOpts, svgToPng } from '../../components/Furballs/Avatars';
import { FurballRenderDisabledSlots } from '../../components/Furballs/FurballRenderer';
import FurballImg from '../../components/Furballs/FurballImg';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    display: 'inline-block',
  },

  container: {
    overflow: 'hidden',
    cursor: (props: any) => (props.displayOnly ? 'default' : 'pointer'),
    transition: 'all 0.2s linear',

    '&:hover': {
      boxShadow: (props: any) =>
        props.displayOnly ? 'none' : 'inset 0 0 1000px 1000px #00000021',
    },
  },

  furball: {
    position: 'relative',
  },
}));

export interface IPictureOpts {
  size: number;
  zoom: number;
  moveX: number;
  moveY: number;
  circle?: boolean;
  border?: boolean;
}

export const defaultPictureOpts: IPictureOpts = {
  size: 400,
  zoom: 1.5,
  moveX: -20,
  moveY: 40,
  circle: true,
  border: true,
};

type Props = IToken & {
  opts?: IPictureOpts;
  saveSize?: number;
  disableSlots?: FurballRenderDisabledSlots;
  clickRef?: React.RefObject<HTMLImageElement>;
  displayOnly?: boolean;
};

export const FurballAvatar: FunctionComponent<Props> = (props) => {
  const { tokenId, saveSize, disableSlots, displayOnly } = props;
  const opts = props.opts ?? defaultPictureOpts;
  const classes = useStyles({ displayOnly });
  const { log } = useFurComponent(FurballAvatar);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const furball = useLoadedFurball(tokenId);
  const bk = furball?.backgroundColor ?? undefined;
  const scale = opts.size / 350;
  const radius = opts.circle ? opts.size / 2 : undefined;
  const showBorder = !radius && opts.border !== false;
  const [png, setPng] = React.useState('');
  const rasterizer: IRasterizeOpts = {
    size: opts.size,
    zoom: opts.zoom,
    moveX: opts.moveX,
    moveY: opts.moveY,
  };

  if (!furball) return null;

  async function download() {
    if (!imageRef.current || !furball) return;
    // const res = await rasterizeElement(imageRef.current, { width: opts.size, height: opts.size });
    const html = imageRef.current.innerHTML;
    const svgs = html
      .split('</svg')
      .map((svgHtml) => {
        const i = svgHtml.indexOf('<svg');
        if (i < 0) return undefined;
        svgHtml = svgHtml.substr(i + 4);
        return svgHtml.substr(svgHtml.indexOf('>') + 1);
      })
      .filter((s) => s);
    const svg =
      '<svg width="350" height="350" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 350" >' +
      svgs.join('') +
      '</svg>';

    const pictureOpts = {
      ...rasterizer,
      size: saveSize ?? rasterizer.size,
      circle: opts.circle,
    };

    const res = await svgToPng(
      svg,
      pictureOpts,
      (disableSlots ?? {})['Background']
        ? undefined
        : '#' + furball.backgroundColor,
      log,
    );

    const element = document.createElement('a');
    element.setAttribute('href', res);
    element.setAttribute(
      'download',
      `furball${furball.number}-${pictureOpts.size}.png`,
    );
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const avatar = (
    <div
      ref={props.clickRef}
      className={classes.container}
      onClick={() => !displayOnly && void download()}
      style={{
        backgroundColor: (disableSlots ?? {})['Background']
          ? 'transparent'
          : '#' + bk,
        width: opts.size,
        height: opts.size,
        border: showBorder ? '3px solid #000' : undefined,
        clipPath: radius ? `circle(${radius}px at center)` : undefined,
        // borderRadius: `${opts.size/4}`,
      }}>
      <div
        className={classes.furball}
        ref={imageRef}
        style={{
          transform: `scale(${opts.zoom}, ${opts.zoom}) translate(${
            opts.moveX * scale
          }px, ${opts.moveY * scale}px)`,
          // left: rasterizer.moveX * scale,
          // bottom: rasterizer.moveY * scale,
        }}>
        <FurballImg
          tokenId={furball.tokenId}
          size={opts.size}
          disableSlots={disableSlots}
        />
      </div>
    </div>
  );

  return <div className={classes.root}>{avatar}</div>;
};

export default FurballAvatar;
