import { SvgEditionSlot } from '../part';
import * as React from 'react';

const HappyHeart = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffa6a9'
      d='M196.44 168.66c-6.81 0-12.41-2.25-13.15-6.14-.06.61-.09 1.22-.09 1.85 0 9.05 5.93 16.39 13.24 16.39s13.24-7.34 13.24-16.39c0-.63-.03-1.24-.09-1.85-.74 3.9-6.35 6.14-13.15 6.14z'
    />
    <path d='M196.44 173.31c4.19 0 7.64 1.5 8.05 3.56 3.16-2.73 5.19-7.01 5.19-11.84 0-.57-.03-1.13-.09-1.68-.74 3.54-6.35 5.59-13.15 5.59-6.81 0-12.41-2.04-13.15-5.59-.06.55-.09 1.11-.09 1.68 0 4.83 2.04 9.11 5.19 11.84.41-2.06 3.85-3.56 8.05-3.56z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196.44 168.97c-6.81 0-13.24-5.23-13.24-4.6 0 9.05 5.93 16.39 13.24 16.39s13.24-7.34 13.24-16.39c0-.63-6.44 4.6-13.24 4.6zM196 156v19'
    />
    <path
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M203.41 147.83a4.116 4.116 0 0 0-5.81 0l-1.16 1.16-1.16-1.16a4.116 4.116 0 0 0-5.81 0 4.098 4.098 0 0 0 0 5.81l1.16 1.16 5.81 5.81 5.81-5.81 1.16-1.16c1.61-1.6 1.61-4.2 0-5.81z'
    />
  </svg>
);

export default HappyHeart;
