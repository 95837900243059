import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Fur = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#cfb39d'
      d='M131.92 187s-7.74 2.22-6.96 13.32c3.48 49.32 65.28 45.6 65.28 45.6s18.27-7.04 1.07-32.38c-10.43-15.38-30.23 3.1-59.39-26.54z'
    />
    <path
      fill='#e8cdcc'
      d='M153.47 209.04c-10.47-2.04-18.29-15.01-18.15-14.63.85 2.34 7.21 11.79 9.23 13.86 2.59 2.66 6.33 9.64 6.33 9.4.1-3.17-.85-5.93-2.59-8.4 1.8 1.56 3.17 3.46 4.2 5.77 1.06 2.37 1.1 5.29 1.93 7.77.11.33.6-6.5-.79-9.52-.31-.68-.71-1.32-1.17-1.94 1.73 1.06 5.04 6.35 4.97 6.04-.65-2.82-2.25-5.16-4.35-6.97 1.8.83 5.29 3.32 5.19 3.16-1.3-2.06-2.44-4.08-4.8-4.54z'
    />
    <path
      fill='#e8cdcc'
      d='M141.49 197.49c-.23-.35-.5-.28-.28.09 2.58 4.42 7.51 9.12 12.71 9.73.45.05-9.4-5.24-12.43-9.82zM131.15 187.02c.62 4.6-.17 10.9 2.63 14.6 1.29 1.71 5.76 6.1 7.27 7.59 2.09 2.07 2.68 4.2 2.95 7.08.05.51 2.8-3.76-6.94-13.81-3.91-4.02-5.99-16-5.91-15.46zM158.02 219.76c2.09 2.82 4.78 4.99 7.35 7.33 2.88 2.63 4.45 6.2 6.83 9.22.22.28-1.94-6.65-4.13-9.19-2.71-3.14-10.35-7.76-10.05-7.36zM158.74 214.9c-.41-.32 6.72 7.67 10.49 11.08 1.94 1.76 3.56 3.73 5.04 5.91 1.29 1.89 2.42 3.98 4.1 5.53.4.37-1.89-4.81-3.06-7.01-1.1-2.08-2.26-4.09-3.92-5.75-3.77-3.81-8.44-6.53-12.65-9.76zM165.7 216.38c2.04 1.46 4.02 2.83 5.73 4.71 1.8 1.98 3.18 4.37 4.42 6.75 1.22 2.33 5.45 9.59 10.54 12.4.47.26-4.21-6.04-5.36-8.66-6.03-13.76-15.73-15.49-15.33-15.2zM158.24 208.32c6.76.68 12.04 3.75 14.76 7.68.33.48-15.15-7.72-14.76-7.68zM166.02 205.38c6.72 2.27 12.16 4.74 15.01 11.86.24.58-15.29-11.96-15.01-11.86zM176.01 217.6c3.11 4.43 5.45 10.81 8.99 12.4 4.69 2.1-9.42-13.01-8.99-12.4zM187.6 218.8c-.42-.41-.97.18-.6.63 2.11 2.6 4.04 5.21 5.86 8.04.25.38.85.19.72-.25-.97-3.46-3.49-5.99-5.98-8.42z'
    />
    <path
      opacity={0.2}
      fill='#c7b0af'
      d='M189.29 245.96c-.88-3.99-2.96-8.8-6.85-14.54-10.08-14.85-28.89 1.88-56.44-23.67 8.16 38.29 55.93 38.42 63.29 38.21z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M131.92 187s-7.74 2.22-6.96 13.32c3.48 49.32 65.28 45.6 65.28 45.6s18.27-7.04 1.07-32.38c-10.43-15.38-30.23 3.1-59.39-26.54z'
    />
    <path
      fill='#e8cdcc'
      d='M226.15 206.19c-10.37.25-31.96-2.77-40.05 17.58-11.39 28.64 22.16 32.7 22.16 32.7s-1.38-11.61 21.2-19.17c22.3-7.47 36.23-14.58 23.19-41.17-.01-.01-7.09 9.59-26.5 10.06z'
    />
    <path
      fill='#c7b0af'
      d='M229.45 237.29c14.88-4.98 26.13-10.3 27.05-21.61 0 0-16.59 11.48-26.52 10.86-10.45-.65-29.39 6.09-36.41 24.64 6.77 4.4 14.68 5.29 14.68 5.29s-1.38-11.62 21.2-19.18z'
    />
    <path
      fill='#FFF'
      d='M231.13 208.73c7.06-.95 14.56-8.88 14.39-8.59-1.02 1.75-7.53 8.43-9.47 9.81-2.49 1.77-4.84 3.98-6.14 6.78-.1.21 1.16-4.77 2.93-6.5-4.12 1.79-7.31 10.04-7.25 9.78 1.33-6.05 3.08-8.27 3.54-8.69-.27.15-3.59 2.83-4.56 4.34-.14.22 2.21-4.01 4.18-5.16-1.59.42-3.13.98-4.52 2.04-.14.1.81-3.1 6.9-3.81zM239.61 209.68c-2.79 1.25-4.07 5.6-4.12 5.26-.62-4.39 5.31-7.45 7.49-9.07 3.36-2.5 2.92-2.43 4.62-6.27.17-.37 1.8 5.7-7.99 10.08zM223.39 217.26c-5.85 3.38-14.52 11.27-14.37 10.97 5.45-10.5 14.73-11.17 14.37-10.97zM223.83 213.84c-3.32 2.58-9.14 3.42-15.45 10.87-1.21 1.43-4.92 3.6-4.63 3.27 1.33-1.53 2.71-3 3.97-4.6 6.48-8.24 16.45-9.81 16.11-9.54zM217.53 213.49c-1.88.89-3.7 1.7-5.36 2.97-1.75 1.34-7.21 8.01-8.86 9.39-1.82 1.52-6.78 3.54-6.42 3.28 1.9-1.37 4.12-2.45 5.52-4.34 8.12-10.97 15.49-11.47 15.12-11.3zM221.37 209.74c-2.93 1.36-17.14 9.18-16.94 8.91 4.25-5.49 17.13-9 16.94-8.91zM224.6 206.88c-8.77.77-15.32 6.21-14.79 5.96 0 .01 15.42-6.01 14.79-5.96zM207.07 212.04c.52-.38-6.57 6.1-9.08 9.84-2.33 3.48-7.53 10.33-7.34 10.02 2.42-3.91 3.7-8.29 6.47-12.05 2.55-3.47 6.57-5.32 9.95-7.81zM203.16 211.6c.47-.36-9.45 9.76-11.48 11.96-.23.25 2.09-4.79 3.9-6.62 2.18-2.21 5.15-3.46 7.58-5.34z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M226.15 206.19c-11.69.21-34.94-.75-39.84 19.07-7.4 29.92 21.94 31.21 21.94 31.21s-.43-3.61 3.11-8.12c2.8-3.57 8.1-7.71 18.09-11.05 22.3-7.47 36.17-14.52 23.13-41.11 0-.01-11.35 9.73-26.43 10z'
    />
    <path d='M223.9 198.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57zM201 239l-10 12 3 2zM215.14 234.11s-6.63 6.17-7.09 17.23l4.47-3.13s-1-5.39 2.62-14.1zM219.31 235.37s-3.74 3.49-4.01 9.74l2.53-1.77s-.57-3.04 1.48-7.97zM159 226s3 14 14.25 20.14L179 245s-11-3-20-19z' />
  </svg>
);

export default Fur;
