import React, { FunctionComponent } from 'react';
import { Theme, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import useCommonStyles from '../../components/useCommonStyles';
import { useWalletSelector } from '../../hooks';
import SyncAccountButton from './SyncAccountButton';
import { inIframe } from '../../wallet/Wallet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0',
    },
  },
  edition: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
    width: '100%',
  },
  disableAnimation: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '15px',
    width: '100%',
  },
}));

interface IGameStats {}

export const GameStats: FunctionComponent<IGameStats> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const edition = useWalletSelector((s) => s.edition);
  const account = useWalletSelector((s) => s.contents);
  const iframe = inIframe(); // iframes cannot access local storage
  const disableAnimation = iframe
    ? false
    : localStorage.getItem('disableAnimation') === '1';
  const [animState, setAnimState] = React.useState(disableAnimation);

  function toggleAnimations() {
    if (iframe) return;
    if (disableAnimation) {
      localStorage.removeItem('disableAnimation');
      setAnimState(false);
    } else {
      localStorage.setItem('disableAnimation', '1');
      setAnimState(true);
    }
  }

  function renderPercentMinted(
    cur: number,
    start: number,
    end: number,
    fur?: number,
  ): React.ReactNode {
    const name =
      fur === 0
        ? 'Free Mint'
        : fur === undefined
        ? 'Furry Blowout & Gifts'
        : `${fur.toLocaleString()} $FUR`;
    let title = '';
    // let node: React.ReactNode = null;
    if (cur < start) {
      title = 'Not Yet Begun';
    } else if (cur >= end) {
      title = 'Minted Out!';
    } else {
      title = `${(cur - start).toLocaleString()}/${(
        end - start
      ).toLocaleString()} Minted`;
      // node = <LinearProgress style={{ width: '100%' }} value={cur} />;
    }

    return (
      <Grid container>
        <Grid item xs={6}>
          <Typography variant={'h6'}>{name}</Typography>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Typography variant={'subtitle2'}>{title}</Typography>
        </Grid>
      </Grid>
    );
  }

  // function renderEdition(ed: IFurballEdition): React.ReactNode {
  //   const count = ed.count;
  //
  //   return (
  //     <Card className={clsx(classes.edition, common.cardRoot)}>
  //       <CardHeader
  //         className={common.cardHeader}
  //         title={'Edition #1 (Genesis)'}
  //       />
  //       <CardContent className={common.cardContent}>
  //         {renderPercentMinted(count, 0, 2500, 0)}
  //         {renderPercentMinted(count, 2500, 3500, 40000)}
  //         {renderPercentMinted(count, 3500, 4500, 80000)}
  //         {renderPercentMinted(count, 4500, 5500, 160000)}
  //         {renderPercentMinted(count, 5500, 6500, 320000)}
  //         {renderPercentMinted(count, 6500, 7250, 640000)}
  //         {renderPercentMinted(count, 7300, 7400, 10000000)}
  //         {/*{renderPercentMinted(count, 7250, 7500, undefined)}*/}
  //         <Typography
  //           variant={'h5'}
  //           style={{ textAlign: 'center', marginTop: 20 }}>
  //           <Link to={'/adopt'}>Mint Furball</Link>
  //         </Typography>
  //       </CardContent>
  //     </Card>
  //   );
  // }

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={3}>
        <SyncAccountButton />
      </Grid>
      <Grid item xs={9} style={{ textAlign: 'left' }}>
        <Typography variant={'h4'} sx={{ fontWeight: '400' }}>
          My Account
        </Typography>
        <Typography variant={'h6'}>
          {/*<OutboundLink*/}
          {/*  to={*/}
          {/*    'https://p.datadoghq.com/sb/qd1k8ijfrdl6v588-6d1afaa93366907ff4955c2c644a10bf'*/}
          {/*  }>*/}
          Recent Activity
          {/*</OutboundLink>*/}
        </Typography>
      </Grid>

      {/*{account && (*/}
      {/*  <Container style={{ width: '100%', marginTop: 20 }}>*/}
      {/*    <ShareReferButton />*/}
      {/*  </Container>*/}
      {/*)}*/}
      {/*{edition && renderEdition(edition)}*/}
      {/*<br />*/}
      {/*<div className={classes.disableAnimation}>*/}
      {/*  <FormControlLabel*/}
      {/*    control={*/}
      {/*      <Checkbox*/}
      {/*        checked={animState}*/}
      {/*        sx={{*/}
      {/*          '&.Mui-checked': {*/}
      {/*            color: themev2.palette.secondary.light,*/}
      {/*          },*/}
      {/*        }}*/}
      {/*        onChange={toggleAnimations}*/}
      {/*      />*/}
      {/*    }*/}
      {/*    label={'Disable Animations?'}*/}
      {/*  />*/}
      {/*</div>*/}
    </Grid>
  );
};

export default GameStats;
