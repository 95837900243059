import { ILogManager, ILogSink, ILogEntry, LogLevel } from './types';
import {
  LogBundleInput,
  LogLevel as FurLogLevel,
} from '../../components/schema';
import { stringifyUnknowns } from './hooks';

export type DispatchFunc = (bundle: LogBundleInput) => Promise<void>;

class ServerLogSink implements ILogSink {
  public readonly defaultSinkKey = 'server';

  private readonly _dispatch: DispatchFunc;

  private _bundle: LogBundleInput = { entries: [] };

  private _lastDispatch = 0;

  private _timeBetweenDispatch = 15000;

  private _timeout: NodeJS.Timeout | undefined;

  public readonly logManager: ILogManager;

  constructor(manager: ILogManager, dispatchBundle: DispatchFunc) {
    this.logManager = manager;
    this._dispatch = dispatchBundle;
    this._lastDispatch = new Date().getTime();
  }

  private get timeSinceDispatch(): number {
    return new Date().getTime() - this._lastDispatch;
  }

  public logEntry(entry: ILogEntry): void {
    this._bundle.entries.push({
      context: entry.context,
      timestamp: entry.timestamp,
      logLevel: this.getLogLevel(entry.level),
      messages: stringifyUnknowns(entry.messages),
      exception: entry.exception ? entry.exception : null,
    });

    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = undefined;
    }

    const bundle = { entries: [...this._bundle.entries] };
    const timeRemaining = this._timeBetweenDispatch - this.timeSinceDispatch;
    if (timeRemaining <= 0) {
      this._lastDispatch = new Date().getTime();
      void this._dispatch(bundle);
    } else {
      this._timeout = setTimeout(() => {
        this._timeout = undefined;
        this._lastDispatch = new Date().getTime();
        this._bundle = { entries: [] };
        void this._dispatch(bundle);
      }, timeRemaining + 100);
    }
  }

  private getLogLevel(level: LogLevel): FurLogLevel {
    if (level === LogLevel.verbose) return FurLogLevel.Verbose;
    if (level === LogLevel.debug) return FurLogLevel.Debug;
    if (level === LogLevel.warn) return FurLogLevel.Warn;
    if (level === LogLevel.error) return FurLogLevel.Error;
    return FurLogLevel.Info;
  }
}

export default ServerLogSink;
