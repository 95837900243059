import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme } from '@mui/material';
import CurrencyExchangeRow from './CurrencyExchangeRow';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import SvgCaret from '../../assets/SvgCaret';
import themev2 from '../../themev2';
import SvgIconFurNew from '../../assets/SvgIconFurNew';
import SvgIconWrappedFur from '../../assets/SvgIconWrappedFur';
import FurSwapButton from './FurSwapButton';
import SvgIconTix from '../../assets/SvgIconTix';
import SvgIconDollar from '../../assets/SvgIconDollar';
import { useFurComponent } from '../../utils';
import WalletSlice from '../../wallet/WalletSlice';
import FurBuyButton from './FurBuyButton';
import AlertProvider from '../Alert/AlertProvider';
import { ExchangeRatesFragment, useExchangeRatesQuery } from '../schema';
import SvgIconEth from '../../assets/SvgIconEth';
import { formatCurrencyValue } from './CurrencyAmountInput';
import TixBuyButton from './TixBuyButton';
import { useHistory } from 'react-router-dom';
import TicketPurchaseForm from '../../pages/Tickets/TicketPurchaseForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  swapOrderButtonCont: {
    position: 'absolute',
    top: 100,
  },
  swapOrderButton: {
    minHeight: '42.5px !important',
    minWidth: '42.5px !important',
    border: '3px solid white !important',
    borderRadius: '50px !important',
    backgroundColor: 'rgba(233, 233, 255, 1) !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // top: '-46px',
  },
}));

export enum SwapCurrency {
  FUR = 'FUR',
  wFUR = 'wFUR',
  USD = 'USD',
  TIX = 'TIX',
  ETH = 'ETH',
}

// export type SwapCurrency = 'FUR' | 'wFUR' | 'USD' | 'TIX';

export interface ISwapOptions {
  from: IExchangeCurrency;
  to: IExchangeCurrency;
  // fuelCost: number;
}

export type ExchangeRates = { [key: string]: number };

export interface IExchangeCurrency {
  currency: SwapCurrency;
  rates: ExchangeRates; // map of this currency -> other currency
  // balance: number | undefined; // If the current player has a balance...
  baseCosts: ExchangeRates; // Fuel cost (base) to another currency
  amount: number; // amount to swap
  amountStr: string; // amount for the text input
  max: number;
  min: number;
  error?: string;
}

export type ExchangeCurrencies = { [key: string]: IExchangeCurrency };

export function getSwapCurrencyDescription(token: SwapCurrency): string {
  if (token === SwapCurrency.FUR)
    return 'ERC-20 Token | ETH Blockchain | In-Game Value';
  if (token === SwapCurrency.TIX) return 'Buy In-Game Items | Real-World Value';
  if (token === SwapCurrency.USD) return 'Fiat Currency | Real-World Value';
  if (token === SwapCurrency.ETH)
    return 'Blockchain Currency | Real-World Value';
  if (token === SwapCurrency.wFUR)
    return 'Free to Earn | Off-Chain | In-Game Value';
  return '';
}

export function getSwapCurrencyDecimals(token: SwapCurrency): number {
  if (token === SwapCurrency.ETH) return 6;
  return 0;
}

export function renderSwapCurrencyIcon(
  token: SwapCurrency,
  size = 15,
): React.ReactNode {
  if (token === SwapCurrency.FUR)
    return <SvgIconFurNew height={size} width={size} />;
  if (token === SwapCurrency.TIX)
    return <SvgIconTix height={size} width={size} />;
  if (token === SwapCurrency.USD)
    return <SvgIconDollar height={size} width={size} />;
  if (token === SwapCurrency.ETH)
    return <SvgIconEth height={size} width={size} />;
  if (token === SwapCurrency.wFUR)
    return <SvgIconWrappedFur height={size} width={size} />;
  return null;
}

function getExchangeCurrency(
  currency: SwapCurrency,
  min = 1,
  max = 10000000,
): IExchangeCurrency {
  return {
    currency,
    rates: {},
    baseCosts: {},
    amount: min,
    amountStr: formatCurrencyValue(min, currency),
    max,
    min,
  };
}

function useSwapCurrencies(rates?: ExchangeRatesFragment): ExchangeCurrencies {
  const web3 = !!window.ethereum;

  return React.useMemo(() => {
    const ret: ExchangeCurrencies = {
      [SwapCurrency.USD.toString()]: getExchangeCurrency(SwapCurrency.USD),
      [SwapCurrency.TIX.toString()]: getExchangeCurrency(SwapCurrency.TIX),
      [SwapCurrency.wFUR.toString()]: getExchangeCurrency(SwapCurrency.wFUR),
      // [SwapCurrency.FUR.toString()]: getExchangeCurrency(SwapCurrency.FUR),
    };
    if (web3) {
      ret[SwapCurrency.FUR.toString()] = getExchangeCurrency(SwapCurrency.FUR);
      ret[SwapCurrency.FUR.toString()].rates[SwapCurrency.wFUR] = 1;

      // ret[SwapCurrency.wFUR.toString()] = getExchangeCurrency(SwapCurrency.wFUR);
      ret[SwapCurrency.wFUR.toString()].rates[SwapCurrency.FUR] = 1;

      ret[SwapCurrency.ETH.toString()] = getExchangeCurrency(SwapCurrency.ETH);
      ret[SwapCurrency.ETH.toString()].rates[SwapCurrency.TIX] = 0.001;
    }
    if (rates) {
      const tixToUsd = rates.eth.usdPrice / 1000;
      ret[SwapCurrency.USD.toString()].rates[SwapCurrency.TIX] = tixToUsd;
      // ret[SwapCurrency.USD.toString()].rates[SwapCurrency.wFUR] =
      //   rates.fur.costBuy.costEach.usd;
      ret[SwapCurrency.TIX.toString()].rates[SwapCurrency.wFUR] =
        rates.fur.costBuy.costEach.fuel;
      ret[SwapCurrency.TIX.toString()].baseCosts[SwapCurrency.wFUR] =
        rates.fur.costBuy.costBase.fuel;

      ret[SwapCurrency.wFUR.toString()].max = 100000000; //rates.fur.amountAvailable;
      if (web3) {
        ret[SwapCurrency.wFUR.toString()].baseCosts[SwapCurrency.FUR] =
          rates.fur.costSwap.costEach.fuel;

        ret[SwapCurrency.FUR.toString()].baseCosts[SwapCurrency.wFUR] =
          rates.fur.costSwap.costEach.fuel;
        ret[SwapCurrency.FUR.toString()].max = 100000000; // rates.fur.amountAvailable;
      }

      // ret[SwapCurrency.wFUR.toString()].baseCosts[SwapCurrency.FUR] = rates.furSwapCost;
    } else {
      ret[SwapCurrency.USD.toString()].rates[SwapCurrency.wFUR] = 0.001;
    }
    return ret;
  }, [web3, rates]);
}

export type CurrencyBalances = { [key: string]: number };

// export const SWAP_COMPUTE_DELAY = 1000;

interface IExchangeCurrencies {}

function findCurrency(
  name: string,
  currencies: ExchangeCurrencies,
): IExchangeCurrency | undefined {
  name = name.toLowerCase();
  const c = Object.keys(currencies).find((c) => c.toLowerCase() === name);
  return c ? currencies[c] : undefined;
}

const ExchangeCurrencies: React.FunctionComponent<IExchangeCurrencies> = (
  props,
) => {
  const classes = useStyles();
  const { log } = useFurComponent(ExchangeCurrencies);
  const web3 = !!window.ethereum;
  const dispatch = useAppDispatch();
  const { data: exchangeRateData, loading } = useExchangeRatesQuery();
  const rates = exchangeRateData?.exchangeRates;
  const contents = useWalletSelector((s) => s.contents!);
  const history = useHistory();
  const { furBalance, wFur, tix } = contents;
  const [balances, setBalances] = React.useState<CurrencyBalances>({});
  const paths = window.location.pathname.split('/').filter((p) => p.length > 0);
  const isBuy = paths[0].toLowerCase() === 'buy';
  const currencies = useSwapCurrencies(rates);
  const loadCurrency =
    history.location.hash.length > 0
      ? findCurrency(history.location.hash.substring(1), currencies)
      : undefined;
  const hasRates = !!rates;
  const [swapOptions, setSwapOptions] = useState<ISwapOptions>({
    from: currencies[SwapCurrency.USD],
    to: currencies[SwapCurrency.wFUR],
    // fuelCost: 0,
  });
  const isFurSwap =
    swapOptions.from.currency === SwapCurrency.FUR ||
    swapOptions.from.currency === SwapCurrency.wFUR;
  const exchangeRate =
    currencies[swapOptions.from?.currency]?.rates[swapOptions.to?.currency] ??
    0;
  const isValidExchange = !!exchangeRate;
  const isUsdPurchase = swapOptions.from.currency === SwapCurrency.USD;
  const canExchange =
    swapOptions.from &&
    swapOptions.to &&
    !swapOptions.from.error &&
    !swapOptions.to.error &&
    hasRates;

  React.useEffect(() => {
    setBalances({
      [SwapCurrency.wFUR.toString()]: wFur,
      [SwapCurrency.FUR.toString()]: furBalance,
      [SwapCurrency.TIX.toString()]: tix,
    });
  }, [furBalance, wFur, tix]);

  React.useEffect(() => {
    if (hasRates) {
      if (loadCurrency) {
        if (isBuy) {
          let fromCurrency = SwapCurrency.USD;
          if (loadCurrency.currency === SwapCurrency.TIX && web3)
            fromCurrency = SwapCurrency.ETH;
          if (loadCurrency.currency === SwapCurrency.wFUR && web3)
            fromCurrency = SwapCurrency.TIX;
          updateSwapCurrencies(currencies[fromCurrency], loadCurrency);
        } else {
          const allowedTo = Object.keys(loadCurrency.rates);
          const to =
            allowedTo.includes(swapOptions.to.currency) || allowedTo.length <= 0
              ? swapOptions.to.currency
              : allowedTo[0];
          updateSwapCurrencies(loadCurrency, currencies[to]);
        }
      } else if (web3)
        updateSwapCurrencies(currencies[SwapCurrency.TIX], swapOptions.to);
    }
  }, [hasRates]);

  function updateSwapCurrencies(
    from: IExchangeCurrency,
    to: IExchangeCurrency,
  ) {
    if (!from.rates[to.currency] && from.rates[to.currency] !== 0) {
      const canSwapTo = Object.keys(from.rates);
      log.warn(
        from.currency,
        'cannot swap to',
        to.currency,
        'only',
        from.rates,
      );
      if (canSwapTo.length > 0) to = currencies[canSwapTo[0]];
    }
    assignValues({ ...from }, { ...to }, from.amount, true);
    // console.log('buy', isBuy, to.currency, from.currency);
    history.push(
      '/' +
        (isBuy ? 'buy' : 'swap') +
        '#' +
        (isBuy ? to.currency : from.currency).toLowerCase(),
    );
  }
  function changeSwapOrder() {
    updateSwapCurrencies(swapOptions.to, swapOptions.from);
  }

  function assignValue(
    v: number,
    o: IExchangeCurrency,
    reFormat = true,
  ): number {
    const realVal = getSwapCurrencyDecimals(o.currency) > 0 ? v : Math.floor(v);
    const ret = (o.amount = Math.max(realVal, 0));
    if (o.amount > o.max) o.error = `max: ${o.max.toLocaleString()}`;
    else if (o.amount < o.min) o.error = `max: ${o.min.toLocaleString()}`;
    else o.error = undefined;
    if (reFormat) o.amountStr = formatCurrencyValue(o.amount, o.currency);
    return ret;
    // return o.amount = Math.min(Math.max(Math.floor(v), o.min), o.max);
  }

  function assignValues(
    from: IExchangeCurrency,
    to: IExchangeCurrency,
    a: number,
    isValFrom = true,
    strVal?: string,
  ) {
    const er = currencies[from.currency].rates[to.currency] ?? 0;
    const factor = from.currency === SwapCurrency.TIX ? 10000 : 1;
    const baseCost =
      from.currency === SwapCurrency.TIX
        ? currencies[from.currency].baseCosts[to.currency] ?? 0
        : 0;
    if (isValFrom) {
      const val = assignValue(a, from);
      if (strVal) from.amountStr = strVal;
      // console.log(er, val * factor, (val * factor) / er);
      assignValue(er === 0 ? 0 : (val * factor - baseCost) / er, to);
      // reFormatCurrency(to);
      // console.log('assign', a, strVal, from, to);
    } else {
      const val = assignValue(a, to);
      if (strVal) to.amountStr = strVal;
      assignValue(er === 0 ? baseCost : (val / factor) * er + baseCost, from);
    }
    setSwapOptions({ ...swapOptions, from, to });
  }

  function updateAmount(swapAmount: string, currency: SwapCurrency) {
    const numberStr = swapAmount.replaceAll(',', '');
    const dec = getSwapCurrencyDecimals(currency);
    const numStr = numberStr.length <= 0 ? '0' : numberStr;
    const a = dec > 0 ? Number.parseFloat(numStr) : Number.parseInt(numStr);
    if (isNaN(a)) return;
    if (numberStr.length <= 0) swapAmount = '0';
    else
      while (swapAmount.startsWith('0') && !swapAmount.startsWith('0.'))
        swapAmount = swapAmount.substr(1);
    // console.log(swapAmount, numberStr);
    assignValues(
      { ...swapOptions.from },
      { ...swapOptions.to },
      a,
      swapOptions.from.currency === currency,
      dec > 0 ? swapAmount : formatCurrencyValue(a, currency), // decimals don't handle formatting well
    );
  }

  function onTransactionComplete() {
    log.info('txn', swapOptions);
    const bals = {
      ...balances,
      [swapOptions.from.currency]:
        balances[swapOptions.from.currency] - swapOptions.from.amount,
      [swapOptions.to.currency]:
        balances[swapOptions.to.currency] + swapOptions.to.amount,
    };
    // if (swapOptions.fuelCost > 0)
    //   bals[SwapCurrency.TIX] -= swapOptions.fuelCost / 10000;
    setBalances(bals);
    // Swap balances happen later, do not immediately update
    if (
      [SwapCurrency.wFUR, SwapCurrency.FUR].includes(swapOptions.from.currency)
    )
      return;
    dispatch(
      WalletSlice.actions.updateBalances({
        wFur: bals[SwapCurrency.wFUR],
        fur: bals[SwapCurrency.FUR],
        tix: bals[SwapCurrency.TIX],
      }),
    );
  }

  function updateFromCurrency(c: SwapCurrency): void {
    if (c === swapOptions.from.currency) return;
    const from = currencies[c.toString()];
    if (!from) return;
    const defaultTo = Object.keys(from.rates)[0] as SwapCurrency;
    const toC = from.rates[swapOptions.to?.currency]
      ? swapOptions.to.currency.toString()
      : undefined;
    const to = toC && currencies[toC] ? currencies[toC] : currencies[defaultTo];
    if (!to) {
      log.warn(
        '[CUR]',
        from.currency,
        'has no TO',
        toC,
        'among',
        from.rates,
        'default',
        defaultTo,
      );
      return;
    }
    updateSwapCurrencies(from, to);
  }

  function renderSwapFrom(): React.ReactNode {
    // if (isUsdPurchase) return null;
    if (!rates) return null;
    const swappable = Object.keys(currencies)
      .filter((c) => Object.keys(currencies[c].rates).length > 0)
      .map((o) => o as SwapCurrency);
    return (
      <CurrencyExchangeRow
        key={`swap-option-${swapOptions.from.currency}`}
        options={swappable}
        balance={balances[swapOptions.from.currency]}
        swapOption={swapOptions.from}
        updateAmount={updateAmount}
        onSelectCurrency={updateFromCurrency}
      />
    );
  }

  function renderUsdPurchase(): React.ReactNode {
    return <TicketPurchaseForm currency={'USD'} />;
  }

  function renderSwapTo(): React.ReactNode {
    if (!rates) return null; // causes error if invalid select
    // if (swapOptions.from.currency === SwapCurrency.USD) {
    //   // When we use USD, there are checkout buttons instead of an input field
    //   return null;
    // }
    return (
      <CurrencyExchangeRow
        key={`swap-option-${swapOptions.to.currency}`}
        options={Object.keys(swapOptions.from.rates).map(
          (o) => o as SwapCurrency,
        )}
        balance={balances[swapOptions.to.currency]}
        swapOption={swapOptions.to}
        updateAmount={updateAmount}
        onSelectCurrency={(c) =>
          updateSwapCurrencies(swapOptions.from, currencies[c])
        }
        input={
          swapOptions.from.currency === SwapCurrency.USD
            ? renderUsdPurchase()
            : undefined
        }
      />
    );
  }

  function renderConfirmButton() {
    if (swapOptions.from.currency === SwapCurrency.USD) {
      return null;
    }
    if (isFurSwap)
      return (
        <FurSwapButton
          fuelCost={rates?.fur.costSwap.costBase.fuel ?? 0}
          swapOptions={swapOptions}
          onComplete={onTransactionComplete}
          disabled={!canExchange}
        />
      );
    if (swapOptions.to.currency === SwapCurrency.wFUR && rates)
      return (
        <FurBuyButton
          currency={swapOptions.from.currency}
          amount={swapOptions.to.amount}
          costs={rates.fur.costBuy}
          onComplete={onTransactionComplete}
          disabled={!canExchange}
        />
      );
    if (swapOptions.to.currency === SwapCurrency.TIX && rates)
      return (
        <TixBuyButton
          currency={swapOptions.from.currency}
          amount={swapOptions.to.amount}
          costs={rates.tix.costBuy}
          onComplete={onTransactionComplete}
          disabled={!canExchange}
        />
      );
    if (isUsdPurchase) return null;
    return (
      <span color={'red'}>
        {isValidExchange ? 'Coming Soon' : 'Invalid'}:{' '}
        {swapOptions.from.currency} to {swapOptions.to.currency}
      </span>
    );
  }

  function renderFlipButton(): React.ReactNode {
    if (loading) {
      return (
        <div>
          <CircularProgress
            color={'secondary'}
            size={30}
            // className={classes.swapOrderButton}
          />
        </div>
      );
    }
    if (
      !currencies[swapOptions.to?.currency]?.rates[swapOptions.from?.currency]
    )
      return null;
    return (
      <div className={classes.swapOrderButtonCont}>
        <Button
          color={'secondary'}
          className={classes.swapOrderButton}
          onClick={() => changeSwapOrder()}>
          <SvgCaret
            fill={themev2.palette.secondary.main}
            height={15}
            width={15}
            style={{
              transform: 'rotate(90deg)',
            }}
          />
        </Button>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AlertProvider>
        {renderSwapFrom()}
        {renderSwapTo()}
        {renderFlipButton()}
        <br />
        {renderConfirmButton()}
      </AlertProvider>
    </div>
  );
};

export default ExchangeCurrencies;
