import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import clsx from 'clsx';
import { IFurballBase, IFurballPlayerMinimal } from '../../wallet/WalletTypes';
import { useFindFurballLazyQuery } from '../schema';
// import { parseFurball } from '../../wallet';
import { placeholderFurballId } from '../../wallet';
import PlayerLink from './PlayerLink';
import FurballLink from './FurballLink';
import { getInventoryQuantities } from '../Furballs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IPlayerFurballLink {
  tokenId: string;
  furball?: IFurballBase;
  player?: IFurballPlayerMinimal;
  hideFurballLink?: boolean;
}

export const PlayerFurballLink: FunctionComponent<IPlayerFurballLink> = (
  props,
) => {
  const { tokenId } = props;
  const classes = useStyles();
  const [loadFurball, loadedFurball] = useFindFurballLazyQuery();
  const [furball, setFurball] = React.useState<IFurballBase | undefined>(
    props.furball,
  );
  const [player, setPlayer] = React.useState<IFurballPlayerMinimal | undefined>(
    props.player,
  );

  React.useEffect(() => {
    if (tokenId === placeholderFurballId) return;
    if (!player || furball?.tokenId !== tokenId)
      loadFurball({ variables: { query: tokenId } });
  }, [tokenId]);

  React.useEffect(() => {
    if (loadedFurball.data && loadedFurball.data.furball.tokenId === tokenId) {
      setFurball({
        ...loadedFurball.data.furball,
        inventory: getInventoryQuantities(loadedFurball.data.furball.inventory),
      });
      const p: IFurballPlayerMinimal | undefined =
        loadedFurball.data?.furball.owner;
      setPlayer(p ?? undefined);
    }
  }, [tokenId, loadedFurball.data]);

  const both = !props.hideFurballLink && furball && player;

  return (
    <React.Fragment>
      {!props.hideFurballLink && (
        <>
          {furball && <FurballLink furball={furball} />}
          {!furball && 'a Furball'}
        </>
      )}
      {both && ' ('}
      {player && <PlayerLink player={player} />}
      {both && ')'}
    </React.Fragment>
  );
};

export default PlayerFurballLink;
