import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { RealmType } from '../../../components/schema';
import { getBossNameFromRealm } from '../../../utils';
import { cdnRoot } from '../../../theme';
import { capitalize } from 'lodash';
import OpenSeaLink from '../../../components/Blockchain/OpenSeaLink';
import FtuePopout from '../../../components/FtuePopout';
import { useRoundOutPartialPartyContext } from '../RoundOutPartialParty/RoundOutPartialPartyContext';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // maxWidth: '100% !important',
    paddingLeft: '16px',
  },
  banner: {
    color: 'white',
    height: '150px',
    width: '100%',
    maxWidth: '750px',
    // margin: '1.5rem 1.5rem 0 1.5rem',
    // borderRadius: '12px',
    // backgroundColor: yellow,
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem',
    // boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    gap: '1.75rem',
    // border: '2px solid black',

    [theme.breakpoints.down(1180)]: {
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '0.5rem',
    },
  },
  link: {
    color: 'white !important',
    textDecoration: 'underline',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    paddingRight: 10,

    '& h2': {
      fontSize: '40px !important',
    },

    '& p': {
      fontSize: '0.9rem !important',
      maxWidth: '600px !important',
    },

    [theme.breakpoints.down(1180)]: {
      '& h2': {
        fontSize: '30px !important',
        margin: '0.5rem auto !important',
        textAlign: 'center !important',
      },

      '& p': {
        fontSize: '0.8rem !important',
      },
    },
  },
  ftue: {
    position: 'relative',
    top: 80,
    // paddingLeft: 30,
    // paddingTop: 50,
    // marginBottom: -20,
    paddingBottom: 0,
    height: 80,
    // maxWidth: 360,
    textAlign: 'center',
  },
}));

interface IRentMarketBossBanner {
  realm: RealmType;
}

const RentMarketBossBanner: FunctionComponent<IRentMarketBossBanner> = (
  props,
) => {
  const { realm } = props;
  const bossName = getBossNameFromRealm(realm);
  const classes = useStyles();

  const { selectedPartySize } = useRoundOutPartialPartyContext();

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <img
          src={`${cdnRoot}/images/avatars/${bossName}.png`}
          alt={bossName}
          height={90}
          width={90}
        />
        <div className={classes.info}>
          <Typography variant={'h2'}>
            Build a party to battle {capitalize(bossName)}
          </Typography>
          <Typography variant={'body1'}>
            Borrowing Furballs from other players is free. You may also buy a
            Furball{' '}
            <OpenSeaLink className={classes.link}>on OpenSea</OpenSeaLink>.
          </Typography>
        </div>
      </div>
      <div className={classes.ftue}>
        <FtuePopout
          message={
            selectedPartySize > 0 ? undefined : 'Select Furballs to Build Party'
          }
        />
      </div>
    </div>
  );
};

export default RentMarketBossBanner;
