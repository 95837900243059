import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Tuft = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M184.27 103.42c-15.19-6.08-10.13-19.03-10.13-19.03l13.68 9.18c-2.49-3.7 4.67-11.91 4.67-11.91l4.72 8.54c2.97 5.38 10.43 11.19 16.16 14.47'
    />
    <path d='M182.44 103.23c-1.84-.67-3.67-1.5-5.37-2.65-1.7-1.14-3.27-2.62-4.42-4.49-1.14-1.87-1.77-4.1-1.79-6.3-.02-1.1.09-2.19.32-3.25.11-.53.25-1.06.42-1.58.18-.54.34-.99.65-1.62l.06-.11c.57-1 1.84-1.34 2.84-.77.08.05.16.1.23.16l13.14 10.13-2.12 1.78c-.72-1.41-.75-2.97-.55-4.3.22-1.34.64-2.57 1.15-3.72.51-1.15 1.11-2.23 1.75-3.27.66-1.05 1.32-2.01 2.14-3.04.8-.89 2.16-.96 3.04-.16.18.16.33.35.44.55l.01.01 3.67 6.64.92 1.66c.31.54.56 1 .89 1.48.62.95 1.34 1.86 2.12 2.7 1.56 1.7 3.39 3.15 5.39 4.29 2 1.15 4.17 1.95 6.4 2.4l.04.01c1.17.24 1.93 1.38 1.69 2.54s-1.37 1.93-2.54 1.69a25.49 25.49 0 0 1-7.73-2.89 26.919 26.919 0 0 1-6.43-5.11 23.87 23.87 0 0 1-2.56-3.26c-.39-.56-.74-1.21-1.06-1.77l-.92-1.66-3.67-6.64 3.49.4c-.69.7-1.44 1.56-2.09 2.4-.66.85-1.27 1.73-1.79 2.63-1.04 1.77-1.74 3.8-1.21 5.19v.62c0 .76-.62 1.38-1.38 1.38-.27 0-.53-.08-.74-.22l-13.98-8.95 3.13-.72c-.12.19-.33.62-.47.96-.15.36-.29.74-.39 1.12-.23.76-.37 1.55-.41 2.33-.1 1.56.21 3.11.92 4.52.73 1.41 1.84 2.66 3.19 3.72 1.34 1.07 2.91 1.94 4.55 2.68l.01.01c.67.3.96 1.08.66 1.75-.27.67-.99.96-1.64.73z' />
  </svg>
);

export default Tuft;
