import React, { FunctionComponent, useState } from 'react';
import MyFurballs from './MyFurballs';
import { makeStyles } from '@mui/styles';
import { Theme, Grid, Typography } from '@mui/material';
import { useWalletSelector } from '../../hooks';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';

import { darkBlue } from '../../theme';
import { Redirect, useHistory } from 'react-router-dom';

import OkDialog from '../../components/OkDialog';
import ShopWaresAll from '../../components/Shop/ShopWaresAll';
import VoyageZone from './VoyageZone';
import { useActiveVoyageRealms } from '../../components/Zones/ZoneHelpers';
import RealmDilemmaResultDialog from '../../components/Zones/Realms/RealmDilemmaResultDialog';
import FullDrawer from '../../components/Drawer/FullDrawer';
import AccountHeader from '../../AccountHeader';
import BossFights from './BossFights';
import Scholarships from './Scholarships';
import LootFoundInExplore from '../../components/Zones/LootFoundInExplore';
import { ILootFoundFurball, LootFoundContext } from './LootFoundContext';
import { useAuthContext } from '../../components/Auth/AuthContext';
import { FurAccountTypes } from '../../components/Auth/AuthTypes';
import Town from './Town';
import { usePageTitle } from '../../Analytics';

const useStyles = makeStyles((theme: Theme) => ({
  instructions: {
    // paddingLeft: theme.spacing(10),
    textAlign: 'center',
  },
  zoneTransfer: {
    paddingLeft: '60px !important',
    paddingRight: '60px !important',
  },
  content: {
    padding: theme.spacing(4),
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(3),
    },
  },
  fur: {
    maxWidth: '140px',
    backgroundColor: darkBlue,
    color: 'white',
    padding: theme.spacing(1),
    borderRadius: '16px',
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(2),
  },
  furHeader: {
    lineHeight: '1.8rem !important',
  },
  furBalance: {
    position: 'relative',
    top: '-8px',
    marginBottom: '-8px',
  },
  comingSoon: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    textAlign: 'center',
    color: 'white',
  },
  corner: {
    textAlign: 'center',
    color: 'black',
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
  },
}));

interface OwnProps {}

type Props = OwnProps;

const Play: FunctionComponent<Props> = (props) => {
  const wallet = useWalletSelector((s) => s.contents);
  const history = useHistory();
  const classes = useStyles();
  const common = useCommonStyles();
  const voyageRealms = useActiveVoyageRealms();

  const [waresAllOpen, setWaresAllOpen] = React.useState(0);
  const [lootFound, setLootFound] = useState<ILootFoundFurball[]>([]);

  usePageTitle('Play | Furballs');

  const {
    authState: { playerType },
  } = useAuthContext();

  function goToPage(e: React.MouseEvent, page: string): boolean {
    history.push(page);
    e.preventDefault();
    return false;
  }

  if (!wallet) {
    return (
      <div className={clsx(common.fullScreen)}>
        <Typography variant={'h3'}>Missing Account</Typography>
        <Typography variant={'body1'}>
          The account data could not be loaded.
        </Typography>
      </div>
    );
  }

  function renderChildren() {
    return (
      <>
        <Grid container spacing={4} className={classes.content}>
          <AccountHeader title={'Play'} />
          <Grid item xs={12}>
            <BossFights />
          </Grid>

          {playerType === FurAccountTypes.PLAYER && (
            <>
              {/*<Grid item xs={12} xl={12}>*/}
              {/*  <BattleExplore zoneNumber={0} />*/}
              {/*</Grid>*/}
              {/*<Grid item xs={12} xl={6}>*/}
              {/*  <BattleExplore zoneNumber={0x10000} />*/}
              {/*</Grid>*/}
              {voyageRealms.length > 0 && (
                <Grid item xs={12}>
                  <VoyageZone />
                </Grid>
              )}
              <Grid item xs={12}>
                <Town />
              </Grid>
              <Grid item xs={12}>
                <Scholarships />
              </Grid>
            </>
          )}

          {/*<Grid item xs={12} >*/}
          {/*  <ZoneCard zoneNumber={-1} title={'COMING SOON!'} >*/}
          {/*    <div className={classes.corner}>*/}
          {/*      <Typography variant={'h5'}>*/}
          {/*        Many new game modes coming soon!*/}
          {/*      </Typography>*/}
          {/*    </div>*/}
          {/*  </ZoneCard>*/}
          {/*</Grid>*/}
        </Grid>
        <OkDialog
          title={'Holiday Surprises'}
          topRight={true}
          open={waresAllOpen > 0}
          onClose={() => setWaresAllOpen(0)}
          actions={
            waresAllOpen >= 2 ? (
              <div style={{ borderTop: '2px solid black', width: '100%' }}>
                <Typography variant={'h4'} style={{ color: 'red' }}>
                  Schedule Not Yet Saved!
                </Typography>
                <Typography variant={'body1'}>
                  When you're done adding loot, be sure to sign your moves with{' '}
                  <a
                    href='/timekeeper'
                    onClick={(e) => goToPage(e, '/timekeeper')}>
                    Timekeeper
                  </a>
                  .
                </Typography>
              </div>
            ) : null
          }>
          <ShopWaresAll onChanged={() => setWaresAllOpen(2)} />
        </OkDialog>
        <RealmDilemmaResultDialog />
      </>
    );
  }

  return <Redirect to={'/furverse'} />;

  return (
    <LootFoundContext.Provider value={{ lootFound, setLootFound }}>
      <FullDrawer drawer={<MyFurballs />}>{renderChildren()}</FullDrawer>
      <LootFoundInExplore />
    </LootFoundContext.Provider>
  );
};

export default Play;
