import React from 'react';

const SvgIconCircleUnchecked = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'
      focusable='false'
      viewBox='0 0 24 24'
      aria-hidden='true'
      data-testid='CircleOutlinedIcon'
      fill='currentColor'
      {...props}>
      <path d='M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'></path>
    </svg>
  );
};

export default SvgIconCircleUnchecked;
