import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import useCommonStyles from '../../components/useCommonStyles';
import SvgTix from '../../assets/SvgIconTix';
import { useVerifyReceiptMutation } from '../../components/schema';
import { useFurComponent } from '../../utils';
import { IPurchasedTickets } from './TicketPurchaseForm';
import { useFurverseContext } from '../Furverse/Furverse';

type ITicketPurchaseApp = IPurchasedTickets;

export interface IInAppPurchaseItem {
  id: string;
  priceUsd: number;
  resource: 'TIX';
  quantity: number;
}

export const inAppPurchases: IInAppPurchaseItem[] = [
  {
    id: 'furballs.tix.005',
    resource: 'TIX',
    quantity: 5,
    priceUsd: 9.99,
  },
  {
    id: 'furballs.tix.020',
    resource: 'TIX',
    quantity: 20,
    priceUsd: 34.99,
  },
  {
    id: 'furballs.tix.050',
    resource: 'TIX',
    quantity: 50,
    priceUsd: 89.99,
  },
];

export interface IPurchase extends IInAppPurchaseItem {
  txnId?: string;
  receipt?: string;
}

const TicketPurchaseApp: React.FunctionComponent<ITicketPurchaseApp> = (
  props,
) => {
  const { onPurchased } = props;
  const common = useCommonStyles();
  const { log } = useFurComponent(TicketPurchaseApp);
  const [purchasing, setPurchasing] = React.useState<IPurchase>();
  const { sendGameMessage } = useFurverseContext();
  const [verifyReceipt, { data }] = useVerifyReceiptMutation();
  const receiptVerification = data?.verifyReceipt;

  React.useEffect(() => {
    if (receiptVerification) handlePurchased();
  }, [receiptVerification]);

  function setComplete(purchasedItemId?: string) {
    setPurchasing(undefined);
    if (purchasedItemId) {
      log.info('[IAP] complete');
      onPurchased(
        inAppPurchases.find((p) => p.id === purchasedItemId)?.quantity ?? 0,
      );
    }
  }

  function handlePurchased() {
    if (!purchasing) {
      log.warn('[IAP] purchased txn', 'without item');
      setComplete();
      return;
    }
    sendGameMessage({ topic: 'Purchased', data: { id: purchasing.txnId } });
    setComplete(purchasing.id);
  }

  function handlePurchasing(txnId: string, receipt: string) {
    if (!purchasing) {
      log.warn('[IAP] purchasing txn', txnId, 'without item');
      setComplete();
      return;
    }
    log.info(
      '[IAP] purchasing',
      txnId,
      'from',
      purchasing.id,
      'receipt',
      receipt,
    );
    setPurchasing({ ...purchasing, txnId, receipt });
    verifyReceipt({ variables: { receiptData: receipt, platform: 'iOS' } });
  }
  function handlePurchase(iap: IInAppPurchaseItem): void {
    setPurchasing(iap);
    sendGameMessage({ topic: 'Purchase', data: { id: iap.id } });
  }

  // function handleVuplexMessage(e: IGameMessage) {
  //   const txnId = e.data?.id;
  //   const receipt = e.data?.secret;
  //   log.info('[IAP] message', txnId, receipt);
  //   // if (purchasing) fireSpendTixEvent(tixProfit, ethPrice, itemName, 'purchased');
  //   if (txnId) handlePurchasing(txnId, receipt ?? '');
  //   else setComplete();
  // }

  return (
    <Grid container>
      {inAppPurchases.map((iap) => {
        const { id, resource, quantity, priceUsd } = iap;
        return (
          <Grid key={id} item xs={4} spacing={1}>
            <Typography variant={'h4'} style={{ marginBottom: 14 }}>
              {quantity}{' '}
              <span>
                <SvgTix fill={'black'} style={{ width: 20 }} />
              </span>
            </Typography>
            <Button
              variant={'contained'}
              className={common.actionButton}
              color={'primary'}
              disabled={!!purchasing}
              onClick={() => handlePurchase(iap)}>
              <Typography variant={'h6'}>${priceUsd}</Typography>
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TicketPurchaseApp;
