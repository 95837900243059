import * as React from 'react';

function SvgCaret(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 21.27 31.73'
      {...props}>
      <path
        fill='none'
        stroke={`${props.fill ?? '#000'}`}
        strokeWidth={6}
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M1.91 2.31l16.36 13.55L1.91 29.42'
      />
    </svg>
  );
}

export default SvgCaret;
