import { SubscriptionClient } from 'subscriptions-transport-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { Observable } from '@apollo/client/utilities';
import { FetchResult, Operation } from '@apollo/client/link/core/types';
import { defaultLogManager, ILogger } from './utils';

export enum ConnectionState {
  Reconnect = -2,
  Disconnected = -1,
  Connecting,
  Connected,
}

export interface IServerConnection {
  isConnected: boolean;
  state: ConnectionState;
  reconnect: () => Promise<boolean>;
  awaitConnection: () => Promise<boolean>;
  requestHandler: (operation: Operation) => Observable<FetchResult> | null;
  getEndpointUrl: (schema: string, ep?: string) => string;
}

export class ServerConnection implements IServerConnection {
  // Lazy-loaded iVars prevent the connection from being established before necessary.
  private _subscriptionClient?: SubscriptionClient;
  private _webSocketLink?: WebSocketLink;

  private _state: ConnectionState = ConnectionState.Disconnected;
  private _secure = false;
  private _port = '9000';
  private _host = 'localhost';

  constructor(
    host?: string,
    // getToken?: () => Promise<string | undefined>,
  ) {
    this._secure =
      host?.includes('furballs.com') ||
      window.location.protocol.startsWith('https') ||
      window.location.port === '443';
    this._host = host ?? window.location.hostname;
    this._port =
      host?.includes('furballs.com') ||
      window.location.port === '80' ||
      window.location.port === '443'
        ? ''
        : window.location.port;
    console.log('create server connection');
  }

  public get httpUrl(): string {
    return this.getEndpointUrl(this._secure ? 'https' : 'http');
  }

  public get rootUrl(): string {
    return this.getEndpointUrl(this._secure ? 'https' : 'http', '');
  }

  // public async getToken(): Promise<string | undefined> {
  //   const user = await this._ows.authManager.getUser();
  //   return user?.access_token;
  // }

  public getEndpointUrl(schema: string, ep = '/api/graphql'): string {
    const s = schema.length > 0 ? `${schema}://` : '';
    const p = this._port.length > 0 ? `:${this._port}` : '';
    return `${s}${this._host}${p}${ep}`;
  }

  // Implementation of RequestHandler for Apollo
  public requestHandler(operation: Operation): Observable<FetchResult> | null {
    if (operation.operationName !== 'PipeLogs') {
      this.log.debug(
        '[GQL]',
        operation.operationName,
        // '[VARS]',
        // operation.variables,
      );
    }
    return this.webSocketLink.request(operation);
  }

  public async awaitConnection(): Promise<boolean> {
    this.ensureClient();
    while (!this.isConnected) {
      // The subscription client may not fire events during initial startup...
      if (this._subscriptionClient?.status === 1) {
        this.log.info('[subscription]', 'init-connected.');
        this.setState(ConnectionState.Connected);
        break;
      }

      this.log.debug(
        'connection pending...',
        ConnectionState[this.state],
        this._subscriptionClient?.status,
      );
      await new Promise((r) => setTimeout(r, 1000));
      if (this.state === ConnectionState.Disconnected) {
        this.log.warn('connection failed.');
        return false;
      }
    }
    return this.isConnected;
  }

  public get subscriptionClient(): SubscriptionClient {
    return this.ensureClient()[0];
  }
  public get webSocketLink(): WebSocketLink {
    return this.ensureClient()[1];
  }

  private ensureClient(): [SubscriptionClient, WebSocketLink] {
    if (!this._subscriptionClient || !this._webSocketLink)
      [this._subscriptionClient, this._webSocketLink] = this.rebuild();
    return [this._subscriptionClient, this._webSocketLink];
  }

  private rebuild(): [SubscriptionClient, WebSocketLink] {
    if (this._subscriptionClient) {
      this._subscriptionClient.close(true, false);
    }
    const subscriptionClient = new SubscriptionClient(
      this.getEndpointUrl(this._secure ? 'wss' : 'ws'),
      {
        reconnect: true,
        // connectionParams: async () => {
        //   const token = await this._getToken();
        //   return token ? { token } : {};
        // },
        connectionCallback: (error, result) => {
          this.log.info('[connection]', error, result);
        },
      },
    );

    subscriptionClient.onConnected((a) => {
      this.log.info('[subscription]', 'connected.', a);
      this.setState(ConnectionState.Connected);
    });

    subscriptionClient.onConnecting((a) => {
      this.log.info('[subscription]', 'connecting...', a);
      this.setState(ConnectionState.Connecting);
    });

    subscriptionClient.onReconnected((a) => {
      this.log.info('[subscription]', 're-connected.', a);
      this.setState(ConnectionState.Connected);
    });

    subscriptionClient.onReconnecting((a) => {
      this.log.info('[subscription]', 're-connecting...', a);
      this.setState(ConnectionState.Connecting);
    });

    subscriptionClient.onDisconnected((a) => {
      this.log.info('[subscription]', 'disconnected.', a);
      this.setState(ConnectionState.Disconnected);
    });

    subscriptionClient.onError((a) => {
      this.log.info('[subscription]', 'error', a);
      if (this.state === ConnectionState.Connecting) {
        this.setState(ConnectionState.Disconnected);
      }
    });

    const ws = new WebSocketLink(subscriptionClient);
    ws.setOnError((req) => {
      this.log.error('request error', req);
    });

    this.log.debug('rebuild');
    return [subscriptionClient, ws];
  }

  public async reconnect(): Promise<boolean> {
    await new Promise((r) => setTimeout(r, 1000));

    this.log.debug('reconnecting...');
    this.subscriptionClient.close(false);
    // [this._subscriptionClient, this._webSocketLink] = this.rebuild();
    return await this.awaitConnection();
  }

  private setState(state: ConnectionState) {
    if (this._state === state) return;
    this._state = state;
  }

  public get state(): ConnectionState {
    return this._state;
  }

  public get isConnected(): boolean {
    return this.state === ConnectionState.Connected;
  }

  private _logger: ILogger | undefined = undefined;
  public get log(): ILogger {
    if (!this._logger) this._logger = defaultLogManager.getLogger('WS');
    return this._logger;
  }
}
