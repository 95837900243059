import React, { FunctionComponent } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  ItemsFragment,
  RewardSource,
  useMyPriorRewardsQuery,
} from '../../components/schema';
import { getTimeBetween } from '../../utils';
import useCommonStyles from '../../components/useCommonStyles';
import { formatTimeDuration } from '../../components/BossFights/BossLeaderboards/LeaderboardResetCountdown';
import InventorySlot from '../../components/Inventory/InventorySlot';
import { useWalletSelector } from '../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.9rem',
    width: '100%',
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0.15rem',
    marginTop: '2.8rem',

    '&::-webkit-scrollbar': {
      [theme.breakpoints.down('lg')]: {
        width: '0',
      },
    },
  },
  row: {
    // display: 'flex',
    borderRadius: '6px',
    backgroundColor: 'white',
    // padding: '0.2rem 0.75rem',
    // paddingLeft: '1rem',
    // minHeight: '67.5px',
    // maxHeight: '67.5px',
    // alignItems: 'center',
    // justifyContent: 'space-between',
    //
    // [theme.breakpoints.down('lg')]: {
    //   padding: '0.2rem 0.5rem',
    //   paddingLeft: '0.75rem',
    // },
    //
    // [theme.breakpoints.down('md')]: {
    //   padding: '0.2rem 0.35rem',
    //   paddingLeft: '0.5rem',
    // },
  },
  cell: {
    textAlign: 'left',
  },
  slot: {},
}));

interface IPlayerRewards {}

export interface IPlayerPendingReward {
  id: string;
  expiresAt: Date | number | string;
  source: RewardSource | null;
  sourcePosition: number | null;
  notificationId: string | null;
  claimedAt: Date | number | string | null;
  createdAt: Date | number | string | null;
  items: ItemsFragment[];
}

export function getRewardSourceName(source: RewardSource | null): string {
  if (!source) return 'None';
  if (source === RewardSource.LootBox) return 'Loot Box';
  if (source === RewardSource.RentalAgreement) return 'Rented Furball';
  if (
    source === RewardSource.CraftingFurball ||
    source == RewardSource.CraftingPlayer
  )
    return 'Crafting';

  const src = source.toString();
  const pts = src.split('_BOSS_LEADERBOARD_');
  if (pts.length === 2) {
    return (
      pts[0].substr(0, 1) +
      pts[0].substring(1).toLowerCase() +
      ' Tournament: ' +
      (pts[1].substr(0, 1) + pts[1].substring(1).toLowerCase())
    );
  }

  return src.substr(0, 1) + src.substring(1).toLowerCase();
}

export const PlayerRewards: FunctionComponent<IPlayerRewards> = (props) => {
  const classes = useStyles();
  const { data, loading, error } = useMyPriorRewardsQuery();
  const common = useCommonStyles();
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');
  const rewards = data?.priorRewards?.nodes ?? [];

  function renderReward(reward: IPlayerPendingReward): React.ReactNode {
    const createdAt = new Date(
      reward.createdAt ?? new Date().getTime(),
    ).getTime();
    const timeAgo = getTimeBetween((new Date().getTime() - createdAt) / 1000);

    return (
      <Grid
        key={reward.id}
        item
        xs={12}
        md={4}
        lg={2}
        className={classes.row}
        // style={{
        //   marginRight: '1rem',
        // }}
      >
        <Typography variant='h5'>
          {getRewardSourceName(reward.source)}
        </Typography>
        <Typography variant={'subtitle2'}>
          {formatTimeDuration(timeAgo)} Ago
        </Typography>
        {reward.items.map((item) => {
          return (
            <Grid
              key={item.id}
              container
              alignItems='center'
              justifyContent='center'>
              <InventorySlot
                isListing
                containerId={playerId}
                item={item}
                small={true}
                className={classes.slot}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <Grid
      // ref={bossBattlesRef}
      className={clsx(common.styledScrollBar, classes.root, {
        // [classes.noScrollOut]: bossBattlesForSelectedRealm.length > 0,
      })}
      container>
      {rewards.map(renderReward)}
    </Grid>
  );
};

export default PlayerRewards;
