import React, { FunctionComponent } from 'react';
import { Grid, Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  ItemListingFragment,
  EquipmentItemFragment,
} from '../../../components/schema';
import theme, { shadeColor } from '../../../themev2';
import InventorySlot from '../../../components/Inventory/InventorySlot';
import SvgIconWrappedFur from '../../../assets/SvgIconWrappedFur';
import { getTimeBetween } from '../../../utils';
import SvgIconGearTime from '../../../assets/SvgIconGearTime';
import SvgIconCoinStack from '../../../assets/SvgIconCoinStack';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    borderRadius: '10px 10px 10px',
    overflow: 'hidden',
    border: `3px solid ${theme.palette.common.black}`,
    backgroundColor: 'white',
    color: 'black',
    textAlign: 'center',
  },

  stats: {
    padding: '1rem',
  },
}));

interface ILootListing {
  listing: ItemListingFragment;
  purchase: (l: ItemListingFragment) => void;
}

export const LootListing: FunctionComponent<ILootListing> = (props) => {
  const { listing, purchase } = props;
  const classes = useStyles();
  const backgroundColor = '#FFFFFF';

  const equipment =
    listing.item.__typename === 'EquipmentItem'
      ? (listing.item as EquipmentItemFragment)
      : undefined;

  const isStacking =
    listing.item.maxStack > 1 &&
    (listing.item.__typename === 'MaterialItem' ||
      listing.item.__typename === 'ConsumableItem');

  const dur = equipment ? getTimeBetween(equipment.durabilityTime) : undefined;
  const subtext = dur
    ? `${dur.days} days`
    : isStacking
    ? `${listing.currentPriceEach.toLocaleString()} each`
    : undefined;

  function onSelected() {
    purchase(listing);
  }

  return (
    <div className={clsx(classes.root)}>
      <Grid container alignItems='center' justifyContent='center'>
        <InventorySlot
          isListing
          containerId={'market'}
          item={listing.item}
          hideAmount={false}
          hideRarity={false}
          onSelectItem={onSelected}
        />
      </Grid>

      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        direction='column'
        columnGap='0.5rem'
        rowGap='0.2rem'
        className={classes.stats}
        style={{
          backgroundColor: shadeColor(backgroundColor, -40),
        }}>
        <div>
          <Grid container alignItems='center' gap='0.3rem'>
            <SvgIconWrappedFur
              height={20}
              width={20}
              fill={theme.palette.secondary.dark}
            />

            <Grid container gap='0.3rem' style={{ width: 'fit-content' }}>
              <Typography
                variant={'h6'}
                fontSize='1rem'
                color={theme.palette.secondary.dark}>
                {listing.currentPrice.toLocaleString()}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div style={{ cursor: 'pointer' }}>
          <Tooltip title={dur ? 'Durability' : 'Price Per Item'}>
            <Grid container alignItems='center' gap='0.3rem'>
              {dur ? (
                <SvgIconGearTime
                  height={20}
                  width={20}
                  fill={theme.palette.grey[700]}
                />
              ) : (
                <SvgIconCoinStack
                  height={19}
                  width={19}
                  fill={theme.palette.grey[700]}
                />
              )}
              <Typography
                variant={'h6'}
                fontSize='0.9rem'
                fontWeight='800'
                color={theme.palette.grey[800]}>
                {subtext ?? ''}
              </Typography>
            </Grid>
          </Tooltip>
        </div>
      </Grid>
    </div>
  );
};

export default LootListing;
