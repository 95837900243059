import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Corgi = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffc9ce'
      d='M265.29 55.41c-29.08 7.54-29.81 55.15-29.81 55.15l.15.55c9.68 7.93 16.23 18.59 18.51 30.21a17.88 17.88 0 0 0 3.55 7.71c8.89-2.66 16.95-27.49 16.95-27.49 9.85-27.91-9.35-66.13-9.35-66.13z'
    />
    <path
      fill='#f0a8b3'
      d='M240.14 117.39c10.49 8.03 13.94 28.66 16.48 30.13 1.35-1.19 1.6-4.25-.26-7.44C240.39 112.71 265 58 265 58c-25.78 6.69-39.79 37.93-42.63 44.92 4.21 1.89 14.14 11.69 17.77 14.47z'
    />
    <path
      fill={props.renderer.color1}
      d='M265.29 55.41c-29.08 7.54-45.1 50.35-45.1 50.35-.76 5.7 20.69 17.03 20.69 17.03-7.2-40.67 24.41-67.38 24.41-67.38z'
    />
    <path
      opacity={0.51}
      fill={props.renderer.color1}
      d='M220.29 105.06s15.67-41.83 45.38-48.89c0 0-41.69 26.14-32.07 49.92.95 2.34-.29 4.78-2.62 4.48l-10.69-5.51z'
    />
    <path d='M266.89 56.87c-4.15 4.66-8.08 9.73-11.65 14.94-3.56 5.23-6.83 10.66-9.47 16.36-1.33 2.84-2.49 5.76-3.39 8.73-.9 2.97-1.6 5.99-1.85 9.04-.25 3.04-.09 6.08.66 8.96.74 2.88 2.08 5.57 3.94 7.89l.02.02a1.666 1.666 0 0 1-2.59 2.1 24.404 24.404 0 0 1-4.71-9.13c-.91-3.32-1.12-6.77-.89-10.13.23-3.37.96-6.64 1.87-9.82.92-3.18 2.1-6.26 3.45-9.25 2.68-5.99 5.98-11.63 9.57-17.06 3.63-5.42 7.5-10.58 11.82-15.56.78-.9 2.15-1 3.05-.21.9.78 1 2.15.21 3.05l-.01.01-.03.06z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M220.66 104.64c1.03-2.73 15.45-41.65 44.63-49.22 0 0 29.03 66.31-7.6 93.61'
    />
    <path
      fill='#ffc9ce'
      d='M127.37 55.41c29.08 7.54 29.81 55.15 29.81 55.15l-.15.55c-9.68 7.93-16.23 18.59-18.51 30.21a17.88 17.88 0 0 1-3.55 7.71c-8.89-2.66-16.95-27.49-16.95-27.49-9.85-27.91 9.35-66.13 9.35-66.13z'
    />
    <path
      fill='#f0a8b3'
      d='M158.5 109.94c-10.49 8.03-17.58 19.17-19.98 31.37-.44 2.24-1.28 4.33-2.48 6.21-1.35-1.19-1.6-4.25.26-7.44 15.97-27.37-8.64-82.08-8.64-82.08 25.78 6.69 39.79 37.93 42.63 44.92a59.327 59.327 0 0 0-11.79 7.02z'
    />
    <path
      fill={props.renderer.color1}
      d='M127.37 55.41c29.08 7.54 45 51.26 45 51.26l-20.59 16.12c7.2-40.67-24.41-67.38-24.41-67.38z'
    />
    <path
      opacity={0.51}
      fill={props.renderer.color1}
      d='M172.37 105.06s-15.67-41.83-45.38-48.89c0 0 41.69 26.14 32.07 49.92-.95 2.34.29 4.78 2.62 4.48l10.69-5.51z'
    />
    <path d='M128.97 53.96c4.32 4.98 8.2 10.14 11.82 15.56 3.59 5.42 6.9 11.06 9.57 17.06 1.35 2.99 2.53 6.07 3.45 9.25.92 3.18 1.64 6.45 1.87 9.82.23 3.36.02 6.81-.89 10.13-.89 3.32-2.52 6.47-4.71 9.13-.59.71-1.64.81-2.34.22a1.66 1.66 0 0 1-.24-2.32l.02-.02c1.86-2.33 3.21-5.01 3.94-7.89.75-2.88.91-5.92.66-8.96-.25-3.04-.95-6.07-1.85-9.04-.9-2.97-2.06-5.89-3.39-8.73-2.63-5.7-5.91-11.13-9.47-16.36-3.57-5.2-7.5-10.28-11.65-14.94l-.04-.05c-.79-.89-.71-2.26.18-3.05.89-.79 2.26-.71 3.05.18 0 0 .01.01.02.01z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M172 104.64c-1.03-2.73-15.45-41.65-44.63-49.22 0 0-29.03 66.31 7.6 93.61'
    />
  </svg>
);

export default Corgi;
