import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Freckles = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <g opacity={0.8} fill='#ff149a'>
      <circle cx={148} cy={179} r={5} />
      <circle cx={157} cy={185} r={2} />
      <circle cx={153} cy={169} r={2} />
      <circle cx={161} cy={175} r={4} />
    </g>
  </svg>
);

export default Freckles;
