import React, { FunctionComponent } from 'react';
import PlayButton from './PlayButton';
import { ISelectedFurball, ZoneCard } from '../../components/Zones';
import { useWalletSelector } from '../../hooks';
import { RealmType } from '../../components/schema';
import {
  IFurball,
  ITransactionError,
  TransactionState,
} from '../../wallet/WalletTypes';
import {
  allRealms,
  getRealmName,
  ZONE_NUM_VOYAGE,
} from '../../components/Zones/ZoneHelpers';
import { useFurComponent } from '../../utils';

interface IVoyage {}

function getRealmTabs(): { [key: string]: string } {
  const tabs: { [key: string]: string } = {};
  allRealms.forEach((r) => {
    tabs[r.toString()] = getRealmName(r);
  });
  return tabs;
}

export const VoyageZone: FunctionComponent<IVoyage> = (props) => {
  // const classes = useStyles();

  const { log } = useFurComponent(VoyageZone);
  const zoning = useWalletSelector((s) => Object.keys(s.pending));
  const loading = false;
  const isLoading = zoning.length > 0 || loading;
  const [selectedFurballs, setSelectedFurballs] = React.useState<
    ISelectedFurball[]
  >([]);
  const [realm, setRealm] = React.useState(RealmType.TurtleSea.toString());
  const tabs = getRealmTabs();

  function onState(s: TransactionState, error?: ITransactionError) {
    log.info('Zone');
  }

  function isFurballInTab(fb: IFurball, tab: string): boolean {
    return (fb.realm ?? RealmType.TurtleSea).toString() === tab;
  }

  return (
    <ZoneCard
      zoneNumber={ZONE_NUM_VOYAGE}
      disabled={isLoading}
      title={undefined}
      isFurballInTab={isFurballInTab}
      doesFurballHaveNotice={(fb) =>
        !!fb.activeVoyage?.activeEncounter &&
        !fb.activeVoyage.activeEncounter.didPlayerComplete
      }
      tabs={tabs}
      selectedTab={realm}
      setSelectedTab={setRealm}
      loading={loading}
      selectedFurballs={selectedFurballs}
      setSelectedFurballs={setSelectedFurballs}
      background={realm.toLowerCase()}
      // selectAllFurballs={selectAll}
      action={
        <PlayButton
          furballIds={selectedFurballs.map((item) => item.id)}
          setSelectedFurballs={setSelectedFurballs}
          setState={onState}
          zoneNumber={ZONE_NUM_VOYAGE}
          isLoading={isLoading}
          timekeeperDisabled={false}
        />
      }
    />
  );
};

export default VoyageZone;
