import * as React from 'react';

function SvgLobbyHeart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 112.3 102'
      enableBackground='new 0 0 112.3 102'
      xmlSpace='preserve'
      {...props}>
      <g>
        <g>
          <g>
            <path
              fill='#13F3A4'
              d='M12,52.9c-5.5-5.5-8.5-12.7-8.5-20.5c0-7.7,3-15,8.5-20.5c5.5-5.5,12.7-8.5,20.5-8.5s15,3,20.5,8.5
				 l3.3,3.3l3.3-3.3c5.5-5.5,12.7-8.5,20.5-8.5s15,3,20.5,8.5c5.5,5.5,8.5,12.7,8.5,20.5c0,7.7-3,15-8.5,20.5L56.1,97.1L12,52.9z'
            />
            <path
              d='M32.4,7c6.5,0,13,2.5,18,7.4l5.8,5.8l5.7-5.7c5-5,11.5-7.4,18-7.4s13,2.5,18,7.4c9.9,9.9,9.9,26,0,36l-5.8,5.8l-36,36
				 l-36-36l-5.8-5.8c-9.9-9.9-9.9-26,0-36l0,0C19.4,9.5,25.9,7,32.4,7 M32.4,0C23.8,0,15.6,3.4,9.5,9.5c-12.6,12.6-12.6,33.2,0,45.9
				 l5.8,5.8l36,36l4.9,4.9l4.9-4.9l36-36l5.8-5.8c6.1-6.1,9.5-14.3,9.5-22.9c0-8.7-3.4-16.8-9.5-22.9C96.7,3.4,88.5,0,79.9,0
				 S63.1,3.4,56.9,9.5l-0.8,0.8l-0.8-0.8C49.2,3.4,41.1,0,32.4,0L32.4,0z'
            />
          </g>
        </g>
        <g>
          <path
            fill='#7DFFD2'
            d='M28.2,18.3L28.2,18.3c4.9-4.9,11.3-7.4,17.7-7.4c-9.8-6.2-22.9-5-31.5,3.6l0,0c-9.9,9.9-9.9,26,0,36
			 l5.8,5.8l36,36L28.2,54.3C18.4,39.6,18.3,28.3,28.2,18.3z'
          />
        </g>
        <g>
          <path
            fill='#7DFFD2'
            d='M62.9,20.6c8.9-15.1,24-9.6,30.4-9.7c0,0-0.1-0.1-0.1-0.1c-9.8-6-22.8-4.8-31.3,3.7l-5.7,5.7l0,0
			 C56,24.1,60.7,24.3,62.9,20.6z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgLobbyHeart;
