import React from 'react';
import { ILogger, ILogManager, useDefaultLogManager } from './logging';

type UnknownHook = () => unknown;

export type FurComponent<TProps> =
  | React.ComponentType<unknown>
  | React.FunctionComponent<TProps>
  | string;
export interface IFurComponent {
  name: string;
  // ows: IOpenWorkShop;
  // t: TTranslateFunc;
  log: ILogger;
  logManager: ILogManager;
  // analytics: IAnalytics;
  // theme: Theme;
}

export function getComponentName<TProps>(
  component: FurComponent<TProps>,
): string {
  if (typeof component === 'string') return component;
  return component.displayName || component.name;
}

export function useLogger(name: string, id?: string): ILogger {
  const logManager = useDefaultLogManager();

  return React.useMemo(() => {
    return logManager.getLogger(name, id);
  }, [name, id]);
}

// Convenience function for loading common utilities in a component.
export function useFurComponent<TProps>(
  component: FurComponent<TProps>,
  id?: string,
  screenView?: boolean,
): IFurComponent {
  // const t = useTrans();
  // const ows = useOpenWorkShop();
  const logManager = useDefaultLogManager();
  const name = getComponentName(component);
  // const theme = useTheme();

  // React.useEffect(() => {
  //   if (screenView) analyticsRoot.screenView(name);
  // }, [name, screenView]);

  return React.useMemo(() => {
    const log = logManager.getLogger(name, id);
    // const analytics = analyticsRoot.forComponent(name);
    return { name, log, logManager };
  }, [name, id]);
}

// Same as useMakerHubComponent, but defaults to screenView: true
export function useFurScreen<TProps>(
  component: FurComponent<TProps>,
  id?: string,
): IFurComponent {
  return useFurComponent(component, id, true);
}
