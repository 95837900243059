import { inIframe } from '../../wallet/Wallet';

export enum GameWrap {
  Iframe = -1, // embedded
  Unknown, // Cannot launch / use
  Web3, // normal desktop web view
  App, // Embedded within a distributed app
}

export interface IEventWithData {
  data: string;
}

export function getGameWrap(): GameWrap {
  if (inIframe()) return GameWrap.Iframe;
  if (window.ethereum) return GameWrap.Web3;
  if (isVuplexExpected()) return GameWrap.App;
  return GameWrap.Unknown;
}

export function isVuplexExpected() {
  // // @ts-ignore
  // const hasWindowVuplex = !!window.vuplex;
  return (
    !inIframe() &&
    // hasWindowVuplex ||
    (document.cookie.includes('FurballsGame') ||
      window.navigator.userAgent.includes('Furballs') ||
      window.location.pathname.startsWith('/app/'))
  );
}
