import React, { FunctionComponent } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useWalletSelector } from '../../../hooks';

import clsx from 'clsx';
import theme from '../../../themev2';

import {
  getFilteredItems,
  getItemQuantityList,
  IOwnedItem,
  useFurballItemList,
  useInventoryHelpers,
} from '../../../components/Inventory/InventoryHelpers';
import {
  ALL_INVENTORIES_OPTION,
  ITEMGROUP_FILTER_OPTIONS,
} from '../../../components/Inventory/InventoryFilters';
import InventorySlot, {
  IInventorySlot,
} from '../../../components/Inventory/InventorySlot';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  inventoryView: {
    minHeight: '20rem',
  },

  trigger: {
    cursor: 'pointer',
    borderRadius: '16px',
    border: `2px solid ${theme.palette.grey[300]}`,
  },

  item: {
    border: `2px solid ${theme.palette.secondary.light}`,
    backgroundColor: `${theme.palette.secondary.dark} !important`,
    color: '#A5B4FC',
    cursor: 'pointer',
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
  },
}));

interface ISelectOwnedLoot {
  item?: IOwnedItem;
  selectedItemId: number;
  showInventory: boolean;
  setItem: (item?: IOwnedItem) => void;
  setSelectedItemId: (id: number) => void;
  setShowInventory: (show: boolean) => void;
  selectedFromInventory?: boolean;
}

export const SelectOwnedItem: FunctionComponent<ISelectOwnedLoot> = (props) => {
  const classes = useStyles();
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');
  const furballItems = useFurballItemList({
    playerOnly: false,
    includePlayerInventory: true,
    filterOnInventoryFor: ALL_INVENTORIES_OPTION,
  });

  const {
    item,
    setItem,
    selectedItemId,
    setSelectedItemId,
    showInventory,
    setShowInventory,
  } = props;

  const { inventoryItemQuantities, allInventoryItems } = useInventoryHelpers();

  const itemQuantities = getItemQuantityList(
    inventoryItemQuantities,
    furballItems,
    true,
  );

  const allItems = allInventoryItems(true, true);

  const inventoryItems = getFilteredItems(
    allItems,
    selectedItemId,
    ITEMGROUP_FILTER_OPTIONS[0],
  );

  function renderSlots() {
    const slotProps: IInventorySlot = {
      containerId: playerId,
    };

    if (selectedItemId <= 0) {
      return itemQuantities.map((quant, i) => {
        return (
          <InventorySlot
            {...slotProps}
            fluidSize
            small
            className={classes.item}
            key={`inventory-slot-${quant.itemId}`}
            itemQuantity={quant}
            onSelectItemId={() => setSelectedItemId(quant.itemId)}
          />
        );
      });
    }

    return inventoryItems.map((ownedItem, i) => {
      const item = ownedItem?.item;

      return (
        <InventorySlot
          {...slotProps}
          fluidSize
          small
          key={`inventory-slot-${i}`}
          item={item}
          onSelectItem={() => {
            setItem(ownedItem ?? undefined);
            setSelectedItemId(0);
            setShowInventory(false);
          }}
          furball={ownedItem?.furball}
        />
      );
    });
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.inventoryView]: showInventory,
      })}>
      <Grid
        item
        container
        alignItems='center'
        justifyContent='center'
        height='100%'
        width='100%'>
        <Grid
          container
          width='136px'
          height='136px'
          justifyContent='center'
          alignItems='center'
          onClick={() => setShowInventory(true)}
          className={item ? undefined : classes.trigger}>
          {item ? (
            <InventorySlot small containerId={playerId} item={item.item} />
          ) : (
            <Typography
              variant='h6'
              color={theme.palette.grey[500]}
              fontSize='3rem'>
              +
            </Typography>
          )}
        </Grid>

        {showInventory && (
          <div
            style={{
              position: 'absolute',
              inset: '0',
              zIndex: 10,
              backgroundColor: theme.palette.secondary.dark,
              overflow: 'auto',
            }}>
            <div
              style={{
                padding: '1rem',
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
              }}>
              {renderSlots()}
            </div>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default SelectOwnedItem;
