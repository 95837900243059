import React from 'react';
import { ISpinLootCell } from './VoyageGameRealmSpinner';
import {
  getLootItem,
  getLootSlot,
  LOOT_ID_EXP_1_SHOE,
  LOOT_ID_EXP_2_FRISBEE,
  LOOT_ID_EXP_3_LASER_POINTER,
  LOOT_ID_FUR_1_SPRAY,
  LOOT_ID_FUR_2_TILIZER,
  LOOT_ID_FUR_3_GAINE,
  // LOOT_ID_LUCK_1_SNOWBALLS,
} from '../../Loot';

export const wizardsPathSpinCells: ISpinLootCell[] = [
  {
    svg: (
      <path
        key={'0'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M627.21 226.78l30.76-15.04c-13.38 7.91-33.41 23.47-47.13 50.97l31.08-17.58 195.19 112.03V157.2c.01 0-134.27-48.3-209.9 69.58z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_3_GAINE)),
    offset: { x: -60, y: 150 },
  },
  {
    svg: (
      <path
        key={'1'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M641.92 245.13s-47.75 33.65-50.1 113.42c-2.35 79.77-23.46 84.15-23.46 84.15s34.57 6.64 47.33-36.24c0 0-14.11 57.78 13.14 93.98L640.67 472l196.45-112.75v-2.07l-195.2-112.05z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_1_SPRAY)),
    offset: { x: -160, y: 0 },
  },
  {
    svg: (
      <path
        key={'2'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M837.12 359.24L640.67 472c34.93 56.03 29.59 81.56 29.59 81.56 28.8-6.86 17.99-52.38 17.99-52.38S729.51 569 837.12 605.59V359.24z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_2_FRISBEE)),
    offset: { x: -60, y: -150 },
  },
  {
    svg: (
      <path
        key={'4'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M837.31 359.24L1033.76 472c-34.93 56.03-29.59 81.56-29.59 81.56-28.8-6.86-17.99-52.38-17.99-52.38S944.92 569 837.31 605.59V359.24z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_3_LASER_POINTER)),
    offset: { x: 60, y: -150 },
  },
  {
    svg: (
      <path
        key={'3'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M1032.5 245.13s47.75 33.65 50.1 113.42c2.35 79.77 23.46 84.15 23.46 84.15s-34.57 6.64-47.33-36.24c0 0 14.11 57.78-13.14 93.98L1033.76 472 837.31 359.24v-2.07l195.19-112.04z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_2_TILIZER)),
    offset: { x: 160, y: 0 },
  },
  {
    svg: (
      <path
        key={'2'}
        fill='#BEF900'
        stroke='#C99361'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M1047.22 226.78l-30.76-15.04c13.38 7.91 33.41 23.47 47.13 50.97l-31.08-17.58-195.2 112.04V157.2s134.28-48.3 209.91 69.58z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_1_SHOE)),
    offset: { x: 60, y: 150 },
  },
];
