import * as React from 'react';

function SvgVoyageSpeechBubble(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 145.64 130.64'
      {...props}>
      <defs>
        <path
          id='voyage-speech-bubble_svg__a'
          d='M-473.36 235.43h1067v380h-1067z'
        />
      </defs>
      <clipPath id='voyage-speech-bubble_svg__b'>
        <use xlinkHref='#voyage-speech-bubble_svg__a' overflow='visible' />
      </clipPath>
      <g clipPath='url(#voyage-speech-bubble_svg__b)'>
        <path
          fill='#86A4E3'
          d='M500.91 58.14c-4.82 9.91-19.33 13.57-29.66 7.33-19.47-11.75-43.31-18.67-69.08-18.67-63.36 0-115.13 41.81-118.64 94.5-2.27-.14-4.56-.21-6.87-.21-44.69 0-81.99 26.69-90.71 62.2-14.18-7.44-30.92-11.74-48.85-11.74-34.87 0-65.23 16.24-81.03 40.24-16.97-17.97-43.14-29.49-72.5-29.49-4.2 0-8.34.24-12.39.7 0-.23.01-.46.01-.7 0-43.14-41.47-78.11-92.63-78.11-15.72 0-30.53 3.31-43.5 9.13-4.85-25.52-31.08-45.01-62.71-45.01-8.73 0-17.05 1.49-24.62 4.17-8.12-21.69-32.17-37.42-60.58-37.42-3.37 0-6.68.22-9.91.65-16.63-29.69-52.46-50.23-93.98-50.23-22.7 0-43.7 6.14-60.79 16.56V481.96H596V-3.27c-43.17 4.55-79.14 28.62-95.09 61.41z'
        />
      </g>
      <g>
        <path
          fill='#FFF'
          stroke='#000'
          strokeWidth={4}
          strokeMiterlimit={10}
          d='M118.68 13.4H26.96c-7.64 0-13.84 6.2-13.84 13.84v56.33c0 7.64 6.2 13.84 13.84 13.84h67.58l19.74 19.83V97.41h4.4c7.64 0 13.84-6.2 13.84-13.84V27.24c0-7.65-6.2-13.84-13.84-13.84z'
        />
        <path d='M51.42 45.19c0 1.4-.41 2.48-1.24 3.24-.83.76-2.03 1.13-3.61 1.11l-8.22-.12V30.64l7.83-.12c1.58-.04 2.77.32 3.58 1.06.81.75 1.21 1.83 1.21 3.25v1.95c0 1.28-.41 2.22-1.24 2.82-.83.6-2.03.9-3.61.9l.18-1.15c1.73-.02 3.02.27 3.86.87.84.6 1.26 1.58 1.26 2.94v2.03zm-9.6-12.33v6.12l-.5-.53h5.73c.28 0 .41-.13.41-.38v-4.2c0-.26-.14-.38-.41-.38h-5.73l.5-.63zm0 7.86v6.3l-.5-.47h6.18c.28 0 .41-.13.41-.38V41.7c0-.28-.14-.41-.41-.41h-6.18l.5-.57zM68.33 49.41h-3.46l-1.03-4.82.35.3h-7.42l.35-.3-1.18 4.82h-3.25l4.14-15.67c.24-.85.48-1.51.74-1.98s.62-.8 1.09-.99c.47-.19 1.12-.27 1.95-.25l3.49.12 4.23 18.77zm-8.54-15.43l-2.07 8.22-.24-.3h6.09l-.24.3-1.89-8.57h-1.3c-.12 0-.2.03-.24.07-.03.05-.07.15-.11.28zM74.15 49.41h-3.49V30.64l7.95-.12c1.58-.04 2.77.32 3.59 1.06.82.75 1.23 1.83 1.23 3.25v2.99c0 1.14-.31 2.1-.93 2.87-.62.77-1.53 1.25-2.73 1.45v-.68c.61.12 1.09.35 1.45.71.35.35.66.9.92 1.63l1.98 5.62h-3.49l-2.25-6.71c-.06-.16-.11-.27-.16-.34s-.16-.1-.34-.1h-4.29l.56-.56v7.7zm0-16.46v6.86l-.5-.53h5.82c.28 0 .41-.13.41-.38v-4.97c0-.26-.14-.38-.41-.38h-5.82l.5-.6zM90.53 49.41h-3.46V30.64h3.46v18.77zm3.48-7.95h-4.17v-1.83h4.2c.93 0 1.63.08 2.11.24.48.16.88.45 1.18.87.31.42.65 1.03 1.02 1.82l3.25 6.86h-3.69l-3.55-7.6a.844.844 0 00-.12-.28c-.03-.05-.11-.08-.23-.08zm-.14-1.18h-3.93v-1.86h4.05c.14 0 .23-.03.27-.09.04-.06.08-.15.12-.27l3.07-7.42h3.49l-2.84 6.71c-.26.59-.49 1.08-.71 1.48-.22.39-.46.69-.74.9-.28.21-.64.35-1.08.43-.45.08-1.01.12-1.7.12zM106.52 43.38h-1.92l-.77-8.57v-4.46h3.46v4.46l-.77 8.57zm.68 6.03h-3.28v-3.69h3.28v3.69z' />
        <g>
          <path d='M62.02 83.99H60.6c-1.62 0-2.83-.37-3.65-1.11-.82-.74-1.23-1.83-1.23-3.27V63.25c0-1.46.41-2.55 1.23-3.28.82-.73 2.03-1.09 3.65-1.09h1.42v2.51h-2.54c-.22 0-.33.12-.33.35v19.39c0 .24.11.35.33.35h2.54v2.51zM69.97 64.22h1.36c1.24 0 2.15.26 2.72.77.57.51.86 1.39.86 2.63v11.35h-3.34V67.3c0-.24-.12-.36-.35-.36h-3.55c-.24 0-.35.12-.35.36l.03 11.68H64l-.03-19.95h3.34v7.92l-.21.38c0-1.01.23-1.77.68-2.31.45-.53 1.18-.8 2.19-.8zM81.32 62.18h-3.34V59.2h3.34v2.98zm0 16.79h-3.34V64.49h3.34v14.48zM83.62 83.99v-2.51h2.51c.24 0 .35-.12.35-.35V61.74c0-.24-.12-.35-.35-.35h-2.51v-2.51h1.42c1.62 0 2.83.37 3.65 1.09.82.73 1.23 1.82 1.23 3.28v16.37c0 1.44-.41 2.53-1.23 3.27-.82.74-2.03 1.11-3.65 1.11h-1.42z' />
        </g>
      </g>
    </svg>
  );
}

export default SvgVoyageSpeechBubble;
