import * as React from 'react';
import { NotificationFragment, useNotifySubscription } from '../schema';
import { SocketClient } from '../../FurballClient';

interface INotificationSubscription {
  playerId: string;
  onNotify: (n: NotificationFragment) => void;
}

const NotificationsSubscription: React.FunctionComponent<
  INotificationSubscription
> = ({ playerId, onNotify }) => {
  const { data } = useNotifySubscription({
    variables: { playerId },
    context: { clientName: SocketClient },
  });

  const notification = data?.notify;

  React.useEffect(() => {
    notification && onNotify(notification);
  }, [notification]);

  return null;
};

export default NotificationsSubscription;
