import { IFurballContext } from '../../wallet/WalletTypes';
import { TimekeeperArgsInput } from '../schema';
import { getSignatureDomain } from '../../blockchain';
import { ITimekeeperArgs } from './TimekeeperSlice';

export const FurgreementDomain = {
  name: 'Furgreement',
  version: '1',
  // "chainId": 31337,
  // "verifyingContract": context.addresses.furgreement,
};

export const TimekeeperRequestSigType = {
  TimekeeperRequest: [
    { name: 'sender', type: 'address' },
    { name: 'fuel', type: 'uint32' },
    { name: 'fur_gained', type: 'uint32' },
    { name: 'fur_spent', type: 'uint32' },
    { name: 'mint_edition', type: 'uint8' },
    { name: 'mint_count', type: 'uint8' },
    { name: 'deadline', type: 'uint64' },
  ],
};

const domainType = {
  EIP712Domain: [
    { name: 'name', type: 'string' },
    { name: 'version', type: 'string' },
    { name: 'chainId', type: 'uint256' },
    { name: 'verifyingContract', type: 'address' },
  ],
};

export async function signTimekeeperRequest(
  input: ITimekeeperArgs,
  sender: string,
  context: IFurballContext,
): Promise<[TimekeeperArgsInput, string]> {
  const domain = getSignatureDomain(
    FurgreementDomain,
    context.network.deployment?.addresses.furgreement,
    context,
  );
  const deadline =
    input.deadline ?? Math.round(new Date().getTime() / 1000) + 3600 * 72;
  const message = {
    sender,
    fuel: input.fuelCost,
    fur_gained: input.gained,
    fur_spent: input.spent,
    mint_edition: input.mintEdition,
    mint_count: input.mintCount,
    deadline,
  };

  const data = JSON.stringify({
    types: {
      ...domainType,
      ...TimekeeperRequestSigType,
    },
    domain: domain,
    primaryType: Object.keys(TimekeeperRequestSigType)[0],
    message: message,
  });

  const signature = await context.provider.send('eth_signTypedData_v4', [
    sender,
    data,
  ]);

  // const signature = await context.provider.getSigner()._signTypedData(domain, TimekeeperRequestSigType, message);
  return [
    {
      gained: input.gained,
      mintEdition: input.mintEdition,
      mintCount: input.mintCount,
      sender,
      spent: input.spent,
      fuelCost: input.fuelCost,
      deadline,
      movementList: input.movementList,
      collectStayList: input.collectStayList,
    },
    signature,
  ];
}
