import { Box, Grid } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import useCommonStyles from '../../components/useCommonStyles';

interface IUnifursityPage {}

const UnifursityPage: React.FunctionComponent<IUnifursityPage> = (props) => {
  const common = useCommonStyles();

  return (
    <Box
      className={clsx(common.fullCenter, common.furBk)}
      style={{}}
      display={'flex'}>
      <Box m={'auto'}>
        <Grid container maxWidth='sm' alignItems={'center'} spacing={2}>
          <Grid item xs={12}>
            Rented Furballs
          </Grid>
          <Grid item xs={12}>
            {/*<iframe src={'https://guide.furballs.com'} />*/}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UnifursityPage;
