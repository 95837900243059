import React, { FunctionComponent } from 'react';
import { useFurComponent } from '../../utils';
import { useFurverseContext } from '../../pages/Furverse/Furverse';

export interface IOutboundLinkProps {
  eventLabel?: string;
  to: string;
  target?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const OutboundLink: FunctionComponent<IOutboundLinkProps> = (props) => {
  const { eventLabel, to, target, children, className, style } = props;
  const { log } = useFurComponent(OutboundLink);
  const { sendGameMessage } = useFurverseContext();

  function handleClick(): void {
    // analytics.interactionEvent('outbound', { label: eventLabel });
    sendGameMessage({ topic: 'OutboundLink', data: { id: to } });
    log.info('[LINK]', '[OUT]', to);
  }

  return (
    <a
      href={to}
      target={target ?? '_blank'}
      rel='noopener noreferrer'
      onClick={handleClick}
      className={className}
      style={style}>
      {children}
    </a>
  );
};

export default OutboundLink;
