import React, { FunctionComponent } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IHaveVoyage } from '../../wallet/WalletTypes';
import SvgVoyageProgressBar from '../../assets/voyages/VoyageProgressBar';
import SvgVoyageProgressComplete from '../../assets/voyages/VoyageProgressComplete';
import SvgVoyageProgressPin from '../../assets/voyages/VoyageProgressPin';
import { getRealmColor } from './ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 100,
    position: 'relative',
    color: '#666',
  },
  pin: {
    position: 'absolute',
    left: -12,
    bottom: 12,
    width: 24,
    height: 24,
  },
  label: {
    position: 'absolute',
    bottom: 3.5,
  },
  label1: {
    left: 13,
  },
  label2: {
    left: 42,
  },
  label3: {
    right: 9,
  },
}));

interface IVoyageProgressBar extends IHaveVoyage {
  className?: string;
}

export const VoyageProgressBar: FunctionComponent<IVoyageProgressBar> = (
  props,
) => {
  const { voyage, className } = props;
  const classes = useStyles();
  const isComplete = voyage.percentComplete >= 100;
  const realmColor = getRealmColor(voyage.realm);

  return (
    <div className={clsx(classes.root, className)}>
      <SvgVoyageProgressBar
        percent={voyage.percentComplete}
        svg={{ fill: realmColor.light }}
      />
      <div className={classes.pin}>
        {isComplete ? (
          <SvgVoyageProgressComplete fill={realmColor.dark} />
        ) : (
          <SvgVoyageProgressPin fill={realmColor.dark} />
        )}
      </div>
      <Typography
        variant={'h6'}
        className={clsx(classes.label, classes.label1)}>
        {voyage.daysComplete}
      </Typography>
      <Typography
        variant={'h6'}
        className={clsx(classes.label, classes.label2)}>
        of
      </Typography>
      <Typography
        variant={'h6'}
        className={clsx(classes.label, classes.label3)}>
        {voyage.daysDuration}
      </Typography>
    </div>
  );
};

export default VoyageProgressBar;
