import React, { FunctionComponent } from 'react';
import {
  Theme,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
  Button,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgIconEdit from '../../../assets/SvgIconEdit';
import SvgIconTrash from '../../../assets/SvgIconTrash';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useFurComponent } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  createLink: {
    textDecoration: 'none !important',
    width: '100%',
  },

  selectDropdown: {
    border: `2px solid ${theme.palette.common.black} !important`,

    '& ul': {
      padding: 0,
    },
  },

  createButton: {
    borderRadius: '0 !important',
    border: 'none !important',
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },

  select: {
    minWidth: '20rem',
    flexGrow: 1,

    '& > div': {
      border: `2px solid ${theme.palette.common.black} !important`,
      backgroundColor: '#784DFF !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem 0.9rem',

      '& [data-icons]': {
        display: 'none !important',
      },
    },

    '& p': {
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.common.white,
    },
  },

  selectMenuItem: {
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
  },

  selectIcons: {
    display: 'flex !important',
    alignItems: 'center',
    gap: '0px',
  },

  selectIcon: {
    display: 'grid',
    placeItems: 'center',
    padding: '0.6rem',
    borderRadius: '100%',
    aspectRatio: '1/1',
    transition: 'all 0.2s linear',
    color: theme.palette.common.black,

    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
  },
}));

export interface IGamePartyName {
  id: string;
  name: string;
}

interface ISelectPartyDropdown {
  parties: IGamePartyName[];
  partyId: string;
  setPartyId: (p: string) => void;
  disabled?: boolean;
  isDeleting?: boolean;
  currentlyDeleting?: string;
  onMenuOptionAction: (
    action: 'delete' | 'edit',
    id: string,
    name: string,
  ) => void;
  className?: string;
}

export const SelectPartyDropdown: FunctionComponent<ISelectPartyDropdown> = (
  props,
) => {
  const {
    partyId,
    setPartyId,
    disabled,
    parties,
    isDeleting,
    onMenuOptionAction,
    currentlyDeleting,
    className,
  } = props;
  const classes = useStyles();
  const { log } = useFurComponent(SelectPartyDropdown);
  const realPartyId = !partyId || partyId === 'new' ? '' : partyId;

  function onChangeParty(id: string) {
    log.info('[BOSS] change party', id);
    setPartyId(id);
  }

  return (
    <Select
      labelId='party'
      id='party'
      color='secondary'
      className={clsx(classes.select, className)}
      MenuProps={{
        classes: {
          paper: classes.selectDropdown,
        },
      }}
      value={realPartyId}
      defaultValue={realPartyId}
      disabled={disabled}
      onChange={(e) => onChangeParty(e.target.value as string)}>
      {parties.map((party) => (
        <MenuItem
          key={party.id}
          value={party.id}
          disabled={isDeleting}
          className={classes.selectMenuItem}>
          <Typography>{party.name}</Typography>
          <div data-icons className={classes.selectIcons}>
            <span
              className={classes.selectIcon}
              onClick={(e) => {
                e.stopPropagation();
                onMenuOptionAction('edit', party.id, party.name);
              }}>
              <SvgIconEdit width='15' height='15' />
            </span>

            {parties.length > 1 && (
              <span
                className={classes.selectIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  onMenuOptionAction('delete', party.id, party.name);
                }}>
                {isDeleting && currentlyDeleting === party.id ? (
                  <CircularProgress size='15px' color='inherit' />
                ) : (
                  <SvgIconTrash width='15' height='15' fill='currentColor' />
                )}
              </span>
            )}
          </div>
        </MenuItem>
      ))}
      <Grid container alignItems='center' justifyContent='center' gap='0.3rem'>
        <Link to='/boss/party/new' className={classes.createLink}>
          <Button
            fullWidth
            variant='contained'
            className={classes.createButton}>
            <Typography fontWeight='600' fontSize='0.9rem !important'>
              + Create Party
            </Typography>
          </Button>
        </Link>
      </Grid>
    </Select>
  );
};

export default SelectPartyDropdown;
