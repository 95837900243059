import * as React from 'react';
import { useAuthContext } from '../Auth/AuthContext';
import {
  DuelBattleFragment,
  NotificationFragment,
  useReadNotificationMutation,
  useUnreadNotificationsLazyQuery,
} from '../schema';
import NotificationsSubscription from './NotificationsSubscription';
import { useWalletSelector } from '../../hooks';
import { useFurComponent } from '../../utils';
import { useTryCatchParamAsync } from '../../utils/Errors';
import { useFurverseContext } from '../../pages/Furverse/Furverse';
import { DilemmaResultType } from './NotificationTypes';
import BossRewardsReveal, {
  IBossRewardsReveal,
} from '../BossFights/BossRewards/BossRewardsReveal';
import DilemmaResult from './DilemmaResult';

interface INotificationsContext {
  children: React.ReactNode;
}

const createNotificationsContext = () => {
  const { log } = useFurComponent(NotificationsProvider);
  const { isSignedIn } = useAuthContext();
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const { sendGameMessage } = useFurverseContext();

  const [unreadNotifications, setUnreadNotifications] = React.useState<
    NotificationFragment[]
  >([]);
  const [markAsRead, { loading: isMutating }] = useReadNotificationMutation({
    refetchQueries: ['UnreadNotifications'],
  });

  const [beganLoad, setBeganLoad] = React.useState(false);
  const [loadUnreadNotifications, loaded] = useUnreadNotificationsLazyQuery();
  const cursor = loaded.data?.unreadNotifications?.pageInfo.endCursor ?? null;
  const hasMore = loaded.data?.unreadNotifications?.pageInfo.hasNextPage;
  const isLoading = isMutating || loaded.loading;
  const loadedNotifications = loaded.data?.unreadNotifications?.nodes ?? [];

  const [pendingDuel, setPendingDuel] = React.useState<
    DuelBattleFragment | undefined
  >(undefined);

  const [dilemmaData, setDilemmaData] = React.useState<DilemmaResultType>();
  const [bossRewardRevealData, setBossRewardRevealData] =
    React.useState<IBossRewardsReveal>();
  const unreadNotificationCount = unreadNotifications;

  React.useEffect(() => {
    if (!isSignedIn) return;
    // should only called when signed in, but playerId acts as trigger
    loadUnreadNotifications();
    setBeganLoad(true);
  }, [isSignedIn, playerId]);

  React.useEffect(() => {
    if (!loadedNotifications.length) return;
    // log.info('loaded', unreadNotifications);
    addNotifications(...loadedNotifications);
  }, [loadedNotifications]);

  React.useEffect(() => {
    if (!isSignedIn || !beganLoad || isLoading) return;
    sendGameMessage({
      topic: 'SetUnreadNotifications',
      data: { notifications: unreadNotifications },
    });
  }, [isSignedIn, isLoading, beganLoad, unreadNotificationCount]);

  const markNotificationsAsRead = useTryCatchParamAsync(
    'ReadNotifications',
    async (ids: string[]) => {
      log.info('[MARK]', ids);
      setUnreadNotifications(
        unreadNotifications.filter((n) => !ids.includes(n.id)),
      );
      await markAsRead({ variables: { notificationIds: ids } });
    },
    [unreadNotifications],
  );

  function addNotifications(...arr: NotificationFragment[]) {
    const nots: NotificationFragment[] = [];
    for (let i = 0; i < unreadNotifications.length; i++) {
      const newer = arr.findIndex((n) => n.id === unreadNotifications[i].id);
      nots.push(newer >= 0 ? arr[newer] : unreadNotifications[i]);
      if (newer >= 0) arr.splice(newer, 1);
    }
    nots.push(...arr);
    nots.sort(function (x, y) {
      return new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime();
    });
    setUnreadNotifications(nots);
  }

  return {
    unreadNotifications,
    loadUnreadNotifications,
    markNotificationsAsRead,
    addNotifications,
    isMutating,
    isLoading,
    hasMore,
    cursor,
    dilemmaData,
    setDilemmaData,
    bossRewardRevealData,
    setBossRewardRevealData,
    pendingDuel,
    setPendingDuel,
  };
};

const NotificationsContext = React.createContext(
  {} as ReturnType<typeof createNotificationsContext>,
);

export const NotificationsProvider: React.FunctionComponent<
  INotificationsContext
> = (props) => {
  const { log } = useFurComponent(NotificationsProvider);
  const { children } = props;
  const { isSignedIn } = useAuthContext();
  const { sendGameMessage } = useFurverseContext();
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const nc = createNotificationsContext();
  const {
    addNotifications,
    dilemmaData,
    setDilemmaData,
    markNotificationsAsRead,
    bossRewardRevealData,
  } = nc;

  const onNotify = (newNotification: NotificationFragment) => {
    log.info('[NOTIFY]', newNotification);
    addNotifications(newNotification);

    sendGameMessage({
      topic: 'Notify',
      data: { notifications: [newNotification] },
    });
  };

  return (
    <NotificationsContext.Provider value={nc}>
      {isSignedIn && (
        <NotificationsSubscription playerId={playerId} onNotify={onNotify} />
      )}
      {children}
      {!!dilemmaData && (
        <DilemmaResult
          {...dilemmaData}
          onClose={(id) => {
            markNotificationsAsRead([id]);
            setDilemmaData(undefined);
          }}
        />
      )}

      {!!bossRewardRevealData && (
        <BossRewardsReveal {...bossRewardRevealData} />
      )}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = () => React.useContext(NotificationsContext);
