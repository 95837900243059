import React from 'react';
import { useWalletSelector } from '../hooks';

export interface IAnimateFrame {
  className: string;
  duration: number;
  callback?: () => void;
}

export type AnimatableFrame =
  | IAnimateFrame
  | string
  | number
  | (() => void)
  | undefined;

export function useAnimationFrames(
  frms: AnimatableFrame[],
  enabled?: boolean,
  duration = 100,
  minDuration = 0,
): { [key: string]: boolean } {
  const [frame, setFrame] = React.useState(0);
  const animationsDisabled = useAnimationsDisabled();
  // const isActive = !!enabled;

  const frames = frms.map((f) => {
    if (f === undefined) return { className: '', duration };
    if (typeof f === 'string') return { className: f, duration };
    if (typeof f === 'number') return { className: '', duration: f as number };
    if (typeof f === 'function')
      return { className: '', duration, callback: f };
    const af = f as IAnimateFrame;
    if (af.duration <= 0) af.duration = duration;
    return af;
  });
  const frameIndex = frame % frames.length;

  const classes: { [key: string]: boolean } = {};
  frames.forEach((f, i) => {
    const cn = f.className;
    if (cn.length > 0) classes[cn] = !!enabled && i === frameIndex;
    else if (enabled && i === frameIndex)
      classes[frames[i - 1].className] = true;
  });

  function checkEnabled(): boolean {
    if (enabled) return true;

    setFrame(0);
    return false;
  }

  function animateLoading(f: number) {
    if (!checkEnabled()) return;
    setFrame(f + 1);
  }

  React.useEffect(() => {
    if (animationsDisabled) return;
    if (!checkEnabled()) return;
    const cb = frames[frameIndex].callback;
    if (cb) cb();
    const interval = setTimeout(
      () => animateLoading(frame),
      frames[frameIndex].duration,
    );
    return () => {
      clearTimeout(interval);
    };
  }, [enabled, frame, animationsDisabled]);

  return classes;
}

export function useAnimationsDisabled(): boolean {
  const numFurballs = useWalletSelector((s) => s.contents?.furballBalance ?? 0);
  return numFurballs >= 200;
}

export function useFrameRate(active: boolean, time = 30): number {
  const [frameNum, setFrameNum] = React.useState(0);
  const animationsDisabled = useAnimationsDisabled();

  React.useEffect(() => {
    // Turn off animations for whale...
    if (!active || animationsDisabled) return;

    const timeout = setTimeout(() => {
      setFrameNum(frameNum + 1);
    }, time);
    return () => {
      clearTimeout(timeout);
    };
  }, [frameNum, active]);

  return frameNum;
}
