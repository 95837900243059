import { ItemDefinitionFragment, RewardSource } from '../../schema';
import { itemLibrary } from '../../Inventory/ItemLibrary';
import { cdnRoot } from '../../../theme';

export const isSeasonalReward = (source: RewardSource) => {
  return (
    source === RewardSource.SeasonalBossLeaderboardSebastian ||
    source === RewardSource.SeasonalBossLeaderboardSkullumbo ||
    source === RewardSource.SeasonalBossLeaderboardTrashy
  );
};

export const isLeaderboardReward = (source: RewardSource) => {
  return (
    isSeasonalReward(source) ||
    source === RewardSource.WeeklyBossLeaderboardSebastian ||
    source === RewardSource.WeeklyBossLeaderboardSkullumbo ||
    source === RewardSource.WeeklyBossLeaderboardTrashy
  );
};

export const hasPendingRewardExpired = (
  rewardDateExpires: string | undefined,
): boolean => {
  if (!rewardDateExpires) return false;
  const now = new Date().getTime();
  const expiresAt = new Date(rewardDateExpires).getTime();
  return now > expiresAt;
};

export const getBossIconSrc = (seasonal: boolean) => {
  return `${cdnRoot}/${
    seasonal
      ? 'svgs/seasonal_loot_bag.svg'
      : 'images/icons/boss_loot/boss_loot_generic.png'
  }`;
};

export const getItemDefs = (items: any[]) => {
  const itemDefs: { [p: string]: ItemDefinitionFragment } = {};

  items.forEach((item) => {
    const itemDef = getItemDef(item.itemId);
    if (itemDefs[item.itemId] === undefined && itemDef) {
      itemDefs[item.itemId] = itemDef;
    }
  });

  return itemDefs;
};

export const getItemDef = (
  itemId: number,
): ItemDefinitionFragment | undefined => {
  return itemLibrary.find((item) => item.id === itemId);
};
