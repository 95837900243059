import * as React from 'react';

function SvgVoyageWidgetBkBoneyard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 500 500' {...props}>
      <path fill='#755474' d='M22.81 136.49H1.09v83.36h21.77z' />
      <linearGradient
        id='voyage-widget-bk-boneyard_svg__a'
        gradientUnits='userSpaceOnUse'
        x1={250.076}
        y1={342.035}
        x2={250.076}
        y2={502}
        gradientTransform='matrix(1 0 0 -1 0 502)'>
        <stop offset={0} stopColor='#912bc8' />
        <stop offset={0.886} stopColor='#ffa861' />
      </linearGradient>
      <path
        fill='url(#voyage-widget-bk-boneyard_svg__a)'
        d='M.17 0h499.81v159.96H.17z'
      />
      <path
        fill='#565D81'
        d='M56.8 118.54c-9.98 0-19.22 1.2-26.69 3.24-8.24 2.24-19.96-3.24-29.95-3.24v33.5h80.69c24.94 0 21.09-7.49 21.09-16.75s-20.19-16.75-45.14-16.75z'
      />
      <path
        opacity={0.3}
        fill='#FFAC40'
        d='M.17 164.63c8.5 2.31 17.44 3.55 26.67 3.55 56.12 0 101.62-45.51 101.62-101.65 0-25.44-9.34-48.69-24.78-66.52H.17v164.62z'
      />
      <path fill='#755474' d='M0 138.65h27.59v70.31H0v-70.31z' />
      <path
        opacity={0.3}
        fill='#FFAC40'
        d='M148.46 66.52c0 56.14 45.49 101.65 101.62 101.65 56.12 0 101.62-45.51 101.62-101.65 0-25.44-9.34-48.69-24.78-66.52H173.24c-15.44 17.83-24.78 41.08-24.78 66.52zM371.69 66.52c0 56.14 45.49 101.65 101.62 101.65 9.23 0 18.17-1.24 26.67-3.55V0H396.47c-15.44 17.83-24.78 41.08-24.78 66.52z'
      />
      <g fill='#DEEEE8'>
        <path d='M30.39 100.23L12.52 80.22c.09-.08.18-.15.27-.24 2.75-2.68 2.76-7.09.03-9.8-2.66-2.62-7.11-2.58-9.73.08a6.84 6.84 0 00-1.96 4.57c-.03.66-.4 1.21-.95 1.48v13.87c1.5-.14 2.97-.78 4.14-1.91.08-.1.15-.19.23-.27l18.99 21.27 3.28 1.19M24.97 116.92L.17 113.1v8.03l29.19 4.49c-.01.09-.03.16-.04.25M16.55 106.72L.17 100.77v5.83l16.75 6.08' />
      </g>
      <path
        fill='none'
        d='M8.85 171.07c-.03.91-.18 1.79-.39 2.24.18-.03.33.41.39 1.12.05-.82.19-1.58.38-1.99-.21.01-.35-.52-.38-1.37zM1.37 156.82c.02-.01.04-.03.05-.04l5.79-3.37s.01 0 .01-.01l1.16-.68c.29-.16.52.66.52 1.85v.05c.05-.51.18-1.02.38-1.49.1-.38.21-.69.34-.91l-.21.13c-.27.15-.48-.56-.51-1.62-.07.87-.27 1.57-.49 1.7l-1.18.68-.01.01-1.42.81-5.54 3.18c-.03.02-.06.01-.09 0v.46c.03-.03.05-.07.08-.08l1.12-.67z'
      />
      <path
        fill='#825E81'
        d='M17.23 133.56l-7.35.52c-.44.03-.81 1.31-.92 2.99l-.01 4.26c.01-.28.02-.55.02-.84l-.01 1.89c.14 2.69.74 4.49 1.46 4.17l6.19-2.7c.83-.35 1.53-2.71 1.55-5.27l-.05 6.42c0-.03.01-.06.01-.09l.09-11.37c0-.12-.01-.23-.03-.34l-.03 3.15c.02-1.58-.4-2.83-.92-2.79z'
      />
      <path
        fill='#755474'
        d='M16.4 147.84l-5.79 3.32c-.83.47-1.51-.02-1.67-1.11 0 .23-.01.46-.03.68.03 1.06.24 1.77.51 1.62l.21-.13c.1-.18.2-.32.3-.38l.53-.31.13-.1 5.79-3.37c.05-.03.1-.05.15-.07l.51-.3a.36.36 0 01.3-.03l.21-.12c.29-.16.53-1.03.54-1.91V145c-.03 1.06-.78 2.31-1.69 2.84z'
      />
      <path
        fill='#755474'
        d='M16.61 143.85l-6.19 2.7c-.72.32-1.32-1.48-1.46-4.17l-.02 7.59v.08c.16 1.09.84 1.58 1.67 1.11l5.79-3.32c.91-.53 1.66-1.78 1.71-2.83l.05-6.42c-.02 2.55-.72 4.9-1.55 5.26zM8.53 131.62c.25 0 .45.82.45 1.85v.38c.12-1.27.83-2.24 1.69-2.24H7.26c-.01 0 0 .01 0 .01h1.27zM18.18 133.2c.03-.87-.21-1.6-.5-1.6H16.5c.85.01 1.55.69 1.68 1.6z'
      />
      <path
        fill='#755474'
        d='M8.98 133.85l-.01 2.64c0-.01 0-.01-.01-.02v.58c.11-1.68.48-2.95.92-2.99l7.35-.52c.52-.04.94 1.22.93 2.8l.03-3.15c-.13-.91-.83-1.6-1.68-1.6h-5.83c-.88.02-1.58.99-1.7 2.26zM18.05 151.84c-.01 1.76-1.3-2.76-2.51-1.01-1.24 1.86-2.3 2.66-3.04 2.99-.89.37-1.77 1.62-2.59 3.7-.49 1.28-.9 2.26-1 1.72l-.01 3.16c.11 2.77.72 4.36 1.46 3.62l6.15-6.2c.83-.84 1.52-3.58 1.53-6.11l-.02 2.54.06-7.21c.01-.48-.15-.81-.39-1.01.24.44.39 1.24.38 2.26l-.02 1.55zM8.91 155.03v.67c.06-.98.2-1.87.38-2.56-.2.47-.33.98-.38 1.49v.4zM16.39 148.06l-5.79 3.37c-.05.03-.09.07-.13.1l6.08-3.54c-.06.03-.11.04-.16.07z'
      />
      <path
        fill='#825E81'
        d='M17.68 148.01c-.1-.18-.21-.3-.33-.34l-.28.16c.24-.02.45.05.61.18zM9.63 152.22c-.12.23-.24.54-.34.91.17-.4.37-.77.62-1.08l-.28.17z'
      />
      <path
        fill='#825E81'
        d='M10.46 151.53l-.53.31c-.11.06-.21.2-.3.38l.28-.16c.17-.22.35-.39.55-.53zM17.05 147.7l-.51.3c.19-.09.37-.15.54-.16l.28-.16c-.11-.04-.21-.05-.31.02z'
      />
      <path
        fill='#825E81'
        d='M9.91 152.05c-.24.3-.45.67-.62 1.08-.18.69-.32 1.58-.38 2.56l-.01 3.54c.11.53.51-.44 1-1.72.82-2.08 1.71-3.33 2.59-3.7.74-.33 1.8-1.13 3.04-2.99 1.22-1.75 2.5 2.77 2.51 1.01l.01-1.57c.01-1.02-.14-1.82-.38-2.26a.903.903 0 00-.61-.18l-7.15 4.23z'
      />
      <path
        fill='#825E81'
        d='M9.91 152.05l7.17-4.22c-.17.01-.35.07-.54.16l-6.08 3.54c-.2.14-.38.31-.55.52zM16.81 163.58l.65-.75c.05-.06.09-.08.13-.1.23-.44.39-1.26.39-1.99l.03-4.51-.03 4.16c.01.85-.49 2.15-1.17 3.19zM8.85 171.07c.03.85.17 1.39.38 1.37.04-.09.09-.18.13-.23l.65-.76c-.64.43-1.1.1-1.15-.88v.21c0 .09-.01.19-.01.29z'
      />
      <path
        fill='#825E81'
        d='M16.5 159.8l-6.15 6.2c-.74.74-1.35-.85-1.46-3.62l-.02 7.92c0 .06-.01.12-.01.18v.08c.06.98.52 1.31 1.15.88l.48-.55c.01-.02.02-.03.04-.05l5.75-6.67c.03-.03.06-.06.08-.09l.45-.52c.68-1.04 1.18-2.33 1.18-3.19l.03-4.16.02-2.54c-.01 2.55-.7 5.29-1.54 6.13zM7.22 153.1h.01l-1.43.81zM1.24 150.55l6.19-3.23c.72-.31 1.33-2.64 1.49-5.48V141v.25l.01-3.86c-.01.2-.02.4-.02.61l.01-1.61c-1.46-4.63-3.01-2-3.5-.62-.26.78-1.79 2.75-2.75.05-.9-2.62-1.65-2.01-1.65-2.01-.26.01-.6.41-.86 1.03v13.55c.28 1.46.66 2.34 1.08 2.16zM8.95 141.32l.01-4.26c-.01.11-.01.22-.02.33l-.01 3.86v.43c.01-.12.01-.24.02-.36zM8.93 141.25V141 141.8v-.55zM8.93 141.67v-.43V141.67z'
      />
      <path
        fill='#825E81'
        d='M8.93 136.39L8.92 138c0-.21.01-.41.02-.61v-.96c0-.01-.01-.02-.01-.04zM8.96 136.48c-.01-.02-.01-.03-.02-.04v.96c0-.11.01-.22.02-.33v-.59z'
      />
      <path
        fill='#755474'
        d='M8.9 149.9c-.12 1.33-.82 2.7-1.67 3.2l1.18-.68c.23-.13.42-.83.49-1.7 0-.08-.01-.15-.01-.23l.01-.59zM5.8 153.91l-4.38 2.51c-.5.29-.94.12-1.25-.36v1.03c.03.01.06.01.09 0l5.54-3.18zM8.96 142.37l.01-1.89c0 .28-.01.56-.02.84v.72c0 .11 0 .22.01.33zM8.91 149.72c0 .06-.01.12-.01.18v.6c0 .08 0 .15.01.23.02-.22.03-.45.03-.68-.02-.11-.02-.22-.03-.33z'
      />
      <path
        fill='#755474'
        d='M8.92 149.51c0 .07-.01.14-.01.21.01.11.01.23.02.33v-.08l.02-7.59c-.01-.11-.01-.22-.02-.33l-.01 7.46zM1.42 156.42l4.38-2.51 1.42-.82c.85-.5 1.55-1.87 1.67-3.2v-.34-.04l.01-4.06.01-3.62c-.16 2.84-.77 5.17-1.49 5.48l-6.19 3.23c-.42.18-.8-.69-1.07-2.16v7.68c.32.48.77.65 1.26.36zM8.94 142.04v-.72c0 .12-.01.24-.01.36s.01.24.01.36zM8.9 149.52v.04l.01-4.1-.01 4.06z'
      />
      <path
        fill='#755474'
        d='M8.9 149.56v.34c.01-.06.01-.12.01-.18s-.01-.11-.01-.16zM8.92 141.84l-.01 3.62.01-3.66v.04z'
      />
      <path
        fill='#755474'
        d='M8.92 149.51l.02-7.47c0-.12-.01-.24-.01-.37 0 .04 0 .09-.01.13l-.01 3.66-.01 4.1c0 .06.01.1.01.16 0-.07.01-.14.01-.21zM8.93 133.73c0 .03 0 .06.01.09v-.06c0-.01-.01-.02-.01-.03zM1.44 131.62H.17v1.08c.31-.66.76-1.08 1.27-1.08z'
      />
      <path
        fill='#755474'
        d='M8.98 133.85v-.38c0-1.03-.2-1.85-.45-1.85H7.26c.84 0 1.52.91 1.67 2.11 0 .01.01.02.01.03v.06c.01.1.02.2.02.31 0-.1.01-.19.02-.28z'
      />
      <path
        fill='#755474'
        d='M8.96 134.23l-.01 2.25c0 .01 0 .01.01.02l.01-2.64c-.01.09-.02.18-.02.28.01.02.01.05.01.09zM1.03 133.81s.75-.61 1.65 2.01c.97 2.7 2.49.73 2.75-.05.49-1.38 2.04-4.01 3.5.62l.01-2.57c0-.03 0-.06-.01-.09-.03-1.13-1.8-2.04-1.68-2.11H1.44c-.51 0-.95.42-1.27 1.08v2.14c.26-.62.59-1.02.86-1.03z'
      />
      <path
        fill='#755474'
        d='M8.93 133.73c-.15-1.2-.83-2.11-1.67-2.11-.13.07 1.64.98 1.67 2.11zM8.95 134.23c0-.03.01-.07.01-.1 0-.1-.01-.21-.02-.31l-.01 2.57c0 .01.01.03.01.04l.01-2.2z'
      />
      <path
        fill='#755474'
        d='M8.96 134.13c0 .03-.01.07-.01.1l-.01 2.2c.01.02.01.03.02.04l.01-2.25c-.01-.03-.01-.06-.01-.09zM1.42 156.77c-.02.01-.04.03-.05.04l5.86-3.42s-.01 0-.01.01l-5.8 3.37zM1.23 175.21l6.15-6.2c.75-.76 1.38-3.68 1.49-6.78l.01-3.32v-1.01c-.1-1.61-.46-2.61-.9-2.32l-7.32 4.77c-.18.12-.34.5-.49 1.05v12.64c.27 1.1.64 1.58 1.06 1.17z'
      />
      <path
        fill='#755474'
        d='M8.87 161.32c0 .21 0 .42.01.62.01-.21.01-.43.01-.64v.81c0 .09.01.19.01.28l.01-3.16c-.02-.08-.02-.19-.03-.33l-.01 3.32v-.9zM8.91 155.01v.69-.67-.02zM8.87 157.89v1.01-.94c.01-.02 0-.04 0-.07z'
      />
      <path
        fill='#755474'
        d='M8.91 155.69v.02l-.01 3.1c0-.3-.01-.58-.02-.84v.94c0 .14.01.25.03.33v-3.55zM8.87 162.14c0-.07.01-.14.01-.21 0-.2-.01-.41-.01-.62v.83zM8.86 170.49c0-.06.01-.12.01-.18l.02-7.92c0-.09-.01-.19-.01-.28l-.02 8.38z'
      />
      <path
        fill='#825E81'
        d='M.89 182.02c-.28.15-.52.13-.72-.06v.93c.03-.05.05-.1.08-.13l.64-.74zM8.46 173.31c.21-.45.36-1.33.39-2.24 0-.08-.01-.16-.01-.25v-.12c-.1 1.1-.55 2.44-1.15 3.45l.65-.75c.04-.07.08-.09.12-.09z'
      />
      <path
        fill='#825E81'
        d='M8.85 171.07c0-.1.01-.19.01-.29v-.21-.04c0 .05-.01.11-.01.16v.38zM8.86 170.57v-.08.08z'
      />
      <path
        fill='#825E81'
        d='M.89 182.02l6.81-7.89c.61-1.01 1.06-2.34 1.15-3.45v-.33-.02l.02-8.12c-.11 3.09-.74 6.02-1.49 6.78l-6.15 6.2c-.41.41-.78-.07-1.06-1.17v7.93c.19.2.44.22.72.07z'
      />
      <path
        fill='#825E81'
        d='M8.87 162.23l-.02 8.12v.02l.02-8.22v.08zM8.88 161.93c0 .06 0 .11.01.17v-.81c-.01.22-.01.43-.01.64z'
      />
      <path
        fill='#825E81'
        d='M8.85 170.36v.33c0-.05.01-.11.01-.16-.01-.06-.01-.1-.01-.17z'
      />
      <path
        fill='#825E81'
        d='M8.86 170.49l.02-8.38c0-.06 0-.11-.01-.17 0 .07-.01.14-.01.21l-.02 8.22c0 .06.01.11.01.16.01-.01.01-.03.01-.04zM1.37 156.82l-1.12.65c-.03.01-.05.05-.08.08v1.03c.3-.81.73-1.46 1.2-1.76zM8.91 154.57c0-1.19-.22-2.01-.52-1.85l-1.16.68c.89-.5 1.6.13 1.67 1.43 0-.07.01-.14.01-.21v-.05z'
      />
      <path
        fill='#825E81'
        d='M8.91 154.63c-.01.07-.01.14-.01.21 0 .06.01.11.01.18v-.39zM7.97 155.57c.44-.29.8.72.9 2.32v-1.17c0-.11.01-.22.01-.33v-1.36c0-.07.01-.14.01-.21-.07-1.3-.78-1.93-1.67-1.43l-5.86 3.42c-.48.3-.9.96-1.2 1.77v2.81c.14-.55.31-.93.49-1.05l7.32-4.77z'
      />
      <path
        fill='#825E81'
        d='M8.91 155.01c0-.06-.01-.12-.01-.18 0 .07-.01.14-.01.21v1.36c.01-.23.01-.46.03-.68l-.01-.71zM8.88 156.4c0 .11-.01.22-.01.33v1.17c0 .03 0 .05.01.07v-1.57z'
      />
      <path
        fill='#825E81'
        d='M8.9 158.81l.01-3.1-.03.68v1.57c.01.28.02.56.02.85z'
      />
      <path
        fill='#755474'
        d='M3.49 196.32l3.84-6.1c.72-1.16 1.33-4.27 1.48-7.34l.01-4.81c-.12-1.41-.46-2.13-.88-1.61l-7.26 8.95c-.18.23-.36.72-.5 1.38v5.13c.69 4.93 1.95 6.57 3.31 4.4zM1.4 206.22l5.71-9.99c.01-.02.02-.04.03-.05l-5.78 10.11c.01-.03.02-.04.04-.07zM1.36 206.29c-.47.8-.89 1.13-1.19 1.02v1.02c.02-.02.04-.04.06-.08l1.13-1.96zM8.78 191.07c-.12 1.45-.8 3.62-1.64 5.11l1.14-1.99c.25-.44.46-1.53.51-2.59-.01-.13-.01-.27-.01-.42v-.11z'
      />
      <path
        fill='#755474'
        d='M3.49 196.32c-1.36 2.17-2.62.53-3.32-4.41v15.4c.3.11.72-.23 1.19-1.02l5.78-10.11c.83-1.48 1.52-3.65 1.64-5.11v-.34-.02l.02-7.83M8.85 174.43c-.05-.71-.2-1.15-.39-1.12-.04.09-.09.17-.13.23l-.64.74c.62-.41 1.08-.07 1.14.92v-.23c0-.18.01-.36.02-.54zM.23 182.95c-.02.03-.04.03-.06.04v.93c.2-.64.45-1.24.74-1.75l-.68.78z'
      />
      <path
        fill='#755474'
        d='M.91 182.17c.04-.08.09-.16.13-.24-.05.04-.1.06-.15.09l-.64.74c-.03.03-.05.08-.08.13v.1a.21.21 0 00.06-.04l.68-.78zM8.46 173.31c-.04.01-.08.03-.12.07l-.65.75-.15.24c.05-.04.1-.07.15-.1l.64-.74c.05-.05.09-.13.13-.22z'
      />
      <path
        fill='#755474'
        d='M7.17 174.75c.13-.15.26-.27.38-.37l.15-.24-6.81 7.89c.05-.03.1-.05.15-.09.12-.19.24-.37.37-.51l5.76-6.68zM7.93 176.46c.42-.51.76.2.88 1.61l.01-2.64c0-.06.01-.12.01-.18v-.05c-.06-.99-.51-1.33-1.14-.92l-.53.61-5.75 6.7c0 .01-.01.01-.01.01l-.49.57c-.29.5-.54 1.11-.74 1.75v2.86c.15-.65.32-1.15.5-1.38l7.26-8.94z'
      />
      <path
        fill='#755474'
        d='M1.41 181.59l5.75-6.7-5.76 6.71c0-.01.01-.01.01-.01zM7.17 174.89l.53-.61c-.05.03-.1.06-.15.1-.13.18-.25.36-.38.51zM.91 182.17l.49-.57c-.12.14-.24.25-.36.34-.05.07-.09.15-.13.23z'
      />
      <path
        fill='#755474'
        d='M7.17 174.75l-5.75 6.67c-.13.15-.25.32-.37.51.12-.08.23-.19.36-.34l5.77-6.71c.13-.15.26-.33.38-.51-.14.11-.26.23-.39.38zM17.02 165.24l-7.26 8.95c-.42.52-.78 2.13-.9 3.87v1.11c0-.27-.01-.53-.02-.77v.03l-.01 3.45v-.23 1.07c.12 2.72.73 3.97 1.46 2.79l6.11-9.72c.82-1.3 1.51-4.43 1.52-6.96l-.02 2.51.06-7.19v-.03l-.02 2.84c.01-1.58-.4-2.35-.92-1.72zM16.28 164.18l-5.75 6.67c-.01.01-.02.03-.04.05l5.88-6.81c-.04.03-.06.06-.09.09z'
      />
      <path
        fill='#755474'
        d='M8.81 181.69c0 .25 0 .49.01.73.01-.18.01-.36.01-.54l.01-3.45c-.01.26-.02.52-.02.77v-1.01c0-.04-.01-.08-.01-.12l-.01 4.81v-.08l.01-1.11zM8.83 182.72v-1.07 1.07zM8.8 182.88l-.02 7.83v.02l.02-7.93v.08z'
      />
      <path
        fill='#755474'
        d='M8.83 182.65v-.77c0 .18-.01.36-.01.54 0 .07 0 .15.01.23zM8.86 179.17v-1.11c-.01.11-.02.22-.02.34.01.24.02.5.02.77zM8.83 175.25c0 .06-.01.12-.01.18l-.01 2.64c0 .04.01.08.01.12l.01-2.94zM16.17 180.41l-5.71 9.99c-.84 1.46-1.53 1.72-1.65.68v.05c0 .15-.01.31-.02.47.05.89.25 1.26.51.83l8.04-14.05c.29-.51.53-1.62.53-2.51l.03-4.54-.03 4.18c0 1.09-.77 3.28-1.7 4.9z'
      />
      <path
        fill='#755474'
        d='M8.81 191.08c-.01-.06-.01-.13-.01-.19 0 .06-.01.12-.01.19v.11c0 .15 0 .29.01.42.01-.16.01-.32.02-.47l-.01-.06zM8.78 190.73v.34c.01-.06.01-.13.01-.19 0-.05-.01-.09-.01-.15z'
      />
      <path
        fill='#755474'
        d='M16.4 175.79l-6.11 9.72c-.73 1.18-1.34-.08-1.46-2.79l-.02 8.36c.13 1.03.82.78 1.65-.68l5.71-9.99c.93-1.62 1.69-3.81 1.69-4.89l.03-4.18.02-2.51c0 2.53-.69 5.65-1.51 6.96zM8.8 182.8c.01-.13.01-.26.01-.39-.01-.23-.01-.47-.01-.73v1.12z'
      />
      <path
        fill='#755474'
        d='M8.81 190.68c0 .06-.01.14-.01.21 0 .07.01.13.01.19l.02-8.36v-.07l-.02 8.03z'
      />
      <path
        fill='#755474'
        d='M8.81 190.68l.02-8.03c0-.08-.01-.16-.01-.24 0 .13-.01.26-.01.39l-.02 7.93c0 .06.01.1.01.16 0-.07.01-.15.01-.21zM16.87 163.62c.63-.47 1.08-.3 1.09.5v-.31c.01-.73-.15-1.16-.37-1.09-.04.08-.09.16-.13.21l-.59.69zM9.23 172.43c-.19.41-.33 1.18-.38 1.99.01.12.01.24.02.38.13-1.04.55-2.23 1.1-3.15l-.61.71c-.05.05-.09.07-.13.07z'
      />
      <path
        fill='#755474'
        d='M9.23 172.43c.04 0 .09-.02.13-.08l.61-.71c.06-.1.12-.2.18-.29-.05.04-.09.06-.13.09l-.65.76c-.06.06-.1.14-.14.23zM16.81 163.58c-.04.07-.09.14-.14.2.07-.06.13-.11.2-.16l.59-.68c.05-.05.09-.13.13-.21a.23.23 0 00-.13.1l-.65.75z'
      />
      <path
        fill='#755474'
        d='M10.49 170.9l-.48.55c.04-.03.09-.05.13-.09.12-.17.23-.32.35-.46zM16.67 163.78c.05-.07.09-.14.14-.2l-.45.52c.11-.13.21-.23.31-.32zM8.87 174.8c0-.13-.01-.26-.02-.38-.01.18-.02.36-.02.54v.26c.01-.13.02-.27.04-.42zM8.83 175.2V175.2zM16.87 163.62l-6.9 8.03c-.55.93-.97 2.12-1.1 3.15v.12l-.01 3.13c.12-1.74.47-3.35.9-3.87l7.26-8.95c.52-.63.93.14.91 1.72l.02-2.84c0-.79-.45-.96-1.08-.49zM8.84 178.43v-.03-.01.04z'
      />
      <path
        fill='#755474'
        d='M10.53 170.99c-.13.15-.26.27-.38.37-.06.09-.12.19-.18.29l6.9-8.03c-.06.05-.13.1-.2.16-.13.18-.26.36-.39.51l-5.75 6.7z'
      />
      <path
        fill='#755474'
        d='M10.53 170.99l5.75-6.7c.14-.16.27-.33.39-.51-.1.09-.2.19-.31.31l-5.88 6.81c-.12.14-.23.29-.34.46.13-.1.25-.22.39-.37zM8.82 178.19v1.01c0-.26.01-.52.02-.77v-.05c-.01-.06-.01-.12-.02-.19zM8.85 175.4l-.01 2.99v.01c.01-.11.02-.22.02-.34l.01-3.13v-.12c-.02.14-.03.28-.03.42 0 .05.01.1.01.17z'
      />
      <path
        fill='#755474'
        d='M8.84 178.39l.01-2.99c0-.06-.01-.11-.01-.17v.03l-.01 2.94c0 .06 0 .12.01.19z'
      />
      <g>
        <path
          fill='#825E81'
          d='M24.06 143.09l3.4-1.95c.56-.32 1.01-1.09 1.01-1.71l.05-6.67c0-.63-.44-1.14-.99-1.14h-3.41c-.56 0-1.01.68-1.01 1.53l-.03 8.98c-.01.85.44 1.28.98.96z'
        />
        <path
          fill='#755474'
          d='M27.59 138.8l-3.64 1.58c-.49.22-.89-1.24-.87-3.25l-.01 5.57c0 .7.13 1.19.3 1.09l4.79-2.81c.17-.1.32-.61.32-1.13l.03-4.14c-.02 1.49-.43 2.87-.92 3.09zM28.23 131.61h-7.35c-.17 0 2.24.57 2.24 1.27l-.01 2.49c0-1.25.25-2.28.56-2.3l4.33-.3c.3-.03.56.71.54 1.65l.01-1.85c-.02-.53-.15-.96-.32-.96z'
        />
        <path
          fill='#755474'
          d='M24.02 154.74l3.37-3.92c.56-.63.99-1.67 1.01-2.29l.05-6.67c0-.63-.44-.87-.99-.56l-3.4 1.97c-.56.32-1.01 1.27-1.01 2.11l-.03 8.98c.01.84.46 1.01 1 .38z'
        />
        <path
          fill='#825E81'
          d='M26.97 142.89c.71-1.03 1.47 1.62 1.48.59l.01-.92c.01-1.03-.26-1.71-.6-1.51l-4.18 2.43c-.34.2-.61 1.48-.62 2.86v1.24c0 .73.28.09.61-.78.48-1.22 1.01-1.95 1.52-2.16.43-.18 1.04-.65 1.78-1.75zM27.53 148.15l-3.61 3.63c-.49.49-.87-.73-.87-2.75l-.01 5.58c0 .7.13 1.1.3.91l4.75-5.53c.17-.2.3-.77.32-1.29l.03-4.14c-.01 1.5-.42 3.11-.91 3.59zM18.68 146.18l3.4-1.95c.56-.32 1.01-1.27 1.01-2.1l.03-8.98c0-.85-.45-1.53-1.01-1.53H18.7c-.56 0-1.01.86-1.01 1.92v11.28c0 1.07.43 1.67.99 1.36z'
        />
        <path
          fill='#755474'
          d='M22.21 140.84l-3.64 1.9c-.49.22-.89-2.04-.89-4.57v7c0 .87.13 1.51.3 1.42l4.79-2.75c.17-.1.3-.75.32-1.44l.01-5.57c0 1.99-.4 3.79-.89 4.01zM23.11 134.48c-.86-2.81-1.79-1.24-2.08-.43-.16.46-1.04 1.61-1.61.03-.53-1.54-.98-1.18-.98-1.18-.3.01-.77.87-.77 1.96v-2.15c0-.61-1.76-1.09-1.59-1.09h6.76c.15 0 .26.48.26 1.09l.01 1.77M18.68 160.95l3.37-3.92c.56-.63.99-1.85.99-2.68l.03-8.98c0-.85-.45-1.28-.99-.95l-3.4 1.97c-.56.32-1.01 1.44-1.01 2.51v11.28c.02 1.06.45 1.42 1.01.77z'
        />
        <path
          fill='#825E81'
          d='M22.17 153.56l-3.61 3.63c-.49.49-.89-1.15-.89-3.68v7c0 .87.13 1.43.3 1.23l4.75-5.53c.17-.2.3-.92.3-1.62l.01-5.57c.03 2.02-.37 4.04-.86 4.54zM22.78 144.01l-4.79 2.79c-.17.1-.3.89-.3 1.77v3.13c0-1.57.25-3.01.56-3.2l4.3-2.81c.3-.2.54.66.54 1.9l.01-2.49c-.02-.71-.16-1.19-.32-1.09z'
        />
        <path
          fill='#755474'
          d='M18.67 175.41l3.36-5.86c.54-.95.99-2.42.99-3.25l.03-8.98c0-.85-.44-1.01-.99-.38l-3.37 3.91c-.56.63-.99 2.01-.99 3.08v11.28c-.01 1.06.42 1.16.97.2z'
        />
        <path
          fill='#755474'
          d='M17.69 175.55c0 .87.13 1.35.3 1.06l4.72-8.25c.17-.29.3-1.1.3-1.8l.01-5.57c0 2.01-.4 4.27-.89 5.03l-2.25 3.58c-.82 1.32-1.59.25-1.98-2.87l-.21-1.65v10.44'
        />
        <path
          fill='#755474'
          d='M22.74 156.14l-4.75 5.51c-.17.19-.3 1.06-.3 1.95v3.13c0-1.57.25-3.15.54-3.52l4.26-5.25c.3-.37.54.34.54 1.6l.01-2.49c0-.73-.13-1.13-.3-.93z'
        />
        <path
          fill='#755474'
          d='M23.98 166.12l3.36-5.86c.54-.95.99-2.24.99-2.86l.05-6.67c0-.63-.44-.62-.98.01l-3.37 3.91c-.56.63-.99 1.84-.99 2.68l-.03 8.98c-.01.86.43.77.97-.19z'
        />
        <path
          fill='#755474'
          d='M27.47 157.55l-3.58 5.71c-.49.77-.87-.24-.86-2.24l-.01 5.57c0 .7.13 1.03.3.73l4.72-8.25c.17-.29.3-.95.32-1.47l.03-4.14c-.03 1.49-.44 3.33-.92 4.09z'
        />
      </g>
      <path
        fill='#565D81'
        d='M499.72 146.32V130.3c-8.74-6.95-29.22-11.82-53.09-11.82-12.75 0-24.52 1.39-34.05 3.75-10.5 2.6-21.55 2.6-32.06 0-9.53-2.35-21.32-3.75-34.05-3.75-12.73 0-24.52 1.39-34.05 3.75-10.5 2.6-21.55 2.6-32.06 0-9.53-2.35-21.32-3.75-34.05-3.75-12.75 0-24.52 1.39-34.05 3.75-10.5 2.6-21.55 2.6-32.06 0-9.53-2.35-21.32-4.14-34.05-3.75-51.13 1.6-57.6 8.66-57.6 19.34l411.17 8.5s0-1.73 0 0z'
      />
      <path fill='#535171' d='M.17 219.93h499.81V500H.17z' />
      <g>
        <path
          fill='#DEEEE8'
          d='M79.22 123.54c.09.09.16.18.24.27l38.93-43.59c-.09-.08-.18-.15-.27-.24-2.75-2.68-2.76-7.09-.03-9.8 2.66-2.62 7.11-2.58 9.73.08a6.84 6.84 0 011.96 4.57c.04.94.76 1.65 1.7 1.66 1.66.01 3.32.63 4.61 1.85 2.72 2.56 2.86 7.01.3 9.72-2.64 2.8-7.05 2.87-9.79.2-.09-.09-.16-.18-.24-.27l-38.94 43.6c.09.08.18.15.27.24 2.75 2.68 2.76 7.09.03 9.8-2.66 2.62-7.11 2.58-9.73-.08a6.84 6.84 0 01-1.96-4.57c-.04-.94-.76-1.65-1.7-1.66a6.759 6.759 0 01-4.61-1.85c-2.72-2.56-2.86-7.01-.3-9.72 2.65-2.8 7.07-2.89 9.8-.21z'
        />
      </g>
      <g>
        <path
          fill='#DEEEE8'
          d='M99.05 114.37c.03.06.04.14.06.2l32.34-13.43c-.03-.06-.06-.13-.09-.2-.78-2.16.35-4.56 2.53-5.3 2.11-.73 4.52.46 5.24 2.57a4.1 4.1 0 01-.13 2.97c-.23.52-.01 1.09.48 1.34.89.44 1.62 1.2 2.01 2.2.81 2.09-.28 4.53-2.37 5.33-2.15.82-4.56-.28-5.34-2.44-.03-.06-.04-.14-.06-.2l-32.34 13.44c.03.06.06.13.09.2.78 2.16-.35 4.56-2.53 5.3-2.11.73-4.52-.44-5.24-2.57a4.1 4.1 0 01.13-2.97c.23-.52.01-1.09-.48-1.34-.89-.44-1.62-1.2-2.01-2.2-.81-2.09.28-4.53 2.37-5.33a4.09 4.09 0 015.34 2.43z'
        />
      </g>
      <g>
        <path
          fill='#DEEEE8'
          d='M105.95 120.69c0 .05.01.09.01.14l22.41-3.77c-.01-.05-.01-.09-.03-.14-.18-1.48.89-2.82 2.38-2.99 1.44-.15 2.78.95 2.94 2.39.08.7-.13 1.34-.52 1.86a.66.66 0 00.11.91c.49.41.85 1 .95 1.68.2 1.44-.84 2.82-2.28 3.03-1.48.2-2.85-.84-3.02-2.33 0-.05-.01-.09-.01-.14l-22.41 3.77c.01.05.01.09.03.14.18 1.48-.89 2.82-2.38 2.99-1.44.15-2.78-.95-2.94-2.39-.08-.7.13-1.34.52-1.86a.66.66 0 00-.11-.91c-.49-.41-.85-1-.95-1.68-.2-1.44.84-2.82 2.27-3.03 1.5-.2 2.85.85 3.03 2.33z'
        />
      </g>
      <path fill='#242842' d='M36.63 109.97h56.1v34.08h-56.1z' />
      <g fill='#FFFDD4'>
        <path d='M79.78 118.5l-4.2 25.01c-.05.3-.32.53-.63.53h-.67c-.99-3.43-.85-5.71-1.34-9.37-1.47-10.84-4.8-8.98-7.61-13.39' />
        <path d='M87.03 124.73l-4.04 18.51c-.1.47-.53.81-1.01.78-1.83-4.8-2.94-10.6-3.24-15.91l-.15-2.54' />
        <g>
          <path d='M51.13 118.5l4.2 25.01c.05.3.32.53.63.53h.67c.99-3.43.85-5.71 1.34-9.37 1.47-10.84 4.8-8.98 7.61-13.39' />
          <path d='M43.88 124.73l4.04 18.51c.1.47.53.81 1.01.78 1.83-4.8 2.94-10.6 3.24-15.91l.15-2.54' />
        </g>
      </g>
      <path
        fill='#EEFFF8'
        d='M111.66 103.42c-2.23-2.14-3.72-4.95-4.32-7.99-3.57-18.01-20.96-31.67-41.89-31.67-20.92 0-38.32 13.67-41.87 31.67-.59 3.04-2.09 5.85-4.32 7.99-4.23 4.06-6.64 8.73-6.64 13.72 0 7.14 5.78 12.93 12.92 12.93h2.53c1.44 0 2.59 1.19 2.57 2.63-.01.72-.91 4.29-.92 5.13a6.153 6.153 0 006.15 6.24h1.28c2.73 0 5.05-1.96 5.57-4.63 2.06-10.52 12.91-17.85 22.74-17.85s18.93 7.33 20.98 17.85c.52 2.68 2.83 4.63 5.57 4.63 3.43 0 6.2-2.81 6.15-6.24-.01-.84-.9-4.41-.92-5.13a2.57 2.57 0 012.57-2.63h5.57c7.14 0 12.92-5.79 12.92-12.93-.01-4.99-2.42-9.66-6.64-13.72z'
      />
      <g fill='#A1A4B1'>
        <path d='M46.11 89.31c-.68-1.76-2.9-2.3-4.33-1.08-10.25 8.8-2.75 20.24 3.26 22.32 5.92 2.04 13.29-1.58 14.1-9.72.15-1.58-1.04-2.96-2.62-3.01-5.24-.16-8.57-3.79-10.41-8.51zM84.8 89.31c.68-1.76 2.9-2.3 4.33-1.08 10.25 8.8 2.75 20.24-3.26 22.32-5.92 2.04-13.29-1.58-14.1-9.72-.15-1.58 1.04-2.96 2.62-3.01 5.24-.16 8.57-3.79 10.41-8.51z' />
      </g>
      <path
        fill='#A1A4B1'
        d='M64.47 107.17L61 113.4c-.53.96.48 2.04 1.47 1.57l2.51-1.19c.3-.14.66-.14.96 0l2.51 1.19c.99.47 2-.61 1.47-1.57l-3.47-6.23c-.44-.77-1.55-.77-1.98 0z'
      />
      <g>
        <path fill='#565D81' d='M.82 181.85H125v11.22H.82z' />
        <path fill='#666E98' d='M.82 183.97H125v9.1H.82z' />
      </g>
      <g>
        <path fill='#565D81' d='M5.7 171.66h114.4V182H5.7z' />
        <path fill='#666E98' d='M5.7 173.61h114.4V182H5.7z' />
      </g>
      <g>
        <path fill='#565D81' d='M12.58 162.62h100.67v9.1H12.58z' />
        <path fill='#666E98' d='M12.58 164.33h100.67v7.38H12.58z' />
      </g>
      <g>
        <path fill='#565D81' d='M17.61 154.46h90.9v8.24h-90.9z' />
        <path fill='#666E98' d='M17.61 156.01h90.9v6.68h-90.9z' />
      </g>
      <g>
        <path fill='#565D81' d='M20.26 148.25h84.67v6.28H20.26z' />
        <path fill='#666E98' d='M20.26 149.44h84.67v5.09H20.26z' />
      </g>
      <g>
        <path fill='#565D81' d='M22.79 142.81h77.1v5.52h-77.1z' />
        <path fill='#666E98' d='M22.79 143.85h77.1v4.48h-77.1z' />
      </g>
      <g>
        <path fill='#565D81' d='M.17 193.04h108.07v11.87H.17z' />
        <path fill='#666E98' d='M.17 195.28h108.07v9.63H.17z' />
      </g>
      <g>
        <path fill='#755474' d='M99.15 136.49h400.83v83.36H98.03z' />
      </g>
      <g>
        <path
          fill='#825E81'
          d='M117.28 151.15l-5.54-3.32c-.9-.54-1.63-1.85-1.65-2.92l-.09-11.37c-.01-1.08.72-1.94 1.63-1.94h5.58c.91 0 1.65 1.16 1.65 2.62l.04 15.29c.01 1.45-.72 2.19-1.62 1.64z'
        />
        <path
          fill='#755474'
          d='M111.53 143.85l5.92 2.7c.8.37 1.44-2.11 1.43-5.54l.03 9.49c0 1.19-.22 2.01-.49 1.85l-7.8-4.8c-.28-.16-.51-1.03-.52-1.91l-.05-7.05c.02 2.55.67 4.9 1.48 5.26zM110.5 131.61h9.97c.28 0-1.61.96-1.61 2.15l.01 4.24c0-2.13-.42-3.89-.91-3.92l-7.04-.52c-.49-.04-.9 1.22-.89 2.8l-.03-3.15c0-.88.22-1.6.5-1.6z'
        />
        <path
          fill='#755474'
          d='M117.34 170.99l-5.5-6.7c-.9-1.09-1.63-2.84-1.63-3.91l-.09-11.37c-.01-1.08.72-1.49 1.62-.95l5.54 3.37c.9.54 1.63 2.16 1.63 3.61l.04 15.29c.01 1.46-.71 1.75-1.61.66z'
        />
        <path
          fill='#825E81'
          d='M112.56 150.82c-1.16-1.75-2.39 2.77-2.4 1.01l-.01-1.57c-.01-1.76.42-2.91.97-2.57l6.81 4.14c.54.33 1 2.52 1.01 4.89v2.13c0 1.24-.44.15-.99-1.34-.78-2.08-1.63-3.33-2.48-3.7-.71-.33-1.72-1.13-2.91-2.99zM111.64 159.8l5.88 6.2c.8.84 1.43-1.27 1.42-4.68l.03 9.49c0 1.19-.22 1.89-.49 1.54l-7.74-9.42c-.28-.33-.51-1.33-.51-2.2l-.05-7.05c0 2.54.66 5.28 1.46 6.12zM126.06 156.42l-5.54-3.32c-.9-.54-1.63-2.15-1.63-3.6l-.05-15.28c0-1.44.72-2.61 1.63-2.61h5.58c.91 0 1.65 1.47 1.65 3.28v19.22c-.02 1.82-.74 2.85-1.64 2.31z'
        />
        <path
          fill='#755474'
          d='M120.32 147.32l5.92 3.23c.8.37 1.44-3.48 1.44-7.79v11.93c0 1.49-.23 2.57-.51 2.41l-7.8-4.67c-.28-.16-.51-1.27-.51-2.46l-.03-9.48c.03 3.41.69 6.47 1.49 6.83zM118.84 136.49c1.4-4.79 2.91-2.11 3.38-.72.25.78 1.71 2.75 2.63.05.86-2.62 1.58-2.01 1.58-2.01.49.03 1.24 1.49 1.24 3.34v-3.67c0-1.03 5.43-1.86 5.15-1.86h-13.55c-.24 0-.43.82-.43 1.85v3.02M126.06 181.59l-5.5-6.7c-.9-1.09-1.62-3.14-1.63-4.58l-.04-15.28c0-1.44.72-2.16 1.62-1.62l5.54 3.37c.9.54 1.63 2.47 1.63 4.28v19.22c0 1.81-.72 2.39-1.62 1.31z'
        />
        <path
          fill='#825E81'
          d='M120.37 169l5.88 6.2c.8.84 1.44-1.97 1.44-6.28v11.93c0 1.49-.23 2.43-.49 2.1l-7.74-9.42c-.28-.33-.51-1.57-.51-2.76l-.03-9.48c0 3.42.65 6.88 1.45 7.71zM119.39 152.72l7.8 4.75c.28.16.51 1.52.51 3.01v5.33c0-2.68-.4-5.13-.9-5.47l-7-4.77c-.49-.34-.9 1.11-.89 3.24l-.01-4.24c-.01-1.19.21-2.01.49-1.85z'
        />
        <path
          fill='#755474'
          d='M126.07 206.22l-5.47-9.99c-.89-1.62-1.61-4.11-1.62-5.56l-.04-15.28c0-1.44.72-1.73 1.61-.65l5.5 6.67c.9 1.09 1.62 3.43 1.62 5.24v19.22c.01 1.82-.71 1.97-1.6.35z'
        />
        <path
          fill='#755474'
          d='M127.68 206.45c0 1.49-.23 2.3-.49 1.8l-7.69-14.05c-.28-.51-.49-1.87-.51-3.06l-.03-9.48c.01 3.42.66 7.25 1.44 8.57l3.67 6.1c1.34 2.24 2.58.43 3.24-4.89l.34-2.81v17.79'
        />
        <path
          fill='#755474'
          d='M119.44 173.38l7.74 9.38c.28.33.49 1.82.49 3.32v5.33c0-2.68-.4-5.37-.9-6l-6.95-8.95c-.49-.63-.89.58-.89 2.71l-.01-4.24c.03-1.18.24-1.88.52-1.55z'
        />
        <path
          fill='#755474'
          d='M117.42 190.4l-5.47-9.99c-.89-1.62-1.62-3.81-1.62-4.89l-.09-11.37c-.01-1.08.71-1.06 1.61.03l5.5 6.67c.9 1.09 1.62 3.13 1.63 4.58l.04 15.29c.01 1.45-.72 1.3-1.6-.32z'
        />
        <path
          fill='#755474'
          d='M111.74 175.79l5.85 9.72c.78 1.32 1.42-.41 1.42-3.82l.03 9.49c0 1.19-.22 1.75-.49 1.25l-7.69-14.05c-.28-.51-.51-1.62-.51-2.51l-.05-7.05c-.01 2.54.65 5.66 1.44 6.97zM110.72 162.82l7.74 9.38c.28.33.51 1.57.51 2.76l.01 4.24c0-2.13-.4-4.38-.9-5.01l-6.95-8.95c-.49-.63-.89.14-.87 1.72l-.03-3.15c0-.87.22-1.33.49-.99z'
        />
      </g>
      <g>
        <path
          fill='#825E81'
          d='M104 143.09l-3.25-1.95c-.53-.32-.96-1.09-.96-1.71l-.05-6.67c0-.63.42-1.14.95-1.14h3.28c.53 0 .96.68.96 1.53l.03 8.98c.01.85-.42 1.28-.96.96z'
        />
        <path
          fill='#755474'
          d='M100.63 138.8l3.48 1.58c.47.22.85-1.24.84-3.25l.01 5.57c0 .7-.13 1.19-.29 1.09l-4.58-2.81c-.16-.1-.3-.61-.3-1.13l-.03-4.14c.02 1.49.4 2.87.87 3.09zM100.03 131.61h7.04c.16 0-2.14.57-2.14 1.27l.01 2.49c0-1.25-.24-2.28-.53-2.3l-4.14-.3c-.29-.03-.53.71-.52 1.65l-.01-1.85c-.01-.53.13-.96.29-.96z'
        />
        <path
          fill='#755474'
          d='M104.04 154.74l-3.23-3.92c-.53-.63-.95-1.67-.96-2.29l-.05-6.67c0-.63.42-.87.95-.56l3.25 1.97c.53.32.96 1.27.96 2.11l.03 8.98c0 .84-.42 1.01-.95.38z'
        />
        <path
          fill='#825E81'
          d='M101.23 142.89c-.68-1.03-1.4 1.62-1.42.59l-.01-.92c-.01-1.03.25-1.71.57-1.51l4 2.43c.33.2.58 1.48.59 2.86v1.24c0 .73-.27.09-.58-.78-.46-1.22-.96-1.95-1.46-2.16-.4-.18-.99-.65-1.69-1.75zM100.69 148.15l3.45 3.63c.47.49.84-.73.84-2.75l.01 5.57c0 .7-.13 1.1-.29.91l-4.54-5.53c-.16-.2-.29-.77-.3-1.29l-.03-4.14c.01 1.51.39 3.12.86 3.6zM109.16 146.18l-3.25-1.95c-.53-.32-.96-1.27-.96-2.1l-.03-8.98c0-.85.43-1.53.96-1.53h3.28c.53 0 .96.86.96 1.92v11.28c0 1.07-.43 1.67-.96 1.36z'
        />
        <path
          fill='#755474'
          d='M105.79 140.84l3.48 1.9c.47.22.85-2.04.85-4.57v7c0 .87-.13 1.51-.29 1.42l-4.58-2.75c-.16-.1-.29-.75-.3-1.44l-.01-5.57c0 1.99.38 3.79.85 4.01zM104.93 134.48c.82-2.81 1.71-1.24 1.99-.43.15.46 1 1.61 1.54.03.51-1.54.94-1.18.94-1.18.29.01.73.87.73 1.96v-2.15c0-.61 1.68-1.09 1.52-1.09h-6.48c-.14 0-.25.48-.25 1.09l.01 1.77M109.17 160.95l-3.23-3.92c-.53-.63-.95-1.85-.95-2.68l-.03-8.98c0-.85.43-1.28.95-.95l3.25 1.97c.53.32.96 1.44.96 2.51v11.28c0 1.06-.43 1.42-.95.77z'
        />
        <path
          fill='#825E81'
          d='M105.81 153.56l3.45 3.63c.47.49.85-1.15.85-3.68v7c0 .87-.13 1.43-.29 1.23l-4.54-5.53c-.16-.2-.29-.92-.29-1.62l-.01-5.57c-.01 2.02.38 4.04.83 4.54zM105.24 144.01l4.58 2.79c.16.1.29.89.29 1.77v3.13c0-1.57-.24-3.01-.53-3.2l-4.11-2.81c-.29-.2-.52.66-.52 1.9l-.01-2.49c.01-.71.14-1.19.3-1.09z'
        />
        <path
          fill='#755474'
          d='M109.17 175.41l-3.21-5.86c-.52-.95-.95-2.42-.95-3.25l-.03-8.98c0-.85.42-1.01.95-.38l3.23 3.91c.53.63.95 2.01.95 3.08v11.28c.01 1.06-.41 1.16-.94.2z'
        />
        <path
          fill='#755474'
          d='M110.12 175.55c0 .87-.13 1.35-.29 1.06l-4.52-8.25c-.16-.29-.29-1.1-.29-1.8l-.01-5.57c0 2.01.38 4.27.85 5.03l2.15 3.58c.78 1.32 1.52.25 1.9-2.87l.2-1.65v10.44'
        />
        <path
          fill='#755474'
          d='M105.28 156.14l4.54 5.51c.16.19.29 1.06.29 1.95v3.13c0-1.57-.24-3.15-.52-3.52l-4.07-5.25c-.29-.37-.52.34-.52 1.6l-.01-2.49c0-.73.13-1.13.29-.93z'
        />
        <path
          fill='#755474'
          d='M104.09 166.12l-3.21-5.86c-.52-.95-.95-2.24-.95-2.86l-.05-6.67c0-.63.42-.62.94.01l3.23 3.91c.53.63.95 1.84.95 2.68l.03 8.98c0 .86-.42.77-.94-.19z'
        />
        <path
          fill='#755474'
          d='M100.75 157.55l3.43 5.71c.47.77.84-.24.82-2.24l.01 5.57c0 .7-.13 1.03-.29.73l-4.52-8.25c-.16-.29-.29-.95-.3-1.47l-.03-4.14c.03 1.49.41 3.33.88 4.09z'
        />
      </g>
      <g>
        <path
          fill='#E3BED9'
          d='M456.64 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 3-2.43 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M422.05 151.33h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M416.93 131.56h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38a2.73 2.73 0 012.74-2.73z'
        />
        <path
          fill='#A587B3'
          d='M456.64 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.43 5.42-5.42 5.42z'
        />
        <path
          fill='#E3BED9'
          d='M427.42 161.47c-6.29-1.53-13.22 7.58-13.22 4.6v-2.68c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v2.68c0 1.57-2.43.66-5.37-.72a27.285 27.285 0 00-13.5-2.49c-3.84.27-9.34.18-15.78-1.39z'
        />
        <path
          fill='#825E81'
          d='M422.05 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#825E81'
          d='M456.64 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.43 5.42-5.42 5.42z'
        />
        <path
          fill='#755474'
          d='M422.05 202.9h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#A588B2'
          d='M416.93 183.79h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38c0-1.5 1.23-2.73 2.74-2.73z'
        />
      </g>
      <path
        fill='#E3BED9'
        d='M500 131.56h-32.64c-2.99 0-5.42 2.42-5.42 5.42v15.19c0 2.99 2.42 5.42 5.42 5.42H500v-26.03z'
      />
      <g>
        <path
          fill='#E3BED9'
          d='M361.04 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 3-2.42 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M326.46 151.33h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M321.33 131.56h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9H323.5c-2.71 0-4.9 2.19-4.9 4.9v-5.38c0-1.52 1.23-2.73 2.73-2.73z'
        />
        <path
          fill='#A587B3'
          d='M361.04 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#E3BED9'
          d='M331.82 161.47c-6.29-1.53-13.22 7.58-13.22 4.6v-2.68c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v2.68c0 1.57-2.43.66-5.37-.72a27.285 27.285 0 00-13.5-2.49c-3.83.27-9.33.18-15.78-1.39z'
        />
        <path
          fill='#825E81'
          d='M326.46 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#E3BED9'
          d='M408.78 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c-.01 3-2.44 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M374.19 151.33h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M366.33 137.75c7.66-6.08 15.79-2.95 18.32-1.39 1.39.86 9.23 2.82 14.25-.27 4.67-2.86 8.58-2.25 8.58-2.25 2.71 0 6.72 1.44 6.72 3.3v-3.71c0-1.04-1.23-1.89-2.73-1.89h-42.78c-1.29 0-2.34 1.05-2.34 2.34v3.86'
        />
        <path
          fill='#A587B3'
          d='M408.78 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c-.01 2.99-2.44 5.42-5.42 5.42z'
        />
        <path
          fill='#825E81'
          d='M374.19 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#E3BED9'
          d='M369.07 157.95h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38c0-1.5 1.22-2.73 2.74-2.73z'
        />
        <path
          fill='#825E81'
          d='M408.78 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c-.01 2.99-2.44 5.42-5.42 5.42z'
        />
        <path
          fill='#755474'
          d='M414.19 207.08c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c0 4.34 3.52 7.86 7.86 7.86h20.17c7.39 0 14.21-4 17.82-10.46l1.86-3.33v17.96'
        />
        <path
          fill='#A588B2'
          d='M369.07 183.79h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38c0-1.5 1.22-2.73 2.74-2.73z'
        />
        <path
          fill='#825E81'
          d='M361.04 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#755474'
          d='M326.46 202.9h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#A588B2'
          d='M321.33 183.79h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9H323.5c-2.71 0-4.9 2.19-4.9 4.9v-5.38c0-1.5 1.23-2.73 2.73-2.73z'
        />
      </g>
      <path
        fill='#825E81'
        d='M500 183.8h-32.64c-2.99 0-5.42 2.42-5.42 5.42v15.18c0 2.99 2.42 5.42 5.42 5.42H500V183.8z'
      />
      <g>
        <path
          fill='#E3BED9'
          d='M265.46 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 3-2.43 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M230.87 151.33h32.16c4.34 0 7.86-.85 7.86-5.19v9.37c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.52 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M225.74 131.56h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9H227.9c-2.71 0-4.9 2.19-4.9 4.9v-5.38a2.742 2.742 0 012.74-2.73z'
        />
        <path
          fill='#A587B3'
          d='M265.45 183.98h-37.13a5.42 5.42 0 01-5.43-5.42v-15.18c0-3 2.44-5.42 5.43-5.42h37.13a5.42 5.42 0 015.43 5.42v15.18c0 2.99-2.44 5.42-5.43 5.42z'
        />
        <path
          fill='#E3BED9'
          d='M236.13 161.47c-6.31-1.53-13.26 7.58-13.26 4.6v-2.68c0-3 2.44-5.42 5.43-5.42h37.13a5.42 5.42 0 015.43 5.42v2.68c0 1.57-2.44.66-5.38-.72a27.44 27.44 0 00-13.54-2.49c-3.82.27-9.35.18-15.81-1.39z'
        />
        <path
          fill='#825E81'
          d='M230.75 177.07H263c4.35 0 7.88-3.52 7.88-7.86v12.03c0 1.51-1.23 2.74-2.74 2.74h-42.53c-1.51 0-2.74-1.23-2.74-2.73l.14-9.51c0-.25.34-.29.42-.06.99 3.13 3.84 5.39 7.32 5.39z'
        />
        <path
          fill='#E3BED9'
          d='M313.18 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 3-2.42 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M278.6 151.33h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.5-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.97-1.46-2.97-2.97l.21-10.3c0-.1.15-.12.17-.02a7.847 7.847 0 007.71 6.38z'
        />
        <path
          fill='#FFEDE7'
          d='M270.74 137.75c7.67-6.08 15.79-2.95 18.32-1.39 1.39.86 9.23 2.82 14.25-.27 4.67-2.86 8.58-2.25 8.58-2.25 2.71 0 6.72 1.44 6.72 3.3v-3.71c0-1.04-1.23-1.89-2.73-1.89H273.1c-1.29 0-2.34 1.05-2.34 2.34l-.02 3.87'
        />
        <path
          fill='#A587B3'
          d='M313.18 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#825E81'
          d='M278.6 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73l-.07-9.06c-.01 4.34 3.58 4.88 7.92 4.88z'
        />
        <path
          fill='#E3BED9'
          d='M273.47 157.95h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9l-.31-5.38c.01-1.5 1.55-2.73 3.05-2.73z'
        />
        <path
          fill='#825E81'
          d='M313.18 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#755474'
          d='M318.61 207.08c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c0 4.34 3.52 7.86 7.86 7.86h20.17c7.39 0 14.21-4 17.82-10.46l1.86-3.33v17.96'
        />
        <path
          fill='#A588B2'
          d='M273.47 183.79h42.41c1.5 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08a4.9 4.9 0 00-4.69 3.47c-.04.12-.21.1-.21-.03v-3.92c.01-1.5 1.24-2.73 2.74-2.73z'
        />
        <path
          fill='#825E81'
          d='M265.46 209.82h-37.03c-3 0-6.03-2.43-6.03-5.42l.61-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.43 5.42-5.42 5.42z'
        />
        <path
          fill='#A588B2'
          d='M225.74 183.79h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9H227.9c-2.71 0-4.9 2.19-4.9 4.9l-.22-3.53.22-1.85c.01-1.5 1.24-2.73 2.74-2.73z'
        />
        <path
          fill='#755474'
          d='M230.87 202.9h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.5-1.23 2.73-2.73 2.73h-42.41c-1.51 0-3.07-1.23-3.07-2.73l.05-11.26c0-.13.18-.15.21-.03 1.11 3.89 3.89 7.11 7.93 7.11z'
        />
      </g>
      <path
        fill='#A587B3'
        d='M500 157.96h-32.64c-2.99 0-5.42 2.42-5.42 5.42v15.18c0 2.99 2.42 5.42 5.42 5.42H500v-26.02z'
      />
      <g>
        <path
          fill='#E3BED9'
          d='M169.86 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 3-2.42 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M135.27 151.33h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c0 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M130.15 131.56h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38a2.736 2.736 0 012.74-2.73z'
        />
        <path
          fill='#A587B3'
          d='M169.86 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#E3BED9'
          d='M140.64 161.47c-6.29-1.53-13.22 7.58-13.22 4.6v-2.68c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v2.68c0 1.57-2.43.66-5.37-.72a27.285 27.285 0 00-13.5-2.49c-3.83.27-9.34.18-15.78-1.39z'
        />
        <path
          fill='#825E81'
          d='M135.27 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c0 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#E3BED9'
          d='M217.59 157.58h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.19c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 3-2.42 5.43-5.42 5.43z'
        />
        <path
          fill='#A588B2'
          d='M183.01 150.67l32.16.66c4.34 0 7.86-4.18 7.86-8.52v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#FFEDE7'
          d='M175.15 137.75c7.67-6.08 15.79-2.95 18.32-1.39 1.39.86 9.23 2.82 14.25-.27 4.67-2.86 8.58-2.25 8.58-2.25 2.71 0 6.72 1.44 6.72 3.3v-3.71c0-1.04-1.23-1.89-2.73-1.89H177.5c-1.29 0-2.34 1.05-2.34 2.34v3.86'
        />
        <path
          fill='#A587B3'
          d='M217.59 183.98h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c0 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#825E81'
          d='M183.01 177.07h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c-.01 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#E3BED9'
          d='M177.88 157.95h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38c.01-1.5 1.24-2.73 2.74-2.73z'
        />
        <path
          fill='#825E81'
          d='M217.59 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42l-.28 15.18c0 2.99-2.14 5.42-5.14 5.42z'
        />
        <path
          fill='#755474'
          d='M223.01 207.08c0 1.51-1.23 2.73-2.73 2.73h-42.41a2.74 2.74 0 01-2.73-2.73v-12.04c0 4.34 3.52 7.86 7.86 7.86h20.17c7.39 0 14.21-4 17.82-10.46l.37-.56c.53-.81 1.79-.41 1.77.55l-.28 14.64'
        />
        <path
          fill='#A588B2'
          d='M177.88 183.79h42.39c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.06c-2.71 0-4.9 2.19-4.9 4.9v-5.38c.01-1.5 1.24-2.73 2.74-2.73z'
        />
        <path
          fill='#825E81'
          d='M169.86 209.82h-37.03c-3 0-5.42-2.43-5.42-5.42v-15.18c0-3 2.43-5.42 5.42-5.42h37.03c3 0 5.42 2.43 5.42 5.42v15.18c.01 2.99-2.42 5.42-5.42 5.42z'
        />
        <path
          fill='#755474'
          d='M135.27 202.9h32.16c4.34 0 7.86-3.52 7.86-7.86v12.04c0 1.51-1.23 2.73-2.73 2.73h-42.41c-1.51 0-2.73-1.23-2.73-2.73v-12.04c0 4.34 3.51 7.86 7.85 7.86z'
        />
        <path
          fill='#A588B2'
          d='M130.15 183.79h42.41c1.51 0 2.73 1.23 2.73 2.73v5.38c0-2.71-2.19-4.9-4.9-4.9h-38.08c-2.71 0-4.9 2.19-4.9 4.9v-5.38c.01-1.5 1.23-2.73 2.74-2.73z'
        />
      </g>
      <g>
        <path fill='#565D81' d='M54.14 181.85h67.65v11.22H54.14z' />
        <path fill='#666E98' d='M54.14 183.96h67.65v9.1H54.14z' />
      </g>
      <g>
        <path fill='#565D81' d='M59.03 171.66h57.87V182H59.03z' />
        <path fill='#666E98' d='M59.03 173.61h57.87V182H59.03z' />
      </g>
      <g>
        <path fill='#565D81' d='M65.9 162.61h44.14v9.1H65.9z' />
        <path fill='#666E98' d='M65.9 164.34h44.14v7.38H65.9z' />
      </g>
      <g>
        <path fill='#565D81' d='M70.61 154.47h34.37v8.24H70.61z' />
        <path fill='#666E98' d='M70.61 156.02h34.37v6.68H70.61z' />
      </g>
      <path
        fill='#755474'
        d='M500 202.9h-30.19c-4.34 0-7.86-3.52-7.86-7.86v12.04c0 1.51 1.23 2.73 2.73 2.73H500v-6.91z'
      />
      <g>
        <path fill='#565D81' d='M74.2 148.26h28.14v6.28H74.2z' />
        <path fill='#666E98' d='M74.2 149.44h28.14v5.09H74.2z' />
      </g>
      <g>
        <path fill='#565D81' d='M79.26 142.81h20.56v5.52H79.26z' />
        <path fill='#666E98' d='M79.26 143.85h20.56v4.48H79.26z' />
      </g>
      <g>
        <path fill='#565D81' d='M70.89 193.04h56.53v11.87H70.89z' />
        <path fill='#666E98' d='M70.89 195.28h56.53v9.63H70.89z' />
      </g>
      <g>
        <path fill='#565D81' d='M.17 204.89h499.81v14.19H.17z' />
        <path fill='#666E98' d='M.17 207.56h499.81v11.51H.17z' />
        <path fill='#565D81' d='M.17 218.1h499.81v15.66H.17z' />
        <path fill='#666E98' d='M.17 221.04h499.81v12.71H.17z' />
      </g>
      <path
        fill='#A588B2'
        d='M500 151.33h-30.19c-4.34 0-7.86-3.52-7.86-7.86v12.04c0 1.51 1.23 2.73 2.73 2.73H500v-6.91z'
      />
      <path
        fill='#825E81'
        d='M500 177.07h-30.19c-4.34 0-7.86-3.52-7.86-7.86v12.04c0 1.51 1.23 2.73 2.73 2.73H500v-6.91z'
      />
      <path
        fill='#A588B2'
        d='M500 183.79h-35.32c-1.51 0-2.73 1.23-2.73 2.73v5.38c0-2.71 2.19-4.9 4.9-4.9H500v-3.21z'
      />
      <path
        fill='#E3BED9'
        d='M500 157.96h-32.64c-2.99 0-5.42 2.42-5.42 5.42v2.68c0 2.99 6.93-6.13 13.22-4.6 6.44 1.57 11.95 1.66 15.77 1.38 3.06-.22 6.12.09 9.06.88v-5.76z'
      />
      <path
        fill='#FFEDE7'
        d='M500 131.56h-35.32c-1.51 0-2.73 1.22-2.73 2.73v5.38c0-2.71 2.19-4.9 4.9-4.9H500v-3.21z'
      />
    </svg>
  );
}

export default SvgVoyageWidgetBkBoneyard;
