import { SvgEditionSlot } from '../part';
import * as React from 'react';

const BubbleWand = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#87ffe1'
      d='M300.68 53.47c-23.61-6.22-47.79 7.89-54.01 31.5s7.89 47.79 31.5 54.01c23.61 6.22 47.79-7.89 54.01-31.5s-7.88-47.79-31.5-54.01zm-19.07 72.45c-16.4-4.32-26.19-21.11-21.88-37.51 4.32-16.4 21.11-26.19 37.51-21.88s26.19 21.11 21.88 37.51c-4.32 16.4-21.11 26.2-37.51 21.88z'
    />
    <path
      fill='#3bffcf'
      d='m296 64 1-12 6 2-5 11zM282 127l-2 12-5-1 5-12zM320 103l12 1-1 6-11-5zM258 89l-13-2 2-5 11 4zM316 79l10-8 3 5-12 5zM262 113l-10 7-3-4 12-5zM306 123l8 10-5 2-5-11zM272 69l-8-10 5-3 5 11zM308 70l6-11 4 4-8 8zM270 122l-6 10-4-3 8-9zM315 114l11 7-4 4-8-9zM263 77l-11-6 4-5 8 9zM321 90l11-3 1 6h-12zM257 101l-12 3v-5h12zM294 127l3 12-5 1v-12zM284 64l-3-12h5v12z'
    />
    <path
      fill='#72ffdc'
      d='m287.28 140.89-14.85-3.76-46.15 157.85h.01c-.02.05-.04.1-.05.15-.53 2 3.57 4.82 9.16 6.29 5.58 1.47 7.53 2.15 8.05.15.02-.04 43.83-160.68 43.83-160.68z'
    />
    <path opacity={0.32} fill='#2cbf9b' d='m282 150-13-5 3-9 13 4z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m286.68 140.89-43.22 160.69c-.53 2-4.8 2.37-10.38.9-5.58-1.47-7.96-5.34-7.43-7.34.01-.05.03-.1.05-.15h-.01l46.15-157.85'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M300.68 53.47c-23.61-6.22-47.79 7.89-54.01 31.5s7.89 47.79 31.5 54.01c23.61 6.22 47.79-7.89 54.01-31.5s-7.88-47.79-31.5-54.01z'
    />
    <circle
      transform='rotate(-45.001 289.428 96.23)'
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={289}
      cy={96}
      r={30}
    />
    <linearGradient
      id='BubbleWand__a'
      gradientUnits='userSpaceOnUse'
      x1={97}
      y1={98}
      x2={312}
      y2={98}
      gradientTransform='rotate(-13.663 213.755 81.707)'>
      <stop
        offset={0.122}
        style={{
          stopColor: '#7bcdfd',
        }}
      />
      <stop
        offset={0.291}
        style={{
          stopColor: '#66f4ff',
        }}
      />
      <stop
        offset={0.676}
        style={{
          stopColor: '#89b8f2',
        }}
      />
      <stop
        offset={0.973}
        style={{
          stopColor: '#b0b0ff',
          stopOpacity: 0.6,
        }}
      />
    </linearGradient>
    <path
      opacity={0.73}
      fill='url(#BubbleWand__a)'
      d='M268.9 60.78c-37.71 1.32-72.64-15.81-80.18-17.87-19.27-5.28-39.97-1.14-55.54 11.39-16.08 12.94-30.96 33.01-30.53 55.23.74 38.1 36.99 68.3 75.09 67.56 0 0 30.24-.74 61.02-24.11 24.02-18.24 43.66-29.05 43.66-29.05 12.83 3.97 34.03-4.45 35.51-26.07 1.63-23.73-25.9-37.89-49.03-37.08z'
    />
    <path
      opacity={0.3}
      fill='#FFF'
      d='M124.95 102.08c-35.28 27.05 44.44 98.6 112.51 42.98 11.16-9.12 17.21-15.03 20.06-19.24 2.9-4.29 2.73-9.99-.53-14.01-11.13-13.74-39.84 14.48-66.7 8.8-32.22-6.82-49.82-30.43-65.34-18.53z'
    />
    <circle
      transform='matrix(1 -.00563 .00563 1 -.433 .76)'
      opacity={0.3}
      fill='#FFF'
      cx={134}
      cy={77}
      r={9}
    />
    <circle
      transform='rotate(-52.705 293.16 87.184)'
      opacity={0.3}
      fill='#FFF'
      cx={293}
      cy={87}
      r={14}
    />
    <path
      opacity={0.3}
      fill='none'
      stroke='#FFF'
      strokeWidth={13}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M164.05 59.9s14.37-7.76 29.49 1.88c5.95 3.79 11.96 5.09 17.19 5.84'
    />
  </svg>
);

export default BubbleWand;
