import React, { FunctionComponent } from 'react';
import { Button, Grid, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballBase } from '../../wallet/WalletTypes';
import TimekeeperSlice, {
  IShopSnack,
  ISnackSelection,
} from '../Timekeeper/TimekeeperSlice';
import {
  TimekeeperInteractionAction,
  TimekeeperInteractionArgInput,
  TimekeeperInteractionType,
  useShopSnacksQuery,
  useTimekeeperSaveActionMutation,
} from '../schema';
import { doesSnackBoostStats, hasSnackPurchase } from './ShopHelpers';
import FurballImg from '../Furballs/FurballImg';
import TimekeeperMarketButton from '../Timekeeper/TimekeeperMarketButton';
import {
  getAllTimekeeperInteractions,
  useCurrentTimekeeperRequest,
} from '../Timekeeper/TimekeeperHooks';
import { getFoodItem } from '../Food';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { cdnRoot } from '../../theme';
import useCommonStyles from '../useCommonStyles';
import {
  IFurballWithReward,
  useEstimatedFurballRewards,
  useFurRemaining,
} from '../Timekeeper/RewardEstimator';
import SvgIconCheck from '../../assets/SvgIconCheck';
import SvgX from '../../assets/SvgX';
import { useFrameRate } from '../useAnimation';
import { useSaveInteraction } from '../../pages/Timekeeper/TimekeeperHelpers';
import { useFurComponent } from '../../utils/furComponent';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  header: {
    backgroundImage: `url(${cdnRoot}/svgs/snacks-bk.svg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  },
  marketButton: {
    minWidth: '220px !important',
  },
  removeButton: {
    backgroundColor: '#000 !important',
    color: 'white !important',
    // '&:hover': {
    //   backgroundColor: '#000 !important',
    //   color: theme.palette.secondary.main,
    // }
  },
}));

type IShopSnackPowerUpTable = ISnackSelection;

export const ShopSnackPowerUpTable: FunctionComponent<
  IShopSnackPowerUpTable
> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { log } = useFurComponent(ShopSnackPowerUpTable);
  const { furballs, stats, close } = props;
  const { data, loading } = useShopSnacksQuery();
  const tkState = useCurrentTimekeeperRequest();
  const sender = useWalletSelector((s) => s.address);
  const dispatch = useAppDispatch();
  const [hoverButton, setHoverButton] = React.useState('');
  const loadedSnacks = data?.shopSnacks ?? [];
  const snacks = loadedSnacks.filter((s) => doesSnackBoostStats(s, ...stats));
  const interactions = getAllTimekeeperInteractions(tkState.current);
  const furRemaining = useFurRemaining();
  const rewards = useEstimatedFurballRewards(furballs);
  const [save, saved] = useTimekeeperSaveActionMutation();
  const [saveInteraction, deleting, error] = useSaveInteraction();
  const [savingSnacks, setSavingSnacks] = React.useState<{
    [key: string]: boolean;
  }>({});

  useFrameRate(true);

  function onSaved(fb: IFurballBase, snack: IShopSnack) {
    log.info('snack saved', fb, snack);
    // onChanged();
  }

  function onSaving(fb: IFurballBase, saving: boolean): void {
    setSavingSnacks({ ...savingSnacks, [fb.tokenId]: saving });
  }

  async function addToAll(snack: IShopSnack) {
    // const furballIds =
    const furballIds = furballs.map((fb) => fb.tokenId);
    const interaction: TimekeeperInteractionArgInput = {
      expectedValue: snack.id,
      furballIds,
      gasEstimate: 0,
      action: TimekeeperInteractionAction.Append,
      interactionType: TimekeeperInteractionType.FeedSnack,
      sender: sender ?? '',
      count: null,
    };
    try {
      const d = await save({ variables: { interaction } });

      const req = d.data?.timekeeperSaveAction;
      if (!req) return;
      dispatch(TimekeeperSlice.actions.setCurrentRequests(req));

      for (let i = 0; i < furballs.length; i++) {
        onSaved(furballs[i], snack);
      }
    } catch (e) {
      log.error(e, 'snack save');
    }
  }

  function removeSnack(fb: IFurballBase, snack: IShopSnack) {
    const interaction = interactions.find(
      (i) =>
        i.interactionType === TimekeeperInteractionType.FeedSnack &&
        i.expectedValue === snack.id,
    );
    const fbIdx = interaction?.furballIds.indexOf(fb.tokenId) ?? -1;
    if (!interaction || fbIdx < 0) {
      log.warn('remove', fb, interaction);
      return;
    }

    // const fbids = [...interaction.furballIds];
    // fbids.splice(fbIdx, 1);
    void saveInteraction(
      interaction,
      [fb.tokenId],
      TimekeeperInteractionAction.Delete,
    );
  }

  let purchaseable = 0;
  function renderFurballRow(fbr: IFurballWithReward, i: number) {
    const fb = fbr.furball;
    const furballIds = [fb.tokenId];
    const units = fbr.reward.levelGain > 0 ? 'LVL' : 'EXP';
    const value =
      fbr.reward.levelGain > 0
        ? fbr.reward.levelGain
        : fbr.reward.experienceGain;

    return (
      <React.Fragment key={fb.tokenId}>
        {i !== 0 && (
          <Grid item xs={12}>
            <hr />
          </Grid>
        )}
        <Grid item xs={1}>
          <FurballImg tokenId={fb.tokenId} size={60} />
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'h5'}>{fb.name}</Typography>
          <Typography variant={'subtitle2'}>
            est. {value.toLocaleString()} {units}
          </Typography>
        </Grid>
        {snacks.map((snack) => {
          const hoverKey = `${fb.tokenId}_${snack.id}`;
          const hovering = hoverKey === hoverButton;
          const purchased = hasSnackPurchase(interactions, fb.tokenId, snack);
          const hasEnoughFur = snack.furCost <= furRemaining;
          const canPurchase = !purchased && hasEnoughFur;
          const iconStyle = { height: 12, marginRight: 4 };
          if (canPurchase) purchaseable++;

          return (
            <Grid item key={snack.id} xs={4} style={{ textAlign: 'center' }}>
              {canPurchase && (
                <TimekeeperMarketButton
                  title={snack.name}
                  className={clsx(classes.marketButton)}
                  marketRequest={{
                    interactionType: TimekeeperInteractionType.FeedSnack,
                    furballIds,
                    expectedValue: snack.id,
                  }}
                  stayOnPage={true}
                  onSaved={() => onSaved(fb, snack)}
                  onSaving={(saving) => onSaving(fb, saving)}
                  furballIds={furballIds}
                  fuelCost={tkState.round?.costs.snack1 ?? 20000}
                  disabled={savingSnacks[fb.tokenId] || saved.loading}
                  // onClick={() => onSelectedZone(otherZone.zoneNumber)}
                />
              )}
              {purchased && (
                <React.Fragment>
                  {deleting && (
                    <div style={{ width: 220 }}>
                      <LinearProgress color={'secondary'} />
                    </div>
                  )}
                  {!deleting && (
                    <Button
                      variant={'contained'}
                      color={'secondary'}
                      className={clsx(
                        classes.removeButton,
                        classes.marketButton,
                      )}
                      onMouseEnter={() => setHoverButton(hoverKey)}
                      onMouseLeave={() => setHoverButton('')}
                      onClick={() => removeSnack(fb, snack)}
                      disabled={
                        deleting || savingSnacks[fb.tokenId] || saved.loading
                      }>
                      <Typography variant={'h6'}>
                        {hovering ? (
                          <SvgX style={iconStyle} fill={'#FF0000'} />
                        ) : (
                          <SvgIconCheck style={iconStyle} fill={'#FFFFFF'} />
                        )}
                        {hovering
                          ? `Remove ${snack.name}?`
                          : `${snack.name} Added!`}
                      </Typography>
                    </Button>
                  )}
                </React.Fragment>
              )}
              {error && (
                <React.Fragment>
                  <Typography variant={'h5'}>${error.message}</Typography>
                </React.Fragment>
              )}
              {saved.error && (
                <React.Fragment>
                  <Typography variant={'h5'}>${saved.error.message}</Typography>
                </React.Fragment>
              )}
              {!hasEnoughFur && (
                <React.Fragment>
                  <Typography variant={'h5'}>Not Enough $FUR</Typography>
                </React.Fragment>
              )}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  const rows = rewards.furballsWithRewards.map(renderFurballRow);

  React.useEffect(() => {
    if (loadedSnacks.length > 0 && purchaseable <= 0) {
      log.info('nothing purchaseable', snacks);
      dispatch(TimekeeperSlice.actions.setSnackSelection(undefined));
      close();
    }
  }, [loadedSnacks, purchaseable]);

  return (
    <React.Fragment>
      <Grid
        container
        spacing={1}
        className={clsx(classes.root, classes.header)}>
        {snacks.map((snack) => {
          const food = getFoodItem(snack.id);
          return (
            <React.Fragment key={snack.id}>
              <Grid item xs={1} style={{ textAlign: 'center' }}>
                {food.icon}
              </Grid>
              <Grid item key={snack.id} xs={3} style={{ textAlign: 'left' }}>
                <Typography variant={'h5'}>{food.name}</Typography>
                <Typography variant={'subtitle2'}>
                  +{food.snack.happiness.toLocaleString()}% EXP for{' '}
                  {food.snack.furCost.toLocaleString()} $FUR
                </Typography>
                {furballs.length > 1 && (
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    className={common.secondaryButton}
                    style={{ marginTop: 10, marginBottom: 20 }}
                    onClick={() => void addToAll(snack)}>
                    Feed to All Furballs
                  </Button>
                )}
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid container spacing={1} className={clsx(classes.root)}>
        {rows}
      </Grid>
    </React.Fragment>
  );
};

export default ShopSnackPowerUpTable;
