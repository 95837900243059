import React, { FunctionComponent } from 'react';
import { Button, Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { cdnRoot, darkBlue } from '../../theme';
import useCommonStyles from '../useCommonStyles';
import FurballWidget from '../../pages/Embed/FurballWidget';
import { usePlayersReadyFurballs } from '../../wallet';
import VoyageGameRealmSpinner from './Realms/VoyageGameRealmSpinner';
import { RealmType, useSubmitVoyageEncounterMutation } from '../schema';
import { getLootItem, getLootSlot, ILootSlot, LootSlot } from '../Loot';
import RealmCreatureDialog from './Realms/RealmCreatureDialog';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletContext from '../../wallet/WalletContext';
import TimekeeperSlice from '../Timekeeper/TimekeeperSlice';
import RealmCreature from './Realms/RealmCreature';
import { REPLAY_SPIN_GAME_FUR_COST } from './ZoneConstants';
import WalletSlice from '../../wallet/WalletSlice';
import { IFurball, IVoyage } from '../../wallet/WalletTypes';
import { usePageTitle } from '../../Analytics';
import { getRealmName } from './ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'white',
    overflow: 'hidden',
    position: 'absolute',
  },
  widgetWrap: {
    position: 'relative',
    width: 240,
    height: 280,
    overflow: 'hidden',
    background: '#000',
  },
  bottomBar: {
    position: 'absolute',
    bottom: -80,
    left: '50%',
    width: 800,
    maxWidth: '100%',
  },
  menuBar: {
    backgroundColor: '#7259E9',
    borderRadius: '8px !important',
    borderColor: darkBlue,
    borderWidth: 8,
    marginLeft: 'calc(-50%)',
    overflowY: 'hidden',
  },
  menuArea: {
    paddingTop: theme.spacing(4),
    cursor: 'pointer',
    userSelect: 'none',
  },
  tapVerb: {
    color: '#efefef',
  },
  practiceButton: {
    marginTop: theme.spacing(4),
  },
  signButton: {
    width: '100%',
    marginTop: `${theme.spacing(1)} !important`,
    marginBottom: `${theme.spacing(1)} !important`,
  },
  spinContainer: {
    position: 'relative',
    left: '-50%',
    // backgroundColor: 'transparent !important',
    // position: 'absolute',
    // bottom: 10,
    // left: 20,
    // width: '150%',
    // maxHeight: 'calc(100vh)'
    // width: 400,
    // height: 800,
  },
  dialogContent: {
    // minWidth: 400,
  },
}));

interface IVoyageSpinsGameParams {
  tokenId: string;
  realm: string;
}

interface IVoyageSpinsGame {}

function getRealmType(realm: string): RealmType {
  if (realm.startsWith('wizard')) return RealmType.WizardsPath;
  if (realm.startsWith('turtle')) return RealmType.TurtleSea;
  return RealmType.Boneyard;
}

export const VoyageSpinsGame: FunctionComponent<IVoyageSpinsGame> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { realm, tokenId } = useParams<IVoyageSpinsGameParams>();
  const realmType = getRealmType(realm);
  const furballs = usePlayersReadyFurballs();
  const [submitEncounter, { error, loading, data }] =
    useSubmitVoyageEncounterMutation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const context = React.useContext(WalletContext);
  const walletAddress = useWalletSelector((w) => w.address);
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const furball = furballs.find((fb) => fb.tokenId === tokenId) as IFurball;
  const backgroundImage = `url(${cdnRoot}/svgs/realm-game-${realm.replaceAll(
    '-',
    '_',
  )}.svg)`;
  const [curLootItem, setCurLootItem] = React.useState<ILootSlot | undefined>();
  const [completed, setCompleted] = React.useState(false);
  const [resetOrExit, setResetOrExit] = React.useState(false);
  const lastEncounter = furball?.activeVoyage?.lastEncounter;
  const isDisabled = loading;
  const canReplay = wFurBalance >= REPLAY_SPIN_GAME_FUR_COST;

  usePageTitle('Spin ' + getRealmName(realmType) + ' | Voyage');

  React.useEffect(() => {
    const itemId = lastEncounter?.decision;
    if (data || !itemId) return;

    const item = getLootSlot(getLootItem(itemId));
    setCurLootItem(item);
    setCompleted(true);
    setResetOrExit(true);
  }, [lastEncounter]);

  function scoreGame() {
    setCompleted(true);
    setTimeout(() => setResetOrExit(true), 1000);
    void saveResponse(false);
  }

  function resetGame() {
    setResetOrExit(false);
    setCompleted(false);
    dispatch(
      WalletSlice.actions.setWFurBalance(
        wFurBalance - REPLAY_SPIN_GAME_FUR_COST,
      ),
    );
  }

  function handleSpinState(hash?: string, lootItem?: ILootSlot) {
    setCurLootItem(lootItem);
  }

  async function saveResponse(sign: boolean) {
    if (!walletAddress || !context) return;

    try {
      const decision = curLootItem?.item.lootId ?? 0;
      const encounter = await submitEncounter({
        variables: { complete: sign, tokenId, decision },
      });

      const voyageEncounter = encounter?.data?.submitVoyageEncounter;
      if (voyageEncounter) {
        dispatch(
          TimekeeperSlice.actions.updateActiveEncounter(voyageEncounter),
        );

        dispatch(
          WalletSlice.actions.setFurball({
            ...furball,
            lastVoyage: {
              ...(furball.lastVoyage as IVoyage),
              activeEncounter: voyageEncounter,
            },
          }),
        );
      }

      if (sign) history.push('/play');
    } catch (e) {
      console.error(e, 'Encounter');
    }
  }

  return (
    <div
      className={clsx(common.fullScreen, classes.root)}
      style={{ backgroundImage }}>
      <div className={classes.bottomBar}>
        <div className={classes.spinContainer}>
          <VoyageGameRealmSpinner
            realmType={realmType}
            onSpinState={handleSpinState}
            paused={completed}
          />
        </div>
        <Grid container className={classes.menuBar}>
          <Grid item xs={6}>
            <div className={classes.widgetWrap}>
              <FurballWidget
                size={230}
                tokenId={tokenId}
                furball={furball}
                disableUnity={true}
              />
            </div>
          </Grid>
          {!completed && (
            <Grid item xs={6} className={classes.menuArea} onClick={scoreGame}>
              <Typography variant={'h1'}>Win Loot!</Typography>
              <Typography variant={'h4'}>
                Tap <span className={classes.tapVerb}>Here</span> to Stop the
                Light
              </Typography>
              <Typography variant={'subtitle2'}>
                Stop the light on the loot you wish to win!
              </Typography>
            </Grid>
          )}
          {completed && curLootItem && (
            <Grid
              item
              xs={6}
              className={classes.menuArea}
              onClick={() => setResetOrExit(true)}>
              <Typography variant={'h1'}>You Win!</Typography>
              <Typography variant={'h4'}>
                {curLootItem.item.name} ({curLootItem.rarityName}) = +
                {curLootItem.boost}% {curLootItem.item.stat?.toUpperCase()}
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
      <RealmCreatureDialog
        realmType={realmType}
        title={<React.Fragment>Sign Score or Try Again?</React.Fragment>}
        open={resetOrExit}
        creature={
          <React.Fragment>
            <RealmCreature realmType={realmType} />
          </React.Fragment>
        }
        onClose={() => setResetOrExit(false)}>
        <div className={classes.dialogContent}>
          <Grid container style={{ marginBottom: 8 }}>
            <Grid item xs={12} lg={2}>
              {curLootItem && <LootSlot {...curLootItem} />}
            </Grid>
            <Grid item xs={12} lg={10}>
              <Typography variant={'h5'}>You may keep playing...</Typography>
              <Typography variant={'h6'}>
                When you're done, end the game.
              </Typography>
              <Typography variant={'subtitle2'}>
                The loot will be added to your Voyage rewards.
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant={'contained'}
            color={'primary'}
            className={clsx(common.actionButton, classes.signButton)}
            onClick={() => void saveResponse(true)}
            disabled={isDisabled}>
            <Container>
              <Typography variant={'h5'}>End Game</Typography>
            </Container>
          </Button>
          <Button
            variant={'contained'}
            color={'secondary'}
            className={clsx(common.secondaryButton, classes.signButton)}
            onClick={resetGame}
            disabled={isDisabled || !canReplay}>
            <Container>
              <Typography variant={'h5'}>Re-Try?</Typography>
              <Typography variant={'h6'}>
                {REPLAY_SPIN_GAME_FUR_COST.toLocaleString()} $wFUR
              </Typography>
            </Container>
          </Button>
          <Typography variant={'subtitle1'}>
            $wFUR Balance: {wFurBalance.toLocaleString()}
          </Typography>
          <br />
          {error && (
            <Typography variant={'subtitle2'}>{error.message}</Typography>
          )}
        </div>
      </RealmCreatureDialog>
    </div>
  );
};

export default VoyageSpinsGame;
