import React, { FunctionComponent } from 'react';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import { black, headerFont, yellow } from '../../themev2';
import SvgIconCaretRight from '../../assets/SvgIconCaretRight';
import { makeStyles } from '@mui/styles';
import { mint } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily: `${headerFont} !important`,
    width: '17rem',
    padding: '0.8rem 0 0.8rem 0.6rem !important',
    backgroundColor: (props: any) =>
      `${props.collect ? theme.palette.primary.main : yellow} !important`,
    border: '2px solid #000 !important',
    boxShadow: (props: any) =>
      `inset 0 -5px 0 2px ${props.collect ? '#86EFAC' : '#00000022'}`,
    borderRadius: '50px !important',
    transition: 'all 0.2s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      backgroundColor: `${mint.light} !important`,
      // boxShadow: 'none',
    },
  },
  buttonText: {
    fontSize: '1.7rem !important',
  },
  icon: {
    marginLeft: '1.1rem',
  },
}));

interface IZoneButton {
  onClick: () => void;
  text: string;
  width?: string;
  collect?: boolean;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties;
}

const ZoneButton: FunctionComponent<IZoneButton> = (props) => {
  const { onClick, text, width, collect, disabled, loading, style } = props;
  const classes = useStyles({ collect });
  const isDisabled = disabled || loading;

  function renderButtonText() {
    if (loading) {
      return <CircularProgress color='inherit' size={30} />;
    }

    return (
      <>
        <Typography
          role='textbox'
          variant='h4'
          className={classes.buttonText}
          fontWeight={800}
          color={black}
          lineHeight={1}>
          {text}
        </Typography>
        <SvgIconCaretRight
          width={'1.3rem'}
          height={'1.3rem'}
          fill={black}
          className={classes.icon}
        />
      </>
    );
  }

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      style={{ width, opacity: isDisabled ? 0.6 : 1, ...(props.style ?? {}) }}
      disabled={isDisabled}>
      {renderButtonText()}
    </Button>
  );
};

export default ZoneButton;
