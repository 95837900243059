import { SvgEditionSlot } from '../part';
import * as React from 'react';

const DalmatianPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M241.91 204.04c-55.63 29.76-98.12-3.98-104.4-10.36l-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.73-34.08 3.93-38.26z'
    />
    <path
      fill='#3c3c42'
      d='M66.67 257c5.79 0 5.8-9 0-9s-5.8 9 0 9zM231.51 239.15c9.65 0 9.67-15 0-15-9.65 0-9.66 15 0 15z'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill='#3c3c42'
      d='M100.26 224.67c0 3.18-2.89 7.62-6.74 7.62s-11.25-4.99-11.25-8.18c0-3.18 9.05-8 12.89-8s5.1 5.37 5.1 8.56zM189.43 245.76c-.13 1.74.73 3.36 1.97 4.52 1.37 1.27 2.62 2.7 3.4 4.39.08.17.17.34.27.51 1.95 3.31 6.96 3.93 9.7 2.31 2.74-1.62 4.87-6.75 2.92-10.06-.68-1.16-1.77-1.94-3.01-2.39-1.78-.66-3.42-1.67-4.84-2.92-1.17-1.03-2.65-1.7-3.94-1.79-2.68-.21-6.23 2.2-6.47 5.43zM74.74 229.15c2.73.01 4.89 6.96 4.87 10.26-.02 3.3-3.84 6.15-6.58 6.14s-6.16-2.67-6.14-5.97c.02-3.3 5.12-10.44 7.85-10.43zM123.02 215.46c-1.83 2.6-6.75 4.57-9.9 2.36-3.14-2.21-3.32-7.76-1.49-10.37s6.64-4.11 9.79-1.9c3.15 2.22 3.43 7.31 1.6 9.91zM103.54 278.11c-2.1 2.98-9.55 5.42-13.27 2.8-3.72-2.62-2.31-9.3-.22-12.28 2.1-2.98 7.71-4.64 11.43-2.02s4.16 8.52 2.06 11.5zM158.37 254.62c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7z'
    />
    <path
      fill='#3c3c42'
      d='M158.37 254.62c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7zM223.55 260.95c8.36 0 8.38-13 0-13-8.37 0-8.38 13 0 13zM208.01 239.62c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7zM159.26 241.05c2.09 0 4.1-1.84 4-4-.1-2.17-1.76-4-4-4-2.09 0-4.1 1.84-4 4 .1 2.17 1.76 4 4 4zM131.43 231.34c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4zM62.21 272.03c4.5 0 4.51-7 0-7s-4.52 7 0 7zM80.97 277.69c6.43 0 6.45-10 0-10-6.43 0-6.44 10 0 10zM86.62 293.5c6.43 0 6.45-10 0-10-6.44 0-6.45 10 0 10zM88.97 262.7c3.86 0 3.87-6 0-6-3.86 0-3.86 6 0 6zM123.13 244.19c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7zM145.72 268.9c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM175.67 228.2c-8.37 0-8.54 11.12-.16 11.12 8.36 0 8.54-11.12.16-11.12z'
    />
    <path
      fill='#3c3c42'
      d='M169.25 233.14c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM205.58 264.01c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM140.08 221.98c2.57 0 2.58-4 0-4s-2.58 4 0 4zM122.38 252.1c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM201.16 288.97c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8zM226.66 264.64c5.15 0 5.16-8 0-8-5.14 0-5.15 8 0 8zM183.37 274.81c3.22 0 3.22-5 0-5s-3.22 5 0 5z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill='#3c3c42'
      d='M94.9 249.7c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM112.22 222.97c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM86.8 282.27c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM128.4 266.83c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM140.26 244.43c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM65.06 279.16c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM187.6 255.73c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM141.96 219.4c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM171.7 272.29c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9z'
      opacity={0.52}
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='#3c3c42'
      d='M166.9 282.11c3.86 0 3.87-6 0-6-3.86 0-3.87 6 0 6z'
    />
    <path
      fill='#3c3c42'
      d='M163.79 279.2c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM170.1 289.77c3.22 0 3.22-5 0-5s-3.22 5 0 5zM132.27 218.24c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM86.67 237.71c5.79 0 5.8-9 0-9s-5.8 9 0 9zM105.3 225c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM115.89 231.92c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM72.27 285.47c2.57 0 2.58-4 0-4s-2.58 4 0 4zM77.91 253.52c3.86 0 3.87-6 0-6-3.86 0-3.86 6 0 6zM149.63 233.62c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM131.28 247.6c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11z'
    />
    <path
      fill='#3c3c42'
      d='M136.64 253.53c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11z'
    />
    <path
      fill='#3c3c42'
      d='M144.97 250.28c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM115.83 286.99c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11z'
    />
    <path
      fill='#3c3c42'
      d='M124.16 283.74c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM107.09 243.6c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11z'
    />
    <path
      fill='#3c3c42'
      d='M113.07 247.72c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM71.14 265.75c7.72 0 7.73-12 0-12-7.72 0-7.74 12 0 12zM89.21 249.57c5.79 0 5.8-9 0-9-5.79 0-5.8 9 0 9zM137.07 280.61c4.5 0 4.51-7 0-7s-4.52 7 0 7zM123.72 264.67c4.5 0 4.51-7 0-7s-4.51 7 0 7zM146.67 286.55c5.79 0 5.8-9 0-9s-5.8 9 0 9zM193.82 234.95c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8z'
    />
    <path
      fill='#3c3c42'
      d='M199.04 229.87c5.15 0 5.16-8 0-8-5.15 0-5.15 8 0 8zM222.2 238.57c7.08 0 7.09-11 0-11-7.08 0-7.09 11 0 11zM211.89 275.39c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7zM225.44 246.01c3.22 0 3.22-5 0-5s-3.22 5 0 5zM215.7 247.41c1.93 0 1.93-3 0-3s-1.93 3 0 3zM187.32 228.64c1.93 0 1.93-3 0-3s-1.93 3 0 3zM173.63 256.75c4.5 0 4.51-7 0-7-4.5 0-4.51 7 0 7z'
    />
    <path
      fill='#3c3c42'
      d='M172.5 267.16c7.72 0 7.73-12 0-12-7.72 0-7.73 12 0 12z'
    />
  </svg>
);

export default DalmatianPower;
