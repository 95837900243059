import React, { FunctionComponent } from 'react';
import { Theme, Fab, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SvgIconSync from '../../assets/SvgIconSync';
import { useMeLazyQuery } from '../../components/schema';
import { useAppDispatch, useWalletSelector } from '../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface ISyncAccountButton {
  showTitle?: boolean;
  className?: string;
}

export const SyncAccountButton: FunctionComponent<ISyncAccountButton> = (
  props,
) => {
  const { className, showTitle } = props;
  const [loadAccount, account] = useMeLazyQuery();
  const dispatch = useAppDispatch();
  const walletAddress = useWalletSelector((s) => s.address ?? s.contents?.id);
  const isDisabled =
    !walletAddress || !walletAddress.startsWith('0x') || account.loading;
  const classes = useStyles();

  React.useEffect(() => {
    if (account.loading) return;
    if (account.error || !account.data) {
      if (account.error) console.error('ACCOUNT', account.error, account);
      return;
    }

    window.location.reload();
  }, [account]);

  async function doSync(wallet: string) {
    // maxCache=0 means always sync (force refresh)
    await loadAccount({ variables: { maxCache: 0 } });
  }

  function onClick() {
    if (!walletAddress) return;
    void doSync(walletAddress);
  }

  return (
    <Fab
      color={'primary'}
      className={clsx(className)}
      onClick={onClick}
      disabled={isDisabled}>
      {!account.loading && <SvgIconSync style={{ height: 24, width: 24 }} />}
      {account.loading && (
        <CircularProgress size={24} style={{ height: 24, width: 24 }} />
      )}
      {showTitle && <Typography variant={'h6'}>SYNC ACCOUNT</Typography>}
    </Fab>
  );
};

export default SyncAccountButton;
