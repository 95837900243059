import * as React from 'react';

function SvgSnackMeatLeg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' {...props}>
      <path
        fill='#FFF'
        d='M153.45 137.86c5.67 1.94 12.13-.17 15.48-5.46 3.91-6.18 2.06-14.36-4.12-18.27s-14.36-2.06-18.27 4.12c-.29.46-.54.93-.77 1.4l-21.33-13.48-12.06 19.08 21.33 13.48c-.33.41-.65.84-.94 1.3-3.91 6.18-2.06 14.36 4.12 18.27s14.36 2.06 18.27-4.12c3.34-5.29 2.47-12.03-1.71-16.32z'
      />
      <path
        fill='#F4EBE3'
        d='M153.45 137.86c5.67 1.94 12.13-.17 15.48-5.46 1.26-1.99 1.91-4.19 2.03-6.39-.29-.22-.59-.43-.9-.62-6.93-4.38-14.55-4.34-18.93 2.59 0 .01-.01.01-.01.02a4.34 4.34 0 01-5.98 1.31l-20.31-12.84-7.51 11.89 16.41 10.37c-.33.41-.65.84-.94 1.3-2.82 4.47-2.63 9.97.02 14.16.88.02 1.45.09 1.36.22-.19.31-.37.62-.54.94 1.4 1.76 3.25 3.22 5.61 4.12 5.47 2.08 11.79.3 15.31-4.37 4.04-5.41 3.33-12.7-1.1-17.24z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M153.45 137.86c5.67 1.94 12.13-.17 15.48-5.46 3.91-6.18 2.06-14.36-4.12-18.27s-14.36-2.06-18.27 4.12c-.29.46-.54.93-.77 1.4l-21.33-13.48-12.06 19.08 21.33 13.48c-.33.41-.65.84-.94 1.3-3.91 6.18-2.06 14.36 4.12 18.27s14.36 2.06 18.27-4.12c3.34-5.29 2.47-12.03-1.71-16.32z'
      />
      <path
        fill='#FFC093'
        d='M127.37 121.94c-13.04 20.63-63.74 21.07-90.95-3.93-9.22-8.46-10.8-31.52 2.24-52.15s32.86-27.55 44.94-24.3c39.92 10.76 56.81 59.75 43.77 80.38z'
      />
      <path
        opacity={0.45}
        fill='#FFC093'
        d='M131.13 111.52c-1.99 5.61-4.9 10.31-8.65 13.52-18.55 15.86-67.11 1.3-85.74-30.62-3.71-6.36-3.37-16.78 1.3-27.51-12.34 20.36-10.68 42.78-1.62 51.1 27.22 25 77.92 24.56 90.95 3.93 1.87-2.95 3.1-6.5 3.76-10.42z'
      />
      <path
        fill='#FFD1B1'
        d='M83.59 41.57c-3.42-.92-7.46-1.02-11.79-.23 39.65 10.97 56.39 59.7 43.39 80.26-5.81 9.19-19.1 14.37-34.46 15.08 20.2 2.04 39.33-3.18 46.63-14.74 13.05-20.63-3.84-69.62-43.77-80.37z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M127.37 121.94c-13.04 20.63-65.2 20.14-92.42-4.86-9.22-8.46-7.5-29.43 5.54-50.06s29.55-29.64 41.64-26.39c39.92 10.76 58.28 60.68 45.24 81.31z'
      />
      <g>
        <ellipse
          transform='rotate(-57.704 60.107 78.858)'
          fill='#FFF'
          cx={60.11}
          cy={78.86}
          rx={43.05}
          ry={23.77}
        />
        <path
          fill='#F4EBE3'
          d='M88.75 49.35c.05 9.37-3.48 20.94-10.44 31.94-12.7 20.1-32 30.7-43.1 23.68-2.51-1.59-4.39-3.95-5.64-6.88.04 7.74 2.51 13.97 7.53 17.15 11.1 7.02 30.39-3.59 43.1-23.68 9.83-15.54 12.82-32.21 8.55-42.21z'
        />
        <ellipse
          transform='rotate(-57.704 58.9 78.095)'
          fill='#FFF'
          cx={58.9}
          cy={78.09}
          rx={10.24}
          ry={5.66}
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M80.2 91.56c-12.7 20.1-32.93 32.17-44.02 25.15s-8.87-30.46 3.83-50.56 32.46-31.43 43.56-24.42 9.34 29.73-3.37 49.83z'
        />
        <ellipse
          transform='rotate(-57.704 58.891 79)'
          fill='#FFC093'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          cx={58.89}
          cy={79}
          rx={15.56}
          ry={7.13}
        />
      </g>
    </svg>
  );
}

export default SvgSnackMeatLeg;
