import React, { FunctionComponent, useCallback, useState } from 'react';
import { ISelectedFurball, ZoneCard } from '../../components/Zones';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import PlayButton from './PlayButton';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip, Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import TransactionErrorDialog from '../../wallet/TransactionErrorDialog';
import { useWalletSelector } from '../../hooks';
import {
  getZone,
  ZONE_NUM_BOSS_FIGHTS,
} from '../../components/Zones/ZoneHelpers';
import ZoneButton from '../../components/Zones/ZoneButton';
import SvgZoneIconsFurballs from '../../assets/SvgZoneIconsFurballs';
import { usePlayersReadyFurballs, usePlayGamePath } from '../../wallet';
import { useFurComponent } from '../../utils/furComponent';
import { playGameVerb } from '../Furverse/Lobby/BossGamePrepare';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    minWidth: '100%',
    minHeight: '105px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: 10,
  },
}));

interface IBossFights {}

const BossFights: FunctionComponent<IBossFights> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const playGamePath = usePlayGamePath();

  const zoneNumber = ZONE_NUM_BOSS_FIGHTS;
  const zone = getZone(zoneNumber);

  const [mode, setMode] = React.useState('0');
  const [error, setError] = React.useState<ITransactionError | undefined>();
  const [selectedFurballs, setSelectedFurballs] = useState<ISelectedFurball[]>(
    [],
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const zoning = useWalletSelector((s) => Object.keys(s.pending));
  const allFurballs = usePlayersReadyFurballs();
  const isLoading = zoning.length > 0 || loading;
  const { log } = useFurComponent(BossFights);

  function onState(s: TransactionState, error?: ITransactionError) {
    log.info('Zone');
  }

  const tabs = {
    '0': 'Furballs',
    // '1': 'Leaderboards',
  };

  const tabsIcons = {
    '0': <SvgZoneIconsFurballs />,
    // '1': <SvgZoneIconsLeaderboards />,
  };

  const getActionBtnState = useCallback((): 'show' | 'disabled' => {
    const selectedRentalFurballs =
      selectedFurballs.filter((item) => item.isRented) || [];
    const hasRentedFurballSelected = selectedRentalFurballs.length > 0;

    return hasRentedFurballSelected ? 'disabled' : 'show';
  }, [selectedFurballs]);

  return (
    <ZoneCard
      title={zone.title}
      zoneNumber={zoneNumber}
      disabled={isLoading}
      loading={loading}
      selectedFurballs={selectedFurballs}
      setSelectedFurballs={setSelectedFurballs}
      tabs={tabs}
      tabsIcons={tabsIcons}
      selectedTab={mode}
      setSelectedTab={setMode}
      background={'boss_fights'}
      noBackground={mode !== '0'}
      footer={
        <div className={classes.footer}>
          <ZoneButton
            onClick={() => history.push(playGamePath)}
            text={playGameVerb}
          />
        </div>
      }
      isFurballInTab={(fb, tab) => tab === '0'}
      action={
        <Tooltip
          PopperProps={{
            hidden: getActionBtnState() !== 'disabled',
          }}
          placement='bottom-end'
          title={"Can't move rented furballs out of boss-fight zone"}>
          <div>
            <PlayButton
              furballIds={selectedFurballs.map((item) => item.id)}
              setSelectedFurballs={setSelectedFurballs}
              setState={onState}
              zoneNumber={zoneNumber}
              disabled={getActionBtnState() === 'disabled'}
              isLoading={isLoading}
              timekeeperDisabled={false}
            />
          </div>
        </Tooltip>
      }
      emptyState={
        <Typography variant={'h5'}>
          {allFurballs.length > 0
            ? 'Move Furballs into Boss Fights, or '
            : "You'll need to Buy or "}
          <Link to={'/market/furballs'}>Borrow Furballs</Link>!
        </Typography>
      }>
      <TransactionErrorDialog
        error={error}
        onClose={() => setError(undefined)}
      />
      {/*{mode === '1' ? <BossLeaderboards /> : null}*/}
    </ZoneCard>
  );
};

export default BossFights;
