import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as animation from '../../../animations/bk-snow.json';
import Lottie from 'react-lottie';
import { IFurballBase } from '../../../wallet/WalletTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IHolidaySnowBackground {
  furball: IFurballBase;
}

export const HolidaySnowBackground: FunctionComponent<
  IHolidaySnowBackground
> = (props) => {
  const { furball } = props;
  const classes = useStyles();
  const lottieRef = React.useRef<Lottie>(null);

  const hasSnowballs = furball.inventory.items.find(
    (i) => i.itemId === 0x10200,
  );
  const month = new Date().getMonth();
  const isHoliday = month === 11 || month === 0;
  if (!isHoliday || !hasSnowballs) return null;

  const animationData = JSON.parse(JSON.stringify(animation)).default;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={defaultOptions}
      ref={lottieRef}
      // eventListeners={[
      //   { eventName: 'complete', callback: onLottieCompleted }
      // ]}
    />
  );
};

export default HolidaySnowBackground;
