import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Dinosaur = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m82 160 4 11-12-2M72 172l8 8-13 3M66 186l9 6-8 6M66 202h10l-5 10M73 215l11-3-3 11'
    />
    <path
      fill={props.renderer.color2}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M93.7 153.65c-19.9 10.06-42.18 44.48-15.45 67.92 0 0 12.39 13.41 21.07 12.76l-23.94 56.08S26.98 246.7 41.2 197.9c10.53-36.16 35.47-43.8 51.16-47.38 1.87-.43 3.05 2.26 1.34 3.13z'
    />
    <g opacity={0.7} fill={props.renderer.color1}>
      <circle
        transform='rotate(-11.45 59.956 211.803)'
        cx={59}
        cy={211}
        r={6}
      />
      <circle transform='rotate(-11.45 54.5 197.223)' cx={54} cy={197} r={6} />
      <circle transform='rotate(-11.45 57.859 238.64)' cx={57} cy={238} r={6} />
      <circle
        transform='rotate(-11.45 51.967 223.996)'
        cx={51}
        cy={223}
        r={6}
      />
      <circle
        transform='rotate(-11.45 66.347 226.074)'
        cx={66}
        cy={226}
        r={6}
      />
    </g>
  </svg>
);

export default Dinosaur;
