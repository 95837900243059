import * as React from 'react';

function SvgLootSnowballs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='loot-snowballs_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 200 153.66'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.loot-snowballs_svg__st0{fill:#eefdfa}.loot-snowballs_svg__st1{fill:#d0f3ff}.loot-snowballs_svg__st2{fill:none;stroke:#000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}'
        }
      </style>
      <path
        className='loot-snowballs_svg__st0'
        d='M122.39 22.58s-17.23-24.06-38.28-5.22-6.74 42.65 5.55 45.9 40.06 3.79 40-17.01c-.04-15.08-7.27-23.67-7.27-23.67z'
      />
      <path
        className='loot-snowballs_svg__st1'
        d='M129.66 46.24c-.04-15.07-7.27-23.66-7.27-23.66s-2-2.8-5.48-5.74c1.57 3.94 2.98 9.31 3 15.95.06 20.81-27.72 20.27-40 17.01-1.72-.45-3.47-1.32-5.16-2.51 2.72 8.16 9.04 14.41 14.91 15.96 12.28 3.26 40.06 3.8 40-17.01z'
      />
      <path
        className='loot-snowballs_svg__st1'
        d='M88.42 44.95c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8zM95.19 48.23c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4zM103.25 44.77c1.29 0 1.29-2 0-2s-1.29 2 0 2z'
      />
      <path
        className='loot-snowballs_svg__st2'
        d='M122.39 22.58s-17.23-24.06-38.28-5.22-6.74 42.65 5.55 45.9 40.06 3.79 40-17.01c-.04-15.08-7.27-23.67-7.27-23.67z'
      />
      <g>
        <path
          className='loot-snowballs_svg__st0'
          d='M89.95 53.85S70.52 39.33 54.36 62.4s-.82 30.8 6.45 36.75c1.79 1.46 17.36 10.13 29.59 4.72 12.23-5.41 24.64-34.13-.45-50.02z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M94.07 56.85c.16.63.26 1.11.29 1.39 1.24 10.87 7.75 29.78-24.47 35.9-5.92 1.12-10.89 1.09-15.08.3 2.1 1.79 4.28 3.3 6 4.71 1.79 1.46 17.36 10.13 29.59 4.72 11.49-5.09 23.15-30.76 3.67-47.02zM60.97 80.69c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M68.41 86.66c1.93 0 1.93-3 0-3s-1.93 3 0 3zM75.23 81.33c3.22 0 3.22-5 0-5s-3.22 5 0 5z'
        />
        <path
          className='loot-snowballs_svg__st2'
          d='M89.95 53.85S70.52 39.33 54.36 62.4s-.82 30.8 6.45 36.75c1.79 1.46 17.36 10.13 29.59 4.72 12.23-5.41 24.64-34.13-.45-50.02z'
        />
      </g>
      <g>
        <path
          className='loot-snowballs_svg__st0'
          d='M140.81 53.85s-19.43-14.52-35.59 8.55-.82 30.8 6.45 36.75c1.79 1.46 17.36 10.13 29.59 4.72 12.23-5.41 24.65-34.13-.45-50.02z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M144.93 56.85c.16.63.26 1.11.29 1.39 1.24 10.87 7.75 29.78-24.47 35.9-5.92 1.12-10.89 1.09-15.08.3 2.1 1.79 4.28 3.3 6 4.71 1.79 1.46 17.36 10.13 29.59 4.72 11.49-5.09 23.15-30.76 3.67-47.02zM111.83 80.69c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M119.28 86.66c1.93 0 1.93-3 0-3s-1.94 3 0 3zM126.09 81.33c3.22 0 3.22-5 0-5s-3.22 5 0 5z'
        />
        <path
          className='loot-snowballs_svg__st2'
          d='M140.81 53.85s-19.43-14.52-35.59 8.55-.82 30.8 6.45 36.75c1.79 1.46 17.36 10.13 29.59 4.72 12.23-5.41 24.65-34.13-.45-50.02z'
        />
      </g>
      <g>
        <path
          className='loot-snowballs_svg__st0'
          d='M123.02 111.98c-2.69 11.59 3.33 23.45 13.85 27.6 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57s8.04-30.79-21.57-37.06c-19.38-4.09-30.13 8.58-33.34 22.4z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M176.38 101.16c0 5.02-1.24 9.6-2.14 12.45-2.58 8.2-10.57 18.61-26.93 16.57-5.4-.67-10.16-2.07-14.13-3.63-4.55-1.8-8.25-5.04-10.78-9.07-.04 9.66 5.61 18.62 14.47 22.12 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57 1.66-5.28 4.49-16.49-1.55-25.5z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M142.88 118.36c3.86 0 3.87-6 0-6-3.86 0-3.86 6 0 6zM144.01 125.84c1.93 0 1.93-3 0-3s-1.93 3 0 3zM131.1 122.4c6.43 0 6.45-10 0-10-6.44 0-6.45 10 0 10z'
        />
        <path
          className='loot-snowballs_svg__st2'
          d='M123.02 111.98c-2.69 11.59 3.33 23.45 13.85 27.6 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57s8.04-30.79-21.57-37.06c-19.38-4.09-30.13 8.58-33.34 22.4z'
        />
      </g>
      <g>
        <path
          className='loot-snowballs_svg__st0'
          d='M122.41 101.13s-17.23-24.06-38.28-5.22-6.74 42.65 5.55 45.9 40.06 3.79 40-17.01c-.04-15.08-7.27-23.67-7.27-23.67z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M129.68 124.8c-.04-15.07-7.27-23.66-7.27-23.66s-2-2.8-5.48-5.74c1.57 3.94 2.98 9.31 3 15.95.06 20.81-27.72 20.27-40 17.01-1.72-.45-3.47-1.32-5.16-2.51 2.72 8.16 9.04 14.41 14.91 15.96 12.28 3.25 40.06 3.79 40-17.01z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M88.44 123.51c5.15 0 5.16-8 0-8-5.15 0-5.16 8 0 8zM95.21 126.79c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM103.27 123.33c1.29 0 1.29-2 0-2s-1.29 2 0 2z'
        />
        <path
          className='loot-snowballs_svg__st2'
          d='M122.41 101.13s-17.23-24.06-38.28-5.22-6.74 42.65 5.55 45.9 40.06 3.79 40-17.01c-.04-15.08-7.27-23.67-7.27-23.67z'
        />
      </g>
      <g>
        <path
          className='loot-snowballs_svg__st0'
          d='M20.57 111.98c-2.69 11.59 3.33 23.45 13.85 27.6 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57s8.04-30.79-21.57-37.06c-19.38-4.09-30.13 8.58-33.34 22.4z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M73.93 101.16c0 5.02-1.24 9.6-2.14 12.45-2.58 8.2-10.57 18.61-26.93 16.57-5.4-.67-10.16-2.07-14.13-3.63-4.55-1.8-8.25-5.04-10.78-9.07-.04 9.66 5.61 18.62 14.47 22.12 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57 1.66-5.28 4.49-16.49-1.55-25.5z'
        />
        <path
          className='loot-snowballs_svg__st1'
          d='M40.44 118.36c3.86 0 3.87-6 0-6-3.86 0-3.87 6 0 6zM41.56 125.84c1.93 0 1.93-3 0-3s-1.93 3 0 3zM28.65 122.4c6.43 0 6.45-10 0-10-6.44 0-6.45 10 0 10z'
        />
        <path
          className='loot-snowballs_svg__st2'
          d='M20.57 111.98c-2.69 11.59 3.33 23.45 13.85 27.6 3.96 1.56 8.73 2.96 14.13 3.63 16.36 2.05 24.36-8.36 26.93-16.57s8.04-30.79-21.57-37.06c-19.38-4.09-30.13 8.58-33.34 22.4z'
        />
      </g>
    </svg>
  );
}

export default SvgLootSnowballs;
