import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Pirate = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M227.93 261.1c-4.66-1.09-10.56 2.61-14.33 2.61-3.77 0 1.13-3.05-1.09-3.94-2.22-.89-6.11-2.3-10.78.72-1.19.77 2.52.92.44 2.4-1.94 1.38-4.7 1.91-8.05 1.91-3.35 0-6.12-.53-8.05-1.91-2.08-1.48.98-1.09.44-2.4-.54-1.31-7.23-3.01-10.78-.72-2.01 1.3 2.88 2.84-.89 2.84s-9.87-2.6-14.53-1.5c0-2.06 3.23-9.87 9.08-17.06 2.03.43 47.42.43 49.45 0 5.86 7.18 9.09 15 9.09 17.05z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M227.93 261.1c-4.66-1.09-10.56 2.61-14.33 2.61-3.77 0 1.13-3.05-1.09-3.94-2.22-.89-6.11-2.3-10.78.72-1.19.77 2.52.92.44 2.4-1.94 1.38-4.7 1.91-8.05 1.91-3.35 0-6.12-.53-8.05-1.91-2.08-1.48.98-1.09.44-2.4-.54-1.31-7.23-3.01-10.78-.72-2.01 1.3 2.88 2.84-.89 2.84s-9.87-2.6-14.53-1.5c0-2.06 3.23-9.87 9.08-17.06 2.03.43 42.69-4.6 49.45 0 5.86 7.18 9.09 15 9.09 17.05z'
    />
    <path
      fill='#FFF'
      d='M223.31 246.37c-2.4-.56-5.25.01-7.84.56-1.75.37-3.39.74-4.7.74-3.26 0 1.15-1.68-.77-2.45-1.91-.77-4.68-1.43-9.31.62-1.12.49 2.18.8.38 2.07-1.67 1.19-4.06 1.65-6.95 1.65-2.89 0-5.28-.46-6.95-1.65-1.79-1.28.81-.93.38-2.07-.59-1.56-5.87-3-9.31-.62-1.7 1.18 2.49 2.45-.77 2.45-1.31 0-2.95-.36-4.7-.74-2.6-.55-5.44-1.12-7.84-.56 0-1.78 2.79-8.53 7.84-14.73 6.61-1.94 41.47.12 42.7 0 5.05 6.2 7.84 12.95 7.84 14.73z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M215.47 231.64c5.05 6.21 7.84 12.96 7.84 14.73-2.4-.56-5.25.01-7.84.56-1.75.37-3.39.74-4.7.74-3.26 0 1.15-1.68-.77-2.45-1.91-.77-4.68-1.43-9.31.62-1.12.49 2.18.8.38 2.07-1.67 1.19-4.06 1.65-6.95 1.65-2.89 0-5.28-.46-6.95-1.65-1.79-1.28.81-.93.38-2.07-.59-1.56-5.87-3-9.31-.62-1.7 1.18 2.49 2.45-.77 2.45-1.31 0-2.95-.36-4.7-.74-2.6-.55-5.44-1.12-7.84-.56 0-1.78 2.79-8.53 7.84-14.73'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m204.88 214.36 8.83 17.61c-1.34.28-5.84.93-6.84.93-2.49 0 .73-1.01-.59-1.88-2.28-1.51-6.78-.39-7.13.47-.36.87 1.67.61.29 1.59-1.28.91-3.11 1.26-5.33 1.26s-4.05-.35-5.33-1.26c-1.37-.98.65-.72.29-1.59-.36-.86-4.05-1.86-7.13-.47-1.44.65 1.91 1.88-.59 1.88-1.01 0-5.68-.26-7.02-.54l8.29-18.11c6.18-1.5 17.08-1.45 23.25.11h-.99z'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m185.68 214.36-11.16 19.44s-45.96-28.32-46.8-35.64c0 0-1.68-7.56 2.64-13.08 0 0 23.52 26.52 55.32 29.28zM204.16 214.12l9.72 20.28s23.04-10.56 29.4-20.16c0 0 2.3-4.32-1.8-9.72 0 0-23.88 8.76-37.32 9.6z'
    />
    <path d='M223.9 203.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57zM208.45 231.64l-3.13-6.79c-.21-.45-.88-.24-.8.25L206 234l2.45-2.36zM199.92 233.62l.09-7.49c.01-.49-.69-.59-.82-.12l-2.48 8.7 3.21-1.09zM190.9 233.14l-1.7-8.3c-.11-.48-.81-.41-.83.08l-.35 9.02 2.88-.8zM182.15 232.35l2.4-7.24c.11-.48-.55-.72-.78-.28l-4.98 7.94 3.36-.42zM212.91 246.96l-4.45-11.1c-.23-.59-.97-.31-.88.32l1.63 11.73 3.7-.95zM202.54 247.42l-.34-10.97c.01-.65-.76-.78-.9-.16l-3.21 12.56 4.45-1.43zM216.31 262.84l-5.43-11.61c-.28-.59-1.18-.31-1.07.32l2.47 11.29h4.03zM203.62 262.78l-.42-10.97c.01-.65-.93-.78-1.11-.16l-3.32 12.74 4.85-1.61zM189.79 248.17l-2.76-12.31c-.12-.63-.89-.54-.91.1l-.38 11.88 4.05.33zM179.29 245.74l1.9-9.52c.12-.63-.61-.95-.86-.37l-4.73 10.45 3.69-.56zM188.82 263.22l-2.56-11.53c-.14-.75-1.06-.64-1.08.12l-.22 10.77 3.86.64zM176.68 262.57l2.24-11.27c.14-.75-.72-1.12-1.02-.44l-6.15 12.1 4.93-.39z' />
  </svg>
);

export default Pirate;
