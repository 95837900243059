import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Blush = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffa6d3'
      d='M140.6 164.63c-1.25-1.7-3.74-1.89-5.21-.39-.51.52-.98 1.1-1.39 1.74-3.28 5.08-1.82 11.87 3.26 15.15 5.08 3.28 11.87 1.82 15.15-3.26.29-.45.54-.91.76-1.39.88-1.92-.18-4.19-2.22-4.75a19.083 19.083 0 0 1-10.35-7.1zM252.93 164.53c1.14-1.77 3.62-2.11 5.18-.69.54.49 1.04 1.04 1.49 1.65 3.58 4.88 2.52 11.74-2.36 15.31-4.88 3.58-11.74 2.52-15.31-2.36-.32-.43-.6-.88-.84-1.34-.99-1.87-.07-4.19 1.93-4.87 4-1.36 7.53-4.03 9.91-7.7z'
    />
  </svg>
);

export default Blush;
