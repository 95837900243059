import React, { FunctionComponent, useState } from 'react';
import {
  Theme,
  Container,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import FullDrawer from '../Drawer/FullDrawer';
import { useHistory, useParams } from 'react-router-dom';
import PlayerAvatar from '../../pages/Account/PlayerAvatar';
import { IFurballPlayer } from '../../wallet/WalletTypes';
import useCommonStyles from '../useCommonStyles';
import MyFurballsList from '../../pages/Account/MyFurballsList';
import FurballSortBySelect, { SELECT_OPTIONS } from '../FurballSortBySelect';
import BossBattleHistory from '../BossFights/BossBattleHistory/BossBattleHistory';
import { usePageTitle } from '../../Analytics';
import { getInventoryQuantities } from '../Furballs';
import { usePlayerLazyQuery } from '../schema';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  main: {
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18px !important',
    },
  },
  header: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  action: {
    marginTop: '18px !important',
    marginRight: '6px !important',
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      marginRight: '0px !important',
      marginBottom: '10px !important',
      textAlign: 'center',
    },
  },
  avatar: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: '12px !important',
    },
    [theme.breakpoints.down('sm')]: {
      marginRight: '0px !important',
      marginLeft: '0px !important',
    },
  },
  cardTitle: {
    fontSize: '1.8rem !important',
  },
  wrapper: {
    display: 'flex',
    minHeight: '450px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& p': {
      fontSize: '1.5rem',
      color: 'white',
      opacity: 0.7,
      marginBottom: '2rem !important',

      '& span': {
        fontWeight: 'bold',
      },
    },
  },
}));

interface IPlayerHome {}

interface IPlayerParams {
  query: string;
}

export const PlayerHome: FunctionComponent<IPlayerHome> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const history = useHistory();
  const { log } = useFurComponent(PlayerHome);
  const p = useParams<IPlayerParams>();
  const arg = decodeURIComponent(p.query);
  const [query, setQuery] = React.useState<string | undefined>();
  const [loadPlayer, { loading, data, error }] = usePlayerLazyQuery();
  const player: IFurballPlayer | undefined | null = data?.player;
  const furballs = (data?.player?.furballs ?? []).map((fb) => {
    return { ...fb, inventory: getInventoryQuantities(fb.inventory) };
  });
  const [sortBy, setSortBy] = useState(SELECT_OPTIONS[0].value);

  React.useEffect(() => {
    if (arg && arg.length && query !== arg) {
      log.info('[PLAYER] load', arg);
      setQuery(arg);
      loadPlayer({ variables: { query: arg } });
    }
  }, [arg]);

  usePageTitle('Player');

  function renderPlayer() {
    if (loading) {
      return (
        <div className={classes.wrapper}>
          <CircularProgress color={'secondary'} size={60} />
        </div>
      );
    }

    if (!player) {
      return (
        <div className={classes.wrapper}>
          <Typography variant={'body1'}>
            No player with the username
            <span> {query} </span> was found
          </Typography>
          <Button
            variant={'outlined'}
            size={'large'}
            onClick={() => history.push('/')}>
            Back to homepage
          </Button>
        </div>
      );
    }

    return (
      <>
        <BossBattleHistory player={player} />
        <Card className={clsx(common.cardRoot, classes.main)}>
          <CardHeader
            className={clsx(common.cardHeader, classes.header)}
            classes={{
              title: classes.cardTitle,
              action: classes.action,
              avatar: classes.avatar,
            }}
            sx={{ padding: '24px 16px !important' }}
            title={player.username}
            avatar={<PlayerAvatar player={player} radius={40} />}
            action={
              <FurballSortBySelect
                sortBy={sortBy}
                setSortBy={setSortBy}
                offsetRight={'2rem'}
              />
            }
          />
          <CardContent
            className={common.cardContent}
            sx={{ padding: '0 !important' }}>
            {furballs.length > 0 && (
              <MyFurballsList furballs={furballs} sortBy={sortBy} />
            )}
            {furballs.length <= 0 && (
              <>{player.username} does not have any Furballs!</>
            )}
          </CardContent>
        </Card>
      </>
    );
  }

  return (
    <FullDrawer drawer={null}>
      <Container className={classes.root}>{renderPlayer()}</Container>
    </FullDrawer>
  );
};

export default PlayerHome;
