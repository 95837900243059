import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Walrus = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ccaa9a'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M194.76 151.58s-12.63 0-15.79 9.4c-2.65 7.88 5.51 11.86 8.23 12.94.52.21 1.07.31 1.63.31h17.92c2.11 0 4.13-1.03 5.44-2.83 2.63-3.64 5.71-10.14-.45-15.55-6.32-5.55-16.98-4.27-16.98-4.27z'
    />
    <path d='M206.5 152.35c0 2.4-3.99 4.35-9.65 4.35s-9.53-1.95-9.53-4.35 3.87-4.35 9.53-4.35 9.65 1.95 9.65 4.35z' />
    <path
      fill='#faf8f4'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m187.23 167.23-.1 24.85c0 1.43.74 2.6 1.66 2.6s2.41-.46 2.98-5.19c0 0 2.76-19.55 2.43-24.04M199.84 166.34l-.6 24.28c0 1.43.88 3.16 1.8 3.16s2.18-2.68 2.84-5.75c0 0 2.98-14.88 2.65-19.36'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M211.86 165.54c-1.87 3.67-6.1 3.41-11.91-.15-6.19-3.79-12.93 5.43-16.13-.37'
    />
  </svg>
);

export default Walrus;
