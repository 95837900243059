import React from 'react';
import { IRenderPart } from '../part';
import Scar from './00_Scar';
import Tears from './01_Tears';
import Blush from './02_Blush';
import Freckles from './03_Freckles';
import Bandaid from './04_Bandaid';
import Paw from './05_Paw';

export function renderEdition0Tattoo(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Scar renderer={renderer} />;
  if (renderer.partNumber === 2) return <Tears renderer={renderer} />;
  if (renderer.partNumber === 3) return <Blush renderer={renderer} />;
  if (renderer.partNumber === 4) return <Freckles renderer={renderer} />;
  if (renderer.partNumber === 5) return <Bandaid renderer={renderer} />;
  if (renderer.partNumber === 6) return <Paw renderer={renderer} />;
  console.warn('renderEdition0Tattoo', renderer);
}
