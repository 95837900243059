import { Button, Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import useCommonStyles from '../../components/useCommonStyles';
import {
  IFurball,
  IFurballBase,
  IInventoryAll,
  IInventoryItems,
} from '../../wallet/WalletTypes';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { expColor, furColor } from '../../theme';
import { useHistory } from 'react-router-dom';
// import { getFoodClockTimeRemaining, getFoodItem, SnackStatRow } from '../../components/Food';
import {
  getLootItem,
  getLootSlot,
  ILootSlot,
  LootSlot,
} from '../../components/Loot';
import { TotalBoostBar, ItemStatRow } from '../Stats';
// import { useFrameRate } from '../useAnimation';
import OkDialog from '../OkDialog';
import ShopWaresSingle from '../Shop/ShopWaresSingle';
import InventoryItemDialog from '../Inventory/InventorItemDialog';
import { ItemQuantityFragment, ItemsFragment, RarityLevel } from '../schema';
import { useLoadedFurball } from '../../wallet';
import { useWalletSelector } from '../../hooks';
import BuyKeysDialog from '../../pages/Furverse/BuyKeysDialog';
import { getItemName } from '../Inventory/InventoryHelpers';
import { IFurballItemInventory } from '../../pages/Furverse/FurballBossKeys';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import { getRarityLevelName } from '../../pages/Play';
import { slugify } from '../../utils';
import _ from 'lodash';
// import { getRealmKeyLootId } from '../Zones/ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  footer: {
    color: '#FFF',
  },
  exp: {
    backgroundColor: expColor.dark,
  },
  fur: {
    backgroundColor: furColor.dark,
  },
}));

interface IInventory {
  furball: IFurballBase;
  disableHoverEffect?: boolean;
}

export function getItemQuantities(
  items: ItemsFragment[],
): ItemQuantityFragment[] {
  const ret: { [key: number]: ItemQuantityFragment } = {};
  items.map((item) => {
    const itemId = item.itemId;
    if (!ret[itemId]) ret[itemId] = { itemId, quantity: 0 };
    ret[itemId].quantity += item.stack;
  });
  return Object.values(ret);
}

export function getTotalDustCount(items: ItemsFragment[]): number {
  return _.sum(
    items
      .filter((i) => getItemDef(i.itemId)?.icon.startsWith('boss_dust_'))
      .map((i) => i.stack),
  );
}

export function getInventoryQuantities(
  inventory: IInventoryItems,
): IInventoryAll {
  return {
    ...inventory,
    totalDustCount: getTotalDustCount(inventory.items as ItemsFragment[]),
    itemQuantities: getItemQuantities(inventory.items as ItemsFragment[]),
  };
}

export function getFurballInventory(fb: IFurball): IFurballItemInventory {
  return { ...fb, inventory: getInventoryQuantities(fb.inventory) };
}

export const Inventory: FunctionComponent<IInventory> = (props) => {
  const common = useCommonStyles();
  const classes = useStyles();
  const [buyKeys, setBuyKeys] = React.useState(false);
  const { furball, disableHoverEffect } = props;

  const fullFurball = useLoadedFurball(furball.tokenId);
  const history = useHistory();
  const playersFurballIds = useWalletSelector(
    (w) => w.contents?.tokenIds ?? [],
  );

  const inventory = [...(furball?.inventory.items ?? [])];
  // const inventoryStats = groupInventory(inventory);
  // const inventoryWithoutStats = [
  //   ...inventory
  //     .map((i) => {
  //       return { ...getLootSlot(getLootItem(i.itemId), i.stack), id: i.id };
  //     })
  //     .filter((i) => !i.item.stat),
  //   // ...(furball?.state.completedVoyages ?? []).map(r => getLootItem(getRealmKeyLootId(r))),
  // ];

  const [clickedMax, setClickedMax] = React.useState(false);
  const [shopOpen, setShopOpen] = React.useState(false);
  const [movingItem, setMovingItem] = React.useState<ItemsFragment>();
  // const totalBoost = inventoryKeys.map(k => {
  //   return 5 * item.rarity * inventory[k];
  // })
  const expBoost = 0;
  const furBoost = 0;

  function onClickItem(item: ItemsFragment, name: string): void {
    const isMax = item.rarity >= RarityLevel.Legendary;
    if (isMax) {
      setClickedMax(true);
      return;
    }

    history.push(`/shop/upgrade/${slugify(name)}?id=${item.id}`);
  }

  function moveItem(item?: ItemsFragment) {
    // const item = furball.inventory.items.find(({ id }) => id === slot?.id);
    setMovingItem(!!item ? item : undefined);
  }

  function getOwnedItem() {
    if (!movingItem) return null;

    return {
      id: movingItem.id,
      name: getItemName(movingItem, fullFurball),
      item: movingItem,
      furball: fullFurball,
    };
  }

  let rowIdx = 0;

  function renderItem(item: ItemsFragment) {
    const slot: ILootSlot = {
      ...getLootSlot(getLootItem(item.itemId), item.stack),
      id: item.id,
    };
    const def = getItemDef(item.itemId);
    const hoverEffect = def?.upgradeToItemId && !disableHoverEffect;
    const rarityName = def ? getRarityLevelName(def.rarity) : '';
    const countSuff = item.stack > 1 ? ` (x${item.stack})` : '';
    rowIdx++;

    return (
      <Grid item xs={12} key={rowIdx}>
        <ItemStatRow
          title={rarityName.toUpperCase() + countSuff}
          name={def?.name ?? ''}
          odd={rowIdx % 2 == 0}
          // callout={
          //   statItem ? { stat: slot.item.stat, value: slot.boost } : undefined
          // }
          activeText={hoverEffect ? 'Upgrade' : undefined}
          extraButton={
            playersFurballIds.includes(furball.id) && !def?.untradeable ? (
              <Button
                variant={'contained'}
                color={'primary'}
                style={{ marginLeft: 10 }}
                onClick={() => moveItem(item)}>
                Move
              </Button>
            ) : undefined
          }
          onClick={hoverEffect ? () => onClickItem(item, def.name) : undefined}
          icon={<LootSlot size={'sm'} {...slot} />}
        />
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <div className={common.cardDropHeader}>
        <Typography variant={'h5'} className={common.unfocusedText}>
          Loot
        </Typography>
      </div>
      <Grid container className={common.paddingTable} spacing={1}>
        {inventory.length === 0 && (
          <Grid item xs={12}>
            <Typography variant={'h4'}>None Yet!</Typography>
            <Typography variant={'body1'}>
              Earn Boss Keys via Voyages.
              <br />
              Other items in Account page.
            </Typography>
          </Grid>
        )}
        {inventory.map(renderItem)}
        {/*<Grid item xs={12}>*/}
        {/*  <Button*/}
        {/*    onClick={() => setBuyKeys(true)}*/}
        {/*    variant={'contained'}*/}
        {/*    className={common.actionButton}>*/}
        {/*    Buy Boss Keys*/}
        {/*  </Button>*/}
        {/*</Grid>*/}
        {/*<Grid item xs={12}>*/}
        {/*  /!*<Button variant={'contained'} onClick={onClickSnack} className={common.secondaryButton} color={'secondary'} >*!/*/}
        {/*  /!*  Feed Furball a Snack*!/*/}
        {/*  /!*</Button>*!/*/}
        {/*  {isOwner && <Button variant={'contained'} onClick={onClickShop} className={common.actionButton} color={'primary'} >*/}
        {/*    Claim Holiday Surprise*/}
        {/*  </Button>}*/}
        {/*</Grid>*/}
      </Grid>
      <TotalBoostBar furBoost={furBoost} expBoost={expBoost} />
      <OkDialog
        title={'Max Upgrade!'}
        open={clickedMax}
        topRight={true}
        onClose={() => setClickedMax(false)}>
        The loot item you selected is already the max rarity (Legendary).
      </OkDialog>
      <OkDialog
        title={'Holiday Surprise'}
        open={shopOpen}
        topRight={true}
        onClose={() => setShopOpen(false)}>
        <ShopWaresSingle furballId={furball.tokenId} />
      </OkDialog>
      <InventoryItemDialog
        open={!!movingItem}
        close={() => setMovingItem(undefined)}
        selectedItem={getOwnedItem()}
      />
      <BuyKeysDialog
        open={buyKeys}
        onClose={() => setBuyKeys(false)}
        furballs={[
          { ...furball, inventory: getInventoryQuantities(furball.inventory) },
        ]}
      />
    </React.Fragment>
  );
};

export default Inventory;
