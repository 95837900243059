import React, { FunctionComponent } from 'react';
import { Theme, Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  defaultBossKeys,
  getBossKeyRealm,
  getBossKeySvg,
  getLootItem,
} from '../../components/Loot';
import { ItemsFragment, RealmType } from '../../components/schema';
import BuyKeysDialog from './BuyKeysDialog';
import { IInventoryQuantities } from '../../wallet/WalletTypes';

const useStyles = makeStyles((theme: Theme) => ({
  key: {
    display: 'grid',
    placeItems: 'center',
    width: '1.1rem',
    height: '1.1rem',
    position: 'relative',

    '&[data-disabled="true"]': {
      filter: 'brightness(60%) grayScale(50%)',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },

    '& > svg': {
      position: 'static !important',
    },
  },
}));

interface IInventoryItems {
  items: ItemsFragment[];
}

export interface IFurballItemInventory {
  id: string;
  name: string;
  inventory: IInventoryQuantities;
}

interface IFurballBossKeys {
  furball: IFurballItemInventory;
  className?: string;
}

export const FurballBossKeys: FunctionComponent<IFurballBossKeys> = (props) => {
  const { furball, className } = props;
  const [buyingKeys, setBuyingKeys] = React.useState<RealmType>();
  const classes = useStyles();

  return (
    <Grid
      container
      gap='0.3rem'
      alignItems='center'
      justifyContent='center'
      className={className}>
      {defaultBossKeys.map((bossKey, index) => {
        const loot = getLootItem(bossKey);
        const hasKey = furball.inventory.itemQuantities.find(
          (item) => item.itemId === bossKey,
        );

        const disabled = !hasKey;

        const tip = disabled
          ? `${loot.name} missing (click to buy)`
          : `Has a ${loot.name}!`;

        return (
          <Tooltip key={index} title={tip}>
            <div
              className={classes.key}
              data-disabled={disabled}
              onClick={() => setBuyingKeys(getBossKeyRealm(bossKey))}>
              {getBossKeySvg(bossKey)}
            </div>
          </Tooltip>
        );
      })}
      <BuyKeysDialog
        open={!!buyingKeys}
        onClose={() => setBuyingKeys(undefined)}
        furballs={[furball]}
        realms={buyingKeys ? [buyingKeys] : undefined}
      />
    </Grid>
  );
};

export default FurballBossKeys;
