import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { expColor, furColor } from '../../theme';
import { ITotalBoost } from './StatTypes';
import { white } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    color: white,
    marginTop: 'auto !important',
  },
  exp: {
    backgroundColor: expColor.dark,
  },
  fur: {
    backgroundColor: furColor.dark,
  },
}));

export const TotalBoostBar: FunctionComponent<ITotalBoost> = (props) => {
  const { expBoost, furBoost } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.footer}>
      <Grid item xs={6} className={classes.exp}>
        <Typography variant={'h5'}>+{expBoost}% EXP</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant={'h5'} className={classes.fur}>
          +{furBoost}% FUR
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TotalBoostBar;
