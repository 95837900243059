import * as React from 'react';

function SvgIconSync(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' {...props}>
      <path d='M149.8 362.2c-58.3-58.5-58.3-153.8 0-212.3l31.1 31.1V87.3H87.2l36.2 36.2c-73.2 73.1-73.2 192 0 265.2 46.2 46.1 110.5 63.2 170.1 51v-38.6c-49.7 12.9-104.8 0-143.7-38.9zM388.6 123.5c-46.2-46.3-110.5-63.4-170.1-51.2v38.6c49.7-12.9 104.9.2 143.7 39 58.5 58.5 58.5 153.8 0 212.3l-31.1-31.1v93.8h93.8l-36.2-36.2c73.1-73.2 73.1-192.1-.1-265.2z' />
    </svg>
  );
}

export default SvgIconSync;
