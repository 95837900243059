import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Teeth = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M211.98 167.43c1.2 11.44-8.6 11-15.06 11-6.46 0-18.86 1.81-18.18-8.03.96-13.97 11.42-12 17.88-12.1 14.7-.21 14.78 3.66 15.36 9.13z' />
    <path
      fill='#FFF'
      d='m207.47 167.55.51-7.04c-1.05-.28-2.47-1.05-4.44-1.24.05 1.66.45 5.42 2.61 8.84.41.66 1.25.3 1.32-.56zM186.89 167.79c1.45-2.87 1.84-6.9 1.89-8.76-1.56.3-2.98 1.49-4.29 1.46l1.03 6.82c.14.88 1.01 1.19 1.37.48zM187.63 168.91c-2.43 2.85-3.34 6.81-3.66 9.03 1.15 1.29 2.47.7 3.92 1.28l.86-9.28c.18-1.06-.53-1.73-1.12-1.03zM203.4 169.22l.21 8.15c1.55-.18 3.03-.24 4.37-.41-.33-1.95-1.18-5.48-3.32-8.49-.48-.68-1.29-.2-1.26.75zM192.87 165.03c3.19 0 2.3-4.05 2.71-7.74-.51.12-3.22.27-4 .38.12 2.78-1.16 7.36 1.29 7.36zM198.85 165.19c3.31-.01 2.31-3.8 2.71-7.49-.51.12-3.23.02-4 .13.12 2.77-.55 7.36 1.29 7.36zM199.47 170.41c-2.17.38-2.67 4.84-2.75 7.31 1.72.02 3.43.16 5.11.18-.13-2.64-.18-7.87-2.36-7.49zM192.68 170.77c-2.46.04-2.7 5.64-2.7 7.4.47.04 3.86.14 5.16.17-.09-2.3.01-7.6-2.46-7.57z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M210.9 169.06c1.2 11.69-7.52 9.41-13.98 9.41-6.46 0-17.66 3.4-16.98-6.65.96-14.27 10.22-13.42 16.68-13.51 14.7-.23 13.7 5.16 14.28 10.75z'
    />
  </svg>
);

export default Teeth;
