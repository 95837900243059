import React, { FunctionComponent, useContext } from 'react';
import {
  CircularProgress,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { RealmType, useBuyBossKeysMutation } from '../../components/schema';
import {
  allRealms,
  getRealmKeyLootId,
  getRealmName,
} from '../../components/Zones/ZoneHelpers';
import BossKey from '../../components/Furballs/BossKey';
import { ALERT_TYPES, AlertContext } from '../../components/Alert/AlertContext';
import TixBalanceButtonV2 from '../Account/BalanceButtons/TixBalanceButtonV2';
import FurballRenderer from '../../components/Furballs/FurballRenderer';
import { useAppDispatch } from '../../hooks';
import walletSlice from '../../wallet/WalletSlice';
import { IFurballItemInventory } from './FurballBossKeys';
import { useAuthContext } from '../../components/Auth/AuthContext';
import SpendTixButton from '../../components/Account/SpendTixButton';
import { getInventoryQuantities } from '../../components/Furballs';
import { useFuelFactor } from '../../components/Timekeeper/TimekeeperHooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  row: {
    paddingBottom: 14,
  },
  rowAlt: {
    backgroundColor: '#eeeeee !important',
  },
  spendTix: {
    maxWidth: '120px !important',
  },
}));

export const tixCostPerVoyageDay = 1;

export interface IBuyKeys {
  furballs: IFurballItemInventory[];
  realms?: RealmType[];
}

export const BuyKeys: FunctionComponent<IBuyKeys> = (props) => {
  const { furballs } = props;
  const classes = useStyles();
  const tixCost = 21;
  const fuelCost = tixCost * 10000;
  const realms = props.realms ?? [...allRealms];
  const [buyKeys, { data, loading }] = useBuyBossKeysMutation();
  const {
    authState: { playerType },
  } = useAuthContext();
  const dispatch = useAppDispatch();
  const { addAlert } = useContext(AlertContext);
  const fuelFactor = useFuelFactor();

  async function buyBossKey(fb: IFurballItemInventory, realm: RealmType) {
    const { data } = await buyKeys({
      variables: { furballIds: [fb.id], realms: [realm] },
    });
    if (!data?.buyBossKeys) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: 'No data was returned',
      });
      return;
    }

    dispatch(
      walletSlice.actions.setTixBalance(
        data.buyBossKeys.remainingFuel / fuelFactor,
      ),
    );
    dispatch(
      walletSlice.actions.updateFurballs(
        data.buyBossKeys.furballs.map((fb) => {
          return { ...fb, inventory: getInventoryQuantities(fb.inventory) };
        }),
      ),
    );
  }

  function renderRow(fb: IFurballItemInventory, i: number): React.ReactNode {
    const clses = clsx(classes.row, { [classes.rowAlt]: i % 2 === 0 });

    return (
      <React.Fragment key={`buy-key-${fb.id}`}>
        <Grid item xs={3} lg={1} className={clses}>
          <div style={{ position: 'relative' }}>
            <FurballRenderer size={60} tokenId={fb.id} />
          </div>
        </Grid>
        <Grid item xs={9} lg={5} className={clses}>
          <Typography variant={'h5'}>{fb.name}</Typography>
        </Grid>
        {allRealms.map((realm) => {
          const keyId = getRealmKeyLootId(realm);
          const key = fb.inventory.itemQuantities.find(
            (i) => i.itemId === keyId,
          );
          const disabled = !!key || loading;

          return (
            <Grid
              key={`buy-key-${fb.id}-${realm}`}
              item
              xs={4}
              lg={2}
              className={clses}
              style={{ textAlign: 'center' }}>
              {realms.includes(realm) && (
                <Tooltip
                  style={{ maxWidth: '200px' }}
                  title={
                    !!key
                      ? 'This furball already has this key!'
                      : 'Buy this key?'
                  }>
                  <span>
                    <SpendTixButton
                      itemName={'Boss Key (' + getRealmName(realm) + ')'}
                      cost={fuelCost}
                      isLoading={loading}
                      onAccepted={() => buyBossKey(fb, realm)}
                      disabled={disabled}
                      className={classes.spendTix}>
                      {loading && (
                        <div style={{ width: 44, height: 44 }}>
                          <CircularProgress size={44} />
                        </div>
                      )}
                      {!loading && <BossKey realm={realm} size={44} />}
                    </SpendTixButton>
                  </span>
                </Tooltip>
              )}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Grid container className={clsx(classes.root)} spacing={2}>
      <Grid item xs={12} lg={6} />
      {allRealms.map((realm) => (
        <Grid key={`header-key-${realm}`} item xs={4} lg={2}>
          {realms.includes(realm) && (
            <Typography variant={'body1'}>{getRealmName(realm)}</Typography>
          )}
        </Grid>
      ))}
      {furballs.map(renderRow)}
      <Grid item xs={12} lg={6}>
        <Typography variant={'h6'}>
          You can earn keys for free via Voyages
        </Typography>
        <Typography variant={'subtitle2'}>
          Each key costs {tixCostPerVoyageDay * 21} TIX.
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6} style={{ textAlign: 'center' }}>
        <TixBalanceButtonV2 />
      </Grid>
    </Grid>
  );
};

export default BuyKeys;
