import * as React from 'react';

function SvgLootFurtilizer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <path
        fill='#C4F8C3'
        d='M53.6 57.9v-8.6c0-4.6 3.7-8.2 8.2-8.2h1.3c4.6 0 8.2-3.7 8.2-8.2 0-4.6 3.7-8.2 8.2-8.2h2.6c5.3 0 9.3-4.9 8-10.1-.7-3-2.9-6.5-7.5-10.3 0 0 21 1.2 27.6 18.4 1.1 2.9 3.9 4.8 7 4.8 4.1 0 7.5 3.4 7.5 7.5v.3c0 4.1 3.4 7.5 7.5 7.5h6.6c4.1 0 7.5 3.4 7.5 7.5V61'
      />
      <path
        fill='#B7EA7E'
        d='M132.7 54.1l-11 1c-4.1 0 .2-14.2-14.7-15.2-4.4-.3-15.2-2.3-13.1-11.2 2.3-9.9.1-18.9-5.3-21.5-.9 3.4 8.4 16.1-6.2 17.3h-2.6c-4.6 0-8.1 3.8-8.2 8.4-.1 4.5-3.7 8.1-8.2 8.1h-1.3c-4.6 0-9.1 8.2-9.1 12.8l91.1 1.4c-.3 0-7.3-1.1-11.4-1.1z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M53.6 57.9v-8.6c0-4.6 3.7-8.2 8.2-8.2h1.3c4.6 0 8.2-3.7 8.2-8.2v0c0-4.6 3.7-8.2 8.2-8.2h2.6c5.3 0 9.3-4.9 8-10.1-.7-3-2.9-6.5-7.5-10.3 0 0 21 1.2 27.6 18.4 1.1 2.9 3.9 4.8 7 4.8h0c4.1 0 7.5 3.4 7.5 7.5v.3c0 4.1 3.4 7.5 7.5 7.5h6.6c4.1 0 7.5 3.4 7.5 7.5V61'
      />
      <path
        fill='#FBCA9F'
        d='M145.5 117.9c1.3-7.5 2-15.5 2-23.7 0-11.1-1.2-21.7-3.5-31.4-32.9 9.3-52.8 9.2-90.1-.1-2.3 9.8-3.5 20.4-3.5 31.6 0 9.1.8 18 2.4 26.2-5.1 7.4-10.3 16.7-5.4 18.2 5 1.6 11.2.6 15.4-.5 30 7.3 46.8 7.3 73.7-.2 4.2 1.1 10.9 2.4 16.2.7 5.4-1.8-1.7-13.2-7.2-20.8z'
      />
      <path
        fill='#C6B8A8'
        d='M52.7 53.8c-2.5 2.9-5.2 6-5.2 9.2 0 4.5 1.3 8.8 6 12.4 39.3 2.7 58.4 2.7 93 0 4.6-3.6 6-7.9 6-12.4 0-3.2-1.4-6.3-3.9-9.2-36.4 2.7-54.5 2.7-95.9 0z'
      />
      <path
        fill='#E8AF86'
        d='M152.6 138.6c5.5-1.8-1.6-13.1-7.1-20.7.1-.8.3-1.6.4-2.4H104c-17.3 0-31.4-14-31.4-31.4V71.3l-18.8-8.1c-2.2 9.6-3.4 20.1-3.4 31 0 9.1.8 18 2.4 26.2-5.1 7.4-10.3 16.7-5.4 18.2 5 1.6 11.2.6 15.4-.5 30 7.3 46.8 7.3 73.7-.2 4.1 1.2 10.8 2.5 16.1.7z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeMiterlimit={10}
        d='M145.5 117.9c1.3-7.5 2-15.5 2-23.7 0-11.1-1.2-21.7-3.5-31.4-32.9 9.3-52.8 9.2-90.1-.1-2.3 9.8-3.5 20.4-3.5 31.6 0 9.1.8 18 2.4 26.2-5.1 7.4-10.3 16.7-5.4 18.2 5 1.6 11.2.6 15.4-.5 30 7.3 46.8 7.3 73.7-.2 4.2 1.1 10.9 2.4 16.2.7 5.4-1.8-1.7-13.2-7.2-20.8z'
      />
      <path
        fill='#FBCA9F'
        stroke='#000'
        strokeWidth={4}
        strokeMiterlimit={10}
        d='M52.7 53.8c-2.5 2.9-5.2 6-5.2 9.2 0 4.5 1.3 8.8 6 12.4h0c39.3 2.7 58.4 2.7 93 0h0c4.6-3.6 6-7.9 6-12.4 0-3.2-1.4-6.3-3.9-9.2-36.4 2.7-54.5 2.7-95.9 0z'
      />
      <path
        fill='#FFF'
        d='M116.1 43.3l4.8 1.1-4.8 1.1-1.1 4.8-1.1-4.8-4.9-1.1 4.9-1.1 1.1-4.9z'
      />
      <g>
        <path
          fill='#FFF'
          d='M85.5 34.6l6.5 1.5-6.5 1.4-1.4 6.4-1.5-6.4-6.4-1.4 6.4-1.5 1.5-6.4z'
        />
      </g>
    </svg>
  );
}

export default SvgLootFurtilizer;
