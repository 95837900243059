import React, { FunctionComponent } from 'react';
import {
  Theme,
  Container,
  TextField,
  Grid,
  Button,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurball } from '../../../wallet/WalletTypes';
import { parseFurball, useLoadedFurball } from '../../../wallet';
import FurballEditorWidget from './FurballEditorWidget';
import useCommonStyles from '../../../components/useCommonStyles';
import { useFindFurballLazyQuery } from '../../../components/schema';
import WalletSlice from '../../../wallet/WalletSlice';
import { useAppDispatch } from '../../../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IFurballEditor {
  tokenId?: string;
  queryString?: string;
}

export const FurballEditor: FunctionComponent<IFurballEditor> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { queryString } = props;
  const [tokenId, setTokenId] = React.useState(props.tokenId);
  const loadedFurball = useLoadedFurball(tokenId);
  const dispatch = useAppDispatch();
  const [furball, setFurball] = React.useState(loadedFurball);
  const [findFurball, { data, loading, error }] = useFindFurballLazyQuery();
  const [typingId, setTypingId] = React.useState('');

  React.useEffect(() => {
    if (data) {
      const fb = parseFurball(data.furball);
      dispatch(WalletSlice.actions.setFurball(fb));
      setTokenId(fb.tokenId);
      setFurball(fb);
    }
  }, [data]);

  React.useEffect(() => {
    if (queryString) {
      let qs = queryString;
      if (!qs.startsWith('0x')) qs = '#' + qs;
      setTypingId(qs);
      search(qs);
    }
  }, [queryString]);

  function search(query: string): void {
    findFurball({ variables: { query } });
  }

  function onTyped(val: string): void {
    setTypingId(val);
  }

  function onClearFurball(): void {
    setFurball(undefined);
  }

  function onChangeFurball(fb: IFurball): void {
    setFurball(fb);
  }

  return (
    <Container className={clsx(classes.root)}>
      {loading && <LinearProgress color={'secondary'} />}
      {furball && (
        <FurballEditorWidget
          furball={furball}
          onClear={onClearFurball}
          onChange={onChangeFurball}
        />
      )}
      {!furball && (
        <Grid container>
          <Grid item xs={10}>
            <TextField
              style={{ width: '100%' }}
              value={typingId}
              onChange={(e) => onTyped(e.target.value)}
              placeholder={'Starting with # or 0x...'}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant={'contained'}
              color={'primary'}
              className={common.actionButton}
              onClick={() => search(typingId)}>
              Search
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default FurballEditor;
