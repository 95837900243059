import React, { FunctionComponent } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useAuthContext } from './AuthContext';
import ForceLoginDialog from '../../wallet/ForceLoginDialog';
import { usePageTitle } from '../../Analytics';
import OkDialog from '../OkDialog';
import { useWalletSelector } from '../../hooks';
import { Button, Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cdnRoot } from '../../theme';
import Preloader from '../../pages/Preloader';
import useHeroStyles from '../useHeroStyles';
import { useWindowSize } from '../useWindowSize';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '600px',
    minWidth: '600px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    '& img': {
      width: '400px',
      marginTop: '0.5rem',
    },

    [theme.breakpoints.down(650)]: {
      minHeight: '100%',
      minWidth: '100vw',

      '& img': {
        width: '330px',
        marginTop: '0.5rem',
      },
    },
  },
  cont: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '450px',
    margin: '1.7rem auto 0 auto',
    textAlign: 'center',
    gap: '0.5rem',

    '& h5': {
      fontSize: '2rem !important',
    },

    '& p': {
      color: '#4b5563 !important',
      fontSize: '13px !important',
    },

    [theme.breakpoints.down(650)]: {
      '& h5, p': {
        maxWidth: '300px !important',
      },
    },
  },
  coverImg: {
    minHeight: '280px',

    [theme.breakpoints.down(650)]: {
      minHeight: '230px',
    },
  },
  buttons: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1rem',

    '& button': {
      width: '200px !important',
      minWidth: '200px !important',
    },

    [theme.breakpoints.down(650)]: {
      flexDirection: 'column',

      '& button': {
        width: '300px !important',
        minWidth: '300px !important',
      },
    },
  },
}));

interface IProps {
  exact?: boolean;
  path?: string;
  furballsRequired?: boolean;
}

export const AuthenticatedRoute: FunctionComponent<IProps> = ({
  children,
  exact,
  path,
  furballsRequired,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const size = useWindowSize();
  const hero = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });
  const { isSignedIn } = useAuthContext();
  const usableFurballsLoaded = useWalletSelector(
    (s) => s.usableFurballsLoaded ?? false,
  );
  const usableTokenIds = useWalletSelector((s) => s.contents?.tokenIds ?? []);
  const hasFurballs = usableTokenIds.length > 0;
  const needsFurballs = isSignedIn && !!furballsRequired && !hasFurballs;
  const awaitingFurballs = !usableFurballsLoaded && needsFurballs;
  const loadedEmptyFurballs = usableFurballsLoaded && needsFurballs;
  const renderChildren = isSignedIn && !needsFurballs;

  usePageTitle(isSignedIn ? undefined : 'Login Required');

  if (awaitingFurballs) {
    return <Preloader title='Loading Player Furballs...' />;
  }

  return (
    <Route exact={exact} path={path}>
      <ForceLoginDialog open={!isSignedIn} onClose={() => history.push('/')} />
      {renderChildren && children}
      {!renderChildren && (
        <div className={hero.background}>
          <Container maxWidth={'sm'}>
            <Grid container className={hero.header} justifyContent={'center'} />
          </Container>
        </div>
      )}
      <OkDialog
        open={loadedEmptyFurballs}
        topRight={false}
        blockchainBanner={true}
        className={classes.root}
        onClose={() => history.push('/')}>
        <div className={classes.root}>
          <div className={classes.coverImg}>
            <img
              src={`${cdnRoot}/images/furballs-hero.png`}
              alt={'furballs cover'}
            />
          </div>
          {loadedEmptyFurballs && (
            <>
              <div className={classes.cont}>
                <Typography variant={'h5'}>
                  You don't have any usable Furballs!
                </Typography>
                <Typography variant={'body1'}>
                  To battle against bosses you need a party and some furballs.
                  Borrowing furballs from others players free of charge via our
                  marketplace. As you destroy Bosses, both you and the player
                  you are borrowing from will earn rewards!
                </Typography>
                <div className={classes.buttons}>
                  <Button
                    variant={'contained'}
                    onClick={() => history.push('/market/furballs')}>
                    Borrow Furballs
                  </Button>
                  {/*<Button*/}
                  {/*  variant={'contained'}*/}
                  {/*  onClick={() => history.push('/market/furballs/')}>*/}
                  {/*  Borrow from Market*/}
                  {/*</Button>*/}
                </div>
                {/*<OutboundLink*/}
                {/*  style={{*/}
                {/*    fontSize: '13px',*/}
                {/*    marginTop: '1.75rem',*/}
                {/*  }}*/}
                {/*  to={*/}
                {/*    'https://guide.furballs.com/scholarships/scholarships-scholars'*/}
                {/*  }*/}
                {/*  target={'_blank'}>*/}
                {/*  Learn more about Furballs*/}
                {/*</OutboundLink>*/}
              </div>
            </>
          )}
        </div>
      </OkDialog>
    </Route>
  );
};
