import * as React from 'react';

function SvgBunny(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='bunny_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 380.94 348.61'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.bunny_svg__st1{fill:#fff}.bunny_svg__st3{opacity:.15;enable-background:new}.bunny_svg__st5{fill:#fdbecc}.bunny_svg__st6,.bunny_svg__st7{opacity:.23;enable-background:new}.bunny_svg__st7{opacity:.4}.bunny_svg__st8{fill:#be66d0}.bunny_svg__st10{fill:#fff5ed}.bunny_svg__st11{fill:#d0f0fa}.bunny_svg__st12{fill:#f0f8fa}.bunny_svg__st13{fill:#a4894f}.bunny_svg__st14{fill:#ebcd70}.bunny_svg__st15{fill:#f0f7ff}.bunny_svg__st16{fill:#939b9f}'
        }
      </style>
      <path
        d='M132.09 193.04s-3.7-1.65-9.97-28.49c0 0-24.93-1.21-25.64 14.25-.1 1.96 17.98 23.17 35.61 14.24z'
        fill='#8e6796'
      />
      <path d='M122.99 198.09c-5.34 0-10.94-1.83-16.57-5.48-7.35-4.75-12.91-11.4-12.79-13.95.19-4.14 1.82-7.63 4.85-10.39 7.97-7.25 23.14-6.6 23.78-6.57 1.27.06 2.35.96 2.64 2.2 4.85 20.76 8.33 26.53 8.36 26.54 1 .45 1.66 1.43 1.69 2.53.03 1.1-.58 2.12-1.56 2.61-3.32 1.67-6.8 2.51-10.4 2.51zm-23.63-19.72c1.31 2.21 8.07 9.66 16.64 12.71 4.12 1.47 8.04 1.68 11.69.64-2.86-5.42-5.66-15.33-7.83-24.33-2.25.05-5.99.3-9.69 1.27-6.72 1.75-10.35 5.02-10.81 9.71z' />
      <path
        className='bunny_svg__st1'
        d='M307.35 139.55a28.496 28.496 0 01-8.18-15.06c-6.71-34.15-39.69-60.08-79.36-60.08-39.68 0-72.65 25.93-79.36 60.08-1.13 5.74-3.96 11-8.18 15.06-8.01 7.69-12.57 16.54-12.57 25.97 0 29.55 44.82 65.71 100.1 65.71s100.1-36.16 100.1-65.71c.02-9.43-4.54-18.27-12.55-25.97z'
      />
      <path
        d='M73.47 235.09c-.31-5.81-5.73-10.2-11.54-10.2-3.76 0-10.14 3.5-10.14 3.46 0-4.7-4.37-8.01-9.08-8.01-4.03 0-8.36 2.98-9.25 6.74-.37.07.23-.04-.14.04-2.13-2.59-5.36-4-8.71-3.82-5.3.28-9.62 4.54-9.96 9.84-.11 1.88.93 5.93.93 5.94-.17.24-2.79 0-3.88.14-3.83.51-6.88 3.62-7.29 7.47a8.534 8.534 0 005.49 8.95 10.541 10.541 0 00-7.05 9.94c0 5.83 4.72 10.54 10.54 10.54.66 0 1.3-.06 1.91-.17a8.478 8.478 0 00-.7 4.25c.37 3.97 3.5 7.21 7.48 7.66 1.47.17 54.8 10.44 51.39-52.77z'
        fill='#f0e9ea'
      />
      <path d='M31.05 291.4c-4.03 0-7.27-.39-9.11-.67l-.21-.03c-5.28-.61-9.48-4.91-9.97-10.23-.05-.54-.06-1.07-.04-1.59C5.13 278.06 0 272.41 0 265.59c.01-3.96 1.75-7.62 4.62-10.11a11.393 11.393 0 01-3.04-9.1c.55-5.09 4.65-9.29 9.75-9.97.24-.03.49-.05.76-.06-.21-1.24-.34-2.44-.28-3.37.44-6.78 5.88-12.15 12.65-12.52 2.88-.16 5.7.63 8.06 2.17 2.3-3.03 6.22-5.14 10.19-5.14 4.99 0 9.27 2.8 11.05 6.77 3.4-1.49 6.09-2.22 8.17-2.22 7.55 0 14 5.79 14.39 12.9 1.12 20.85-3.66 36.28-14.23 45.85-9.69 8.77-22.23 10.61-31.04 10.61zM15.3 273.11c.86 0 1.69.39 2.24 1.08.66.83.8 1.96.37 2.92-.4.9-.56 1.85-.46 2.82.25 2.65 2.34 4.8 4.97 5.1.05.01.19.03.4.06 5.22.8 23.2 2.56 35.45-8.54 9.23-8.36 13.39-22.27 12.36-41.32-.22-4.07-4.2-7.51-8.7-7.51-.78 0-3.17.29-8.55 3.01-.52.26-1.73.87-3.09.03a2.866 2.866 0 01-1.36-2.43c0-3.19-3.23-5.16-6.22-5.16-2.83 0-5.91 2.16-6.47 4.54-.24 1-.98 1.79-1.95 2.08-.1.03-.21.06-.32.09-1.05.25-2.16-.13-2.84-.97a7.633 7.633 0 00-6.35-2.78c-3.9.21-7.02 3.3-7.27 7.18-.04.64.2 2.45.83 5.01.05.22.1.51.1.74 0 .59-.18 1.16-.52 1.64-.96 1.36-2.31 1.34-4.23 1.31-.49-.01-1.32-.02-1.6.01-2.53.34-4.56 2.42-4.83 4.94a5.678 5.678 0 003.65 5.97c1.12.42 1.86 1.5 1.85 2.7-.01 1.2-.77 2.26-1.9 2.66-3.07 1.08-5.14 4-5.15 7.26 0 4.24 3.45 7.69 7.69 7.69.48 0 .95-.04 1.39-.12.16.01.34-.01.51-.01z' />
      <path d='M37.81 241.64s-4.93-7.31-11.07-3.42c-6.14 3.89-.41 10.84-.41 10.84s-2.45-14.33 11.48-7.42zM52.22 233.35s-7.51 4.45-4.09 9.94c3.42 5.5 10.64.34 10.64.34s-14.23 2.24-6.55-10.28z' />
      <path
        className='bunny_svg__st3'
        d='M44.11 271.23c-19.79 4.42-25.64-1.97-25.64-15.67 0-11.8 19.21-20.47 8.05-29.75-4.09-3.39-11.51 2.02-11.85 7.32-.11 1.88-1.87 5.93-2.93 6.08-3.83.51-6.88 3.62-7.29 7.47a8.534 8.534 0 005.49 8.95 10.541 10.541 0 00-7.05 9.94c0 5.83 4.72 10.54 10.54 10.54.66 0 1.08 2.55 1.21 4.07.37 3.97 3.5 7.21 7.48 7.66 1.43.19 22.17-16.65 21.99-16.61zM43.82 220.4c-.37-.04-.74-.06-1.11-.06-3.79 0-7.85 2.64-9.05 6.08 2.98-2.56 6.42-4.61 10.16-6.02z'
      />
      <path d='M21.86 256.23s-6.45 6-1.67 11.47c4.79 5.46 10.77-1.27 10.77-1.27s-13.76 4.63-9.1-10.2z' />
      <path
        className='bunny_svg__st1'
        d='M284.77 217.54c-17.48 10.69-40.16 17.95-64.95 17.95-35.06 0-65.91-14.55-83.79-32.71l-68.6 36.53c-14.53 7.72-26.66 19.4-34.54 33.84-9.45 17.3-14.53 41.16 3.72 66.72 2.54 3.54 6.63 5.65 10.98 5.64h162.36c4.23 0 8.45-.73 12.41-2.21 14.15-5.24 44.31-21.94 56.8-71.25 3.08-12.15.64-31.4 1.81-40.65.79-6.18 2.18-10.64 3.8-13.86z'
      />
      <path
        className='bunny_svg__st1'
        d='M189.94 329.52c-4.17.28-8.05 11.28-11.97 15.99h29.38l.09-.09c-5.29.84-12.44-16.24-17.5-15.9z'
      />
      <path
        className='bunny_svg__st3'
        d='M91.99 314.55c16.41 26.47 115.4 13.65 115.4 13.65 58.98-10.77 66.25-54.98 73.33-82.09 9.92 58.51-44.22 91.65-58.36 96.88-3.97 1.47-134.55 2.21-134.55 2.21s-7.99-19.67 4.18-30.65'
      />
      <path d='M47.59 348.06c-5.26 0-10.23-2.55-13.29-6.83-19.66-27.54-12.98-53.13-3.9-69.74 8.06-14.76 20.4-26.86 35.7-34.99l68.6-36.53c1.39-.74 3.12-.21 3.86 1.18.74 1.39.21 3.12-1.18 3.86l-68.6 36.53c-14.31 7.6-25.85 18.91-33.37 32.69-12.06 22.09-10.87 43.52 3.54 63.7 1.99 2.79 5.23 4.45 8.66 4.45h162.37c3.98 0 7.82-.68 11.41-2.03 37.34-13.84 50.93-53.06 55.04-69.28 1.79-7.06 1.61-16.9 1.46-25.58-.1-5.62-.19-10.93.29-14.73.75-5.93 2.11-10.9 4.05-14.78.7-1.41 2.41-1.98 3.82-1.27 1.41.7 1.98 2.42 1.27 3.82-1.65 3.3-2.83 7.66-3.5 12.95-.43 3.39-.34 8.5-.24 13.91.16 9.04.34 19.28-1.63 27.08-5.56 21.96-15.16 39.94-28.52 53.45-11.13 11.26-22.49 16.96-30.06 19.77-4.23 1.58-8.74 2.39-13.4 2.39H47.59v-.02z' />
      <path
        className='bunny_svg__st1'
        d='M127.91 196.27c-4.72 1.04-7.19 9.75-4.63 14.12 10.93 18.65 54.32 28.74 54.32 28.74'
      />
      <path d='M177.6 240.55c-.11 0-.22-.01-.32-.04-1.8-.42-44.12-10.44-55.23-29.4-1.89-3.22-1.36-7.81 0-10.93 1.27-2.91 3.24-4.79 5.55-5.3a1.428 1.428 0 01.61 2.79c-1.37.3-2.67 1.63-3.55 3.65-1.25 2.86-1.32 6.38-.16 8.35 10.49 17.9 52.99 27.97 53.42 28.07.77.18 1.24.94 1.06 1.71-.14.66-.73 1.1-1.38 1.1z' />
      <path
        className='bunny_svg__st1'
        d='M281.44 218.84c1.51.34 2.79 1.47 3.76 2.98 3.08 4.86 1.35 11.34-3.65 14.18-13.51 7.65-34.55 9.42-34.55 9.42'
      />
      <path d='M247 246.83c-.73 0-1.36-.56-1.42-1.31a1.43 1.43 0 011.3-1.54c.21-.02 20.89-1.83 33.97-9.24a8.893 8.893 0 004.2-5.53c.58-2.31.2-4.67-1.05-6.65-.58-.9-1.55-2.05-2.87-2.35-.77-.17-1.25-.94-1.08-1.7.17-.77.94-1.25 1.7-1.08 1.79.41 3.4 1.65 4.65 3.6 1.68 2.65 2.18 5.8 1.41 8.87-.77 3.08-2.8 5.75-5.56 7.31-13.62 7.72-34.26 9.53-35.13 9.6-.04.02-.08.02-.12.02zM80.71 332.13c-1.01 0-2-.54-2.51-1.5a2.843 2.843 0 011.16-3.86c16.94-9.13 6.68-35.97 6.58-36.24a2.85 2.85 0 011.61-3.69c1.47-.58 3.12.15 3.69 1.61.13.32 3.06 7.87 3.53 16.87.66 12.48-3.74 21.64-12.71 26.48-.43.22-.89.33-1.35.33z' />
      <path d='M89.43 347.58c-1.57 0-2.85-1.28-2.85-2.85 0-7.46-6.12-14.26-12.84-14.26-1.57 0-2.85-1.28-2.85-2.85s1.28-2.85 2.85-2.85c9.87 0 18.54 9.33 18.54 19.96 0 1.57-1.28 2.85-2.85 2.85z' />
      <path
        className='bunny_svg__st1'
        d='M160.21 309.22l8.23 27.82c1.24 4.76 5.54 8.08 10.46 8.08h30.55v-1.82c0-8.66-7.02-15.69-15.69-15.69'
      />
      <path d='M212.29 347.97h-33.4c-6.21 0-11.65-4.2-13.21-10.21l-8.21-27.73c-.45-1.51.41-3.09 1.92-3.54 1.5-.44 3.09.41 3.54 1.92l8.23 27.82c.94 3.59 4.11 6.04 7.72 6.04h27.66c-.52-6.6-6.06-11.81-12.8-11.81-1.57 0-2.85-1.28-2.85-2.85s1.28-2.85 2.85-2.85c10.22 0 18.54 8.32 18.54 18.54v4.67z' />
      <path
        className='bunny_svg__st1'
        d='M204.37 331.69c7.5-9 7.5-21.82 7.5-21.82'
      />
      <path d='M204.37 334.54a2.842 2.842 0 01-2.18-4.67c6.72-8.07 6.84-19.88 6.84-20a2.853 2.853 0 012.85-2.84c1.57 0 2.85 1.27 2.85 2.85 0 .57-.09 13.96-8.16 23.65-.57.66-1.38 1.01-2.2 1.01zM219.82 238.06c-57.03 0-102.95-37.5-102.95-68.56 0-9.89 4.65-19.58 13.44-28.03 3.79-3.65 6.33-8.33 7.36-13.55 7.1-36.15 41.65-62.38 82.15-62.38 40.5 0 75.05 26.23 82.15 62.38 1.01 5.2 3.55 9.88 7.36 13.56 8.79 8.45 13.44 18.14 13.44 28.02 0 31.05-45.93 68.56-102.95 68.56zm0-166.82c-37.78 0-69.98 24.3-76.56 57.78-1.25 6.37-4.36 12.1-9 16.56-7.65 7.35-11.69 15.62-11.69 23.92 0 13.68 10.51 29.23 28.11 41.6 19.5 13.71 44.05 21.26 69.14 21.26s49.64-7.55 69.14-21.26c17.6-12.37 28.11-27.93 28.11-41.6 0-8.3-4.04-16.57-11.69-23.92a31.159 31.159 0 01-8.99-16.57c-6.59-33.47-38.79-57.77-76.57-57.77z' />
      <path
        className='bunny_svg__st5'
        d='M283.79 92.32c8.92-6.62 21.13-16.18 28.41-23.94 12.48-13.28-1.71-41.94-22.51-38.88-14.3 2.11-24.21 28.92-28.99 45.46 4.58 2.36 20.04 14.07 23.09 17.36z'
      />
      <path
        className='bunny_svg__st1'
        d='M306.68 52.56c3.16 18.76-37.4 39.71-37.4 39.71l10.94 7.52c16.11-5 39.32-34.95 39.32-34.95l-12.86-12.28z'
      />
      <path d='M283.83 99.9a2.85 2.85 0 01-2.72-2.01c-.47-1.5.37-3.1 1.88-3.57 12.57-3.9 27.99-22.94 32.78-29.18l-6.47-6.18c-3.92 17.45-31.9 30.99-33.24 31.63a2.848 2.848 0 11-2.46-5.14c9.2-4.4 32.59-18.8 30.29-32.43-.2-1.21.39-2.42 1.47-2.99 1.09-.58 2.42-.39 3.3.46l12.87 12.28c1.03.98 1.18 2.57.34 3.73-.82 1.14-20.37 28.04-37.2 33.27-.28.09-.56.13-.84.13z' />
      <path
        className='bunny_svg__st6'
        d='M304.5 65.25l-15.56 14.19s-19.66-21.54-1.54-44.28c0 0 17.2 29.38 17.1 30.09z'
      />
      <path
        className='bunny_svg__st1'
        d='M264.5 85.43c.95-27.8 22.95-50.26 22.95-50.26s6.71 23.96 22.04 32.58 21.09 20.13 21.09 20.13c2.88-11.5 6.71-60.38-28.75-67.08s-48.27 61.03-48.27 61.03'
      />
      <path d='M330.57 90.73c-1.07 0-2.06-.6-2.55-1.58-.05-.1-5.63-10.88-19.93-18.92-12.11-6.81-19.03-22.07-21.87-29.75-5.63 6.24-17 21.2-17.68 40.94-.05 1.57-1.38 2.76-2.95 2.75a2.85 2.85 0 01-2.75-2.95c.99-28.69 21.83-47.42 22.72-48.2.73-.65 1.74-.88 2.69-.6.94.28 1.67 1.02 1.94 1.96.06.23 6.59 22.94 20.69 30.87 8.82 4.96 14.61 10.82 18.07 15.15 1.3-11.55.77-30.44-7.38-43.18-4.73-7.39-11.56-11.98-20.28-13.63-6.59-1.25-12.51.01-18.11 3.84-18.52 12.68-25.39 48.75-25.46 49.11a2.843 2.843 0 01-3.32 2.28 2.843 2.843 0 01-2.28-3.32c.29-1.58 7.36-38.74 27.84-52.77 6.81-4.67 14.35-6.27 22.39-4.74 10.33 1.95 18.41 7.39 24.03 16.16 12.29 19.19 8.62 47.8 6.96 54.41a2.847 2.847 0 01-2.45 2.14c-.11.02-.22.03-.32.03z' />
      <path
        className='bunny_svg__st1'
        d='M136.56 17.92C109.79 11.7 102.53 7.21 100.2 4.57c3.12 5.6 12.04 23.05 14.25 40.76 2.65 21.23 34.51 49.54 34.51 49.54 7.24 5.98 40.5 10.29 36.23-14.4-2.87-16.5-20.4-55.98-48.63-62.55z'
      />
      <path
        className='bunny_svg__st5'
        d='M117.98 22.34s13.35 41.47 44.68 65.64c.67.51 1.6.53 2.27.03l6.75-5.03c.53-.38.8-1.03.76-1.67-3.46-40.85-54.46-58.97-54.46-58.97z'
      />
      <path d='M163.82 91.22c-1.02 0-2.04-.33-2.89-.98-31.67-24.43-45.1-65.3-45.66-67.02-.33-1.03-.05-2.16.73-2.92.78-.76 1.91-1 2.94-.64.54.19 13.3 4.78 26.66 14.55 17.93 13.11 28.2 29.31 29.68 46.86.11 1.7-.6 3.25-1.91 4.21l-6.73 5.01c-.84.62-1.83.93-2.82.93zm-40.84-63.66c4.93 12.16 18.01 39.81 40.84 57.72l5.72-4.26c-2.84-30-33.41-47.34-46.56-53.46zm46.61 53.94h.01-.01zm.38-.81c-.01 0-.01.01-.02.01l.02-.01z' />
      <path
        className='bunny_svg__st6'
        d='M119.69 22.68s10.31 37.3 43.08 65.31c2.02-1.91 8.62-4.74 8.89-5.13-17.1-10.26-51.97-60.18-51.97-60.18z'
      />
      <path d='M155.42 100.59c-.53 0-1.06-.15-1.54-.46-1.37-.89-33.7-22.06-42.24-53.97C105.95 24.91 96.69 4.22 96.6 4.02c-.58-1.28-.13-2.8 1.05-3.56.97-.63 2.21-.6 3.13.02.59.32 5.27 2.63 36.8 14.78 24.68 9.51 36.38 25.7 41.86 37.6 5.95 12.94 6.02 23.56 6.02 24.01 0 1.57-1.28 2.85-2.85 2.85s-2.85-1.27-2.85-2.84c-.01-1.6-.65-39.5-44.22-56.3-16.23-6.26-25.53-9.96-30.89-12.2 3.27 7.97 8.67 22.01 12.5 36.3 7.96 29.76 39.51 50.45 39.83 50.66 1.32.85 1.7 2.62.85 3.94-.56.85-1.48 1.31-2.41 1.31zM99.2 2.85h.01-.01z' />
      <ellipse
        className='bunny_svg__st7'
        cx={165.22}
        cy={140.25}
        rx={29.92}
        ry={31.34}
      />
      <ellipse
        className='bunny_svg__st7'
        cx={273.5}
        cy={141.67}
        rx={27.07}
        ry={28.49}
      />
      <circle className='bunny_svg__st1' cx={169.5} cy={130.28} r={32.77} />
      <circle className='bunny_svg__st5' cx={176.44} cy={135.79} r={25.83} />
      <path d='M169.5 165.89c-19.64 0-35.62-15.98-35.62-35.62s15.98-35.62 35.62-35.62 35.62 15.98 35.62 35.62-15.98 35.62-35.62 35.62zm0-65.53c-16.5 0-29.92 13.42-29.92 29.92S153 160.2 169.5 160.2s29.92-13.42 29.92-29.92c-.01-16.5-13.43-29.92-29.92-29.92z' />
      <circle className='bunny_svg__st1' cx={270.65} cy={130.28} r={32.77} />
      <circle cx={178.23} cy={148.12} r={13.5} />
      <circle className='bunny_svg__st5' cx={271.89} cy={135.79} r={25.83} />
      <path d='M270.65 165.89c-19.64 0-35.62-15.98-35.62-35.62s15.98-35.62 35.62-35.62 35.62 15.98 35.62 35.62-15.98 35.62-35.62 35.62zm0-65.53c-16.5 0-29.92 13.42-29.92 29.92s13.42 29.92 29.92 29.92 29.92-13.42 29.92-29.92-13.42-29.92-29.92-29.92z' />
      <path
        className='bunny_svg__st1'
        d='M190.48 77.36s-9.33-7.24-6.68-12.11c3.28-6.03 11.28 3.25 11.28 3.25s1.8-14.62 10.17-17.52c2.31-.8.34 10.94 2.74 17.44 0 0 5.98-22.4 14.7-22.4 2.05 0-.26 14.45-.26 14.45s7.05-13.36 15.47-9.66c2.14.94-2.99 10.69-2.99 10.69s9.39-7.1 15.13-4.53c6.5 2.91-7.52 11.45-7.52 11.45s4.26-2.86 8.46-3.12c2.93-.17 4.63 3.28 2.74 5.51-1.74 2.07-5.39 4.89-7.57 7.15'
      />
      <path
        className='bunny_svg__st1'
        d='M241.17 69.19c0 6.2 11.6-2.22 11.7-2.22.34-1.2-11.7 3.03-11.7 2.22zM186.88 69.53c2.01-3.69 5.8-1.64 8.43.51 1.28 1.05 3.23.41 3.65-1.2 1.03-4.03 3.23-10.1 7.62-12.75-.1-3.21-.26-5.47-1.31-5.11-8.38 2.91-10.17 17.52-10.17 17.52s-8.01-9.27-11.28-3.25c-1.28 2.35.58 5.03 2.86 7.17-.32-.98-.3-1.97.2-2.89zM211.03 69.54c2.18-5.3 8.52-16.98 12.48-18.75-3.01-12.69-16.85 17.92-16.85 17.95.06 2.45 3.43 3.07 4.37.8zM225.03 63.47c3.28-4.39 9.09-10 13.31-10.02.54-1.78-8.05-5.47-16.1 8.93-.77 1.39 1.85 2.36 2.79 1.09zM239.34 64.46c3.79-1.97 8.76-4.1 11.94-3.72.83-1.51-3.28-6.03-15.33.93-.01.03-.01.06-.03.1-.5 1.99 1.59 3.63 3.42 2.69z'
      />
      <path d='M188.98 77.92c-.43 0-.87-.13-1.25-.4-3.48-2.51-8.61-8.13-5.81-13.28 1.26-2.33 3.06-2.98 4.34-3.12 2.66-.28 5.44 1.42 7.48 3.11 1.24-4.96 4.22-12.98 10.81-15.26.83-.29 1.71-.17 2.41.31 1.59 1.1 1.66 3.47 1.77 7.76.02.74.04 1.52.07 2.32 2.81-6.94 7.55-15.47 13.89-15.47.76 0 1.47.33 1.98.93.38.45 1.35 1.58.88 7.87 3.28-3.33 7.93-6.16 13.22-3.84.74.33 1.3.95 1.58 1.75.46 1.33.03 3.43-.73 5.62 3.45-1.6 7.77-2.79 11.3-1.21 2.45 1.09 2.91 2.87 2.98 3.83.05.79-.15 2.29-1.75 4.4 1.69.29 3.13 1.35 3.93 2.95 1 2 .72 4.3-.72 6.01-1.11 1.31-1.88 1.79-2.57 2.2-.54.33-1 .61-1.88 1.52-.82.85-2.17.88-3.02.06-.85-.82-.88-2.17-.06-3.02 1.27-1.32 2.09-1.81 2.75-2.21.54-.32.86-.52 1.52-1.3.47-.56.27-1.12.16-1.34-.11-.21-.45-.71-1.14-.66-2.47.15-5.23 1.47-6.69 2.32-.26.16-.52.33-.79.49-.99.6-2.29.31-2.91-.67-.63-.98-.36-2.28.61-2.93.04-.03.28-.19.68-.42 3.98-2.53 7.57-5.85 7.62-7.07-.03 0-.16-.11-.45-.24-3.74-1.67-10.79 2.64-12.97 4.29-.8.6-1.91.57-2.67-.08-.76-.65-.97-1.74-.51-2.62 1.62-3.07 2.97-6.49 3.23-8.01-6.12-1.62-11.87 8.87-11.93 8.98a2.14 2.14 0 01-2.57 1.03c-.99-.33-1.6-1.33-1.43-2.36.68-4.26 1.18-9.34 1.08-11.7-4.51 1.84-9.24 12.66-11.35 20.54-.24.9-1.04 1.54-1.97 1.58-.93.05-1.78-.52-2.1-1.4-1.31-3.54-1.43-8.24-1.53-12.01-.03-1.05-.06-2.33-.13-3.33-4.66 3.12-6.7 11.61-7.12 14.95-.1.84-.69 1.54-1.5 1.78s-1.68-.01-2.24-.64c-1.68-1.94-4.97-4.73-6.74-4.54-.18.02-.58.06-1.05.91-1.42 2.61 2.93 6.59 4.56 7.78.95.69 1.17 2.03.48 2.98-.43.55-1.09.86-1.75.86z' />
      <path d='M202.73 63.02s-2.96 3.52-1.14 8.33c.14.37.61.5 1 .44.8-.1 1.21-1.04.7-1.68-1-1.3-1.65-3.09-.56-7.09zM232.21 58.46s-4.1 2.01-4.46 7.14c-.03.38.34.7.71.8.77.23 1.51-.47 1.32-1.25-.36-1.59-.2-3.49 2.43-6.69zM248 70.97s-1.07 2.52-4.1 2.91c-.23.03-.43-.19-.5-.4-.16-.44.23-.91.7-.83.95.15 2.07 0 3.9-1.68zM259.11 213.41s-51.28 14.45-87.55-7.94c0 0 31.4 30.91 87.55 7.94zM241.61 97.79c4.13-5.73 11.4-8.59 18.32-8.28 8.77-.1 17.32 2.52 24.55 7.39l-.26.5-2.64-.88c-5.92-2.07-12.36-2.89-18.6-2.35-6.38.97-13.61 3.03-21.03 4.07-.02.02-.34-.45-.34-.45zM200.12 98.26c-3.74-.6-7.18-1.32-10.79-1.97-3.42-.54-6.7-1.83-10.24-2.1-6.25-.54-12.68.28-18.6 2.35l-2.64.88-.26-.5c7.23-4.87 15.78-7.49 24.55-7.39 4.96-.23 10 1.14 14.04 4.07 1.62 1.15 3.13 2.48 4.28 4.21l-.34.45z' />
      <path
        className='bunny_svg__st8'
        d='M328.7 194.46s-27.07 47.02-68.39 48.44l5.7-15.67c13.25-6.63 28.49-18.52 34.19-35.62.01 0 17.1 7.13 28.5 2.85z'
      />
      <path d='M260.31 245.75c-.91 0-1.77-.44-2.31-1.17-.56-.77-.7-1.76-.37-2.65l5.7-15.67c.25-.68.75-1.25 1.4-1.57 7.74-3.87 26.41-14.92 32.76-33.97a2.85 2.85 0 013.79-1.73c.16.07 16.21 6.63 26.4 2.81 1.12-.42 2.4-.09 3.17.83.78.92.9 2.22.3 3.27-.28.49-7.09 12.22-19.15 24.28-16.13 16.13-33.98 24.98-51.6 25.59-.02-.02-.06-.02-.09-.02zm8-16.49l-3.81 10.47c28.69-3.35 49.96-29.5 58.1-41.14-7.99.55-16.45-1.85-20.71-3.31-7.47 18.58-25.11 29.59-33.58 33.98z' />
      <g>
        <path
          d='M170.56 225.81l-14.25-37.04s-38.67 10.54-59.5-8.79c0 0 16.76 44.41 73.75 57.23v-11.4z'
          fill='#c58ed0'
        />
        <path d='M170.56 240.06c-.21 0-.42-.02-.63-.07-57.82-13.01-75.08-57.13-75.79-59a2.842 2.842 0 011.08-3.37 2.85 2.85 0 013.53.28c19.44 18.04 56.44 8.23 56.81 8.13 1.4-.38 2.88.36 3.41 1.73l14.25 37.04c.13.33.19.67.19 1.02v11.4c0 .87-.39 1.68-1.07 2.22-.51.4-1.14.62-1.78.62zm-65.93-51.35c8.16 13.05 26.96 35.64 63.08 44.88v-7.25l-13.16-34.22c-3.73.82-11.23 2.19-20.13 2.35-11.56.21-21.67-1.77-29.79-5.76z' />
      </g>
      <path
        className='bunny_svg__st1'
        d='M338.81 184.44c-6.73-3.04-14.46-.48-17.28 5.72-1.93 4.26-1.13 9.23 1.91 12.77 5.16 6 12.04 3.38 15.22 1.51.32-.18-2.85 1.42 4.27-2.85l9.97 4.27c.02-15.67-14.09-21.42-14.09-21.42z'
      />
      <g>
        <path
          className='bunny_svg__st10'
          d='M199.1 174.44s20.61 29.28 42.73 0'
        />
        <path d='M220.92 189.59h-.35c-13.43-.23-22.83-13.36-23.22-13.92l3.49-2.46c.08.12 8.57 11.93 19.82 12.11 6.77.14 13.28-3.99 19.45-12.16l3.41 2.58c-6.94 9.19-14.54 13.85-22.6 13.85z' />
        <g>
          <path
            className='bunny_svg__st10'
            d='M211.38 148.81c-7.45.36-17.57 8.05-18.09 14.3-.61 7.35 6.4 14.67 14.95 13.49 12.12-1.68 23.68 0 24.83 0 8.63 0 16.73-6.13 16.1-13.49-.53-6.25-10.63-13.93-18.09-14.3'
          />
          <path d='M206.43 178.87c-3.89 0-7.66-1.49-10.57-4.25-3.29-3.12-5.05-7.49-4.7-11.68.66-7.77 12.08-15.88 20.12-16.26l.2 4.27c-6.48.31-15.64 7.35-16.07 12.35-.24 2.91 1.02 5.99 3.38 8.22 2.48 2.35 5.81 3.43 9.14 2.97 10.37-1.44 20.24-.48 23.95-.12.59.06 1 .1 1.19.1 4.39 0 8.81-1.83 11.51-4.77 1.25-1.36 2.68-3.57 2.44-6.4-.42-4.99-9.58-12.03-16.07-12.35l.21-4.27c8.05.4 19.46 8.51 20.12 16.26.29 3.41-.97 6.84-3.55 9.65-3.54 3.86-9.03 6.16-14.68 6.16-.22 0-.76-.05-1.58-.13-3.58-.35-13.08-1.26-22.95.1-.69.1-1.39.15-2.09.15z' />
          <path d='M234.56 146.96c-.51 3.75-6.43 14.13-14.35 14.13s-13.69-10.37-13.34-14.13c.95-10.14 28.87-8.62 27.69 0z' />
          <path d='M220.36 177.05a2.134 2.134 0 01-2.03-2.81c1.22-3.64 1.31-7.43.27-11.28-1.06-3.91-2.94-6.45-2.95-6.48-.7-.94-.51-2.28.43-2.99.94-.71 2.28-.52 2.99.42.1.13 2.38 3.19 3.67 7.93 1.26 4.67 1.15 9.3-.34 13.75-.31.9-1.15 1.46-2.04 1.46zM206.31 161.32c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08zM201.27 165.03c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08zM207.65 169.73c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08zM212.35 165.7c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08zM235.62 161.22c1.98 0 1.98-3.08 0-3.08s-2 3.08 0 3.08zM230.57 164.91c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08zM236.96 169.61c1.98 0 1.98-3.08 0-3.08-1.98.01-1.98 3.08 0 3.08zM241.66 165.58c1.98 0 1.98-3.08 0-3.08s-1.98 3.08 0 3.08z' />
        </g>
      </g>
      <circle
        transform='rotate(-15.017 186.552 155.307)'
        className='bunny_svg__st11'
        cx={186.52}
        cy={155.28}
        r={13.53}
      />
      <path
        className='bunny_svg__st12'
        d='M199.72 148.81c-1.03-1.33-5.17-3.91-6.71-4.81l-13.63 23.39c1.54.9 6.03 2.87 7.69 3.11l12.65-21.69z'
      />
      <path d='M186.52 170.96c-8.64 0-15.67-7.03-15.67-15.67s7.03-15.67 15.67-15.67 15.67 7.03 15.67 15.67-7.03 15.67-15.67 15.67zm0-27.07c-6.28 0-11.4 5.11-11.4 11.4 0 6.28 5.11 11.4 11.4 11.4 6.28 0 11.4-5.11 11.4-11.4s-5.12-11.4-11.4-11.4z' />
      <g>
        <path
          className='bunny_svg__st8'
          d='M223.15 235.48s14.46-6.57 36.84-12.27c3.12-.79 6.25 1.32 6.01 4.03-.41 4.63-1.86 10.01-9.68 19.55 4.2 4.5 6.77 9.91 7.4 15.55l.03.3c.44 2.59-2.24 4.78-5.34 4.34-7.5-1.07-21.35-5.86-33.92-15.16'
        />
        <path d='M259.21 269.91c-.4 0-.8-.03-1.2-.08-8.63-1.22-22.86-6.55-35.21-15.69l3.39-4.58c13.2 9.76 26.72 13.79 32.63 14.63.91.13 1.6-.2 1.92-.53.25-.26.22-.44.21-.51l-.06-.5c-.55-5.01-2.85-9.83-6.64-13.89l-1.7-1.82 1.58-1.93c7.7-9.4 8.71-14.22 9.05-18 .02-.24-.2-.47-.33-.59-.4-.35-1.21-.66-2.14-.43-21.84 5.55-36.22 12.03-36.36 12.1l-2.36-5.19c.6-.27 14.95-6.75 37.32-12.43 2.58-.65 5.29-.05 7.24 1.62 1.65 1.41 2.5 3.39 2.31 5.43-.41 4.65-1.81 10.21-8.81 19.23 3.67 4.56 5.92 9.81 6.52 15.31l.03.25c.29 1.93-.35 3.87-1.76 5.33-1.46 1.45-3.49 2.27-5.63 2.27z' />
        <path d='M248.83 252.43h-.08c-1.5-.16-14.82-1.66-21.3-4.28-1.16-.47-1.93-1.38-2.05-2.44-.11-.98.34-1.9 1.24-2.52 5.6-3.86 14.95-3.64 15.35-3.63.39.01.7.34.69.73-.01.39-.34.7-.73.69-.09 0-9.29-.22-14.5 3.37-.46.32-.68.74-.63 1.18.06.53.5 1.01 1.17 1.28 5.12 2.07 15.56 3.6 20.92 4.18.39.04.67.39.63.78-.04.39-.35.66-.71.66z' />
        <path
          className='bunny_svg__st8'
          d='M204.72 234.78s-12.86-7.37-34.67-14.79c-3.04-1.03-6.35.82-6.35 3.56.02 4.64.99 9.8 7.94 19.94-4.58 4.15-7.61 9.34-8.74 14.93l-.06.3c-.67 2.56 1.81 4.95 4.95 4.75 7.58-.47 21.79-4.17 35.12-12.43'
        />
        <path d='M167.43 266.33c-2.39 0-4.63-1-6.07-2.75-1.3-1.58-1.77-3.57-1.3-5.49l.05-.24c1.1-5.45 3.79-10.51 7.84-14.75-5.83-8.96-7.09-14.35-7.11-19.54 0-2.07 1.02-3.98 2.79-5.25 2.08-1.49 4.82-1.87 7.32-1.02 21.82 7.43 34.63 14.71 35.17 15.02l-2.83 4.95c-.13-.07-12.92-7.33-34.17-14.57-.91-.31-1.73-.06-2.17.26-.19.13-.41.35-.41.6.01 3.8.6 8.35 7.44 18.32l1.41 2.05-1.84 1.67c-4.13 3.75-6.85 8.37-7.86 13.38l-.1.46c-.02.08-.06.25.16.52.28.34.93.72 1.85.67 5.96-.37 19.78-3.32 33.8-12.01l3 4.84c-13.11 8.13-27.76 12.31-36.45 12.85-.16.02-.34.03-.52.03z' />
        <path d='M178.69 249.69c-.38 0-.7-.3-.71-.69-.01-.39.3-.72.69-.73 6.75-.22 16.35-1.01 21.21-2.54.71-.22 1.2-.67 1.3-1.21.08-.43-.1-.87-.53-1.22-4.85-3.95-14.12-3.42-14.22-3.42-.43.05-.73-.28-.76-.67-.03-.39.27-.73.67-.76.4-.03 9.93-.58 15.21 3.73.84.69 1.21 1.63 1.03 2.59-.2 1.05-1.05 1.91-2.27 2.29-4.99 1.57-14.75 2.38-21.59 2.6-.01.03-.02.03-.03.03z' />
        <g>
          <path
            className='bunny_svg__st8'
            d='M218.25 255.02l-9.85-.38c-4.03-.15-7.15-3.35-7-7.12l.49-11.03c.11-2.53 2.37-4.47 5.07-4.37l14.7.58c2.69.11 4.78 2.24 4.67 4.75l-.49 11.03c-.17 3.76-3.58 6.7-7.59 6.54z'
          />
          <path d='M218.55 257.88c-.14 0-.27 0-.41-.01l-9.85-.38c-2.79-.11-5.35-1.26-7.2-3.27-1.74-1.88-2.64-4.3-2.54-6.82l.49-11.04c.17-4.08 3.79-7.24 8.03-7.09l14.7.58c2.12.08 4.07.97 5.47 2.5a7.198 7.198 0 011.93 5.22l-.49 11.04c-.23 5.19-4.73 9.27-10.13 9.27zm-.2-5.7c2.45.09 4.54-1.62 4.64-3.82l.49-11.03c.02-.51-.22-.88-.43-1.11-.37-.4-.92-.64-1.51-.67l-14.7-.58c-1.13-.05-2.07.69-2.11 1.64l-.49 11.04c-.04.98.33 1.95 1.03 2.71.82.88 1.96 1.39 3.23 1.44l9.85.38z' />
        </g>
      </g>
      <g>
        <circle className='bunny_svg__st13' cx={286.3} cy={267.37} r={75.35} />
        <path d='M286.3 344.85c-42.72 0-77.48-34.76-77.48-77.48 0-42.72 34.76-77.48 77.48-77.48 42.72 0 77.48 34.76 77.48 77.48.01 42.72-34.75 77.48-77.48 77.48zm0-150.69c-40.37 0-73.21 32.84-73.21 73.21 0 40.37 32.84 73.21 73.21 73.21 40.37 0 73.21-32.84 73.21-73.21 0-40.37-32.84-73.21-73.21-73.21z' />
        <path
          className='bunny_svg__st14'
          d='M310.54 175.41c0 5.22-5.24 8.27-11.69 8.27s-11.69-3.06-11.69-8.27 5.24-9.44 11.69-9.44 11.69 4.23 11.69 9.44z'
        />
        <path d='M298.85 185.82c-8.14 0-13.83-4.22-13.83-10.27 0-6.31 6.21-11.45 13.83-11.45s13.83 5.13 13.83 11.45c0 6.05-5.69 10.27-13.83 10.27zm0-17.44c-5.27 0-9.56 3.22-9.56 7.17 0 4.12 4.95 6 9.56 6 4.6 0 9.56-1.88 9.56-6 0-3.96-4.29-7.17-9.56-7.17z' />
        <path
          className='bunny_svg__st13'
          d='M306.72 190.91a15.06 15.06 0 00-15.74 0v-8.95a15.06 15.06 0 0115.74 0v8.95z'
        />
        <path d='M308.86 194.72l-3.25-1.99a12.886 12.886 0 00-13.51 0l-3.25 1.99v-13.96l1.02-.62a17.147 17.147 0 0117.97 0l1.02.62v13.96zm-10.01-8.18c1.94 0 3.88.33 5.73.99v-4.32c-3.62-1.8-7.84-1.8-11.47 0v4.32c1.86-.66 3.8-.99 5.74-.99z' />
        <circle className='bunny_svg__st14' cx={299.69} cy={267.37} r={79.39} />
        <path d='M299.69 348.61c-44.8 0-81.24-36.45-81.24-81.25s36.45-81.24 81.24-81.24c44.8 0 81.25 36.45 81.25 81.24 0 44.81-36.45 81.25-81.25 81.25zm0-158.78c-42.76 0-77.54 34.78-77.54 77.54 0 42.76 34.78 77.54 77.54 77.54 42.76 0 77.54-34.78 77.54-77.54 0-42.76-34.78-77.54-77.54-77.54z' />
        <circle className='bunny_svg__st1' cx={299.69} cy={267.68} r={61.65} />
        <path
          className='bunny_svg__st15'
          d='M330.22 214.12l-64.86 104.77a61.383 61.383 0 0031.45 10.37l56.72-91.62a61.87 61.87 0 00-23.31-23.52zM311.54 207.18l-60.72 98.08a62.12 62.12 0 009.51 9.87l64.28-103.84a60.084 60.084 0 00-13.07-4.11z'
        />
        <path d='M299.69 331.46c-35.17 0-63.78-28.61-63.78-63.78s28.61-63.78 63.78-63.78 63.78 28.61 63.78 63.78-28.61 63.78-63.78 63.78zm0-123.29c-32.81 0-59.51 26.7-59.51 59.51s26.7 59.51 59.51 59.51 59.51-26.7 59.51-59.51-26.7-59.51-59.51-59.51z' />
        <g>
          <path d='M305.47 234.35l-5.48-12.04c-.05-.12-.22-.12-.28 0l-5.48 12.04a.15.15 0 00.03.17l3.86 3.86c.03.03.04.07.04.11v30.59c0 .08.07.15.15.15h3.07c.08 0 .15-.07.15-.15v-30.59c0-.04.02-.08.04-.11l3.86-3.86c.05-.04.06-.11.04-.17z' />
          <path d='M301.38 269.95h-3.07a.86.86 0 01-.86-.86v-30.36l-3.7-3.7a.89.89 0 01-.18-.97l5.48-12.04c.29-.62 1.29-.61 1.57 0l5.48 12.04c.15.33.08.72-.18.97l-3.7 3.7v30.36c.03.47-.36.86-.84.86zm-2.51-1.43h1.95v-30.34l.27-.32 3.57-3.57-4.81-10.57-4.81 10.57 3.79 3.79.04.4v30.04z' />
        </g>
        <g>
          <path d='M320.7 301.18l13.01 6.15c.13.06.27-.06.23-.19l-3.9-13.86c-.02-.07-.08-.11-.15-.12l-5.92-.49c-.04 0-.08-.02-.11-.06l-21.49-25.42a.16.16 0 00-.23-.02l-2.55 2.16a.16.16 0 00-.02.23l21.49 25.42c.03.03.04.08.04.12l-.49 5.92c0 .07.03.13.09.16z' />
          <path d='M333.79 308.06c-.13 0-.25-.03-.37-.08l-13.01-6.15a.868.868 0 01-.5-.88l.47-5.68-21.35-25.24a.872.872 0 01.1-1.23l2.78-2.35.41-.02c.23.02.45.13.6.31l21.35 25.24 5.69.47c.37.04.68.3.77.64l3.9 13.85c.09.33-.01.68-.28.91-.16.14-.36.21-.56.21zm-.53-.73zm-11.91-6.63l11.58 5.47-3.48-12.34-5.83-.49-.3-.29-21.14-24.99-1.71 1.45 21.33 25.23.01.42-.46 5.54zm-.03.36v0zm8.03-7.59c.01.01.01.01 0 0zm-29.24-24.37c.01 0 .01 0 0 0zm2.5-1.39c-.01.01-.01.01 0 0z' />
        </g>
        <g>
          <path className='bunny_svg__st16' d='M299.69 267.68l-23.49-18.79' />
          <path d='M299.69 269.82c-.47 0-.94-.15-1.33-.47l-23.49-18.79a2.132 2.132 0 01-.33-3c.74-.92 2.08-1.07 3-.33l23.49 18.79c.92.74 1.07 2.08.33 3-.42.52-1.04.8-1.67.8z' />
        </g>
        <g>
          <circle
            className='bunny_svg__st16'
            cx={299.69}
            cy={267.68}
            r={4.67}
          />
          <path d='M299.69 274.49c-3.75 0-6.81-3.05-6.81-6.81 0-3.75 3.05-6.81 6.81-6.81 3.75 0 6.81 3.05 6.81 6.81 0 3.75-3.06 6.81-6.81 6.81zm0-9.34c-1.4 0-2.53 1.14-2.53 2.53s1.14 2.53 2.53 2.53 2.53-1.14 2.53-2.53-1.13-2.53-2.53-2.53z' />
        </g>
      </g>
      <path
        className='bunny_svg__st1'
        d='M338.81 184.44c-6.73-3.04-14.46-.48-17.28 5.72-1.93 4.26-1.13 9.23 1.91 12.77 5.16 6 12.04 3.38 15.22 1.51.32-.18-1.42-2.85 5.7-7.12l8.55 8.55c.01-15.68-14.1-21.43-14.1-21.43z'
      />
      <path d='M352.77 209.93c-1.14 0-2.09-.9-2.13-2.05-.67-17.19-12.39-21.38-12.51-21.42-.07-.02-.13-.05-.19-.08-5.65-2.54-12.13-.46-14.45 4.65-1.96 4.34.25 7.98 1.8 9.8 2.03 1.99 4.63 2.19 6.45 2.01 3.79-.38 7.41-2.63 8.72-4.59a2.13 2.13 0 012.96-.59c.98.66 1.25 1.98.59 2.96-2.19 3.29-7.18 6.01-11.86 6.47-3.97.39-7.4-.75-9.95-3.3-.04-.04-.08-.08-.11-.12-3.76-4.37-4.67-9.63-2.5-14.42 3.28-7.23 12.25-10.28 20.02-6.82 1.45.53 14.54 5.86 15.3 25.26.05 1.18-.87 2.17-2.05 2.22-.04.02-.06.02-.09.02z' />
      <circle cx={270.84} cy={148.12} r={13.5} />
      <circle className='bunny_svg__st11' cx={257.75} cy={155.28} r={13.53} />
      <path
        className='bunny_svg__st12'
        d='M246.83 163.25c.13.17.25.35.38.51.3.26.54.56.77.87.66.69-.03 1.32.76 1.86l12.63-21.67c-.5-.64.7-1.57.43-2.46-.8-.25-1.64-.42-2.5-.52l-12.47 21.41zM264.63 144.29l-13.34 22.89c1.92 1.04 4.12 1.64 6.46 1.64.42 0 .83-.03 1.24-.06l10.21-17.52c-1.03-1.33-3.03-6.05-4.57-6.95z'
      />
      <path d='M257.75 170.96c-8.64 0-15.67-7.03-15.67-15.67s7.03-15.67 15.67-15.67 15.67 7.03 15.67 15.67c.01 8.64-7.02 15.67-15.67 15.67zm0-27.07c-6.28 0-11.4 5.11-11.4 11.4 0 6.28 5.11 11.4 11.4 11.4 6.28 0 11.4-5.11 11.4-11.4s-5.11-11.4-11.4-11.4z' />
    </svg>
  );
}

export default SvgBunny;
