import * as React from 'react';

function SvgX(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 22.45 22.45'
      {...props}>
      <g
        fill='none'
        stroke={props.fill ?? '#fff'}
        strokeWidth={3}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}>
        <path d='M1.5 1.5l19.45 19.45M20.95 1.5L1.5 20.95' />
      </g>
    </svg>
  );
}

export default SvgX;
