import * as React from 'react';

function SvgLootPresent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='loot-present_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 200 153.66'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.loot-present_svg__st2{fill:none;stroke:#000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.loot-present_svg__st3{fill:#dafccf}.loot-present_svg__st5{fill:#ff817f}'
        }
      </style>
      <path
        className='loot-present_svg__st2'
        d='M106.23 18.87l-5.55 14.52 14.52 5.55c8.02 3.06 17.01-.95 20.07-8.98 3.06-8.02-.95-17.01-8.98-20.07-8.02-3.05-17 .96-20.06 8.98zM93.77 18.87l5.55 14.52-14.52 5.55c-8.02 3.06-17.01-.95-20.07-8.98-3.06-8.02.95-17.01 8.98-20.07 8.02-3.05 17 .96 20.06 8.98z'
      />
      <path
        className='loot-present_svg__st3'
        d='M169.63 60.41H30.37V40.18c0-4.62 3.75-8.37 8.37-8.37h122.53c4.62 0 8.37 3.75 8.37 8.37v20.23zM160.89 60.41H39.11v75.13c0 5.11 4.14 9.24 9.24 9.24h103.29c5.11 0 9.24-4.14 9.24-9.24V60.41z'
      />
      <path className='loot-present_svg__st2' d='M85.7 31.8h28.6v28.6H85.7z' />
      <path
        d='M160.89 60.41H39.11v75.13c0 5.11 4.14 9.24 9.24 9.24h5.56V85.67c0-6.52 5.29-11.81 11.81-11.81h95.16V60.41z'
        fill='#c3e8b7'
      />
      <path
        className='loot-present_svg__st2'
        d='M160.89 60.41H39.11v75.13c0 5.11 4.14 9.24 9.24 9.24h103.29c5.11 0 9.24-4.14 9.24-9.24V60.41z'
      />
      <path
        className='loot-present_svg__st5'
        d='M88.73 60.41h22.54v84.38H88.73zM85.7 31.8h28.6v28.6H85.7z'
      />
      <path className='loot-present_svg__st2' d='M85.7 31.8h28.6v28.6H85.7z' />
      <path fill='#ff6361' d='M88.73 60.41h22.54v13.44H88.73z' />
      <path
        className='loot-present_svg__st2'
        d='M88.73 60.41h22.54v84.38H88.73zM169.63 60.41H30.37V40.18c0-4.62 3.75-8.37 8.37-8.37h122.53c4.62 0 8.37 3.75 8.37 8.37v20.23z'
      />
      <path
        className='loot-present_svg__st2'
        d='M78.82 31.84c-3.44 1.25-6.91 2.42-9.16 7.14-4.42 9.22 4.62 13.56.2 22.78S56.4 66.65 51.97 75.87c-3.14 6.55.51 10.64 1.31 15.76'
      />
    </svg>
  );
}

export default SvgLootPresent;
