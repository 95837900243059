import { ethers } from 'ethers';
import React, { FunctionComponent } from 'react';
import WalletContext from './WalletContext';
import WalletSlice from './WalletSlice';
import { useAppDispatch, useWalletSelector } from '../hooks';
import currentEdition from '../blockchain/currentEdition.json';
import { getTransactionErrorMessage } from './helpers';
import { IFurballContext, IFurballEdition } from './WalletTypes';
import WalletEvents from './WalletEvents';

import { loadBlockchainNetwork } from '../blockchain';
import BlockchainBannerDialog from '../blockchain/BlockchainBannerDialog';
import { disconnectAuth } from '../components/Auth/AuthContext';
import usePromise from 'react-promise-suspense';
import { useFurComponent } from '../utils/furComponent';
import { IFurPlayerLinkable } from '../components/Players/PlayerLink';

type Props = {
  children: React.ReactNode;
};

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function usePlayerSlug(p?: IFurPlayerLinkable | null) {
  return useWalletSelector((s) => {
    const c = p ?? s.contents;
    return encodeURIComponent(c?.username ?? c?.id ?? '');
  });
}

const Wallet: FunctionComponent<Props> = (props) => {
  const eth = window.ethereum;
  const dispatch = useAppDispatch();
  const { log } = useFurComponent(Wallet);

  async function loadWallet(ctxt?: IFurballContext, addr?: string) {
    // Always start loading, for globoal
    dispatch(WalletSlice.actions.setLoading(true));
    dispatch(WalletSlice.actions.setWalletContents(undefined));

    if (!ctxt) {
      dispatch(WalletSlice.actions.setLoading(false));
      return;
    }

    if (!addr) {
      try {
        const accts = await ctxt.provider.listAccounts();
        addr = accts.length > 0 ? accts[0] : undefined;

        const walletAddr = addr ? ethers.utils.getAddress(addr) : undefined;
        const { contracts } = ctxt.network;

        log.info('Addr', walletAddr, !!contracts);
        dispatch(WalletSlice.actions.setAddress(addr));

        const { purchaseFur, maxSize, maxAdoptable } = currentEdition;
        const edition: IFurballEdition = {
          // count,
          // minted,
          // maxMintable,
          // liveAt,
          purchaseFur,
          maxSize,
          maxAdoptable,
        };

        dispatch(WalletSlice.actions.setEdition(edition));
        dispatch(WalletSlice.actions.setLoading(false));
      } catch (e) {
        log.error(e);
        log.error('loading', getTransactionErrorMessage(log, e));
      } finally {
        dispatch(WalletSlice.actions.setLoading(false));
      }
    }
  }

  // React.useEffect(() => {
  //   if (context) {
  //     void loadWallet();
  //   }
  // }, [context]);

  async function loadNetworkContext(
    networkId?: number,
  ): Promise<IFurballContext | undefined> {
    if (inIframe() || !eth) {
      log.info('skipping web3 network context');
      dispatch(WalletSlice.actions.setLoading(false));
      dispatch(WalletSlice.actions.setAddress(undefined));
      return undefined;
    }

    const provider = new ethers.providers.Web3Provider(eth);
    const network = await loadBlockchainNetwork(provider, networkId);
    const ctxt: IFurballContext = { network, provider };
    // log.info('setting web3 context', ctxt.network.name);

    return ctxt;
  }

  function onConnect(): void {
    dispatch(WalletSlice.actions.setConnected(true));
  }

  function onDisconnect(ctxt?: IFurballContext): void {
    dispatch(WalletSlice.actions.setConnected(false));
    if (ctxt) disconnectAuth(ctxt.network.name);
  }

  async function onAccountsChanged(
    ctxt: IFurballContext | undefined,
    v: string[],
  ) {
    // screw it. full reload is safer when we change users underneath ourserves.
    window.location.reload();

    // await loadWallet(ctxt, v.length > 0 ? v[0] : undefined);
    // if (context) disconnectAuth(context.network.name);
  }

  async function onChainChanged(id: string) {
    // screw it. full reload is safer when we change core settings.
    window.location.reload();

    // dispatch(WalletSlice.actions.setConnected(false));
    // await loadNetworkContext(parseInt(id.substr(2), 16));
    // dispatch(WalletSlice.actions.setConnected(true));
    // await loadWallet(ctxt, undefined);
  }

  const ctxt = usePromise(async () => {
    // React.useEffect(() => {
    log.info('load blockchain');

    if (inIframe() || !eth) {
      dispatch(WalletSlice.actions.setLoading(false));
      return undefined;
    } else {
      eth.on('connect', onConnect);
      eth.on('disconnect', () => onDisconnect(ctxt));
      eth.on('accountsChanged', (v: string[]) => onAccountsChanged(ctxt, v));
      eth.on('chainChanged', onChainChanged);

      const ctxt = await loadNetworkContext();
      log.info('loaded network');

      await loadWallet(ctxt, undefined);
      log.info('loaded wallet');

      return ctxt;
    }

    // return function () {
    //   // eth.off('connect', onConnect);
    //   // eth.off('disconnect', onDisconnect);
    //   // eth.off('accountsChanged', onAccountsChanged);
    //   // eth.off('chainChanged', onChainChanged);
    // };
  }, [eth]);

  function clearError() {
    window.location.reload();
  }

  return (
    <WalletContext.Provider value={ctxt}>
      <WalletEvents>{props.children}</WalletEvents>
      <BlockchainBannerDialog
        error={ctxt?.network.subdomainMismatch}
        onClose={() => clearError}
      />
    </WalletContext.Provider>
  );
};

export default Wallet;
