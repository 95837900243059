import React, { FunctionComponent, useEffect } from 'react';
import {
  Drawer,
  Theme,
  Box,
  Button,
  // Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
} from '@mui/material';
import { ClassNameMap, makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';

import clsx from 'clsx';
import WalletButton from './wallet/WalletButton';

import SvgIconCaretDown from './assets/SvgIconCaretDown';
import { useWalletSelector } from './hooks';
import theme, { white, black, darkBlue } from './themev2';
import { useHeaderMenuOptions } from './headerMenu';
import { useAuthContext } from './components/Auth/AuthContext';
import { useAppBarHeight } from './components/useCommonStyles';
import { useClientHardware } from './pages/App/clientHardware';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: 'rgba(0, 0, 0, 0.0) !important',
  },
  menuContent: {
    backgroundColor: theme.palette.secondary.light,
    marginTop: theme.mixins.toolbar.height,
    width: '92.5vw',
    margin: '0 auto',
    padding: '50px',
    borderBottomRightRadius: '30px',
    borderBottomLeftRadius: '30px',
  },
  divider: {
    backgroundColor: white,
    minHeight: '2px',
    opacity: '0.3',
  },
  a: {
    paddingLeft: '7.5px',
    color: `${white} !important`,
    fontWeight: 400,
  },
  link: {
    margin: '15px 0 !important',
  },
  buttonCont: {
    minWidth: '100%',
    marginTop: '60px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    whiteSpace: 'nowrap',
    width: '275px',
    height: '55px',
    borderRadius: '50px !important',
    fontSize: '1.3rem !important',
  },
  accountButton: {
    color: `${white} !important`,
    backgroundColor: `${theme.palette.secondary.light} !important`,
    border: `4px solid ${white} !important`,
  },
  connectButton: {
    backgroundColor: theme.palette.primary.main,
    color: black,
    border: 'none !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'default',
    textDecoration: 'none',
    opacity: '0.5',
  },
}));

type Props = {
  isOpen: boolean;
  isSignedIn: boolean;
  headerText: (
    text: React.ReactNode,
    path: string,
    icon: string | null | undefined,
    classes: ClassNameMap,
    isDesktop?: boolean,
  ) => React.ReactNode;
  close: () => void;
};

const width = '100vw';

const MobileMenu: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const headerMenu = useHeaderMenuOptions();
  const username = useWalletSelector((s) => s.contents?.username);
  const { isSignedIn } = useAuthContext();
  const { cornerPadding } = useClientHardware();
  const barHeight = useAppBarHeight();

  useEffect(() => {
    return history.listen(() => props.close());
  }, []);

  function renderButton() {
    if (!isSignedIn) {
      return (
        <>
          <WalletButton
            className={clsx(classes.button, classes.connectButton)}
            analyticsPathComponent={'menu'}
          />
        </>
      );
    }

    return (
      <Button
        variant={'contained'}
        color={'primary'}
        className={clsx(classes.button, classes.accountButton)}
        onClick={() => history.push('/account')}>
        {username ?? '?'}'s Account
      </Button>
    );
  }

  function renderLinks() {
    return (
      <>
        {headerMenu.map((item, index) => (
          <Accordion
            key={index}
            sx={{
              borderRadius:
                index === headerMenu.length - 1 ? '0 0  20px 20px' : undefined,
              overflow: 'hidden',
            }}>
            <AccordionSummary
              expandIcon={<SvgIconCaretDown width='2rem' height='2rem' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
              onClick={(e) => {
                if (
                  item.subMenu.length === 0 &&
                  (item.url?.startsWith('/') ?? false)
                ) {
                  e.preventDefault();
                  history.push(item.url!);
                  return false;
                }
              }}>
              <Typography fontWeight='500' color={darkBlue}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ borderTop: `1px solid ${theme.palette.grey[300]}` }}>
              <Grid
                container
                width='14rem'
                rowGap='0.2rem'
                padding='0 0.5rem'
                justifyContent='stretch'>
                {item.subMenu.map((menuItem, index) => (
                  <Grid container key={index}>
                    {props.headerText(
                      menuItem.title,
                      menuItem.url,
                      menuItem.icon,
                      classes,
                      true,
                    )}
                  </Grid>
                ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  }

  return (
    <Drawer
      classes={{ paper: classes.paper }}
      anchor={'top'}
      open={props.isOpen}
      onClose={() => props.close()}
      sx={{
        width,
        flexShrink: 0,
        ['& .MuiDrawer-paper']: { width, boxSizing: 'border-box' },
      }}>
      <Box
        className={classes.menuContent}
        boxShadow={3}
        sx={{ overflow: 'auto', marginTop: barHeight + 'px' }}>
        {renderLinks()}
        <Box className={classes.buttonCont}>{renderButton()}</Box>
      </Box>
    </Drawer>
  );
};

export default MobileMenu;
