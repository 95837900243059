import React, { FunctionComponent } from 'react';
import { Theme, Grid, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgLobbyHeartDisabled from '../../assets/SvgLobbyHeartDisabled';
import SvgLobbyHeart from '../../assets/SvgLobbyHeart';
import SvgUnbonded from '../../assets/bonding/SvgUnbonded';

const useStyles = makeStyles((theme: Theme) => ({
  heart: {
    display: 'grid',
    placeItems: 'center',
    width: '1rem',
    height: '1rem',
    paddingTop: '0.05rem',

    '&[data-disabled="true"]': {
      filter: 'brightness(60%) grayScale(50%)',
      borderColor: theme.palette.grey[800],
    },
  },
}));

interface IFurballBattleCount {
  maxDailyBattles: number;
  hearts: number;
}

interface IFurballHearts {
  furball: IFurballBattleCount;
  className?: string;
}

export const FurballHearts: FunctionComponent<IFurballHearts> = (props) => {
  const { furball, className } = props;
  const classes = useStyles();

  const maxFightsAllowed = furball.maxDailyBattles ?? 2; // bonded furballs get 3 battles
  const numBrokenHearts = Math.max(0, 3 - maxFightsAllowed);
  const fightsRemaining = furball.hearts;

  return (
    <Grid
      container
      gap='0.3rem'
      alignItems='center'
      justifyContent='center'
      className={className}>
      {[...Array(maxFightsAllowed)].map((_, index) => (
        <Tooltip
          key={index}
          title={
            fightsRemaining < index + 1
              ? 'This Furball fell in a fight today'
              : 'Represents a boss fight remaining today'
          }>
          <div
            className={classes.heart}
            data-disabled={fightsRemaining < index + 1}>
            {fightsRemaining < index + 1 ? (
              <SvgLobbyHeartDisabled />
            ) : (
              <SvgLobbyHeart />
            )}
          </div>
        </Tooltip>
      ))}
      {[...Array(numBrokenHearts)].map((_, index) => (
        <Tooltip
          key={index}
          title={'Bond this Furball to unlock more daily fights!'}>
          <div className={classes.heart} data-disabled={true}>
            <SvgUnbonded />
          </div>
        </Tooltip>
      ))}
    </Grid>
  );
};

export default FurballHearts;
