import { combineReducers, createStore } from '@reduxjs/toolkit';
import WalletSlice from './wallet/WalletSlice';
import timekeeperSlice from './components/Timekeeper/TimekeeperSlice';

export const store = createStore(
  combineReducers({
    wallet: WalletSlice.reducer,
    timekeeper: timekeeperSlice.reducer,
  }),
);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
