import * as React from 'react';

type CardProps = React.SVGProps<SVGSVGElement> & {
  backgroundColor?: string;
};

function SvgIconInventory(props: CardProps) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 22'
      enableBackground='new 0 0 20 22'
      xmlSpace='preserve'
      {...props}>
      <g>
        <path
          fill={props.fill}
          d='M18.23,17.6L20,15.83l-1.26-1.26l-0.57,0.57c-0.4,0.4-1.06,0.4-1.46,0c-0.4-0.4-0.4-1.06,0-1.46l0.57-0.57
		l-1.26-1.26l-1.77,1.77L9.47,8.84c1.34-2.07,1.26-4.69-0.35-6.3C7.23,0.65,3.93,0.87,1.78,3.02C0.76,4.04,0.14,5.34,0.02,6.68
		C-0.1,8.11,0.35,9.42,1.3,10.36c0.84,0.84,1.97,1.3,3.22,1.3c0.15,0,0.31-0.01,0.47-0.02c0.92-0.08,1.82-0.4,2.62-0.92l9.64,9.64
		c0.26,0.26,0.6,0.39,0.93,0.39c0.34,0,0.68-0.13,0.93-0.39c0.52-0.52,0.52-1.35,0-1.87L18.23,17.6z M4.75,9
		c-0.64,0.05-1.2-0.13-1.59-0.51S2.6,7.55,2.65,6.91c0.06-0.72,0.42-1.44,0.99-2.02c0.65-0.65,1.49-1,2.25-1
		c0.53,0,1.01,0.17,1.36,0.51c0.85,0.85,0.62,2.49-0.48,3.6C6.19,8.59,5.47,8.94,4.75,9z'
        />
      </g>
    </svg>
    // <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' {...props}>
    //   <path
    //     fill='none'
    //     d='M11.28 1.79H8.72a.56.56 0 00-.56.56v.91h3.69v-.91a.574.574 0 00-.57-.56z'
    //   />
    //   <path
    //     fill={`#${props.fill ?? 'FFFFFF'}`}
    //     d='M14.18 11.76v2.54c.44.16.77.59.77 1.08v1.05c0 .64-.52 1.16-1.16 1.16h-.53c-.64 0-1.16-.52-1.16-1.16v-1.05c0-.5.32-.92.77-1.08v-2.54H7.14v2.54c.44.16.77.59.77 1.08v1.05c0 .64-.52 1.16-1.16 1.16h-.53c-.64 0-1.16-.52-1.16-1.16v-1.05c0-.5.32-.92.77-1.08v-2.54H3.72c-.63 0-1.21-.2-1.69-.54v7.23c0 1.7 1.38 3.08 3.08 3.08h9.78c1.7 0 3.08-1.38 3.08-3.08v-7.23c-.48.34-1.06.54-1.69.54h-2.1zM8.16 2.35c0-.31.25-.56.56-.56h2.56c.31 0 .56.25.56.56v.91h1.32v-.91c0-1.04-.85-1.88-1.88-1.88H8.72c-1.04 0-1.88.85-1.88 1.88v.91h1.32v-.91zM5.82 9.89V3.92h-4.4V8.8c0 .59.23 1.13.6 1.54v-.45h3.8zM7.14 3.92h5.71v5.97H7.14zM17.97 9.89v.46c.37-.41.6-.95.6-1.54V3.92h-4.4v5.97h3.8zM7.14 9.89h5.71v1.21H7.14z'
    //   />
    //   <path
    //     fill={`#${props.fill ?? 'FFFFFF'}`}
    //     d='M2.03 9.89v.46c.42.46 1.02.76 1.69.76h2.11V9.89h-3.8zM14.18 9.89v1.21h2.11c.67 0 1.27-.3 1.69-.76v-.45h-3.8z'
    //   />
    // </svg>
  );
}

export default SvgIconInventory;
