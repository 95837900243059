import {
  CardHeader,
  Card,
  CardContent,
  Tabs,
  Tab,
  Typography,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { IHaveZone, ISelectedFurball } from './ZoneTypes';
import useCommonStyles from '../../components/useCommonStyles';
import clsx from 'clsx';
import {
  getFurballDisplayZone,
  getZone,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_SCHOLARSHIPS,
  ZONE_NUM_VOYAGE,
} from './ZoneHelpers';

import HorizontalScroller from '../HorizontalScroller';
import { cdnRoot, purple } from '../../theme';
import { IFurball } from '../../wallet/WalletTypes';
import { SelectableFurball } from '../Furballs';
import { useWalletSelector } from '../../hooks';
import { RealmType } from '../schema';
import SvgCheckBoxs03 from '../../assets/SvgUncheckedBox';
import SvgCheckBoxs02 from '../../assets/SvgCheckedBox';
import { useFurballs } from '../../wallet';
import AlertProvider from '../Alert/AlertProvider';
import { useScholarshipHelpers } from '../Scholarships';
import { fbHaveUpgradeCd, useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#141B76 !important',
    minWidth: '320px',
    '@media (max-width: 390px)': {
      minWidth: '305px',
    },
    '@media (max-width: 370px)': {
      minWidth: '290px',
    },
  },
  backgroundArea: {
    marginTop: '28px',
    paddingRight: '0px',
    backgroundSize: 'cover',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    maxHeight: '420px',
    position: 'relative',
    marginBottom: '-24px',
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  bgAreaNoMaxHeight: {
    maxHeight: '100% !important',
  },
  furballWrapper: {
    position: 'absolute',
    marginRight: '20px',
  },
  empty: {
    textAlign: 'center',
    color: 'black',
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
  },
  emptyDark: {
    color: 'white',
  },
  zoneLoading: {
    //     -webkit-filter: grayscale(1),
    // -webkit-filter: grayscale(100%);
    // -moz-filter: grayscale(100%);
    filter: 'grayscale(80%)',
  },
  cardContent: {
    position: 'relative',
  },
  cardLink: {
    color: `${purple.light} !important`,
    '&:hover': {
      color: 'white !important',
    },
  },
  [theme.breakpoints.down('sm')]: {
    backgroundArea: {
      minHeight: '300px',
    },
    furballWrapper: {
      bottom: '25px',
    },
  },
  [theme.breakpoints.up('sm')]: {
    root: {
      minWidth: '400px',
    },
    backgroundArea: {
      minHeight: '300px',
    },
  },
  [theme.breakpoints.up('lg')]: {
    root: {
      minWidth: '500px',
    },
    backgroundArea: {
      minHeight: '400px',
    },
    furballWrapper: {
      bottom: '35px',
    },
  },
  [theme.breakpoints.up('xl')]: {
    backgroundArea: {
      minHeight: '500px',
    },
    furballWrapper: {
      bottom: '45px',
    },
  },
  tabs: {
    zIndex: 1,
    width: '100%',
    position: 'absolute',
    top: '-4px',
    left: 0,
    right: 0,
    maxHeight: 40,
    backgroundColor: 'white',
    padding: '0 15px',
  },
  tab: {
    maxHeight: 40,
    minWidth: '110px !important',
    border: 'none !important',
    fontSize: '18px !important',
    color: '#212121 !important',
    flexDirection: 'row',

    '&.Mui-selected': {
      color: `${theme.palette.secondary.light} !important`,

      '& svg': {
        fill: `${theme.palette.secondary.light} !important`,
      },
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
    },
    '@media (max-width: 395px)': {
      fontSize: '13px !important',
      lineHeight: '13px !important',
    },
  },
  notice: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: 'red',
    color: 'white',
    position: 'absolute',
    top: 6,
    right: 0,
    paddingBottom: 4,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '3px 5px 6px 13px',
    fontSize: '20px !important',
    textTransform: 'uppercase',
    minHeight: '44px',
    maxHeight: '44px',

    [theme.breakpoints.down('sm')]: {
      padding: '3px 0 6px 0',
    },
  },
  emptyPlaceholder: {
    padding: '5px 12px',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',

    '& svg': {
      height: '23.5px',
      width: '23.5px',
      marginRight: '7.5px',
      fill: '#B6B6F8',
    },
  },
}));

interface OwnProps {}

type Props = IHaveZone & {
  disabled?: boolean;
  title?: string;
  subtitle?: string;
  action?: React.ReactNode;
  isFurballInTab: (fb: IFurball, tab: string) => boolean;
  doesFurballHaveNotice?: (fb: IFurball) => boolean;
  tabs: { [key: string]: string };
  tabsIcons?: { [key: string]: React.ReactElement };
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  selectedFurballs: ISelectedFurball[];
  setSelectedFurballs: (furballs: ISelectedFurball[]) => void;
  loading: boolean;
  background: string;
  noBackground?: boolean;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  hideSelectAll?: boolean;
  emptyState?: React.ReactNode;
};

interface IP {
  fb: IFurball;
  i: number;
  isLoading: boolean;
  zoneNumber: number;
  selectedFurballs: ISelectedFurball[];
  onSelectedFurball: (fb: ISelectedFurball, selected: boolean) => void;
}

const SelectableFurballContainer = ({
  fb,
  i,
  selectedFurballs,
  isLoading,
  zoneNumber,
  onSelectedFurball,
}: IP) => {
  const classes = useStyles();
  const { log } = useFurComponent(SelectableFurballContainer);
  const { isOwner, timeRemaining } = useScholarshipHelpers(
    log,
    fb.tokenId,
    true,
  );

  return (
    <div
      key={fb.tokenId}
      className={classes.furballWrapper}
      style={{ left: `${i * 220 + 20}px` }}>
      <React.Suspense fallback={<LinearProgress style={{ width: '100%' }} />}>
        <SelectableFurball
          tokenId={fb.tokenId}
          disabled={isLoading}
          zoneNumber={zoneNumber}
          activeRentalData={{
            isRented: fb.isRented,
            isRentedOut: isOwner,
            timeRemaining: timeRemaining(),
          }}
          isSelected={selectedFurballs
            .map((item) => item.id)
            .includes(fb.tokenId)}
          onSelected={(sel) =>
            onSelectedFurball({ id: fb.tokenId, isRented: !!fb.isRented }, sel)
          }
        />
      </React.Suspense>
    </div>
  );
};

export const ZoneCard: FunctionComponent<Props> = (props) => {
  const {
    zoneNumber,
    disabled,
    children,
    action,
    tabs,
    tabsIcons,
    isFurballInTab,
    doesFurballHaveNotice,
    selectedTab,
    setSelectedTab,
    loading,
    selectedFurballs,
    setSelectedFurballs,
    background,
    noBackground,
    footer,
    hideSelectAll,
  } = props;
  const zone = getZone(zoneNumber);
  const canSelectAll =
    ![ZONE_NUM_VOYAGE, ZONE_NUM_SCHOLARSHIPS, ZONE_NUM_BOSS_FIGHTS].includes(
      zoneNumber,
    ) && hideSelectAll !== true;

  const classes = useStyles();
  const common = useCommonStyles();
  const zoning = useWalletSelector((s) => Object.keys(s.pending));
  const playerId = useWalletSelector((s) => s.contents?.id ?? '');
  const isLoading = zoning.length > 0 || loading;
  const backgroundImage = `url(${cdnRoot}/svgs/background-${background}.svg)`;
  const allFurballs = useFurballs();
  const [fbIndex, setFbIndex] = React.useState(0);

  const furballs = React.useMemo(
    () =>
      allFurballs.filter(
        (fb) => zoneNumber === getFurballDisplayZone(fb, playerId),
      ),
    [allFurballs, zoneNumber, playerId],
  );

  const furballsTab = React.useMemo(
    () => furballs.filter((fb) => isFurballInTab(fb, selectedTab)),
    [furballs, selectedTab],
  );
  const darkBackground =
    zoneNumber === ZONE_NUM_VOYAGE &&
    selectedTab === RealmType.Boneyard.toString();
  let title = props.title ?? zone.title.toUpperCase();

  if (zone.isBattle) {
    const furRate = furballs
      .map((fb) =>
        selectedFurballs.map((item) => item.id).includes(fb.tokenId)
          ? fb.state.furRate
          : 0,
      )
      .reduce((partial_sum, a) => partial_sum + a, 0);
    if (furRate > 0) title = `${furRate.toLocaleString()} $FUR/hr`;
  }

  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    if (selectedFurballs.length !== getFbsThatCanMove().length) {
      setChecked(false);
    }
  }, [selectedFurballs]);

  const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setSelectedFurballs(
        furballsTab
          .filter((fb) => !fbHaveUpgradeCd(fb))
          .map((fb) => ({ id: fb.tokenId, isRented: !!fb.isRented })),
      );
    } else {
      setSelectedFurballs([]);
    }

    setChecked(e.target.checked);
  };

  function getFbsThatCanMove() {
    return furballs.filter((fb) => !fbHaveUpgradeCd(fb));
  }

  function onSelectedFurball(fb: ISelectedFurball, selected: boolean) {
    const sf = [...selectedFurballs];
    let idx = sf.map((item) => item.id).indexOf(fb.id);
    const fbsThatCanMove = getFbsThatCanMove();

    if (selected) {
      if (idx < 0) sf.push(fb);
      if (sf.length === fbsThatCanMove.length) {
        setChecked(true);
      }
    } else {
      while (idx >= 0) {
        if (sf.length === fbsThatCanMove.length) {
          setChecked(false);
        }

        sf.splice(idx, 1);
        idx = sf.map((item) => item.id).indexOf(fb.id);
      }
    }

    setSelectedFurballs(sf);
  }

  function onSelectedTab(tab: string) {
    setSelectedFurballs([]);
    setSelectedTab(tab);
  }

  function renderCardContent() {
    if (noBackground) {
      return (
        <div
          className={clsx(classes.backgroundArea, classes.bgAreaNoMaxHeight)}>
          {children}
        </div>
      );
    }

    return (
      <HorizontalScroller
        className={clsx(classes.backgroundArea, {
          [classes.zoneLoading]: !!disabled,
        })}
        style={{ backgroundImage }}
        itemIndex={fbIndex}
        setItemIndex={setFbIndex}
        numItems={furballsTab.length}
        renderItem={(p, i) => {
          const fb = furballsTab[p];
          if (!fb) return null;
          return (
            <React.Fragment key={p}>
              <SelectableFurballContainer
                fb={fb}
                i={i}
                onSelectedFurball={onSelectedFurball}
                isLoading={isLoading}
                zoneNumber={zoneNumber}
                selectedFurballs={selectedFurballs}
              />
            </React.Fragment>
          );
        }}>
        {furballsTab.length === 0 && (
          <div
            className={clsx(classes.empty, {
              [classes.emptyDark]: darkBackground,
            })}>
            <div className={classes.emptyPlaceholder}>
              {props.emptyState ?? (
                <Typography variant={'h5'}>
                  No Furballs in This Zone!
                </Typography>
              )}
            </div>
          </div>
        )}
        {children}
      </HorizontalScroller>
    );
  }

  return (
    <AlertProvider>
      <Card className={clsx(classes.root, common.cardRoot)}>
        <CardHeader
          className={common.cardHeader}
          title={
            <div className={classes.header}>
              {title}
              {furballsTab.length > 0 && (
                <div
                  style={{
                    marginRight: selectedFurballs.length ? '12px' : '16px',
                  }}>
                  {canSelectAll && (
                    <FormControlLabel
                      className={classes.headerForm}
                      label={
                        <Typography
                          variant='h5'
                          sx={{
                            fontSize: '18px !important',
                            lineHeight: '25px',
                          }}>
                          All
                        </Typography>
                      }
                      labelPlacement='start'
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={selectAll}
                          icon={<SvgCheckBoxs03 height={19} width={19} />}
                          checkedIcon={
                            <SvgCheckBoxs02 height={19} width={19} />
                          }
                          sx={{ maxWidth: 40, maxHeight: 40 }}
                        />
                      }
                    />
                  )}
                </div>
              )}
            </div>
          }
          action={action}
        />
        <CardContent className={classes.cardContent}>
          <Tabs
            variant={'standard'}
            value={selectedTab}
            className={classes.tabs}
            onChange={(e, v) => onSelectedTab(v)}
            textColor='secondary'
            indicatorColor='secondary'
            TabIndicatorProps={{ style: { height: '4px' } }}>
            {Object.keys(tabs).map((tabKey) => {
              const tabFurballs = furballs.filter((fb) =>
                isFurballInTab(fb, tabKey),
              );

              let tabCount;
              let rentalCount;
              const totalCount = tabFurballs.length;

              if (zoneNumber === ZONE_NUM_BOSS_FIGHTS) {
                rentalCount = furballs.filter((item) => item.isRented).length;

                const ownedCount = totalCount - rentalCount;

                tabCount =
                  totalCount === 0
                    ? ''
                    : rentalCount === 0
                    ? ` (${totalCount})`
                    : ownedCount === 0
                    ? ` (+${rentalCount})`
                    : ` (${ownedCount} + ${rentalCount})`;
              } else {
                tabCount = totalCount > 0 ? ` (${totalCount})` : '';
              }

              const notice = doesFurballHaveNotice
                ? tabFurballs.filter(doesFurballHaveNotice).length
                : 0;

              return (
                <Tab
                  key={tabKey}
                  className={classes.tab}
                  color={'secondary'}
                  label={
                    <div className={classes.tabLabel}>
                      {tabsIcons && tabsIcons[tabKey]}
                      {`${tabs[tabKey]}${tabCount}`}
                      {notice > 0 && <div className={classes.notice}></div>}
                    </div>
                  }
                  value={tabKey}
                />
              );
            })}
          </Tabs>
          {renderCardContent()}
          {footer}
        </CardContent>
      </Card>
    </AlertProvider>
  );
};

export default ZoneCard;
