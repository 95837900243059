import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import { IFurball, IFurballBase } from '../../wallet/WalletTypes';
import {
  estimateFurballRewards,
  IFurballWithReward,
  useTimeRewardInput,
} from './RewardEstimator';
import FurballRenderer from '../Furballs/FurballRenderer';
import { ZONE_NUM_BATTLE, ZONE_NUM_EXPLORE } from '../Zones/ZoneHelpers';
import { FB_MAX_LVL } from '../Zones/MoveZonesButton';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  disclaimer: {
    paddingTop: theme.spacing(2),
    color: theme.palette.grey.A700,
    textAlign: 'center',
  },
  furball: {
    position: 'relative',
  },
}));

type Props = {
  furballs?: IFurball[];
  zoneNumber?: number;
  showFur?: boolean;
  showExp?: boolean;
  showTotals?: boolean;
  hideDisclaimer?: boolean;
  forSingleFb?: boolean;
  snackMultiplier?: number;
};

export const EstimatedRewards: FunctionComponent<Props> = (props) => {
  const {
    furballs,
    showFur,
    showExp,
    hideDisclaimer,
    zoneNumber,
    forSingleFb,
    snackMultiplier,
  } = props;
  const classes = useStyles();
  const rewardArgs = useTimeRewardInput();
  const rewardsWithSnacks = estimateFurballRewards(furballs ?? [], {
    ...rewardArgs,
    snackHappiness: snackMultiplier ?? 0,
  });
  const rewardsPlain = estimateFurballRewards(furballs ?? [], rewardArgs);
  const showTotals =
    props.showTotals ?? rewardsWithSnacks.furballsWithRewards.length > 1;
  let renderIdx = 0;

  function renderRow(
    fb: IFurballBase | undefined,
    title: string,
    value: number,
    kind: string,
    total = false,
  ): React.ReactNode {
    renderIdx++;
    const style = {
      padding: 2,
      background: renderIdx % 2 == 0 ? '#EEE' : '#FFF',
    };

    return (
      <React.Fragment key={renderIdx}>
        <Grid item xs={1} style={style}>
          {fb && (
            <div style={{ maxHeight: 40, marginBottom: -6 }}>
              <FurballRenderer
                tokenId={fb.tokenId}
                key={fb.tokenId}
                size={40}
                className={classes.furball}
                //
              />
            </div>
          )}
        </Grid>
        <Grid item xs={4} style={style}>
          <Typography variant={total ? 'h4' : 'h5'} style={{ marginTop: 2 }}>
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            ...style,
            textAlign: 'right',
            paddingRight: 8,
            paddingTop: 4,
          }}>
          <Typography variant={total ? 'h5' : 'body1'}>
            <span style={{ fontStyle: 'italic' }}>est. </span>
            <span style={{ fontWeight: 'bold' }}>
              {value.toLocaleString()}
            </span>{' '}
            {kind}
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }

  function renderExploringRow(rew: IFurballWithReward): React.ReactNode {
    if (rew.furball.level >= FB_MAX_LVL) {
      return renderRow(rew.furball, `Furball #${rew.furball.number}`, 0, 'MAX');
    }
    const gainedLevel = rew.reward.levelGain > 0;
    const gainValue = gainedLevel
      ? rew.reward.levelGain
      : rew.reward.experienceGain;
    return renderRow(
      rew.furball,
      `Furball #${rew.furball.number}`,
      gainValue,
      gainedLevel ? ' LVL' + (rew.reward.levelGain === 1 ? '' : 'S') : ' EXP',
    );
  }

  function renderBattlingRow(rew: IFurballWithReward): React.ReactNode {
    return renderRow(
      rew.furball,
      `Furball #${rew.furball.number}`,
      rew.reward.furGain,
      '$wFUR',
    );
  }

  function renderRows() {
    return (
      <Grid container className={clsx(classes.root)}>
        {rewardsWithSnacks.furballsWithRewards.map((rew, i) => {
          if (zoneNumber !== undefined && zoneNumber !== rew.furball.zone) {
            return null;
          }
          if (rew.furball.zone < 0x10000) {
            return showExp ? renderExploringRow(rew) : null;
          } else {
            return showFur ? renderBattlingRow(rew) : null;
          }
        })}
        {showTotals && (
          <React.Fragment>
            {rewardsWithSnacks.furEarned > 0 &&
              renderRow(
                undefined,
                'Total',
                rewardsWithSnacks.furEarned,
                '$wFUR',
                true,
              )}
          </React.Fragment>
        )}
        {!hideDisclaimer && (
          <Grid item xs={12} className={classes.disclaimer}>
            <Typography variant={'subtitle2'}>
              Rewards are estimated based upon your computer's clock.
              <br />
              Final outcomes executed with the blockchain's clock.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }

  function render() {
    if (!forSingleFb) {
      return renderRows();
    }

    const fb = rewardsWithSnacks.furballsWithRewards[0];
    const rew = fb.reward;
    const plainRew = rewardsPlain.furballsWithRewards[0].reward;

    if (zoneNumber === ZONE_NUM_EXPLORE) {
      const isMaxLvl = fb.furball.level >= 200;
      const gainedLevel = rew.levelGain > 0;

      let gainValue = 0;
      let gainTitle = 'MAX LVL';
      let extraValue: number | undefined = undefined;

      if (gainedLevel) {
        gainValue = rew.levelGain;
        gainTitle = 'LVL' + (rew.levelGain === 1 ? '' : 'S');
        extraValue = rew.levelGain - plainRew.levelGain;
      } else if (rew.experienceGain > 0) {
        gainValue = rew.experienceGain;
        gainTitle = 'EXP';
        extraValue = rew.experienceGain - plainRew.experienceGain;
      }
      //gainedLevel ? rew.levelGain : rew.experienceGain;
      // let expFromSnacks = 0;

      // if (snackMultiplier) {
      //   expFromSnacks = Math.floor((500 * snackMultiplier) / 100);
      //   gainValue = gainValue + expFromSnacks;
      // }

      // const title = isMaxLvl
      //   ? 'MAX LVL'
      //   : gainedLevel
      //   ? 'LVL' + (rew.levelGain === 1 ? '' : 'S')
      //   : 'EXP';

      return (
        <>
          <Typography variant={'body1'}>
            {isMaxLvl ? 0 : gainValue.toLocaleString()}
            {extraValue && extraValue > 0 ? ` (+${extraValue})` : ''}
          </Typography>
          <Typography variant={'h6'}>{gainTitle}</Typography>
        </>
      );
    }

    if (zoneNumber === ZONE_NUM_BATTLE) {
      return (
        <>
          <Typography variant={'body1'}>
            {rew.furGain.toLocaleString()}
          </Typography>
          <Typography variant={'h6'}>$wFUR</Typography>
        </>
      );
    }

    return null;
  }

  return render();
};

export default EstimatedRewards;
