import { decodeReward } from '../../wallet';
import WalletSlice from '../../wallet/WalletSlice';
import { IFurballContracts } from '../../wallet/WalletTypes';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { IPlayReward } from '../../components/Rewards';
import { RarityLevel } from '../../components/schema';

// Used by play button to trigger an update/collection of the furball
export async function collect(
  tokenId: string,
  walletAddress: string,
  contracts: IFurballContracts,
  dispatch: Dispatch<AnyAction>,
): Promise<IPlayReward> {
  // const stats = decodeStats(await contracts.furballs.stats(tokenId, true));
  const reward = decodeReward(await contracts.furballs.collect(tokenId));

  let furBalance = undefined;
  if (reward.furGain > 0) {
    furBalance = (await contracts.fur.balanceOf(walletAddress)).toNumber();
  }
  // const reward: IPlayResponse = { tokenId, furBalance, stats };

  // Adding play response must come first
  dispatch(WalletSlice.actions.addPlayReward({ tokenId, reward, furBalance }));

  return reward;
}

export function getRarityName(level: number) {
  if (level === 0) return 'Common';
  if (level === 1) return 'Elite';
  if (level === 2) return 'Mythic';
  if (level === 3) return 'Legendary';
  if (level === 4) return 'Ultimate';
  throw `Rarity ${level}`;
}

export function getRarityLevelNumber(level: RarityLevel): number {
  return [
    RarityLevel.Common,
    RarityLevel.Elite,
    RarityLevel.Mythic,
    RarityLevel.Legendary,
    RarityLevel.Ultimate,
  ].indexOf(level);
}

export function getRarityLevelName(level: RarityLevel): string {
  const s = level.toString();
  return s.substr(0, 1) + s.substr(1).toLowerCase();
}

// export function rewardIfOwner(
//   tokenId: BigNumber, furBalance: BigNumber, statArr: unknown[], dataArr: unknown[]
// ): IPlayReward | undefined {
//
// }
