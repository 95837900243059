import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Stars = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffe967'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m156.39 110.87 7.29 9.12a9.25 9.25 0 0 0 6.87 3.47l11.67.45c1.92.07 3.02 2.21 1.96 3.82l-6.42 9.75a9.254 9.254 0 0 0-1.18 7.61l3.18 11.23c.52 1.85-1.17 3.55-3.02 3.04l-11.26-3.1a9.287 9.287 0 0 0-7.6 1.23l-9.7 6.49c-1.59 1.07-3.74-.02-3.83-1.93l-.53-11.66a9.26 9.26 0 0 0-3.52-6.85l-9.17-7.22c-1.51-1.19-1.14-3.56.66-4.24l10.93-4.11c2.52-.95 4.5-2.94 5.42-5.46l4.03-10.96c.65-1.8 3.02-2.18 4.22-.68z'
    />
    <path
      fill='#FFF'
      d='m157.56 126.27 3.45 4.32c.8 1 1.98 1.6 3.26 1.64l5.53.21c.91.03 1.43 1.05.93 1.81l-3.04 4.62a4.42 4.42 0 0 0-.56 3.61l1.51 5.32c.25.87-.56 1.68-1.43 1.44l-5.33-1.47a4.403 4.403 0 0 0-3.6.58l-4.6 3.08c-.76.51-1.77-.01-1.82-.92l-.25-5.53a4.377 4.377 0 0 0-1.67-3.24l-4.35-3.42c-.71-.56-.54-1.69.31-2.01l5.18-1.95a4.383 4.383 0 0 0 2.57-2.59l1.91-5.19c.3-.84 1.43-1.02 2-.31z'
    />
    <path
      fill='#ffe967'
      d='m158.05 132.74 1.76 2.2c.4.51 1.01.81 1.66.84l2.82.11c.46.02.73.53.47.92l-1.55 2.35c-.36.54-.46 1.21-.28 1.84l.77 2.71c.13.45-.28.86-.73.74l-2.72-.75c-.63-.17-1.3-.06-1.83.3l-2.34 1.57c-.38.26-.9 0-.92-.47l-.13-2.82c-.03-.65-.34-1.25-.85-1.65l-2.21-1.74a.59.59 0 0 1 .16-1.02l2.64-.99a2.22 2.22 0 0 0 1.31-1.32l.97-2.65c.13-.44.71-.54 1-.17z'
    />
    <path
      fill='#ffe967'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m238.88 110.87-7.29 9.12a9.25 9.25 0 0 1-6.87 3.47l-11.67.45c-1.92.07-3.02 2.21-1.96 3.82l6.42 9.75a9.254 9.254 0 0 1 1.18 7.61l-3.18 11.23c-.52 1.85 1.17 3.55 3.02 3.04l11.26-3.1c2.59-.71 5.36-.26 7.6 1.23l9.7 6.49c1.59 1.07 3.74-.02 3.83-1.93l.53-11.66a9.26 9.26 0 0 1 3.52-6.85l9.17-7.22c1.51-1.19 1.14-3.56-.66-4.24l-10.93-4.11a9.222 9.222 0 0 1-5.42-5.46l-4.03-10.96c-.65-1.8-3.02-2.18-4.22-.68z'
    />
    <path
      fill='#FFF'
      d='m237.72 126.27-3.45 4.32c-.8 1-1.98 1.6-3.26 1.64l-5.53.21c-.91.03-1.43 1.05-.93 1.81l3.04 4.62c.7 1.06.9 2.38.56 3.61l-1.51 5.32c-.25.87.56 1.68 1.43 1.44l5.33-1.47c1.23-.34 2.54-.12 3.6.58l4.6 3.08c.76.51 1.77-.01 1.82-.92l.25-5.53c.06-1.27.67-2.46 1.67-3.24l4.35-3.42c.71-.56.54-1.69-.31-2.01l-5.18-1.95a4.383 4.383 0 0 1-2.57-2.59l-1.91-5.19c-.31-.84-1.44-1.02-2-.31z'
    />
    <path
      fill='#ffe967'
      d='m237.23 132.74-1.76 2.2c-.4.51-1.01.81-1.66.84l-2.82.11c-.46.02-.73.53-.47.92l1.55 2.35c.36.54.46 1.21.28 1.84l-.77 2.71c-.13.45.28.86.73.74l2.72-.75c.63-.17 1.3-.06 1.83.3l2.34 1.57c.38.26.9 0 .92-.47l.13-2.82c.03-.65.34-1.25.85-1.65l2.21-1.74a.59.59 0 0 0-.16-1.02l-2.64-.99a2.22 2.22 0 0 1-1.31-1.32l-.97-2.65a.57.57 0 0 0-1-.17z'
    />
  </svg>
);

export default Stars;
