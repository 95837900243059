import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

interface IFurballLinkable {
  id: string;
  name: string;
}

interface IFurballLink {
  furball: IFurballLinkable;
  className?: string;
  children?: React.ReactNode;
}

export const FurballLink: FunctionComponent<IFurballLink> = (props) => {
  const { furball, children, className } = props;
  // const classes = useStyles();

  return (
    <Link className={className} to={`/furball/${furball.id}`}>
      {children ?? furball.name}
    </Link>
  );
};

export default FurballLink;
