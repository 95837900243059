import React, { FunctionComponent } from 'react';
import WalletSlice from './WalletSlice';

import { BigNumber } from 'ethers';
import WalletContext from './WalletContext';
import { useAppDispatch, useWalletSelector } from '../hooks';
import { useFurComponent } from '../utils';

type Props = {
  children: React.ReactNode;
};

const WalletEvents: FunctionComponent<Props> = (props) => {
  const { log } = useFurComponent(WalletEvents);
  const context = React.useContext(WalletContext);
  const dispatch = useAppDispatch();
  const walletAddress = useWalletSelector((s) => s.address);
  const furballs = context?.network.contracts?.furballs;
  const edition = context?.network.contracts?.currentEdition;

  async function onTransfer(from: string, to: string, tokenId: BigNumber) {
    if (to === walletAddress) {
      log.info('RECEIVE', from, tokenId);
      // n.b., other places do this too...
      dispatch(WalletSlice.actions.addToWallet(tokenId._hex));
    }
  }

  async function onSpawn(editionIndex: number, count: number) {
    log.info('SPAWN', editionIndex, count);
    // dispatch(WalletSlice.actions.updateEditionCount(count));
  }

  async function onCollection(tokenNum: any, rewardIndex: any) {
    log.info('COLLECTION', tokenNum, rewardIndex);
    // const tokenId: string = tokenNum._hex;
    // if (!context?.contracts || !walletAddress) return;
    //
    // const index: number = rewardIndex.toNumber();
    // const stats = decodeStats(await context?.contracts.furballs.stats(tokenNum, true));
    // const reward = decodeReward(await context?.contracts.furballs.collect(tokenNum, index - 1));
    //
    // let furBalance = undefined;
    // if (reward.furGain > 0) {
    //   furBalance = (await context.contracts.fur.balanceOf(walletAddress)).toNumber();
    // }
    // // const reward: IPlayResponse = { tokenId, furBalance, stats };
    //
    // // Adding play response must come first
    // dispatch(WalletSlice.actions.addPlayReward({ tokenId, stats, reward, furBalance }));
  }

  async function onInventory(
    tokenId: BigNumber,
    item: BigNumber,
    dropCount: number,
  ) {
    // const stats = loadFurballStats(statArr);
    if (!context?.network.contracts) return;
    log.info(
      'INVENTORY',
      tokenId,
      item.toNumber(),
      dropCount <= 0 ? 'pickup 1' : `drop ${dropCount}`,
    );
    // const stats = decodeStats(statArr);
    // const stats = decodeStats(await context?.contracts.furballs.stats(tokenId));
    //
    // const pickup: IPickup = { tokenId: tokenId._hex, slot, item: item._hex, stats };
    // dispatch(WalletSlice.actions.addPickup(pickup));
  }

  React.useEffect(() => {
    if (!furballs || !edition) return;
    edition.on('Spawn', onSpawn);
    furballs.on('Transfer', onTransfer);
    furballs.on('Collection', onCollection);
    furballs.on('Inventory', onInventory);

    return () => {
      edition.off('Spawn', onSpawn);
      furballs.off('Transfer', onTransfer);
      furballs.off('Collection', onCollection);
      furballs.off('Inventory', onInventory);
    };
  }, [furballs, edition, walletAddress]);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default WalletEvents;
