import {
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { IFurball } from '../../../wallet/WalletTypes';
import useCommonStyles from '../../useCommonStyles';
import {
  RenameFurballArgsInput,
  Specialization,
  useSpecializeMutation,
} from '../../schema';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import SpendWFurButton from '../../Account/SpendWFurButton';
import { useFurComponent } from '../../../utils';
import { useAppDispatch, useWalletSelector } from '../../../hooks';
import WalletSlice from '../../../wallet/WalletSlice';
import { getInventoryQuantities } from '../../Furballs';
import OkDialog from '../../OkDialog';
import WalletContext from '../../../wallet/WalletContext';
import { signTimekeeperRequest } from '../../Timekeeper/FurgreementSig';
import { useTryCatchAsync } from '../../../utils/Errors';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    color: 'white',
  },
  button: {
    '&:disabled': {
      color: `${theme.palette.grey.A400} !important`,
      borderColor: `${theme.palette.grey.A700} !important`,
    },
  },
}));

interface ISpecializeFurball {
  furball: IFurball;
  onSpecialized?: () => void;
}

const SpecializeFurball: React.FunctionComponent<ISpecializeFurball> = (
  props,
) => {
  const common = useCommonStyles();
  const { log } = useFurComponent(SpecializeFurball);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { furball, onSpecialized } = props;
  const { specialization } = furball;
  const specs = [
    Specialization.Defense,
    Specialization.Offense,
    Specialization.Hero,
  ];
  const allowed = specs.filter((s) => s != specialization);
  const [spec, setSpec] = React.useState(
    specialization === Specialization.None
      ? allowed[allowed.length - 1]
      : specialization,
  );
  const furCost = spec === Specialization.Hero ? 350 * 100000 : 45 * 100000;
  const [heroConfirm, setHeroConfirm] = React.useState(false);
  const furballId = furball.id;
  const [specialize, { loading }] = useSpecializeMutation();
  const context = React.useContext(WalletContext);
  const [name, setName] = React.useState<string>('');
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const walletAddress = useWalletSelector((w) => w.address);
  const pattern = /^[a-zA-Z0-9 ]*$/;
  const nameError =
    name.length < 2
      ? 'Too short!'
      : name.length > 16
      ? 'Too Long!'
      : !name.match(pattern)
      ? 'Letters and numbers only!'
      : undefined;

  const buySpecialization = useTryCatchAsync(
    'Specialize',
    async () => {
      const renameArgs: RenameFurballArgsInput | null =
        spec == Specialization.Hero
          ? { name, furballId, deadline: 0, sender: playerId }
          : null;
      let renameSignature: string | null = null;
      if (spec === Specialization.Hero && context && walletAddress) {
        if (!context) log.error('no context');
        else if (!walletAddress) log.error('no wallet');
        else {
          const [_, signature] = await signTimekeeperRequest(
            {
              fuelCost: 0,
              deadline: 0,
              spent: 0,
              gained: 0,
              mintEdition: 0,
              mintCount: 0,
              movementList: '',
              collectStayList: '',
            },
            walletAddress,
            context,
          );
          renameSignature = signature;
        }
      }
      log.info('specializing...', spec, furballId, renameArgs, renameSignature);
      const res = await specialize({
        variables: {
          specialization: spec,
          furballId,
          renameArgs,
          renameSignature,
        },
      });
      const fb = res.data?.specializeFurball;
      if (fb)
        dispatch(
          WalletSlice.actions.setFurball({
            ...fb,
            inventory: getInventoryQuantities(fb.inventory),
          }),
        );
      log.info('specialized', res);

      if (onSpecialized) onSpecialized();
    },
    [context, spec, furballId, name, walletAddress],
    `Specialized as ${spec.toString()}`,
  );

  const isDisabled =
    loading ||
    // tixCost > tix ||
    (spec === specialization && spec !== Specialization.Hero);
  // spec === Specialization.Hero;

  function getSkillDesc() {
    if (spec === Specialization.Defense)
      return 'the offensive (first) skill as defensive';
    if (spec === Specialization.Offense)
      return 'the defensive (second) skill as offensive';
    return 'both of the default skills';
  }

  function getApDpDesc() {
    const ap = 'the AP stat by ' + (3000).toLocaleString();
    const dp = 'the DP stat by ' + (1500).toLocaleString();
    if (spec === Specialization.Defense) return dp;
    if (spec === Specialization.Offense) return ap;
    return ap + ' AND ' + dp;
  }

  function getStatDesc() {
    if (spec === Specialization.Defense) return 'the BUFF stat';
    if (spec === Specialization.Offense) return 'the DEBUFF stat';
    return 'both BUFF and DEBUFF stats';
  }

  function onClickBuy(): Promise<void> {
    if (spec === Specialization.Hero) {
      setHeroConfirm(true);
      return Promise.resolve();
    } else return buySpecialization();
  }

  return (
    <div className={classes.root}>
      <Typography variant={'h5'} style={{ marginBottom: 10 }}>
        Current Specialization: {specialization}
      </Typography>
      <ButtonGroup>
        {specs.map((s) => {
          return (
            <Button
              className={clsx(common.actionButton, classes.button)}
              key={s}
              disabled={s === spec}
              onClick={() => setSpec(s)}>
              {s}
            </Button>
          );
        })}
      </ButtonGroup>
      <br />
      <br />
      <div>
        <span>Increases level cap to 300</span>
        <br />
        <span>Increases {getApDpDesc()}</span>
        <br />
        <span>Increases {getStatDesc()} by 100</span>
        <br />
        <span>Allows re-rolling {getSkillDesc()}</span>
        <br />
        {spec === Specialization.Hero && (
          <span>Lets you change the ON-CHAIN name of the Furball.</span>
        )}
        <br />
        <br />
        <SpendWFurButton
          cost={furCost}
          onAccepted={onClickBuy}
          itemName={
            spec === Specialization.Hero ? 'Hero' : `Specialize in ${spec}`
          }
          suffix={
            spec === Specialization.Hero ? (
              <>
                wFUR
                {/*{' '}*/}
                {/*<SvgIconTix*/}
                {/*  fill={'#000000'}*/}
                {/*  style={{*/}
                {/*    width: 24,*/}
                {/*    marginRight: 4,*/}
                {/*    position: 'relative',*/}
                {/*    top: 4,*/}
                {/*  }}*/}
                {/*/>*/}
                {/*{tixCost.toLocaleString()} TIX*/}
              </>
            ) : undefined
          }
          disabled={isDisabled}
        />
        <br />
        <br />
        <OkDialog
          open={heroConfirm}
          title={'Please Read Carefully'}
          onClose={() => setHeroConfirm(false)}
          maxWidth={'sm'}>
          <Typography maxWidth={'xs'} variant={'subtitle2'}>
            All name changes will be manually reviewed for profanity by the
            team, and then written to the blockchain. The new name will appear
            (instead of "{furball.name}") everywhere (including OpenSea). There
            is no undo. Changing the name again requires paying for Hero
            specialization again. If nothing happens when you press the button,
            make sure you are logged in to MetaMask.
          </Typography>
          <br />
          <Typography variant={'h5'}>Rename {furball.name}</Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={8}>
              <TextField
                // className={clsx(classes.textField, {
                //   [classes.textFieldLight]: light,
                // })}
                variant='outlined'
                color='secondary'
                style={{ width: '100%' }}
                error={!!nameError}
                helperText={nameError}
                value={name}
                name='reserved'
                placeholder='New Furball Name'
                inputProps={{ maxLength: 16, pattern }}
                onChange={(e) => setName(e.target.value)}
                // disabled={!isNameValid}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant={'contained'}
                style={{ width: '100%', height: '100%' }}
                className={common.actionButton}
                disabled={isDisabled || !!nameError}
                onClick={buySpecialization}>
                Become a Hero!
              </Button>
            </Grid>
          </Grid>
        </OkDialog>
      </div>
    </div>
  );
};

export default SpecializeFurball;
