import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { headerFont, white, yellow } from '../themev2';
import { cdnRoot, mint } from '../theme';
import { appBarHeightSm } from './useCommonStyles';

interface IAspectRatio {
  aspectRatio: number;
}

const useHeroStyles = makeStyles((theme: Theme) => ({
  header: {
    color: white,
    textAlign: 'center',
    // backgroundColor: theme.palette.secondary.light,
    minHeight: '560px',
    marginTop: theme.mixins.toolbar.height,
    padding: '85px 0 170px 0',
    [theme.breakpoints.down('lg')]: {
      padding: '75px 15px',
      textAlign: 'center',
    },
  },
  background: {
    backgroundColor: theme.palette.secondary.light,
    backgroundImage: (props: IAspectRatio) => {
      const mode = props.aspectRatio >= 0.7 ? 'landscape' : 'portrait';
      return `url(${cdnRoot}/images/backgrounds/welcome_home_${mode}.png)`;
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: `calc(100vh - ${appBarHeightSm}px)`,

    [theme.breakpoints.down('lg')]: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      background: `url(${cdnRoot}/images/backgrounds/welcome_back.png)`,
    },
  },
  headerInfoContainer: {
    maxWidth: '500px !important',
    marginTop: '50px !important',

    [theme.breakpoints.down('sm')]: {
      marginTop: '15px !important',
    },
  },
  titleText: {
    lineHeight: '4rem !important',
    fontSize: '4rem !important',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      lineHeight: '3rem !important',
      fontSize: '2.5rem !important',
      marginBottom: '10px !important',
    },
  },
  playButton: {
    fontFamily: `${headerFont} !important`,
    fontSize: '1.5rem !important',
    width: '14rem',
    // padding: '0.8rem 0 0.8rem 0.6rem !important',
    backgroundColor: `${yellow} !important`,
    border: '2px solid #000 !important',
    // boxShadow: 'outset 0 -5px 0 2px #00000066 !important',
    filter: 'drop-shadow(0 5px 0px #000000CC) !important',
    borderRadius: '50px !important',
    transition: 'all 0.2s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      backgroundColor: `${mint.light} !important`,
      // boxShadow: 'none',
    },
  },
  button: {
    // color: 'white !important',
    // backgroundColor: `${theme.palette.secondary.light} !important`,
    borderRadius: '60px !important',
    whiteSpace: 'nowrap',
    width: '270px',
    height: '55px',
    // border: `2px solid ${white} !important`,
    fontSize: '1.4rem !important',
    marginTop: '-55px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem !important',
      width: '275px',
      height: '65px',
      marginTop: '-35px !important',
    },
    // '&:hover': {
    //   backgroundColor: `${black} !important`,
    // },
  },
  buttonCont: {
    // display: 'flex',
    // alignItems: 'flex-start',
    // flexDirection: 'column',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
      alignItems: 'center',
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
    },
  },
  bodyText: {
    ['@media (min-width:1200px)']: {
      maxWidth: '375px',
    },
  },
  furballsHeroImage: {
    width: '110%',
    height: '100%',
    ['@media (min-width:1200px)']: {
      width: '100% !important',
      margin: 'auto',
    },
  },
  video: {
    marginLeft: '60px',
    position: 'relative',
    marginTop: '30px',
    padding: '12px',
    width: '100%',
    height: '465px',
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main,
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px',

    [theme.breakpoints.down('lg')]: {
      marginLeft: '0',
      height: '520px',
    },

    ['@media (max-width:900px)']: {
      width: '100%',
      marginTop: '0',
      height: '370px',
    },
  },

  signInNotice: {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9rem !important',
      marginTop: '0.5rem !important',
      padding: '0 10%',
    },
  },
}));

export default useHeroStyles;
