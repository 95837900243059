import * as React from 'react';

function SvgLootNet(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='loot-net_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 200 153.66'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.loot-net_svg__st2,.loot-net_svg__st7{fill:none;stroke:#000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.loot-net_svg__st7{stroke-width:3}'
        }
      </style>
      <path
        className='loot-net_svg__st2'
        d='M96.58 76.97s2.24 6.93 6.12 15.8c7.1 16.26 19.69 39.03 33.93 37.38 28.23-3.27 34.58-70.85 26.84-99.36-5.12-18.88-21.79-23.9-21.79-23.9M92.42 81.33l-8.99-2.95'
      />
      <path
        className='loot-net_svg__st7'
        d='M143.44 14.3l23.07 48.56M132.96 36.09l28.6 60.19M120.64 54.02l31.35 65.96M107.06 69.29l28.95 60.9M165.88 54.68L99.61 86.17M164.37 34.56l-46.18 21.95M156.6 17.42l-17.43 8.28M162.38 98.01l-16.99 8.08-17 8.08-10.59 5.03M165.34 75.77l-58.21 27.66'
      />
      <path
        d='M141.07 6.65c-2.78-2.01-8.11.17-15 6.15-6.02 5.22-12.81 12.92-19.14 21.69-6.33 8.77-11.5 17.64-14.56 24.99-1.9 4.57-2.88 8.35-2.94 11.18L35.1 136.07c-2.6 3.19-1.98 7.91 1.35 10.31a7.15 7.15 0 009.98-1.61l49.68-68.8c2.74-.86 6.18-3.03 10.13-6.46 6.02-5.22 12.81-12.92 19.14-21.69 2.8-3.88 5.38-7.78 7.64-11.57 2.85-4.76 5.21-9.33 6.92-13.43 3.52-8.41 3.91-14.16 1.13-16.17zm-6.91 13.76c-2.88 6.92-7.8 15.35-13.85 23.73-6.05 8.39-12.51 15.71-18.17 20.62-2.87 2.49-4.93 3.82-6.29 4.53.24-1.52.86-3.89 2.32-7.4 2.88-6.92 7.8-15.34 13.86-23.73s12.51-15.71 18.17-20.62c2.87-2.49 4.93-3.82 6.29-4.53-.26 1.53-.87 3.9-2.33 7.4z'
        fill='#b5bdff'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgLootNet;
