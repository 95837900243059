import { Card, CardContent, CardHeader, Grid, Theme, Box } from '@mui/material';
import clsx from 'clsx';
import * as React from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import useCommonStyles from '../../components/useCommonStyles';
import { makeStyles } from '@mui/styles';
import { usePageTitle } from '../../Analytics';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '90% !important',
    margin: '1rem auto !important',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '1600px',
    },
  },

  socialIcon: {
    width: '48px',
    marginRight: '12px',
    marginTop: '4px',
  },
  twitterContent: {
    margin: '0px !important',
    padding: '0px !important',
  },
}));

interface INewsPage {}

const NewsPage: React.FunctionComponent<INewsPage> = (props) => {
  const common = useCommonStyles();
  const classes = useStyles();
  usePageTitle('News');

  return (
    <Box
      className={clsx(common.fullCenter, common.furBk)}
      style={{}}
      display={'flex'}>
      <Box m={'auto'}>
        <Grid container maxWidth='sm' alignItems={'center'} spacing={2}>
          {/*<Grid item xs={12}>*/}

          {/*  <Card*/}
          {/*    className={common.cardRoot}*/}
          {/*    style={{*/}
          {/*      width: '100%',*/}
          {/*    }}>*/}
          {/*    <CardHeader*/}
          {/*      className={common.cardHeader}*/}
          {/*      // classes={{ title: classes.cardTitle }}*/}
          {/*      // sx={{ minHeight: '62px' }}*/}
          {/*      title={'Find Furballs Everywhere'}*/}
          {/*    />*/}
          {/*    <CardContent*/}
          {/*      className={clsx(common.cardContent, classes.twitterContent)}*/}
          {/*    >*/}
          {/*      <OutboundLink*/}
          {/*        to={'https://opensea.io/collection/furballs-com-official'}>*/}
          {/*        <SvgIconOpensea className={classes.socialIcon} />*/}
          {/*      </OutboundLink>*/}
          {/*      <OutboundLink to={'https://twitter.com/furballsNFT'}>*/}
          {/*        <SvgIconTwitter className={classes.socialIcon} />*/}
          {/*      </OutboundLink>*/}
          {/*      <OutboundLink to={discordChannelInviteUrl}>*/}
          {/*        <SvgIconDiscord className={classes.socialIcon} />*/}
          {/*      </OutboundLink>*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Card
              className={common.cardRoot}
              style={{
                width: '100%',
                minWidth: 400,
              }}>
              <CardHeader
                className={common.cardHeader}
                // classes={{ title: classes.cardTitle }}
                // sx={{ minHeight: '62px' }}
                title={'Twitter Feed'}
              />
              <CardContent
                className={clsx(common.cardContent, classes.twitterContent)}>
                <TwitterTimelineEmbed
                  sourceType='profile'
                  screenName='FurballsNFT'
                  theme={'dark'}
                  noHeader={true}
                  noFooter={true}
                  noBorders={true}
                  options={{ height: 600, width: '100%' }}
                />
              </CardContent>
            </Card>
          </Grid>
          {/*<Grid item xs={12} lg={6}>*/}
          {/*  <Card*/}
          {/*    className={common.cardRoot}*/}
          {/*    style={{*/}
          {/*      width: '100%',*/}
          {/*    }}>*/}
          {/*    <CardHeader*/}
          {/*      className={common.cardHeader}*/}
          {/*      // classes={{ title: classes.cardTitle }}*/}
          {/*      // sx={{ minHeight: '62px' }}*/}
          {/*      title={'Get in Touch'}*/}
          {/*    />*/}
          {/*    <CardContent*/}
          {/*      className={clsx(common.cardContent, classes.twitterContent)}*/}
          {/*    >*/}
          {/*      <WidgetBot*/}
          {/*        server='887187998519730177'*/}
          {/*        channel='887189582901301258'*/}
          {/*        height={600}*/}
          {/*        style={{ width: '100%' }}*/}
          {/*      />*/}
          {/*    </CardContent>*/}
          {/*  </Card>*/}
          {/*</Grid>*/}
        </Grid>
      </Box>
    </Box>
  );
};

export default NewsPage;
