import * as React from 'react';

function SvgIconBossHistoryAll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-boss-history-all_svg__a'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      {...props}>
      <defs>
        <style>{'.icon-boss-history-all_svg__b{fill:#410cd6}'}</style>
      </defs>
      <path
        className='icon-boss-history-all_svg__b'
        d='M12.57 7.86V4.88L9.99 3.4 7.41 4.88v2.98l2.58 1.49 2.58-1.49zM16.04 13.82v-2.98l-2.58-1.49-2.58 1.49v2.98l2.58 1.49 2.58-1.49zM9.32 13.82v-2.98L6.74 9.35l-2.58 1.49v2.98l2.58 1.49 2.58-1.49z'
      />
      <path
        className='icon-boss-history-all_svg__b'
        d='M10 20C4.49 20 0 15.51 0 10S4.49 0 10 0s10 4.49 10 10-4.49 10-10 10zm0-18.1c-4.47 0-8.1 3.63-8.1 8.1s3.63 8.1 8.1 8.1 8.1-3.63 8.1-8.1-3.63-8.1-8.1-8.1z'
      />
    </svg>
  );
}

export default SvgIconBossHistoryAll;
