import * as React from 'react';
import { SwapCurrency } from './ExchangeCurrencies';
import SpendTixButton from '../Account/SpendTixButton';
import { Typography } from '@mui/material';
import { CurrencyCostsFragment, usePurchaseFurMutation } from '../schema';
import { useFurComponent } from '../../utils';
import SpendUsdButton from '../Account/SpendUsdButton';
import { useTryCatchAsync } from '../../utils/Errors';

interface IFurBuyButton {
  currency: SwapCurrency;
  amount: number;
  costs: CurrencyCostsFragment;
  onComplete: () => void;
  disabled?: boolean;
}

const FurBuyButton: React.FunctionComponent<IFurBuyButton> = (props) => {
  const { log } = useFurComponent(FurBuyButton);
  const { amount, currency, costs, onComplete, disabled } = props;
  const [purchaseFur, { loading: purchasing }] = usePurchaseFurMutation();
  const fuelCost = Math.ceil(
    costs.costEach.fuel * amount + costs.costBase.fuel,
  );
  const isLoading = purchasing;
  const isDisabled = !!disabled;

  // console.log('sts', costs.costEach.usd, amount, usdPrice);

  const buyWithTix = useTryCatchAsync(
    'buyFur',
    async () => {
      const purchase = await purchaseFur({ variables: { fuel: fuelCost } });
      const purchased = purchase.data?.purchaseFur;
      log.info('purchased', purchased);
      onComplete();
    },
    [fuelCost],
    `Bought ${amount.toLocaleString()} wFUR`,
  );

  function renderButton() {
    if (currency === SwapCurrency.TIX)
      return (
        <SpendTixButton
          itemName={'wFUR'}
          title={`${amount.toLocaleString()} wFUR`}
          // className={clsx(classes.root, { [classes.disabled]: isDisabled })}
          onAccepted={() => buyWithTix()}
          isLoading={isLoading}
          cost={fuelCost ?? 20000}
          disabled={isDisabled}
          profitMult={0.1}
        />
      );

    if (currency === SwapCurrency.USD)
      return (
        <SpendUsdButton
          resource={'wFUR'}
          quantity={amount}
          priceEach={costs.costEach.usd}
          title={`${amount.toLocaleString()} wFUR`}
          disabled={isDisabled}
        />
      );

    return <span>Unknown Currency: {currency}</span>;
  }

  return (
    <>
      {renderButton()}
      <br />
      <Typography variant={'subtitle2'} color={'gray'}>
        You are currently buying wFUR from other players.
        <br />
        (LFG Gaming LLC does not directly create or sell wFUR)
      </Typography>
    </>
  );
};

export default FurBuyButton;
