import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme } from '@mui/material';
import FurSwapCard from '../../components/Swap/FurSwapCard';
import { usePageTitle } from '../../Analytics';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '90% !important',
    margin: '1rem auto !important',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '1600px',
    },
  },
}));

interface ISwap {}

const SwapCurrencies: FunctionComponent<ISwap> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const paths = window.location.pathname.split('/').filter((p) => p.length > 0);
  const currency = paths.length > 1 ? paths[1] : 'WFUR';
  const currencyTitle = currency.toUpperCase();
  usePageTitle(currencyTitle);

  return (
    <div className={clsx(common.fullCenter, common.furBk)}>
      <Grid container className={classes.main} spacing={2}>
        {/*<AccountHeader title={currencyTitle} />*/}
        <FurSwapCard />
      </Grid>
    </div>
  );
};

export default SwapCurrencies;
