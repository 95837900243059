import { makeStyles } from '@mui/styles';
import { Theme, useMediaQuery } from '@mui/material';
import theme, { black, white } from '../themev2';
import { cdnRoot } from '../theme';
import { getCornerPadding } from '../pages/App/clientHardware';
import { useWindowSize } from './useWindowSize';

export const appBarHeightSm = 56;

const appBarHeightLg = 80;

function useLargeAppBarHeight() {
  const cornerPadding = getCornerPadding();
  return appBarHeightLg + cornerPadding;
}

export function useAppBarHeight() {
  const { isLandscape } = useWindowSize();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const height = useLargeAppBarHeight();
  return isSmallScreen && !isLandscape ? height : appBarHeightSm;
}

interface ICommonStylesProps {
  appBarHeight: number;
}

const useCommonStyles = () => {
  const appBarHeight = useAppBarHeight();
  return makeStyles((theme: Theme) => {
    return {
      cardHeader: {
        color: white,
        textAlign: 'left',
        padding: '6px 16px !important',
        backgroundColor: 'black !important',

        [theme.breakpoints.down('sm')]: {
          flexWrap: 'wrap',
          rowGap: '0.4rem',
          padding: '1rem !important',
        },

        '& a': {
          color: `${theme.palette.primary.light} !important`,
        },
      },
      overUnity: {
        zIndex: 10001,
      },
      furBk: {
        backgroundImage: `url(${cdnRoot}/svgs/background-fur.svg)`,
        backgroundColor: theme.palette.secondary.dark,
      },
      fullFur: {
        backgroundImage: `url(${cdnRoot}/svgs/background-fur.svg)`,
        backgroundColor: theme.palette.secondary.dark,
        paddingTop: 20,
        width: '100%',
        minHeight: 'calc(100vh)',
        maxHeight: 'calc(100vh)',
        color: 'white',
        overflowY: 'hidden',
        overflowX: 'hidden',
      },
      cardRoot: {
        borderRadius: '12px !important',
        backgroundColor: `${theme.palette.secondary.dark} !important`,
      },
      cardContent: {
        backgroundColor: `${white} !important`,
      },
      expandIcon: {
        transform: 'rotate(90deg)',
        height: '22px',
      },
      subtext: {
        fontSize: '0.8rem',
      },
      cardActionFooter: {
        marginTop: `${appBarHeightSm}px`,
        paddingLeft: theme.spacing(4),
      },
      unfocusedText: {
        color: theme.palette.grey.A700,
      },
      primaryHover: {
        '&:hover': {
          backgroundColor: `${theme.palette.primary.light} !important`,
          color: theme.palette.primary.main,
        },
      },
      secondaryHover: {
        '&:hover': {
          backgroundColor: `${theme.palette.secondary.light} !important`,
          color: theme.palette.secondary.main,
        },
      },
      actionButton: {
        '&:hover': {
          backgroundColor: `${black} !important`,
          color: theme.palette.primary.main,
        },
      },
      secondaryButton: {
        '&:hover': {
          backgroundColor: `${black} !important`,
          color: theme.palette.secondary.main,
        },
      },
      select: {
        borderRadius: '4px !important',
        border: '1px solid #e9e9e9',
        marginTop: '0.1rem',
        '& li': {
          padding: '12px',
          fontWeight: '600',
          borderBottom: '1px solid #eeeeee',
        },
        '& li:last-child': {
          borderBottom: 'none',
        },
        '& .MuiMenuItem-root.Mui-selected': {
          backgroundColor: '#eeeeee',
        },
        '& .MuiMenuItem-root:hover': {
          backgroundColor: '#eeeeee',
        },
        '& .MuiMenuItem-root.Mui-selected:hover': {
          backgroundColor: '#eeeeee',
        },
        '& .MuiMenuItem-root:focus:hover': {
          backgroundColor: '#eeeeee',
        },
        '& .MuiMenuItem-root:focus': {
          backgroundColor: white,
        },
      },
      cardDropHeader: {
        backgroundColor: theme.palette.grey.A100,
        padding: '12px',
        minWidth: '100%',
        textAlign: 'center',
      },
      emptyPage: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        paddingTop: 40,
      },
      fullHeight: (props: ICommonStylesProps) => ({
        minHeight: `calc(100vh - ${props.appBarHeight}px)`,
        maxHeight: `calc(100vh - ${props.appBarHeight}px)`,
      }),
      fullCenter: (props: ICommonStylesProps) => ({
        minHeight: `calc(100vh - ${props.appBarHeight}px)`,
        maxHeight: `calc(100vh - ${props.appBarHeight}px)`,
        overflowY: 'auto',
        width: '100%',
        textAlign: 'center',
        marginTop: `${props.appBarHeight}px`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
      }),
      fullScreen: (props: ICommonStylesProps) => ({
        /* Fullscreen window that sits under the navBar */
        width: '100%',
        marginTop: `${props.appBarHeight}px`,
        minHeight: `calc(100vh - ${props.appBarHeight}px)`,
        maxHeight: `calc(100vh - ${props.appBarHeight}px)`,
      }),
      underHeaderBar: (props: ICommonStylesProps) => ({
        marginTop: `${props.appBarHeight}px`,
      }),
      appBarHeight: (props: ICommonStylesProps) => ({
        height: `${props.appBarHeight}px`,
      }),
      fullPadding: {
        /* For fullScreen usage, adds common padding, e.g. for fullscreen grid in shop/about */
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(20),
      },
      paddingTable: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
      },
      paddingSm: {
        padding: theme.spacing(2),
      },
      paddingMd: {
        padding: theme.spacing(4),
      },
      paddingLg: {
        padding: theme.spacing(10),
      },
      hideScrollBar: {
        scrollbarWidth: 'none', // Firefox
        msOverflowStyle: 'none', // IE and Edg
        '&::-webkit-scrollbar': {
          width: 0, // Chrome, Safari and Opera
        },
      },
      styledScrollBar: {
        '&::-webkit-scrollbar': {
          width: '0.5em',
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 50px #1A237E',
          webkitBoxShadow: 'inset 0 0 50px #1A237E',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.secondary.light,
          borderRadius: '10px',
        },

        [theme.breakpoints.down('sm')]: {
          '&::-webkit-scrollbar': {
            width: '0em',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          },
        },
      },
      [theme.breakpoints.down('sm')]: {
        fullPadding: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
        },
      },
      [theme.breakpoints.up('sm')]: {
        fullPadding: {
          paddingLeft: theme.spacing(10),
          paddingRight: theme.spacing(10),
        },
      },
      [theme.breakpoints.up('lg')]: {
        fullPadding: {
          paddingLeft: theme.spacing(20),
          paddingRight: theme.spacing(20),
        },
      },
      [theme.breakpoints.up('xl')]: {
        fullPadding: {
          paddingLeft: theme.spacing(40),
          paddingRight: theme.spacing(40),
        },
      },
      cardTitle: {
        fontSize: '1.8rem !important',
      },
      tabLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',

        '& svg': {
          height: '23.5px',
          width: '23.5px',
          marginRight: '7.5px',
          fill: '#B6B6F8',
        },
      },
      tabs: {
        zIndex: 1,
        width: '100%',
        position: 'absolute',
        top: '-4px',
        left: 0,
        right: 0,
        maxHeight: 40,
        backgroundColor: 'white',
        padding: '0 15px',
      },
      tab: {
        maxHeight: 40,
        border: 'none !important',
        fontSize: '18px !important',
        color: '#212121 !important',
        flexDirection: 'row',

        '& img, svg': {
          opacity: 0.6,
        },

        '&.Mui-selected': {
          color: `${theme.palette.secondary.light} !important`,

          '& svg': {
            fill: `${theme.palette.secondary.light} !important`,
          },

          '& img, svg': {
            opacity: 1,
          },
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px !important',
          lineHeight: '14px !important',
        },
        '@media (max-width: 395px)': {
          fontSize: '13px !important',
          lineHeight: '13px !important',
        },
      },
    };
  })({ appBarHeight });
};

export default useCommonStyles;
