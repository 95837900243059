import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Crown = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffd54a'
      d='M176.69 78.04s3.86 7.76 4.44 16.29c.06.86.85 1.48 1.7 1.31l29.04.03c.83.16 1.62-.44 1.69-1.28.27-3.27 1.07-10.95 3.01-16.88l-1.12.38a35.48 35.48 0 0 0-12.16 7l-.23.35-6.1-11.29-6.57 11.29c-.02-.02-9.33-8.19-13.7-7.2z'
    />
    <path
      fill='#ffc400'
      d='M198.48 84.06c-.7-1.3-2.56-1.33-3.31-.05l-4.87 8.37s-5.94-5.2-10.55-6.8c.75 2.58 1.4 5.62 1.61 8.76.06.86 32.35.89 32.42.04.16-1.94.5-5.44 1.17-9.2a35.609 35.609 0 0 0-11.75 6.85l-4.72-7.97z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M177.44 77.96c-.36 0-.6.38-.45.71.91 2.01 3.66 8.57 4.14 15.66.06.86.85 1.48 1.7 1.31l29.04.03c.83.16 1.62-.44 1.69-1.28.27-3.27 1.07-10.95 3.01-16.88l-1.12.38a35.48 35.48 0 0 0-12.16 7l-.23.35-6.1-11.29-6.57 11.29c-.02-.02-8.36-7.33-12.95-7.28z'
    />
    <circle
      fill='#2eff5d'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={217}
      cy={78}
      r={3}
    />
    <circle
      fill='#2eff5d'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={197}
      cy={71}
      r={3}
    />
    <circle
      fill='#2eff5d'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={176}
      cy={77}
      r={3}
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M179.3 101.67c-.73-1.88.2-4.83 2.08-5.55 4.83-1.87 9.96-2.82 15.24-2.82 5.28 0 10.41.95 15.24 2.82 1.88.73 2.81 3.68 2.08 5.55-.73 1.88-4.54-1.04-17.32-.97-12.58.07-16.76 2.41-17.32.97z'
    />
  </svg>
);

export default Crown;
