import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import { IToken } from '../../wallet/WalletTypes';
import Edition0Renderer from '../Editions/Edition0/Edition0Renderer';
import { edition0SlotNames } from '../../assets/edition0';
import { EditionAsset, FurballSlot } from '../schema';
import { edition0Assets } from '../Editions/Edition0/edition0Assets';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export interface IRenderToken extends IToken {
  size?: number | string;
  className?: string;
  disableSlots?: FurballRenderDisabledSlots;
}

export interface IBodyPart {
  partNumber: number;
  slot: FurballSlot;
  asset: EditionAsset;
}

export type FurballRenderDisabledSlots = { [key: string]: boolean };

export interface IRenderFurball {
  editionIndex: number;
  backgroundIndex: number;
  paletteIndex: number;
  slots: IBodyPart[];
  disableSlots?: FurballRenderDisabledSlots;
}

function edition0BodyParts(tokenId: string): IBodyPart[] {
  const ret: IBodyPart[] = [];
  for (let i = 0; i < edition0SlotNames.length; i++) {
    const idx = Number.parseInt(tokenId.substr(tokenId.length - 2, 2), 16);
    const slot = edition0SlotNames[i];
    const asset = edition0Assets.find(
      (a) => a.slot === slot && a.assetIndex === idx - 1,
    );
    if (!asset) {
      if (idx > 0) console.warn('[E0] missing asset for slot', slot, '#', idx);
    } else {
      ret.push({ partNumber: idx, slot, asset });
    }
    tokenId = tokenId.substr(0, tokenId.length - 2);
  }
  return ret;
}

export function getRenderableFurball(tokenId: string): IRenderFurball {
  if (!tokenId.startsWith('0x')) throw `no hex start ${tokenId}`;
  tokenId = tokenId.substr(2);

  if (!tokenId.endsWith('00')) throw `not genesis ${tokenId}`;
  let genData = tokenId.substr(0, tokenId.length - 2);

  const paletteIndex = Number.parseInt(
    genData.substr(genData.length - 2, 2),
    16,
  );
  genData = genData.substr(0, genData.length - 2);

  const backgroundIndex = Number.parseInt(
    genData.substr(genData.length - 2, 2),
    16,
  );
  genData = genData.substr(0, genData.length - 2);

  const slots = edition0BodyParts(genData);

  return {
    editionIndex: 0,
    backgroundIndex,
    paletteIndex,
    slots,
  };
}

export const FurballRenderer: FunctionComponent<IRenderToken> = (props) => {
  const { tokenId, className, size, disableSlots } = props;
  const classes = useStyles();
  const renderable = getRenderableFurball(tokenId);

  return (
    <Edition0Renderer
      size={size ?? 350}
      className={clsx(className, classes.root)}
      disableSlots={disableSlots}
      {...renderable}
    />
  );
};

export default FurballRenderer;
