import React, { FunctionComponent, useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography } from '@mui/material';
import OkDialog from '../OkDialog';
import WalletSlice from '../../wallet/WalletSlice';
import { ICraftingSlot, IInventoryQuantities } from '../../wallet/WalletTypes';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useAppDispatch } from '../../hooks';
import {
  NotificationFragment,
  RewardSource,
  useClaimCraftedItemMutation,
} from '../schema';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import SvgIconCaretRight from '../../assets/SvgIconCaretRight';

const useStyles = makeStyles((theme: Theme) => ({
  cont: {
    height: '480px',
    width: '530px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    padding: '2rem 1.5rem',
    paddingBottom: '1.35rem',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '325px',
    maxWidth: '325px',
    minHeight: '336px',
    color: 'white !important',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    background:
      'radial-gradient(circle at 50% 50%, rgba(209, 196, 233, 0.6) 0%, rgba(84, 48, 224, 0.675) 50%, rgba(84, 48, 224, 0.99) 100%)',

    '& h3': {
      fontSize: '2.1rem !important',
      marginBottom: '1rem !important',
      fontWeight: 600,
    },

    '& h6': {
      fontSize: '1.85rem !important',
      marginBottom: '1rem !important',
      marginTop: '0.5rem',
    },

    '& img': {
      height: '100px',
      width: '100px',
      margin: '0.75rem auto',
    },
  },
  items: {
    marginTop: '2.5rem',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  claim: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& button': {
      width: '100% !important',
      height: '55px !important',
      border: 'none !important',
      borderRadius: '0px !important',
      fontSize: '1.25rem !important',
    },
  },
  tags: {
    width: '300px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tag: {
    borderRadius: '20px',
    width: '135px',
    padding: '0.35rem 0.25rem',
    textAlign: 'center',
    fontSize: '0.715rem !important',
    border: '2px solid rgba(255, 255, 255, 0.8)',
    backgroundColor: 'rgba(84, 48, 224, 0.3)',
    color: 'white',
  },
  nextBtn: {
    position: 'absolute',
    top: 150,
    right: 25,

    '& svg': {
      marginLeft: '6px',
    },
  },
  prevBtn: {
    position: 'absolute',
    top: 150,
    left: 25,

    '& svg': {
      transform: 'rotate(180deg)',
      marginRight: '6px',
    },
  },
  navigateBtn: {
    height: '55px !important',
    width: '55px !important',
    minWidth: '55px !important',
    backgroundColor: 'transparent !important',
    borderRadius: '50% !important',
    border: 'none !important',
    color: `${theme.palette.secondary.main} !important`,
  },
}));

interface INotificationClaimItemsDialog {
  notification: NotificationFragment;
  markAsRead: (id: string[]) => Promise<void>;
  show: boolean;
  onClose: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  claimRewards: (pendingRewardId: string) => Promise<void>;
}

const NotificationClaimItemsDialog: FunctionComponent<
  INotificationClaimItemsDialog
> = (props) => {
  const {
    notification,
    markAsRead,
    show,
    onClose,
    loading,
    setLoading,
    claimRewards,
  } = props;
  const { id, thumbnailUrl, title, pendingReward } = notification;
  const items = pendingReward?.items ?? [];
  const multipleItems = items.length > 1;
  const classes = useStyles();

  const { addAlert } = useContext(AlertContext);
  const dispatch = useAppDispatch();

  const [itemIndex, setItemIndex] = useState<number>(0);
  const [claimCraftedItems] = useClaimCraftedItemMutation();

  async function handleFinishedCrafting() {
    setLoading(true);

    try {
      const pendingRewardId = pendingReward!.id;
      const { data } = await claimCraftedItems({
        variables: { pendingRewardId },
      });
      const { inventory, craftingSlots } = data!.claimPendingReward;
      await markAsRead([id]);

      dispatch(
        WalletSlice.actions.setInventory(inventory as IInventoryQuantities),
      );
      dispatch(
        WalletSlice.actions.setCraftingSlots(craftingSlots as ICraftingSlot[]),
      );
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'Successfully claimed item!',
      });
    } catch (error: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Unable to claim crafted item',
      });
    }

    setLoading(false);
  }

  const handleClick = async () => {
    if (pendingReward!.source === RewardSource.CraftingPlayer) {
      await handleFinishedCrafting();
    } else {
      await claimRewards(pendingReward!.id);
    }

    onClose();
  };

  function renderNextItemBtn() {
    if (!multipleItems || itemIndex === items.length - 1) {
      return null;
    }

    return (
      <div className={classes.nextBtn}>
        <Button
          onClick={() => setItemIndex((prevState) => prevState + 1)}
          className={classes.navigateBtn}>
          <SvgIconCaretRight height={27.5} width={27.5} />
        </Button>
      </div>
    );
  }

  function renderPrevItemBtn() {
    if (!multipleItems || itemIndex === 0) {
      return null;
    }

    return (
      <div className={classes.prevBtn}>
        <Button
          onClick={() => setItemIndex((prevState) => prevState - 1)}
          className={classes.navigateBtn}>
          <SvgIconCaretRight height={27.5} width={27.5} />
        </Button>
      </div>
    );
  }

  function renderItems() {
    const item = items[itemIndex];
    if (!item) return null;
    const itemDef = getItemDef(item.itemId);
    if (!itemDef) return null;

    return (
      <div key={`claim-item-${item.id}`} className={classes.item}>
        <Typography variant={'h3'}>{itemDef.name}</Typography>
        {thumbnailUrl && <img src={thumbnailUrl} />}
        <Typography variant={'h6'}>x {item.stack.toLocaleString()}</Typography>
        <div className={classes.tags}>
          <div className={classes.tag}>{itemDef.rarity}</div>
          <div className={classes.tag}>
            {item.itemGroup.split('_').join(' ')}
          </div>
        </div>
      </div>
    );
  }

  return (
    <OkDialog
      title={title}
      open={show}
      onClose={() => {
        setItemIndex(0);
        onClose();
      }}
      maxWidth={'sm'}
      noPadding={true}>
      {!!items && items.length > itemIndex && (
        <div className={classes.cont}>
          <div className={classes.items}>
            {renderPrevItemBtn()}
            {renderItems()}
            {renderNextItemBtn()}
          </div>
          <div className={classes.claim}>
            <Button
              variant={'contained'}
              disabled={loading}
              onClick={handleClick}>
              {loading ? 'Claiming...' : 'Claim!'}
            </Button>
          </div>
        </div>
      )}
    </OkDialog>
  );
};

export default NotificationClaimItemsDialog;
