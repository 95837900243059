import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  Theme,
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { useChangeUsernameMutation, usePlayerLazyQuery } from '../schema';
import OkDialog from '../OkDialog';
import theme from '../../themev2';
import WalletSlice from '../../wallet/WalletSlice';
import { debounce } from 'lodash';
import { useFurComponent } from '../../utils';
import { useTryCatchAsync } from '../../utils/Errors';

const useStyles = makeStyles((theme: Theme) => ({
  editUsernameContainer: {
    minWidth: '25rem',
    maxWidth: '25rem',
    padding: '1.5rem 0 0',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },

  usernameInput: {
    width: '100%',
  },

  updateButton: {
    width: '8rem',
  },

  loader: {
    display: 'grid',
    placeItems: 'center',
    position: 'absolute',
    right: '14px',
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
}

export const UsernameEditDialog: FunctionComponent<IProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const account = useWalletSelector((s) => s.contents);
  const { log } = useFurComponent(UsernameEditDialog);

  const [username, setUsername] = useState<string>(account?.username ?? '');
  const [changeUsername, { loading }] = useChangeUsernameMutation();
  const [suggestion, setSuggestion] = useState<string>();
  const [disabled, setDisabled] = useState(true);
  const [checkUsername, { loading: validating, error }] = usePlayerLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const updateUsername = useTryCatchAsync(
    'editUsername',
    async () => {
      const { data } = await changeUsername({
        variables: {
          username,
        },
      });

      if (data?.changeUsername.username) {
        dispatch(
          WalletSlice.actions.setWalletContents({
            ...(account as any),
            username: data?.changeUsername.username,
            socialName: data.changeUsername.socialName,
          }),
        );
      }

      onClose();
    },
    [username],
    'Username changed',
  );

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        const { data } = await checkUsername({
          variables: {
            query,
          },
        });

        setSuggestion(data?.player.id ? query : undefined);
      }, 1000),
    [],
  );

  useEffect(() => {
    debouncedSearch.cancel();

    if (!open || username === account?.username) {
      setSuggestion(undefined);
      return;
    }

    !!username && debouncedSearch(username);
  }, [username, open]);

  useEffect(() => {
    const disabled =
      username === account?.username ||
      loading ||
      !!suggestion ||
      validating ||
      !error;

    setDisabled(disabled);
  }, [loading, suggestion, error, validating]);

  return (
    <OkDialog title='Edit username' open={open} onClose={onClose}>
      <Grid
        container
        gap='0.5rem'
        direction='column'
        className={classes.editUsernameContainer}>
        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          position='relative'
          xs={12}>
          <TextField
            id='new-username'
            label='New Username'
            color='secondary'
            value={username}
            error={!!suggestion}
            className={classes.usernameInput}
            onChange={(e) => {
              const value = e.target.value;

              const isAlphabet = value.match(/^[A-Z]+$/i);
              if (!username.length && !isAlphabet) return;

              setDisabled(true);
              setSuggestion(undefined);
              setUsername(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {validating && (
            <div className={classes.loader}>
              <CircularProgress size='1.3rem' color='secondary' />
            </div>
          )}
        </Grid>

        {suggestion && (
          <Grid item xs={12}>
            <Typography
              px='0.5rem'
              color={theme.palette.error.main}
              fontSize='0.8rem !important'>
              The username "<b>{suggestion}</b>" is already taken.
            </Typography>
          </Grid>
        )}

        <Grid item container xs={12} justifyContent='flex-end' mt='1rem'>
          <Button
            disabled={disabled}
            variant='contained'
            color='primary'
            onClick={updateUsername}
            className={classes.updateButton}>
            {loading ? 'Updating...' : 'Update'}
          </Button>
        </Grid>
      </Grid>
    </OkDialog>
  );
};
