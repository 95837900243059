import * as React from 'react';
import { useFurverseContext } from './Furverse';
import UpgradeDialog from '../../components/Town/Upgrade/UpgradeDialog';
import useCommonStyles from '../../components/useCommonStyles';

export enum FurversePopup {
  Furball = 'Furball',
  Unifursity = 'Unifursity',
  News = 'News',
}
interface IFurversePopups {}

const FurversePopups: React.FunctionComponent<IFurversePopups> = (props) => {
  const { currentPopup, currentPopupId, showPopup } = useFurverseContext();
  const common = useCommonStyles();

  return (
    <>
      <UpgradeDialog
        show={currentPopup === FurversePopup.Furball}
        tokenId={currentPopupId}
        onClose={() => showPopup()}
        onAction={() => showPopup()}
        className={common.emptyPage}
        // rentFurball={isForRent}
      />
    </>
  );
};

export default FurversePopups;
