import * as React from 'react';

function SvgLobbyHeartDisabled(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 112.3 102'
      enableBackground='new 0 0 112.3 102'
      xmlSpace='preserve'
      {...props}>
      <g>
        <g>
          <g>
            <path
              fill='#FFFFFF'
              d='M12,52.9C0.7,41.6,0.7,23.3,12,12c5.5-5.5,12.7-8.5,20.5-8.5s15,3,20.5,8.5l3.3,3.3l3.3-3.3
				c5.5-5.5,12.7-8.5,20.5-8.5s15,3,20.5,8.5c5.5,5.5,8.5,12.7,8.5,20.5c0,7.7-3,15-8.5,20.5L56.1,97.1L12,52.9z'
            />
            <path
              d='M32.4,7c6.5,0,13,2.5,18,7.4l5.8,5.8l5.7-5.7c5-5,11.5-7.4,18-7.4s13,2.5,18,7.4c9.9,9.9,9.9,26,0,36l-5.8,5.8l-36,36
				l-36-36l-5.8-5.8c-9.9-9.9-9.9-26,0-36l0,0C19.4,9.5,25.9,7,32.4,7 M32.4,0C23.8,0,15.6,3.4,9.5,9.5C3.4,15.6,0,23.8,0,32.4
				s3.4,16.8,9.5,22.9l5.8,5.8l36,36l4.9,4.9l4.9-4.9l36-36l5.8-5.8c6.1-6.1,9.5-14.3,9.5-22.9s-3.4-16.8-9.5-22.9
				C96.7,3.4,88.5,0,79.9,0c-8.7,0-16.8,3.4-22.9,9.5l-0.8,0.8l-0.8-0.8C49.2,3.4,41.1,0,32.4,0L32.4,0z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgLobbyHeartDisabled;
