import React, { FunctionComponent } from 'react';
import { Theme, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

// @ts-ignore
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: 'black !important',
    textAlign: 'center',
    // color: 'white',
    // height: '150px',
    // width: '100%',
    // maxWidth: '750px',
    // margin: '1.5rem 1.5rem 0 1.5rem',
    borderRadius: '10px !important',
    backgroundColor: '#fdeb73 !important',
    display: 'flex',
    alignItems: 'center !important',
    padding: '0.5rem 1.5rem !important',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important',
    gap: '1.75rem !important',
    // position: 'relative',
    // border: '2px solid black',

    [theme.breakpoints.down(1180)]: {
      height: 'auto',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '0.5rem',
    },
  },
  ftueArrow: {
    color: '#fdeb73 !important',
    height: '0.8em !important',
  },
  message: {
    width: '100%',
    textAlign: 'center',
  },
  popper: {
    zIndex: '1000 !important',
  },
}));

interface IFtuePopout {
  message?: string;
  open?: boolean;
  children?: React.ReactNode;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

export const FtuePopout: FunctionComponent<IFtuePopout> = (props) => {
  const { message, children } = props;
  const classes = useStyles();
  const placement = props.placement ?? 'top';
  const open = props.open === undefined ? !!message : props.open;

  const title = (
    <Typography variant={'h6'} className={classes.message}>
      {message?.toUpperCase() ?? '?'}
    </Typography>
  );

  return (
    <Tooltip
      classes={{
        tooltip: classes.root,
        arrow: classes.ftueArrow,
        popper: classes.popper,
      }}
      open={open}
      arrow
      title={title}
      placement={placement}>
      <span>{children ?? ' '}</span>
    </Tooltip>
  );
};

export default FtuePopout;
