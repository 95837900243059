import React, { FunctionComponent } from 'react';
import { Grid, MenuItem, Select, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballModifier } from './FurballEditorWidget';
import { VoyageEncounterType } from '../../../components/schema';
import { useFurComponent } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export const VoyageEditor: FunctionComponent<IFurballModifier> = (props) => {
  const { mods, setMods, furball } = props;
  const { log } = useFurComponent(VoyageEditor);
  const classes = useStyles();
  const voyage = furball.activeVoyage;
  const days = [...Array(22)].map((a, i) => i);
  const encounters = [null, VoyageEncounterType.Fur, VoyageEncounterType.Loot];

  function updateEncounter(str: string): void {
    let enc: VoyageEncounterType | null = null;
    if (str === 'FUR') enc = VoyageEncounterType.Fur;
    if (str === 'LOOT') enc = VoyageEncounterType.Loot;
    log.info('enc', str, enc);
    setMods({ ...mods, addEncounterType: enc });
  }

  return (
    <Grid container className={clsx(classes.root)}>
      {!voyage && (
        <Grid item xs={12}>
          <Typography variant={'h5'}>No Active Voyage</Typography>
        </Grid>
      )}
      {voyage && (
        <React.Fragment>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Active Voyage</Typography>
          </Grid>
          <Grid item xs={6}>
            <Select
              style={{ width: '100%' }}
              value={
                mods.setActiveVoyageDays === null
                  ? voyage?.daysComplete ?? 0
                  : mods.setActiveVoyageDays
              }
              onChange={(e, v) =>
                setMods({
                  ...mods,
                  setActiveVoyageDays: e.target.value as number,
                })
              }>
              {days.map((i) => {
                return (
                  <MenuItem key={i} value={i}>
                    {i}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <Select
              style={{ width: '100%' }}
              value={
                mods.addEncounterType === null ? '' : mods.addEncounterType
              }
              onChange={(e, v) => updateEncounter(e.target.value.toString())}>
              {encounters.map((enc) => {
                const str = enc?.toString() ?? 'None';
                return (
                  <MenuItem key={str} value={enc ?? ''}>
                    {str}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
};

export default VoyageEditor;
