import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  Button,
  Typography,
  MenuItem,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurball } from '../../../wallet/WalletTypes';
import { FurballCard } from '../../../components/Furballs';
import useCommonStyles from '../../../components/useCommonStyles';
import InventoryEditor from './InventoryEditor';
import PlayerFurballLink from '../../../components/Players/PlayerFurballLink';
import {
  FurballModificationArgsInput,
  FurOAuthTokenInput,
  useModifyFurballMutation,
} from '../../../components/schema';
import { signOAuthToken } from '../../../components/Auth/OAuthSig';
import { useWalletSelector } from '../../../hooks';
import WalletContext from '../../../wallet/WalletContext';
import { parseFurball } from '../../../wallet';
import VoyageEditor from './VoyageEditor';
import { SelectCustom } from '../../../components/SelectCustom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IFurballEditorWidget {
  furball: IFurball;
  onClear?: () => void;
  onChange?: (fb: IFurball) => void;
}

export interface IFurballModifier {
  furball: IFurball;
  mods: FurballModificationArgsInput;
  setMods: (m: FurballModificationArgsInput) => void;
  onChange?: (fb: IFurball) => void; // temporary fix for type error
}

export const FurballEditorWidget: FunctionComponent<IFurballEditorWidget> = (
  props,
) => {
  const { furball, onClear, onChange } = props;
  const common = useCommonStyles();
  const classes = useStyles();
  const walletAddress = useWalletSelector((s) => s.address);
  const context = React.useContext(WalletContext);
  const [modify, { data, loading, error }] = useModifyFurballMutation();
  const [mods, setMods] = React.useState<FurballModificationArgsInput>({
    tokenId: furball.tokenId,
    drops: [],
    pickups: [],
    setActiveVoyageDays: null,
    setMarketListingCount: null,
    addEncounterType: null,
    setBondingDays: null,
  });
  const hasChanges =
    mods.drops.length > 0 ||
    mods.pickups.length > 0 ||
    mods.setActiveVoyageDays != null ||
    mods.addEncounterType != null ||
    mods.setMarketListingCount != null ||
    mods.setBondingDays != null;

  React.useEffect(() => {
    if (!data) return;
    const fb = parseFurball(data.modification.furball);
    if (fb && onChange) onChange(fb);
  }, [data]);

  async function saveChanges() {
    if (!walletAddress || !context || !hasChanges) return;
    const access = mods.drops.length + mods.pickups.length;
    const token: FurOAuthTokenInput = await signOAuthToken(
      walletAddress,
      context,
      access,
    );
    const variables = { token, mods };
    try {
      const res = await modify({ variables });
    } catch (e) {
      console.error(e, 'Edit');
    }
  }

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={12} lg={4}>
        <FurballCard tokenId={furball.tokenId} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Typography variant={'h5'}>
          <PlayerFurballLink tokenId={furball.tokenId} furball={furball} />
        </Typography>
        <InventoryEditor furball={furball} mods={mods} setMods={setMods} />
        <VoyageEditor furball={furball} mods={mods} setMods={setMods} />

        <Typography variant={'h5'}>Listed for Sale?</Typography>
        <SelectCustom
          style={{ width: '100%' }}
          value={
            mods.setMarketListingCount == null ? -1 : mods.setMarketListingCount
          }
          onChange={(e, v) =>
            setMods({
              ...mods,
              setMarketListingCount:
                e.target.value === -1 ? null : (e.target.value as number),
            })
          }>
          <MenuItem key={'-1'} value={-1}>
            Unchanged
          </MenuItem>
          {[...Array(2)].map((_, i) => {
            return (
              <MenuItem key={i.toString()} value={i}>
                {i === 0 ? 'No' : 'Yes'}
              </MenuItem>
            );
          })}
        </SelectCustom>

        <Typography variant={'h5'}>Bonding Days</Typography>
        <TextField
          style={{ width: '100%' }}
          value={mods.setBondingDays}
          onChange={(e) =>
            setMods({
              ...mods,
              setBondingDays: parseInt(e.target.value as string),
            })
          }
        />
      </Grid>
      <Grid item xs={12} lg={4}></Grid>
      <Grid item xs={12} lg={4}>
        <Button
          variant={'contained'}
          color={'primary'}
          className={common.actionButton}
          onClick={saveChanges}
          disabled={loading || !hasChanges}>
          Save Changes
        </Button>
        {data && <Typography variant={'subtitle2'}>Saved!</Typography>}
        {error && (
          <Typography variant={'subtitle2'}>{error.message}</Typography>
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        <Button
          variant={'contained'}
          color={'secondary'}
          className={common.secondaryButton}
          onClick={onClear}>
          Return to Search
        </Button>
      </Grid>
    </Grid>
  );
};

export default FurballEditorWidget;
