import { FormationPosition } from '../../components/schema';

export type FormationDefinition = FormationPosition[];

export const size1Formations: FormationDefinition[] = [
  [FormationPosition.TopFront],
  [FormationPosition.BottomFront],
  [FormationPosition.Center],
  [FormationPosition.BottomBack],
  [FormationPosition.TopBack],
];

export const size3Formations: FormationDefinition[] = [
  [
    FormationPosition.Center,
    FormationPosition.TopBack,
    FormationPosition.BottomBack,
  ], // All back
  [
    FormationPosition.Center,
    FormationPosition.TopBack,
    FormationPosition.TopFront,
  ], // All top
  [
    FormationPosition.Center,
    FormationPosition.BottomBack,
    FormationPosition.BottomFront,
  ], // All bottom
  [
    FormationPosition.Center,
    FormationPosition.TopFront,
    FormationPosition.BottomFront,
  ], // All front
  [
    FormationPosition.Center,
    FormationPosition.TopBack,
    FormationPosition.BottomFront,
  ], // Backslash \ shape
  [
    FormationPosition.Center,
    FormationPosition.BottomBack,
    FormationPosition.TopFront,
  ], // Frontslash / shape
];

export const size5Formations: FormationDefinition[] = [
  [
    FormationPosition.TopFront,
    FormationPosition.Center,
    FormationPosition.BottomFront,
    FormationPosition.TopBack,
    FormationPosition.BottomBack,
  ],
];

export const allFormations = {
  1: size1Formations,
  3: size3Formations,
  5: size5Formations,
};
