import React, { FunctionComponent } from 'react';
import HowToPlay from './HowToPlay';
import FooterV2 from './FooterV2';
import HeroV2 from './HeroV2';
import { usePageTitle } from '../../Analytics';

interface OwnProps {}

type Props = OwnProps;

const HomeV2: FunctionComponent<Props> = (props) => {
  usePageTitle('Home | Furballs');

  return (
    <>
      <HeroV2 />
      <HowToPlay />
      {/*<RoadmapV2 />*/}
      {/*<FurTeam />*/}
      {/*<Updates /> */}
      <FooterV2 />
    </>
  );
};

export default HomeV2;
