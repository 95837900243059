import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Seal = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.04c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <path
      fill={props.renderer.color2}
      d='M173.84 215.07c-.31-.17-.62-.33-.92-.5.41.22.72.39.92.5zM172.22 214.2l-.62-.34c.2.11.41.22.62.34zM172.71 214.46c-.17-.09-.33-.18-.49-.27.17.1.34.19.49.27zM174.05 215.18c-.03-.01-.1-.05-.21-.11.07.04.13.07.21.11zM174.06 215.19s-.01 0-.01-.01c0 .01.01.01.01.01zM172.91 214.57c-.07-.04-.13-.07-.2-.11.07.04.14.08.2.11zM168.51 212.2c.14.08.28.15.42.23-.14-.08-.28-.16-.42-.23zM171.59 213.86c-.23-.12-.45-.24-.68-.36.24.12.47.25.68.36zM170.47 213.26c-.49-.26-1.01-.54-1.55-.83.5.26 1.02.54 1.55.83z'
    />
    <path
      fill={props.renderer.color2}
      d='M186.78 293.86h2.61c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.35-2.72-4.14-5.96-4.47-5.72-21.46 15.53-60.73 7.14-60.73 7.14 32.3 17.39 16.83 56.12 16.83 56.12l-4.09 22.55zM170.92 213.5c-.03-.02-.06-.03-.09-.05.03.01.06.03.09.05zM170.83 213.45c-.12-.06-.24-.13-.35-.19.11.06.23.13.35.19z'
    />
    <path
      fill={props.renderer.color1}
      d='M215.4 223.82s-1.34 31.22 16.98 34.59c2.16-4.72 4.07-10.07 5.6-16.1 1.71-6.73 1-16.55 1.03-23.58-1.52-3.58-23.61 5.09-23.61 5.09z'
    />
    <path
      fill={props.renderer.color2}
      d='M129.88 249.52c-25.08-3.84-24.71 8.2-24.71 8.2l1.44 14.62-7.92 10.34 7.92 11.17h56.33c-.01.01-4.1-39.9-33.06-44.33z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h113.95c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17'
    />
    <path
      fill={props.renderer.color2}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill={props.renderer.color2}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color1}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 .27.01.54.02.82 52.82 19.8 55.8-2.31 65.85-16.99 4.03-5.89 8-5.89 12.03 0 10.03 14.65 16.55 35.65 62.61 17.06.01-.29.02-.59.02-.88-.01-6.63-3.21-12.84-8.83-18.24z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill='#fdd64c'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M265.42 224.42c-2.04.44-6.53.88-8.17-6.07-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Seal;
