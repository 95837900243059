import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, CircularProgress, Grid, Typography } from '@mui/material';

import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    width: '100%',
    minHeight: 'calc(100vh)',
    maxHeight: 'calc(100vh)',
    color: 'white',
    overflowY: 'hidden',
    overflowX: 'hidden',
    // height: '100vh',
    // marginTop: 0,
    display: 'grid',
    placeItems: 'center',
    // backgroundColor: 'white',
    // position: 'relative',
    zIndex: 1252,
  },

  caption: {
    textAlign: 'center',
    fontSize: '1.5rem !important',
  },
}));

type Props = {
  title?: string;
};

const Preloader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <div className={clsx(classes.root, common.furBk)}>
      <Grid
        container
        // xs={12}
        gap='2.5rem'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'>
        <CircularProgress size={100} color='secondary' />

        <Typography fontWeight={600} className={classes.caption}>
          {props.title ?? 'Synchronizing Account...'}
        </Typography>
      </Grid>
    </div>
  );
};

export default Preloader;
