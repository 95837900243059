import { SvgEditionSlot } from '../part';
import * as React from 'react';

const GreyHoodie = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#636a67'
      d='M189.92 271.7c1.68-4.92 1.37-9.26 1.36-9.35-.05-1.78 1.39-1.75 1.41-1.75 1.22-.02 1.7.61 1.73 1.78.01.46.09 10.81-6.67 21.64 1.7 2.06 2.79 5.08 2.98 7.91 2.13-.16 4.76-.61 6.74-1.35 4.92-1.82 12.58-5.62 19.86-12.98 8.85-8.95 15.16-21.08 18.88-35.77 1.25-4.93 1.22-12.06 1.12-18.14-.07-4.12.28-13.49.64-14.96-4.3 2.23-32.13 10.05-41.65 10.05-9.52 0-78.56-7.56-78.56-7.56-1.58 16.49-1.56 58.44 35.16 80.46l7.48.14c-.61-.89-1.48-2.22-1.77-3.3l-5.91-19.4c-.34-1.14.05-2 1.2-2.34 1.14-.34 1.63.13 1.97 1.27l1.81 6.56c16.51 6.77 32.22-2.91 32.22-2.91z'
    />
    <path
      fill='#636a67'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M205.36 230.32c4.14-3.46 16.84-12.6 33.96-13.08 16.18-.45 7.91-16.22 5.88-15.36-19.92 8.4-33.12 7.44-40.68 14.4'
    />
    <path
      fill='#636a67'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M205.36 230.32s-18.24-13.44-87.96-12.96c-3.55.02-14.52-3.12-14.76-11.52-.2-7.08 7.67-9.4 10.2-11.4 0 0 .47-4.97 2.64-7.32 4.32-4.68 11.4-5.28 11.4-5.28s6.08 12.66 22.68 21.48c10.55 5.6 25.1 7.52 47.64 10.2 10.08 1.2 7.68 10.32 8.16 16.8z'
    />
    <path d='M178.24 212.44c-32.76-8.04-48.84-25.56-48.84-25.56s-5.4 1.32-5.64 6.84c-.14 3.32 6.15 7.03 9.12 8.52 19.2 9.6 45.36 10.2 45.36 10.2z' />
    <path
      fill='#FFF'
      d='M215.91 253.32c-.43 0-.86-.11-1.23-.32-5.59-3.16-7.15-11.93-5.05-17.83 2.87-8.09 1.41-13.18 1.39-13.23-.2-.62-.15-1.29.16-1.89.3-.59.82-1.04 1.46-1.24a2.504 2.504 0 0 1 3.15 1.61c.09.26 2.06 6.56-1.44 16.43-1.36 3.83-.33 10.03 2.8 11.8.58.33 1 .86 1.18 1.51.18.64.1 1.32-.23 1.9a2.54 2.54 0 0 1-2.19 1.26z'
    />
    <path d='M213.41 219.76c.6 0 1.16.38 1.36.99.08.25 1.95 6.2-1.44 15.73-1.51 4.24-.38 11.03 3.29 13.1.68.39.93 1.25.54 1.94-.26.46-.74.72-1.24.72-.24 0-.48-.06-.7-.18-4.97-2.81-6.52-11.05-4.57-16.53 3.03-8.54 1.43-13.85 1.41-13.9-.24-.75.17-1.55.91-1.79.14-.06.29-.08.44-.08m0-2.16c-.38 0-.75.06-1.11.18-.91.3-1.65.93-2.08 1.78-.43.84-.51 1.8-.23 2.7.06.23 1.3 5.01-1.38 12.55-2.24 6.31-.51 15.72 5.54 19.14a3.59 3.59 0 0 0 4.88-1.36c.47-.83.59-1.8.33-2.72-.26-.92-.86-1.69-1.69-2.16-2.4-1.35-3.61-6.85-2.31-10.5 3.56-10.04 1.68-16.43 1.45-17.12a3.559 3.559 0 0 0-3.4-2.49z' />
    <path
      fill='#FFF'
      d='M198.74 256.36c-.83-.05-1.58-.51-2-1.23-.18-.3-4.28-7.43-1.47-18.15 1.19-4.54.85-8.27.35-10.59-.52-2.45-1.33-3.91-1.34-3.93-.68-1.19-.26-2.72.93-3.41.38-.22.81-.33 1.25-.33.89 0 1.72.48 2.16 1.25.18.3 4.3 7.58 1.5 18.29-1.19 4.54-.85 8.22-.37 10.52.51 2.41 1.31 3.83 1.32 3.85.33.56.42 1.23.26 1.88-.17.65-.58 1.19-1.16 1.53-.38.23-.82.35-1.27.35l-.16-.03z'
    />
    <path d='M196.45 219.79c.49 0 .97.25 1.23.71.17.29 4.08 7.2 1.38 17.47-1.24 4.74-.89 8.61-.38 11.02.56 2.62 1.43 4.14 1.43 4.15.4.68.17 1.55-.51 1.95-.23.13-.47.2-.72.2h-.09c-.46-.03-.89-.28-1.14-.7-.17-.28-4.05-7.05-1.36-17.33 1.24-4.74.88-8.65.36-11.09-.57-2.66-1.45-4.22-1.45-4.23a1.43 1.43 0 0 1 .52-1.94c.25-.15.49-.21.73-.21m0-2.16c-.63 0-1.24.17-1.79.48-1.71.98-2.3 3.17-1.32 4.88 0 0 .73 1.35 1.22 3.61.47 2.21.8 5.76-.34 10.1-1.19 4.55-1.34 9.19-.42 13.42.72 3.32 1.87 5.34 2 5.55.6 1.03 1.67 1.68 2.86 1.76.08 0 .15.01.23.01.64 0 1.26-.17 1.81-.49 1.7-1 2.27-3.2 1.27-4.9 0 0-.71-1.3-1.18-3.51-.46-2.18-.78-5.69.35-10.02 1.19-4.55 1.33-9.21.4-13.49-.73-3.35-1.88-5.4-2.01-5.62a3.53 3.53 0 0 0-3.08-1.78z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M117.4 217.36s-4.8 57.24 40.2 76.2'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m157.31 274.31.15.05c10.86 4.02 23.16 3 33.21-2.75'
    />
    <path d='M223.9 202.79s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57zM114.68 194.4s3.58 10.68 27.54 15.31c0 0-25.6-.59-31.05-13.17l3.51-2.14z' />
  </svg>
);

export default GreyHoodie;
