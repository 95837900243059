import React, { FunctionComponent } from 'react';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cdnRoot } from '../../../theme';
import { EntityEffectOperand, RealmType } from '../../schema';
import { getItemDef } from '../../BossFights/BossRewards/bossRewardsUtils';
import { IBattleEffectPassive } from '../../../wallet/WalletTypes';
import { getRealmName } from '../../Zones/ZoneHelpers';
import { getBossNameFromRealm } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.5px',

    '& img': {
      height: '27.5px !important',
      width: '27.5px !important',
      marginRight: '7.5px',
      marginTop: '0 !important',
    },
  },
}));

export interface IEquipmentEffect {}

interface IEquipmentEffects {
  itemId: number;
  effects?: IBattleEffectPassive[];
  passives?: IBattleEffectPassive[];
}

interface IEquipmentStats {
  equipment: IEquipmentEffects;
  className?: string;
}

export const EquipmentStats: FunctionComponent<IEquipmentStats> = (props) => {
  const { equipment } = props;
  const classes = useStyles();
  const className = props.className ?? classes.root;
  const itemDef = getItemDef(equipment.itemId);
  const bossName =
    !itemDef || itemDef.realmAffiliation === RealmType.None
      ? undefined
      : getBossNameFromRealm(itemDef.realmAffiliation);
  const realmName =
    !itemDef || itemDef.realmAffiliation === RealmType.None
      ? undefined
      : getRealmName(itemDef.realmAffiliation);
  const effects = equipment.effects ?? equipment.passives ?? [];

  function renderStatsFromItem() {
    return effects.map((passive) => {
      const { stat, value, operand } = passive;

      const prefix =
        operand == EntityEffectOperand.Add
          ? '+'
          : operand == EntityEffectOperand.Subtract
          ? '-'
          : '';

      const statParts = stat.split('_');
      const statInitials =
        statParts.length === 1
          ? stat
          : statParts.map((part) => part.slice(0, 1)).join('');

      return (
        <div key={`${stat}-${operand}-${value}`} className={className}>
          <img
            src={`${cdnRoot}/images/icons/battle_stats/${stat.toLowerCase()}.png`}
          />
          <Typography variant={'body1'}>
            <span>
              {prefix}
              {value} {statInitials}
            </span>
          </Typography>
        </div>
      );
    });
  }

  return (
    <div>
      {renderStatsFromItem()}
      {realmName && (
        <div className={className}>
          <img src={`${cdnRoot}/images/avatars/${bossName}.png`} />
          <Typography variant={'body1'}>{realmName} Only</Typography>
        </div>
      )}
    </div>
  );
};

export default EquipmentStats;
