import React, {
  Children,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import clsx from 'clsx';
import SvgIconCaretLeft from '../assets/SvgIconCaretLeft';
import SvgIconCaretRight from '../assets/SvgIconCaretRight';

const useStyles = makeStyles((theme: Theme) => ({
  carouselContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  carousel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflowX: 'hidden',
  },

  carouselInner: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    width: '100%',
  },

  carouselItem: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    minHeight: '8rem',
    flexShrink: 0,
    cursor: 'pointer',
  },

  carouselItemScaled: {
    // pointerEvents: 'none',
    transform: (props: any) =>
      props.scaleCurrentSlide ? 'scale(1.5)' : 'none',
  },

  carouselArrow: {
    display: 'grid',
    placeItems: 'center',
    background: 'none',
    outline: 'none',
    border: 'none',
    padding: 0,
    cursor: 'pointer',

    '&:hover': {
      opacity: '0.8',
    },

    '&:first-of-type': {
      transform: 'rotate(180deg)',
    },

    '& svg': {
      color: theme.palette.primary.main,
    },

    '&:disabled': {
      filter: 'grayScale(100%)',
      opacity: 1,
      pointerEvents: 'none',
    },
  },

  carouselTransition: {
    transition: 'all 0.3s linear',
  },
}));

interface ISkillsCarousel {
  gap?: number;
  showArrows?: boolean;
  visibleAtOnce?: number;
  children?: React.ReactNode;
  scaleCurrentSlide?: boolean;
  arrowSize?: number;
  disabled?: boolean;
  onSlideChange?: (index: number) => void;
  initialIndex?: number;
}

export const Carousel = ({
  children,
  gap = 55,
  showArrows = true,
  visibleAtOnce = 3,
  arrowSize = 1.7,
  scaleCurrentSlide = true,
  onSlideChange,
  disabled,
  initialIndex,
}: ISkillsCarousel) => {
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [itemCount, setItemCount] = useState<number>(0);
  const [allItems, setAllItems] = useState<HTMLDivElement[]>();
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex ?? 1);
  const [hasInit, setHasInit] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);
  const carouselInnerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ scaleCurrentSlide });

  const childrenArray = Children.toArray(children);

  const moveCarousel = useCallback(
    (direction: 'left' | 'right', currentIndex: number) => {
      const inner = carouselInnerRef.current;
      const isLast = direction === 'left' && currentIndex === 0;
      const isFirst = direction === 'right' && currentIndex === itemCount - 1;

      if (!inner || isLast || isFirst) return;
      const newIndex =
        direction === 'left' ? currentIndex - 1 : currentIndex + 1;
      setCurrentIndex(newIndex);
      onSlideChange && onSlideChange(newIndex);

      // const fullwidth = itemWidth + gap;
      // const xLeft = currentIndex * fullwidth - fullwidth * 2;
      // const xRight = currentIndex * fullwidth;
      // const x = direction === 'left' ? xLeft : xRight;
      // inner.style.transform = `translateX(${-x}px)`;
    },
    [currentIndex, itemCount, itemWidth],
  );

  useLayoutEffect(() => {
    const carousel = carouselRef.current;
    const carouselInner = carouselInnerRef.current;
    const carouselWidth = carousel?.clientWidth;

    const initCarousel = () => {
      if (!carouselWidth || !carouselInner) return;

      const items =
        carousel?.querySelectorAll<HTMLDivElement>('.carousel-item');
      const itemsArray = items ? Array.from(items) : [];
      setAllItems(itemsArray);

      // set carousel height to height of single item
      const itemHeight = itemsArray[0]?.offsetHeight;

      carousel.style.height = `${
        itemHeight + (scaleCurrentSlide ? itemHeight / 2 : 0)
      }px`;
      carouselInner.style.height = `${itemHeight}px`;

      itemsArray?.forEach((item: HTMLDivElement, index) => {
        item.style.width = `${carouselWidth / visibleAtOnce - gap}px`;
        item.style.margin = `0 ${gap / 2}px`;

        setItemWidth(carouselWidth / visibleAtOnce - gap);
        setItemCount(itemsArray.length);
      });
    };

    initCarousel();

    window.addEventListener('resize', initCarousel);

    return () => {
      window.removeEventListener('resize', initCarousel);
    };
  }, [children]);

  useEffect(() => {
    const carouselInner = carouselInnerRef.current;
    if (!carouselInner || !allItems) return;

    // translate carouselInner to make currentIndex centered
    const { x: x1, width: w1 } = carouselInner.getBoundingClientRect();
    const { x: x2, width: w2 } = allItems[currentIndex].getBoundingClientRect();

    const containerMidpoint = x1 + w1 / 2;
    const currentMidpoint = x2 + w2 / 2;
    const diff = currentMidpoint - containerMidpoint;
    carouselInner.style.transform = `translateX(${-diff}px)`;

    !hasInit && setHasInit(true);
  }, [carouselInnerRef, itemWidth, itemCount, allItems, currentIndex]);

  return (
    <div ref={containerRef} className={classes.carouselContainer}>
      {showArrows && (
        <button
          disabled={currentIndex === 0 || disabled}
          className={clsx(classes.carouselArrow, 'carousel-arrow')}
          onClick={() => moveCarousel('left', currentIndex)}>
          <SvgIconCaretLeft
            width={`${arrowSize}rem`}
            height={`${arrowSize}rem`}
          />
        </button>
      )}
      <div ref={carouselRef} className={classes.carousel}>
        <div
          ref={carouselInnerRef}
          className={clsx(classes.carouselInner, {
            [classes.carouselTransition]: hasInit,
          })}>
          {Children.map(childrenArray, (child, i) => (
            <div
              onClick={() => {
                if (currentIndex === i || disabled) return;
                const isLeft = currentIndex > i;
                isLeft
                  ? moveCarousel('left', currentIndex)
                  : moveCarousel('right', currentIndex);
              }}
              className={clsx('carousel-item', classes.carouselItem, {
                [classes.carouselItemScaled]: i === currentIndex,
                [classes.carouselTransition]: hasInit,
              })}>
              {child}
            </div>
          ))}
        </div>
      </div>
      {showArrows && (
        <button
          disabled={currentIndex === itemCount - 1 || disabled}
          className={clsx(classes.carouselArrow, 'carousel-arrow')}
          onClick={() => moveCarousel('right', currentIndex)}>
          <SvgIconCaretRight
            width={`${arrowSize}rem`}
            height={`${arrowSize}rem`}
          />
        </button>
      )}
    </div>
  );
};

export default Carousel;
