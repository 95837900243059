import React from 'react';
import { cdnRoot } from './theme';
import { useTimekeeperSelector } from './components/Timekeeper/TimekeeperSlice';
import { useAuthContext } from './components/Auth/AuthContext';
import { useWalletSelector } from './hooks';
import { useFurverseContext } from './pages/Furverse/Furverse';

interface IHeaderSubMenuItem {
  title: string;
  icon?: string | null;
  url: string;
  external: boolean;
}

interface IHeaderMenuItem {
  title: string;
  icon?: string;
  url?: string;
  subMenu: IHeaderSubMenuItem[];
}

const gameMenu: IHeaderMenuItem[] = [
  {
    title: 'Return to Game',
    icon: undefined,
    url: '/furverse',
    subMenu: [],
  },
];

const headerMenu: IHeaderMenuItem[] = [
  {
    title: 'Game',
    icon: undefined,
    url: undefined,
    subMenu: [
      {
        title: 'Play Furballs',
        icon: 'Swap',
        url: '/play',
        external: false,
      },
      // {
      //   title: 'Play Game',
      //   icon: 'Play',
      //   url: '/boss',
      //   external: false,
      // },
      {
        title: 'My Account',
        icon: '',
        url: '/account',
        external: false,
      },
      // {
      //   title: 'Guides',
      //   icon: null,
      //   url: 'https://guide.furballs.com/',
      //   external: true,
      // },
      {
        title: 'Leaderboards',
        icon: null,
        url: '/leaderboards',
        external: false,
      },
    ],
  },
  {
    title: 'Market',
    icon: undefined,
    url: undefined,
    subMenu: [
      {
        title: 'Get Furballs',
        icon: `${cdnRoot}/images/avatar_placeholder.png`,
        url: '/market/furballs/get',
        external: false,
      },
      {
        title: window.ethereum ? 'Currency Exchange' : 'Get FUR',
        icon: 'Swap',
        url: '/swap',
        external: false,
      },
      // {
      //   title: 'Mint Furball',
      //   icon: 'Mint',
      //   url: '/adopt',
      //   external: false,
      // },
      {
        title: 'Loot Market',
        icon: 'Loot',
        url: '/market/loot/browse',
        external: false,
      },
      // {
      //   title: 'Buy TIX',
      //   icon: 'Tix',
      //   url: '/tix',
      //   external: false,
      // },
    ],
  },
  {
    title: 'Connect',
    icon: '',
    url: undefined,
    subMenu: [
      // {
      //   title: 'Discord',
      //   icon: null,
      //   url: discordChannelInviteUrl,
      //   external: true,
      // },
      {
        title: 'Twitter',
        icon: null,
        url: 'https://twitter.com/furballsNFT',
        external: true,
      },
      {
        title: 'YouTube',
        icon: 'Youtube',
        url: 'https://www.youtube.com/channel/UCkqMdzXHwW6600_20UeEG8Q',
        external: true,
      },
      // {
      //   title: 'Merch',
      //   icon: null,
      //   url: 'https://merch.furballs.com/',
      //   external: true,
      // },
      // {
      //   title: 'Site Status',
      //   icon: 'Guides',
      //   url: 'https://status.furballs.com/',
      //   external: true,
      // },
      // {
      //   title: 'OpenSea',
      //   icon: null,
      //   url: 'https://opensea.io/collection/furballs-com-official',
      //   external: true,
      // },
      // {
      //   title: 'Press',
      //   icon: 'Swap',
      //   url: 'https://press.furballs.com',
      //   external: true,
      // },
    ],
  },
];

export function useHeaderMenuOptions() {
  const { isSignedIn } = useAuthContext();
  const { gameReady } = useFurverseContext();
  const bossBattleCount = useWalletSelector(
    (c) => c.contents?.bossBattleCount ?? 0,
  );
  const ethPrice = useTimekeeperSelector((s) => s.meta?.ethPrice ?? 0);
  const tournaments = useTimekeeperSelector(
    (s) => s.meta?.activeTournaments ?? [],
  );
  const lastLootBoxAt = useTimekeeperSelector(
    (s) => s.meta?.lootBox?.myLastPurchaseAt,
  );
  const day = 3600000 * 24;
  const lootBoxEligible =
    (!lastLootBoxAt ||
      new Date().getTime() - new Date(lastLootBoxAt).getTime() >= day) &&
    isSignedIn &&
    bossBattleCount > 0;

  return React.useMemo(() => {
    if (gameReady && isSignedIn) return gameMenu;
    const ret = [...headerMenu];

    // if (tournaments.length > 0) {
    //   const totalEth = _.sum(tournaments.map((t) => t.payoutPoolEth));
    //   const prize =
    //     ethPrice > 0
    //       ? '$' + Math.floor(totalEth * ethPrice) + ' USD'
    //       : Math.round(totalEth * 1000) / 1000 + ' ETH';
    //   ret.push({
    //     title: prize + ' Prizes',
    //     url: undefined,
    //     icon: `${cdnRoot}/images/icons/menus/trophy.png`,
    //     subMenu: tournaments.map((t) => {
    //       let icon = `${cdnRoot}/images/icons/menus/trophy.png`;
    //       if (t.realm) {
    //         const boss = getBossNameFromRealm(t.realm);
    //         icon = `${cdnRoot}/images/avatars/${boss}.png`;
    //       }
    //
    //       return {
    //         title: t.name,
    //         icon,
    //         url: '/leaderboards',
    //         external: false,
    //       };
    //     }),
    //   });
    // }

    // if (lootBoxEligible && false) {
    //   ret.push({
    //     title: 'Loot Box',
    //     icon: `${cdnRoot}/images/icons/menus/loot_box.png`,
    //     url: '/loot-box',
    //     subMenu: [],
    //   });
    // }

    return ret;
  }, [tournaments, gameReady, isSignedIn]);
}
