import { Button, LinearProgress, Theme, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IFurballIds } from './PlayTypes';
import { contractTransaction, loadFurballs } from '../../wallet';
import WalletContext from '../../wallet/WalletContext';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import WalletSlice from '../../wallet/WalletSlice';
import { useAppDispatch, useWalletSelector } from '../../hooks';

import { collect } from './helpers';
import useCommonStyles from '../../components/useCommonStyles';
import {
  IFurball,
  ITransactionError,
  SetTransactionState,
  TransactionState,
} from '../../wallet/WalletTypes';
import { ethers } from 'ethers';
import {
  ZoneSelection,
  IHaveZone,
  ISelectedFurball,
} from '../../components/Zones';
import { getZone, ZONE_NUM_VOYAGE } from '../../components/Zones/ZoneHelpers';

import { useApolloClient } from '@apollo/client';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '56px',
  },
  prompt: {},
  promptText: {
    paddingTop: theme.spacing(0.75),
    color: '#ffedad !important',
  },
  playButton: {
    margin: '7px 5px 5px 0 !important',
    width: '147px',
    height: '35px',
  },
  playBtndisabled: {
    pointerEvents: 'none',
    opacity: 0.7,
  },
  footerButton: {
    width: '100%',
  },
  [theme.breakpoints.down('lg')]: {
    prompt: {
      display: 'none',
    },
  },
  [theme.breakpoints.up('xl')]: {
    root: {
      maxWidth: '700px',
    },
  },
  // [theme.breakpoints.up('sm')]: {
  //   fullPadding: {
  //     paddingLeft: theme.spacing(10),
  //     paddingRight: theme.spacing(10),
  //   },
  // },
  // [theme.breakpoints.up('lg')]: {
  //   fullPadding: {
  //     paddingLeft: theme.spacing(20),
  //     paddingRight: theme.spacing(20),
  //   },
  // },
  // [theme.breakpoints.up('xl')]: {
  //   fullPadding: {
  //     paddingLeft: theme.spacing(40),
  //     paddingRight: theme.spacing(40),
  //   },
  // },
}));

interface IProps extends IFurballIds, IHaveZone {
  setState: SetTransactionState;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  timekeeperDisabled: boolean;
  setSelectedFurballs?: (furballs: ISelectedFurball[]) => void;
}

enum SelectionPhase {
  None,
  Snacks,
  Check,
  Zone,
}

const PlayButton: FunctionComponent<IProps> = (props) => {
  const {
    furballIds,
    className,
    setState,
    zoneNumber,
    disabled,
    isLoading,
    timekeeperDisabled,
    setSelectedFurballs,
  } = props;
  const { log } = useFurComponent(PlayButton);
  const currentZone = getZone(zoneNumber);
  const furballMap = useWalletSelector((s) => s.furballs);
  const furballs = furballIds
    .map((id) => furballMap[id] as IFurball)
    .filter((f) => f);
  const pending = useWalletSelector((s) => s.pending);
  const client = useApolloClient();
  const walletAddress = useWalletSelector((s) => s.address ?? '');
  const dispatch = useAppDispatch();
  const common = useCommonStyles();
  const context = React.useContext(WalletContext);
  const classes = useStyles();
  const [awaitingConfirmation, setAwaitingConfirmation] = React.useState<
    string[]
  >([]);
  const [isSelecting, setIsSelecting] = React.useState(SelectionPhase.None);
  const voyageIncompleteFurballs = furballs.filter((fb) =>
    fb.zone === ZONE_NUM_VOYAGE
      ? (fb.lastVoyage?.percentComplete ?? 100) < 100
      : false,
  );

  React.useEffect(() => {
    const ac = [];
    for (let i = 0; i < awaitingConfirmation.length; i++) {
      if (pending[awaitingConfirmation[i]]?.zoning !== undefined) {
        ac.push(awaitingConfirmation[i]);
      }
    }
    if (ac.length !== awaitingConfirmation.length) {
      log.info('set ac', awaitingConfirmation, ac);
      setAwaitingConfirmation(ac);
    }
  }, [awaitingConfirmation]);

  async function play(zone: number) {
    setIsSelecting(SelectionPhase.None);

    furballIds.forEach((tokenId) => {
      log.info('ZONING', tokenId);
      dispatch(WalletSlice.actions.setFurballZoning({ tokenId, zone }));
    });

    await contractTransaction(
      log,
      async (contracts) => {
        return await contracts.furballs.estimateGas.playMany(
          furballIds,
          zone,
          ethers.constants.AddressZero,
        );
        // return hasMany ?
        //   (await contracts.furballs.estimateGas.playMany(furballIds, zone, ethers.constants.AddressZero)) :
        //   (await contracts.furballs.estimateGas.playOne(furballIds[0], zone));
      },
      async (opts, contracts) => {
        return await contracts.furballs.playMany(
          furballIds,
          zone,
          ethers.constants.AddressZero,
          opts,
        );
        // return hasMany ?
        //   (await contracts.furballs.playMany(furballIds, zone, ethers.constants.AddressZero, { gasLimit })) :
        //   (await contracts.furballs.playOne(furballIds[0], zone, { gasLimit }));
      },
      async (contracts) => {
        for (let i = 0; i < furballs.length; i++) {
          const fb = furballs[i];
          await collect(fb.tokenId, walletAddress, contracts, dispatch);
        }
        await loadFurballs(client, dispatch, ...furballIds);
      },
      (s: TransactionState, e?: ITransactionError) => {
        if (setState) setState(s, e);
        if (e) {
          furballIds.forEach((tokenId) => {
            log.info('ERRED', tokenId, e);
            dispatch(
              WalletSlice.actions.setFurballZoning({
                tokenId,
                zone: undefined,
              }),
            );
          });
        }
      },
      context?.network.contracts,
    );
  }

  if (awaitingConfirmation.length > 0) {
    return (
      <React.Fragment>
        <Typography variant={'h4'} color={'secondary'}>
          {'Entering Zone'}...
        </Typography>
        <LinearProgress style={{ width: '100%' }} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {furballIds.length > 0 && (
        <Button
          variant={'contained'}
          className={clsx(classes.playButton, common.actionButton, className, {
            [classes.playBtndisabled]: !!disabled,
          })}
          color={'primary'}
          disabled={isLoading}
          onClick={() => {
            if (disabled) return;

            setIsSelecting(
              voyageIncompleteFurballs.length > 0
                ? SelectionPhase.Check
                : SelectionPhase.Zone,
            );
          }}>
          <Typography variant={'h6'}>Make Moves</Typography>
        </Button>
      )}
      <ZoneSelection
        zoneNumber={zoneNumber}
        onSelectedZone={(z: number) => void play(z)}
        furballIds={furballIds}
        open={isSelecting === SelectionPhase.Zone}
        onClose={() => setIsSelecting(SelectionPhase.None)}
        timekeeperDisabled={timekeeperDisabled}
        setSelectedFurballs={setSelectedFurballs}
      />
    </React.Fragment>
  );
};

export default PlayButton;
