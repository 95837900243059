import * as React from 'react';

function SvgIconLeaderboardRewards(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-leaderboard-rewards_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 33.9 16'
      xmlSpace='preserve'
      {...props}>
      <style>{'.icon-leaderboard-rewards_svg__st0{fill:#6c6c6c}'}</style>
      <path
        className='icon-leaderboard-rewards_svg__st0'
        d='M32.9 10.1c-.6 0-1-.5-1-1V2.3h-6.8c-.6 0-1-.5-1-1s.5-1 1-1L33 .2c.3 0 .5.1.7.3.2.2.3.4.3.7L33.9 9c0 .6-.5 1.1-1 1.1z'
      />
      <path
        className='icon-leaderboard-rewards_svg__st0'
        d='M24.5 10.6c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l7.8-7.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-7.8 7.7c-.2.2-.5.3-.7.3z'
      />
      <g>
        <path
          className='icon-leaderboard-rewards_svg__st0'
          d='M20.4 3.1c0-.5-.4-.8-.9-.8H16V1.1c0-.5-.4-.9-.9-.9H5.2c-.5 0-.9.4-.9.9v1.2H.9c-.5 0-.9.4-.9.8 0 .1-.1 2.7 1.5 4.4.9.9 2 1.4 3.5 1.4h.1c.7 1.2 1.7 2.1 3 2.6.3.1.6.5.6.9v1.5h-1c-1.3 0-2.4 1.1-2.4 2.4h9.9c0-1.3-1.1-2.4-2.4-2.4h-1v-1.5c0-.4.2-.7.6-.9 1.3-.5 2.3-1.4 3-2.6h.1c1.5 0 2.6-.5 3.5-1.4 1.5-1.6 1.4-4.3 1.4-4.4zM2.8 6.3c-.7-.6-.9-1.6-1-2.3h2.5v2c0 .4 0 .7.1 1.1-.7-.1-1.2-.3-1.6-.8zm14.8 0c-.4.4-1 .7-1.7.8.1-.3.2-.7.2-1.1V4h2.5c-.1.7-.3 1.7-1 2.3z'
        />
      </g>
    </svg>
  );
}

export default SvgIconLeaderboardRewards;
