import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SparklePink = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.13} cx={163} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={160} cy={143} r={23} />
    <linearGradient
      id='SparklePink__a'
      gradientUnits='userSpaceOnUse'
      x1={160}
      y1={167}
      x2={160}
      y2={128}>
      <stop
        offset={0.315}
        style={{
          stopColor: '#ff78c2',
        }}
      />
      <stop
        offset={0.871}
        style={{
          stopColor: '#eab0ff',
        }}
      />
    </linearGradient>
    <circle fill='url(#SparklePink__a)' cx={160} cy={148} r={19} />
    <circle cx={160} cy={153} r={12} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={160}
      cy={143}
      r={23}
    />
    <ellipse opacity={0.13} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <linearGradient
      id='SparklePink__b'
      gradientUnits='userSpaceOnUse'
      x1={232}
      y1={167}
      x2={232}
      y2={128}>
      <stop
        offset={0.315}
        style={{
          stopColor: '#ff78c2',
        }}
      />
      <stop
        offset={0.871}
        style={{
          stopColor: '#eab0ff',
        }}
      />
    </linearGradient>
    <circle fill='url(#SparklePink__b)' cx={232} cy={148} r={19} />
    <circle cx={232} cy={153} r={12} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <path
      fill='#FFF'
      d='m162 154 6-1-6-2-2-6-2 6-6 2 6 1 2 6zM234 154l5-1-5-2-2-6-2 6-6 2 6 1 2 6z'
    />
  </svg>
);

export default SparklePink;
