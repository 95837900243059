import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import OkDialog, { ICloseDialog } from '../../components/OkDialog';
import { BuyKeys, IBuyKeys, tixCostPerVoyageDay } from './BuyKeys';
import AlertProvider from '../../components/Alert/AlertProvider';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IBuyKeysDialog extends IBuyKeys, ICloseDialog {}

export const BuyKeysDialog: FunctionComponent<IBuyKeysDialog> = (props) => {
  const { open, onClose, furballs, realms } = props;
  const classes = useStyles();

  return (
    <OkDialog
      open={open}
      onClose={onClose}
      className={clsx(classes.root)}
      title={`Buy Boss Keys (${tixCostPerVoyageDay * 21} TIX e.a.)`}>
      <AlertProvider>
        <BuyKeys furballs={furballs} realms={realms} />
      </AlertProvider>
    </OkDialog>
  );
};

export default BuyKeysDialog;
