import React, { FunctionComponent, useCallback, useState } from 'react';
import { CircularProgress, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { FormationPosition } from '../../../components/schema';
import { IFurballPartyMember, useBossBattle } from '../useBossBattle';
import {
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_SCHOLARSHIPS,
} from '../../../components/Zones/ZoneHelpers';
import { FormationFurball } from './FormationFurball';
import UpgradeDialog from '../../../components/Town/Upgrade/UpgradeDialog';
import { placeholderFurballId } from '../../../wallet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    padding: '5rem',

    [theme.breakpoints.down('sm')]: {
      padding: '0',
      margin: (props: any) => (props.isNew ? '2rem 0 3rem' : '5rem 0 3rem'),
    },
  },

  loader: {
    position: 'absolute',
    inset: 0,
    display: 'grid',
    placeItems: 'center',
  },

  furballsGrid: {
    width: '100%',
    display: 'grid',
    gap: '0rem',
    rowGap: '1.4rem',
    gridTemplateAreas: `
      "${FormationPosition.TopBack} a ${FormationPosition.TopFront}" 
      "b ${FormationPosition.Center} c" 
      "${FormationPosition.BottomBack} d ${FormationPosition.BottomFront}"`,
    gridTemplateColumns: 'repeat(3,  1fr)',
    gridTemplateRows: 'repeat(3,  1fr)',
    height: '90%',

    [theme.breakpoints.down('sm')]: {
      height: '15rem',
      marginTop: '2rem',
    },
  },
}));

export interface IPartyGridItem extends IFurballPartyMember {
  empty?: boolean;
}

export const FormationGrid: FunctionComponent = () => {
  const {
    isNew,
    partyMembers,
    toSwap,
    isSwapping,
    allParties,
    currentFormation,
  } = useBossBattle();
  const classes = useStyles({ isNew });

  const [showStatsTokenId, setShowStatsTokenId] = useState<
    string | undefined
  >();

  const getUnfilledPositions = useCallback(() => {
    if (!allParties || !currentFormation) {
      return [];
    }

    const filled = partyMembers.map((item) => item.position);
    const unfilled = currentFormation?.filter((item) => !filled.includes(item));

    return unfilled?.map(
      (item) =>
        ({
          id: placeholderFurballId,
          position: item,
          empty: true,
        } as IPartyGridItem),
    );
  }, [partyMembers, allParties, currentFormation]);

  const furballs: IPartyGridItem[] = [
    ...getUnfilledPositions(),
    ...partyMembers,
  ];

  return (
    <div className={classes.root}>
      {!allParties && (
        <div className={classes.loader}>
          <CircularProgress size={45} />
        </div>
      )}

      <div className={classes.furballsGrid}>
        {furballs.map((furball, index) => {
          const isSelected = toSwap?.id === furball.id && !isSwapping;
          const isHidden = !currentFormation.includes(furball.position);

          const warning =
            !furball.empty &&
            furball.zone !== undefined &&
            furball.zone !== ZONE_NUM_SCHOLARSHIPS &&
            furball.zone !== ZONE_NUM_BOSS_FIGHTS;

          if (isHidden) {
            return null;
          }

          return (
            <FormationFurball
              key={index}
              index={index}
              furball={furball}
              warning={warning}
              isSelected={isSelected}
              setStatsTokenId={setShowStatsTokenId}
            />
          );
        })}
      </div>
      <UpgradeDialog
        show={!!showStatsTokenId}
        tokenId={showStatsTokenId}
        onClose={() => setShowStatsTokenId(undefined)}
        onAction={() => setShowStatsTokenId(undefined)}
        setTokenId={setShowStatsTokenId}
        tokenIds={furballs.filter(({ empty }) => !empty).map(({ id }) => id)}
      />
    </div>
  );
};

export default FormationGrid;
