import React, { FunctionComponent } from 'react';
import {
  Theme,
  Container,
  LinearProgress,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurball } from '../../wallet/WalletTypes';
import { usePlayersReadyFurballs } from '../../wallet';
import { TimekeeperInteractionType, useShopWaresQuery } from '../schema';
import {
  getAllTimekeeperInteractions,
  useCurrentTimekeeperRequest,
} from '../Timekeeper/TimekeeperHooks';
import { purple } from '../../theme';
import { IShopWares } from '../Timekeeper/TimekeeperSlice';
import FurballImg from '../Furballs/FurballImg';
import { getLootItem, getLootSlot, LootSlot } from '../Loot';
import { hasWaresPurchase } from './ShopHelpers';
import TimekeeperMarketButton from '../Timekeeper/TimekeeperMarketButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  tabs: {
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    maxHeight: 40,
    backgroundColor: 'white',
  },
  tab: {
    maxHeight: 40,
    border: 'none !important',
    '&.Mui-selected': {
      color: `${purple.dark} !important`,
    },
  },
  marketButton: {
    width: '100%',
  },
}));

interface IShopWaresMulti {
  onChanged: () => void;
}

export const ShopWaresAll: FunctionComponent<IShopWaresMulti> = (props) => {
  const classes = useStyles();
  const { onChanged } = props;
  const { data, loading } = useShopWaresQuery();
  const [tab, setTab] = React.useState(1);
  const tkState = useCurrentTimekeeperRequest();
  const furballs = usePlayersReadyFurballs();
  const history = useHistory();
  const shopWares = data?.shopWares ?? [];
  const interactions = getAllTimekeeperInteractions(tkState.current);

  function onSaved(fb: IFurball, wares: IShopWares) {
    onChanged();
  }

  function renderFurballWares(fb: IFurball): React.ReactNode {
    const furballIds = [fb.tokenId];

    return (
      <React.Fragment key={fb.tokenId}>
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <FurballImg tokenId={fb.tokenId} size={60} />
        </Grid>
        {shopWares.map((wares) => {
          const pendingPurchase = interactions.find(
            (i) =>
              i.interactionType === TimekeeperInteractionType.ShopPurchase &&
              i.expectedValue === wares.id &&
              i.furballIds.includes(fb.tokenId),
          );
          const fuelCost = pendingPurchase ? wares.fuelCostN : wares.fuelCost1;
          const purchased = hasWaresPurchase(
            furballs,
            interactions,
            fb.tokenId,
            wares,
          );
          return (
            <Grid key={wares.id} item xs={5} style={{ textAlign: 'center' }}>
              {!purchased && (
                <TimekeeperMarketButton
                  title={`Claim ${wares.name}`}
                  className={clsx(classes.marketButton)}
                  marketRequest={{
                    interactionType: TimekeeperInteractionType.ShopPurchase,
                    furballIds,
                    expectedValue: wares.id,
                  }}
                  stayOnPage={true}
                  onSaved={() => onSaved(fb, wares)}
                  furballIds={furballIds}
                  fuelCost={fuelCost}
                  // onClick={() => onSelectedZone(otherZone.zoneNumber)}
                />
              )}
              {purchased && (
                <React.Fragment>
                  <Typography variant={'body1'} style={{ marginTop: 10 }}>
                    {`${fb.name} ${
                      pendingPurchase ? 'will claim' : 'already claimed'
                    } ${wares.name}`}
                  </Typography>
                </React.Fragment>
              )}
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Container className={clsx(classes.root)}>
      <Grid container spacing={2}>
        {shopWares.map((w) => {
          const loots = w.itemIds.map((id) => getLootItem(id)) ?? [];
          const slots = loots.map((l) => getLootSlot(l));
          const desc = loots[0]?.description ?? '';

          return (
            <React.Fragment key={w.id}>
              <Grid item xs={1} style={{ textAlign: 'center', paddingTop: 24 }}>
                {slots.map((s) => (
                  <div key={s.item.lootId} style={{ display: 'inline-block' }}>
                    <LootSlot size={'sm'} {...s} />
                  </div>
                ))}
              </Grid>
              <Grid item xs={11} style={{ textAlign: 'left' }}>
                <Typography variant={'h5'}>{w.name}</Typography>
                <Typography variant={'body1'}>{desc}</Typography>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12}>
          <hr />
        </Grid>

        {furballs.map((fb) => renderFurballWares(fb))}
      </Grid>
      {loading && (
        <LinearProgress style={{ width: '100%' }} color={'secondary'} />
      )}
    </Container>
  );
};

export default ShopWaresAll;
