import React from 'react';
import { getApiRoot } from './FurballClient';
import { inIframe } from './wallet/Wallet';
import { getGameWrap } from './pages/App/vuplexBridge';
import { defaultLogManager } from './utils';

export type CheckoutEvent = 'add_to_cart' | 'begin_checkout' | 'purchase';

export interface IAnalyticsProps {
  playerId?: string;
  playerType?: string;
  numFurballs?: number;
  numRented?: number;
  bossBattleCount?: number;
  fuelSpent?: number;
}

let analyticsBegan: string | undefined | null = undefined;
let pageViews = 0;
export function updateAnalytics(props: IAnalyticsProps): void {
  const isFirstLoad = analyticsBegan === undefined && pageViews === 0;
  const gaId = 'G-HXXQVK76GN';
  const log = defaultLogManager.getLogger('Analytics');

  const num_furballs = props.numFurballs ?? 0;
  const num_rented = props.numRented ?? 0;
  const num_playable_furballs = num_rented + num_furballs;
  const has_web3 = !inIframe() && !!window.ethereum;
  const num_boss_fights = props.bossBattleCount ?? 0;
  const has_fought_boss = num_boss_fights > 0;
  const fuel_spent = props.fuelSpent ?? 0;
  const spent_fuel = fuel_spent > 0;
  const api_root = getApiRoot();
  const payload = {
    api_root, // Broad environment bucket mapping
    has_web3, // Is a web3 plugin enabled?
    wrap: getGameWrap().toString(),
    user_id: props.playerId, // either scholar OR wallet ID
    wallet: props.playerId?.startsWith('0x') ?? false ? props.playerId : '', // empty string = scholar
    player_type: props.playerType ?? 'None',
    num_furballs,
    has_furballs: num_furballs > 0,
    num_rented,
    has_rented: num_rented > 0,
    can_play_bosses: num_playable_furballs >= 3, // player is eligible for bosses
    has_fought_boss,
    num_playable_furballs,
    spent_fuel,
    send_page_view: false, // manual mode only
  };

  // @ts-ignore
  window.gtag('config', gaId, { ...payload });
  // @ts-ignore
  window.gtag('set', 'user_properties', { ...payload });

  const title = pathTitles[window.location.pathname] ?? 'Startup | Furballs';
  analyticsBegan = props.playerId ?? null;

  if (isFirstLoad) {
    setTimeout(() => {
      if (pageViews > 0) return;
      log.info(
        '[ANALYTICS] firing pageView because none exists',
        window.location.pathname,
        title,
      );
      firePageView(window.location.pathname, title);
    }, 2000);
  }

  log.info(
    '[ANALYTICS] began as',
    props.playerId,
    'at',
    window.location.pathname,
  );
}

export function ensureAnalytics() {
  if (analyticsBegan !== undefined) return;
  const log = defaultLogManager.getLogger('Analytics');
  log.warn('[ANALYTICS] backup begin');
  updateAnalytics({});
}

const pathTitles: { [key: string]: string } = {};

function firePageView(path: string, title: string): void {
  pathTitles[path] = title;
  const log = defaultLogManager.getLogger('Analytics');
  if (analyticsBegan === undefined) {
    log.warn('[ANALYTICS] not configured', path, title);
    return;
  }
  if (pageViews <= 0)
    log.info('[ANALYTICS] first page', title ?? document.title, 'on', path);
  pageViews++;
  fireEvent('page_view', {
    page_title: title ?? 'Unknown | Furballs',
    page_location: path,
  });
}

export function usePageTitle(pageTitle?: string): void {
  React.useEffect(() => {
    if (!pageTitle) return;
    firePageView(window.location.pathname, pageTitle);
    document.title = pageTitle;
  }, [pageTitle, analyticsBegan]);
}

export interface IEventItem {
  item_name: string;
  price?: number;
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#post_score
export interface IEventData {
  event_category?: string;
  event_label?: string;
  value?: number;
  method?: string;
  page_title?: string;
  page_location?: string;
  item_name?: string;
  virtual_currency_name?: string;
  score?: number;
  level?: number;
  character?: string;
  currency?: string;
  name?: string;
  items?: IEventItem[];
  experiment_id?: string;
  variant_id?: string;
  callback?: (s: string) => void;
}

export function fireEvent(
  action: string,
  // category?: string,
  data?: IEventData,
): void {
  // @ts-ignore
  window.gtag('event', action, data);
}

export function fireLoginBeginEvent(method: string, event_label: string): void {
  fireEvent('login_begin', {
    method,
    event_label,
  });
}

export function fireLoginEvent(method: string): void {
  fireEvent('login', {
    method,
    // event_label,
  });
  fireTwitterPixelEvent('tw-ocubt-ocubv');
  fireTikTokPixelEvent('Login'); // ?
  fireMetaPixelEvent('Login');
}

export function fireSignUpEvent(method: string): void {
  fireEvent('sign_up', {
    method,
    // event_label,
  });
  fireTwitterPixelEvent('tw-ocubt-odoty'); // SignUp
  fireTikTokPixelEvent('Complete Registration');
  fireMetaPixelEvent('Complete Registration');
}

function fireTwitterPixelEvent(id: string) {
  // @ts-ignore
  window.twq('event', id, {});
}

function fireTikTokPixelEvent(id: string, params?: unknown) {
  // @ts-ignore
  window.ttq.track(id, params ?? {});
}

function fireMetaPixelEvent(id: string, params?: unknown) {
  // @ts-ignore
  window.fbq('track', id, params ?? {});
}

export function fireSpendWFurEvent(value: number, item_name: string) {
  fireEvent('spend_virtual_currency', {
    value,
    item_name,
    virtual_currency_name: 'WFUR',
  });
}

export function fireSpendTixEvent(
  tix: number,
  ethPrice: number,
  item_name: string,
  event_name: CheckoutEvent = 'purchase',
) {
  const value = Math.round((tix / 10) * (ethPrice ? ethPrice : 1500)) / 100;
  fireEvent(event_name, {
    value,
    currency: 'USD',
    items: [{ item_name, price: value }],
  });
  fireTwitterPixelEvent('tw-ocubt-odotq'); // SpendTIX
  fireTikTokPixelEvent('Place an Order', {
    price: value,
    value: value,
    content_type: 'product',
    content_id: item_name,
    content_name: item_name,
    currency: 'USD',
  });
  fireMetaPixelEvent('Purchase', {
    value: value,
    currency: 'USD',
    contents: [{ id: item_name, quantity: 1 }],
  });
}

export function firePostScoreEvent(
  score: number,
  character?: string,
  level?: number,
) {
  fireEvent('post_score', { score, character, level });
  fireTwitterPixelEvent('tw-ocubt-odot8'); // QuickPlay
  fireTikTokPixelEvent('Subscribe'); // ?
  fireMetaPixelEvent('Subscribe');
}

export function fireShareEvent(platform: string) {
  fireEvent('share', { method: platform });
}

// export type HomepageCopyExperimentVariant =
//   | 'Original'
//   | 'PlayFree'
//   | 'FightStrategy';

// export function fireHomepageExperimentImpression(variant_id: HomepageCopyExperimentVariant): void {
//   fireEvent('experiment_impression', {
//     experiment_id: 'iD2cS6n3Q7WGShxuaCfLCw',
//     variant_id: `iD2cS6n3Q7WGShxuaCfLCw.${variant_id}`,
//   });
// }

// export function useExperiment(experimentId: string): number {
//   // @ts-ignore
//   const existing = Number.parseInt(window.google_optimize?.get(experimentId));
//   const [treatmentGroup, setTreatmentGroup] = React.useState<number>(
//     existing ?? 0,
//   );
//
//   React.useEffect(() => {
//     fireEvent('optimize.callback', {
//       name: experimentId,
//       callback: (value) => {
//         const idx = Number.parseInt(value);
//         setTreatmentGroup(idx);
//       },
//     });
//   }, [experimentId]);
//
//   return existing ?? treatmentGroup;
// }

// export function fireSpendTutorialBeginEvent() {
//   fireEvent('tutorial_begin');
// }
//
// export function fireSpendTutorialCompleteEvent() {
//   fireEvent('tutorial_complete');
// }
