import * as React from 'react';

function SvgLootFurspray(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <path
        fill='#BCF0F2'
        d='M114.6 38.4H85.4C76.2 43.5 70 53.3 70 64.6v74c0 4.1 3.3 7.5 7.5 7.5h45.1c4.1 0 7.5-3.3 7.5-7.5v-74c-.1-11.3-6.3-21.1-15.5-26.2z'
      />
      <path
        fill='#7CE3E4'
        d='M130 138.6v-74c0-11.3-6.2-21.1-15.4-26.2h-12.9c9.2 5.1 15.4 14.9 15.4 26.2v74c0 4.1-3.3 7.5-7.5 7.5h12.9c4.2 0 7.5-3.4 7.5-7.5z'
      />
      <path
        fill='#D4F1F2'
        d='M83.8 138.6v-74c0-11.3 6.2-21.1 15.4-26.2H85.4C76.2 43.5 70 53.3 70 64.6v74c0 4.1 3.3 7.5 7.5 7.5h13.8c-4.2 0-7.5-3.4-7.5-7.5z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M114.6 38.4H85.4C76.2 43.5 70 53.3 70 64.6v74c0 4.1 3.3 7.5 7.5 7.5h45.1c4.1 0 7.5-3.3 7.5-7.5v-74c-.1-11.3-6.3-21.1-15.5-26.2z'
      />
      <path
        fill='#BCF0F2'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M108.7 25.9H91.3v-18c0-3.6 2.9-6.4 6.4-6.4h4.5c3.6 0 6.4 2.9 6.4 6.4v18z'
      />
      <path
        fill='#FFF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M109.2 21.7H90.8c-2.6 0-4.6 2.1-4.6 4.6v11.6h27.6V26.3c0-2.5-2.1-4.6-4.6-4.6z'
      />
      <path d='M100 15.4c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5 3.5 1.6 3.5 3.5-1.6 3.5-3.5 3.5z' />
      <circle
        fill='#FFF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        cx={100}
        cy={89.8}
        r={18.2}
      />
    </svg>
  );
}

export default SvgLootFurspray;
