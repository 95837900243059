import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Llama = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M234.25 115.09s6.02-18.6 1.55-46.77c-.29-1.84.81-2.94 2.3-1.82 5.81 4.38 18.89 16.66 22.3 32.96 3.43 16.42.55 31.08-6.22 39.55-3.62 4.52-10.69 2.89-12.58-2.59l-7.35-21.33z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M235.98 115.33s4.31-28.56-1.36-46.94c-.55-1.78 2-3 3.49-1.88 5.81 4.38 18.45 16.66 21.86 32.96 3.94 18.85 1.04 34.91-8.06 42.57'
    />
    <path
      fill='#fbc6a3'
      d='M242.07 121.85c-.17-.35-.22-.74-.14-1.12.67-3.16 3.69-18.61 1.63-32.18-.18-1.18.2-2.59 1.22-1.96 3.95 2.46 7.51 7.23 9.57 17.7 1.76 8.97-.44 22.06-4.53 27.57-.76 1.02-2.34.87-2.9-.27l-4.85-9.74z'
    />
    <path
      fill='#fbaaa3'
      d='M254.35 104.29c-.99-5.05-2.33-8.77-3.9-11.55.74 2.04 1.39 4.43 1.94 7.21 1.76 8.97-.44 22.06-4.53 27.57-.76 1.02-2.34.87-2.9-.27l1.96 4.34c.57 1.14 2.15 1.29 2.9.27 4.08-5.51 6.29-18.6 4.53-27.57z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M241.93 120.69c.67-3.29 3.69-19.38 1.63-33.51'
    />
    <path
      fill={props.renderer.color1}
      d='M158.47 115.09s-5.69-19.31-1.22-47.47c.29-1.84-2.12-1.47-3.61-.35-5.81 4.38-17.91 15.9-21.32 32.2-3.43 16.42-.67 30.72 6.1 39.19 3.62 4.52 10.81 3.25 12.7-2.23l7.35-21.34z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M156.74 115.21s-4.31-28.44 1.36-46.82c.55-1.78-2-3-3.49-1.88-5.81 4.38-18.45 16.66-21.86 32.96-3.94 18.85-1.04 34.91 8.06 42.57'
    />
    <path
      fill='#fbc6a3'
      d='M150.65 121.85c.17-.35.22-.74.14-1.12-.67-3.16-3.69-18.61-1.63-32.18.18-1.18-.2-2.59-1.22-1.96-3.95 2.46-7.51 7.23-9.57 17.7-1.76 8.97.44 22.06 4.53 27.57.76 1.02 2.34.87 2.9-.27l4.85-9.74z'
    />
    <path
      fill='#fbaaa3'
      d='M138.37 104.29c.99-5.05 2.33-8.77 3.9-11.55-.74 2.04-1.39 4.43-1.94 7.21-1.76 8.97.44 22.06 4.53 27.57.76 1.02 2.34.87 2.9-.27l-1.96 4.34c-.57 1.14-2.15 1.29-2.9.27-4.09-5.51-6.29-18.6-4.53-27.57z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M150.79 120.69c-.67-3.29-3.69-19.38-1.63-33.51'
    />
  </svg>
);

export default Llama;
