import { SvgEditionSlot } from '../part';
import * as React from 'react';

const WhimsyWand = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <g opacity={0.3}>
      <path
        fill='#ff93ff'
        d='M294.67 57.37s-73.95-50.61-132.5 15.15c-54.99 61.76-89.05 55.82-89.05 55.82s61.78 16.16 134.83-17.18c47.57-21.72 87.17 6.6 87.17 6.6'
      />
      <path
        fill='#ffff5a'
        d='M300.88 89.2s-69.84-59.76-115.2-13.68c-58.01 58.93-112.56 52.82-112.56 52.82s61.78 16.16 134.83-17.18c47.57-21.72 87.17 6.6 87.17 6.6'
      />
      <path
        fill='#3fff73'
        d='M297.4 103.36s-53.76-53.76-114.96-5.88c-65.13 50.95-109.32 30.86-109.32 30.86s61.78 16.16 134.83-17.18c47.57-21.72 87.17 6.6 87.17 6.6'
      />
    </g>
    <path
      fill='#eb9bf6'
      d='m301.47 45.42 4.64 16.53c1.07 3.81 3.74 6.96 7.32 8.64l15.54 7.3c2.55 1.2 2.82 4.73.47 6.29l-14.29 9.52a13.639 13.639 0 0 0-5.96 9.63l-2.14 17.04c-.35 2.8-3.63 4.14-5.84 2.39l-13.47-10.65c-3.1-2.45-7.11-3.43-11-2.69l-16.86 3.23c-2.77.53-5.06-2.17-4.08-4.82l5.97-16.1c1.37-3.71 1.07-7.83-.84-11.29l-8.28-15.04c-1.36-2.47.5-5.48 3.32-5.37l17.15.7a13.6 13.6 0 0 0 10.48-4.29l11.74-12.53c1.93-2.05 5.37-1.21 6.13 1.51z'
    />
    <linearGradient
      id='WhimsyWand__a'
      gradientUnits='userSpaceOnUse'
      x1={232}
      y1={215}
      x2={310}
      y2={215}
      gradientTransform='rotate(13.736 841.934 223.9)'>
      <stop
        offset={0}
        style={{
          stopColor: '#b34aef',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#de6ff2',
        }}
      />
    </linearGradient>
    <path
      fill='url(#WhimsyWand__a)'
      d='M308.63 68.82c.69.43 20.02 10.07 20.02 10.07 2.44 1.14 1.15 3.96-1.1 5.46 0 0-18.35 10.93-18.02 13.94l-3.35 20.6c-.34 2.67-3.46 3.95-5.57 2.28L287.1 110.4c-.71-1.09-7.83-2.83-8.96-2.19l-16.96 3.31c-2.64.51-4.32 1.33-3.39-1.19l8.28-15.53c1.92-2.65 2.73-5.97 2.08-9.18-.01-.04-.01-.07-.02-.11-.38-1.79-1.22-3.45-2.37-4.88L254.1 62.66s26.94 3.58 28.95 2.91c1.67-.56 15.61-21.84 15.61-21.84 1.84-1.98 7.66 23.67 9.97 25.09z'
    />
    <path
      fill='#b34aef'
      d='m294.29 118.71.83-3.4c1.75-7.16-2.63-14.38-9.79-16.13-7.16-1.75-14.38 2.63-16.13 9.79l-.76 3.1 5.79-1.11c5.24-1.01 10.67.32 14.86 3.63l5.2 4.12zM328.96 77.88l-15.54-7.3a13.602 13.602 0 0 1-7.32-8.64l-4.64-16.53c-.76-2.72-3.08-2.64-2.81-1.7l1.42 20.42c.37 4.59 2.68 8.7 6.25 10.38l23.55 6.65-20.79 9.92c-3.25 1.73-5.85 2.01-5.77 5.34l1.08 26.98c.86.16 2.3-.25 2.65-3.05l2.14-17.04c.49-3.92 2.67-7.44 5.96-9.63l14.29-9.52c2.35-1.55 2.09-5.08-.47-6.28z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m301.47 45.42 4.64 16.53c1.07 3.81 3.74 6.96 7.32 8.64l15.54 7.3c2.55 1.2 2.82 4.73.47 6.29l-14.29 9.52a13.639 13.639 0 0 0-5.96 9.63l-2.14 17.04c-.35 2.8-3.63 4.14-5.84 2.39l-13.47-10.65c-3.1-2.45-7.11-3.43-11-2.69l-16.86 3.23c-2.77.53-5.06-2.17-4.08-4.82l5.97-16.1c1.37-3.71 1.07-7.83-.84-11.29l-8.28-15.04c-1.36-2.47.5-5.48 3.32-5.37l17.15.7a13.6 13.6 0 0 0 10.48-4.29l11.74-12.53c1.93-2.05 5.37-1.21 6.13 1.51z'
    />
    <path
      fill='#db95f1'
      d='M245.89 259.11c-5.59-1.37-9.01-7.01-7.64-12.59l32.54-132.49c1.37-5.59 7.01-9.01 12.59-7.64 5.59 1.37 9.01 7.01 7.64 12.59l-32.54 132.49c-1.36 5.58-7 9.01-12.59 7.64z'
    />
    <path
      fill='#e9cff1'
      d='m244.83 248.12 32.54-132.49c1.08-4.44 4.86-7.5 9.17-7.89-.95-.61-2-1.08-3.15-1.36-5.59-1.37-11.23 2.06-12.59 7.64l-32.54 132.49c-1.37 5.59 2.06 11.23 7.64 12.59 1.15.28 2.31.35 3.43.25-3.65-2.33-5.59-6.79-4.5-11.23z'
    />
    <path
      fill='#d374f1'
      d='M283.39 106.38c-1.25-.31-2.5-.36-3.7-.22 3.49 2.38 5.32 6.74 4.26 11.08l-32.54 132.49c-1.06 4.34-4.7 7.36-8.89 7.86 1 .68 2.13 1.21 3.38 1.52 5.59 1.37 11.23-2.06 12.59-7.64l32.54-132.49c1.37-5.59-2.05-11.23-7.64-12.6z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M245.89 259.11c-5.59-1.37-9.01-7.01-7.64-12.59l32.54-132.49c1.37-5.59 7.01-9.01 12.59-7.64 5.59 1.37 9.01 7.01 7.64 12.59l-32.99 132.37c-1.35 5.58-6.55 9.13-12.14 7.76z'
    />
    <path
      opacity={0.41}
      fill='#e3b5f1'
      d='M256.9 254.97a10.408 10.408 0 0 1-11 4.13c-4.28-1.05-7.29-4.6-7.85-8.71-7.98 1.76-14.67 7.88-16.74 16.35-2.9 11.86 4.36 23.83 16.23 26.73 11.86 2.9 23.83-4.36 26.73-16.23 2.06-8.49-1.07-17.03-7.37-22.27z'
    />
    <path fill='#81d2ff' d='m244 264-1 6 3 3 6-2z' />
    <path fill='#ae66fc' d='m245 277 5 3 2-9-6 2z' />
    <path fill='#81d2ff' d='m245 277-5 1-2 8 12-6z' />
    <path fill='#aafbff' d='m240 278-2-3h-6l6 11z' />
    <path fill='#b2f6ff' d='m238 272 5-2 1-6-10 3z' />
    <path fill='#FFF' d='m243 270-5 2-4-5-2 8h6l2 3 5-1 1-4z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m250 280 2-9-8-7-10 3-2 8 6 11z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M256.9 254.97a10.408 10.408 0 0 1-11 4.13c-4.28-1.05-7.29-4.6-7.85-8.71-7.98 1.76-14.67 7.88-16.74 16.35-2.9 11.86 4.36 23.83 16.23 26.73 11.86 2.9 23.83-4.36 26.73-16.23 2.06-8.49-1.07-17.03-7.37-22.27z'
    />
  </svg>
);

export default WhimsyWand;
