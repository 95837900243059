import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Bear = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M141.76 135.52c-11 0-19.92-8.92-19.92-19.92s8.92-19.92 19.92-19.92c9.31 0 17.13 6.39 19.31 15.02l1.09 3.7-15.6 22.8-4.8-1.68z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M142.93 135.52c-11 0-21.09-8.92-21.09-19.92s8.92-19.92 19.92-19.92c9.31 0 17.13 6.39 19.31 15.02'
    />
    <circle fill='#ffafab' cx={142} cy={116} r={11} />
    <path
      fill='#e87e79'
      d='M142.93 105.47c-4.18 0-7.82 2.32-9.69 5.75 1.86-1.43 4.19-2.28 6.72-2.28 6.1 0 11.05 4.95 11.05 11.05 0 1.92-.49 3.72-1.35 5.3 2.63-2.02 4.32-5.19 4.32-8.76 0-6.11-4.95-11.06-11.05-11.06z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M134.72 109.14c2.02-2.25 4.95-3.66 8.22-3.66 6.1 0 12.04 4.16 11.05 13.47'
    />
    <path
      fill={props.renderer.color1}
      d='M250.43 135.64c11 0 19.92-8.92 19.92-19.92s-8.92-19.92-19.92-19.92c-9.31 0-17.13 6.39-19.31 15.02l-1.09 3.7 15.6 22.8 4.8-1.68z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M250.43 135.64c11 0 19.92-8.92 19.92-19.92s-8.92-19.92-19.92-19.92c-9.31 0-17.13 6.39-19.31 15.02'
    />
    <circle fill='#ffafab' cx={249} cy={116} r={11} />
    <path
      fill='#e87e79'
      d='M249.25 105.59c4.18 0 7.82 2.32 9.69 5.75a10.986 10.986 0 0 0-6.72-2.28c-6.1 0-11.05 4.95-11.05 11.05 0 1.92.49 3.72 1.35 5.3a11.014 11.014 0 0 1-4.32-8.76c.01-6.11 4.95-11.06 11.05-11.06z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M257.47 109.26a11.003 11.003 0 0 0-8.22-3.66c-6.1 0-12.04 4.16-11.05 13.47'
    />
  </svg>
);

export default Bear;
