import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, CircularProgress, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';
import SvgIconTicket from '../../assets/SvgIconTicket';

import {
  TimekeeperToggleArgsInput,
  useTimekeeperToggleAcceptMutation,
  useTimekeeperToggleComputeMutation,
} from '../schema';
import { useWalletSelector } from '../../hooks';
import { getTicketDisplay } from './TimekeeperReq';
import { signTimekeeperRequest } from './FurgreementSig';
import WalletContext from '../../wallet/WalletContext';
import TransactionLink from '../Blockchain/TransactionLink';
import { useCurrentTimekeeperRequest, useFuelFactor } from './TimekeeperHooks';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IUnknown {}

type Props = {
  furballIds: string[];
  timekeeperDisabled: boolean;
  onComplete?: () => void;
};

export const TimekeeperToggleButton: FunctionComponent<Props> = (props) => {
  const { timekeeperDisabled, furballIds, onComplete } = props;
  const { log } = useFurComponent(TimekeeperToggleButton);
  const classes = useStyles();
  const common = useCommonStyles();
  const context = React.useContext(WalletContext);
  const sender = useWalletSelector((s) => s.address) ?? '';
  const tickets = useWalletSelector((s) => s.contents?.tix ?? 0);
  const tkState = useCurrentTimekeeperRequest();
  const fuelCost = useFuelFactor();
  const [compute, computed] = useTimekeeperToggleComputeMutation();
  const [accept, accepted] = useTimekeeperToggleAcceptMutation();
  const [stale, setStale] = React.useState(false);
  const [trigger, setTrigger] = React.useState(false);
  const [deadline, setDeadline] = React.useState(0);
  const furballList = furballIds.join(',');
  const toggleArgs: TimekeeperToggleArgsInput = {
    deadline,
    furballList,
    sender,
    timekeeperDisabled,
  };
  const toggleData = computed.data?.timekeeperToggleCompute;
  const isLoading = computed.loading || accepted.loading;
  const transaction = accepted.data?.timekeeperToggleAccept.transaction;
  const canBuy = (toggleData?.fuelCost ?? 1) / fuelCost <= tickets;
  const isReady = deadline > 0;

  React.useEffect(() => {
    if (toggleArgs.furballList.length <= 0) return;
    toggleArgs.deadline = Math.round(new Date().getTime() / 1000) + 3600 * 72;
    setDeadline(toggleArgs.deadline);
    setTrigger(false);
    async function doCompute() {
      try {
        const res = await compute({ variables: { toggleArgs } });
      } catch (e) {
        log.error(e, 'TK args', toggleArgs);
      }
    }
    void doCompute();
    const to = setTimeout(() => {
      setStale(true);
    }, 60000 * 10);

    return () => {
      clearTimeout(to);
    };
  }, [furballList, sender, timekeeperDisabled]);

  async function run() {
    if (!toggleData || !context) return;
    const signature = '';
    try {
      const [req, signature] = await signTimekeeperRequest(
        {
          fuelCost: toggleData.fuelCost,
          gained: 0,
          spent: 0,
          mintEdition: 0,
          mintCount: 0,
          movementList: '',
          collectStayList: '',
          deadline,
        },
        sender,
        context,
      );

      const accpt = await accept({
        variables: { id: toggleData.id, signature },
      });
      if (onComplete) onComplete();
    } catch (e) {
      log.error(e);
    }
  }

  function onClick() {
    if (stale) {
      setTrigger(true);
      return;
    }
    void run();
  }

  return (
    <React.Fragment>
      {isLoading && <CircularProgress color={'secondary'} />}
      {transaction && (
        <React.Fragment>
          <TransactionLink transactionHash={transaction.hash} />
          <Typography variant={'subtitle2'}>
            You may need to wait some time and refresh this page after the
            transaction completes.
          </Typography>
        </React.Fragment>
      )}
      {!isLoading && toggleData && !transaction && (
        <Button
          variant={'contained'}
          className={clsx(classes.root, common.actionSecondary)}
          color={'secondary'}
          onClick={onClick}
          disabled={!stale && !canBuy}>
          {stale && <Typography variant={'h5'}>Refresh Prices</Typography>}
          {!canBuy && !stale && (
            <Typography variant={'h5'}>
              {`Requires ${getTicketDisplay(
                toggleData.fuelCost,
                tkState.round?.costs,
              )} TIX!`}
            </Typography>
          )}
          {canBuy && !stale && (
            <Typography variant={'h5'}>
              {getTicketDisplay(toggleData.fuelCost, tkState.round?.costs)} TIX
              <SvgIconTicket
                style={{
                  width: 22,
                  marginRight: 4,
                  marginLeft: 8,
                  position: 'relative',
                  top: 4,
                }}
              />{' '}
              {timekeeperDisabled ? 'Disable' : 'Enable'} {furballIds.length}
            </Typography>
          )}
        </Button>
      )}
      <React.Fragment>
        {computed.error && (
          <Typography variant={'subtitle2'}>
            {computed.error?.message}
          </Typography>
        )}
        {accepted.error && (
          <Typography variant={'subtitle2'}>
            {accepted.error?.message}
          </Typography>
        )}
      </React.Fragment>
    </React.Fragment>
  );
};

export default TimekeeperToggleButton;
