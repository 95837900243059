import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, LinearProgress, Theme, Typography } from '@mui/material';
import clsx from 'clsx';
import TimekeeperSlice, { ITimekeeperCosts } from './TimekeeperSlice';
import {
  TimekeeperInteractionAction,
  TimekeeperInteractionArgInput,
  TimekeeperInteractionType,
  useTimekeeperSaveActionMutation,
} from '../schema';
import { useCurrentTimekeeperRequest } from './TimekeeperHooks';
// import GasSavingsEstimator from './GasSavingsEstimator';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import SvgIconTicket from '../../assets/SvgIconTicket';
import { usePlayersReadyFurballs } from '../../wallet';
import { IFurball } from '../../wallet/WalletTypes';
import {
  getZone,
  ZONE_NUM_EXPLORE,
  ZONE_NUM_VOYAGE,
} from '../Zones/ZoneHelpers';
import { StatAbbreviation } from '../Stats';
import useCommonStyles from '../useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  gasSavings: {
    paddingLeft: 20,
  },
}));

interface IUnknown {}

// Becomes an InteractionArgInput...
export interface ITimekeeperMarketRequest {
  interactionType: TimekeeperInteractionType;
  action?: TimekeeperInteractionAction;
  furballIds?: string[];
  expectedValue?: number;
  count?: number;
}

type Props = {
  title: string;
  marketRequest: ITimekeeperMarketRequest;
  furballIds: string[];
  className?: string;
  instant?: boolean;
  onSaved: () => void;
  overrideClick?: () => void;
  fuelCost?: number;
  stayOnPage?: boolean;
  secondary?: boolean;
  snackStats?: StatAbbreviation[];
  disabled?: boolean;
  onSaving?: (saving: boolean) => void;
  onError?: (error: string | undefined) => void;
};

function getRequestCost(
  request: ITimekeeperMarketRequest,
  costs: ITimekeeperCosts,
  furballs: IFurball[],
): number {
  if (request.interactionType === TimekeeperInteractionType.CollectAllFur)
    return costs.collectFur;
  if (!request.furballIds || request.furballIds.length <= 0) return 0;
  if (request.interactionType === TimekeeperInteractionType.CollectStay)
    return (
      costs.collectExp1 + costs.collectExpN * (request.furballIds.length - 1)
    );

  if (request.interactionType === TimekeeperInteractionType.VoyageBegin) {
    const furballIds = request.furballIds;
    // if (request.interactionType === TimekeeperInteractionType.VoyageBegin) {
    //   furballIds = furballIds.filter(tokenId => {
    //     return furballs.filter(f => f.tokenId === tokenId)[0].zone !== ZONE_NUM_VOYAGE;
    //   });
    // }
    return furballIds.length * costs.enterVoyages;
    // ret -= 20;
    // ret -= 10;
  }
  if (request.interactionType === TimekeeperInteractionType.CollectMove) {
    const furballIds = request.furballIds;
    let ret = costs.move1 + costs.moveN * (furballIds.length - 1);
    let j = 0;
    for (let i = 0; i < furballs.length; i++) {
      if (!furballIds.includes(furballs[i].tokenId)) continue;
      const zone = getZone(furballs[i].zone);
      if (zone.zoneNumber === ZONE_NUM_EXPLORE) {
        ret += j === 0 ? costs.collectExp1 : costs.collectExpN;
        j++;
      } else if (zone.zoneNumber === ZONE_NUM_VOYAGE) {
        ret += costs.dropLoot;
      }
    }
    // ret -= 20;
    // ret -= 10;
    return ret;
  }

  return 0;
}

export const timekeeperInteractionsWithGasEquivalents = [
  TimekeeperInteractionType.CollectAllFur,
  TimekeeperInteractionType.CollectStay,
  TimekeeperInteractionType.CollectMove,
  TimekeeperInteractionType.FeedSnack,
  TimekeeperInteractionType.UpgradeLoot,
  TimekeeperInteractionType.Mint,
];

export const TimekeeperMarketButton: FunctionComponent<Props> = (props) => {
  const {
    title,
    className,
    marketRequest,
    furballIds,
    instant,
    fuelCost,
    stayOnPage,
    onSaved,
    secondary,
    overrideClick,
    onSaving,
    onError,
  } = props;
  const snackStats = props.snackStats ?? [];
  const sender = useAppSelector((s) => s.wallet.address);
  const tkRequest = useCurrentTimekeeperRequest();
  const common = useCommonStyles();
  const dispatch = useAppDispatch();
  const furballs = usePlayersReadyFurballs().filter((i) =>
    furballIds.includes(i.tokenId),
  );
  const history = useHistory();
  const [gasEstimate, setGasEstimate] = React.useState(0);
  const [hover, setHover] = React.useState(false);
  const [save, { data, loading, error }] = useTimekeeperSaveActionMutation();
  const [saving, setSaving] = React.useState(false);
  const classes = useStyles();
  const costs = tkRequest.round?.costs;
  const interaction: TimekeeperInteractionArgInput = {
    expectedValue: marketRequest.expectedValue ?? null,
    furballIds,
    gasEstimate,
    action: marketRequest.action ?? TimekeeperInteractionAction.Append,
    interactionType: marketRequest.interactionType,
    sender: sender ?? '',
    count: marketRequest.count ?? null,
  };
  const tkDisabled = furballs.find((fb) => fb.timekeeperDisabled);
  const isDisabled = props.disabled || loading;

  function onComplete() {
    onSaved();
    if (!stayOnPage) history.push('/timekeeper');
  }

  function doSetSaving(saving: boolean): void {
    setSaving(saving);
    if (onSaving) onSaving(saving);
  }

  React.useEffect(() => {
    if (!saving || !sender) return;

    let _cancelled = false;
    async function saveChanges() {
      if (!sender) return;
      try {
        if (onError) onError(undefined);
        const d = await save({ variables: { interaction } });
        const req = d.data?.timekeeperSaveAction;
        if (_cancelled || !req) return;
        dispatch(TimekeeperSlice.actions.setCurrentRequests(req));
        if (snackStats.length === 0) {
          onComplete();
        } else {
          dispatch(
            TimekeeperSlice.actions.setSnackSelection({
              furballs,
              stats: snackStats,
              close: onComplete,
            }),
          );
        }
        doSetSaving(false);
      } catch (e) {
        console.error(e, 'save');
        if (onError) onError(`${e}`);
        doSetSaving(false);
      }
    }

    void saveChanges();
    return () => {
      _cancelled = true;
    };
  }, [saving]);

  if (!costs) return <LinearProgress color={'secondary'} />;

  const totalFuelCost =
    fuelCost ?? getRequestCost(marketRequest, costs, furballs);
  const tixCost = Math.ceil(totalFuelCost / costs.factor);
  const gasPrice = tkRequest.round?.gasPrice;
  const hasGasEquivalent =
    timekeeperInteractionsWithGasEquivalents.includes(
      interaction.interactionType,
    ) && furballs.filter((fb) => fb.zone === ZONE_NUM_VOYAGE).length <= 0;
  const iconStyle = {
    maxWidth: 22,
    marginRight: 6,
    marginLeft: 6,
    marginBottom: 2,
  };

  return (
    <React.Fragment>
      {loading && <LinearProgress color={'secondary'} />}
      {!loading && !tkDisabled && (
        <Button
          variant={'contained'}
          color={secondary ? 'secondary' : 'primary'}
          className={clsx(common.actionButton, className, classes.root)}
          onClick={overrideClick ?? (() => doSetSaving(true))}
          disabled={isDisabled}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <Typography variant={'h6'}>+{tixCost} TIX</Typography>
          <SvgIconTicket
            style={iconStyle}
            fill={hover ? '#ffffff' : '#000000'}
          />
          <Typography variant={'h6'}>{title}</Typography>
        </Button>
      )}
      {!loading && tkDisabled && (
        <Typography variant={'subtitle2'}>Timekeeper is disabled!</Typography>
      )}
      {error && <Typography variant={'subtitle2'}>{error.message}</Typography>}
      {/*{hasGasEquivalent && gasPrice && (*/}
      {/*  <Typography variant={'subtitle2'}>*/}
      {/*    <GasSavingsEstimator*/}
      {/*      interaction={interaction}*/}
      {/*      ethCost={tixCost * ethPerTix}*/}
      {/*      gasPrice={gasPrice}*/}
      {/*      onEstimated={setGasEstimate}*/}
      {/*      className={classes.gasSavings}*/}
      {/*    />*/}
      {/*  </Typography>*/}
      {/*)}*/}
    </React.Fragment>
  );
};

export default TimekeeperMarketButton;
