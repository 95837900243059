import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fade,
  Grid,
  Tab,
  Tabs,
  Theme,
  Typography,
} from '@mui/material';
import useCommonStyles from '../../useCommonStyles';
import { useWindowSize } from '../../useWindowSize';
import { cdnRoot } from '../../../theme';
import {
  formatNumber,
  getAverageNum,
  getBossNameFromRealm,
  getResponsiveWidth,
  secToMinAndSec,
  useFurComponent,
} from '../../../utils';
import { RealmType, useBossBattleHistoryPlayerLazyQuery } from '../../schema';
import BossBattleStats from './BossBattleStats';
import { useWalletSelector } from '../../../hooks';
import { IFurballPlayer } from '../../../wallet/WalletTypes';
import clsx from 'clsx';
import FurballHead from '../../Furballs/FurballHead';
import { shadeColor, yellow } from '../../../themev2';
import { useHistory } from 'react-router-dom';
import SvgIconBossHistoryAll from '../../../assets/SvgIconBossHistoryAll';
import SvgIconBossHistoryDate from '../../../assets/SvgIconBossHistoryDate';
import SvgIconBossHistoryDuration from '../../../assets/SvgIconBossHistoryDuration';
import { capitalize } from 'lodash';

const useStylesWithProps = (onAccountPage: boolean) => {
  const { desktop, tablet, mobile } = getResponsiveWidth(onAccountPage);

  return makeStyles((theme: Theme) => {
    return {
      cardTitle: {
        fontSize: '1.8rem !important',
      },
      cardContent: {
        position: 'relative',
        minHeight: '480px',
        maxHeight: '480px',

        [theme.breakpoints.down(mobile)]: {
          padding: '0.7rem !important',
        },
      },
      cont: {
        display: 'flex',
        gap: '16px',
        marginTop: '1rem',

        [theme.breakpoints.down(desktop)]: {
          flexDirection: 'column',
        },
      },
      bossBattles: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.9rem',
        width: '100%',
        maxHeight: '400px',
        overflowY: 'auto',
        padding: '0.15rem',
        marginTop: '2.8rem',

        '&::-webkit-scrollbar': {
          width: '0.7em',

          [theme.breakpoints.down(tablet)]: {
            width: '0',
          },
        },
      },
      bossBattle: {
        display: 'flex',
        borderRadius: '6px',
        backgroundColor: 'white',
        padding: '1rem 0.75rem',
        paddingLeft: '1rem',
        minHeight: '67.5px',
        maxHeight: '67.5px',
        alignItems: 'center',
        justifyContent: 'space-between',

        [theme.breakpoints.down(tablet)]: {
          padding: '1rem 0.5rem',
          paddingLeft: '0.75rem',
        },

        [theme.breakpoints.down(mobile)]: {
          padding: '1rem 0.35rem',
          paddingLeft: '0.5rem',
        },
      },
      bossAvatar: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '52px',
        width: '170px',
        position: 'relative',

        '& span': {
          fontWeight: 'bold',
          textAlign: 'left',
          fontSize: '1.1rem',
        },

        '& img': {
          marginRight: '12px',
        },

        [theme.breakpoints.down(tablet)]: {
          '& span': {
            fontSize: '1rem',
          },

          '& img': {
            marginRight: '0',
          },
        },

        [theme.breakpoints.down(mobile)]: {
          '& span': {
            display: 'none',
          },

          '& img': {
            marginRight: '0',
          },
        },
      },
      bossLvl: {
        padding: '1px 0.3rem',
        backgroundColor: theme.palette.secondary.light,
        borderRadius: '4px',
        color: 'white',
        fontSize: '12px',
        width: '55px',
        marginTop: '3px',

        [theme.breakpoints.down(mobile)]: {
          position: 'absolute',
          borderRadius: '50%',
          bottom: '3px',
          left: '27.5px',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
      partyInfo: {
        height: '52px',
        minWidth: '330px',
        maxWidth: '330px',
        padding: '0.1rem 0.5rem',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: `2px solid ${shadeColor(theme.palette.secondary.main, 55)}`,
        textAlign: 'left',
        gap: '1rem',
        backgroundColor: shadeColor(theme.palette.secondary.light, 45),
        color: 'white',

        '& span': {
          color: shadeColor(theme.palette.secondary.main, 95),
        },

        '& p': {
          color: `${shadeColor(theme.palette.secondary.main, 60)} !important`,
          marginTop: '-2px !important',
          fontSize: '12px !important',
        },

        [theme.breakpoints.down(tablet)]: {
          minWidth: '175px',
          maxWidth: '175px',
          gap: '0',

          '& span, p': {
            display: 'none',
          },
        },

        [theme.breakpoints.down(mobile)]: {
          minWidth: '162.5px',
          maxWidth: '162.5px',
          padding: '0 0.25rem',
        },
      },
      partyFurballs: {
        display: 'flex',
        alignItems: 'flex-end',
        gap: '2px',
        paddingLeft: '0.75rem',
        borderLeft: `1px solid ${shadeColor(
          theme.palette.secondary.light,
          65,
        )}`,

        [theme.breakpoints.down(tablet)]: {
          gap: '1px',
          paddingLeft: '0',
          borderLeft: 'none',
          justifyContent: 'center',
        },

        [theme.breakpoints.down(mobile)]: {
          gap: '0',
        },
      },
      rewards: {
        display: 'flex',
        position: 'relative',
      },
      battleInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-evenly',
        height: '52px',
        marginRight: '5px',

        '& p': {
          fontWeight: 'bold',
          fontSize: '12px !important',
          display: 'flex',
          alignItems: 'center',

          '& svg': {
            marginRight: '7.5px',
          },
        },

        [theme.breakpoints.down(mobile)]: {
          display: 'none',
        },
      },
      points: {
        minWidth: '65px',
        maxWidth: '65px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        borderRadius: '6px',
        fontWeight: 'bold',
        fontSize: '1.15rem',
        lineHeight: '1.15rem',
        backgroundColor: yellow,
        height: '52px',

        '& span': {
          fontSize: '0.6rem',
          marginBottom: '5px',
        },

        [theme.breakpoints.down(tablet)]: {
          fontSize: '1rem',
          minWidth: '55px',
          maxWidth: '55px',
        },

        [theme.breakpoints.down(mobile)]: {
          minWidth: '52px',
          maxWidth: '52px',
        },
      },
      info: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '0.75rem',
        marginLeft: '0.75rem',

        [theme.breakpoints.down(tablet)]: {
          gap: '0.35rem',
        },
      },
      dataInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: '250px',

        '& h6': {
          color: 'rgba(255, 255, 255, 0.7) !important',
          marginTop: '2rem !important',
        },
      },
      tabWith: {
        [theme.breakpoints.down(400)]: {
          minWidth: '75px !important',
          width: '75px !important',
        },
      },
      noBattles: {
        display: 'flex',
        minHeight: '375px',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        flexDirection: 'column',

        '& img, svg': {
          position: 'absolute',
          opacity: 0.1,
          marginBottom: '5rem',
        },

        '& h6': {
          fontSize: '2rem !important',
          opacity: 0.8,
          color: '#A5B4FC',
          zIndex: '10',
          marginTop: '6rem',

          [theme.breakpoints.down(mobile)]: {
            fontSize: '1.45rem !important',
          },
        },
      },
      noBattleCta: {
        border: `1px solid ${theme.palette.primary.main} !important`,
        borderRadius: '4px !important',
        height: '40px !important',
        minWidth: '130px !important',
        width: '130px !important',
        margin: '0.35rem !important',
        fontSize: '0.9rem !important',
      },
      noScrollOut: {
        overscrollBehavior: 'contain',
      },
    };
  })();
};

const tabs: { [k: string]: RealmType } = {
  '0': RealmType.None,
  '1': RealmType.TurtleSea,
  '2': RealmType.WizardsPath,
  '3': RealmType.Boneyard,
};

export interface IPercentiles {
  lastSevenDays: number;
  lastThirtyDays: number;
}

interface IBossBattleHistory {
  player?: IFurballPlayer;
  onAccountPage?: boolean;
}

export const BossBattleHistory: FunctionComponent<IBossBattleHistory> = (
  props,
) => {
  const { log } = useFurComponent(BossBattleHistory);
  const { player, onAccountPage } = props;
  const classes = useStylesWithProps(!!onAccountPage);
  const common = useCommonStyles();

  const windowWidth = useWindowSize().width;
  const responsiveWidth = getResponsiveWidth(!!onAccountPage);
  const numRentalCancellationsPlayer = useWalletSelector(
    (c) => c.contents?.numRentalCancellationsPlayer ?? 0,
  );
  const numRentalCancellationsOwner = useWalletSelector(
    (c) => c.contents?.numRentalCancellationsOwner ?? 0,
  );
  const isMobile = windowWidth < responsiveWidth.mobile;
  const isTablet = windowWidth < responsiveWidth.tablet;

  const history = useHistory();
  const myAccount = useWalletSelector((s) => s.contents);
  const viewedPlayer = player ?? myAccount;
  const viewPlayerId = viewedPlayer?.id;
  const isViewingMyAccount = myAccount?.id === viewedPlayer?.id;

  const [selectedTab, setSelectedTab] = useState<string>('0');
  const onAllTab = selectedTab === '0';

  const [loadHistory, { data, loading }] =
    useBossBattleHistoryPlayerLazyQuery();

  const bossBattlesRef = useRef<HTMLDivElement>(null);
  const nodes = data?.playerHistory?.nodes ?? [];
  const bossBattles = useMemo(() => getBossBattles(), [nodes]);
  const avgPercentiles = useMemo(() => calculateAvgPercentiles(), [nodes]);
  const bossBattlesForSelectedRealm = bossBattles.filter((bossBattle) =>
    onAllTab ? true : bossBattle.worldBoss.realm === tabs[selectedTab],
  );

  useEffect(() => {
    if (viewPlayerId) {
      log.info('[BOSS] history for', viewPlayerId);
      loadHistory({ variables: { playerId: viewPlayerId } });
    }
  }, [viewPlayerId]);

  useEffect(() => {
    if (!!bossBattlesRef && !!bossBattlesRef.current) {
      bossBattlesRef.current.scrollTo(0, 0);
    }
  }, [selectedTab]);

  function getBossBattles() {
    return nodes.map(({ bossBattles }) => bossBattles).flat();
    // .filter(
    //   (bossBattle) => !(bossBattle.isTrialGame || !bossBattle.duration),
    // );
  }

  function renderTabIcon(tabKey: string) {
    if (tabKey !== '0') {
      return getBossAvatar(getBossNameFromRealm(tabs[tabKey]), 28, true);
    }

    return (
      <SvgIconBossHistoryAll
        height={23}
        width={23}
        style={{ marginRight: isMobile ? '0' : '12px ' }}
      />
    );
  }

  function getBossAvatar(bossName: string, size: number, margin?: boolean) {
    return (
      <img
        src={`${cdnRoot}/images/avatars/${bossName.toLowerCase()}.png`}
        height={size}
        width={size}
        style={{ marginRight: margin && !isMobile ? '12px' : '0' }}
        alt={`${bossName} avatar`}
      />
    );
  }

  function calculateAvgPercentiles(): IPercentiles {
    const percentiles = nodes.map(
      ({ percentileRankOverall }) => percentileRankOverall,
    );

    const percentileLastSevenDays = percentiles.slice(0, 7);
    const percentileLastThirtyDays = percentiles.slice(0, 30);

    const lastSevenDays = getAverageNum(percentileLastSevenDays);
    const lastThirtyDays = getAverageNum(percentileLastThirtyDays);

    return { lastSevenDays, lastThirtyDays };
  }

  function renderPartyFurballs(furballIds: string[]) {
    return furballIds.map((furballId, i) => (
      <ButtonBase
        key={`${furballId}-${i}`}
        onClick={() => history.push(`/furball/${furballId}`)}
        style={{ cursor: 'pointer', borderRadius: '50%' }}>
        <FurballHead tokenId={furballId} size={isTablet ? 27.5 : 35} />
      </ButtonBase>
    ));
  }

  function renderNoBattleCta(text: string, route: string, outlined?: boolean) {
    return (
      <Button
        variant={outlined ? 'outlined' : 'contained'}
        onClick={() => history.push(route)}
        className={classes.noBattleCta}>
        {text}
      </Button>
    );
  }

  function renderNoBossBattlesForBoss() {
    const bossName = capitalize(getBossNameFromRealm(tabs[selectedTab]));

    return (
      <Fade in={true} timeout={1300} key={`fade-${bossName}`}>
        <div className={classes.noBattles}>
          {onAllTab ? (
            <SvgIconBossHistoryAll
              height={185}
              width={185}
              style={{ marginRight: isMobile ? '0' : '12px ' }}
            />
          ) : (
            getBossAvatar(bossName, 185)
          )}
          <Typography variant={'h6'}>
            No recent battles
            {onAllTab ? '' : ` vs ${bossName}`}
          </Typography>
          {isViewingMyAccount && (
            <div>
              {renderNoBattleCta('Borrow', '/market/furballs', true)}
              {renderNoBattleCta('Play Now', '/boss')}
            </div>
          )}
        </div>
      </Fade>
    );
  }

  function renderBossBattles() {
    if (bossBattlesForSelectedRealm.length === 0) {
      return renderNoBossBattlesForBoss();
    }

    return bossBattlesForSelectedRealm.map((bossBattle) => {
      const { score, createdAt, duration, furballIds, playerParty, worldBoss } =
        bossBattle;

      return (
        <div
          key={bossBattle.id}
          className={classes.bossBattle}
          style={{
            marginRight:
              bossBattlesForSelectedRealm.length < 5 || isTablet ? '0' : '1rem',
          }}>
          <div className={classes.bossAvatar}>
            {getBossAvatar(
              worldBoss.name.toLowerCase(),
              isTablet ? 40 : 47.5,
              true,
            )}
            <div>
              <span>{worldBoss.name}</span>
              <div className={classes.bossLvl}>
                {!isMobile ? 'Level ' : ''}
                {worldBoss.level}
              </div>
            </div>
          </div>
          <div className={classes.info}>
            <div className={classes.battleInfo}>
              <Typography variant={'body1'}>
                <SvgIconBossHistoryDuration height={15} width={15} />{' '}
                {secToMinAndSec(duration)}
              </Typography>
              <Typography variant={'body1'}>
                <SvgIconBossHistoryDate height={15} width={15} />{' '}
                {new Date(createdAt).toLocaleDateString()}
              </Typography>
            </div>
            <div className={classes.points}>
              {formatNumber(score)} <span>POINTS</span>
            </div>
            <div className={classes.partyInfo}>
              <div>
                <span>
                  {!!playerParty ? playerParty?.name : 'Deleted Party'}
                </span>
                <Typography variant={'body1'}>
                  Party of {furballIds.length}
                </Typography>
              </div>
              <div className={classes.partyFurballs}>
                {renderPartyFurballs(furballIds)}
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  function renderBossBattleHistory() {
    if (loading) {
      return (
        <div className={classes.dataInfo}>
          <CircularProgress color={'secondary'} size={50} />
          <Typography variant={'h6'}>Loading recent battle stats...</Typography>
        </div>
      );
    }

    if (!viewedPlayer || !data) {
      return (
        <div className={classes.dataInfo}>
          <Typography variant={'h6'}>
            Unable to load recent boss battles. Please re-load this page.
          </Typography>
        </div>
      );
    }

    return (
      <>
        <BossBattleStats
          percentiles={avgPercentiles}
          battlesPlayed={bossBattles.length}
          numRentalCancellationsOwner={numRentalCancellationsOwner}
          numRentalCancellationsPlayer={numRentalCancellationsPlayer}
          onAccountPage={onAccountPage}
        />
        <Card
          className={common.cardRoot}
          style={{
            width: '100%',
          }}>
          <CardHeader
            className={common.cardHeader}
            classes={{ title: classes.cardTitle }}
            sx={{ minHeight: '62px' }}
            title={'Recent Boss Battles'}
          />
          <CardContent className={classes.cardContent}>
            <Tabs
              variant={'standard'}
              value={selectedTab}
              className={common.tabs}
              onChange={(e, v) => setSelectedTab(v)}
              textColor='secondary'
              indicatorColor='secondary'
              TabIndicatorProps={{ style: { height: '4px' } }}>
              {Object.keys(tabs).map((tabKey) => {
                return (
                  <Tab
                    key={tabKey}
                    className={clsx(common.tab, classes.tabWith)}
                    color={'secondary'}
                    label={
                      <div className={common.tabLabel}>
                        {renderTabIcon(tabKey)}
                        {isMobile
                          ? ''
                          : tabKey === '0'
                          ? 'All'
                          : getBossNameFromRealm(tabs[tabKey])}
                      </div>
                    }
                    value={tabKey}
                  />
                );
              })}
            </Tabs>
            <div
              ref={bossBattlesRef}
              className={clsx(common.styledScrollBar, classes.bossBattles, {
                [classes.noScrollOut]: bossBattlesForSelectedRealm.length > 0,
              })}>
              {renderBossBattles()}
            </div>
          </CardContent>
        </Card>
      </>
    );
  }

  return (
    <Grid item xs={12}>
      <div className={classes.cont}>{renderBossBattleHistory()}</div>
    </Grid>
  );
};

export default BossBattleHistory;
