import { Theme, Grid, Typography } from '@mui/material';

import React, { FunctionComponent } from 'react';

import OkDialog from '../OkDialog';
import FurballRenderer from '../Furballs/FurballRenderer';

import { makeStyles } from '@mui/styles';
import { useScholarshipHelpers } from './ScholarshipHelpers';
import { useFurComponent } from '../../utils';
import ScholarshipEditor from './ScholarshipEditor';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '35rem !important',

    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },

  titleAddress: {
    color: theme.palette.primary.main,
  },

  furballContainer: {
    position: 'relative',
  },

  furball: {
    position: 'relative',
  },

  furballNumber: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: '-1rem',
  },
}));

interface IProps {
  tokenId: string;
  open: boolean;
  setOpen: () => void;
}

export const ScholarshipDetailsDialogue: FunctionComponent<IProps> = ({
  open,
  setOpen,
  tokenId,
}) => {
  const { log } = useFurComponent(ScholarshipDetailsDialogue);
  const classes = useStyles();
  const { isOwner, furball, playerLink, ownerLink } = useScholarshipHelpers(
    log,
    tokenId,
    true,
  );

  return (
    <OkDialog
      title={
        <Typography variant='h5' fontSize='1.8rem'>
          Rental Contract With{' '}
          <span className={classes.titleAddress}>
            {isOwner ? playerLink : ownerLink}
          </span>
        </Typography>
      }
      open={open}
      onClose={setOpen}>
      <Grid container padding='1rem' gap='3rem' className={classes.root}>
        <Grid container justifyContent='center' alignItems='center' gap='2rem'>
          <Grid
            item
            container
            xs={5}
            direction='column'
            gap='0.5rem'
            justifyContent='center'
            alignItems='center'
            className={classes.furballContainer}>
            <FurballRenderer
              tokenId={tokenId}
              size='10rem'
              className={classes.furball}
            />
            <Typography
              variant='h4'
              className={classes.furballNumber}
              textAlign='center'
              fontSize='1.4rem'>
              Furball #{furball?.number}
            </Typography>
          </Grid>
        </Grid>

        <ScholarshipEditor tokenId={tokenId} onEnded={setOpen} />
      </Grid>
    </OkDialog>
  );
};
