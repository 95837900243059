import { ILootItem, ILootSlot } from './LootTypes';
import { getRarityName } from '../../pages/Play/helpers';
// import { BigNumber } from 'ethers';
import { getLootItem } from './LootItems';
import { IGameItem } from '../../wallet/WalletTypes';

export type LootStatMap = { [key: string]: ILootSlot[] };

function getLootRarityBoost(rarity: number): number {
  if (rarity === 1) return 5;
  if (rarity === 2) return 15;
  if (rarity === 3) return 30;
  return 0;
}

export function getLootSlot(item: ILootItem, stack = 1): ILootSlot {
  const rarityName = getRarityName(item.rarity);
  const title = stack > 1 ? `${rarityName} (x${stack})` : rarityName;
  const boost = getLootRarityBoost(item.rarity) * stack;
  return { stack, title, boost, rarityName, item };
}

export function canLootBeUpgraded(slot: ILootSlot): boolean {
  return (
    (slot.item.stat === 'exp' || slot.item.stat === 'fur') &&
    slot.item.rarity < 3
  );
}

export function groupInventory(inventory: IGameItem[]): LootStatMap {
  const ret: LootStatMap = {};
  for (let i = 0; i < inventory.length; i++) {
    const item = getLootItem(inventory[i].itemId);
    const stat = item.stat;
    if (!stat) continue;

    if (!ret[stat]) ret[stat] = [];
    ret[stat].push({
      ...getLootSlot(item, inventory[i].stack),
      id: inventory[i].id,
    });
  }
  return ret;
}

export function getTotalLootBonus(slots?: ILootSlot[]): number {
  if (!slots) return 0;
  let ret = 0;
  slots.forEach((slot) => {
    ret += slot.boost;
  });
  return ret;
}
