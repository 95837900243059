import React, { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import theme, { black, white, yellow } from '../../../themev2';
import { PartySize, useBossBattle } from '../useBossBattle';
import {
  getBossKeyRealm,
  getLootItem,
  getLootSlot,
} from '../../../components/Loot';

import SvgIconCircleChecked from '../../../assets/SvgIconCircleChecked';
import SelectCustom from '../../../components/SelectCustom';
import AccountHeader from '../../../AccountHeader';
import FullDrawer from '../../../components/Drawer/FullDrawer';
import FormationDrawer from './FormationDrawer';
import FormationGrid from './FormationGrid';
import SvgIconTrash from '../../../assets/SvgIconTrash';
import SvgIconDice from '../../../assets/SvgIconDice';
import SvgIconEdit from '../../../assets/SvgIconEdit';
import OkDialog from '../../../components/OkDialog';
import { RealmType } from '../../../components/schema';
import BuyKeysDialog from '../BuyKeysDialog';
import { IFurball } from '../../../wallet/WalletTypes';
import { usePageTitle } from '../../../Analytics';
import clsx from 'clsx';
import { slugify } from '../../../utils';
import { ILobbyParams } from '../Lobby';
import { usePlayersReadyFurballs } from '../../../wallet';
import WalletContext from '../../../wallet/WalletContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: { position: 'relative' },

  accountHeader: {
    width: '100%',
    marginRight: '16px',
  },

  wrapper: {
    position: 'relative',
    margin: '0 16px 1rem 0',
    padding: '1.5rem 16px',

    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },

  ctaButtons: {
    position: 'relative',
    margin: '1rem 16px 1rem 0',
    padding: '0 16px',

    [theme.breakpoints.down('sm')]: {
      margin: '0',
    },
  },

  titleBar: {
    border: `8px solid ${theme.palette.secondary.dark}`,
    borderBottom: 'none',
    backgroundColor: '#242385 !important',
    borderRadius: '20px 20px 0 0',
    padding: '1rem 1.5rem',

    [theme.breakpoints.down('sm')]: {
      padding: '1rem',
    },
  },

  btnGroup: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  createButton: {
    minHeight: '3rem',
    marginRight: 'auto',
    backgroundColor: `${theme.palette.grey[400]} !important`,
    borderColor: `${theme.palette.secondary.light} !important`,
    minWidth: '15rem !important',

    '&[data-active="true"]': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `2px solid ${theme.palette.common.black} !important`,
    },

    '&:first-child': {
      borderRadius: '40px 0 0 40px !important',
    },

    '&:last-child': {
      borderRadius: '0 40px 40px 0px !important',
    },

    [theme.breakpoints.down('sm')]: {
      minWidth: '50% !important',
    },
  },

  createBtnText: {
    fontSize: '0.9rem !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '0.7rem !important',
    },
  },

  cancelButton: {
    width: '2.2rem !important',
    height: '2.2rem !important',
    padding: '0 !important',
    borderRadius: '100% !important',
    minWidth: 'unset !important',
    border: 'none !important',
    backgroundColor: `${yellow} !important`,

    '&[data-disabled="true"]': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  formationWrapper: {
    width: '100%',
  },

  formationContainer: {
    width: '100%',
    border: `8px solid ${theme.palette.secondary.dark}`,
    backgroundColor: '#242385 !important',
    borderRadius: '0 0 20px 20px',
    minHeight: '31.25rem',
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },

  infoBox: {
    padding: '3rem 2rem',
    borderLeft: `8px solid ${theme.palette.secondary.dark}`,

    [theme.breakpoints.down('sm')]: {
      borderLeft: 'none',
    },
  },

  infoIcon: {
    width: '3rem',
    height: '3rem',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#653FFF',
    borderRadius: '100%',
    flexShrink: 0,
  },

  input: {
    '& fieldset': {
      borderRadius: '40px',
      border: '1px solid #653FFF !important',
    },

    '& input': {
      color: white,
      paddingLeft: '1.5rem',
    },

    '& input::placeholder': {
      color: white,
    },
  },

  saveLoadButton: {
    height: '3.5rem',
    borderRadius: '40px !important',

    '&[data-disabled="true"]': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
  },

  btnIcon: {
    marginTop: '0.2rem',
  },

  titleText: {
    fontSize: '1.5rem !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem !important',
    },
  },

  selectContainer: {
    minWidth: '12rem',
  },

  select: {
    width: 'fit-content !important',
    overflow: 'visible !important',

    '& > div': {
      border: 'none !important',
      backgroundColor: 'transparent !important',
      paddingLeft: '0 !important',
    },

    '& > svg': {
      right: 0,
      color: `${theme.palette.primary.light} !important`,
    },

    '& .MuiSelect-select': {
      paddingRight: '2.3rem !important',
    },

    '& p': {
      fontSize: '1.5rem !important',
      color: `${theme.palette.primary.light} !important`,
      fontWeight: 800,
      fontFamily: 'Ubuntu Medium',
      width: 'fit-content !important',

      [theme.breakpoints.down('sm')]: {
        fontSize: '1.3rem !important',
      },
    },
  },

  formation: {
    padding: '1rem',
  },

  formationButton: {
    minHeight: '2rem',
    minWidth: '4rem !important',
    color: `${theme.palette.common.white} !important`,
    borderColor: `${theme.palette.common.white} !important`,

    '&[data-selected="true"]': {
      backgroundColor: theme.palette.primary.main,
      borderColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.common.black} !important`,

      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },

    '&:disabled': {
      opacity: 0.7,
      borderRadius: '30px !important',
    },

    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0.7rem !important',
      paddingRight: '0.7rem !important',
      minWidth: 'unset !important',
    },
  },

  keysContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0.3rem',
  },

  key: {
    display: 'grid',
    placeItems: 'center',
    width: '1.8rem',
    height: '1.8rem',
    border: '2px solid black',
    padding: '0.2rem',
    borderRadius: '8px',
    backgroundColor: '#E9E9ED',
    position: 'relative',

    '&[data-disabled="true"]': {
      // filter: 'brightness(60%) grayScale(50%)',
      borderColor: theme.palette.grey[800],
      cursor: 'pointer',
    },

    '& > svg': {
      position: 'static !important',
    },
  },

  keyCheckmark: {
    position: 'absolute',
    top: '-1.3rem',
  },

  forfeitCta: {
    textDecoration: 'underline',
    cursor: 'pointer',
    transition: 'all o.2s linear',

    '&:hover': {
      opacity: '0.8',
    },
  },

  forfeitDialogContent: {
    width: '25rem',
    padding: '2rem 1rem',
  },

  forfeitDialogButton: {
    minWidth: '10rem !important',
    borderRadius: '40px !important',
  },

  sizeControlWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'grid',
    placeItems: 'center',
  },

  sizeControl: {
    margin: 'auto',
  },

  sizeButton: {
    minWidth: '2.7rem !important',
    maxWidth: '2.7rem !important',

    [theme.breakpoints.down('sm')]: {
      minWidth: '2.4rem !important',
      maxWidth: '2.4rem !important',
    },
  },

  sizeBtnText: {
    fontSize: '0.8rem !important',
  },
}));

export const FormationPage = () => {
  const [showForfeit, setShowForfeit] = useState(false);
  const [buyingKeys, setBuyingKeys] = useState<RealmType>();
  const classes = useStyles();
  const history = useHistory();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const params = useParams<ILobbyParams>();
  const furballs = usePlayersReadyFurballs();
  const context = React.useContext(WalletContext);
  // const isMainNet = context?.network.type === NetworkType.MainNet;

  const {
    isNew,
    partySize,
    setPartySize,
    allFormations,
    currentFormationId,
    setCurrentFormationId,
    currentParty,
    allParties,
    paramId,
    partyName,
    setPartyName,
    partyMembers,
    mutateParty,
    isDeleting,
    hasChanges,
    loading,
    fallBackId,
    bossKeys,
    hasRequiredKey,
    partyErrors,
    forfeitBattle,
    isForfeiting,
  } = useBossBattle(params);
  const partySlug = currentParty ? slugify(currentParty.name) : paramId;

  usePageTitle((isNew ? 'Create' : 'Edit') + ' Party | Boss');

  function setParty(partyId: string) {
    // const party = allParties.find((p) => p.id === partyId);
    // const slug = party ? slugify(party.name) : partyId;
    history.push(`/boss/party/${partyId}`);
  }

  return (
    <FullDrawer
      drawer={<FormationDrawer />}
      customContainerClass={classes.root}>
      <div className={classes.accountHeader}>
        <AccountHeader hideCurrencies={true} />
      </div>

      <Grid container justifyContent='flex-end' className={classes.ctaButtons}>
        {!!allParties?.length && (
          <ButtonGroup variant='contained' className={classes.btnGroup}>
            <Button
              className={classes.createButton}
              data-active={isNew}
              disabled={!isNew}
              startIcon={<SvgIconEdit width='15' height='15' />}
              onClick={() =>
                isNew
                  ? history.push(`/boss/party/${fallBackId}`)
                  : history.push('/boss/party/new')
              }>
              <Typography variant='h4' className={classes.createBtnText}>
                Edit Saved Parties
              </Typography>
            </Button>
            <Button
              className={classes.createButton}
              data-active={!isNew}
              disabled={isNew}
              startIcon={
                <Typography variant='h4' fontSize='1.1rem'>
                  +
                </Typography>
              }
              onClick={() =>
                isNew
                  ? history.push(`/boss/party/${fallBackId}`)
                  : history.push('/boss/party/new')
              }>
              <Typography variant='h4' className={classes.createBtnText}>
                Create New Party
              </Typography>
            </Button>
          </ButtonGroup>
        )}
      </Grid>

      <Grid container direction='column' gap={0} className={classes.wrapper}>
        <Grid
          item
          container
          alignItems='center'
          rowGap='0.5rem'
          justifyContent='space-between'
          className={classes.titleBar}>
          <Grid
            item
            container
            gap='0.8rem'
            flexWrap='nowrap'
            width='fit-content'
            alignItems='center'>
            {allParties?.length > 0 && (
              <Button
                variant='contained'
                color='error'
                data-disabled={isDeleting}
                className={classes.cancelButton}
                onClick={() => {
                  isNew ? history.push('/furverse') : mutateParty('delete');
                }}>
                <Typography variant='h4' fontSize='0.7rem' color={black}>
                  {isDeleting ? (
                    <CircularProgress
                      className={classes.btnIcon}
                      size={10}
                      color='inherit'
                    />
                  ) : isNew ? (
                    <>&#x2715;</>
                  ) : (
                    <SvgIconTrash
                      width='0.9rem'
                      height='0.9rem'
                      fill='currentColor'
                      className={classes.btnIcon}
                    />
                  )}
                </Typography>
              </Button>
            )}

            <Grid item>
              {isNew ? (
                <Grid
                  item
                  container
                  xs={12}
                  gap='0.5rem'
                  alignItems='center'
                  flexWrap='nowrap'
                  width='fit-content'>
                  <Typography
                    variant='h4'
                    color={white}
                    className={classes.titleText}>
                    {isSmallScreen ? 'Size' : 'Creating Party Of'}
                  </Typography>
                  <div className={classes.selectContainer}>
                    <SelectCustom
                      fullWidth
                      id='party-size'
                      inputClass={classes.select}
                      value={partySize}
                      onChange={(e) =>
                        setPartySize(Number(e.target.value as PartySize))
                      }
                      options={[
                        { label: 'One Furball', value: PartySize.ONE },
                        { label: 'Three Furballs', value: PartySize.THREE },
                        { label: 'Five Furballs', value: PartySize.FIVE },
                      ]}
                    />
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  container
                  xs={12}
                  gap='0.5rem'
                  alignItems='center'
                  flexWrap='nowrap'
                  width='fit-content'>
                  <Typography
                    variant='h4'
                    color={white}
                    className={classes.titleText}>
                    Editing
                  </Typography>
                  <div className={classes.selectContainer}>
                    <SelectCustom
                      fullWidth
                      id='party'
                      inputClass={classes.select}
                      value={paramId}
                      defaultValue={currentParty?.id ?? ''}
                      onChange={(e) =>
                        setParty((e.target.value as string) ?? '')
                      }
                      options={allParties?.map((party) => ({
                        label: party.name,
                        value: party.id,
                      }))}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            container
            width='fit-content'
            gap='2rem'
            alignItems='center'>
            <div className={classes.keysContainer}>
              {bossKeys.map((bossKey, index) => {
                const loot = getLootItem(bossKey);
                const realm = getBossKeyRealm(bossKey);
                const slot = loot ? getLootSlot(loot) : undefined;
                const disabled = !hasRequiredKey(bossKey);

                const tip = disabled
                  ? `Not all party members possess ${loot.name} (click to buy)`
                  : `All party members possess ${loot.name}`;

                return (
                  <Tooltip key={index} title={tip}>
                    <div
                      className={classes.key}
                      data-disabled={disabled}
                      onClick={() => setBuyingKeys(realm)}>
                      {slot?.item.icon}
                      {!disabled && (
                        <div className={classes.keyCheckmark}>
                          <SvgIconCircleChecked
                            width='1rem'
                            height='1rem'
                            fill={theme.palette.primary.main}
                          />
                        </div>
                      )}
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </Grid>
        </Grid>

        <Grid item className={classes.formationWrapper}>
          <div className={classes.formationContainer}>
            <Grid
              container
              direction='column'
              position='relative'
              justifyContent='space-between'
              className={classes.formation}>
              <Grid item container flexGrow={1}>
                <FormationGrid />
              </Grid>

              <Grid
                item
                container
                justifyContent='center'
                alignItems='center'
                gap='1rem'>
                {allParties && (
                  <ButtonGroup>
                    {allFormations?.[partySize]?.map((formation, index) => (
                      <Button
                        key={index}
                        disabled={partySize === 5}
                        variant='outlined'
                        color='primary'
                        className={classes.formationButton}
                        onClick={() => setCurrentFormationId(index)}
                        data-selected={index === currentFormationId}>
                        <SvgIconDice
                          width='1.5rem'
                          height='1.5rem'
                          fill={index === currentFormationId ? black : white}
                          formation={formation}
                        />
                      </Button>
                    ))}
                  </ButtonGroup>
                )}
              </Grid>

              {!isNew && allParties && (
                <div className={classes.sizeControlWrapper}>
                  <ButtonGroup size='small' className={classes.sizeControl}>
                    {[PartySize.ONE, PartySize.THREE, PartySize.FIVE].map(
                      (size, index) => (
                        <Button
                          key={index}
                          variant='outlined'
                          className={clsx(
                            classes.formationButton,
                            classes.sizeButton,
                          )}
                          onClick={() => setPartySize(size)}
                          data-selected={partySize === size}>
                          <Typography
                            variant='h6'
                            fontWeight='600'
                            className={classes.sizeBtnText}>
                            {size}
                          </Typography>
                        </Button>
                      ),
                    )}
                  </ButtonGroup>
                </div>
              )}
            </Grid>

            <Grid
              container
              direction='column'
              justifyContent='center'
              gap='4rem'
              className={classes.infoBox}>
              <Grid item container direction='column' gap='3rem'>
                <Grid
                  item
                  container
                  alignItems='center'
                  gap='1rem'
                  flexWrap='nowrap'>
                  <div className={classes.infoIcon}>
                    <Typography variant='h4' color={white}>
                      1
                    </Typography>
                  </div>
                  <Typography variant='body2' lineHeight='130%' color={white}>
                    Select Furballs in the left sidebar
                  </Typography>
                </Grid>

                <Grid
                  item
                  container
                  alignItems='center'
                  gap='1rem'
                  flexWrap='nowrap'>
                  <div className={classes.infoIcon}>
                    <Typography variant='h4' color={white}>
                      2
                    </Typography>
                  </div>
                  <Typography variant='body2' lineHeight='130%' color={white}>
                    Use arrows under Furballs to change position
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction='column'
                justifyContent='stretch'
                gap='1rem'>
                {isNew && (
                  <Grid item container>
                    <TextField
                      fullWidth
                      variant='outlined'
                      placeholder='Enter party name'
                      color='primary'
                      value={partyName ?? ''}
                      onChange={(e) => setPartyName(e.target.value)}
                      className={classes.input}
                    />
                  </Grid>
                )}

                <Grid item container direction='column' gap='0.5rem'>
                  <Button
                    fullWidth
                    variant='contained'
                    className={classes.saveLoadButton}
                    onClick={() =>
                      hasChanges ? mutateParty() : history.push('/furverse')
                    }
                    data-disabled={
                      loading
                        ? true
                        : currentParty?.isInBattle && hasChanges
                        ? true
                        : !!partyErrors
                        ? true
                        : !isNew
                        ? partyMembers?.length < partySize
                        : partyMembers?.length < partySize ||
                          !partyName ||
                          partyName.length < 3
                    }>
                    <Typography variant='h4'>
                      {loading && !isDeleting ? (
                        <CircularProgress size={15} color='inherit' />
                      ) : isNew || hasChanges ? (
                        'Save'
                      ) : (
                        'Load'
                      )}
                    </Typography>
                  </Button>
                  {!!partyErrors ? (
                    <Typography
                      textAlign='center'
                      fontSize='0.9rem'
                      color={theme.palette.error.light}>
                      {partyErrors}
                    </Typography>
                  ) : (
                    currentParty?.isInBattle &&
                    hasChanges && (
                      <Grid container direction='column'>
                        <Typography
                          textAlign='center'
                          fontSize='0.9rem'
                          color={yellow}>
                          Party is currently in a fight, editing is disabled
                          till fight is over.{' '}
                        </Typography>

                        <span onClick={() => setShowForfeit(true)}>
                          <Typography
                            textAlign='center'
                            fontSize='0.9rem'
                            className={classes.forfeitCta}
                            color={theme.palette.warning.light}>
                            Forfeit Fight?
                          </Typography>
                        </span>

                        <OkDialog
                          title='Forfeit Fight'
                          open={showForfeit}
                          onClose={() => setShowForfeit(false)}>
                          <div className={classes.forfeitDialogContent}>
                            <Grid
                              container
                              direction='column'
                              gap='0.7rem'
                              alignItems='center'
                              justifyContent='center'>
                              <Typography variant='h3'>
                                Are You Sure?
                              </Typography>
                              <Typography textAlign='center'>
                                You're abount to forfeit the current bossFight
                                for <b>{currentParty.name}</b> party, this
                                cannot be undone, do you want to proceed with
                                this action?
                              </Typography>
                              <Button
                                variant='contained'
                                disabled={isForfeiting}
                                className={classes.forfeitDialogButton}
                                onClick={() => forfeitBattle()}>
                                {isForfeiting ? (
                                  <CircularProgress
                                    size='1.2rem'
                                    color='secondary'
                                  />
                                ) : (
                                  'Proceed'
                                )}
                              </Button>
                            </Grid>
                          </div>
                        </OkDialog>
                      </Grid>
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <BuyKeysDialog
        open={!!buyingKeys}
        onClose={() => setBuyingKeys(undefined)}
        furballs={furballs.filter((f) => !!f).map((f) => f as IFurball)}
        realms={buyingKeys ? [buyingKeys] : undefined}
      />
    </FullDrawer>
  );
};

export const Formation = () => {
  return <FormationPage />;
};
