import React, {
  FunctionComponent,
  ReactElement,
  useState,
  useEffect,
} from 'react';
import {
  Theme,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IFurball } from '../../wallet/WalletTypes';
import { usePlayersReadyFurballs } from '../../wallet';
import FurballImg from '../../components/Furballs/FurballImg';
import { Link, useHistory } from 'react-router-dom';

import { black, white } from '../../themev2';
import { OutboundLink } from '../../components/Navigation';
import theme, { cdnRoot, purple } from '../../theme';
import useCommonStyles from '../../components/useCommonStyles';
import SvgTimer from '../../assets/SvgTimer';
import { ScholarshipDetailsDialogue } from '../../components/Scholarships';
import UpgradeDialog from '../../components/Town/Upgrade/UpgradeDialog';
import AlertProvider from '../../components/Alert/AlertProvider';
import SvgUpgradeIcon from '../../assets/SvgIconUpgrade';
import { useWalletSelector } from '../../hooks';
import { useFurComponent } from '../../utils';
import SvgScroll from '../../assets/SvgScroll';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  mainCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '&:nth-child(1)': {
      borderTopRightRadius: '12px',
      borderTopLeftRadius: '12px',
    },

    '&:last-child': {
      borderBottomRightRadius: '12px',
      borderBottomLeftRadius: '12px',
    },
  },
  imgCont: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    minWidth: '265px',
    borderRight: `2px solid ${black}`,

    [theme.breakpoints.down('md')]: {
      minWidth: '200px',
    },
  },
  infoCont: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
      paddingRight: '20px',
    },

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingRight: '14px',
    },
  },
  rankCont: {
    minWidth: '135px',
    textAlign: 'left',
    marginLeft: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '0.5rem',
    marginRight: '1rem',
    flexGrow: 1,

    [theme.breakpoints.down('sm')]: {
      minWidth: '20px',
    },
  },
  rowHeader: {
    marginBottom: 0,
    color: black,
    fontSize: '1.05rem !important',
  },
  rowValue: {
    marginTop: '-6px !important',
    marginBottom: '-8px !important',
    color: theme.palette.secondary.light,
    fontSize: '1.85rem !important',
  },
  actionButton: {
    marginTop: theme.spacing(2),
  },
  actionButton2: {
    position: 'relative',
    top: 10,
  },
  expText: {},
  subtext: {
    fontSize: '1rem',
  },
  rankButton: {
    color: '#888888 !important',
    padding: '0px !important',
    margin: '0px !important',
  },
  furballButton: {
    padding: '0 !important',
    margin: '4px 0 !important',
    border: 'none !important',
  },
  placeholder: {
    textAlign: 'center',
    width: '100%',
    color: 'white !important',

    '& a': {
      color: `${purple.light} !important`,
      textDecoration: 'underline',
    },

    '& h5': {
      fontSize: '24px !important',
      marginTop: '40px',
      marginBottom: '2px',
      textTransform: 'capitalize',
    },
    '& h6': {
      fontSize: '24px !important',
    },
    [theme.breakpoints.down('md')]: {
      '& h5': {
        fontSize: '22px !important',
        marginTop: '50px',
      },
      '& p': {
        fontSize: '14px !important',
      },
    },
  },
  imageContent: {
    maxWidth: '380px',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      maxWidth: '300px',
    },
  },
  hideOnMobile: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  rentalIndicator: {
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '100%',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    // border: '1px solid black',
  },
  viewStatsBtn: {
    marginRight: '2rem !important',
    // marginLeft: 'auto !important',
    // minWidth: '100px !important',
    // maxHeight: '44px !important',
    // fontSize: '1rem !important',
    //
    // [theme.breakpoints.down('sm')]: {
    //   minWidth: '60px !important',
    // },
  },
}));

interface IMyFurballsList {
  furballs?: IFurball[];
  sortBy?: string;
  showStats?: boolean;
  onAccountPage?: boolean;
}

export const MyFurballsList: FunctionComponent<IMyFurballsList> = (props) => {
  const { onAccountPage } = props;
  const classes = useStyles({ onAccountPage });
  const history = useHistory();
  const { log } = useFurComponent(MyFurballsList);
  const common = useCommonStyles();
  const playersFurballs = usePlayersReadyFurballs();
  // const ranks: IFurballRank[] = rankResponse?.data?.ranks ?? [];
  const furballs = props.furballs ?? playersFurballs;
  const tokenIds = furballs.map((fb) => fb.tokenId);
  const tokenHash = tokenIds.join('_');
  const usableFurballsLoaded = useWalletSelector(
    (s) => s.usableFurballsLoaded ?? false,
  );

  const [startAt, setStartAt] = useState(0);
  const [loadingRanks, setLoadingRanks] = useState<string>();
  const [sortedFurballs, setSortedFurballs] = useState<IFurball[]>([]);
  const [showScholarshipDialog, setShowScholarshipDialog] = useState<
    string | undefined
  >();

  const maxRender = 20;
  const sortBy = props.sortBy ?? '1';
  const isAtEnd = startAt + maxRender >= furballs.length;
  const [showStatsTokenId, setShowStatsTokenId] = useState<
    string | undefined
  >();

  // useEffect(() => {
  //   if (tokenHash.length > 0 && loadingRanks !== tokenHash) {
  //     setLoadingRanks(tokenHash);
  //     loadRanks({ variables: { tokenIds } });
  //   }
  // }, [tokenHash]);

  useEffect(() => {
    const sorted = [...furballs].sort((a, b) => {
      if (sortBy === '1') {
        // Level: High to low
        return b.level - a.level;
      } else if (sortBy === '2') {
        // Level: Low to high
        return a.level - b.level;
      } else if (sortBy === '3') {
        // EXP/hr: High to low
        return b.state.expRate - a.state.expRate;
      } else if (sortBy === '4') {
        // EXP/hr: Low to high
        return a.state.expRate - b.state.expRate;
      } else if (sortBy === '5') {
        // FUR/hr: High to low
        return b.state.furRate - a.state.furRate;
      } else if (sortBy === '6') {
        // FUR/hr: Low to high
        return a.state.furRate - b.state.furRate;
      }

      // Default: number-based
      return a.number - b.number;
    });

    setSortedFurballs(sorted);
  }, [sortBy, tokenHash]);

  function renderPlaceholder() {
    return (
      <Grid item sm={12} className={classes.placeholder}>
        <Typography variant={'h5'} style={{ color: 'white' }}>
          {usableFurballsLoaded
            ? "You don't have any Furballs"
            : 'Your Furballs are loading...'}
        </Typography>
        <img
          src={`${cdnRoot}/images/jars-furballs.png`}
          className={classes.imageContent}
        />
        {!usableFurballsLoaded && <LinearProgress />}
        {usableFurballsLoaded && (
          <Typography variant={'h6'}>
            <OutboundLink
              to={'https://opensea.io/collection/furballs-com-official'}>
              Buy Furball
            </OutboundLink>
            {'   |   '}
            <Link to={'/market/furballs'}>Borrow Furballs</Link>
          </Typography>
        )}
      </Grid>
    );
  }

  function renderSortedFurballs() {
    if (!furballs.length) {
      return renderPlaceholder();
    }

    return sortedFurballs
      .slice(startAt, startAt + maxRender)
      .map(renderFurball);
  }

  function renderRank(text: ReactElement<any>, rankCategory: string) {
    return text;
  }

  function renderFurball(fb: IFurball, i: number): React.ReactNode {
    const isFirst = i === 0;
    const isLast = i === furballs.length - 1;
    const alt = i % 2 !== 0;
    // const rank = _.find(ranks, (r) => r.id === fb.id);

    return (
      <Box
        className={classes.mainCont}
        sx={{
          backgroundColor: alt ? white : '#f5f3ff',
          borderTopLeftRadius: isFirst ? '12px' : '0',
          borderTopRightRadius: isFirst ? '12px' : '0',
          borderBottomLeftRadius: isLast ? '12px' : '0',
          borderBottomRightRadius: isLast ? '12px' : '0',
        }}
        key={fb.tokenId}>
        <Box
          className={classes.imgCont}
          style={{
            backgroundColor: `#${fb.backgroundColor}`,
            borderTopLeftRadius: isFirst ? '12px' : '0',
            borderBottomLeftRadius: isLast ? '12px' : '0',
          }}>
          <Button
            variant={'contained'}
            className={classes.furballButton}
            style={{
              backgroundColor: 'transparent',
            }}
            onClick={() => history.push(`/furball/${fb.tokenId}`)}>
            <FurballImg tokenId={fb.tokenId} size={85} />
          </Button>
          <Box className={classes.rankCont}>
            <Typography
              variant={'h3'}
              sx={{
                fontSize: '2.45rem',
                marginRight: '5px',
              }}>
              #{fb.number}
            </Typography>

            {fb.isRented ? (
              <Tooltip title='Rented furball. View Details?'>
                <div
                  role='button'
                  className={classes.rentalIndicator}
                  onClick={() => setShowScholarshipDialog(fb.tokenId)}>
                  <SvgTimer
                    width='1.2rem'
                    height='1.2rem'
                    fill={theme.palette.grey[900]}
                  />
                </div>
              </Tooltip>
            ) : null}

            {(fb.isRented || fb.activeRentalAgreement) && (
              <ScholarshipDetailsDialogue
                tokenId={fb.tokenId}
                open={fb.tokenId === showScholarshipDialog}
                setOpen={() => setShowScholarshipDialog(undefined)}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.infoCont}>
          <Box className={classes.hideOnMobile}>
            <Typography variant={'h4'} className={classes.rowHeader}>
              <span>EXP</span>
              <span className={classes.subtext}>/hr</span>
            </Typography>
            <Typography variant={'h5'} className={classes.rowValue}>
              {fb.state.expRate.toLocaleString()}
            </Typography>
          </Box>
          <Box className={classes.hideOnMobile}>
            <Typography variant={'h4'} className={classes.rowHeader}>
              <span>$FUR</span>
              <span className={classes.subtext}>/hr</span>
            </Typography>
            <Typography variant={'h5'} className={classes.rowValue}>
              {fb.state.furRate.toLocaleString()}
            </Typography>
          </Box>
          <Box className={classes.hideOnMobile}>
            <Typography variant={'h4'} className={classes.rowHeader}>
              Level
            </Typography>
            <Typography variant={'h5'} className={classes.rowValue}>
              {fb.level.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        {props.showStats && (
          <Button
            className={clsx(
              classes.viewStatsBtn,
              fb.activeRentalAgreement
                ? common.secondaryHover
                : common.primaryHover,
            )}
            color={fb.activeRentalAgreement ? 'secondary' : 'primary'}
            variant={'contained'}
            onClick={() => setShowStatsTokenId(fb.tokenId)}>
            {fb.activeRentalAgreement || fb.openRentalAgreement ? (
              <SvgScroll style={{ width: 32 }} />
            ) : (
              <SvgUpgradeIcon style={{ width: 32 }} />
            )}
          </Button>
        )}
      </Box>
    );
  }

  function renderPageButton(title: string, dir: number): React.ReactNode {
    return (
      <Grid
        item
        xs={12}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
          borderTop: dir === 1 ? `2px solid ${black}` : 'unset',
          borderBottom: dir === -1 ? `2px solid ${black}` : 'unset',
        }}>
        <Button
          variant={'contained'}
          className={common.actionButton}
          onClick={() =>
            setStartAt(
              Math.max(
                0,
                Math.min(
                  startAt + dir * maxRender,
                  furballs.length - maxRender,
                ),
              ),
            )
          }>
          {title}
        </Button>
      </Grid>
    );
  }

  return (
    <>
      <Grid container>
        {startAt > 0 && renderPageButton('Previous Page', -1)}
        <AlertProvider>{renderSortedFurballs()}</AlertProvider>
        {!isAtEnd && renderPageButton('Next Page', 1)}
      </Grid>
      <UpgradeDialog
        show={!!showStatsTokenId}
        tokenId={showStatsTokenId}
        onClose={() => setShowStatsTokenId(undefined)}
        onAction={() => setShowStatsTokenId(undefined)}
        setTokenId={setShowStatsTokenId}
        tokenIds={tokenIds}
      />
    </>
  );
};

export default MyFurballsList;
