import questDefinitions from './quests.json';
import { QuestDefinitionFragment } from '../schema';

/*
fragment LeaderboardTierRewardChance on LeaderboardTierRewardChance {
  rewardId
  rewardType
  rewardQuantity
  percentChance
}

fragment QuestWaypointBracketDefinition on QuestWaypointBracketDefinition {
  percentileRank
  furAmount
  possibleRewards { ...LeaderboardTierRewardChance }
}

fragment QuestWaypointDefinition on QuestWaypointDefinition {
  name
  style
  brackets { ...QuestWaypointBracketDefinition }
}

fragment QuestDefinition on QuestDefinition {
  name
  respawn
  maxCompletions
  waypoints { ...QuestWaypointDefinition }
}

query {
  questDefinitions { ...QuestDefinition }
}
 */
export const questLibrary = questDefinitions.data
  .questDefinitions as QuestDefinitionFragment[];
