import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CircularProgress,
  Fade,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  EntitySkill,
  EquipmentSlot,
  InventoryDocument,
  ItemDefinitionFragment,
  ItemGroup,
  ItemsFragment,
  Specialization,
  StatEntity,
  useEquipFurballMutation,
} from '../../schema';
import { isMobile } from 'react-device-detect';
import {
  IEquipment,
  IFurball,
  IInventory,
  IInventoryQuantities,
} from '../../../wallet/WalletTypes';
import BattleStats from '../../Furballs/BattleStats';
import FurballRenderer from '../../Furballs/FurballRenderer';
import { cdnRoot, purple } from '../../../theme';
import clsx from 'clsx';
import { getItemDef } from '../../BossFights/BossRewards/bossRewardsUtils';
import WalletSlice from '../../../wallet/WalletSlice';
import { useAppDispatch, useWalletSelector } from '../../../hooks';
import EquipmentTooltip from './EquipmentTooltip';
import { ALERT_TYPES, AlertContext } from '../../Alert/AlertContext';
import InventorySelectItem from '../../Inventory/InventorySelectItem';
import { Skills } from '../../../pages/Skills';
import UpgradeFooter from './UpgradeFooter';
import { getSkillDescription } from '../../Furballs/Skills';
import FurballCardStats from '../../Furballs/FurballCardStats';
import { MarketplaceContractDetails } from '../../../pages/Marketplace/Rent/RentalContractDetails';
import FurballHearts from '../../Furballs/FurballHearts';
import FurballBossKeys from '../../../pages/Furverse/FurballBossKeys';
import { useApolloClient } from '@apollo/client';
import { usePlayersReadyFurballs } from '../../../wallet';
import { getInventoryQuantities } from '../../Furballs';
import { useFurComponent } from '../../../utils/furComponent';
import { UpgradeMode } from './UpgradeDialog';
import SpecializeFurball from './SpecializeFurball';
import useCommonStyles from '../../useCommonStyles';
import { getSkillDefinitionById } from '../../../pages/Skills/SkillLibrary';
import { useScholarshipHelpers } from '../../Scholarships';
import { ScholarshipPlacement } from '../../Scholarships/ScholarshipPlacement';
import ScholarshipEditor from '../../Scholarships/ScholarshipEditor';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '1.2rem auto',
    position: 'relative',
    minHeight: '509px',
    minWidth: '100%',
    // color: '#A5B4FC',

    '& h3': {
      color: 'white !important',
      fontSize: '2.2rem !important',
      marginBottom: '1rem',
      textAlign: 'center !important',
      userSelect: 'none',
    },
  },
  equipmentSlots: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '15px',

    [theme.breakpoints.down('md')]: {
      maxWidth: '300px !important',
      margin: '0 auto',
    },
  },
  slot: {
    width: '70px',
    minWidth: '70px',
    height: '70px',
    minHeight: '70px',
    borderRadius: '8px',
    padding: '6px',
    display: 'flex',
    color: '#A5B4FC',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.dark,
    fontSize: '1.65rem',
    cursor: 'pointer',
    userSelect: 'none',
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
    position: 'relative',

    '& img': {
      height: '70px',
      width: '70px',
    },
  },
  statsCont: {
    display: 'flex',
    justifyContent: 'center',
    width: '300px',
    flexDirection: 'column',
    userSelect: 'none',

    [theme.breakpoints.down('md')]: {
      margin: '1rem auto',
    },
  },
  furball: {
    position: 'relative',
    borderRadius: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem 1rem',
    marginBottom: '1.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    background: '#ffffff',

    width: '100% !important',
    maxWidth: '100% !important',

    [theme.breakpoints.down('md')]: {
      // maxWidth: '250px !important',
      // width: '250px !important',
      margin: '0 auto',
      marginBottom: '1.5rem',
    },

    [theme.breakpoints.down('sm')]: {
      // width: '250px !important',
      // maxWidth: '250px !important',
    },
  },
  furballFigure: {
    position: 'relative',
    bottom: 20,
  },
  skillSlot: {
    paddingTop: '23.5px',
    border: `2px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.light,
  },
  equippedSlot: {
    border: `2px solid ${theme.palette.secondary.light}`,
    backgroundColor: theme.palette.secondary.main,

    '& img': {
      height: '50px',
      width: '50px',
      marginTop: '10px',
    },
  },
  disabledSlot: {
    cursor: 'default',
  },
  svgIcon: {
    '& svg': {
      height: '65px',
      width: '65px',
      marginTop: '10px',
    },
  },
  clearChangesBtn: {
    borderRadius: '6px !important',
    fontSize: '14px !important',
    marginTop: '10px !important',
    // marginBottom: '-35px !important',
    width: '145px !important',
    backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
    border: '1px solid rgba(0, 0, 0, 0.2) !important',

    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
      border: '1px solid rgba(0, 0, 0, 0.2) !important',
      color: '#AFFFB2 !important',
      opacity: 0.5,
      transition: '0.3s all linear',
    },

    [theme.breakpoints.down('md')]: {
      marginBottom: '5px !important',
      marginTop: '0px !important',
    },
  },
  listForRentBtn: {
    color: `${purple.light} !important`,
  },
  stats: {
    [theme.breakpoints.down('md')]: {
      minWidth: '370px !important',
      paddingLeft: '50px',
    },
  },
  midCont: {
    minHeight: '509px',
    textAlign: 'center',

    '& h3': {
      fontSize: '35px !important',
    },

    [theme.breakpoints.down('md')]: {
      minHeight: '480px',
    },
  },
  gridCont: {
    justifyContent: 'center',
    padding: '0px 20px 0px 20px',

    [theme.breakpoints.down('md')]: {
      //flexDirection: 'column-reverse !important',
    },
  },
  upgradeButtonCont: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '15px',
    minWidth: '300px !important',
    margin: '0 auto !important',

    '& span': {
      color: '#A5B4FC',
      fontSize: '0.725rem',
      opacity: 0.9,
      fontWeight: 400,
      marginTop: '10px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '300px !important',
    },
  },
  notOwnerWarning: {
    color: 'red',
    textAlign: 'center',
    maxHeight: '80px',
    userSelect: 'none',
    maxWidth: '380px !important',
    margin: '0 auto !important',

    '& h6': {
      marginTop: '-2px !important',
      marginBottom: '2px !important',
    },
  },
  cardBottom: {
    height: '130px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  buttonCont: {
    '& button': {
      width: '100% !important',
      maxWidth: '420px',
      borderRadius: '6px !important',
      height: '50px !important',
      fontSize: '14px !important',

      '&:disabled': {
        backgroundColor: '#eee !important',
      },
    },
  },
  rentalCont: {
    marginTop: '-10px !important',
    maxWidth: '420px',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '340px !important',
      marginTop: '0px !important',
      paddingBottom: '1.5rem',
    },
  },
  fbStats: {
    border: 'none',
    bottom: '-3px',
  },
}));

const NUM_EQUIPMENT_SLOTS = 9;

const EQUIPMENT_SLOTS: ISlotsOrder = {
  left: [EquipmentSlot.Head, EquipmentSlot.Body, EquipmentSlot.Weapon],
  right: [
    EquipmentSlot.OffHand,
    EquipmentSlot.OffHand,
    EquipmentSlot.OffHand,
    EquipmentSlot.Ring,
    EquipmentSlot.Ring,
    EquipmentSlot.Ring,
  ],
};

interface ISlotsOrder {
  left: EquipmentSlot[];
  right: EquipmentSlot[];
}

interface IUpgradeCard {
  furball: IFurball;
  upgradeMode: UpgradeMode;
  setUpgradeMode: (state: UpgradeMode) => void;
  tokenIds: string[];
  setTokenId?: (tokenId: string | undefined) => void;
  onAction: () => void;
  canUpgrade: boolean;
  rentFurball?: boolean;
  close?: () => void;
}

interface IPlayerWithInventory {
  id: string;
  inventory: IInventoryQuantities;
}

export const UpgradeCard: FunctionComponent<IUpgradeCard> = (props) => {
  const {
    furball,
    upgradeMode,
    setUpgradeMode,
    tokenIds,
    setTokenId,
    onAction,
    canUpgrade,
    rentFurball,
    close,
  } = props;
  const { tokenId, equipment } = furball;
  const common = useCommonStyles();
  const { skills: furballSkills, ...stats } = furball.battleStats as StatEntity;

  const classes = useStyles();
  const client = useApolloClient();
  const dispatch = useAppDispatch();
  const { addAlert } = useContext(AlertContext);
  const { log } = useFurComponent(UpgradeCard);
  // const size = useWindowSize();
  // const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  // const isSmall = size.width < 600;

  const isRentedOut = !!furball.activeRentalAgreement;
  const inventory = useWalletSelector(({ contents }) => contents?.inventory);
  const playerId = useWalletSelector(({ contents }) => contents?.id ?? '');
  const playerFurballs = usePlayersReadyFurballs();

  const viewingFb =
    !isRentedOut && !playerFurballs.find(({ id }) => id === tokenId);
  const isOwner = !isRentedOut
    ? !!playerFurballs.find(({ id }) => id === tokenId)
    : furball.activeRentalAgreement?.ownerPlayer.id === playerId;
  const { agreement } = useScholarshipHelpers(log, furball.tokenId);

  const furballInventory: IInventory = furball.inventory;
  const equipmentInFbInventory = isOwner
    ? furballInventory.items.filter((item) =>
        getItemDef(item.itemId)?.itemGroup.includes('EQUIPMENT'),
      )
    : [];

  const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);
  const [skills, setSkills] = useState<EntitySkill[]>([]);
  const [modifiedEquipment, setModifiedEquipment] = useState<
    (IEquipment | null)[]
  >(initEquipmentSlots(equipment));
  const [selectedSlotIndex, setSelectedSlotIndex] = useState<number | null>(
    null,
  );

  const [equipFurball, { loading }] = useEquipFurballMutation();
  const readOnly = !canUpgrade || viewingFb || loading;
  const roTip = readOnly
    ? rentFurball
      ? 'Once rented, you will be able to upgrade this Furball.'
      : isOwner
      ? 'This Furball cannot currently be upgraded.'
      : ''
    : '';

  useEffect(() => {
    setSkills(furballSkills);
    setModifiedEquipment(initEquipmentSlots(equipment));
    // setUpgradeMode(furball.activeRentalAgreement ? UpgradeMode.Rental : UpgradeMode.None);
  }, [tokenId]);

  function initEquipmentSlots(updatedEquipment: IEquipment[]) {
    const slots = new Array(NUM_EQUIPMENT_SLOTS).fill(null);
    updatedEquipment.forEach(
      (equipment) => (slots[equipment.position] = equipment),
    );
    return slots;
  }

  async function clearChanges() {
    return await runEquipFurball(new Array(NUM_EQUIPMENT_SLOTS).fill(null));
  }

  async function selectEquipmentItem(item: ItemsFragment | null) {
    setUpgradeMode(UpgradeMode.None);

    const updatedEquipmentIds = [...modifiedEquipment].map(
      (equipment) => equipment?.id ?? null,
    );
    updatedEquipmentIds[selectedSlotIndex as number] = item ? item.id : null;
    await runEquipFurball(updatedEquipmentIds);
  }

  async function runEquipFurball(itemIds: (string | null)[]) {
    try {
      const { data } = await equipFurball({
        variables: {
          furballId: tokenId,
          itemIds,
        },
      });

      if (data) {
        const updatedFb = data.equipFurball;
        const { equipment, player, activeRentalAgreement } = updatedFb;

        const updatedInventory: IPlayerWithInventory | undefined =
          player.id === playerId
            ? player
            : activeRentalAgreement?.player?.id === playerId
            ? activeRentalAgreement.player
            : undefined;

        if (updatedInventory) {
          dispatch(
            WalletSlice.actions.setInventory({
              id: inventory!.id,
              totalDustCount: inventory!.totalDustCount,
              itemQuantities: updatedInventory.inventory.itemQuantities,
            }),
          );
        } else {
          log.warn('Inventory missing for', playerId, 'in', updatedFb);
        }

        dispatch(
          WalletSlice.actions.setFurball({
            ...updatedFb,
            inventory: getInventoryQuantities(updatedFb.inventory),
          }),
        );
        setModifiedEquipment(initEquipmentSlots(equipment));
        setSelectedSlotIndex(null);

        client.refetchQueries({ include: [InventoryDocument] });
      }
    } catch (error: any) {
      log.error(error);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error?.message ?? 'Unable to update equipment',
      });
    }
  }

  function getItemGroupForSelectedSlot() {
    const { left, right } = EQUIPMENT_SLOTS;
    const slot = [...left, ...right][selectedSlotIndex as number];

    if (slot === EquipmentSlot.Head) return ItemGroup.EquipmentHead;
    if (slot === EquipmentSlot.Body) return ItemGroup.EquipmentBody;
    if (slot === EquipmentSlot.Weapon) return ItemGroup.EquipmentWeapon;
    if (slot === EquipmentSlot.OffHand) return ItemGroup.EquipmentOffHand;

    return ItemGroup.EquipmentRing;
  }

  function getModifiedEquipmentIds() {
    return modifiedEquipment
      .filter((equipment) => !!equipment)
      .map((equipment) => equipment!.id);
  }

  function getItemIcon(itemDef: ItemDefinitionFragment) {
    return (
      <img src={`${cdnRoot}/images/icons/boss_loot/${itemDef.icon}.png`} />
    );
  }

  function selectSlot(slotIndex: number) {
    if (readOnly) return;

    setUpgradeMode(UpgradeMode.Equipment);
    setSelectedSlotIndex(slotIndex);
  }

  function renderMainContent() {
    if (rentFurball) {
      return (
        <Grid
          item
          xs={12}
          md={12}
          lg={12}
          className={clsx(classes.buttonCont, classes.rentalCont)}>
          <MarketplaceContractDetails
            setOpen={() => !!close && close()}
            furball={furball}
            textColor={'#A5B4FC'}
          />
        </Grid>
      );
    }

    return (
      <Grid item xs={12} md={12} lg={12}>
        <div className={classes.cardBottom}>
          {!isOwner &&
            isRentedOut &&
            renderWarning(
              'You are not the owner of this Furball!',
              'Equipment you add will be returned when the contract ends. Skills you upgrade will stay with the Furball (non-refundable).',
            )}
          {isOwner && (
            <div
              className={clsx(classes.upgradeButtonCont, classes.buttonCont)}>
              {/*<UpgradeButton furball={furball} onUpgrade={onAction} />*/}
              <Button
                color={'primary'}
                className={common.actionButton}
                onClick={() => setUpgradeMode(UpgradeMode.Specialization)}
                disabled={!isOwner}>
                {furball.specialization === Specialization.None
                  ? 'Specialize Furball'
                  : 'Re-Specialize Furball'}
              </Button>
              {furball.specialization !== Specialization.None && (
                <Typography variant={'subtitle2'} color={'white'}>
                  Current Specialization: {furball.specialization}
                </Typography>
              )}
            </div>
          )}
          {!!tokenIds && !!setTokenId && (
            <UpgradeFooter
              tokenIds={tokenIds}
              setTokenId={setTokenId}
              selectedTokenId={tokenId}
            />
          )}
        </div>
      </Grid>
    );
  }

  function renderSlots(renderLeft?: boolean) {
    const { left, right } = EQUIPMENT_SLOTS;
    return (renderLeft ? left : right).map((slot, i) => {
      const slotIndex = renderLeft ? i : i + left.length;
      const slotInitial = slot.slice(0, 1).toUpperCase();
      const slotName =
        slotInitial + slot.slice(1).toLowerCase().replace('_', '-');

      const equipmentInSlot = modifiedEquipment[slotIndex];
      const equippingItem = slotIndex === selectedSlotIndex && loading;

      const showTooltip =
        tooltipIndex === slotIndex && equipmentInSlot && !isMobile;

      const itemDef = equipmentInSlot
        ? getItemDef(equipmentInSlot.itemId)
        : undefined;

      const canAddEquipment = (equipmentInSlot && isOwner) || !equipmentInSlot;

      return (
        <div
          key={i}
          className={clsx(classes.slot, {
            [classes.disabledSlot]: readOnly || !canAddEquipment,
            [classes.equippedSlot]: equipmentInSlot,
          })}
          onMouseEnter={(_) => setTooltipIndex(slotIndex)}
          onMouseLeave={(_) => setTooltipIndex(null)}
          onClick={() => canAddEquipment && selectSlot(slotIndex)}>
          {equippingItem ? (
            <CircularProgress
              sx={{
                color: equipmentInSlot ? 'rgba(255, 255, 255, 0.5)' : 'inherit',
              }}
              size={30}
            />
          ) : equipmentInSlot && itemDef ? (
            <div>{getItemIcon(itemDef)}</div>
          ) : (
            <Tooltip key={i} title={`${slotName} (Empty). ${roTip}`}>
              <img
                style={{
                  position: 'relative',
                  top: '0.5rem',
                  transform: 'scale(0.9)',
                }}
                src={`${cdnRoot}/svgs/${slotName.toLowerCase()}.svg`}
              />
            </Tooltip>
          )}
          {showTooltip && <EquipmentTooltip equipment={equipmentInSlot} />}
        </div>
      );
    });
  }

  function renderSkills() {
    const hasRolledSkill = skills.length > 2;
    const skillsToRender = skills.map((skill, i) => {
      const def = getSkillDefinitionById(skill.skillDefinitionId);
      return (
        <Tooltip
          key={`skill-${skill.id}`}
          title={`${getSkillDescription(skill)}. ${roTip}`}>
          <div
            key={`skill-${skill.id}`}
            className={clsx(classes.slot, classes.skillSlot, {
              [classes.disabledSlot]: readOnly,
            })}
            onClick={() => {
              if (canUpgrade) {
                setSelectedSlotIndex(i);
                setUpgradeMode(UpgradeMode.Skills);
              }
            }}>
            <img src={`${cdnRoot}/images/icons/skills/${def?.icon}.png`} />
          </div>
        </Tooltip>
      );
    });

    if (!hasRolledSkill) {
      skillsToRender.push(
        <div
          key={'free-skill-slot'}
          className={clsx(classes.slot, classes.skillSlot, {
            [classes.disabledSlot]: readOnly,
          })}
          style={{
            paddingTop: '6px',
          }}
          onClick={() => {
            if (canUpgrade) {
              setSelectedSlotIndex(2);
              setUpgradeMode(UpgradeMode.Skills);
            }
          }}>
          ???
        </div>,
      );
    }

    return skillsToRender;
  }

  function renderPanel() {
    const bkStyle = { background: furball.backgroundColor };
    return (
      <>
        <Grid container spacing={3} className={classes.gridCont}>
          <Grid item xs={2}>
            <div className={classes.equipmentSlots}>
              {renderSlots(true)}
              {/*{isSmall && renderSlots(false)}*/}
              {renderSkills()}
            </div>
          </Grid>
          <Grid item xs={8} className={classes.midCont}>
            <div className={classes.furball} style={bkStyle}>
              <FurballRenderer
                tokenId={tokenId}
                size={150}
                className={classes.furballFigure}
              />
              <FurballCardStats
                furball={furball}
                className={classes.fbStats}
                bottomRadius={12}
              />
              <div style={{ position: 'absolute', top: 4, left: 8 }}>
                <FurballHearts furball={furball} />
              </div>
              <div style={{ position: 'absolute', top: 4, right: 8 }}>
                <FurballBossKeys
                  furball={{
                    ...furball,
                    inventory: getInventoryQuantities(furball.inventory),
                  }}
                />
              </div>
            </div>
            <div className={classes.statsCont}>
              <BattleStats
                tokenId={tokenId}
                iconSize={26}
                textSize={16}
                rowGap={10}
                colGap={5}
                offset={4}
                className={classes.stats}
                equipment={modifiedEquipment}
                battleStats={stats}
              />
            </div>
            {isOwner && (
              <Button
                className={clsx(classes.clearChangesBtn, {
                  [classes.listForRentBtn]: !agreement,
                })}
                color={agreement ? 'primary' : 'secondary'}
                onClick={() => setUpgradeMode(UpgradeMode.Rental)}>
                {agreement
                  ? !agreement.endedAt && agreement.beganAt
                    ? 'View Active Contract'
                    : 'Edit Furball Listing'
                  : 'List for Rent'}
              </Button>
            )}
            {getModifiedEquipmentIds().length > 0 && !readOnly && isOwner && (
              <Fade in={true}>
                <Button
                  className={classes.clearChangesBtn}
                  onClick={clearChanges}
                  disabled={loading}>
                  Clear Equipment
                </Button>
              </Fade>
            )}
          </Grid>
          <Grid item xs={2}>
            <div className={classes.equipmentSlots}>{renderSlots()}</div>
          </Grid>
          {renderMainContent()}
        </Grid>
      </>
    );
  }

  function renderSkillshop() {
    return (
      <Skills
        tokenId={tokenId}
        skills={skills}
        specialization={furball.specialization}
        // equipment={furball.equipment}
        isRentedFurball={!!furball.isRented}
        setSkills={(skills) => setSkills(skills)}
        selectedSkillIndex={selectedSlotIndex}
      />
    );
  }

  function renderEquipmentSelector() {
    if (selectedSlotIndex === null || inventory == null) return null;
    const item = modifiedEquipment[selectedSlotIndex];

    return (
      <InventorySelectItem
        containerId={inventory.id}
        itemQuantities={inventory.itemQuantities}
        extraItems={equipmentInFbInventory}
        onSelectItem={selectEquipmentItem}
        onClose={() => setSelectedSlotIndex(null)}
        itemGroup={getItemGroupForSelectedSlot()}
        showRemoveCurrentItem={
          !!item && (isOwner || item.equippedById === playerId)
        }
        excludedItemIds={getModifiedEquipmentIds()}
        smallItems={true}
      />
    );
  }

  function renderWarning(title: string, body: string) {
    return (
      <Grid item xs={12} className={classes.notOwnerWarning}>
        <Typography variant={'h6'} fontSize={'18px'}>
          {title}
        </Typography>
        <Typography variant={'subtitle2'} fontSize={'12px'}>
          {body}
        </Typography>
      </Grid>
    );
  }

  function renderContent() {
    if (upgradeMode === UpgradeMode.Skills) return renderSkillshop();
    if (upgradeMode === UpgradeMode.Equipment) return renderEquipmentSelector();
    if (upgradeMode === UpgradeMode.Specialization && isOwner)
      return (
        <SpecializeFurball
          furball={furball}
          onSpecialized={() => setUpgradeMode(UpgradeMode.None)}
        />
      );
    if (upgradeMode === UpgradeMode.Rental)
      return agreement ? (
        <ScholarshipEditor tokenId={furball.id} />
      ) : (
        <ScholarshipPlacement furball={furball} />
      );
    return renderPanel();
  }

  return (
    <div
      className={classes.root}
      style={{
        margin:
          upgradeMode === UpgradeMode.Equipment ? '0 auto' : '1.2rem auto',
      }}>
      {renderContent()}
    </div>
  );
};

export default UpgradeCard;
