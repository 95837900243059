import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Toupee = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M210.88 95.09c-.28-.01-.55 0-.81.03-.62-3.13-3.57-5.29-6.78-4.87-1.3.17-2.45.73-3.35 1.56a6.12 6.12 0 0 0-6.73-4.61 6.12 6.12 0 0 0-5.33 6.41 6.123 6.123 0 0 0-7.5 6.73c.43 3.35 3.5 5.72 6.85 5.29.43 3.35 3.5 5.72 6.85 5.29 2.32-.3 4.17-1.86 4.94-3.9a6.115 6.115 0 0 0 5.77 2.52 6.122 6.122 0 0 0 5.26-5.13c.12.01.23.03.35.03 2.58.13 4.78-1.86 4.9-4.44a4.648 4.648 0 0 0-4.42-4.91z'
    />
    <path
      fill='#FFF'
      d='M210.88 95.09c-.28-.01-.55 0-.81.03-.62-3.13-3.57-5.29-6.78-4.87-1.3.17-2.45.73-3.35 1.56a6.12 6.12 0 0 0-6.73-4.61c-3 .39-5.21 2.89-5.33 5.81a6.05 6.05 0 0 1 3.41-1.61c2.19-.28 4.25.63 5.54 2.22a3.283 3.283 0 0 0 3.5 1.06c2.6-.81 7.19 1.52 7.81 4.65 2.64-.71 5.05 1.94 5.24 4.23 1.11-.8 1.86-2.09 1.93-3.56a4.663 4.663 0 0 0-4.43-4.91z'
      opacity={0.3}
    />
    <path
      opacity={0.2}
      d='M208.62 102.55c-.78-.81-2.1-.81-2.84.03a4.914 4.914 0 0 1-3.69 1.67c-1.88 0-3.51-1.05-4.34-2.6a4.919 4.919 0 0 1-4.33 2.61c-2.72 0-4.92-2.19-4.92-4.91-2.72 0-4.92-2.19-4.92-4.91 0-.1 0-.2.01-.3-2.18 1.16-3.53 3.59-3.19 6.18.43 3.35 3.5 5.72 6.85 5.29.43 3.35 3.5 5.72 6.85 5.29 2.32-.3 4.17-1.86 4.94-3.9a6.115 6.115 0 0 0 5.77 2.52 6.122 6.122 0 0 0 5.26-5.13c.12.01.23.03.35.03 0 .02-.9-.93-1.8-1.87z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M210.88 95.09c-.28-.01-.55 0-.81.03-.62-3.13-3.57-5.29-6.78-4.87-.59.08-1.15.23-1.67.46-.9.39-1.94.07-2.47-.75a6.094 6.094 0 0 0-5.93-2.76 6.11 6.11 0 0 0-5.1 4.39c-.29 1.02-1.15 1.78-2.2 1.88-.2.02-.41.05-.62.09-2.99.58-5.12 3.31-4.94 6.35.17 2.97 2.4 5.26 5.15 5.7.99.16 1.82.84 2.23 1.76 1.08 2.39 3.63 3.91 6.36 3.55 1.54-.2 2.88-.96 3.83-2.05.67-.77 1.81-.92 2.66-.34 1.19.81 2.68 1.21 4.22 1.01a6.124 6.124 0 0 0 5.2-4.78.4.4 0 0 1 .41-.31s4.78-1.86 4.91-4.44c.12-2.6-1.87-4.79-4.45-4.92z'
    />
  </svg>
);

export default Toupee;
