import { IZone, IZoneRealmNumber } from './ZoneTypes';
import { RealmType, TimekeeperInteractionType } from '../schema';

import {
  realmBoneyardColor,
  realmTurtleSeaColor,
  realmWizardsPathColor,
} from '../../theme';
import {
  BossKeyDefinition,
  LOOT_ID_KEY_BONEYARD,
  LOOT_ID_KEY_TURTLE_SEA,
  LOOT_ID_KEY_WIZARDS_PATH,
} from '../Loot';
import { ITimekeeperUserState } from '../Timekeeper/TimekeeperSlice';
import { getAllTimekeeperInteractions } from '../Timekeeper/TimekeeperHooks';
import { IFurball } from '../../wallet/WalletTypes';

export const ZONE_NUM_EXPLORE = 0;
export const ZONE_NUM_TOWN = 1;
export const ZONE_NUM_BATTLE = 0x10000;
export const ZONE_NUM_VOYAGE = 0x10001;
export const ZONE_NUM_BOSS_FIGHTS = 0x10002;
export const ZONE_NUM_SCHOLARSHIPS = 0x10003;

export function getZoneNumbers(): number[] {
  return [
    ZONE_NUM_EXPLORE,
    ZONE_NUM_BATTLE,
    ZONE_NUM_VOYAGE,
    ZONE_NUM_BOSS_FIGHTS,
    ZONE_NUM_SCHOLARSHIPS,
    ZONE_NUM_TOWN,
  ];
}

export function getZoneName(zoneNumber: number): string {
  if (zoneNumber === ZONE_NUM_EXPLORE) return 'Explore';
  if (zoneNumber === ZONE_NUM_BATTLE) return 'Battle';
  if (zoneNumber === ZONE_NUM_VOYAGE) return 'Voyage';
  if (zoneNumber === ZONE_NUM_BOSS_FIGHTS) return 'Boss Fights';
  if (zoneNumber === ZONE_NUM_SCHOLARSHIPS) return 'Scholarships';
  if (zoneNumber === ZONE_NUM_TOWN) return 'Furburbia';
  return '?';
}

export function getFurballDisplayZone(
  furball: IFurball,
  playerId: string,
): number {
  const zone = furball.zone;
  if (furball.activeRentalAgreement) {
    if (furball.activeRentalAgreement.ownerPlayer.id === playerId)
      return ZONE_NUM_SCHOLARSHIPS;
    if (furball.activeRentalAgreement.renterPlayer?.id === playerId)
      return ZONE_NUM_BOSS_FIGHTS;
  }
  return zone;
}

export function getFormattedZoneName(zoneNumber: number): string {
  const zoneName = getZoneName(zoneNumber);
  return zoneName.toLowerCase().replace(' ', '_');
}

export function getZone(zoneNumber: number): IZone {
  const isBattle = zoneNumber === ZONE_NUM_BATTLE;
  return { zoneNumber, isBattle, title: getZoneName(zoneNumber) };
}

export function getRealmName(realm: RealmType): string {
  if (realm === RealmType.Boneyard) return 'Boneyard';
  if (realm === RealmType.WizardsPath) return "Wizard's Path";
  if (realm === RealmType.TurtleSea) return 'Turtle Sea';
  return '???';
}

export function useActiveVoyageRealms(): RealmType[] {
  // const context = React.useContext(WalletContext);
  return allRealms; // context?.network.id === 1 ? [] : allRealms;
}

export interface IRealmColor {
  light: string;
  dark: string;
}

export function getRealmColor(realmType: RealmType): IRealmColor {
  if (realmType === RealmType.Boneyard) return realmBoneyardColor;
  if (realmType === RealmType.WizardsPath) return realmWizardsPathColor;
  if (realmType === RealmType.TurtleSea) return realmTurtleSeaColor;

  return realmTurtleSeaColor;
}

export function getRealmKeyLootId(realmType: RealmType): number {
  if (realmType === RealmType.Boneyard) return LOOT_ID_KEY_BONEYARD;
  if (realmType === RealmType.WizardsPath) return LOOT_ID_KEY_WIZARDS_PATH;
  if (realmType === RealmType.TurtleSea) return LOOT_ID_KEY_TURTLE_SEA;
  return LOOT_ID_KEY_TURTLE_SEA;
}

export function getRealmKey(realmType: RealmType): BossKeyDefinition {
  if (realmType === RealmType.Boneyard) return BossKeyDefinition.BONE_YARD;
  if (realmType === RealmType.WizardsPath)
    return BossKeyDefinition.WIZARDS_PATH;
  if (realmType === RealmType.TurtleSea) return BossKeyDefinition.TURTLE_SEA;
  return BossKeyDefinition.TURTLE_SEA;
}

export function getFurballDestinationZoneRealm(
  tkState: ITimekeeperUserState,
  tokenId: string,
): IZoneRealmNumber | undefined {
  const cur = tkState.current;
  const interactions = getAllTimekeeperInteractions(tkState.current);
  if (!cur || interactions.length <= 0) return undefined;
  for (let i = 0; i < interactions.length; i++) {
    if (!interactions[i].furballIds.includes(tokenId)) continue;
    if (
      interactions[i].interactionType === TimekeeperInteractionType.CollectMove
    ) {
      return { zoneNumber: interactions[i].expectedValue ?? undefined };
    }
    if (
      interactions[i].interactionType === TimekeeperInteractionType.VoyageBegin
    ) {
      return {
        realmIndex: interactions[i].expectedValue
          ? (interactions[i].expectedValue ?? 0) - 1
          : undefined,
      };
    }
  }
}

export const allRealms = [
  RealmType.TurtleSea,
  RealmType.WizardsPath,
  RealmType.Boneyard,
];

export function getRealmSlug(realm: RealmType): string {
  return realm.toString().replace('_', '-').toLowerCase();
}

export function getRealmFromSlug(realm: string): RealmType | undefined {
  return allRealms.find((r) => getRealmSlug(r) === realm);
}

export function isPassiveEarnZone(zone: number): boolean {
  return [ZONE_NUM_EXPLORE, ZONE_NUM_BATTLE, ZONE_NUM_VOYAGE].includes(zone);
}

export function canUpgradeInZone(zone: number): boolean {
  return !isPassiveEarnZone(zone);
}
