import { useCallback, useRef } from 'react';

const useScrollToPlayerPlacingHook = () => {
  const playerPlacingRef = useRef<HTMLDivElement | null>(null);

  const setPlayerPlacingRef = useCallback(
    (playerPlacingNode: HTMLDivElement) => {
      const leaderboardNode = getLeaderboardNode(playerPlacingNode);
      if (!!leaderboardNode) {
        const placingTop = playerPlacingNode.offsetTop;
        const placingBottom = placingTop + playerPlacingNode.clientHeight;
        const leaderboardTop = leaderboardNode.scrollTop;
        const leaderboardBottom = leaderboardTop + leaderboardNode.clientHeight;

        if (placingTop < leaderboardTop) {
          leaderboardNode.scrollTop -= leaderboardTop - placingTop;
          return;
        }

        if (placingBottom > leaderboardBottom) {
          leaderboardNode.scrollTop += placingBottom - leaderboardBottom;
        }
      }

      playerPlacingRef.current = playerPlacingNode;
    },
    [],
  );

  const getLeaderboardNode = (node: HTMLDivElement): HTMLElement | null => {
    if (!node || !node.parentElement) return null;

    const placingWrapper = node.parentElement;
    const tierWrapper = placingWrapper.parentElement;
    if (!tierWrapper || !tierWrapper.parentElement) return null;

    return tierWrapper.parentElement;
  };

  return [setPlayerPlacingRef];
};

export default useScrollToPlayerPlacingHook;
