import { SvgEditionSlot } from '../part';
import * as React from 'react';

const BeardPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='m157.17 195.63-29.86-32.86c-1.35 3.7-2.13 9.78-3.22 16.85-2.11 13.75-16.62 12.78-16.62 12.78s5.61 11.32 19.19 5.55c0 0-1.01 28.24 19.27 37.64 0 0-.21-11.69 1.33-18.29 0 0-.3 12.27 17.29 22.61 0 0-5.32-14.95 0-25.94 0 0 7.66 27.94 34.26 40.24 0 0-1.94-9.41 10.97-18.95 7.65-5.65 13.85-11.66 11.43-14.96 0 0 8.18 2.75 8.53 16.72 0 0 9.77-10.87 12.5-23.58 0 0 .68 5.43 4.14 4.52 7.31-1.94 20.37-15.22 18.88-48.59l-27.11 28.62c-24.99 15.63-56.93 14.7-80.98-2.36z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M266.22 166.72c4.82 38.44-20.97 52.18-20.97 52.18-.94-4.52-4.35-10.19-4.35-10.19 1.22 24.42-12.22 29.52-12.22 29.52 2.32-12.75-7.44-17.32-7.44-17.32-.19 8.43-3.98 10.68-11.62 16.33-12.91 9.54-9.82 16.96-9.82 16.96-32.6-10.97-35.26-40.24-35.26-40.24-6.82 14.16 0 25.94 0 25.94-9.45-2.36-17.36-21.56-17.36-21.56-1.51 6.78-1.26 17.24-1.26 17.24-20.29-9.4-19.27-37.64-19.27-37.64-13.58 5.77-19.19-5.55-19.19-5.55s14.83 1.03 16.94-12.72c.92-7.21 1.24-9.25 2.54-15'
    />
    <path d='M178.85 208.49s-7.09 10.52 3.39 21.04c0 0-2.87-11.81-3.39-21.04zM134.4 197.19s-3.81 9.43 6.17 15.94c0 0-4.22-8.78-6.17-15.94zM126.22 191.32s9.25-3.56 4.72-12.77c0 0-1.33 6.18-4.72 12.77zM247.9 208.45s6.71-4.06.46-11.5c0 0 1.61 7.17-.46 11.5zM206.37 230.49s11.31-5.74 7.27-20.03c0 0-3.23 11.71-7.27 20.03zM231.86 221.82s7.43-7.33-.64-14.66c0 0 .57 5.44.64 14.66zM190.5 223.42s10.36-1.3 8.42-12.02c-.13 2.11-3.12 9.01-8.42 12.02zM185.48 232.93s5.21 7.59 10.17 10.98c0 0-.17-8 5.84-15.68 0 0-5.88.78-8 9.66-.01 0-4.87-5.09-8.01-4.96zM157.12 221.76s-5.81-4.81-2.84-11.66c0 .01 3.38 5.75 2.84 11.66z' />
  </svg>
);

export default BeardPower;
