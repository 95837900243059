import * as React from 'react';

function SvgKeySkullumbo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 141.7 153.1'
      enableBackground='new 0 0 141.7 153.1'
      xmlSpace='preserve'
      {...props}>
      <g>
        <path
          fill='#EEFFF8'
          d='M31.9,149.6c-1.9,0-3.7-0.7-5.1-2c-1.4-1.4-2.2-3.2-2.2-5.2c0-0.8,0.1-1.6,0.4-2.4l-3.7-3.7
		c-0.3,0.1-0.5,0.3-0.8,0.4c-1.1,0.5-2.3,0.7-3.5,0.7c-0.4,0-0.9,0-1.3-0.1c-2-0.3-3.9-1.2-5.3-2.7l-5.9-5.9
		c-1.5-1.5-2.2-3.8-1.8-6.2c0.2-2.8,1.5-5.3,3.7-6.9l6.9-5.2c0.5-0.4,1-0.7,1.7-1c0.8-0.4,1.6-0.8,2.4-1c0.5-1.6,1.6-3.1,3-4.3
		l5.2-4.3c0.5-0.4,1-0.7,1.5-1c0.1-1,0.5-2,1.2-3c0.4-0.6,1-1.2,1.6-1.6c0.4-0.3,0.7-0.5,1.1-0.7l4.2-2c0.8-2.4,2.8-4.4,5.3-5.3l0,0
		c0.8-2.4,2.8-4.4,5.3-5.3c0,0,1-0.3,1-0.3l2.4-2.4l-0.2-1.5c-0.3-3.2,1.8-6.1,4.9-6.7l0.5-0.1l0.6-3c0.5-2.8,2.8-4.8,5.6-5.1
		l2.6-0.2l0.6-2.2c0.4-1.7,1.6-3.1,3.1-4C62,49,53.7,36.1,53.5,23.9c-0.1-5.4,1.5-10,4.6-13.9c4.4-5.4,9.5-6.6,13-6.6
		c0.4,0,0.8,0,1.2,0c12.5,0.9,24.1,16.5,28.8,23.7c3.1,0.1,5.8,2.2,6.7,5.1c3.1,1,5,3.8,5.1,6.9c2.7,1.7,7.2,4.9,11.6,8.8
		c7.9,7.1,12.1,13.9,12.6,20.3c0.4,5.4-1.9,10.4-6.5,14.2c-3.5,2.8-7.7,4.3-12.6,4.3c0,0,0,0,0,0c-12.4,0-26.4-9.5-33.2-14.3
		c-0.8,1.8-2.3,3.1-4.2,3.6l-2.2,0.6l-0.2,2.6c-0.1,0.7-0.2,1.4-0.5,2c-0.4,0.9-1,1.7-1.8,2.4c-0.8,0.6-1.7,1.1-2.8,1.3l-3,0.6
		L70,86c-0.5,2.3-2.2,4.1-4.4,4.7c-0.5,0.1-1.1,0.2-1.6,0.2c-0.2,0-0.4,0-0.6,0l-1.9-0.2l-2.8,2.8c-0.6,2.7-2.7,4.8-5.3,5.4
		c-0.6,2.6-2.7,4.7-5.3,5.3l-1.3,4.2c-0.1,0.4-0.3,0.9-0.5,1.2c-0.2,0.4-0.5,0.7-0.8,1.1c-0.8,0.8-1.8,1.5-2.8,1.8l10.9,10.9
		c0.7-0.2,1.5-0.4,2.3-0.4c2,0,3.9,0.8,5.3,2.2c1.4,1.4,2.1,3.3,2,5.3c0,2-0.8,3.8-2.2,5.2c-0.8,0.8-1.8,1.3-2.8,1.7
		c-0.3,1-0.9,2-1.7,2.8c-1.4,1.4-3.3,2.2-5.3,2.2c-1.9,0-3.7-0.7-5.1-2c-0.9-0.9-1.6-2-2-3.2c0,0.2,0,0.4,0,0.6c0,2-0.8,3.8-2.2,5.2
		c-0.8,0.8-1.8,1.3-2.8,1.7c-0.3,1-0.9,2-1.7,2.8C35.8,148.8,33.9,149.6,31.9,149.6z M36.6,130.2c2,0,3.9,0.8,5.3,2.2
		c0.8,0.9,1.4,1.9,1.8,3c0-0.1,0-0.2,0-0.4c0-0.8,0.1-1.6,0.4-2.4l-11-11c-0.5,0.3-1.1,0.5-1.6,0.6c-0.2,1.4-0.7,2.7-1.5,3.9
		l4.4,4.4C35,130.3,35.8,130.2,36.6,130.2z M93.1,62.3c6.1,4.6,16.4,11,23.8,11c2.1,0,3.7-0.5,4.9-1.5c1.5-1.3,1.5-2,1.5-2.4
		c-0.1-0.7-0.7-3.6-6.3-9.3c-4-3.9-8.9-7.5-12.5-9.7c0,0,0,0-0.1,0c-0.5,0-1-0.1-1.5-0.2c-0.4,0.7-0.9,1.4-1.5,2
		c-1,1.1-2.2,1.8-3.6,2.2c-0.4,1.8-1.5,3.3-3,4.3c-0.2,1.2-0.7,2.3-1.6,3.2L93.1,62.3z M71.1,17.4c-0.3,0-1,0-2.2,1.5
		c-3.6,4.4,0,13,2.5,17.8c2.1,4,4.6,7.7,6.4,10.2l0.3-0.1c0.7-0.7,1.6-1.1,2.5-1.4c1-1.7,2.7-3,4.5-3.7l0,0c0.8-2.2,2.6-4.2,4.8-5.1
		c-0.1-0.5-0.1-1,0-1.4c-2.7-4-6-8.2-9.2-11.4c-5.7-5.7-8.6-6.3-9.4-6.4C71.2,17.4,71.2,17.4,71.1,17.4z'
        />
        <path
          d='M71.1,7c0.3,0,0.6,0,0.9,0C84.4,7.9,96.6,26.5,99.3,31l0.3-0.1c0.4-0.1,0.8-0.2,1.1-0.2c1.7,0,3.2,1.1,3.8,2.8
		c0.3,1,1.1,1.8,2.2,2.2c2.1,0.6,3.2,2.8,2.6,4.9l-0.1,0.5c3.1,1.9,23.5,14.7,24.5,27.6c0.2,3-0.5,7.4-5.3,11.2
		c-3.1,2.5-6.6,3.5-10.4,3.5c-15.4,0-34.2-16.4-35.4-16.4c0,0-0.1,0-0.1,0l-0.8,3.7c-0.2,1-1,1.8-2,2.1l-2.8,0.7
		c-1.1,0.3-2,1.3-2.1,2.4l-0.2,3.2c0,0.3-0.1,0.6-0.2,0.9c-0.2,0.4-0.4,0.8-0.8,1c-0.3,0.3-0.8,0.5-1.2,0.6l-3.5,0.7
		c-1.1,0.2-1.9,1-2.2,2.1l-0.2,1c-0.2,1-1,1.8-1.9,2.1c-0.2,0.1-0.5,0.1-0.7,0.1c-0.1,0-0.2,0-0.3,0l-2.3-0.2c-0.1,0-0.2,0-0.3,0
		c-0.3,0-0.6,0.1-0.9,0.2c-0.4,0.1-0.7,0.4-1,0.6l-3,3c-0.2,0.2-0.5,0.4-0.7,0.5l-0.2,1c-0.3,1.6-1.6,2.9-3.2,3.1l-0.8,0.1
		c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.5-0.2-0.7-0.4l0,0c0,0,0,0,0,0c0.2,0.2,0.3,0.4,0.4,0.6
		c0.1,0.3,0.2,0.7,0.1,1l-0.2,1.1c-0.3,1.6-1.6,2.9-3.2,3.1l-0.8,0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.6-0.1
		c-0.3-0.1-0.5-0.2-0.7-0.4l-0.1-0.1c0.1,0.1,0.3,0.3,0.4,0.5c0.2,0.3,0.3,0.7,0.3,1.2c0,0.2,0,0.4-0.1,0.6l-1.5,4.7
		c-0.1,0.2-0.1,0.4-0.2,0.5s-0.2,0.3-0.3,0.5c-0.5,0.5-1.2,0.9-1.9,0.9c-0.2,0-0.4,0-0.6-0.1c-0.2,0-0.3-0.1-0.5-0.2
		c-0.6-0.3-1.1-0.6-1.6-1c-0.1-0.1-0.2-0.1-0.3-0.2c0.4,1.4,0.2,2.9-0.7,4.1l-0.1,0.2l15.8,15.8c0,0,0.1-0.1,0.1-0.1
		c0.8-0.8,1.7-1.1,2.7-1.1c1,0,2,0.4,2.8,1.2c1.5,1.5,1.4,4-0.1,5.5c-0.7,0.7-1.6,1-2.6,1.1c-0.5,0-0.9,0.4-0.9,0.9
		c0,0.9-0.4,1.9-1.1,2.6c-0.7,0.8-1.8,1.2-2.8,1.2c-1,0-1.9-0.4-2.7-1.1c-1.6-1.5-1.6-4,0-5.5c0,0,0.1-0.1,0.1-0.1L33.2,117
		l-0.2,0.2c-0.8,1.1-2.1,1.7-3.4,1.8c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.5-0.2-0.8-0.3
		c0,0,0,0,0,0c0.3,0.6,0.5,1.3,0.5,2c0,0.3,0,0.7,0,1c-0.1,1-0.5,2-1.1,2.9l-1.5,2l8.4,8.4c0,0,0.1-0.1,0.1-0.1
		c0.8-0.8,1.7-1.1,2.7-1.1c1,0,2,0.4,2.8,1.2c1.5,1.5,1.4,4-0.1,5.5c-0.7,0.7-1.6,1-2.6,1.1c-0.5,0-0.9,0.4-0.9,0.9
		c0,0.9-0.4,1.9-1.1,2.6c-0.7,0.8-1.8,1.2-2.8,1.2c-1,0-1.9-0.4-2.7-1.1c-1.6-1.5-1.6-4,0-5.5c0,0,0.1-0.1,0.1-0.1l-7.8-7.8
		c-0.4,0.5-0.8,0.9-1.3,1.2c-0.3,0.2-0.7,0.4-1.1,0.6c-0.7,0.3-1.4,0.4-2.2,0.4c-0.3,0-0.5,0-0.8-0.1c-1.2-0.2-2.4-0.7-3.3-1.7
		l-2.9-2.9l-0.6-0.6l-1.5-1.5l-1-1c-0.8-0.8-1-2-0.8-3.3c0-1.7,0.8-3.4,2.3-4.6l6.9-5.2c0.4-0.3,0.8-0.5,1.2-0.7c1-0.6,2-0.9,3-0.9
		c0.4,0,0.7,0,1,0.1c-0.4-1.5,0.4-3.5,2.1-4.9l5.2-4.3c1-0.8,2.2-1.3,3.2-1.3c0.1,0,0.3,0,0.4,0c-0.3-0.4-0.5-0.9-0.7-1.4
		c-0.2-0.6,0-1.4,0.5-2.1c0.2-0.3,0.5-0.6,0.8-0.8c0.2-0.1,0.4-0.3,0.6-0.4l4.2-2c0.3-0.2,0.7-0.2,1-0.2c0.3,0,0.6,0.1,0.8,0.3
		l-0.1-0.1c-0.3-0.3-0.3-0.7-0.2-1.2l0.2-0.7c0.4-1.4,1.7-2.7,3.1-3.2l0.9-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3l0,0
		c-0.3-0.3-0.3-0.7-0.2-1.2l0.2-0.7c0.4-1.4,1.7-2.7,3.1-3.2l0.9-0.3c0.2-0.1,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2,0
		c0.1-0.1,0.1-0.1,0.2-0.2l3-3c0.6-0.6,0.9-1.3,1-2l0-0.3l-0.2-2c-0.1-1.4,0.8-2.7,2.1-2.9l1-0.2c1.1-0.2,1.9-1.1,2.1-2.2l0.7-3.5
		c0.2-1.2,1.2-2.1,2.5-2.2l3.2-0.2c1.2-0.1,2.1-0.9,2.4-2.1l0.7-2.8c0.3-1,1.1-1.8,2.1-2l3.5-1.1C71.9,56.3,57.2,39,57,23.9h0
		c0,0,0,0,0,0c-0.1-4.2,1-8.2,3.8-11.6C64.3,8,68.2,7,71.1,7 M76.5,50.9c0.3-0.2,0.5-0.3,0.8-0.3l1.8-0.4c0.5-0.1,1-0.4,1.3-0.7
		c0.5-0.5,1.2-0.8,1.9-0.8c0,0,0,0,0.1,0l0.1,0l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0l0.1-0.4c0.4-1.4,1.7-2.7,3.1-3.2l0.9-0.3
		c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3l0,0c-0.3-0.3-0.3-0.7-0.2-1.2l0.2-0.7c0.4-1.4,1.7-2.7,3.1-3.2l0.9-0.3
		c0.2-0.1,0.4-0.1,0.5-0.1c0.3,0,0.5,0.1,0.7,0.3c0.1,0,0.1,0,0.2,0c0.3,0,0.6,0.1,0.9,0.2l-1.1-2.6c-0.4-0.9-0.4-1.9-0.1-2.8
		c-2.6-4-6.4-9-10.4-13.1c-4-4.1-8.3-7.2-11.6-7.4c-0.1,0-0.3,0-0.5,0c-1.3,0-3,0.4-4.9,2.7c-4.6,5.6-1.4,14.9,2.1,21.6
		C71.1,43.7,74.6,48.4,76.5,50.9 M95.4,51.1c-0.2,0-0.4,0-0.6-0.1c-0.3-0.1-0.5-0.2-0.7-0.4l0,0c0,0,0,0,0,0
		c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.3,0.2,0.7,0.1,1l-0.2,1.1c-0.3,1.6-1.6,2.9-3.2,3.1l-0.1,0c0.1,0.3,0.2,0.6,0.2,1l0,0.3
		c0,0.8-0.3,1.5-0.8,2c-0.4,0.3-0.6,0.8-0.7,1.3l-0.4,1.8c-0.1,0.3-0.2,0.7-0.4,0.9c5.9,4.9,18.4,13.2,27.9,13.2
		c2.7,0,5.1-0.7,7.1-2.3c2.6-2.2,2.8-4.1,2.7-5.4c-0.2-3.3-3.3-7.4-7.3-11.5c-4.4-4.3-9.9-8.3-14.1-10.8l0.1-0.1
		c-0.3,0.1-0.7,0.1-1.1,0.1c-0.5,0-1-0.1-1.5-0.3l-2.7-1.2c0.1,0.3,0.2,0.7,0.1,1l-0.2,1.1c-0.1,0.9-0.6,1.7-1.3,2.2
		c-0.6,0.7-1.5,1.2-2.4,1.3l-0.8,0.1C95.6,51.1,95.5,51.1,95.4,51.1 M71.1,0C71.1,0,71.1,0,71.1,0c-4.3,0-10.4,1.4-15.7,7.9
		c-3.6,4.4-5.4,9.8-5.4,15.9c0,0.1,0,0.2,0,0.4c0.2,11.5,7.1,23.5,12.2,30.8c-0.8,1-1.4,2.1-1.7,3.4l-0.1,0c-4.4,0.3-8,3.6-8.8,7.9
		l-0.2,0.9c-3.8,1.6-6.2,5.6-5.8,9.8L45,77.6l-0.3,0.1c-2.9,1-5.4,3.2-6.8,5.9c-2.2,1.2-4.1,3.1-5.3,5.3l-3,1.4
		c-0.6,0.3-1.1,0.6-1.6,1c-1,0.7-1.8,1.5-2.5,2.5c-0.6,0.9-1.1,1.8-1.4,2.8c-0.2,0.2-0.5,0.3-0.7,0.5l-5.2,4.3
		c-1.4,1.2-2.6,2.6-3.4,4.2c-0.5,0.2-1,0.4-1.4,0.7c-0.8,0.4-1.5,0.8-2.2,1.3l-6.9,5.2c-3,2.2-4.8,5.6-5.1,9.3
		c-0.5,3.5,0.4,6.7,2.8,9.1l1,1l1.5,1.5l0.6,0.6l2.9,2.9c2,2,4.5,3.3,7.3,3.6c0.6,0.1,1.2,0.1,1.8,0.1c1.2,0,2.3-0.2,3.5-0.5
		l0.7,0.7c0,0.4-0.1,0.8-0.1,1.3c0,2.9,1.2,5.7,3.3,7.7c2,2,4.7,3,7.5,3c3,0,5.8-1.2,7.8-3.3c0.7-0.8,1.4-1.6,1.8-2.6
		c0.9-0.5,1.8-1.1,2.6-1.8c0.4-0.4,0.7-0.8,1.1-1.2c1.7,1.1,3.7,1.7,5.8,1.7c3,0,5.8-1.2,7.8-3.3c0.7-0.8,1.4-1.6,1.8-2.6
		c0.9-0.5,1.8-1.1,2.6-1.8c2.1-2,3.3-4.7,3.3-7.6c0.1-2.9-1-5.7-3-7.8c-2-2.1-4.9-3.3-7.8-3.3c-0.4,0-0.8,0-1.2,0.1l-6.6-6.6
		c0.4-0.5,0.8-1,1.2-1.6c0.4-0.6,0.6-1.3,0.9-2l0.8-2.5c2.3-1.1,4.2-2.9,5.2-5.2c2.7-1.2,4.7-3.5,5.7-6.3l0.9-0.9l0.3,0
		c0.3,0,0.7,0.1,1,0.1c0.9,0,1.7-0.1,2.6-0.3c2.9-0.8,5.2-2.8,6.3-5.5l0.9-0.2c1.6-0.3,3.1-1,4.3-2c1.2-1,2.2-2.3,2.8-3.7
		c0.4-1,0.7-2,0.8-3.1l0-0.1c1.5-0.4,2.8-1.2,3.9-2.1c7.9,5.4,20.5,13,32.5,13c5.7,0,10.7-1.7,14.8-5.1c5.5-4.5,8.3-10.6,7.8-17.2
		c-0.5-7.3-5.1-14.9-13.7-22.6c-3.9-3.5-7.8-6.4-10.7-8.3c-0.7-3.2-2.7-6-5.7-7.5c-1.5-2.9-4.2-5-7.4-5.7C97.2,15.2,85.7,1,72.6,0.1
		C72.1,0,71.6,0,71.1,0L71.1,0L71.1,0z M72,20.7L72,20.7L72,20.7L72,20.7z M78.7,42c-1.4-2-2.9-4.4-4.2-7
		c-4.1-7.9-4.1-12.4-2.9-13.9c1,0.4,3.1,1.6,6.6,5.2c2.5,2.5,5.1,5.7,7.4,8.9c-1.3,1.1-2.4,2.4-3.1,3.9C81,39.8,79.7,40.8,78.7,42
		L78.7,42z M103.9,54.6c0.1-0.1,0.2-0.2,0.3-0.3c3.2,2.1,7.1,5,10.3,8.2c3.4,3.5,4.6,5.6,5,6.5c-0.2,0.2-0.9,0.7-2.7,0.7
		c-5,0-12.7-3.9-19.3-8.5c0.1-0.2,0.2-0.4,0.2-0.7c1.1-1,2-2.2,2.6-3.5C101.8,56.5,102.9,55.7,103.9,54.6L103.9,54.6z'
        />
      </g>
    </svg>
  );
}

export default SvgKeySkullumbo;
