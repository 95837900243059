import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ButtonBase,
  CircularProgress,
  Fade,
  Theme,
  Typography,
} from '@mui/material';
import FullDrawer from '../../../components/Drawer/FullDrawer';
import { useHistory, useParams } from 'react-router-dom';
import SvgIconBackArrow from '../../../assets/SvgIconBackArrow';
import { RealmType, usePartyCompleteQuery } from '../../../components/schema';
import { PartySize } from '../../Furverse/useBossBattle';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';
import { usePageTitle } from '../../../Analytics';
import RentMarketBosses from './RentMarketBosses';
import { capitalize } from 'lodash';
import RentMarketOnboardProgress, {
  OnboardStep,
} from './RentMarketOnboardProgress';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5rem 2rem 2rem 2rem',
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    color: 'white',
    flexDirection: 'column',
    minHeight: '400px',

    [theme.breakpoints.down('sm')]: {
      padding: '1.35rem',

      '& h2': {
        maxWidth: '300px !important',
      },
    },
  },
  btn: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    width: '225px !important',
    minWidth: '225px !important',
    marginTop: '1rem !important',

    '&:disabled': {
      backgroundColor: '#eee !important',
    },
  },
  partyHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '2rem',
    textAlign: 'center',

    '& p': {
      marginTop: '0.5rem !important',
      opacity: 0.7,
    },

    [theme.breakpoints.down('sm')]: {
      '& p': {
        fontSize: '0.9rem !important',
        maxWidth: '300px !important',
      },
    },
  },
  back: {
    position: 'absolute',
    top: '80px',
    left: '30px',
    color: 'white',

    '& button': {
      padding: '0.5rem !important',
      borderRadius: '6px !important',
    },

    [theme.breakpoints.down('lg')]: {
      top: '100px',
    },

    [theme.breakpoints.down('sm')]: {
      position: 'initial',
      marginTop: '1.5rem',
      marginLeft: '1.5rem',
    },
  },
  empty: {
    height: '60px',
    width: '60px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    marginTop: '8px',
    marginLeft: '1rem',
  },
  furballs: {
    display: 'flex',
    gap: '0.25rem',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1.3rem 1.5rem 1rem 1.5rem',
    borderRadius: '12px',
    backgroundColor: 'white',
    position: 'relative',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    // width: '440px',
    margin: '0.5rem auto 1rem auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap',
      padding: '1.3rem 1rem 1rem 1rem',
    },
  },
  partyName: {
    position: 'absolute',
    height: '25px',
    left: 15,
    top: -15,
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    padding: '0 12.5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    fontWeight: 'bold',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',

    '& h6': {
      lineHeight: '1rem !important',
      marginBottom: '2px !important',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bosses: {
    width: 300,
    // height: 440,
    marginTop: 0,
  },
}));

interface IParams {
  partyId: string;
  realm?: RealmType;
  onboard?: string;
}

interface IRentMarketCompleteParty {
  className?: string;
}

const RentMarketCompleteParty: FunctionComponent<IRentMarketCompleteParty> = (
  props,
) => {
  const classes = useStyles();
  const { className } = props;
  const history = useHistory();
  const { partyId, realm, onboard } = useParams<IParams>();

  const { data, error, loading } = usePartyCompleteQuery({
    variables: { partyId },
    fetchPolicy: 'no-cache', // avoid cache for scenarios where user went back and rented more furballs
  });

  const showBackBtn = !onboard && data?.party.size !== PartySize.FIVE;
  const backPath = `/market/furballs/${partyId}${!!realm ? `/${realm}` : ''}`;

  usePageTitle(
    `Complete Party ${
      !!realm
        ? `| ${realm.replace('-', ' ').split(' ').map(capitalize)} ${
            !!onboard ? '| Onboard' : ''
          }`
        : ''
    } | Furball Market`,
  );

  function renderTitleAndSub(partySize: number, canComplete: boolean) {
    const maxSize = PartySize.THREE; //  !!onboard ? PartySize.THREE : PartySize.FIVE;

    const title = !canComplete
      ? 'Party is not ready for battle!'
      : partySize === maxSize
      ? 'Your Party is Ready'
      : 'Complete party and battle?';

    const sub = !canComplete
      ? 'Please add more Furballs to the party'
      : partySize === maxSize
      ? ''
      : '...or add more furballs to the party';

    return (
      <div className={classes.partyHeader}>
        <Typography variant={'h2'}>{title}</Typography>
        <Typography variant={'body1'}>{sub}</Typography>
      </div>
    );
  }

  function renderPartyMembers(tokenIds: string[]) {
    const heads = tokenIds.map((id) => (
      <div
        key={`fb-${id}`}
        style={{
          position: 'relative',
        }}>
        <FurballRenderer tokenId={id} size={80} />
      </div>
    ));

    while (heads.length < (!!onboard ? PartySize.THREE : PartySize.FIVE)) {
      heads.push(
        <div key={`empty-${heads.length}`} className={classes.empty} />,
      );
    }

    return heads;
  }

  function renderContent() {
    if (loading) {
      return <CircularProgress color={'secondary'} size={40} />;
    }

    if (error || !data?.party) {
      return (
        <Typography variant={'body1'} color={'red'}>
          Unable to load party
        </Typography>
      );
    }

    const { party } = data;
    const { name, size, partyMemberIds } = party;
    const canComplete = size === partyMemberIds.length;

    return (
      <Fade in={true} timeout={400}>
        <div className={classes.wrapper}>
          {renderTitleAndSub(partyMemberIds.length, canComplete)}
          <div className={classes.furballs}>
            <div className={classes.partyName}>
              <Typography variant={'h6'}>{name}</Typography>
            </div>
            {renderPartyMembers(partyMemberIds)}
          </div>
          {!canComplete && (
            <Button
              className={classes.btn}
              size={'medium'}
              variant={'contained'}
              onClick={() => history.push(backPath)}>
              <Typography variant={'h6'}>Back to market</Typography>
            </Button>
          )}
          {canComplete && (
            <RentMarketBosses
              className={classes.bosses}
              partyId={partyId}
              fightOnComplete={realm}
            />
          )}
        </div>
      </Fade>
    );
  }

  return (
    <FullDrawer drawer={null}>
      {!!onboard && <RentMarketOnboardProgress step={OnboardStep.COMPLETE} />}
      {showBackBtn && (
        <div className={classes.back}>
          <ButtonBase onClick={() => history.push(backPath)}>
            <SvgIconBackArrow height={30} width={30} />
          </ButtonBase>
        </div>
      )}
      <div className={clsx(classes.root, className)}>{renderContent()}</div>
    </FullDrawer>
  );
};

export default RentMarketCompleteParty;
