import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Pipe = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M201.95 158.37c0 3.02-2.45 5.47-5.47 5.47-3.02 0-5.47-2.45-5.47-5.47 0-3.02 2.45-1.88 5.47-1.88 3.02 0 5.47-1.14 5.47 1.88z' />
    <path
      fill='#3f3fab'
      d='M187.6 173.68s.15-4.79 2.85-9.46c2.31-3.98 5.58-5.35 7.29-5.55 1.1-.13 2.07.46 2.12 1.56v.02c.04.81-.45 1.55-1.2 1.86-.08.03-.16.07-.24.1-3.15 1.36-7 3.79-7.08 18.2 0 0 .77 7.41-7.1 7.6-6.9.17-6.47-12.14-6.47-12.14'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M187 173.56s.15-5.27 2.85-9.94c2.31-3.98 6.18-4.75 7.89-4.95 1.1-.13 2.07.46 2.12 1.56v.02c.04.81-.45 1.55-1.2 1.86-.08.03-.16.07-.24.1-3.15 1.36-7 3.79-7.08 18.2 0 0 .77 7.41-7.1 7.6-6.9.17-6.47-12.14-6.47-12.14'
    />
    <path d='M188.19 174.11c.21 1.57-1.86 3.05-5.14 3.48-3.28.44-6.21-.02-6.42-1.59-.21-1.57 2.38-3.18 5.67-3.61 3.28-.44 5.68.14 5.89 1.72zM195.7 149.5c0 1.83-.65 2.82-1.44 2.82-.8 0-1.44-.99-1.44-2.82s.65-1.98 1.44-1.98c.8 0 1.44.15 1.44 1.98zM199.82 149.5c0 1.83-.65 2.82-1.44 2.82-.8 0-1.44-.99-1.44-2.82s.65-1.98 1.44-1.98c.79 0 1.44.15 1.44 1.98zM198.12 154.12s8.1-.32 4.99 7.97c0 0 3.76-2.29 1.95-6.26-1.82-3.96-6.94-1.71-6.94-1.71z' />
  </svg>
);

export default Pipe;
