import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Lion = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffb261'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M67.73 264.7s-23.58-17.24-13.81-40.22 32.56-14.36 28.51-45.83c0 0 17.18 17.39-5.37 34.67s-19.48 31.64-6.23 46.38l-3.1 5z'
    />
    <path
      fill='#ff5252'
      d='M94.46 153.74s-24.11-6.01-22.88 18.61c.04.86.2 1.71.46 2.54l.6 1.95c.73 2.37 2.25 4.42 4.31 5.8 2.91 1.96 6.6 2.4 9.87 1.13 4.79-1.86 10.11-6.06 6.35-15.38-.62-1.53-1.49-2.94-2.5-4.24-1.51-1.94-3.59-6.32 3.79-10.41z'
    />
    <path
      fill='#ff6969'
      d='M71.58 172.34c-1.23-24.62 22.88-18.61 22.88-18.61-.41.23-32.46 3.27-12.63 30.1-8.83.17-10.2-10.63-10.25-11.49z'
    />
    <path
      fill='#e63333'
      d='M90.67 164.14c1.01 1.3 10.33 18.86-9.49 20.18L80 180.78a9.83 9.83 0 0 1 1.61-9.2l.94-1.19a9.71 9.71 0 0 0 2.09-5.48c.61-11.09 9.82-11.17 9.82-11.17-7.38 4.09-5.3 8.47-3.79 10.4z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M94.46 153.74s-24.11-6.01-22.88 18.61c.04.86.97 7.88 5.36 10.29 2.91 1.96 6.6 2.4 9.87 1.13 4.79-1.86 10.11-6.06 6.35-15.38-.62-1.53-1.49-2.94-2.5-4.24-1.5-1.94-3.58-6.32 3.8-10.41z'
    />
  </svg>
);

export default Lion;
