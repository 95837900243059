import { SvgEditionSlot } from '../part';
import * as React from 'react';

const ConeofShame = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <linearGradient
      id='ConeofShame__a'
      gradientUnits='userSpaceOnUse'
      x1={192}
      y1={216}
      x2={192}
      y2={101}>
      <stop
        offset={0.16}
        style={{
          stopColor: '#ccf1ff',
          stopOpacity: 0.8,
        }}
      />
      <stop
        offset={0.886}
        style={{
          stopColor: '#ccf1ff',
          stopOpacity: 0.4,
        }}
      />
    </linearGradient>
    <path
      opacity={0.9}
      fill='url(#ConeofShame__a)'
      d='m91.47 115.65 37.09 71.47c10.56 15.64 38.48 29.15 67.76 29.15 17.08 0 32.73-4.8 44.91-11.91 10.67-6.23 19.06-15.71 24.11-26.99l27.62-61.77c-34.71-5.65-61.89-13.82-96.96-13.82S126.18 110 91.47 115.65z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M91.71 115.65h-.24l35.6 71.09c10.56 15.64 39.97 29.9 69.25 29.9 22.05 0 42.75-8.83 55.9-19.87 5.74-4.82 9.8-10.91 13.3-17.77l27.92-63.39c-117.28-20.53-108.36-16.59-201.73.04z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m112.44 113.67 49.96 96.07'
    />
    <path
      fill='#c7efff'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M130.39 139.06c-.96-1.81-.32-4.09 1.41-5.09 1.74-1 3.91-.34 4.87 1.48.95 1.81.32 4.09-1.41 5.09-.55.32-1.14.47-1.73.47-1.26-.01-2.48-.71-3.14-1.95zm-6.54-12.42c-.96-1.81-.32-4.09 1.41-5.09 1.74-1 3.91-.34 4.87 1.47.95 1.81.32 4.09-1.41 5.09-.55.32-1.14.47-1.73.47-1.26 0-2.49-.7-3.14-1.94z'
    />
  </svg>
);

export default ConeofShame;
