import React, { FunctionComponent } from 'react';
import { Theme, Button, Typography, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useAuthContext } from '../Auth/AuthContext';
import WalletButton from '../../wallet/WalletButton';
import OkDialog from '../OkDialog';
import { fireSpendTixEvent, fireSpendWFurEvent } from '../../Analytics';
import { useTimekeeperSelector } from '../Timekeeper/TimekeeperSlice';
import useCommonStyles from '../useCommonStyles';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { mint, purple } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '400px',
    height: '110px',
  },
  confirmActions: {
    display: 'flex',
    gap: '0.5rem',
    padding: '0.4rem 0',
  },
}));

export interface ISpendButtonBase {
  cost: number;
  onAccepted: () => Promise<void>;
  itemName: string;
  onComplete?: (amount: number) => void;
  onFailed?: (e: any) => void;
  isLoading?: boolean;
  title?: string;
  disabled?: boolean;
  profitMult?: number;
  className?: string;
  secondary?: boolean;
  children?: React.ReactNode;
  hideTitle?: boolean;
  hidePrompt?: boolean;
  suffix?: React.ReactNode;
}

interface ISpendButton extends ISpendButtonBase {
  balance: number;
  currency: 'TIX' | 'wFUR' | 'USD';
  renderActions?: (hasEnough: boolean) => React.ReactNode;
  renderIcon: (
    iconFill: string,
    iconStyle: React.CSSProperties,
  ) => React.ReactNode;
}

export const SpendButton: FunctionComponent<ISpendButton> = (props) => {
  const {
    className,
    children,
    itemName,
    onAccepted,
    secondary,
    currency,
    hideTitle,
    renderActions,
    renderIcon,
    balance,
    suffix,
    cost,
  } = props;
  const isTix = currency === 'TIX';
  const isUsd = currency === 'USD';
  const hidePrompt = props.hidePrompt || isUsd;
  const profitMult = props.profitMult ?? 1;
  const ethPrice = useTimekeeperSelector(
    (s) => s.userState.round?.ethPrice ?? 0,
  );
  const common = useCommonStyles();
  const tixProfit = profitMult * (isTix ? props.cost : 0);
  const title = props.title ?? itemName;
  const [hover, setHover] = React.useState(false);
  const { isSignedIn } = useAuthContext();
  const [lo, setLoading] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const classes = useStyles();
  const hasEnough = balance > cost;
  const balanceDisplay = Math.floor(balance);
  const loading = !!props.isLoading || lo;
  const disabled = !!props.disabled || loading;
  const { addAlert } = React.useContext(AlertContext);
  const iconStyle: React.CSSProperties = {
    width: 24,
    height: 24,
    position: 'relative',
    top: 4,
  };
  const iconFill = hover
    ? `${secondary ? purple.light : mint.light}`
    : '#000000';
  const icon = loading ? (
    <CircularProgress color={secondary ? 'primary' : 'secondary'} size={24} />
  ) : (
    renderIcon(iconFill, iconStyle)
  );

  const onComplete = () => {
    if (props.onComplete) return props.onComplete(cost);

    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: `Purchased | ${itemName}`,
    });
  };

  const onFailed =
    props.onFailed ??
    ((e: any) => {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e?.message ?? `Failed to purchase ${itemName}`,
      });
    });

  async function doSpend() {
    try {
      await onAccepted();
      if (isTix) fireSpendTixEvent(tixProfit, ethPrice, itemName, 'purchase');
      else fireSpendWFurEvent(cost, itemName);
      setLoading(false);
      setConfirmOpen(false);
      onComplete();
    } catch (e: any) {
      onFailed(e);
    }
  }

  async function spendTix() {
    setLoading(true);
    void doSpend();
  }

  function promptSpend() {
    if (hidePrompt) {
      if (onAccepted) void onAccepted();
      return;
    }
    if (isTix) fireSpendTixEvent(tixProfit, ethPrice, itemName, 'add_to_cart');
    setConfirmOpen(true);
  }

  function onClose() {
    setConfirmOpen(false);
  }

  if (!isSignedIn)
    return (
      <WalletButton
        className={className}
        analyticsPathComponent={'spend'}
        title={`Sign In to Spend ${currency}`}
      />
    );

  const costStr = cost.toLocaleString();

  return (
    <>
      <Button
        color={secondary ? 'secondary' : 'primary'}
        variant={'contained'}
        className={clsx(
          secondary ? common.secondaryButton : common.actionButton,
          className,
        )}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => promptSpend()}
        disabled={disabled}>
        {children && children}
        {!children && (
          <Typography variant={'h5'}>
            {title === '' || hideTitle ? costStr : title} {icon}{' '}
            {title === '' || hideTitle ? '' : costStr}{' '}
            {costStr.length > 5 ? '' : currency}
            {suffix && suffix}
          </Typography>
        )}
      </Button>

      <OkDialog
        title={
          !hasEnough
            ? `${currency} Required!`
            : `Spend ${cost.toLocaleString()} ${currency}?`
        }
        open={confirmOpen}
        onClose={onClose}
        actionsClassName={common.cardHeader}
        actions={
          <div className={classes.confirmActions}>
            {renderActions && renderActions(hasEnough)}
            {hasEnough && (
              <Button
                variant={'contained'}
                color={'primary'}
                className={common.actionButton}
                disabled={loading}
                onClick={() => spendTix()}>
                <Typography variant={'h5'}>Confirm</Typography>
              </Button>
            )}
          </div>
        }>
        <div className={classes.root}>
          <Typography variant={'h6'}>
            You are about to spend {cost.toLocaleString()} {currency} for:
          </Typography>
          <Typography variant={'h4'}>"{itemName}"</Typography>
          <Typography variant={'h6'}>
            {hasEnough
              ? `Your balance is: ${balanceDisplay.toLocaleString()} ${currency}`
              : `...but, you don't have enough ${currency} (currently: ${balanceDisplay.toLocaleString()}).`}
          </Typography>
        </div>
      </OkDialog>
    </>
  );
};

export default SpendButton;
