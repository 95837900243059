export type CookieKey =
  | 'playerType'
  | 'playerId'
  | 'playerUsername'
  | 'playerRoleFlags'
  | 'sessionSecret';

export enum FurAccountTypes {
  PLAYER = 'FurAccount',
  SCHOLAR = 'FurScholar',
}
export interface IAuthData {
  playerType?: FurAccountTypes;
  playerId?: string;
  playerUsername?: string;
  playerAvatar?: string;
  playerRoleFlags?: string;
  sessionSecret: string;
}

export const FurCookieNames: IAuthData = {
  playerType: 'FUR.Player.Type' as any,
  playerId: 'FUR.Player.Id',
  playerUsername: 'FUR.Player.Username',
  playerAvatar: 'FUR.Player.Avatar',
  playerRoleFlags: 'FUR.Player.RoleFlags',
  sessionSecret: 'FUR.Session.Secret',
};
