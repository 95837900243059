import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Bunny = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M137.88 64.14c-18.79-4.37-23.88-7.52-25.52-9.37 2.19 3.93 8.45 16.18 10 28.61 1.86 14.9 24.22 34.77 24.22 34.77 5.08 4.2 28.43 7.22 25.43-10.11-2.01-11.58-14.31-39.29-34.13-43.9z'
    />
    <path
      fill='#90a7ab'
      d='M112.35 54.77c-.44-.78-.69-1.21-.69-1.21s0 .44.69 1.21z'
    />
    <linearGradient
      id='Bunny__a'
      gradientUnits='userSpaceOnUse'
      x1={-374}
      y1={-392}
      x2={-382}
      y2={-434}
      gradientTransform='rotate(-68.328 256.817 -546.655)'>
      <stop
        offset={0}
        style={{
          stopColor: '#fdbfb4',
        }}
      />
      <stop
        offset={0.509}
        style={{
          stopColor: '#f7ffd1',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#91da87',
        }}
      />
    </linearGradient>
    <path
      fill='url(#Bunny__a)'
      d='M164.44 109.72s-2.4-29.04-35.88-39.6c0 0-3.18 14.98 29.25 44.69 2.72-2.4 3.54-3.24 6.63-5.09'
    />
    <linearGradient
      id='Bunny__b'
      gradientUnits='userSpaceOnUse'
      x1={247}
      y1={115}
      x2={240}
      y2={73}>
      <stop
        offset={0}
        style={{
          stopColor: '#fdbfb4',
        }}
      />
      <stop
        offset={0.509}
        style={{
          stopColor: '#f7ffd1',
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: '#91da87',
        }}
      />
    </linearGradient>
    <path
      fill='url(#Bunny__b)'
      d='M241.22 116.36c6.26-4.65 14.83-11.36 19.94-16.8 8.76-9.32-1.2-29.44-15.8-27.29-10.04 1.48-16.99 20.3-20.35 31.91 3.22 1.65 14.07 9.87 16.21 12.18z'
    />
    <path
      fill={props.renderer.color1}
      d='M257.29 88.45c2.22 13.17-26.25 27.87-26.25 27.87l7.68 5.28c11.31-3.51 27.6-24.53 27.6-24.53l-9.03-8.62z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M234.93 113.35s24.59-11.73 22.37-24.9l9.03 8.62s-13.77 19.1-25.08 22.61'
    />
    <path
      opacity={0.23}
      d='m255.76 97.36-10.92 9.96s-13.8-15.12-1.08-31.08c0 0 12.07 20.62 12 21.12z'
    />
    <path
      fill={props.renderer.color1}
      d='M227.68 111.52c.67-19.51 16.11-35.28 16.11-35.28s4.71 16.82 15.47 22.87 14.8 14.13 14.8 14.13c2.02-8.07 4.71-42.38-20.18-47.08S220 109 220 109'
    />
    <path
      opacity={0.23}
      d='M126.04 67.48s7.24 26.18 30.24 45.84c1.42-1.34 6.05-3.33 6.24-3.6-12-7.2-36.48-42.24-36.48-42.24z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M228.52 108.64c.67-19.51 15.27-32.4 15.27-32.4s4.71 16.82 15.47 22.87 14.8 14.13 14.8 14.13c2.02-8.07 4.71-42.38-20.18-47.08s-32.92 38.77-32.92 38.77M170.2 105.52s0-28.92-32.32-41.38c-25.58-9.86-26.22-10.58-26.22-10.58s6.58 14.64 10.66 29.88c5.86 21.91 28.8 36.72 28.8 36.72'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M124.84 67.24s9.37 29.11 31.36 46.07c.47.36 1.12.37 1.59.02l4.74-3.53c.37-.27.56-.72.53-1.17-2.42-28.67-38.22-41.39-38.22-41.39z'
    />
  </svg>
);

export default Bunny;
