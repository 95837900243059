import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Bandaid = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#dec2ff'
      stroke='#000'
      strokeMiterlimit={10}
      d='m168.95 193.66-16.8-10.89a5.218 5.218 0 0 1-1.54-7.22 5.218 5.218 0 0 1 7.22-1.54l16.8 10.89a5.218 5.218 0 0 1 1.54 7.22 5.218 5.218 0 0 1-7.22 1.54z'
    />
    <path
      opacity={0.46}
      fill='#FFF'
      d='m164.91 188.68-6.55-4.25a.724.724 0 0 1-.21-1l2.74-4.22c.22-.33.66-.43 1-.21l6.55 4.25c.33.22.43.66.21 1l-2.74 4.22c-.22.33-.66.43-1 .21z'
    />
    <path
      fill='#bb82ff'
      d='M152.34 178.12c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.56.56 0 0 1-.17-.79zM153.74 175.96c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.56.56 0 0 1-.17-.79zM155.66 180.27c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.576.576 0 0 1-.17-.79zM157.06 178.11c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.576.576 0 0 1-.17-.79zM154.66 178.18c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.569.569 0 0 1-.17-.79zM168.85 188.82c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.576.576 0 0 1-.17-.79zM170.25 186.66c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.576.576 0 0 1-.17-.79zM172.18 190.98c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.559.559 0 0 1-.17-.79zM173.58 188.82c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.567.567 0 0 1-.17-.79zM171.18 188.88c.17-.27.53-.34.79-.17.27.17.34.53.17.79-.17.27-.53.34-.79.17a.559.559 0 0 1-.17-.79z'
    />
  </svg>
);

export default Bandaid;
