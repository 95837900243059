import React from 'react';
import { FunctionComponent } from 'react';

import { useWalletSelector } from '../../../hooks';
import BalanceButtonV2 from './BalanceButtonV2';
import SvgIconWrappedFur from '../../../assets/SvgIconWrappedFur';
import SvgIconFurNew from '../../../assets/SvgIconFurNew';

interface IFurBalanceButton {
  value?: number;
  wrapped?: boolean;
  static?: boolean;
}

const FurBalanceButtonV2: FunctionComponent<IFurBalanceButton> = (props) => {
  const { value, wrapped } = props;
  const icon = wrapped ? (
    <SvgIconWrappedFur height={25} width={25} />
  ) : (
    <SvgIconFurNew height={25} width={25} />
  );

  const balance = useWalletSelector(({ contents }) => {
    return (wrapped ? contents?.wFur : contents?.furBalance) ?? 0;
  });

  return (
    <BalanceButtonV2
      balance={(value ?? balance).toLocaleString()}
      units={'$'}
      path={'/swap'}
      icon={icon}
    />
  );
};

export default FurBalanceButtonV2;
