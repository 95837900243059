import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { ButtonBase, Fade, Theme } from '@mui/material';
import FurballRenderer from '../../Furballs/FurballRenderer';
import { white, yellow } from '../../../themev2';
import GamePlayerAvatar from './GamePlayerAvatar';
import PlayerLink, { IFurPlayerLinkableAvatar } from '../../Players/PlayerLink';
import { formatNumber } from '../../../utils';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  gameTitle: {
    fontWeight: 'bold',
    fontSize: '14px !important',
  },
  infoWrapper: {
    display: 'flex',
    minWidth: '200px !important',

    '& span': {
      fontWeight: 700,
      fontSize: '14px !important',
    },

    '@media (max-width: 380px)': {
      minWidth: '170px !important',
    },
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  furballWrapper: {
    display: 'flex',
  },
  furball: {
    position: 'relative',
  },
  gameOverview: {
    borderRadius: '6px',
    backgroundColor: white,
    padding: '10px',
    margin: '5px 0',
    minHeight: '70px',
    maxHeight: '70px',
  },
  itemCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  points: {
    minWidth: '65px',
    maxWidth: '65px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1rem',
    backgroundColor: yellow,
    height: '52px',
    paddingTop: '0.5rem',

    '& span': {
      fontSize: '0.45rem',
      marginBottom: '5px',
    },
  },
}));

export interface IBossBattleOverview {
  id: string;
  createdAt: Date;
  score: number;
  furballIds: string[];
  player: IFurPlayerLinkableAvatar | null;
}

interface IGameOverviewPlayer {
  recentBossBattle: IBossBattleOverview;
}

const GameOverviewPlayer: FunctionComponent<IGameOverviewPlayer> = (props) => {
  const { id, createdAt, score, furballIds, player } = props.recentBossBattle;
  const classes = useStyles();
  const history = useHistory();

  function getBattleDuration(createdAt: Date) {
    const MIN_IN_HOUR = 60;

    let diff = (new Date().getTime() - new Date(createdAt).getTime()) / 1000;
    diff /= MIN_IN_HOUR;

    const totalMins = Math.abs(Math.round(diff));
    const hours = Math.floor(totalMins / MIN_IN_HOUR);
    const mins = totalMins % MIN_IN_HOUR;

    const hourTxt = `${totalMins >= MIN_IN_HOUR ? `${hours}h` : ''}`;
    const minTxt = `${mins !== 0 ? `${mins}m` : ''}`;

    return `${hourTxt} ${minTxt}`;
  }

  function renderFurballsInGame() {
    return furballIds.map((furballId, i) => {
      return (
        <ButtonBase
          key={`party-member-${id}-${furballId}-${i}`}
          style={{ borderRadius: '50%' }}
          onClick={() => history.push(`/furball/${furballId}`)}>
          <div className={classes.furball}>
            <FurballRenderer tokenId={furballId} size={'26.5px'} />
          </div>
        </ButtonBase>
      );
    });
  }

  return (
    <div className={classes.gameOverview}>
      <Fade in={true}>
        <div className={classes.itemCont}>
          <div className={classes.infoWrapper}>
            <GamePlayerAvatar src={player?.avatar} />
            <div className={classes.info}>
              <span className={classes.gameTitle}>
                <PlayerLink player={player} /> • {getBattleDuration(createdAt)}
              </span>
              <div className={classes.furballWrapper}>
                {renderFurballsInGame()}
              </div>
            </div>
          </div>
          <div className={classes.points}>
            {formatNumber(score)} <span>POINTS</span>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default GameOverviewPlayer;
