import React, { FunctionComponent } from 'react';
import { RealmType } from '../schema';
import SvgRealmBossKeyTurtleSea from '../../assets/voyages/SvgRealmBossKeyTurtleSea';
import SvgRealmBossKeyBoneyard from '../../assets/voyages/SvgRealmBossKeyBoneyard';
import SvgRealmBossKeyWizardsPath from '../../assets/voyages/SvgRealmBossKeyWizardsPath';

interface IBossKey {
  realm?: RealmType;
  size?: number;
}

export const BossKey: FunctionComponent<IBossKey> = (props) => {
  const { realm } = props;
  const size = props.size ?? 50;
  const style = { width: size, height: size };
  return (
    <div>
      {realm === RealmType.TurtleSea && (
        <SvgRealmBossKeyTurtleSea style={style} />
      )}
      {realm === RealmType.Boneyard && (
        <SvgRealmBossKeyBoneyard style={style} />
      )}
      {realm === RealmType.WizardsPath && (
        <SvgRealmBossKeyWizardsPath style={style} />
      )}
    </div>
  );
};

export default BossKey;
