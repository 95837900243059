import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Tiger = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.04c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <g opacity={0.37} fill={props.renderer.color2}>
      <path d='M123.46 201.21s7.06 19.39 23.82 18.19c31.92-2.28 37.62 21.36 37.62 21.36s-18.18-17.28-49.38-9.12c-17.89 4.68-28.72-23.06-28.72-23.06' />
      <path d='M104.2 211.24s4.1 26.97 28.32 24.84c27.24-2.4 36.26 18.67 36.26 18.67s-11.66.23-33.44 5.18-24.42-8.01-34.8-16.16c-5.53-4.35-29.69-11.98-39.18 7.75 0 0 3.79-17.45 12.63-20.58 8.84-3.13 19.69-2.93 32.8 9.87s19.13 13.56 29.38 10.84c10.26-2.72 18.3-.51 18.3-.51s1.08-7.37-26.83-5.93c-17.09.88-18.95-18.39-30.74-20.9-11.79-2.5-14.31.98-14.31.98l21.61-14.05z' />
      <path d='M59.93 256.97s15.35-19.57 37.31-6.25c0 0-22.56-1.32-35.87 18' />
      <path d='M78.64 257.32s-5.87 18.82-17.27 15.59c0 0-1.2 3.31 3.11 11.41 0 0 15.36-3.96 14.16-27zM238.06 221.85s-5.82 11.6-29.76 9.98c0 0 14.53 13.16 30.7 2.95l-.94-12.93z' />
      <path d='M237.98 238.82s-12.74 10.1-29.51 0c0 0-6.53-4.2-6.5-12.05 0 0-3.15 11.27 4.83 19.15 0 0-11.38-1.69-14.15-10.75 0 0 1.88 24.46 39.92 18.74M181.84 248.68s16.02-2.85 22.2 8.76c11.78 22.12 25.47 6.64 25.47 6.64l-11.79 14.6s-9.57-1.57-15.86-13.93c-9.22-18.11-20.02-16.07-20.02-16.07z' />
      <path d='M209.45 257.4s6.32 10.56 17.08 7.82l5.54-6.14c-.01 0-11.7 4.63-22.62-1.68z' />
    </g>
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill={props.renderer.color1}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Tiger;
