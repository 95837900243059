import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Grid, Theme, Typography } from '@mui/material';
import { NotificationPurpose, useClaimPendingRewardsMutation } from '../schema';

import { Notification } from './NotificationTypes';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import NotificationClaimItemsDialog from './NotificationClaimItemsDialog';
import { IClaimedRewards } from '../../wallet/WalletTypes';
import {
  getBossIconSrc,
  getItemDefs,
  isLeaderboardReward,
  isSeasonalReward,
} from '../BossFights/BossRewards/bossRewardsUtils';
import { getTimeAgo, useFurComponent } from '../../utils';
import WalletSlice from '../../wallet/WalletSlice';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useNotifications } from './NotificationsContext';

const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    padding: '1rem 1.5rem',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'all 0.2s linear',
    position: 'relative',

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[100]}`,
    },

    '&:hover': {
      transition: 'all 0.2s linear',
      backgroundColor: theme.palette.grey[100],
    },
  },

  notificationItemIcon: {
    width: '2.8rem',
    height: '2.8rem',
    borderRadius: '100%',
    marginRight: '0.7rem',
    display: 'grid',
    placeItems: 'center',
    flexShrink: 0,
    padding: '0.3rem',
    backgroundColor: (props: any) =>
      props.bg ? `${props.bg}` : theme.palette.grey[300],
  },

  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface IProps {
  notification: Notification;
  setVisible?: (v: boolean) => void;
  markAsRead: (id: string[]) => Promise<void>;
}

const NotificationItem = ({ notification, setVisible, markAsRead }: IProps) => {
  const { log } = useFurComponent(NotificationItem);
  const { purpose, createdAt, thumbnailUrl, pendingReward } = notification;
  const furball = useWalletSelector(
    (s) => s.furballs[notification.furballId ?? ''],
  );
  const { setDilemmaData, setBossRewardRevealData } = useNotifications();
  const classes = useStyles({ bg: furball?.backgroundColor });

  const { addAlert } = useContext(AlertContext);
  const dispatch = useAppDispatch();

  const [showClaimDialog, setShowClaimDialog] = useState<boolean>(false);
  const [claimPendingRewards] = useClaimPendingRewardsMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDilemmaEncounter = () => {
    const title = notification.title;
    const encounterId = notification.data;
    const furballId = notification.furballId;
    const sender = notification.sender;

    if (!!title && !!encounterId && sender && furballId) {
      setDilemmaData({
        title,
        encounterId,
        sender,
        furballId,
        show: true,
        notificationId: notification.id,
      });
    }
  };

  async function handleBossRewardClaim() {
    if (!pendingReward) {
      return;
    }
    const { id, source, items } = pendingReward;

    const seasonal = isSeasonalReward(source);
    const iconSrc = getBossIconSrc(seasonal);
    const bossName = source.split('_').pop()!.toLowerCase();

    const timeUntil =
      new Date(pendingReward.expiresAt).getTime() - new Date().getTime();
    log.info('until', pendingReward.expiresAt, 'is', timeUntil);
    if (timeUntil < 0) {
      await markAsRead([notification.id]);
      return;
    }

    setBossRewardRevealData({
      iconSrc,
      bossName,
      pendingReward,
      itemDefs: getItemDefs(items),
      onClose: () => {
        setBossRewardRevealData(undefined);
      },
    });

    await claimRewards(id);
  }

  async function claimRewards(pendingRewardId: string) {
    setLoading(true);

    try {
      const { data } = await claimPendingRewards({
        variables: { pendingRewardId },
      });
      await markAsRead([notification.id]);
      dispatch(
        WalletSlice.actions.updateUserAfterClaimedRewards({
          ...(data?.claimPendingReward as IClaimedRewards),
        }),
      );
    } catch (error: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Unable to claim rewards',
      });
    }

    setLoading(false);
  }

  const handleClick = async () => {
    if (purpose === NotificationPurpose.PendingReward && !!pendingReward) {
      if (isLeaderboardReward(pendingReward.source)) {
        return await handleBossRewardClaim();
      }

      return setShowClaimDialog(true);
    }

    if (purpose === NotificationPurpose.DilemmaMinigame) {
      handleDilemmaEncounter();
      if (setVisible) setVisible(false);
      return;
    }

    log.info('click', purpose);
    await markAsRead([notification.id]);
  };

  const { days, hours, mins, secs } = getTimeAgo(createdAt, new Date());

  return (
    <>
      <Grid
        container
        alignItems='center'
        flexWrap='nowrap'
        className={classes.notification}
        onClick={handleClick}>
        {loading && (
          <div className={classes.overlay}>
            <CircularProgress color='secondary' size={30} />
          </div>
        )}
        <div className={classes.notificationItemIcon}>
          <img width='100%' height='100%' src={thumbnailUrl as string} />
        </div>
        <div>
          <Grid container item direction='column' gap='0.4rem'>
            <Typography
              variant='body1'
              fontSize='0.75rem !important'
              lineHeight='1.3'>
              {notification.title}
            </Typography>
            <Typography
              fontSize='0.75rem'
              variant='body2'
              color='GrayText'
              lineHeight='1'>
              {days !== 0
                ? `${days} day${days > 1 ? 's' : ''}`
                : hours !== 0
                ? `${hours} hour${hours > 1 ? 's' : ''}`
                : mins !== 0
                ? `${mins} minute${mins > 1 ? 's' : ''}`
                : `${secs} second${secs > 1 ? 's' : ''}`}{' '}
              ago
            </Typography>
          </Grid>
        </div>
      </Grid>
      <NotificationClaimItemsDialog
        notification={notification}
        markAsRead={markAsRead}
        show={showClaimDialog}
        onClose={() => setShowClaimDialog(false)}
        loading={loading}
        setLoading={setLoading}
        claimRewards={claimRewards}
      />
    </>
  );
};

export default NotificationItem;
