import React, { FunctionComponent } from 'react';
import { Button, Tooltip } from '@mui/material';
import Bonded00 from '../../assets/bonding/Bonded00';
import Bonded01 from '../../assets/bonding/Bonded01';
import Bonded02 from '../../assets/bonding/Bonded02';
import Bonded03 from '../../assets/bonding/Bonded03';
import Bonded04 from '../../assets/bonding/Bonded04';
import SvgUnbonded from '../../assets/bonding/SvgUnbonded';

interface IBondedIcon {
  days: number;
  marketListingCount: number;
  className?: string;
  size?: number | string;
}

export const BondedIcon: FunctionComponent<IBondedIcon> = (props) => {
  const { days, className, size, marketListingCount } = props;
  const style = { width: size, height: size };
  const percentBonded = Math.round(Math.min(1, days / 28) * 100);
  const title =
    marketListingCount > 0
      ? 'Bonding broken due to listing for sale!'
      : `${percentBonded}% Bonded (after ${Math.round(days)} days)`;

  function getIcon() {
    if (marketListingCount > 0) return <SvgUnbonded style={style} />;
    if (days < 7) return <Bonded00 style={style} />;
    if (days < 14) return <Bonded01 style={style} />;
    if (days < 21) return <Bonded02 style={style} />;
    if (days < 28) return <Bonded03 style={style} />;
    return <Bonded04 style={style} />;
  }

  return (
    <div className={className}>
      <Tooltip title={title}>
        <Button variant={'text'} style={{ border: 'none', padding: 0 }}>
          {getIcon()}
        </Button>
      </Tooltip>
    </div>
  );
};

export default BondedIcon;
