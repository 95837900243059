import { SvgEditionSlot } from '../part';
import * as React from 'react';

const FrostingPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      fill='#ff6aae'
      d='M87.06 220.81c-8.79 4.67-16.13 11.74-20.9 20.47a45.509 45.509 0 0 0-2.87 6.32c2.42 7.73 1.84 15.33 6.9 15.15 10.54-.38 4.71-20.14 11.29-20.52 7.88-.45 5.8 15.73 12.99 11.67 8.66-4.89 2.12-20.74 13.18-22.21 8.39-1.12 10.15 23.88 13.23 29.03 3.36 5.61 11.86 3.68 8.04-9.34-3.83-13.02-1.35-15.68 2.5-14.08 1.26.53 3.43 3.77 6.12 7.53 6.29 8.79 19.57 8.21 25.02-1.12 3.1-5.3 7.53-9.74 13.05-6.85 9.41 4.94 4.54 15.99 10.59 19.69 2.92 1.79 7.22-1.37 8.47-4.09 1.62-3.53 7.36-1.34 7.13 4.87-.01.36-1.79 14.04-1.62 15.03.32 1.93 1.84 3.52 3.75 3.91 3.14.65 6.1-1.23 6.1-4.26 0-2.61-1.48-7.62-1.66-12-.38-9.31-1.59-14.72 2.75-15.18 7.27-.76-.19 12.39 5.79 14.54 5.2 1.87 2.92-11.21 7.98-9.89 2.87.75-.02 7.92 5.13 7.79 6.82-.17 13.93-20.15 9.55-37.65 9.72-4.18-152.51 1.19-152.51 1.19z'
    />
    <path
      fill='#ff9cc7'
      d='M196.32 216.81c-18.46 0-36.54-5.57-50.92-15.68-6.73-4.73-10.52-9.01-14.21-14.61-8.52.75-8.9 12.76-9.65 13.22 0 0-52.42 26.92-55.09 41.03-.2.49 1.25 16.94 4.03 16.52 5.55-.24.69-20.4 10.73-21.84 3.95-.56 7.81 5.58 10.1 9.64.87 1.54 3.13 1.26 3.63-.43.44-1.46 3.27-18.98 12.24-19.57 13.65-.89 12.63 29.44 16.19 29.84 4.61.52.05-13.08.66-18.64.48-4.31 3.58-6.72 5.59-7.04 7.41-1.21 11.92 16.55 20.07 15.51 11.28-1.45 10.87-17.53 22.46-16.52 17.44 1.51 11.75 22.83 16.02 24.3 3.52.38 4.82-5.72 9.11-5.72 4.02 0 4.85 4.85 6.64 4.45 1.55-.35 1.06-2.81 1.34-4.94.49-3.74 2.4-5.86 5.08-6.21 5.25-.69 8.25 4.42 8.23 6.95 1.03-2.22 2.59-4.57 5.51-4.5 2.19.05 3.7 2.93 4.34 3.7 1.43 1.73 3.81 1.56 5.36-.08 5.31-5.62-1.13-21.88 3.95-25.27 9.73-4.21 5.36-12.83 3.11-16.38-12.75 8.11-26.16 12.27-44.52 12.27z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196.32 216.81c-18.46 0-36.54-5.57-50.92-15.68-6.73-4.73-10.52-9.01-14.21-14.61-5.69 2.13-9.06 6.94-9.61 10.78-.18 1.28-.85 2.43-1.94 3.12l-32.58 20.39c-8.79 4.67-16.13 11.74-20.9 20.47a45.509 45.509 0 0 0-2.87 6.32c2.42 7.73 1.84 15.33 6.9 15.15 10.54-.38 4.71-19.14 11.29-19.52 7.88-.45 5.84 11.81 12.99 10.67 8-1.27 2.12-20.74 13.18-22.21 8.39-1.12 10.15 23.88 13.23 29.03 3.36 5.61 11.86 3.68 8.04-9.34-3.83-13.02-1.35-15.68 2.5-14.08 1.26.53 3.43 3.76 6.12 7.53 6.29 8.79 19.57 8.21 25.02-1.12 3.1-5.3 7.53-9.74 13.05-6.85 9.41 4.94 5.51 16.46 10.24 19.41 3.01 1.88 5.81-.41 7.6-2.82 4.45-6 9.13-1.34 8.02 7.34-.05.35-1.45 10.59-1.29 11.57.32 1.93 1.84 3.52 3.75 3.91 3.14.65 6.1-1.23 6.1-4.26 0-2.61-1.48-7.62-1.66-12-.38-9.31-1.59-14.72 2.75-15.18 7.27-.76-.19 12.39 5.79 14.54 5.2 1.87 2.92-11.21 7.98-9.89 2.87.75-.01 8.15 5.13 7.79 7.14-.5 12.81-17.36 10.4-34.96-.18-1.28.75-2.2 1.56-3.21 4.37-5.41 1.09-11.57-1-14.86-12.75 8.12-26.3 12.57-44.66 12.57z'
    />
    <path fill='#85ff8c' d='m85 227-1 5 5-2z' />
    <path fill='#40b9ff' d='m108 215 5 1-1-5z' />
    <path fill='#85ff8c' d='m230 221-4 3 5 2z' />
    <path fill='#e8ff54' d='m209 221-1 6 5-2zM152 229l-1 7 6-2z' />
    <path fill='#8e46e6' d='m200 229-7 3 5 5z' />
    <path fill='#40b9ff' d='m186 227-2 5 5-1z' />
    <path fill='#ff5487' d='m134 223-4-2 1 5z' />
    <path fill='#ff54bd' d='m154 221-5 3 5 2z' />
    <path fill='#85ff8c' d='m127 200 5-1-4-4z' />
    <path
      fill='#e8ff54'
      d='m228.11 233.34 1.66-.93c.4-.22.91.03.97.48l.27 1.88c.03.16.09.28.21.39l1.4 1.29c.34.31.26.87-.16 1.07l-1.71.84c-.14.06-.25.18-.31.31l-.79 1.72c-.19.41-.75.52-1.07.18l-1.32-1.37c-.1-.1-.25-.18-.4-.19l-1.89-.22c-.45-.05-.73-.56-.5-.96l.89-1.68c.06-.13.09-.28.06-.44l-.38-1.86c-.09-.45.3-.85.76-.78l1.88.32c.15.04.3.02.43-.05z'
    />
    <path
      fill='#ff54bd'
      d='m176.45 219.97 1.52-1.14c.37-.27.9-.09 1.03.35l.51 1.83c.05.15.13.27.26.36l1.56 1.1c.37.26.37.83-.01 1.09l-1.58 1.06a.67.67 0 0 0-.27.35l-.56 1.81a.66.66 0 0 1-1.04.32l-1.49-1.19a.646.646 0 0 0-.42-.14l-1.9.03a.659.659 0 0 1-.63-.88l.67-1.79c.05-.14.05-.29.01-.44l-.62-1.8c-.15-.44.18-.89.66-.87l1.9.08c.13 0 .28-.04.4-.13z'
    />
    <path
      fill='#85ff8c'
      d='M121.02 233.51c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM145.05 239.42c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4zM158.82 218.8c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM202.15 225.51c2.57 0 2.58-4 0-4s-2.58 4 0 4z'
    />
    <path fill='#40b9ff' d='M131.47 215.54c2.57 0 2.58-4 0-4s-2.58 4 0 4z' />
    <path
      fill='none'
      stroke='#e8ff54'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M121.28 216.63s3.54 1.64 2.31 6.68'
    />
    <path
      fill='#8e46e6'
      d='M70.29 248.05c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM142.76 224.57c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4zM119.32 209.82c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4z'
    />
    <path
      fill='#e8ff54'
      d='M137.07 206.83c2.57 0 2.58-4 0-4s-2.58 4 0 4zM200.74 245.23c2.57 0 2.58-4 0-4s-2.58 4 0 4z'
    />
    <path fill='#40b9ff' d='M234.62 217.89c2.57 0 2.58-4 0-4s-2.58 4 0 4z' />
    <path
      fill='none'
      stroke='#e8ff54'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M73.51 239.17s.08-4.18 4.48-5.46'
    />
    <path
      fill='none'
      stroke='#ff54bd'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M125.1 209.98s1.47-4.72 5.63-4.27'
    />
    <path fill='#ff54bd' d='M115.47 225.32c2.57 0 2.58-4 0-4s-2.58 4 0 4z' />
    <path
      fill='#ff5487'
      d='M161.3 228.02c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4z'
    />
    <path
      fill='#ff54bd'
      d='M91.65 238.03c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4z'
    />
    <path
      fill='none'
      stroke='#40b9ff'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M138.27 230.6s3.68-3.21 7.05-.51'
    />
    <path
      fill='none'
      stroke='#ff5487'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M152.04 213.16s-.05 3.9-5.16 4.83M214.29 230.87s.81 3.82-3.98 5.84'
    />
    <path
      fill='none'
      stroke='#e8ff54'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M187.9 223.37s3.58-1.56 6.53 2.71'
    />
    <path
      fill='none'
      stroke='#40b9ff'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M165.14 218.15s3.71 1.22 3.06 6.37'
    />
    <path
      fill='none'
      stroke='#85ff8c'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M188.86 238.1s3.71 1.22 3.06 6.37'
    />
    <path
      fill='none'
      stroke='#ff54bd'
      strokeWidth={2}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M218.6 224.25s1.06-5.64 7.41-5.91'
    />
    <path
      fill='#8e46e6'
      d='M219.56 234.27c2.57 0 2.58-4 0-4-2.57 0-2.58 4 0 4z'
    />
    <path fill='#85ff8c' d='m138 211-1 6 5-2z' />
    <path fill='#8e46e6' d='M147 208h-5l2 5z' />
    <path
      fill='#40b9ff'
      d='m91.79 223.77.16-1.89a.66.66 0 0 1 .94-.54l1.71.83c.14.07.29.08.44.05l1.86-.43c.45-.1.86.27.8.73l-.26 1.89c-.03.15.01.31.08.43l.98 1.62c.23.39.01.91-.45.99l-1.88.32a.7.7 0 0 0-.39.22l-1.24 1.44c-.3.35-.87.29-1.08-.12l-.89-1.68a.693.693 0 0 0-.33-.3l-1.75-.74a.656.656 0 0 1-.21-1.07l1.32-1.37a.58.58 0 0 0 .19-.38z'
    />
    <path
      fill='#ff5487'
      d='M102.85 223.25c2.57 0 2.58-4 0-4-2.57 0-2.57 4 0 4z'
    />
    <path fill='#ff54bd' d='M206.6 233.89c2.57 0 2.58-4 0-4s-2.58 4 0 4z' />
  </svg>
);

export default FrostingPower;
