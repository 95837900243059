import * as React from 'react';

function SvgIconBossHistoryDate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-boss-history-date_svg__a'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      {...props}>
      <defs>
        <style>{'.icon-boss-history-date_svg__b{fill:#410cd6}'}</style>
      </defs>
      <path
        className='icon-boss-history-date_svg__b'
        d='M17.32 3.62c.38 0 .68.31.68.68v13.48a.1.1 0 01-.1.1H2.1a.1.1 0 01-.1-.1V4.3c0-.38.31-.68.68-.68h14.63m0-2H2.68A2.68 2.68 0 000 4.3v13.48c0 1.16.94 2.1 2.1 2.1h15.81a2.1 2.1 0 002.1-2.1V4.3c0-1.48-1.2-2.68-2.68-2.68z'
      />
      <path
        className='icon-boss-history-date_svg__b'
        d='M.88 6.53h18.25v2H.88zM5.25 5.16c-.55 0-1-.45-1-1V1.12c0-.55.45-1 1-1s1 .45 1 1v3.04c0 .55-.45 1-1 1zM14.76 5.16c-.55 0-1-.45-1-1V1.12c0-.55.45-1 1-1s1 .45 1 1v3.04c0 .55-.45 1-1 1z'
      />
      <g>
        <rect
          className='icon-boss-history-date_svg__b'
          x={3.59}
          y={9.91}
          width={3.32}
          height={2.6}
          rx={0.72}
          ry={0.72}
        />
        <rect
          className='icon-boss-history-date_svg__b'
          x={8.34}
          y={9.91}
          width={3.32}
          height={2.6}
          rx={0.72}
          ry={0.72}
        />
        <rect
          className='icon-boss-history-date_svg__b'
          x={13.1}
          y={9.91}
          width={3.32}
          height={2.6}
          rx={0.72}
          ry={0.72}
        />
        <g>
          <rect
            className='icon-boss-history-date_svg__b'
            x={3.59}
            y={13.75}
            width={3.32}
            height={2.6}
            rx={0.72}
            ry={0.72}
          />
          <rect
            className='icon-boss-history-date_svg__b'
            x={8.34}
            y={13.75}
            width={3.32}
            height={2.6}
            rx={0.72}
            ry={0.72}
          />
          <rect
            className='icon-boss-history-date_svg__b'
            x={13.1}
            y={13.75}
            width={3.32}
            height={2.6}
            rx={0.72}
            ry={0.72}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconBossHistoryDate;
