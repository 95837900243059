import { SkillDefinition, SkillStatEffect } from '../../components/schema';
import skillDefinitions from './skillDefinitions.json';

export interface ISkillDefinition {
  id: number;
  name: string;
  summary: string;
  icon: string;
  maxLevel: number;
  maxUsesBoost: number;
  levelPartialCost: number;
  maxUsesPartialCost: number;
  description: string;
  effectBonuses: SkillStatEffect[];
}

// The library can be generated/updated via a GraphQL query, i.e.,
// query {
//   skillDefinitions {
//     id
//     name
//     summary
//     icon
//     maxLevel
//     levelPartialCost
//     maxUsesPartialCost
//     maxUsesBoost
//     description
//     effectBonuses {
//       value
//       valuePerLevel
//     }
//   }
// }

export const SkillLibrary = skillDefinitions.data
  .skillDefinitions as SkillDefinition[];

export function getSkillDefinitionById(
  skillDefinitionId: number,
): ISkillDefinition | undefined {
  return SkillLibrary.find((s) => s.id === skillDefinitionId);
}
