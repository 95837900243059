import React from 'react';
import { ISpinLootCell } from './VoyageGameRealmSpinner';
import {
  getLootItem,
  getLootSlot,
  LOOT_ID_EXP_1_SHOE,
  LOOT_ID_EXP_2_FRISBEE,
  LOOT_ID_EXP_3_LASER_POINTER,
  LOOT_ID_FUR_1_SPRAY,
  LOOT_ID_FUR_2_TILIZER,
  LOOT_ID_FUR_3_GAINE,
} from '../../Loot';

export const turtleSeaSpinCells: ISpinLootCell[] = [
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M774.34 394.08h0v-.56L841 348.38h0v-66.09c0-10.25-9.08-18.26-19.22-16.73-42.02 6.36-78.53 33.3-101.99 71.91-4.63 7.61-2.3 17.55 5.08 22.54l49.47 33.51v.56z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_2_TILIZER)),
    offset: { x: -50, y: 15 },
  },
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M774.35 483.25v-80.22c0-5.6-2.78-10.83-7.41-13.97l-37.97-25.72c-9.06-6.14-21.61-2.21-25.28 8.1-7.38 20.69-11.47 43.39-11.47 67.22s4.09 46.53 11.47 67.22c3.68 10.31 16.22 14.23 25.28 8.1l45.38-30.73h0z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_1_SHOE)),
    offset: { x: -105, y: -100 },
  },
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M774.35 483.81v-.56h0v.56l-49.47 33.51c-7.38 5-9.7 14.93-5.08 22.54 23.46 38.61 59.97 65.55 101.99 71.91 10.13 1.53 19.22-6.48 19.22-16.73V537.9c0-5.6-2.78-10.83-7.41-13.97l-59.25-40.12z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_3_GAINE)),
    offset: { x: -50, y: -200 },
  },
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M907.66 483.25h0v.56L841 528.95h0v66.09c0 10.25 9.08 18.26 19.22 16.73 42.02-6.36 78.53-33.3 101.99-71.91 4.63-7.61 2.3-17.55-5.08-22.54l-49.47-33.51v-.56z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_1_SPRAY)),
    offset: { x: 60, y: -200 },
  },
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M907.66 394.08v80.22c0 5.6 2.78 10.83 7.41 13.97l37.97 25.72c9.06 6.14 21.61 2.21 25.28-8.1 7.38-20.69 11.47-43.39 11.47-67.22s-4.09-46.53-11.47-67.22c-3.68-10.31-16.22-14.23-25.28-8.1l-45.38 30.73h0z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_2_FRISBEE)),
    offset: { x: 105, y: -100 },
  },
  {
    svg: (
      <path
        fill='#FFFB64'
        stroke='#6BB251'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M907.65 393.52v.56h0v-.56l49.47-33.51c7.38-5 9.7-14.93 5.08-22.54-23.46-38.61-59.97-65.55-101.99-71.91-10.13-1.53-19.22 6.48-19.22 16.73v57.14c0 5.6 2.78 10.83 7.41 13.97l59.25 40.12z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_3_LASER_POINTER)),
    offset: { x: 50, y: 15 },
  },
];
