import React from 'react';
import TimekeeperSlice, {
  ITimekeeperInteraction,
  ITimekeeperInteractionBase,
} from '../../components/Timekeeper/TimekeeperSlice';

import { useAppDispatch, useAppSelector } from '../../hooks';
import WalletContext from '../../wallet/WalletContext';
import {
  TimekeeperInteractionAction,
  TimekeeperInteractionArgInput,
  useTimekeeperSaveActionMutation,
} from '../../components/schema';

import { estimateGas } from '../../components/Timekeeper/TimekeeperReq';
import { ApolloError } from '@apollo/client';
import { useFurComponent } from '../../utils';

type SaveInteraction = (
  interaction: ITimekeeperInteraction,
  furballIds: string[],
  action: TimekeeperInteractionAction,
) => Promise<void>;

export function useSaveInteraction(): [
  SaveInteraction,
  boolean,
  ApolloError | undefined,
] {
  const { log } = useFurComponent(useSaveInteraction.name);
  const sender = useAppSelector((s) => s.wallet.address);
  const context = React.useContext(WalletContext);
  const [save, { data, loading, error }] = useTimekeeperSaveActionMutation();
  const dispatch = useAppDispatch();

  const contracts = context?.network.contracts;

  return [
    async (
      oldInteraction: ITimekeeperInteraction,
      furballIds: string[],
      action: TimekeeperInteractionAction,
    ) => {
      if (!sender || !contracts) return;
      const interactionBase: ITimekeeperInteractionBase = {
        expectedValue: oldInteraction.expectedValue ?? null,
        count: oldInteraction.count ?? null,
        interactionType: oldInteraction.interactionType,
        furballIds,
      };
      let gasEstimate = 0;
      try {
        gasEstimate = (
          await estimateGas(interactionBase, contracts)
        ).toNumber();
      } catch (e) {
        console.warn(e, 'gas?');
      }
      try {
        const interaction: TimekeeperInteractionArgInput = {
          ...interactionBase,
          action,
          gasEstimate,
          sender,
          count: null,
        };
        const res = await save({ variables: { interaction } });
        const req = res.data?.timekeeperSaveAction;
        if (!req) throw new Error('No Timekeeper data returned');
        log.info('save', interaction, req);
        dispatch(TimekeeperSlice.actions.setCurrentRequests(req));
      } catch (e) {
        log.error(e, 'save');
      }
    },
    loading,
    error,
  ];
}
