import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  LinearProgress,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useWalletSelector } from '../../hooks';
import {
  CommunityFlag,
  FurOAuthTokenInput,
  useApproveAirdropsMutation,
  useListAirdropRequestsLazyQuery,
} from '../../components/schema';
import useCommonStyles from '../../components/useCommonStyles';
import { signOAuthToken } from '../../components/Auth/OAuthSig';
import WalletContext from '../../wallet/WalletContext';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IAirdropRequestList {}

export const AirdropRequestList: FunctionComponent<IAirdropRequestList> = (
  props,
) => {
  const { log } = useFurComponent(AirdropRequestList);
  const flags = useWalletSelector((s) => s.contents?.communityFlags ?? []);
  const classes = useStyles();
  const common = useCommonStyles();
  const [loadAirdropRequests, airdropRequests] =
    useListAirdropRequestsLazyQuery();
  const [requests, setRequests] = React.useState(
    airdropRequests?.data?.listAirdropRequests ?? [],
  );
  const [approvedRequestIds, setApprovedRequestIds] = React.useState<string[]>(
    [],
  );
  const walletAddress = useWalletSelector((s) => s.address);
  const context = React.useContext(WalletContext);
  const [modify, { data, loading, error }] = useApproveAirdropsMutation();
  const canApprove = flags.includes(CommunityFlag.Admin);

  React.useEffect(() => {
    loadAirdropRequests();
  }, []);

  React.useEffect(() => {
    const newRequests = airdropRequests?.data?.listAirdropRequests ?? [];
    if (newRequests.length > 0) setRequests(newRequests);
  }, [airdropRequests?.data]);

  function setApproved(id: string, approved: boolean) {
    const idx = approvedRequestIds.indexOf(id);
    const reqs = [...approvedRequestIds];
    if (approved && idx < 0) {
      reqs.push(id);
    } else if (!approved && idx >= 0) {
      reqs.splice(idx, 1);
    }
    setApprovedRequestIds(reqs);
  }

  async function approveAirdrops() {
    if (!walletAddress || !context) return;
    const access = approvedRequestIds.length;
    const token: FurOAuthTokenInput = await signOAuthToken(
      walletAddress,
      context,
      access,
    );
    try {
      const res = await modify({
        variables: { args: approvedRequestIds, token },
      });
      log.info('approved all', res);
    } catch (e) {
      log.error(e, 'Edit');
    }
  }

  if (!canApprove) return null;

  return (
    <Grid container className={clsx(classes.root)}>
      {airdropRequests?.loading && <LinearProgress />}
      {requests.map((req, i) => {
        const approved = approvedRequestIds.includes(req.id);
        return (
          <React.Fragment key={req.id}>
            <Grid item xs={4}>
              {req.recipient}
            </Grid>
            <Grid item xs={3}>
              {req.fur} $FUR
            </Grid>
            <Grid item xs={3}>
              {req.tix} $TIX
            </Grid>
            <Grid item xs={2}>
              <FormControlLabel
                // disabled
                control={
                  <Checkbox
                    color={'secondary'}
                    disabled={!canApprove}
                    // className={classes.checkbox}
                    checked={approved}
                    onChange={(e) => setApproved(req.id, !approved)}
                  />
                }
                label={approved ? 'Approved' : 'Not Approved'}
              />
            </Grid>
          </React.Fragment>
        );
      })}
      <Grid item xs={12} lg={8}></Grid>
      <Grid item xs={12} lg={4}>
        <Button
          color={'primary'}
          variant={'contained'}
          className={common.actionButton}
          onClick={approveAirdrops}>
          Approve Selected
        </Button>
      </Grid>
    </Grid>
  );
};

export default AirdropRequestList;
