import React, { FunctionComponent } from 'react';
import { Grid, Theme, Typography, Button, Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';
import UpgradeButton from './UpgradeButton';
import { useLocation, useParams } from 'react-router-dom';
import { ITransactionError } from '../../wallet/WalletTypes';
import TransactionErrorDialog from '../../wallet/TransactionErrorDialog';
import { useAnimationFrames } from '../../components/useAnimation';
import { getRarityName } from '../Play/helpers';

import { cdnRoot } from '../../theme';
import { usePageTitle } from '../../Analytics';
import { RarityLevel } from '../../components/schema';
import { getKeySvgByItemDefIcon } from '../../components/Inventory/InventorySlot';
import { slugify } from '../../utils';
import { itemLibrary } from '../../components/Inventory/ItemLibrary';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundImage: `url(${cdnRoot}/svgs/upgrade-bk.svg)`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    paddingTop: '1.5rem',
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  title: {
    textAlign: 'center',
    padding: '20px',

    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },

  machine: {
    backgroundImage: `url(${cdnRoot}/svgs/upgrade-machine.svg)`,
    backgroundPosition: 'bottom left',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    left: '80px',
    width: '100%',
    height: '600px',

    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      padding: '0 1.5rem',
      backgroundPosition: 'center',
      transform: 'scale(0.9)',
    },
  },

  spin: {
    backgroundImage: `url(${cdnRoot}/svgs/upgrade-spin.svg)`,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '400px',
    width: '400px',
    position: 'absolute',
    bottom: '80px',
    left: '40px',
    display: 'grid',
    placeItems: 'center',

    [theme.breakpoints.down('sm')]: {
      height: '245px',
      width: '245px',
      bottom: '167px',
      left: '37px',
    },
  },

  spin1: {
    transform: 'rotate(90deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  spin2: {
    transform: 'rotate(180deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  spin3: {
    transform: 'rotate(270deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  spin4: {
    transform: 'rotate(360deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  spin5: {
    transform: 'rotate(0deg)',
  },
  lootIcon: {
    width: '14rem',
    height: '14rem',
    display: 'grid',
    placeItems: 'center',

    '& > div': {
      padding: '1rem',
      width: '100% !important',
      height: '100% !important',
    },

    '& svg': {
      padding: '1rem',
      width: '90% !important',
      height: '90% !important',
    },
  },

  controls: {
    position: 'absolute',
    top: '12px',
    left: '100px',
    maxWidth: '340px',

    [theme.breakpoints.down('sm')]: {
      left: 0,
      maxWidth: 'unset',
      width: '100%',
    },
  },

  chanceButton: {
    border: '0px !important',
    color: '#000 !important',
    fontSize: '1.6rem !important',
    textAlign: 'center',
    marginBottom: '14px !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem !important',

      '&:first-child': {
        marginTop: '50px !important',
      },

      '&:last-child': {
        marginTop: '7px !important',
      },
    },
  },

  basket: {
    backgroundImage: `url(${cdnRoot}/svgs/upgrade-basket.svg)`,
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    height: '410px',
    width: '400px',
    textAlign: 'center',
    paddingTop: '12px',

    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      bottom: '7rem',
      right: '-100px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      position: 'relative',
      top: '-10rem',
      transformOrigin: 'center',
    },
  },

  furballImg: {
    width: '350px',
    height: '350px',
    position: 'absolute',
    top: '-290px',
    left: '0px',
  },

  figContainer: {
    display: 'flex',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      marginTop: '-1rem',
    },
  },

  upgradeButton: {
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '90px',
      left: '250px',
      transform: 'scale(0.85)',
    },
  },
}));

interface OwnProps {}

type Props = OwnProps;

interface IParams {
  lootName?: string;
}

const Upgrade: FunctionComponent<Props> = (props) => {
  const common = useCommonStyles();
  const classes = useStyles();
  const params = useParams<IParams>();
  const location = useLocation();
  const name = params.lootName;

  const lootItem = itemLibrary.find((d) => slugify(d.name) === name);
  const lootId = new URLSearchParams(location.search).get('id');

  const [chances, setChances] = React.useState(0);
  const [error, setError] = React.useState<ITransactionError | undefined>();
  const [loading, setLoading] = React.useState(false);

  const frames = useAnimationFrames(
    [classes.spin1, classes.spin2, classes.spin3, classes.spin4, classes.spin5],
    loading,
  );
  const chanceCost = 5000;

  const rarityLevelIndexes = {
    [RarityLevel.Common]: 0,
    [RarityLevel.Elite]: 1,
    [RarityLevel.Mythic]: 2,
    [RarityLevel.Legendary]: 3,
    [RarityLevel.Ultimate]: 4,
    [RarityLevel.NumRarities]: 5,
  };

  usePageTitle(lootItem?.name ? `${lootItem?.name} | Upgrade Loot` : undefined);
  const isMaxUpgrades = lootItem && rarityLevelIndexes[lootItem.rarity] >= 3;

  function renderChanceButton(cnt: number): React.ReactNode {
    return (
      <Button
        variant={'text'}
        className={classes.chanceButton}
        onClick={() => setChances(cnt)}>
        {cnt}x Chance{cnt === 1 ? '' : 's'} (
        {(cnt * chanceCost).toLocaleString()} $
        <span style={{ textTransform: 'lowercase' }}>w</span>FUR)
      </Button>
    );
  }

  function getTitle() {
    if (isMaxUpgrades) return 'Max Upgrades Reached!';
    if (lootItem && chances > 0) return 'Attempt to Upgrade?';
    return 'Take a Spin!';
  }

  function getSubtitle() {
    if (isMaxUpgrades) return `${lootItem.name} is already ${lootItem.rarity}!`;
    if (chances > 0) return 'Click the power button!';
    return '';
  }

  if (!lootItem) {
    return null;
  }

  return (
    <Grid container className={clsx(classes.root, common.fullScreen)}>
      <Grid item xs={12} className={classes.title}>
        <Typography variant={'h3'}>{getTitle()}</Typography>
        <Typography variant={'subtitle2'}>{getSubtitle()}</Typography>
      </Grid>

      <div className={classes.figContainer}>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{ position: 'relative', padding: '20px' }}>
          <div className={classes.basket}>
            {renderChanceButton(1)}
            {renderChanceButton(
              rarityLevelIndexes[lootItem.rarity] === 2 ? 10 : 5,
            )}
            {renderChanceButton(
              rarityLevelIndexes[lootItem.rarity] === 2 ? 30 : 10,
            )}
          </div>
        </Grid>

        <Grid item xs={12} md={8} lg={6}>
          {lootItem && (
            <div className={classes.machine}>
              <div className={clsx(classes.spin, frames)}>
                <div className={classes.lootIcon}>
                  {lootItem.name.includes('Key') ? (
                    getKeySvgByItemDefIcon(lootItem.icon)
                  ) : (
                    <Avatar
                      src={`${cdnRoot}/images/icons/boss_loot/${lootItem.icon}.png`}
                    />
                  )}
                </div>
              </div>
              {chances > 0 && rarityLevelIndexes[lootItem.rarity] < 3 && (
                <Grid container className={classes.controls}>
                  <Grid item xs={10}>
                    <Typography variant={'h5'}>
                      {chances} Spin{chances === 1 ? '' : 's'} (
                      {(chances * chanceCost).toLocaleString()} $wFUR)
                    </Typography>
                    <Typography variant={'subtitle2'} pr='0.5rem'>
                      Each spin of the machine increases the odds of an upgrade
                      to a{' '}
                      {getRarityName(rarityLevelIndexes[lootItem.rarity] + 1)}{' '}
                      item.
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.upgradeButton}>
                    {lootId && (
                      <UpgradeButton
                        lootId={lootId}
                        lootItem={lootItem}
                        chances={chances}
                        setLoading={(loading) => setLoading(loading)}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        </Grid>
      </div>

      <Grid item xs={1} md={2} lg={3} />
      <TransactionErrorDialog
        error={error}
        onClose={() => setError(undefined)}
      />
    </Grid>
  );
};

export default Upgrade;
