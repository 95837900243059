import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { SkillLevelControl } from './SkillLevelControl';
import { SkillMaxUsesControl } from './SkillMaxUsesControl';
import { EntitySkill, Specialization } from '../../components/schema';
import { getTimeRemaining } from '../../utils';
import AlertProvider from '../../components/Alert/AlertProvider';
import { ICooldownRemaining, SkillType } from './SkillTypes';
import { RollASkill } from './RollASkill';
import { useLoadedFurball } from '../../wallet';
import SkillConfirmChange from './SkillConfirmChange';
import { getSkillDefinitionById } from './SkillLibrary';
import WalletContext from '../../wallet/WalletContext';
import { NetworkType } from '../../wallet/WalletTypes';
import { isSkillUpgradeable } from './SkillsCarousel';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    minHeight: '550px',
    position: 'relative',
    top: '-1.2rem',
    transition: 'all 0.2s linear',
    width: '450px',
    display: 'grid',
    placeItems: 'center',

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
    },

    [theme.breakpoints.down('sm')]: {
      width: '95%',
      margin: '0 auto',
    },
  },

  descriptionWrap: {
    gap: '0.8rem',
    minHeight: '205px',
    marginBottom: '15px',
    padding: '0.25rem 1rem',
    justifyContent: 'flex-end !important',
  },

  descriptionText: {
    textAlign: 'center',
    fontSize: '0.8rem !important',
    minHeight: '39px',
  },

  totalStats: {
    width: '100%',
    backgroundColor: '#3832A0',
    color: 'white !important',
    alignItems: 'stretch !important',
    padding: '0.5rem 1rem 0.75rem',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },

  total: {
    textTransform: 'uppercase',
    textAlign: 'center',
  },

  totalStatBoxes: {
    maxWidth: '315px',
    marginLeft: 'auto',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
    },
  },

  totalStatBox: {
    padding: '0.3rem 1.5rem',
    backgroundColor: 'rgba(26, 2, 115, 0.3)',
    border: '1px solid #7986CB',
    borderRadius: '2px',
    width: '100%',
  },

  totalStatText: {
    lineHeight: 1,
    fontSize: '0.8rem !important',
  },
}));

interface IProps {
  skills: EntitySkill[];
  currentTab: number;
  furballId: string;
  showReroll: boolean;
  setSkills: (skills: EntitySkill[]) => void;
  updateSkills: (skill: EntitySkill) => void;
  setDisabled: (value: boolean) => void;
  toggleReroll: () => void;
  specialization: Specialization;
}

const UPGRADE_COOLDOWN_DURATION = 86400000; // 24 hours

export const SkillUpgradeBox: FunctionComponent<IProps> = ({
  skills,
  setSkills,
  currentTab,
  furballId,
  updateSkills,
  setDisabled,
  showReroll,
  toggleReroll,
  specialization,
}) => {
  // const context = React.useContext(WalletContext);
  // const isMainNet = context?.network.type === NetworkType.MainNet;
  const furball = useLoadedFurball(furballId);

  const [levelPartials, setLevelPartials] = useState<number>(0);
  const [maxUsesPartials, setMaxusesPartials] = useState<number>(0);
  const [levelPartialsSelected, setLevelPartialsSelected] = useState<number>(0);
  const [maxUsesPartialsSelected, setMaxusesPartialsSelected] =
    useState<number>(0);
  const [currentSkill, setCurrentSkill] = useState<EntitySkill>(
    skills?.[currentTab],
  );
  const [numLevelsUpgraded, setNumLevelsUpgraded] = React.useState(0);
  const [numUsesUpgraded, setNumUsesUpgraded] = React.useState(0);
  const [loadingLevel, setLoadingLevel] = useState(false);
  const [loadingUses, setLoadingUses] = useState(false);
  const [totalFurCost, setTotalFurCost] = React.useState(0);
  const [error, setError] = useState<string | undefined>(undefined);

  // Allow infinite upgrades on test nets
  const networkType =
    React.useContext(WalletContext)?.network.type ?? NetworkType.MainNet;
  const maxDailyUpgrades =
    networkType != NetworkType.MainNet
      ? 9999
      : furball?.skillUpgradesAvailable ?? 0;

  const appliedLevelPartialsCount = React.useMemo(() => {
    const purchasedLevels =
      currentSkill?.levelNumPartialsPerTier * currentSkill?.level +
      currentSkill?.levelPartialUpgrade;
    return Math.max(0, levelPartials - purchasedLevels);
  }, [levelPartials, currentSkill]);

  const appliedMaxUsesPartialsCount = React.useMemo(() => {
    const purchasedMaxUses =
      currentSkill?.maxUsesNumPartialsPerTier * currentSkill?.maxUsesBoost +
      currentSkill?.maxUsesPartialUpgrade;
    return Math.max(0, maxUsesPartials - purchasedMaxUses);
  }, [maxUsesPartials, currentSkill]);

  const classes = useStyles();

  function isOnCooldown(type: SkillType): boolean {
    const upgradedAt =
      type === SkillType.Level
        ? currentSkill.levelUpgradedAt
        : currentSkill.maxUsesUpgradedAt;
    if (!upgradedAt) return false;

    const now = new Date().getTime();
    const upgradedAtTime = new Date(upgradedAt).getTime();

    return now - upgradedAtTime <= UPGRADE_COOLDOWN_DURATION;
  }

  function getCooldownRemaining(upgradedAt: number): ICooldownRemaining {
    const upgradeStartTime = new Date(upgradedAt).getTime();

    const { days, hours, mins, secs } = getTimeRemaining(
      upgradeStartTime,
      UPGRADE_COOLDOWN_DURATION,
    );

    return { hours, mins };
  }

  function setPartialsSelected(amount: number, type: SkillType) {
    if (amount <= maxDailyUpgrades) {
      if (type === SkillType.Level) {
        setLevelPartialsSelected((prevState) => (prevState += amount));
      }

      if (type === SkillType.Uses) {
        setMaxusesPartialsSelected((prevState) => (prevState += amount));
      }
    }
  }

  function getNumTiersUpgraded(
    partials: number,
    cur: number,
    perTier: number,
  ): number {
    const totalPartials = cur + partials;
    return Math.floor(totalPartials / perTier);
  }

  useEffect(() => {
    setLevelPartialsSelected(0);
    setMaxusesPartialsSelected(0);
    setCurrentSkill(skills[currentTab]);
  }, [currentTab, skills]);

  useEffect(() => {
    if (!skills[currentTab]) return;

    setNumLevelsUpgraded(
      !currentSkill
        ? 0
        : getNumTiersUpgraded(
            appliedLevelPartialsCount,
            currentSkill?.levelPartialUpgrade,
            currentSkill?.levelNumPartialsPerTier,
          ),
    );
    setNumUsesUpgraded(
      !currentSkill
        ? 0
        : getNumTiersUpgraded(
            appliedMaxUsesPartialsCount,
            currentSkill?.maxUsesPartialUpgrade,
            currentSkill?.maxUsesNumPartialsPerTier,
          ),
    );

    // return () => {
    //   if (showReroll) {
    //     toggleReroll();
    //   }
    // };
  }, [currentTab, levelPartials, maxUsesPartials]);

  useEffect(() => {
    const levelsCost =
      appliedLevelPartialsCount * currentSkill?.levelPartialCost;
    const usesCost =
      appliedMaxUsesPartialsCount * currentSkill?.maxUsesPartialCost;
    setTotalFurCost(levelsCost + usesCost);
  }, [levelPartials, maxUsesPartials]);

  function renderSkill() {
    if (
      !currentSkill ||
      (showReroll && isSkillUpgradeable(currentSkill.slotIndex, specialization))
    ) {
      return (
        <RollASkill
          tokenId={furballId}
          skills={skills}
          slotIndex={currentSkill ? currentSkill.slotIndex : 2}
          updateSkills={updateSkills}
          setDisabled={setDisabled}
          toggleReroll={toggleReroll}
        />
      );
    }

    if (!currentSkill) return null;
    const def = getSkillDefinitionById(currentSkill.skillDefinitionId);

    return (
      <>
        <div style={{ overflow: 'hidden' }}>
          <Grid
            container
            direction='column'
            justifyContent='flex-start'
            alignItems='center'
            className={classes.descriptionWrap}>
            <Typography
              className={classes.descriptionText}
              color='#fff'
              fontWeight='500'
              fontSize='0.9rem'
              maxWidth='350px'
              textAlign='center'>
              {def?.description || '...'}
              {currentSkill && (
                <>
                  {` Effect: ${currentSkill?.value}${currentSkill?.valueUnits}`}
                  .
                </>
              )}
            </Typography>
          </Grid>
        </div>

        {currentSkill && (
          <>
            <SkillLevelControl
              label='Skill Level'
              furballId={furballId}
              skill={currentSkill}
              partials={levelPartials}
              setPartials={setLevelPartials}
              partialsSelected={levelPartialsSelected}
              setPartialsSelected={setPartialsSelected}
              isLoading={loadingLevel}
              setLoading={setLoadingLevel}
              setError={setError}
              isOnCooldown={isOnCooldown}
              getCooldownRemaining={getCooldownRemaining}
              numUpgraded={numLevelsUpgraded}
              maxDailyUpgrades={maxDailyUpgrades - maxUsesPartialsSelected}
            />
            <SkillMaxUsesControl
              label='# of Uses'
              furballId={furballId}
              skill={currentSkill}
              partials={maxUsesPartials}
              setPartials={setMaxusesPartials}
              partialsSelected={maxUsesPartialsSelected}
              setPartialsSelected={setPartialsSelected}
              isLoading={loadingUses}
              setLoading={setLoadingUses}
              setError={setError}
              isOnCooldown={isOnCooldown}
              getCooldownRemaining={getCooldownRemaining}
              numUpgraded={numUsesUpgraded}
              maxDailyUpgrades={maxDailyUpgrades - levelPartialsSelected}
            />
          </>
        )}

        {error && (
          <Typography
            variant={'subtitle2'}
            style={{ color: 'red', textAlign: 'center', padding: 4 }}>
            {error}
          </Typography>
        )}

        <Grid container alignItems='center' className={classes.totalStats}>
          <Grid item>
            <Typography
              fontWeight='600'
              className={classes.total}
              variant='body2'>
              Total
            </Typography>
          </Grid>

          <Grid item>
            <Grid className={classes.totalStatBoxes} container rowGap='0.5rem'>
              <Grid item className={classes.totalStatBox}>
                <Grid container>
                  <Grid
                    item
                    justifyContent='center'
                    alignItems='center'
                    gap='0.2rem'>
                    <Typography
                      fontWeight={800}
                      className={classes.totalStatText}>
                      +{numUsesUpgraded} <span>MAX-USES</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.totalStatBox}>
                <Grid container>
                  <Grid
                    item
                    justifyContent='center'
                    alignItems='center'
                    gap='0.2rem'>
                    <Typography
                      fontWeight={800}
                      className={classes.totalStatText}>
                      +{numLevelsUpgraded} <span>LVL</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.totalStatBox}>
                <Grid container>
                  <Grid
                    item
                    justifyContent='center'
                    alignItems='center'
                    gap='0.2rem'>
                    <Typography
                      fontWeight={800}
                      className={classes.totalStatText}>
                      {totalFurCost.toLocaleString()} <span>$wFUR</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div>
          {currentSkill && (
            <AlertProvider>
              {
                <SkillConfirmChange
                  skillId={currentSkill.id}
                  furballId={furballId}
                  levelPartials={appliedLevelPartialsCount}
                  maxUsesPartials={appliedMaxUsesPartialsCount}
                  totalFurCost={totalFurCost}
                  setSkills={setSkills}
                />
              }
            </AlertProvider>
          )}
          {/*<Typography*/}
          {/*  style={{*/}
          {/*    textAlign: 'center',*/}
          {/*    color: '#A5B4FC',*/}
          {/*    fontSize: '0.725rem',*/}
          {/*    opacity: 0.9,*/}
          {/*    fontWeight: 400,*/}
          {/*    marginTop: '1rem',*/}
          {/*  }}>*/}
          {/*  You may upgrade {dailyUpgradesRemaining} more times. <br />*/}
          {/*  Fight bosses to be able to upgrade more!*/}
          {/*</Typography>*/}
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.contentWrapper}>{renderSkill()}</div>
    </>
  );
};
