import { Container, Grid, Tab, Tabs, Theme, Typography } from '@mui/material';
import * as React from 'react';
import TicketPurchaseBlockchain from './TicketPurchaseBlockchain';
import WalletContext from '../../wallet/WalletContext';
import TicketPurchaseApp from './TicketPurchaseApp';
import TicketPurchaseCreditCard from './TicketPurchaseCreditCard';
import { TabContext, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';
import themev2, { white } from '../../themev2';
import CdnLink from '../../components/Navigation/CdnLink';
import walletSlice from '../../wallet/WalletSlice';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { useFurComponent } from '../../utils';
import { useFurverseContext } from '../Furverse/Furverse';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    zIndex: 1,
    width: '100%',
    maxHeight: 40,
  },
  tab: {
    maxHeight: 40,
    textTransform: 'uppercase',
    border: 'none !important',
    fontSize: '18px !important',
    borderTopRightRadius: '7px !important',
    borderTopLeftRadius: '7px !important',

    [theme.breakpoints.down('lg')]: {
      fontSize: '16px !important',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
      minWidth: '70px !important',
    },

    '@media (max-width: 395px)': {
      fontSize: '13px !important',
      lineHeight: '13px !important',
    },
  },
}));
interface ITicketPurchaseForm {
  currency?: TixBuyCurrency;
}

type TixBuyCurrency = 'USD' | 'ETH';
export interface IPurchasedTickets {
  onPurchased: (tix: number) => void;
}

const TicketPurchaseForm: React.FunctionComponent<ITicketPurchaseForm> = (
  props,
) => {
  const { currency } = props;
  const classes = useStyles();
  const { log } = useFurComponent(TicketPurchaseForm);
  const context = React.useContext(WalletContext);
  const { vuplexReady } = useFurverseContext();
  const [tab, setTab] = React.useState<TixBuyCurrency>('ETH');
  const dispatch = useAppDispatch();
  const tix = useWalletSelector((c) => c.contents?.tix ?? 0);

  function handlePurchase(tixPurchased: number): void {
    const newTix = tix + tixPurchased;
    log.info('[TIX] completed purchase +', tix, '=>', newTix);

    dispatch(walletSlice.actions.setTixBalance(newTix));
  }

  function renderTabs(nodes: {
    [key: string]: React.ReactNode;
  }): React.ReactNode {
    return (
      <TabContext value={tab}>
        <Tabs
          variant={'fullWidth'}
          value={tab}
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          onChange={(e, v) => setTab(v)}>
          {Object.keys(nodes).map((t) => {
            const { main, light, dark } = themev2.palette.secondary;
            const tabStyles = {
              backgroundColor: tab === t ? white : main,
              color: `${tab === t ? dark : white} !important`,
              fontWeight: `${700} !important`,
            };
            return (
              <Tab
                key={t}
                color={'secondary'}
                className={classes.tab}
                label={`${t === tab ? '(Buying With' : 'Switch To'} ${t}${
                  t === tab ? ')' : ''
                }`}
                value={t}
                sx={tabStyles}
              />
            );
          })}
        </Tabs>
        {Object.keys(nodes).map((t) => {
          return (
            <TabPanel key={t} value={t} style={{ paddingTop: 40 }}>
              {nodes[t]}
            </TabPanel>
          );
        })}
      </TabContext>
    );
  }
  function renderForm(): React.ReactNode {
    if (vuplexReady) return <TicketPurchaseApp onPurchased={handlePurchase} />; // app ONLY allows IAPs
    if (!context)
      return <TicketPurchaseCreditCard onPurchased={handlePurchase} />;
    // return renderTabs({
    //   ETH: <TicketPurchaseBlockchain onPurchased={handlePurchase} />,
    //   USD: <TicketPurchaseCreditCard onPurchased={handlePurchase} />,
    // });
    return currency === 'ETH' ? (
      <TicketPurchaseBlockchain onPurchased={handlePurchase} />
    ) : (
      <TicketPurchaseCreditCard onPurchased={handlePurchase} />
    );
  }

  return (
    <Grid container spacing={2} maxWidth={'sm'}>
      <Grid item xs={12} style={{ paddingTop: 40 }}>
        {renderForm()}
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth={'md'}>
          <Typography
            variant={'subtitle2'}
            color={'gray'}
            style={{ marginTop: 40, textAlign: 'center' }}>
            (TIX are used instead of $USD in-game)
            <br />
            Here's our{' '}
            <CdnLink to={'/downloads/terms-of-service.pdf'}>
              Terms of Service
            </CdnLink>{' '}
            and{' '}
            <CdnLink to={'/downloads/privacy-policy.pdf'}>
              Privacy Policy
            </CdnLink>
            .
          </Typography>
        </Container>
      </Grid>
    </Grid>
  );
};

export default TicketPurchaseForm;
