import React, { FunctionComponent } from 'react';
import { IUnityContextHook } from 'react-unity-webgl/distribution/interfaces/unity-context-hook';
import {
  BattleMoveResponseFragment,
  useGameBattleLoopSubscription,
} from '../../../components/schema';
import { SocketClient } from '../../../FurballClient';
import { useFurverseContext } from '../Furverse';

interface IGameBattleSubscription {
  gameBattleId: string;
  unityContext: IUnityContextHook;
  onLoaded: () => void;
}

const GameBattleSubscription: FunctionComponent<IGameBattleSubscription> = (
  props,
) => {
  const { gameBattleId, onLoaded, unityContext } = props;
  const { setCurrentBattle } = useFurverseContext();
  // const { data } = Apollo.useSubscription<
  //   BossBattleGameLoopSubscription,
  //   BossBattleGameLoopSubscriptionVariables
  //   >(Apollo.gql(doc), { variables: { bossBattlePlayerId }});
  const { data } = useGameBattleLoopSubscription({
    variables: { gameBattleId },
    context: { clientName: SocketClient },
  });
  const move = data?.gameBattleLoop as BattleMoveResponseFragment;

  // ONLY fired by the web client!
  function onMove(move: BattleMoveResponseFragment) {
    if (!unityContext) return;
    if (unityContext.loadingProgression < 1) return;
    unityContext.sendMessage(
      'ClientBridge',
      'OnBossBattleMove',
      JSON.stringify(move),
    );
    if (move.bossBattle) setCurrentBattle(move.bossBattle);
    if (move.monsterBattle) setCurrentBattle(move.monsterBattle);
    if (move.duelBattle) setCurrentBattle(move.duelBattle);
  }

  React.useEffect(() => {
    if (move) onMove(move);
  }, [move]);

  React.useEffect(() => {
    onLoaded();
  }, []);

  return null;
};

export default GameBattleSubscription;
