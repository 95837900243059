import React, { FunctionComponent } from 'react';
import { IToken } from '../../wallet/WalletTypes';
import { useParams } from 'react-router-dom';
import { useFurballQuery } from '../../components/schema';
import { normalizeTokenId } from '../../components/Furballs/Furball';
import { parseFurball } from '../../wallet';
import FurballWidget from './FurballWidget';
import { usePageTitle } from '../../Analytics';
import { inIframe } from '../../wallet/Wallet';
import { useFurComponent } from '../../utils';

interface IUnknown {}

type Props = IUnknown;

export const EmbeddedFurball: FunctionComponent<Props> = () => {
  const params = useParams<IToken>();
  const tokenId = normalizeTokenId(params.tokenId);
  const { log } = useFurComponent(EmbeddedFurball);
  const { data, error } = useFurballQuery({ variables: { tokenId } });

  usePageTitle(`Furball | ${inIframe() ? 'Embedded' : 'Widget'}`);

  React.useEffect(() => {
    if (error) {
      log.warn('failed to load furball', error);
    } else {
      log.info('loaded widget furball', data);
    }
  }, [error, data]);

  const furball = data?.furball ? parseFurball(data?.furball) : undefined;
  const size = 'calc(75vmin)';

  return <FurballWidget furball={furball} tokenId={tokenId} size={size} />;
};

export default EmbeddedFurball;
