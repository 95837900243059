import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Racoon = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M73.16 234.45s-6.43-4.19.88-19.55 27.52-22.22 29.07-45.06c1.56-22.84-30.82-40.28-30.82-40.28s-4.08-2.37-12.26-3.06c0 0 7.3 8.52 7.79 12.53 0 0-7.2-1.84-8.93-2.03 0 0 11.22 16.9-10.89 39.2s-21.05 52.9 8.73 74.58 21.13-8.85 21.13-8.85l-4.7-7.48'
    />
    <path
      fill={props.renderer.color1}
      d='M72.3 129.57s-4.08-2.37-12.26-3.06c0 0 5.83 8.09 6.32 12.11 0 0-5.73-1.41-7.47-1.6 0 0 5.29 6.58 1.79 19.02 6.98-4.26 26.15-11.49 41.73 18.96.34-1.63.59-3.34.71-5.16 1.56-22.83-30.82-40.27-30.82-40.27zM75.87 170.16c-9.12-3.34-17.32-.86-22.47 1.71-1.22 1.49-13.7 13.71-16.34 19.02 5.95-5.09 16.46-9.63 27.29-5.87 14.19 4.92 17.09 14.54 17.66 18.98 4.1-4.31 8.72-8.39 12.61-13.22-1.79-5.03-6.98-16.31-18.75-20.62zM71.01 223.43c.47-2.24 1.35-4.9 2.8-8.04-3.05-4.18-9.6-10.96-20.22-11.2-11.73-.27-17.1 10.98-18.99 16.34 1.4 5.8 4.12 11.51 8.26 16.94 1.26-4.41 4.12-11.28 10.3-15.34 7.81-5.12 15.29-.59 17.85 1.3z'
    />
    <path
      opacity={0.3}
      d='M65.12 244.28c-22.25-6.8-34.72-43.34-14.61-70.73-26.81 26.05-22.23 56.52 6.23 77.24 24.27 17.67 23.01.66 21.72-6.24-3.18-.96-6.84 1.72-13.34-.27z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M73.16 234.45s-6.43-4.19.88-19.55 28.99-22.16 29.07-45.06c.1-27.82-34.9-42.64-43.08-43.33 0 0 8.73 9.25 9.23 13.27 0 0-8.63-2.57-10.37-2.76 0 0 10.21 16.9-11.89 39.2s-20.05 52.9 9.73 74.58 16.43-16.35 16.43-16.35'
    />
  </svg>
);

export default Racoon;
