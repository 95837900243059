import React from 'react';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgBunny from '../../assets/SvgBunny';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 10000,
    position: 'fixed',
    inset: 0,
    width: '100%',
    height: 'calc(100vh)',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#0A1062',
    padding: '2rem 7%',
  },

  bunny: {
    margin: '0 auto 0.5rem',
    height: '150px',
    width: '150px',
  },

  button: {
    marginTop: '0.7rem !important',
    minWidth: '9rem !important',
    height: '2.8rem !important',
  },
}));

interface INoticeProps {
  title: string;
  subTitle: string;
  button: React.ReactNode;
  hideImage?: boolean;
  action?: () => void;
}

export const Notice = ({
  title,
  subTitle,
  action,
  button,
  hideImage,
}: INoticeProps) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      direction='column'
      className={classes.root}>
      {!hideImage && (
        <div className={classes.bunny}>
          <SvgBunny />
        </div>
      )}

      <Typography variant='h2' color='#fff' fontWeight='800' textAlign='center'>
        {title}
      </Typography>
      <Typography variant='body1' color='#fff' textAlign='center'>
        {subTitle}
      </Typography>

      {typeof button === 'string' ? (
        <Button variant='contained' className={classes.button} onClick={action}>
          <Typography variant='h6'>{button}</Typography>
        </Button>
      ) : (
        <>
          <br />
          {button}
        </>
      )}
    </Grid>
  );
};

export default Notice;
