import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FunctionComponent } from 'react';
import { LootSlot } from './LootSlot';
import { ILootSlot } from './LootTypes';

type Props = {
  slot: ILootSlot;
};

const useStyles = makeStyles((theme: Theme) => ({
  slotContainer: {
    border: '3px solid black',
    borderRadius: '16px',
    padding: '0.8rem',
  },
}));

export const LootSlotWide: FunctionComponent<Props> = (props) => {
  const { slot } = props;
  const classes = useStyles();

  return (
    <Grid container className={classes.slotContainer} alignItems='center'>
      <Grid item xs={3}>
        <LootSlot {...slot} />
      </Grid>
      <Grid item xs={8}>
        {slot.item && (
          <Typography variant={'h5'}>{slot.rarityName} Loot!</Typography>
        )}
        <Typography variant={'h6'}>
          {slot.item.name} (+{slot.boost}% {slot.item.stat?.toUpperCase() ?? ''}
          )
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LootSlotWide;
