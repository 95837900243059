import * as React from 'react';

function SvgIconLootNone(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-loot-none_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 55.68 55.68'
      xmlSpace='preserve'
      {...props}>
      <style>{'.icon-loot-none_svg__st0{fill:#ededed}'}</style>
      <path
        className='icon-loot-none_svg__st0'
        d='M35.68 29.26v4.77c.83.3 1.44 1.1 1.44 2.04v1.97c0 1.2-.98 2.17-2.17 2.17h-1c-1.2 0-2.17-.98-2.17-2.17v-1.97c0-.94.6-1.73 1.44-2.04v-4.77H22.48v4.77c.83.3 1.44 1.1 1.44 2.04v1.97c0 1.2-.98 2.17-2.17 2.17h-1c-1.2 0-2.17-.98-2.17-2.17v-1.97c0-.94.6-1.73 1.44-2.04v-4.77h-3.95c-1.18 0-2.28-.38-3.18-1.01v13.57c0 3.2 2.59 5.79 5.79 5.79h18.36c3.2 0 5.79-2.59 5.79-5.79V28.25c-.9.63-1.99 1.01-3.18 1.01h-3.97zM24.38 11.61c0-.58.48-1.06 1.06-1.06h4.8c.58 0 1.06.48 1.06 1.06v1.7h2.48v-1.7c0-1.95-1.59-3.54-3.54-3.54h-4.8c-1.95 0-3.54 1.59-3.54 3.54v1.7h2.48v-1.7zM11.74 23.71c0 1.12.44 2.12 1.14 2.89.79.87 1.92 1.42 3.18 1.42H20V14.54h-8.27v9.17zM22.48 25.75v2.27H33.2V14.55H22.48zM35.68 14.55V28.03h3.95c1.26 0 2.39-.55 3.18-1.42.7-.77 1.14-1.77 1.14-2.89v-9.17h-8.27z'
      />
      <g>
        <path
          fill='none'
          stroke='#a1a1a1'
          strokeWidth={4}
          strokeLinecap='round'
          strokeMiterlimit={10}
          d='M53.68 2L2 53.68'
        />
      </g>
    </svg>
  );
}

export default SvgIconLootNone;
