import { ServerConnection } from './ServerConnection';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
  HttpLink,
  Observable,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React, { FunctionComponent } from 'react';
import { triggerAuthPrompt, LS } from './components/Auth/AuthContext';
import { IAuthData } from './components/Auth/AuthTypes';
import { inIframe } from './wallet/Wallet';
import { defaultLogManager } from './utils';
import usePromise from 'react-promise-suspense';
import { PipeLogsDocument } from './components/schema';
import ServerLogSink from './utils/logging/ServerLogSink';

export function getApiRoot() {
  if (window.location.hostname === 'rinkeby.furballs')
    return 'rinkeby.furballs.com';
  if (window.location.hostname === 'goerli.furballs')
    return 'goerli.furballs.com';
  if (window.location.hostname === 'www.furballs') return 'www.furballs.com';
  if (window.location.hostname === 'furballs.prod') return 'www.furballs.com';
  if (window.location.hostname === 'prod.furballs.com')
    return 'prod.furballs.com';
  if (window.location.host === 'localhost:3000') return 'goerli.furballs.com';
  if (window.location.hostname === 'alpha.furballs.com')
    return 'goerli.furballs.com';
  return window.location.hostname;
}

export function getBlockchainName(): string {
  const api = getApiRoot();
  if (api.includes('.furballs.')) {
    const parts = api.split('.');
    const sd = parts[0];
    return sd === 'prod' ? 'ethereum' : sd;
  }
  return api;
}

export const SocketClient = 'socket';

interface IFurballClient {
  children: React.ReactNode;
}

const serverConnection = new ServerConnection(getApiRoot());

export function useServerConnection(): ServerConnection {
  return serverConnection;
}

export const FurballClient: FunctionComponent<IFurballClient> = (props) => {
  const logManager = defaultLogManager;
  const log = logManager.getLogger('Client');

  const client = usePromise(async () => {
    const httpLink = new HttpLink({
      uri: serverConnection.httpUrl,
    });

    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          const hasAuthError = extensions?.code === 'AUTH_NOT_AUTHENTICATED';
          hasAuthError && triggerAuthPrompt();

          log.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if (networkError) {
        log.error(`[NETWORK]: ${networkError}`);
        return Observable.of(operation);
      }
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const authState = inIframe()
        ? undefined
        : localStorage.getItem(LS.AUTH_STATE);
      // @ts-ignore
      let token: string | undefined = window.token;

      try {
        const parsedState = authState ? JSON.parse(authState) : {};
        const authData = parsedState as IAuthData | undefined;
        token = authData?.sessionSecret;
      } catch (e) {
        log.error(e, 'Failed to set API token');
      }

      // return the headers to the context so httpLink can read them
      const h: { [key: string]: string } = {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      };
      if (window.location.hostname === 'localhost') {
        h['GraphQL-Tracing'] = '1';
      }

      return {
        headers: h,
      };
    });

    const operationLink = ApolloLink.split(
      (operation) => operation.getContext().clientName !== SocketClient,
      httpLink,
      serverConnection.requestHandler.bind(serverConnection),
    );

    const c = new ApolloClient({
      // uri: '/api/graphql',
      // window.location.hostname.includes('localhost') ?
      //   'http://localhost:9000/api/graphql' :
      //   'https://furballs.com/api/graphql',
      link: from([authLink, errorLink, operationLink]),
      cache: new InMemoryCache({
        possibleTypes: {
          IFurPlayer: [
            'FurAccount',
            'FurScholar',
            'FurScholarEmail',
            'FurScholarDiscord',
          ],
          GameItem: [
            'ContainerItem',
            'ConsumableItem',
            'EquipmentItem',
            'LootItem',
            'MaterialItem',
            'SpecialItem',
          ],
          IBattleEffect: [
            'BattleEffectPassive',
            'BattleMoveOutcome',
            'EntityEffect',
          ],
        },
        typePolicies: {
          Furball: {
            fields: {
              battleStats: {
                merge(existing, incoming) {
                  return existing;
                },
              },
            },
          },

          Query: {
            fields: {
              furball: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
    });

    logManager.setSink(
      new ServerLogSink(logManager, async (bundle) => {
        console.log('[LOGS]', 'sent', bundle.entries.length);
        try {
          await c.mutate({
            mutation: PipeLogsDocument,
            variables: { logBundle: bundle },
          });
        } catch (e) {
          console.error('Failed to pipe logs to server.', bundle, e);
        }
      }),
    );

    return c;
  }, []);

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default FurballClient;
