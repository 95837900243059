import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Fangs = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M178.24 159.04c1.4-4.07 7.2 4.56 17.76 4.56 10.71 0 16.92-8.4 17.96-4.33 1.11 4.35-7.13 14.29-17.84 14.29s-19.34-10.25-17.88-14.52z' />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M212.68 159.73s3.79 13.08-3.92 22.01c0 0-2.77-14.21-6.16-17.26l10.08-4.75zM179.4 159.73s-3.79 13.08 3.92 22.01c0 0 2.77-14.21 6.16-17.26l-10.08-4.75z'
    />
  </svg>
);

export default Fangs;
