import * as React from 'react';

function SvgIconGearTime(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 330 330'
      xmlSpace='preserve'
      {...props}>
      <g id='XMLID_108_'>
        <path
          id='XMLID_109_'
          d='M315,210c8.284,0,15-6.716,15-15v-60c0-8.284-6.716-15-15-15h-22.688
		c-1.588-4.488-3.416-8.893-5.477-13.196l16.05-16.05c5.858-5.857,5.858-15.355,0.001-21.213l-42.426-42.426
		c-2.814-2.813-6.629-4.394-10.607-4.394c-3.978,0-7.793,1.58-10.606,4.394l-16.051,16.05c-4.304-2.06-8.708-3.888-13.196-5.476V15
		c0-8.284-6.716-15-15-15h-60c-8.284,0-15,6.716-15,15v22.689c-4.488,1.588-8.893,3.416-13.195,5.476l-16.051-16.05
		c-5.857-5.859-15.355-5.858-21.213,0L27.114,69.54c-2.814,2.813-4.394,6.628-4.394,10.607c0,3.978,1.58,7.793,4.394,10.606
		l16.05,16.051c-2.06,4.303-3.887,8.708-5.475,13.196H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h22.689
		c1.588,4.488,3.415,8.893,5.475,13.196l-16.05,16.051c-2.814,2.813-4.394,6.628-4.394,10.606c0,3.979,1.58,7.794,4.394,10.607
		l42.428,42.426c5.858,5.857,15.355,5.857,21.213,0l16.051-16.05c4.302,2.06,8.707,3.888,13.194,5.476V315c0,8.284,6.716,15,15,15
		h60c8.284,0,15-6.716,15-15v-22.689c4.488-1.588,8.893-3.416,13.196-5.476l16.051,16.05c5.858,5.857,15.355,5.858,21.213,0
		l42.426-42.426c5.858-5.858,5.858-15.355,0-21.213l-16.05-16.05c2.061-4.303,3.889-8.709,5.477-13.196H315z M217.472,190H155
		c-8.284,0-15-6.716-15-15v-62.474c0-8.284,6.716-15,15-15c8.284,0,15,6.716,15,15V160h47.472c8.284,0,15,6.716,15,15
		S225.756,190,217.472,190z'
        />
      </g>
    </svg>
  );
}

export default SvgIconGearTime;
