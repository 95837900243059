import React, { useCallback, useState } from 'react';

import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFurballs } from '../../../wallet';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';
import FurballSortBySelect from '../../../components/FurballSortBySelect';

import SvgIconCircleChecked from '../../../assets/SvgIconCircleChecked';
import SvgIconCircleUnchecked from '../../../assets/SvgIconCircleUnchecked';
import SvgIconSortVertical from '../../../assets/SvgIconSortVertical';

import { black, shadeColor } from '../../../themev2';
import { useBossBattle } from '../useBossBattle';
import { BattleTurnPhase } from '../../../components/schema';
import { SelectCustom } from '../../../components/SelectCustom';
import {
  BossKeyDefinition,
  defaultBossKeys,
  getLootItem,
  getLootSlot,
  verifyBossKeys,
} from '../../../components/Loot';

import { cdnRoot } from '../../../theme';
import clsx from 'clsx';
import { getSkillDefinitionById } from '../../Skills/SkillLibrary';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0.5rem 0.5rem 2rem ',
  },

  furballs: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '0.2rem',
  },

  furballsGridItem: {
    position: 'relative',
    overflow: 'hidden',
  },

  furballWrap: {
    display: 'flex',
    flexDirection: 'column',
    height: '14.5rem',
    width: '100%',
    border: '1.5px solid #cfcfcf',
    position: 'relative',
    cursor: 'pointer',
    transformOrigin: 'center',
    transition: 'all 0.2s ease-in',

    '&:hover': {
      boxShadow: 'inset 0 0 1000px 1000px #0000001c',
    },

    '&[data-no-pointer="true"]': {
      boxShadow: 'none !important',
    },

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      filter: 'grayScale(100%) brightness(60%)',
    },

    '&[data-inbattle="true"]': {
      cursor: 'default',
      filter: 'grayScale(100%) brightness(60%)',
    },
  },

  furball: {
    height: '8rem',
    width: '100%',
    position: 'relative',
    top: '0.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
  },

  furballRender: {
    position: 'relative',
    transition: 'all 0.2s ease-in',
  },

  furballRenderSelected: {
    position: 'relative',
    transition: 'all 0.2s ease-in',
    transform: 'scale(0.8)',
  },

  topExtras: {
    width: '100%',
    padding: '0.5rem 0.5rem 0.2rem',
    transition: 'all 0.2s ease-in',
    height: '2rem',
  },

  bottomExtras: {
    left: 0,
    right: 0,
    height: '2.5rem',
    padding: '0.3rem 0 0.5rem',
    transition: 'all 0.2s ease-in',
  },

  checkMark: {
    position: 'absolute',
    inset: 0,
    display: 'grid',
    placeItems: 'center',
    opacity: 0,
    transform: 'scale(1.3)',
    transition: 'all 0.2s ease-in',

    '&[data-visible="true"]': {
      opacity: 1,
      transform: 'scale(1)',
    },
  },

  checkIcon: {
    transition: 'all 0.2s ease-in',
    pointerEvents: 'none',
    padding: '0 !important',
    color: 'rgba(0,0,0,0.4) !important',
  },

  notice: {
    position: 'absolute',
    zIndex: 5,
    bottom: 0,
    right: 0,
    left: 0,
    height: '2.2rem',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.grey[400],
  },

  forfeitWrap: {
    position: 'absolute',
    zIndex: 6,
    inset: 0,
    display: 'grid',
    padding: '0.7rem',
    placeItems: 'center',
    backgroundColor: '#4d4d4dda',
    transition: 'all 0.2s linear',
  },

  forfeitBtn: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.78rem !important',
      lineHeight: '160% !important',
    },
  },

  filters: {
    margin: '1.2rem 0 2.7rem',
  },

  sortBy: {
    '& p': {
      fontSize: '0.85rem !important',
    },
  },

  keySort: {
    '& > svg': {
      width: '2rem !important',
      height: '2rem !important',
    },

    '& p': {
      fontSize: '0.85rem !important',
    },
  },

  sortIcon: {
    flexShrink: 0,
    width: '1.3rem',
    height: '1.3rem',
    position: 'absolute',
    right: '0.9rem',
  },

  keysContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.2rem',
  },

  key: {
    display: 'grid',
    placeItems: 'center',
    width: '1.1rem',
    height: '1.1rem',
    overflow: 'hidden',
    border: '1px solid gray',
    padding: '0.15rem',
    borderRadius: '0',
    backgroundColor: '#E9E9ED',
    position: 'relative',

    '& > svg': {
      position: 'static !important',
    },
  },

  skillsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '0.2rem',
  },

  skill: {
    display: 'grid',
    placeItems: 'center',
    width: '1.6rem',
    height: '1.6rem',
    overflow: 'hidden',
    border: '1px solid gray',
    padding: '0.15rem',
    borderRadius: '100%',
    backgroundColor: '#E9E9ED',
    position: 'relative',
  },

  skillIcon: {
    width: '120%',
    aspectRatio: '1/1',
    position: 'absolute',
    top: '0.08rem',
  },
}));

export const FormationDrawer = () => {
  const [sortBy, setSortBy] = useState<string>('1');
  const [filter, setFilter] = useState<BossKeyDefinition | 'all'>('all');

  const classes = useStyles();
  const allFurballs = useFurballs();

  const furballs = allFurballs.filter((fb) => {
    const agreement = fb.activeRentalAgreement;
    const isRented = fb.isRented;

    return !agreement || isRented;
  });

  const {
    paramId,
    isNew,
    partySize,
    selectedFurballs,
    handleSelect,
    forfeitBattle,
    allParties,
    isForfeiting,
    partyMembers,
  } = useBossBattle();

  const [showForfeits, setShowForfeits] = useState<{ [key: string]: boolean }>(
    {},
  );

  const filteredByBosskey = useCallback(() => {
    return furballs.filter((furball) => {
      if (filter === 'all' || !filter) {
        return true;
      }

      const furballKeys = verifyBossKeys(furball);
      return furballKeys.includes(filter);
    });
  }, [selectedFurballs, furballs]);

  const sortedFurballs = useCallback(() => {
    const sorted = [...filteredByBosskey()].sort((a, b) => {
      if (sortBy === '2') {
        // Level: Low to high
        return a.level - b.level;
      } else if (sortBy === '3') {
        // EXP/hr: High to low
        return b.state.expRate - a.state.expRate;
      } else if (sortBy === '4') {
        // EXP/hr: Low to high
        return a.state.expRate - b.state.expRate;
      } else if (sortBy === '5') {
        // FUR/hr: High to low
        return b.state.furRate - a.state.furRate;
      } else if (sortBy === '6') {
        // FUR/hr: Low to high
        return a.state.furRate - b.state.furRate;
      }

      // Level: High to low
      return b.level - a.level;
    });

    return sorted;
  }, [selectedFurballs, furballs]);

  if (furballs.length === 0) {
    return null;
  }

  return (
    <Container className={classes.root}>
      <Typography variant='h4'>Select Furballs ({furballs.length})</Typography>
      <Typography variant={'body1'}>
        Pick party members, create and edit new/saved parties for boss fights!
      </Typography>

      <Grid
        container
        direction='column'
        gap='0.5rem'
        className={classes.filters}>
        <SelectCustom
          id='key'
          fullWidth
          iconColor='secondary'
          fieldLabel='BOSS KEY'
          value={filter}
          inputClass={classes.keySort}
          options={[
            { label: 'All Boss Keys', value: 'all' },
            { label: 'Turtle Sea Keys', value: BossKeyDefinition.TURTLE_SEA },
            { label: 'Boneyard Keys', value: BossKeyDefinition.BONE_YARD },
            {
              label: "Wizard's Path Keys",
              value: BossKeyDefinition.WIZARDS_PATH,
            },
          ]}
          onChange={(e) => {
            setFilter(e.target.value as BossKeyDefinition);
          }}
        />

        <FurballSortBySelect
          id='sort'
          noMargin
          fullWidth
          fieldLabel='SORT BY'
          inputClass={classes.sortBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
          iconColor='default'
          icon={
            <SvgIconSortVertical
              fill='#000000'
              height='1.3rem'
              width='1.3rem'
            />
          }
        />
      </Grid>

      <div className={classes.furballs}>
        {sortedFurballs().map((furball, index) => {
          const showForfeit = showForfeits[furball.tokenId];
          const isSelected = selectedFurballs
            .map((item) => item.id)
            .includes(furball.tokenId);

          const belongsToAnotherParty = isNew
            ? !!furball.partyId
            : !!furball.partyId && furball.partyId !== paramId;

          // console.log('party', furball.number, furball.partyId);

          const inBattle =
            furball.battleStats.phase !== BattleTurnPhase.NotPlaying;

          return (
            <div
              key={index}
              className={classes.furballsGridItem}
              onMouseEnter={(e) =>
                inBattle &&
                setShowForfeits({ ...showForfeits, [furball.tokenId]: true })
              }
              onMouseLeave={(e) =>
                inBattle &&
                setShowForfeits({ ...showForfeits, [furball.tokenId]: false })
              }>
              <div
                role='button'
                className={classes.furballWrap}
                data-no-pointer={isSelected}
                data-inbattle={inBattle}
                data-disabled={
                  // (furball.level < BossBattleMinFurballLevel && !isSelected) ||
                  belongsToAnotherParty ||
                  (partyMembers.length === partySize && !isSelected)
                }
                style={{
                  backgroundColor: `${furball.backgroundColor}`,
                  borderColor: `${shadeColor(furball.backgroundColor, -30)}`,
                }}
                onClick={() => {
                  if (inBattle) return;

                  handleSelect({
                    id: furball.tokenId,
                    position: furball.battleStats.position,
                    zone: furball.zone,
                  });
                }}>
                <Grid
                  container
                  justifyContent='space-between'
                  alignItems='flex-start'
                  className={classes.topExtras}>
                  <div>
                    <Typography variant='h6' fontSize='0.8rem' lineHeight='1.2'>
                      LVL {furball.level}
                    </Typography>
                    <Typography fontSize='0.8rem' color='GrayText'>
                      {furball.maxDailyBattles - furball.hearts}/
                      {furball.maxDailyBattles}
                    </Typography>
                  </div>

                  <div className={classes.keysContainer}>
                    {defaultBossKeys.map((bossKey, index) => {
                      const loot = getLootItem(bossKey);
                      const slot = loot ? getLootSlot(loot) : undefined;
                      const hasKey = furball.inventory.items.find(
                        (item) => item.itemId === bossKey,
                      );

                      if (!hasKey) return null;

                      return (
                        <Tooltip title={loot.name} key={index}>
                          <div className={classes.key}>{slot?.item.icon}</div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </Grid>

                <Grid container direction='column' flexGrow={1}>
                  <div className={classes.furball}>
                    <FurballRenderer
                      tokenId={furball.tokenId}
                      size='7.5rem'
                      className={clsx(classes.furballRender, {
                        [classes.furballRenderSelected]: isSelected,
                      })}
                    />

                    <div
                      className={classes.checkMark}
                      data-visible={isSelected}>
                      <Checkbox
                        data-icon
                        className={classes.checkIcon}
                        value={furball.tokenId}
                        checked={true}
                        color='default'
                        icon={
                          <SvgIconCircleUnchecked
                            width='1.4rem'
                            height='1.4rem'
                            style={{ visibility: 'hidden' }}
                          />
                        }
                        checkedIcon={
                          <SvgIconCircleChecked
                            width='8.4rem'
                            height='8.4rem'
                          />
                        }
                      />
                    </div>
                  </div>
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    marginTop='0.5rem'>
                    <Typography variant='h6' fontSize='0.9rem' lineHeight='1.2'>
                      #{furball.number.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems='center'
                  justifyContent='center'
                  className={classes.bottomExtras}>
                  <div className={classes.skillsContainer}>
                    {furball.battleStats.skills.map((skill, index) => {
                      const def = getSkillDefinitionById(
                        skill.skillDefinitionId,
                      );
                      return (
                        <Tooltip title={def?.name ?? ''} key={index}>
                          <div
                            className={classes.skill}
                            style={{
                              backgroundColor: `#${shadeColor(
                                furball.backgroundColor,
                                -30,
                              )}`,
                            }}>
                            <img
                              className={classes.skillIcon}
                              src={`${cdnRoot}/images/icons/skills/${def?.icon}.png`}
                              alt={def?.name}
                            />
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </Grid>

                {inBattle ? (
                  <div className={classes.notice}>
                    <Typography
                      variant='body1'
                      color={black}
                      fontWeight='600'
                      fontSize='0.9rem'
                      textAlign='center'>
                      In a Fight
                    </Typography>
                  </div>
                ) : belongsToAnotherParty ? (
                  <div className={classes.notice}>
                    <Typography
                      variant='body1'
                      color={black}
                      fontWeight='600'
                      fontSize='0.9rem'
                      textAlign='center'>
                      In Another Party
                    </Typography>
                  </div>
                ) : null}
              </div>

              {showForfeit && (
                <div className={classes.forfeitWrap}>
                  <Button
                    fullWidth
                    variant='contained'
                    size='small'
                    className={classes.forfeitBtn}
                    onClick={() => {
                      const partyId = furball.partyId;
                      const party = allParties?.find(
                        (item) => item.id === partyId,
                      );

                      const battleId = party?.currentBattleId;
                      battleId && forfeitBattle(battleId);
                    }}>
                    {isForfeiting ? (
                      <CircularProgress size='1rem' color='secondary' />
                    ) : (
                      'Forfeit Fight?'
                    )}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export default FormationDrawer;
