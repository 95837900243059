import { Grid, Theme, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { getOtherZones } from './ZoneSelection';
import {
  ZONE_NUM_BATTLE,
  ZONE_NUM_EXPLORE,
  ZONE_NUM_TOWN,
  ZONE_NUM_VOYAGE,
} from './ZoneHelpers';
import { RealmType } from '../schema';
import MoveZonesButton, { VOYAGE_NOT_ALLOWED_REASON } from './MoveZonesButton';
import { makeStyles } from '@mui/styles';
import { useWalletSelector } from '../../hooks';
import { VOYAGE_FUR_COST, VOYAGE_MINIMUM_LVL } from './ZoneConstants';
import { useFurballs } from '../../wallet';
import { IVoyage } from '../../wallet/WalletTypes';
import FurballRenderer from '../Furballs/FurballRenderer';
import EstimatedRewards from '../Timekeeper/EstimatedRewards';
import clsx from 'clsx';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '2.25rem !important',
    margin: '30px 5px 5px 5px !important',
  },
  fbsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '75px',
    padding: '0 10px',
    backgroundColor: '#eee',
    borderRadius: '8px',
  },
  fb: {
    position: 'relative',
    textAlign: 'center',
    marginRight: '5.5px',
    marginTop: '10px',

    '& p': {
      fontSize: '12px !important',
      fontWeight: 700,
    },

    '& h6': {
      fontSize: '14px !important',
      color: '#9e9e9e !important',
      marginTop: '-2.5px !important',
    },
  },
  fbsPadding: {
    padding: '10px',
  },
  fbNumber: {
    width: '55px',
    padding: '1px 0',
    margin: '0 auto !important',
    color: `${theme.palette.secondary.light} !important`,
    borderRadius: '30px',
    fontSize: '12px !important',
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  rel: {
    position: 'relative',
  },
}));

interface IMoveZonesButtons {
  isBossFights?: boolean;
  realms: RealmType[];
  canShowRealms: boolean;
  furballIds: string[];
  currentZone: number;
  realm?: RealmType;
  onSaved: () => void;
  closeEarly?: () => void;
}

interface IMoveZonesButtons {
  isBossFights?: boolean;
  realms: RealmType[];
  canShowRealms: boolean;
  furballIds: string[];
  currentZone: number;
  realm?: RealmType;
  onSaved: () => void;
  closeEarly?: () => void;
  lastVoyage?: IVoyage;
}

const MoveZonesButtons: FunctionComponent<IMoveZonesButtons> = (props) => {
  const {
    furballIds,
    currentZone,
    onSaved,
    realms,
    canShowRealms,
    isBossFights,
    lastVoyage,
    closeEarly,
  } = props;

  const classes = useStyles();
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const furballs = useFurballs(furballIds);

  function canGoOnVoyage() {
    let balance = wFurBalance;

    if (lastVoyage) {
      const { totalFurCost, encountersFurGained } = lastVoyage;
      balance += encountersFurGained - totalFurCost;
    }

    const enoughFur = balance >= VOYAGE_FUR_COST * furballIds.length;

    for (let i = 0; i < furballs.length; i++) {
      if (furballs[i].level < VOYAGE_MINIMUM_LVL) {
        return VOYAGE_NOT_ALLOWED_REASON.NOT_HIGH_ENOUGH_FB_LVL;
      }
    }

    return enoughFur || VOYAGE_NOT_ALLOWED_REASON.NOT_ENOUGH_FUR;
  }

  function renderMoveZonesButtons() {
    const otherZones: number[] = getOtherZones(currentZone);

    return otherZones.map((otherZoneNum) => {
      if (otherZoneNum === ZONE_NUM_BATTLE) return;
      return (
        <Grid key={`move-zone-button-${otherZoneNum}`} item xs={12}>
          <MoveZonesButton
            furballIds={furballIds}
            zoneTarget={otherZoneNum}
            currentZone={currentZone}
            onSaved={onSaved}
          />
        </Grid>
      );
    });
  }

  function renderRealms() {
    if (canShowRealms) {
      const canGo = canGoOnVoyage();

      return (
        <>
          <Grid item xs={12}>
            <Typography variant={'h2'} className={classes.title}>
              Voyage Realms
            </Typography>
          </Grid>
          <Grid container>
            {realms.map((realm) => {
              return (
                <Grid key={realm} item xs={12}>
                  <MoveZonesButton
                    furballIds={furballIds}
                    realm={realm}
                    zoneTarget={ZONE_NUM_VOYAGE}
                    currentZone={currentZone}
                    onSaved={onSaved}
                    canGoOnVoyage={canGo}
                    closeEarly={closeEarly}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      );
    }

    return null;
  }

  function renderCollectFromCurrentZone() {
    if (
      currentZone === ZONE_NUM_VOYAGE ||
      currentZone === ZONE_NUM_TOWN ||
      isBossFights
    ) {
      return null;
    }

    return (
      <Grid item xs={12}>
        <MoveZonesButton
          furballIds={furballIds}
          zoneTarget={currentZone}
          currentZone={currentZone}
          onSaved={onSaved}
        />
      </Grid>
    );
  }

  function renderFurballsToMove() {
    const fbs = furballs.map((fb) => {
      const { tokenId } = fb;
      return (
        <div key={`fb-to-move-${tokenId}`} className={classes.fb}>
          <Typography variant={'h5'} className={classes.fbNumber}>
            #{fb.number}
          </Typography>
          <FurballRenderer
            tokenId={tokenId}
            size={'70px'}
            className={classes.rel}
          />
          <EstimatedRewards
            forSingleFb={true}
            zoneNumber={currentZone}
            furballs={[fb]}
          />
        </div>
      );
    });

    return (
      <Grid
        item
        xs={12}
        className={clsx(classes.fbsWrapper, {
          [classes.fbsPadding]:
            currentZone === ZONE_NUM_EXPLORE || currentZone === ZONE_NUM_BATTLE,
        })}>
        {fbs}
      </Grid>
    );
  }

  return (
    <>
      {renderFurballsToMove()}
      {renderCollectFromCurrentZone()}
      {renderMoveZonesButtons()}
      {/*{renderRealms()}*/}
    </>
  );
};

export default MoveZonesButtons;
