import React, { FunctionComponent, useState } from 'react';
import { ISelectedFurball, ZoneCard } from '../../components/Zones';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useWalletSelector } from '../../hooks';
import { getZone, ZONE_NUM_TOWN } from '../../components/Zones/ZoneHelpers';
import PlayButton from './PlayButton';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import { fbHaveUpgradeCd, useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({}));

interface IUpgrade {}

const Town: FunctionComponent<IUpgrade> = (props) => {
  const zoneNumber = ZONE_NUM_TOWN;
  const zone = getZone(zoneNumber);
  const { log } = useFurComponent(Town);

  const [mode, setMode] = React.useState('0');
  const [selectedFurballs, setSelectedFurballs] = useState<ISelectedFurball[]>(
    [],
  );

  const furballMap = useWalletSelector((s) => s.furballs);
  const zoning = useWalletSelector((s) => Object.keys(s.pending));
  const isLoading = zoning.length > 0;
  const fbIdsToMove = selectedFurballs
    .filter(({ id }) => !fbHaveUpgradeCd(furballMap[id]))
    .map((item) => item.id);
  const tokenIds = Object.keys(furballMap).filter(
    (tokenId) =>
      furballMap[tokenId].zone === ZONE_NUM_TOWN &&
      !furballMap[tokenId].pendingUpgrade?.isPending,
  );

  function onState(s: TransactionState, error?: ITransactionError) {
    log.info('Zone');
  }

  const tabs = {
    '0': 'Upgrade',
  };

  const upgradingFb = false;

  return (
    <ZoneCard
      title={zone.title}
      zoneNumber={zoneNumber}
      disabled={isLoading}
      loading={isLoading}
      selectedFurballs={selectedFurballs}
      setSelectedFurballs={setSelectedFurballs}
      tabs={tabs}
      selectedTab={mode}
      setSelectedTab={setMode}
      background={'town'}
      noBackground={upgradingFb}
      hideSelectAll={upgradingFb}
      isFurballInTab={(fb, tab) => (fb.timekeeperDisabled ? '1' : '0') === tab}
      action={
        !upgradingFb && (
          <PlayButton
            furballIds={fbIdsToMove}
            setSelectedFurballs={setSelectedFurballs}
            setState={onState}
            zoneNumber={zoneNumber}
            isLoading={isLoading}
            timekeeperDisabled={false}
          />
        )
      }></ZoneCard>
  );
};

export default Town;
