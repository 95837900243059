import * as React from 'react';

function SvgTimer(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 60 60'
      xmlSpace='preserve'
      stroke={props.fill}
      strokeWidth='1px'
      {...props}>
      <g>
        <path
          d='M34.74,36.53l9.363-12.944c0.287-0.397,0.244-0.945-0.104-1.293c-0.348-0.346-0.896-0.39-1.293-0.104L29.76,31.555
		c-0.844,0.614-1.375,1.563-1.456,2.604s0.296,2.06,1.033,2.797C30.01,37.629,30.904,38,31.854,38
		C32.992,38,34.07,37.451,34.74,36.53z'
        />
        <path
          d='M54.534,19.564c-0.01-0.021-0.01-0.043-0.021-0.064c-0.012-0.02-0.031-0.031-0.044-0.05
		c-1.011-1.734-2.207-3.347-3.565-4.809l2.147-2.147l1.414,1.414l4.242-4.242l-4.242-4.243l-4.243,4.243l1.415,1.414l-2.147,2.147
		c-1.462-1.358-3.074-2.555-4.809-3.566c-0.019-0.013-0.03-0.032-0.05-0.044c-0.021-0.012-0.043-0.011-0.064-0.022
		c-3.093-1.782-6.568-2.969-10.272-3.404V5h1.5c1.379,0,2.5-1.121,2.5-2.5S37.172,0,35.793,0h-9c-1.379,0-2.5,1.121-2.5,2.5
		s1.121,2.5,2.5,2.5h1.5v1.156c-1.08,0.115-2.158,0.291-3.224,0.535c-0.538,0.123-0.875,0.66-0.751,1.198
		c0.123,0.538,0.66,0.874,1.198,0.751c0.946-0.217,1.902-0.378,2.86-0.485l0.991-0.075c0.335-0.025,0.635-0.043,0.925-0.057h0V10
		c0,0.553,0.447,1,1,1s1-0.447,1-1V8.03c3.761,0.173,7.305,1.183,10.456,2.845l-0.986,1.708c-0.276,0.479-0.112,1.09,0.366,1.366
		c0.157,0.091,0.329,0.134,0.499,0.134c0.346,0,0.682-0.18,0.867-0.5l0.983-1.704c3.129,1.985,5.787,4.643,7.772,7.772l-1.703,0.983
		C50.07,20.91,49.906,21.521,50.183,22c0.186,0.321,0.521,0.5,0.867,0.5c0.17,0,0.342-0.043,0.499-0.134l1.707-0.986
		c1.685,3.196,2.698,6.798,2.849,10.619H54.13c-0.553,0-1,0.447-1,1s0.447,1,1,1h1.975c-0.151,3.821-1.164,7.423-2.849,10.619
		l-1.707-0.986c-0.478-0.277-1.09-0.114-1.366,0.366c-0.276,0.479-0.112,1.09,0.366,1.366l1.703,0.983
		c-1.985,3.129-4.643,5.787-7.772,7.772l-0.983-1.703c-0.277-0.48-0.89-0.644-1.366-0.366c-0.479,0.276-0.643,0.888-0.366,1.366
		l0.986,1.707c-3.151,1.662-6.695,2.672-10.456,2.845V56c0-0.553-0.447-1-1-1s-1,0.447-1,1v1.976
		c-1.597-0.055-3.199-0.255-4.776-0.617c-0.538-0.125-1.075,0.213-1.198,0.751c-0.124,0.538,0.213,1.075,0.751,1.198
		C27.068,59.768,29.107,60,31.13,60c0.049,0,0.096-0.003,0.145-0.004c0.007,0,0.012,0.004,0.018,0.004
		c0.008,0,0.015-0.005,0.023-0.005c4.807-0.033,9.317-1.331,13.219-3.573c0.031-0.014,0.064-0.021,0.094-0.039
		c0.02-0.012,0.031-0.031,0.05-0.044c4.039-2.354,7.414-5.725,9.773-9.761c0.019-0.027,0.043-0.048,0.06-0.078
		c0.012-0.021,0.011-0.043,0.021-0.064C56.817,42.476,58.13,37.89,58.13,33S56.817,23.524,54.534,19.564z'
        />
        <path d='M22.293,13h-5c-1.104,0-2,0.896-2,2s0.896,2,2,2h5c1.104,0,2-0.896,2-2S23.397,13,22.293,13z' />
        <path d='M22.293,20h-10c-1.104,0-2,0.896-2,2s0.896,2,2,2h10c1.104,0,2-0.896,2-2S23.397,20,22.293,20z' />
        <path d='M22.293,27h-15c-1.104,0-2,0.896-2,2s0.896,2,2,2h15c1.104,0,2-0.896,2-2S23.397,27,22.293,27z' />
        <path d='M22.293,34h-19c-1.104,0-2,0.896-2,2s0.896,2,2,2h19c1.104,0,2-0.896,2-2S23.397,34,22.293,34z' />
        <path d='M22.293,41h-13c-1.104,0-2,0.896-2,2s0.896,2,2,2h13c1.104,0,2-0.896,2-2S23.397,41,22.293,41z' />
        <path d='M22.293,48h-7c-1.104,0-2,0.896-2,2s0.896,2,2,2h7c1.104,0,2-0.896,2-2S23.397,48,22.293,48z' />
      </g>
    </svg>
  );
}

export default SvgTimer;
