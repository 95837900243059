import React, { FunctionComponent, useState } from 'react';
import PlayerAvatar from './pages/Account/PlayerAvatar';
import { useWalletSelector } from './hooks';
import { OutboundLink } from './components/Navigation';
import { Theme, Typography, Grid, Box, Fab } from '@mui/material';
import { useDiscordLoginUrl } from './components/Auth/DiscordOAuthButton';
import { makeStyles } from '@mui/styles';
import TixBalanceButtonV2 from './pages/Account/BalanceButtons/TixBalanceButtonV2';
import FurBalanceButtonV2 from './pages/Account/BalanceButtons/FurBalanceButtonV2';
import { white } from './themev2';
import { useAuthContext } from './components/Auth/AuthContext';
import { FurAccountTypes } from './components/Auth/AuthTypes';
import PlayerLink from './components/Players/PlayerLink';
import SvgIconEdit from './assets/SvgIconEdit';
import { UsernameEditDialog } from './components/Account/UsernameEditDialog';
import { darkBlue } from './theme';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: `${white} !important`,
    textDecoration: 'underline',
  },

  player: {
    textAlign: 'center',
  },

  accountLink: {
    color: `${white} !important`,
    textAlign: 'left',
    fontSize: '1.5rem !important',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem !important',

      '& span': {
        display: 'none',
      },
    },
  },

  accountSub: {
    textAlign: 'left',
  },

  walletAddr: {
    textAlign: 'left',
    color: white,
    fontSize: '16px !important',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },

  mainCont: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '20px !important',
    paddingBottom: '20px !important',
  },

  playerCont: {
    display: 'flex',
    alignContent: 'flex-start',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      display: 'inline',
      textAlign: 'center',
    },
  },

  accountInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginLeft: '15px',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '0',
    },
  },

  buttonsCont: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      marginTop: '20px !important',
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column !important',
    },
  },

  myAccountLink: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.2rem',

    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center',
    },
  },

  editButton: {
    transform: 'scale(0.8)',
  },
}));

interface OwnProps {
  title?: string;
  hideCurrencies?: boolean;
  showEditUsername?: boolean;
  tint?: boolean;
}

type Props = OwnProps;

const AccountHeader: FunctionComponent<Props> = (props) => {
  const { title, hideCurrencies, showEditUsername, tint } = props;

  const classes = useStyles();
  const account = useWalletSelector((s) => s.contents);
  const discordLoginUrl = useDiscordLoginUrl();
  const history = useHistory();
  const { logout, authState } = useAuthContext();
  const socialProfiles = account?.socialProfiles ?? [];
  const isLoggedInDiscord =
    authState.playerType !== FurAccountTypes.PLAYER ||
    !!socialProfiles.find((p) => p.source === 'Discord');
  // const addr = useWalletSelector((s) => s.address);
  // const addrAbbr = addr?.substr(0, 4) + '...' + addr?.substr(-4);

  const [show, setShow] = useState(false);

  const {
    authState: { playerType },
  } = useAuthContext();

  function doLogout(e: React.MouseEvent): boolean {
    e.preventDefault();
    logout(true);
    return false;
  }

  function renderLink() {
    const isOnAccountPage = window.location.pathname.startsWith('/account');

    return (
      <div className={classes.myAccountLink}>
        <span>
          {title ?? (
            <PlayerLink
              className={classes.link}
              player={account}
              path={isOnAccountPage ? undefined : '/account'}
            />
          )}
        </span>
        {showEditUsername && (
          <>
            <Fab
              size='small'
              color='primary'
              onClick={() => setShow(true)}
              className={classes.editButton}>
              <SvgIconEdit width='1rem' height='1rem' />
            </Fab>
          </>
        )}
        <UsernameEditDialog open={show} onClose={() => setShow(false)} />
      </div>
    );
  }

  return (
    <Grid
      container
      item
      xs={12}
      md={12}
      className={classes.mainCont}
      style={{
        backgroundColor: tint ? `${darkBlue}88` : undefined,
        // position: 'relative',
        // left: -20,
        // right: 20,
        // top: -10,
      }}>
      <Grid item xs={12} lg={6} className={classes.playerCont}>
        <PlayerAvatar player={account} radius={40} />
        <Box className={classes.accountInfo}>
          <Typography variant={'h3'} className={classes.accountLink}>
            {renderLink()}
          </Typography>
          {showEditUsername && (
            <Typography variant={'h6'} className={classes.accountSub}>
              <Link to={'/logout'} className={classes.link} onClick={doLogout}>
                Logout
              </Link>
              {!isLoggedInDiscord && (
                <OutboundLink className={classes.link} to={discordLoginUrl}>
                  Connect Discord
                </OutboundLink>
              )}
            </Typography>
          )}
        </Box>
      </Grid>
      {!hideCurrencies && (
        <Grid item xs={12} md={12} lg={6} className={classes.buttonsCont}>
          <TixBalanceButtonV2 />
          {playerType === FurAccountTypes.PLAYER && (
            <>
              {/*<TimekeeperStateButtonV2 />*/}
              <FurBalanceButtonV2 />
            </>
          )}
          <FurBalanceButtonV2 wrapped={true} />
        </Grid>
      )}
    </Grid>
  );
};

export default AccountHeader;
