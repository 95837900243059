import { useLogger } from './furComponent';
import { ALERT_TYPES, AlertContext } from '../components/Alert/AlertContext';
import React from 'react';

export function useTryCatchAsync<T>(
  name: string,
  func: () => Promise<T>,
  deps?: React.DependencyList,
  successMessage?: string,
): () => Promise<void> {
  return useTryCatchParamAsync<void, T>(name, func, deps, successMessage);

  // const log = useLogger(name);
  // const { addAlert } = React.useContext(AlertContext);
  // return React.useCallback(async () => {
  //   try {
  //     await func();
  //
  //     if (successMessage)
  //       addAlert({
  //         type: ALERT_TYPES.SUCCESS,
  //         message: successMessage,
  //       });
  //   } catch (e: any) {
  //     log.warn(name, 'failed', e);
  //     addAlert({
  //       type: ALERT_TYPES.ERROR,
  //       message: `${name} failed: ${e.message ?? JSON.stringify(e)}`,
  //     });
  //   }
  // }, deps ?? []);
}

export function useTryCatchParamAsync<P, T>(
  name: string,
  func: (p: P) => Promise<T>,
  deps?: React.DependencyList,
  successMessage?: string,
): (p: P) => Promise<void> {
  const log = useLogger(name);
  const { addAlert } = React.useContext(AlertContext);
  return React.useMemo(
    () => async (p: P) => {
      try {
        await func(p);

        if (successMessage)
          addAlert({
            type: ALERT_TYPES.SUCCESS,
            message: successMessage,
          });
      } catch (e: any) {
        log.warn(name, 'failed', e);
        addAlert({
          type: ALERT_TYPES.ERROR,
          message: `${name} failed: ${e.message ?? JSON.stringify(e)}`,
        });
      }
    },
    deps ?? [],
  );
}
