import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Tophat = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse
      fill='#bae9ff'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={196}
      cy={104}
      rx={23}
      ry={8}
    />
    <path
      fill='#bae9ff'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M196.32 77.3c-7.51 0-13.59 1.82-13.59 4.06v20.33c0 2.24 6.09 4.06 13.59 4.06 7.51 0 13.59-1.82 13.59-4.06V81.36c0-2.24-6.09-4.06-13.59-4.06z'
    />
    <path
      fill='#bae9ff'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M208.96 81.36c0 2.24-5.13 4.06-12.64 4.06s-13.59-1.82-13.59-4.06 6.09-4.06 13.59-4.06 12.64 1.82 12.64 4.06z'
    />
    <path d='M209.9 101.83s.02-8.32 0-7.24c-.03 1.98-6.14 3.49-13.64 3.38-7.51-.11-13.57-1.8-13.54-3.78.02-1.08.06 8.02.06 8.02s17.11 6.07 27.12-.38z' />
  </svg>
);

export default Tophat;
