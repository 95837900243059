import { useWalletSelector } from '../../hooks';
import { IFurballBase, IGameItem } from '../../wallet/WalletTypes';
import { IStatRow } from '../Furballs';
import { StatAbbreviation } from './StatTypes';
import { getTotalLootBonus, groupInventory, LootStatMap } from '../Loot';
import { ITimekeeperInteraction } from '../Timekeeper/TimekeeperSlice';
import _ from 'lodash';
import { getSnack } from '../Food';
import { getRarityLevelNumber } from '../../pages/Play';

function _getFurBoost(level: number): number {
  if (level >= 200) return 581;
  if (level < 25) return 2 * level;
  if (level < 50) return (5000 + (level - 25) * 225) / 100;
  if (level < 75) return (10625 + (level - 50) * 250) / 100;
  if (level < 100) return (16875 + (level - 75) * 275) / 100;
  if (level < 125) return (23750 + (level - 100) * 300) / 100;
  if (level < 150) return (31250 + (level - 125) * 325) / 100;
  if (level < 175) return (39375 + (level - 150) * 350) / 100;
  return (48125 + (level - 175) * 375) / 100;
}

export function getLevelFurBoost(level: number): number {
  return Math.floor(_getFurBoost(level));
}

export function getTeamModifier(teamSize: number) {
  return 2 * (teamSize <= 10 ? teamSize - 1 : 10);
}

export function useTeamModifier(): number {
  const furballs = useWalletSelector((s) => s.contents);
  return getTeamModifier(furballs?.furballBalance ?? 0);
}

export function getLootStat(
  cat: StatAbbreviation,
  inventoryStats: LootStatMap,
): IStatRow {
  const stats = inventoryStats[cat] ?? [];
  const num = stats.length;
  return {
    title: `+${cat.toUpperCase()} LOOT`,
    name: `${num} Item${num == 1 ? '' : 's'}`,
    callout: {
      stat: cat,
      value: getTotalLootBonus(stats),
    },
  };
}

export function computeStats(
  furball: IFurballBase,
  teamSize: number,
  hideTeamStats: boolean | undefined,
  ...snackInteractions: ITimekeeperInteraction[]
): [number, number, IStatRow[]] {
  const rows: IStatRow[] = [];
  const rarity = furball.rarity;
  const snacks = snackInteractions
    .filter((i) => i.furballIds.includes(furball.tokenId))
    .map((s) => getSnack(s.expectedValue ?? 0));
  const snackExp = _.sum(snacks.map((s) => s.happiness));

  rows.push({
    title: 'RARITY',
    name: `+${rarity}% Rare Gene Boost`,
    callout: { value: rarity },
  });
  let expBoost = rarity + snackExp;
  let furBoost = rarity;

  if (!hideTeamStats) {
    const teamModifier = getTeamModifier(teamSize);
    rows.push({
      title: 'TEAM SIZE',
      name: `${teamSize} Furball${teamSize === 1 ? '' : 's'}`,
      callout: { value: teamModifier },
    });

    expBoost += teamModifier;
    furBoost += teamModifier;
  }

  expBoost += furball.state.happiness;
  // rows.push({
  //   title: 'SNACKS', name: `${happiness} Happiness`, callout: { stat: 'exp', value: happiness }
  // });

  const inventoryStats = groupInventory(
    furball?.equipment.map((e) => {
      return {
        id: e.id,
        itemId: e.itemId,
        stack: 1,
        maxStack: 1,
        rarity: getRarityLevelNumber(e.rarity),
      } as IGameItem;
    }) ?? [],
  );

  const expLoot = getLootStat('exp', inventoryStats);
  rows.push(expLoot);
  expBoost += expLoot.callout.value ?? 0;

  const furLoot = getLootStat('fur', inventoryStats);
  rows.push(furLoot);
  furBoost += furLoot.callout.value ?? 0;

  const level = furball.level;
  const lb = getLevelFurBoost(level);
  furBoost += lb;
  rows.push({
    title: 'LEVEL',
    name: `${level}`,
    callout: { stat: 'fur', value: lb },
  });

  return [expBoost, furBoost, rows];
}
