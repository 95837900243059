import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { ItemGroup, ItemQuantityFragment, ItemsFragment } from '../schema';
import InventorySlot, { IInventorySlot } from './InventorySlot';
import { rarityInNum } from '../../utils';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import useCommonStyles from '../useCommonStyles';
import clsx from 'clsx';
import { getItemQuantityList, useInventoryHelpers } from './InventoryHelpers';
import SvgIconBackArrow from '../../assets/SvgIconBackArrow';
import { black } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '475px',
    maxWidth: '475px',
    paddingBottom: '0.5rem',
    marginLeft: '0.4rem',

    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
      maxWidth: '100%',
      marginLeft: '0',
    },
  },

  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
    padding: '0.5rem',

    // [theme.breakpoints.down('sm')]: {
    //   // gap: '10px',
    //   width: '100%',
    //   justifyContent: 'center',
    //   marginLeft: '0',
    // },
  },

  content: {
    backgroundColor: theme.palette.secondary.dark,
  },

  item: {
    border: `2px solid ${theme.palette.secondary.light}`,
    backgroundColor: `${theme.palette.secondary.dark} !important`,
    color: '#A5B4FC',
    cursor: 'pointer',
    boxShadow:
      'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
  },
  noItemsAvailable: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '600px',
    gridColumnEnd: '3',

    [theme.breakpoints.down('sm')]: {
      height: '550px',
    },
  },
}));

interface IInventorySelectItemPopUp {
  containerId: string;
  itemQuantities: ItemQuantityFragment[];
  extraItems: ItemsFragment[];
  onSelectItem: (item: ItemsFragment | null) => void;
  onClose: () => void;
  itemGroup: ItemGroup;
  showRemoveCurrentItem?: boolean;
  excludedItemIds?: string[];
  hideRarity?: boolean;
  hideAmount?: boolean;
  smallItems?: boolean;
}

export const InventorySelectItem: FunctionComponent<
  IInventorySelectItemPopUp
> = (props) => {
  const {
    containerId,
    extraItems,
    onSelectItem,
    onClose,
    itemGroup,
    showRemoveCurrentItem,
    excludedItemIds,
    hideRarity,
    hideAmount,
    smallItems,
  } = props;
  const classes = useStyles();
  const common = useCommonStyles();

  const [selectedItemId, selectItemId] = React.useState(0);
  const { playerInventory } = useInventoryHelpers(containerId);

  const allItems = [...(playerInventory()?.items ?? []), ...extraItems];
  const itemQuantities = getItemQuantityList(props.itemQuantities, extraItems);

  const selectedItemQuantity =
    itemQuantities.find((q) => q.itemId === selectedItemId)?.quantity ?? 0;

  function sortBy(a: any, b: any) {
    const aRarity = rarityInNum(a.rarity);
    const bRarity = rarityInNum(b.rarity);

    if (aRarity < bRarity) return 1;
    if (aRarity > bRarity) return -1;

    const itemDefA = getItemDef(a.itemId)?.name ?? '';
    const itemDefB = getItemDef(b.itemId)?.name ?? '';
    return itemDefA.localeCompare(itemDefB);
  }

  function renderItems() {
    const slotProps: IInventorySlot = {
      containerId,
      hideRarity,
      hideAmount,
      onSelectItem,
      fluidSize: true,
      onSelectItemId: selectItemId,
      small: smallItems,
    };

    let rendered: React.ReactNode[];

    if (selectedItemId > 0) {
      rendered = allItems
        .filter(
          (item) => !excludedItemIds || !excludedItemIds.includes(item.id),
        )
        .filter((item) => item.itemId === selectedItemId)
        .sort(sortBy)
        .map((item) => (
          <InventorySlot {...slotProps} key={'item_' + item.id} item={item} />
        ));
    } else {
      rendered = itemQuantities
        .filter(
          (item) => !excludedItemIds || !excludedItemIds.includes(item.itemId),
        )
        .filter(
          (itemQuantity) =>
            getItemDef(itemQuantity.itemId)?.itemGroup === itemGroup,
        )
        .sort(sortBy)
        .map((itemQuantity) => (
          <InventorySlot
            {...slotProps}
            className={classes.item}
            key={itemQuantity.itemId + '_quantity'}
            itemQuantity={itemQuantity}
          />
        ));
    }

    if (showRemoveCurrentItem) {
      rendered = [renderRemoveCurrentItem(), ...rendered];
    }

    return !rendered.length ? renderNoItemsAvailable() : rendered;
  }

  function renderRemoveCurrentItem() {
    return (
      <InventorySlot
        key={'remove-item'}
        containerId={containerId}
        hideRarity={hideRarity}
        hideAmount={hideAmount}
        className={classes.item}
        onSelectItem={() => onSelectItem(null)}
        removeCurrentItem={true}
        fluidSize={true}
      />
    );
  }

  function renderNoItemsAvailable() {
    return (
      <div className={classes.noItemsAvailable}>
        <Typography variant={'h3'}>No items available!</Typography>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, common.styledScrollBar)}>
      {selectedItemId > 0 && (
        <Grid container>
          <Grid item xs={6}>
            <Typography variant={'h5'} color='#A5B4FC'>
              {getItemDef(selectedItemId)?.name} (x{selectedItemQuantity})
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant={'contained'}
              onClick={() => selectItemId(0)}
              startIcon={<SvgIconBackArrow width='0.7rem' fill={black} />}>
              <Typography
                variant='body2'
                lineHeight='1rem'
                fontWeight='600'
                fontSize='0.7rem'>
                All Items
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <div className={classes.container}>{renderItems()}</div>
    </div>
  );
};

export default InventorySelectItem;
