import React, { FunctionComponent } from 'react';
import {
  Theme,
  DialogContent,
  Typography,
  Dialog,
  Button,
  Grid,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgIconDiscord from '../assets/SvgIconDiscord';
import SvgIconDownload from '../assets/SvgIconDownload';
import WalletContext from './WalletContext';
import { useDiscordLoginUrl } from '../components/Auth/DiscordOAuthButton';
import { useAppDispatch, useWalletSelector } from '../hooks';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../components/Auth/AuthContext';
import { fireLoginBeginEvent } from '../Analytics';
import { inIframe } from './Wallet';
import { cdnRoot, mint } from '../theme';
import LoginEmailForm from './LoginEmailForm';
import WalletSlice from './WalletSlice';
import { useClientHardware } from '../pages/App/clientHardware';
import CdnLink from '../components/Navigation/CdnLink';
import { useFurComponent } from '../utils';
import AppleLoginButton from './AppleLoginButton';
import AppInstallPrompt from '../pages/App/AppInstallPrompt';
import { isVuplexExpected } from '../pages/App/vuplexBridge';

const discordBlue = '#7289da';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  discordButton: {
    width: '100%',
    marginBottom: '10px !important',
    color: 'white !important',
    backgroundColor: `${discordBlue} !important`,
    '&:hover': {
      backgroundColor: `${mint.dark} !important`,
      color: 'black !important',
    },
  },
  metaMaskButton: {
    width: '100%',
    marginTop: '20px !important',
    marginBottom: '10px !important',
    color: 'black !important',
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: `${mint.dark} !important`,
      color: 'black !important',
    },
  },
}));

interface IForceLoginPrompt {
  open: boolean;
  onClose?: () => void;
  analyticsPathComponent?: string;
  title?: string;
  subtitle?: string;
  isSignup?: boolean;
}

export const ForceLoginDialog: FunctionComponent<IForceLoginPrompt> = (
  props,
) => {
  const classes = useStyles();
  const { open, onClose, isSignup } = props;
  const analyticsPathComponent =
    props.analyticsPathComponent ?? window.location.pathname;
  const discordLoginUrl = useDiscordLoginUrl();
  const history = useHistory();
  const isIframe = inIframe();
  const addr = useWalletSelector((s) => s.address);
  const metamaskUrl = 'https://metamask.io';
  const context = React.useContext(WalletContext);
  const [sentLink, setSentLink] = React.useState(false);
  const [isSigning, setIsSigning] = React.useState(false);
  const { signInWallet, isSignedIn } = useAuthContext();
  const isApp = isVuplexExpected();
  const dispatch = useAppDispatch();
  const { isMobile } = useClientHardware();
  const { log } = useFurComponent(ForceLoginDialog);
  const verb = isSignup ? 'Sign Up' : 'Sign In';

  React.useEffect(() => {
    if (!open) setSentLink(false);
    else dispatch(WalletSlice.actions.setTriedLogin(true));
  }, [open]);

  async function requestAccounts() {
    try {
      await context?.provider.send('eth_requestAccounts', []);
    } catch (e) {
      log.error('Connection error', e);
      // Most connection errors are pressing the button again while the MetaMask dialog is already open.
      // Reloading is a silly simple way to... fix? this
      window.location.reload();
    }
  }

  function sendEvent(source: string) {
    fireLoginBeginEvent(source, analyticsPathComponent);
  }

  function connectWallet() {
    setIsSigning(true);
    if (!addr) {
      log.info('[AUTH] request accounts');
      void requestAccounts();
      return;
    }
    log.info('[AUTH] sign in wallet');
    sendEvent('Web3');
    void signInWallet(true);
  }

  function handleDiscord() {
    sendEvent('Discord');
    window.open(discordLoginUrl);
    if (onClose) onClose();
  }

  function handleClose() {
    setIsSigning(false);
    if (onClose) onClose();
  }

  const title =
    props.title ??
    (context
      ? 'Web3 Login?'
      : isIframe
      ? 'Visit Furballs.com?'
      : isSignup === false
      ? 'Welcome Back'
      : 'Welcome, FurFam');

  const showWallet = !isIframe && context;
  const subtitle =
    props.subtitle ??
    (context
      ? 'Your crypto wallet (Web3) allows you to own and borrow Furballs.'
      : isIframe
      ? 'Please visit Furballs.com in a new window to get started.'
      : isSignup === false
      ? 'Access your Account'
      : 'Play for Free');

  const isMobileWithoutApp = isMobile && !isApp;
  const canInstallMM = !isMobile && !isApp;
  const downloadTitle = canInstallMM ? 'Download Metamask' : 'Link Web3';
  const mmButton = (
    <Button
      variant='contained'
      color='secondary'
      className={classes.metaMaskButton}
      startIcon={<SvgIconDownload width='1.5rem' height='1.5rem' />}
      onClick={() => {
        if (context) {
          connectWallet();
        } else if (canInstallMM) {
          handleClose();
          sendEvent('Download');
          window.open(metamaskUrl, '_blank');
        } else {
          // handleClose();
          // sendEvent('Link');
          // window.open(
          //   'https://guide.furballs.com/help/link-accounts',
          //   '_blank',
          // );
        }
      }}>
      <Typography variant='h6' fontSize='0.8rem'>
        {context ? 'Sign-in with Web3' : downloadTitle}
      </Typography>
    </Button>
  );

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xs'}>
      <DialogContent style={{ marginBottom: '0.5rem' }}>
        <div style={{ paddingTop: 30, textAlign: 'center' }}>
          <img src={`${cdnRoot}/favicon-32x32.png`} />
        </div>
        <Container maxWidth={'sm'}>
          <Typography
            variant={'h3'}
            style={{ textAlign: 'center', marginTop: '1.5rem' }}>
            {title}
          </Typography>
        </Container>
        {isMobileWithoutApp && <AppInstallPrompt />}
        {isSigning && (
          <Container maxWidth={'sm'}>
            <Typography variant={'h6'}>
              If the Web3 extension (MetaMask) does not open, please ensure it
              is unlocked and try refreshing your browser.
            </Typography>
          </Container>
        )}
        {!isMobileWithoutApp && !isSigning && (
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ padding: '0px' }}>
            {/*{showWallet && (*/}
            {/*  <Grid item xs={12} lg={6} style={{ textAlign: 'center' }}>*/}
            {/*    <SvgWallet style={{ maxWidth: '250px' }} />*/}
            {/*  </Grid>*/}
            {/*)}*/}
            <Grid item xs={12}>
              <div>
                {/*<Typography variant={'h5'}>*/}
                {/*  {context*/}
                {/*    ? 'Huzzah!'*/}
                {/*    : isIframe*/}
                {/*    ? 'Play for free!'*/}
                {/*    : "Don't fret!"}*/}
                {/*</Typography>*/}
                {!sentLink && (
                  <Container maxWidth={'md'}>
                    <Typography
                      style={{
                        textAlign: 'center',
                        fontSize: '1.2rem',
                        marginBottom: 10,
                      }}
                      color={'gray'}
                      variant={'h4'}>
                      {subtitle}
                    </Typography>
                  </Container>
                )}
                <LoginEmailForm
                  onSent={() => setSentLink(true)}
                  analyticsPathComponent={analyticsPathComponent}
                />
              </div>
              {!sentLink && !context && (
                <Typography
                  variant={'h5'}
                  color={'gray'}
                  style={{ textAlign: 'center', marginBottom: 20 }}>
                  - OR -
                </Typography>
              )}
              {!sentLink && !isIframe && (
                <Container maxWidth={'md'}>
                  {context && mmButton}
                  {!isIframe && (
                    <>
                      <AppleLoginButton verb={verb} />
                      <Button
                        className={classes.discordButton}
                        startIcon={
                          <SvgIconDiscord
                            fill={'white'}
                            width='1.5rem'
                            height='1.5rem'
                          />
                        }
                        onClick={handleDiscord}>
                        <Typography variant='h6' fontSize='0.8rem'>
                          {verb} With Discord
                        </Typography>
                      </Button>
                    </>
                  )}
                  {!context && !isSignup && mmButton}
                </Container>
              )}
              <Container maxWidth={'md'}>
                <Typography
                  variant={'subtitle2'}
                  color={'gray'}
                  style={{ marginTop: 40, textAlign: 'center' }}>
                  By logging in, you agree to the{' '}
                  <CdnLink to={'/downloads/terms-of-service.pdf'}>
                    Terms of Service
                  </CdnLink>{' '}
                  and{' '}
                  <CdnLink to={'/downloads/privacy-policy.pdf'}>
                    Privacy Policy
                  </CdnLink>
                  . (C) 2021-2023 LFG Gaming LLC
                </Typography>
              </Container>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ForceLoginDialog;
