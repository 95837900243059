import React, { FunctionComponent } from 'react';
import {
  Theme,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { VoyageEncounterType } from '../../schema';
import { getLootItem, getLootSlot, LootSlot } from '../../Loot';

import OkDialog, { ICloseDialog } from '../../OkDialog';
import { IFurball, IVoyage } from '../../../wallet/WalletTypes';
import { Link } from 'react-router-dom';
import { allRealms, getRealmKeyLootId, ZONE_NUM_VOYAGE } from '../ZoneHelpers';
import SvgCaret from '../../../assets/SvgCaret';
import useCommonStyles from '../../useCommonStyles';
import { expColor, furColor } from '../../../theme';
import { dilemmaSummary } from './RealmDilemmaResultDialog';
import MoveZonesButtons from '../MoveZonesButtons';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '700px',
    margin: '0 auto',
  },
  header: {
    margin: -24,
    marginBottom: 8,
    minWidth: 440,
    padding: theme.spacing(2),
    // paddingLeft: 50,
    width: '100%',
    backgroundColor: '#EEE',
  },
  content: {
    backgroundColor: '#FFF',
    color: '#000',
    textAlign: 'left',
  },
  dialogHeader: {
    backgroundColor: '#EEE',
  },
  zoneButtons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  zoneButton: {
    width: '100%',
    maxWidth: '400px !important',
  },
  battleButton: {
    backgroundColor: `${furColor.light.toString()} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: furColor.light,
    },
  },
  exploreButton: {
    backgroundColor: `${expColor.light.toString()} !important`,
    '&:hover': {
      backgroundColor: '#000 !important',
      color: expColor.light,
    },
  },
  headerGroup: {
    backgroundColor: 'white',
    padding: '20px',

    '& h5': {
      marginBottom: '10px !important',
    },

    '& button': {
      marginRight: '0 !important',
    },
  },
  buttons: {
    width: '100%',
    margin: '15px',
  },
}));

interface IVoyageHistoryDialog extends ICloseDialog {
  furball: IFurball;
}

export const VoyageHistoryDialog: FunctionComponent<IVoyageHistoryDialog> = (
  props,
) => {
  const { furball, open, onClose } = props;
  const common = useCommonStyles();
  const lastVoyage = furball.lastVoyage;
  const lastEncounters = lastVoyage?.encounters ?? [];
  const encounters = [...lastEncounters].sort(
    (a, b) => (a.date ?? 0) - (b.date ?? 0),
  );
  const classes = useStyles();
  const [showZoneButtons, setShowZoneButtons] = React.useState(
    (lastVoyage?.percentComplete ?? 0) >= 100,
  );

  const { totalFurCost, encountersFurGained } = lastVoyage as IVoyage;

  if (lastVoyage == null) return null;

  const isComplete = lastVoyage.daysComplete >= lastVoyage.daysDuration;
  const title = isComplete
    ? 'Voyage Complete!'
    : lastVoyage.daysComplete +
      '/' +
      lastVoyage.daysDuration +
      ' Days Complete';

  const furEncounters = lastEncounters.filter(
    (e) => e.encounterType === VoyageEncounterType.Fur,
  );
  const lootEncounters = lastEncounters.filter(
    (e) => e.encounterType === VoyageEncounterType.Loot,
  );

  function onSaved() {
    onClose();
  }

  function renderEncounterHistory() {
    return (
      <Accordion className={common.cardRoot}>
        <AccordionSummary
          className={common.cardHeader}
          expandIcon={<SvgCaret fill={'#FFF'} className={common.expandIcon} />}>
          <Typography variant={'h5'} style={{ color: 'white' }}>
            Encounter History ({encounters.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>
          {encounters.length === 0 && (
            <React.Fragment>
              <Typography variant={'h4'}>Nothing Yet!</Typography>
              <Typography variant={'subtitle2'}>
                Your Furball will face various challenges along the way.
              </Typography>
            </React.Fragment>
          )}
          {encounters.map((encounter) => {
            let text: React.ReactNode = null;
            const rewards = [];
            if (encounter.furGained)
              rewards.push(`+${encounter.furGained.toLocaleString()} $FUR`);
            // if (encounter.furGained) rewards.push(`+${encounter.furGained} $FUR`);

            if (encounter.encounterType === VoyageEncounterType.Fur) {
              const link = encounter.match ? (
                <Link to={`/furball/${encounter.match.tokenId}`}>
                  a Furball
                </Link>
              ) : (
                'a wild Furball'
              );
              const verb = dilemmaSummary(encounter);
              text = (
                <React.Fragment>
                  {verb} {link}
                </React.Fragment>
              );
            }
            if (encounter.encounterType === VoyageEncounterType.Loot) {
              const dec = encounter.decision ?? 0;
              text =
                dec > 0
                  ? 'Won a ' + getLootItem(dec).name
                  : 'Skipped Loot Mini-Game';
            }

            return (
              <React.Fragment key={encounter.id}>
                <Typography variant={'h6'}>
                  Day #{encounter.voyageDayNumber}: {rewards.join(' & ')}
                </Typography>
                <Typography variant={'subtitle2'}>{text}</Typography>
              </React.Fragment>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  }

  const keySlot = getLootSlot(getLootItem(getRealmKeyLootId(lastVoyage.realm)));
  const keySuffix = isComplete
    ? ' (complete voyage to claim!)'
    : ' (available once voyage is complete)';

  return (
    <OkDialog
      className={classes.root}
      open={open}
      onClose={onClose}
      title={title}
      actions={
        <Grid container className={classes.zoneButtons} spacing={2}>
          <Grid item xs={12}>
            {renderEncounterHistory()}
          </Grid>
          {!showZoneButtons && (
            <Grid item xs={12}>
              <Typography variant={'h5'}>Move Zones</Typography>
              <Typography variant={'subtitle2'}>
                If you move zones now, you will quit the voyage early and
                abandon all rewards.
              </Typography>
              <br />
              <Button
                variant={'contained'}
                onClick={() => setShowZoneButtons(true)}
                className={common.actionButton}>
                <Typography variant={'h6'}>I understand</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      }
      contentClassName={classes.dialogHeader}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.headerGroup}>
            <Grid container spacing={2}>
              {lootEncounters.map((e) => {
                const slot = getLootSlot(
                  getLootItem(e.didPlayerComplete ? e.decision ?? 0 : 0),
                );

                return (
                  <Grid key={e.id} item xs={2}>
                    <div style={{ width: 40 }}>
                      {slot && <LootSlot {...slot} />}
                    </div>
                  </Grid>
                );
              })}
              <Grid item xs={2}>
                <div style={{ width: 40 }}>
                  <LootSlot
                    {...keySlot}
                    disabled={!isComplete}
                    tip={`${keySlot.item.name} ${keySuffix}`}
                  />
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {showZoneButtons && (
          <div className={classes.buttons}>
            <MoveZonesButtons
              realms={allRealms.filter((r) => r !== lastVoyage.realm)}
              canShowRealms={true}
              furballIds={[furball.tokenId]}
              currentZone={ZONE_NUM_VOYAGE}
              onSaved={onSaved}
            />
          </div>
        )}
      </Grid>
    </OkDialog>
  );
};

export default VoyageHistoryDialog;
