import React, { FunctionComponent, useState } from 'react';

import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import theme from '../../themev2';

import { makeStyles } from '@mui/styles';
import { IFurballBase } from '../../wallet/WalletTypes';
import {
  ScholarshipDetailsDialogue,
  useScholarshipHelpers,
} from '../../components/Scholarships';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem',
    backgroundColor: '#32318E !important',
  },

  button: {
    minWidth: '13rem !important',
    maxHeight: '40px !important',
    fontSize: '0.875rem !important',
  },

  detailsCont: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '1.5rem',
    paddingTop: '2rem',
    borderTop: '1px solid rgba(255, 255, 255, 0.3)',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

interface IProps {
  furball: IFurballBase;
}

export const ScholarshipContractDetails: FunctionComponent<IProps> = ({
  furball,
}) => {
  const classes = useStyles();
  const { log } = useFurComponent(ScholarshipContractDetails);
  const [showScholarshipDialog, setShowScholarshipDialog] = useState<string>();

  const {
    furball: fb,
    agreement,
    isOwner,
    isActiveRental,
    playerLink,
    ownerLink,
    timeRemaining,
    endRentalAgreement,
    pendingCancellation,
    loading,
  } = useScholarshipHelpers(log, furball.tokenId);

  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          direction='column'
          gap='2.5rem'>
          {isOwner ? (
            <Typography variant='h4' color={theme.palette.primary.light}>
              {isActiveRental ? 'Rented Out' : 'Listed for rent'}
            </Typography>
          ) : (
            <Typography variant='h4' color={theme.palette.primary.light}>
              Rental Agreement
            </Typography>
          )}

          <Grid container direction='column' gap='1rem'>
            <Grid
              container
              justifyContent='space-between'
              color={theme.palette.grey[300]}>
              {isOwner ? (
                <Typography fontWeight='600' color={theme.palette.grey[100]}>
                  {isActiveRental ? 'Renter' : 'Reserved for'}
                </Typography>
              ) : (
                <Typography fontWeight='600' color={theme.palette.grey[100]}>
                  Contract with
                </Typography>
              )}

              {isOwner ? (
                !agreement?.playerId ? (
                  '-'
                ) : (
                  <Typography>{playerLink}</Typography>
                )
              ) : (
                <Typography>{ownerLink}</Typography>
              )}
            </Grid>

            <Grid
              container
              justifyContent='space-between'
              color={theme.palette.grey[300]}>
              <Typography fontWeight='600' color={theme.palette.grey[100]}>
                {isActiveRental ? 'Time Remaining:' : 'Duration:'}
              </Typography>
              {isActiveRental ? (
                <Typography>{timeRemaining()}</Typography>
              ) : (
                <Typography>
                  {(agreement?.duration || 0) / 60 / 60 / 24} days
                </Typography>
              )}
            </Grid>

            <Grid
              container
              justifyContent='space-between'
              color={theme.palette.grey[300]}>
              <Typography fontWeight='600' color={theme.palette.grey[100]}>
                Auto Renews
              </Typography>
              <Typography fontWeight='600'>
                {agreement?.autoRenew ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container justifyContent='center'>
            {pendingCancellation ? (
              <Typography
                textAlign='center'
                fontSize='1rem'
                mx='1.8rem'
                fontWeight='600'
                color={theme.palette.warning.main}>
                Contract has been terminated and this agreement will be closed
                after the current contract duration is complete.
              </Typography>
            ) : (
              <div className={classes.detailsCont}>
                {isOwner && isActiveRental && (
                  <Button
                    disabled={loading}
                    variant='contained'
                    className={classes.button}
                    onClick={() => setShowScholarshipDialog(furball.tokenId)}>
                    Rental Details
                  </Button>
                )}
                <Button
                  disabled={loading}
                  variant='outlined'
                  className={classes.button}
                  onClick={() => endRentalAgreement()}>
                  {loading ? (
                    <CircularProgress size='1.5rem' />
                  ) : isActiveRental ? (
                    'Terminate Contract'
                  ) : (
                    'Cancel listing'
                  )}
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <ScholarshipDetailsDialogue
        tokenId={furball.tokenId}
        open={furball.tokenId === showScholarshipDialog}
        setOpen={() => setShowScholarshipDialog(undefined)}
      />
    </>
  );
};
