import { getNetworkName, getNetworkType } from '../wallet';
import { currentNetwork, getCompiledNetwork, getContracts } from './compiled';
import {
  IBlockchainNetwork,
  IFurballContext,
  IFurballContracts,
  NetworkType,
} from '../wallet/WalletTypes';
import { ethers } from 'ethers';
import { defaultLogManager } from '../utils';

export interface ISignatureDomain {
  name: string;
  version: string;
}

export function getSignatureDomain(
  domain: ISignatureDomain,
  contractAddress?: string,
  context?: IFurballContext,
) {
  if (!context || !contractAddress) {
    throw `Missing contract for ${domain.name}`;
  }
  return {
    ...domain,
    chainId: context.network.id,
    verifyingContract: contractAddress,
  };
}

function getAllowedSubdomains(name: string): string[] {
  if (name === 'ethereum') return ['www', 'furballs', 'prod'];
  if (name === 'rinkeby') return ['rinkeby', 'localhost', 'alpha', 'beta'];
  if (name === 'goerli') return ['goerli', 'localhost', 'alpha', 'beta'];
  return [name];
}

export async function loadBlockchainNetwork(
  provider: ethers.providers.Web3Provider,
  networkId?: number,
): Promise<IBlockchainNetwork> {
  if (!networkId) {
    const network = await provider.getNetwork();
    networkId = network.chainId;
  }

  const log = defaultLogManager.getLogger('Blockchain');

  const signer = provider.getSigner();
  const networkType = getNetworkType(networkId);
  const deployment = getCompiledNetwork(networkId);
  const name = getNetworkName(networkId);
  const reqSub = getAllowedSubdomains(name);
  const subdomain = reqSub[0];

  let interval = 3600;
  if (networkId === 4) interval = 30;
  else if (networkType === NetworkType.DevNet) {
    interval = 1;
  } else if (networkType !== NetworkType.MainNet) {
    interval = 5;
  }

  let subdomainMismatch: string | undefined = undefined;
  if (networkType !== NetworkType.DevNet) {
    const subs = window.location.hostname.split('.');
    const subdomain = subs[0];
    if (!reqSub.includes(subdomain)) {
      subdomainMismatch = subdomain;
    }
  }

  let contracts: IFurballContracts | undefined = undefined;
  if (deployment) {
    try {
      contracts = getContracts(deployment, signer);
      log.info('network', name, networkId, NetworkType[networkType]);
    } catch (e) {
      console.error(
        name,
        'failed to load contracts.',
        e,
        'from',
        currentNetwork,
      );
    }
  } else {
    log.warn('no deployment to load for', name);
  }
  return {
    id: networkId,
    type: networkType,
    name,
    subdomain,
    interval,
    deployment,
    contracts,
    subdomainMismatch,
  };

  // contracts.furballs.on('GainExp', onGainExp);
  // contracts.furballs.on('GainFur', onGainFur);
}
