import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import OkDialog from '../OkDialog';
import { useShopSnacksQuery } from '../schema';
import { doesSnackBoostStats } from '../Shop/ShopHelpers';
import { lootStats } from '../Stats';
import { getFoodItem } from '../Food';
import FurballRenderer from '../Furballs/FurballRenderer';
import EstimatedRewards from '../Timekeeper/EstimatedRewards';
import { ZONE_NUM_EXPLORE } from './ZoneHelpers';
import clsx from 'clsx';
import { IFurball } from '../../wallet/WalletTypes';
import ZoneButton from './ZoneButton';
import { useWalletSelector } from '../../hooks';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useInterval } from '../../wallet';
import { getFurballIntervals } from '../Timekeeper/TimekeeperReq';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: '700px',
    maxWidth: '750px',
  },
  dialog: {
    padding: '0 !important',
  },
  snacks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5F5F5',
    padding: '40px 20px',

    ['@media (max-width: 700px)']: {
      flexDirection: 'column',
    },
  },
  snack: {
    width: '220px',
    textAlign: 'center',
    padding: '5px 5px 20px 5px',
    margin: '0 10px',
    borderRadius: '6px',
    backgroundColor: theme.palette.secondary.light,
    boxShadow:
      'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    '& svg': {
      height: '120px',
      width: '120px',
    },

    ['@media (max-width: 710px)']: {
      width: '100%',
      marginBottom: '25px',
    },
  },
  furballs: {
    margin: '0px 15px',
  },
  fbsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 10px 115px 10px',
  },
  fbWrapper: {
    position: 'relative',
    padding: '20px 45px 10px 45px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fb: {
    position: 'relative',
    textAlign: 'center',
    backgroundColor: '#EDE7F6',
    borderRadius: '6px',
    margin: '10px',

    '& p': {
      fontSize: '12px !important',
      fontWeight: 700,
    },

    '& h6': {
      fontSize: '14px !important',
      color: '#757575 !important',
      marginTop: '-2.5px !important',
    },

    ['@media (max-width: 710px)']: {
      width: '100%',
      margin: '10px 20px',
    },
  },
  fbNumber: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '25px',
    width: '65px',
    fontSize: '0.9rem !important',
    color: '#212121',
    backgroundColor: '#D1C4E9',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  rel: {
    position: 'relative',
  },
  addSnacksFb: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    height: '65px',
    width: '100%',
    backgroundColor: '#D1C4E9',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
  },
  snackButton: {
    borderRadius: '50% !important',
    backgroundColor: '#EDE7F6 !important',
    border: '2px solid #EDE7F6 !important',
    height: '42.5px !important',
    width: '42.5px !important',
    minHeight: '35px !important',
    minWidth: '35px !important',
    margin: '0 7.5px !important',
    transition: 'all 0.3s !important',

    '& svg': {
      minHeight: '35px !important',
      minWidth: '35px !important',
    },
  },
  fbGotSnack: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    border: '2px solid #86EFAC !important',
  },
  tags: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
  },
  tag: {
    margin: '0px 5px',
    padding: '5px',
    minWidth: '85px',
    fontSize: '0.9rem',
    borderRadius: '4px',
    border: '2px solid #7C4DFF',
    backgroundColor: '#7C4DFF',

    '& p': {
      fontSize: '0.95rem !important',
      lineHeight: '1.2 !important',
      fontWeight: 600,
      opacity: 0.85,

      '& span': {
        fontSize: '0.8rem',
        fontWeight: 400,
        opacity: 0.75,
      },
    },
  },
  feedAllButton: {
    marginTop: '15px !important',
    minWidth: '180px !important',
    minHeight: '40px !important',
    borderRadius: '4px !important',
    backgroundColor: '#AFFFB2 !important',
    border: 'none !important',
    color: 'black !important',
    fontSize: '0.95rem !important',
    transition: 'all 0.3s !important',
  },
  overview: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '90px',
    backgroundColor: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px 30px 0px 30px',

    ['@media (max-width: 600px)']: {
      padding: '5px 10px',
    },
  },
  overviewInfo: {
    color: 'white',

    '& h3': {
      lineHeight: '2.4rem !important',
      fontSize: '2.4rem !important',
    },

    '& h6': {
      fontSize: '0.95rem !important',
      opacity: 0.7,
    },
  },
  loading: {
    height: '350px',
    width: '700px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',

    '& h6': {
      color: 'white !important',
      fontSize: '1.35rem !important',
      marginRight: '1rem !important',
      fontWeight: 600,
    },
  },
}));

interface IShopSnacksProps {
  furballs: IFurball[];
  onClose: () => void;
  collect: boolean;
  onSave: (
    snacksForFurballs?: { [key: string]: number[] },
    snacksCost?: number,
  ) => void;
}

export const ShopSnacksDialog: FunctionComponent<IShopSnacksProps> = (
  props,
) => {
  const { furballs, onClose, onSave, collect } = props;
  const classes = useStyles();

  const { addAlert } = useContext(AlertContext);
  const { data, loading, error } = useShopSnacksQuery();
  const intervalTime = useInterval();

  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const loadedSnacks = data?.shopSnacks ?? [];
  const snacks = loadedSnacks.filter((s) =>
    doesSnackBoostStats(s, ...lootStats),
  );

  const [isCollecting, setIsCollecting] = useState(false);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [snacksForFurballs, setSnacksForFurballs] = useState<{
    [key: string]: number[];
  }>(initSnacksForFurballs);
  const canAfford = wFurBalance >= totalCost;

  useEffect(() => {
    setTotalCost(getTotalCost());
  }, [snacksForFurballs]);

  if (error) {
    addAlert({
      type: ALERT_TYPES.ERROR,
      message: error.message ?? 'Unable to load snacks',
    });

    onClose();
  }

  function initSnacksForFurballs() {
    const mapping: { [key: string]: number[] } = {};
    furballs.forEach((fb) => (mapping[fb.tokenId] = []));
    return mapping;
  }

  function applySnacksAndCollect() {
    setIsCollecting(true);
    onSave(snacksForFurballs, totalCost);
  }

  function addSnack(tokenId: string, snackId: number) {
    const fbSnacks = snacksForFurballs[tokenId];
    const haveSnack = fbSnacks.includes(snackId);

    if (haveSnack) {
      const updatedSnackIds = fbSnacks.filter((id) => id !== snackId);
      setSnacksForFurballs({
        ...snacksForFurballs,
        [tokenId]: updatedSnackIds,
      });
      return;
    }

    setSnacksForFurballs({
      ...snacksForFurballs,
      [tokenId]: [...fbSnacks, snackId],
    });
  }

  function feedAllFbsSnack(snackId: number) {
    Object.keys(snacksForFurballs).forEach((tokenId) => {
      if (!snacksForFurballs[tokenId].includes(snackId)) {
        snacksForFurballs[tokenId].push(snackId);
      }
    });

    setSnacksForFurballs({ ...snacksForFurballs });
  }

  function allFbsGotSnack(snackId: number) {
    for (const key of Object.keys(snacksForFurballs)) {
      if (!snacksForFurballs[key].includes(snackId)) {
        return false;
      }
    }

    return true;
  }

  function getSnackMultiplier(tokenId: string) {
    let totalMultiplier = 0;
    snacksForFurballs[tokenId].forEach((snackId) => {
      const { snack } = getFoodItem(snackId);
      totalMultiplier += snack.happiness;
    });

    return totalMultiplier;
  }

  function getTotalCost() {
    let total = 0;
    Object.keys(snacksForFurballs).forEach((tokenId) => {
      const fb = furballs.find((f) => f.tokenId === tokenId);
      if (!fb) {
        console.warn('missing furball', tokenId);
        return;
      }
      const intervals = getFurballIntervals(fb, intervalTime);
      const days = intervals / 24.0;

      snacksForFurballs[tokenId].forEach((snackId) => {
        const { snack } = getFoodItem(snackId);
        total += Math.ceil(snack.furCost * days);
      });
    });

    return total;
  }

  function renderSnacks() {
    return snacks.map((snack) => {
      const { icon } = getFoodItem(snack.id);
      const disabled = allFbsGotSnack(snack.id);

      return (
        <div key={snack.name} className={classes.snack}>
          {icon}
          <Typography variant={'h5'}>{snack.name}</Typography>
          <div className={classes.tags}>
            <div className={classes.tag}>
              <Typography variant={'body2'}>
                +{snack.happiness}%<br />
                <span>EXP</span>
              </Typography>
            </div>
            <div className={classes.tag}>
              <Typography variant={'body2'}>
                {snack.furCost.toLocaleString()}
                <br />
                <span>$wFUR/day</span>
              </Typography>
            </div>
          </div>
          <Button
            className={classes.feedAllButton}
            disabled={disabled}
            style={{
              opacity: disabled ? 0.7 : 1,
            }}
            onClick={() => feedAllFbsSnack(snack.id)}>
            {disabled ? 'All Furballs Fed!' : 'Feed All!'}
          </Button>
        </div>
      );
    });
  }

  function renderSnackButtons(tokenId: string) {
    return snacks.map((snack) => {
      const { icon } = getFoodItem(snack.id);
      const fbGotSnack = snacksForFurballs[tokenId].includes(snack.id);

      return (
        <Button
          key={`button-single-${snack.name}`}
          color={'secondary'}
          className={clsx(classes.snackButton, {
            [classes.fbGotSnack]: fbGotSnack,
          })}
          onClick={() => addSnack(tokenId, snack.id)}>
          {icon}
        </Button>
      );
    });
  }

  function renderFurballs() {
    return furballs.map((fb) => {
      const { tokenId } = fb;

      return (
        <div key={tokenId} className={classes.fb}>
          <div className={classes.fbWrapper}>
            <Typography variant={'h5'} className={classes.fbNumber}>
              #{fb.number}
            </Typography>
            <FurballRenderer
              tokenId={tokenId}
              size={'110px'}
              className={classes.rel}
            />
            <EstimatedRewards
              forSingleFb={true}
              zoneNumber={ZONE_NUM_EXPLORE}
              furballs={[fb]}
              snackMultiplier={getSnackMultiplier(tokenId)}
            />
          </div>
          <div className={classes.addSnacksFb}>
            {renderSnackButtons(tokenId)}
          </div>
        </div>
      );
    });
  }

  function renderOverview() {
    return (
      <div className={classes.overview}>
        <div className={classes.overviewInfo}>
          <Typography variant={'h6'}>Total $wFUR cost</Typography>
          <Typography variant={'h3'}>{totalCost.toLocaleString()}</Typography>
        </div>
        <div className={classes.actions}>
          {!canAfford && (
            <Typography variant={'h6'}>Not Enough $wFUR!</Typography>
          )}
          <ZoneButton
            text={collect ? 'Collect' : 'Enter'}
            collect={collect}
            width={'13rem'}
            onClick={() => applySnacksAndCollect()}
            disabled={!canAfford || isCollecting}
            loading={isCollecting}
          />
        </div>
      </div>
    );
  }

  function renderContent() {
    if (loading) {
      return (
        <div className={classes.loading}>
          <CircularProgress color='secondary' size={60} />
        </div>
      );
    }

    return (
      <>
        <div className={classes.snacks}>{renderSnacks()}</div>
        <Grid item xs={12} className={classes.fbsWrapper}>
          {renderFurballs()}
        </Grid>
        {renderOverview()}
      </>
    );
  }

  return (
    <OkDialog
      title={'Add Snacks?'}
      open={true}
      onClose={onClose}
      contentClassName={classes.dialog}>
      <div className={classes.root}>{renderContent()}</div>
    </OkDialog>
  );
};

export default ShopSnacksDialog;
