import * as React from 'react';

function SvgIconWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' {...props}>
      <path
        d='M5.37 36.19l15.28-26.4c1.49-2.58 5.21-2.58 6.7 0l15.28 26.4c1.5 2.58-.37 5.81-3.35 5.81H8.72c-2.98 0-4.85-3.23-3.35-5.81zM22.7 30.6h3V19.4h-3v11.2zm1.5 5.55c.43 0 .79-.14 1.07-.43.28-.28.43-.64.43-1.07 0-.43-.14-.79-.43-1.08-.28-.28-.64-.42-1.07-.42s-.79.14-1.08.42-.42.64-.42 1.08c0 .43.14.79.42 1.07.28.28.64.43 1.08.43z'
        fill={props.fill ?? '#000000'}
      />
    </svg>
  );
}

export default SvgIconWarning;
