import React, { FunctionComponent } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';

import clsx from 'clsx';

import { useStyles } from './SkillLevelControl';

import { useSkillMaxUsesControls } from './hooks';
import { IUpgradeControlProps } from './SkillTypes';

export const SkillMaxUsesControl: FunctionComponent<IUpgradeControlProps> = ({
  label,
  skill,
  partials,
  setPartials,
  partialsSelected,
  setPartialsSelected,
  furballId,
  isLoading,
  isOnCooldown,
  getCooldownRemaining,
  numUpgraded,
  maxDailyUpgrades,
}) => {
  const { bars, params, canAfford, handleUpgrade, getBarStatus } =
    useSkillMaxUsesControls({
      skill,
      partials,
      setPartials,
      partialsSelected,
      setPartialsSelected,
      furballId,
      numUpgraded,
      maxDailyUpgrades,
    });

  const classes = useStyles();
  const downDisabled =
    isLoading ||
    !skill.maxUsesIsUpgradeable ||
    params.noChanges ||
    skill.maxUsesTotalPartialsRemaining === 0;
  const upDisabled =
    !canAfford || isLoading || !skill.maxUsesIsUpgradeable || params.isMaxLevel;

  const [mouseDown, setMouseDown] = React.useState(0);
  React.useEffect(() => {
    if (mouseDown === 0) return;
    const dir = mouseDown < 0 ? -1 : 1;
    const to = setTimeout(
      () => {
        if (dir < 0 && !downDisabled) handleUpgrade('down');
        if (dir > 0 && !upDisabled) handleUpgrade('up');
        setMouseDown(mouseDown + dir);
      },
      Math.abs(mouseDown) === 1 ? 1000 : 30,
    );
    return () => {
      clearTimeout(to);
    };
  }, [mouseDown]);
  // const skillIsOnCooldown = isOnCooldown(SkillType.Uses);

  function renderHelperText() {
    if (!skill.maxUsesIsUpgradeable) {
      return (
        <Typography
          variant='subtitle2'
          color='#7DF5B7'
          className={classes.pendingText}>
          Not Upgradable!
        </Typography>
      );
    }

    // if (skillIsOnCooldown) {
    //   const { hours, mins } = getCooldownRemaining(skill.maxUsesUpgradedAt);
    //   return (
    //     <>
    //       <Typography
    //         color='#7DF5B7'
    //         className={classes.pendingText}
    //         style={{ fontSize: '0.8rem !important' }}>
    //         Next daily upgrade in:
    //       </Typography>
    //       <Typography
    //         color='#FFF'
    //         fontWeight='600'
    //         className={classes.pendingText}
    //         style={{ marginTop: '30px' }}>
    //         {hours}h {mins}m
    //       </Typography>
    //     </>
    //   );
    // }

    // if (dailyLimitReached) {
    //   return (
    //     <Typography
    //       color='#FFF'
    //       fontWeight='600'
    //       className={classes.pendingText}>
    //       Current upgrade <br />
    //       limit reached!
    //     </Typography>
    //   );
    // }

    return null;
  }

  return (
    <div className={classes.root}>
      <Typography
        variant='body1'
        fontWeight={500}
        className={classes.label}
        color='#ffff'>
        {label}
      </Typography>
      <div className={classes.wrapper}>
        <Grid
          item
          alignItems='center'
          justifyContent='center'
          className={classes.buttonContainer}>
          <Button
            data-type='minus'
            data-maxuses-minus
            disabled={isLoading || downDisabled}
            className={classes.ctrlButton}
            onClick={() => handleUpgrade('down')}>
            <span>-</span>
          </Button>

          {skill.maxUsesIsUpgradeable &&
            skill.maxUsesTotalPartialsRemaining !== 0 &&
            !params.isMaxLevel && (
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.buttonLabel}>
                <Typography
                  className={classes.btnLabel}
                  textAlign='center'
                  fontWeight='600'
                  color='#8785D2'>
                  {params.currentLevel}
                </Typography>
              </Grid>
            )}
        </Grid>

        <div
          style={{ gridTemplateColumns: `repeat(${bars?.length}, 1fr)` }}
          className={classes.progressWrap}>
          {bars?.map((i) => (
            <div
              key={i}
              className={clsx(classes.progressBar, getBarStatus(i), {
                [classes.progressBarDim]: !skill.maxUsesIsUpgradeable,
              })}></div>
          ))}

          {renderHelperText()}
        </div>

        <Grid
          item
          alignItems='center'
          justifyContent='center'
          className={classes.buttonContainer}>
          <Button
            disabled={upDisabled}
            data-type='plus'
            data-maxuses-plus
            className={classes.ctrlButton}
            onClick={() => handleUpgrade('up')}>
            <span>
              {isLoading ? <CircularProgress size={20} color='inherit' /> : '+'}
            </span>
          </Button>

          {skill.maxUsesIsUpgradeable && (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.buttonLabel}>
              <Typography
                className={classes.btnLabel}
                textAlign='center'
                fontWeight='600'
                color='#ffff'>
                {params.isMaxLevel
                  ? params.currentLevel
                  : params.currentLevel + 1}
              </Typography>
              {params.isMaxLevel && (
                <Typography
                  className={classes.btnLabel}
                  textAlign='center'
                  fontWeight='600'
                  color='#7DF5B7'>
                  MAX
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
