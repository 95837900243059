import * as React from 'react';

function SvgAccount(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='account_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 77.93 68.04'
      xmlSpace='preserve'
      {...props}>
      <style>{'.account_svg__st7{fill:#bba4ff}'}</style>
      <path
        className='account_svg__st7'
        d='M54.07 36.37c-4.04 3.52-9.32 5.66-15.11 5.66s-11.06-2.14-15.11-5.66C10.93 40.44 1.51 49.98.02 64.05c-.23 2.13 1.42 3.99 3.56 3.99h70.77c2.14 0 3.78-1.87 3.56-3.99C76.42 49.98 67 40.44 54.07 36.37z'
      />
      <path
        className='account_svg__st7'
        d='M38.96 0c10.49 0 19.02 8.53 19.02 19.02s-8.53 19.02-19.02 19.02-19.01-8.54-19.01-19.02S28.48 0 38.96 0'
      />
    </svg>
  );
}

export default SvgAccount;
