import React, { FunctionComponent } from 'react';
import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import useCommonStyles from '../useCommonStyles';
import { yellow } from '../../themev2';
import MobileDrawer from './MobileDrawer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.light,
    overflowY: 'auto',
  },
  childContainer: {
    paddingTop: (props: any) =>
      props.drawer === null ? '0px !important' : theme.spacing(2),
    paddingBottom: (props: any) =>
      props.drawer === null ? '0' : theme.spacing(2),
    marginTop: '0 !important',
    paddingLeft: '16px !important',
  },
  drawer: {
    width: '21.25rem',
    backgroundColor: yellow,
    overflowY: 'auto',
    position: 'fixed',
    padding: '20px 0',
    left: 0,
  },
  main: {
    position: 'absolute',
    left: '21.25rem',
    right: 0,
    overflowY: 'auto',
    paddingTop: '0 !important',
  },
}));

interface IFullDrawer {
  drawer: React.ReactNode;
  children: React.ReactNode;
  customContainerClass?: string;
  customDrawerClass?: string;
}

export const FullDrawer: FunctionComponent<IFullDrawer> = (props) => {
  const { drawer, children } = props;
  const common = useCommonStyles();
  const classes = useStyles(props);
  const theme = useTheme();
  // const isDesktop = useWindowSize().width > 600;
  const isDesktop = !useMediaQuery(theme.breakpoints.down('lg'));

  function renderDrawer() {
    if (!drawer) return null;

    if (isDesktop) {
      return (
        <div
          className={clsx(
            classes.drawer,
            common.fullHeight,
            props.customDrawerClass,
          )}>
          {drawer}
        </div>
      );
    }

    return <MobileDrawer drawerContent={drawer} />;
  }

  return (
    <div
      className={clsx(
        classes.root,
        common.fullScreen,
        props.customContainerClass,
      )}>
      {renderDrawer()}
      <div
        className={clsx(common.fullHeight, {
          [classes.main]: isDesktop && drawer,
        })}>
        <Grid
          container
          className={classes.childContainer}
          spacing={2}
          sx={{
            paddingLeft: drawer ? '24px' : '16px',
          }}>
          {children}
        </Grid>
      </div>
    </div>
  );
};

export default FullDrawer;
