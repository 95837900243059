import * as React from 'react';

function SvgGift(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 490 490'
      xmlSpace='preserve'
      {...props}>
      <path
        id='XMLID_1571_'
        d='M475,100.031h-65.792c10.483-15.805,13.518-34.99,8.297-53.228c-0.278-0.975-1.567-4.819-1.94-5.79
	C408.268,22.11,392.826,8.083,373.2,2.529c-20.051-5.676-41.06-1.695-57.633,10.923L245,67.179l-70.567-53.727
	c-16.572-12.617-37.577-16.6-57.634-10.923C97.173,8.084,81.732,22.11,74.438,41.01c-0.362,0.937-1.663,4.815-1.94,5.785
	c-5.223,18.245-2.189,37.43,8.294,53.236H15c-8.284,0-15,6.715-15,14.999V235.02c0,8.284,6.716,14.999,15,14.999h15v224.982
	C30,483.285,36.716,490,45,490h400c8.284,0,15-6.715,15-14.999V250.019h15c8.284,0,15-6.715,15-14.999V115.029
	C490,106.746,483.284,100.031,475,100.031z M260,93.463l73.741-56.144c8.994-6.848,20.398-9.01,31.289-5.926
	c10.462,2.961,18.68,10.404,22.541,20.408c0.204,0.53,0.934,2.703,1.09,3.25c2.974,10.386,0.892,21.387-5.711,30.18
	c-6.823,9.087-17.188,14.325-28.437,14.368c-1.215,0.005-9.514,0.432-9.514,0.432h-85V93.463z M260,162.286l40.757,57.735H260
	V162.286z M101.338,55.052c0.156-0.545,0.881-2.708,1.086-3.238c3.866-10.017,12.084-17.46,22.545-20.421
	c10.89-3.083,22.294-0.922,31.289,5.926L230,93.463v6.568h-85c0,0-8.298-0.427-9.514-0.432
	c-11.248-0.044-21.612-5.281-28.436-14.368C100.447,76.437,98.365,65.437,101.338,55.052z M30,220.021v-89.993h200v89.993H30z
	 M430,460.002H60V250.019h170v104.992c0,8.284,6.716,14.999,15,14.999s15-6.715,15-14.999V250.019h61.934l30.812,43.647
	c4.779,6.768,14.14,8.38,20.905,3.604c6.768-4.777,8.382-14.136,3.604-20.903l-18.601-26.348H430V460.002z M460,220.021H337.479
	l-63.529-89.993H460V220.021z'
      />
    </svg>
  );
}

export default SvgGift;
