import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Pinata = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M241.91 204.25c-12.27 7.5-28.19 12.6-45.59 12.6-24.61 0-46.26-10.21-58.81-22.96l-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.66-9.73z'
    />
    <path
      fill={props.renderer.color2}
      d='M231.24 251.1c-2.91.86-8.74-10.03-11.58-9.43-2.96.63-3.84 12.95-6.73 13.33-3 .4-7.04-11.28-9.95-11.13-3.02.15-5.87 12.17-8.79 12.07-3.02-.09-5.12-12.27-8.01-12.6-3-.34-7.75 11.06-10.62 10.5-2.96-.58-3.12-12.93-5.93-13.71-2.92-.8-9.35 9.74-12.09 8.76-2.86-1.02-1.21-13.27-3.87-14.43-2.79-1.22-10.63 8.32-13.19 7-2.73-1.41.52-13.34-1.93-14.81-2.67-1.6-11.65 6.87-13.97 5.28-2.63-1.8 2.06-13.26-.1-14.94-2.66-2.08-12.63 5.21-14.52 3.53-3.68-3.27 3.01-9.62 2.61-13.34 0 0-24.71 13.82-24.97 15.12 3.47-1.78 6.52-2.45 8.41-1.17 2.97 2-2.9 13.68-.35 15.9 2.7 2.35 13.04-4.67 15.68-2.58 2.82 2.22-1.57 13.91 1.18 15.86 2.94 2.07 12.46-6.01 15.32-4.24 3.06 1.9.1 14.03 3.06 15.6 3.18 1.69 11.54-7.59 14.61-6.24 3.31 1.45 2.18 13.88 5.34 14.97 3.42 1.18 10.14-9.35 13.39-8.55 3.52.86 4.66 13.28 7.96 13.76 3.6.51 8.13-11.12 11.45-11 3.65.13 7.36 12.03 10.66 11.78 3.67-.28 5.53-12.63 8.75-13.25 3.69-.71 10.04 10 13.07 9.02 3.77-1.21 2.64-13.69 5.33-14.95 3.52-1.65 6.79 1.61 9.74 3.82 2.64-5.3 7.39-21.55 7.56-23.22-2.83 1.07-4.74 12.46-7.52 13.29z'
    />
    <path
      fill={props.renderer.color1}
      d='M175.35 282.85c-2.93.2-5.65 7.92-8.4 11.22h20.62l.06-.06c-3.71.59-8.73-11.4-12.28-11.16z'
    />
    <path
      fill={props.renderer.color2}
      d='M154.69 282.26c-3.71-.47-9.5 11.14-12.98 10.34-3.64-.84-3.73-13.84-7.12-14.99-3.53-1.2-11.5 9.04-14.76 7.57-3.4-1.54-1.01-14.32-4.11-16.08-3.26-1.85-12.97 6.76-15.9 4.74-3.11-2.14 1.46-14.32-1.27-16.55-2.96-2.42-13.95 4.49-16.46 2.09-2.83-2.7 3.56-14.04 1.3-16.55-2.77-3.07-14.69 2.07-16.6-.41-.26-.34-1.33.22-1.67.85-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96h87c-2.68-3.37-4.94-11.44-7.75-11.8z'
    />
    <path
      opacity={0.15}
      d='M106.6 272.34c11.52 18.58 81 9.58 81 9.58 41.4-7.56 46.5-38.59 51.47-57.62 6.96 41.07-31.04 64.33-40.96 68-2.79 1.03-94.44 1.55-94.44 1.55s-5.61-13.8 2.93-21.51'
    />
    <path
      fill='#ff7d12'
      d='M92.09 280.55c10.01 1.14 11.58 13.35 11.58 13.35h-21.8c-1.44 0-2.71-3.17-3.14-4.55l-.9-2.86s3 .75 7.17-.26c5.51-1.33 6.94-5.69 7.09-5.68z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m78 288 2 5'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-48.15 25.64c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M131.81 189.32c-3.31.73-5.05 6.84-3.25 9.91 7.67 13.09 38.13 20.17 38.13 20.17M239.57 205.16c1.06.24 1.96 1.03 2.64 2.09 2.16 3.41.95 7.96-2.56 9.95-9.48 5.37-24.25 6.61-24.25 6.61'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01'
    />
    <path
      fill='#ff7d12'
      d='M161.98 290.02c.51 1.6 2.04 2.69 3.66 2.69h22.47c.55 0 .98-.47.92-1.01-.26-2.35-1.8-8.07-10.73-9.09 0 0-.98-.06-1.39-.79-.39-.69-1.19-1.23-1.9-.9-3.24 1.52-10.26 2.84-13.83 1.93a.824.824 0 0 0-1 1.03l1.8 6.14z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m154.48 268.6 5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      d='M257.76 153.51a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill={props.renderer.color2}
      d='M134.87 153.51s-15.38 17.52-6.54 30.8c16.89 25.39 55.47 33.59 52.09 31.75-2.98-1.62 6.04-17.4 2.98-19.44-2.81-1.87-17.96 7.28-20.72 5.03-2.59-2.1 7.91-15 5.45-17.46-2.33-2.33-18.31 5.13-20.46 2.46-2.05-2.55 10.3-14.47 8.47-17.34-1.74-2.75-18.19 2.75-19.67-.32-1.42-2.93 12.54-13.54 11.41-16.78-1.09-3.1-12.23 4.69-13.01 1.3M258.01 153.51s15.38 17.52 6.54 30.8c-16.89 25.39-56.21 32.93-52.83 31.09 2.98-1.62-5.3-16.74-2.24-18.78 2.81-1.87 17.96 7.28 20.72 5.03 2.59-2.1-7.91-15-5.45-17.46 2.33-2.33 18.31 5.13 20.46 2.46 2.05-2.55-10.3-14.47-8.47-17.34 1.74-2.75 18.19 2.75 19.67-.32 1.42-2.93-12.54-13.54-11.41-16.78 1.1-3.1 12.24 4.69 13.01 1.3'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M180.99 281.91s-.52 2.83-3.92 3.38M184.22 283.42s-.1 2.69-3.52 4.17M95.42 282.63s.12 2.88-3.08 4.16M98.91 283.39s.5 2.65-2.52 4.84'
    />
    <path
      fill='#ff7d12'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
  </svg>
);

export default Pinata;
