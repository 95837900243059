import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';

import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import OkDialog from '../OkDialog';
import SvgPadlock from '../../assets/SvgPadlock';

import { LS } from './AuthContext';
import { inIframe } from '../../wallet/Wallet';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: 'center',
    maxWidth: '32rem',
    padding: '2rem 0',
  },

  button: {
    minWidth: '12rem !important',
  },

  loader: {
    position: 'absolute',
    inset: 0,
    zIndex: 10,
    display: 'grid',
    placeItems: 'center',
    backgroundColor: theme.palette.common.white,
  },
}));

interface IProps {
  open: boolean;
  loading: boolean;
  signIn: (skipIgnore: boolean) => void;
}

export const AuthResignModal: FunctionComponent<IProps> = ({
  open,
  loading,
  signIn,
}) => {
  const classes = useStyles();
  const iframe = inIframe(); // iframes cannot access local storage
  const ignoredAuth = iframe ? true : localStorage.getItem(LS.AUTH_IGNORED);

  return (
    <OkDialog
      blockchainBanner
      open={open}
      topRight={false}
      onClose={() => null}>
      <Grid container gap='2rem' className={classes.container}>
        {loading && (
          <div className={classes.loader}>
            <CircularProgress size='4rem' color='secondary' />
          </div>
        )}

        <Grid item xs={12}>
          <SvgPadlock style={{ maxWidth: 130, maxHeight: 130 }} />
          <Typography
            variant='h3'
            style={{ fontWeight: 'bolder', paddingBottom: 10, paddingTop: 10 }}>
            {ignoredAuth ? 'Authentication Required!' : 'Session Expired!'}
          </Typography>
          <Typography
            variant='body1'
            style={{ paddingLeft: 50, paddingRight: 50 }}>
            {ignoredAuth
              ? 'You must sign in to your Furballs account to authorize this action.'
              : 'Please sign in again to continue.'}
          </Typography>
        </Grid>

        <Grid
          item
          container
          alignItems='center'
          justifyContent='center'
          gap='0.3rem'>
          <Button
            variant='contained'
            className={classes.button}
            onClick={() => signIn(true)}>
            Sign In
          </Button>
        </Grid>
      </Grid>
    </OkDialog>
  );
};
