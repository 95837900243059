import React, { FunctionComponent, useState } from 'react';
import { Theme, Container, Typography, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballBase } from '../../wallet/WalletTypes';
import PfpGenerator from './PfpGenerator';
import { useWalletSelector } from '../../hooks';
import { FurballCard } from '../../components/Furballs';

import OkDialog from '../../components/OkDialog';
import UpgradeDialog from '../../components/Town/Upgrade/UpgradeDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
  },

  furballName: {
    fontWeight: 'bold',
  },

  dialogue: {
    backgroundColor: (props: any) => `#${props.furBg}`,
  },
}));

interface IFurballPrivateDrawer {
  furball: IFurballBase;
  isRented?: boolean;
}

export function useOwnershipCheck(tokenId: string): boolean {
  const tokenIds = useWalletSelector((s) => s.contents?.tokenIds ?? []);
  return tokenIds.includes(tokenId);
}

export const FurballPrivateDrawer: FunctionComponent<IFurballPrivateDrawer> = (
  props,
) => {
  const [showCustomiser, setShowCustomiser] = useState<boolean>(false);
  const [showUpgrade, setShowUpgrade] = useState<boolean>(false);

  const { furball, isRented } = props;
  const classes = useStyles({ furBg: furball.backgroundColor });
  const isOwner = useOwnershipCheck(furball.tokenId);

  if (!isOwner) return null;

  const toggleModal = () => setShowCustomiser(!showCustomiser);

  return (
    <Container className={clsx(classes.root)}>
      <Typography variant={'h4'}>
        {isRented ? 'Borrowed' : 'My'} Furball
      </Typography>
      <Typography variant={'body1'}>You can share this page.</Typography>
      <Typography variant={'subtitle2'}>
        Only you can see this yellow area.
      </Typography>
      <br />
      <FurballCard
        tokenId={furball.tokenId}
        onClickDetails={() => setShowUpgrade(true)}
      />
      <br />
      <Grid>
        <Typography variant={'h4'}>Avatar Generator</Typography>
        <Typography variant={'body2'}>
          Create awesome profile pictures for social media from this furball.{' '}
        </Typography>
        <br />
        <Button variant='contained' onClick={toggleModal}>
          <Typography variant='h6' fontWeight='800' fontSize='1rem'>
            Open Customiser
          </Typography>
        </Button>
      </Grid>

      <OkDialog
        keepMounted
        title='Avatar Generator'
        titleBg='#1A0273'
        open={showCustomiser}
        onClose={toggleModal}
        className={classes.dialogue}>
        <PfpGenerator furball={furball} />
      </OkDialog>

      <UpgradeDialog
        show={showUpgrade}
        tokenId={furball.tokenId}
        onClose={() => setShowUpgrade(false)}
        onAction={() => setShowUpgrade(false)}
      />
    </Container>
  );
};

export default FurballPrivateDrawer;
