import * as React from 'react';

function SvgIconDollar(props: React.SVGProps<SVGSVGElement>) {
  const fill = props.fill ?? '#fde99a';
  return (
    <svg
      width={1600}
      height={1600}
      viewBox='0 0 1200 1200'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      fill={fill}>
      <path d='M1150 975H50c-13.801 0-25-11.199-25-25V250c0-13.801 11.199-25 25-25h1100c13.801 0 25 11.199 25 25v700c0 13.801-11.199 25-25 25zM75 925h1050V275H75z' />
      <path d='M599.95 775c-66.699 0-120.4-40.051-124.85-93.102-1.148-13.75 9.05-25.852 22.801-27 13.551-1.398 25.852 9.05 27 22.801 2.2 26.102 35.852 47.301 75 47.301 40.699 0 75.051-22.898 75.051-50 0-24.398-23.699-40.102-74.551-49.5l-5.398-1c-81.148-14.949-120-48.148-120-99.5 0-55.148 56.102-100 125.05-100 66.699 0 120.35 40 124.85 93.102 1.148 13.75-9.051 25.852-22.801 27-13.301 1.7-25.852-9.05-27-22.801-2.2-26.051-35.852-47.301-75-47.301-40.7 0-75.051 22.898-75.051 50 0 24.398 23.75 40.102 74.648 49.5l5.5 1.05c81 14.95 119.85 48.103 119.85 99.5-.051 55.099-56.152 99.95-125.1 99.95z' />
      <path d='M600 825c-13.801 0-25-11.199-25-25V400c0-13.801 11.199-25 25-25s25 11.199 25 25v400c0 13.801-11.199 25-25 25zM1050 375H950c-13.801 0-25-11.199-25-25s11.199-25 25-25h100c13.801 0 25 11.199 25 25s-11.199 25-25 25zM250 875H150c-13.801 0-25-11.199-25-25s11.199-25 25-25h100c13.801 0 25 11.199 25 25s-11.199 25-25 25z' />
    </svg>
  );
}

export default SvgIconDollar;
