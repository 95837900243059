import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';

import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';
import {
  Maybe,
  TransactionHistoryQuery,
  useTransactionHistoryLazyQuery,
} from '../../components/schema';
import { formatDate } from '../../utils';
import theme from '../../themev2';
import SvgIconFurNew from '../../assets/SvgIconFurNew';
import SvgIconTix from '../../assets/SvgIconTix';
import SvgIconWrappedFur from '../../assets/SvgIconWrappedFur';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import SvgIconCaretDown from '../../assets/SvgIconCaretDown';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxHeight: '450px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '0.15rem',
    position: 'relative',

    '&::-webkit-scrollbar': {
      [theme.breakpoints.down('lg')]: {
        width: '0',
      },
    },
  },

  loader: {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    height: '450px',
  },

  wrapper: {
    width: '100%',
    overflowX: 'auto',
  },

  container: {
    position: 'relative',
    marginTop: '2rem',
    minWidth: '750px',
  },

  row: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '15% 30% 30% 1fr',
    padding: '0.5rem 0.75rem',

    '&:not(:first-of-type)': {
      borderTop: '1px solid #1A1E7D',
    },

    '&[data-top-row]': {
      top: 0,
      position: 'sticky',
      padding: '0.7rem 0.75rem',
      borderTop: 'none',
      borderBottom: '1px solid #1A1E7D',
      backgroundColor: theme.palette.secondary.dark,

      [theme.breakpoints.down('sm')]: {
        marginTop: '2.7rem',
      },
    },
  },

  rowTitle: {
    fontSize: '0.8rem !important',
    textAlign: 'left',
    fontWeight: 600,
    transition: 'all 0.2s linear',
    textTransform: 'uppercase',
    color: theme.palette.grey[500],
  },

  rowText: {
    textAlign: 'left',
    fontSize: '0.95rem !important',
    color: `${theme.palette.common.white} !important`,
  },

  sensor: {
    height: '4rem',
    paddingTop: '1rem',
    display: 'grid',
    placeItems: 'center',
    width: '100%',
  },

  trigger: {
    cursor: 'pointer',
    color: theme.palette.common.white,

    '&:hover': {
      '& p': {
        color: 'white !important',
      },
    },
  },

  menu: {
    marginTop: '0.3rem',
    borderRadius: '4px !important',
    minWidth: '7rem !important',

    '& ul': {
      padding: 0,
    },

    '& li': {
      paddingLeft: '0.5rem',
      fontSize: '0.8rem',
    },
  },
}));

enum TOKENS {
  WFUR = 'WFUR',
  FUR = 'FUR',
  TIX = 'TIX',
}

const icons = {
  fur: <SvgIconFurNew height={20} width={20} />,
  wfur: <SvgIconWrappedFur height={20} width={20} />,
  tix: <SvgIconTix height={15} width={15} />,
};

type SearchResults = TransactionHistoryQuery['playerTransactions'];

export const TransactionHistory = () => {
  const classes = useStyles();
  const common = useCommonStyles();
  const bottomRef = useRef<HTMLDivElement>(null);

  const [filter, setfilter] = useState<TOKENS>();
  const [history, setHistory] = useState<SearchResults>();

  const columns = [filter ?? 'Token', 'Date', 'Amount', 'Validation'];
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'transaction-filter',
  });

  const [loadTransactions, { loading }] = useTransactionHistoryLazyQuery();

  const load = async (isLoadingMore?: boolean) => {
    const { data } = await loadTransactions({
      variables: {
        token: !filter ? (Object.values(TOKENS) as any) : [filter],
        endCursor: (isLoadingMore
          ? history?.pageInfo.endCursor
          : undefined) as Maybe<string>,
      },
      fetchPolicy: 'network-only',
    });

    !!data && updateHistory(data);
  };

  const updateHistory = (data?: TransactionHistoryQuery) => {
    if (!data) return;

    const newNodes = data?.playerTransactions?.nodes || [];
    const oldNodes = history?.nodes || [];
    const allNodes = [...oldNodes, ...newNodes];

    // removes duplicate results
    const uniqueNodes = new Map(
      allNodes.map((item) => [item['id'], item]),
    ).values();

    if (data?.playerTransactions?.pageInfo.hasPreviousPage) {
      setHistory({
        ...data?.playerTransactions,
        nodes: Array.from(uniqueNodes),
      });
    } else {
      setHistory(data?.playerTransactions);
    }
  };

  useEffect(() => {
    load();
  }, [filter]);

  useEffect(() => {
    const target = bottomRef.current as HTMLElement;
    if (!target || !history) return;

    const options = {
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && history.pageInfo.hasNextPage) {
          load(true);
        }
      });
    }, options);

    observer.observe(target);

    return () => {
      observer.unobserve(target);
    };
  }, [history]);

  const handleFilter = (value?: TOKENS) => {
    setfilter(value);
    popupState.close();
  };

  if (!history?.nodes?.length && loading) {
    return (
      <div className={classes.loader}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div data-top-row className={classes.row}>
          {columns.map((item, index) => (
            <>
              {index === 0 ? (
                <>
                  <Grid
                    container
                    gap='1rem'
                    alignItems='center'
                    className={classes.trigger}
                    {...bindTrigger(popupState)}>
                    <Typography key={index} className={classes.rowTitle}>
                      {item}
                    </Typography>
                    <SvgIconCaretDown width='1rem' height='1rem' />
                  </Grid>
                  <Menu
                    PaperProps={{ className: classes.menu }}
                    {...bindMenu(popupState)}>
                    <MenuItem onClick={() => handleFilter(undefined)}>
                      ALL
                    </MenuItem>
                    {Object.values(TOKENS).map((item, i) => (
                      <MenuItem key={i} onClick={() => handleFilter(item)}>
                        {item}
                      </MenuItem>
                    ))}
                  </Menu>
                </>
              ) : (
                <Typography key={index} className={classes.rowTitle}>
                  {item}
                </Typography>
              )}
            </>
          ))}
        </div>

        {!history?.nodes?.length && !loading ? (
          <div className={classes.loader}>
            <Typography className={classes.rowText}>
              No Transactions Found!
            </Typography>
          </div>
        ) : (
          <Grid
            className={clsx(common.styledScrollBar, classes.root)}
            container>
            {history?.nodes?.map((transaction, i) => (
              <div key={i} className={classes.row}>
                <Typography className={classes.rowText}>
                  {transaction.token.name}
                </Typography>
                <Typography className={classes.rowText}>
                  {formatDate(new Date(transaction.createdAt)).ddmmyy}{' '}
                  <span style={{ color: theme.palette.grey[500] }}>
                    {formatDate(new Date(transaction.createdAt)).time}
                  </span>
                </Typography>
                <Typography className={classes.rowText}>
                  {Number(transaction.amount).toFixed(2)}
                </Typography>
                <Typography className={classes.rowText}>
                  {transaction.validation}
                </Typography>
              </div>
            ))}

            <Grid container xs={12} ref={bottomRef}>
              {loading && history?.nodes?.length && (
                <div className={classes.sensor}>
                  <CircularProgress size='1.5rem' color='primary' />
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
};
