import * as React from 'react';

function SvgIconDna(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' {...props}>
      <g fill={`#${props.fill ?? 'FFFFFF'}`}>
        <path d='M10 11.85c-4.6 0-8.07-4.59-8.07-10.67 0-.5.4-.9.9-.9s.9.4.9.9c0 5.06 2.7 8.87 6.27 8.87s6.27-3.81 6.27-8.87c0-.5.4-.9.9-.9s.9.4.9.9c0 6.09-3.47 10.67-8.07 10.67z' />
        <path d='M16.44 4.46H3.53c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h12.91c.5 0 .9.4.9.9s-.4.9-.9.9zM15.55 8.04H4.33c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h11.21c.5 0 .9.4.9.9s-.4.9-.89.9z' />
        <g>
          <path d='M17.17 21.72c-.5 0-.9-.4-.9-.9 0-5.06-2.7-8.87-6.27-8.87s-6.27 3.81-6.27 8.87c0 .5-.4.9-.9.9s-.9-.4-.9-.9c0-6.08 3.47-10.67 8.07-10.67s8.07 4.59 8.07 10.67c0 .49-.4.9-.9.9z' />
          <path d='M16.47 19.34H3.56c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h12.91c.5 0 .9.4.9.9s-.41.9-.9.9zM15.67 15.76H4.45c-.5 0-.9-.4-.9-.9s.4-.9.9-.9h11.21c.5 0 .9.4.9.9s-.4.9-.89.9z' />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconDna;
