import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  Button,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { getLootItem, getLootSlot } from '../../../components/Loot';
import useCommonStyles from '../../../components/useCommonStyles';

import {
  FurOAuthTokenInput,
  useModifyFurballMutation,
} from '../../../components/schema';

import { signOAuthToken } from '../../../components/Auth/OAuthSig';
import { useWalletSelector } from '../../../hooks';
import WalletContext from '../../../wallet/WalletContext';
import { IFurballModifier } from './FurballEditorWidget';
import { itemLibrary } from '../../../components/Inventory/ItemLibrary';
import { useFurComponent } from '../../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export const InventoryEditor: FunctionComponent<IFurballModifier> = (props) => {
  const { mods, setMods, furball } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const { log } = useFurComponent(InventoryEditor);
  const [addLootId, setAddLootId] = React.useState(0);
  const [dropId, setDropId] = React.useState(0);
  const availableLootItems = itemLibrary;

  const droppable = [
    ...furball.inventory.items
      // .filter((i) => i.offChain)
      .map((i) => i.itemId),
  ].map(getLootItem);

  const [modify, { data, loading, error }] = useModifyFurballMutation();

  const walletAddress = useWalletSelector((s) => s.address);
  const context = React.useContext(WalletContext);
  const hasChanges = mods.drops.length > 0 || mods.pickups.length > 0;

  function addLootItem(lootId: number) {
    const pickups = [...mods.pickups];
    pickups.push(lootId);
    setMods({ ...mods, pickups });
  }

  function dropLootItem(lootId: number) {
    const drops = [...mods.drops];
    drops.push(lootId);
    setMods({ ...mods, drops });
  }

  async function saveChanges() {
    if (!walletAddress || !context || !hasChanges) return;
    const access = mods.drops.length + mods.pickups.length;
    const token: FurOAuthTokenInput = await signOAuthToken(
      walletAddress,
      context,
      access,
    );
    const variables = { token, mods };
    try {
      const res = await modify({ variables });
    } catch (e) {
      log.error(e, 'Edit');
    }
  }

  return (
    <Grid container className={clsx(classes.root)}>
      <Grid item xs={12}>
        {mods.pickups.map((lootId, i) => {
          const slot = getLootSlot(getLootItem(lootId));
          return (
            <Button
              key={i}
              variant={'outlined'}
              color={'secondary'}
              className={common.secondaryButton}
              onClick={() => {
                const pickups = [...mods.pickups];
                pickups.splice(i, 1);
                setMods({ ...mods, pickups });
              }}>
              {slot.item.name}
            </Button>
          );
        })}
      </Grid>

      <Grid item xs={8}>
        <FormControl fullWidth>
          <Select
            value={addLootId}
            onChange={(e, v) => setAddLootId(e.target.value as number)}>
            {availableLootItems.map((item) => {
              const slot = getLootSlot(getLootItem(item.id));
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <Button
          variant={'contained'}
          color={'secondary'}
          className={common.secondaryButton}
          onClick={() => addLootItem(addLootId)}>
          Add Loot Item
        </Button>
      </Grid>

      <Grid item xs={12}>
        {mods.drops.map((lootId, i) => {
          const slot = getLootSlot(getLootItem(lootId));
          return (
            <Button
              key={i}
              variant={'outlined'}
              color={'secondary'}
              onClick={() => {
                const drops = [...mods.drops];
                drops.splice(i, 1);
                setMods({ ...mods, drops });
              }}>
              {slot.item.name}
            </Button>
          );
        })}
      </Grid>

      <Grid item xs={8}>
        <FormControl fullWidth>
          <Select
            value={dropId}
            onChange={(e, v) => setDropId(e.target.value as number)}>
            {droppable.map((item) => {
              const slot = getLootSlot(item);
              return (
                <MenuItem key={item.lootId} value={item.lootId}>
                  [{slot.title}] {item.name} (+{slot.boost}% {slot.item.stat})
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <Button
          variant={'contained'}
          color={'secondary'}
          className={common.secondaryButton}
          onClick={() => dropLootItem(dropId)}>
          Drop Loot Item
        </Button>
      </Grid>
    </Grid>
  );
};

export default InventoryEditor;
