import * as React from 'react';
import useCommonStyles from '../useCommonStyles';
import { makeStyles } from '@mui/styles';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Theme,
} from '@mui/material';
import clsx from 'clsx';

export interface ICurrencyCardChoice {
  title: string;
  render: () => React.ReactNode;
}

interface ITixCard {
  title: string;
  icon: React.ReactNode;
  choices: ICurrencyCardChoice[];
}

const useStyles = makeStyles((theme: Theme) => ({
  swapCard: {
    maxWidth: '466px',
    width: '100%',
    margin: '8rem auto',
    boxShadow:
      'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important',

    [theme.breakpoints.down('md')]: {
      margin: '1rem auto',
    },
  },
  swapCardContent: {
    // minHeight: '304px',
    backgroundColor: 'rgba(233, 233, 255, 1) !important',
    // paddingTop: '8px !important',
  },
  tabButton: {
    '&:disabled': {
      color: '#ccc !important',
    },
  },
}));

const CurrencyCard: React.FunctionComponent<ITixCard> = (props) => {
  const { title, icon, choices } = props;
  const web3 = !!window.ethereum;
  const choiceIds = choices.map((c) => c.title);
  const [choiceId, setChoiceId] = React.useState(choiceIds[0] ?? '');
  const choice = choices.find((c) => c.title === choiceId);

  const classes = useStyles();
  const common = useCommonStyles();

  return (
    <Card className={clsx(common.cardRoot, classes.swapCard)}>
      <CardHeader
        className={common.cardHeader}
        title={web3 ? 'Currency Exchange' : 'Get FUR'}
        avatar={<div style={{ paddingTop: 6 }}>{icon}</div>}
        action={
          <div style={{ paddingTop: 6 }}>
            {choices.length > 1 && (
              <ButtonGroup>
                {choices.map((c) => {
                  const isChosen = c.title === choiceId;
                  return (
                    <Button
                      key={c.title}
                      variant={'contained'}
                      color={isChosen ? 'secondary' : 'primary'}
                      className={clsx(
                        isChosen ? common.secondaryButton : common.actionButton,
                        classes.tabButton,
                      )}
                      onClick={() => setChoiceId(c.title)}
                      disabled={isChosen}>
                      {c.title}
                    </Button>
                  );
                })}
              </ButtonGroup>
            )}
          </div>
        }
      />
      <CardContent
        className={clsx(common.cardContent, classes.swapCardContent)}>
        {choice?.render()}
      </CardContent>
      {/*{choiceIds.length > 1 && <CardActions>*/}
      {/*  <ButtonGroup>*/}
      {/*    {choiceIds.map(c => {*/}
      {/*      return (<Button*/}
      {/*        key={c}*/}
      {/*        variant={'contained'}*/}
      {/*        color={c === choice ? 'secondary' : 'primary'}*/}
      {/*        className={clsx(c === choice ? common.secondaryButton : common.actionButton, classes.tabButton)}*/}
      {/*        onClick={() => setChoice(c)}*/}
      {/*        disabled={c === choice}*/}
      {/*      >*/}
      {/*        {choices[c]}*/}
      {/*      </Button>);*/}
      {/*    })}*/}
      {/*  </ButtonGroup>*/}
      {/*</CardActions>}*/}
    </Card>
  );
};

export default CurrencyCard;
