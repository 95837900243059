import * as React from 'react';

function SvgIconMinus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 456 115'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      {...props}>
      <path d='M50 0h356c28 0 50 26 50 57 0 32-22 58-50 58H50C23 115 0 89 0 57 0 26 23 0 50 0z' />
    </svg>
  );
}

export default SvgIconMinus;
