import { useWalletSelector } from '../hooks';
import { getApiRoot } from '../FurballClient';

export function getReferrer(): string | null {
  return new URLSearchParams(window.location.search).get('ref');
}
export function useOAuthUrl(
  url: string,
  clientId: string,
  serviceName: string,
  scope: string,
  responseType = 'code',
  extra?: string,
): string {
  const walletAddress = useWalletSelector((s) => s.address ?? '');
  const referrer = getReferrer() ?? '';
  const redirect = encodeURIComponent(getOAuthCallbackUrl(serviceName));
  const ex = extra ? `&${extra}` : '';
  return (
    `${url}?client_id=${clientId}&scope=${encodeURIComponent(scope)}` +
    `&state=${walletAddress}_${referrer}&redirect_uri=${redirect}&response_type=${responseType}${ex}`
  );
}

export function getOAuthCallbackUrl(serviceName: string): string {
  // The OAuth redirect URL must use a subdomain (www) not the root domain
  const host =
    window.location.host.toLowerCase() === 'furballs.com'
      ? 'www.furballs.com'
      : getApiRoot();
  return `https://${host}/oauth/callback/${serviceName}`;
}
