import * as React from 'react';
import { useTimekeeperSelector } from '../../components/Timekeeper/TimekeeperSlice';
import { useAuthContext } from '../../components/Auth/AuthContext';
import FurverseWebGlGame from './FurverseWebGlGame';
import { browserName, isMobile } from 'react-device-detect';
import OkDialog from '../../components/OkDialog';
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Typography,
} from '@mui/material';
import useCommonStyles from '../../components/useCommonStyles';
import { ILogEntry, useFurComponent } from '../../utils';
import { useWalletSelector } from '../../hooks';
import {
  IUnityClientArgs,
  IUnityOptionalArgs,
} from '../../components/useUnity';
import { useServerConnection } from '../../FurballClient';
import { IFurball } from '../../wallet/WalletTypes';
import { useClientHardware } from '../App/clientHardware';
import { inIframe } from '../../wallet/Wallet';
import { inAppPurchases } from '../Tickets/TicketPurchaseApp';
import { IEventWithData, isVuplexExpected } from '../App/vuplexBridge';
import { useHistory } from 'react-router-dom';
import { IFightArgs } from './Lobby';
import FurversePopups, { FurversePopup } from './FurversePopups';
import clsx from 'clsx';
import { useWindowSize } from '../../components/useWindowSize';
import useHeroStyles from '../../components/useHeroStyles';
import { NotificationFragment } from '../../components/schema';

const supportedBrowsers = ['Chrome', 'Firefox', 'Brave', 'Edge'];

interface IFurverse {
  children: React.ReactNode;
}

export interface IGameBattle {
  id: string;
  score: number;
}

let vuplexReadyInternal = false;

const useFurverse = () => {
  const { log, logManager } = useFurComponent('Furverse');
  const gameBuild = useTimekeeperSelector((tk) => tk.meta?.gameBuild);
  const history = useHistory();
  const [browserError, setBrowserError] = React.useState<string>();
  const [loadProgress, setLoadProgress] = React.useState(0);
  const { authState, isSignedIn } = useAuthContext();
  const playerData = useWalletSelector((s) => s.contents);
  const [extraArgs, setOptionalArgs] = React.useState<IUnityOptionalArgs>();
  const secret = authState?.sessionSecret ?? '';
  const api = useServerConnection().rootUrl;
  const furballs: IFurball[] = []; //usePlayersReadyFurballs();
  const clientHardware = useClientHardware();
  const iframe = inIframe(); // iframes cannot access local storage
  const [currentBattle, setCurrentBattle] = React.useState<
    IGameBattle | undefined
  >(undefined);
  // const [unityContext, setContext] = React.useState<
  //   IUnityContextHook | undefined
  // >(undefined);
  const [currentBattleId, setGameBattleId] = React.useState<string | undefined>(
    undefined,
  );
  const [inbox, setInbox] = React.useState<IGameMessage[]>([]);
  const [vuplexReady, setVuplexReady] = React.useState(false);
  const [vuplexFailed, setVuplexFailed] = React.useState(false);
  const [vuplexExpected, setVuplexExpected] = React.useState(
    vuplexReady || isVuplexExpected(),
  );
  const [outbox, setOutbox] = React.useState<IGameMessage[]>([]);
  const [activePages, setActivePages] = React.useState<string[]>([]);
  // const webGlReady = !!unityContext && unityContext.isLoaded;
  // const gameClientReady = vuplexReady || webGlReady;
  const [gameReady, setGameReady] = React.useState(false);
  const [currentPopup, setCurrentPopup] = React.useState<
    FurversePopup | undefined
  >(undefined);
  const [currentPopupId, setCurrentPopupId] = React.useState<
    string | undefined
  >(undefined);

  // log.info('webgl ready', !!unityContext, unityContext?.loadingProgression, unityContext?.unityProvider);

  function handleMessage(m: IEventWithData): void {
    handleGameMessage(m.data);
  }

  function onVuplexReady() {
    if (vuplexReady) return;
    // @ts-ignore
    const vp = window.vuplex;
    if (!vp) return;

    const ready = !!vp && typeof vp.addEventListener === 'function';
    setVuplexReady(ready);
    setVuplexExpected(true);
    vuplexReadyInternal = ready;
  }

  function showPopup(popup?: FurversePopup, id?: string) {
    log.info('[POP]', popup ? FurversePopup[popup] : undefined, '#', id);
    setCurrentPopup(popup);
    setCurrentPopupId(id);
    if (popup) history.push('/furverse');
    sendGameMessage({
      topic: 'PopupChanged',
      data: { type: popup?.toString(), id },
    });
  }

  function handleGameMessage(json: string): void {
    try {
      const msg: IGameMessage = JSON.parse(json) as IGameMessage;
      if (!msg || !msg.topic) console.warn('invalid vuplex message', json);
      else if (msg.topic === 'Log') {
        logManager.pipeToServer('Unity.Vuplex', msg.data?.log);
      } else if (msg.topic === 'Navigate' || msg.topic === 'NavigateWeb') {
        const path = msg.data?.id;
        if (path) {
          log.info('[NAV] ', path);
          if (path.toLowerCase().startsWith('http')) {
            window.open(path, '_blank');
          } else {
            history.push(path);
          }
        } else {
          log.warn('[NAV] no path provided');
        }
      } else if (msg.topic === 'PopupWeb') {
        const pop: string = msg.data?.type ?? '';
        log.info('pop', pop);
        showPopup(
          FurversePopup[pop as keyof typeof FurversePopup],
          msg.data?.id,
        );
      } else if (msg.topic === 'ClientArgs') {
        // Game entered WebUI scene (loaded)
        log.info('[CLIENT] started');
        setGameReady(true);
        sendQueue();
      } else {
        setInbox([...inbox, msg]);
      }
    } catch (e: any) {
      log.error('[VUPLEX] failed to parse json', e?.message, 'from', json);
    }
  }

  function sendGameMessage(obj: IGameMessage) {
    if (vuplexReady) {
      postVuplexMessage(obj);
    } else {
      // if (!gameReady) log.info('[MSG]', obj.topic, 'queued');
      setOutbox([...outbox, obj]);
    }
  }

  // function postWebMessage(obj: IGameMessage) {
  //   unityContext?.sendMessage(
  //     'ClientBridge',
  //     'HandleMessage',
  //     JSON.stringify(obj),
  //   );
  // }

  function postVuplexMessage(obj: IGameMessage) {
    // @ts-ignore
    window.vuplex.postMessage(JSON.stringify(obj));
  }

  function sendQueue() {
    if (outbox.length <= 0 || !vuplexReady) return;
    log.info('[MSG] vuplex queue process', outbox.length);
    outbox.forEach(postVuplexMessage);
    setOutbox([]);
  }

  React.useEffect(() => {
    // @ts-ignore
    if (!vuplexReady && !window.vuplex) {
      const to = setTimeout(() => {
        setVuplexFailed(true);
        onVuplexReady();
      }, 10000);
      window.addEventListener('vuplexready', onVuplexReady);

      return () => {
        window.removeEventListener('vuplexready', onVuplexReady);
        clearTimeout(to);
      };
    } else {
      log.info('[VUPLEX] ready');
      if (!vuplexReady) onVuplexReady();
      // setVuplexFailed(false);

      // @ts-ignore
      window.vuplex.addEventListener('message', handleMessage);

      return () => {
        // @ts-ignore
        window.vuplex.removeEventListener('message', handleMessage);
      };
    }
  }, [vuplexReady]);

  function beginBattleId(id: string): void {
    log.info('begin battle', id, 'from', currentBattleId);
    if (id === currentBattleId) return;
    setGameBattleId(id);
  }

  function endCurrentBattle(): void {
    setGameBattleId(undefined);
    setCurrentBattle(undefined);
  }
  //
  // function setUnityContext(c?: IUnityContextHook) {
  //   log.info('UNITY context change', unityContext, '=>', c);
  //   setContext(c);
  // }

  const clientArgs: IUnityClientArgs = React.useMemo(() => {
    const ret = {
      api,
      player: playerData
        ? {
            id: playerData.id,
            type: authState.playerType ?? '',
            username: playerData.username ?? '',
            avatar: playerData.avatar ?? '',
            tix: playerData.tix,
            wFur: playerData.wFur,
            fur: playerData.furBalance,
            furballs,
          }
        : undefined,
      secret,
      networkTimeout: 90, // in seconds, override client default
      // @ts-ignore
      buildNumber: Number.parseInt(window.furballsBuildNumber ?? '0'),
      clientHardware,
      products: inAppPurchases,
      ...(extraArgs || {}),
    };

    // log.info('[ARGS] update', ret);
    const jsonArgs = JSON.stringify(ret);

    // @ts-ignore
    window.furballsClientArgs = jsonArgs;

    if (!iframe) {
      try {
        localStorage.setItem('furballsClientArgs', jsonArgs);
      } catch (e) {
        log.warn('failed to update client args', jsonArgs);
      }
    }

    return ret;
  }, [isSignedIn, secret, extraArgs]);

  React.useEffect(() => {
    const to = setTimeout(() => {
      sendGameMessage({ topic: 'LoadClientArgs', data: { clientArgs } });
    }, 100);
    return () => clearTimeout(to);
  }, [clientArgs]);

  React.useEffect(() => {
    if (isMobile && !isVuplexExpected()) {
      setBrowserError(
        "It looks like you're on a mobile device. This may work okay on Android, but will not work on iOS or other mobile devices. The Furballs team is working hard to release a mobile app ASAP!",
      );
    } else if (
      !isVuplexExpected() &&
      !supportedBrowsers.includes(browserName)
    ) {
      setBrowserError(
        `The "${browserName}" internet browser has not been fully tested with Furballs, and Boss Fights may not work well. We highly recommend using Chrome or Firefox for the best gameplay experience.`,
      );
    }
  }, [isMobile, browserName]);

  function setActivePage(pg: string, active: boolean) {
    let pgs = [...activePages];
    if (active && !pgs.includes(pg)) {
      pgs = pgs.concat(pg);
    } else if (!active && pgs.includes(pg)) {
      pgs.splice(pgs.indexOf(pg), 1);
    }
    // log.info('[PGS] ', pgs);
    setActivePages(pgs);
  }

  const gameActive = activePages.length > 0; // React.useMemo(() => activePages.le

  return {
    gameBuild,
    // unityContext,
    // setUnityContext,
    browserError,
    setBrowserError,
    loadProgress,
    setLoadProgress,
    clientArgs,
    setOptionalArgs,
    outbox,
    setOutbox,
    currentBattleId,
    currentBattle,
    setCurrentBattle,
    beginBattleId,
    endCurrentBattle,
    gameActive,
    setActivePage,
    sendGameMessage,
    handleGameMessage,
    vuplexFailed,
    vuplexReady,
    vuplexExpected,
    gameReady,
    showPopup,
    currentPopup,
    currentPopupId,
  };
};

const FurverseContext = React.createContext(
  {} as ReturnType<typeof useFurverse>,
);
export const useFurverseContext = (activate?: boolean, name?: string) => {
  const ret = React.useContext(FurverseContext);
  const page = React.useMemo(() => name ?? window.location.pathname, [name]);

  React.useEffect(() => {
    if (activate === undefined) return;
    ret.setActivePage(page, activate);
    return () => {
      ret.setActivePage(page, false);
    };
  }, [activate, page]);

  return ret;
};

export interface IGameMessageData {
  id?: string;
  type?: string;
  secret?: string;
  clientArgs?: IUnityClientArgs;
  gameBattle?: IGameBattle;
  fightArgs?: IFightArgs;
  log?: ILogEntry;
  notifications?: NotificationFragment[];
}

export interface IGameMessage {
  topic: string;
  data?: IGameMessageData;
}

const Furverse: React.FunctionComponent<IFurverse> = (props) => {
  const { log, logManager } = useFurComponent(Furverse);
  const { children } = props;
  const { isSignedIn } = useAuthContext();
  const common = useCommonStyles();
  const furverse = useFurverse();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const size = useWindowSize();
  const hero = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });

  const { gameBuild, browserError, setBrowserError, loadProgress, gameActive } =
    furverse;
  const canShowGame =
    isSignedIn && gameBuild && !browserError && !isVuplexExpected();

  const contents = React.useMemo((): React.ReactNode => {
    if (browserError)
      return (
        <OkDialog
          title={'Internet Browser'}
          open={!!browserError}
          onClose={() => setBrowserError(undefined)}
          maxWidth={'md'}>
          <Container maxWidth={'md'}>
            {browserError}
            <br />
            <br />
            <Typography variant={'subtitle2'}>
              If you know this browser works fine, please let us know on
              Discord!
              <br />
              You may close this window and play anyway.
            </Typography>
          </Container>
        </OkDialog>
      );

    if (!canShowGame) return null;

    return (
      <>
        {gameActive && loadProgress < 100 && (
          <div
            className={clsx(
              common.overUnity,
              hero.background,
              common.emptyPage,
            )}>
            <Container style={{ maxWidth: 400, textAlign: 'center' }}>
              <Card>
                <CardHeader
                  className={common.cardHeader}
                  sx={{ minHeight: '62px' }}
                  title={`Downloading Game (${loadProgress}%)...`}
                />
                <CardContent>
                  <Typography variant={'subtitle2'}>
                    Stuck loading? Problems with the game?
                    {/*<br />*/}
                    {/*Check out the{' '}*/}
                    {/*<GuideLink to={'bosses/support-debugging'}>*/}
                    {/*  Support/Debugging Guide*/}
                    {/*</GuideLink>*/}
                    {/*.*/}
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </div>
        )}
      </>
    );
  }, [canShowGame, browserError, loadProgress, gameActive]);

  return (
    <FurverseContext.Provider value={furverse}>
      {canShowGame && (
        <FurverseWebGlGame containerRef={containerRef} gameBuild={gameBuild} />
      )}
      {contents}
      {children}
      <FurversePopups />
    </FurverseContext.Provider>
  );
};

export default Furverse;
