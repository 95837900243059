import React, { FunctionComponent } from 'react';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useScholarshipHelpers } from './ScholarshipHelpers';
import { cdnRoot } from '../../theme';

import clsx from 'clsx';
import theme from '../../themev2';
import FurballRenderer from '../Furballs/FurballRenderer';
import SvgIconWrappedFur from '../../assets/SvgIconWrappedFur';
import { ItemDefinitionFragment, PlayerPendingRewardFragment } from '../schema';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';
import { useFurComponent } from '../../utils';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    inset: 0,
    height: '100%',
    padding: '1rem',
    backgroundColor: 'white',

    [theme.breakpoints.down('sm')]: {
      padding: '0',
      paddingTop: '1.5rem',
    },
  },

  furball: {
    position: 'relative',
  },

  tableContainer: {
    padding: '1rem',
    border: `2px solid ${theme.palette.secondary.light}`,
    borderRadius: '20px',
    backgroundColor: theme.palette.grey[50],
  },

  tableHeadRow: {
    // borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },

  tableRow: {
    display: 'grid',
    width: '100%',
    height: '3rem',
    gridTemplateColumns: '40% 20% 20% 20%',

    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },

    [theme.breakpoints.down('sm')]: {
      '& h4, p': {
        fontSize: '0.7rem !important',
      },
    },
  },

  lootImg: {
    width: '1.5rem',
    height: '1.5rem',
  },
}));

const createData = (
  name: string,
  rarity: string,
  type: string,
  icon: string,
  quantity: number,
) => {
  return {
    icon: `${cdnRoot}/images/icons/boss_loot/${icon}.png`,
    name,
    rarity,
    type: type.split('_').join(' '),
    quantity,
  };
};

interface IProps {
  tokenId: string;
  onClose: () => void;
  rewards: PlayerPendingRewardFragment[];
}

interface IRewardObject extends ItemDefinitionFragment {
  quantity: number;
}

export const ScholarshipRewards: FunctionComponent<IProps> = ({
  tokenId,
  onClose,
  rewards,
}) => {
  const classes = useStyles();
  const { log } = useFurComponent(ScholarshipRewards);
  const { agreement } = useScholarshipHelpers(log, tokenId, true);

  const rewardsItemQuantities: { [x: number]: IRewardObject } = {};

  rewards.forEach((reward) => {
    reward.items.forEach((item) => {
      const exists = rewardsItemQuantities[item.itemId];

      if (exists) {
        rewardsItemQuantities[item.itemId] = {
          ...exists,
          quantity: exists.quantity + item.stack,
        };
      } else {
        const def = getItemDef(item.itemId);

        if (def) {
          rewardsItemQuantities[item.itemId] = {
            ...def,
            quantity: item.stack,
          };
        }
      }
    });
  });

  const rewardRows = Object.values(rewardsItemQuantities).map((reward) => {
    if (!reward.id) return null;

    return createData(
      reward.name,
      reward.rarity,
      reward.itemGroup,
      reward.icon,
      reward.quantity,
    );
  });

  return (
    <Grid container direction='column' gap='1rem' className={classes.root}>
      <Grid container alignItems='center' gap='1rem'>
        <FurballRenderer
          tokenId={tokenId}
          size='6rem'
          className={classes.furball}
        />
        <Typography variant='h4' fontSize='1.4rem' fontWeight='600'>
          Rental Rewards
        </Typography>
      </Grid>

      <Grid
        container
        marginTop='2rem'
        justifyContent='space-between'
        gap='0.4rem'
        className={classes.tableContainer}>
        <div>
          <Grid container alignItems='center' gap='0.2rem'>
            <SvgIconWrappedFur
              height={25}
              width={25}
              fill={theme.palette.secondary.dark}
            />
            <Typography variant='h4' fontSize='1rem' fontWeight='600'>
              $wFur Earned
            </Typography>
          </Grid>
        </div>

        <Typography variant='h4' fontSize='1rem' fontWeight='600'>
          {agreement?.wFurEarned}
        </Typography>
      </Grid>

      {rewardRows.length !== 0 && (
        <Grid
          container
          direction='column'
          marginTop='0.5rem'
          gap='1rem'
          className={classes.tableContainer}>
          <Grid container>
            <div className={clsx(classes.tableRow, classes.tableHeadRow)}>
              <Grid container alignItems='center'>
                <Typography fontSize='0.9rem' variant='h4' fontWeight={600}>
                  LOOT ITEMS
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography
                  fontSize='0.9rem'
                  fontWeight={600}
                  color={theme.palette.grey[600]}>
                  RARITY
                </Typography>
              </Grid>

              <Grid container alignItems='center'>
                <Typography
                  fontSize='0.9rem'
                  fontWeight={600}
                  color={theme.palette.grey[600]}>
                  TYPE
                </Typography>
              </Grid>

              <Grid container alignItems='center' justifyContent='flex-end'>
                <Typography
                  fontSize='0.9rem'
                  fontWeight={600}
                  color={theme.palette.grey[600]}>
                  QUANTITY
                </Typography>
              </Grid>
            </div>
            {rewardRows.map((item, index) => (
              <div key={index} className={classes.tableRow}>
                <Grid container alignItems='center' gap='0.4rem'>
                  <img src={item?.icon} className={classes.lootImg} />
                  <Typography fontSize='0.9rem' fontWeight={500}>
                    {item?.name}
                  </Typography>
                </Grid>

                <Grid container alignItems='center'>
                  <Typography fontSize='0.9rem'>{item?.rarity}</Typography>
                </Grid>
                <Grid container alignItems='center'>
                  <Typography fontSize='0.9rem'>{item?.type}</Typography>
                </Grid>
                <Grid container alignItems='center' justifyContent='flex-end'>
                  <Typography fontSize='0.9rem'>x{item?.quantity}</Typography>
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid item justifySelf='flex-end' alignSelf='center' marginTop='1rem'>
        <Button variant='outlined' color='error' onClick={onClose}>
          <Typography fontSize='0.8rem' fontWeight='600'>
            Hide Rewards
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};
