import React, { FunctionComponent } from 'react';
import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ISpendButtonBase, SpendButton } from './SpendButton';
import SvgIconTix from '../../assets/SvgIconTix';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../useCommonStyles';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletSlice from '../../wallet/WalletSlice';
import { useFuelFactor } from '../Timekeeper/TimekeeperHooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export const SpendTixButton: FunctionComponent<ISpendButtonBase> = (props) => {
  const history = useHistory();
  const common = useCommonStyles();
  const dispatch = useAppDispatch();
  const fuelFactor = useFuelFactor();
  const fuelBalance = useWalletSelector(
    (s) => (s.contents?.tix ?? 0) * fuelFactor,
  );
  const tixBalance = Math.floor(fuelBalance / fuelFactor);
  const cost = Math.ceil(props.cost / fuelFactor);

  function onComplete(amount: number) {
    dispatch(WalletSlice.actions.setTixBalance(tixBalance - amount));
    if (props.onComplete) props.onComplete(amount);
  }

  function renderActions(hasEnough: boolean): React.ReactNode {
    return (
      <>
        {/*<ShareReferButton />*/}
        {!hasEnough && (
          <Button
            variant={'contained'}
            color={'primary'}
            className={common.actionButton}
            onClick={() => history.push('/buy#tix')}>
            <Typography variant={'h5'}>
              <SvgIconTix
                fill={'#000000'}
                style={{
                  width: 24,
                  marginRight: 10,
                  position: 'relative',
                  top: 4,
                }}
              />
              Get More TIX
            </Typography>
          </Button>
        )}
      </>
    );
  }

  return (
    <SpendButton
      {...props}
      cost={cost}
      balance={tixBalance}
      currency={'TIX'}
      renderActions={renderActions}
      onComplete={onComplete}
      renderIcon={(f, s) => <SvgIconTix fill={f} style={s} />}
    />
  );
};

export default SpendTixButton;
