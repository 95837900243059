import React, { FunctionComponent, useEffect, useState } from 'react';
import { Theme, Typography, Grid, Tabs, Tab, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballBase } from '../../wallet/WalletTypes';
import { darkBlue, expColor, furColor, purple } from '../../theme';
import { useEstimatedFurballRewards } from '../../components/Timekeeper/RewardEstimator';
import {
  getZone,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_SCHOLARSHIPS,
  ZONE_NUM_TOWN,
  ZONE_NUM_VOYAGE,
} from '../../components/Zones/ZoneHelpers';

import FurballButtons, {
  ExpandMode,
} from '../../components/Furballs/FurballButtons';
import { black, white } from '../../themev2';
import GenePool from '../../components/Furballs/GenePool';
import Inventory from '../../components/Furballs/Inventory';
import Rankings from '../../components/Furballs/Rankings';
import Skills from '../../components/Furballs/Skills';
import SvgIconSkills from '../../assets/SvgIconSkills';
import SvgIconRanks from '../../assets/SvgIconRanks';
import SvgIconInventory from '../../assets/SvgIconInventory';
import SvgIconDna from '../../assets/SvgIconDna';
import { approxTimeSince, useInterval } from '../../wallet';
import SvgMobileMenuX from '../../assets/SvgMobileMenuX';
import { StatEntity } from '../../components/schema';
import { inIframe } from '../../wallet/Wallet';

const heightOverlayHeader = 56;
const heightOverlayFooter = 52;
const heightOverlayOffset = heightOverlayHeader + heightOverlayFooter;

const indicatorColor = '#5B50D9';

enum FurballOverlayModesHUD {
  Loot = 'loot',
  Genes = 'genes',
  Ranks = 'ranks',
  Skills = 'skills',
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    color: white,
  },
  topAction: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  bottomBar: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    minHeight: 80,
    backgroundColor: '#000000',
  },
  statHeader: {
    width: '100%',
    backgroundColor: darkBlue,
    paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
  },
  statFooter: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  subtext: {
    fontSize: '0.8rem',
    marginLeft: 2,
  },
  buttonGroup: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    maxHeight: 40,
  },
  linkGroup: {
    position: 'absolute',
    borderRadius: '8px',
    top: '-8px',
    left: '-8px',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#000000CC',
  },
  lightLink: {
    color: `${purple.light} !important`,
    textDecoration: 'underline',
  },
  rankBar: {
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  infoOverlay: {
    backgroundColor: white,
    zIndex: 1000,
    minHeight: '100%',
    minWidth: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
  },
  overlayHeader: {},
  overlayContent: {
    maxHeight: `calc(100% - ${heightOverlayOffset}px) !important`,
    minHeight: `calc(100% - ${heightOverlayOffset}px) !important`,
    overflowX: 'auto',
    color: black,
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    zIndex: 1,
    width: '100%',
    backgroundColor: white,
    borderBottom: '1px solid #9E9E9E',
  },
  tab: {
    maxHeight: 40,
    fontSize: '1.25rem !important',
    border: 'none !important',
    '&.Mui-selected': {
      color: `${indicatorColor} !important`,
    },
    ['@media (max-width:400px)']: {
      maxWidth: '55px !important',
      minWidth: '55px !important',
    },
  },
  svgActionIcon: {
    margin: '0px',
    maxHeight: '24px',
    minWidth: '24px',
    ['@media (max-width:400px)']: {
      maxWidth: '22px !important',
      minWidth: '22px !important',
    },
  },
  popupFooter: {
    backgroundColor: black,
    color: white,
  },
  popupFooterHeader: {
    backgroundColor: darkBlue,
  },
  popupFooterFirst: {
    paddingLeft: theme.spacing(2),
  },
  expText: {
    color: expColor.light,
  },
  furText: {
    color: furColor.light,
  },
  closeOverlay: {
    backgroundColor: indicatorColor,
    display: 'flex',
    justifyContent: 'center',
  },
  indicator: {
    backgroundColor: `${indicatorColor} !important`,
  },
}));

interface IEmbeddedHud {
  furball: IFurballBase;
  isSmall: boolean;
}

export const EmbeddedHud: FunctionComponent<IEmbeddedHud> = (props) => {
  const { furball, isSmall } = props;
  const classes = useStyles();
  const rewards = useEstimatedFurballRewards([furball], true);
  const reward = rewards.furballsWithRewards[0].reward;
  const zone = getZone(furball.zone);
  const isVoyage = zone.zoneNumber === ZONE_NUM_VOYAGE;
  const isInIframe = inIframe();
  const unit =
    reward.furGain > 0 ? '$FUR' : reward.levelGain > 0 ? 'LVL' : 'EXP';
  const amount = Math.max(
    reward.furGain,
    reward.levelGain > 0 ? reward.levelGain : reward.experienceGain,
  );

  const [mode, setMode] = useState<ExpandMode | undefined>(undefined);
  const [showInfoOverlay, setShowInfoOverlay] = useState(false);

  const interval = useInterval();
  const timeSince = approxTimeSince(furball.last, interval);

  useEffect(() => {
    if (mode && !showInfoOverlay) {
      setShowInfoOverlay(true);
    }
  }, [mode]);

  function toggleMode(newMode: ExpandMode) {
    setMode(newMode === mode ? undefined : newMode);
  }

  const tabs = [
    {
      name: FurballOverlayModesHUD.Loot,
      icon: (
        <SvgIconInventory
          className={classes.svgActionIcon}
          fill={indicatorColor}
        />
      ),
    },
    {
      name: FurballOverlayModesHUD.Genes,
      icon: (
        <SvgIconDna className={classes.svgActionIcon} fill={indicatorColor} />
      ),
    },
    {
      name: FurballOverlayModesHUD.Ranks,
      icon: (
        <SvgIconRanks className={classes.svgActionIcon} fill={indicatorColor} />
      ),
    },
    {
      name: FurballOverlayModesHUD.Skills,
      icon: (
        <SvgIconSkills
          className={classes.svgActionIcon}
          fill={indicatorColor}
        />
      ),
    },
  ];

  function renderInfoOverlay() {
    return (
      <div className={classes.infoOverlay}>
        <Grid container xs={12} className={classes.overlayHeader}>
          <Grid item xs={10}>
            <Tabs
              centered
              variant={'fullWidth'}
              textColor='secondary'
              indicatorColor='secondary'
              className={classes.tabs}
              value={mode}
              onChange={(_, newMode) => setMode(newMode)}
              classes={{
                indicator: classes.indicator,
              }}
              aria-label='Furball stat overlay'>
              {tabs.map(({ name, icon }) => {
                return (
                  <Tab
                    key={name}
                    className={classes.tab}
                    color={'secondary'}
                    value={name}
                    icon={icon}
                  />
                );
              })}
            </Tabs>
          </Grid>
          <Grid item xs={2} className={classes.closeOverlay}>
            <IconButton
              sx={{
                height: '45px',
                width: '45px',
                '&:hover': {
                  backgroundColor: indicatorColor,
                },
              }}
              onClick={() => {
                setMode(undefined);
                setShowInfoOverlay(false);
              }}>
              <SvgMobileMenuX width={24} height={24} stroke={white} />
            </IconButton>
          </Grid>

          {/*<IconButton
            sx={{
              height: '45px',
              width: '45px',
              '&:hover': {
                backgroundColor: white,
              },
            }}
            onClick={() => {
              setMode(undefined);
              setShowInfoOverlay(false);
            }}>
            <SvgMobileMenuX width={32} height={32} />
          </IconButton>*/}
        </Grid>
        <div className={classes.overlayContent}>
          {mode === 'genes' && <GenePool furball={furball} />}
          {mode === 'loot' && (
            <Inventory furball={furball} disableHoverEffect={true} />
          )}
          {mode === 'ranks' && (
            <Rankings furball={furball} hideTeamSize={true} />
          )}
          {mode === 'skills' && (
            <Skills
              tokenId={furball.tokenId}
              battleStats={furball.battleStats as StatEntity}
              equipment={furball.equipment}
            />
          )}
        </div>
        <Grid container className={classes.popupFooter}>
          <Grid container className={classes.popupFooterHeader}>
            <Grid item xs={4} className={classes.popupFooterFirst}>
              <Typography variant={'h6'}>
                {furball.timekeeperDisabled ? 'INSTANT' : 'TIME'}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h6'}>EXP</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h6'}>FUR</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} className={classes.popupFooterFirst}>
              <Typography variant={'h5'}>
                {timeSince[0]}
                <span className={classes.subtext}>{timeSince[1]}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h5'}>
                <span className={classes.expText}>
                  {furball.state.expRate.toLocaleString()}
                </span>
                <span className={classes.subtext}>/hr</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant={'h5'}>
                <span className={classes.furText}>
                  {(
                    furball.state.furRateBase - furball.state.teamSize
                  ).toLocaleString()}
                </span>
                <span className={classes.subtext}>/hr</span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <div className={clsx(classes.root)}>
      {showInfoOverlay && renderInfoOverlay()}
      <div className={classes.bottomBar}>
        <Grid container className={classes.statHeader}>
          <Grid item xs={5}>
            <Typography variant={'h6'}>ZONE</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant={'h6'}>EST. REWARDS</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.statFooter}>
          <Grid item xs={5}>
            <Typography variant={'h5'}>{zone.title}</Typography>
          </Grid>
          <Grid item xs={7}>
            {![
              ZONE_NUM_SCHOLARSHIPS,
              ZONE_NUM_TOWN,
              ZONE_NUM_BOSS_FIGHTS,
            ].includes(furball.zone) && (
              <Typography variant={'h5'}>
                +{isVoyage ? 'BOSS' : amount.toLocaleString()}
                <span className={classes.subtext}>
                  {isVoyage ? 'KEY' : unit}
                </span>
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
      {!isSmall && (
        <div className={classes.buttonGroup}>
          <FurballButtons
            furball={furball}
            mode={mode}
            toggleMode={toggleMode}
            largeBonded={true}
          />
        </div>
      )}
      {/*{inIframe() && (*/}
      {/*  <div className={classes.linkGroup}>*/}
      {/*    <Typography variant={'h5'}>Weekly ETH Prizes!</Typography>*/}
      {/*    <Typography*/}
      {/*      variant={'body1'}*/}
      {/*      style={{ fontSize: '0.8rem !important' }}>*/}
      {/*      <a*/}
      {/*        className={classes.lightLink}*/}
      {/*        rel='noopener noreferrer'*/}
      {/*        href={'https://furballs.com/get/furballs/iframe'}>*/}
      {/*        Click here to play*/}
      {/*      </a>{' '}*/}
      {/*      and win ETH prizes!*/}
      {/*      <br />*/}
      {/*      No crypto wallet (or Furball) required!*/}
      {/*    </Typography>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};

export default EmbeddedHud;
