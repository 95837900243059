import React from 'react';

export interface IWindowSize {
  width: number;
  height: number;
  aspectRatio: number;
  isLandscape: boolean;
}

// Hook
export function useWindowSize(): IWindowSize {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<IWindowSize>({
    width: 0,
    height: 0,
    aspectRatio: 1,
    isLandscape: true,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      const aspectRatio = window.innerWidth / Math.max(window.innerHeight, 1);
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        aspectRatio,
        isLandscape: aspectRatio >= 1,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
