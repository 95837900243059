import { SvgEditionSlot } from '../part';
import * as React from 'react';

const PsychedelicPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#fffe65'
      d='M157.53 293.87c-92.61-55.37-68.27-72.49-65.13-74.65l36.14-19.36 99.83 16.53-37.24 77.48h-33.6z'
    />
    <path
      fill='#ffb82b'
      d='M93.68 217.05s-14.8 17.62 7.32 33.75 53.87 36.41 53.87 41.9H85.2s-26.11-58.52 8.48-75.65z'
    />
    <path
      fill='#7cf2ff'
      d='M86.03 221.87s-19.52 11.31 19.95 37.08c35.91 23.44 42.07 35.58 42.07 35.58H76.38s-31.25-48.22 9.65-72.66z'
    />
    <path
      fill='#2b66ff'
      d='M181.8 219.05s-38.74 4.99-21.95 37.58c0 0 20.95 19.95 17.46 37.08h16.13s11.45-.85 23.27-12.64c11.81-11.81-34.91-62.02-34.91-62.02z'
    />
    <path
      fill='#c6f'
      d='M112.59 269.16c-4.93-4.45-36.37-27.02-37.97-38.12-.3-.89-33.15 29.24-4.81 61.13l54.94 1.76c.46-1.2 6.81-10.19-12.16-24.77z'
    />
    <path
      fill='#ffb82b'
      d='m240.55 205.3-42.95 11.92c-34.39 15.82-6.2 40.92 10.64 54.2 6.53 5.15 8.92 9.26 8.92 9.26s27.01-18.03 23.39-75.38z'
    />
    <path
      fill='#fffe65'
      d='m240.5 205.25-36.41 10.48c.33 7.48-16.65 10.8-16.96 20.95-.33 10.97 15.99 17.47 24.11 24.77 6.18 5.55 6.47 11.37 5.48 19.61-.02.06 25.24-16.82 23.78-75.81z'
    />
    <path
      fill='#2b66ff'
      d='M107.29 270.95c-4.55-4.08-25.51-24.82-30.73-17.91-3.46 5.51.51 9.46 1.48 15.74 1.2 10.87-4.45 17.6-8.33 21.95 4.18 6.3 35.94 3.13 35.94 3.13l-5.99-11.63 7.63-11.28z'
    />
    <path
      fill='#fffe65'
      d='M76.38 294.53s.96-12.3 18.13-12.3c9.98 0 10.48 12.14 10.48 12.14s-29.45.16-28.61.16z'
    />
    <path
      fill='#7cf2ff'
      d='m239.33 205.97-25.6 9.09c-7.32 10.64-19.34 12.83-20.12 20.29-1.16 11.14 12.4 16.14 19.79 22.78 5.62 5.05 7.58 10.46 6.67 17.95-.01.05 21.59-14.8 19.26-70.11z'
    />
    <path
      fill='#2b66ff'
      d='M235.34 253.46c-3.29 1.92-7.49 3.06-11.15 3.06-5.56 0-10.93-2.38-14.73-6.52-3.71-4.04-5.58-9.34-5.14-14.54 1.51-17.72 29.36-27.07 36.51-29.05l-5.49 47.05z'
    />
    <path
      fill='#fffe65'
      d='M239.86 220.49s-21.73 6-22.59 16.07c-.4 4.69 6.12 10.24 12.64 4.54 4.91-4.3 8.17-1.59 8.17-1.59l1.78-19.02z'
    />
    <path
      fill='#2b66ff'
      d='M104.67 210.58s-19.52 15.65-8.82 28.58c11.14 13.47 42.11 33.02 47.71 28.76 5.6-4.25-8.72-15.57-8.94-26.33-.25-12.37 16.25-18.79 36.75-26.93 13.14-5.21-66.7-4.08-66.7-4.08z'
    />
    <path
      fill='#7cf2ff'
      d='M95.7 230.24c-.69 7.76 15.95 20.19 26.82 11.29s13.22-24.15 47.63-28.7l-40.62-15.4S94.18 211.9 95.7 230.24z'
    />
    <path
      fill='#c6f'
      d='M107.48 232.35c5.01 4.05 13.97-1.32 29.26-13.47 12.14-9.64 26.66-7.27 26.77-7.32-.09-.03-17.44-8.12-24.52-16.82-1.56-1.92-47.64 24.31-31.51 37.61z'
    />
    <path
      fill='#ffb82b'
      d='M134.99 195.38c-1.21 1.61-17.11 16.3-18.2 18.01-6.06 9.05 30.43-2.8 38.58-4.46-16.04-9.1-19.07-14.41-20.38-13.55z'
    />
    <path
      fill='#fffe65'
      d='M190.1 269.72c-.49-.18 4.19 21 3.37 23.5 1.17-.26 14.28-5.28 20.07-9.66-3.38-2.89-20.84-12.88-23.44-13.84z'
    />
    <path
      fill='#7cf2ff'
      d='M63.15 253.06s-9.78 16.52 2.26 29.52c0 0 12.24-13.95-2.26-29.52zM170.99 245.91c-2.82-4.61-9.69.66-6.43 6.65 1.6 2.95 24.25 29.83 23.17 39.26 2.31 1.41 4.64 2.02 6.91 1.46 4.5-3.89 3.79-4.9 2.99-8.4-.48-2.07-4.8-6.21-1.88-8.66 3.58-2.92 11.87 5.95 15.54 9.09.93-.71 2.54-1.17 3.02-2.33-8.57-11.14-31.57-15.46-43.32-37.07z'
    />
    <path
      fill='#c6f'
      d='M181.03 293.84c1.96-17.71-11.55-29.49-16.02-35.72-24.44-27.85 15.21-37.01 15.13-36.83-17.04 36.58 27.1 43.65 35.37 60.56.48-.46 3.38-6.77-8.76-16.58-4.56-3.69-47.55-25.11-7.39-48.32 3.35 0-16.75-1.9-16.87-1.93-1.32.56-46.15 10.57-27.95 37.78 4.84 8.16 22.95 20.78 19.95 40.94'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M266.38 224.9c-.22.04-.47.07-.73.08-2.59.13-7.13-1.23-8.4-6.64-.75-3.19.36-6.54 2.94-8.57 3.76-2.95 9.4-2.04 12.61 2.03 0 0 12.72 13.22-12.48 31.58'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.21 254.75s8.09 20.59-5.53 27.93'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M104.8 293.52c0-6.08-4.93-12.01-11.01-12.01M154.48 268.6l5.78 19.53a7.586 7.586 0 0 0 7.34 5.67h21.44v-1.28c0-6.08-4.93-11.01-11.01-11.01'
    />
    <linearGradient
      id='PsychedelicPower__a'
      gradientUnits='userSpaceOnUse'
      x1={223}
      y1={-635}
      x2={94}
      y2={-732}
      gradientTransform='matrix(1 0 0 -1 0 -388)'>
      <stop
        offset={0}
        style={{
          stopColor: '#f9c6f6',
        }}
      />
      <stop
        offset={0.181}
        style={{
          stopColor: '#a4d0ff',
        }}
      />
      <stop
        offset={0.301}
        style={{
          stopColor: '#9dfadf',
        }}
      />
      <stop
        offset={0.429}
        style={{
          stopColor: '#bafbb8',
        }}
      />
      <stop
        offset={0.581}
        style={{
          stopColor: '#ffef9c',
        }}
      />
      <stop
        offset={0.737}
        style={{
          stopColor: '#ffd3f4',
        }}
      />
      <stop
        offset={0.935}
        style={{
          stopColor: '#d8d9fd',
        }}
      />
    </linearGradient>
    <path
      fill='url(#PsychedelicPower__a)'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M185.48 284.37c7.41-10.87 7.12-22.02 7.12-22.02'
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M257.76 152.3a19.986 19.986 0 0 1-5.74-10.57c-4.71-23.97-27.86-42.17-55.7-42.17-27.85 0-50.99 18.2-55.7 42.17-.79 4.03-2.78 7.72-5.74 10.57-5.62 5.4-8.82 11.61-8.82 18.23 0 20.74 31.46 46.12 70.26 46.12s70.26-25.38 70.26-46.12c0-6.62-3.2-12.83-8.82-18.23z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m137.51 193.68-4.56 2.49-43.59 23.15c-10.2 5.42-18.71 13.62-24.24 23.75-6.63 12.14-10.2 28.89 2.61 46.83a9.47 9.47 0 0 0 7.71 3.96H189.4c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 2.16-8.53.45-22.04 1.27-28.53.55-4.34 1.52-7.47 2.65-9.73'
    />
  </svg>
);

export default PsychedelicPower;
