import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  IFurballPlayerMinimal,
  IFurballWithOwner,
  IMaybeToken,
} from '../../wallet/WalletTypes';
import { useHistory, useParams } from 'react-router-dom';
import { placeholderFurballId } from '../../wallet';

import { Container, LinearProgress } from '@mui/material';

import ChooseFurball from '../../components/Furballs/ChooseFurball';
import { normalizeTokenId } from '../../components/Furballs/Furball';

import FullDrawer from '../../components/Drawer/FullDrawer';
import FurballPublicHome from './FurballPublicHome';
import FurballPrivateDrawer from './FurballPrivateDrawer';
import { useFindFurballLazyQuery } from '../../components/schema';
import { useWalletSelector } from '../../hooks';
import { usePageTitle } from '../../Analytics';
import { getInventoryQuantities } from '../../components/Furballs';
import { useFurComponent } from '../../utils';

interface IFurballProps {}

export const Furball: FunctionComponent<IFurballProps> = (props) => {
  const params = useParams<IMaybeToken>();
  const { log } = useFurComponent(Furball);
  const tokenArg = params.tokenId
    ? params.tokenId.startsWith('0x')
      ? normalizeTokenId(params.tokenId)
      : params.tokenId
    : undefined;
  const [query, setQuery] = React.useState<string>('');

  const account = useWalletSelector((s) => s.contents);
  const ownedFurball = useWalletSelector((s) => {
    const furballIds = s.contents?.tokenIds ?? [];
    const ret = Object.values(s.furballs)
      .filter((fb) => furballIds.includes(fb.id))
      .find((fb) => fb.id === query || fb.name.endsWith(`#${query}`));
    return ret
      ? { ...ret, owner: account as IFurballPlayerMinimal }
      : undefined;
  });

  const history = useHistory();
  const [loadFurball, { data, loading }] = useFindFurballLazyQuery();
  const [furball, setFurball] = useState<IFurballWithOwner | undefined>(
    ownedFurball,
  );

  const isRentedByPlayer =
    !!furball?.activeRentalAgreement &&
    furball.activeRentalAgreement.playerId === account?.id;
  const isOwnedByPlayer = furball?.owner.id === account?.id;

  const dataFurball = data?.furball;

  useEffect(() => {
    if (tokenArg && tokenArg !== query && query !== placeholderFurballId) {
      log.info('[FB] load', tokenArg);
      setQuery(tokenArg);
      loadFurball({ variables: { query: tokenArg } });
    }
  }, [tokenArg]);

  useEffect(() => {
    if (dataFurball) {
      const furball = {
        ...dataFurball,
        inventory: getInventoryQuantities(dataFurball.inventory),
      };
      log.info('[FB] loaded', furball);
      setFurball(furball);
    }
  }, [dataFurball]);

  function onSelectedFurball(tokenId: string): void {
    history.push(`/furball/${tokenId}`);
    setQuery(tokenId);
  }

  usePageTitle('Furball');

  return (
    <FullDrawer
      drawer={
        isOwnedByPlayer && furball ? (
          <FurballPrivateDrawer furball={furball} isRented={isRentedByPlayer} />
        ) : null
      }>
      <br />
      {loading && <LinearProgress color={'primary'} />}
      {!furball && !loading && <ChooseFurball onChose={onSelectedFurball} />}
      <Container>
        {furball && (
          <FurballPublicHome
            furball={furball}
            owner={furball.owner}
            isRentedByPlayer={isRentedByPlayer}
          />
        )}
      </Container>
    </FullDrawer>
  );
};

export default Furball;
