import { Card, CardHeader, CardContent, Button } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import useCommonStyles from '../../components/useCommonStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    minWidth: '200px',
    position: 'relative',
  },
  content: {
    backgroundColor: '#FFFFFF',
    padding: '40px !important',
    paddingTop: '0px !important',
    textAlign: 'center',
  },
  footer: {
    backgroundColor: '#ffedad',
    width: '100%',
  },
  disabledAction: {
    color: '#C9FFD4 !important',
  },
}));

type Props = {
  title: string;
  children: React.ReactNode;
  callback?: () => void;
};

const ShopCard: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { title, callback } = props;

  return (
    <Card className={clsx(classes.root, common.cardRoot)}>
      <CardHeader
        className={common.cardHeader}
        title={title}
        action={
          <Button
            variant={'outlined'}
            disabled={!callback}
            className={clsx({
              [common.actionButton]: !!callback,
              [classes.disabledAction]: !callback,
            })}
            onClick={callback}
            color={'primary'}>
            {callback ? 'Claim' : 'Furball Required!'}
          </Button>
        }
      />
      <CardContent className={classes.content}>{props.children}</CardContent>
    </Card>
  );
};

export default ShopCard;
