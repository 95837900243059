import { Button, Container, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
// import {} from '../../theme';
import clsx from 'clsx';
import SvgLogo from '../../assets/SvgLogo';
import useCommonStyles from '../../components/useCommonStyles';
import { purple, white } from '../../themev2';
import SvgIconCaretRight from '../../assets/SvgIconCaretRight';
import ForceLoginDialog from '../../wallet/ForceLoginDialog';
import useHeroStyles from '../../components/useHeroStyles';
import { useWindowSize } from '../../components/useWindowSize';
import { useFurverseContext } from '../Furverse/Furverse';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: 'calc(100vh)',
    maxHeight: 'calc(100vh)',
    paddingTop: 50,
  },
  button: {
    backgroundColor: `${purple.dark} !important`,
    width: '100%',
    maxWidth: 300,
    // height: '40px !important',
    marginBottom: '16px !important',
    textAlign: 'left',

    border: '2px solid #000 !important',
    boxShadow: 'inset 0 -5px 0 2px #00000022',
    borderRadius: '10px !important',
    transition: 'all 0.2s linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    '&:hover': {
      backgroundColor: `${purple.light} !important`,
      color: theme.palette.primary.main,
    },
  },
  buttons: {
    paddingLeft: 40,
    paddingRight: 40,
    textAlign: 'center',
  },
  buttonText: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    marginLeft: '0.5rem !important',
    fontSize: '1.5rem !important',
  },
  icon: {
    marginLeft: '1.1rem',
    marginRight: '0.5rem',
  },
}));

interface IAppLogin {}

const AppLogin: React.FunctionComponent<IAppLogin> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const size = useWindowSize();
  const hero = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });
  const [signup, setSignup] = React.useState<boolean | undefined>();
  const { sendGameMessage } = useFurverseContext();

  // function openHelp() {
  //   const helpLink = 'https://guide.furballs.com/';
  //   sendGameMessage({ topic: 'OutboundLink', data: { id: helpLink } });
  //   window.open(helpLink, '_blank');
  // }

  return (
    <div className={clsx(hero.background, classes.root)}>
      <Container maxWidth={'sm'}>
        <SvgLogo />
        <div className={clsx(classes.buttons)}>
          <Button className={classes.button} onClick={() => setSignup(false)}>
            <Typography
              role='textbox'
              variant='h4'
              className={classes.buttonText}
              fontWeight={800}
              color={white}
              lineHeight={1}>
              Sign In
            </Typography>
            <SvgIconCaretRight
              width={'1.3rem'}
              height={'1.3rem'}
              fill={white}
              className={classes.icon}
            />
          </Button>
          <br />
          <Button className={classes.button} onClick={() => setSignup(true)}>
            <Typography
              role='textbox'
              variant='h4'
              className={classes.buttonText}
              fontWeight={800}
              color={white}
              lineHeight={1}>
              Create Account
            </Typography>
            <SvgIconCaretRight
              width={'1.3rem'}
              height={'1.3rem'}
              fill={white}
              className={classes.icon}
            />
          </Button>
          {/*<br />*/}
          {/*<Button*/}
          {/*  variant={'contained'}*/}
          {/*  className={common.actionButton}*/}
          {/*  style={{*/}
          {/*    minWidth: '40px',*/}
          {/*    width: '40px',*/}
          {/*    height: '40px',*/}
          {/*    padding: '0px',*/}
          {/*    margin: 0,*/}
          {/*  }}*/}
          {/*  onClick={openHelp}>*/}
          {/*  <SvgIconInfo />*/}
          {/*</Button>*/}
        </div>
      </Container>
      <ForceLoginDialog
        open={signup !== undefined}
        onClose={() => setSignup(undefined)}
        isSignup={signup}
      />
    </div>
  );
};

export default AppLogin;
