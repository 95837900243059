import * as React from 'react';

function SvgIconBackArrow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-back-arrow_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 55.7 50.3'
      xmlSpace='preserve'
      {...props}>
      <style>
        {`.icon-back-arrow_svg__st0{fill:none;stroke:${
          props.fill ?? '#fff'
        };stroke-width:9;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`}
      </style>
      <path
        className='icon-back-arrow_svg__st0'
        d='M25.1 45.8L4.5 25.1 25.1 4.5M7 25.1h44.2'
      />
    </svg>
  );
}

export default SvgIconBackArrow;
