import * as React from 'react';

function SvgBossKeyWizardsPath(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 169.32 173.14'
      {...props}>
      <linearGradient
        id='boss-key-wizards_path_svg__a'
        gradientUnits='userSpaceOnUse'
        x1={72.177}
        y1={24.584}
        x2={84.765}
        y2={11.996}
        gradientTransform='rotate(45.001 84.657 95.308)'>
        <stop offset={0.213} stopColor='#ffeb98' />
        <stop offset={0.932} stopColor='#b6f6b3' />
      </linearGradient>
      <path
        fill='url(#boss-key-wizards_path_svg__a)'
        d='M142.95 31.25l-2.97-2.97c-.93-.93-2.45-.93-3.38 0l-10.05 10.05c-.93.93-.93 2.45 0 3.38l2.97 2.97c.93.93 2.45.93 3.38 0l10.05-10.05c.94-.93.94-2.45 0-3.38z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M142.95 31.25l-2.97-2.97c-.93-.93-2.45-.93-3.38 0l-10.05 10.05c-.93.93-.93 2.45 0 3.38l2.97 2.97c.93.93 2.45.93 3.38 0l10.05-10.05c.94-.93.94-2.45 0-3.38z'
      />
      <linearGradient
        id='boss-key-wizards_path_svg__b'
        gradientUnits='userSpaceOnUse'
        x1={87.574}
        y1={64.996}
        x2={127.002}
        y2={25.568}
        gradientTransform='rotate(45.001 84.657 95.308)'>
        <stop offset={0} stopColor='#fca3a3' />
        <stop offset={0.092} stopColor='#fdc78f' />
        <stop offset={0.189} stopColor='#ffeb98' />
        <stop offset={0.313} stopColor='#b6f6b3' />
        <stop offset={0.463} stopColor='#a6f8f8' />
        <stop offset={0.638} stopColor='#9fccff' />
        <stop offset={0.647} stopColor='#9fcaff' />
        <stop offset={0.776} stopColor='#a2b3ff' />
        <stop offset={0.896} stopColor='#a4a5ff' />
        <stop offset={1} stopColor='#a5a0ff' />
      </linearGradient>
      <path
        fill='url(#boss-key-wizards_path_svg__b)'
        d='M131.27 45.7l9.94 9.94c4.1 4.1 10.36-1.82 10.42-1.88l.82-.79 11.46 11.46-17.86 17.87-5.68-5.68.7-.7c.27-.54 1.21-2.84-.8-4.85-.23-.23-.49-.43-.76-.6a4.193 4.193 0 00-5.2.6 4.225 4.225 0 000 5.96 4.518 4.518 0 001.04.76c.68.35 1.44.51 2.21.46l.53-.03 11.48 11.48-16.5 16.5-17.65-17.65.83-.83c3.63-3.63 4.19-6.02 4.02-7.39-.18-1.45-1.21-2.12-1.22-2.12l-10.88-10.88 23.1-21.63z'
      />
      <linearGradient
        id='boss-key-wizards_path_svg__c'
        gradientUnits='userSpaceOnUse'
        x1={29.95}
        y1={25.565}
        x2={69.378}
        y2={64.993}
        gradientTransform='rotate(45.001 84.657 95.308)'>
        <stop offset={0} stopColor='#fca3a3' />
        <stop offset={0.092} stopColor='#fdc78f' />
        <stop offset={0.189} stopColor='#ffeb98' />
        <stop offset={0.313} stopColor='#b6f6b3' />
        <stop offset={0.463} stopColor='#a6f8f8' />
        <stop offset={0.638} stopColor='#9fccff' />
        <stop offset={0.647} stopColor='#9fcaff' />
        <stop offset={0.776} stopColor='#a2b3ff' />
        <stop offset={0.896} stopColor='#a4a5ff' />
        <stop offset={1} stopColor='#a5a0ff' />
      </linearGradient>
      <path
        fill='url(#boss-key-wizards_path_svg__c)'
        d='M103.92 63.07L93.03 52.19c-.01-.01-.67-1.04-2.12-1.22-1.37-.17-3.77.4-7.39 4.02l-.83.83-17.65-17.66 16.5-16.5 11.48 11.48-.03.53c-.05.76.11 1.53.46 2.21.13.26.3.51.49.74.08.1.17.2.27.3a4.225 4.225 0 005.96 0 4.193 4.193 0 00.6-5.2c-.17-.27-.37-.53-.6-.76-2.01-2.01-4.31-1.06-4.85-.8l-.7.7-5.68-5.68L106.8 7.31l11.46 11.46-.79.82c-.06.06-5.98 6.33-1.88 10.42l9.94 9.94-21.61 23.12z'
      />
      <path d='M131.17 51.26l7.21 7.21c1.71 1.71 3.88 2.61 6.27 2.61 3 0 5.75-1.45 7.57-2.69l6.04 6.04-12.21 12.21-.75-.75c.54-2.33.2-5.25-2.19-7.65-.45-.45-.95-.85-1.48-1.17-1.3-.81-2.8-1.24-4.33-1.24-2.19 0-4.25.86-5.8 2.41-3.2 3.2-3.2 8.41 0 11.61.2.2.4.38.59.54.44.36.92.68 1.44.95.91.47 1.9.77 2.92.87l7.48 7.48-10.85 10.85-12.09-12.09c2.49-3.05 3.58-5.93 3.26-8.6-.27-2.2-1.45-3.81-2.59-4.7l-7.73-7.73 17.24-16.16m.1-5.56l-23.11 21.62 10.88 10.88c.01.01 1.04.67 1.22 2.12.17 1.37-.4 3.77-4.02 7.39l-.83.83 17.65 17.65 16.5-16.5-11.48-11.48-.53.03c-.09.01-.18.01-.28.01a4.199 4.199 0 01-2.97-1.23 4.225 4.225 0 010-5.96 4.195 4.195 0 015.2-.6c.27.17.53.37.76.6 2.01 2.01 1.06 4.31.8 4.85l-.7.7 5.68 5.68 17.87-17.87-11.46-11.46-.82.79c-.05.04-3.52 3.32-6.98 3.32-1.2 0-2.39-.39-3.44-1.44l-9.94-9.93zM106.8 12.97l6.05 6.05c-.51.73-1.04 1.62-1.5 2.59-1.99 4.26-1.48 8.36 1.41 11.25l7.21 7.21-16.15 17.26-7.73-7.73c-.89-1.13-2.5-2.32-4.69-2.59-.3-.04-.61-.05-.92-.05-2.45 0-4.98 1.09-7.69 3.31L70.7 38.16l10.85-10.85 7.48 7.48a8.164 8.164 0 002.37 4.94c1.55 1.55 3.61 2.4 5.81 2.4s4.26-.85 5.81-2.4a8.188 8.188 0 001.18-10.13c-.33-.54-.73-1.04-1.18-1.49-1.59-1.59-3.56-2.42-5.72-2.42-.72 0-1.37.09-1.94.23l-.75-.75 12.19-12.2m0-5.66L88.93 25.18l5.68 5.68.7-.7c.28-.14 1.03-.46 1.96-.46.88 0 1.92.28 2.89 1.25.23.23.43.49.6.76 1.04 1.67.79 3.81-.6 5.2-.82.82-1.9 1.23-2.98 1.23a4.201 4.201 0 01-3.25-1.53c-.19-.23-.35-.48-.49-.74a4.22 4.22 0 01-.46-2.21l.03-.53-11.46-11.47-16.5 16.5L82.7 55.81l.83-.83c3.27-3.27 5.54-4.05 6.96-4.05.15 0 .3.01.43.03 1.45.18 2.12 1.21 2.12 1.22l10.88 10.88 21.62-23.11-9.94-9.94c-4.1-4.1 1.82-10.36 1.88-10.42l.79-.82L106.8 7.31z' />
      <linearGradient
        id='boss-key-wizards_path_svg__d'
        gradientUnits='userSpaceOnUse'
        x1={43.388}
        y1={123.015}
        x2={113.555}
        y2={52.849}
        gradientTransform='rotate(45.001 84.657 95.308)'>
        <stop offset={0} stopColor='#fca3a3' />
        <stop offset={0.092} stopColor='#fdc78f' />
        <stop offset={0.189} stopColor='#ffeb98' />
        <stop offset={0.313} stopColor='#b6f6b3' />
        <stop offset={0.463} stopColor='#a6f8f8' />
        <stop offset={0.638} stopColor='#9fccff' />
        <stop offset={0.647} stopColor='#9fcaff' />
        <stop offset={0.776} stopColor='#a2b3ff' />
        <stop offset={0.896} stopColor='#a4a5ff' />
        <stop offset={1} stopColor='#a5a0ff' />
      </linearGradient>
      <path
        fill='url(#boss-key-wizards_path_svg__d)'
        d='M77.88 104.36l56.67-56.67c.76-.77.77-2.01 0-2.77l-8.24-8.24a1.95 1.95 0 00-2.77 0L66.88 93.35c-8.75 8.75-19.57 14.98-30.98 17.84l24.15 24.15c2.85-11.41 9.09-22.24 17.83-30.98z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M60.05 135.34c2.86-11.41 9.09-22.24 17.84-30.98l56.67-56.67c.77-.77.77-2.01 0-2.77l-8.24-8.24a1.95 1.95 0 00-2.77 0L66.88 93.35c-8.75 8.75-19.57 14.98-30.98 17.84'
      />
      <g>
        <linearGradient
          id='boss-key-wizards_path_svg__e'
          gradientUnits='userSpaceOnUse'
          x1={63.892}
          y1={121.33}
          x2={93.07}
          y2={121.33}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#fca3a3' />
          <stop offset={0.092} stopColor='#fdc78f' />
          <stop offset={0.189} stopColor='#ffeb98' />
          <stop offset={0.313} stopColor='#b6f6b3' />
          <stop offset={0.463} stopColor='#a6f8f8' />
          <stop offset={0.638} stopColor='#9fccff' />
          <stop offset={0.647} stopColor='#9fcaff' />
          <stop offset={0.776} stopColor='#a2b3ff' />
          <stop offset={0.896} stopColor='#a4a5ff' />
          <stop offset={1} stopColor='#a5a0ff' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__e)'
          d='M53.02 126.92l-8.71-8.71a8.432 8.432 0 010-11.92l14.52-14.52a8.432 8.432 0 0111.92 0l8.71 8.71a8.432 8.432 0 010 11.92l-14.52 14.52c-3.29 3.29-8.62 3.29-11.92 0z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M53.02 126.92l-8.71-8.71a8.432 8.432 0 010-11.92l14.52-14.52a8.432 8.432 0 0111.92 0l8.71 8.71a8.432 8.432 0 010 11.92l-14.52 14.52c-3.29 3.29-8.62 3.29-11.92 0z'
        />
      </g>
      <g>
        <linearGradient
          id='boss-key-wizards_path_svg__f'
          gradientUnits='userSpaceOnUse'
          x1={58.618}
          y1={135.969}
          x2={98.343}
          y2={175.694}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#fca3a3' />
          <stop offset={0.092} stopColor='#fdc78f' />
          <stop offset={0.189} stopColor='#ffeb98' />
          <stop offset={0.313} stopColor='#b6f6b3' />
          <stop offset={0.463} stopColor='#a6f8f8' />
          <stop offset={0.638} stopColor='#9fccff' />
          <stop offset={0.647} stopColor='#9fcaff' />
          <stop offset={0.776} stopColor='#a2b3ff' />
          <stop offset={0.896} stopColor='#a4a5ff' />
          <stop offset={1} stopColor='#a5a0ff' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__f)'
          d='M65.58 105.65l-56.18 4 52.18 52.18z'
        />
        <linearGradient
          id='boss-key-wizards_path_svg__g'
          gradientUnits='userSpaceOnUse'
          x1={41.588}
          y1={137.385}
          x2={78.481}
          y2={137.385}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#b6f6b3' />
          <stop offset={0.262} stopColor='#a6f8f8' />
          <stop offset={0.537} stopColor='#9fccff' />
          <stop offset={0.548} stopColor='#9fcaff' />
          <stop offset={0.713} stopColor='#a2b3ff' />
          <stop offset={0.867} stopColor='#a4a5ff' />
          <stop offset={1} stopColor='#a5a0ff' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__g)'
          d='M65.58 105.65l-56.18 4 16.12 16.12z'
        />
        <linearGradient
          id='boss-key-wizards_path_svg__h'
          gradientUnits='userSpaceOnUse'
          x1={78.481}
          y1={137.385}
          x2={115.373}
          y2={137.385}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#fca3a3' />
          <stop offset={0.536} stopColor='#fdc78f' />
          <stop offset={1} stopColor='#ffeb98' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__h)'
          d='M65.58 105.65l-4 56.18-16.12-16.11z'
        />
        <linearGradient
          id='boss-key-wizards_path_svg__i'
          gradientUnits='userSpaceOnUse'
          x1={58.609}
          y1={181.362}
          x2={98.334}
          y2={141.637}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#fca3a3' />
          <stop offset={0.092} stopColor='#fdc78f' />
          <stop offset={0.189} stopColor='#ffeb98' />
          <stop offset={0.313} stopColor='#b6f6b3' />
          <stop offset={0.463} stopColor='#a6f8f8' />
          <stop offset={0.638} stopColor='#9fccff' />
          <stop offset={0.647} stopColor='#9fcaff' />
          <stop offset={0.776} stopColor='#a2b3ff' />
          <stop offset={0.896} stopColor='#a4a5ff' />
          <stop offset={1} stopColor='#a5a0ff' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__i)'
          d='M5.4 165.83l56.18-4L9.4 109.65z'
        />
        <linearGradient
          id='boss-key-wizards_path_svg__j'
          gradientUnits='userSpaceOnUse'
          x1={78.481}
          y1={179.942}
          x2={115.374}
          y2={179.942}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#b6f6b3' />
          <stop offset={0.262} stopColor='#a6f8f8' />
          <stop offset={0.537} stopColor='#9fccff' />
          <stop offset={0.548} stopColor='#9fcaff' />
          <stop offset={0.713} stopColor='#a2b3ff' />
          <stop offset={0.867} stopColor='#a4a5ff' />
          <stop offset={1} stopColor='#a5a0ff' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__j)'
          d='M5.4 165.83l56.18-4-16.12-16.11z'
        />
        <linearGradient
          id='boss-key-wizards_path_svg__k'
          gradientUnits='userSpaceOnUse'
          x1={41.588}
          y1={179.942}
          x2={78.481}
          y2={179.942}
          gradientTransform='rotate(45.001 84.657 95.308)'>
          <stop offset={0} stopColor='#fca3a3' />
          <stop offset={0.536} stopColor='#fdc78f' />
          <stop offset={1} stopColor='#ffeb98' />
        </linearGradient>
        <path
          fill='url(#boss-key-wizards_path_svg__k)'
          d='M5.4 165.83l4-56.18 16.12 16.12z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M61.58 161.83l-56.18 4 4-56.18 56.18-4z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}
          d='M61.58 161.83l-56.18 4 4-56.18 56.18-4z'
        />
        <g
          fill='none'
          stroke='#000'
          strokeWidth={4}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit={10}>
          <path d='M25.52 125.77l40.06-20.12M45.46 145.72l20.12-40.07M61.58 161.83L9.4 109.65M45.46 145.72L5.4 165.83M25.52 125.77L5.4 165.83' />
        </g>
      </g>
    </svg>
  );
}

export default SvgBossKeyWizardsPath;
