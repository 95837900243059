import React from 'react';
import { IRenderPart } from '../part';
import Corgi from './00_Corgi';
import Cat from './01_Cat';
import Cow from './02_Cow';
import Gerbil from './03_Gerbil';
import Tiger from './04_Tiger';
import Furdenza from './05_Furdenza';
import Hippo from './06_Hippo';
import Mammoth from './07_Mammoth';
import Seal from './08_Seal';
import Pinata from './09_Pinata';
import RainbowPower from './10_RainbowPower';
import PsychedelicPower from './11_PsychedelicPower';
import DalmatianPower from './12_DalmatianPower';
import DiscoBallPower from './13_DiscoBallPower';
import MermanPower from './14_MermanPower';

export function renderEdition0Body(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Corgi renderer={renderer} />;
  if (renderer.partNumber === 2) return <Cat renderer={renderer} />;
  if (renderer.partNumber === 3) return <Cow renderer={renderer} />;
  if (renderer.partNumber === 4) return <Gerbil renderer={renderer} />;
  if (renderer.partNumber === 5) return <Tiger renderer={renderer} />;
  if (renderer.partNumber === 6) return <Furdenza renderer={renderer} />;
  if (renderer.partNumber === 7) return <Hippo renderer={renderer} />;
  if (renderer.partNumber === 8) return <Mammoth renderer={renderer} />;
  if (renderer.partNumber === 9) return <Seal renderer={renderer} />;
  if (renderer.partNumber === 10) return <Pinata renderer={renderer} />;
  if (renderer.partNumber === 11) return <RainbowPower renderer={renderer} />;
  if (renderer.partNumber === 12)
    return <PsychedelicPower renderer={renderer} />;
  if (renderer.partNumber === 13) return <DalmatianPower renderer={renderer} />;
  if (renderer.partNumber === 14) return <DiscoBallPower renderer={renderer} />;
  if (renderer.partNumber === 15) return <MermanPower renderer={renderer} />;
  console.warn('renderEdition0Body', renderer);
}
