import React, { FunctionComponent, useEffect } from 'react';
import Lottie from 'react-lottie';
import * as animation from '../../animations/loot-trash.json';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Typography, Paper, Grid } from '@mui/material';
import clsx from 'clsx';

import { useLoadedFurball } from '../../wallet';
import { getLootItem } from '../../components/Loot/LootItems';
import { getLootSlot } from '../../components/Loot/LootHelpers';
import LootSlotWide from '../../components/Loot/LootSlotWide';
import WalletSlice from '../../wallet/WalletSlice';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { getRewardTitle } from '../../components/Rewards/RewardHelpers';
import { cdnRoot } from '../../theme';
import FurballRenderer from '../../components/Furballs/FurballRenderer';
import { ILootFoundFurball } from './LootFoundContext';
import SvgX from '../../assets/SvgX';
import themev2 from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    inset: 0,
  },

  contain: {
    position: 'absolute',
    bottom: '0px',
    top: '0px',
    left: '0px',
    right: '0px',
    overflow: 'hidden',
    backgroundImage: `url(${cdnRoot}/svgs/loot-bk.svg)`,
    backgroundPosition: 'bottom',
    backgroundSize: 'cover',
    zIndex: 10001,
  },

  wrap: {
    position: 'absolute',
  },

  furball: {
    width: '300px',
    position: 'absolute',
    bottom: '70px',
    left: 'calc(40vw)',
    opacity: '0',
    textAlign: 'center',
  },

  furballMoved: {
    bottom: '25%',
    left: '43%',
    opacity: '100%',
    transition: theme.transitions.create(['bottom'], {
      duration: theme.transitions.duration.short,
    }),
  },

  prompt: {
    position: 'absolute',
    bottom: '340px',
    width: '400px',
    padding: theme.spacing(2),
    zIndex: 10002,
  },

  closeEarly: {
    position: 'absolute',
    height: '75px',
    width: '75px',
    borderRadius: '0px 20px 20px 0px !important',
    border: '5px solid #978EE4 !important',
    backgroundColor: 'rgba(151, 142, 228, 0.8) !important',
    zIndex: 10002,
    left: '-4px',
    top: '20px',
  },

  [theme.breakpoints.down('lg')]: {
    furballMoved: {
      // bottom: '240px',
    },
    furball: {
      width: '240px',
      left: 'calc(60vw)',
    },
    wrap: {
      bottom: '0px',
      left: '-200px',
      right: '-200px',
    },
    prompt: {
      right: 'calc(50vw) !important',
      bottom: '20px',
    },
  },

  [theme.breakpoints.down('md')]: {
    furballMoved: {
      // bottom: '200px',
    },
  },

  [theme.breakpoints.down('sm')]: {
    furballMoved: {
      // bottom: '120px',
    },
  },

  [theme.breakpoints.up('lg')]: {
    furballMoved: {},
    wrap: {
      bottom: '-60px',
    },
    prompt: {
      right: '10%',
    },
  },

  [theme.breakpoints.up('xl')]: {
    furballMoved: {},
    furball: {
      width: '400px',
    },
    wrap: {
      bottom: '-60px',
    },
  },
}));

interface IParams {
  lootFound: ILootFoundFurball;
  haveNext: boolean;
  onAction: (exit: boolean) => void;
}

interface IFurballMove extends IParams {
  cleanupLootState: (tokenId: string, exit: boolean) => void;
}

const FurballMove: FunctionComponent<IFurballMove> = (props) => {
  const { lootFound, haveNext, cleanupLootState } = props;
  const { tokenId, lootId } = lootFound;
  const furball = useLoadedFurball(tokenId);

  const classes = useStyles();
  const item = getLootItem(lootId as number);
  const slot = item ? getLootSlot(item) : undefined;
  const [secPassed, setSecPassed] = React.useState(0);
  const pending = useWalletSelector((s) => s.pending);
  const currentResponses = pending[tokenId]?.playResponses ?? [];
  const reward = currentResponses[currentResponses.length - 1]?.reward;

  React.useEffect(() => {
    const t = setTimeout(() => setSecPassed(secPassed + 0.1), 100);
    return () => {
      clearTimeout(t);
    };
  }, [secPassed]);

  useEffect(() => {
    setSecPassed(0);
  }, [tokenId]);

  return (
    <React.Fragment>
      <div
        className={clsx(classes.furball, {
          [classes.furballMoved]: secPassed > 0.7,
        })}>
        {furball && <FurballRenderer tokenId={furball.tokenId} />}
      </div>

      {secPassed > 2 && slot && (
        <Paper className={classes.prompt}>
          <Grid container spacing={2}>
            {reward && (
              <Grid item xs={12}>
                <Typography variant={'h4'}>{getRewardTitle(reward)}</Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <LootSlotWide slot={slot} />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                sx={{
                  width: '100%',
                  backgroundColor: `${
                    haveNext
                      ? themev2.palette.primary.main
                      : themev2.palette.secondary.main
                  } !important`,
                }}
                onClick={() => cleanupLootState(tokenId, false)}>
                <Typography variant={'h5'}>
                  Go {haveNext ? 'next' : 'back'}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
    </React.Fragment>
  );
};

export const LootFound: FunctionComponent<IParams> = (props) => {
  const {
    lootFound: { tokenId },
    onAction,
  } = props;
  const dispatch = useAppDispatch();
  const lottieRef = React.useRef<Lottie>(null);
  const classes = useStyles();

  const animationData = JSON.parse(JSON.stringify(animation)).default;
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function cleanupLootState(tokenId: string, exit: boolean) {
    if (tokenId) {
      dispatch(WalletSlice.actions.clearPlayResponse(tokenId));
    }

    onAction(exit);
  }

  return (
    <div className={classes.root}>
      <Button
        className={classes.closeEarly}
        onClick={() => cleanupLootState(tokenId, true)}>
        <SvgX height={22.5} width={22.5} />
      </Button>
      <div className={classes.contain}>
        <div className={classes.wrap}>
          <FurballMove {...props} cleanupLootState={cleanupLootState} />
          <Lottie options={defaultOptions} ref={lottieRef} />
        </div>
      </div>
    </div>
  );
};

export default LootFound;
