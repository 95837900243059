import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { getSkillDefinitionById, SkillLibrary } from './SkillLibrary';
import {
  EntitySkill,
  useRollSkillMutation,
  RollSkillMutation,
} from '../../components/schema';
import { cdnRoot } from '../../theme';
import { useFrameRate } from '../../components/useAnimation';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import SvgRedo from '../../assets/SvgRedo';
import { useLoadedFurball } from '../../wallet';
import { useFurballHelpers } from '../../components/Furballs';
import { useFurComponent } from '../../utils';
import { IFurball } from '../../wallet/WalletTypes';
import WalletSlice from '../../wallet/WalletSlice';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '@keyframes upDown': {
      '0%': {
        transform: 'translateY(-0.8rem)',
      },
      '100%': {
        transform: 'translateY(0.8rem)',
      },
    },

    '@keyframes shakeX': {
      'from, to': {
        transform: 'translateX(0, 0, 0)',
      },

      '10%, 30%, 50%, 70%, 90%': {
        transform: 'translateX(-10px)',
      },

      '20%, 40%, 60%, 80%': {
        transform: 'translateX(10px)',
      },
    },
  },

  comingSoon: {
    position: 'absolute',
    bottom: '-180px',
    inset: 0,
    zIndex: 1,
    minHeight: '680px',
    maxHeight: '680px',
  },

  bunny: {
    margin: '0 auto 0.5rem',
    height: '150px',
    width: '150px',
  },

  cubeContainer: {
    position: 'absolute',
    display: 'grid',
    placeItems: 'center',
    height: '10rem',
    width: '10rem',
    borderRadius: '50%',
    marginTop: '2rem !important',
    boxShadow: '0 0 15rem 4rem #abe8bc6b',
    transition: 'transform 0.2s',
    animationFillMode: 'forwards',
    backgroundColor: '#354B7B',
    paddingBottom: '0.8rem !important',
  },

  cube: {
    width: '10rem',
    height: '10rem',
    position: 'absolute',
    top: '50%',
    transformStyle: 'preserve-3d',
    transform: 'rotateX(245deg) rotateY(0deg) rotateZ(-140deg)',
  },

  side: {
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    position: 'absolute',
    display: 'grid',
    placeItems: 'center',
    backgroundColor: '#968DE4', //theme.palette.secondary.main,
    border: '3px solid black',
    transition: 'all 1s ease-in-out',

    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      backgroundSize: 'cover',
      backgroundImage: `url(${cdnRoot}/images/questioncover.png)`,
    },
  },

  top: {
    transform: 'translateZ(-10rem)',
    borderTop: '4px solid black',
    borderLeft: '4px solid black',

    '&::before': {
      transform: 'scaleX(-1)',
      backgroundImage: `url(${cdnRoot}/images/questionpattern.png)`,
    },
  },

  left: {
    width: '10rem',
    transform: 'translateZ(-10rem) rotateY(90deg)',
    transformOrigin: 'right',
    borderTop: '4px solid black',
    borderLeft: '4px solid black',
  },

  right: {
    transform: 'rotateX(90deg)',
    transformOrigin: 'bottom',
    borderLeft: '4px solid black',
    borderBottom: '4px solid black',

    '&::before': {
      transform: 'scaleX(-1)',
    },
  },

  skillIcon: {
    width: '9rem',
    height: '9rem',
    position: 'relative',
    top: '1rem',
  },

  topText: {
    transform: 'rotateY(180deg)',
    WebkitTextStroke: '2px black',
  },

  buttonContainer: {
    marginTop: '31rem !important',
  },

  infoText: {
    padding: '1rem 20%',
  },

  openButton: {
    width: '13rem',
  },

  cubeOpen: {
    '& $side': {
      opacity: 0.1,

      '& img': {
        opacity: 0,
      },
    },

    '& $top': {
      top: '-7rem',
      left: '-7rem',
    },

    '& $left': {
      top: '-8rem',
    },

    '& $right': {
      left: '-8rem',
    },
  },

  newSkill: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '1rem',
    position: 'relative',
    transition: 'all 1s ease-in-out',
    transformOrigin: 'center',
  },

  newSkillIcon: {
    width: '9rem',
    height: '9rem',
    position: 'relative',
    top: '0.5rem',
  },

  newSkillText: {
    marginTop: '-3rem !important',
  },
}));

interface IProps {
  slotIndex: number;
  skills: EntitySkill[];
  tokenId: string;
  updateSkills: (skill: EntitySkill) => void;
  toggleReroll: () => void;
  setDisabled: (value: boolean) => void;
}

enum CubeSides {
  TOP = 'top',
  LEFT = 'left',
  RIGHT = 'right',
}

interface ISideProps
  extends Omit<
    IProps,
    'updateSkills' | 'tokenId' | 'setDisabled' | 'toggleReroll'
  > {
  side: CubeSides;
  index: number;
  loading: boolean;
}

const CubeSide = ({ side, index, skills, loading }: ISideProps) => {
  const classes = useStyles();

  const getRandomSkills = useCallback(() => {
    const availableSkills = SkillLibrary.filter((skill) => {
      const userSkillIds = skills.map((item) => item.skillDefinitionId);
      return !userSkillIds.includes(skill.id) && skill.id !== 26;
    });

    const randomNumbers = [];

    for (let i = 0; i < 3; i++) {
      randomNumbers.push(
        Math.floor(Math.random() * availableSkills.length) + 0,
      );
    }

    return [
      availableSkills[randomNumbers[0]],
      availableSkills[randomNumbers[1]],
      availableSkills[randomNumbers[2]],
    ];
  }, [skills]);

  useFrameRate(loading, 200);

  return (
    <div className={clsx(classes.side, classes[side])}>
      {side === CubeSides.TOP ? (
        <Typography
          variant='h4'
          fontSize='1.6rem'
          textAlign='center'
          className={classes.topText}>
          ROLL <br /> A SKILL
        </Typography>
      ) : (
        <img
          className={classes.skillIcon}
          src={`${cdnRoot}/images/icons/skills/${
            getRandomSkills()[index].icon
          }.png`}
          alt={getRandomSkills()[index].name}
        />
      )}
    </div>
  );
};

export const RollASkill: FunctionComponent<IProps> = ({
  skills,
  tokenId,
  updateSkills,
  setDisabled,
  toggleReroll,
  slotIndex,
}) => {
  const { log } = useFurComponent(RollASkill);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newSkill, setNewSkill] =
    useState<RollSkillMutation['rollSkill']['battleStats']['skills'][0]>();
  const furball = useLoadedFurball(tokenId);
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const sides = ['top', 'left', 'right'];

  const [rollASkill] = useRollSkillMutation({ refetchQueries: [] });
  const { refreshFurballs } = useFurballHelpers(log);

  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const requiredBalance =
    (furball?.skillRollCost ?? 0) <= 0 ? 500000 : furball?.skillRollCost ?? 0;
  const requiredBalanceThousands = requiredBalance / 1000;
  const hasRolledSkill = skills.length > 2;

  const rollSkill = async () => {
    setOpen(false);
    setLoading(true);
    setDisabled(true);

    try {
      const { data } = await rollASkill({
        variables: {
          furballId: tokenId,
          slotIndex,
        },
      });

      if (data?.rollSkill) {
        setTimeout(async () => {
          const furball = data.rollSkill as IFurball;
          const rolledSkill = furball.battleStats.skills.find(
            (item) => item.slotIndex === slotIndex,
          );

          setNewSkill(rolledSkill as EntitySkill);

          // await refreshFurballs([tokenId]);
          dispatch(WalletSlice.actions.updateFurballs([furball]));
          setLoading(false);
          setOpen(true);
        }, 3000);
      }
    } catch (error) {
      log.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      setDisabled(false);
      toggleReroll();
    };
  }, []);

  function updateRolledSkill() {
    [updateSkills(newSkill as EntitySkill), setDisabled(false)];
    toggleReroll();
  }
  const def = newSkill
    ? getSkillDefinitionById(newSkill.skillDefinitionId)
    : undefined;

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        direction='column'
        className={classes.comingSoon}>
        <div
          className={classes.cubeContainer}
          style={{
            animationPlayState: open ? 'paused' : 'running',
            animation: `upDown 1.5s ease-in-out infinite alternate, shakeX 1.5s ease-in-out infinite alternate ${
              !loading ? 'paused' : 'running'
            }`,
          }}>
          {!!newSkill && !!def && (
            <div data-show={open} className={classes.newSkill}>
              <img
                className={classes.newSkillIcon}
                src={`${cdnRoot}/images/icons/skills/${def?.icon}.png`}
                alt={def?.name}
              />
              <Typography
                variant='h4'
                color='#fff'
                textAlign='center'
                className={classes.newSkillText}>
                {def?.name}
              </Typography>
            </div>
          )}
          <div
            className={clsx(classes.cube, {
              [classes.cubeOpen]: open,
            })}>
            {sides.map((side, index) => (
              <CubeSide
                key={index}
                skills={skills}
                slotIndex={slotIndex}
                side={side as CubeSides}
                index={index}
                loading={loading}
              />
            ))}
          </div>
        </div>

        <Grid
          item
          container
          justifyContent='center'
          alignItems='center'
          justifySelf='flex-end'
          flexDirection={
            wFurBalance < requiredBalance ? 'column-reverse' : 'column'
          }
          className={classes.buttonContainer}>
          <Typography
            color={wFurBalance < requiredBalance ? '#a37e22' : '#fff'}
            textAlign='center'
            fontSize='1.1rem'
            className={classes.infoText}>
            {open ? (
              <b>{def?.description}</b>
            ) : (
              <b>
                {wFurBalance < requiredBalance
                  ? `Insufficient balance, $${requiredBalanceThousands}k wFur required. `
                  : `Cost: $${requiredBalanceThousands}k wFur.`}
              </b>
            )}
          </Typography>

          <Grid
            container
            alignItems='center'
            justifyContent='center'
            gap='0.4rem'>
            {open && (
              <Button
                variant='contained'
                color='secondary'
                disabled={loading || wFurBalance < requiredBalance}
                // className={classes.openButton}
                onClick={rollSkill}
                startIcon={<SvgRedo width='1rem' height='1rem' />}>
                Reroll for ${requiredBalanceThousands}k wFUR
              </Button>
            )}
            <Button
              variant='contained'
              disabled={loading || wFurBalance < requiredBalance}
              className={classes.openButton}
              onClick={() => {
                open && newSkill ? updateRolledSkill() : rollSkill();
              }}>
              {loading ? (
                <CircularProgress size='1.5rem' color='secondary' />
              ) : open ? (
                'Keep Current'
              ) : hasRolledSkill ? (
                'Reroll Skill'
              ) : (
                'Roll & Reveal Skill'
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
