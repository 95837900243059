import { Button, Grid } from '@mui/material';
import * as React from 'react';
import {
  useApproveNameMutation,
  useFurballNameChangesQuery,
} from '../../components/schema';
import FurballLink from '../../components/Players/FurballLink';
import PlayerLink from '../../components/Players/PlayerLink';
import { useFurComponent } from '../../utils';
import useCommonStyles from '../../components/useCommonStyles';

interface INameChanges {}

const NameChanges: React.FunctionComponent<INameChanges> = (props) => {
  const { log } = useFurComponent(NameChanges);
  const { loading, data } = useFurballNameChangesQuery();
  const loadedChanges = data?.furballNameChanges?.nodes ?? [];
  const [changes, setChanges] = React.useState(loadedChanges);
  const [approval, approved] = useApproveNameMutation();
  const isLoading = loading || approved.loading;
  const common = useCommonStyles();

  React.useEffect(() => {
    if (loadedChanges.length > 0) setChanges(loadedChanges);
  }, [loadedChanges]);

  const doApprove = React.useCallback(
    async (idx, renameId: string, approve: boolean) => {
      try {
        const res = await approval({ variables: { renameId, approve } });

        const rename = res.data?.renameFurballAccept;
        if (rename) {
          const chngs = [...changes];
          chngs.splice(idx, 1, rename);
          setChanges(chngs);
        }
      } catch (e: any) {
        log.warn(name, 'failed', e);
      }
    },
    [],
  );

  return (
    <Grid container>
      {changes.map((c, idx) => {
        return (
          <>
            <Grid item xs={4}>
              <FurballLink furball={{ ...c.furball, name: c.name }} />
            </Grid>
            <Grid item xs={4}>
              <PlayerLink player={c.player} />
            </Grid>
            {c.approved === true && (
              <Grid item xs={4}>
                Approved by {c.approvedBy?.username}
              </Grid>
            )}
            {c.approved === false && (
              <Grid item xs={4}>
                Rejected by {c.approvedBy?.username}
              </Grid>
            )}
            {c.approved === null && (
              <>
                <Grid item xs={2}>
                  <Button
                    variant={'contained'}
                    className={common.actionButton}
                    onClick={() => void doApprove(idx, c.id, true)}
                    disabled={isLoading}>
                    Approve
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant={'contained'}
                    className={common.secondaryButton}
                    onClick={() => void doApprove(idx, c.id, false)}
                    disabled={isLoading}>
                    Reject
                  </Button>
                </Grid>
              </>
            )}
          </>
        );
      })}
    </Grid>
  );
};

export default NameChanges;
