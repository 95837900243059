import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import SvgX from '../assets/SvgX';
import SvgIconBackArrow from '../assets/SvgIconBackArrow';
import { useClientHardware } from '../pages/App/clientHardware';
import { useAppBarHeight } from './useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },

  noPadding: {
    padding: '0 !important',
  },
  mobilePadding: {
    padding: '16px !important',
  },

  xButton: {
    paddingLeft: 0,
    paddingRight: 0,
    border: 'none',
  },

  okTxt: {},

  xTxt: {
    display: 'none',
  },

  topRight: {
    position: 'absolute',
    top: '7.5px',
    right: '0',
  },

  svgX: {
    width: 22,
    height: 22,
  },

  [theme.breakpoints.down('lg')]: {
    okTxt: {
      display: 'none',
    },
    xTxt: {
      display: 'block',
    },
  },
}));

export interface ICloseDialog {
  open: boolean;
  onClose: () => void;
}

type Props = ICloseDialog & {
  title?: React.ReactNode;
  titleBg?: 'black' | '#1A0273' | `#${string}`;
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  ok?: string;
  actions?: React.ReactNode;
  actionsClassName?: string;
  color?: 'primary' | 'secondary';
  topRight?: boolean;
  maxWidth?: 'sm' | 'md' | 'lg';
  blockchainBanner?: boolean;
  style?: React.CSSProperties;
  keepMounted?: boolean;
  fullScreen?: boolean;
  noPadding?: boolean;
  hideScrollbar?: boolean;
  transparentBg?: boolean;
  backIcon?: boolean;
  disabled?: boolean;
  onBackdropClick?: () => void;
  paperStyle?: React.CSSProperties;
  classes?: DialogProps['classes'];
};

const OkDialog: FunctionComponent<Props> = (props) => {
  const {
    open,
    onClose,
    children,
    title,
    actions,
    contentClassName,
    className,
    maxWidth,
    actionsClassName,
    style,
    keepMounted,
    fullScreen,
    noPadding,
    hideScrollbar,
    transparentBg,
    backIcon,
    disabled,
    onBackdropClick,
    paperStyle,
    classes: dialogClasses,
  } = props;
  const classes = useStyles();
  const okTxt = props.ok ?? 'Ok';
  const topRight = props.topRight === undefined ? true : props.topRight;
  const theme = useTheme();
  const blockchainBanner =
    props.blockchainBanner === undefined ? false : props.blockchainBanner;
  const isFullScreen =
    fullScreen === undefined
      ? useMediaQuery(theme.breakpoints.down('md'))
      : fullScreen;
  const appBarHeight = useAppBarHeight();

  //useWindowSize().width <= themev2.breakpoints.values.sm;
  const { cornerPadding } = useClientHardware();

  return (
    <Dialog
      fullScreen={isFullScreen}
      keepMounted={keepMounted}
      open={open}
      style={style}
      classes={dialogClasses}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)',
        },
      }}
      PaperProps={{
        style: {
          margin: isFullScreen ? '0' : '16px',
          borderRadius: isFullScreen ? '0' : '12px',
          backgroundColor: transparentBg ? 'transparent' : '#ffffff',
          ...(!!paperStyle ? paperStyle : {}),
        },
      }}
      onClose={
        !disabled && !blockchainBanner
          ? !!onBackdropClick
            ? onBackdropClick
            : onClose
          : (_, reason) => {
              if (reason !== 'backdropClick') {
                if (onClose) onClose();
              }
            }
      }
      maxWidth={maxWidth ?? 'md'}
      className={className}
      disableEscapeKeyDown={blockchainBanner}>
      {title && (
        <DialogTitle
          style={{
            fontSize: '1.7rem',
            backgroundColor: 'black',
            color: 'white',
            height: appBarHeight,
            padding: '12.5px 25px 12.5px 25x',
            textTransform: 'capitalize',
            paddingTop: 12.5 + cornerPadding,
          }}>
          {title}
        </DialogTitle>
      )}
      {topRight && (
        <div className={classes.topRight} style={{ top: 7.5 + cornerPadding }}>
          <Button
            variant={'text'}
            color={props.color ?? 'primary'}
            onClick={disabled ? undefined : onClose}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              border: 'none',
              height: '55px',
              width: '55px',
              marginRight: '8px',
            }}>
            {disabled ? null : backIcon ? (
              <SvgIconBackArrow className={classes.svgX} />
            ) : (
              <SvgX className={classes.svgX} />
            )}
          </Button>
        </div>
      )}
      <DialogContent
        style={{ overflowY: hideScrollbar ? 'hidden' : 'auto' }}
        className={clsx(
          {
            [classes.noPadding]: noPadding,
            [classes.mobilePadding]: isFullScreen,
          },
          classes.content,
          contentClassName,
        )}>
        {children}
      </DialogContent>

      {!!actions && (
        <DialogActions
          className={actionsClassName}
          style={{ paddingBottom: 25 }}>
          {actions}
          {blockchainBanner && (
            <Button
              style={{
                backgroundColor: props.color ?? '#FD776A',
                display: 'flex',
                margin: '0 auto',
                borderWidth: 4,
              }}
              variant={'contained'}
              onClick={disabled ? undefined : onClose}
              disableElevation>
              <Typography variant={'h5'}>Ignore</Typography>
            </Button>
          )}
          {!disabled && !topRight && !blockchainBanner && (
            <Button
              color={props.color ?? 'primary'}
              variant={'contained'}
              onClick={onClose}
              disableElevation>
              <Typography className={classes.okTxt} variant={'h6'}>
                {okTxt}
              </Typography>
              <Typography className={classes.xTxt} variant={'h6'}>
                {'X'}
              </Typography>
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default OkDialog;
