import React, { FunctionComponent, useState } from 'react';
import {
  Theme,
  Grid,
  CardContent,
  CardHeader,
  Card,
  IconButton,
  Button,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IFurballBase, IFurballPlayerMinimal } from '../../wallet/WalletTypes';
import FurballWidget from '../Embed/FurballWidget';

import clsx from 'clsx';
import SvgIconOpensea from '../../assets/SvgIconOpenSea';
import useCommonStyles from '../../components/useCommonStyles';
import PlayerFurballLink from '../../components/Players/PlayerFurballLink';
import AlertProvider from '../../components/Alert/AlertProvider';

import { useOpenSeaLink } from '../../components/Blockchain/OpenSeaLink';
import { ScholarshipPlacement } from '../../components/Scholarships/ScholarshipPlacement';
import { ScholarshipContractDetails } from './ScholarshipContractDetails';
import { useScholarshipHelpers } from '../../components/Scholarships';
import UpgradeDialog from '../../components/Town/Upgrade/UpgradeDialog';
import FurballRecentBossFights from './FurballRecentBossFights';
import { useWalletSelector } from '../../hooks';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    justifyContent: 'center',
    padding: '4rem 2rem',

    [theme.breakpoints.down(1375)]: {
      padding: '2rem 0.25rem 2rem 0.5rem',
    },
  },

  widgetWrap: {
    position: 'relative',
    width: '100%',
    height: '31.875rem',
    overflow: 'hidden',
    background: '#000',
    border: '3px solid #000',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '335px',
      ['@media (min-height:800px)']: {
        maxHeight: '360px',
      },
      ['@media (min-height:850px)']: {
        maxHeight: '370px',
      },
    },
  },

  paper: {
    padding: theme.spacing(2),
  },

  cardContent: {
    padding: '0 !important',
  },

  scholarshipBox: {
    padding: '0 !important',
    width: '100% !important',
    maxWidth: '540px !important',

    [theme.breakpoints.down(1375)]: {
      padding: '2rem 0.25rem 2rem 0.5rem',
      flexDirection: 'column',
      minWidth: '100% !important',
    },
  },

  fbCardWrapper: {
    padding: '0 !important',
    width: '100% !important',
    minWidth: '440px !important',
    maxWidth: '540px !important',
    flexGrow: 1,

    [theme.breakpoints.down(1375)]: {
      padding: '2rem 0.25rem 2rem 0.5rem',
      flexDirection: 'column',
      minWidth: '100% !important',
    },
  },

  scholarshipCard: {
    height: '100%',
    borderRadius: '12px !important',
  },

  scholarshipContent: {
    padding: '1.5rem !important',
    height: '100%',
    backgroundColor: '#32318E !important',
  },

  textField: {
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
    },

    '& .MuiInputBase-input': {
      borderRadius: '6px !important',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      padding: '10px 15px',

      '&:focus': {
        borderRadius: '6px !important',
      },
    },
  },

  seeDetailsBtnCont: {
    display: 'flex',
    justifyContent: 'center',

    '& button': {
      width: '100% !important',
      minHeight: '55px !important',
      maxHeight: '65px !important',
      borderTopLeftRadius: '0px !important',
      borderTopRightRadius: '0px !important',
      borderBottomLeftRadius: '12px !important',
      borderBottomRightRadius: '12px !important',
      fontSize: '0.875rem !important',
      flexDirection: 'column !important',

      '& p': {
        fontSize: '14px !important',
        lineHeight: '1.5rem !important',
      },
    },
  },

  noBottomRadius: {
    borderBottomLeftRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
  },

  wrapper: {
    display: 'flex',
    gap: '2rem',
    justifyContent: 'center',

    [theme.breakpoints.down(1375)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
}));

interface IFurballPublicHome {
  furball: IFurballBase;
  owner?: IFurballPlayerMinimal;
  isRentedByPlayer: boolean;
}

export const FurballPublicHome: FunctionComponent<IFurballPublicHome> = (
  props,
) => {
  const { log } = useFurComponent(FurballPublicHome);
  const { furball, owner, isRentedByPlayer } = props;
  const playerId = useWalletSelector((s) => s.contents?.id);
  const ownedByPlayer = owner?.id === playerId;
  const classes = useStyles();
  const common = useCommonStyles();
  const openSeaLink = useOpenSeaLink(furball.tokenId);

  const [showStatsDialog, setShowStatsDialog] = useState<boolean>(false);
  const { agreement } = useScholarshipHelpers(log, furball.tokenId);
  const isForRent = !!furball.openRentalAgreement && !isRentedByPlayer;

  return (
    <AlertProvider>
      <div className={clsx(classes.root)}>
        <div className={classes.wrapper}>
          <Grid item className={classes.fbCardWrapper}>
            <Card className={clsx(common.cardRoot, classes.noBottomRadius)}>
              <CardHeader
                className={common.cardHeader}
                title={
                  <PlayerFurballLink
                    tokenId={furball.tokenId}
                    furball={furball}
                    player={owner}
                  />
                }
                action={
                  <IconButton
                    onClick={() => {
                      document.location = openSeaLink;
                    }}>
                    <SvgIconOpensea
                      fill={'#fff'}
                      style={{ width: '1.5rem', height: '1.5rem' }}
                    />
                  </IconButton>
                }
              />
              <CardContent
                className={clsx(common.cardContent, classes.cardContent)}>
                <div className={classes.widgetWrap}>
                  <FurballWidget
                    furball={furball}
                    tokenId={furball.tokenId}
                    size={'calc(45vmin)'}
                  />
                </div>
              </CardContent>
            </Card>

            <div className={classes.seeDetailsBtnCont}>
              <Button
                variant={'contained'}
                size={'large'}
                onClick={() => setShowStatsDialog(true)}>
                <Typography variant={'body1'} fontWeight={'bold'}>
                  See All Stats and Equipment
                </Typography>
                {isForRent && (
                  <Typography variant={'body2'}>
                    (Available for rent)
                  </Typography>
                )}
              </Button>
            </div>
          </Grid>

          {!!ownedByPlayer && (
            <Grid item className={classes.scholarshipBox}>
              <Card className={classes.scholarshipCard}>
                <CardHeader
                  className={common.cardHeader}
                  title='Scholarship'
                  action={
                    <IconButton
                      style={{ visibility: 'hidden' }}
                      onClick={() => {
                        document.location = openSeaLink;
                      }}>
                      <SvgIconOpensea
                        fill={'#fff'}
                        style={{ width: '1.5rem', height: '1.5rem' }}
                      />
                    </IconButton>
                  }
                />

                <CardContent
                  className={clsx(
                    common.cardContent,
                    classes.scholarshipContent,
                  )}>
                  {agreement ? (
                    <ScholarshipContractDetails furball={furball} />
                  ) : (
                    <ScholarshipPlacement furball={furball} />
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}
        </div>

        <FurballRecentBossFights furball={furball} />
      </div>

      <UpgradeDialog
        show={showStatsDialog}
        tokenId={furball.id}
        onClose={() => setShowStatsDialog(false)}
        onAction={() => setShowStatsDialog(false)}
        rentFurball={isForRent}
      />
    </AlertProvider>
  );
};

export default FurballPublicHome;
