import * as React from 'react';
import { useWalletSelector } from '../../hooks';
import useCommonStyles from '../useCommonStyles';
import SpendButton from './SpendButton';
import { getApiRoot } from '../../FurballClient';
import SvgIconDollar from '../../assets/SvgIconDollar';

interface IStripeBuyButton {
  resource: 'TIX' | 'wFUR';
  quantity: number;
  priceEach: number;
  title?: string;
  disabled?: boolean;
}

const resources = {
  wFUR: 'price_1MstPXHv2TvDINA3i7JYLdQ4',
  TIX: 'price_1MstRNHv2TvDINA3lpvAm24c',
};

const SpendUsdButton: React.FunctionComponent<IStripeBuyButton> = (props) => {
  const common = useCommonStyles();
  const { title, resource, disabled, quantity, priceEach } = props;
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const priceUsd = Math.ceil(priceEach * quantity);

  function handlePurchase(): Promise<void> {
    window.location.replace(
      // priceUsd is used as the "quantity" since we're normalizing into dollars
      `//${getApiRoot()}/checkout/${resource.toLowerCase()}?playerId=${playerId}&quantity=${priceUsd}`,
    );
    return Promise.resolve();
  }

  return (
    <SpendButton
      currency={'USD'}
      balance={0}
      cost={priceUsd}
      onAccepted={() => handlePurchase()}
      itemName={resource}
      title={title}
      disabled={disabled}
      // icon={<SvgIconDollar style={{ position: 'relative', top: 4 }} width={24} height={24} fill={'#000'} />}
      hidePrompt={true}
      renderIcon={(f, s) => <SvgIconDollar fill={f} style={s} />}
    />
  );
};

export default SpendUsdButton;
