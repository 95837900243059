import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Pug = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffdca8'
      d='M196.38 152.08h-.12c-9.48.09-25.31 11.33-25.11 20.52.37 16.72 48.84 13.05 50.34 0 1.05-9.13-15.63-20.42-25.11-20.52z'
    />
    <path
      fill='#fbf4e8'
      d='M196.66 152.32h-.08c-6.19.05-16.41 5.56-16.41 10.07 0 4.17 3.78 6.34 16.86-.08.93-.45-1.05-.05 0 0 6.39.32 17.84 10.63 17.84-.49.01-3.27-12.02-9.45-18.21-9.5z'
    />
    <path
      fill='#75726c'
      d='M196.65 152.32h-.04c-10.86.21-9.5 3.07-8.71 5.35 2 5.73 4.18.58 7.53-.01 4.77-.84 7.03 5.87 9.93.01 2.34-4.72-5.42-5.32-8.71-5.35z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196.38 152.08h-.12c-7.7.08-19.47 7.51-23.61 15.22-.96 1.78-1.38 3.94-1.51 5.66-1.03 13.62 49.3 14.56 50.34 0 .67-9.17-15.62-20.78-25.1-20.88z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196.5 167.65c.82-2.1-.33-5.21-.33-5.21M192.28 158.85c0 1.27-1.38 2.16-2.7 1.74l-.04-.01c-1.73-.55-2.61-2.31-1.93-3.86l1.17-2.17M200.45 158.89c0 1.27 1.38 2.16 2.7 1.74l.04-.01c1.73-.55 2.61-2.31 1.93-3.86l-1.17-2.17M218.85 165.59s2.26 5.78-3.68 8.91c-5.94 3.13-14.96-6.96-18.58-6.8M173.08 169.72c.73 1.35 1.21 3.94 5.04 5.4 6.33 2.4 14.42-7.47 18.19-7.43'
    />
    <path
      fill='#fc9198'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M177.16 173.84s-9.46-10.32-.41-14.13c6.41-2.7 5.09 11.18 12.23 11.33 0 0-8.08 7.82-11.82 2.8z'
    />
  </svg>
);

export default Pug;
