import React, { FunctionComponent } from 'react';
import { Button, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { fireShareEvent } from '../../Analytics';
import SvgIconTwitter from '../../assets/SvgIconTwitter';
import { TwitterShareButton } from 'react-share';
import OkDialog from '../OkDialog';
import useCommonStyles from '../useCommonStyles';
import { usePlayerSlug } from '../../wallet/Wallet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IShareReferButton {
  primary?: boolean;
}

export const ShareReferButton: FunctionComponent<IShareReferButton> = (
  props,
) => {
  const { primary } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const ref = usePlayerSlug();
  const refLink = `https://furballs.com?ref=${ref}&utm_campaign=boss_share`;
  const [prompt, setPrompt] = React.useState(false);

  return (
    <>
      <Button
        variant={'contained'}
        color={primary ? 'primary' : 'secondary'}
        className={primary ? common.actionButton : common.secondaryButton}
        onClick={() => setPrompt(true)}>
        <Typography variant={'h6'}>
          <SvgIconTwitter
            style={{ width: 24, marginRight: 10, position: 'relative', top: 4 }}
          />
          Earn TIX by Sharing
        </Typography>
      </Button>

      <OkDialog
        title={'Share Furballs'}
        open={prompt}
        onClose={() => setPrompt(false)}
        maxWidth={'md'}>
        <Typography variant={'body1'} style={{ marginBottom: 10 }}>
          Whenever someone signs up via your referral link and joins a
          Tournament, you will earn +8 TIX.
        </Typography>
        <div style={{ textAlign: 'right' }}>
          <TwitterShareButton
            url={refLink}
            title={'Play Furballs for Free... without a Crypto Wallet'}
            hashtags={['NFTCommunity', 'NFTs']}
            beforeOnClick={() => {
              fireShareEvent('Twitter');
            }}>
            <div
              style={{
                padding: '4px 8px',
                border: '1px solid black',
                borderRadius: 4,
              }}>
              <Typography variant={'h5'}>
                <SvgIconTwitter style={{ width: 24, marginRight: 10 }} />
                Share to Twitter
              </Typography>
            </div>
          </TwitterShareButton>
        </div>
      </OkDialog>
    </>
  );
};

export default ShareReferButton;
