import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Market from './Market';
import { LootMarket } from './Loot/LootMarket';
import { RentMarket } from './Rent/RentMarket';
import RentMarketCreateParty from './Rent/RentMarketCreateParty';
import RentMarketCompleteParty from './Rent/RentMarketCompleteParty';
import { AuthenticatedRoute } from '../../components/Auth/AuthenticatedRoute';
import RentMarketGetFurballs from './Rent/RentMarketGetFurballs';

interface IMarketRoutes {}

export const MarketRoutes: FunctionComponent<IMarketRoutes> = (props) => {
  return (
    <Switch>
      <Route
        exact
        path='/market/loot/:section?/:lootId?'
        component={LootMarket}
      />
      <Route path='/market/furballs/get' component={RentMarketGetFurballs} />
      <Route exact path='/market/furballs' component={RentMarket} />
      <AuthenticatedRoute exact path='/market/furballs/create/:onboard?'>
        <RentMarketCreateParty />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path='/market/furballs/:partyId/complete/:realm?/:onboard?'>
        <RentMarketCompleteParty />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path='/market/furballs/:partyId?/:realm?/:onboard?'>
        <RentMarket />
      </AuthenticatedRoute>
      <Route path={'/market'} component={Market} />
    </Switch>
  );
};

export default MarketRoutes;
