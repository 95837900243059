import React from 'react';
import { IRenderPart } from '../part';
import Googley from './00_Googley';
import Angry from './01_Angry';
import Lazy from './02_Lazy';
import Creepy from './03_Creepy';
import Monocle from './04_Monocle';
import Vapor from './05_Vapor';
import SkepticalBlue from './06_SkepticalBlue';
import SkepticalRed from './07_SkepticalRed';
import SkepticalRainbow from './08_SkepticalRainbow';
import SparkleBlue from './09_SparkleBlue';
import SparklePink from './10_SparklePink';
import Spider from './11_Spider';
import Stars from './12_Stars';
import OhNo from './13_OhNo';
import Evil from './14_Evil';
import Goofy from './15_Goofy';
import Chill from './16_Chill';
import WorryGreenPurple from './17_WorryGreenPurple';
import WorrySilver from './18_WorrySilver';

export function renderEdition0Eyes(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Googley renderer={renderer} />;
  if (renderer.partNumber === 2) return <Angry renderer={renderer} />;
  if (renderer.partNumber === 3) return <Lazy renderer={renderer} />;
  if (renderer.partNumber === 4) return <Creepy renderer={renderer} />;
  if (renderer.partNumber === 5) return <Monocle renderer={renderer} />;
  if (renderer.partNumber === 6) return <Vapor renderer={renderer} />;
  if (renderer.partNumber === 7) return <SkepticalBlue renderer={renderer} />;
  if (renderer.partNumber === 8) return <SkepticalRed renderer={renderer} />;
  if (renderer.partNumber === 9)
    return <SkepticalRainbow renderer={renderer} />;
  if (renderer.partNumber === 10) return <SparkleBlue renderer={renderer} />;
  if (renderer.partNumber === 11) return <SparklePink renderer={renderer} />;
  if (renderer.partNumber === 12) return <Spider renderer={renderer} />;
  if (renderer.partNumber === 13) return <Stars renderer={renderer} />;
  if (renderer.partNumber === 14) return <OhNo renderer={renderer} />;
  if (renderer.partNumber === 15) return <Evil renderer={renderer} />;
  if (renderer.partNumber === 16) return <Goofy renderer={renderer} />;
  if (renderer.partNumber === 17) return <Chill renderer={renderer} />;
  if (renderer.partNumber === 18)
    return <WorryGreenPurple renderer={renderer} />;
  if (renderer.partNumber === 19) return <WorrySilver renderer={renderer} />;
  console.warn('renderEdition0Eyes', renderer);
}
