import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Card, Container, Theme, Typography } from '@mui/material';
import SvgIconDiscord from '../../assets/SvgIconDiscord';
import { useWindowSize } from '../../components/useWindowSize';
import { OutboundLink } from '../../components/Navigation';
import SvgDiscordChatBubble from '../../assets/SvgDiscordChatBubble';
import { yellow } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDiscordCard: {
    zIndex: 1,
    position: 'fixed',
    width: '160px',
    height: '180px',
    right: '0px',
    bottom: '20%',
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    [theme.breakpoints.down('lg')]: {
      width: '80px',
      height: '80px',
    },
  },
  socialIcon: {
    width: '80px',
    position: 'absolute',
    marginLeft: '47.5px !important',
    marginTop: '-110px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '22.5px',
      width: '50px',
      marginLeft: '17.5px !important',
    },
  },
  widescreenSocialIcon: {
    position: 'absolute',
    marginLeft: '47.5px !important',
    marginTop: '-110px',
  },
  widescreenDiscordInvite: {
    zIndex: 1,
    position: 'fixed',
    bottom: '124px !important',
    right: '10px  !important',
  },
}));

interface OwnProps {}

type Props = OwnProps;

export const discordChannelInviteUrl = 'https://discord.gg/4Xeq2p8u2r';

const StickyDiscordInvite: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const windowSize = useWindowSize();

  const discordLinkComponent =
    windowSize.width < 1200 ? (
      <Card
        className={classes.mobileDiscordCard}
        sx={{ backgroundColor: yellow }}>
        <OutboundLink to={discordChannelInviteUrl}>
          <SvgIconDiscord className={classes.socialIcon} fill={'#262626'} />
        </OutboundLink>
      </Card>
    ) : (
      <div className={classes.widescreenDiscordInvite}>
        <SvgDiscordChatBubble />
        <OutboundLink to={discordChannelInviteUrl}>
          <Typography
            variant={'h1'}
            sx={{
              fontSize: '1.2rem !important',
              marginTop: '-100px',
              marginLeft: '15px',
              maxWidth: '150px',
              textAlign: 'center',
              color: '#262626',
            }}>
            Have a question?
            <br />
            Join our Discord
          </Typography>
          <SvgIconDiscord
            width={80}
            className={classes.socialIcon}
            fill={'#262626'}
          />
        </OutboundLink>
      </div>
    );

  return <Container>{discordLinkComponent}</Container>;
};

export default StickyDiscordInvite;
