import React, { FunctionComponent } from 'react';
import SpendButton, { ISpendButtonBase } from './SpendButton';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletSlice from '../../wallet/WalletSlice';
import SvgIconWrappedFur from '../../assets/SvgIconWrappedFur';
import useCommonStyles from '../useCommonStyles';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@mui/material';

export const SpendWFurButton: FunctionComponent<ISpendButtonBase> = (props) => {
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const common = useCommonStyles();

  function onComplete(amount: number) {
    dispatch(WalletSlice.actions.setWFurBalance(wFurBalance - amount));
    if (props.onComplete) props.onComplete(amount);
  }

  function renderActions(hasEnough: boolean): React.ReactNode {
    return (
      <>
        {/*<ShareReferButton />*/}
        <Button
          variant={'contained'}
          color={hasEnough ? 'secondary' : 'primary'}
          className={hasEnough ? common.secondaryButton : common.actionButton}
          onClick={() => history.push('/buy#wfur')}>
          <Typography variant={'h5'}>
            <SvgIconWrappedFur
              fill={'#000000'}
              style={{
                width: 24,
                marginRight: 10,
                position: 'relative',
                top: 4,
              }}
            />
            Get More wFUR
          </Typography>
        </Button>
      </>
    );
  }

  return (
    <SpendButton
      {...props}
      balance={wFurBalance}
      currency={'wFUR'}
      onComplete={onComplete}
      renderActions={renderActions}
      renderIcon={(f, s) => <SvgIconWrappedFur fill={f} style={s} />}
    />
  );
};

export default SpendWFurButton;
