import { ILogEntry, LogLevel } from './types';

export function stringifyUnknowns(arr: unknown[]): string[] {
  const ret: string[] = [];
  if (!arr) return ret;
  for (let m = 0; m < arr.length; m++) {
    const msg = arr[m];
    const t = typeof msg;
    try {
      if (typeof msg === 'string') {
        ret.push(msg);
      } else if (t === 'object') {
        ret.push(JSON.stringify(msg));
      } else if (t === 'undefined') {
        ret.push('<NULL/>');
      } else if (typeof msg === 'number' || typeof msg === 'boolean') {
        ret.push(msg.toString());
      } else {
        console.log('unknown', t);
        ret.push(JSON.stringify(msg));
      }
    } catch (e: any) {
      console.warn('[LOGS] failed to stringify', t, msg);
    }
  }
  return ret;
}

export function getLogEntry(
  context: string,
  level: LogLevel,
  ...messages: unknown[]
): ILogEntry {
  const timestamp = new Date().toLocaleTimeString('en-GB'); //24h
  return { level, context, messages, timestamp };
}

export function logLevelStr(level: LogLevel): string {
  return LogLevel[level];
}

export function getLogLevel(
  levelStr: string,
  defaultLevel: LogLevel = LogLevel.info,
): LogLevel {
  const lvl = levelStr.trim().toLowerCase();
  if (lvl.length <= 0) return defaultLevel;
  const fc = lvl.charAt(0);
  if (fc === 'v') return LogLevel.verbose;
  if (fc === 't') return LogLevel.verbose; // trace
  if (fc === 'd') return LogLevel.debug;
  if (fc === 'i') return LogLevel.info;
  if (fc === 'w') return LogLevel.warn;
  if (fc === 'e') return LogLevel.error;
  return defaultLevel;
}
