import { SvgEditionSlot } from '../part';
import * as React from 'react';

const TripleChin = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      opacity={0.4}
      d='M212.44 201.22s-22.48 5.39-38.52-3.1c0 0 13.81 12.06 38.52 3.1z'
    />
    <path
      opacity={0.25}
      d='M201.86 198.31s-11.43 1.08-17.36-1.47c-.01.01 5.6 6.33 17.36 1.47z'
    />
  </svg>
);

export default TripleChin;
