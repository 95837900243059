import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Poodle = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='m75.71 236.71-7.29 23.44s-38.88-19.38-12.55-69.55c-.01 0-15.57 48.65 19.84 46.11z'
    />
    <path
      opacity={0.3}
      fill='#FFF'
      d='m71.23 251.1 4.48-14.39c-35.4 2.54-18.92-46.85-18.92-46.85-17.9 40.56 10.69 59.03 14.44 61.24z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m75.71 236.71-7.29 23.44s-38.88-19.38-12.55-69.55c-.01 0-11.24 47.71 19.84 46.11z'
    />
    <path
      fill='#f4f4f2'
      d='M42.73 183.97c2.18 12.18 13.68 20.31 25.68 18.16s19.96-13.77 17.78-25.94c-2.18-12.18-11.91-18.36-23.91-16.21s-21.73 11.81-19.55 23.99z'
    />
    <path
      fill='#FFF'
      d='M59.38 172.82c1.1 6.14 6.9 10.24 12.95 9.15 6.05-1.08 10.06-6.94 8.96-13.08-1.1-6.14-6-9.26-12.05-8.17s-10.96 5.97-9.86 12.1z'
    />
    <path
      opacity={0.12}
      d='M50.49 181.66c-.74-13.4 12.84-20.86 12.19-20.74-12 2.15-22.13 10.88-19.95 23.05 2.18 12.18 16.6 20.49 28.6 18.34 2.34-.42-19.85-2.86-20.84-20.65z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M42.73 183.97c2.18 12.18 13.68 20.31 25.68 18.16s19.96-13.77 17.78-25.94c-2.18-12.18-11.91-18.36-23.91-16.21s-21.73 11.81-19.55 23.99z'
    />
  </svg>
);

export default Poodle;
