import { SvgEditionSlot } from '../part';
import * as React from 'react';

const FurReaper = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path fill='#a3a6a6' d='m242 296-9 2 62-182h8z' />
    <path fill='#c6c9ca' d='m235 299-17-7 72-180 8 3z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m295.82 99.06-75.87 189.3a4.64 4.64 0 0 0 2.62 6.02l9.47 3.72c2.81 1.11 6 .68 8.42-1.14a8.461 8.461 0 0 0 2.93-4.06l63.05-183.84'
    />
    <path
      fill='#8a8c8d'
      d='M265.81 78.53c11.51 4.54 23.99 10.88 37.38 19.53l10.13-25.03s-80.58-76.47-138.47 6.79c0 0 27.2-20.73 77.07-6.08l13.89 4.79z'
    />
    <path
      fill='#8a8c8d'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m299.85 117.26-5.67-2.05c-3.26-1.18-4.81-5.22-3.47-9.02l12.62-35.54c1.35-3.8 5.09-5.92 8.35-4.74l5.67 2.05c3.26 1.18 4.81 5.22 3.47 9.02l-12.62 35.54c-1.35 3.8-5.09 5.93-8.35 4.74z'
    />
    <path
      fill='#6c6e70'
      d='M300.74 76.12s-7.03 8.74-24.08.34c-56.75-27.98-100.44 1.52-100.95 2.31 0 0 43.2-29.92 119.03 13.22l6-15.87z'
    />
    <path
      fill='#8a8c8d'
      d='M195.57 65.68c44.78-38.72 118.29 20.75 118.29 20.75l4.1-10.02s-39.78-32.01-66.78-33.15'
    />
    <path
      opacity={0.2}
      fill='#FFF'
      d='M176.23 79.37c46.27-57.48 105.48-22.36 121.99-10.93 2.07 1.43 7.31 1.26 8.24-1.1 0 0-75.03-65.4-133.48 14.37 0 0-2.3-.09 3.25-2.34z'
    />
    <path d='M300.36 78.23c-57.24-39.85-100.94-15.74-104.91-13.15 22.79-16.89 65.88-17.47 106.52 10.76 1.47.66.06 3.28-.32 2.95-.01-.01-1.28-.56-1.29-.56z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M305.5 67.34c-21.63-15.78-85.26-53.71-132.52 14.37 0 0 48.77-35.41 122.59 10.8'
    />
  </svg>
);

export default FurReaper;
