import React, { FunctionComponent } from 'react';
import { Theme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useOAuthUrl } from '../../wallet/oauth';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IDiscordOAuthButton {}

export function useDiscordLoginUrl(): string {
  // const clientId = '933922882311041034';
  // const walletAddress = useWalletSelector((s) => s.address ?? '');
  // const referrer = getReferrer() ?? '';
  // // The OAuth redirect URL must use a subdomain (www) not the root domain
  // const host =
  //   window.location.host.toLowerCase() === 'furballs.com'
  //     ? 'www.furballs.com'
  //     : window.location.host;
  // const redirect = `${window.location.protocol}//${host}/oauth/callback/discord`;
  // return (
  //   'https://discord.com/api/oauth2/authorize?response_type=code' +
  //   `&client_id=${clientId}&scope=identify&state=${walletAddress}_${referrer}&redirect_uri=${redirect}`
  // );

  return useOAuthUrl(
    'https://discord.com/api/oauth2/authorize',
    '933922882311041034',
    'discord',
    'identify',
  );
}

export const DiscordOAuthButton: FunctionComponent<IDiscordOAuthButton> = (
  props,
) => {
  const classes = useStyles();
  const url = useDiscordLoginUrl();

  return (
    <React.Fragment>
      <Button
        className={clsx(classes.root)}
        onClick={() => (window.location.href = url)}>
        Login
      </Button>
    </React.Fragment>
  );
};

export default DiscordOAuthButton;
