import { SvgEditionSlot } from '../part';
import * as React from 'react';

const ButterKnife = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffa6d3'
      d='m310.03 79.98 9.62 156.13c.01 4.01-3.24 7.27-7.25 7.28-4.01.01-7.27-3.24-7.28-7.25l-.05-21.85c0-2.44-1.99-4.42-4.43-4.41-2.44 0-4.42 1.99-4.41 4.43l.01 4.12c.01 3.29-2.65 5.95-5.94 5.96-3.29.01-5.95-2.65-5.96-5.94l-.04-27.4 25.73-111.07z'
    />
    <path
      fill='#e2f3f6'
      d='m254.88 200.98 39.39-157.14s24.15 18.51 13.98 87.01c-5.02 33.82-8.66 50.01-10.68 57.39a6.108 6.108 0 0 1-7.38 4.3l-19.61-4.92-4.08 16.27-11.62-2.91z'
    />
    <path
      fill='#f1fafb'
      d='m261.45 174.78 9.31-37.16 39.27-19.73c-.45 4.11-1.04 8.42-1.79 12.95-1.42 8.54-2.68 15.94-3.8 22.33l-42.99 21.61z'
    />
    <path
      fill='#ffa6d3'
      d='M308.97 117.96c.44 1.56-1.3 3.24-2.45 4.65-3.86 4.75-1.41 12.81-5.78 17.09-1.57 1.54-3.82 2.33-5.1 4.11-1.68 2.34-1.14 5.54-.64 8.38 1.17 6.65 1.33 14.52-3.72 19.01-.81.72-1.75 1.35-2.22 2.32-.56 1.12-.37 2.46-.17 3.7.66 4.11-4.5 7.59-3.83 11.7 0 0 8.2 6.05 12.5-.68 2.02-7.38 11.41-70.28 11.41-70.28z'
      opacity={0.78}
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={5}
      strokeMiterlimit={10}
      d='m255.01 198.37 39.25-154.53s22.72 18.31 13.98 87.01c-4.24 33.35-7.94 49.43-10.19 56.94a6.832 6.832 0 0 1-8.21 4.66l-19.27-4.83-3.95 13.66-11.61-2.91z'
    />
    <path
      fill='#bae9ff'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m224.98 294.74 16.95 4.25c2.25.57 4.54-.8 5.1-3.06l22.67-90.45c.57-2.25-.8-4.54-3.06-5.1l-16.95-4.25c-2.25-.57-4.54.8-5.1 3.06l-22.67 90.45a4.21 4.21 0 0 0 3.06 5.1z'
    />
  </svg>
);

export default ButterKnife;
