import * as React from 'react';
import SelectCustom from '../SelectCustom';
import SvgGift from '../../assets/SvgGift';
import theme from '../../themev2';
import {
  Button,
  CircularProgress,
  Grid,
  Switch,
  Typography,
  Theme,
} from '@mui/material';
import { ScholarshipRewards } from './ScholarshipRewards';
import {
  PlayerPendingRewardFragment,
  useEditRentalAgreementMutation,
  useRentalAgreementLazyQuery,
} from '../schema';
import { signScholarRental } from '../Auth/ScholarRental';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useFurballHelpers } from '../Furballs';
import WalletContext from '../../wallet/WalletContext';
import { useWalletSelector } from '../../hooks';
import { useScholarshipHelpers } from './ScholarshipHelpers';
import { useFurComponent } from '../../utils';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  dataGroup: {
    padding: '1.5rem',
    border: `2px solid ${theme.palette.secondary.light}`,
    borderRadius: '20px',
    backgroundColor: theme.palette.grey[50],
  },

  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    maxHeight: '1.5rem',
  },

  toggle: {
    '& .MuiSwitch-thumb': {
      boxShadow: '0 0 8px 0 #0000005f !important',
    },
  },

  disabledToggle: {
    pointerEvents: 'none',
    filter: 'grayScale(100%)',
  },

  button: {
    width: '12rem',
  },

  select: {
    // borderBottom: `1px solid ${theme.palette.grey[500]}`,
    maxHeight: '100%',
    transition: 'all 0.1s linear',
    overflow: 'visible !important',

    '& > div': {
      padding: '0.3rem 0 !important',
      width: 'fit-content',
      border: 'none !important',
      display: 'flex',
      flexDirection: 'row-reverse',
      backgroundColor: 'transparent !important',
    },

    '& svg': {
      left: '-2.5rem',
    },

    '& p': {
      fontSize: '1.1rem !important',
    },
  },
}));

interface IScholarshipDetails {
  tokenId: string;
  onEnded?: () => void;
}

const ScholarshipEditor: React.FunctionComponent<IScholarshipDetails> = (
  props,
) => {
  const { log } = useFurComponent(ScholarshipEditor);
  const classes = useStyles();
  const { tokenId, onEnded } = props;
  const { refreshFurballs } = useFurballHelpers(log);
  const {
    isOwner,
    agreement,
    furball,
    duration,
    playerLink,
    ownerLink,
    lastBattle,
    // rewards,
    loading,
    timeRemaining,
    endRentalAgreement,
    pendingCancellation,
  } = useScholarshipHelpers(log, tokenId, true);
  const [loadAgreement, { data }] = useRentalAgreementLazyQuery();

  const agreementId = agreement?.id;
  const rewards: PlayerPendingRewardFragment[] =
    data?.rentalAgreement.rewards ?? [];

  // const [loading, setLoading] = useState(false);
  const [editing, setEditing] = React.useState(false);
  const [autoRenews, setAutoRenews] = React.useState(agreement?.autoRenew);
  const [contractDuration, setContractDuration] = React.useState(duration);
  const [disabledOptions, setDisabledOptions] = React.useState<number[]>([]);
  const [showRewards, setShowRewards] = React.useState(false);

  const { addAlert } = React.useContext(AlertContext);

  const context = React.useContext(WalletContext);
  const addr = useWalletSelector((s) => s.address);

  const [editAgreement] = useEditRentalAgreementMutation();

  React.useEffect(() => {
    if (agreementId && open) {
      log.info('[RA] loading', agreementId);
      loadAgreement({ variables: { id: agreementId } });
    }
  }, [agreementId, open]);

  const editRentalAgreement = async () => {
    if (!context || !addr || !agreement) return;

    const durationSeconds = contractDuration * 24 * 60 * 60;
    const reservee = agreement?.playerId ?? undefined;
    setEditing(true);

    try {
      const { signature, startsAt, percent } = await signScholarRental(
        context,
        addr,
        tokenId,
        durationSeconds,
        autoRenews,
        reservee,
      );

      await editAgreement({
        variables: {
          rentalAgreementId: agreement?.id,
          terms: {
            startsAt,
            signature,
            percent,
            ownerId: addr,
            playerId: reservee ?? null,
            duration: durationSeconds,
            autoRenew: autoRenews ?? false,
            furballId: tokenId,
          },
        },
      });

      await refreshFurballs([tokenId]);
      setEditing(false);

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `Sucessfully edited rental contract for ${furball?.name}`,
      });
    } catch (error: any) {
      setEditing(false);

      addAlert({
        type: ALERT_TYPES.ERROR,
        message:
          error.message ??
          "Sorry! Couldn't make that happen, please try again.",
      });
    }
  };

  React.useEffect(() => {
    const checkDisabled = (duration: number) => {
      if (!agreement) return true;

      const startDate = new Date(agreement?.beganAt);

      // add duration in days to start date
      startDate.setDate(startDate.getDate() + duration);
      const endDate = startDate;
      const now = new Date();

      const seconds = (endDate.getTime() - now.getTime()) / 1000;
      return seconds <= 0;
    };

    const arr: number[] = [];

    [3, 7, 30].forEach((item) => {
      checkDisabled(item) && arr.push(item);
    });

    setDisabledOptions(arr);
  }, [contractDuration, agreement?.duration]);

  return (
    <>
      <Grid container gap='0.5rem' className={classes.dataGroup}>
        <Grid
          container
          gap='1rem'
          height={isOwner ? '2.5rem' : 'auto'}
          alignItems='center'
          justifyContent='space-between'>
          <Typography fontSize='1.1rem' fontWeight='600'>
            Time Remaining:
          </Typography>
          <Typography fontSize='1.1rem'>{timeRemaining()}</Typography>
        </Grid>

        {isOwner && (
          <Grid
            container
            gap='1rem'
            height={isOwner ? '2.5rem' : 'auto'}
            alignItems='center'
            justifyContent='space-between'>
            <Typography fontSize='1.1rem' fontWeight='600'>
              Duration:
            </Typography>
            <SelectCustom
              id='contract-duration'
              value={contractDuration}
              name='contract-duration'
              className={classes.select}
              disabled={
                (disabledOptions.length === 2 &&
                  !disabledOptions.includes(contractDuration)) ||
                pendingCancellation
              }
              options={[
                {
                  label: '3 days',
                  value: 3,
                  disabled: disabledOptions.includes(3),
                },
                {
                  label: '1 week',
                  value: 7,
                  disabled: disabledOptions.includes(7),
                },
                {
                  label: '1 month',
                  value: 30,
                  disabled: disabledOptions.includes(30),
                },
              ]}
              onChange={(e) => setContractDuration(e.target.value as number)}
            />
          </Grid>
        )}

        <Grid
          container
          gap='1rem'
          height={isOwner ? '2.5rem' : 'auto'}
          alignItems='center'
          justifyContent='space-between'>
          <Typography fontSize='1.1rem' fontWeight='600'>
            Auto Renews:
          </Typography>

          {isOwner ? (
            <div className={classes.toggleContainer}>
              <Switch
                color='secondary'
                checked={autoRenews}
                className={classes.toggle}
                disabled={pendingCancellation}
                onChange={(e) => setAutoRenews(!autoRenews)}
              />

              <Typography fontSize='1.1rem'>
                {autoRenews ? 'Yes' : 'No'}
              </Typography>
            </div>
          ) : (
            <Typography fontSize='1.1rem'>
              {agreement?.autoRenew ? 'Yes' : 'No'}
            </Typography>
          )}
        </Grid>

        {isOwner && (
          <Grid
            container
            gap='1rem'
            height={isOwner ? '2.5rem' : 'auto'}
            alignItems='center'
            justifyContent='space-between'>
            <Typography fontSize='1.1rem' fontWeight='600'>
              Last Battle:
            </Typography>

            <Typography fontSize='1.1rem'>{lastBattle()}</Typography>
          </Grid>
        )}

        {isOwner && (!!rewards?.length || agreement?.wFurEarned !== 0) && (
          <Grid
            container
            alignItems='center'
            justifyContent='center'
            mt='1.5rem'>
            <Button
              variant='outlined'
              color='secondary'
              onClick={() => setShowRewards(true)}
              endIcon={
                <SvgGift
                  width='0.8rem'
                  height='0.8rem'
                  fill={theme.palette.grey[700]}
                />
              }>
              <Typography fontSize='0.8rem' fontWeight='600'>
                Show Rewards
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        justifyContent='center'
        alignItems='center'
        gap='0.5rem'
        paddingBottom='1rem'>
        {pendingCancellation ? (
          <Typography
            textAlign='center'
            fontSize='1rem'
            mx='2rem'
            fontWeight='600'
            color={theme.palette.warning.dark}>
            Contract has been terminated and this agreement will be closed after
            the current contract duration is complete.
          </Typography>
        ) : (
          <>
            <Button
              variant='outlined'
              color='error'
              className={classes.button}
              disabled={editing || loading}
              onClick={() => endRentalAgreement(onEnded)}>
              {loading ? (
                <CircularProgress size='1.5rem' color='secondary' />
              ) : (
                'Terminate Contract'
              )}
            </Button>

            {isOwner && (
              <Button
                variant='contained'
                className={classes.button}
                disabled={editing || loading}
                onClick={() => editRentalAgreement()}>
                {editing ? (
                  <CircularProgress size='1.5rem' color='secondary' />
                ) : (
                  'Save Changes'
                )}
              </Button>
            )}
          </>
        )}
      </Grid>

      {(!!rewards?.length || agreement?.wFurEarned !== 0) && showRewards && (
        <ScholarshipRewards
          tokenId={tokenId}
          onClose={() => setShowRewards(false)}
          rewards={rewards}
        />
      )}
    </>
  );
};

export default ScholarshipEditor;
