import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import TimekeeperSlice, {
  ITimekeeperUserState,
  useTimekeeperSelector,
} from './TimekeeperSlice';
import { useFurballsMetaLazyQuery } from '../schema';
import { useFrameRate } from '../useAnimation';
import OkDialog from '../OkDialog';
import ShopSnackPowerUpTable from '../Shop/ShopSnackPowerUpTable';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0px !important',
  },
}));

interface IUnknown {}

type Props = {
  children?: React.ReactNode;
};

export const TimekeeperStateLoader: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const sender = useWalletSelector((s) => s.address);
  const dispatch = useAppDispatch();
  const reload = useTimekeeperSelector((s) => s.reload);
  const userState = useTimekeeperSelector((s) => s.userState);
  const snackSelection = useTimekeeperSelector((s) => s.snackSelection);
  const [loadMeta, { loading, data }] = useFurballsMetaLazyQuery();
  // const context = React.useContext(WalletContext);
  // const [roundNum, secondsRemaining] = context
  //   ? getTimekeeperRoundTime(context)
  //   : [0, 0];
  // const [curRoundNum, setCurRoundNum] = React.useState(roundNum);
  const tkCur = data?.meta.timekeeperUserState;

  useFrameRate(true, 1000);

  function setCurrent(c: ITimekeeperUserState) {
    dispatch(TimekeeperSlice.actions.setUserState({ ...c }));
  }

  React.useEffect(() => {
    if (data?.meta) dispatch(TimekeeperSlice.actions.setMeta({ ...data.meta }));
  }, [data]);

  function loadTimekeeper(force = false) {
    dispatch(TimekeeperSlice.actions.setReload(false));
    // if (!sender) {
    //   setCurrent({ sender, pending: [], current: [] });
    // } else if (force || userState.sender !== sender) {
    // }
    loadMeta();
  }

  // React.useEffect(() => {
  //   if (roundNum == curRoundNum) return;
  //
  //   const refresh = curRoundNum !== 0;
  //   setCurRoundNum(roundNum);
  //   if (!refresh) return;
  //
  //   // When the round changes, refresh the page automatically
  //
  //   const to = setTimeout(() => {
  //     // loadTimekeeper(true);
  //     window.location.reload();
  //   }, 15000 + Math.round(Math.random() * 1000));
  //   return () => { clearTimeout(to); }
  // }, [roundNum]);

  React.useEffect(() => {
    dispatch(TimekeeperSlice.actions.setLoading(loading));
  }, [loading]);

  React.useEffect(() => {
    loadTimekeeper();
  }, [sender]);

  React.useEffect(() => {
    if (reload) loadTimekeeper();
  }, [reload]);

  React.useEffect(() => {
    if (tkCur) {
      setCurrent({
        sender,
        current: tkCur.current,
        round: tkCur.round,
        pending: tkCur.pending,
      });
    }
  }, [tkCur]);

  function onClose() {
    dispatch(TimekeeperSlice.actions.setSnackSelection(undefined));
    if (snackSelection) snackSelection.close();
  }

  return (
    <React.Fragment>
      {props.children ?? props.children}
      <OkDialog
        title={'Add Snacks?'}
        open={!!snackSelection}
        onClose={onClose}
        contentClassName={classes.root}>
        {snackSelection && <ShopSnackPowerUpTable {...snackSelection} />}
      </OkDialog>
    </React.Fragment>
  );
};

export default TimekeeperStateLoader;
