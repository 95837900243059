import React, { FunctionComponent } from 'react';
import { Fade, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IEquipment } from '../../../wallet/WalletTypes';
import { getItemDef } from '../../BossFights/BossRewards/bossRewardsUtils';
import { cdnRoot } from '../../../theme';
import { getEquipableHolidayLoot } from '../../../utils';
import { getLootItem } from '../../Loot';
import EquipmentTimeRemaining from './EquipmentTimeRemaining';
import EquipmentStats from './EquipmentStats';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1000,
    position: 'absolute',
    right: '60px',
    top: '-20px',
    minHeight: '160px',
    width: '240px',
    borderRadius: '6px',
    padding: '12px 20px',
    border: '1px solid #A5B4FC',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
    color: 'white',

    '& h6': {
      textTransform: 'capitalize',
    },

    '@media (max-width: 1310px)': {
      right: '85px',
    },
  },
  cont: {
    display: 'flex',
    alignItems: 'center',

    '& img ': {
      height: '60px !important',
      maxHeight: '60px !important',
      width: '60px !important',
      maxWidth: '60px !important',
    },
  },
  info: {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    height: '50px',

    '& p': {
      fontSize: '0.75rem !important',
      color: 'white !important',
      textTransform: 'capitalize',

      '& span': {
        fontWeight: 600,
        fontSize: '0.85rem !important',
      },
    },
  },
  breaksAt: {
    marginTop: '5px',

    '& p': {
      fontSize: '0.7rem !important',
      opacity: 0.8,

      '& span': {
        fontSize: '0.8rem !important',
      },
    },
  },
  bonus: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2.5px',

    '& img': {
      height: '27.5px !important',
      width: '27.5px !important',
      marginRight: '7.5px',
      marginTop: '0 !important',
    },
  },
  icon: {
    '& svg': {
      height: '60px !important',
      maxHeight: '60px !important',
      width: '60px !important',
      maxWidth: '60px !important',
    },
  },
}));

interface IEquipmentTooltip {
  equipment: IEquipment;
}

export const EquipmentTooltip: FunctionComponent<IEquipmentTooltip> = (
  props,
) => {
  const { equipment } = props;
  const { itemId } = equipment;
  const itemDef = getItemDef(equipment.itemId);
  const classes = useStyles();

  function getIcon() {
    const equipableHolidayLoot = getEquipableHolidayLoot();
    if (equipableHolidayLoot.find((loot) => loot.id === itemId)) {
      return <div className={classes.icon}>{getLootItem(itemId).icon}</div>;
    }

    return (
      <img src={`${cdnRoot}/images/icons/boss_loot/${itemDef?.icon}.png`} />
    );
  }

  return (
    <Fade in={true}>
      <div className={classes.root}>
        {itemDef && (
          <Typography variant={'h6'}>
            {itemDef.rarity.toLowerCase()} {itemDef.name}
          </Typography>
        )}
        <div className={classes.cont}>
          {getIcon()}
          <div className={classes.info}>
            <EquipmentStats equipment={equipment} className={classes.bonus} />
            <div className={classes.breaksAt}>
              <Typography variant={'body1'}>
                <EquipmentTimeRemaining equipment={equipment} />
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default EquipmentTooltip;
