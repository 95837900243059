import React, { FunctionComponent } from 'react';
import { Theme, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IFurball } from '../../wallet/WalletTypes';
import { darkBlue, expColor, furColor } from '../../theme';
import { stroke } from './FurballCard';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  stats: {
    // backgroundColor: '#000000',
    color: '#ffffff',
    position: 'absolute',
    border: `${stroke}px solid #000000`,
    left: 0,
    right: 0,
    bottom: `${0}px`,
    // top: `${stroke + 220}px`,
    // left: `${stroke}px`,
    // right: `${stroke}px`,
  },
  subtext: {
    fontSize: '0.8rem',
  },
  [theme.breakpoints.down('lg')]: {
    subtext: {
      display: 'none',
    },
    stats: {
      // top: `${230}px`,
    },
  },
  expText: {
    color: expColor.light,
  },
  furText: {
    color: furColor.light,
  },
  statHeader: {
    width: '100%',
    backgroundColor: darkBlue,
  },
  statData: {
    width: '100%',
    backgroundColor: '#000000',
    // borderBottomRightRadius: (props: any) =>
    //   props.bottomRadius ? `${props.bottomRadius}px` : 'none',
    // borderBottomLeftRadius: (props: any) =>
    //   props.bottomRadius ? `${props.bottomRadius}px` : 'none',
  },
}));

interface IFurballCardStats {
  furball: IFurball;
  className?: string;
  bottomRadius?: number;
}

export const FurballCardStats: FunctionComponent<IFurballCardStats> = (
  props,
) => {
  const { furball, className, bottomRadius } = props;
  const classes = useStyles({ bottomRadius });

  return (
    <div className={clsx(classes.stats, className)}>
      <Grid container className={classes.statHeader}>
        <Grid item xs={4}>
          <Typography variant={'h6'}>Level</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h6'}>Exp</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h6'}>FUR</Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.statData}>
        <Grid item xs={4}>
          <Typography variant={'h5'}>
            {furball.level.toLocaleString()}
            <span className={classes.subtext}>
              /{furball.maxLevel.toLocaleString()}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h5'}>
            <span className={classes.expText}>
              {furball.state.expRate.toLocaleString()}
            </span>
            <span className={classes.subtext}>%</span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h5'}>
            <span className={classes.furText}>
              {furball.state.furRate.toLocaleString()}
            </span>
            <span className={classes.subtext}>%</span>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default FurballCardStats;
