import { useState, useEffect, useRef, useMemo } from 'react';
import { getAllLootItems } from '../../../components/Loot';
import { SkillLibrary } from '../../Skills/SkillLibrary';
import { getFilters, weapons } from './RentHelpers';

import {
  SearchFurballsQuery,
  SearchFurballsQueryVariables,
  useSearchFurballsLazyQuery,
  useSearchFurballByNumberLazyQuery,
  useSearchFurballByTokenIdLazyQuery,
  Maybe,
  SortEnumType,
} from '../../../components/schema';
import { FILTERKEYS, IFilterState } from './RentMarketTypes';
import { debounce } from 'lodash';
import { SORTKEYS } from './RentMarketSort';
import { eventEmitter } from '../../../utils';
import { REFRESH_EVENT } from '../../../components/Scholarships';

type SearchResults = SearchFurballsQuery['searchFurballs'];

export const useMarketplaceFilters = () => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const bottomRefII = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState<'1' | '2' | '3' | '4'>('1');
  const [query, setQuery] = useState<string>('');
  const [sortBy, setSortBy] = useState<SORTKEYS>();
  const [sortOrder, setSortOrder] = useState<SortEnumType>(SortEnumType.Asc);
  const [result, setResult] = useState<SearchResults>();
  const [state, setState] = useState<IFilterState>({
    skill: [],
    loot: [],
    weapon: [],
    level: [],
    bosskey: [],
    gene: [],
    exp: [],
    fur: [],
  });

  const filters = getFilters(state, setState);
  const [loadSearchResults, { loading, data }] = useSearchFurballsLazyQuery();

  const [loadSearchTokenId, { data: sidData, loading: sidLoading }] =
    useSearchFurballByTokenIdLazyQuery();
  const [loadSearchNumber, { data: snData, loading: snLoading }] =
    useSearchFurballByNumberLazyQuery();

  const hasTokenIdQuery = query?.slice(0, 2).toLowerCase() === '0x';
  const hasNumberQuery =
    query.length <= 5 && Number(query) !== 0 && !isNaN(Number(query));

  const search = async (isLoadingMore?: boolean) => {
    const inventory = [...state[FILTERKEYS.LOOT], ...state[FILTERKEYS.BOSSKEY]];
    const skills = state[FILTERKEYS.SKILL];
    const weaponFilter = state[FILTERKEYS.WEAPON];

    const geneFilter = state[FILTERKEYS.GENE]?.[0]?.split(':');
    const geneMin = Number(geneFilter?.[0] ?? -1);
    const geneMax = Number(geneFilter?.[1] ?? 101);

    const lvlFilter = state[FILTERKEYS.LEVEL]?.[0]?.split(':');
    const lvlMin = Number(lvlFilter?.[0] ?? -1);
    const lvlMax = Number(lvlFilter?.[1] ?? 201);

    const expFilter = state[FILTERKEYS.EXP]?.[0]?.split(':');

    const expMin = Number(expFilter?.[0] ?? -1);
    const expMax = Number(expFilter?.[1] ?? 2001);
    const furFilter = state[FILTERKEYS.FUR]?.[0]?.split(':');
    const furMin = Number(furFilter?.[0] ?? -1);
    const furMax = Number(furFilter?.[1] ?? 1001);

    const isLevel = sortBy === SORTKEYS.LEVEL;
    const isNumber = sortBy === SORTKEYS.NUMBER;

    const sort = isLevel
      ? { level: sortOrder }
      : isNumber
      ? { number: sortOrder }
      : { battleStats: { [sortBy as string]: sortOrder } };

    const params: SearchFurballsQueryVariables = {
      inventory: inventory.length
        ? inventory
        : getAllLootItems().map((item) => item.lootId),
      weapon: weaponFilter.length
        ? weaponFilter
        : weapons.map((weapon) => weapon.assetIndex + 1),
      endCursor: (isLoadingMore
        ? result?.pageInfo.endCursor
        : undefined) as Maybe<string>,

      minLevel: lvlMin,
      maxLevel: lvlMax,
      expBoostMin: expMin,
      expBoostMax: expMax,
      furBoostMin: furMin,
      furBoostMax: furMax,
      geneBoostMin: geneMin,
      geneBoostMax: geneMax,
      skills: skills.length ? skills : SkillLibrary.map((skill) => skill.id),
      sort: sortBy ? (sort as any) : undefined,
    };

    const { data } = await loadSearchResults({
      variables: params,
      fetchPolicy: 'network-only',
    });

    !!data && updateResults(data);
  };

  const updateResults = (data?: SearchFurballsQuery) => {
    console.log('search', data?.searchFurballs?.nodes?.length ?? -1);
    if (!data) return;

    const newNodes = data?.searchFurballs?.nodes || [];
    const oldNodes = result?.nodes || [];
    const allNodes = [...oldNodes, ...newNodes];

    // removes duplicate results
    const uniqueNodes = new Map(
      allNodes.map((item) => [item['id'], item]),
    ).values();

    if (data?.searchFurballs?.pageInfo.hasPreviousPage) {
      setResult({
        ...data?.searchFurballs,
        nodes: Array.from(uniqueNodes),
      });
    } else {
      setResult(data?.searchFurballs);
    }
  };

  // filter handlers
  useEffect(() => {
    search();
  }, [state, sortBy, sortOrder]);

  useEffect(() => {
    const refresh = () => updateResults(data);
    eventEmitter.once(REFRESH_EVENT, refresh);

    return () => {
      eventEmitter.removeListener(REFRESH_EVENT, refresh);
    };
  }, [data]);

  useEffect(() => {
    const target = bottomRef.current as HTMLElement;
    const targetII = bottomRefII.current as HTMLElement;

    const currentTarget = tab === '1' ? target : targetII;

    if (!currentTarget || !result || hasNumberQuery || hasTokenIdQuery) return;

    const options = {
      rootMargin: '0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && result.pageInfo.hasNextPage) {
          search(true);
        }
      });
    }, options);

    observer.observe(currentTarget);

    return () => {
      observer.unobserve(currentTarget);
    };
  }, [result, tab]);

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (query: string, hasTokenIdQuery: boolean, hasNumberQuery: boolean) => {
          if (hasTokenIdQuery) {
            loadSearchTokenId({
              variables: {
                tokenId: query,
              },
            });
          }

          if (hasNumberQuery) {
            loadSearchNumber({ variables: { number: Number(query) } });
          }
        },
        1000,
      ),
    [],
  );

  // search by id/number handlers
  useEffect(() => {
    debouncedSearch.cancel();

    !!query && debouncedSearch(query, hasTokenIdQuery, hasNumberQuery);
  }, [query]);

  return {
    filters,
    search,
    bottomRef,
    bottomRefII,
    tab,
    setTab,
    query,
    setQuery,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
    loading:
      !!query && !snData && !sidData
        ? true
        : hasTokenIdQuery
        ? sidLoading
        : hasNumberQuery
        ? snLoading
        : loading,
    furballs: hasTokenIdQuery
      ? sidData?.searchFurballs?.nodes || []
      : hasNumberQuery
      ? snData?.searchFurballs?.nodes || []
      : result?.nodes || [],
  };
};
