import { SvgEditionSlot } from '../part';
import * as React from 'react';

const DogTag = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M203.9 228.81c-1.07 0-1.94-2.45-1.94-5.47s.87-5.47 1.94-5.47'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M216.74 233.48a6.04 6.04 0 0 0 1.67-4.57 6.011 6.011 0 0 0-6.4-5.6 5.996 5.996 0 0 0-5.4 4.41l-8.72.58a6 6 0 0 0-5.94-3.66 6.011 6.011 0 0 0-5.6 6.4c.12 1.76.98 3.29 2.26 4.31a6.04 6.04 0 0 0-1.67 4.57 6.011 6.011 0 0 0 6.4 5.6 5.996 5.996 0 0 0 5.4-4.41l8.72-.58a6 6 0 0 0 5.94 3.66 6.011 6.011 0 0 0 5.6-6.4 6.014 6.014 0 0 0-2.26-4.31z'
    />
    <circle cx={198} cy={233} r={1} />
    <circle cx={206} cy={232} r={1} />
    <path
      fill='none'
      stroke='#000'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M200.68 235.13c.48.71 1.26 1.15 2.1 1.1.84-.06 1.55-.59 1.94-1.36'
    />
    <path
      fill='#999b9e'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M241.97 206.67a4.836 4.836 0 0 0-6.16-2.99c-2.53.88-3.87 3.64-2.99 6.16a4.857 4.857 0 0 0 4.54 3.26c.54 0 1.09-.08 1.62-.27 2.52-.87 3.86-3.63 2.99-6.16zM165.1 204.55c-2.51-.94-5.3.34-6.23 2.84-.94 2.51.34 5.3 2.84 6.23.55.2 1.1.3 1.65.31a4.84 4.84 0 0 0 4.58-3.15c.94-2.51-.34-5.3-2.84-6.23zM174.88 207.59c-2.59-.65-5.23.92-5.88 3.52-.65 2.6.92 5.23 3.52 5.88.38.1.77.14 1.14.15 2.18.02 4.18-1.45 4.74-3.67.65-2.6-.92-5.23-3.52-5.88zM138.58 189.25a4.84 4.84 0 0 0-6.82.64 4.84 4.84 0 0 0 .64 6.82 4.76 4.76 0 0 0 3.05 1.11c1.41.01 2.81-.59 3.77-1.75a4.85 4.85 0 0 0-.64-6.82zM146.82 195.32a4.841 4.841 0 0 0-5.29 8.11c.8.52 1.71.78 2.6.79 1.6.01 3.16-.76 4.1-2.2 1.47-2.24.83-5.24-1.41-6.7zM155.71 200.43a4.842 4.842 0 0 0-6.51 2.14 4.842 4.842 0 0 0 2.14 6.51c.69.35 1.42.52 2.14.52 1.78.01 3.51-.96 4.37-2.66 1.2-2.38.25-5.3-2.14-6.51zM215.71 208.89a4.855 4.855 0 0 0-3.97 5.59 4.836 4.836 0 0 0 5.58 3.96 4.855 4.855 0 0 0 3.97-5.59 4.825 4.825 0 0 0-5.58-3.96zM184.94 209.53a4.853 4.853 0 0 0-5.46 4.14 4.838 4.838 0 0 0 4.14 5.45c.21.03.42.04.62.04 2.4.02 4.5-1.75 4.83-4.19.38-2.63-1.48-5.07-4.13-5.44zM225.85 206.7c-2.59.67-4.14 3.32-3.47 5.91a4.837 4.837 0 0 0 4.64 3.62c.42 0 .84-.05 1.26-.16 2.59-.67 4.14-3.32 3.47-5.91-.67-2.58-3.32-4.13-5.9-3.46zM205.46 210.14c-2.67.2-4.67 2.52-4.48 5.19a4.852 4.852 0 0 0 4.79 4.49c.13 0 .26 0 .4-.01 2.67-.2 4.67-2.52 4.48-5.19-.2-2.67-2.52-4.68-5.19-4.48zM195.17 210.37c-2.67-.08-4.91 2.03-4.98 4.7a4.846 4.846 0 0 0 4.7 4.98h.1c2.63.02 4.81-2.06 4.88-4.71.08-2.66-2.02-4.89-4.7-4.97z'
    />
    <path d='M223.9 198.26s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
  </svg>
);

export default DogTag;
