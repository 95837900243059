import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  Paper,
  Typography,
  Slider,
  FormControl,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import FurballAvatar, { defaultPictureOpts } from './FurballAvatar';
import { IFurballBase } from '../../wallet/WalletTypes';

import { edition0SlotNames } from '../../assets/edition0';
import { FurballRenderDisabledSlots } from '../../components/Furballs/FurballRenderer';
import { useWindowSize } from '../../components/useWindowSize';
import { SelectCustom } from '../../components/SelectCustom';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '40rem',
    padding: '2rem 1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '1rem',
    },
  },

  paper: {
    padding: '0 1.5rem',
    maxWidth: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
    },
  },

  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
  },

  select: {
    '& .MuiSelect-select': {
      borderRadius: '30px !important',
      paddingTop: '0.45rem !important',
      paddingBottom: '0.45rem !important',
    },
  },

  selectMenu: {
    borderRadius: '30px !important',
    marginTop: '0.1rem',
  },

  sectionHeader: {
    padding: '0 0.3rem',
    marginBottom: '0.5rem !important',
    width: '100%',
    fontWeight: 'bold',
    textTransform: 'capitalize',

    '&:nth-of-type(2)': {
      marginTop: '1.5rem',
      marginBottom: '1rem !important',
    },
  },

  slideLabel: {
    display: 'inline-block',
    marginBottom: '-10px !important',
  },

  downloadWrapper: {
    marginTop: '0.6rem !important',
  },

  customMerchLink: {
    fontSize: '0.9rem',
    fontWeight: 500,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1rem',
    gap: '0.2rem',
  },

  linkIcon: {
    width: '0.7rem',
    height: '0.7rem',
  },

  menuItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    gap: '0.2rem',
  },

  optionUseCase: {
    fontSize: '0.7rem',
  },

  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: '350px',
      margin: '0 auto',
    },
  },
}));

interface IPfpGenerator {
  furball: IFurballBase;
}

export const PfpGenerator: FunctionComponent<IPfpGenerator> = (props) => {
  const { furball } = props;
  const [pictureOpts, setPictureOpts] = React.useState(defaultPictureOpts);

  const classes = useStyles();
  const clickRef = React.useRef<HTMLImageElement>(null);
  const isMobile = useWindowSize().width < 600;

  const slots = [...edition0SlotNames, 'Rounded', 'Background'];
  const [disabledSlotNames, setDisabledSlotNames] =
    React.useState<FurballRenderDisabledSlots>({});

  const sizes = [
    { size: '125 x 125', value: 125, useCase: 'Discord' },
    { size: '400 x 400', value: 400, useCase: 'Twitter' },
    { size: '1000 x 1000', value: 1000 },
    { size: '2000 x 2000', value: 2000 },
  ];

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={4} className={classes.container}>
        <Grid item xs={12} md={5} lg={5}>
          <div className={classes.avatarWrapper}>
            <FurballAvatar
              clickRef={clickRef}
              tokenId={furball.tokenId}
              saveSize={pictureOpts.size}
              opts={{
                ...pictureOpts,
                size: isMobile ? 120 : 200,
                circle: !disabledSlotNames?.Rounded,
                border: false,
              }}
              disableSlots={disabledSlotNames}
            />
            <br />
            <Typography variant={'h4'}>{furball?.name}</Typography>
            <Typography variant='body2'>Avatar Generator</Typography>
          </div>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <SelectCustom
                color='secondary'
                size='small'
                className={classes.select}
                value={pictureOpts.size}
                MenuProps={{
                  classes: { paper: classes.selectMenu },
                }}
                onChange={(e, v) =>
                  setPictureOpts({
                    ...pictureOpts,
                    size: e.target.value as number,
                  })
                }>
                {sizes.map((item, i) => (
                  <MenuItem key={i} value={item.value}>
                    <Typography
                      className={classes.menuItem}
                      lineHeight={'unset'}
                      variant='body1'>
                      <b>{item.size}</b>
                      {item?.useCase && (
                        <>
                          <span className={classes.optionUseCase}>-</span>
                          <span className={classes.optionUseCase}>
                            {item.useCase}
                          </span>
                        </>
                      )}
                    </Typography>
                  </MenuItem>
                ))}
              </SelectCustom>
            </FormControl>
          </Grid>

          <Grid className={classes.downloadWrapper} item xs={12}>
            <Button
              fullWidth
              variant='contained'
              onClick={() => clickRef.current?.click()}>
              <Typography variant='h6' fontSize='1rem' fontWeight='800'>
                Download
              </Typography>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                height='24px'
                viewBox='0 0 24 24'
                width='16px'
                fill='#000000'>
                <path d='M0 0h24v24H0z' fill='none' />
                <path d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z' />
              </svg>
            </Button>

            {/*<GuideLink*/}
            {/*  className={classes.customMerchLink}*/}
            {/*  to='the-basics/merch'>*/}
            {/*  Create Custom Merch*/}
            {/*  <svg*/}
            {/*    fill='none'*/}
            {/*    className={classes.linkIcon}*/}
            {/*    stroke='currentColor'*/}
            {/*    viewBox='0 0 12 12'>*/}
            {/*    <path d='M10.976 1.193A.314.314 0 0010.687 1H6.312a.313.313 0 000 .625h3.62L5.467 6.091a.313.313 0 00.443.442l4.466-4.466v3.62a.313.313 0 00.625 0V1.313a.328.328 0 00-.024-.119z'></path>*/}
            {/*    <path d='M3.5 1v.625H2.25a.625.625 0 00-.625.625v7.5c0 .345.28.625.625.625h7.5c.345 0 .625-.28.625-.625V8.5H11v1.875c0 .345-.28.625-.625.625h-8.75A.625.625 0 011 10.375v-8.75C1 1.28 1.28 1 1.625 1H3.5z'></path>*/}
            {/*  </svg>*/}
            {/*</GuideLink>*/}
          </Grid>
        </Grid>

        <Grid item xs={12} md={7} lg={7}>
          <Paper className={classes.paper}>
            <Grid container spacing={1}>
              <Typography className={classes.sectionHeader} fontWeight='bold'>
                Position
              </Typography>
              <Grid item xs={12}>
                <Typography variant='overline' className={classes.slideLabel}>
                  Zoom
                </Typography>
                <Slider
                  min={80}
                  max={300}
                  size='small'
                  color='secondary'
                  value={pictureOpts.zoom * 100}
                  // marks={[{ value: 200, label: '2x Zoom' }]}
                  onChange={(e, v) =>
                    setPictureOpts({
                      ...pictureOpts,
                      zoom: ((v as number) ?? 100) / 100,
                    })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='overline' className={classes.slideLabel}>
                  X-offset
                </Typography>
                <Slider
                  min={-150}
                  max={150}
                  size='small'
                  color='secondary'
                  value={pictureOpts.moveX}
                  onChange={(e, v) =>
                    setPictureOpts({ ...pictureOpts, moveX: v as number })
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='overline' className={classes.slideLabel}>
                  Y-offset
                </Typography>
                <Slider
                  min={-150}
                  max={150}
                  size='small'
                  color='secondary'
                  value={pictureOpts.moveY}
                  onChange={(e, v) =>
                    setPictureOpts({ ...pictureOpts, moveY: v as number })
                  }
                />
              </Grid>

              <Typography className={classes.sectionHeader} fontWeight='bold'>
                Attributes
              </Typography>

              <Grid container xs={12}>
                {slots.map((slotName) => {
                  const isEnabled = !disabledSlotNames[slotName];
                  return (
                    <Grid key={slotName} item xs={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            color={'secondary'}
                            checked={isEnabled}
                            onChange={(e) =>
                              setDisabledSlotNames({
                                ...disabledSlotNames,
                                [slotName]: !!isEnabled,
                              })
                            }
                          />
                        }
                        label={slotName}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PfpGenerator;
