import React, { FunctionComponent } from 'react';
import { Theme, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballPlayer } from '../../wallet/WalletTypes';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../components/useCommonStyles';
import SvgIconAccount from '../../assets/SvgIconAccount';
import { white } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0',
    background: 'none',
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
  },
  button: {
    color: `${white} !important`,
    marginLeft: '10px !important',
    height: '75px !important',
    width: '75px !important',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '10px !important',
      marginLeft: '0 !important',
    },
  },
}));

interface IAccountAvatar {
  player?: IFurballPlayer;
  radius?: number;
}

export const PlayerAvatar: FunctionComponent<IAccountAvatar> = (props) => {
  const { player } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const history = useHistory();
  const pathDest = '/account';
  const isOnPage =
    location.pathname === '/account' || location.pathname === pathDest;
  const radius = props.radius ?? 200;
  const dia = radius * 2;
  const border = { borderRadius: `${radius}px` };
  const borderWidth = 4;
  const innerDia = dia - borderWidth * 2;

  function renderAvatar() {
    if (!player?.avatar) {
      return <SvgIconAccount style={{ height: 30, width: 30 }} />;
    }

    return (
      <img
        src={player.avatar}
        style={{
          width: innerDia,
          height: innerDia,
          clipPath: `circle(${innerDia / 2}px at center)`,
        }}
      />
    );
  }

  return (
    <Button
      variant={'contained'}
      color={'secondary'}
      className={clsx(common.actionSecondary, classes.root, classes.button)}
      style={{ width: dia, height: dia, ...border }}
      disabled={isOnPage}
      onClick={() => history.push(pathDest)}>
      {renderAvatar()}
    </Button>
  );
};

export default PlayerAvatar;
