import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  GameParty,
  usePlayerPartiesQuery,
  useSetpartyMutation,
} from '../../../components/schema';
import { useWalletSelector } from '../../../hooks';
import { IFurballPartyMember, PartySize } from '../../Furverse/useBossBattle';
import {
  ALERT_TYPES,
  AlertContext,
} from '../../../components/Alert/AlertContext';
import { useHistory, useParams } from 'react-router-dom';
import { useFurComponent } from '../../../utils/furComponent';

interface IParams {
  realm?: string;
  onboard?: string;
}

const useRoundOuPartialParty = () => {
  const { addAlert } = useContext(AlertContext);
  const history = useHistory();
  const { log } = useFurComponent(useRoundOuPartialParty.name);
  const { realm, onboard } = useParams<IParams>();
  const playerId = useWalletSelector((s) => s.contents?.id) ?? '';

  const [selectedPartyId, setSelectedPartyId] = useState<string>('');
  const [unfilledParties, setUnfilledParties] = useState<
    GameParty[] | undefined
  >(undefined);

  const [setParty] = useSetpartyMutation();
  const { data, error, refetch } = usePlayerPartiesQuery({
    variables: { playerId },
  });

  useEffect(() => {
    if (!!data && !!data.playerParties) {
      getPartialParties();
    }
  }, [data]);

  async function addRentedFbToParty(
    furballId: string,
    refresh: () => Promise<void>,
  ): Promise<boolean> {
    const selectedParty = getSelectedParty();
    if (!selectedParty) return false;

    const { id, name, slotNumber, partyMembers, openPositions } = selectedParty;

    const newPartyMember = {
      id: furballId,
    };

    const partyFbs = !partyMembers
      ? [newPartyMember]
      : ([...partyMembers, newPartyMember] as IFurballPartyMember[]);

    const furballIds = partyFbs.map((fb) => fb.id);
    /*const positions = partyMembers?.map((fb) => fb?.position) ?? null;

    const nextAvailableSlot = openPositions.includes(FormationPosition.Center)
      ? FormationPosition.Center
      : openPositions[0];

    const formationPositions =
      positions && nextAvailableSlot
        ? [...(positions as FormationPosition[]), nextAvailableSlot]
        : null;*/

    const args = {
      id,
      name,
      furballIds,
      slotNumber,
      playerId,
      formationPositions: null,
      deleteParty: false,
    };

    try {
      const { data } = await setParty({ variables: args });
      if (!data) return false;

      if (
        partyFbs.length === PartySize.THREE ||
        partyFbs.length === PartySize.FIVE
      ) {
        await refresh(); // ensure furballs are refreshed before redirecting
        history.push(
          `/market/furballs/${id}/complete${
            !!realm ? `/${realm}${!!onboard ? '/onboard' : ''}` : ''
          }`,
        );
        return true;
      }
    } catch (error: any) {
      log.error('error', error);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Unable to update party',
      });
    }
    return false;
  }

  async function createNewUnfilledParty(name: string) {
    const args = {
      id: null,
      slotNumber: null,
      formationPositions: null,
      furballIds: [],
      name,
      playerId,
      deleteParty: false,
    };

    try {
      const { data } = await setParty({ variables: args });
      if (!data) throw new Error(error?.message);
      await refetch();

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: 'New party created',
      });

      return data;
    } catch (error: any) {
      log.error('error', error);
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Unable to create new party',
      });

      throw error;
    }
  }
  //
  // function willRedirectAfterNextFbAdd() {
  //   const selectedParty = getSelectedParty();
  //   if (!selectedParty || !selectedParty.partyMembers) return false;
  //
  //   const currPartySize = selectedParty.partyMembers.length;
  //   const partySizeAfterAdd = currPartySize + 1;
  //
  //   return (
  //     partySizeAfterAdd === PartySize.THREE ||
  //     partySizeAfterAdd === PartySize.FIVE
  //   );
  // }

  function getSelectedParty() {
    return unfilledParties?.find(({ id }) => id === selectedPartyId);
  }

  function getPartialParties() {
    if (!data) return setUnfilledParties([]);

    const { playerParties } = data;
    const unfilledPartiesToSet = playerParties.filter(
      ({ filledPositions, size }) => {
        return filledPositions.length !== Math.max(size, PartySize.FIVE);
      },
    );

    return setUnfilledParties(unfilledPartiesToSet as GameParty[]);
  }

  const selectedParty = (unfilledParties ?? []).find(
    (p) => p.id === selectedPartyId,
  );
  const selectedPartySize = selectedParty?.partyMembers?.length ?? 0;

  return {
    selectedParty,
    selectedPartySize,
    selectedPartyId,
    setSelectedPartyId,
    unfilledParties,
    addRentedFbToParty,
    createNewUnfilledParty,
    // willRedirectAfterNextFbAdd,
  };
};

const RoundOutPartialPartyContext = createContext(
  {} as ReturnType<typeof useRoundOuPartialParty>,
);

export const RoundOutPartialPartyProvider: FunctionComponent = ({
  children,
}) => {
  const roundOutPartialParty = useRoundOuPartialParty();

  return (
    <RoundOutPartialPartyContext.Provider value={roundOutPartialParty}>
      {children}
    </RoundOutPartialPartyContext.Provider>
  );
};

export const useRoundOutPartialPartyContext = () =>
  useContext(RoundOutPartialPartyContext);
