import React, { FunctionComponent, useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  Typography,
  Theme,
  AccordionDetails,
  ButtonBase,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SvgCaret from '../../../assets/SvgCaret';

import { MarketplaceFilterItem } from './RentMarketFilterItem';
import { IFilterGroup } from './RentMarketTypes';
import { MarketplaceSlideFilter } from './RentMarketSlideFilter';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import useCommonStyles from '../../../components/useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0 -24px',

    [theme.breakpoints.down('sm')]: {
      margin: '0 -16px',
    },
  },

  accordion: {
    border: `1px solid ${theme.palette.grey[700]}`,
    borderLeft: 'none',
    borderRight: 'none',

    '&:not(:last-child)': {
      borderBottom: 0,
    },
  },

  accordionSummary: {
    position: 'relative',

    '&.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.grey[700]}`,
    },
  },

  expandIcon: {
    transform: 'rotate(90deg)',
  },

  accordionDetails: {
    backgroundColor: '#F5F5F5',
    maxHeight: '20rem',
    overflowY: 'auto',
  },

  btn: {
    minWidth: '100%',
    width: '100%',
    height: '55px !important',
  },
}));

interface IParams {
  realm?: string;
  onboard?: string;
}

interface IProps {
  filters: IFilterGroup[];
}

export const MarketplaceFilters: FunctionComponent<IProps> = ({ filters }) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { realm, onboard } = useParams<IParams>();

  useEffect(() => {
    if (!realm) return;

    const keysFilter = filters.find(({ title }) => title === 'Boss keys');
    if (!keysFilter) return;

    const keyForRealm = keysFilter.options.find(
      ({ title }) =>
        title.toLowerCase().replaceAll(' ', '-') === `${realm}-key`,
    );

    if (!!keyForRealm) {
      keysFilter.updater(keyForRealm.id);
    }
  }, [realm]);

  return (
    <div className={classes.root}>
      {filters.map((filterGroup, index) => (
        <Accordion
          key={index}
          square
          disableGutters
          defaultExpanded={!!realm && filterGroup.title === 'Boss keys'}
          className={classes.accordion}>
          <AccordionSummary
            expandIcon={
              <SvgCaret
                width='0.9rem'
                height='0.9rem'
                className={classes.expandIcon}
              />
            }
            className={classes.accordionSummary}>
            <Typography>{filterGroup.title}</Typography>
          </AccordionSummary>
          <AccordionDetails
            className={clsx(classes.accordionDetails, common.hideScrollBar)}>
            {filterGroup.slider ? (
              <MarketplaceSlideFilter
                max={filterGroup.slider}
                onChange={filterGroup.updater}
              />
            ) : (
              filterGroup.options.map((option, index) => {
                const isDisabled =
                  !!realm && filterGroup.title === 'Boss keys' && !!onboard;

                return (
                  <ButtonBase
                    key={index}
                    onClick={() => filterGroup.updater(option.id)}
                    className={classes.btn}
                    disableRipple={true}
                    disableTouchRipple={true}
                    disabled={isDisabled}>
                    <MarketplaceFilterItem
                      title={option.title}
                      description={option.description}
                      icon={option.icon}
                      isDisabled={isDisabled}
                      isChecked={filterGroup.checkedOptions.includes(option.id)}
                      data-skill={filterGroup.title.toLowerCase() === 'skill'}
                      data-weapon={filterGroup.title.toLowerCase() === 'weapon'}
                    />
                  </ButtonBase>
                );
              })
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
