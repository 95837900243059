import { secSinceBlock } from '../../wallet';
import {
  IFurballBase,
  IFurballContext,
  IFurballContracts,
} from '../../wallet/WalletTypes';
import { BigNumber, ethers } from 'ethers';
import {
  ITimekeeperArgs,
  ITimekeeperCosts,
  ITimekeeperInput,
  ITimekeeperInteractionBase,
} from './TimekeeperSlice';
import { TimekeeperInteractionType } from '../schema';

export function getTicketDisplay(
  tix: number,
  costs?: ITimekeeperCosts,
): number {
  return Math.ceil(tix / (costs?.factor ?? 10000));
}

export type ZoneMoveMap = { [key: number]: string[] };

export function unpackMovements(movementList: string): ZoneMoveMap {
  const ret: ZoneMoveMap = {};
  let curZone = 0;
  const movements = movementList.split(',');
  for (let i = 0; i < movements.length; i++) {
    const str = movements[i].trim();
    if (str.length <= 0) continue;
    const num = BigNumber.from(str);
    if (num.lt(0x100000000)) {
      curZone = num.div(0x100).toNumber();
      ret[curZone] = [];
    } else {
      ret[curZone].push(str);
    }
  }

  return ret;
}

export function countMovements(movement: ZoneMoveMap): number {
  let cnt = 0;
  Object.keys(movement).forEach((m) => {
    cnt += movement[Number.parseInt(m)].length;
  });
  return cnt;
}

export function packMovements(map: ZoneMoveMap): string {
  const zones = Object.keys(map).map(Number.parseInt).sort();
  const ret: string[] = [];
  for (let i = 0; i < zones.length; i++) {
    const numFb = map[zones[i]].length;
    if (numFb === 0) {
      continue;
    }
    if (numFb >= 0x100) {
      throw 'Too many furballs';
    }
    ret.push(BigNumber.from(zones[i]).mul(0x100).add(numFb)._hex);
    for (let j = 0; j < map[zones[i]].length; j++) {
      ret.push(map[zones[i]][j]);
    }
    map[zones[i]] = ret.concat(map[zones[i]]);
  }
  return ret.join(',');
}

export function addMovement(
  movementList: string,
  zoneNum: number,
  furballIds: string[],
) {
  const map = unpackMovements(movementList);
  if (!map[zoneNum]) map[zoneNum] = [];
  for (let i = 0; i < furballIds.length; i++) {
    if (!map[zoneNum].includes(furballIds[i])) map[zoneNum].push(furballIds[i]);
  }

  return packMovements(map);
}

export function removeMovement(movementList: string, furballIds: string[]) {
  const map = unpackMovements(movementList);
  Object.keys(map).forEach((z) => {
    const zoneNum = Number.parseInt(z);
    map[zoneNum] = map[zoneNum].filter((id) => !furballIds.includes(id));
  });
  return packMovements(map);
}

export async function estimateGas(
  interaction: ITimekeeperInteractionBase,
  contracts: IFurballContracts,
): Promise<BigNumber> {
  if (interaction.interactionType === TimekeeperInteractionType.CollectStay) {
    return contracts.furballs.estimateGas.playMany(
      interaction.furballIds,
      0,
      ethers.constants.AddressZero,
    );
  } else if (
    interaction.interactionType === TimekeeperInteractionType.CollectMove
  ) {
    return contracts.furballs.estimateGas.playMany(
      interaction.furballIds,
      interaction.expectedValue,
      ethers.constants.AddressZero,
    );
  } else if (
    interaction.interactionType === TimekeeperInteractionType.CollectAllFur
  ) {
    return contracts.furballs.estimateGas.playMany(
      interaction.furballIds,
      0x10000,
      ethers.constants.AddressZero,
    );
  } else if (
    interaction.interactionType === TimekeeperInteractionType.FeedSnack
  ) {
    const feedArgs: unknown[] = [];
    for (let i = 0; i < interaction.furballIds.length; i++) {
      feedArgs.push([interaction.furballIds[i], interaction.expectedValue, 1]);
    }
    return await contracts.furballs.estimateGas.feed(
      feedArgs,
      ethers.constants.AddressZero,
    );
  } else if (
    interaction.interactionType === TimekeeperInteractionType.UpgradeLoot
  ) {
    const gas = await contracts.furballs.estimateGas.upgrade(
      interaction.furballIds[0],
      interaction.expectedValue,
      30,
      ethers.constants.AddressZero,
    );
    return gas.mul(interaction.furballIds.length);
  } else if (
    interaction.interactionType === TimekeeperInteractionType.VoyageBegin
  ) {
    return BigNumber.from(0);
  }

  console.warn('no gas est for ', interaction.interactionType);
  return BigNumber.from(0);
}

export function getFuelCost(
  input: ITimekeeperArgs,
  costs: ITimekeeperCosts,
): number {
  const totalCost = costs.spendTickets;
  //
  // if (input.gained > 0) {
  //   totalCost += costs.collectFur;
  // }
  // if (input.spent > 0) {
  //   totalCost += costs.spendFur;
  // }
  // const moveMap: ZoneMoveMap = unpackMovements(input.movements);
  // Object.keys(moveMap).map((z) => {
  //   const zoneNum = Number.parseInt(z);
  //   const numFb = moveMap[zoneNum].length;
  //   totalCost += costs.move1 + (costs.moveN * (numFb - 1));
  //   if (zoneNum >= 0x10000) {
  //     // When going TO battle (from explore), add these costs
  //     totalCost += costs.collectExp1 + (costs.collectExpN * (numFb - 1));
  //   } else if (input.gained <= 0) {
  //     totalCost += costs.collectFur;
  //   }
  // });
  // if (input.mintCount >= 1) {
  //   totalCost += costs.mint1 + (costs.mintN * (input.mintCount - 1));
  // }

  return totalCost;
}

export function hasTimekeeperInputChanged(
  localInput?: ITimekeeperInput,
  i2?: ITimekeeperInput,
) {
  if (!i2) return true;
  if (!localInput) return false;
  return (
    localInput.fuelCost != i2.fuelCost ||
    localInput.movements.join(',') !== i2.movements.join(',')
  ); // || localInput.gained > i2.gained;
}
//
// export function getTimekeeperDate(context: IFurballContext) {
//
// }

export function getTimekeeperRoundTime(
  context: IFurballContext,
): [number, number] {
  const ts = Math.round(new Date().getTime()) - 3600000 * 6;
  const date = new Date(ts);

  let roundNum =
    (date.getUTCFullYear() % 100) * 10000 +
    date.getUTCMonth() * 100 +
    date.getUTCDate();
  let timePassed = 0;
  let duration = 3600 * 24;
  if (context.network.id === 1) {
    roundNum =
      date.getUTCFullYear() * 10000 +
      date.getUTCMonth() * 100 +
      date.getUTCDate();
    timePassed +=
      date.getUTCHours() * 3600 +
      date.getUTCMinutes() * 60 +
      date.getUTCSeconds();
    // } else if(context .network.id === 31337 || context.network.id === 1337) {
    //   duration = 60;
    //   roundNum = roundNum * 10000 + date.getUTCHours() * 100 + date.getUTCSeconds();
    //   timePassed += date.getUTCSeconds();
  } else {
    duration = 600;
    roundNum =
      roundNum * 10000 +
      date.getUTCHours() * 100 +
      Math.floor(date.getUTCMinutes() / 10);
    timePassed +=
      Math.floor(date.getUTCMinutes() % 10) * 60 + date.getUTCSeconds();
  }

  return [roundNum, duration - timePassed];
}

export function getFurballIntervals(
  fb: IFurballBase,
  interval: number,
  reduce = 0,
): number {
  const furballLast = Math.max(fb.last, fb.birth);
  const est = Math.max(0, secSinceBlock(furballLast) / interval - reduce);

  // If the estimate is close, use it. If far off, fall back on server's interval count (may be stale tho)
  return Math.abs(fb.intervals - est) < 0.5 ? est : fb.intervals;
}
