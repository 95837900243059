import * as React from 'react';

function SvgIconBossHistoryPlayed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-boss-history-played_svg__a'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      {...props}>
      <defs>
        <style>{'.icon-boss-history-played_svg__b{fill:#410cd6}'}</style>
      </defs>
      <path
        className='icon-boss-history-played_svg__b'
        d='M4.34 6.43s.01.09.05.13l1.78 1.75L9.6 3.26l-.43-.64s-.07-.06-.12-.07c0 0-.02-.01-.03-.02L3.18.05a.175.175 0 00-.17.03s-.07.1-.05.16L4.34 6.4s0 .02.01.03zM19.78 17.95l-2.44-2.94.98-.81.03-.02c.4-.34.67-.71.77-1.08.13-.44.02-.88-.3-1.27a.16.16 0 00-.23-.02l-2.03 1.69-2.82-4.15-3.25 3.2 3.31 3.25-1.88 1.56a.16.16 0 00-.02.23c.32.39.73.57 1.19.52.39-.04.82-.24 1.23-.58l1.03-.85 2.44 2.94c.17.2.4.32.66.35.26.02.51-.05.71-.22l.51-.42c.19-.17.31-.4.34-.66a.962.962 0 00-.22-.71z'
      />
      <path
        className='icon-boss-history-played_svg__b'
        d='M1.42 11.81l2.03 1.69 7.38-10.89s.07-.06.12-.07c0 0 .02-.01.03-.02L16.82.05c.06-.02.12-.01.17.03s.07.1.05.16L15.66 6.4s0 .02-.01.03c0 .05-.01.09-.05.13l-9.38 9.23 1.88 1.56c.07.06.08.16.02.23-.32.39-.73.57-1.19.52-.39-.04-.82-.24-1.23-.58l-1.03-.85-2.44 2.94c-.17.2-.4.32-.66.35a.962.962 0 01-.71-.22l-.51-.42c-.19-.17-.31-.4-.34-.66-.02-.26.05-.51.22-.71l2.44-2.94-.98-.81-.03-.02c-.4-.34-.67-.71-.77-1.08-.13-.44-.02-.88.3-1.27a.16.16 0 01.23-.02z'
      />
    </svg>
  );
}

export default SvgIconBossHistoryPlayed;
