import React, { FunctionComponent } from 'react';
import {
  Theme,
  Container,
  TextField,
  Grid,
  Button,
  LinearProgress,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  PendingRewardRequestInput,
  useGivePendingRewardMutation,
} from '../../components/schema';
import ItemAutocomplete from './ItemAutocomplete';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IGiftPlayer {}

const defaultItem: PendingRewardRequestInput = {
  itemId: 0,
  stack: 1,
};

export const GiftPlayer: FunctionComponent<IGiftPlayer> = (props) => {
  const classes = useStyles();
  const { log } = useFurComponent(GiftPlayer);
  const [playerId, setPlayerId] = React.useState<string | null>(null);
  const [notificationTitle, setNotificationTitle] = React.useState<string>(
    "You've received an Airdrop",
  );
  const [rewards, setRewards] = React.useState<PendingRewardRequestInput[]>([
    { ...defaultItem },
  ]);
  const [givePendingRewards, { loading, error, data }] =
    useGivePendingRewardMutation();
  const realRewards = rewards.filter((r) => r.itemId > 0 && r.stack > 0);

  function updateItem(rew: PendingRewardRequestInput, idx: number): void {
    const updatedRewards = [...rewards];
    if (rew.stack > 0) updatedRewards[idx] = rew;
    else updatedRewards.splice(idx, 1);

    const lastReward =
      updatedRewards.length <= 0
        ? undefined
        : updatedRewards[updatedRewards.length - 1];
    if (!lastReward || (lastReward.itemId > 0 && lastReward.stack > 0)) {
      updatedRewards.push({ ...defaultItem });
    }
    setRewards(updatedRewards);
  }

  async function sendGift() {
    try {
      await givePendingRewards({
        variables: { playerId, notificationTitle, rewards: realRewards },
      });
    } catch (e) {
      log.error(e, 'send gift error');
    }
  }

  function renderReward(
    rew: PendingRewardRequestInput,
    idx: number,
  ): React.ReactNode {
    return (
      <>
        <Grid item xs={8}>
          <ItemAutocomplete
            itemId={rew.itemId}
            setItemId={(itemId) => updateItem({ ...rew, itemId }, idx)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            id='outlined-number'
            label='Quantity'
            type='number'
            value={rew.stack}
            onChange={(e) =>
              updateItem(
                { ...rew, stack: Number.parseInt(e.target.value) },
                idx,
              )
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={2}></Grid>
      </>
    );
  }

  return (
    <Container className={clsx(classes.root)}>
      <TextField
        style={{ width: '100%' }}
        value={playerId}
        onChange={(e) => setPlayerId(e.target.value)}
        placeholder={'Player / Wallet ID (blank=self)'}
      />
      <TextField
        style={{ width: '100%' }}
        value={notificationTitle}
        onChange={(e) => setNotificationTitle(e.target.value)}
        placeholder={'Message to appear in notifications'}
      />
      <br />
      <Grid container spacing={2}>
        {rewards.map(renderReward)}
        <Grid item xs={6}>
          {loading && <LinearProgress color={'secondary'} />}
          {error}
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={loading || realRewards.length <= 0}
            onClick={() => sendGift()}
            color={'secondary'}>
            Send Gift
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GiftPlayer;
