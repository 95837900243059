import * as React from 'react';

function SvgZoneIconsQuests(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='icon-quests_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 20 20'
      xmlSpace='preserve'
      {...props}>
      <path
        className='icon-quests_svg__st0'
        d='M11.6 17.3c0-.4.2-.8.4-1.2H1.2c-.2.3-.4.7-.4 1.2 0 1.1.9 1.9 1.9 1.9h10.8c-1.1 0-1.9-.8-1.9-1.9z'
      />
      <path
        className='icon-quests_svg__st0'
        d='M17.3.8H6.2c-1.3 0-2.3 1-2.3 2.3v12.3h9.8c.2 0 .4.2.4.4s-.2.4-.4.4c-.7 0-1.2.5-1.2 1.2s.5 1.2 1.1 1.2h2.1c.4-.4.6-.9.6-1.5V2.7c0-.6.5-1.2 1.2-1.2.6 0 1.2.5 1.2 1.2s-.5 1.2-1.2 1.2c-.2 0-.4.2-.4.4s.2.4.4.4c1.1 0 1.9-.9 1.9-1.9-.2-1.2-1-2-2.1-2zm-11.1 4h3.1c.2 0 .4.2.4.4s-.3.4-.5.4h-3c-.2 0-.4-.2-.4-.4s.1-.4.4-.4zm7.6 5.4H6.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h7.7c.2 0 .4.2.4.4s-.2.4-.5.4zm0-2.3H6.2c-.2 0-.4-.2-.4-.4s.2-.4.4-.4h7.7c.2 0 .4.2.4.4s-.2.4-.5.4z'
      />
    </svg>
  );
}

export default SvgZoneIconsQuests;
