import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Dog = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M73.49 242.61s-19.03 11.93-34.4-7.51l8.3-.62s-20.86-11.59-15.27-38.6l6.68 5.97s1.21-38.42 32.48-40.67 35.52 22.81 35.52 22.81-21.63-1.21-31.99 18.53c-4.14 7.88-5.39 17.18-3.8 26.15l2.48 13.94z'
    />
    <path
      opacity={0.4}
      fill='#FFF'
      d='M71.29 161.18c-25.34 1.82-30.94 27.4-32.16 37.03-2.57-.13-5.55.17-6.84-2.04l-.17-.29c-5.59 27.01 15.27 38.6 15.27 38.6l-8.3.62c9.01 11.4 19.28 12.01 26.24 10.57-4.37-.12-11.15-.06-14.2-5.73-1.28-2.38 4.67-3.81 2.53-6.1-6.46-6.92-10.84-18.14-8.01-34.53 1.85-10.7 6.24-29.08 26.8-33.29 14.57-2.98 22.7-.08 27.76 5.05-5.08-5.73-13.87-10.97-28.92-9.89z'
    />
    <path
      opacity={0.19}
      d='M65.54 245.62c4.79-1.03 7.95-3.01 7.95-3.01l-2.47-13.95c-1.59-8.97 6.73-29.46 10.86-37.34 0 0-29.4 15.6-16.34 54.3z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M73.49 242.61s-19.03 11.93-34.4-7.51l8.3-.62s-20.86-11.59-15.27-38.6l6.68 5.97s1.21-38.42 32.48-40.67 35.52 22.81 35.52 22.81-21.63-1.21-31.99 18.53c-4.14 7.88-5.39 17.18-3.8 26.15l2.48 13.94z'
    />
  </svg>
);

export default Dog;
