import React from 'react';
import { IFurballSnack } from '../../wallet/WalletTypes';
import SvgSnackButterballs from '../../assets/SvgSnackButterballs';
import SvgSnackFurgonzola from '../../assets/SvgSnackFurgonzola';
import SvgSnackMeatLeg from '../../assets/SvgSnackMeatLeg';
import { IFoodItem } from './FoodTypes';
import { secSinceBlock } from '../../wallet';

export function getSnack(hex: number): IFurballSnack {
  const snackId = hex;
  const fed = 0;
  const energy = 0;
  const count = 1;
  if (snackId === 0x100)
    return {
      snackId,
      fed,
      count,
      energy,
      happiness: 15,
      furCost: 2500,
      duration: 24,
    };
  if (snackId === 0x200)
    return {
      snackId,
      fed,
      count,
      energy,
      happiness: 20,
      furCost: 7500,
      duration: 72,
    };
  if (snackId === 0x300)
    return {
      snackId,
      fed,
      count,
      energy,
      happiness: 25,
      furCost: 15000,
      duration: 24 * 7,
    };
  throw `Invalid snack ${hex}`;
}

export function getFoodItem(
  snackId: number | IFurballSnack,
  count?: number,
): IFoodItem {
  const snack = typeof snackId === 'number' ? getSnack(snackId) : snackId;
  snackId = snack.snackId;
  const intervalDuration = snack.count * snack.duration;
  const clockDuration = intervalDuration * 3600;
  const base = { snack: { ...snack }, intervalDuration, clockDuration };
  if (count) base.snack.count = count;
  if (snackId === 0x100)
    return { ...base, name: 'Butterballs', icon: <SvgSnackButterballs /> };
  if (snackId === 0x200)
    return { ...base, name: 'Furgonzola', icon: <SvgSnackFurgonzola /> };
  if (snackId === 0x300)
    return { ...base, name: 'Meat Leg', icon: <SvgSnackMeatLeg /> };
  throw new Error(`Invalid snack=>food ${snackId}`);
}

export function getFoodClockTimeRemaining(
  food: IFoodItem,
  interval: number,
): number {
  const elapsed = secSinceBlock(food.snack.fed, interval);
  return food.clockDuration - elapsed;
}

export const allSnackIds = [0x100, 0x200, 0x300];
