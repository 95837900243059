import React, { FunctionComponent } from 'react';
import { OutboundLink } from '../Navigation';
import WalletContext from '../../wallet/WalletContext';
import { BigNumber } from 'ethers';
import { IBlockchainNetwork } from '../../wallet/WalletTypes';

interface IOpenSeaLink {
  tokenId?: string;
  className?: string;
  children?: React.ReactNode;
}

function getSubdomain(network?: IBlockchainNetwork) {
  const subdomain = network?.subdomain ?? '';
  return ['rinkeby', 'goerli'].includes(subdomain) ? 'testnets.' : '';
}

export function useOpenSeaLink(tokenId?: string): string {
  if (!tokenId) return 'https://opensea.io/collection/furballs-com-official';
  const context = React.useContext(WalletContext);
  const contractAddress = context?.network.contracts?.furballs.address;
  const tokenNum = BigNumber.from(tokenId);
  return `https://${getSubdomain(
    context?.network,
  )}opensea.io/assets/${contractAddress}/${tokenNum.toString()}`;
}

export const OpenSeaLink: FunctionComponent<IOpenSeaLink> = (props) => {
  const { children, tokenId, className } = props;
  const link = useOpenSeaLink(tokenId);

  return (
    <OutboundLink to={link} className={className}>
      {children ?? 'View on OpenSea'}
    </OutboundLink>
  );
};

export default OpenSeaLink;
