import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SnaggleTooth = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m194.91 165.88-1.8 5.25c-.2.82-1 1.35-1.83 1.21l-2.44-.39c-.83-.13-1.42-.88-1.36-1.72v-5.54'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M184.07 162.08s11.26 7.51 23.51 0M202.59 152.7c.69 2.4-2.03 6.09-6.22 6.1-4.19 0-6.91-3.7-6.22-6.1'
    />
  </svg>
);

export default SnaggleTooth;
