import * as React from 'react';

function SvgLootGhostPotion(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='loot-ghost-potion_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 200 153.66'
      xmlSpace='preserve'
      {...props}>
      <style>
        {
          '.loot-ghost-potion_svg__st2,.loot-ghost-potion_svg__st7{fill:none;stroke:#000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}.loot-ghost-potion_svg__st7{stroke-width:3}.loot-ghost-potion_svg__st26{fill:#fff}'
        }
      </style>
      <path
        d='M111.66 39.81H88.34L82.75 10c10.92-5.33 23.58-5.33 34.51 0l-5.6 29.81z'
        fill='#fbca9f'
      />
      <path
        d='M103.82 6.19c4.61.45 9.16 1.72 13.44 3.81l-5.59 29.81h-9.76'
        fill='#e0b48d'
      />
      <path
        className='loot-ghost-potion_svg__st2'
        d='M111.66 39.81H88.34L82.75 10c10.92-5.33 23.58-5.33 34.51 0l-5.6 29.81z'
      />
      <path
        d='M71.65 140.08c1.15 4.41 6.2 7.58 12.05 7.58h32.6c5.86 0 10.9-3.17 12.05-7.58l16.27-63.57-4.13-11.45c-3.36-9.32-14.42-15.77-27.02-15.77v-7.92h5.69c2.1 0 3.8-1.31 3.8-2.92V24.41c0-1.61-1.7-2.92-3.8-2.92H80.84c-2.1 0-3.8 1.31-3.8 2.92v14.05c0 1.61 1.7 2.92 3.8 2.92h5.69v7.92c-12.6 0-23.66 6.45-27.02 15.77l-4.13 11.45 16.27 63.56z'
        fill='#435f80'
      />
      <path
        className='loot-ghost-potion_svg__st26'
        d='M138.96 98.64s-23.11 24.22-44.13 0C77.57 78.75 57.72 85.66 57.72 85.66l13.93 54.42c1.15 4.41 6.19 7.58 12.05 7.58h32.6c5.86 0 10.9-3.17 12.05-7.58l10.61-41.44z'
      />
      <path
        d='M86.6 140.08L73.31 88.17c-1.96-7.66-1.57-15.57 1.15-23.1 2.02-5.6 12.07-15.77 12.07-15.77-12.6 0-23.66 6.45-27.02 15.77l-4.13 11.45 16.27 63.57c1.15 4.41 6.19 7.58 12.05 7.58h14.95c-5.85-.02-10.9-3.19-12.05-7.59z'
        opacity={0.16}
        fill='#fff'
      />
      <path
        className='loot-ghost-potion_svg__st7'
        d='M80.49 77.66l9.11 67.96M119.51 77.66l-9.11 67.96'
      />
      <path
        className='loot-ghost-potion_svg__st2'
        d='M71.65 140.08c1.15 4.41 6.2 7.58 12.05 7.58h32.6c5.86 0 10.9-3.17 12.05-7.58l13.26-51.78a39.516 39.516 0 00-1.11-23.23c-3.36-9.32-14.42-15.77-27.02-15.77v-7.92h5.69c2.1 0 3.8-1.31 3.8-2.92V24.41c0-1.61-1.7-2.92-3.8-2.92H80.84c-2.1 0-3.8 1.31-3.8 2.92v14.05c0 1.61 1.7 2.92 3.8 2.92h5.69v7.92c-12.6 0-23.66 6.45-27.02 15.77A39.52 39.52 0 0058.4 88.3l13.25 51.78z'
      />
      <circle
        className='loot-ghost-potion_svg__st26'
        cx={100}
        cy={89.44}
        r={3.38}
      />
      <circle
        className='loot-ghost-potion_svg__st26'
        cx={85.05}
        cy={65.45}
        r={4.82}
      />
      <circle
        className='loot-ghost-potion_svg__st26'
        cx={93.97}
        cy={55.9}
        r={2.08}
      />
      <circle
        className='loot-ghost-potion_svg__st26'
        cx={126.36}
        cy={100.22}
        r={2.08}
      />
      <path
        d='M140.49 65.07c-3.36-9.32-14.42-15.77-27.02-15.77v-7.92h5.69c2.1 0 3.8-1.31 3.8-2.92V25.2c0-1.61-1.7-2.92-3.8-2.92H106.8c2.1 0 9.05 1.14 9.05 8.29v10.8c0 .4-3.16 9.84-3.16 9.84 7.39 2.58 13.17 7.56 15.44 13.85 2.72 7.53 3.11 15.44 1.15 23.1l-13.29 51.91c-1.15 4.41-6.2 7.58-12.05 7.58h12.37c5.86 0 10.9-3.17 12.05-7.58l16.27-63.57-4.14-11.43z'
        opacity={0.48}
        fill='#435f80'
      />
      <path className='loot-ghost-potion_svg__st7' d='M141.62 76.51H58.38' />
    </svg>
  );
}

export default SvgLootGhostPotion;
