import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';

import { useAppDispatch, useWalletSelector } from '../../hooks';
import { useHistory, useLocation } from 'react-router';
import { allFormations, FormationDefinition } from './FormationLibrary';

import {
  usePlayerPartiesLazyQuery,
  useBossesLazyQuery,
  useSetpartyMutation,
  useForfeitBattleMutation,
  FormationPosition,
  PlayerPartyFragment,
  usePartyLazyQuery,
  PlayerPartyFurballsFragment,
} from '../../components/schema';
import { usePlayersReadyFurballs } from '../../wallet';

import { AlertContext, ALERT_TYPES } from '../../components/Alert/AlertContext';
import { useFurballHelpers } from '../../components/Furballs';
import { BossKeyDefinition, defaultBossKeys } from '../../components/Loot';
import { getPartyFromSlug, ILobbyParams } from './Lobby';
import { slugify, useFurComponent } from '../../utils';
import { IFurball } from '../../wallet/WalletTypes';
import WalletSlice from '../../wallet/WalletSlice';

export enum PartySize {
  ONE = 1,
  THREE = 3,
  FIVE = 5,
}

export interface IFurballPartyMember {
  id: string;
  position: FormationPosition;
  zone: number | null;
}

export const useBossBattleState = () => {
  const { log } = useFurComponent(useBossBattleState.name);
  const dispatch = useAppDispatch();
  const [isNew, setisNew] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isForfeiting, setisForfeiting] = useState<boolean>(false);
  const [partyErrors, setPartyErrors] = useState<string>();
  const [partyId, setPartyId] = useState<string>();

  const [partyName, setPartyName] = useState<string>();
  const [partySize, setPartySize] = useState<PartySize>(5);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [params, setParams] = useState<ILobbyParams>({});

  const { addAlert } = useContext(AlertContext);
  const { refreshFurballs } = useFurballHelpers(log);

  type IMembers = IFurballPartyMember[];

  const [partyMembers, setPartyMembers] = useState<IMembers>([]);
  const [currentFormationId, setCurrentFormationId] = useState<number>(0);
  const [selectedFurballs, setSelectedFurballs] = useState<
    IFurballPartyMember[]
  >([]);

  const [toSwap, setToswap] = useState<IFurballPartyMember>();
  const [isSwapping, setIsSwapping] = useState<number>();

  const playerId = useWalletSelector((s) => s.contents?.id);
  const history = useHistory();
  const location = useLocation();

  const [loadPlayerParties, { data, refetch, loading: loadingParties }] =
    usePlayerPartiesLazyQuery({
      variables: { playerId: playerId || '' },
    });
  const [
    reloadPartyFurballs,
    { data: reloadedPartyData, loading: reloadingParty },
  ] = usePartyLazyQuery();
  // const reloadedParty = reloadedPartyData?.party;
  // const [reloading, setReloading] = React.useState(false);

  const [loadBosses, { data: bosses, loading: loadingBosses }] =
    useBossesLazyQuery();
  const [setParty, { loading: settingParties }] = useSetpartyMutation();
  const [forfeit, { loading: forfeitting }] = useForfeitBattleMutation();
  const playerParties = data?.playerParties;
  const [allParties, setAllParties] = React.useState<PlayerPartyFragment[]>(
    playerParties ?? [],
  );
  const loading =
    loadingParties ||
    settingParties ||
    loadingBosses ||
    forfeitting ||
    reloadingParty;

  React.useEffect(() => {
    if (playerParties) {
      setAllParties(playerParties);
    } else if (!allParties) {
      setAllParties([]);
    }
  }, [playerParties]);

  const currentFormation =
    allFormations?.[partySize]?.[currentFormationId] ??
    allFormations?.[partySize]?.[0];

  const currentParty = React.useMemo(() => {
    const ret = isNew
      ? undefined
      : getPartyFromSlug(params.party ?? partyId ?? '', allParties);
    // log.info('[BOSS] party', ret?.name, params.party, partyId, isNew, params);
    return ret;
  }, [allParties, partyId, params.party, isNew]);

  function reloadParty(partyId: string) {
    // setReloading(true);
    void doReloadParty(partyId);
  }

  async function doReloadParty(partyId: string) {
    try {
      log.info('[BOSS] reloading party', partyId);
      const partyRes = await reloadPartyFurballs({ variables: { partyId } });
      const reloadedParty = partyRes.data?.party;
      if (reloadedParty) onPartyUpdated(reloadedParty);
      else log.warn('[BOSS] failed to reload party', partyRes);
    } catch (e) {
      log.warn('[BOSS] reload party', e);
    }
    // setReloading(false);
  }

  // React.useEffect(() => {
  //   if (!reloading) return;
  //   setReloading(false);
  //   log.info('[BOSS] reloaded party', reloadedParty);
  //   onPartyUpdated(reloadedParty);
  // }, [reloadedParty, reloading, reloadingParty]);

  function onPartyUpdated(party?: PlayerPartyFurballsFragment): void {
    if (!party) return;

    // we need to modify furball storage directly to make UI update
    const partyFurballs: IFurball[] = (
      party.partyMembers?.map((pm) => pm?.entity as IFurball) ?? []
    )
      .filter((i) => !!i)
      .map((f) => f as IFurball);
    dispatch(WalletSlice.actions.updateFurballs(partyFurballs));

    const idx = allParties.findIndex((p) => p.id === party.id);
    const parties = [...allParties];
    if (idx >= 0) {
      parties.splice(idx, 1, party);
    } else {
      parties.push(party);
    }
    setAllParties(parties);

    log.info(
      'updated party',
      party.id,
      'with',
      partyFurballs.length,
      'furballs',
    );
  }

  const getFormationId = useCallback(
    (formation: FormationDefinition, partySize: PartySize) => {
      const formationId = allFormations?.[partySize]?.findIndex((item) => {
        const itemString = item.sort().toString();
        const formationString = [...formation].sort().toString();

        return itemString === formationString;
      });

      return formationId;
    },
    [allFormations],
  );

  // const ids = selectedFurballs?.map((item) => item.id);
  const furballs = usePlayersReadyFurballs();
  const currentPartyFurballIds =
    currentParty?.partyMembers?.filter((m) => !!m).map((m) => m?.id) ?? [];
  const currentPartyFurballs = furballs.filter(
    (fb) => fb && currentPartyFurballIds.includes(fb.id),
  );

  const hasRequiredKey = useCallback(
    (keyId: BossKeyDefinition) => {
      let count = 0;

      const inventories = currentPartyFurballs.map(
        (item) => item?.inventory.items,
      );

      inventories.forEach((inventory) => {
        const hasKey = inventory?.find((item) => item.itemId === keyId);
        hasKey && count++;
      });

      return count === partySize;
    },
    [currentPartyFurballs, partySize],
  );

  const forfeitBattle = async (battleId?: string) => {
    const party = currentParty;

    const id = battleId || party?.currentBattleId;

    if (!id) {
      return;
    }

    setisForfeiting(true);

    try {
      await forfeit({
        variables: { id },
      });

      await refreshFurballs([id]);
      await loadPlayerParties({ fetchPolicy: 'network-only' });
      setisForfeiting(false);
    } catch (error) {
      log.error(error);
      setisForfeiting(false);
    }
  };

  function doSetPartyId(id?: string) {
    setPartyId(id === 'new' ? undefined : id);
  }

  const mutateParty = async (
    type?: 'delete' | 'setDefault',
    customId?: string,
  ) => {
    setPartyErrors(undefined);

    if (!playerId) {
      log.warn('[PARTY] cannot mutate without a player!');
      return;
    }

    const deleteParty = type === 'delete';
    const isSetDefault = type === 'setDefault' && !!partyId;
    const members = isSetDefault
      ? (currentParty?.partyMembers as IFurballPartyMember[]) || []
      : partyMembers;
    const id = isNew ? null : currentParty?.id ?? partyId ?? params.party;
    const name = isNew && !!partyName ? partyName : null;
    const slotNumber = isSetDefault ? 0 : null;
    const furballIds = members.map((item) => item.id);
    const formationPositions = members.map((item) => item.position);

    const args = {
      id: customId || (id ?? null),
      name,
      formationPositions,
      furballIds,
      slotNumber,
      playerId,
      deleteParty,
    };

    deleteParty && setIsDeleting(true);

    try {
      const response = await setParty({ variables: args });

      if (response) {
        const oldMembers = currentParty?.partyMembers;
        const newMemberIds = partyMembers.map((member) => member!.id);
        const oldMemberIds = oldMembers?.map((member) => member!.id) ?? [];
        await refreshFurballs([...newMemberIds, ...oldMemberIds]);
      }

      const refetchData = await loadPlayerParties({
        fetchPolicy: 'network-only',
      });

      setIsDeleting(false);

      const id = response?.data?.setParty.id;
      const defaultId = refetchData?.data?.playerParties?.[0]?.id || 'new';

      const action = isNew
        ? 'created'
        : !type
        ? 'updated'
        : type === 'delete'
        ? 'delete'
        : 'set as default';

      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `${response.data?.setParty.name} party ${action} successfully`,
      });

      !!customId && customId === partyId
        ? doSetPartyId(defaultId)
        : /*
        is custom id is equal to current party id
        it means the current party has been mutated (deleted)
        so switch to default id
        */
        !!customId
        ? null
        : isNew
        ? history.replace(`/boss/party/${id}`)
        : deleteParty
        ? history.replace(`/boss/party/${defaultId}`)
        : null;
    } catch (error: any) {
      setPartyErrors(
        error.message ??
          'Oops! Somthing went wrong, please reload and try again.',
      );
      setIsDeleting(false);
    }
  };

  const handleSelect = (furball: IFurballPartyMember) => {
    const isPresent = selectedFurballs
      .map((item) => item.id)
      .includes(furball.id);

    if (isPresent) {
      const index = selectedFurballs.findIndex(
        (item) => item.id === furball.id,
      );

      const copy = [...selectedFurballs];
      copy.splice(index, 1);

      setSelectedFurballs(copy);
      setToswap(undefined);
    } else {
      setSelectedFurballs([...selectedFurballs, furball]);
    }
  };

  const handleSwap = async (
    replacerFurball: IFurballPartyMember,
    targetSlotIndex: number,
    emptySlot: boolean,
  ) => {
    if (!toSwap) {
      setToswap(replacerFurball);
    } else if (replacerFurball.id === toSwap.id) {
      setToswap(undefined);
    } else {
      setIsSwapping(targetSlotIndex);
      const replacerIndex = partyMembers.findIndex(
        (item) => item.id === replacerFurball.id,
      );

      const toSwapIndex = partyMembers.findIndex(
        (item) => item.id === toSwap.id,
      );

      const membersCopy = [...partyMembers];

      if (emptySlot) {
        membersCopy.splice(toSwapIndex, 1, {
          id: toSwap.id,
          zone: toSwap.zone,
          position: replacerFurball.position,
        });
      } else {
        membersCopy.splice(toSwapIndex, 1, {
          id: replacerFurball.id,
          zone: replacerFurball.zone,
          position: toSwap.position,
        });

        membersCopy.splice(replacerIndex, 1, {
          id: toSwap.id,
          zone: toSwap.zone,
          position: replacerFurball.position,
        });
      }

      await animateSwap(targetSlotIndex, toSwap.id);

      setPartyMembers(membersCopy);
      setToswap(undefined);
      setIsSwapping(undefined);
    }
  };

  const animateSwap = async (targetSlotIndex: number, toswapId: string) => {
    const selector1 = `[data-party-slot-index='${targetSlotIndex}']`;
    const selector2 = `[data-party-slot-id='${toswapId}']`;

    const slot1: HTMLDivElement | null = document.querySelector(selector1);
    const slot2: HTMLDivElement | null = document.querySelector(selector2);

    if (!slot1 || !slot2) return;

    let distX = 0,
      distY = 0;

    try {
      const { x: x1, y: y1 } = slot1.getBoundingClientRect();
      const { x: x2, y: y2 } = slot2.getBoundingClientRect();

      distX = x1 - x2;
      distY = y1 - y2;
    } catch (e) {
      log.error(e, 'swap');
      return;
    }

    // add transitions
    slot1.style.transition = 'transform 0.4s linear';
    slot2.style.transition = 'transform 0.4s linear';

    return new Promise((resolve) => {
      // add translates
      slot1.style.transform = `translate(${-distX}px, ${-distY}px)`;
      slot2.style.transform = `translate(${distX}px, ${distY}px)`;

      setTimeout(() => {
        // remove transitions
        slot1.style.transition = 'none';
        slot2.style.transition = 'none';

        resolve('');

        // remove translates
        slot1.style.transform = 'translate(0)';
        slot2.style.transform = 'translate(0)';
      }, 500);
    });
  };

  useEffect(() => {
    setPartySize(5);
    setPartyMembers([]);
    setSelectedFurballs([]);

    setHasChanges(false);
    setPartyName(undefined);
    setToswap(undefined);
    setisNew(params.party === 'new');

    refetch && refetch();
  }, [params.party]);

  useEffect(() => {
    loadPlayerParties();
    loadBosses();
  }, [playerId, params.party]);

  useEffect(() => {
    const parties = data?.playerParties;

    if (
      !isNew &&
      parties?.length === 0 &&
      location.pathname.startsWith('/boss/lobby')
    ) {
      history.replace('/boss/party/new');
      return;
    }

    if (!!parties && !partyId && parties?.length > 0) {
      doSetPartyId(params.party || params.party || parties[0].id);
    }

    if (isNew) {
      log.info('[PARTY] new', partyId, params.party);
      return;
    }

    const currentParty = parties?.find(
      (item) => item.id === params.party || slugify(item.name) === params.party,
    );

    if (currentParty) {
      const size = currentParty.size;
      const members = currentParty.partyMembers;
      const formation = currentParty.formation.positions;

      const formationId = getFormationId(formation, size);

      size && setPartySize(size);
      members && setSelectedFurballs(members as IFurballPartyMember[]);

      const defaultId = isNew || formationId < 0 ? 0 : formationId;
      setCurrentFormationId(defaultId);
    }
  }, [data, isNew, params]);

  useEffect(() => {
    if (currentParty) {
      setPartySize(currentParty.size);
      setSelectedFurballs(currentParty.partyMembers as IFurballPartyMember[]);
      setPartyMembers(currentParty.partyMembers as IMembers);
    }
  }, [currentParty, partyId, params]);

  useEffect(() => {
    if (!isNew) return;

    const newParty = partyMembers.slice(0, partySize);

    setCurrentFormationId(0);
    setSelectedFurballs(newParty);
    setPartyMembers(newParty);
  }, [partySize]);

  useEffect(() => {
    const invalidPositionPresent = selectedFurballs.find(
      (item) => !currentFormation.includes(item.position),
    );

    const skipPositionCheck =
      !isNew &&
      !hasChanges &&
      !invalidPositionPresent &&
      partyMembers.length === 0;

    if (skipPositionCheck) {
      setPartyMembers(selectedFurballs);
      return;
    }

    const newSlots: FormationDefinition = [];
    const occupiedMembers = selectedFurballs.filter((item) =>
      currentFormation.includes(item.position),
    );
    const occupiedSlots = occupiedMembers.map((i) => i.position);
    const furballsWithPosition = selectedFurballs.map((furball, i) => {
      const index = occupiedMembers.findIndex(
        (item) => item.position === furball.position,
      );

      const posessesDuplicatePosition =
        index !== -1 && occupiedMembers[index].id !== furball.id;

      const alreadyInFormation = posessesDuplicatePosition
        ? false
        : occupiedSlots.includes(furball.position);

      const allFilledSlots = [...occupiedSlots, ...newSlots];
      const nextAvailableSlot = currentFormation?.find(
        (item) => !allFilledSlots.includes(item),
      );

      if (!alreadyInFormation && nextAvailableSlot) {
        newSlots.push(nextAvailableSlot);
      }

      return {
        id: furball.id,
        zone: furball.zone,
        position: !!alreadyInFormation
          ? furball.position
          : !!nextAvailableSlot
          ? nextAvailableSlot
          : undefined,
      };
    });

    const validMembers = furballsWithPosition.filter(
      (item) => !!item.position && currentFormation.includes(item.position),
    );

    setPartyMembers(validMembers as any);
  }, [selectedFurballs, currentFormationId, partySize]);

  useEffect(() => {
    const party = currentParty;

    const savedIdsAndPositions = party?.partyMembers?.map(
      (item) => `${item?.id}${item?.position}`,
    );

    const currentIdsAndPositions = partyMembers.map(
      (item) => `${item?.id}${item?.position}`,
    );

    const saved = savedIdsAndPositions?.toString();
    const current = currentIdsAndPositions?.toString();

    const hasChanges = saved !== current;
    !!current && setHasChanges(hasChanges);
  }, [partyMembers, currentFormationId]);

  useEffect(() => {
    if (!partyErrors) return;

    const timeout = setTimeout(() => {
      setPartyErrors(undefined);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [partyErrors]);

  return {
    isNew,
    setisNew,
    partySize,
    partyErrors,
    setPartySize,
    currentFormationId,
    setCurrentFormationId,
    selectedFurballs,
    setSelectedFurballs,
    partyId,
    partyMembers,
    setPartyMembers,
    loading,
    mutateParty,
    handleSelect,
    allFormations,
    partyName,
    setPartyName,
    currentFormation,
    isDeleting,
    hasChanges,
    allParties,
    setAllParties,
    toSwap,
    setToswap,
    isSwapping,
    handleSwap,
    forfeitBattle,
    isForfeiting,
    hasRequiredKey,
    bossKeys: defaultBossKeys,
    bosslist: bosses?.topBossBattles,
    paramId: params.party,
    fallBackId: partyId || allParties?.[0]?.id || 'new',
    currentParty,
    setPartyId: doSetPartyId,
    onPartyUpdated,
    reloadParty,
    setParams,
    currentPartyFurballs,
  };
};

const BossBattleContext = createContext(
  {} as ReturnType<typeof useBossBattleState>,
);

export const BossBattleProvider: React.FC = ({ children }) => {
  return (
    <BossBattleContext.Provider value={useBossBattleState()}>
      {children}
    </BossBattleContext.Provider>
  );
};

export const useBossBattle = (params?: ILobbyParams) => {
  const ctxt = useContext(BossBattleContext);

  React.useEffect(() => {
    if (params) ctxt.setParams(params);
  }, [params]);

  return ctxt;
};
