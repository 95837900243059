import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import SvgIconNotification from '../../assets/SvgIconNotification';

import clsx from 'clsx';

import { useFurComponent } from '../../utils';
import { useNotifications } from './NotificationsContext';
import NotificationsList from './NotificationsList';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    padding: '0 0 0 24px',
    marginLeft: '10px',
    display: 'grid',
    placeItems: 'center',
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  },

  wrap: {
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
  },

  notificationIcon: {
    width: '18px',
  },

  notificationsEmpty: {
    opacity: '0.6',
    pointerEvents: 'none',
  },

  notificationDrawer: {
    position: 'absolute',
    padding: '0',
    top: 'calc(100% + 1.7rem)',
    right: '-16px',
    minWidth: '20rem',
    backgroundColor: theme.palette.common.white,
    zIndex: 10,
    boxShadow: '0px 15px 25px 0px rgba(20, 20, 0, 0.2)',
    transform: (props: any) => `translateX(${props.visible ? 0 : '120%'})`,
    transition:
      'opacity 0.25s 0.1s, transform 0.25s 0.1s, box-shadow 0.2ms 0.1ms',
  },

  notificationIndicator: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    borderRadius: '100%',
    fontSize: '0.5rem',
    fontWeight: 600,
    display: 'grid',
    placeItems: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.light,
    top: '-0.3rem',
    right: '-0.3rem',
    userSelect: 'none',
  },
}));

interface INotificationIcon {}

const NotificationIcon: React.FunctionComponent<INotificationIcon> = (
  props,
) => {
  const { log } = useFurComponent(NotificationIcon);
  const [visible, setVisible] = useState(false);
  const classes = useStyles({ visible });
  const {
    isMutating,
    unreadNotifications,
    hasMore,
    isLoading,
    cursor,
    loadUnreadNotifications,
  } = useNotifications();

  useEffect(() => {
    if (!isLoading && hasMore && cursor && visible) {
      log.info('[LOAD] notifications from', cursor);
      loadUnreadNotifications({ variables: { after: cursor, limit: 100 } });
    }
  }, [isLoading, hasMore, visible]);

  return (
    <div
      className={clsx(classes.root, {
        [classes.notificationsEmpty]: unreadNotifications?.length === 0,
      })}>
      <div
        className={classes.wrap}
        onClick={() => (isMutating ? null : setVisible(!visible))}>
        <SvgIconNotification className={classes.notificationIcon} />
        {!!unreadNotifications?.length && (
          <span className={classes.notificationIndicator}>
            {unreadNotifications?.length}
            {hasMore ? '+' : ''}
          </span>
        )}
      </div>
      <NotificationsList
        visible={visible}
        setVisible={setVisible}
        className={classes.notificationDrawer}
        maxHeight={'27.4rem'}
      />
    </div>
  );
};

export default NotificationIcon;
