import { SvgEditionSlot } from '../part';
import * as React from 'react';

const FurdenzaHoodie = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ebe5d9'
      d='M189.92 271.7c1.68-4.92 1.28-9.35 1.28-9.44-.05-1.78 1.47-1.65 1.49-1.65 1.22-.02 1.7.61 1.73 1.78.01.46.09 10.81-6.67 21.64 1.7 2.06 2.79 5.08 2.98 7.91 2.13-.16 4.76-.61 6.74-1.35 4.92-1.82 12.58-5.62 19.86-12.98 8.85-8.95 15.16-21.08 18.88-35.77 1.25-4.93 1.22-12.06 1.12-18.14-.07-4.12.28-13.49.64-14.96-4.3 2.23-32.13 10.05-41.65 10.05-9.52 0-78.56-7.56-78.56-7.56-1.58 16.49-1.56 58.44 35.16 80.46l7.48.14c-.61-.89-1.48-2.22-1.77-3.3l-5.91-19.4c-.34-1.14.05-2 1.2-2.34 1.14-.34 1.63.13 1.97 1.27l1.81 6.56c16.51 6.76 32.22-2.92 32.22-2.92z'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M150.39 228.51c-3.86 2.31-8.48 3.93-13.49 4.88'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M162.93 217.7c-.58 2.91-9.8 9.16-12.54 10.81'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M133.38 233.91c-4.82.55-10.46.61-15.37.18'
    />
    <path
      fill='none'
      stroke='#264b79'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M136.91 233.38c-1.1.21-2.29.38-3.52.53'
    />
    <path
      fill='none'
      stroke='#694e39'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M152.1 248.72a78.215 78.215 0 0 1-10.84 2.84'
    />
    <path
      fill='none'
      stroke='#422f23'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M163.37 243.56c-3.42 2.05-7.21 3.77-11.26 5.16'
    />
    <path
      fill='none'
      stroke='#305f8b'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M174.73 233.77c-3.01 3.84-6.87 7.1-11.36 9.79'
    />
    <path
      fill='none'
      stroke='#fbd364'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M141.26 251.55c-5.56 1.05-12.39 1.58-19.21 1.62'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M180.78 221.89c-1.12 4.76-3.16 8.18-6.05 11.87'
    />
    <path
      fill='none'
      stroke='#fbd364'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M179.86 256.79c-3.26 1.4-6.82 2.49-10.22 3.25'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M169.65 260.03c-4.07.9-7.91 1.31-10.74 1.15'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M188.88 251.2c-2.25 2.18-5.45 4.06-9.01 5.58'
    />
    <path
      fill='none'
      stroke='#694e39'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M200.17 235.73c-2.63 5.65-6.48 10.83-11.29 15.48'
    />
    <path
      fill='none'
      stroke='#7ca9c0'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M158.91 261.18c-1.01-.07-1.98-.08-2.93-.04'
    />
    <path
      fill='none'
      stroke='#d12a2f'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M149.32 262.26c-9.15 2.79-14.29 9.83-15.93 11.1'
    />
    <path
      fill='none'
      stroke='#f9af16'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M155.98 261.14c-2.42.09-4.64.5-6.66 1.12'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M204.25 219.21c-.01 6.25-1.56 11.09-4.08 16.52'
    />
    <path
      fill='none'
      stroke='#b8d9ce'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M214.02 266.01c-1.85-6-2.52-12.81-2.13-19.91'
    />
    <path
      fill='none'
      stroke='#f7b1a1'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M219.81 277.4c-2.57-3.27-4.48-7.12-5.8-11.39'
    />
    <path
      fill='none'
      stroke='#db4f54'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M211.88 246.1c.47-8.69 3.16-20.46 6.49-29.11'
    />
    <path
      fill='none'
      stroke='#fbd364'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M226.95 249.46c-.58-5.83.08-12.17 1.75-18.59'
    />
    <path
      fill='none'
      stroke='#29a691'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M231.34 262.35c-3.62-3.27-3.98-8.75-4.39-12.89'
    />
    <path
      fill='none'
      stroke='#264b79'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M228.7 230.87c1.18-4.55 3.24-9.91 5.67-15.03'
    />
    <path
      fill='#ebe5d9'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M205.36 230.32c4.14-3.46 16.84-12.6 33.96-13.08 16.18-.45 7.91-16.22 5.88-15.36-19.92 8.4-33.12 7.44-40.68 14.4'
    />
    <path
      fill='#ebe5d9'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M205.36 230.32s-18.24-13.44-87.96-12.96c-3.55.02-14.52-3.12-14.76-11.52-.2-7.08 7.67-9.4 10.2-11.4 0 0 .47-4.97 2.64-7.32 4.32-4.68 11.4-5.28 11.4-5.28s6.08 12.66 22.68 21.48c10.55 5.6 25.1 7.52 47.64 10.2 10.08 1.2 7.68 10.32 8.16 16.8z'
    />
    <path d='M178.24 212.44c-32.76-8.04-48.84-25.56-48.84-25.56s-5.4 1.32-5.64 6.84c-.14 3.32 6.15 7.03 9.12 8.52 19.2 9.6 45.36 10.2 45.36 10.2z' />
    <path
      fill='#fcd264'
      d='M215.91 253.32c-.43 0-.86-.11-1.23-.32-5.59-3.16-7.15-11.93-5.05-17.83 2.87-8.09 1.41-13.18 1.39-13.23-.2-.62-.15-1.29.16-1.89.3-.59.82-1.04 1.46-1.24a2.504 2.504 0 0 1 3.15 1.61c.09.26 2.06 6.56-1.44 16.43-1.36 3.83-.33 10.03 2.8 11.8.58.33 1 .86 1.18 1.51.18.64.1 1.32-.23 1.9a2.54 2.54 0 0 1-2.19 1.26z'
    />
    <path d='M213.41 219.76c.6 0 1.16.38 1.36.99.08.25 1.95 6.2-1.44 15.73-1.51 4.24-.38 11.03 3.29 13.1.68.39.93 1.25.54 1.94-.26.46-.74.72-1.24.72-.24 0-.48-.06-.7-.18-4.97-2.81-6.52-11.05-4.57-16.53 3.03-8.54 1.43-13.85 1.41-13.9-.24-.75.17-1.55.91-1.79.14-.06.29-.08.44-.08m0-2.16c-.38 0-.75.06-1.11.18-.91.3-1.65.93-2.08 1.78-.43.84-.51 1.8-.23 2.7.06.23 1.3 5.01-1.38 12.55-2.24 6.31-.51 15.72 5.54 19.14a3.59 3.59 0 0 0 4.88-1.36c.47-.83.59-1.8.33-2.72-.26-.92-.86-1.69-1.69-2.16-2.4-1.35-3.61-6.85-2.31-10.5 3.56-10.04 1.68-16.43 1.45-17.12a3.559 3.559 0 0 0-3.4-2.49z' />
    <path
      fill='#b8d9ce'
      d='M198.74 256.36c-.83-.05-1.58-.51-2-1.23-.18-.3-4.28-7.43-1.47-18.15 1.19-4.54.85-8.27.35-10.59-.52-2.45-1.33-3.91-1.34-3.93-.68-1.19-.26-2.72.93-3.41.38-.22.81-.33 1.25-.33.89 0 1.72.48 2.16 1.25.18.3 4.3 7.58 1.5 18.29-1.19 4.54-.85 8.22-.37 10.52.51 2.41 1.31 3.83 1.32 3.85.33.56.42 1.23.26 1.88-.17.65-.58 1.19-1.16 1.53-.38.23-.82.35-1.27.35l-.16-.03z'
    />
    <path d='M196.45 219.79c.49 0 .97.25 1.23.71.17.29 4.08 7.2 1.38 17.47-1.24 4.74-.89 8.61-.38 11.02.56 2.62 1.43 4.14 1.43 4.15.4.68.17 1.55-.51 1.95-.23.13-.47.2-.72.2h-.09c-.46-.03-.89-.28-1.14-.7-.17-.28-4.05-7.05-1.36-17.33 1.24-4.74.88-8.65.36-11.09-.57-2.66-1.45-4.22-1.45-4.23a1.43 1.43 0 0 1 .52-1.94c.25-.15.49-.21.73-.21m0-2.16c-.63 0-1.24.17-1.79.48-1.71.98-2.3 3.17-1.32 4.88 0 0 .73 1.35 1.22 3.61.47 2.21.8 5.76-.34 10.1-1.19 4.55-1.34 9.19-.42 13.42.72 3.32 1.87 5.34 2 5.55.6 1.03 1.67 1.68 2.86 1.76.08 0 .15.01.23.01.64 0 1.26-.17 1.81-.49 1.7-1 2.27-3.2 1.27-4.9 0 0-.71-1.3-1.18-3.51-.46-2.18-.78-5.69.35-10.02 1.19-4.55 1.33-9.21.4-13.49-.73-3.35-1.88-5.4-2.01-5.62a3.53 3.53 0 0 0-3.08-1.78z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M117.4 217.36s-4.8 57.24 40.2 76.2'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M156.45 274.37c10.86 4.02 24.96 2.93 35-2.81'
    />
    <path
      fill='none'
      stroke='#7ca9c0'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M203.03 279.64c.78 2.63 1.88 5.61 3.41 8.68'
    />
    <path
      fill='none'
      stroke='#305f8b'
      strokeWidth={10}
      strokeMiterlimit={10}
      d='M201.14 270.01s.22 4.03 1.89 9.64'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M189.39 293.86c2.97 0 5.93-.51 8.71-1.55 9.93-3.68 31.1-15.4 39.87-50.01 1.83-7.21.89-17.99 1.05-25.07'
    />
    <path d='M223.9 202.79s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57zM114.68 194.4s3.58 10.68 27.54 15.31c0 0-25.6-.59-31.05-13.17l3.51-2.14z' />
  </svg>
);

export default FurdenzaHoodie;
