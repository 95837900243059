import React, { FunctionComponent, useState } from 'react';
import { cdnRoot } from '../../../theme';
import useBossLobbyStyles from '../useBossFightsStyles';
import { Maybe } from '../../schema';

interface IGamePlayerAvatar {
  src: Maybe<string> | undefined;
  size?: number | string;
}

export function getAvatarUrl(src: Maybe<string> | undefined) {
  return src && src.length > 0
    ? src
    : `${cdnRoot}/images/avatar_placeholder.png`;
}

const GamePlayerAvatar: FunctionComponent<IGamePlayerAvatar> = (props) => {
  const { src, size } = props;

  const bossStyles = useBossLobbyStyles();
  const [srcError, setSrcError] = useState(false);

  return (
    <img
      className={bossStyles.img}
      style={{ height: size, width: size }}
      src={getAvatarUrl(srcError ? undefined : src)}
      onError={() => setSrcError(true)}
    />
  );
};

export default GamePlayerAvatar;
