import { SvgEditionSlot } from '../part';
import * as React from 'react';

const StabbyThing = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ca362c'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='m318.33 102.11-3.06 10.54c-1.93 6.64-6.21 12.45-12.22 15.88a25.97 25.97 0 0 1-11.88 3.42c-2.2.08-3.84-2-3.23-4.11l16.6-57.14-7.67-2.23-16.6 57.14c-.61 2.11-3.12 2.99-4.93 1.74-3.4-2.34-6.21-5.49-8.2-9.26-3.23-6.12-3.74-13.31-1.81-19.96l3.88-13.35-7.67-2.23-4.05 13.92c-1.28 4.4-1.66 8.93-1.13 13.45.51 4.36 1.85 8.54 3.98 12.41 2.13 3.87 4.93 7.23 8.34 10.01 2.01 1.63 4.17 3.02 6.47 4.14l-52.99 157.13c-.78 2.44.57 5.05 3.02 5.83l9.94 3.08c2.88.91 6.03.27 8.32-1.71a8.45 8.45 0 0 0 2.64-4.25l41.97-156.67c2.01.12 4.02.07 6.04-.17 4.36-.51 8.54-1.85 12.41-3.98 3.87-2.13 7.23-4.93 10.01-8.34a33.86 33.86 0 0 0 6.26-11.96l3.2-11.01a3.02 3.02 0 0 0-2.06-3.75l-1.95-.57c-1.55-.45-3.18.44-3.63 2z'
    />
    <path
      fill='#a32a22'
      d='m235.46 300.35 45.58-157.2a8.084 8.084 0 0 1 5.34-5.48l-42.46 158.66c-.01 0-1.93 6.36-8.46 4.02z'
    />
    <path
      opacity={0.35}
      d='m261.495 79.996 6.722 1.954-2.512 8.642-6.722-1.953zM324 107l-7-2 2-5 8-1z'
    />
    <path
      fill='#d9433a'
      d='M269.12 67.68c-2.48 3.6-7.73 9.97-16.33 14.47 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-4.85-8.41-5.87-16.6-6.03-20.96-.03-.9-1.17-1.23-1.67-.5z'
    />
    <path
      fill='#fa4f45'
      d='M270.14 66.11s-4.72 9.43-17.35 16.03c0 0 7.73 5.04 12.57 1.59'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M270.14 66.11c-1.82 2.96-7.29 10.77-17.35 16.03 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-5.67-9.83-6.11-19.36-6.05-22.83 0-.35-.45-.48-.63-.19z'
    />
    <path
      fill='#d9433a'
      d='M326.96 84.46c-2.47 3.6-7.73 9.98-16.34 14.49 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-4.86-8.43-5.88-16.63-6.03-20.99-.04-.89-1.17-1.22-1.66-.49z'
    />
    <path
      fill='#fa4f45'
      d='M327.24 84.94s-4 7.4-16.62 14.01c0 0 7.73 5.04 12.57 1.59'
    />
    <path
      opacity={0.35}
      d='m297.513 67.128 6.722 1.954-2.512 8.642-6.722-1.953z'
    />
    <path
      fill='#d9433a'
      d='M304.85 53.48c-2.12 3.31-7.53 10.5-16.98 15.45 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-5.33-9.24-6.04-18.21-6.06-22.14 0-.54-.7-.75-.99-.3z'
    />
    <path
      fill='#fa4f45'
      d='M305.22 52.9s-4.72 9.43-17.35 16.03c0 0 7.73 5.04 12.57 1.59'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M305.22 52.9c-1.82 2.96-7.29 10.77-17.35 16.03 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-5.67-9.83-6.11-19.36-6.05-22.83 0-.36-.45-.49-.63-.19zM327.43 83.72c-1.82 2.96-7.29 10.77-17.35 16.03 0 0 7.73 5.04 12.57 1.59 2.23 5.51 11.46 5.4 11.46 5.4-5.67-9.83-6.11-19.36-6.05-22.83 0-.35-.45-.48-.63-.19z'
    />
  </svg>
);

export default StabbyThing;
