import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Monocle = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.2} cx={236} cy={151} rx={19} ry={20} />
    <path
      fill='#ebb82a'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M252.76 179.56c-1.46 0-2.64-1.18-2.64-2.64v-22.68c0-1.46 1.18-2.64 2.64-2.64 1.46 0 2.64 1.18 2.64 2.64v22.68c0 1.46-1.18 2.64-2.64 2.64z'
    />
    <path
      fill='#ebb82a'
      d='M233.9 119.9c-12.89 0-23.34 10.45-23.34 23.34s10.45 23.34 23.34 23.34 23.34-10.45 23.34-23.34-10.45-23.34-23.34-23.34z'
    />
    <circle fill='#b8e7fc' cx={233} cy={143} r={16} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={233}
      cy={143}
      r={23}
    />
    <path
      fill='#caedfc'
      d='m218.02 138.54 14.46 21.2a16.74 16.74 0 0 0 4.84-.29l-17.26-25.3c-.88 1.34-1.58 2.82-2.04 4.39zM245.95 154.6l-18.23-26.72a16.631 16.631 0 0 0-6.6 4.82l17.93 26.29c2.67-.88 5.03-2.4 6.9-4.39z'
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={233}
      cy={143}
      r={16}
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m255 162-5 6M255 169l-5 6'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M149.41 144.88c.46 1.65 1.35 3.18 2.59 4.52 3.09 3.35 8.32 5.56 14.25 5.56 8.31 0 15.25-4.33 16.84-10.08'
    />
    <path d='M159.79 155.38s-.72 3.67-7.17 5.88c0 0 3.97-2.49 5.08-6.72M156.58 154.4s-.87 4.07-8.6 6.51c0 0 4.75-2.75 6.09-7.44' />
    <path d='M153.54 151.93s0 5.44-9.04 7.47c0 0 6.6-2.77 6.91-8.3' />
    <path d='M151.1 149.15s0 6.97-11.98 6.77c0 0 8.7-1.45 9.68-8.17M213.24 120.54s6.96-6.45 13.7-6.93c3.07-.22 4.11-.06 4.81 1.53.44 1-1.18 2.44-3.21 2.28-4.05-.32-4.1-1.63-15.3 3.12z' />
  </svg>
);

export default Monocle;
