import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Dog = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill={props.renderer.color1}
      d='M171.34 105.34c1.28-.57-3.96-.6-7.32-3-2.59-1.85-3.47-5.26-7.44-6.96-9.24-3.96-17.57 3.47-22.12 9.98-6.71 9.6-17.38 5.91-21.42 13.52-6.16 11.61 6.39 17.97-1.76 31.75-.56.95-.78 2.08-.54 3.16.42 1.95 2.31 4 9.34 1.08 8.44-3.5 24.38-3.57 28.59-23.6 2.51-12.01 7.43-19.09 22.67-25.93z'
    />
    <path
      opacity={0.12}
      d='M170.03 104.67c-14.33 4.22-19.98 10.26-23.81 20.42-6.27 16.63-21.43 16.96-32.22 18.09-.29 2.26-1.09 4.7-2.72 7.45-.56.95-.78 2.08-.54 3.16.42 1.95 2.31 4 9.34 1.08 8.44-3.5 24.38-3.57 28.59-23.6 2.52-12 7.44-19.08 22.68-25.92.54-.26-.12-.4-1.32-.68z'
    />
    <path
      fill='#FFF'
      d='M170.3 105.82c.34-.16.69-.32 1.04-.48.7-.31-.56-.46-2.39-.93.51.46.95.93 1.35 1.41z'
    />
    <path
      opacity={0.4}
      fill='#FFF'
      d='M138.61 108.84c5.49-5.08 14.65-10.4 24.1-7.68-1.72-1.88-2.91-4.4-6.13-5.78-9.24-3.96-17.57 3.47-22.12 9.98-5.55 7.94-13.81 6.79-18.79 10.46 6.38-2.33 15.7-.28 22.94-6.98z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M171.34 105.34c1.28-.57-3.96-.6-7.32-3-2.59-1.85-3.47-5.26-7.44-6.96-9.24-3.96-17.57 3.47-22.12 9.98-6.71 9.6-17.38 5.91-21.42 13.52-6.16 11.61 6.39 17.97-1.76 31.75-.56.95-.78 2.08-.54 3.16.42 1.95 2.31 4 9.34 1.08 8.44-3.5 24.38-3.57 28.59-23.6 2.51-12.01 7.43-19.09 22.67-25.93z'
    />
    <path
      fill={props.renderer.color1}
      d='M221.37 104.73c-1.21-.54 3.74-.57 6.92-2.83 2.44-1.75 3.28-4.97 7.03-6.58 8.73-3.74 16.6 3.28 20.9 9.43 6.34 9.07 16.42 5.58 20.24 12.77 5.82 10.97-6.04 16.98 1.66 30 .53.9.74 1.96.51 2.98-.4 1.84-2.18 3.78-8.82 1.02-7.97-3.31-23.03-3.37-27.01-22.3-2.38-11.34-7.03-18.03-21.43-24.49z'
    />
    <path
      opacity={0.12}
      d='M222.61 104.09c13.54 3.99 18.88 9.7 22.49 19.29 5.92 15.71 20.25 16.02 30.45 17.09.28 2.13 1.03 4.44 2.57 7.04.53.9.74 1.96.51 2.98-.4 1.84-2.18 3.78-8.82 1.02-7.97-3.31-23.03-3.37-27.01-22.3-2.38-11.34-7.03-18.03-21.43-24.49-.52-.22.1-.36 1.24-.63z'
    />
    <path
      fill='#FFF'
      d='M222.35 105.18c-.32-.15-.65-.3-.98-.45-.66-.3.53-.44 2.26-.88-.48.43-.9.88-1.28 1.33z'
    />
    <path
      opacity={0.4}
      fill='#FFF'
      d='M252.29 108.04c-5.19-4.8-13.84-9.83-22.77-7.26 1.63-1.78 2.75-4.15 5.8-5.46 8.73-3.74 16.6 3.28 20.9 9.43 5.25 7.5 13.05 6.41 17.75 9.88-6.03-2.2-14.83-.26-21.68-6.59z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M221.37 104.73c-1.21-.54 3.74-.57 6.92-2.83 2.44-1.75 3.28-4.97 7.03-6.58 8.73-3.74 16.6 3.28 20.9 9.43 6.34 9.07 16.42 5.58 20.24 12.77 5.82 10.97-6.04 16.98 1.66 30 .53.9.74 1.96.51 2.98-.4 1.84-2.18 3.78-8.82 1.02-7.97-3.31-23.03-3.37-27.01-22.3-2.38-11.34-7.03-18.03-21.43-24.49z'
    />
  </svg>
);

export default Dog;
