import React, { FunctionComponent, useContext, useState } from 'react';
import { CircularProgress, Theme, Typography } from '@mui/material';
import {
  MoveZoneRequestInput,
  MoveZonesResponseFragment,
  RealmType,
  useMoveZonesMutation,
} from '../schema';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletSlice from '../../wallet/WalletSlice';
import { IFurball } from '../../wallet/WalletTypes';
import {
  ILootFoundFurball,
  LootFoundContext,
} from '../../pages/Play/LootFoundContext';
import {
  getFormattedZoneName,
  getRealmName,
  getZoneName,
  isPassiveEarnZone,
  ZONE_NUM_BATTLE,
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_EXPLORE,
  ZONE_NUM_TOWN,
  ZONE_NUM_VOYAGE,
} from './ZoneHelpers';
import { makeStyles } from '@mui/styles';
import { cdnRoot } from '../../theme';
import ZoneButton from './ZoneButton';
import themev2, { white } from '../../themev2';
import SvgIconCircleChecked from '../../assets/SvgIconCircleChecked';
import RealmPreviewDialog from './Realms/RealmPreviewDialog';
import { VOYAGE_MINIMUM_LVL } from './ZoneConstants';
import ShopSnacksDialog from './ShopSnacksDialog';
import { useFurballs, useLoadedFurballs } from '../../wallet';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { getTimeAgo } from '../../utils';
import { fireSpendWFurEvent } from '../../Analytics';
import _ from 'lodash';
import { getInventoryQuantities } from '../Furballs';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '200px',
    position: 'relative',
    margin: '13px 0',
    borderRadius: '8px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px',
    display: 'flex',

    ['@media (max-width: 700px)']: {
      height: '285px',
      flexDirection: 'column',
    },
  },
  mainSection: {
    position: 'relative',
    height: '100%',
    width: '100%',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',

    ['@media (max-width: 700px)']: {
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '8px',
    },
  },
  titleCont: {
    textAlign: 'left',
    backgroundColor: theme.palette.secondary.light,
    height: '100%',
    minWidth: '240px',
    padding: '20px',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',

    '& h3': {
      color: 'white !important',
      textTransform: 'uppercase !important',
      fontSize: '2rem !important',
    },

    ['@media (max-width: 700px)']: {
      borderTopRightRadius: '8px',
      borderBottomLeftRadius: '0px',
      height: '130px',
      padding: '16px',
    },
  },
  info: {
    ['@media (max-width: 700px)']: {
      display: 'flex',
    },
  },
  infoCont: {
    borderRadius: '4px',
    backgroundColor: white,
    padding: '5px 10px',
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    margin: '15px 0',
    fontWeight: 800,

    '& span': {
      marginLeft: '7.5px',
    },

    ['@media (max-width: 700px)']: {
      width: '155px',
      padding: '3.5px 5px',
      fontSize: '0.85rem !important',
      marginRight: '10px',

      '& svg': {
        height: '22.5px !important',
        width: '22.5px !important',
      },

      '& span': {
        marginLeft: '5px',
      },
    },
  },
  buttonCont: {
    position: 'absolute',
    right: '25px',
    bottom: '25px',
  },
  disabled: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: 1,
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  disabledMsg: {
    color: 'white !important',
    opacity: 0.85,
    margin: '0 6rem !important',
  },
}));

export enum VOYAGE_NOT_ALLOWED_REASON {
  NOT_ENOUGH_FUR = 'NOT_ENOUGH_FUR',
  NOT_HIGH_ENOUGH_FB_LVL = 'NOT_HIGH_ENOUGH_FB_LVL',
}

interface IMoveZonesButton {
  furballIds: string[];
  currentZone: number;
  zoneTarget: number;
  realm?: RealmType;
  onSaved: () => void;
  fromRealmPreview?: boolean;
  canGoOnVoyage?: boolean | VOYAGE_NOT_ALLOWED_REASON;
  closeEarly?: () => void;
}
const FB_MAX_DAILY_BATTLES = 3;
export const FB_MAX_LVL = 200;

const MoveZonesButton: FunctionComponent<IMoveZonesButton> = (props) => {
  const {
    furballIds,
    currentZone,
    zoneTarget,
    realm,
    onSaved,
    fromRealmPreview,
    canGoOnVoyage,
    closeEarly,
  } = props;
  const classes = useStyles();

  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const imgSrc = realm ? realm.toLowerCase() : getFormattedZoneName(zoneTarget);
  const backgroundImage = `url(${cdnRoot}/svgs/background-${imgSrc}.svg)`;
  const title = realm ? getRealmName(realm) : getZoneName(zoneTarget);
  const collect = zoneTarget === currentZone && currentZone !== ZONE_NUM_VOYAGE;
  const fbsNotMaxLvl = getFbsNotMaxLvl(useFurballs(furballIds));
  const furballs = useLoadedFurballs(furballIds);
  const fbsUpgradeRequired =
    furballs.filter((f) => !!f?.pendingUpgrade?.writeBackRequired).length > 0 &&
    zoneTarget === ZONE_NUM_BOSS_FIGHTS;
  const fbsBossBattleCD = haveFbsBossBattleCD();
  const voyageNotAllowed =
    canGoOnVoyage !== undefined && canGoOnVoyage !== true;
  const isDisabled = fbsUpgradeRequired || voyageNotAllowed || fbsBossBattleCD;

  const { setLootFound } = useContext(LootFoundContext);
  const { addAlert } = useContext(AlertContext);

  const dispatch = useAppDispatch();
  const [save, { error, loading }] = useMoveZonesMutation();
  const [realmPreview, setRealmPreview] = useState<boolean>(false);
  const [showSnacks, setShowSnacks] = useState<boolean>(false);

  async function moveZones(
    snacksForFurballs?: { [key: string]: number[] },
    snacksCost?: number,
  ) {
    const moveRequestInputs = furballIds.map((tokenId) => {
      return {
        tokenId,
        zone: zoneTarget,
        snackIds: snacksForFurballs ? snacksForFurballs[tokenId] : null,
        realm,
      } as MoveZoneRequestInput;
    });

    const { data } = await save({
      variables: { requests: moveRequestInputs },
    });

    let wFurAccumulated = 0;
    const updatedZoneFbs: { [key: string]: IFurball } = {};
    const lootFound: ILootFoundFurball[] = [];
    const moves = (data?.moveZones as MoveZonesResponseFragment[]) ?? [];
    moves.forEach(({ furball, items, furGained, furSpent }) => {
      items.forEach(({ itemId }) =>
        lootFound.push({ tokenId: furball.id, lootId: itemId }),
      );

      wFurAccumulated += furGained - furSpent;
      updatedZoneFbs[furball.id] = {
        ...furball,
        inventory: getInventoryQuantities(furball.inventory),
      };
    });

    const furSpent = _.sum(moves.map((m) => m.furSpent));
    if (furSpent > 0) fireSpendWFurEvent(furSpent, 'Move Zones');

    showLoot(lootFound);
    updateAndMoveFurballs(updatedZoneFbs, wFurAccumulated);
    dispatch(
      WalletSlice.actions.setWFurBalance(
        wFurBalance + wFurAccumulated - (snacksCost ?? 0),
      ),
    );
  }

  function updateAndMoveFurballs(fbs: { [p: string]: IFurball }, wFur: number) {
    addAlert({
      type: ALERT_TYPES.SUCCESS,
      message: getAlertText(wFur),
    });

    dispatch(WalletSlice.actions.setFurballs(fbs));
    fromRealmPreview ? !!closeEarly && closeEarly() : onSaved();
  }

  function haveFbsBossBattleCD() {
    const haveCD =
      furballs.filter((fb) => {
        const { days, hours, mins, secs } = getTimeAgo(
          fb?.lastBattleAt as any,
          new Date(),
        );

        if (days >= 1 || hours >= 24) return false;
        const hrsRemain = 24 - hours;
        return hrsRemain > 0;
      }).length > 0;

    return (
      haveCD && !isPassiveEarnZone(currentZone) && isPassiveEarnZone(zoneTarget)
    );
  }

  function showLoot(lootFound: ILootFoundFurball[]) {
    const shouldShowLoot = currentZone === ZONE_NUM_EXPLORE && lootFound.length;
    if (shouldShowLoot) {
      setLootFound(lootFound);
    }
  }

  function handleOnClick() {
    if (currentZone === ZONE_NUM_EXPLORE && fbsNotMaxLvl.length) {
      return setShowSnacks(true);
    }

    if (fromRealmPreview || !realm) {
      return moveZones();
    }

    setRealmPreview(true);
  }

  function getAlertText(wFur: number) {
    if (currentZone === zoneTarget) {
      if (currentZone === ZONE_NUM_EXPLORE) {
        return 'Collected EXP and LOOT from Explore!';
      }

      if (currentZone === ZONE_NUM_BATTLE) {
        return `Collected ${wFur.toLocaleString()} $wFUR from Battle!`;
      }
    }

    return `Moved ${furballIds.length} furball${
      furballIds.length > 1 ? 's' : ''
    } into ${realm ? getRealmName(realm) : getZoneName(zoneTarget)}!`;
  }

  function getFbsNotMaxLvl(furballs: IFurball[]) {
    return furballs.filter((fb) => fb.level !== FB_MAX_LVL);
  }

  function getBg(top: number, bottom: number) {
    return {
      background: `linear-gradient(0deg, rgba(0, 0, 0, ${top}), rgba(0, 0, 0, ${bottom})), ${backgroundImage}`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    };
  }

  function getInfo() {
    if (zoneTarget === ZONE_NUM_EXPLORE) {
      return ['Gain Exp', 'Earn Loot'];
    }

    if (zoneTarget === ZONE_NUM_BATTLE) {
      return ['Earn $wFUR', 'Enter Voyages'];
    }

    if (zoneTarget === ZONE_NUM_TOWN) {
      return ['Equip Items', 'Upgrade Furball'];
    }

    if (zoneTarget === ZONE_NUM_VOYAGE) {
      return ['Earn Boss Keys', 'Win Loot'];
    }

    if (zoneTarget === ZONE_NUM_BOSS_FIGHTS) {
      return ['Earn Dust + FUR', 'Win Leaderboards'];
    }

    return ['Earn Boss Keys', 'Win Loot'];
  }

  function renderRealmPreview() {
    if (!realm) return null;

    return (
      <RealmPreviewDialog
        furballIds={furballIds}
        currentZone={currentZone}
        realmToEnter={realm}
        open={realmPreview}
        onClose={() => setRealmPreview(false)}
        onAccept={moveZones}
        closeEarly={closeEarly}
      />
    );
  }

  function renderShopSnacksDialog() {
    if (!showSnacks) return null;

    return (
      <ShopSnacksDialog
        furballs={fbsNotMaxLvl}
        collect={zoneTarget === currentZone}
        onClose={() => setShowSnacks(false)}
        onSave={moveZones}
      />
    );
  }

  function renderLoading() {
    if (!loading) return null;

    return (
      <div className={classes.disabled} style={getBg(0.7, 0.7)}>
        <CircularProgress size={60} color={'secondary'} />
      </div>
    );
  }

  function getDisabledMessage() {
    if (voyageNotAllowed) {
      return canGoOnVoyage === VOYAGE_NOT_ALLOWED_REASON.NOT_ENOUGH_FUR
        ? 'Not enough $wFUR to start voyage!'
        : `Furball must be minimum lvl ${VOYAGE_MINIMUM_LVL} to start voyage!`;
    }

    if (fbsUpgradeRequired) {
      return 'Write-Back Required, Move into Town to schedule!';
    }

    if (fbsBossBattleCD) {
      return `Cannot move from ${getZoneName(currentZone)} to ${getZoneName(
        zoneTarget,
      )} because of boss fight cooldown!`;
    }

    return '';
  }

  function renderDisabledZone() {
    return (
      <div className={classes.disabled} style={getBg(0.85, 0.85)}>
        <Typography variant={'h4'} className={classes.disabledMsg}>
          {getDisabledMessage()}
        </Typography>
      </div>
    );
  }

  function renderTitle() {
    return (
      <div className={classes.titleCont}>
        <Typography variant={'h3'}>{title}</Typography>
        <div className={classes.info}>
          {getInfo().map((info) => {
            return (
              <div key={info} className={classes.infoCont}>
                <SvgIconCircleChecked
                  height={30}
                  width={30}
                  fill={themev2.palette.primary.main}
                />
                <span>{info}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={classes.root}>
        {renderLoading()}
        {isDisabled && renderDisabledZone()}
        {renderTitle()}
        <div className={classes.mainSection} style={getBg(0.2, 0.1)}>
          <div className={classes.buttonCont}>
            <ZoneButton
              onClick={handleOnClick}
              text={collect ? 'Collect' : 'Enter'}
              collect={collect}
              width={'13rem'}
              loading={loading}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      {renderRealmPreview()}
      {renderShopSnacksDialog()}
    </>
  );
};

export default MoveZonesButton;
