import React, { FunctionComponent } from 'react';
import { Container, Grid } from '@mui/material';
import useBossLobbyStyles from '../useBossFightsStyles';
import { LeaderboardStat, LeaderboardTimeframe, RealmType } from '../../schema';
import { getBosses } from '../../../utils';
import BossLeaderboard from './BossLeaderboard';
import { IPendingRewards } from '../../../wallet/WalletTypes';

export interface IBossLeaderboard {
  leaderboardStat: LeaderboardStat;
  name: string;
  realm: RealmType;
}

interface IBossLeaderboards {
  // onLeaderboardPage?: boolean;
  timeframe?: LeaderboardTimeframe;
  pendingRewards: IPendingRewards[];
}

const BossLeaderboards: FunctionComponent<IBossLeaderboards> = (props) => {
  const { pendingRewards } = props;
  const bossStyles = useBossLobbyStyles();
  const bosses = getBosses();

  // if we want to toggle season/weekly in the future
  const timeframe = props?.timeframe ?? LeaderboardTimeframe.Weekly;
  function renderBossLeaderboards() {
    return bosses.map((boss) => (
      <BossLeaderboard
        key={boss.name}
        boss={boss}
        timeframe={timeframe}
        pendingRewards={pendingRewards}
      />
    ));
  }

  return (
    <Container className={bossStyles.bossLobby}>
      <Grid container className={bossStyles.bossLobbyGrid}>
        {renderBossLeaderboards()}
      </Grid>
    </Container>
  );
};

export default BossLeaderboards;
