import * as React from 'react';
import { useWindowSize } from '../../components/useWindowSize';
import useHeroStyles from '../../components/useHeroStyles';
import { makeStyles } from '@mui/styles';
import { Container, Theme } from '@mui/material';
import clsx from 'clsx';
import SignupPromptCard from './SignupPromptCard';
import SvgLogo from '../../assets/SvgLogo';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    minHeight: 'calc(100vh)',
    maxHeight: 'calc(100vh)',
    paddingTop: 50,
  },
}));
interface ISignupFurballs {}

const SignupFurballs: React.FunctionComponent<ISignupFurballs> = (props) => {
  const size = useWindowSize();
  const hero = useHeroStyles({
    aspectRatio: size.width / Math.max(size.height, 1),
  });
  const classes = useStyles();

  return (
    <div className={clsx(hero.background, classes.root)}>
      <Container maxWidth={'sm'}>
        <SvgLogo />
        <SignupPromptCard />
      </Container>
    </div>
  );
};

export default SignupFurballs;
