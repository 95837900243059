import React, { FunctionComponent, useState } from 'react';
import { Avatar, Fab, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useAppDispatch, useWalletSelector } from '../../hooks';
import { useHistory } from 'react-router-dom';
import {
  ItemDefinitionFragment,
  useUpgradeLootMutation,
} from '../../components/schema';

import SvgIconPower from '../../assets/SvgIconPower';
import OkDialog from '../../components/OkDialog';
import WalletSlice from '../../wallet/WalletSlice';
import { getItemDef } from '../../components/BossFights/BossRewards/bossRewardsUtils';
import { cdnRoot } from '../../theme';
import { useFurComponent } from '../../utils/furComponent';
// import { cdnRoot } from '../../theme';

type Props = {
  lootId: string;
  lootItem: ItemDefinitionFragment;
  chances: number;
  setLoading: (loading: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  marketButton: {
    width: '100%',
  },

  lootIcon: {
    width: '5rem',
    height: '5rem',
  },

  dialogContent: {
    width: '25rem',
    maxWidth: '25rem',
  },

  upgradedItemIcon: {
    width: '7.5rem',
    height: '7.5rem',
    display: 'grid',
    placeItems: 'center',

    '& > div': {
      padding: '1rem',
      width: '100% !important',
      height: '100% !important',
    },
  },
}));

const UpgradeButton: FunctionComponent<Props> = (props) => {
  const { setLoading, lootId, chances, lootItem } = props;

  const { log } = useFurComponent(UpgradeButton);
  const wFurCost = chances * 500;
  const playerId = useWalletSelector((s) => s.contents?.id);
  const wFurRemaining = useWalletSelector(
    ({ contents }) => contents?.wFur ?? 0,
  );
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [notEnoughError, setNotEnoughError] = useState(false);
  const [upgraded, setUpgraded] = useState<boolean | undefined>(undefined);
  const [upgradedItem, setUpgradedItem] = useState<ItemDefinitionFragment>();

  const [upgradeLoot, { loading, data }] = useUpgradeLootMutation();

  const hasEnoughWrappedFur = wFurCost <= wFurRemaining;
  const canRun = !loading && !!playerId;

  function handleClose() {
    if (upgradedItem) {
      history.push('/account');
    } else {
      setUpgraded(undefined);
    }
  }

  async function instantUpgrade() {
    if (!playerId) return;

    setUpgraded(undefined);
    setUpgradedItem(undefined);
    setLoading(true);

    try {
      const { data } = await upgradeLoot({
        variables: {
          itemId: lootId,
          chances,
        },
      });

      if (data?.upgradeLoot) {
        dispatch(WalletSlice.actions.setWFurBalance(wFurRemaining - wFurCost));
        setUpgraded(true);
      }

      if (data?.upgradeLoot.upgradedItem) {
        const itemDef = getItemDef(data?.upgradeLoot.upgradedItem.itemId);
        setUpgradedItem(itemDef);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      log.error(error);
    }
  }

  function upgrade() {
    log.info('upgrading');

    if (!hasEnoughWrappedFur) {
      setNotEnoughError(true);
      return;
    }

    void instantUpgrade();
  }

  return (
    <React.Fragment>
      <Fab
        variant={'circular'}
        color={'primary'}
        style={{ marginTop: '6px' }}
        disabled={!canRun}
        onClick={upgrade}>
        <SvgIconPower style={{ width: '32px', height: '32px' }} />
      </Fab>

      <OkDialog
        open={upgraded !== undefined}
        onClose={() => handleClose()}
        title={upgradedItem ? 'Upgrade Successful!' : 'Not This Time...'}>
        <Grid container padding='1.5rem'>
          {upgradedItem ? (
            <Grid
              container
              mb='1.5rem'
              justifyContent='center'
              rowGap='0.5rem'
              className={classes.dialogContent}
              alignItems='center'>
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'>
                <div className={classes.upgradedItemIcon}>
                  <Avatar
                    src={`${cdnRoot}/images/icons/boss_loot/${upgradedItem.icon}.png`}
                  />
                </div>
              </Grid>
              <Typography px='2rem' textAlign='center'>
                <b>
                  {lootItem.rarity} {lootItem.name}
                </b>{' '}
                has been Upgraded to{' '}
                <b>
                  {data?.upgradeLoot?.upgradedItem?.rarity} {upgradedItem.name}!
                </b>
              </Typography>
            </Grid>
          ) : (
            <Grid
              container
              my='0.5rem'
              justifyContent='center'
              className={classes.dialogContent}
              alignItems='center'>
              <Typography>
                You didn't get any upgrades for {lootItem.rarity}{' '}
                {lootItem.name} this time, You may be lucky on the next spin.
                Try again!
              </Typography>
            </Grid>
          )}
        </Grid>
      </OkDialog>

      <OkDialog
        open={notEnoughError}
        onClose={() => setNotEnoughError(false)}
        title={'Not Enough $FUR!'}>
        <Typography variant={'body1'}>
          This costs {wFurCost.toLocaleString()} $FUR, but you have{' '}
          {wFurRemaining.toLocaleString()} $FUR left (after all other purchases
          in this Timekeeper round).
        </Typography>
      </OkDialog>
    </React.Fragment>
  );
};

export default UpgradeButton;
