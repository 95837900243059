import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Theme, Tooltip } from '@mui/material';
import FurballHead from '../../Furballs/FurballHead';
import HorizontalScroller from '../../HorizontalScroller';
import clsx from 'clsx';
import { useLoadedFurballs } from '../../../wallet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '330px',
    position: 'relative',
    margin: '0 auto',
    padding: '0 20px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    height: '47.5px',
    backgroundColor: 'rgba(51, 49, 148, 0.3)',

    ['@media (max-width: 495px)']: {
      width: '225px',
      padding: '0 10px',
      marginBottom: '20px',
    },
  },
  cont: {
    columnGap: '5px',
    display: 'flex',
    maxWidth: '100%',
  },
  btn: {
    border: 'none !important',
    height: '37px !important',
    minWidth: '37px !important',
    width: '37px !important',
    borderRadius: '50% !important',
  },
  selected: {
    backgroundColor: 'rgba(255, 255, 255, 0.2) !important',
  },
}));

interface IUpgradeFooter {
  tokenIds: string[];
  setTokenId: (tokenId: string) => void;
  selectedTokenId: string;
}

export const UpgradeFooter: FunctionComponent<IUpgradeFooter> = (props) => {
  const { tokenIds, setTokenId, selectedTokenId } = props;
  const classes = useStyles();
  const furballs = useLoadedFurballs(tokenIds);

  const [fbIndex, setFbIndex] = useState<number>(0);

  return (
    <div className={classes.root}>
      <HorizontalScroller
        numItems={furballs.length}
        itemIndex={fbIndex}
        setItemIndex={setFbIndex}
        smallNavButtons={true}
        renderItem={(p, i) => {
          const furball = furballs[p];
          if (!furball) return null;
          const tokenId = furball.tokenId;
          const selected = selectedTokenId === tokenId;
          return (
            <Tooltip key={`footer-fb-${tokenId}`} title={`${furball.name}`}>
              <span>
                <Button
                  onClick={() => setTokenId(tokenId)}
                  disabled={selected}
                  className={clsx(classes.btn, {
                    [classes.selected]: selected,
                  })}>
                  <FurballHead tokenId={tokenId} size={35} borderRadius={100} />
                </Button>
              </span>
            </Tooltip>
          );
        }}
        className={classes.cont}
      />
    </div>
  );
};

export default UpgradeFooter;
