import React, { FunctionComponent } from 'react';

import { useWalletSelector } from '../../../hooks';
import { useFuelFactor } from '../../../components/Timekeeper/TimekeeperHooks';
import SvgIconTix from '../../../assets/SvgIconTix';
import BalanceButtonV2 from './BalanceButtonV2';

interface ITixBalanceButton {
  cost?: number;
}

const TixBalanceButtonV2: FunctionComponent<ITixBalanceButton> = (props) => {
  const { cost } = props;
  const fuelFactor = useFuelFactor();
  const balance = useWalletSelector((s) => (s.contents?.tix ?? 0) * fuelFactor);
  const value = cost ?? balance ?? 0;
  const tickets = Math.floor(value / fuelFactor);

  return (
    <BalanceButtonV2
      balance={tickets.toLocaleString()}
      path={'/tix'}
      icon={<SvgIconTix height={25} width={25} fill={'#FFE47C'} />}
    />
  );
};

export default TixBalanceButtonV2;
