import * as React from 'react';

const SvgRedX01 = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 105.7 105.7'
    //style={{
    //enableBackground: "new 0 0 105.7 105.7"
    //}}
    xmlSpace='preserve'
    {...props}>
    <circle
      cx={52.85}
      cy={52.85}
      r={50.85}
      style={{
        fill: '#ff6c63',
        stroke: '#000',
        strokeWidth: 4,
        strokeMiterlimit: 10,
      }}
    />
    <path
      style={{
        fill: '#fff',
        stroke: '#000',
        strokeWidth: 4,
        strokeMiterlimit: 10,
      }}
      d='M74.61 39.71 66 31.09 52.85 44.24 39.7 31.09l-8.61 8.62 13.15 13.14L31.09 66l8.61 8.61 13.15-13.14L66 74.61 74.61 66 61.47 52.85z'
    />
  </svg>
);

export default SvgRedX01;
