import React, { FunctionComponent } from 'react';
import { Button, Container, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { usePageTitle } from '../../../Analytics';
import { usePlayersReadyFurballs } from '../../../wallet';
import BuyKeysDialog from '../../Furverse/BuyKeysDialog';
import AccountHeader from '../../../AccountHeader';
import useCommonStyles from '../../../components/useCommonStyles';
import SvgBossKeyBoneyard from '../../../assets/voyages/SvgRealmBossKeyBoneyard';
import SvgBossKeyTurtleSea from '../../../assets/voyages/SvgRealmBossKeyTurtleSea';
import SvgBossKeyWizardsPath from '../../../assets/voyages/SvgRealmBossKeyWizardsPath';
import { useHistory } from 'react-router-dom';
import { getItemDef } from '../../../components/BossFights/BossRewards/bossRewardsUtils';
import { slugify } from '../../../utils';
import SvgIconWrappedFur from '../../../assets/SvgIconWrappedFur';
import { getFurballInventory } from '../../../components/Furballs';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: 20,
    width: '100%',
    minHeight: 'calc(100vh)',
    maxHeight: 'calc(100vh)',
    color: 'white',
    textAlign: 'center',
  },
  main: {
    paddingTop: 100,
  },
  actionButton: {
    width: '100%',
  },
  paper: {
    padding: 10,
  },
}));

interface ILootMarketSelection {}

export const LootMarketSelection: FunctionComponent<ILootMarketSelection> = (
  props,
) => {
  const classes = useStyles();
  const furballs = usePlayersReadyFurballs();
  const [buyingKeys, setBuyingKeys] = React.useState(false);
  const [buyItemId, setBuyItemId] = React.useState(0);
  const common = useCommonStyles();
  const history = useHistory();

  React.useEffect(() => {
    if (buyItemId > 0) {
      const def = getItemDef(buyItemId);
      if (def) {
        history.push(`/market/loot/browse/${slugify(def.name)}`);
      }
    }
  }, [buyItemId]);

  usePageTitle('Select | Loot Market');

  return (
    <div className={clsx(classes.root, common.furBk)}>
      <Container className={classes.main}>
        <AccountHeader />
        <br />
        <br />
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Typography variant={'h2'}>Buy with wFUR</Typography>
            {/*<Paper className={classes.paper}>*/}
            {/*  <ItemAutocomplete itemId={buyItemId} setItemId={setBuyItemId} />*/}
            {/*  <Typography variant={'subtitle2'} style={{ float: 'right' }}>*/}
            {/*    ...or, just{' '}*/}
            {/*    <Link to={'/market/loot/browse'}>browse around</Link>*/}
            {/*  </Typography>*/}
            {/*  <br />*/}
            {/*</Paper>*/}

            <Button
              variant='contained'
              color={'secondary'}
              className={clsx(common.secondaryAction, classes.actionButton)}
              onClick={() => history.push('/market/loot/browse')}>
              <Typography variant={'h3'}>
                <SvgIconWrappedFur style={{ width: 32 }} /> | Browse Items
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant={'h2'}>Sell for wFUR</Typography>
            <Button
              variant='contained'
              color={'secondary'}
              className={clsx(common.secondaryAction, classes.actionButton)}
              onClick={() => history.push('/market/loot/new')}>
              <Typography variant={'h3'}>
                <SvgIconWrappedFur style={{ width: 32 }} /> | Choose Item
              </Typography>
            </Button>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant={'h2'}>Buy with TIX</Typography>
            <Button
              variant='contained'
              color={'primary'}
              className={clsx(common.actionButton, classes.actionButton)}
              onClick={() => setBuyingKeys(true)}>
              <Typography variant={'h3'}>Boss Keys | </Typography>
              <div style={{ marginLeft: 10, paddingTop: 4 }}>
                <SvgBossKeyTurtleSea style={{ height: 32 }} />
                <SvgBossKeyWizardsPath style={{ height: 32 }} />
                <SvgBossKeyBoneyard style={{ height: 32 }} />
              </div>
            </Button>
          </Grid>
        </Grid>
      </Container>

      <BuyKeysDialog
        open={buyingKeys}
        onClose={() => setBuyingKeys(false)}
        furballs={furballs.map(getFurballInventory)}
      />
    </div>
  );
};

export default LootMarketSelection;
