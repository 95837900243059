import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { IFurballBase } from '../../wallet/WalletTypes';
import { approxTimeSince, useInterval } from '../../wallet';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import useCommonStyles from '../../components/useCommonStyles';
import { expColor, furColor } from '../../theme';
import { ItemStatRow } from '../Stats';
import { getRenderableFurball } from './FurballRenderer';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  footer: {
    backgroundColor: '#000',
    color: '#FFF',
  },
  subtext: {
    fontSize: '1rem',
  },
  expText: {
    color: expColor.light,
  },
  furText: {
    color: furColor.light,
  },
}));

type GeneTrait =
  | 'Tail'
  | 'Weapon'
  | 'Body'
  | 'Ears'
  | 'Tattoo'
  | 'Eyes'
  | 'Mouth'
  | 'Hat'
  | 'Outfit';
interface IFurballGeneTrait {
  trait: string;
  // value: string,
  name: string; // Without modifier
  rarityName: string;
  percentBonus: number;
}

interface IGenePool {
  furball: IFurballBase;
}

type FurballSlotMap = { [key: string]: IFurballGeneTrait };

export const GenePool: FunctionComponent<IGenePool> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { furball } = props;
  const interval = useInterval();
  const timeSince = furball
    ? approxTimeSince(furball.birth, interval)
    : ['', ''];
  // const [boostPercent, setBoostPercent] = React.useState(0);
  // const slots = getGenes(furball?.info.attributes ?? []);
  const boostPercent = furball?.rarity ?? 0;
  const renderer = furball?.tokenId
    ? getRenderableFurball(furball?.tokenId)
    : undefined;
  const slots = renderer?.slots ?? [];

  // function translateRarityBonus(rarityName: string): number {
  //   if (rarityName === "Elite") return 10;
  //   if (rarityName === "Mythic") return 15;
  //   if (rarityName === "Legendary") return 20;
  //   if (rarityName === "Ultimate") return 40;
  //   return 0;
  // }
  //
  // function getGenes(attributes: IFurballAttribute[]): FurballSlotMap {
  //   const ret: FurballSlotMap = {};
  //
  //   attributes.forEach((a) => {
  //     if (slotNames.includes(a.traitType)) {
  //       const value = a.value.toString();
  //       const names = value.split(' (');
  //       const rarityName = names.length > 1 ? names[1].substr(0, names[1].length - 1) : "Common";
  //       const percentBonus = translateRarityBonus(rarityName);
  //       ret[a.traitType] = {
  //         trait: a.traitType,
  //         // value: a.value.toString(),
  //         name: names[0],
  //         rarityName,
  //         percentBonus,
  //       };
  //     }
  //   });
  //   return ret;
  // }

  // React.useEffect(() => {
  //   let totalBonus = 0;
  //   Object.keys(slots).forEach((s) => {
  //     totalBonus += slots[s].percentBonus;
  //   });
  //   setBoostPercent(totalBonus);
  // }, [slots]);

  return (
    <React.Fragment>
      <div className={common.cardDropHeader}>
        <Typography variant={'h5'} className={common.unfocusedText}>
          {timeSince[0]} <span className={classes.subtext}>{timeSince[1]}</span>{' '}
          Old
        </Typography>
      </div>
      <Grid container className={common.paddingTable} spacing={1}>
        {slots.map((slot, i) => {
          if (!slot) return null;
          const raritySuff =
            slot.asset.percentBoost > 0
              ? ` (${
                  slot.asset.name.endsWith(' Power')
                    ? 'SPECIAL'
                    : slot.asset.rarityLevel.toUpperCase()
                })`
              : '';
          return (
            <Grid key={slot.slot.toString()} item xs={12}>
              <ItemStatRow
                odd={i % 2 === 1}
                title={`${slot.slot.toString().toUpperCase()}${raritySuff}`}
                name={slot.asset.name}
                callout={
                  slot.asset.percentBoost > 0
                    ? { value: slot.asset.percentBoost }
                    : undefined
                }
              />
            </Grid>
          );
        })}
        {/*<ItemStatRow title={furball.d} />*/}
      </Grid>
      <Grid container className={classes.footer}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>
            +{boostPercent}% <span className={classes.furText}>$FUR</span> and{' '}
            <span className={classes.expText}>EXP</span>
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GenePool;
