import React, { FunctionComponent } from 'react';
import { Theme, Typography, Button, Grid, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ICloseDialog } from '../../OkDialog';
import { RealmType, useSubmitVoyageEncounterMutation } from '../../schema';

import { IFurball, IVoyage } from '../../../wallet/WalletTypes';
import FurballImg from '../../Furballs/FurballImg';
import useCommonStyles from '../../useCommonStyles';
import RealmHeaderDialog from './RealmHeaderDialog';
import SvgVoyageSpeechBubble from '../../../assets/voyages/SvgVoyageSpeechBubble';
import TimekeeperSlice from '../../Timekeeper/TimekeeperSlice';
import PlayerFurballLink from '../../Players/PlayerFurballLink';

import { purple } from '../../../theme';
import { OutboundLink } from '../../Navigation';
import { useAppDispatch, useWalletSelector } from '../../../hooks';
import WalletSlice from '../../../wallet/WalletSlice';
import { placeholderFurballId } from '../../../wallet';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  decisions: {
    marginTop: theme.spacing(1),
  },
  flip: {
    transform: 'scale(-1, 1)',
  },
  box: {
    backgroundColor: purple.light,
    padding: theme.spacing(2),
    cornerRadius: 4,
    fontSize: '1rem !important',
    lineHeight: '1.2rem !important',
    textAlign: 'center',
    minHeight: 130,
  },
}));

interface IRealmDilemmaPopup extends ICloseDialog {
  realmType: RealmType;
  otherTokenId?: string;
  furball: IFurball;
}

export const RealmDilemmaDialog: FunctionComponent<IRealmDilemmaPopup> = (
  props,
) => {
  const { onClose, open, realmType, otherTokenId, furball } = props;

  const classes = useStyles();
  const common = useCommonStyles();
  const dispatch = useAppDispatch();
  const walletAddress = useWalletSelector((w) => w.address);
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);

  const [submitEncounter, submittedEncounter] =
    useSubmitVoyageEncounterMutation();

  const hasOtherToken = (otherTokenId?.length ?? 0) > 0;
  const tokenId = hasOtherToken ? '' + otherTokenId : placeholderFurballId;

  React.useEffect(() => {
    if (submittedEncounter.data) onClose();
  }, [submittedEncounter.data]);

  async function saveResponse(decision: number) {
    if (!walletAddress) return;
    try {
      const encounter = await submitEncounter({
        variables: { complete: true, tokenId: furball.tokenId, decision },
      });

      const voyageEncounter = encounter?.data?.submitVoyageEncounter;
      if (voyageEncounter) {
        dispatch(
          TimekeeperSlice.actions.updateActiveEncounter(voyageEncounter),
        );

        const { didPlayerComplete, furGained } = voyageEncounter;
        if (didPlayerComplete && furGained > 0) {
          dispatch(WalletSlice.actions.setWFurBalance(wFurBalance + furGained));
        }

        dispatch(
          WalletSlice.actions.setFurball({
            ...furball,
            lastVoyage: {
              ...(furball.lastVoyage as IVoyage),
              activeEncounter: voyageEncounter,
            },
          }),
        );
      }
    } catch (e) {
      console.error(e, 'Encounter');
    }
  }

  return (
    <RealmHeaderDialog
      className={clsx(classes.root)}
      open={open}
      onClose={onClose}
      title={"It's Dangerous to Go Alone"}
      realmType={realmType}
      header={
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FurballImg tokenId={furball.tokenId} size={160} />
          </Grid>
          <Grid item xs={2}>
            <SvgVoyageSpeechBubble />
          </Grid>
          <Grid item xs={4}>
            <FurballImg tokenId={tokenId} size={160} className={classes.flip} />
          </Grid>
        </Grid>
      }>
      <Grid container spacing={2} style={{ maxWidth: 460, marginTop: 10 }}>
        <Grid item xs={12}>
          <Typography variant={'h5'} style={{ textAlign: 'center' }}>
            You've met{' '}
            {otherTokenId ? (
              <PlayerFurballLink tokenId={otherTokenId} />
            ) : (
              'a Furball'
            )}{' '}
            on the path!
            <br />
            Do you want to travel with them for safety?
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'subtitle1'} className={classes.box}>
            If you both decide to travel together, you will EACH get 15k $wFUR.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'subtitle1'} className={classes.box}>
            If the other Furball wants to travel together but you ignore them,
            you get 30k $wFUR.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'subtitle1'} className={classes.box}>
            If you decide to travel together but the other Furball ignores you,
            you get 5k $wFUR.
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant={'subtitle1'} className={classes.box}>
            If you both ignore each other, you both get nothing.
          </Typography>
        </Grid>
        <Grid item xs={1} />
        {!submittedEncounter.loading && (
          <React.Fragment>
            <Grid item xs={5}>
              <Button
                color={'primary'}
                variant={'contained'}
                className={common.actionButton}
                onClick={() => saveResponse(1)}
                // disabled={decision === 1}
                style={{ width: '100%' }}>
                <Typography variant={'h6'}>Travel Together</Typography>
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                color={'secondary'}
                variant={'contained'}
                className={common.secondaryButton}
                style={{ borderColor: 'black', width: '100%' }}
                onClick={() => saveResponse(0)}
                // disabled={decision === 0}
              >
                <Typography variant={'h6'}>Ignore Furball</Typography>
              </Button>
            </Grid>
          </React.Fragment>
        )}
        {submittedEncounter.error && (
          <Grid item xs={10}>
            {submittedEncounter.error.message}
          </Grid>
        )}
        {submittedEncounter.loading && (
          <Grid item xs={10}>
            <LinearProgress color={'secondary'} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant={'subtitle2'} style={{ textAlign: 'center' }}>
            Join the{' '}
            <OutboundLink to={'https://discord.gg/C8YMqa7pwt'}>
              #fur-feed
            </OutboundLink>{' '}
            channel!
          </Typography>
        </Grid>
      </Grid>
    </RealmHeaderDialog>
  );
};

export default RealmDilemmaDialog;
