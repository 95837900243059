import React from 'react';
import { ISpinLootCell } from './VoyageGameRealmSpinner';
import {
  getLootItem,
  getLootSlot,
  LOOT_ID_EXP_1_SHOE,
  LOOT_ID_EXP_2_FRISBEE,
  LOOT_ID_EXP_3_LASER_POINTER,
  LOOT_ID_FUR_1_SPRAY,
  LOOT_ID_FUR_2_TILIZER,
  LOOT_ID_FUR_3_GAINE,
} from '../../Loot';

export const boneyardSpinCells: ISpinLootCell[] = [
  {
    svg: (
      <path
        fill='#F0CF3E'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M682.43 299.6c-4.91 4.54-9.32 9.52-13.2 14.84l171.33 115.93v-215.8c-41.28.03-82.55 15.07-115.01 45.11l-43.12 39.92z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_1_SPRAY)),
    offset: { x: -100, y: 130 },
  },
  {
    svg: (
      <path
        fill='#F283D8'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M649.44 375.1v188.04l191.12-129.32v-3.44L669.23 314.45a102.902 102.902 0 00-19.79 60.65z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_2_FRISBEE)),
    offset: { x: -170, y: -40 },
  },
  {
    svg: (
      <path
        fill='#F0CF3E'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M840.56 433.82L649.44 563.14v73h191.12z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_3_GAINE)),
    offset: { x: -100, y: -150 },
  },
  {
    svg: (
      <path
        fill='#F283D8'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M1031.5 636.14v-73L840.38 433.82v202.32z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_1_SHOE)),
    offset: { x: 60, y: -150 },
  },
  {
    svg: (
      <path
        fill='#F0CF3E'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M840.38 433.82l191.12 129.32V375.1c0-21.97-7.03-43.18-19.79-60.65L840.38 430.38v3.44z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_FUR_2_TILIZER)),
    offset: { x: 140, y: -40 },
  },
  {
    svg: (
      <path
        fill='#F283D8'
        stroke='#72638A'
        strokeWidth={6}
        strokeMiterlimit={10}
        d='M1011.71 314.45c-3.88-5.32-8.29-10.3-13.2-14.84l-43.12-39.92c-32.45-30.04-73.72-45.08-115.01-45.11v215.8l171.33-115.93z'
      />
    ),
    slot: getLootSlot(getLootItem(LOOT_ID_EXP_3_LASER_POINTER)),
    offset: { x: 60, y: 130 },
  },
];
