import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Theme,
  Tabs,
  Tab,
  CircularProgress,
  Grid,
  TextField,
  Button,
  Typography,
  useMediaQuery,
} from '@mui/material';

import clsx from 'clsx';
import theme from '../../../themev2';
import OkDialog from '../../../components/OkDialog';
import AlertProvider from '../../../components/Alert/AlertProvider';
import FullDrawer from '../../../components/Drawer/FullDrawer';
import useCommonStyles from '../../../components/useCommonStyles';

import { TabContext, TabPanel } from '@mui/lab';
import { MarketplaceDrawer } from './RentMarketDrawer';
import { useMarketplaceFilters } from './useMarketplaceFilters';
import { MarketplaceFurball } from './RentMarketFurball';
import { SvgIconSearch } from '../../../assets/SvgIconSearch';
import { useLoadedFurballs } from '../../../wallet';
import { useWalletSelector } from '../../../hooks';
import { ScholarshipPlacement } from '../../../components/Scholarships/ScholarshipPlacement';
import { useAuthContext } from '../../../components/Auth/AuthContext';
import { MarketSort } from './RentMarketSort';
import { RoundOutPartialPartyProvider } from '../RoundOutPartialParty/RoundOutPartialPartyContext';
import RoundOutPartialParty from '../RoundOutPartialParty/RoundOutPartialParty';
import { IFurball } from '../../../wallet/WalletTypes';
import { usePageTitle } from '../../../Analytics';
import { getRealmTypeFromUrl } from '../../../utils';
import { useParams } from 'react-router-dom';
import RentMarketBossBanner from './RentMarketBossBanner';
import { capitalize } from 'lodash';
import RentMarketOnboardProgress, {
  OnboardStep,
} from './RentMarketOnboardProgress';

export const useStyles = makeStyles((theme: Theme) => ({
  headSection: {
    padding: '1rem 0',
    width: 'calc(100% - 16px) !important',
    zIndex: 5,
    [theme.breakpoints.up('md')]: {
      paddingLeft: '2rem',
    },
  },

  filters: {
    '@media (max-width: 1630px)': {
      marginTop: '1.5rem !important',
    },

    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginLeft: '1rem !important',
    },
  },

  tabs: {
    zIndex: 2,

    '& .MuiTabs-indicator': {
      height: '3px',
    },

    [theme.breakpoints.down('lg')]: {
      paddingLeft: '60px',
    },
  },

  tab: {
    border: 'none !important',
    color: '#978EE4 !important',
    fontSize: '1rem !important',

    '&.Mui-selected': {
      color: `${theme.palette.common.white} !important`,
    },
  },

  tabPanel: {
    width: '100%',
    padding: '0px !important',
    paddingRight: '14px !important',
    paddingLeft: '14px !important',
  },

  furballs: {
    width: '100%',
    marginTop: '1rem',
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(12rem, 1fr))',
  },

  loader: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    inset: 0,
    display: 'grid',
    placeItems: 'center',
    zIndex: 2,
    backgroundColor: theme.palette.secondary.light,

    [theme.breakpoints.down('md')]: {
      marginTop: '5rem',
    },
  },

  sensor: {
    height: '6rem',
    paddingTop: '40px',
    display: 'grid',
    placeItems: 'center',
  },

  searchContainer: {
    marginRight: '16px',
    position: 'relative',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  textField: {
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
    },

    '& .MuiInputBase-input': {
      borderRadius: '6px !important',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      padding: '10px 15px',
      minWidth: '15.35rem',
      paddingLeft: '2.5rem',

      '&:focus': {
        borderRadius: '6px !important',
      },
    },
  },

  searchIcon: {
    position: 'absolute',
    left: '1rem',
    top: 0,
    bottom: 0,
    margin: 'auto',
    zIndex: 5,
  },

  sortBy: {
    '& p': {
      fontSize: '0.85rem !important',
    },

    [theme.breakpoints.down('md')]: {
      width: '303px !important',
    },
  },

  placementContainer: {
    borderRadius: '24px',
    maxWidth: '30rem',
    paddingBottom: '0.7rem',
  },

  ctaText: {
    fontSize: '1rem !important',
  },
}));

interface IParams {
  partyId?: string;
  realm?: string;
  onboard?: string;
}

const FurballMarketplace = () => {
  const classes = useStyles();
  const common = useCommonStyles();
  const { partyId, realm, onboard } = useParams<IParams>();
  const {
    filters,
    loading,
    furballs,
    bottomRef,
    bottomRefII,
    tab,
    setTab,
    query,
    setQuery,
    sortBy,
    setSortBy,
    sortOrder,
    setSortOrder,
  } = useMarketplaceFilters();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [showCreateContract, setShowCreateContract] = useState<boolean>(false);

  const addr = useWalletSelector((s) => s.address);
  const account = useWalletSelector((s) => s.contents);
  const accountFurballs = useWalletSelector((s) => s.furballs);
  const accountFurballsArray = Object.values(accountFurballs);
  const { isSignedIn } = useAuthContext();

  const tabOptions = React.useMemo(() => {
    const ret = [{ value: '1', label: 'Browse' }];
    if (isSignedIn) {
      ret.push({ value: '2', label: 'Reserved for Me' });
      ret.push({ value: '3', label: 'My Furballs for Rent' });
    }
    return ret;
  }, [isSignedIn]);

  function getPageTitle(tabValue: string) {
    const section = showCreateContract
      ? 'Create'
      : tabOptions.find((t) => t.value === tabValue)?.label ?? '';
    return `${section} ${
      !!realm
        ? `| ${realm.replace('-', ' ').split(' ').map(capitalize)} ${
            !!onboard ? '| Onboard' : ''
          }`
        : ''
    } | Furball Market`;
  }

  usePageTitle(getPageTitle(tab));

  const getForRentIds = useCallback(() => {
    const ids =
      accountFurballsArray
        ?.filter((item) => {
          const agreement = item?.openRentalAgreement;
          return (
            agreement?.ownerPlayer?.id === account?.id &&
            !agreement?.endedAt &&
            !agreement?.beganAt
          );
        })
        ?.map((item) => item.tokenId) || [];

    return ids;
  }, [accountFurballs, account]);

  const myFurballs = useLoadedFurballs([...getForRentIds()]);

  const searchResults = useMemo(() => {
    const reservedFurballs = furballs.filter((item) => {
      return (
        item.openRentalAgreement?.playerId?.toLowerCase() ===
        addr?.toLowerCase()
      );
    });

    return tab === '2' ? reservedFurballs : furballs;
  }, [furballs, sortBy, tab]);

  useEffect(() => {
    !isSignedIn && setTab('1');
  }, [isSignedIn]);

  return (
    <FullDrawer
      drawer={
        <MarketplaceDrawer
          filters={filters}
          loading={loading}
          tab={tab}
          query={query}
          setQuery={setQuery}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
        />
      }>
      {!!onboard && <RentMarketOnboardProgress step={OnboardStep.RENT} />}
      {loading && searchResults.length === 0 ? (
        <div className={clsx(classes.loader, common.fullHeight)}>
          <CircularProgress size='2.5rem' color='inherit' />
        </div>
      ) : (
        tab !== '3' &&
        searchResults.length === 0 && (
          <div className={clsx(classes.loader, common.fullHeight)}>
            <Typography>No Results</Typography>
          </div>
        )
      )}

      <RoundOutPartialPartyProvider>
        <TabContext value={tab}>
          <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            className={classes.headSection}>
            <Tabs
              value={tab}
              indicatorColor='secondary'
              textColor='secondary'
              variant='scrollable'
              onChange={(e, value) => setTab(value)}
              aria-label='furballs tabs'
              className={classes.tabs}>
              {tabOptions.map((t) => (
                <Tab key={t.label} {...t} className={classes.tab} />
              ))}
            </Tabs>

            <Grid
              item
              container
              className={classes.filters}
              width='fit-content !Important'
              alignItems='center'
              gap='1rem'>
              {tab !== '3' && (
                <>
                  {!isSmallScreen && (
                    <Grid item className={classes.searchContainer}>
                      <SvgIconSearch
                        width='1rem'
                        height='1rem'
                        fill={theme.palette.secondary.main}
                        className={classes.searchIcon}
                      />
                      <TextField
                        className={classes.textField}
                        variant='outlined'
                        color='secondary'
                        id='rent-reserve'
                        value={query}
                        name='reserved'
                        fullWidth
                        placeholder='Search by token id or number'
                        onChange={(e) => setQuery(e.target.value)}
                      />
                    </Grid>
                  )}

                  {!isSmallScreen && (
                    <MarketSort
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      sortOrder={sortOrder}
                      setSortOrder={setSortOrder}
                    />
                  )}

                  {(partyId || !isSmallScreen) && (
                    <RoundOutPartialParty
                      partyId={partyId}
                      realm={realm}
                      onboard={!!onboard}
                    />
                  )}
                </>
              )}

              {tab === '3' && (
                <Button
                  variant='contained'
                  disabled={showCreateContract}
                  onClick={() => setShowCreateContract(true)}
                  startIcon={
                    <Typography variant='h5' lineHeight='0'>
                      +
                    </Typography>
                  }>
                  <Typography variant='h6' className={classes.ctaText}>
                    Create New Rental Agreement
                  </Typography>
                </Button>
              )}
            </Grid>
          </Grid>

          {!!realm && (
            <>
              <RentMarketBossBanner realm={getRealmTypeFromUrl(realm)} />
            </>
          )}

          <TabPanel className={classes.tabPanel} value='1'>
            <div className={classes.furballs}>
              {searchResults?.map((furball, index) => (
                <MarketplaceFurball
                  key={index}
                  id={furball.id}
                  furball={furball}
                  isListing
                />
              ))}
            </div>
            <div ref={bottomRef} className={classes.sensor}>
              {loading && furballs.length > 0 && (
                <CircularProgress size='2rem' color='inherit' />
              )}
            </div>
          </TabPanel>

          <TabPanel className={classes.tabPanel} value='2'>
            <div className={classes.furballs}>
              {searchResults?.map((furball, index) => (
                <MarketplaceFurball
                  key={index}
                  id={furball.id}
                  furball={furball}
                  isListing
                />
              ))}
            </div>
            <div ref={bottomRefII} className={classes.sensor}>
              {loading && furballs.length > 0 && (
                <CircularProgress size='2rem' color='inherit' />
              )}
            </div>
          </TabPanel>

          <TabPanel className={classes.tabPanel} value='2'>
            <div className={classes.furballs}>
              {searchResults?.map((furball, index) => (
                <MarketplaceFurball
                  key={index}
                  id={furball.id}
                  furball={furball}
                  isListing
                />
              ))}
            </div>
            <div ref={bottomRefII} className={classes.sensor}>
              {loading && furballs.length > 0 && (
                <CircularProgress size='2rem' color='inherit' />
              )}
            </div>
          </TabPanel>

          <TabPanel className={classes.tabPanel} value='3'>
            <OkDialog
              title='Create Rental Contract'
              open={showCreateContract}
              onClose={() => setShowCreateContract(false)}>
              <Grid container className={classes.placementContainer}>
                <ScholarshipPlacement
                  light
                  onSuccess={() => setShowCreateContract(false)}
                />
              </Grid>
            </OkDialog>
            <div className={classes.furballs}>
              {(myFurballs as IFurball[]).map((furball, index) => {
                return (
                  <MarketplaceFurball
                    key={index}
                    id={furball!.tokenId}
                    furball={furball}
                  />
                );
              })}
            </div>
          </TabPanel>
        </TabContext>
      </RoundOutPartialPartyProvider>
    </FullDrawer>
  );
};

export const RentMarket = () => (
  <AlertProvider>
    <FurballMarketplace />
  </AlertProvider>
);
