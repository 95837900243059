import { BigNumber, ethers } from 'ethers';
import { IPlayResponse } from '../pages/Play/PlayTypes';
import {
  CommunityFlag,
  CraftingAttemptChangeFragment,
  CraftingAttemptFragment,
  EntityEffectOperand,
  EquipmentSlot,
  FurballBattleStatsFragment,
  FurballSlot,
  ItemQuantityFragment,
  ItemsFragment,
  LeaderboardEntityType,
  LeaderboardStat,
  LeaderboardTimeframe,
  PayoutMethod,
  PlayerPendingReward,
  RarityLevel,
  RealmType,
  RewardSource,
  SocialProfileFragment,
  Specialization,
  StatEffectTarget,
  VoyageEncounterType,
  VoyageState,
  WaitlistFlag,
} from '../components/schema';
import { IFurballUpgrade } from '../components/Timekeeper/Furgreement2';
import { IUnityOptionalArgs } from '../components/useUnity';
import { IFurPlayerLinkableAvatar } from '../components/Players/PlayerLink';

export enum WalletState {
  Trigger,
  Connecting,
}

export interface IFurballInventoryItem {
  id: string;
  itemId: number;
  stack: number;
  offChain: boolean;
}

export interface IFurballDefinition {
  number: number;
  count: number;
  rarity: number;
  experience: number;
  zone: number;
  level: number;
  weight: number;
  last: number;
  birth: number;
  trade: number;
  moves: number;
  // inventory: IFurballInventoryItem[];
}

export interface IFurballAttribute {
  // display_type?: string | null;
  // trait_type: string;
  displayType?: string | null;
  traitType: string;
  value: string | number;
}

export interface IFurballInfo {
  name: string;
  // image: string;
  // externalUrl: string;
  rareGenesCount: number;
  backgroundColor: string;
  attributes: IFurballAttribute[];
}

export interface IFurballStats {
  expRate: number;
  furRate: number;
  definition: IFurballDefinition;
  // snacks: IFurballSnack[];
}

export interface IFurballSnack {
  snackId: number;
  duration: number;
  furCost: number;
  happiness: number;
  energy: number;
  count: number;
  fed: number;
}

export interface ILeaderboardSlot {
  stat: LeaderboardStat;
  timeframe: LeaderboardTimeframe;
  entityType: LeaderboardEntityType;
  position: number;
  overall: number;
  ties: number;
  score: number;
}

export interface IFurballWithPlayer extends IFurballCore {
  player: IFurballPlayer;
}

export interface ILeaderboardSlotFurballs extends ILeaderboardSlot {
  furballs: IFurballWithPlayer[];
}

export interface IFurballRank {
  id: string;
  level: ILeaderboardSlot | null;
  expRate: ILeaderboardSlot | null;
  furRate: ILeaderboardSlot | null;
}

// number
// birth
// name
// backgroundColor
// last
// realm
// intervals
export interface IFurballState {
  id: string;
  totalExp: number;
  furRate: number;
  furRateBase: number;
  expRate: number;
  happiness: number;
  bondingDays: number;
  teamSize: number;
  // ranks: IFurballRank;
  // completedVoyages: RealmType[];
  expPercentBase: number;
  furPercentBase: number;
}

export interface IVoyageEncounterBase {
  id: string;
  date: number;
  encounterType: VoyageEncounterType;
  outcome: number | null;
  decision: number | null;
  subType: number | null;
  sender: string;
  tokenId: string;
  voyageDayNumber: number;
  didPlayerComplete: boolean;
  fuelCost: number | null;
  furCost: number | null;
  furGained: number | null;
  createdAt: number | null;
  updatedAt: number | null;
}

export interface IVoyageEncounter extends IVoyageEncounterBase {
  match: IVoyageEncounterBase | null;
}

export interface IVoyageBase {
  id: string;
  sender: string;
  realm: RealmType;
  state: VoyageState;
  percentComplete: number;
  daysComplete: number;
  daysDuration: number;
  maxLootItems: number;
  encountersFuelCost: number;
  encountersFurGained: number;
  totalFurCost: number;
}

export interface IVoyage extends IVoyageBase {
  activeEncounter?: IVoyageEncounter | null;
  lastEncounter?: IVoyageEncounter | null;
  encounters?: IVoyageEncounter[];
}

export interface IHaveVoyage {
  voyage: IVoyage;
}

export interface IEditionAsset {
  id: string;
  slot: FurballSlot;
  name: string;
  rarityLevel: RarityLevel;
  percentBoost: number;
  realmAffiliation: RealmType | null;
  __typename: string;
}

export interface IFurballToken {
  tokenId: string;
  paletteIdx: number;
  backgroundIdx: number;
  assets: IEditionAsset[];
}

export interface IGameItemId {
  id: string;
  itemId: number;
  stack: number;
}

export interface IGameItem extends IGameItemId {
  maxStack: number;
  rarity: number;
}

export interface IEffectPassive {
  operand: EntityEffectOperand;
  stat: StatEffectTarget;
  value: number;
}

export interface IBattleEffectPassive extends IEffectPassive {
  id: string;
}

export interface IInventory {
  id: string;
  totalDustCount: number;
  items: any[];
  itemQuantities: ItemQuantityFragment[];
}

export interface IRewardItem {
  id: string;
  itemId: number;
  stack: number;
  maxStack: number;
  rarity: string;
  itemGroup: any;
}

export type IPlayerPendingRewards = { items: IRewardItem[] } & Pick<
  PlayerPendingReward,
  'id' | 'expiresAt' | 'source' | 'sourcePosition' | 'notificationId'
>;

export interface IEquipment {
  id: string;
  itemId: number;
  slot: EquipmentSlot;
  position: number;
  durabilityTime: number;
  breakAt: Date | null;
  rarity: RarityLevel;
  stack: number;
  equippedById: string | null;
  equippedAt: Date | null;
  // effects: IBattleEffectPassive[];
  passives: IBattleEffectPassive[];
}

export interface IFurballRentalAgreement {
  id: string;
  startsAt: number;
  duration: number;
  autoRenew: boolean;
  percent: number;
  // rewards: IPlayerPendingRewards[];
  ownerPlayer: IFurPlayerLinkableAvatar;
  rewardCount: number;
  renterPlayer?: null | IFurPlayerLinkableAvatar;
  playerId: string | null;
  beganAt: Date;
  endedAt: Date;
  signature: string;
  furballId: string;
  wFurEarned: number;
  pendingCancellation: boolean;
}

export interface IFurballBase {
  id: string;
  tokenId: string;
  partyId: string | null;
  name: string;
  level: number;
  maxLevel: number;
  maxExp: number;
  ownerId: string;
  specialization: Specialization;
  backgroundColor: string;
  expRate: number;
  furRate: number;
  dailyBattleCount: number;
  maxDailyBattles: number;
  battleStats: FurballBattleStatsFragment;
  state: IFurballState;
  inventory: IInventory;
  equipment: IEquipment[];
  marketListingCount: number;
  zone: number;
  wExp: number;
  karma: number;
  isRented?: boolean;
  battleCount: number;
  lastBattleAt: Date;
  lastHeartLostAt: Date;
  skillRollCost: number;
  skillUpgradesAvailable: number;
  openRentalAgreement: IFurballRentalAgreement | null;
  activeRentalAgreement: IFurballRentalAgreement | null;
  pendingUpgrade: IFurballUpgrade | null;
  number: number;
  birth: number;
  last: number;
  realm: RealmType | null;
  rarity: number;
  intervals: number;
  pendingExp: number;
  pendingFur: number;
  hearts: number;
  timekeeperDisabled: boolean;
}

export interface IFurballWithOwner extends IFurballBase {
  owner: IFurballPlayerMinimal;
}

export interface IFurballCore extends IFurballBase {
  activeVoyage: IVoyageBase | null;
  lastVoyage: IVoyageBase | null;
}

export interface IFurball extends IFurballBase {
  activeVoyage: IVoyage | null;
  lastVoyage: IVoyage | null;
}

export enum TransactionState {
  None = 0,
  EstimatingGas,
  SendingRequest,
  AwaitingConfirmations,
  QueryingUpdate,
  Complete,
}

// Base types for server-side account system, usable to represent other players
export interface IFurballPlayerMinimal {
  id: string;
  avatar: string | null;
  username: string | null;
  socialName: string;
}

export interface IFurballPlayer extends IFurballPlayerMinimal {
  communityFlags: CommunityFlag[];
  waitlistFlags: WaitlistFlag[];
  wFur: number;
  tix: number;
  bossBattleCount: number;
  referralCount: number;
  rentedFurballCount: number;
  numRentalCancellationsPlayer: number;
  numRentalCancellationsOwner: number;
  createdAt: number | string | null;
}

//
// export interface IFurballPlayerFurballs extends IFurballPlayer {
//   furballs: IFurball[];
// }

// Base type for on-chain data
export interface IGovernanceAccount {
  maxFurballs: number;
  maxLevel: number;
  numFurballs: number;
  permissions: number;
  reputation: number;
}

export interface IPendingRewards {
  id: string;
  items: any[];
  createdAt?: string;
  expiresAt?: string;
  sourcePosition: number | null;
  notificationId: string | null;
  source: RewardSource;
}

export interface IInventoryBase {
  id: string;
}

export interface IInventoryQuantities extends IInventoryBase {
  itemQuantities: ItemQuantityFragment[];
  totalDustCount: number;
}

export interface IInventoryItems extends IInventoryBase {
  items: ItemsFragment[];
}

export interface IInventoryAll
  extends IInventoryBase,
    IInventoryQuantities,
    IInventoryItems {}

export interface ICraftingSlotBase {
  crafterId: string;
  isActive: boolean;
  isLocked: boolean;
  slotIndex: number;
  fuelCost: number;
  unlockCost: number;
  unlockedAt: string;
}

export interface ICraftingSlot extends ICraftingSlotBase {
  currentAttempt: CraftingAttemptFragment | null;
}

export interface ICraftingSlotChanged extends ICraftingSlotBase {
  currentAttempt: CraftingAttemptChangeFragment | null;
}

export interface IPayout {
  id: string;
  ethAmount: number;
  tixRatio: number;
  payoutMethod: PayoutMethod;
  transactionId: string | null;
}

export interface IPlayerQuest {
  questName: string;
  completedWaypoints?: any[];
}

// Stuff lots of things into the wallet, representing "current player"
export interface IFurballWalletContents extends IFurballPlayer {
  furBalance: number;
  furballBalance: number;
  tokenIds: string[];
  craftingSlots: ICraftingSlot[];
  inventory: IInventoryQuantities;
  payouts: IPayout[];
  quests: IPlayerQuest[];
  socialProfiles: SocialProfileFragment[];
}

export interface IFurballAccount extends IFurballPlayer, IGovernanceAccount {
  created: BigNumber;
}

export interface IFurballUser extends IFurballAccount {
  // isAdmin: boolean;
  // isModerator: boolean;
  communityFlags: CommunityFlag[];
}

export interface IFurballEdition {
  // count: number;
  // minted: number;
  // maxMintable: number;
  // liveAt: number;
  purchaseFur: { [key: string | number]: number };
  maxSize: number;
  maxAdoptable: number;
}

export interface IEditionAddresses {
  edition: string;
}

export type Abi = unknown[];

export interface IContractAddresses {
  furballs: string;
  fur: string;
  engine: string;
  governance: string;
  furgreement: string;
  oauth: string;
  fuel: string;
  zones: string;
  scholarrental: string;
  editions: IEditionAddresses[];
  furgreement2?: string;
  progress?: string;
  skills?: string;
  equipment?: string;
}

export interface IContractArtifact {
  contractName: string;
  sourceName: string;
  abi: Abi;
  bytecode: string;
  deployedBytecode: string;
}

export interface IEditionArtifacts {
  edition: IContractArtifact;
}

export interface IContractArtifacts {
  furballs: IContractArtifact;
  fur: IContractArtifact;
  engine: IContractArtifact;
  governance: IContractArtifact;
  oauth?: IContractArtifact;
  fuel?: IContractArtifact;
  editions: IEditionArtifacts[];
  furgreement2?: IContractArtifact;
  progress?: IContractArtifact;
  skills?: IContractArtifact;
  equipment?: IContractArtifact;
}

export interface ICompiledNetwork {
  addresses: IContractAddresses;
  artifacts: IContractArtifacts;
}

export interface IBlockchainNetwork {
  id: number;
  type: NetworkType;
  name: string;
  subdomain: string;
  interval: number;
  deployment?: ICompiledNetwork;
  contracts?: IFurballContracts;
  subdomainMismatch?: string; // If the user needs to be redirected to a dif. subdomain
}

export enum NetworkType {
  MainNet,
  TestNet,
  DevNet,
}

export interface IFurballContracts {
  furballs: ethers.Contract;
  fur: ethers.Contract;
  engine: ethers.Contract;
  governance: ethers.Contract;
  oauth?: ethers.Contract;
  fuel?: ethers.Contract;
  currentEdition: ethers.Contract;
  furgreement2?: ethers.Contract;
  equipment?: ethers.Contract;
}

export interface IFurballContext {
  network: IBlockchainNetwork;
  provider: ethers.providers.Web3Provider;
}

export interface IContractErrorData {
  message: string;
}

export interface IContractError {
  data: IContractErrorData;
}

export interface IFurballPending {
  zoning?: number;
  playResponses: IPlayResponse[];
}

export interface IClaimedRewards {
  pendingRewards: IPendingRewards[];
  inventory: IInventoryQuantities;
}

export interface IFurballWallet {
  loaded?: boolean; // its been loaded once and is valid for use
  connected?: boolean;
  address?: string;
  loading?: boolean;
  // user?: IFurballUser;
  edition?: IFurballEdition;
  contents?: IFurballWalletContents;
  furballs: { [key: string]: IFurball };
  pending: { [key: string]: IFurballPending };
  received: string[]; // Received furballs (transfer/spawn)
  triedLogin?: boolean;
  usableFurballsLoaded?: boolean;
  unityOptionalArgs?: IUnityOptionalArgs;
  upgrade?: IUpgradeFurball;
}

export interface IUpgradeFurball {
  tokenId: string;
  tokenIds: string[];
}

export interface IFurballZoneMove {
  tokenId: string;
  zone?: number;
}

export interface IHaveTix {
  tix: number;
}

export interface IToken {
  tokenId: string;
}

export interface IMaybeToken {
  tokenId?: string;
}

export type SetTransactionState = (
  state: TransactionState,
  error?: ITransactionError,
) => void;

export enum TxnErrReason {
  Unknown,
  UserDenied,
  OutOfGas,
  MaxGas,
  ErrGas,
  Replacement,
  Replaced,
  Nonce,
  Pre,
  Balance,
}

export interface ITransactionError {
  message: string;
  reason: TxnErrReason;
}

export interface IBalances {
  fur?: number;
  wFur?: number;
  tix?: number;
}

export interface IHaveFurballs {
  furballs: IFurball[];
}
