import { Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import useCommonStyles from '../../components/useCommonStyles';
import { IFurballBase } from '../../wallet/WalletTypes';
import { useWalletSelector } from '../../hooks';
// import { getTotalLootBonus, groupInventory } from '../../components/Loot';
import {
  TotalBoostBar,
  ItemStatRow,
  ITotalBoost,
  // getTeamModifier,
  // getLevelFurBoost,
  // StatAbbreviation,
  IStatCallout,
  computeStats,
} from '../Stats';

export interface IStatRow {
  title: string;
  name: string;
  callout: IStatCallout;
}

interface IRankings {
  furball: IFurballBase;
  hideTeamSize?: boolean;
}

export const Rankings: FunctionComponent<IRankings> = (props) => {
  const { furball, hideTeamSize } = props;
  const common = useCommonStyles();

  const furballs = useWalletSelector((s) => s.contents);
  const teamSize = furballs?.furballBalance ?? 0;

  const [stats, setStats] = React.useState<IStatRow[]>([]);
  const [boosts, setBoosts] = React.useState<ITotalBoost>({
    expBoost: 0,
    furBoost: 0,
  });

  React.useEffect(() => {
    if (!furball) {
      return;
    }

    const [expBoost, furBoost, rows] = computeStats(
      furball,
      teamSize,
      hideTeamSize,
    );

    setBoosts({ expBoost, furBoost });
    setStats(rows);
  }, [furball]);

  function renderStatRow(stat: IStatRow, odd: boolean): React.ReactNode {
    return <ItemStatRow odd={odd} {...stat} />;
  }

  return (
    <React.Fragment>
      <div className={common.cardDropHeader}>
        <Typography variant={'h5'} className={common.unfocusedText}>
          Grand Totals
        </Typography>
      </div>
      <Grid container className={common.paddingTable} spacing={1}>
        {stats.map((stat, i) => {
          return (
            <Grid key={stat.title} item xs={12}>
              {renderStatRow(stat, i % 2 === 1)}
            </Grid>
          );
        })}
      </Grid>
      <TotalBoostBar {...boosts} />
    </React.Fragment>
  );
};

export default Rankings;
