import React, { FunctionComponent } from 'react';
import { Grid, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  ShopWaresFragment,
  TimekeeperInteractionType,
  useShopWaresQuery,
} from '../schema';
import { getLootItem, getLootSlot, LootSlot } from '../Loot';
import TimekeeperMarketButton from '../Timekeeper/TimekeeperMarketButton';
import {
  getAllTimekeeperInteractions,
  useCurrentTimekeeperRequest,
} from '../Timekeeper/TimekeeperHooks';
import { usePlayersReadyFurballs } from '../../wallet';
// import {IShopWares} from '../Timekeeper/TimekeeperSlice';
import { hasWaresPurchase } from './ShopHelpers';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: 400,
  },
  marketButton: {
    width: '100%',
  },
}));

interface IShop {
  furballId: string;
}

export const ShopWaresSingle: FunctionComponent<IShop> = (props) => {
  const classes = useStyles();
  const { furballId } = props;
  const { log } = useFurComponent(ShopWaresSingle);
  const { data, loading } = useShopWaresQuery();
  const tkState = useCurrentTimekeeperRequest();
  const furballs = usePlayersReadyFurballs();
  const shopWares = data?.shopWares ?? [];
  const interactions = getAllTimekeeperInteractions(tkState.current);

  function onSaved() {
    log.info('wares saved');
  }

  function renderWares(w: ShopWaresFragment): React.ReactNode {
    const loots = w.itemIds.map((id) => getLootItem(id));
    const slots = loots.map((l) => getLootSlot(l));
    const desc = loots[0]?.description ?? '';
    const furballIds = [furballId];
    const purchased = hasWaresPurchase(furballs, interactions, furballId, w);

    return (
      <Grid container spacing={1}>
        <Grid item xs={2} style={{ textAlign: 'right', paddingTop: 42 }}>
          {slots.map((s) => (
            <div key={s.item.lootId} style={{ display: 'inline-block' }}>
              <LootSlot size={'sm'} {...s} />
            </div>
          ))}
        </Grid>
        <Grid item xs={10} style={{ textAlign: 'left' }}>
          <Typography variant={'h5'}>{w.name}</Typography>
          <Typography variant={'body1'}>{desc}</Typography>
          <br />
          {!purchased && (
            <TimekeeperMarketButton
              title={'Claim Loot'}
              className={clsx(classes.marketButton)}
              marketRequest={{
                interactionType: TimekeeperInteractionType.ShopPurchase,
                furballIds,
                expectedValue: w.id,
              }}
              onSaved={onSaved}
              furballIds={furballIds}
              fuelCost={w.fuelCost1}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'subtitle2'}>
            Only one of each may be claimed per Furball.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={clsx(classes.root)}>
      {shopWares.map((w) => {
        return (
          <Grid item xs={6} key={w.id} style={{ textAlign: 'center' }}>
            {renderWares(w)}
          </Grid>
        );
      })}
      {loading && (
        <LinearProgress style={{ width: '100%' }} color={'secondary'} />
      )}
    </Grid>
  );
};

export default ShopWaresSingle;
