import React, { FunctionComponent } from 'react';
import { Theme, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ICloseDialog } from '../../OkDialog';
import RealmCreatureDialog from './RealmCreatureDialog';
import { RealmType } from '../../schema';
import { useHistory } from 'react-router-dom';
import useCommonStyles from '../../useCommonStyles';
import { IFurball } from '../../../wallet/WalletTypes';
import { playGameVerb } from '../../../pages/Furverse/Lobby/BossGamePrepare';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  playButton: {
    width: '100%',
  },
}));

interface IRealmCreatureVendorGameDialog extends ICloseDialog {
  realmType: RealmType;
  furball: IFurball;
}

export const RealmCreatureVendorGameDialog: FunctionComponent<
  IRealmCreatureVendorGameDialog
> = (props) => {
  const { onClose, open, realmType, furball } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const history = useHistory();
  const encounter = furball.activeVoyage?.lastEncounter;

  function openNext() {
    const path = `/voyages/${realmType.toLowerCase().replaceAll('_', '-')}/${
      furball.tokenId
    }`;
    history.push(path);
  }

  return (
    <RealmCreatureDialog
      className={clsx(classes.root)}
      open={open}
      onClose={onClose}
      title={'Here, Take This!'}
      realmType={realmType}>
      <div style={{ minWidth: 200 }}>
        <Typography variant={'h4'}>Loot Game</Typography>
        <Typography variant={'h6'}>Every Player's a Winner!</Typography>
        <Typography variant={'subtitle2'}>
          The faster you are, the better the loot you'll win. Loot is added to
          the voyage, collected at the end.
        </Typography>
        <br />
        <Button
          variant={'contained'}
          color={'primary'}
          className={clsx(common.actionButton, classes.playButton)}
          onClick={openNext}>
          {encounter?.decision && (
            <Typography variant={'h5'}>
              {(encounter.furCost || 0).toLocaleString()} $wFUR | Continue
            </Typography>
          )}
          {!encounter?.decision && (
            <Typography variant={'h5'}>{playGameVerb}</Typography>
          )}
        </Button>
      </div>
    </RealmCreatureDialog>
  );
};

export default RealmCreatureVendorGameDialog;
