import * as React from 'react';

function SvgDrawerClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id='drawer-close_svg__Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      x={0}
      y={0}
      viewBox='0 0 115.72 127.48'
      xmlSpace='preserve'
      {...props}>
      <style />
      <path
        d='M51.99 127.48H0V0h51.99c35.2 0 63.74 28.54 63.74 63.74-.01 35.2-28.54 63.74-63.74 63.74z'
        fill='#fde99a'
      />
      <path
        fill='none'
        stroke='#4935cd'
        strokeWidth={10}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M63.87 87.84l-26.38-24.1 26.38-24.1'
      />
    </svg>
  );
}

export default SvgDrawerClose;
