import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { RealmType } from '../../schema';
import { wizardsPathSpinCells } from './WizardsPathDefinitions';
import { ILootSlot } from '../../Loot';
import SvgSpinWizardsPath from '../../../assets/voyages/SvgSpinWizardsPath';
import { StatAbbreviation } from '../../Stats';
import SvgSpinBoneyard from '../../../assets/voyages/SvgSpinBoneyard';
import SvgSpinTurtleSea from '../../../assets/voyages/SvgSpinTurtleSea';
import { turtleSeaSpinCells } from './TurtleSeaDefinitions';
import { boneyardSpinCells } from './BoneyardDefinitions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
  },
  loot: {
    position: 'absolute',
    height: 100,
    width: 100,
  },
  spinner: {
    height: '600px',
    width: '100%',
  },
}));

interface IVoyageGameRealmSpinner {
  realmType: RealmType;
  onSpinState: (hash?: string, lootItem?: ILootSlot) => void;
  paused?: boolean;
}

interface ICoordinates {
  x: number;
  y: number;
}

export interface ISpinLootCell {
  svg: React.ReactNode;
  slot: ILootSlot;
  offset: ICoordinates;
}

function getSpinCells(realmType: RealmType): ISpinLootCell[] {
  if (realmType === RealmType.WizardsPath) return wizardsPathSpinCells;
  if (realmType === RealmType.TurtleSea) return turtleSeaSpinCells;
  if (realmType === RealmType.Boneyard) return boneyardSpinCells;
  return wizardsPathSpinCells;
}

// Multiplier goes 1-100 (ish) where harder = smaller, scaled to normal loot drop chances
function getSpinDurationMult(stat: StatAbbreviation, rarity: number): number {
  const statTypeMult = stat === 'exp' ? 9 : 1;
  if (rarity <= 1) return statTypeMult * 6;
  if (rarity === 2) return statTypeMult * 3;
  return statTypeMult;
}

export const VoyageGameRealmSpinner: FunctionComponent<
  IVoyageGameRealmSpinner
> = (props) => {
  const { realmType, onSpinState, paused } = props;
  const classes = useStyles();
  const spinCells = getSpinCells(realmType);
  const [activeCellNum, setActiveCellNum] = React.useState<number | undefined>(
    undefined,
  );
  const activeCell =
    activeCellNum !== undefined ? spinCells[activeCellNum] : undefined;

  React.useEffect(() => {
    if (paused) return;

    let interval = 20; // the shortest time (leg. FUR = Fur-gaine)
    if (activeCell?.slot.item.stat) {
      interval *= getSpinDurationMult(
        activeCell.slot.item.stat,
        activeCell.slot.item.rarity,
      );
      interval = Math.min(1000, interval);
      interval = (Math.random() / 2 + 0.5) * interval;

      onSpinState('', activeCell.slot);
    } else {
      onSpinState(undefined, undefined);
    }

    const to = setTimeout(() => {
      const act = activeCellNum === undefined ? 0 : activeCellNum + 1;
      setActiveCellNum(act >= spinCells.length ? 0 : act); //
    }, interval);
    return () => clearTimeout(to);
  }, [activeCellNum, paused]);

  function renderRealmSpinner(): React.ReactNode {
    if (realmType === RealmType.WizardsPath)
      return <SvgSpinWizardsPath>{activeCell?.svg}</SvgSpinWizardsPath>;
    if (realmType === RealmType.Boneyard)
      return (
        <SvgSpinBoneyard svg={{ className: classes.spinner }}>
          {activeCell?.svg}
        </SvgSpinBoneyard>
      );
    if (realmType === RealmType.TurtleSea)
      return (
        <SvgSpinTurtleSea svg={{ className: classes.spinner }}>
          {activeCell?.svg}
        </SvgSpinTurtleSea>
      );
    return null;
  }

  return (
    <div className={classes.root}>
      {renderRealmSpinner()}
      {spinCells.map((cell, i) => {
        const left = `calc(50% - 50px + ${cell.offset.x}px)`;
        const bottom = `calc(50% - 30px + ${cell.offset.y}px)`;
        const style = { left, bottom };

        return (
          <div key={i} className={classes.loot} style={style}>
            {cell.slot.item.icon}
          </div>
        );
      })}
    </div>
  );
};

export default VoyageGameRealmSpinner;
