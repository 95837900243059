import { ILogManager, ILogSink, ILogger, LogLevel } from './types';
import { getLogEntry } from './hooks';

class ContextualLogger implements ILogger {
  public readonly logManager: ILogManager;
  public readonly context: string;
  private _sinks: ILogSink[];

  constructor(manager: ILogManager, context: string, ...sinks: ILogSink[]) {
    this.logManager = manager;
    this.context = context;
    this._sinks = sinks;
  }

  public log(logLevel: LogLevel, ...args: unknown[]): void {
    if (this._sinks.length <= 0) return;

    const minLevel = this.logManager.getMinimumLogLevelForContext(this.context);
    if (minLevel > logLevel) return;

    const logEntry = getLogEntry(this.context, logLevel, ...args);
    this._sinks.forEach((h) => {
      h.logEntry(logEntry);
    });
  }

  public verbose(...args: unknown[]): void {
    this.log(LogLevel.verbose, ...args);
  }

  public debug(...args: unknown[]): void {
    this.log(LogLevel.debug, ...args);
  }

  public info(...args: unknown[]): void {
    this.log(LogLevel.info, ...args);
  }

  public warn(...args: unknown[]): void {
    this.log(LogLevel.warn, ...args);
  }

  public error(...args: unknown[]): void {
    this.log(LogLevel.error, ...args);
  }
}

export default ContextualLogger;
