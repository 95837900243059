import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Sloth = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M212.23 171.27c0 7.29-7.31 6.66-16.07 6.66-8.87 0-16.07.62-16.07-6.66s7.19-13.19 16.07-13.19c8.88 0 16.07 5.91 16.07 13.19z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M212.23 171.27c0 7.29-7.31 6.66-16.07 6.66-8.87 0-16.07.62-16.07-6.66s7.19-13.19 16.07-13.19c8.88 0 16.07 5.91 16.07 13.19z'
    />
    <path
      fill='#fd98b4'
      d='M185.71 175.15c0-3.94 4.68-7.14 10.45-7.14s10.45 3.2 10.45 7.14'
    />
    <path
      fill='#bd6c58'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M192.26 177.72s5.34 1.91 5.34 7.8v4.08c0 2.25 1.83 4.08 4.08 4.08s4.08-1.83 4.08-4.08v-4.4c0-1.93.57-3.81 1.65-5.41l1.31-2.07'
    />
    <path d='M204.71 147.59c0 4.55-3.69 3.38-8.23 3.38-4.55 0-8.23 1.17-8.23-3.38a8.23 8.23 0 0 1 16.46 0z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M196 145v12'
    />
  </svg>
);

export default Sloth;
