// import rinkebyCompiled from './rinkeby.json';
// import ropstenCompiled from './ropsten.json';
// import mainnetCompiled from './mainnet.json';
// import localhostCompiled from './localhost.json';

import currentCompiled from './current.json';
import { ICompiledNetwork, IFurballContracts } from '../../wallet/WalletTypes';
import { ethers, Signer } from 'ethers';

export const currentNetwork: ICompiledNetwork = currentCompiled;
// export const mainnetNetwork: ICompiledNetwork = mainnetCompiled;
// export const rinkebyNetwork: ICompiledNetwork = rinkebyCompiled;
// export const ropstenNetwork: ICompiledNetwork = ropstenCompiled;
// export const localhostNetwork: ICompiledNetwork = localhostCompiled;

export function getCompiledNetwork(
  networkId: number,
): ICompiledNetwork | undefined {
  try {
    return currentNetwork;
    // if (networkId === 1) return mainnetNetwork;
    // if (networkId === 31337) return localhostNetwork;
    // if (networkId === 3) return ropstenNetwork;
    // if (networkId === 4) return rinkebyNetwork;
  } catch (e) {
    console.warn('network down?', networkId, e);
    return undefined;
  }
  return undefined;
}

export function getContracts(
  network: ICompiledNetwork,
  signer: Signer,
): IFurballContracts {
  const { addresses, artifacts } = network;
  return {
    // @ts-ignore
    furballs: new ethers.Contract(
      addresses.furballs,
      // @ts-ignore
      artifacts.furballs.abi,
      signer,
    ),
    // @ts-ignore
    fur: new ethers.Contract(addresses.fur, artifacts.fur.abi, signer),
    // @ts-ignore
    engine: new ethers.Contract(addresses.engine, artifacts.engine.abi, signer),
    // @ts-ignore
    governance: new ethers.Contract(
      addresses.governance,
      // @ts-ignore
      artifacts.governance.abi,
      signer,
    ),
    // @ts-ignore
    fuel: new ethers.Contract(addresses.fuel, artifacts.fuel.abi, signer),
    // @ts-ignore
    // furgreement: new ethers.Contract(
    //   // @ts-ignore
    //   addresses.furgreement,
    //   // @ts-ignore
    //   artifacts.furgreement.abi,
    //   signer,
    // ),
    // @ts-ignore
    currentEdition: new ethers.Contract(
      addresses.editions[0].edition,
      artifacts.editions[0].edition.abi as any, //temp fix for typescript error
      signer,
    ),
    // @ts-ignore
    furgreement2: new ethers.Contract(
      // @ts-ignore
      addresses.furgreement2,
      // @ts-ignore
      artifacts.furgreement2.abi,
      signer,
    ),
    // @ts-ignore
    equipment: new ethers.Contract(
      // @ts-ignore
      addresses.equipment,
      // @ts-ignore
      artifacts.equipment.abi,
      signer,
    ),
    // @ts-ignore
    progress: new ethers.Contract(
      // @ts-ignore
      addresses.progress,
      // @ts-ignore
      artifacts.progress.abi,
      signer,
    ),
    // @ts-ignore
    skills: new ethers.Contract(
      // @ts-ignore
      addresses.skills,
      // @ts-ignore
      artifacts.skills.abi,
      signer,
    ),
    // @ts-ignore
    equipment: new ethers.Contract(
      // @ts-ignore
      addresses.equipment,
      // @ts-ignore
      artifacts.equipment.abi,
      signer,
    ),
  };
}
