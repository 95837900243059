import * as React from 'react';
import SvgIconMinus from '../../assets/SvgIconMinus';
import { ethPerTix } from '../../components/Timekeeper/TimekeeperSlice';
import SvgIconPlus from '../../assets/SvgIconPlus';
import BuyTicketsButton from '../../components/Timekeeper/BuyTicketsButton';
import { Fab, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ITransactionError, TransactionState } from '../../wallet/WalletTypes';
import clsx from 'clsx';
import { useFurComponent } from '../../utils';
import { IPurchasedTickets } from './TicketPurchaseForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  ticketExpansion: {
    // padding: theme.spacing(1),
    // border: '3px solid #000',
  },
  plusMinus: {
    // top: '60px',
    zIndex: 100,
  },
  plus: {
    // right: '20px',
  },
  minus: {
    // left: '20px',
  },
}));

type ITicketPurchaseBlockchain = IPurchasedTickets;

const TicketPurchaseBlockchain: React.FunctionComponent<
  ITicketPurchaseBlockchain
> = (props) => {
  const classes = useStyles();
  const { log } = useFurComponent(TicketPurchaseBlockchain);
  const { onPurchased } = props;
  const [buyCount, setBuyCount] = React.useState(10);
  const [transactionState, setTransactionState] = React.useState(
    TransactionState.None,
  );

  function addTickets(num: number) {
    setBuyCount(buyCount + num);
  }

  function setState(s: TransactionState, e?: ITransactionError): void {
    log.info('[ETH] +TIX', s, e);
    setTransactionState(s);
    if (s === TransactionState.Complete) {
      onPurchased(buyCount);
    }
  }

  return (
    <Grid container spacing={2} className={classes.ticketExpansion}>
      <Grid item xs={3} style={{ paddingTop: 10 }}>
        <Fab
          color={'secondary'}
          className={clsx(classes.plusMinus, classes.minus)}
          onClick={() => addTickets(-1)}>
          <SvgIconMinus style={{ width: '20px' }} />
        </Fab>
      </Grid>
      <Grid item xs={6} style={{ paddingTop: '10px', textAlign: 'center' }}>
        <Typography variant={'h4'}>{buyCount.toLocaleString()} TIX</Typography>
        <Typography variant={'h6'} style={{ marginTop: '-8px' }}>
          {(buyCount * ethPerTix).toFixed(3)} ETH
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Fab
          color={'secondary'}
          className={clsx(classes.plusMinus, classes.plus)}
          onClick={() => addTickets(10)}>
          <SvgIconPlus style={{ width: '20px' }} />
        </Fab>
      </Grid>
      <Grid item xs={12} style={{ padding: 10, paddingTop: 30 }}>
        <BuyTicketsButton amount={buyCount} onState={setState} />
        {/*{input.round && (*/}
        {/*  <Typography variant={'subtitle2'} style={{ textAlign: 'right' }}>*/}
        {/*    <br />1 TIX = ${(input.round?.ethPrice * ethPerTix).toFixed(2)} USD*/}
        {/*  </Typography>*/}
        {/*)}*/}
      </Grid>
    </Grid>
  );
};

export default TicketPurchaseBlockchain;
