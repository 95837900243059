import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

const headerFont = 'GemunuLibre';
const bodyFont = 'Ubuntu';

function getCdnRoot() {
  if (
    window.location.hostname === 'localhost' ||
    window.location.hostname === 'alpha.furballs.com'
  )
    return 'http://' + window.location.host;
  if (window.location.hostname === 'rinkeby.furballs.com')
    return 'https://test.furballs.com';
  if (window.location.hostname === 'goerli.furballs.com')
    return 'https://test.furballs.com';
  return 'https://assets.furballs.com';
}

export const cdnRoot = getCdnRoot();

export function getGameCdnShard(shard: string): string {
  if (shard === 'alpha') return `${gameRoot}/unity-alpha`;
  if (shard === 'beta') return `${gameRoot}/unity-beta`;
  return `${gameRoot}/unity`;
}

function getGameCdnRoot() {
  if (
    window.location.host === 'localhost:9000' ||
    window.location.hostname === 'alpha.furballs.com'
  )
    return getGameCdnShard('alpha');
  if (
    window.location.hostname === 'rinkeby.furballs.com' ||
    window.location.hostname === 'goerli.furballs.com' ||
    window.location.hostname === 'localhost'
  )
    return getGameCdnShard('beta');
  return getGameCdnShard('');
}

export const gameRoot = 'https://game.furballs.com';

export const gameCdnRoot = getGameCdnRoot();

export const mint = {
  light: '#C9FFD4',
  main: '#A8FFB9',
  dark: '#C9FFD4',
  contrastText: '#000',
};

export const darkBlue = '#362cd1';

export const purple = {
  light: '#D0D0FD',
  main: '#D0D0FD',
  dark: '#7777d1',
  contrastText: '#000',
};

export const furColor = {
  light: '#fff0ab',
  main: '#e8c525',
  dark: '#e8c525',
  contrastText: '#000',
};

export const expColor = {
  light: '#ffdcf5',
  main: '#e077c2',
  dark: '#e077c2',
  contrastText: '#000',
};

export const luckColor = {
  light: '#e4ffc4',
  main: '#6ed25a',
  dark: '#6ed25a',
  contrastText: '#000',
};

export const realmWizardsPathColor = {
  light: '#ecd1ff',
  main: '#d9a3ff',
  dark: '#d9a3ff',
  contrastText: '#000',
};

export const realmBoneyardColor = {
  light: '#ffdbbd',
  main: '#ffa861',
  dark: '#ffa861',
  contrastText: '#000',
};

export const realmTurtleSeaColor = {
  light: '#ccffec',
  main: '#59f5bc',
  dark: '#59f5bc',
  contrastText: '#000',
};

const theme = createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: mint,
    secondary: purple,
    // error: Colors.error,
    background: {
      paper: '#fff',
      default: '#fafafa',
    },
  },
  shape: {
    borderRadius: 20,
  },
  mixins: {
    toolbar: {
      height: '56px',
    },
  },
  typography: {
    fontFamily: [bodyFont, headerFont, 'sans-serif'].join(','),
    h1: {
      fontFamily: headerFont,
      fontSize: '4rem',
    },
    h2: {
      fontFamily: headerFont,
      fontSize: '2.2rem',
    },
    h3: {
      fontFamily: headerFont,
      fontSize: '2.5rem',
    },
    h4: {
      fontSize: '2rem',
      fontFamily: headerFont,
    },
    h5: {
      fontSize: '1.5rem',
      fontFamily: headerFont,
    },
    h6: {
      fontSize: '1.1rem',
      fontFamily: headerFont,
    },
    button: {
      fontFamily: headerFont,
      // fontSize: '1.1rem',
      borderColor: '#000000',
      border: '2px solid',
      // boxShadow:'none !important',
    },
    body1: {
      fontFamily: bodyFont,
    },
    body2: {
      fontFamily: bodyFont, // Used implicitly by listMenu
    },
    caption: {
      fontFamily: bodyFont,
      fontSize: '0.5rem',
    },
    //button
    overline: {
      fontFamily: bodyFont,
      // fontSize: '0.75rem',
    },
    subtitle1: {
      fontFamily: bodyFont,
      fontWeight: 'bold',
    },
    subtitle2: {
      fontFamily: bodyFont,
      fontStyle: 'italic',
    },
  },
});

theme.mixins.toolbar[theme.breakpoints.down('lg')] = {
  height: '100px',
  padding: '0px',
  paddingLeft: theme.spacing(2),
};

theme.typography.button[theme.breakpoints.down('lg')] = {
  fontSize: '2.0rem',
  lineHeight: '2.4rem',
};
theme.typography.h3[theme.breakpoints.down('lg')] = {
  fontSize: '3.2rem',
};
theme.typography.h4[theme.breakpoints.down('lg')] = {
  fontSize: '3.0rem',
};
theme.typography.h5[theme.breakpoints.down('lg')] = {
  fontSize: '2.8rem',
};
theme.typography.h6[theme.breakpoints.down('lg')] = {
  fontSize: '1.8rem',
};
theme.typography.body1[theme.breakpoints.down('lg')] = {
  fontSize: '2.4rem',
  lineHeight: '2.8rem',
};
theme.typography.body2[theme.breakpoints.down('lg')] = {
  fontSize: '1.2rem',
};
theme.typography.subtitle1[theme.breakpoints.down('lg')] = {
  fontSize: '1.8rem',
};
theme.typography.subtitle2[theme.breakpoints.down('lg')] = {
  fontSize: '1.5rem',
};

export default theme;
