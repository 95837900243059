import * as React from 'react';

function SvgZoneIconsFurballs(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' {...props}>
      <g>
        <path d='M11.6 6.3c.2.2.5.4.8.4 1.2.2 2.4-.9 2.8-2.6 0-.2.1-.4.1-.6v-.2V3c-.1-1.3-.7-2.3-1.6-2.5-1.1-.2-2.3.8-2.7 2.3 0 .1-.1.2-.1.4s-.1.4-.1.6c-.1 1 .2 2 .8 2.5zM4.3 3.1V3.7c0 .2.1.4.2.6C5.1 6 6.4 7 7.6 6.6c1.2-.4 1.6-2 1.1-3.7 0-.1 0-.1-.1-.2C8 1.1 6.7.2 5.6.5c-.8.5-1.3 1.4-1.3 2.6zM19.9 9.2V9c.1-.1.1-.3.1-.4v-.2-.3V8c-.1-.6-.3-1.1-.8-1.5-1-.7-2.6 0-3.5 1.4-.2.3-.3.6-.5 1V9.2c0 .1-.1.2-.1.3V10.2c0 .2 0 .3.1.4v.1c0 .1.1.2.1.3 0 0 0 .1.1.1 0 .1.1.2.2.2l.1.1.3.3c1 .7 2.6 0 3.5-1.4l.3-.6c0-.1 0-.1.1-.2-.1 0-.1-.2 0-.3zM4.5 12l.3-.3s0-.1.1-.1c0-.1.1-.2.1-.3v-.1c0-.1.1-.3.1-.4v-.1-.3-.1c0-.2 0-.3-.1-.5.1-.2 0-.3 0-.4-.1-.4-.4-.8-.7-1.2-1-1.4-2.7-1.9-3.6-1.1-.4.3-.6.7-.7 1.1v.6c0 .2 0 .3.1.5v.1c0 .1.1.3.1.4v.1c.1.2.1.3.2.5 0 0 0 .1.1.1.1.2.2.3.3.5.7.8 1.6 1.4 2.4 1.4.5 0 1-.1 1.3-.4zM17 15.6c0-.1-.1-.2-.1-.3-.4-.7-1.3-1.3-2.2-1.8-.8-.4-1.3-1.3-1.3-2.2V11c-.2-1.6-1.5-2.9-3.1-3-2 0-3.5 1.5-3.5 3.4 0 .9-.5 1.8-1.3 2.2-.6.3-1.3.8-1.7 1.2l-.1.1c0 .1-.1.1-.1.2s-.1.1-.1.2l-.1.1c0 .1-.1.2-.1.2v.1c0 .1-.1.2-.1.4 0 .6.2 1.1.4 1.6.3.5.7.9 1.1 1.2l.6.3c.4.2.9.2 1.4.2 1.6-.1 1.8-1.3 3.5-1.3h.5c.3.1.6.2.8.3.2.1.5.3.7.5.3.2.5.3.9.4.2 0 .4.1.6.1 1.9.1 3.5-1.4 3.5-3.3 0-.1 0-.3-.1-.4-.1 0-.1-.1-.1-.1z' />
      </g>
    </svg>
  );
}

export default SvgZoneIconsFurballs;
