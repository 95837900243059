import React, { FunctionComponent, useCallback, useEffect } from 'react';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';
import {
  ZONE_NUM_BOSS_FIGHTS,
  ZONE_NUM_SCHOLARSHIPS,
} from '../../../components/Zones/ZoneHelpers';

import {
  Button,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useBossBattle } from '../useBossBattle';
import { cdnRoot } from '../../../theme';
import { IFurball } from '../../../wallet/WalletTypes';
import { FormationPosition } from '../../../components/schema';
import FurballHearts from '../../../components/Furballs/FurballHearts';
import FurballBossKeys from '../FurballBossKeys';
import SvgIconUpgrade from '../../../assets/SvgIconUpgrade';
import theme from '../../../themev2';
import { getFurballInventory } from '../../../components/Furballs';
import { BossKeyDefinition } from '../../../components/Loot';

const useStyles = makeStyles((theme: Theme) => ({
  furballWrap: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',

    '&[data-disabled="true"]': {
      opacity: 0.4,
      filter: 'grayScale(80%)',
    },
  },

  furballContainer: {
    position: 'absolute',
    inset: 0,

    [theme.breakpoints.down('md')]: {
      left: '0.2rem',
    },
  },

  furballShadow: {
    position: 'absolute',
    width: '7.5rem',
    bottom: '-1.6rem',
    margin: 'auto',
    marginRight: '0.8rem',
    borderRadius: '100%',
    height: '2rem',
    border: `3px solid ${theme.palette.primary.main}`,
  },

  furballGhost: {
    position: 'relative',
    maxWidth: '100%',
    width: '250px',
    top: '2rem',
    left: '-1rem',
    // right: '1.8rem',
  },

  fuballNotice: {
    position: 'absolute',
    width: '12rem !important',
    margin: 'auto',
    left: '-0.4rem',
    bottom: '-6.15rem',
    height: '2rem',
    borderRadius: '30px 30px',
    border: '2px solid black',
    zIndex: 2,
    display: 'grid',
    placeItems: 'center',
    textTransform: 'uppercase',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.6)',
      left: '-2.3rem',
      bottom: '-2rem',
    },
  },

  furballVitals: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 2.2rem',
    alignItems: 'center',
  },

  keys: {
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
  },

  hearts: {
    height: '100%',
    backgroundColor: theme.palette.primary.main,
  },

  upgrade: {
    backgroundColor: 'black',
    height: '100%',
    display: 'grid',
    placeItems: 'center',

    '& button': {
      border: 'none !important',
      padding: '0 !important',
      width: 'fit-content !important',

      '& svg': {
        marginBottom: '2px',
        marginRight: '1px',
      },
    },
  },
}));

interface IProps {
  furball: IFurball;
  position: FormationPosition;
  hovering?: BossKeyDefinition;
  hasNonBossZoneMember: boolean;
  hasDeadMember: boolean;
  setHasNonBossZoneMember: (value: boolean) => void;
  setStatsTokenId: (tokenId: string) => void;
}

export const LobbyFurball: FunctionComponent<IProps> = ({
  furball,
  position,
  hovering,
  hasDeadMember,
  setHasNonBossZoneMember,
  hasNonBossZoneMember,
  setStatsTokenId,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { partyId } = useBossBattle();

  const notInBattleZone = furball.zone !== ZONE_NUM_BOSS_FIGHTS;
  const isOnScholarship = furball.zone === ZONE_NUM_SCHOLARSHIPS;

  const maxFightsAllowed = furball.maxDailyBattles ?? 2; // bonded furballs get 3 battles
  const fightsRemaining = furball.hearts;

  const furballHasCurrentBossKey = useCallback(() => {
    const hasKey = furball.inventory.items.find(
      (item) => item.itemId === hovering,
    );

    return !!hasKey;
  }, [furball, hovering]);

  useEffect(() => {
    notInBattleZone &&
      !isOnScholarship &&
      setHasNonBossZoneMember(notInBattleZone);
  }, [furball, notInBattleZone, partyId]);

  return (
    <div
      style={{
        gridArea: position,
      }}
      data-disabled={
        notInBattleZone && !isOnScholarship
          ? !!hovering
          : !!hovering &&
            !furballHasCurrentBossKey() &&
            !hasNonBossZoneMember &&
            !hasDeadMember
      }
      className={classes.furballWrap}>
      {furball && (
        <>
          {!!hovering &&
          !hasNonBossZoneMember &&
          !hasDeadMember &&
          !furballHasCurrentBossKey() ? (
            <Grid
              container
              alignItems='stretch'
              justifyContent='center'
              className={classes.fuballNotice}>
              <Typography
                fontSize='0.95rem'
                color='black'
                variant='h6'
                alignSelf='center'
                fontWeight={800}>
                Missing Key
              </Typography>
            </Grid>
          ) : (
            <div className={clsx(classes.fuballNotice, classes.furballVitals)}>
              <FurballBossKeys
                furball={getFurballInventory(furball)}
                className={classes.keys}
              />
              <FurballHearts furball={furball} className={classes.hearts} />
              <Grid
                container
                gap='0.3rem'
                alignItems='center'
                justifyContent='center'
                className={classes.upgrade}>
                <Tooltip title={'Modify Skills & Equipment'}>
                  <Button
                    size='small'
                    onClick={() => setStatsTokenId(furball.tokenId)}>
                    <SvgIconUpgrade
                      height='1rem'
                      width='1rem'
                      fill={theme.palette.primary.light}
                    />
                  </Button>
                </Tooltip>
              </Grid>
            </div>
          )}

          <div className={classes.furballContainer}>
            {fightsRemaining > 0 ? (
              <FurballRenderer
                tokenId={furball.tokenId}
                size={isSmallScreen ? '7.4rem' : '12rem'}
              />
            ) : (
              <img
                src={`${cdnRoot}/images/${
                  furball.karma >= 0 ? 'angel' : 'devil'
                }-furball.png`}
                className={classes.furballGhost}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
