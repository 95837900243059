import * as React from 'react';

function SvgScroll(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      {...props}>
      <g>
        <g>
          <path
            d='M448,10.667h-42.667h-256c-35.285,0-64,28.715-64,64v256h-64C9.536,330.667,0,340.224,0,352v85.333
			c0,35.285,28.715,64,64,64h277.333c1.195,0,2.197-0.533,3.328-0.747c2.453,0.256,4.821,0.747,7.339,0.747
			c41.173,0,74.667-33.493,74.667-74.667V181.333h64c11.797,0,21.333-9.557,21.333-21.333V74.667
			C512,39.381,483.285,10.667,448,10.667z M64,458.667c-11.776,0-21.333-9.579-21.333-21.333v-64h64h170.667v53.333
			c0,5.483,0.683,10.773,1.792,15.936c0.32,1.429,0.896,2.752,1.301,4.16c1.024,3.669,2.176,7.253,3.733,10.667
			c0.171,0.405,0.235,0.853,0.427,1.237H64z M320,309.333H192c-11.797,0-21.333-9.557-21.333-21.333s9.536-21.333,21.333-21.333h128
			c11.797,0,21.333,9.557,21.333,21.333S331.797,309.333,320,309.333z M320,224H192c-11.797,0-21.333-9.557-21.333-21.333
			s9.536-21.333,21.333-21.333h128c11.797,0,21.333,9.557,21.333,21.333S331.797,224,320,224z M320,138.667H192
			c-11.797,0-21.333-9.557-21.333-21.333S180.203,96,192,96h128c11.797,0,21.333,9.557,21.333,21.333S331.797,138.667,320,138.667z
			 M469.333,138.667h-42.667V53.333H448c11.776,0,21.333,9.579,21.333,21.333V138.667z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgScroll;
