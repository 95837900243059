import { SvgEditionSlot } from '../part';
import * as React from 'react';

const DapperShirt = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='M131.22 185.93c-7.32 4.91-7.04 14.14-7.04 14.14l48.96 42.17 22.82-25.56c-.07-.01-.14-.03-.2-.04-29.2-.19-54.15-14.71-64.54-30.71zM251.18 197.35c-12.49 10.66-31.49 18.79-52.89 19.27-.11.02-.22.04-.34.05l20.09 29.09 26.35-27.05c5.49-4.96 8.49-12.18 7.82-19.54-.05-.56-.42-1.17-1.03-1.82z'
    />
    <path
      fill='#bae9ff'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M204.91 219.55c0 4.95-4.01 8.96-8.96 8.96-4.95 0-8.96-4.01-8.96-8.96s4.01-6.12 8.96-6.12c4.95 0 8.96 1.18 8.96 6.12z'
    />
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
  </svg>
);

export default DapperShirt;
