import React, { FunctionComponent } from 'react';
import { FormControl } from '@mui/material';
import { ISelectProps, SelectCustom } from './SelectCustom';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    minWidth: (props: any) =>
      `${props.minWidth ? props.minWidth : '17.5'}rem !important`,
    width: (props: any) => (props.fullWidth ? '100%' : 'initial'),
    margin: (props: any) =>
      props.noMargin ? '0px !important' : '3px 0 !important',
    right: (props: any) => props.offsetRight || '0.7',

    [theme.breakpoints.down('sm')]: {
      transformOrigin: 'center',
      position: 'relative',
    },
  },

  smallSelect: {
    transform: 'scale(0.8)',
    transformOrigin: 'center right',
    position: 'relative',

    '@media (max-width: 370px)': {
      right: '1.5rem !important',
    },
  },
}));

export const SELECT_OPTIONS = [
  { label: 'Default', value: '0' },
  { label: 'LVL: High to low', value: '1' },
  { label: 'LVL: Low to high', value: '2' },
  { label: 'EXP/hr: High to low', value: '3' },
  { label: 'EXP/hr: Low to high', value: '4' },
  { label: 'FUR/hr: High to low', value: '5' },
  { label: 'FUR/hr: Low to high', value: '6' },
];

interface OwnProps extends ISelectProps {
  sortBy: string;
  setSortBy: React.Dispatch<React.SetStateAction<string>>;
  noMargin?: boolean;
  minWidth?: number;
  offsetRight?: string;
  smallSelect?: boolean;
}

type Props = OwnProps;

export const FurballSortBySelect: FunctionComponent<Props> = (props) => {
  const classes = useStyles(props);
  const {
    minWidth,
    setSortBy,
    noMargin,
    iconColor,
    offsetRight,
    sortBy,
    smallSelect,
    ...selectProps
  } = props;
  // const selectProps: ISelectProps = props;

  return (
    <FormControl
      className={clsx(classes.form, selectProps.className, {
        [classes.smallSelect]: props.smallSelect,
      })}
      size='small'>
      <SelectCustom
        iconColor='secondary'
        labelId='furball-sort-by'
        id='furball-sort-by'
        options={SELECT_OPTIONS}
        value={props.sortBy}
        onChange={(e) => props.setSortBy(e.target.value as string)}
        {...selectProps}
      />
    </FormControl>
  );
};

export default FurballSortBySelect;
