import { LogLevel, LogOptions } from './types';

export const defaultLogOptions: LogOptions = {
  minLevels: {
    '': LogLevel.info, // fallback/default level
  },
};

export const developmentLogOptions: LogOptions = {
  minLevels: {
    '': LogLevel.debug, // fallback/default level
  },
};
