import React from 'react';
import { isMobile, mobileVendor, mobileModel } from 'react-device-detect';
import { isVuplexExpected } from './vuplexBridge';
import { useTimekeeperSelector } from '../../components/Timekeeper/TimekeeperSlice';
import { useFurverseContext } from '../Furverse/Furverse';

export interface IClientHardware {
  isMobile: boolean;
  mobileVendor: string;
  mobileModel: string;
  cornerPadding: number;
  referenceDpi: number;
  devicePixelRatio?: number;
  uiScale?: number;
}

export function getCornerPadding(): number {
  if (!isVuplexExpected() || !isMobile) return 0;
  if (mobileModel.includes('iPhone 14')) return 48;
  return 36;
}

export function useClientHardware(): IClientHardware {
  const vp = useFurverseContext();
  // @ts-ignore
  const vuplexReady = !!window.vuplex || (vp && vp.vuplexExpected);
  const clientSpec = useTimekeeperSelector((s) => s.meta?.clientSpec);
  const referenceDpi = clientSpec?.referenceDpi ?? 255;
  // const devicePixelRatio = ;
  // Math.min(
  //   mobileModel.includes('iPhone') ? 2 : 1,
  //   useDevicePixelRatio(),
  // );

  return React.useMemo(() => {
    return {
      isMobile,
      mobileVendor,
      mobileModel,
      cornerPadding: getCornerPadding(),
      referenceDpi,
      devicePixelRatio,
      // uiScale: 0,
    };
  }, [vuplexReady, referenceDpi, devicePixelRatio]);
}
