import React, { FunctionComponent } from 'react';
import {
  Theme,
  Container,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Accordion,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { cdnRoot } from '../../theme';
import useCommonStyles from '../useCommonStyles';
import SvgCaret from '../../assets/SvgCaret';
import { OutboundLink } from '../Navigation';
import ReactPlayer from 'react-player';
import { usePageTitle } from '../../Analytics';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflowY: 'auto',
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
  },
  main: {
    width: '90% !important',
    margin: '1rem auto !important',

    [theme.breakpoints.up('lg')]: {
      maxWidth: '1600px',
    },
  },
  content: {
    background: '#eee',
    textAlign: 'left',
  },
}));

interface IPressKit {}

export const PressKit: FunctionComponent<IPressKit> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();

  usePageTitle('Press');

  function renderAccordion(
    title: string,
    body: React.ReactNode,
  ): React.ReactNode {
    return (
      <Accordion className={common.cardRoot}>
        <AccordionSummary
          className={common.cardHeader}
          expandIcon={
            <React.Fragment>
              <SvgCaret
                fill={'#FFF'}
                className={common.expandIcon}
                style={{ marginRight: 15 }}
              />
            </React.Fragment>
          }>
          <Typography variant={'h5'} style={{ color: 'white' }}>
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.content}>{body}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <div className={clsx(common.fullHeight, classes.root, common.furBk)}>
      <Container className={clsx(classes.main)}>
        <Typography variant={'h2'}>Media Resources</Typography>
        {renderAccordion(
          'Company Details',
          <Typography variant={'body1'}>
            LFG Gaming LLC launched Furballs in November 2021. With adorable art
            and unique technical innovations, it has remained one of the most
            stable NFTs in the market. Since then, Furballs has evolved into a
            real-time strategic game with a community of devoted fans on
            Discord.
          </Typography>,
        )}
        {renderAccordion(
          'Gameplay Videos',
          <>
            <Typography variant={'h3'}>Boss Launch Promo</Typography>
            <ReactPlayer url='https://www.youtube.com/watch?v=o2xlcABUIJQ' />
            <Typography variant={'h3'}>Full Gameplay Video</Typography>
            <ReactPlayer url='https://www.youtube.com/watch?v=h0Qve550iLU' />
            <Typography variant={'h3'}>Strategy Walk-Through</Typography>
            <ReactPlayer url='https://www.youtube.com/watch?v=_IMwEWd3dxM' />
          </>,
        )}
        {renderAccordion(
          'Images',
          <>
            <Typography variant={'h3'}>Cover</Typography>
            <img
              src={`${cdnRoot}/images/furballs-hero.png`}
              style={{ maxWidth: '100%' }}
            />
            <Typography variant={'h3'}>Hero</Typography>
            <img
              src={`${cdnRoot}/images/leaderboard-illustration-01.png`}
              style={{ maxWidth: '100%' }}
            />
            <img
              src={`${cdnRoot}/images/banner2.png`}
              style={{ maxWidth: '100%' }}
            />
            <Typography variant={'h3'}>Boss Avatars</Typography>
            <img
              src={`${cdnRoot}/images/avatars/trashy.png`}
              style={{ width: '200px' }}
            />
            <img
              src={`${cdnRoot}/images/avatars/sebastian.png`}
              style={{ width: '200px' }}
            />
            <img
              src={`${cdnRoot}/images/avatars/skullumbo.png`}
              style={{ width: '200px' }}
            />
          </>,
        )}
        {renderAccordion(
          'The Team',
          <>
            <Typography variant={'h3'}>
              QueenJuJu (
              <OutboundLink to={'https://twitter.com/queenfurballs'}>
                Julie Friedenberg
              </OutboundLink>
              )
            </Typography>
            <Typography variant={'body1'}>
              Artist & Designer. Previous clients include MLG, FB Games, Amazon,
              Google, Samsung, IBM, WordPress among others. Allegedly a painter
              of abstract art.
            </Typography>
            <br />
            <br />
            <Typography variant={'h3'}>
              inZania (
              <OutboundLink to={'http://linkedin.com/in/zaneclaes/'}>
                Zane Claes
              </OutboundLink>
              )
            </Typography>
            <Typography variant={'body1'}>
              In a prior life, was the lead architect of Airbnb's API.
              Independently built mobile games for large studios like Konami.
              Self-published multiple #1 top-of-category apps for iPad and
              iPhone. Hacked assembly code, enabling groundbreaking SVG
              compression algorithm used by Furballs' NFT contract.
            </Typography>
            <br />
            <br />
            <Typography variant={'h3'}>Bam (Anonymous)</Typography>
            <Typography variant={'body1'}>
              Built ‘Words with Friends’ and another service you probably use
              regularly 😉. Designs and plays mobile games in his free time.
              Early crypto investor and recently addicted NFT collector. Day 1
              Anonymice / Toadz holder/believer, love Hoots. !vibe
            </Typography>
          </>,
        )}
        {renderAccordion(
          'Recognition',
          <>
            <Typography variant={'h5'}>
              <OutboundLink
                to={
                  'https://www.rollingstone.com/culture/culture-features/nft-play-to-earn-gaming-grimes-defi-1265679/'
                }>
                Rolling Stone
              </OutboundLink>
            </Typography>
          </>,
        )}
      </Container>
    </div>
  );
};

export default PressKit;
