import * as React from 'react';

function SvgIconPlus(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fillRule='evenodd'
      clipRule='evenodd'
      viewBox='0 0 456 456'
      shapeRendering='geometricPrecision'
      textRendering='geometricPrecision'
      imageRendering='optimizeQuality'
      {...props}>
      <path d='M50 171h121V50c0-27 26-50 57-50 32 0 58 23 58 50v121h120c28 0 50 26 50 57 0 32-22 58-50 58H286v120c0 28-26 50-58 50-31 0-57-22-57-50V286H50c-27 0-50-26-50-58 0-31 23-57 50-57z' />
    </svg>
  );
}

export default SvgIconPlus;
