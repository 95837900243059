import React, { FunctionComponent, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { shadeColor } from '../../../themev2';
import { Grid, Theme, Tooltip, Typography } from '@mui/material';
import { FurballBattleStatsFragment } from '../../../components/schema';

import UpgradeDialog from '../../../components/Town/Upgrade/UpgradeDialog';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';

import {
  defaultBossKeys,
  getLootItem,
  getLootSlot,
} from '../../../components/Loot';
import { cdnRoot } from '../../../theme';
import { IFurballItemInventory } from '../../Furverse/FurballBossKeys';
import { getSkillDescription } from '../../../components/Furballs/Skills';
import { getSkillDefinitionById } from '../../Skills/SkillLibrary';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    borderRadius: '10px 10px 10px',
    overflow: 'hidden',
    border: `2px solid ${theme.palette.common.black}`,
  },

  furball: {
    display: 'grid',
    placeItems: 'center',
    height: 'fit-content',
    width: '100%',
    position: 'relative',
    cursor: 'pointer',
  },

  furballFigure: {
    position: 'relative',
    transform: 'scale(0.9)',
  },

  labels: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
  },

  stats: {
    height: '4.4rem',
    padding: '0 1rem',
  },

  iconGroup: {
    display: 'flex',
    gap: '0.2rem',
  },

  iconContainer: {
    display: 'grid',
    placeItems: 'center',
    width: '1.5rem',
    height: '1.5rem',
    overflow: 'hidden',
    // border: '1px solid gray',
    padding: '0.3rem',
    borderRadius: '100%',
    backgroundColor: '#E9E9ED',
    position: 'relative',
    cursor: 'pointer',

    '& > svg': {
      position: 'static !important',
    },
  },

  skillIcon: {
    width: '105%',
    aspectRatio: '1/1',
    position: 'absolute',
    top: '0.15rem',
  },
}));

interface IMarketplaceFurball extends IFurballItemInventory {
  id: string;
  name: string;
  level: number;
  backgroundColor: string;
  maxDailyBattles: number;
  dailyBattleCount: number;
  battleStats: FurballBattleStatsFragment;
}

export interface IFurballItemProps {
  id: string;
  furball: IMarketplaceFurball;
  isListing?: boolean;
}

export const MarketplaceFurball: FunctionComponent<IFurballItemProps> = ({
  id,
  furball,
  isListing,
}) => {
  const classes = useStyles();

  const [show, setShow] = useState<boolean>(false);
  // const hex = BigInt(id);
  // const hexId = hex.toString(10);

  const {
    name,
    level,
    backgroundColor,
    inventory,
    battleStats,
    dailyBattleCount,
    maxDailyBattles,
  } = furball;

  return (
    <div className={classes.root}>
      <div
        className={classes.furball}
        onClick={() => setShow(true)}
        style={{
          backgroundColor: `${backgroundColor}`,
        }}>
        <Grid container className={classes.labels}>
          <Typography fontWeight={600}>LVL {level}</Typography>
        </Grid>
        <FurballRenderer
          tokenId={id}
          size='12rem'
          className={classes.furballFigure}
        />
      </div>
      <Grid
        container
        alignItems='center'
        justifyContent='space-between'
        className={classes.stats}
        style={{
          backgroundColor: shadeColor(backgroundColor, -40),
        }}>
        <div>
          <Typography fontSize='1rem' color='black' fontWeight={600}>
            {name}
          </Typography>

          <Grid container alignItems='center' mt='0.2rem' gap='0.5rem'>
            <div className={classes.iconGroup}>
              {defaultBossKeys.map((bossKey, index) => {
                const loot = getLootItem(bossKey);
                const slot = loot ? getLootSlot(loot) : undefined;
                const hasKey = inventory?.itemQuantities?.find(
                  (item) => item.itemId === bossKey,
                );

                if (!hasKey) return null;

                return (
                  <Tooltip title={loot.name} key={index}>
                    <div
                      className={classes.iconContainer}
                      style={{ backgroundColor }}>
                      {slot?.item.icon}
                    </div>
                  </Tooltip>
                );
              })}
            </div>

            <Typography
              fontSize='0.6rem'
              fontWeight='800'
              color={shadeColor(backgroundColor, -80)}>
              |
            </Typography>

            <div className={classes.iconGroup}>
              {battleStats?.skills?.map((skill, index) => {
                const def = getSkillDefinitionById(skill.skillDefinitionId);
                return (
                  <Tooltip title={getSkillDescription(skill)} key={index}>
                    <div
                      className={classes.iconContainer}
                      style={{ backgroundColor }}>
                      <img
                        className={classes.skillIcon}
                        src={`${cdnRoot}/images/icons/skills/${def?.icon}.png`}
                        alt={def?.name}
                      />
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </Grid>
          {/* <Typography fontSize='0.85rem' color='#3e3e3e' fontWeight={500}>
            {expRate} EXP/hr | {furRate} FUR/hr
          </Typography> */}
        </div>

        {/* <a href={url} target='_blank' rel='noreferrer noopener'>
          <SvgIconOpensea width='1rem' height='1rem' />
        </a> */}
        <div>
          <Grid
            container
            flexDirection='column'
            alignItems='center'
            justifyContent='center'>
            <img
              width='22px'
              height='22px'
              src={`${cdnRoot}/images/icons/battle_stats/hp.png`}
            />
            <Typography fontSize='0.7rem'>
              {dailyBattleCount}/{maxDailyBattles}
            </Typography>
          </Grid>
        </div>
      </Grid>

      <UpgradeDialog
        show={show}
        tokenId={furball.id}
        onClose={() => setShow(false)}
        onAction={() => setShow(false)}
        rentFurball={true}
      />
    </div>
  );
};
