import React, { FunctionComponent, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Fade,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IFurballBase } from '../../wallet/WalletTypes';
import { useRecentFurballBossFightsLazyQuery } from '../../components/schema';
import clsx from 'clsx';
import useCommonStyles from '../../components/useCommonStyles';
import { cdnRoot } from '../../theme';
import SvgIconBossHistoryDuration from '../../assets/SvgIconBossHistoryDuration';
import { formatNumber, secToMinAndSec } from '../../utils';
import SvgIconBossHistoryDate from '../../assets/SvgIconBossHistoryDate';
import { yellow } from '../../themev2';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '1rem',
    minHeight: '80px',
    backgroundColor: '#32318E !important',
  },
  bossFight: {
    borderRadius: '8px',
    padding: '0.5rem 0.75rem',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',

    '& h6': {
      textTransform: 'capitalize',
    },
  },
  level: {
    padding: '1px 0.3rem',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '4px',
    color: 'white',
    fontSize: '12px',
    width: '55px',
    textAlign: 'center',
  },
  info: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '0.75rem',
    marginLeft: '0.75rem',
  },
  battleInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    marginRight: '5px',
    height: '52px',

    '& p': {
      fontWeight: 'bold',
      fontSize: '12px !important',
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        marginRight: '7.5px',
      },
    },

    [theme.breakpoints.down(1000)]: {
      display: 'none',
    },
  },
  dmg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    fontWeight: 'bold',
    fontSize: '1rem',
    lineHeight: '1rem',
    backgroundColor: yellow,
    padding: '0.35rem 0.75rem 0.2rem 0.75rem',
    height: '50px',
    width: '80px',

    '& span': {
      fontSize: '0.6rem',
      marginTop: '3px',
    },

    [theme.breakpoints.down(1000)]: {
      display: 'none',
    },
  },
  playedBy: {
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '7px',

    '& p': {
      fontWeight: 'bold',
      fontSize: '12px !important',
      lineHeight: '0.75rem !important',
    },

    [theme.breakpoints.down(1000)]: {
      marginLeft: 'auto',
    },
  },
}));

interface IFurballPublicHome {
  furball: IFurballBase;
}

export const FurballRecentBossFights: FunctionComponent<IFurballPublicHome> = (
  props,
) => {
  const { furball } = props;
  const classes = useStyles();
  const common = useCommonStyles();

  const [recentBossFightsForFb, { data, loading }] =
    useRecentFurballBossFightsLazyQuery();
  const recentBossFights = data?.findFurball.bossFights?.nodes ?? [];

  useEffect(() => {
    if (!!furball) {
      recentBossFightsForFb({
        variables: { number: `${furball?.number ?? 0}` },
      });
    }
  }, [furball]);

  function renderRecentBossFights() {
    if (loading) return <CircularProgress color={'secondary'} size={40} />;

    if (recentBossFights.length === 0) {
      return (
        <Typography variant={'body1'} color={'rgba(255, 255, 255, 0.6)'}>
          No recent boss fights
        </Typography>
      );
    }

    return recentBossFights.map((bossFight) => {
      const {
        id,
        playerId,
        // damageTotals,
        outcomeSummaries,
        playerDamageTotal,
        worldBoss,
        duration,
        createdAt,
      } = bossFight;
      const { name, level } = worldBoss;

      const dmgByFurball =
        outcomeSummaries.find(
          ({ gameEntityId }) => gameEntityId === furball.tokenId,
        )?.damageTotal ?? 0;
      const percentageOfTotalDmg =
        playerDamageTotal > 0 ? (dmgByFurball / playerDamageTotal) * 100 : 0;

      return (
        <Fade key={id} in={true} timeout={400}>
          <div className={classes.bossFight}>
            <div style={{ display: 'flex' }}>
              <img
                src={`${cdnRoot}/images/avatars/${name.toLowerCase()}.png`}
                height={42.5}
                width={42.5}
                style={{ marginRight: '12px', marginTop: '5px' }}
                alt={`${name} avatar`}
              />
              <div>
                <Typography
                  variant={'h6'}
                  fontSize={'1.15rem'}
                  lineHeight={'1.6rem'}>
                  {name}
                </Typography>
                <div className={classes.level}>Level {level}</div>
              </div>
            </div>

            <div className={classes.playedBy}>
              <Typography variant={'body1'}>Played By:</Typography>
              <Typography variant={'h6'}>
                <Link to={`/player/${playerId}`}>
                  {`${playerId.substr(0, 4)}...${playerId.substr(-4)}`}
                </Link>
              </Typography>
            </div>

            <div className={classes.info}>
              <div className={classes.battleInfo}>
                <Typography variant={'body1'}>
                  <SvgIconBossHistoryDuration height={15} width={15} />{' '}
                  {secToMinAndSec(duration)}
                </Typography>
                <Typography variant={'body1'}>
                  <SvgIconBossHistoryDate height={15} width={15} />{' '}
                  {new Date(createdAt).toLocaleDateString()}
                </Typography>
              </div>
              <div className={classes.dmg}>
                {formatNumber(dmgByFurball)} <span>Dmg Done</span>
              </div>
              <div className={classes.dmg}>
                {formatNumber(percentageOfTotalDmg)}% <span>Of total dmg</span>
              </div>
            </div>
          </div>
        </Fade>
      );
    });
  }

  return (
    <Card className={common.cardRoot}>
      <CardHeader className={common.cardHeader} title={'Recent Boss Fights'} />
      <CardContent className={clsx(common.cardContent, classes.root)}>
        {renderRecentBossFights()}
      </CardContent>
    </Card>
  );
};

export default FurballRecentBossFights;
