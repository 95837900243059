import * as React from 'react';

function SvgIconRanks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 22' {...props}>
      <g fill={`#${props.fill ?? 'FFFFFF'}`}>
        <path d='M.44 14.66h3.59v6.85H.44zM10.79 8.89h3.59v12.62h-3.59zM5.62 3.35h3.59v18.16H5.62zM15.97.49h3.59v21.02h-3.59z' />
      </g>
    </svg>
  );
}

export default SvgIconRanks;
