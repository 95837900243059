import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Gremlin = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffd2cf'
      d='M151.38 116.39c-5.46 5.9-13.2 20.76-14 20.94-13.18 3.06-18.41-2.07-18.41-2.07l4.66-1.82c6.37-6.44-10.9-7.11-10.9-7.11s21.03-28.55 38.65-9.94z'
    />
    <path
      fill='#ffbab5'
      d='M148 122c-7.02 5.22-7 20-13 15 .18-2.56.95-6.55 6.5-12.27.92-.95 1.37-2.3 1.13-3.6-.29-1.6-1.6-3.34-5.79-3.62-7.98-.53-10.02 9.1-24.11 8.83 0-.01 24.29-19.56 35.27-4.34z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M112.73 126.33s7.97.3 11.62 3.49c1.29 1.13.83 3.01-.72 3.61l-4.66 1.82s9.37 5.48 22.55 2.42'
    />
    <path
      fill={props.renderer.color1}
      d='M99.23 123.27c5.68-5.04 18.44-16.13 26.67-21.32 10.84-6.85 22.24 2.97 22.24 2.97l.2-4.17c11.51 4.5 16.18 18 16.18 18-2.08 3.77-6.33 5.8-10.57 5.04l-2.46-.44c-3.6-6.16-7.08-9.28-10.02-10.79-3.44-1.76-7.64-1.05-10.4 1.66a78.54 78.54 0 0 1-10.88 8.92 20.173 20.173 0 0 1-12.48 3.41l-7.51-.44c-1.43-.1-2.04-1.88-.97-2.84z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m148.33 100.74-.2 4.17s-11.4-9.81-22.24-2.97c-8.22 5.2-20.99 16.28-26.67 21.32-1.08.96-.46 2.74.98 2.82l7.51.44c4.42.26 8.81-.93 12.48-3.41 3.05-2.06 6.9-5 10.88-8.92 2.76-2.71 6.95-3.42 10.4-1.66 2.94 1.5 4.67 2.99 8.27 9.15'
    />
    <path
      opacity={0.15}
      d='M126.1 103.28c10.84-6.85 22.24 2.97 22.24 2.97l.2-4.17c3.9 1.53 7.01 4.09 9.43 6.8-3.09-1.41-7.18-3.01-7.68-1.8-.82 1.97-1.26 2.9-6.37-.22C131 99 99.05 124.95 99.44 124.6c5.68-5.04 18.44-16.12 26.66-21.32z'
    />
    <path d='M164.52 114.77S157.5 99.5 148.5 98.5c0 0-4 0-2 3 1.92 2.89 7.23 1.09 15.36 12.12a2.89 2.89 0 0 0 2.66 1.15z' />
    <path
      fill='#ffd2cf'
      d='M240.82 116.39c5.46 5.9 13.2 20.76 14 20.94 13.18 3.06 18.41-2.07 18.41-2.07l-4.66-1.82c-6.37-6.44 10.9-7.11 10.9-7.11s-21.03-28.55-38.65-9.94z'
    />
    <path
      fill='#ffbab5'
      d='M244.2 122c7.02 5.22 7 20 13 15-.18-2.56-.95-6.55-6.5-12.27-.92-.95-1.37-2.3-1.13-3.6.29-1.6 1.6-3.34 5.79-3.62 7.98-.53 10.02 9.1 24.11 8.83 0-.01-26.47-26.34-35.27-4.34z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M279.47 126.33s-7.97.3-11.62 3.49c-1.29 1.13-.83 3.01.72 3.61l4.66 1.82s-9.05 4.8-22.23 1.74'
    />
    <path
      fill={props.renderer.color1}
      d='M292.97 123.27c-5.68-5.04-18.44-16.13-26.67-21.32-10.84-6.85-22.24 2.97-22.24 2.97l-.2-4.17c-11.51 4.5-16.18 18-16.18 18 2.08 3.77 6.33 5.8 10.57 5.04l2.46-.44c3.6-6.16 7.08-9.28 10.02-10.79 3.44-1.76 7.64-1.05 10.4 1.66a78.54 78.54 0 0 0 10.88 8.92c3.67 2.48 8.06 3.67 12.48 3.41l7.51-.44c1.43-.1 2.05-1.88.97-2.84z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='m243.87 100.74.2 4.17s11.4-9.81 22.24-2.97c8.22 5.2 20.99 16.28 26.67 21.32 1.08.96.46 2.74-.98 2.82l-7.51.44c-4.42.26-8.81-.93-12.48-3.41-3.05-2.06-6.9-5-10.88-8.92-2.76-2.71-6.95-3.42-10.4-1.66-2.94 1.5-4.13 3.28-7.73 9.44'
    />
    <path
      opacity={0.15}
      d='M266.1 103.28c-10.84-6.85-22.24 2.97-22.24 2.97l-.2-4.17c-3.9 1.53-7.01 4.09-9.43 6.8 3.09-1.41 7.18-3.01 7.68-1.8.82 1.97 1.26 2.9 6.37-.22 12.92-7.86 44.86 18.08 44.48 17.74-5.67-5.04-18.44-16.12-26.66-21.32z'
    />
    <path d='M227.69 114.77s7.02-15.27 16.02-16.27c0 0 4 0 2 3-1.92 2.89-7.23 1.09-15.36 12.12a2.91 2.91 0 0 1-2.66 1.15z' />
  </svg>
);

export default Gremlin;
