import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Lazy = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#FFF'
      d='M251.07 127.44c-.04 0-.07-.01-.11-.01-3.47 0-6.28 2.81-6.28 6.28s2.81 6.28 6.28 6.28c2.3 0 4.31-1.24 5.4-3.09-1-3.57-2.84-6.8-5.29-9.46z'
    />
    <ellipse opacity={0.15} cx={158} cy={150} rx={21} ry={22} />
    <ellipse opacity={0.15} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <circle fill='#bdefff' cx={166} cy={146} r={12} />
    <circle cx={166} cy={146} r={8} />
    <circle fill='#FFF' cx={167} cy={150} r={2} />
    <circle fill='#bdefff' cx={227} cy={146} r={12} />
    <circle cx={227} cy={146} r={8} />
    <circle fill='#FFF' cx={228} cy={150} r={2} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <path
      fill={props.renderer.color1}
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M152.89 121.51c-12 4.71-17.92 18.25-13.21 30.25l43.47-17.04c-4.71-12-18.26-17.91-30.26-13.21zM232.3 119.9c-12.89 0-23.34 10.45-23.34 23.34h46.69c0-12.89-10.45-23.34-23.35-23.34z'
    />
  </svg>
);

export default Lazy;
