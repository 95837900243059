import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Tab, Tabs, Theme, Typography } from '@mui/material';
import useCommonStyles from '../../useCommonStyles';
import {
  BossLeaderboardDocument,
  LeaderboardFragment,
  LeaderboardGroup,
  LeaderboardRankPlayerFragment,
  LeaderboardTimeframe,
  useBossLeaderboardLazyQuery,
  useBossLeaderboardPlayerBestScoreGroupLazyQuery,
} from '../../schema';
import themev2, { white } from '../../../themev2';
import { TabContext, TabPanel } from '@mui/lab';
import { IBossLeaderboard } from './BossLeaderboards';
import clsx from 'clsx';
import LeaderboardTiers from './LeaderboardTiers';
import { useApolloClient } from '@apollo/client';
import { useWalletSelector } from '../../../hooks';
import useScrollToPlayerPlacingHook from './useScrollToPlayerPlacingHook';

const useStyles = makeStyles((theme: Theme) => ({
  tabs: {
    zIndex: 1,
    width: '100%',
    maxHeight: 40,
  },
  tab: {
    maxHeight: 40,
    textTransform: 'uppercase',
    border: 'none !important',
    fontSize: '18px !important',
    borderTopRightRadius: '7px !important',
    borderTopLeftRadius: '7px !important',

    [theme.breakpoints.down('lg')]: {
      fontSize: '16px !important',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '14px !important',
      minWidth: '70px !important',
    },

    '@media (max-width: 395px)': {
      fontSize: '13px !important',
      lineHeight: '13px !important',
    },
  },
  groupContainer: {
    minWidth: '100%',
    backgroundColor: white,
    flexGrow: 1,
    borderBottomRightRadius: '6px',
    borderBottomLeftRadius: '6px',
  },
  tierCont: {
    minHeight: '350px',
    maxHeight: '350px',
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    padding: '10px 7px !important',

    ['@media (max-width: 1400px)']: {
      padding: '10px 12px !important',
    },

    ['@media (max-width: 375px)']: {
      padding: '10px 5px !important',
    },
  },
  tabPanel: {
    padding: '5px !important',
    maxHeight: '420px',
    minHeight: '420px',
  },
  longTierCont: {
    maxHeight: '410px',
    minHeight: '410px',

    [theme.breakpoints.down('sm')]: {
      minHeight: '265px',
      maxHeight: '265px',
    },
  },
  leaderboardBlock: {
    height: '60px',
    backgroundColor: '#4031b3',
    color: 'white',
    textAlign: 'center',
    paddingTop: 10,
  },
}));

interface ITabGroup {
  title: string;
  group: LeaderboardGroup;
}

type TabMap = { [k: string]: ITabGroup };

const defaultTabs: TabMap = {
  // '0': {
  //   title: 'One',
  //   group: LeaderboardGroup.PartySize1,
  // },
  '0': {
    title: 'Three',
    group: LeaderboardGroup.PartySize3,
  },
  '1': {
    title: 'Five',
    group: LeaderboardGroup.PartySize5,
  },
  // '3': {
  //   title: 'Plus',
  //   group: LeaderboardGroup.PartySizePlus,
  // },
};

interface ILeaderboardRankings {
  boss: IBossLeaderboard;
  timeframe: LeaderboardTimeframe;
  onLeaderboardPage?: boolean;
  tabs?: TabMap;
  leaderboard?: LeaderboardFragment;
}

const LeaderboardRankings: FunctionComponent<ILeaderboardRankings> = (
  props,
) => {
  const { boss, timeframe, onLeaderboardPage } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const tabs = { ...(props.tabs ?? defaultTabs) };
  const tabKeys = Object.keys(tabs);
  const defaultTabId = tabKeys.length > 0 ? tabKeys[0] : undefined;
  const noTabs = !!props.leaderboard;

  const client = useApolloClient();
  const player = useWalletSelector((s) => s.contents);

  const [playerPlacingRef] = useScrollToPlayerPlacingHook();

  const [group, setGroup] = useState<string>(defaultTabId ?? '');
  const [loadedBestPlayerGroup, setLoadedBestPlayerGroup] = useState<boolean>(
    !Boolean(player),
  );

  const [getPlayerBestScoreGroup, playersBestScoreGroup] =
    useBossLeaderboardPlayerBestScoreGroupLazyQuery();
  const loadedPlayerScores = !!playersBestScoreGroup.data;
  const leaderboardRanks: LeaderboardRankPlayerFragment[] =
    playersBestScoreGroup.data?.leaderboardRanksPlayer ?? [];

  const [getLeaderboards, { data, loading }] = useBossLeaderboardLazyQuery({
    variables: {
      timeframe,
      boss: boss.leaderboardStat,
      group: tabs[group]?.group,
    },
  });

  const leaderboards = data?.leaderboards ?? [];
  const leaderboard =
    props.leaderboard ??
    (leaderboards.length > 0 ? leaderboards[0] : undefined);

  useEffect(() => {
    if (player) {
      getPlayerBestScoreGroup({
        variables: { playerId: player.id },
      });
    }
  }, [player]);

  useEffect(() => {
    if (loadedPlayerScores) {
      setTabForBestPlayerScore();
    }
  }, [loadedPlayerScores, leaderboardRanks]);

  useEffect(() => {
    if (!props.leaderboard) {
      client.refetchQueries({ include: [BossLeaderboardDocument] });
      getLeaderboards();
    }
  }, [group]);

  function setTabForBestPlayerScore() {
    const groupScoresForBoss = leaderboardRanks.filter(
      ({ slot }) =>
        slot.stat === boss.leaderboardStat && slot.timeframe === timeframe,
    );

    if (groupScoresForBoss.length === 0) {
      return setLoadedBestPlayerGroup(true);
    }

    const bestScoreGroup = groupScoresForBoss.reduce((prev, current) =>
      prev.slot.score > current.slot.score ? prev : current,
    );

    const tabForGroup = Object.entries(defaultTabs).find(
      ([_, { group }]) => group === bestScoreGroup.slot.leaderGroup,
    );

    if (!!tabForGroup) setGroup(tabForGroup[0]);
    setLoadedBestPlayerGroup(true);
  }

  function renderTabs() {
    return (
      <Tabs
        variant={'fullWidth'}
        value={group}
        className={classes.tabs}
        TabIndicatorProps={{
          style: {
            display: 'none',
          },
        }}
        onChange={(e, v) => setGroup(v)}>
        {Object.keys(tabs).map((tabKey) => {
          const { main, light, dark } = themev2.palette.secondary;
          const tabStyles = {
            backgroundColor: group === tabKey ? white : main,
            color: `${group === tabKey ? dark : light} !important`,
            fontWeight: `${700} !important`,
          };

          return (
            <Tab
              key={tabKey}
              color={'secondary'}
              className={classes.tab}
              label={tabs[tabKey].title}
              value={tabKey}
              sx={tabStyles}
            />
          );
        })}
      </Tabs>
    );
  }

  function renderGroups() {
    if (noTabs) {
      return (
        <>
          <Typography variant={'h5'} className={classes.leaderboardBlock}>
            Tournament Scoreboard
          </Typography>
          <Container
            className={clsx(classes.tierCont, common.hideScrollBar, {
              [classes.longTierCont]: onLeaderboardPage,
            })}>
            <LeaderboardTiers
              playerPlacingRef={playerPlacingRef}
              timeframe={timeframe}
              leaderboard={{ ...leaderboard! }}
              boss={boss.name}
              loading={loading || !loadedBestPlayerGroup}
              emptyMessage={'No scores recorded yet.'}
            />
          </Container>
        </>
      );
    }

    return tabKeys.map((tabKey) => {
      return (
        <TabPanel
          key={`tab-${tabKey}`}
          value={tabKey}
          className={classes.tabPanel}>
          {leaderboard && (
            <Container
              className={clsx(classes.tierCont, common.hideScrollBar, {
                [classes.longTierCont]: onLeaderboardPage,
              })}>
              <LeaderboardTiers
                playerPlacingRef={playerPlacingRef}
                leaderboard={leaderboard}
                boss={boss.name}
                timeframe={timeframe}
                loading={loading || !loadedBestPlayerGroup}
                emptyMessage={'No scores recorded yet.'}
              />
            </Container>
          )}
        </TabPanel>
      );
    });
  }

  return (
    <TabContext value={group}>
      {!noTabs && renderTabs()}
      <div className={classes.groupContainer}>{renderGroups()}</div>
    </TabContext>
  );
};

export default LeaderboardRankings;
