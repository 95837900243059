import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: (props: any) => props.backgroundColor ?? 'transparent',
    borderRadius: (props: any) => `${props.borderRadius}px` ?? '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1px',

    '& img': {
      marginLeft: '1px',
    },
  },
}));

interface IFurballHead {
  tokenId: string;
  size: number;
  borderRadius?: number;
  backgroundColor?: string;
}

export const FurballHead: FunctionComponent<IFurballHead> = (props) => {
  const { tokenId, size, borderRadius, backgroundColor } = props;
  const classes = useStyles({ size, borderRadius, backgroundColor });
  const dpi = window.devicePixelRatio;
  const src = `https://goerli.furballs.com/render/avatar/${tokenId}?size=${Math.round(
    size * dpi,
  )}`;

  return (
    <div className={classes.root}>
      <img style={{ width: size, height: size }} src={src} />
    </div>
  );
};

export default FurballHead;
