import { SvgEditionSlot } from '../part';
import * as React from 'react';

const AsparagusSpear = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#b288b8'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M322.06 60.87c-3.02 1.11-6.42-.37-7.6-3.31a5.53 5.53 0 0 1 1.05-5.81l10.96-10.66-.71 15.12c-.24 2.11-1.66 3.91-3.7 4.66z'
    />
    <path
      fill='#b288b8'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M326.43 71.82c-3.68 1.36-7.83-.45-9.27-4.04a6.758 6.758 0 0 1 1.28-7.09L331.8 47.7l-.87 18.43c-.27 2.58-2.01 4.77-4.5 5.69z'
    />
    <path
      fill='#b288b8'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M314.72 65.07c-3 1.65-6.84.62-8.58-2.31a5.932 5.932 0 0 1 .13-6.33l9.79-13.03 1.78 16.14c.12 2.28-1.09 4.41-3.12 5.53z'
    />
    <path
      fill='#a8e080'
      d='M304.04 128.15c-.75 5.77-3.92 10.96-6.79 15.95l-19.5 40.55a70.383 70.383 0 0 0-3.41 8.79l-21.78 77.33a43.792 43.792 0 0 0-1.89 10.14l-.84 12.13c-.32 4.6-4.57 7.9-9.1 7.05-7.22-1.36-17.29-4.82-23.05-13.63l20.37-64.62c.87-2.76 1.98-5.43 3.32-8l23.31-44.79c.99-1.9 1.74-3.92 2.24-6l5.87-24.77c.57-2.39 1.33-4.71 2.28-6.96l16.62-39.18 17.28 13.98-4.93 22.03z'
    />
    <path
      fill='#ccffa8'
      d='M250.67 280.91c.24-3.49.87-6.88 1.89-10.14l21.78-77.33c.94-3 2.08-5.93 3.41-8.79l19.5-40.55c2.86-5 6.04-10.18 6.79-15.95l4.93-22.02-9.56-7.73-16.36 38.56c-.95 2.25-1.71 4.57-2.28 6.96l-5.87 24.77c-.49 2.09-1.25 4.1-2.24 6l-23.31 44.79c-.9 1.73-1.69 3.5-2.38 5.31-.63 1.65-.93 3.4-.92 5.16.15 19.31-3.82 38.43-11.63 56.09l-4.71 10.65c1.19 1.83-1.52-1.16-.08.24 3.67 1.85 7.79 2.85 11.31 3.4 4.46.7 8.56-2.59 8.87-7.09l.86-12.33z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M304.04 128.15c-.75 5.77-3.92 10.96-6.79 15.95l-19.5 40.55a70.383 70.383 0 0 0-3.41 8.79l-21.78 77.33a43.792 43.792 0 0 0-1.89 10.14l-.84 12.13c-.32 4.6-4.57 7.9-9.1 7.05-7.22-1.36-17.29-4.82-23.05-13.63l20.37-64.62c.87-2.76 1.98-5.43 3.32-8l23.31-44.79c.99-1.9 1.74-3.92 2.24-6l5.87-24.77c.57-2.39 1.33-4.71 2.28-6.96l16.62-39.18 17.28 13.98-4.93 22.03z'
    />
    <path
      fill='#b29788'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M305.31 78.84c-3.74 1.9-8.37.5-10.34-3.13a7.191 7.191 0 0 1 .49-7.68l10.59-15.07 3.35 19.28c.01 2.77-1.56 5.31-4.09 6.6z'
    />
    <path
      fill='#b288b8'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M323.74 86.89c-3.63 1.24-7.64-.62-8.96-4.16a6.603 6.603 0 0 1 1.42-6.89l13.38-12.38-1.3 17.98c-.34 2.51-2.09 4.61-4.54 5.45z'
    />
    <path
      fill='#b29788'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M313.08 81.15c-3.63 1.24-7.64-.62-8.96-4.16a6.603 6.603 0 0 1 1.42-6.89l13.69-13.64-1.62 19.24c-.33 2.52-2.08 4.62-4.53 5.45z'
    />
    <path
      fill='#b2c588'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M298.15 88.47c-2.74 1.41-6.15.4-7.61-2.25-.99-1.8-.87-3.97.32-5.65l9.18-11.42 1.08 14.45c.03 2.04-1.11 3.92-2.97 4.87z'
    />
    <path
      fill='#b29788'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M312.97 98.1c-3.83-.06-6.98-3.16-7.03-6.93a6.606 6.606 0 0 1 3.66-6.01l15.64-5.45-6.17 14.8c-1.15 2.24-3.51 3.63-6.1 3.59z'
    />
    <path
      fill='#b2c588'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M307.05 90.41c-3.63 1.24-7.64-.6-8.96-4.12-.89-2.38-.33-5.02 1.44-6.86l13.28-10.78-1.21 16.33c-.33 2.5-2.09 4.59-4.55 5.43zM290.21 161.39c-2.36.49-4.75-1.01-5.32-3.35-.39-1.59.15-3.23 1.39-4.25l9.28-6.78-2.11 11.29c-.39 1.56-1.64 2.75-3.24 3.09zM293.31 130.24c-1.73 1.68-4.55 1.69-6.29.02a4.213 4.213 0 0 1-1.11-4.34l4.21-10.7 4.27 10.67c.51 1.53.09 3.21-1.08 4.35z'
    />
    <path
      fill='#b2c588'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M308.72 112.44c-3.08-.2-5.5-2.88-5.4-5.98.07-2.1 1.29-3.95 3.17-4.79l13.76-5.21-6.48 13.27a5.367 5.367 0 0 1-5.05 2.71z'
    />
    <path
      fill='#b2c588'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M308.71 101.41c-3.1.99-6.5-.73-7.59-3.83-.74-2.1-.25-4.39 1.28-5.95l11.51-10.41-1.27 15.57a5.678 5.678 0 0 1-3.93 4.62zM296.64 98.01c-2.6 1.14-5.71-.03-6.94-2.61-.83-1.74-.61-3.77.57-5.25l9.04-10.05.31 13.57a4.937 4.937 0 0 1-2.98 4.34z'
    />
    <path
      fill='#b2c588'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M300.77 106.04c-3.09 1.11-6.47-.41-7.56-3.39-.74-2.02-.25-4.28 1.27-5.88l11.45-10.72-1.27 15.3c-.3 2.13-1.8 3.94-3.89 4.69z'
    />
  </svg>
);

export default AsparagusSpear;
