import { SvgEditionSlot } from '../part';
import * as React from 'react';

const SpikedCollar = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#c2ff97'
      d='M196.32 216.64c-31.75 0-58.58-16.98-67.29-34.52-.14.53-4.54 5.13-6.11 10.16-1.17 3.77-1.32 9.96-1.32 9.96 1.18 1.04 45.12 46.32 117.48 20.4l1.46-2.03a30.2 30.2 0 0 0 5.68-17.42l.01-1.99c-12.72 9.02-30.39 15.44-49.91 15.44z'
    />
    <path
      fill='#abeb7f'
      d='m242.16 214.13-1.46 2.03C168.33 242.08 123.19 192.38 123 193c-1.17 3.77-1.4 9.24-1.4 9.24 1.18 1.04 45.12 46.32 117.48 20.4l1.46-2.03c2.91-4.04 4.76-8.73 5.42-13.63a30.51 30.51 0 0 1-3.8 7.15z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinejoin='round'
      strokeMiterlimit={1}
      d='M196.32 216.64c-31.75 0-58.58-16.98-67.29-34.52-.14.53-4.54 5.13-6.11 10.16-1.17 3.77-1.32 9.96-1.32 9.96 1.18 1.04 45.12 46.32 117.48 20.4l1.46-2.03c3.65-5.08 4.8-12.01 4.84-18.26l.85-1.15c-12.72 9.02-30.39 15.44-49.91 15.44z'
    />
    <path
      fill='#FFF'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M233.45 223.01s12.44 7.47 12.35 7.16l-4.25-13.71c-6.05.04-8.59 6.23-8.1 6.55zM210.5 225.5l8 12c.2.25 1-14 1-14-.01-.65-7-1-9 2zM187.51 226.48l3.99 13.02c.08.31 6.42-12.71 6.42-12.71.26-.59-7.42-3.29-10.41-.31zM161.44 220.87l1.67 13.93c.03.32 8.54-11.81 8.54-11.81-5.15-4.49-10.28-2.7-10.21-2.12zM141.71 209.48l-5.13 13.06c-.13.3 13.11-6.36 13.11-6.36-1.19-5.68-7.76-7.24-7.98-6.7zM123.68 195.89l-9.34 10.47c-.22.23 14.5-1.42 14.5-1.42.66-6.44-4.78-9.48-5.16-9.05z'
    />
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
  </svg>
);

export default SpikedCollar;
