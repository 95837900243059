import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import HolidaySnowBackground from './HolidaySnowBackground';
import { IFurballBase } from '../../../wallet/WalletTypes';
import { cdnRoot } from '../../../theme';
import { ZONE_NUM_BATTLE, ZONE_NUM_EXPLORE } from '../../Zones/ZoneHelpers';
import { RealmType } from '../../schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    clipPath: `url(${cdnRoot}/svgs/card-back-mask.svg)`,
  },
  staticBk: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    clipPath: `url(${cdnRoot}/svgs/card-back-mask.svg)`,
    backgroundPosition: 'top',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
  },
}));

interface IFurballBackground {
  furball: IFurballBase;
  disableAnimation?: boolean;
  className?: string;
  widget?: boolean;
}

export function getFurballBackgroundSvg(fb: IFurballBase): string | undefined {
  if (fb.realm && fb.realm !== RealmType.None)
    return `url(${cdnRoot}/svgs/background-${fb.realm.toLowerCase()}.svg)`;
  if (fb.zone === ZONE_NUM_BATTLE)
    return `url(${cdnRoot}/svgs/background-battle.svg)`;
  if (fb.zone === ZONE_NUM_EXPLORE)
    return `url(${cdnRoot}/svgs/background-explore.svg)`;

  return undefined;
}

export function getFurballBackgroundColorCss(fb?: IFurballBase): string {
  return (fb?.backgroundColor ?? '#FFFFFF') + ' !important';
}

export const FurballBackground: FunctionComponent<IFurballBackground> = (
  props,
) => {
  const { furball, disableAnimation, className, widget } = props;
  const classes = useStyles();
  const backgroundColor = getFurballBackgroundColorCss(furball);
  const backgroundImage = widget ? getFurballBackgroundSvg(furball) : undefined;

  return (
    <div className={clsx(classes.root, className)}>
      {/*<div*/}
      {/*  className={className}*/}
      {/*/>*/}
      <div
        className={classes.staticBk}
        style={{ backgroundColor, backgroundImage }}
      />
      {furball && !disableAnimation && (
        <HolidaySnowBackground furball={furball} />
      )}
    </div>
  );
};

export default FurballBackground;
