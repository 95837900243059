import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Fab, FormControl, MenuItem, Theme, Typography } from '@mui/material';
import { ItemGroup } from '../schema';
import SelectCustom from '../SelectCustom';
import { getLootDisplayGroup } from '../../pages/Marketplace/Loot/LootHelpers';
import { IFurball } from '../../wallet/WalletTypes';
import FurballHead from '../Furballs/FurballHead';
import { useWalletSelector } from '../../hooks';
import SvgIconAccount from '../../assets/SvgIconAccount';
import SvgIconBackArrow from '../../assets/SvgIconBackArrow';
import { getItemDef } from '../BossFights/BossRewards/bossRewardsUtils';

const useStyles = makeStyles((theme: Theme) => ({
  filters: {
    marginBottom: '7.5px',
    marginLeft: '7.5px',
    display: 'flex',
    alignItems: 'center',

    '@media (max-width: 1115px)': {
      flexDirection: 'column',
      marginLeft: '0',
      padding: '0 0.5rem',
    },
  },
  select: {
    minWidth: '310px',

    '& > div': {
      border: `2px solid ${theme.palette.common.black} !important`,
      backgroundColor: '#784DFF !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '32px !important',
      padding: '0.5rem 1rem !important',
    },

    '& p': {
      fontWeight: 'bold',
      color: theme.palette.common.white,
    },

    '& svg': {
      fill: theme.palette.common.white,
    },

    '@media (max-width: 1115px)': {
      minWidth: '100% !important',
    },
  },
  selectDropdown: {
    border: `2px solid ${theme.palette.common.black} !important`,
    maxHeight: '400px !important',
  },
  selectFormControl: {
    '@media (max-width: 1115px)': {
      minWidth: '100% !important',
    },
  },
  selectMenuItem: {
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'space-between !important',
    alignItems: 'center !important',
    maxHeight: '47px',
  },
  selectCont: {
    marginRight: '1rem',

    '@media (max-width: 1115px)': {
      marginRight: '0rem',
      marginBottom: '0.5rem',
      width: '100%',
    },
  },
  selectOption: {
    display: 'flex',
    flexDirection: 'row',
  },
  selectOptionIcon: {
    borderRadius: '50%',
    marginRight: '1rem',
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    padding: '2px',

    '& img': {
      height: '30px',
      width: '30px',
    },
  },
  playerAvatar: {
    height: '30px',
    width: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backToAllItems: {
    display: 'flex',

    '@media (max-width: 1115px)': {
      width: '100%',
      justifyContent: 'flex-start',
    },
  },
}));

const FURBALL_FILTER_OPTIONS: IFilterOption[] = [
  { label: 'All Items', value: 'all' },
  {
    label: getLootDisplayGroup(ItemGroup.EquipmentRing),
    value: ItemGroup.EquipmentRing,
  },
  {
    label: getLootDisplayGroup(ItemGroup.EquipmentOffHand),
    value: ItemGroup.EquipmentOffHand,
  },
  { label: getLootDisplayGroup(ItemGroup.Special), value: ItemGroup.Special },
];

export const ITEMGROUP_FILTER_OPTIONS: IFilterOption[] = [
  ...FURBALL_FILTER_OPTIONS,
  { label: getLootDisplayGroup(ItemGroup.Material), value: ItemGroup.Material },
  {
    label: getLootDisplayGroup(ItemGroup.Consumable),
    value: ItemGroup.Consumable,
  },
];

export const ALL_INVENTORIES_OPTION: IFilterOption = {
  label: 'All Inventories',
  value: 'all_inventories',
};

export const PLAYER_INVENTORY_OPTION: IFilterOption = {
  label: 'Player Inventory',
  value: 'player_inventory',
};

export interface IFilterOption {
  label: string;
  value: string;
}

interface IInventoryFilters {
  filterOnItemGroup: IFilterOption;
  setFilterOnItemGroup: (option: IFilterOption) => void;
  filterOnInventoryFor: IFilterOption;
  setFilterOnInventoryFor: (option: IFilterOption) => void;
  furballs: IFurball[];
  selectedItemId: number;
  setSelectedItemId: (n: number) => void;
}

const InventoryFilters: FunctionComponent<IInventoryFilters> = (props) => {
  const {
    filterOnItemGroup,
    setFilterOnItemGroup,
    filterOnInventoryFor,
    setFilterOnInventoryFor,
    selectedItemId,
    setSelectedItemId,
    furballs,
  } = props;
  const classes = useStyles();
  const account = useWalletSelector((s) => s.contents);

  useEffect(() => {
    const nonFbOption = !FURBALL_FILTER_OPTIONS.find(
      ({ value }) => value === filterOnItemGroup.value,
    );

    // if switching from 'all inventories' -> 'furball inventory'
    // and the current option is not available as a filter (e.g. Materials)
    if (nonFbOption) {
      setFilterOnItemGroup(FURBALL_FILTER_OPTIONS[0]);
    }
  }, [filterOnInventoryFor]);

  function getItemGroupOptions() {
    if (
      [ALL_INVENTORIES_OPTION.value, PLAYER_INVENTORY_OPTION.value].includes(
        filterOnInventoryFor.value,
      )
    ) {
      return ITEMGROUP_FILTER_OPTIONS;
    }

    return FURBALL_FILTER_OPTIONS;
  }

  function getInventoryOptions() {
    return [
      ALL_INVENTORIES_OPTION,
      PLAYER_INVENTORY_OPTION,
      ...furballs.map((fb) => {
        const { tokenId, number, name } = fb;
        return { label: name ?? `Furball #${number}`, value: tokenId };
      }),
    ];
  }

  function getInventoryOptionIcon(option: IFilterOption) {
    if (
      [ALL_INVENTORIES_OPTION.value, PLAYER_INVENTORY_OPTION.value].includes(
        option.value,
      )
    ) {
      return (
        <div className={classes.playerAvatar}>
          {!account?.avatar ? (
            <SvgIconAccount style={{ height: 18, width: 18 }} />
          ) : (
            <img src={account?.avatar} />
          )}
        </div>
      );
    }

    return <FurballHead tokenId={option.value} size={30} borderRadius={100} />;
  }

  function renderSelectInventory() {
    const inventoryOptions = getInventoryOptions();

    return (
      <FormControl className={classes.selectFormControl}>
        <SelectCustom
          iconColor='secondary'
          labelId='inventory-fb-select'
          id='inventory-fb-select'
          options={inventoryOptions}
          value={filterOnInventoryFor.value}
          className={classes.select}
          MenuProps={{
            classes: {
              paper: classes.selectDropdown,
            },
          }}>
          {inventoryOptions.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
              disabled={option.value === filterOnInventoryFor.value}
              onClick={(e) => {
                setFilterOnInventoryFor({
                  label: option.label,
                  value: option.value,
                });
              }}>
              <div className={classes.selectOption}>
                <div className={classes.selectOptionIcon}>
                  {getInventoryOptionIcon(option)}
                </div>
                <Typography
                  className={classes.selectMenuItem}
                  lineHeight={'unset'}
                  variant='body1'>
                  {option.label}
                </Typography>
              </div>
            </MenuItem>
          ))}
        </SelectCustom>
      </FormControl>
    );
  }

  function renderFilterOn() {
    const itemGroupOptions = getItemGroupOptions();

    return (
      <FormControl className={classes.selectFormControl}>
        <SelectCustom
          iconColor='secondary'
          labelId='inventory-filter-on'
          id='inventory-filter-on'
          options={itemGroupOptions}
          value={filterOnItemGroup.value}
          className={classes.select}
          MenuProps={{
            classes: {
              paper: classes.selectDropdown,
            },
          }}>
          {itemGroupOptions.map((option, i) => (
            <MenuItem
              key={i}
              value={option.value}
              disabled={option.value === filterOnItemGroup.value}
              onClick={(e) => {
                setFilterOnItemGroup({
                  label: option.label,
                  value: option.value,
                });
              }}>
              <Typography
                className={classes.selectMenuItem}
                lineHeight={'unset'}
                variant='body1'>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </SelectCustom>
      </FormControl>
    );
  }

  const itemDef = getItemDef(selectedItemId);

  return (
    <div className={classes.filters}>
      <div className={classes.selectCont}>{renderSelectInventory()}</div>
      {selectedItemId === 0 && (
        <div className={classes.selectCont}>{renderFilterOn()}</div>
      )}
      {selectedItemId > 0 && (
        <div className={classes.backToAllItems}>
          <Typography variant={'h5'} style={{ color: 'white' }}>
            <Fab
              color={'primary'}
              onClick={() => setSelectedItemId(0)}
              style={{ marginRight: 10 }}>
              <SvgIconBackArrow height={18} width={18} fill={'#000'} />
            </Fab>
            {itemDef && itemDef.name}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default InventoryFilters;
