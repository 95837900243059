import { Card, CardContent, CardHeader } from '@mui/material';
import * as React from 'react';
import useCommonStyles from '../../../components/useCommonStyles';

interface IDuelActiveGamesCard {}

const DuelActiveGamesCard: React.FunctionComponent<IDuelActiveGamesCard> = (
  props,
) => {
  const common = useCommonStyles();

  return null;
  return (
    <Card
      className={common.cardRoot}
      style={{
        width: '100%',
      }}>
      <CardHeader
        className={common.cardHeader}
        // classes={{ title: classes.cardTitle }}
        sx={{ minHeight: '62px' }}
        title={'Ongoing PvP Games'}
      />
      <CardContent className={common.cardContent}></CardContent>
    </Card>
  );
};

export default DuelActiveGamesCard;
