import { ReactNode } from 'react';

export interface IFilterOption {
  id: string | number;
  title: string;
  description?: string;
  icon?: string | ReactNode;
}

export interface IFilterGroup {
  title: string;
  slider?: number;
  checkedOptions: any[];
  options: IFilterOption[];
  updater: (id: any) => void;
}

export enum FILTERKEYS {
  SKILL = 'skill',
  LOOT = 'loot',
  WEAPON = 'weapon',
  LEVEL = 'level',
  BOSSKEY = 'bosskey',
  GENE = 'gene',
  EXP = 'exp',
  FUR = 'fur',
}

export interface IFilterState {
  [FILTERKEYS.SKILL]: string[];
  [FILTERKEYS.LOOT]: string[];
  [FILTERKEYS.WEAPON]: string[];
  [FILTERKEYS.LEVEL]: string[];
  [FILTERKEYS.BOSSKEY]: string[];
  [FILTERKEYS.GENE]: string[];
  [FILTERKEYS.EXP]: string[];
  [FILTERKEYS.FUR]: string[];
}
