import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Cat = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffd9cf'
      d='M262.29 71.8c-19.96 5.18-23.16 34.06-23.66 41.95 8.03 7.58 13.47 17.19 15.51 27.57.33 1.68.89 3.28 1.65 4.77l.44.3c7.08-2.12 13.5-21.9 13.5-21.9 7.86-22.25-7.44-52.69-7.44-52.69z'
    />
    <path
      fill='#ffc4b5'
      d='M234.16 109.94c10.49 8.03 17.58 19.17 19.98 31.37.27 1.35.68 2.65 1.23 3.88 1.09-.94 1.3-3.39-.19-5.94-12.72-21.81 6.88-65.4 6.88-65.4-17.92 4.65-28.7 24.19-32.67 32.8 1.65 1.02 3.24 2.12 4.77 3.29z'
    />
    <path
      fill={props.renderer.color1}
      d='M262.17 71.56c-23.17 6.01-35.86 39.56-35.86 39.56l-.07.56a9.515 9.515 0 0 0 6.47 10.3l10.01 3.27c-5.73-32.41 19.45-53.69 19.45-53.69z'
    />
    <path
      opacity={0.08}
      d='M226.31 111.12s12.48-33.33 36.15-38.95c0 0-33.22 20.83-25.55 39.78.75 1.86-.23 3.81-2.09 3.57l-8.51-4.4z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M226.61 110.78c.82-2.18 12.31-33.19 35.56-39.22 0 0 23.13 52.83-6.05 74.58'
    />
    <path
      fill='#ffd9cf'
      d='M135.18 143.53s.8.64 2.11 1.62c.54-1.22.94-2.5 1.21-3.84 1.94-9.88 6.96-19.06 14.36-26.46-6.87-8.34-23.03-24.76-40.02-19.04 0 .01 3.79 33.53 22.34 47.72z'
    />
    <path
      fill='#ffc4b5'
      d='M144.96 124.86c3.45-5.63 8.02-10.7 13.52-14.92.8-.61 1.62-1.2 2.45-1.78-6.16-4.13-27.75-17.17-46.79-10.77 0 .01 18.63 11.94 30.82 27.47z'
    />
    <path
      fill={props.renderer.color1}
      d='m164.85 110.1-.86-.9s-28.69-20.95-51.15-13.39c0 0 32.4 4.01 45.2 33.99l7.44-15.96c.58-1.25.33-2.74-.63-3.74z'
    />
    <path
      opacity={0.08}
      d='M163.99 109.2s-28.39-20.88-51.07-12.72c0 0 38.82-.72 42.72 19.15.38 1.95 2.25 3.03 3.67 1.83l4.68-8.26z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M163.56 109.08c-1.86-1.36-28.17-20.86-50.72-13.27 0 0 5.88 35.18 26.1 51.04M262.17 71.56s-32 36.54-15.36 56.57'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M112.92 96.48s31.7 7.66 41.25 23.61'
    />
  </svg>
);

export default Cat;
