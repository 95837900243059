import React, { FunctionComponent } from 'react';
import { ITransactionError, TxnErrReason } from './WalletTypes';
import OkDialog from '../components/OkDialog';

type Props = {
  error?: ITransactionError;
  onClose: () => void;
};

const TransactionErrorDialog: FunctionComponent<Props> = (props) => {
  const { error, onClose } = props;

  function getTitle(t: TxnErrReason): string {
    if (t === TxnErrReason.OutOfGas) return 'Out of Gas';
    if (t === TxnErrReason.MaxGas) return 'Max Gas';
    if (t === TxnErrReason.ErrGas) return 'Gas Error';
    if (t === TxnErrReason.UserDenied) return 'User Denied';
    if (t === TxnErrReason.Replaced) return 'Transaction Replaced';
    if (t === TxnErrReason.Replacement) return 'Already Exists';
    if (t === TxnErrReason.Nonce) return 'Account Sync';
    if (t === TxnErrReason.Pre) return 'Prehistory';
    if (t === TxnErrReason.Balance) return 'Insufficient Funds';
    return 'Transaction Error';
  }

  function getBody(t: TxnErrReason, msg: string): React.ReactNode {
    if (t === TxnErrReason.OutOfGas)
      return 'Gas fees may be high right now. You can manually edit your gas fee in MetaMask to try to avoid this.';
    if (t === TxnErrReason.MaxGas)
      return 'The transaction hit the max gas limit. This is bad; please try again later and report the issue.';
    if (t === TxnErrReason.ErrGas)
      return 'A good gas estimate could not be established. Please refresh the page and try again.';
    if (t === TxnErrReason.UserDenied)
      return 'The transaction was cancelled at your request.';
    if (t === TxnErrReason.Replacement)
      return 'There is already a pending transaction of that type from you, waiting to be processed by the blockchain';
    if (t === TxnErrReason.Replaced)
      return 'Your transaction was replaced. If you intended to speed up a transaction, this indicates it was successful.';
    if (t === TxnErrReason.Nonce)
      return 'Your account is out-of-sync and needs to be reset (nonce is too high). See "Advanced" in MetaMask.';
    if (t === TxnErrReason.Pre)
      return (
        <React.Fragment>
          The presale (OG whitelist) is currently in progress.
          <br />
          The game does not begin (and rewards do not start to accrue) until it
          is over.
        </React.Fragment>
      );
    if (t === TxnErrReason.Balance)
      return 'You do not have enough $FUR for that purchase.';
    return msg;
  }

  return (
    <OkDialog
      title={error ? getTitle(error.reason) : ''}
      open={!!error}
      topRight={true}
      onClose={onClose}>
      {error && getBody(error.reason, error.message)}
    </OkDialog>
  );
};

export default TransactionErrorDialog;
