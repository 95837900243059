import { BigNumber } from 'ethers';

export function normalizeTokenId(tokenId: string): string {
  if (!tokenId.toLowerCase().startsWith('0x')) tokenId = '0x' + tokenId;
  return BigNumber.from(tokenId)._hex;
}

// export function generateRandomFurballToken(): string {
//   const ret = "00";
//
//   return ret;
// }
