import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import { IFurballWalletContents } from '../../wallet/WalletTypes';
import { CurrentPlayerFragment, MeDocument, useLoginMutation } from '../schema';
import { AuthErrorModal } from './AuthErrorModal';
import { AuthResignModal } from './AuthResignModal';
import { signOAuthToken } from './OAuthSig';

import WalletContext from '../../wallet/WalletContext';
import WalletSlice from '../../wallet/WalletSlice';

import {
  CookieKey,
  FurAccountTypes,
  FurCookieNames,
  IAuthData,
} from './AuthTypes';
import { useCookies } from 'react-cookie';
import {
  defaultLogManager,
  eventEmitter,
  ILogger,
  useFurComponent,
} from '../../utils';
import usePromise from 'react-promise-suspense';
import { useApolloClient } from '@apollo/client';
import { inIframe } from '../../wallet/Wallet';
import { useHistory, useLocation } from 'react-router';
import { ensureAnalytics, updateAnalytics } from '../../Analytics';
import { getBlockchainName } from '../../FurballClient';
import { isVuplexExpected } from '../../pages/App/vuplexBridge';

export enum LS {
  AUTH_STATE = 'FUR.AuthState',
  AUTH_IGNORED = 'FUR.AuthIgnored',
}

export enum AUTH_EVENTS {
  UNAUTHENTICATED = 'auth-error',
  DISCONNECTED = 'wallet-disconnected',
}

export function disconnectAuth(network: string) {
  if (!inIframe()) {
    // localStorage.removeItem(LS.AUTH_STATE + '.' + network);
    localStorage.removeItem(LS.AUTH_STATE);
    localStorage.removeItem(LS.AUTH_IGNORED);
  }
  eventEmitter.emit(AUTH_EVENTS.DISCONNECTED);
}

export function triggerAuthPrompt() {
  eventEmitter.emit(AUTH_EVENTS.UNAUTHENTICATED);
}

const defaultState: IAuthData = {
  sessionSecret: '',
};

function loadAuthStorage(network: string): IAuthData[] {
  if (inIframe()) return [];

  const log = defaultLogManager.getLogger('Auth');
  const stored = localStorage.getItem(LS.AUTH_STATE + '.' + network);

  if (!stored) return [];

  try {
    const data = JSON.parse(stored);
    return data as IAuthData[];
  } catch (e) {
    log.warn('failed to load state from JSON', stored, e);
    return [];
  }
}

function loadAuthFromStorage(
  network: string,
  requiredPlayerId?: string,
): IAuthData | undefined {
  const states = loadAuthStorage(network);

  const storedState = requiredPlayerId
    ? states.find((s) => s.playerId === requiredPlayerId)
    : states[0];
  return storedState;
}

function removePlayerFromAuthStorage(
  log: ILogger,
  network: string,
  playerId?: string,
): IAuthData[] {
  const oldStates = loadAuthStorage(network);
  const states = oldStates.filter((s) => s.playerId !== playerId);

  if (inIframe()) return states;
  log.info('[AUTH] cleared', playerId, oldStates, states);
  localStorage.setItem(LS.AUTH_STATE + '.' + network, JSON.stringify(states));
  localStorage.removeItem(LS.AUTH_STATE);
  return states;
}

function updateAuthStorage(network: string, authData: IAuthData): IAuthData {
  if (inIframe()) return authData;
  const states = [
    authData,
    ...loadAuthStorage(network).filter((s) => s.playerId !== authData.playerId),
  ];

  localStorage.setItem(LS.AUTH_STATE + '.' + network, JSON.stringify(states));

  const authStr = JSON.stringify(authData);
  localStorage.setItem(LS.AUTH_STATE, authStr);

  localStorage.removeItem(LS.AUTH_IGNORED);
  return authData;
}

const useAuth = () => {
  const context = React.useContext(WalletContext);
  const netName = context?.network.name ?? getBlockchainName();
  const history = useHistory();
  const [networkName, setNetworkName] = useState(netName);
  const [nonce, setNonce] = React.useState(0);
  const [cookies, setCookie] = useCookies(Object.values(FurCookieNames));
  const [authState, setAuthState] = useState<IAuthData>(
    loadAuthFromStorage(networkName) ??
      loadAuthFromCookies() ?? { ...defaultState },
  );
  const dispatch = useAppDispatch();
  const [redirect, setRedirect] = React.useState<string | undefined>();
  const [isSignedIn, setSignedIn] = React.useState(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [showPrompt, setShowPrompt] = useState<boolean>(false);
  const [login, { loading: loggingIn }] = useLoginMutation();
  const addr = useWalletSelector((s) => s.address);
  const { log } = useFurComponent(useAuth.name);

  React.useEffect(() => {
    setNetworkName(netName);
    // setSignedIn(!!authState?.sessionSecret); // first state
  }, [netName]);

  const ignoreAuth = () => {
    if (inIframe()) return;
    // localStorage.setItem(LS.AUTH_IGNORED, 'true');
    setShowError(false);
    setShowPrompt(false);
  };

  function updateAuthState(data: IAuthData, updateAccount: boolean): boolean {
    const changed = Object.keys(FurCookieNames).find((k) => {
      const cookieKey: CookieKey = k as CookieKey;
      return data[cookieKey] !== authState[cookieKey];
    });

    setAuthState(data);
    // @ts-ignore
    window.token = data.sessionSecret;
    if (changed || updateAccount) {
      log.info('[AUTH] changed for', data.playerId);
      setNonce(nonce + Math.round(Math.random() * 10000));
      return true;
    } else {
      return false;
    }
  }

  function handleAuth(
    playerType: FurAccountTypes,
    sessionSecret: string,
    playerId?: string,
  ) {
    const newState: IAuthData = {
      ...authState,
      playerType,
      sessionSecret,
      playerId,
    };

    setShowPrompt(false);
    log.info('[AUTH] complete', newState.playerId);
    const suff =
      playerType === FurAccountTypes.PLAYER ? 'signature' : 'scholar';
    setRedirect(`/welcome/${suff}`);

    updateAuthState(updateAuthStorage(networkName, newState), true);

    history.push(`/welcome/${suff}`);
  }

  const signInWallet = useCallback(
    async (skipIgnore?: boolean) => {
      if (inIframe()) return;

      setShowError(false);
      const ignoredAuth = localStorage.getItem(LS.AUTH_IGNORED);
      const shouldIgnore = ignoredAuth && !skipIgnore;

      if (shouldIgnore || !addr || !context) {
        return;
      }

      const storedAuth = loadAuthFromStorage(networkName, addr);
      if (storedAuth) {
        log.info('[AUTH] load storage', storedAuth.playerId);
        if (updateAuthState(storedAuth, false)) {
          history.push('/welcome/signature');
        }
        return;
      }

      try {
        const oauthData = await signOAuthToken(addr, context);
        const result = await login({ variables: { token: oauthData } });
        const data = result.data?.login;

        if (!data) return;

        handleAuth(
          FurAccountTypes.PLAYER,
          data.secret,
          data.session.accountId || undefined,
        );
      } catch (error) {
        log.info('auth failure; showing expiration dialog', error);
        setShowError(true);
      }
    },
    [addr],
  );

  useEffect(() => {
    if (isSignedIn) return;
    if (!addr) {
      // always fall back on loading from cookies
      const auth = loadAuthFromCookies();
      log.info('[AUTH] load cookies', auth.playerId);
      if (auth.playerId) updateAuthState(auth, false);
    }
  }, [addr, isSignedIn]);

  function loadAuthFromCookies(): IAuthData {
    const playerCookies = { ...defaultState };

    const keyNames: string[] = Object.keys(FurCookieNames);

    keyNames.forEach((keyname, i) => {
      const cookieKey: CookieKey = keyname as CookieKey;
      const cookieName = FurCookieNames[cookieKey];

      if (cookieName) playerCookies[cookieKey] = cookies[cookieName];
    });

    // if (playerCookies.playerId !== authState.playerId || playerCookies.sessionSecret !== authState.sessionSecret)
    // setAuthState(updateAuthStorage(networkName, playerCookies));

    return playerCookies;
  }

  useEffect(() => {
    const prompt = () => {
      log.info('[AUTH] authenticated; showing prompt');
      setShowPrompt(true);
    };

    const clearAuthState = () => {
      log.info('[AUTH] disconnected; clearing state');
      // setAuthState(loadAuthFromStorage());
      // logout();
    };

    eventEmitter.once(AUTH_EVENTS.UNAUTHENTICATED, prompt);
    eventEmitter.once(AUTH_EVENTS.DISCONNECTED, clearAuthState);

    return () => {
      eventEmitter.removeListener(AUTH_EVENTS.UNAUTHENTICATED, prompt);
      eventEmitter.removeListener(AUTH_EVENTS.DISCONNECTED, clearAuthState);
    };
  }, [showPrompt, authState]);

  function logout(clear: boolean): void {
    // const keyNames: string[] = Object.keys(FurCookieNames);
    // keyNames.forEach((keyname, i) => {
    //   const cookieKey: CookieKey = keyname as CookieKey;
    //
    //   // Locally clearing is a bad idea!
    //   // The /path does not match the server
    //
    //   // const cookieName = FurCookieNames[cookieKey];
    //   // if (cookieName) setCookie(cookieName, '');
    // });
    log.info('[AUTH] logging out');

    setShowPrompt(false);
    setShowError(false);

    setSignedIn(false);
    setAuthState({ ...defaultState });

    if (!inIframe()) {
      if (clear) {
        removePlayerFromAuthStorage(log, networkName, authState.playerId);
        localStorage.removeItem(LS.AUTH_IGNORED);
      } else {
        localStorage.removeItem(LS.AUTH_STATE);
      }
    }

    if (clear) {
      dispatch(WalletSlice.actions.clearForLogout());

      setNonce(nonce + Math.round(Math.random() * 10000));
      history.push(isVuplexExpected() ? '/app/landing' : '/');
    }

    log.info('[AUTH] logged out');
  }

  return {
    networkName,
    addr,
    signInWallet,
    authState,
    // setAuthState,
    showError,
    setShowError,
    setShowPrompt,
    showPrompt,
    ignoreAuth,
    loggingIn,
    logout,
    // loadAuthFromCookies,
    isSignedIn,
    setSignedIn,
    nonce,
    setNonce,
    handleAuth,
    redirect,
    setRedirect,
  };
};

const AuthContext = createContext({} as ReturnType<typeof useAuth>);

export const AuthProvider: FunctionComponent = ({ children }) => {
  const auth = useAuth();
  const ignoredAuth = inIframe() ? true : localStorage.getItem(LS.AUTH_IGNORED);
  const nonce = auth.nonce;
  // const curPlayerId = useWalletSelector((c) => c.contents?.id);
  const authenticatedPlayerId = auth.authState.playerId;
  // let localSignedIn: boolean | undefined;
  // const currentPlayerId = useWalletSelector((s) => s.id);

  const { log } = useFurComponent(AuthProvider);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const client = useApolloClient();
  const [loadedPlayerId, setLoadedPlayerId] = React.useState<
    string | undefined | null
  >(undefined); // undefined = no state yet; null = no player
  const expectedPlayerId = auth.addr ?? loadedPlayerId ?? authenticatedPlayerId;
  const curPlayerId = useWalletSelector((c) => c.contents?.id ?? '');
  // const numFurballs = useWalletSelector((c) => c.contents?.numFurballs ?? 0);
  // const numRented = useWalletSelector((c) => c.contents?.numRented ?? 0);
  const networkName = auth.networkName;

  React.useEffect(() => {
    const to = setTimeout(() => ensureAnalytics, 2000);
    return () => clearTimeout(to);
  }, []);

  function doLogout(clear: boolean) {
    auth.logout(clear);
    setLoaded();
  }

  function setLoaded(
    playerId?: string,
    playerType?: string,
    numFurballs = 0,
    numRented = 0,
    bossBattleCount = 0,
    fuelSpent = 0,
  ) {
    playerType = playerType ?? 'None';
    updateAnalytics({
      playerId,
      numFurballs,
      playerType,
      numRented,
      bossBattleCount,
      fuelSpent,
    });
    log.info('[AUTH] set loaded', playerId, !!playerId, auth.isSignedIn);
    const hasPlayerId = !!playerId && playerId.length > 0;
    auth.setSignedIn(hasPlayerId);
    setLoadedPlayerId(playerId ?? null);
    // sendVuplexMessage({
    //   topic: 'Authenticated',
    //   data: { id: playerId, type: playerType },
    // });
  }

  React.useEffect(() => {
    if (
      !ignoredAuth &&
      expectedPlayerId?.toLowerCase() !== loadedPlayerId?.toLowerCase() &&
      expectedPlayerId?.toLowerCase() !== curPlayerId?.toLowerCase()
    ) {
      log.info('[AUTH] expect', expectedPlayerId, '!=', loadedPlayerId);
      auth.setNonce(nonce + Math.round(Math.random() * 10000));
    }
  }, [ignoredAuth, expectedPlayerId, loadedPlayerId]);

  const localSignedIn: boolean = usePromise(async () => {
    log.info('[AUTH] begin new');
    if (inIframe()) {
      doLogout(false);
      return false;
    }

    // Make sure it's ready for the apollo client, which requires local storage session token
    const authData =
      loadAuthFromStorage(networkName, expectedPlayerId) ??
      (auth.authState?.playerId === expectedPlayerId
        ? auth.authState
        : undefined);

    const sec = authData?.sessionSecret;
    const secret = !sec || sec.length <= 0 ? undefined : sec;
    if (!secret) {
      log.info('[AUTH] abort; no secret', expectedPlayerId, 'on', networkName);
      doLogout(false);
      return false;
    }

    // write back current order/state
    if (authData) updateAuthStorage(networkName, authData);

    if (!authData) {
      log.warn('[AUTH] cannot load', expectedPlayerId, 'on', networkName);
      doLogout(false);
      return false;
    }
    log.info('[AUTH] resuming', authData.playerId, 'from', expectedPlayerId);

    try {
      await client.refetchQueries({ include: 'active' });
      const { data, error } = await client.query({
        query: MeDocument,
        fetchPolicy: 'no-cache',
      });
      // if (cancelled) return; // aborted during async execution

      const player = data?.currentPlayer as CurrentPlayerFragment;
      if (!player) {
        log.error(
          '[AUTH] login failed for',
          expectedPlayerId,
          'failed',
          data,
          error,
        );
        doLogout(true);
        return false;
      }

      // const account = player as FurAccount;
      log.info('[AUTH] login finished', player.id);
      const numRented = 0;
      const content: IFurballWalletContents = {
        ...player,
        tokenIds: [],
      };

      dispatch(WalletSlice.actions.setWalletContents(content));
      setLoaded(
        content.id,
        player.__typename,
        player.furBalance ?? 0,
        numRented,
        player.bossBattleCount,
        player.tixBought,
      );

      auth.setShowError(false);
      auth.setShowPrompt(false);

      // redirect to play page
      const redirectPath = auth.redirect ?? '/play';
      const shouldRedirect =
        !!auth.redirect && !location.pathname.startsWith(redirectPath);
      if (shouldRedirect) {
        auth.setRedirect(undefined);
        log.info('[AUTH] redirecting to', redirectPath);
        history.push(redirectPath);
      }
      return true;
    } catch (e) {
      log.error('[AUTH] login for', expectedPlayerId, 'failed', e);
      doLogout(true);
      return false;
    }
  }, [networkName, nonce]);

  return (
    <AuthContext.Provider value={{ ...auth, isSignedIn: localSignedIn }}>
      {children}
      <AuthErrorModal
        open={auth.showError}
        setOpen={auth.ignoreAuth}
        signIn={auth.signInWallet}
        loading={auth.loggingIn}
      />

      <AuthResignModal
        open={!!auth.showPrompt && !auth.showError}
        signIn={auth.signInWallet}
        loading={auth.loggingIn}
      />
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
