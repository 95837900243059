import { SvgEditionSlot } from '../part';
import * as React from 'react';

const PoopSquasher = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffea93'
      d='m268.35 91.01-41.06 190.15c-1.43 6.59 2.76 13.1 9.35 14.53 6.59 1.43 13.1-2.76 14.53-9.35l41.06-190.15'
    />
    <path
      opacity={0.9}
      fill='#f8d276'
      d='M231.04 292.8c4.03-1.29 7.28-4.62 8.24-9.05l41.81-193.57-11.98-2.6-41.81 193.57c-.97 4.43.6 8.81 3.74 11.65z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m268.35 91.01-41.06 190.15c-1.43 6.59 2.76 13.1 9.35 14.53l1.99.26c8.15.57 11.06-3.41 12.49-10.01l41.11-189.75'
    />
    <path
      fill='#ffa6d3'
      d='m285.04 113.06-17.13-3.71c-5.85-1.27-9.57-7.04-8.3-12.9l1.69-7.8 38.33 8.31-1.69 7.8c-1.27 5.86-7.05 9.57-12.9 8.3z'
    />
    <path
      fill='#ff80c0'
      d='M282.52 98.58c-1.43 6.62-7.46 11.06-14.01 10.72.68.27 1.38.48 2.11.64l11.7 2.54c7.35 1.59 14.6-3.07 16.2-10.43'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      d='m285.04 113.06-17.13-3.71c-5.85-1.27-9.57-7.04-8.3-12.9l1.69-7.8 38.33 8.31-1.69 7.8c-1.27 5.86-7.05 9.57-12.9 8.3z'
    />
    <path
      fill='#ffa6d3'
      d='M333.03 66.89c-5 23.07-29.13 37.42-53.9 32.06s-40.79-28.42-35.79-51.5'
    />
    <path
      fill='#ff80c0'
      d='m333.03 66.89-28.05-6.08c-3.65 16.82-5.48 36.09-25.39 38.23 24.61 5.09 48.47-9.22 53.44-32.15z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M331.73 67.66c-4.88 22.52-28.43 36.52-52.6 31.28s-39.81-27.74-34.92-50.26'
    />
    <ellipse
      transform='rotate(-77.766 288.197 57.16)'
      fill='#ffbfdf'
      cx={288}
      cy={57}
      rx={12}
      ry={45}
    />
    <ellipse
      transform='rotate(-77.766 288.197 57.16)'
      fill='#ff80c0'
      cx={288}
      cy={57}
      rx={5}
      ry={31}
    />
    <ellipse
      transform='rotate(-77.766 288.197 57.16)'
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      cx={288}
      cy={57}
      rx={5}
      ry={31}
    />
    <ellipse
      transform='rotate(-77.766 288.197 57.16)'
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={288}
      cy={57}
      rx={12}
      ry={45}
    />
  </svg>
);

export default PoopSquasher;
