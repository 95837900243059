import { Typography, Theme, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import React, { FunctionComponent, ReactNode } from 'react';
import { useWalletSelector } from '../hooks';
import WalletContext from './WalletContext';
import { makeStyles } from '@mui/styles';
import { black } from '../themev2';
import { useAuthContext } from '../components/Auth/AuthContext';
import ForceLoginDialog from './ForceLoginDialog';
import { fireLoginBeginEvent } from '../Analytics';
import { useFurComponent } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
  progress: {
    color: `${black} !important`,
  },
}));

type Props = {
  analyticsPathComponent: string;
  className?: string;
  children?: React.ReactNode;
  hideLoading?: boolean;
  title?: ReactNode;
  block?: boolean;
};

const WalletButton: FunctionComponent<Props> = (props) => {
  const { log } = useFurComponent(WalletButton);
  const [helpOpen, setHelpOpen] = React.useState(false);

  const title = props.title ?? 'Sign In';
  const classes = useStyles();

  const { className, children, hideLoading, analyticsPathComponent, block } =
    props;
  const { signInWallet, isSignedIn } = useAuthContext();

  const addr = useWalletSelector((s) => s.address);
  const isLoading = useWalletSelector((s) => s.loading);
  const hasLoaded = useWalletSelector((s) => s.loaded);
  const context = React.useContext(WalletContext);
  const hasNetwork = !!context?.network;
  const provider = context?.provider;

  // If we're blocking content due to login required, render the pageview for funnel analytics
  // usePageView(isAuthenticated ? undefined : `/login/${analyticsPathComponent}`);

  const requestAccounts = React.useCallback(async () => {
    try {
      await provider?.send('eth_requestAccounts', []);
    } catch (e) {
      log.error('Connection error', e);
      // Most connection errors are pressing the button again while the MetaMask dialog is already open.
      // Reloading is a silly simple way to... fix? this
      window.location.reload();
    }
  }, [provider]);

  function sendEvent(source: string) {
    fireLoginBeginEvent(source, analyticsPathComponent);
  }

  function login() {
    setHelpOpen(true);
    return;

    if (!context) {
      // no web3; discord login
      setHelpOpen(true);
    } else if (addr) {
      // wallet login
      void signInWallet(true);
      sendEvent('Web3');
    } else {
      //
      void requestAccounts();
    }
  }

  React.useEffect(() => {
    if (!isSignedIn && block && !helpOpen) setHelpOpen(true);
  }, [isSignedIn, block, helpOpen]);

  return (
    <React.Fragment>
      {!hideLoading && (!hasLoaded || isLoading) && (
        <div
          className={className}
          style={{
            pointerEvents: 'none',
          }}>
          {(isLoading || !hasLoaded) && (
            <CircularProgress size={30} className={classes.progress} />
          )}
          {hasNetwork && !isLoading && hasLoaded && (
            <Typography variant={'h5'}>Network Offline</Typography>
          )}
        </div>
      )}
      {!isSignedIn && hasLoaded && !isLoading && (
        <Button
          variant={'contained'}
          className={className}
          onClick={() => login()}>
          {title}
        </Button>
      )}
      {isSignedIn && children}
      <ForceLoginDialog
        open={helpOpen}
        onClose={() => setHelpOpen(false)}
        analyticsPathComponent={analyticsPathComponent}
      />
    </React.Fragment>
  );
};

export default WalletButton;
