import * as React from 'react';

function SvgLootShoe(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 153.7' {...props}>
      <path
        fill='#FFF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M150.9 88.2s29.5 5.5 18.4 31.3l-25.7-11'
      />
      <path
        fill='#5FA8FF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M50.9 21.4s7.8-9.5 24 21.2c2.9 5.6 8.7 9.2 14.4 11.9 4.7 2.1 9.8 3.3 13 .4l11.9 20-62.7-16.2-.6-37.3z'
      />
      <path
        fill='#5FA8FF'
        d='M118.5 42.8c7.3 11.9 22.3 35.5 32.4 45.4l-8.8 23.8s-57.6-1.6-107.2-58.8c0 0 3.9-16.7 14.6-30.8 1.5-2 4.7-1.5 5.6.8l3 7.6c3.1 8 8.9 18.8 17 21.9 7.5 2.8 21.6-.8 33.6-11.4 3-2.6 7.7-1.9 9.8 1.5z'
      />
      <path
        fill='#94C3FF'
        d='M142.1 112l4.5-12.2c-.2-.4 1.3-6.1 1-6.5-8.9-9.5-21.6-26.5-28.9-38.3-3.4-5.4-11.5-2.6-16.5 1.2C91 64.8 78.7 67.6 71.8 65c-8.1-3-12.4-11.7-15.5-19.7C54 39.5 49.2 38.6 46 44c-2.5 4.2-1.1 9.8-2.6 13.4 49.5 56.4 98.7 54.6 98.7 54.6z'
      />
      <path
        fill='none'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M118.5 42.8c7.3 11.9 22.3 35.5 32.4 45.4l-8.8 23.8s-57.6-1.6-107.2-58.8c0 0 3.9-16.7 14.6-30.8 1.5-2 4.7-1.5 5.6.8l3 7.6c3.1 8 8.9 18.8 17 21.9 7.5 2.8 21.6-.8 33.6-11.4 3-2.6 7.7-1.9 9.8 1.5z'
      />
      <path d='M128 79.7c-.7-.2-1.3-.7-1.8-1.3-1.1-1.6-.6-3.7.9-4.7s3.7-.6 4.7.9c1.1 1.6.7 3.7-.9 4.7-.9.6-1.9.7-2.9.4zm-6.6-9.7c-.7-.2-1.3-.7-1.8-1.3-1.1-1.6-.6-3.7.9-4.7 1.6-1 3.7-.6 4.7.9 1.1 1.6.6 3.7-.9 4.7-.8.5-1.9.7-2.9.4zm-6.6-9.8c-.7-.2-1.3-.7-1.8-1.3-1.1-1.6-.6-3.7.9-4.7 1.6-1 3.7-.6 4.7.9 1 1.6.6 3.7-.9 4.7-.8.6-1.9.7-2.9.4z' />
      <path
        fill='#FFF'
        stroke='#000'
        strokeWidth={4}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeMiterlimit={10}
        d='M170.6 116.4s.8 9.3-4 10.7C142.2 134.2 47 80.9 32 72.1c-2.8-1.6-4.3-4.9-3.8-8.1 1-5.6 6.6-10.9 6.6-10.9s79.9 52.9 135.8 63.3z'
      />
      <path
        opacity={0.2}
        d='M169.2 121.1c-24.4 7.1-119.6-46.2-134.6-55-2.8-1.6-4.3-4.8-3.8-8-1.1 1.8-2.2 3.8-2.6 6-.6 3.2 1 6.4 3.8 8.1 15 8.8 110.2 62.1 134.6 55 2.7-.8 3.6-4 3.9-6.7-.4.2-.8.5-1.3.6z'
      />
    </svg>
  );
}

export default SvgLootShoe;
