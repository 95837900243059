import React, { FunctionComponent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, Slider, Grid, Button } from '@mui/material';

const shadow =
  '0 3px 1px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.13), 0 0 0 1px rgba(0,0,0,0.02)';

const useStyles = makeStyles((theme: Theme) => ({
  sliderContainer: {
    padding: '3rem 1rem 1.5rem',
  },

  slider: {
    height: 2,
    padding: '15px 0',

    '& .MuiSlider-thumb': {
      height: 28,
      width: 28,
      backgroundColor: '#fff',
      boxShadow: shadow,

      '&:focus, &:hover, &.Mui-active': {
        boxShadow: shadow,

        '@media (hover: none)': {
          boxShadow: shadow,
        },
      },
    },

    '& .MuiSlider-valueLabel': {
      fontSize: '0.75rem',
      fontWeight: 'normal',
      top: '0.2rem',
      padding: 0,
      backgroundColor: 'unset',
      color: theme.palette.text.primary,

      '&:before': {
        display: 'none',
      },
    },

    '& .MuiSlider-track': {
      border: 'none',
    },

    '& .MuiSlider-rail': {
      opacity: 0.5,
      backgroundColor: '#bfbfbf',
    },
  },

  button: {
    width: '50%',
  },
}));

interface IProps {
  max: number;
  onChange: (id: any) => void;
}

export const MarketplaceSlideFilter: FunctionComponent<IProps> = ({
  max,
  onChange,
}) => {
  const [value, setValue] = useState<number[]>([0, max]);
  const classes = useStyles();

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      gap='0.8rem'
      className={classes.sliderContainer}>
      <Slider
        disableSwap
        size='medium'
        color='secondary'
        valueLabelDisplay='on'
        max={max}
        value={value}
        className={classes.slider}
        onChange={(ev, newValue, activeThumb) => {
          if (!Array.isArray(newValue)) return;

          const minDistance = 20;
          const min = Math.min(newValue[0], value[1] - minDistance);
          const max = Math.max(newValue[1], value[0] + minDistance);

          activeThumb === 0
            ? setValue([min, value[1]])
            : setValue([value[0], max]);
        }}
      />

      <Button
        size='small'
        variant='contained'
        className={classes.button}
        onClick={() => {
          const min = value[0] - 1;
          const max = value[1] + 1;

          onChange(`${min}:${max}`);
        }}>
        Apply
      </Button>
    </Grid>
  );
};
