import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Theme, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { cdnRoot, mint } from '../../../theme';
import { getBosses, getTopIllustrationPos, IBossInfo } from '../../../utils';
import { shadeColor, yellow } from '../../../themev2';
import { useHistory } from 'react-router-dom';
import { RealmType } from '../../../components/schema';
import clsx from 'clsx';
import { getRealmSlug } from '../../../components/Zones/ZoneHelpers';
import { capitalize } from 'lodash';
import { useClientHardware } from '../../App/clientHardware';
import useCommonStyles from '../../../components/useCommonStyles';
import OkDialog from '../../../components/OkDialog';
import ZoneButton from '../../../components/Zones/ZoneButton';

export const useStyles = makeStyles((theme: Theme) => ({
  bossCards: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    marginTop: '9rem',
    width: '100%',
    alignItems: 'center',

    [theme.breakpoints.down(1100)]: {
      flexDirection: 'column',
      marginTop: '2rem',
      rowGap: '140px',
    },
  },
  bossCardSm: {
    padding: '2rem 1.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '6px',
    cursor: 'pointer',
    backgroundColor: shadeColor(theme.palette.secondary.light, 40),
  },
  bossCard: {
    padding: '2rem 1.75rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '6px',
    width: '340px',
    backgroundColor: shadeColor(theme.palette.secondary.light, 40),
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    position: 'relative',
    transition: '0.2s all ease-in-out',

    '& h6': {
      fontSize: '35px !important',
    },

    '& p': {
      fontSize: '13px !important',
      margin: '0.75rem auto !important',
      opacity: 0.85,
      paddingLeft: '8.5px !important',
    },

    '& img': {
      position: 'absolute',
    },

    [theme.breakpoints.down(1100)]: {
      width: '515px',
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  bossToFight: {
    width: '515px',
  },
  selectedBoss: {
    backgroundColor: shadeColor(theme.palette.primary.main, 30),
    color: 'black',
  },
  btn: {
    width: '100%',
    // minWidth: '225px !important',
    marginTop: '1rem !important',
  },
  bossName: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem !important',
      marginBottom: '10px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem !important',
      marginBottom: '20px !important',
    },
  },
  playButton: {
    // padding: '20px 10px !important',
    backgroundColor: `${yellow} !important`,

    '&:hover': {
      backgroundColor: `${mint.light} !important`,
    },
  },
}));

interface IOnboardBoss {
  onboard?: boolean;
  selected?: RealmType | null;
  setSelectedBoss?: (real: RealmType | null) => void;
}

interface IRentMarketBosses extends IOnboardBoss {
  partyId?: string;
  fightOnComplete?: RealmType | undefined;
  className?: string;
}

const RentMarketBosses: FunctionComponent<IRentMarketBosses> = (props) => {
  const {
    partyId,
    fightOnComplete,
    onboard,
    selected,
    setSelectedBoss,
    className,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const bosses = getBosses();
  const common = useCommonStyles();
  const [bossInfo, setBossInfo] = React.useState<IBossInfo>();
  const { isMobile } = useClientHardware();

  function handleOnClick(realm: RealmType | null) {
    if (!!setSelectedBoss) {
      return setSelectedBoss(realm);
    }

    if (!!realm) {
      return history.push(
        `/boss/${!!fightOnComplete ? 'fight' : 'lobby'}/${getRealmSlug(
          realm,
        )}/${partyId}`,
      );
    }

    return null;
  }

  function getBtnText(isSelected: boolean, toFight: boolean) {
    if (isSelected) {
      return !onboard ? 'Deselect' : 'Selected';
    }

    return !!partyId ? (toFight ? 'Fight Boss' : 'Enter Boss Lobby') : 'Select';
  }

  function renderBossCards() {
    return bosses.map(({ name, realm, lore }, i) => {
      const bossToFight =
        !!fightOnComplete &&
        getRealmSlug(fightOnComplete) === getRealmSlug(realm);
      if (!bossToFight && !!fightOnComplete) return null;
      const isSelected = realm === selected;

      const cardTitle = !fightOnComplete
        ? name.toUpperCase()
        : `Go Fight ${capitalize(name)}`;

      return (
        <div
          key={`boss-${name}`}
          className={clsx(classes.bossCard, {
            [classes.selectedBoss]: isSelected,
            [classes.bossToFight]: bossToFight,
          })}
          style={{
            marginBottom: i === bosses.length - 1 ? '0' : 'inherit',
          }}>
          <Typography variant={'h6'}>{cardTitle}</Typography>
          <img
            src={`${cdnRoot}/images/leaderboard_top_${name}.png`}
            alt={name}
            height={150}
            style={{
              top: getTopIllustrationPos(name),
            }}
          />
          <Typography variant={'body1'}>{lore}</Typography>
          <ZoneButton
            onClick={() => handleOnClick(realm === selected ? null : realm)}
            text={getBtnText(isSelected, bossToFight)}
            collect={!isSelected}
          />
        </div>
      );
    });
  }

  function renderBossesMobile(): React.ReactNode {
    const renderBosses = bosses.filter(
      (b) =>
        !fightOnComplete ||
        getRealmSlug(fightOnComplete) === getRealmSlug(b.realm),
    );

    return (
      <Grid container spacing={1}>
        {renderBosses.map((boss, i) => {
          const { name, realm } = boss;
          const bossToFight =
            !!fightOnComplete &&
            getRealmSlug(fightOnComplete) === getRealmSlug(realm);
          if (!bossToFight && !!fightOnComplete) return null;
          const isSelected = realm === selected;

          return (
            <Grid
              key={`boss-${name}`}
              item
              xs={renderBosses.length === 1 ? 12 : 4}>
              <div
                className={classes.bossCardSm}
                onClick={() => setBossInfo(boss)}>
                <Typography variant={'h4'} className={classes.bossName}>
                  {name.toUpperCase()}
                </Typography>
                <img
                  style={{ maxWidth: '100%' }}
                  src={`${cdnRoot}/images/avatars/${name}.png`}
                />
              </div>
              <Button
                variant={'contained'}
                color={isSelected ? 'secondary' : 'primary'}
                className={clsx(
                  isSelected ? classes.playButton : common.actionButton,
                  classes.btn,
                )}
                onClick={() => handleOnClick(realm === selected ? null : realm)}
                disabled={!!onboard && isSelected}>
                {getBtnText(isSelected, bossToFight)}
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  }

  return (
    <div className={clsx(classes.bossCards, className)}>
      {isMobile || true ? renderBossesMobile() : renderBossCards()}
      <OkDialog
        title={bossInfo?.name}
        open={!!bossInfo}
        onClose={() => setBossInfo(undefined)}
        fullScreen={false}
        maxWidth={'sm'}>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ maxWidth: '300px' }}
            src={`${cdnRoot}/images/avatars/${bossInfo?.name}.png`}
          />
        </div>
        <br />
        {bossInfo?.lore ?? ''}
      </OkDialog>
    </div>
  );
};

export default RentMarketBosses;
