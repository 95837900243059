import React from 'react';
import { IRenderPart } from './part';
import { renderEdition0Tail } from './Tail';
import { renderEdition0Weapon } from './Weapon';
import { renderEdition0Body } from './Body';
import { renderEdition0Tattoo } from './Tattoo';
import { renderEdition0Ears } from './Ears';
import { renderEdition0Eyes } from './Eyes';
import { renderEdition0Mouth } from './Mouth';
import { renderEdition0Hat } from './Hat';
import { renderEdition0Outfit } from './Outfit';
import { FurballSlot } from '../../components/schema';

export const edition0SlotNames: FurballSlot[] = [
  FurballSlot.Tail,
  FurballSlot.Weapon,
  FurballSlot.Body,
  FurballSlot.Tattoo,
  FurballSlot.Ears,
  FurballSlot.Eyes,
  FurballSlot.Mouth,
  FurballSlot.Hat,
  FurballSlot.Outfit,
];

export const edition0PaletteColors: string[] = [
  '#97c7cf',
  '#63a8b5',
  '#f0e7ea',
  '#f4d6fd',
  '#c6c6ff',
  '#ffc9fd',
  '#dd9962',
  '#f5d0b3',
  '#ffdd5e',
  '#FFF2BD',
  '#FF96E5',
  '#FFC7EB',
  '#65e8fb',
  '#c1f3fb',
  '#FFB569',
  '#FFDA99',
  '#70737c',
  '#a4a9b5',
  '#afe39d',
  '#d4ffc5',
  '#f2Dbbd',
  '#fff6ec',
  '#f2afa4',
  '#f2d4bf',
  '#f2a3c8',
  '#f2cee4',
  '#cea393',
  '#f0d2c7',
  '#B594F2',
  '#DAC7FF',
  '#6cc4cf',
  '#e9bbf2',
  '#84b5ff',
  '#ffefc2',
  '#e3eefc',
  '#bfcffa',
  '#81DE91',
  '#B9EDC0',
  '#c9b5a3',
  '#e8dcd1',
  '#e9af6e',
  '#fdd8b0',
  '#edeae4',
  '#d1cec6',
  '#966548',
  '#BF9276',
  '#95a72f',
  '#c6d55c',
  '#ffb0ff',
  '#ffcf80',
  '#ff6b6c',
  '#ff9c9c',
  '#E0AE45',
  '#F7D280',
  '#ebe4d8',
  '#E3DC9C',
  '#b4ffc6',
  '#e8ff9c',
  '#5c985c',
  '#a7ac73',
];

export function renderEdition0(
  slotIdx: number,
  renderer: IRenderPart,
): React.ReactNode {
  if (slotIdx === 0) return renderEdition0Tail(renderer);
  if (slotIdx === 1) return renderEdition0Weapon(renderer);
  if (slotIdx === 2) return renderEdition0Body(renderer);
  if (slotIdx === 3) return renderEdition0Tattoo(renderer);
  if (slotIdx === 4) return renderEdition0Ears(renderer);
  if (slotIdx === 5) return renderEdition0Eyes(renderer);
  if (slotIdx === 6) return renderEdition0Mouth(renderer);
  if (slotIdx === 7) return renderEdition0Hat(renderer);
  if (slotIdx === 8) return renderEdition0Outfit(renderer);
  console.warn('slotIdx', slotIdx, renderer);
}
