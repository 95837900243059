import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Grid, Theme, Typography } from '@mui/material';
import FullDrawer from '../../components/Drawer/FullDrawer';
import BossLeaderboards from '../../components/BossFights/BossLeaderboards/BossLeaderboards';
import { cdnRoot } from '../../theme';
import { useTimekeeperSelector } from '../../components/Timekeeper/TimekeeperSlice';
import ActiveTournament from './ActiveTournament';
import LeaderboardHeader from './LeaderboardHeader';
import {
  LeaderboardTimeframe,
  useLoadPendingRewardsLazyQuery,
} from '../../components/schema';
import { IPendingRewards } from '../../wallet/WalletTypes';
import AlertProvider from '../../components/Alert/AlertProvider';
import { usePageTitle } from '../../Analytics';
import { useWalletSelector } from '../../hooks';
import { useAuthContext } from '../../components/Auth/AuthContext';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '2rem',
    paddingBottom: '3rem',
    minHeight: '80vh',
    maxWidth: '1450px !important',

    '@media (max-width: 420px)': {
      height: '100px',
    },
  },
  main: {
    position: 'relative',
  },
  header: {
    margin: '0 2rem 1rem 2rem',
    position: 'relative',

    '@media (max-width: 1300px)': {
      margin: '0 2rem 7rem 2rem',
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',

      '& p': {
        margin: '1rem auto',
        maxWidth: '310px',
      },
    },
  },
  illustration: {
    position: 'absolute',
    top: '-155px',
    right: '40px',
    zIndex: 1000,
    height: '180px',

    '@media (max-width: 1400px)': {
      height: '145px',
      top: '-120px',
      right: '210px',
    },

    '@media (max-width: 900px)': {
      height: '100px',
      right: '30px',
      top: '-75px',
    },
  },
  notice: {
    color: 'white',
    textAlign: 'center',
  },
}));

interface ILeaderboardsV2 {}

export const LeaderboardsV2: FunctionComponent<ILeaderboardsV2> = (props) => {
  const classes = useStyles();
  const hasMeta = useTimekeeperSelector((tk) => !!tk.meta);
  const activeTournaments = useTimekeeperSelector((tk) => [
    ...(tk.meta?.activeTournaments ?? []),
  ]);
  const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const { isSignedIn } = useAuthContext();
  usePageTitle('Leaderboards');
  // const activeTournament = activeTournaments.length <= 0 ? undefined : activeTournaments[0];

  const [loadPendingRewards, { data }] = useLoadPendingRewardsLazyQuery();

  React.useEffect(() => {
    if (playerId && isSignedIn) loadPendingRewards();
  }, [playerId, isSignedIn]);

  const pendingRewards: IPendingRewards[] =
    data?.currentPlayer.pendingRewards ?? [];

  return (
    <FullDrawer drawer={null}>
      <AlertProvider>
        <Container className={classes.root}>
          <Typography variant={'h6'} className={classes.notice}>
            Leaderboards may take several minutes to update after a battle is
            finished.
          </Typography>
          {hasMeta && (
            <>
              {activeTournaments.map((tournament) => (
                <ActiveTournament
                  key={tournament.id}
                  tournament={tournament}
                  pendingRewards={pendingRewards}
                />
              ))}
              <LeaderboardHeader
                title={'Weekly Leaderboards'}
                body={
                  <>
                    Earn $wFUR, loot, Dust, and gen2 NFTs by climbing the
                    leaderboards.
                    <br />
                    Each Leaderboard uses the top 3 scores from each player,
                    each day.
                  </>
                }
                heartsMessage={'Furball hearts reset in'}
              />
              <Grid container className={classes.main}>
                <img
                  src={`${cdnRoot}/images/leaderboard-illustration-01.png`}
                  className={classes.illustration}
                />
                <BossLeaderboards pendingRewards={pendingRewards} />
              </Grid>
              {/*<div style={{ textAlign: 'center', padding: 20, paddingTop: 60 }}>*/}
              {/*  <ShareReferButton primary={true} />*/}
              {/*</div>*/}
              <LeaderboardHeader
                title={'Seasonal Leaderboards'}
                body={
                  <>
                    Each month, earn additional rewards for your top scores each
                    day of the month!
                  </>
                }
                timeframe={LeaderboardTimeframe.Seasonal}
                heartsMessage={'Furball hearts reset in'}
              />
              <Grid container className={classes.main}>
                <img
                  src={`${cdnRoot}/images/leaderboard-illustration-01.png`}
                  className={classes.illustration}
                />
                <BossLeaderboards
                  pendingRewards={pendingRewards}
                  timeframe={LeaderboardTimeframe.Seasonal}
                />
              </Grid>
            </>
          )}
        </Container>
      </AlertProvider>
    </FullDrawer>
  );
};

export default LeaderboardsV2;
