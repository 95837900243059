import * as React from 'react';

function SvgIconWrappedFur(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 20 20'
      enableBackground='new 0 0 20 20'
      xmlSpace='preserve'
      {...props}>
      <g>
        <path
          fill={props.fill ?? '#FFE47C'}
          d='M19.75,18.86c-0.3-1.27-0.49-2.46-0.68-3.74c-0.01-0.06-0.01-0.12-0.02-0.18c-0.3,0.4-0.63,0.79-1.05,1.1
		c1.9-4.16,1.9-7.93,0-12.09c0.42,0.32,0.76,0.7,1.05,1.1c0.01-0.06,0.01-0.12,0.02-0.18c0.19-1.28,0.38-2.47,0.68-3.74
		c0.18-0.68-0.41-1.3-1.05-1.11c-1.34,0.35-2.6,0.56-3.96,0.79c-1.42,0.23-3.05,0.29-4.72,0.29c-0.02,0-0.03,0-0.05,0
		c-1.66,0-3.3-0.06-4.72-0.29C3.9,0.6,2.64,0.39,1.3,0.03C0.66-0.16,0.07,0.47,0.25,1.14c0.3,1.27,0.49,2.46,0.68,3.74
		C0.93,4.94,0.93,5,0.94,5.06c0.3-0.4,0.63-0.79,1.05-1.1c-1.9,4.16-1.9,7.93,0,12.09c-0.42-0.32-0.76-0.7-1.05-1.1
		C0.93,15,0.93,15.06,0.93,15.12c-0.19,1.28-0.38,2.47-0.68,3.74c-0.18,0.68,0.41,1.3,1.05,1.11c1.38-0.36,2.67-0.58,4.09-0.81
		c1.33-0.21,2.85-0.3,4.4-0.29c0.14,0,0.28,0,0.42,0c1.55-0.01,3.07,0.08,4.4,0.29c1.41,0.23,2.7,0.45,4.09,0.81
		C19.34,20.16,19.93,19.53,19.75,18.86z M10,16.74c-3.72,0-6.74-3.02-6.74-6.74c0-3.72,3.02-6.74,6.74-6.74
		c3.72,0,6.74,3.02,6.74,6.74C16.74,13.72,13.72,16.74,10,16.74z'
        />
        <g>
          <path
            fill={props.fill ?? '#FFE47C'}
            d='M10.02,9.79c-0.1,0-0.19-0.04-0.26-0.11C9.68,9.61,9.63,9.47,9.63,9.37c0-0.37,0.24-0.59,0.59-0.59h1.96
			c0.4,0,0.77-0.11,0.77-0.93c0-0.17-0.02-0.37-0.07-0.59C12.85,7.13,12.82,7,12.77,6.88h-3.1c-1.61,0-2.19-0.05-2.19,0.23v5.69
			c0,0.24,0.1,0.41,0.32,0.54c0.24,0.15,0.53,0.22,0.88,0.22c0.37,0,0.68-0.04,0.93-0.13c0.18-0.06,0.33-0.13,0.45-0.21v-0.88
			c0,0-0.22-0.01-0.32-0.02c-0.32-0.04-0.47-0.27-0.47-0.48c0-0.14,0.05-0.27,0.15-0.37c0.07-0.07,0.16-0.11,0.26-0.11h2.58
			c0.11-0.21,0.17-0.49,0.17-0.83c0-0.27-0.05-0.52-0.14-0.74H10.02z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconWrappedFur;
