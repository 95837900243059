import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import clsx from 'clsx';
import WalletContext from '../../wallet/WalletContext';
import { OutboundLink } from '../Navigation';
import { IFurballContext } from '../../wallet/WalletTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IUnknown {}

type Props = {
  transactionHash: string;
};

function getSubdomain(context?: IFurballContext) {
  const subdomain = context?.network.subdomain ?? '';
  if (subdomain === 'www' || subdomain.length <= 0) return '';
  return `${subdomain}.`;
}

export function getTransactionLink(hash: string, context?: IFurballContext) {
  return `https://${getSubdomain(context)}etherscan.io/tx/${hash}`;
}

export const TransactionLink: FunctionComponent<Props> = (props) => {
  const { transactionHash } = props;
  const context = React.useContext(WalletContext);

  const classes = useStyles();
  return (
    <OutboundLink
      to={getTransactionLink(transactionHash, context)}
      className={clsx(classes.root)}>
      View on Etherscan
    </OutboundLink>
  );
};

export default TransactionLink;
