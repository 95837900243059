import React, { FunctionComponent } from 'react';
import { Theme, LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { IFurballBase } from '../../wallet/WalletTypes';

import FurballRenderer from '../../components/Furballs/FurballRenderer';
import EmbeddedHUD from './EmbeddedHUD';
import FurballBackground, {
  getFurballBackgroundColorCss,
} from '../../components/Furballs/Backgrounds/FurballBackground';
import {
  IUnityOptionalArgs,
  useUnityBuildName,
} from '../../components/useUnity';
import { Unity } from 'react-unity-webgl';
import { useWindowSize } from '../../components/useWindowSize';
import { useWalletSelector } from '../../hooks';
import { isMobile } from 'react-device-detect';
import { useFurComponent } from '../../utils';
import { useFurverseContext } from '../Furverse/Furverse';

const footerHeight = 74;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  unityWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: footerHeight,
    overflow: 'hidden',
  },
  unityInner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  unity: {
    background: 'transparent !important',
    // backgroundColor: 'white',
    // zIndex: 1,
    position: 'absolute',
    top: 0,
    // top: 0,
    // bottom: 0,
    // width: '100%',
    // height: 'calc(100vh)',
  },
  hud: {
    // zIndex: 10001,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  furballRenderer: {
    position: 'absolute',
    bottom: footerHeight,
    left: '10%',
  },
}));

interface IFurballWidget {
  furball?: IFurballBase;
  tokenId: string;
  size: number | string;
  isLoading?: boolean;
  disableUnity?: boolean;
}

export const FurballUnityWidget: FunctionComponent<IFurballWidget> = (
  props,
) => {
  // const [progress, setProgress] = React.useState(0);

  const { log } = useFurComponent(FurballUnityWidget);
  const [ready, setReady] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const containerWidth = containerRef.current?.clientWidth ?? 500;
  const containerHeight = containerRef.current?.clientHeight ?? 500;
  const windowSize = useWindowSize();
  const [unitySize, setUnitySize] = React.useState(
    Math.min(containerWidth, containerHeight + footerHeight),
  );
  const [left, setLeft] = React.useState(0);
  const classes = useStyles();
  const dpi = window.devicePixelRatio;
  const [canvas, setCanvas] = React.useState<HTMLCanvasElement | undefined>(
    undefined,
  );
  const widgetSize =
    typeof props.size === 'number' ? Math.min(props.size, 300) : props.size;
  const display = ready ? '' : 'none';
  const tokenId = props.tokenId;
  const furball = props.furball;

  const { clientArgs, setOptionalArgs } = useFurverseContext();
  const unityContext = useUnityBuildName('widget', clientArgs);
  const isLoading = !unityContext.isLoaded || props.isLoading;
  const progress = unityContext.loadingProgression;

  React.useEffect(() => {
    const args: IUnityOptionalArgs = { furballId: tokenId };
    if (furball)
      args.furball = {
        ...furball,
        inventory: { ...furball.inventory, items: [] },
      };
    setOptionalArgs(args);
  }, [tokenId, furball]);

  // useUnityLoadProgress(unityContext, setProgress);

  React.useEffect(function () {
    unityContext.addEventListener('canvas', setCanvas);

    return () => {
      unityContext.removeEventListener('canvas', setCanvas);
    };
  }, []);

  React.useEffect(() => {
    let cnvs = canvas;

    if (!cnvs && unityContext) {
      cnvs = unityContext.UNSAFE__unityInstance?.Module.canvas;
      // cnvs = unityContext.htmlCanvasElement;
      setCanvas(cnvs);
    }

    if (cnvs) {
      cnvs.width = containerWidth * dpi;
      cnvs.height = containerHeight * dpi;
      log.info(
        'adjusting canvas',
        containerWidth,
        containerHeight,
        '@',
        dpi,
        'to',
        cnvs.width,
        cnvs.height,
      );
    }

    const us = Math.min(containerWidth, containerHeight + footerHeight);
    setUnitySize(us);
    setLeft(Math.min(0, (containerWidth - us) / 2));
  }, [containerRef, windowSize, canvas]);

  React.useEffect(() => {
    const newReady = !isLoading;
    if (newReady == ready) return;

    // Give unity time to prelaunch/load
    const timeout = setTimeout(() => {
      setReady(newReady);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLoading]);

  return (
    <>
      <div
        ref={containerRef}
        className={classes.unityWrapper}
        style={{ minWidth: unitySize, backgroundColor: 'transparent' }}>
        <Unity
          unityProvider={unityContext.unityProvider}
          className={classes.unity}
          style={{
            left,
            width: containerWidth,
            height: containerHeight,
            display,
          }}
          // matchWebGLToCanvasSize={true}
        />
        {!ready && (
          <LinearProgress
            color={'secondary'}
            value={progress * 100}
            variant={
              progress > 0 && progress < 1 ? 'determinate' : 'indeterminate'
            }
            style={{ position: 'absolute', top: 0, width: '100%' }}
          />
        )}
      </div>
      {!ready && (
        <FurballRenderer
          tokenId={props.tokenId}
          size={widgetSize}
          className={classes.furballRenderer}
        />
      )}
    </>
  );
};

export const FurballWidget: FunctionComponent<IFurballWidget> = (props) => {
  const { furball, tokenId, size, isLoading } = props;
  const classes = useStyles();
  const backgroundColor = getFurballBackgroundColorCss(furball);
  const isSmall = typeof size === 'number' && size < 300;
  const useUnity = !isMobile && !isSmall && !props.disableUnity;
  const isStartingUp = useWalletSelector((w) => w.loading !== false);

  if (isStartingUp) return null;

  return (
    <div
      className={clsx(classes.root)}
      style={{
        backgroundColor,
      }}>
      {furball && (
        <div className={classes.unityWrapper}>
          <FurballBackground
            className={classes.background}
            disableAnimation={isSmall}
            furball={furball}
            widget={true}
          />
        </div>
      )}
      {isLoading && <LinearProgress color={'secondary'} />}
      {useUnity ? (
        <FurballUnityWidget {...props} />
      ) : (
        <FurballRenderer
          tokenId={tokenId}
          size={size}
          className={classes.furballRenderer}
        />
      )}
      {furball && (
        <div className={classes.hud}>
          <EmbeddedHUD furball={furball} isSmall={isSmall} />
        </div>
      )}
    </div>
  );
};

export default FurballWidget;
