import {
  LinearProgress,
  List,
  ListItem,
  Typography,
  Grid,
  Button,
} from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useWalletSelector } from '../../hooks';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { FurballCard } from '../../components/Furballs';
import { usePlayersReadyFurballs } from '../../wallet';
import { Link } from 'react-router-dom';
import SyncAccountButton from '../Account/SyncAccountButton';
import useCommonStyles from '../../components/useCommonStyles';
import UpgradeDialog from '../../components/Town/Upgrade/UpgradeDialog';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
  },
  header: {
    paddingLeft: theme.spacing(2),
  },
  syncButton: {},
}));

interface OwnProps {}

type Props = OwnProps;

const MyFurballs: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();
  const contents = useWalletSelector((s) => s.contents);
  const playersFurballs = usePlayersReadyFurballs(); // enforce that the Furballs are loaded.
  const tokenIds = playersFurballs.map((fb) => fb.tokenId);
  const teamSize = contents?.furballBalance ?? 0;
  const isLoading = useWalletSelector((s) => s.loading);
  const [upgradeFurballId, setUpgradeFurballId] = React.useState<string>();
  const furballsPerPage = 20;
  const [maxFurballs, setMaxFurballs] = React.useState(furballsPerPage);
  const filteredTokenIds =
    tokenIds.length > maxFurballs
      ? [...tokenIds].splice(0, maxFurballs)
      : tokenIds;
  //
  // function renderTeam(title: string, subtitle: string) {
  //   return <React.Fragment>
  //     <Typography variant={'h5'}>
  //       {title}
  //     </Typography>
  //     <Typography variant={'subtitle2'}>
  //       {subtitle}
  //     </Typography>
  //   </React.Fragment>;
  // }

  return (
    <div className={classes.root}>
      {!isLoading && (
        <Grid container className={classes.header}>
          <Grid item xs={3}>
            <SyncAccountButton className={classes.syncButton} />
          </Grid>
          <Grid item xs={9} style={{ textAlign: 'left' }}>
            <Typography variant={'h4'} sx={{ fontWeight: '400' }}>
              My Furballs ({teamSize})
            </Typography>
            <Typography variant={'h6'}>
              <Link to={'/account'}>My Account & Game Stats</Link>
            </Typography>
          </Grid>
        </Grid>
      )}
      {isLoading && <LinearProgress />}
      <List>
        {filteredTokenIds.map((tokenId) => {
          return (
            <ListItem key={tokenId}>
              <FurballCard
                tokenId={tokenId}
                onClickDetails={() => setUpgradeFurballId(tokenId)}
              />
            </ListItem>
          );
        })}
      </List>
      {filteredTokenIds.length < tokenIds.length && (
        <React.Fragment>
          <Button
            onClick={() => setMaxFurballs(maxFurballs + furballsPerPage)}
            variant={'contained'}
            className={common.actionButton}
            style={{ marginBottom: 20 }}>
            Load More
          </Button>
        </React.Fragment>
      )}
      <UpgradeDialog
        show={!!upgradeFurballId}
        tokenId={upgradeFurballId}
        onClose={() => setUpgradeFurballId(undefined)}
        onAction={() => setUpgradeFurballId(undefined)}
      />
    </div>
  );
};

export default MyFurballs;
