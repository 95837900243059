import { EditionAsset } from '../../schema';
import edition0 from './edition0.json';

/**
 * Use the following query to update the itemDefinitions.json...
 */

// query {
//   itemDefinitions {
//     id
//     icon
//     itemId
//     itemGroup
//     name
//     rarity
//     realmAffiliation
//   }
// }

export const edition0Assets = edition0.data.editions[0]
  .allAssets as EditionAsset[];
