import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Theme, Typography } from '@mui/material';
import {
  TimekeeperWrapFurArgsInput,
  TimekeeperWrapFurFragment,
  usePendingFurWrapsQuery,
  useWrapFurMutation,
} from '../schema';
import { approxDuration } from '../../wallet';
import { ISwapOptions, SwapCurrency } from './ExchangeCurrencies';
import SpendTixButton from '../Account/SpendTixButton';
import { ALERT_TYPES, AlertContext } from '../Alert/AlertContext';
import { useAppDispatch, useWalletSelector } from '../../hooks';
import WalletContext from '../../wallet/WalletContext';
import { useFuelFactor } from '../Timekeeper/TimekeeperHooks';
import { signTimekeeperRequest } from '../Timekeeper/FurgreementSig';
import { black } from '../../themev2';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  swapOrderButtonCont: {
    position: 'absolute',
  },
  swapOrderButton: {
    minHeight: '42.5px !important',
    minWidth: '42.5px !important',
    border: '3px solid white !important',
    borderRadius: '50px !important',
    backgroundColor: 'rgba(233, 233, 255, 1) !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '-27.5px',
  },
  btn: {
    marginTop: '15px !important',
    maxHeight: '40px !important',
    minHeight: '40px !important',
    minWidth: '100% !important',
    fontSize: '18px !important',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${black} !important`,
  },
  disabled: {
    opacity: 0.5,
  },
  swapping: {
    display: 'flex',
    alignItems: 'center',
  },
}));
interface OwnProps {
  fuelCost: number;
  swapOptions: ISwapOptions;
  onComplete: () => void;
  disabled?: boolean;
}

const FurSwapButton: FunctionComponent<OwnProps> = (props) => {
  const { swapOptions, onComplete, disabled, fuelCost } = props;
  const { from, to } = swapOptions;
  const classes = useStyles();

  const { data } = usePendingFurWrapsQuery();
  const loadedWraps = data?.currentPlayer?.pendingWraps ?? [];
  const [pendingSwaps, setPendingSwaps] =
    useState<TimekeeperWrapFurFragment[]>(loadedWraps);
  const { addAlert } = React.useContext(AlertContext);
  const dispatch = useAppDispatch();
  const context = React.useContext(WalletContext);
  const sender = useWalletSelector((s) => s.address) ?? '';
  const tixBalance = useWalletSelector((s) => s.contents?.tix ?? 0);
  // const furBalance = useWalletSelector((s) => s.contents?.furBalance ?? 0);
  // const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);

  const [error, setError] = useState<boolean>(false);
  const [deadline, setDeadline] = useState<number>(
    Math.round(new Date().getTime() / 1000 + 3600 * 24 * 7),
  );

  // const tkState = useCurrentTimekeeperRequest();
  // const [compute, computed] = useTimekeeperWrapFurComputeMutation();
  const [accept, accepted] = useWrapFurMutation();
  // const [fuelCost, setFuelCost] = useState<number | null>(null);
  const fuelFactor = useFuelFactor();
  const tixCost = fuelCost ? fuelCost / fuelFactor : null;

  // const wrapFurComputeData = computed.data?.wrapFurCompute;
  const canBuy = !!tixCost && tixCost <= tixBalance;

  const isLoading = accepted.loading;
  const isDisabled = error || isLoading || !canBuy || disabled;
  const isUnWrap = from.currency === SwapCurrency.wFUR;
  const isWrap = !isUnWrap;
  const verb = isUnWrap ? 'Un-Wrap' : 'Wrap';

  async function acceptSwap() {
    if (!context) return;

    // const { id, fuelCost, spent, gained } = wrapFurComputeData;
    // const spent = isWrap ? from.amount : 0;
    // const gained = isUnWrap ? to.amount : 0;
    let spent = 0,
      gained = 0;
    const wrapFurArgs: TimekeeperWrapFurArgsInput = {
      amount: 0,
      deadline,
      sender,
    };
    if (isWrap) {
      spent = from.amount;
      wrapFurArgs.amount = spent;
    } else {
      gained = to.amount;
      wrapFurArgs.amount = -gained;
    }

    try {
      const [_, signature] = await signTimekeeperRequest(
        {
          fuelCost,
          deadline,
          spent,
          gained,
          mintEdition: 0,
          mintCount: 0,
          movementList: '',
          collectStayList: '',
        },
        sender,
        context,
      );

      const { data } = await accept({
        variables: { wrapFurArgs, signature },
      });
      const xfer = data?.wrapFur;
      if (xfer) {
        const swaps = [...pendingSwaps];
        swaps.splice(0, 0, xfer);
        setPendingSwaps(swaps);
      }
      onComplete();
    } catch (e: any) {
      addAlert({
        type: ALERT_TYPES.ERROR,
        message: e.message ?? 'Failed to Swap',
      });
    }
  }

  useEffect(() => {
    setPendingSwaps(loadedWraps);
  }, [loadedWraps]);

  function renderValueCell(n: number, symbol: string): React.ReactNode {
    return (
      <Grid item xs={4}>
        {n > 0 ? '+' : ''}
        <strong>{n.toLocaleString()}</strong> {symbol}
      </Grid>
    );
  }

  function renderPendingSwap(xfer: TimekeeperWrapFurFragment): React.ReactNode {
    const timespan = new Date().getTime() - new Date(xfer.createdAt).getTime();
    const [d1, d2] = approxDuration(timespan / 1000);
    // const { days, hours } = getTimeAgo(xfer.createdAt as any, new Date());
    // const totalHours = days * 24 + hours;

    return (
      <React.Fragment key={xfer.id}>
        {renderValueCell(-xfer.value, '$FUR')}
        {renderValueCell(xfer.value, 'wFUR')}
        <Grid item xs={4}>
          <strong>{d1}</strong> {d2} ago
        </Grid>
      </React.Fragment>
    );
  }

  const swapOffline = false;
  return (
    <div className={classes.root}>
      {!swapOffline && (
        <SpendTixButton
          itemName={verb + ' FUR'}
          className={clsx(classes.root, { [classes.disabled]: isDisabled })}
          onAccepted={() => acceptSwap()}
          isLoading={isLoading}
          cost={fuelCost ?? 20000}
          disabled={isDisabled}
          profitMult={0.5}
        />
      )}
      {swapOffline && (
        <Typography variant={'h6'}>
          Swap Offline for ETH2 Merge Maintenance
        </Typography>
      )}
      <br />
      <br />
      {pendingSwaps.length <= 0 && (
        <Typography variant={'subtitle2'} color={'gray'}>
          $FUR/wFUR swapping may take several hours to complete.
        </Typography>
      )}
      {pendingSwaps.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h5'}>Pending Swaps</Typography>
          </Grid>
          {pendingSwaps.map(renderPendingSwap)}
        </Grid>
      )}
    </div>
  );
};

export default FurSwapButton;
