import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { usePlayerSlug } from '../../wallet/Wallet';

export interface IFurPlayerLinkable {
  id: string;
  username?: string | null;
  socialName: string;
}

export interface IFurPlayerLinkableAvatar extends IFurPlayerLinkable {
  avatar?: string | null;
}

interface IPlayerLink {
  player?: IFurPlayerLinkable | null;
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  path?: string;
}

export const PlayerLink: FunctionComponent<IPlayerLink> = (props) => {
  const { player, children, className, style } = props;
  const playerId = usePlayerSlug(player);

  if (!player) return <>{children ?? '??'}</>;
  const path = props.path ?? `/player/${playerId}`;

  return (
    <Link className={className} style={style} to={path}>
      {children ?? player.socialName}
    </Link>
  );
};

export default PlayerLink;
