import { SvgEditionSlot } from '../part';
import * as React from 'react';

const Corgi = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='#ffa6a9'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M203.9 171.95s2.06 12.37-6.72 11.4c-8.78-.97-5.62-10.16-14.11-13.01-.01 0 10.54-5.21 20.83 1.61z'
    />
    <path
      fill='#fff6ec'
      stroke='#000'
      strokeWidth={3}
      strokeMiterlimit={10}
      d='M189.4 152.01c-5.23.25-12.33 5.65-12.7 10.04-.43 5.16 4.49 10.3 10.49 9.47 8.51-1.18 16.62 0 17.43 0 6.06 0 11.74-4.3 11.3-9.47-.37-4.39-7.46-9.78-12.7-10.04'
    />
    <path d='M205.67 151.71c-.36 2.63-4.51 9.92-10.07 9.92-5.56 0-9.61-7.28-9.36-9.92.66-7.12 20.26-6.05 19.43 0z' />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M193.59 156.49s4.67 6.18 2.11 13.84l1.36 5.67'
    />
    <path d='M185.84 160.79c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM182.3 163.39c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM186.78 166.69c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM190.08 163.86c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM206.41 160.72c1.39 0 1.39-2.16 0-2.16s-1.4 2.16 0 2.16zM202.87 163.31c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM207.35 166.61c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16zM210.65 163.78c1.39 0 1.39-2.16 0-2.16s-1.39 2.16 0 2.16z' />
  </svg>
);

export default Corgi;
