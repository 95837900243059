import React, { FunctionComponent } from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import BossRewardsClaim from '../BossRewards/BossRewardsClaim';
import themev2 from '../../../themev2';
import BossHeader, { IBossInfo } from '../BossHeader';
import LeaderboardRankings from './LeaderboardRankings';
import { IPendingRewards } from '../../../wallet/WalletTypes';
import {
  LeaderboardFragment,
  LeaderboardTimeframe,
  RewardSource,
} from '../../schema';
import { hasPendingRewardExpired } from '../BossRewards/bossRewardsUtils';
import { IBossLeaderboard } from './BossLeaderboards';
import useBossFightsStyles from '../useBossFightsStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  longLeaderboardCard: {
    maxHeight: '550px',
    minHeight: '550px',

    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
    },
  },
}));

interface IBossLeaderboardProps {
  boss: IBossLeaderboard;
  timeframe: LeaderboardTimeframe;
  leaderboard?: LeaderboardFragment;
  pendingRewards: IPendingRewards[];
}

function getPendingRewardForBoss(
  pendingRewards: IPendingRewards[],
  bossName: string,
  timeframe: LeaderboardTimeframe,
): IPendingRewards | null {
  const rewardsForBoss = pendingRewards?.filter((r) => {
    if (
      r.source === RewardSource.Unknown ||
      hasPendingRewardExpired(r.expiresAt)
    ) {
      return false;
    }

    const src = r.source.toUpperCase();
    const expected = `${timeframe.toUpperCase()}_BOSS_LEADERBOARD_${bossName.toUpperCase()}`;
    return src === expected;
    // const prts = src.split('_');
    // return prts.pop() === bossName.toUpperCase() && src.includes(`${timeframe.toUpperCase()}.`);
  });

  return rewardsForBoss.length > 0 ? rewardsForBoss[0] : null;
}

export const BossLeaderboard: FunctionComponent<IBossLeaderboardProps> = (
  props,
) => {
  const classes = useStyles();
  const { boss, timeframe, leaderboard, pendingRewards } = props;
  const onLeaderboardPage = true;
  const bossStyles = useBossFightsStyles();

  const pendingRewardsForBoss = getPendingRewardForBoss(
    pendingRewards,
    boss.name,
    timeframe,
  );

  if (pendingRewardsForBoss) {
    return (
      <BossRewardsClaim
        key={`rewards-${boss.name}`}
        bossName={boss.name}
        pendingReward={pendingRewardsForBoss}
        onLeaderboardPage={true}
      />
    );
  }

  return (
    <div
      key={`leaderboard-${boss.name}`}
      className={clsx(bossStyles.bossCard, {
        [classes.longLeaderboardCard]: onLeaderboardPage,
      })}
      style={{
        backgroundColor: themev2.palette.secondary.light,
      }}>
      <BossHeader boss={boss as IBossInfo} showInfo={false} />
      <LeaderboardRankings
        boss={boss}
        timeframe={timeframe}
        leaderboard={leaderboard}
        onLeaderboardPage={onLeaderboardPage}
      />
    </div>
  );
};

export default BossLeaderboard;
