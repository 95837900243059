// import { renderToString } from 'react-dom/server'
import { IPictureOpts } from '../../pages/Furball/FurballAvatar';
import { ILogger } from '../../utils';

export interface IRasterizeOpts {
  size: number;
  zoom: number;
  moveX: number;
  moveY: number;
}
//
// export async function rasterizeElement(
//   image: HTMLImageElement,
//   options: RasterizerOptions,
// ): Promise<string> {
//   try {
//     const canvas = document.createElement('canvas');
//     canvas.width = options.width;
//     canvas.height = options.height;
//
//     const context = canvas.getContext('2d');
//     if (!context) throw 'no 2d context';
//     context.drawImage(image, 0, 0);
//
//     return canvas.toDataURL(options.type, options.quality);
//   } catch (e) {
//     throw e;
//   }
// }
/**
 * converts an svg string to base64 png using the domUrl
 * @param {string} svgText the svgtext
 * @param {number} [margin=0] the width of the border - the image size will be height+margin by width+margin
 * @param {string} [fill] optionally background canvas fill
 * @return {Promise} a promise to the bas64 png image
 */
export function svgToPng(
  svgText: string,
  opts: IPictureOpts,
  fill?: string,
  log?: ILogger,
): Promise<string> {
  // convert an svg text to png using the browser

  // const { log } = useFurComponent(svgToPng.name);
  return new Promise<string>(function (resolve, reject) {
    try {
      // can use the domUrl function from the browser
      const domUrl = window.URL || window.webkitURL || window;
      if (!domUrl) {
        throw new Error('(browser doesnt support this)');
      }

      // Decode the SVG
      // const b64 = svgText.substr('data:image/svg+xml;base64,'.length);
      // svgText = Buffer.from(b64, "base64").toString('utf8');
      // svgText = svgText.replace("<svg ", '<svg width="350" height="350" ');

      // create a canvas element to pass through
      let canvas = document.createElement('canvas');
      canvas.width = opts.size;
      canvas.height = opts.size;
      const ctx = canvas.getContext('2d');

      // make a blob from the svg
      const svg = new Blob([svgText], {
        type: 'image/svg+xml;charset=utf-8',
      });

      // create a dom object for that image
      const url = domUrl.createObjectURL(svg);

      // create a new image to hold it the converted type
      const img = new Image();

      // when the image is loaded we can get it as base64 url
      img.onload = function () {
        // draw it to the canvas
        const nativeSize = 350;
        const scale = opts.size / nativeSize;

        const width = img.naturalWidth * opts.zoom * scale;
        const height = img.naturalHeight * opts.zoom * scale;

        ctx?.drawImage(
          img,
          // opts.moveX * scale * opts.zoom + (img.naturalWidth - width)/2 + ,
          (width - img.naturalWidth * scale) / -2 +
            opts.moveX * opts.zoom * scale,
          // -opts.moveY * scale + (img.naturalHeight - height)/2,
          (height - img.naturalHeight * scale) / -2 +
            opts.moveY * opts.zoom * scale,
          width,
          height,
        );
        // img.naturalWidth , img.naturalHeight);
        log?.info('loaded image');

        // if it needs some styling, we need a new canvas
        if (fill) {
          log?.info('fill');
          const styled = document.createElement('canvas');
          styled.width = canvas.width;
          styled.height = canvas.height;
          const styledCtx = styled.getContext('2d');
          if (!styledCtx) return;

          styledCtx.save();
          styledCtx.fillStyle = fill;
          styledCtx.fillRect(0, 0, canvas.width, canvas.height);
          // styledCtx.strokeRect(0,0,canvas.width,canvas.height);
          styledCtx.restore();
          styledCtx.drawImage(canvas, 0, 0);
          canvas = styled;
        }

        // add circle shape to canvas
        if (opts.circle) {
          const rounded = document.createElement('canvas');
          rounded.width = canvas.width;
          rounded.height = canvas.height;

          const roundedCtx = rounded.getContext('2d');
          if (!roundedCtx) return;

          roundedCtx?.save();
          roundedCtx?.beginPath();
          roundedCtx?.arc(
            canvas.width / 2,
            canvas.height / 2,
            canvas.width / 2,
            0,
            Math.PI * 2,
            false,
          );
          roundedCtx?.clip();
          roundedCtx.drawImage(canvas, 0, 0);
          roundedCtx.restore();
          canvas = rounded;
        }

        log?.info('url', url);
        // we don't need the original any more
        domUrl.revokeObjectURL(url);
        // now we can resolve the promise, passing the base64 url
        resolve(canvas.toDataURL());
      };

      img.onerror = function (e) {
        log?.info('err', e);
      };

      log?.info('set url', url, svgText);
      // load the image
      img.src = url;
    } catch (err) {
      reject('failed to convert svg to png ' + err);
    }
  });
}
