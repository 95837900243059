import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import theme from '../../../themev2';
import SvgSwap from '../../../assets/SvgSwap';
import FurballRenderer from '../../../components/Furballs/FurballRenderer';

import { IPartyGridItem } from './FormationGrid';
import { useBossBattle } from '../useBossBattle';
import { EntitySkill } from '../../../components/schema';
import SvgIconUpgrade from '../../../assets/SvgIconUpgrade';

const useStyles = makeStyles((theme: Theme) => ({
  furballWrap: {
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    top: '-1.4rem',

    '&[data-no-cursor="true"]': {
      pointerEvents: 'none',
    },

    '&[data-show-swap="false"]': {
      '&:hover': {
        '& [data-shadow]': {
          backgroundColor: '#afffb22a',
        },
      },
    },

    '&[data-show-swap="true"]': {
      '&:hover': {
        '& [data-shadow]': {
          borderColor: `${theme.palette.warning.light} !important`,
          backgroundColor: '#ff99002e',
        },

        '& [data-swap-icon]': {
          opacity: '1 !important',
        },

        '& [data-position-text]': {
          opacity: 0,
        },
      },
    },
  },

  furballShadow: {
    position: 'absolute',
    width: '7.5rem',
    bottom: '-1.6rem',
    margin: 'auto',
    marginRight: '0.8rem',
    borderRadius: '100%',
    height: '2rem',
    border: `3px solid ${theme.palette.primary.main}`,

    '&[data-selected="true"]': {
      backgroundColor: '#5B2879 !important',
      borderColor: theme.palette.error.light,
    },

    '&[data-moving="true"]': {
      borderColor: `${theme.palette.warning.light} !important`,
      backgroundColor: '#ff99002e',
    },

    '&[data-empty="true"]': {
      borderColor: theme.palette.grey[400],
      backgroundColor: '#00000079',
    },

    [theme.breakpoints.down('sm')]: {
      width: '6rem',
      bottom: '-1rem',
      marginRight: '0.6rem',
    },
  },

  swapOutIcon: {
    position: 'absolute',
    bottom: '-2.5rem',
    left: 0,
    right: 0,
  },

  positionText: {
    position: 'absolute',
    bottom: '-2.2rem',
    paddingTop: '1.8rem',
    left: 0,
    right: 0,
  },

  swapInIcon: {
    margin: 'auto',
    display: 'inline-grid',
    placeItems: 'center',

    '&[data-moving="false"]': {
      opacity: 0,
    },

    '&:last-of-type': {
      left: 'unset',
      right: '-1.3rem',
    },
  },

  furball: {
    margin: 'auto',
    position: 'absolute',
  },

  furballPlaceholder: {
    visibility: 'hidden',
  },

  topIconContainer: {
    position: 'absolute',
    top: '-2.3rem',
    marginRight: '-1rem',
    zIndex: 10,
    width: 'fit-content',
    display: 'grid',
    placeItems: 'center',

    [theme.breakpoints.down('sm')]: {
      top: '-1.8rem',
      marginRight: '-0.8rem',
    },
  },

  xButton: {
    width: '1.2rem !important',
    height: '1.2rem !important',
    padding: '0 !important',
    borderRadius: '100% !important',
    minWidth: 'unset !important',
    border: 'none !important',
    transition: 'transform 0.3s linear !important',
    transform: 'scale(0.9)',

    '&:hover': {
      opacity: '0.7 !important',
    },
  },

  noticeIcon: {
    transition: 'all 0.3s linear',

    '&:hover': {
      opacity: 0.5,
    },
  },

  noticePopup: {
    maxWidth: '12rem',

    '& > div': {
      marginBottom: '0.3rem',
      backgroundColor: '#FFF2CD',
    },
  },

  statsPopup: {
    maxWidth: '28rem',

    '& > div': {
      position: 'relative',
      left: '1.2rem',
      top: '-1.5rem',
      maxWidth: '100% !important',
      backgroundColor: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  },

  clearIcon: {
    width: '1.2rem !important',
    height: '1.2rem !important',
    padding: '0 !important',
    borderRadius: '100% !important',
    minWidth: 'unset !important',
    border: 'none !important',
    backgroundColor: '#FFF2CD !important',
    transition: 'all 0.3s linear',
    transform: 'scale(0.9)',
  },
  statsIcon: {
    width: '1.2rem !important',
    height: '1.2rem !important',
    padding: '0 !important',
    borderRadius: '100% !important',
    minWidth: 'unset !important',
    border: 'none !important',
  },
}));

interface IProps {
  index: number;
  furball: IPartyGridItem;
  warning: boolean;
  isSelected: boolean;
  setStatsTokenId: (tokenId: string) => void;
}

export interface ISkillNStats {
  skills: EntitySkill[];
  stats?: { [x: string]: string | number };
}

export const FormationFurball: FunctionComponent<IProps> = ({
  furball,
  warning,
  isSelected,
  index,
  setStatsTokenId,
}) => {
  const classes = useStyles();
  const [hasWarning, setHasWarning] = useState(warning);
  const [showClearWarning, setShowClearWarning] = useState(false);
  const furballId = furball?.id;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { toSwap, setToswap, handleSwap, handleSelect, isSwapping } =
    useBossBattle();

  useEffect(() => {
    setHasWarning(warning);
  }, [warning, furballId]);

  function renderShowStatsTooltip() {
    if (furball.empty || hasWarning || isSelected) return null;

    return (
      <Tooltip
        placement='top'
        PopperProps={{
          className: classes.noticePopup,
        }}
        title={renderTooltipText(<>{'See stats, skills & Equipment'}</>)}>
        <span
          className={classes.topIconContainer}
          onClick={(e) => e.stopPropagation()}>
          <Button
            className={classes.statsIcon}
            onClick={() => setStatsTokenId(furball.id)}>
            <SvgIconUpgrade
              height={35}
              width={35}
              fill={theme.palette.primary.main}
            />
          </Button>
        </span>
      </Tooltip>
    );
  }

  function renderTooltipText(text: JSX.Element) {
    return (
      <Grid padding='0.5rem 0.3rem'>
        <Typography
          variant='body2'
          fontSize='0.7rem'
          lineHeight='150%'
          textAlign='center'
          color='#856404'>
          {text}
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <div
        style={{
          gridArea: furball.position,
        }}
        className={classes.furballWrap}
        data-no-cursor={!!isSwapping || (furball.empty && !toSwap)}
        data-party-slot-id={furball.id}
        data-party-slot-index={index}
        data-show-swap={toSwap !== undefined && toSwap?.id !== furball.id}
        onClick={() => handleSwap(furball, index, furball.empty === true)}>
        {renderShowStatsTooltip()}
        {isSelected && (
          <span
            className={classes.topIconContainer}
            onClick={(e) => {
              e.stopPropagation();
              setToswap(undefined);
              handleSelect(furball);
              // setHasWarning(warning);
              // setShowClearWarning(false)
            }}>
            <Button
              variant='contained'
              color='error'
              className={classes.xButton}>
              <Typography variant='body1' fontWeight='600' fontSize='0.7rem'>
                &#x2715;
              </Typography>
            </Button>
          </span>
        )}

        <span
          data-shadow
          data-empty={furball.empty}
          data-moving={isSwapping === index}
          data-selected={toSwap?.id === furball.id}
          className={classes.furballShadow}>
          {toSwap?.id === furball.id ? (
            <Typography
              fontWeight='600'
              textAlign='center'
              data-moving={!!isSwapping}
              className={classes.swapOutIcon}>
              <SvgSwap
                width='2.2rem'
                height='2.2rem'
                fill={theme.palette.error.light}
              />
            </Typography>
          ) : (
            <Grid
              container
              gap='0.2rem'
              alignItems='center'
              justifyContent='center'
              className={classes.positionText}>
              <span
                data-swap-icon
                data-moving={isSwapping === index}
                className={classes.swapInIcon}>
                <SvgSwap
                  width='2.2rem'
                  height='2.2rem'
                  fill={theme.palette.warning.light}
                />
              </span>

              <Typography
                fontSize='0.65rem !important'
                textAlign='center'
                fontWeight='600'
                width='fit-content'
                position='absolute'
                data-position-text
                visibility={
                  isSwapping === index || !furball?.empty ? 'hidden' : 'visible'
                }
                color={theme.palette.grey[400]}>
                {furball?.position?.replaceAll('_', ' ')}
              </Typography>
            </Grid>
          )}
        </span>
        <FurballRenderer
          className={clsx(classes.furball, {
            [classes.furballPlaceholder]: furball.empty,
          })}
          tokenId={furball.id}
          size={isSmallScreen ? '6.4rem' : '8rem'}
        />
      </div>
    </>
  );
};
