import React from 'react';
import { IRenderPart } from '../part';
import DapperShirt from './00_DapperShirt';
import TripleChin from './01_TripleChin';
import Chin from './02_Chin';
import SpikedCollar from './03_SpikedCollar';
import Bowtie from './04_Bowtie';
import ConeofShame from './05_ConeofShame';
import Pirate from './06_Pirate';
import Fur from './07_Fur';
import DogTag from './08_DogTag';
import ETHoodie from './09_ETHoodie';
import GreyHoodie from './10_GreyHoodie';
import FurdenzaHoodie from './11_FurdenzaHoodie';
import RoyalCape from './12_RoyalCape';
import HarnessPower from './13_HarnessPower';
import DejaVuPower from './14_DejaVuPower';
import FrostingPower from './15_FrostingPower';
import KillerPower from './16_KillerPower';
import BeardPower from './17_BeardPower';
import FurCoatPower from './18_FurCoatPower';
import RobotPower from './19_RobotPower';

export function renderEdition0Outfit(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <DapperShirt renderer={renderer} />;
  if (renderer.partNumber === 2) return <TripleChin renderer={renderer} />;
  if (renderer.partNumber === 3) return <Chin renderer={renderer} />;
  if (renderer.partNumber === 4) return <SpikedCollar renderer={renderer} />;
  if (renderer.partNumber === 5) return <Bowtie renderer={renderer} />;
  if (renderer.partNumber === 6) return <ConeofShame renderer={renderer} />;
  if (renderer.partNumber === 7) return <Pirate renderer={renderer} />;
  if (renderer.partNumber === 8) return <Fur renderer={renderer} />;
  if (renderer.partNumber === 9) return <DogTag renderer={renderer} />;
  if (renderer.partNumber === 10) return <ETHoodie renderer={renderer} />;
  if (renderer.partNumber === 11) return <GreyHoodie renderer={renderer} />;
  if (renderer.partNumber === 12) return <FurdenzaHoodie renderer={renderer} />;
  if (renderer.partNumber === 13) return <RoyalCape renderer={renderer} />;
  if (renderer.partNumber === 14) return <HarnessPower renderer={renderer} />;
  if (renderer.partNumber === 15) return <DejaVuPower renderer={renderer} />;
  if (renderer.partNumber === 16) return <FrostingPower renderer={renderer} />;
  if (renderer.partNumber === 17) return <KillerPower renderer={renderer} />;
  if (renderer.partNumber === 18) return <BeardPower renderer={renderer} />;
  if (renderer.partNumber === 19) return <FurCoatPower renderer={renderer} />;
  if (renderer.partNumber === 20) return <RobotPower renderer={renderer} />;
  console.warn('renderEdition0Outfit', renderer);
}
