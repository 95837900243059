import { Button, Grid, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { purple } from '../../theme';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import StatCallout from './StatCallout';
import { IStatCallout } from './StatTypes';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    // height: '40px',
    // backgroundColor: '#AAA',
    marginLeft: '0px !important',
    // borderRadius: '8px',
    width: 'calc(100% + 0px) !important',
    position: 'relative',
  },
  active: {
    cursor: 'pointer',
    '&:hover': {},
  },
  inactive: {
    opacity: '0.5',
  },
  odd: {
    backgroundColor: '#EEE',
  },
  title: {
    textAlign: 'left',
    paddingTop: '4px !important',
    textTransform: 'uppercase',
  },
  icon: {},
  iconCell: {
    minHeight: '68px',
  },
  calloutCell: {
    paddingRight: '8px',
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  name: {
    textAlign: 'left',
    paddingBottom: '4px !important',
  },
  row: {
    backgroundColor: '#EEEEEE',
  },
  calloutWrap: {
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    height: '100%',
    verticalAlign: 'middle',
    paddingTop: theme.spacing(0.25),
    marginRight: theme.spacing(1.5),
  },
  callout: {
    color: '#FFF',
    width: '100%',
  },
  subtext: {
    fontSize: '1rem',
    color: theme.palette.grey.A700,
  },
  activeOverlay: {
    position: 'absolute',
    paddingTop: theme.spacing(1),
    top: 0,
    right: 0,
    left: 0,
    bottom: '10px',
    backgroundColor: '#A8FFB9AA',
    textAlign: 'center',
  },
}));

interface OwnProps {}

type Props = {
  title: string;
  name: string;
  odd: boolean;
  callout?: IStatCallout;
  icon?: React.ReactNode;
  onClick?: () => void;
  activeText?: string;
  extraButton?: React.ReactNode;
};

export const ItemStatRow: FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { title, name, callout, odd, icon, onClick, activeText, extraButton } =
    props;
  const [active, setActive] = React.useState(false);
  const useActive = onClick || extraButton;
  // const calloutBk = props.calloutBk ?? '/svgs/callout-combined.svg';

  function mouseoverRow(over: boolean): void {
    if (!useActive) return;
    setActive(over);
  }

  function renderActive(): React.ReactNode {
    return (
      <div className={classes.activeOverlay}>
        {onClick && (
          <Button variant={'contained'} color={'primary'} onClick={onClick}>
            {activeText ?? 'Upgrade'}
          </Button>
        )}
        {extraButton}
      </div>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      // onClick={onClick}
      className={clsx(classes.root, {
        [classes.odd]: odd,
        [classes.active]: useActive,
      })}
      onMouseEnter={() => mouseoverRow(true)}
      onMouseLeave={() => mouseoverRow(false)}>
      {icon && (
        <Grid
          item
          xs={2}
          className={clsx(classes.iconCell, { [classes.inactive]: active })}>
          {icon}
        </Grid>
      )}
      <Grid
        item
        xs={icon ? (callout ? 6 : 10) : callout ? 8 : 12}
        className={clsx(classes.title, { [classes.inactive]: active })}>
        <Typography
          variant={'body2'}
          color={'secondary'}
          style={{ position: 'relative', top: '2px', color: purple.dark }}>
          {title}
        </Typography>
        <Typography variant={'body1'} className={classes.name}>
          {name}
        </Typography>
      </Grid>
      {callout && (
        <Grid
          item
          xs={4}
          className={clsx(classes.calloutCell, { [classes.inactive]: active })}>
          <StatCallout {...callout} />
        </Grid>
      )}
      {active && renderActive()}
    </Grid>
  );
};

export default ItemStatRow;
