import { EntitySkill } from '../../components/schema';

export interface IUpgradeControlProps {
  skill: EntitySkill;
  label: string;
  furballId: string;
  partials: number;
  setPartials: (partials: number) => void;
  partialsSelected: number;
  setPartialsSelected: (amount: number, type: SkillType) => void;
  setError: (err: string | undefined) => void;
  setLoading: (loading: boolean) => void;
  isLoading: boolean;
  isOnCooldown: (type: SkillType) => boolean;
  getCooldownRemaining: (upgradedAt: number) => ICooldownRemaining;
  numUpgraded: number;
  maxDailyUpgrades: number;
}

export interface IParams {
  currentLevel: number;
  currentBar: number;
  ownedPartials: number;
  isMaxLevel: boolean;
  isPreMaxLevel: boolean;
  noChanges: boolean;
}

export type IUpgradeControlHook = Pick<
  IUpgradeControlProps,
  | 'skill'
  | 'partials'
  | 'setPartials'
  | 'partialsSelected'
  | 'setPartialsSelected'
  | 'furballId'
  | 'numUpgraded'
  | 'maxDailyUpgrades'
>;

export enum SkillType {
  Level = 'level',
  Uses = 'uses',
}

export interface ICooldownRemaining {
  hours: number;
  mins: number;
}
