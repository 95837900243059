import React from 'react';
import { IRenderPart } from '../part';
import Corgi from './00_Corgi';
import Drool from './01_Drool';
import Grumpy from './02_Grumpy';
import SnaggleTooth from './03_SnaggleTooth';
import HappyHeart from './04_HappyHeart';
import BubbleGum from './05_BubbleGum';
import Pug from './06_Pug';
import Sloth from './07_Sloth';
import Fangs from './08_Fangs';
import Surprised from './09_Surprised';
import Pipe from './10_Pipe';
import Teeth from './11_Teeth';
import Cigar from './12_Cigar';
import Walrus from './13_Walrus';
import SideSmile from './14_SideSmile';

export function renderEdition0Mouth(renderer: IRenderPart): React.ReactNode {
  if (renderer.partNumber === 0) return null;
  if (renderer.partNumber === 1) return <Corgi renderer={renderer} />;
  if (renderer.partNumber === 2) return <Drool renderer={renderer} />;
  if (renderer.partNumber === 3) return <Grumpy renderer={renderer} />;
  if (renderer.partNumber === 4) return <SnaggleTooth renderer={renderer} />;
  if (renderer.partNumber === 5) return <HappyHeart renderer={renderer} />;
  if (renderer.partNumber === 6) return <BubbleGum renderer={renderer} />;
  if (renderer.partNumber === 7) return <Pug renderer={renderer} />;
  if (renderer.partNumber === 8) return <Sloth renderer={renderer} />;
  if (renderer.partNumber === 9) return <Fangs renderer={renderer} />;
  if (renderer.partNumber === 10) return <Surprised renderer={renderer} />;
  if (renderer.partNumber === 11) return <Pipe renderer={renderer} />;
  if (renderer.partNumber === 12) return <Teeth renderer={renderer} />;
  if (renderer.partNumber === 13) return <Cigar renderer={renderer} />;
  if (renderer.partNumber === 14) return <Walrus renderer={renderer} />;
  if (renderer.partNumber === 15) return <SideSmile renderer={renderer} />;
  console.warn('renderEdition0Mouth', renderer);
}
