import React, { FunctionComponent } from 'react';
import { LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuthContext } from '../../components/Auth/AuthContext';
import { Redirect, useParams } from 'react-router-dom';
import { fireLoginEvent, fireSignUpEvent } from '../../Analytics';
import { useWalletSelector } from '../../hooks';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

interface IWelcome {}

interface IParams {
  source?: string;
}

// Post-login page.
export const Welcome: FunctionComponent<IWelcome> = (props) => {
  const classes = useStyles();
  const { log } = useFurComponent(Welcome);
  const params = useParams<IParams>();
  const { isSignedIn, nonce, setNonce } = useAuthContext();
  // const playerId = useWalletSelector((c) => c.contents?.id ?? '');
  const welcomeSource = params.source ?? 'unknown';
  const [expired, setExpired] = React.useState(false);
  const account = useWalletSelector((s) => s.contents);
  const furballsLoaded = useWalletSelector((s) => !!s.usableFurballsLoaded);
  const hasEnoughFurballs = (account?.tokenIds.length ?? 0) >= 3;
  // const [triedLoad, setTriedLoad] = React.useState(false);

  React.useEffect(() => {
    const to = setTimeout(() => {
      if (!isSignedIn) setExpired(true);
    }, 2000);
    return () => {
      clearTimeout(to);
    };
  }, [isSignedIn]);

  //
  const accountAge = account?.createdAt
    ? new Date().getTime() - new Date(account.createdAt).getTime()
    : 0;
  const isNewPlayer =
    account &&
    isSignedIn &&
    account.tokenIds.length === 0 &&
    account.bossBattleCount === 0 &&
    accountAge < 15 * 60000;
  React.useEffect(() => {
    if (isNewPlayer) {
      log.info('new player from', welcomeSource);
      fireSignUpEvent(welcomeSource);
    } else {
      log.info('account age', accountAge / (60000 * 60 * 24), 'days');
    }
  }, [isNewPlayer]);

  // React.useEffect(() => {
  //   if (isSignedIn && !hasFurballs && !furballsLoaded && !triedLoad) {
  //     setTriedLoad(true);
  //     setNonce(nonce + 1);
  //   }
  // }, [isSignedIn, hasFurballs, nonce, triedLoad]);

  React.useEffect(() => {
    if (isSignedIn) {
      fireLoginEvent(welcomeSource);
    }
  }, [isSignedIn]);

  // return <OnboardStart />;

  // if signin is fully successful, then just go to account
  if (isSignedIn) {
    if (!furballsLoaded) {
      // Still waiting for players' furballs...
      return (
        <div style={{ marginTop: 100, width: '100%', textAlign: 'center' }}>
          <Typography variant={'h4'}>Loading Furballs...</Typography>
          <LinearProgress color={'primary'} />
        </div>
      );
    }

    return <Redirect to={'/furverse'} />; // don't show account page, go right to game
  }

  // If we are not signed in and timeout expired, just redirect back to a login...?
  if (expired) return <Redirect to={'/login/welcome'} />;

  // Only short wait, after which we get redirected...
  return (
    <>
      <LinearProgress color={'primary'} />
    </>
  );
};

export default Welcome;
