import React, { FunctionComponent } from 'react';
import { Fade, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { black, headerFont, white } from '../../themev2';
import useBossFightsStyles from './useBossFightsStyles';
import { cdnRoot } from '../../theme';
import { RealmType } from '../schema';

const useStyles = makeStyles((theme: Theme) => ({
  bossLevel: {
    position: 'absolute',
    top: '7.5px',
    left: '15px',
    fontWeight: 'bold',
    fontSize: '14px !important',
    textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  healthWrapper: {
    position: 'absolute',
    left: '0',
    bottom: '-30px',
    display: 'flex',
    alignItems: 'center',
    minWidth: '100%',
    zIndex: 1000,
  },
  healthBar: {
    height: '25px',
    width: '100%',
    borderTop: '3px solid black',
    borderBottom: '3px solid black',
    position: 'relative',
    backgroundColor: white,

    '& span': {
      position: 'absolute',
      left: '7.5px',
      top: '1px',
      color: black,
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  healthBarProgress: {
    height: '100%',
    width: '100%',
  },
  healthDisplay: {
    height: '45px',
    border: '3px solid black',
    minWidth: '75px',
    borderRadius: '8px',
    backgroundColor: '#3edf99',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: black,

    '& p': {
      fontFamily: `${headerFont} !important`,
      fontSize: '35px !important',
      fontWeight: 'bold',

      '& span': {
        fontSize: '1.7rem !important',
      },
    },
  },
  button: {
    border: 'none !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    height: '45px',
    borderRadius: '60px',
  },
  overlay: {
    position: 'absolute',
    bottom: '0',
    height: '100%',
    width: '100%',
    background:
      'linear-gradient(to bottom, rgba(84, 48,224, 0.4), rgba(84, 48,224, 1))',
  },
}));

export interface IBossInfo {
  health?: number;
  maxHealth?: number;
  level?: number;
  realm: RealmType;
  name: string;
}

interface IBossHeader {
  boss: IBossInfo;
  showInfo: boolean;
}

const BossHeader: FunctionComponent<IBossHeader> = (props) => {
  const { boss, showInfo } = props;

  const classes = useStyles();
  const bossStyles = useBossFightsStyles();

  function getBossHealth() {
    const { health, maxHealth } = boss;
    if (!health || !maxHealth) return;

    const percentage = (health / maxHealth) * 100;
    return Math.round(percentage);
  }

  function renderHealthBar() {
    const bossHealth = getBossHealth();

    return (
      <Fade in={true}>
        <div className={classes.healthWrapper}>
          <div
            className={classes.healthDisplay}
            style={{ minWidth: bossHealth === 100 ? '90px' : '75px' }}>
            <p>
              {bossHealth}
              <span>%</span>
            </p>
          </div>
          <div className={classes.healthBar}>
            <span>HP</span>
            <div
              className={classes.healthBarProgress}
              style={{
                width: `${bossHealth}%`,
                maxWidth: '100%',
                background:
                  'linear-gradient(90deg, rgba(77,227,158,1) 18%, rgba(179,247,177,1) 61%, rgba(220,248,164,1) 100%)',
              }}
            />
          </div>
        </div>
      </Fade>
    );
  }

  return (
    <div
      className={bossStyles.bossCardHeader}
      style={{
        backgroundImage: `url(${cdnRoot}/svgs/background-${boss.realm.toLowerCase()}.svg)`,
        backgroundSize: 'cover',
      }}>
      <div className={classes.overlay}>
        {showInfo && (
          <span className={classes.bossLevel}>LVL {boss.level}</span>
        )}
        <h3 className={bossStyles.bossCardTitle}>{boss.name}</h3>
      </div>
      {showInfo && renderHealthBar()}
    </div>
  );
};

export default BossHeader;
