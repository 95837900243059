import { SvgEditionSlot } from '../part';
import * as React from 'react';

const OhNo = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <ellipse opacity={0.15} cx={158} cy={150} rx={21} ry={22} />
    <ellipse opacity={0.15} cx={234} cy={151} rx={19} ry={20} />
    <circle fill='#FFF' cx={161} cy={143} r={23} />
    <circle fill='#99e6ff' cx={166} cy={144} r={14} />
    <circle cx={166} cy={144} r={10} />
    <circle fill='#FFF' cx={167} cy={149} r={2} />
    <circle fill='#FFF' cx={232} cy={143} r={23} />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={232}
      cy={143}
      r={23}
    />
    <circle
      fill='none'
      stroke='#000'
      strokeWidth={4}
      strokeMiterlimit={10}
      cx={161}
      cy={143}
      r={23}
    />
    <circle fill='#99e6ff' cx={228} cy={144} r={14} />
    <circle cx={228} cy={144} r={10} />
    <circle fill='#FFF' cx={229} cy={149} r={2} />
  </svg>
);

export default OhNo;
