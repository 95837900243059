import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { white } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
    backgroundColor: '#d8dce0',
    marginRight: '7px',

    '@media (max-width: 380px)': {
      height: '40px',
      width: '40px',
      marginTop: '5px',
    },
  },
  bossLeaderboardsHeader: {
    color: white,
    textAlign: 'center',
    margin: '0 2rem 2rem 2rem',
    maxWidth: '1300px !important',
    padding: '0 !important',
    display: 'flex !important',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    position: 'relative',

    '@media (max-width: 1300px)': {
      margin: '0 2rem 2rem 2rem',
      flexDirection: 'column',

      '& h6': {
        marginBottom: '1rem',
      },
    },

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  bossCardHeader: {
    backgroundColor: theme.palette.secondary.light,
    color: white,
    position: 'relative',
    maxHeight: '93px !important',
    minHeight: '80px !important',

    '@media (max-width: 1400px)': {
      cursor: 'pointer',
      borderRadius: '6px',
    },
  },
  bossCard: {
    backgroundColor: '#E2E2E2',
    width: '100%',
    maxWidth: '900px',
    borderRadius: '6px',
    minHeight: '475px',
    maxHeight: '475px',
    overflow: 'hidden',
    zIndex: 100,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',

    '@media (min-width: 1800px)': {
      minWidth: '425px',
      width: '425px',
    },
  },
  bossLobby: {
    minWidth: '100%',
    minHeight: '100%',
    padding: '1.5rem 1rem 0 1rem',
  },
  bossLobbyGrid: {
    display: 'grid !important',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(3, 1fr) !important',
    maxHeight: '100%',

    '@media (max-width: 1300px)': {
      gridTemplateColumns: '1fr !important',
      marginBottom: '2rem',
      placeItems: 'center',
    },
  },
  bossCardTitle: {
    textAlign: 'center',
    fontSize: '40px !important',
    margin: '0.5rem 0 0 0',
    textTransform: 'capitalize',

    [theme.breakpoints.down('sm')]: {
      fontSize: '30px !important',
      marginTop: '15px',
    },
  },
  shakeAnimation: {
    animation: '$shake 0.8s cubic-bezier(.36,.5,.35,.97) both',
    animationIterationCount: '1',
    transform: 'translate3d(0, 0, 0)',
  },

  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(2px, 0, 0)',
    },

    '30%, 50%, 70%': {
      transform: 'translate3d(-4px, 0, 0)',
    },

    '40%, 60%': {
      transform: 'translate3d(4px, 0, 0)',
    },
  },
}));

export default useStyles;
