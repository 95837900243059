import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Theme, Typography } from '@mui/material';
import theme, { shadeColor } from '../../../themev2';
import { cdnRoot } from '../../../theme';
import SvgScroll from '../../../assets/SvgScroll';
import SvgIconBossHistoryPlayed from '../../../assets/SvgIconBossHistoryPlayed';
import { getResponsiveWidth } from '../../../utils';
import { IPercentiles } from './BossBattleHistory';

const useStylesWithProps = (onAccountPage: boolean) => {
  const { desktop } = getResponsiveWidth(onAccountPage);

  return makeStyles((theme: Theme) => {
    return {
      root: {
        minWidth: '325px',
        maxWidth: '325px',
        minHeight: '542px',
        backgroundColor: 'white',
        borderRadius: '12px',
        padding: '1.3rem',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',

        [theme.breakpoints.down(desktop)]: {
          flexDirection: 'row',
          maxWidth: '100%',
          minHeight: '275px',
        },

        '@media (max-width: 950px)': {
          flexDirection: 'column',
          minHeight: '542px',
        },

        [theme.breakpoints.down('sm')]: {
          minWidth: '100%',
          maxWidth: '100%',
        },
      },
      topStat: {
        minHeight: '165px',
        minWidth: '165px',
        maxHeight: '165px',
        maxWidth: '165px',
        borderRadius: '50%',
        border: `6px solid  ${theme.palette.secondary.light}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '1rem 0',

        '& h6': {
          fontSize: '4.5rem !important',
          marginLeft: '0.85rem !important',
        },

        '& span': {
          fontSize: '1.5rem',
          marginTop: '0.3rem',
        },
      },
      infoCont: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '0.85rem',

        [theme.breakpoints.down(desktop)]: {
          maxWidth: '277px',
        },

        '@media (max-width: 950px)': {
          maxWidth: '100%',
        },
      },
      info: {
        padding: '0.9rem 1rem',
        width: '100%',
        minHeight: '50px',
        backgroundColor: shadeColor(theme.palette.secondary.main, 80),
        borderRadius: '6px',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        maxHeight: '57px',

        '& h6': {
          marginLeft: '0.6rem !important',
        },
      },
      contractInfo: {
        padding: '0.7rem 1rem',
        borderRadius: '6px',
        border: `1px solid ${theme.palette.secondary.light}`,
        display: 'flex',
        textAlign: 'left',
        flexDirection: 'column',
        justifyContent: 'flex-start',

        '& h6': {
          display: 'flex',
          alignItems: 'center',
        },

        '& p': {
          fontSize: '14px !important',
          marginTop: '2px !important',

          '& span': {
            color: theme.palette.secondary.light,
          },
        },

        '& svg': {
          marginRight: '0.5rem',
        },
      },
    };
  })();
};

interface IBossBattleStats {
  percentiles: IPercentiles;
  battlesPlayed: number;
  numRentalCancellationsOwner: number;
  numRentalCancellationsPlayer: number;
  onAccountPage?: boolean;
}

export const BossBattleStats: FunctionComponent<IBossBattleStats> = (props) => {
  const {
    percentiles,
    battlesPlayed,
    numRentalCancellationsOwner,
    numRentalCancellationsPlayer,
    onAccountPage,
  } = props;
  const classes = useStylesWithProps(!!onAccountPage);
  const percentileLastSeven = cleanPercentile(percentiles.lastSevenDays);
  const percentileLastThirty = cleanPercentile(percentiles.lastThirtyDays);

  function cleanPercentile(percentile: number) {
    if (percentile === 0) return 1;
    if (percentile > 100 || isNaN(percentile)) return 100;
    return percentile;
  }

  return (
    <div className={classes.root}>
      <div>
        <Typography variant={'h6'} style={{ fontSize: '1.25rem' }}>
          Top
        </Typography>
        <Fade in={true} timeout={600}>
          <div className={classes.topStat}>
            <Typography variant={'h6'}>{percentileLastSeven}</Typography>
            <span
              style={{
                marginLeft: percentileLastSeven === 100 ? '0' : '0.3rem',
              }}>
              %
            </span>
          </div>
        </Fade>
        <Typography variant={'h6'}>of players last 7 days</Typography>
      </div>
      <div className={classes.infoCont}>
        <div className={classes.contractInfo}>
          <Typography variant={'h6'}>
            <SvgScroll
              width='20px'
              height='20px'
              fill={theme.palette.secondary.light}
            />
            Contract Cancellations
          </Typography>
          <Typography variant={'body1'}>
            <span>{numRentalCancellationsOwner}</span> as owner,{' '}
            <span>{numRentalCancellationsPlayer}</span> as scholar
          </Typography>
        </div>
        <div className={classes.info}>
          <img
            width='20px'
            height='20px'
            src={`${cdnRoot}/svgs/main_nav_icons_Leaderboards.svg`}
          />
          <Typography variant={'h6'}>
            Top {percentileLastThirty}% of players last 30 days
          </Typography>
        </div>
        <div className={classes.info}>
          <SvgIconBossHistoryPlayed height={20} width={20} />
          <Typography variant={'h6'}>
            {battlesPlayed} battle{battlesPlayed === 1 ? '' : 's'} played last
            30 days
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default BossBattleStats;
