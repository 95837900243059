import * as React from 'react';
import { useFurverseContext } from './Furverse';
import { usePageTitle } from '../../Analytics';
import useCommonStyles from '../../components/useCommonStyles';
import { useNotifications } from '../../components/Notifications/NotificationsContext';

interface IFurversePage {}

const FurversePage: React.FunctionComponent<IFurversePage> = (props) => {
  const { setOptionalArgs } = useFurverseContext(true, 'furverse');

  const { unreadNotifications } = useNotifications();
  const unreadNotificationCount = unreadNotifications?.length ?? 0;

  const common = useCommonStyles();
  usePageTitle('Furverse');

  // const context = React.useContext(WalletContext);
  // const isMainNet = context?.network.type === NetworkType.MainNet;

  React.useEffect(() => {
    setOptionalArgs({ unreadNotificationCount });
  }, [unreadNotificationCount]);

  // if (isMainNet) return <Redirect to={'/play'} />;

  return <div className={common.emptyPage} />;
};

export default FurversePage;
