import React, { FunctionComponent } from 'react';
import {
  Theme,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Container,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { purple } from '../../theme';

import useCommonStyles from '../../components/useCommonStyles';
import { useWalletSelector } from '../../hooks';
import FurballEditor from './FurballEditor/FurballEditor';
import { CommunityFlag } from '../../components/schema';
import { useParams } from 'react-router-dom';
import SvgCaret from '../../assets/SvgCaret';
import AirdropRequester from './FurballEditor/AirdropRequester';
import GiftPlayer from './GiftPlayer';
import NameChanges from './NameChanges';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  content: {
    backgroundColor: 'white',
  },
}));

interface ICommunityLeads {}

interface ICommunityQuery {
  query: string;
}

export const CommunityLeads: FunctionComponent<ICommunityLeads> = (props) => {
  const flags = useWalletSelector((s) => s.contents?.communityFlags ?? []);
  const common = useCommonStyles();
  const classes = useStyles();
  const { query } = useParams<ICommunityQuery>();

  if (
    !flags.includes(CommunityFlag.Support) &&
    !flags.includes(CommunityFlag.Admin)
  ) {
    return <React.Fragment>NOPE {flags.join(', ')}</React.Fragment>;
  }

  function renderAccordion(
    title: string,
    child: React.ReactNode,
  ): React.ReactNode {
    return (
      <Accordion
        // key={`${tab.interactionType ?? ''}-${tab.title}`}
        className={common.cardRoot}
        // defaultExpanded={tab.expanded}
      >
        <AccordionSummary
          className={common.cardHeader}
          expandIcon={
            <React.Fragment>
              <SvgCaret fill={'#FFF'} className={common.expandIcon} />
            </React.Fragment>
          }>
          <Typography variant={'h5'} style={{ color: 'white' }}>
            {title}
          </Typography>
          {/*{tab.tix && <Typography variant={'h6'} className={classes.tix}>{tab.tix} TIX</Typography>}*/}
        </AccordionSummary>
        <AccordionDetails className={classes.content}>{child}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Grid
      container
      className={clsx(common.fullPadding, common.fullScreen)}
      style={{ backgroundColor: purple.dark }}>
      <Grid item xs={12}>
        <Container>
          {renderAccordion('Request Airdrop', <AirdropRequester />)}
          {renderAccordion(
            'Edit Furball',
            <FurballEditor queryString={query} />,
          )}
          {renderAccordion('Gift Player Items', <GiftPlayer />)}
          {renderAccordion('Approve Name Changes', <NameChanges />)}
        </Container>
      </Grid>
    </Grid>
  );
};

export default CommunityLeads;
