import { useEffect, useState, useCallback } from 'react';
import { useStyles } from '../SkillLevelControl';
import { IParams, IUpgradeControlHook, SkillType } from '../SkillTypes';
import { useWalletSelector } from '../../../hooks';
import { getSkillDefinitionById } from '../SkillLibrary';

export const useSkillLevelControls = ({
  skill,
  partials,
  setPartials,
  partialsSelected,
  setPartialsSelected,
  furballId,
  numUpgraded,
  maxDailyUpgrades,
}: IUpgradeControlHook) => {
  const def = getSkillDefinitionById(skill.skillDefinitionId);
  const defaultParams = {
    currentLevel: skill.level,
    currentBar:
      skill.level === 0 ? partials : partials % skill.levelNumPartialsPerTier,
    ownedPartials:
      (def?.maxLevel ?? 0) * skill.levelNumPartialsPerTier -
      skill.levelTotalPartialsRemaining,
    isMaxLevel: skill.level === def?.maxLevel,
    isPreMaxLevel: skill.level === (def?.maxLevel ?? 0) - 1,
    noChanges: true,
  };

  const classes = useStyles();
  const wFurBalance = useWalletSelector((s) => s.contents?.wFur ?? 0);
  const canAfford = skill.levelPartialCost <= wFurBalance;
  let startIndicatorFromIndex = 0;

  const [bars, setBars] = useState<number[]>();
  const [params, setparams] = useState<IParams>(defaultParams);
  const [dummyPartials, setDummyPartials] = useState<boolean>(false);
  const [count, setCount] = useState<number | null>();

  const handleUpgrade = (type: 'down' | 'up') => {
    if (type === 'down') {
      const newPartials = partials - 1;
      setCount(newPartials);
      setPartials(newPartials);
      setPartialsSelected(-1, SkillType.Level);
    }

    if (type === 'up') {
      const newPartials = partials + 1;
      setCount(newPartials);
      setPartials(newPartials);
      setPartialsSelected(1, SkillType.Level);
    }
  };

  const getBarStatus = useCallback(
    (i: number) => {
      const { ownedPartials, currentLevel } = params;
      const { levelNumPartialsPerTier, levelIsUpgradeable, level } = skill;

      const currentLvlIsOwned = currentLevel === level;
      const ownedPartialsOnCurrentLvl = currentLvlIsOwned
        ? ownedPartials % levelNumPartialsPerTier
        : 0;

      const barIsOwned = i <= ownedPartialsOnCurrentLvl || !levelIsUpgradeable;
      const barIsNew = i > ownedPartialsOnCurrentLvl;

      const barIsWithinFilledIndexes = i <= partials % levelNumPartialsPerTier;
      const barIsPending = barIsNew && barIsWithinFilledIndexes;

      const barsCount = skill.levelNumPartialsPerTier;
      const cummulativeIndex = i + currentLevel * barsCount;

      if (barIsOwned && partialsSelected !== maxDailyUpgrades) {
        startIndicatorFromIndex = cummulativeIndex;
      }

      const barClasses = barIsOwned
        ? classes.progressBarFilled
        : barIsPending
        ? classes.progressBarHalfFilled
        : dummyPartials
        ? classes.progressBarHalfFilled
        : params.isMaxLevel
        ? classes.progressBarFilled
        : cummulativeIndex <= maxDailyUpgrades + startIndicatorFromIndex
        ? classes.progressBarIndicator
        : '';

      return barClasses;
    },
    [skill, params, count, numUpgraded, maxDailyUpgrades],
  );

  const getOwnedPartials = useCallback(() => {
    const { levelNumPartialsPerTier, levelTotalPartialsRemaining } = skill;
    const def = getSkillDefinitionById(skill.skillDefinitionId);

    const maxPartials = (def?.maxLevel ?? 1) * levelNumPartialsPerTier;
    const ownedPartials = maxPartials - levelTotalPartialsRemaining;

    return ownedPartials;
  }, [skill, count]);

  useEffect(() => {
    if (!skill) return;

    setCount(undefined);
    setDummyPartials(false);
    setparams(defaultParams);

    const noOfBars = skill.levelNumPartialsPerTier;
    const arrayLength = noOfBars <= 0 ? 8 : noOfBars;
    setBars(Array.from(Array(arrayLength), (_, i) => i + 1));

    const ownedPartials = getOwnedPartials();
    skill.levelIsUpgradeable && setPartials(ownedPartials);
  }, [skill, furballId]);

  useEffect(() => {
    const { level, levelIsUpgradeable, levelNumPartialsPerTier } = skill;

    const def = getSkillDefinitionById(skill.skillDefinitionId);
    const maxLevel = def?.maxLevel ?? 0;

    if (!levelIsUpgradeable) {
      setparams({
        ...params,
        isMaxLevel: true,
        currentLevel: skill.level,
      });

      return;
    }

    const currentLevel = Math.trunc(partials / levelNumPartialsPerTier);
    const isMaxLevel = currentLevel === maxLevel;
    const isPreMaxLevel = currentLevel + 1 === maxLevel;
    const isLvlZero = currentLevel === 0;
    const currentBar = isLvlZero
      ? partials
      : partials % levelNumPartialsPerTier;

    const ownedPartials = getOwnedPartials();
    const noChanges = level === currentLevel && partials === ownedPartials;

    setparams({
      currentLevel,
      isMaxLevel,
      isPreMaxLevel,
      currentBar,
      noChanges,
      ownedPartials,
    });

    isMaxLevel ? setDummyPartials(true) : setDummyPartials(false);
  }, [partials, skill, furballId]);

  return {
    canAfford,
    bars,
    params,
    dummyPartials,
    count,
    handleUpgrade,
    getBarStatus,
    setCount,
  };
};
