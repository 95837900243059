import React, { FunctionComponent } from 'react';

import { Theme, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePlayersReadyFurballs } from '../../wallet';
import HorizontalScroller from '../HorizontalScroller';
import FurballCard from './FurballCard';
import useCommonStyles from '../useCommonStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    textAlign: 'center',
  },
  scroll: {
    overflowX: 'auto',
    overflowY: 'hidden',
    minHeight: '380px',
    position: 'relative',
    bottom: 20,
    width: '100%',
    verticalAlign: 'top',
    paddingRight: '40px',
  },
  furball: {
    display: 'inline-block',
    textAlign: 'center',
    marginLeft: theme.spacing(2),
    position: 'absolute',
  },
  header: {
    position: 'relative',
    top: -40,
  },
}));

type Props = {
  onChose: (tokenId: string) => void;
};

export const ChooseFurball: FunctionComponent<Props> = (props) => {
  const { onChose } = props;
  const classes = useStyles();
  const common = useCommonStyles();
  const furballs = usePlayersReadyFurballs();
  const [fbIndex, setFbIndex] = React.useState(0);

  return (
    <div className={classes.root}>
      <Typography variant={'h3'} className={classes.header}>
        Choose a Furball
      </Typography>
      <HorizontalScroller
        className={classes.scroll}
        itemIndex={fbIndex}
        setItemIndex={setFbIndex}
        numItems={furballs.length}
        renderItem={(p, i) => {
          const fb = furballs[p];
          if (!fb) return null;
          return (
            <div
              key={fb.tokenId}
              className={classes.furball}
              style={{ left: i * 340 + 40 }}>
              <FurballCard tokenId={fb.tokenId} disableExpansion={true} />
              <br />
              <Button
                variant={'contained'}
                color={'primary'}
                className={common.actionButton}
                onClick={() => onChose(fb.tokenId)}>
                <Typography variant={'h5'}>Select</Typography>
              </Button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default ChooseFurball;
