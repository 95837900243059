import { Button, Theme, Typography } from '@mui/material';
import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { mint } from '../theme';
import { useOAuthUrl } from './oauth';
import SvgIconApple from '../assets/SvgIconApple';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  appleButton: {
    width: '100%',
    marginBottom: '10px !important',
    color: 'white !important',
    backgroundColor: 'black !important',
    '&:hover': {
      backgroundColor: `${mint.dark} !important`,
      color: 'black !important',
    },
  },
}));
interface IAppleLoginButton {
  verb?: string;
}

// https://appleid.apple.com/auth/authorize?
// client_id=com.LFG-Gaming-LLC.Furballs
// &redirect_uri=https%3A%2F%2Fwww.furballs.com%2Foauth%2Fcallback%2Fapple
// &response_type=code%20id_token
// &state=ooo
// &scope=email
// &nonce=aoeu
// &response_mode=form_post
// &frame_id=05a344f9-975f-4b5b-bc05-3be61b16ba25&m=22&v=1.5.4
export function useAppleLoginUrl(): string {
  // 	459SFUH8VH.com.LFG-Gaming-LLC.Furballs, 98T24SRBD6
  return useOAuthUrl(
    'https://appleid.apple.com/auth/authorize',
    'com.LFG-Gaming-LLC.SignIn',
    'apple',
    'email',
    'code id_token',
    'response_mode=form_post',
  );
}
const AppleLoginButton: React.FunctionComponent<IAppleLoginButton> = (
  props,
) => {
  const verb = props.verb ?? 'Sign In';
  const classes = useStyles();
  const redirectUrl = useAppleLoginUrl();

  // React.useEffect(() => {
  //
  //   // <meta name="appleid-signin-client-id" content="459SFUH8VH.com.LFG-Gaming-LLC.Furballs">
  //   //   <meta name="appleid-signin-scope" content="email">
  //   //     <meta name="appleid-signin-redirect-uri" content="https://furballs.com/oauth/callback/apple">
  //   //       <meta name="appleid-signin-state" content="ooo">
  //   //         <meta name="appleid-signin-nonce" content="aoeu">
  //   //           <meta name="appleid-signin-use-popup" content="false">
  //   // @ts-ignore
  //   AppleID.auth.init({
  //     clientId: 'com.LFG-Gaming-LLC.Furballs',
  //     scope: 'email',
  //     redirectURI: getO
  //   });
  // }, []);
  function handleLogin() {
    window.location.href = redirectUrl;
  }

  return (
    <Button
      className={classes.appleButton}
      startIcon={<SvgIconApple fill={'white'} width='1.5rem' height='1.5rem' />}
      onClick={handleLogin}>
      <Typography variant='h6' fontSize='0.8rem'>
        {verb} With Apple
      </Typography>
    </Button>
  );
};

export default AppleLoginButton;
