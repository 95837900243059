import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  Theme,
  Grid,
  Typography,
  SelectChangeEvent,
  TextField,
  Button,
  CircularProgress,
  MenuItem,
} from '@mui/material';

import clsx from 'clsx';
import theme from '../../themev2';
import SelectCustom from '../../components/SelectCustom';
import WalletContext from '../../wallet/WalletContext';

import { makeStyles } from '@mui/styles';
import { IFurballBase } from '../../wallet/WalletTypes';
import { signScholarRental } from '../../components/Auth/ScholarRental';
import { useWalletSelector } from '../../hooks';
import { useCreateRentalAgreementMutation } from '../../components/schema';
import { AlertContext, ALERT_TYPES } from '../../components/Alert/AlertContext';
import { useFurballHelpers } from '../../components/Furballs';
import { usePlayersReadyFurballs } from '../../wallet';
import FurballRenderer from '../Furballs/FurballRenderer';
import { defaultBossKeys } from '../Loot';
import { useFurComponent } from '../../utils';

const useStyles = makeStyles((theme: Theme) => ({
  textField: {
    '& .MuiInputBase-root': {
      borderRadius: '6px !important',
    },

    '& .MuiInputBase-input': {
      borderRadius: '6px !important',
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.common.black}`,
      padding: '10px 15px',

      '&:focus': {
        borderRadius: '6px !important',
      },
    },
  },

  textFieldLight: {
    '& .MuiInputBase-input': {
      border: `1px solid ${theme.palette.grey[300]}`,
    },

    '& fieldset': {
      border: 'none',
    },
  },

  selectLight: {
    '& > div': {
      border: `1px solid ${theme.palette.grey[300]} !important`,
    },
  },

  formContainer: {
    transition: 'all 0.3s linear',

    '&[data-disabled="true"]': {
      pointerEvents: 'none',
      opacity: 0.2,
    },
  },

  boldWarningText: {
    fontWeight: 800,
  },

  selectMenu: {
    maxHeight: '25rem !important',
    boxShadow: '0 3px 0 3px #ffffff !important',
  },

  selectMenuText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexGrow: 1,
  },

  selectFurball: {
    position: 'relative',
  },
}));

interface IProps {
  light?: boolean;
  furball?: IFurballBase;
  onSuccess?: () => void;
}

export const ScholarshipPlacement: FunctionComponent<IProps> = ({
  light,
  furball,
  onSuccess,
}) => {
  const { log } = useFurComponent(ScholarshipPlacement);
  const classes = useStyles();
  const context = useContext(WalletContext);
  const addr = useWalletSelector((s) => s.address);

  const [createAgreement] = useCreateRentalAgreementMutation();

  const [fb, setFb] = useState<IFurballBase | undefined>(furball);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    duration: 7,
    autoRenew: true,
    reserved: '',
  });

  const { addAlert } = useContext(AlertContext);
  const { refreshFurballs } = useFurballHelpers(log);
  const furballs = usePlayersReadyFurballs();

  const leaseableFurballs = useCallback(() => {
    const filtered = furballs.filter((item) => {
      const noAgreement =
        !item.openRentalAgreement && !item.activeRentalAgreement;

      return noAgreement;
    });

    const sorted = filtered.sort((a, b) => {
      const aHasBosskey = a?.inventory?.items?.find((item) =>
        defaultBossKeys.includes(item.itemId),
      );

      const bHasBosskey = b?.inventory?.items?.find((item) =>
        defaultBossKeys.includes(item.itemId),
      );

      const keepOrder =
        (aHasBosskey && bHasBosskey) || (!aHasBosskey && !bHasBosskey);

      return keepOrder ? 0 : aHasBosskey ? -1 : 1;
    });

    return sorted?.map((furball) => ({
      value: furball.tokenId,
      name: furball.name,
      furball: furball,
      disabled: !furball.inventory.items.find((item) =>
        defaultBossKeys.includes(item.itemId),
      ),
    }));
  }, [furballs]);

  const hasBosskey = useCallback(() => {
    if (!fb) {
      return true;
    }

    const key = fb?.inventory?.items?.find((item) =>
      defaultBossKeys.includes(item.itemId),
    );

    return !!key;
  }, [fb, furball]);

  const handleChange = (
    e:
      | SelectChangeEvent<unknown>
      | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!context || !addr || !fb) return;

    const { autoRenew, reserved, duration } = formData;

    const durationSeconds = duration * 24 * 60 * 60;
    const reservee = !!formData.reserved ? formData.reserved : undefined;
    setLoading(true);

    try {
      const { signature, startsAt, percent } = await signScholarRental(
        context,
        addr,
        fb.tokenId,
        durationSeconds,
        autoRenew,
        reservee,
      );

      await createAgreement({
        variables: {
          startsAt,
          signature,
          percent,
          ownerId: addr,
          playerId: !!reserved ? reserved : null,
          duration: durationSeconds,
          autoRenew: autoRenew,
          furballId: fb.tokenId,
        },
      });

      await refreshFurballs([fb.tokenId]);
      addAlert({
        type: ALERT_TYPES.SUCCESS,
        message: `${fb.name} is now up for rent`,
      });

      onSuccess && onSuccess();
    } catch (error: any) {
      setLoading(false);

      addAlert({
        type: ALERT_TYPES.ERROR,
        message: error.message ?? 'Oops! Something went wrong, please retry.',
      });
    }
  };

  const myLeasableFurballs = leaseableFurballs();
  const renderFurballs = myLeasableFurballs.length < 20;

  return (
    <Grid container direction='column' gap='2rem'>
      {!furball && (
        <Grid
          container
          direction='column'
          gap='1rem'
          data-disabled={loading || !hasBosskey()}
          className={classes.formContainer}>
          <Grid container direction='column' gap='0.5rem'>
            <Typography
              fontSize='1rem'
              fontWeight='600'
              color={light ? theme.palette.secondary.dark : 'white'}>
              Select Furball
            </Typography>

            <SelectCustom
              id='rent-duration'
              disabled={loading || !hasBosskey()}
              value={fb?.tokenId ?? ''}
              inputClass={clsx({ [classes.selectLight]: light })}
              name='duration'
              MenuProps={{
                classes: { paper: classes.selectMenu },
              }}
              onChange={(e) => {
                const selected = myLeasableFurballs.find(
                  (item) => item.value === e.target.value,
                );

                setFb(selected?.furball);
              }}>
              {myLeasableFurballs.map((item, index) => (
                <MenuItem
                  key={index}
                  value={item.value}
                  disabled={item.disabled}>
                  <Grid container alignItems='center' gap='0.4rem'>
                    {renderFurballs && (
                      <FurballRenderer
                        className={classes.selectFurball}
                        size='2rem'
                        tokenId={item.value}
                      />
                    )}
                    <div className={classes.selectMenuText}>
                      <Typography lineHeight={'unset'} variant='body1'>
                        <b>{item.name}</b>
                      </Typography>
                      {item.disabled && (
                        <Typography
                          fontSize='0.8rem'
                          color={theme.palette.error.light}>
                          No boss keys
                        </Typography>
                      )}
                    </div>
                  </Grid>
                </MenuItem>
              ))}
            </SelectCustom>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        gap='0.5rem'
        maxWidth='90%'
        alignItems='center'
        flexWrap='nowrap'
        marginTop={!furball ? '0' : '0.8rem'}
        data-disabled={loading}
        className={classes.formContainer}>
        <Typography
          fontSize='1.1rem'
          color={
            hasBosskey()
              ? light
                ? theme.palette.secondary.dark
                : 'white'
              : theme.palette.grey[500]
          }>
          {hasBosskey() ? (
            'Place this furball for rent on the marketplace or reserve for a particular user.'
          ) : (
            <>
              Furballs need to possess at least one{' '}
              <b className={classes.boldWarningText}>BOSS-KEY</b> to be eligible
              for scholarship contracts
            </>
          )}
        </Typography>
      </Grid>

      <Grid
        container
        direction='column'
        gap='1rem'
        data-disabled={loading || !hasBosskey()}
        className={classes.formContainer}>
        <Grid container direction='column' gap='0.5rem'>
          <Typography
            fontSize='1rem'
            fontWeight='600'
            color={light ? theme.palette.secondary.dark : 'white'}>
            Reserved for (optional)
          </Typography>

          <TextField
            className={clsx(classes.textField, {
              [classes.textFieldLight]: light,
            })}
            variant='outlined'
            color='secondary'
            id='rent-reserved'
            value={formData.reserved}
            name='reserved'
            placeholder='Wallet address or discord username'
            onChange={handleChange}
            disabled={loading || !hasBosskey()}
          />
        </Grid>

        <Grid container direction='column' gap='0.5rem'>
          <Typography
            fontSize='1rem'
            fontWeight='600'
            color={light ? theme.palette.secondary.dark : 'white'}>
            Duration*
          </Typography>
          <SelectCustom
            id='rent-duration'
            disabled={loading || !hasBosskey()}
            value={formData.duration}
            inputClass={clsx({ [classes.selectLight]: light })}
            name='duration'
            options={[
              {
                label: '3 days',
                value: 3,
              },
              {
                label: '1 week',
                value: 7,
              },
              {
                label: '1 month',
                value: 30,
              },
            ]}
            onChange={handleChange}
          />
        </Grid>

        <Grid container direction='column' gap='0.5rem'>
          <Typography
            fontSize='1rem'
            fontWeight='600'
            color={light ? theme.palette.secondary.dark : 'white'}>
            Autorenews*
          </Typography>
          <SelectCustom
            id='rent-autorenew'
            disabled={loading || !hasBosskey()}
            value={formData.autoRenew}
            inputClass={clsx({ [classes.selectLight]: light })}
            name='autoRenew'
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Button
        variant='contained'
        fullWidth
        type='button'
        disabled={loading || !hasBosskey()}
        onClick={handleSubmit}>
        {loading ? (
          <CircularProgress color='secondary' size='1.5rem' />
        ) : (
          'Place For Rent'
        )}
      </Button>
    </Grid>
  );
};
