import React, { FunctionComponent } from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import OkDialog, { ICloseDialog } from '../../OkDialog';
import { RealmType } from '../../schema';
import MoveZonesButton from '../MoveZonesButton';
import { getRealmName, ZONE_NUM_VOYAGE } from '../ZoneHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'left',
    maxWidth: '700px',
    margin: '0 auto',
  },
  realmText: {
    marginBottom: '50px',

    '& h2': {
      marginBottom: '10px !important',
    },

    '& p': {
      color: '#757575 !important',
      maxWidth: '500px',
    },

    ['@media (max-width: 700px)']: {
      margin: '30px 10px',

      '& h2': {
        fontSize: '2.5rem !important',
      },

      '& p': {
        fontSize: '0.95rem !important',
      },
    },
  },
  content: {
    margin: '0 35px !important',

    ['@media (max-width: 700px)']: {
      margin: '0 !important',
    },
  },
}));

interface IRealmPreviewDialog extends ICloseDialog {
  furballIds: string[];
  currentZone: number;
  realmToEnter: RealmType;
  onAccept: () => void;
  closeEarly?: () => void;
}

export function getVoyagePreview(realmToEnter: RealmType): string {
  if (realmToEnter === RealmType.WizardsPath)
    return (
      'Rumor has it at the end of the rainbow path lives an evil wizard that has been blocking access to the Furverse! ' +
      'The strange part is, no one has heard from the wizard in years. Can you get to the castle and see what’s happening?'
    );
  if (realmToEnter === RealmType.TurtleSea)
    return (
      'Something in that Pirate ship has been hoarding trash and making these waters murky. ' +
      'The turtles are fed up being caught in all of this leftover trash. ' +
      'Can you get to the ship and clean this mess up?'
    );
  if (realmToEnter === RealmType.Boneyard)
    return (
      'A wind whispers from caves, calling out. ' +
      'Deep below resides a portal to the Furverse, but surely such a prize must be guarded by evil minions...'
    );

  return '?';
}

export const RealmPreviewDialog: FunctionComponent<IRealmPreviewDialog> = (
  props,
) => {
  const {
    open,
    onClose,
    realmToEnter,
    onAccept,
    currentZone,
    furballIds,
    closeEarly,
  } = props;
  const classes = useStyles();

  return (
    <OkDialog
      className={clsx(classes.root)}
      title={'Enter Voyage?'}
      open={open}
      onClose={onClose}>
      <Grid container spacing={2} className={classes.root}>
        <br />
        <Grid item xs={12} className={classes.content}>
          <div className={classes.realmText}>
            <Typography variant={'h2'}>{getRealmName(realmToEnter)}</Typography>
            <Typography variant={'body1'}>
              {getVoyagePreview(realmToEnter)}
            </Typography>
          </div>
          <MoveZonesButton
            onSaved={onAccept}
            realm={realmToEnter}
            currentZone={currentZone}
            zoneTarget={ZONE_NUM_VOYAGE}
            furballIds={furballIds}
            fromRealmPreview={true}
            closeEarly={closeEarly}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Typography variant={'subtitle2'}>
            You must pay 100,000 $wFUR per-furball to enter.
          </Typography>
        </Grid>
      </Grid>
    </OkDialog>
  );
};

export default RealmPreviewDialog;
