import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@mui/styles';
import {
  styled,
  Theme,
  InputBase,
  Select as SelectBase,
  SelectProps,
  MenuItem,
  Typography,
  Grid,
} from '@mui/material';
import { black, white } from '../themev2';

// import clsx from 'clsx';
import useCommonStyles from './useCommonStyles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
  },

  selectList: {
    padding: '0 !important',
  },

  icon: {
    height: '2.3rem !important',
    width: '2.3rem !important',
    top: 'unset !important',

    '&.primary': {
      color: `${black} !important`,
    },

    '&.secondary': {
      color: `${black} !important`,
    },
  },

  hasCustomIcon: {
    display: 'none !important',
  },

  customIcon: {
    display: 'grid',
    placeItems: 'center',
    position: 'absolute',
    right: '0.9rem',
    flexShrink: 0,
    height: '100%',

    '& > *': {
      pointerEvents: 'none',
    },
  },

  fieldLabel: {
    backgroundColor: '#E9ECEF', //theme.palette.grey[200],
    borderRadius: '6px 0 0 6px',
    display: 'grid',
    placeItems: 'center',
    padding: '0.2rem 0.7rem',
    border: `1px solid ${black}`,
  },
}));

export interface ISelectProps extends SelectProps {
  dummyMenuItem?: ReactNode;
  options?: {
    label: string;
    value: any;
    disabled?: boolean;
    hidden?: boolean;
  }[];
  fullwidth?: boolean;
  inputClass?: string;
  iconColor?: 'default' | 'primary' | 'secondary';
  fieldLabel?: ReactNode;
  icon?: ReactNode;
}

export const SelectCustom: FunctionComponent<ISelectProps> = (props) => {
  const classes = useStyles();
  const common = useCommonStyles();

  function renderMenuItems() {
    return props.options?.map(({ label, value, disabled, hidden }, i) => (
      <MenuItem
        value={value}
        key={i}
        sx={{ display: hidden ? 'none' : undefined }}
        disabled={props.value === value || disabled}>
        <Typography
          lineHeight={'unset'}
          variant='body1'
          sx={{ textTransform: 'capitalize' }}>
          {label}
        </Typography>
      </MenuItem>
    ));
  }

  const BootstrapInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
      borderRadius: props.fieldLabel ? '0 6px 6px 0 !important' : 6,
      position: 'relative',
      backgroundColor: white,
      border: `1px solid ${black}`,
      borderLeft: props.fieldLabel
        ? 'none'
        : `1px solid ${theme.palette.common.black}`,
      padding: '10px 15px',

      '&:focus': {
        borderRadius: 6,
      },
    },
  }));

  const {
    dummyMenuItem,
    options,
    fullwidth,
    iconColor,
    inputClass,
    fieldLabel,
    icon,
    ...selectProps
  } = props;

  return (
    <Grid
      container
      alignItems='stretch'
      className={classes.root}
      style={{ width: props.fullWidth ? '100%' : 'initial' }}>
      {props.fieldLabel && (
        <Grid item className={classes.fieldLabel}>
          <Typography
            variant='subtitle1'
            fontSize='0.8rem !important'
            color={black}>
            {props.fieldLabel}
          </Typography>
        </Grid>
      )}

      <Grid item flexGrow={1}>
        <SelectBase
          {...selectProps}
          input={<BootstrapInput className={props.inputClass} />}
          style={{ width: '100%', height: '100%', overflow: 'hidden' }}
          classes={{
            ...props.classes,
            icon: clsx(props.classes?.icon, classes.icon, props.iconColor, {
              [classes.hasCustomIcon]: props.icon !== undefined,
            }),
          }}
          MenuProps={{
            ...props.MenuProps,
            classes: {
              ...props.MenuProps?.classes,
              paper: clsx(common.select, props.MenuProps?.classes?.paper),
              list: clsx(classes.selectList, props.MenuProps?.classes?.list),
            },
          }}>
          {props.children || renderMenuItems()}
        </SelectBase>
      </Grid>

      {props.icon !== undefined && (
        <div className={classes.customIcon}>{props.icon}</div>
      )}
    </Grid>
  );
};

export default SelectCustom;
