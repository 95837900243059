import * as React from 'react';

function SvgIconCoinStack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      version='1.1'
      id='Capa_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 489.9 489.9'
      xmlSpace='preserve'
      {...props}>
      <g>
        <g>
          <path
            d='M183.85,216.3c33.8-43.1,86.3-70.8,145.4-70.8c8.8,0,17.5,0.6,26.1,1.8v-39.4c-26.7,24-95.9,41.1-177.2,41.1
			c-80.8,0-149.8-16.9-176.8-40.8l0,50.7C14.85,214.2,181.95,216.4,183.85,216.3z'
          />
          <path d='M1.25,198.5L1.15,250c6.5,28.6,66.8,51.8,144.9,56.9c3-24.3,10.8-47.2,22.4-67.6C91.75,238,27.05,221.5,1.25,198.5z' />
          <path
            d='M1.15,290l-0.1,49c0.3-0.3,0.6-0.6,0.9-0.8c1.9,31.6,69.4,57.5,156.1,61.2c-8.7-21.4-13.4-44.7-13.4-69.2v-0.1
			C78.45,326,24.15,310.6,1.15,290z'
          />
          <path
            d='M169.55,422.7c-78.2-1.3-143.9-18.6-168.5-42.2l-0.1,46.2c0,34.8,79.3,63,177.2,63c18.7,0,36.7-1,53.6-2.9
			C206.15,470.8,184.75,448.8,169.55,422.7z'
          />
          <ellipse cx='178.15' cy='62.9' rx='177.4' ry='62.9' />
          <path
            d='M329.25,170.2c-88.3,0-159.9,71.6-159.9,159.9s71.6,159.8,159.9,159.8s159.9-71.6,159.9-159.9S417.55,170.2,329.25,170.2z
			 M343.85,415.4v10.1c0,8.1-6.5,14.6-14.6,14.6s-14.6-6.5-14.6-14.6v-10.1c-8.5-2.6-16.4-7.3-22.6-13.9c-5.5-5.9-5.2-15.1,0.6-20.6
			c5.9-5.5,14.8-4.9,20.6,0.6c6.4,6.1,17,6.8,17.5,6.8c11.3-0.8,20.3-10.3,20.3-21.8c0-12-9.8-21.9-21.8-21.9c-28.1,0-51-22.9-51-51
			c0-23.1,15.4-42.6,36.4-48.9v-10.1c0-8.1,6.5-14.6,14.6-14.6s14.6,6.5,14.6,14.6v10.1c8.5,2.6,16.4,7.3,22.6,13.9
			c5.5,5.9,5.2,15.1-0.6,20.6c-5.9,5.5-15.6,5.7-20.6-0.6c-5.3-6.7-17-6.8-17.5-6.8c-11.3,0.8-20.3,10.3-20.3,21.8
			c0,12,9.8,21.9,21.8,21.9c28.1,0,51,22.9,51,51C380.25,389.6,364.85,409.1,343.85,415.4z'
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgIconCoinStack;
