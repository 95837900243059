import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Theme, useTheme } from '@mui/material';
// import { white } from '../../themev2';
import CurrencyAmountInput from './CurrencyAmountInput';
import clsx from 'clsx';
import {
  getSwapCurrencyDescription,
  IExchangeCurrency,
  SwapCurrency,
} from './ExchangeCurrencies';
import CurrencySelector from './CurrencySelector';
import { mint } from '../../themev2';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    borderRadius: '10px',
    // borderStyle: '3px solid',
    border: '3px solid',
    borderColor: theme.palette.grey.A200,
    padding: '12px 14px',
    width: '100%',
    minHeight: '105px',
    // maxHeight: '105px',
    margin: '8px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  swapTopSection: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  swapBottomSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  smallText: {
    fontSize: '11px',
    color: 'rgba(77, 77, 77, 1)',
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  balance: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right',
    },
  },
}));

export type UpdateCurrencyAmount = (
  swapAmount: string,
  option: SwapCurrency,
) => void;

export interface ISwapOption {
  swapOption: IExchangeCurrency;
  updateAmount: UpdateCurrencyAmount;
}

export interface ISwapSpot extends ISwapOption {
  balance?: number;
  options: SwapCurrency[];
  onSelectCurrency: (c: SwapCurrency) => void;
  input?: React.ReactNode;
}

const CurrencyExchangeRow: FunctionComponent<ISwapSpot> = (props) => {
  const {
    input,
    options,
    swapOption,
    balance,
    updateAmount,
    onSelectCurrency,
  } = props;
  const { currency, error } = swapOption;
  const description = getSwapCurrencyDescription(currency);
  const [focused, setFocused] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const borderColor = input
    ? 'white'
    : focused
    ? mint.light
    : error
    ? theme.palette.error.light
    : theme.palette.grey.A200;
  const maxHeight = input ? undefined : '105px';

  function updateFocused(f: boolean) {
    if (!f && focused) {
      onSelectCurrency(currency); // re-render
    }
    setFocused(f);
  }

  function renderInput() {
    if (input) return input;
    return (
      <>
        <CurrencyAmountInput
          value={swapOption.amount}
          valueStr={swapOption.amountStr}
          currency={swapOption.currency}
          updateAmount={updateAmount}
          setFocused={updateFocused}
        />
        {error && <span className={classes.smallText}>{error}</span>}
        {!error && balance !== undefined && (
          <span
            className={clsx(classes.smallText, classes.balance)}
            style={{
              cursor: 'pointer',
              fontWeight: 800,
            }}
            onClick={() =>
              updateAmount(balance.toString(), swapOption.currency)
            }>
            Balance: {balance.toLocaleString()}
          </span>
        )}
      </>
    );
  }

  return (
    <div className={classes.root} style={{ borderColor, maxHeight }}>
      <div className={classes.swapTopSection}>
        <CurrencySelector
          currency={currency}
          options={options}
          onSelectCurrency={onSelectCurrency}
        />
        <span className={clsx(classes.smallText, classes.description)}>
          {description}
        </span>
      </div>
      <div className={classes.swapBottomSection}>{renderInput()}</div>
    </div>
  );
};

export default CurrencyExchangeRow;
