import React, { FunctionComponent } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Fade, Theme } from '@mui/material';
import { IRewardItem } from './BossRewardsReveal';
import clsx from 'clsx';
import { cdnRoot } from '../../../theme';
import { ItemDefinitionFragment } from '../../schema';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '10px',
    borderRadius: '12px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    gap: '7.5px',
    bottom: -40,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 100,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    maxWidth: '90%',

    [theme.breakpoints.down('lg')]: {
      bottom: 0,
      borderRadius: '0',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
      justifyContent: 'normal',
      overflowX: 'auto',
    },
  },
  itemIndicator: {
    minWidth: '80px !important',
    minHeight: '80px !important',
    borderRadius: '8px !important',
    backgroundColor: '#EDE9FE !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '2px solid #DDD6FE !important',
  },
  revealed: {
    border: '2px solid #A7F3D0 !important',
    backgroundColor: `${theme.palette.primary.light} !important`,
  },
}));

interface IBossRewardsOverview {
  lootItems: IRewardItem[];
  show: boolean;
  itemDefs: { [p: string]: ItemDefinitionFragment };
  goToItem: (index: number) => void;
  iconSrc: string;
}

const BossRewardsOverview: FunctionComponent<IBossRewardsOverview> = (
  props,
) => {
  const { lootItems, show, itemDefs, goToItem, iconSrc } = props;
  const classes = useStyles();

  function renderItemIndicators() {
    return lootItems.map(({ item, revealed }, i) => {
      return (
        <Button
          color={'secondary'}
          onClick={() => goToItem(i)}
          className={clsx(classes.itemIndicator, {
            [classes.revealed]: revealed,
          })}
          key={`item-indicator-${i}`}>
          <img
            src={
              revealed
                ? `${cdnRoot}/images/icons/boss_loot/${
                    itemDefs[item.itemId].icon
                  }.png`
                : iconSrc
            }
            height={60}
            width={60}
          />
        </Button>
      );
    });
  }

  return (
    <Fade in={show} timeout={300}>
      <div
        className={classes.root}
        style={{
          width: `calc(10px + ${lootItems.length * 90}px)`,
        }}>
        {renderItemIndicators()}
      </div>
    </Fade>
  );
};

export default BossRewardsOverview;
