import { SvgEditionSlot } from '../part';
import * as React from 'react';

const BubbleGum = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M187 157.48c4.27 1.17 7.49 6.24 7.49 12.31 0 5.47-2.61 10.12-6.24 11.85'
    />
    <path d='M204.88 170.08c0 3.92-4.03 7.09-8.99 7.09s-2.13-3.17-2.13-7.09-2.84-7.09 2.13-7.09 8.99 3.17 8.99 7.09z' />
    <path
      fill='#feaaca'
      d='M225.2 146.17c-9.88.02-18.31 6.2-21.68 14.89-1.07 2.76-1.54 4.61-4.5 4.61h-.11c-1.87 0-3.38 1.51-3.38 3.38v.84c0 1.87 1.51 3.38 3.38 3.38h.11c2.93 0 3.42 1.8 4.47 4.54 3.35 8.75 11.83 14.97 21.77 14.97 12.93 0 23.4-10.53 23.3-23.49-.1-12.76-10.59-23.15-23.36-23.12z'
    />
    <path
      fill='#fe82c9'
      d='M239.42 151a23.13 23.13 0 0 1 4.82 13.98c.1 12.95-10.37 23.49-23.3 23.49-5.33 0-10.25-1.79-14.17-4.81 4.26 5.55 10.96 9.13 18.49 9.13 12.93 0 23.4-10.53 23.3-23.49-.06-7.44-3.64-14.06-9.14-18.3z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeMiterlimit={10}
      d='M225.2 146.17c-9.88.02-18.31 6.2-21.68 14.89-1.07 2.76-1.54 4.61-4.5 4.61h-.11c-1.87 0-3.38 1.51-3.38 3.38v.84c0 1.87 1.51 3.38 3.38 3.38h.11c2.93 0 3.42 1.8 4.47 4.54 3.35 8.75 11.83 14.97 21.77 14.97 12.93 0 23.4-10.53 23.3-23.49-.1-12.76-10.59-23.15-23.36-23.12z'
    />
    <g opacity={0.9} fill='#FFF'>
      <path
        opacity={0.6}
        d='M209.39 176.68c-.73 0-1.47-.44-1.65-1.18-3.7-15.26 6.08-22.16 12.88-23.9.94-.24 1.89.33 2.13 1.26.24.94-.32 1.89-1.26 2.13-5.51 1.41-13.61 7.05-10.44 19.37.24.94-.17 1.91-1.09 2.22-.19.07-.38.1-.57.1z'
      />
      <circle cx={226} cy={152} r={2} />
    </g>
  </svg>
);

export default BubbleGum;
