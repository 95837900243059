import { SvgEditionSlot } from '../part';
import * as React from 'react';

const DejaVuPower = (props: SvgEditionSlot) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 350 350'
    xmlSpace='preserve'
    {...(props.svg ?? {})}>
    <path d='M223.9 204.35s-35.99 10.14-61.45-5.57c0 0 22.04 21.69 61.45 5.57z' />
    <path
      fill='#7a90ff'
      stroke='#000'
      strokeWidth={4}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M242.33 203.77c-12.33 7.64-28.41 12.88-46.01 12.88-30.16 0-55.87-15.32-65.83-31.89-2.14 1.76-2.23 7.48-2.46 8.88-2.26 13.84 57.86 51.9 113.74 22.26 2.66-1.41 4.24-4.21 3.15-7.01-.72-1.87-.72-3.8-2.59-5.12z'
    />
    <path
      fill='#ffd159'
      d='M191.42 222.62a9.17 9.17 0 0 0-9.17 9.17c0 4.46 3.18 8.17 7.39 9v-5.01h3.56v5.01c4.21-.83 7.39-4.54 7.39-9a9.17 9.17 0 0 0-9.17-9.17z'
    />
    <path
      opacity={0.56}
      fill='#FFF'
      d='M185.96 232.95a9.17 9.17 0 0 1 9.17-9.17c.27 0 .54.02.81.04a9.17 9.17 0 0 0-13.7 7.97c0 4.79 3.67 8.72 8.36 9.13-2.77-1.58-4.64-4.55-4.64-7.97z'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M199.79 228.07c0 2.15-3.75 3.89-8.37 3.89s-8.37-1.74-8.37-3.89'
    />
    <path
      fill='none'
      stroke='#FFF'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M199.24 228.04c-1.2 1.46-4.25 2.5-7.82 2.5s-6.62-1.04-7.82-2.5'
    />
    <path
      fill='none'
      stroke='#000'
      strokeWidth={3}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      d='M193.2 240.78c4.21-.83 7.39-4.54 7.39-9a9.17 9.17 0 0 0-9.17-9.17 9.17 9.17 0 0 0-9.17 9.17c0 4.46 3.18 8.17 7.39 9'
    />
    <circle cx={191} cy={237} r={3} />
  </svg>
);

export default DejaVuPower;
