import React, { FunctionComponent } from 'react';
import { luckColor } from '../../theme';
import { makeStyles } from '@mui/styles';
import { Theme, Tooltip } from '@mui/material';
import { ILootSlot } from './LootTypes';
import { expColor, furColor } from '../../theme';
import clsx from 'clsx';
import { getLootSlot } from './LootHelpers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '70px',
    height: '70px',
    paddingTop: '10px',
    padding: '2px',
    paddingLeft: '8px',
    border: '3px solid black',
    borderRadius: '16px',
  },
  sm: {
    width: '44px',
    height: '44px',
    paddingTop: '6px',
    padding: '1px',
    border: '1px solid black',
    borderRadius: '12px',
  },
  md: {},
  fit: {
    width: '100%',
    height: '100%',
  },
  exp: {
    backgroundColor: expColor.light,
  },
  fur: {
    backgroundColor: furColor.light,
  },
  luck: {
    backgroundColor: luckColor.light,
  },
  unknown: {
    backgroundColor: 'white',
  },
  wrap: {
    maxWidth: '50px',
    maxHeight: '50px',
  },
  disabled: {
    '& > *': {
      filter: 'saturate(0%)',
      opacity: 0.25,
    },
  },
}));

type Props = ILootSlot & {
  size?: 'sm' | 'md' | 'fit';
  disabled?: boolean;
  tip?: string;
};

export const LootSlot: FunctionComponent<Props> = (props) => {
  const { item, disabled, tip } = props;
  const size = props.size ?? 'md';
  const classes = useStyles();
  const unk = !!item.unknown;
  const stat = unk ? undefined : item.stat;
  const slot = getLootSlot(item);
  const title = unk
    ? 'You missed or skipped the opportunity at loot.'
    : tip ?? `${item.name}: ${slot.rarityName}`;

  return (
    <Tooltip title={title}>
      <button
        className={clsx(classes.root, {
          [classes.unknown]: unk,
          [classes.disabled]: disabled,
          [classes.exp]: stat === 'exp',
          [classes.fur]: stat === 'fur',
          [classes.luck]: stat === 'luck',
          [classes.sm]: size === 'sm',
          [classes.md]: size === 'md',
          [classes.fit]: size === 'fit',
        })}>
        <div className={classes.wrap}>{item?.icon}</div>
      </button>
    </Tooltip>
  );
};

export default LootSlot;
